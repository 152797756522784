/**
 * Utility function to delete specified fields from an object.
 * @param obj - The object from which fields should be deleted.
 * @param fields - An array of field keys to be deleted from the object.
 */
export default function deleteFields<T extends object>(
  obj: T,
  fields: (keyof T)[]
): Partial<T> {
  fields?.forEach(field => {
    delete obj[field];
  });
  return obj;
}