import gql from 'graphql-tag';

export default gql`
  mutation createChannelModule(
    $channelModule: ChannelModuleInput!
    $channel: JSON!
  ) {
    createChannelModule(channelModule: $channelModule, channel: $channel) {
      _id
    }
  }
`;
