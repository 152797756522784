export const underConstructionEvents = [
  {
    key: 'UnderConstruction.View.Index',
    description: 'Viewing the under construction page',
    userPermitted: true,
  },
  {
    key: 'UnderConstruction.Exit.SelectedChannel',
    description:
      'Exiting under construction page through selecting one of the listed channels',
  },
  {
    key: 'UnderConstruction.Exit.Back',
    description:
      'Exiting under construction page through pressing the back button (mobile only)',
  },
  {
    key: 'UnderConstruction.Exit.Profile',
    description:
      'Exiting under construction page through pressing the profile button (web only)',
  },
  {
    key: 'UnderConstruction.Exit.Settings',
    description:
      'Exiting under construction page through pressing the settings button (web only)',
  },
  {
    key: 'UnderConstruction.Exit.ChannelSwitcher',
    description:
      'Exiting under construction page through clicking on channel switcher (web only)',
  },
  {
    key: 'UnderConstruction.Exit.FindAnotherWorkplace',
    description:
      'Exiting under construction page through clicking on Find another workplace.',
  },
];
