import React from 'react';

import cx from 'classnames';
import { Dropdown } from 'components';
import { useTranslation } from 'react-i18next';

import useHostByTenantRelationship from 'lane-shared/domains/visitorManagement/hooks/useHostByTenantRelationship';
import { convertTo62 } from 'lane-shared/helpers/convertId';

import styles from './HostSelectionSearch.scss';

type HostItem = {
  label: string;
  value: string;
};

export default function HostSelectionSearch({
  onChange,
  value,
  channelId,
  className,
  style,
  testId,
  isPreview = false,
}: {
  onChange: (value: string) => void;
  value?: string | null;
  channelId: string;
  userId?: string;
  className?: string;
  style?: React.CSSProperties;
  testId?: string;
  isPreview?: boolean;
}) {
  const { t } = useTranslation();
  const { hosts, loading } = useHostByTenantRelationship(channelId);

  let items: HostItem[] = [];

  if (hosts && !loading) {
    items = hosts.map(host => ({
      label: host.username,
      value: convertTo62(host.id),
    }));
  }

  return (
    <div
      className={cx(className, styles.hostSelectionSearch)}
      style={style}
      data-test={testId}
    >
      <Dropdown
        placeholder={t(
          'web.content.feature.visitorManagement.hostSelection.form.host'
        )}
        initialValue={value}
        value={value}
        items={items}
        onValueChange={value => onChange(value)}
        isSearchable
        isFullWidth
        rightIcon="search"
        rightIconClassName={styles.searchIcon}
        iconType="far"
        initialTouched
        testId="hostSelectionDropdown"
        disabled={isPreview}
      />
    </div>
  );
}
