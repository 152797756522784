/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';

import cx from 'classnames';

import { TENANT_TYPES } from 'lane-shared/helpers/constants/channel';
import { useStoredState } from 'lane-shared/hooks';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import ChannelCircleListView from 'components/lane/ChannelCircleListView';

import Dropdown from '../form/Dropdown';
import ChannelRelationshipsSearch from './ChannelRelationshipsSearch';
import ChannelSearch, { ChannelSearchHierarchiesEnum } from './ChannelSearch';
import ChannelSearchByGeoLocation from './ChannelSearchByGeoLocation';
import MySubscriptionsPicker from './MySubscriptionsPicker';

import styles from './ChannelSelector.scss';

/**
 * What kinds of modes is the end user allowed to use when
 * selecting a channel?
 */
export enum ChannelSelectorModesEnum {
  // allow selecting based on the users current subscriptions
  Subscriptions = 'subscription',
  // allow selecting based on the users location to find nearby channels
  Geo = 'geo',
  // allow a search of any channel on the platform.
  Search = 'search',
  // allow a search of channels related to a channelId
  Relationship = 'relationship',
}

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  modes?: ChannelSelectorModesEnum[];
  hierarchies?: ChannelSearchHierarchiesEnum[];
  types?: ChannelTypeEnum[];
  onChannelSelected: (channel?: ChannelType | null) => void;
  storageKey?: string;
  disableStorage?: boolean;
  relatedToChannelId?: string;
  disableTypeSelect?: boolean;
  shouldHighlightOnClick?: boolean;
};


type Props = OwnProps;

export default function ChannelSelector({
  className,
  style,
  onChannelSelected = () => null,
  modes = [
    ChannelSelectorModesEnum.Subscriptions,
    ChannelSelectorModesEnum.Geo,
    ChannelSelectorModesEnum.Search,
  ],
  hierarchies = [
    ChannelSearchHierarchiesEnum.All,
    ChannelSearchHierarchiesEnum.Parent,
    ChannelSearchHierarchiesEnum.Location,
    ChannelSearchHierarchiesEnum.SubChannel,
  ],
  types = [
    ChannelTypeEnum.Property,
    ChannelTypeEnum.Company,
    ChannelTypeEnum.Service,
    ChannelTypeEnum.Restaurant,
    ChannelTypeEnum.Retail,
    ChannelTypeEnum.Entertainment,
    ChannelTypeEnum.Professional,
    ChannelTypeEnum.Charity,
    ChannelTypeEnum.Meta,
    ChannelTypeEnum.Curated,
  ],
  storageKey = 'ChannelSelector',
  disableStorage = false,
  relatedToChannelId,
  disableTypeSelect = false,
  shouldHighlightOnClick = false,
}: Props) {
  const searchTypes = useMemo(() => {
    const options: {
      label: string;
      value: ChannelSelectorModesEnum;
    }[] = [];

    if (modes!.includes(ChannelSelectorModesEnum.Subscriptions)) {
      options.push({
        label: 'web.admin.content.draftContent.target.channelModal.memberOf',
        value: ChannelSelectorModesEnum.Subscriptions,
      });
    }

    if (modes!.includes(ChannelSelectorModesEnum.Geo)) {
      options.push({
        label: 'web.admin.content.draftContent.target.channelModal.nearBy',
        value: ChannelSelectorModesEnum.Geo,
      });
    }

    if (modes!.includes(ChannelSelectorModesEnum.Search)) {
      options.push({
        label: 'web.admin.content.draftContent.target.channelModal.channels',
        value: ChannelSelectorModesEnum.Search,
      });
    }

    return options;
  }, [modes]);

  const [searchType, setSearchType] = useStoredState(
    `ChannelSelectorSearchType${storageKey}`,
    modes![0],
    {
      disableStorage,
    }
  );
  const { location } = useLocation();

  return (
    <div className={cx(styles.ChannelSelector, className)} style={style}>
      {searchTypes.length > 1 && (
        <Dropdown
          className={styles.dropDown}
          items={searchTypes}
          value={searchType}
          onValueChange={setSearchType}
        />
      )}
      <section>
        {searchType === ChannelSelectorModesEnum.Subscriptions && (
          <MySubscriptionsPicker onChannelSelected={onChannelSelected} />
        )}
        {searchType === ChannelSelectorModesEnum.Geo && (
          <ChannelSearchByGeoLocation
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ storageKey: string; disableStorage: boolea... Remove this comment to see the full error message
            storageKey={`ChannelSelectorChannelSearch${storageKey}`}
            disableStorage={disableStorage}
            onChannelSelected={onChannelSelected}
            location={location}
          />
        )}
        {searchType === ChannelSelectorModesEnum.Search && (
          <ChannelSearch
            storageKey={`ChannelSelectorChannelSearch${storageKey}`}
            disableStorage={disableStorage}
            hierarchies={hierarchies}
            types={types}
            onChannelSelected={onChannelSelected}
            disableTypeSelect={disableTypeSelect}
            shouldHighlightOnClick={shouldHighlightOnClick}
          />
        )}

        {searchType === ChannelSelectorModesEnum.Relationship && (
          <ChannelRelationshipsSearch
            types={TENANT_TYPES}
            className={styles.relationships}
            channelId={relatedToChannelId}
            renderRelationship={relationship => (
              <div
                key={relationship._id}
                className={styles.placementOption}
                onClick={() => onChannelSelected(relationship.channel)}
              >
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message */}
                <ChannelCircleListView channel={relationship.channel} />
              </div>
            )}
          />
        )}
      </section>
    </div>
  );
}
