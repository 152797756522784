import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ChannelFiltersError extends CodedError {
  static get errorName() {
    return errorCodes.channelFiltersError.code;
  }

  constructor(message = errorCodes.channelFiltersError.message) {
    super(message);
    this.code = errorCodes.channelFiltersError.code;
    this.message = `${message} ${errorCodes.channelFiltersError.code}`;
    this.name = errorCodes.channelFiltersError.name;
  }
}
