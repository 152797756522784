export enum SIDE_BAR_LINKS_ORDER {
  DASHBOARD,
  INSIGHTS,
  CONTENT,
  ACCESS_CONTROL,
  WORK_ORDERS,
  VISITOR_MANAGEMENT,
  RESERVABLE_MANAGER,
  HARDWARE_MANAGEMENT,
  SECTIONS,
  BILLING_AND_PAYMENTS,
  INSTANT_MESSAGING,
  USER_MANAGEMENT,
  CHANNEL_SEETINGS,
  PORTAL_MANAGEMENT,
  SEARCH_CHANNELS,
}

export enum CONTENT_LINKS_ORDER {
  CONTENT_CENTER,
  PAGE_CENTER,
  SECTIONS,
  PERKS_CENTER,
  PROMOTIONS_CENTER,
  FILTER_AND_TAGS,
  AUDIENCES,
  LIBRARY,
  CONTENT_TAGS,
  INSTANT_MESSAGING,
}

export enum BILLINGS_AND_PAYMENTS_LINKS_ORDER {
  PAYMENT_ACCOUNTS,
  PRODUCTS_AND_SERVICES,
  CREDIT_BUNDLES,
  CHARGES,
  INVOICES,
  MEMBERSHIPS,
  TRANSACTIONS_AND_REPORTS,
  MERCHANT_ACCOUNTS,
  BILLING_AND_PAYMENT_SETTINGS,
}

export enum CHANNEL_SETTINGS_LINKS_ORDER {
  PROFILE,
  FLOORS,
  UNITS,
  DATA_IMPORT,
  INTEGRATIONS,
  TAB_MANAGEMENT,
  TEAM_MANAGEMENT,
  TENANT_MANAGEMENT,
  CHANNEL_RELATIONSHIPS, // same order as tenant managment, only one be available at a time
  SUB_CHANNELS,
  CHILD_CHANNEL,
  PARENT_CHANNEL,
}

export enum WORKORDER_LINKS_ORDER {
  SERVICE_REQUEST,
  EQUIPMENT,
  PREVENTIVE_MAINTENANCE,
}
