import { ProductItem, ProductItemForCharge } from '../types';
import { GroupType } from 'graphql-query-contracts';

export function productItemsToQueryFormat(
  productItems: ProductItem[]
): ProductItemForCharge[] {
  return productItems.map(productItem => {
    const product = productItem.product;
    return {
      product: {
        id: product.id,
        name: product.name,
        description: product.description,
        amount: Number(product.amount),
        groupId: product.groupId,
        groupType: GroupType.GroupTypeActivateChannel,
        markup: {
          value: Number(product.markup?.value),
          markupAmountType: product.markup?.markupAmountType!,
        },
        tax: {
          value: Number(product.tax?.value),
          taxAmountType: product.tax?.taxAmountType!,
        },
        originalPrice: product.originalPrice
          ? {
              amount: product.originalPrice.amount,
              markup: {
                value: product.originalPrice.markup?.value || 0,
                markupAmountType: product.originalPrice.markup
                  ?.markupAmountType!,
              },
              tax: {
                value: product.originalPrice.tax?.value || 0,
                taxAmountType: product.originalPrice.tax?.taxAmountType!,
              },
            }
          : undefined,
      },
      quantity: productItem.quantity,
      productCategoryId: productItem.product.productCategory.productCategoryId,
      chargeCodeId: productItem.product.chargeCode?.id!,
    };
  });
}
