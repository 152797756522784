import gql from 'graphql-tag';

export default gql`
  mutation addCreditCard($paymentAccountId: UUID!, $source: String!) {
    payments {
      addCreditCard(paymentAccountId: $paymentAccountId, source: $source) {
        id
        brand
        exp_month
        exp_year
        last4
      }
    }
  }
`;
