import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class AcsEmailInviteSendFailedError extends CodedError {
  static get errorName() {
    return errorCodes.acsEmailInviteSendFailedError.code;
  }

  constructor(message = errorCodes.acsEmailInviteSendFailedError.message) {
    super(message);
    this.code = errorCodes.acsEmailInviteSendFailedError.code;
    this.message = `${message} ${errorCodes.acsEmailInviteSendFailedError.code}`;
    this.name = errorCodes.acsEmailInviteSendFailedError.name;
    this.status = errorCodes.acsEmailInviteSendFailedError.status;
  }
}
