import React, { useMemo } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { DocumentType } from 'lane-shared/types/DocumentType';

import ContextMenu from 'components/general/ContextMenu';
import PathStructureDropdown from 'components/navigation/PathStructureDropdown';

import useSimpleDragHelper from 'hooks/useSimpleDragHelper';

import styles from './PathSelector.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  // most paths will have special root paths that represent some system doc
  // ex. a list of libraries
  rootPaths: DocumentType[];
  selectedRootPath: DocumentType;
  // all available paths
  paths: string[];
  // the currently selected path
  selectedPath: string | null | undefined;
  onRootPathSelected: (rootPath: DocumentType) => void;
  onPathSelected: (path: string) => void;
  onPathCreate: (atPath: string) => void;
  onDrop: (e: React.DragEvent, path: string) => void;
};

export type PathType = {
  path: string;
  subPaths: PathType[];
  isSelected: boolean;
};

type Props = OwnProps;

/**
 * Based on an array of Path strings for a Library, this will render a selector
 * tool that allows a user to navigate to those different paths, also based
 * on their current path.  This is similar to the components on DropBox or
 * Google Drive that allow a user to navigate around in paths (folders) in
 * their drive.
 *
 * Example
 *
 * paths = [
 *  'Top',
 *  'Top.Countries',
 *  'Top.Countries.Canada',
 *  'Top.Countries.United_States'
 *  ...
 *]
 *
 * path = 'Top.Countries'
 *
 * Top > Countries > Canada
 *                   United States
 */
export default function PathSelector({
  className,
  style,
  rootPaths,
  selectedRootPath,
  paths,
  selectedPath,
  onRootPathSelected,
  onPathSelected = () => null,
  onPathCreate,
  onDrop = () => null,
}: Props) {
  function _dropHandler(e: any) {
    onDrop(e, '');
  }

  const {
    dragLeaveHandler,
    dragEnterHandler,
    dragOverHandler,
    dropHandler,
    isDropping,
  } = useSimpleDragHelper({ onDrop: _dropHandler });

  const pathStructure = useMemo<PathType>(() => {
    // paths cannot be used twice.
    const usedPaths: { [key: string]: boolean } = {};

    // recursive function to map sub-paths for the current path
    function mapSubPaths(path: string, paths: string[]): PathType | null {
      if (usedPaths[path]) {
        return null;
      }

      usedPaths[path] = true;

      // get all the paths that include this one (i.e. its sub paths)
      const subPaths = paths.filter(subPath => subPath.includes(`${path}.`));
      const isTopLevel = !selectedPath?.includes('.');

      let isSelected = false;

      if (isTopLevel) {
        isSelected = selectedPath === path;
      } else if (selectedPath) {
        isSelected = selectedPath.includes(path);
      }

      return {
        isSelected,
        subPaths: subPaths
          .map(subPath => mapSubPaths(subPath, subPaths) as PathType)
          .filter(subPath => subPath !== null),
        path,
      };
    }

    // sort the paths, this means they are also sorted by hierarchy
    paths.sort();

    // get all the top level paths (ones with no .) and recursively
    // build their sub-path structure

    return {
      isSelected: true,
      path: '',
      subPaths: paths
        .filter(pathOption => !pathOption.includes('.'))
        .map(pathOption => mapSubPaths(pathOption, paths) as PathType)
        .filter(pathOption => pathOption !== null),
    };
  }, [paths, selectedPath]);

  const isChildSelected = pathStructure.subPaths.some(
    ({ isSelected }) => isSelected
  );

  const menuOptions = rootPaths.map(rootPath => (
    <button key={rootPath._id} onClick={() => onRootPathSelected(rootPath)}>
      <Icon
        className={styles.menuIcon}
        name="box"
        type="fal"
        set="FontAwesome"
      />
      {rootPath.name}
    </button>
  ));

  if (!isChildSelected) {
    menuOptions.push(
      <hr key="separator" />,
      <button
        className={styles.action}
        key="add-new"
        onClick={() => onPathCreate('')}
      >
        <Icon
          className={styles.menuIcon}
          name="plus"
          type="fal"
          set="FontAwesome"
        />
        Add new folder
      </button>
    );
  }

  return (
    <div className={cx(styles.PathSelector, className)} style={style}>
      {menuOptions.length > 1 ? (
        <ContextMenu
          menuClassName={styles.menu}
          items={menuOptions}
          hasOpaqueBackground={false}
          autoFocus={false}
          autoClose
        >
          <button
            className={styles.contextButton}
            data-dropping={isDropping}
            onDragOver={dragOverHandler}
            onDragEnter={dragEnterHandler}
            onDragLeave={dragLeaveHandler}
            onDrop={dropHandler}
          >
            {selectedRootPath.name}
            <Icon
              className={styles.icon}
              name="chevron-double-right"
              type="fal"
              set="FontAwesome"
            />
          </button>
        </ContextMenu>
      ) : (
        <button
          className={styles.contextButton}
          onClick={() => onPathSelected('')}
          data-dropping={isDropping}
          onDragOver={dragOverHandler}
          onDragEnter={dragEnterHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={dropHandler}
        >
          {selectedRootPath.name}
          <Icon
            className={styles.icon}
            name="chevron-double-right"
            type="fal"
            set="FontAwesome"
          />
        </button>
      )}
      <PathStructureDropdown
        pathStructure={pathStructure}
        onPathSelected={onPathSelected}
        onPathCreate={onPathCreate}
        onDrop={onDrop}
      />
    </div>
  );
}
