import { gql } from '@apollo/client';

export const createChargeMutation = gql`
  mutation createCharge($createChargeRequest: CreateChargeRequest!) {
    accounts {
      createCharge(createChargeRequest: $createChargeRequest) {
        chargeId
      }
    }
  }
`;
