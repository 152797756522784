import { useContext } from 'react';

import { InviteContext, InviteContextType } from '.';

export const useInviteContext = (): InviteContextType => {
  const context = useContext(InviteContext);

  if (!context) {
    throw new Error(
      'invite context must be used within InviteContextProvider scope'
    );
  }

  return context;
};
