import gql from 'graphql-tag';

export const getVisitorById = gql`
  query getVisitorById($id: UUID!) {
    visitor(id: $id) {
      id
      firstName
      lastName
      email
      phone
      company
      createdAtDatetime
      updatedAtDatetime
      createdByUserId
      updatedByUserId
    }
  }
`;
