import gql from 'graphql-tag';

export default gql`
  mutation updateServiceRequestMutation(
    $updateServiceRequest: UpdateServiceRequestDTO!
  ) {
    updateServiceRequest(serviceRequest: $updateServiceRequest) {
      serviceRequestId
      updatedAt
    }
  }
`;
