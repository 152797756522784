import gql from 'graphql-tag';

export default gql`
  mutation updateBulkUsersAccessControlGroups(
    $channelId: UUID!
    $userIds: [UUID!]!
    $assignedAccessControlGroups: [AccessControlGroupsMapInput!]!
    $revokedAccessControlGroups: [AccessControlGroupsMapInput!]!
  ) {
    updateBulkUsersAccessControlGroups(
      channelId: $channelId
      userIds: $userIds
      assignedAccessControlGroups: $assignedAccessControlGroups
      revokedAccessControlGroups: $revokedAccessControlGroups
    )
  }
`;
