import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';

export function ButtonDeleteGroup({ isLoading, onDelete }: any) {
  const { t } = useTranslation();
  return (
    <Button loading={isLoading} testId="deleteButton" onClick={onDelete}>
      {t('web.components.lane.Team.Header.ButtonDeleteGroup')}
    </Button>
  );
}
