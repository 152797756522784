import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class HomUNRSVPError extends CodedError {
  static get errorName() {
    return errorCodes.homUNRSVPError.code;
  }

  constructor(message = errorCodes.homUNRSVPError.message) {
    super(message);
    this.code = errorCodes.homUNRSVPError.code;
    this.message = `${message} ${errorCodes.homUNRSVPError.code}`;
    this.name = errorCodes.homUNRSVPError.name;
  }
}
