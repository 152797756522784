import React from 'react';

import cx from 'classnames';

import styles from './CardContainer.scss';

export type Props = {
  children?: React.ReactNode;
  p?: boolean;
  style?: React.CSSProperties;
  /** Width in em */
  width?: number;
  /** Height in em */
  height?: number;
  size?: 'large' | 'medium' | 'small';
  className?: string;
};

type PrivateProps = Props;

export default function CardContainer({
  children,
  className,
  p,
  style,
  width,
  height,
  size = 'medium',
  ...otherProps
}: PrivateProps) {
  return (
    <div
      className={cx(styles.CardContainer, className)}
      data-size-card={size}
      data-padding={p}
      style={{
        ...style,
        width: `${width}em` || 'auto',
        height: `${height}em`,
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
}
