import React from 'react';

import { Icon } from 'design-system-web';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { spacing } from 'lane-shared/config';

import IconButton from 'components/general/IconButton';
import Flex from 'components/layout/Flex';

import styles from './ContentModalTopRow.scss';

type Props = {
  onBack: () => void;
  onClose: () => void;
};

export default function ModalTopRow({ onBack, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <Flex justify="space-between" align="center" p={[3, 5]}>
      <Button
        startIcon={<Icon name="chevron-left" />}
        variant="text-icon"
        size="small"
        onClick={onBack}
        style={{
          padding: `${spacing[2]} ${spacing[4]} ${spacing[2]} ${spacing[2]}`,
        }}
      >
        {t('Back')}
      </Button>
      <IconButton
        testId="exitTemplateModal"
        icon="times"
        className={styles.modalCloseButton}
        onClick={onClose}
      />
    </Flex>
  );
}
