import gql from 'graphql-tag';

export default gql`
  mutation updateServiceRequestStatusByUCIIdMutation(
    $uciId: String!
    $status: String!
  ) {
    updateServiceRequestStatusByUCIId(uciId: $uciId, status: $status)
  }
`;
