import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class RemoveUserFromChannelError extends CodedError {
  static get errorName() {
    return errorCodes.removeUserFromChannelError.code;
  }

  constructor(message = errorCodes.removeUserFromChannelError.message) {
    super(message);
    this.code = errorCodes.removeUserFromChannelError.code;
    this.message = `${message} ${errorCodes.removeUserFromChannelError.code}`;
    this.name = errorCodes.removeUserFromChannelError.name;
  }
}
