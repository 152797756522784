import React, { useState } from 'react';

import cx from 'classnames';
import { useScrollToSelectedChild } from '../../../hooks/useScrollToSelectedChild';
import { DateTime, Interval } from 'luxon';

import { parseDateTime } from 'lane-shared/helpers/dates';

import { DatePickerRow } from '../DatePickerRow';
import { YearMonthCell } from './YearMonthCell';

import styles from './MonthCalendar.scss';

const MIN_DATE = DateTime.local().set({ year: 2014 }).startOf('year');
const MAX_DATE = DateTime.local().plus({ years: 2 }).endOf('year');

type YearCalendarProps = {
  // callback for date has changed
  onChange?: (date: Date) => void;
  // start date
  startDate?: string | Date | null;
  // end date
  endDate?: string | Date | null;
  // max date allowed
  maxDate?: Date;
  // min date allowed
  minDate?: Date;
  rangeLimit?: number | null;
  className?: string;
  style?: React.CSSProperties;
};

export const YearCalendar = ({
  className,
  style,
  startDate,
  endDate,
  rangeLimit,
  onChange = () => null,
  maxDate = MAX_DATE.toJSDate(),
  minDate = MIN_DATE.toJSDate(),
}: YearCalendarProps) => {
  const [currentYear, setCurrentYear] = useState(DateTime.local());
  const startDateTime = parseDateTime(startDate);
  const endDateTime = parseDateTime(endDate);
  const minDateTime = parseDateTime(minDate) || MIN_DATE;
  const maxDateTime = parseDateTime(maxDate) || MAX_DATE;
  const year = startDateTime || currentYear;
  const [elementRef] = useScrollToSelectedChild(year.toISO());

  function enforceDate(newDate: any) {
    const _newDate = parseDateTime(newDate) as DateTime;
    if (
      (maxDateTime && _newDate > maxDateTime) ||
      (minDateTime && _newDate < minDateTime)
    ) {
      return false;
    }
    setCurrentYear(_newDate);
    return true;
  }

  function enforceRangeLimit(newDate: DateTime) {
    if (!rangeLimit || !startDateTime) {
      return true;
    }

    return (
      Interval.fromDateTimes(newDate, startDateTime).count('years') < rangeLimit
    );
  }
  const FIRST_MONTH = 1;
  const FIRST_DAY = 1;

  const yearNumber = parseDateTime(currentYear)?.year || 2020;
  const startDecadeNumber = currentYear?.year - (currentYear?.year % 10);

  const startDecadeYear =
    startDecadeNumber < minDateTime?.year
      ? minDateTime?.year
      : startDecadeNumber;
  const startDecadeDate = DateTime.local(
    yearNumber % 10 === 0 ? yearNumber : startDecadeYear,
    1,
    1
  );
  const startDecadeYearDate = DateTime.local(startDecadeNumber, 1, 1);
  const yearStart = startDecadeDate.startOf('year');

  let yearEnd = startDecadeYearDate.plus({ years: 8 }).endOf('year');
  yearEnd = yearEnd < maxDateTime ? yearEnd : maxDateTime;
  function renderYearCells() {
    const years: React.ReactNode[] = [];
    let m: DateTime = yearStart.minus({ years: 1 });
    while (m < yearEnd) {
      m = m.plus({ years: 1 });
      years.push(
        <YearMonthCell
          key={m.toISO()}
          className={styles.monthCell}
          startDate={startDateTime}
          endDate={endDateTime}
          timeUnit="year"
          month={m}
          onClick={date => {
            if (enforceDate(date) && enforceRangeLimit(date)) {
              onChange(date.toJSDate());
            }
          }}
          text={m.toFormat('yyyy')}
        />
      );
    }
    return years;
  }

  return (
    <div className={cx(styles.MonthCalendar, className)} style={style}>
      <DatePickerRow
        value={DateTime.local(
          startDecadeYear,
          FIRST_MONTH,
          FIRST_DAY
        ).toJSDate()}
        maxDate={maxDate}
        minDate={minDate}
        yearEnd={yearEnd < maxDateTime ? yearEnd.year + 1 : maxDateTime.year}
        quickTimeUnit="month"
        timeUnit="year"
        showDays={false}
        onChange={enforceDate}
        showMonths={false}
        showYears={false}
        showFullYears
        hideMonthChevron
      />
      <div className={styles.calendarWrapper} ref={elementRef}>
        {renderYearCells()}
      </div>
    </div>
  );
};
