import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getMeterReadingValue($meterReadingValueId: UUID!) {
    getMeterReadingValue(meterReadingValueId: $meterReadingValueId) {
      id
      meterReadingId
      createdBy
      createdAt
      sourceType
      value
      isArchived
      stepExecutionId
      notes
      taskId
      taskUserFriendlyId
    }
  }
`);
