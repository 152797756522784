import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class UserNotFoundError extends CodedError {
  static get errorName() {
    return errorCodes.userNotFoundError.code;
  }

  static status = 404;
  static message = `${errorCodes.userNotFoundError.message} ${errorCodes.userNotFoundError.code}`;

  constructor(message = errorCodes.userNotFoundError.message) {
    super(message);
    this.code = errorCodes.userNotFoundError.code;
    this.message = `${message} ${errorCodes.userNotFoundError.code}`;
    this.name = errorCodes.userNotFoundError.name;
    this.status = 404;
  }
}
