import { LocationType } from 'lane-shared/types/LocationType';

export const RADIUS_EARTH = 6371; // Radius of the earth in km
export const LATITUDE_CONVERSION = 110.574; // 1 lat = 110km
const EARTH_EQUATOR_LENGTH = 40075; // Length of equator in km
export const LONGEST_DISTANCE_ON_EARTH = EARTH_EQUATOR_LENGTH / 2;

const WITHIN_METERS = 100;

function deg2rad(deg: any) {
  return deg * (Math.PI / 180);
}

// Returns distance in kilometers
export default function distance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return RADIUS_EARTH * c;
}

export function areLocationsWithinAmountOfMeters({
  currentLocation,
  newLocation,
  meters = WITHIN_METERS,
}: {
  currentLocation: Partial<LocationType>;
  newLocation: Partial<LocationType>;
  meters?: number;
}) {
  const KILOMETERS = meters / 1000;
  if (!currentLocation || !newLocation) {
    return false;
  }
  const distanceInKm = distance(
    currentLocation.latitude!,
    currentLocation.longitude!,
    newLocation.latitude!,
    newLocation.longitude!
  );
  if (distanceInKm < KILOMETERS) {
    return true;
  }
  return false;
}
