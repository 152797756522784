import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ContentPublishError extends CodedError {
  static get errorName() {
    return errorCodes.contentPublishError.code;
  }

  constructor(message = errorCodes.contentPublishError.message) {
    super(message);
    this.code = errorCodes.contentPublishError.code;
    this.message = `${message} ${errorCodes.contentPublishError.code}`;
    this.name = errorCodes.contentPublishError.name;
  }
}
