import gql from 'graphql-tag';

export default gql(`
  fragment FullEditContentFragment on Content {
    _id
    _created
    _updated
    _createdBy {
      ...PublicUserFragment
    }
    _updatedBy {
      ...PublicUserFragment
    }
    name
    name_l10n
    description
    description_l10n
    subtitle
    slug
    type
    tags
    category
    reportingGroup
    logo
    color
    isInteractive
    isSyndicateable
    geo
    backgroundImage
    icon
    iconSet
    iconWeight
    backgroundColor
    liveDate
    startDate
    endDate
    interactionEndDate
    resetPeriod
    lastReset
    nextReset
    version
    renderer
    block
    properties
    externalUrl {
      _id
      url
      openingMode
    }
    deepLink {
      _id
      deepLinkUrl
      appStoreUrl
      googlePlayStoreUrl
    }
    card {
      _id
    }
    features {
      _id
      type
      feature
    }
    data
    state
    notifications {
      _id
      _created
      _updated
      title
      sent
      sendAt
    }
    contentMetatags {
      ... ObjectMetatagFragment
    }    
    integration {
      _id
      settings
      integration {
        _id
        name
        settings
      }
    }
  }
`);
