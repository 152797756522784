import React from 'react';

import type { Property } from 'csstype';

import { SpacingType } from 'lane-shared/config/spacing';

import cssVariables from '../../styles/_variables.scss';

import type { BoxProps } from './Box';
import { Box } from './Box';

type Props = {
  children: React.ReactNode;
  /** flex-direction */
  direction?: Property.FlexDirection;
  /** justify-content */
  justify?: Property.JustifyContent;
  /** align-items */
  align?: Property.AlignItems;
  style?: React.CSSProperties;
  className?: string;
  gap?: SpacingType;
} & BoxProps;

/**
 * Renders the `Box` component with the flex css-property.
 */
export const Flex = ({
  children,
  direction,
  justify,
  align,
  gap,
  style,
  ...boxProps
}: Props) => (
  <Box
    style={{
      ...style,
      display: 'flex',
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
      gap: gap ? cssVariables[`spacing${gap}`] : undefined,
    }}
    {...boxProps}
  >
    {children}
  </Box>
);
