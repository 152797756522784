import React from 'react';

import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import ShopifyDateRangePickerButton from 'components/integrations/ShopifyBurst/components/ShopifyDateRangePickerButton';

import DateRangePickerButton from '../form/DatePickers/DateRangePickerButton';

import styles from './DateRangePickerRenderer.scss';

type DateRangePickerRendererProps = {
  integrationName: string;
  startDate: Date | null;
  endDate: Date | null;
  startCreationDate: Date | null;
  endCreationDate: Date | null;
  defaultStartDate: Date;
  defaultEndDate: Date;
  handleChange: (props: any) => void;
};

export default function DateRangePickerRenderer({
  integrationName,
  startDate,
  endDate,
  startCreationDate,
  endCreationDate,
  defaultStartDate,
  defaultEndDate,
  handleChange,
}: DateRangePickerRendererProps) {
  // here you can manage component for displaying DateRangePicker depending on integration
  switch (integrationName) {
    case IntegrationProviderEnum.ShopifyBurst:
      return (
        <ShopifyDateRangePickerButton
          className={styles.datePickerButton}
          startDate={startDate}
          endDate={endDate}
          startCreationDate={startCreationDate}
          endCreationDate={endCreationDate}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          handleChange={handleChange}
        />
      );
  }

  return (
    <DateRangePickerButton
      className={styles.datePickerButton}
      startDate={startDate || defaultStartDate}
      endDate={endDate || defaultEndDate}
      minRangeSize={1}
      maxRangeSize={365}
      onChange={({ startDate, endDate }: any) =>
        handleChange({ startDate, endDate })
      }
    />
  );
}
