import gql from 'graphql-tag';

import { AddressType } from '../../types/AddressType';

export type AddressFragmentType = Pick<
  AddressType,
  | '_id'
  | '_created'
  | '_updated'
  | '_order'
  | 'name'
  | 'street1'
  | 'street2'
  | 'street3'
  | 'city'
  | 'state'
  | 'code'
  | 'country'
  | 'geo'
>;

export default gql(`
  fragment AddressFragment on Address {
      _id
      _created
      _updated
      _order
      name
      street1
      street2
      street3
      city
      state
      code
      country
      geo
  }
`);
