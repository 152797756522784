import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import formatDateRangeDay from 'lane-shared/helpers/formatters/dateRange/day';

import DateRangePicker from 'components/form/DatePickers/DateRangePicker';
import Chip from 'components/general/Chip';
import DropdownContextMenu from 'components/general/DropdownContextMenu';

import styles from './DateFilter.scss';

export default function DateFilter({
  timeZone,
  startDate,
  endDate,
  onChange,
  selected,
  selectedIcon,
  onApplySearchOptions,
}: any) {
  const { i18n } = useTranslation();
  const [isOpen, setOpen] = React.useState(false);

  return (
    <DropdownContextMenu
      isOpen={isOpen}
      onClose={() => {
        onApplySearchOptions();
        setOpen(false);
      }}
      title={
        <Chip
          onClick={() => setOpen(!isOpen)}
          label={formatDateRangeDay({
            _start: startDate,
            _end: endDate,
            timeZone,
            i18n,
          })}
          selected={selected}
          selectedIcon={selectedIcon}
          deleteIcon={<Icon name="angle-down" set={ICON_SET_FONTAWESOME} />}
        />
      }
      hasRippleEffect
    >
      <DateRangePicker
        timeZone={timeZone}
        startDate={startDate}
        endDate={endDate}
        minRangeSize={1}
        maxRangeSize={30}
        className={styles.DateFilter}
        onChange={onChange}
        dropdownPosition="absolute"
      />
    </DropdownContextMenu>
  );
}
