export const STEPS = [
  'Database',
  'Template',
  'Info',
  'Editor',
  'Targeting',
  'Publish',
] as const;

export const [
  STEP_DATABASE,
  STEP_TEMPLATE,
  STEP_INFO,
  STEP_EDITOR,
  STEP_TARGETING,
  STEP_PUBLISH,
] = STEPS;
