import type { StripeElementLocale } from '@stripe/stripe-js';
import { SupportedLocaleEnum } from 'localization/src/lib/i18n';
import { SupportedLocalesStripeMap } from 'lane-shared/domains/billingPayments/stripeLocaleMap';

export const ElementOptions = (userLocale: SupportedLocaleEnum) => {
  return {
    appearance: {
      variables: {
        fontFamily: 'Inter',
        fontWeight: '400',
      },
      rules: {
        '.Input': {
          border: '1px solid #373a3e',
          borderRadius: '0.5rem',
          fontSize: '16px',
        },
        '.Label': {
          color: '#1d1e20',
          fontSize: '14px',
          fontWeight: '500',
          paddingBottom: '0.5em',
        },
      },
    },
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
      },
    ],
    locale: SupportedLocalesStripeMap[userLocale] as StripeElementLocale,
  };
};
