import { useContext } from 'react';

import { Auth0Context, Auth0ContextType } from '.';

export const useAuth0Context = (): Auth0ContextType => {
  const context = useContext(Auth0Context);

  if (!context) {
    throw new Error(
      'auth0 context must be used within Auth0ContextProvider scope'
    );
  }

  return context;
};
