import gql from 'graphql-tag';

import {
  PublicUserFragment,
  ObjectMetatagFragment,
  MetatagFragment,
} from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${MetatagFragment}
  ${ObjectMetatagFragment}

  query getSection($id: UUID!) {
    section(_id: $id) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      name
      name_l10n
      description
      type
      object
      query
      renderType
      tags
      backgroundImage
      logo
      icon
      iconSet
      iconWeight
      color
      filters
      sorts
      groupBy
      backgroundColor
      tagsOnSection {
        id
        name
      }
      channel {
        _id
      }
      sectionMetatags {
        ...ObjectMetatagFragment
      }
    }
  }
`;
