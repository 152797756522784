import gql from 'graphql-tag';

export default gql`
  mutation syncShopifyMultipassMarketingPreference(
    $channelIntegrationId: UUID!
    $maxRetries: Int
  ) {
    me {
      syncShopifyMultipassMarketingPreference(
        channelIntegrationId: $channelIntegrationId
        maxRetries: $maxRetries
      )
    }
  }
`;
