import gql from 'graphql-tag';

export default gql`
  query getAttachmentsByEntity($id: UUID!) {
    getAttachmentsByEntity(_id: $id) {
      created
      deletedAt
      entityId
      entityType
      file
      fileSignedUrl
      id
      thumbnailSignedUrl
      thumbnail
    }
  }
`;
