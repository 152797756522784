import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { GetInvoiceDetailsResponse } from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';

const getInvoiceDetailsQuery = gql`
  query GetInvoiceDetails(
    $getInvoiceDetailsRequest: GetInvoiceDetailsRequest!
  ) {
    accounts {
      getInvoiceDetails(getInvoiceDetailsRequest: $getInvoiceDetailsRequest) {
        invoice {
          id
          displayId
          groupId
          groupType
          amount
          status
          externalPayerId
          externalPayerType
          currency
          invoiceDate
          dueDate
          metadata
        }
        charges {
          name
          description
          amount
          currency
          status
          items {
            product {
              name
              amount
              tax {
                value
                taxAmountType
              }
              markup {
                value
                markupAmountType
              }
            }
            quantity
          }
        }
      }
    }
  }
`;

const channelQuery = gql`
  query Channel($id: UUID) {
    channel(_id: $id) {
      name
      address {
        code
        country
        city
        state
      }
    }
  }
`;

const userQuery = gql`
  query User($id: UUID!) {
    user(_id: $id) {
      profile {
        name
        email
      }
    }
  }
`;

export async function getInvoiceDetails(
  groupId: string,
  invoiceDisplayId?: string,
  invoiceId?: string
): Promise<GetInvoiceDetailsResponse> {
  const { data } = await getClient().query({
    query: getInvoiceDetailsQuery,
    variables: {
      getInvoiceDetailsRequest: {
        invoiceDisplayId,
        invoiceId,
        groupId,
        groupType: GroupType.GroupTypeActivateChannel,
      },
    },
    fetchPolicy: 'network-only',
  });

  return data.accounts.getInvoiceDetails;
}

async function getUserPayerInfo(
  externalPayerId: string
): Promise<{ user: { profile: { email: string; name: string } } }> {
  const { data } = await getClient().query({
    query: userQuery,
    variables: {
      id: externalPayerId,
    },
    fetchPolicy: 'network-only',
  });
  return data;
}

async function getCompanyPayerInfo(
  externalPayerId: string
): Promise<{
  channel: {
    name: string;
    address: { code: string; country: string; city: string; state: string };
  };
}> {
  const { data } = await getClient().query({
    query: channelQuery,
    variables: {
      id: externalPayerId,
    },
    fetchPolicy: 'network-only',
  });
  return data;
}

export function useUserCompanyInfo(id: string, isCompany: boolean) {
  const [info, setInfo] = useState({
    name: '',
    email: '',
    address: { city: '', state: '', country: '', code: '' },
  });
  const { t } = useTranslation();
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        if (isCompany) {
          const companyInfo = await getCompanyPayerInfo(id);
          setInfo(previousState => ({
            ...previousState,
            name: companyInfo.channel.name,
            address: companyInfo.channel.address,
          }));
        } else {
          const userInfo = await getUserPayerInfo(id);
          setInfo(previousState => ({
            ...previousState,
            name:
              userInfo.user.profile.name || t('abp.invoice.details.unknown'),
            email:
              userInfo.user.profile.email || t('abp.invoice.details.unknown'),
          }));
        }
      };

      fetchData();
    }
  }, [id, isCompany]);

  return info;
}
