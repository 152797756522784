import i18n, {
  TranslationValueType,
  getStringValueOfTranslation,
  TranslationKeys,
} from './lib/configuration';

import * as enUSTranslations from './lib/locales/en-US/translation.json';

import {
  SupportedLocales,
  LocalizedField,
  SupportedLocaleEnum,
  SupportedLocalesFriendlyNames,
  friendlyLocaleItems,
  DefaultLocale,
  parseSupportedLocaleEnum,
  isSupportedLocale,
} from './lib/i18n';

export {
  enUSTranslations,
  SupportedLocaleEnum,
  SupportedLocalesFriendlyNames,
  friendlyLocaleItems,
  DefaultLocale,
  parseSupportedLocaleEnum,
  getStringValueOfTranslation,
  isSupportedLocale,
};

export type {
  TranslationValueType,
  SupportedLocales,
  LocalizedField,
  TranslationKeys,
};

export default i18n;
