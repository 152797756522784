import {
  ProductInput as gqlProductInput,
  GroupType,
} from 'graphql-query-contracts';
import { Product } from 'lane-shared/domains/billingPayments/types';

export function convertBPProductToGqlProductInput(
  product: Product
): gqlProductInput {
  return {
    id: product.id,
    name: product.name,
    description: product.description,
    amount: product.amount,
    productCategoryId: product.productCategory.productCategoryId,
    groupId: product.groupId,
    groupType: GroupType.GroupTypeActivateChannel,
    markup: product.markup
      ? {
          value: product.markup.value,
          markupAmountType: product.markup.markupAmountType,
        }
      : undefined,
    tax: product.tax
      ? {
          value: product.tax.value,
          taxAmountType: product.tax.taxAmountType,
        }
      : undefined,
    creditAmount: product.creditAmount,
  };
}
