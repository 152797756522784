import gql from 'graphql-tag';

export default gql`
  query GetEventSubscriptionSettings {
    me {
      user {
        _id
        eventSubscriptions {
          _id
          _created
          _updated
          event
          delivery
          channel {
            _id
            isArchived
          }
        }
      }
    }
  }
`;
