import explodeFeatures from '../features/explodeFeatures';

type PriceRange = {
  maxPrice: number;
  minPrice: number;
};

const DEFAULT_MAX = 100000; // $1,000

export default function getPriceRange(content: any[]): PriceRange {
  if (!(content?.length > 0)) {
    return {
      maxPrice: DEFAULT_MAX,
      minPrice: 0,
    };
  }

  const { minPrice, maxPrice } = content.reduce(
    ({ minPrice, maxPrice }: PriceRange, content) => {
      const { paymentFeature } = explodeFeatures(content.features);

      // TODO: fix bug: as far as I can tell price is not a property of payment features.
      const price = (paymentFeature as any)?.price || 0;

      return {
        minPrice: Math.min(minPrice, price),
        maxPrice: Math.max(maxPrice, price),
      };
    },
    {
      minPrice: DEFAULT_MAX,
      maxPrice: 0,
    }
  );

  return {
    maxPrice: maxPrice || DEFAULT_MAX,
    minPrice: minPrice || 0,
  };
}
