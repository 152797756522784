import { gql } from '@apollo/client';

export const deleteDraftChargeMutation = gql`
  mutation deleteDraftCharge(
    $deleteDraftChargeRequest: DeleteDraftChargeRequest!
  ) {
    accounts {
      deleteDraftCharge(deleteDraftChargeRequest: $deleteDraftChargeRequest)
    }
  }
`;
