import React, { useImperativeHandle, forwardRef, useRef } from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import ValidationMessage from 'components/general/ValidationMessage';

import styles from './SimpleInput.scss';

type Props = {
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onSubmit?: () => void;
  style?: React.CSSProperties;
  className?: string;
  value?: React.ReactText;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  autoComplete?: boolean;
  testId?: string;
  errors?: string[] | null;
  mb?: number;
  mt?: number;
};

/**
 * @deprecated
 * TODO: Why, and what is the replacement path?
 */
function SimpleInput(
  {
    onBlur = () => null,
    onFocus = () => null,
    onChange,
    onSubmit = () => null,
    style,
    className,
    value = '',
    type = 'text',
    placeholder,
    min,
    max,
    disabled = false,
    maxLength = 255,
    autoComplete = false,
    testId,
    errors,
    mb,
    mt,
  }: Props,
  ref: any
) {
  const inputRef = useRef(null);

  function handleChange(e: any) {
    onChange(e.target.value);
  }

  function handleKeyUp(e: any) {
    if (e.key === Key.Enter && onSubmit) {
      onSubmit();
    }
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        inputRef.current.focus();
      }
    },
    blur: () => {
      if (inputRef.current) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        inputRef.current.blur();
      }
    },
  }));

  return (
    <>
      <input
        ref={inputRef}
        className={cx(styles.SimpleInput, className)}
        style={style}
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={handleKeyUp}
        type={type}
        placeholder={placeholder}
        data-private={type === 'password'}
        autoComplete={autoComplete ? 'on' : 'off'}
        maxLength={maxLength}
        data-test={testId}
        min={min}
        max={max}
        data-margin-top={mt}
        data-margin-bottom={mb}
      />
      <ValidationMessage errors={errors} />
    </>
  );
}

/**
 * @deprecated
 * TODO: Why, and what is the replacement path?
 */
const SimpleInputForwarded = forwardRef(SimpleInput);

export default SimpleInputForwarded;
