import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class PersonNotFoundError extends CodedError {
  static get errorName() {
    return errorCodeTypes.personNotFoundError.code;
  }

  constructor(message = errorCodeTypes.personNotFoundError.message) {
    super(message);
    this.code = errorCodeTypes.personNotFoundError.code;
    this.message = `${message} ${errorCodeTypes.personNotFoundError.code}`;
    this.name = errorCodeTypes.personNotFoundError.name;
  }
}
