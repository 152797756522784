export const audienceEvents = [
  {
    key: 'Audience.View.Index',
    description: 'Viewing all audiences',
    userPermitted: true,
  },
  {
    key: 'Audience.View.SingleAudience',
    description: 'Viewing an audience',
  },
  {
    key: 'Audience.Create.Start',
    description: 'Start workflow for creating an audience',
  },
  {
    key: 'Audience.Create.Add.Team',
    description: 'Start workflow to add a team to a new audience',
  },
  {
    key: 'Audience.Create.Add.Tenant',
    description: 'Start workflow to add a tenant to a new audience',
  },
  {
    key: 'Audience.Create.Add.Channel',
    description:
      'Start workflow to add a channel or a subchannel to a new audience',
  },
  {
    key: 'Audience.Create.Select.Team',
    description: 'Select a team to add to a new audience',
  },
  {
    key: 'Audience.Create.Select.Tenant',
    description: 'Select a tenant to add to a new audience',
  },
  {
    key: 'Audience.Create.Select.Channel',
    description: 'Select a channel or a subchannel to add to a new audience',
  },
  {
    key: 'Audience.Create.Remove.Team',
    description: 'Remove a selected team from a new audience',
  },
  {
    key: 'Audience.Create.Remove.Tenant',
    description: 'Remove a selected tenant from a new audience',
  },
  {
    key: 'Audience.Create.Remove.Channel',
    description:
      'Remove a selected channel or a subchannel from a new audience',
  },
  {
    key: 'Audience.Create.End',
    description: 'End workflow for creating an audience',
  },
  {
    key: 'Audience.Update.Start',
    description: 'Start workflow for updating an audience',
  },
  {
    key: 'Audience.Update.Add.Team',
    description: 'Start workflow to add a team to an existing audience',
  },
  {
    key: 'Audience.Update.Add.Tenant',
    description: 'Start workflow to add a tenant to an existing audience',
  },
  {
    key: 'Audience.Update.Add.Channel',
    description:
      'Start workflow to add a channel or subchannel to an existing audience',
  },
  {
    key: 'Audience.Update.Select.Team',
    description: 'Select a team to add to an existing audience',
  },
  {
    key: 'Audience.Update.Select.Tenant',
    description: 'Select a tenant to add to an existing audience',
  },
  {
    key: 'Audience.Update.Select.Channel',
    description:
      'Select a channel or a subchannel to add to an existing audience',
  },
  {
    key: 'Audience.Update.Remove.Team',
    description: 'Remove a selected team from an existing audience',
  },
  {
    key: 'Audience.Update.Remove.Tenant',
    description: 'Remove a selected tenant from an existing audience',
  },
  {
    key: 'Audience.Update.Remove.Channel',
    description:
      'Remove a selected channel or a subchannel from an existing audience',
  },
  {
    key: 'Audience.Update.End',
    description: 'End workflow for updating an audience',
  },
  {
    key: 'Audience.Delete.Start',
    description: 'Start workflow for deleting an audience',
  },
  {
    key: 'Audience.Delete.Cancel',
    description: 'Canceled workflow for deleting an audience',
  },
  {
    key: 'Audience.Delete.End',
    description: 'End workflow for deleting an audience',
  },
  {
    key: 'Audience.Target.Start',
    description: 'Start workflow for targeting an audience',
  },
  {
    key: 'Audience.Target.Select',
    description: 'Select an audience as a target',
  },
  {
    key: 'Audience.Target.Remove',
    description: 'Remove an audience as a target',
  },
];
