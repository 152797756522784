import gql from 'graphql-tag';

export default gql`
  query userChannelSettings($userId: UUID!, $channelId: UUID!) {
    userChannelSettings(userId: $userId, channelId: $channelId) {
      id
      userId
      channelId
      language
    }
  }
`;
