import { INVOICE_STATUS } from '../../domains/billingPayments/types';
import { isOverdue } from './isOverdue';

export const INVOICE_STATUS_MAP: { [key: string]: [string, string] } = {
  PENDING: [INVOICE_STATUS.INVOICE_PENDING, 'blue'],
  UNPAID: [INVOICE_STATUS.INVOICE_UNPAID, 'yellow'],
  VOIDED: [INVOICE_STATUS.INVOICE_VOID, 'grey'],
  OVERDUE: [INVOICE_STATUS.INVOICE_OVERDUE, 'red'],
  PAID: [INVOICE_STATUS.INVOICE_PAID, 'green'],
  PAYMENT_FAILED: [INVOICE_STATUS.INVOICE_PAYMENTFAILED, 'red'],
  AUTOPAY: [INVOICE_STATUS.INVOICE_AUTOPAY, 'grey'],
};

export function getInvoiceStatus(
  invoiceStatus: string,
  row: any,
  showAutopay?: boolean
): [string, string] {
  let statusMap;
  switch (row.status) {
    case 'OVERDUE':
      statusMap = INVOICE_STATUS_MAP.OVERDUE;
      break;
    case 'INVOICED':
      if (row.amount > 0) {
        if (isOverdue(row.dueDate)) {
          statusMap = INVOICE_STATUS_MAP.OVERDUE;
          break;
        } else {
          statusMap = INVOICE_STATUS_MAP.UNPAID;
          break;
        }
      } else {
        statusMap = INVOICE_STATUS_MAP.VOIDED;
        break;
      }
    case 'PAID':
      statusMap = INVOICE_STATUS_MAP.PAID;
      break;
    case 'PAYMENT_FAILED':
      statusMap = INVOICE_STATUS_MAP.PAYMENT_FAILED;
      break;
    case 'VOIDED':
      statusMap = INVOICE_STATUS_MAP.VOIDED;
      break;
    default:
      statusMap = INVOICE_STATUS_MAP.PENDING;
      break;
  }

  if (
    showAutopay &&
    (statusMap === INVOICE_STATUS_MAP.OVERDUE ||
      statusMap === INVOICE_STATUS_MAP.UNPAID)
  ) {
    statusMap = INVOICE_STATUS_MAP.AUTOPAY;
  }

  return (
    statusMap ||
    INVOICE_STATUS_MAP[invoiceStatus] || [
      'abp.invoiceList.status.pending',
      'honey',
    ]
  );
}
