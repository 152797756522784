import gql from 'graphql-tag';

export default gql`
  query getPastVisits(
    $channelId: UUID!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    pastVisits(
      channelId: $channelId
      startDate: $startDate
      endDate: $endDate
    ) {
      result {
        visits {
          id
          visitor {
            firstName
            lastName
            company
            email
          }
          accessGrantBuildingId
          hostName
          tenantName
          floor
          companyName
          status
          visitorNote
          staffNote
          startDatetime
          endDatetime
          submittedBy
          eventName
          createdAtDatetime
          sourceId
          submissionId
          validStateTransitions
          checkedInDatetime
          checkedOutDatetime
        }
        eventNames
        tenantNames
      }
      totalCount
    }
  }
`;
