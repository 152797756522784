import gql from 'graphql-tag';

export default gql(`
  fragment FullContentTemplateFragment on ContentTemplate {
    _id
    _created
    _updated
    _createdBy {
      ...PublicUserFragment
    }
    _updatedBy {
      ...PublicUserFragment
    }
    templateName
    notes
    name
    name_l10n
    description
    description_l10n
    subtitle
    subtitle_l10n
    type
    tags
    contentTags
    category
    reportingGroup
    logo
    color
    icon
    iconSet
    iconWeight
    card {
      _id
    }
    isInteractive
    backgroundImage
    backgroundColor
    liveDate
    startDate
    endDate
    unpublishDate
    resetPeriod
    lastReset
    nextReset
    channel {
      _id
    }
    theme {
      ...ThemeFragment
    }
    version
    renderer
    block
    properties
    features
    data
    state
    isEvent
  }
`);
