import gql from 'graphql-tag';

import { Metatag } from 'lane-shared/types/Metatag';

export type MetatagFragmentType = Pick<
  Metatag,
  | '_id'
  | '_created'
  | '_updated'
  | 'name'
  | 'type'
  | 'description'
  | 'showDescription'
  | 'properties'
>;

export default gql(`
  fragment MetatagFragment on Metatag {
    _id
    _created
    _updated
    name
    type
    description
    showDescription
    properties
  }
`);
