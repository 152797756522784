import { v4 as uuid } from 'uuid';

import castGraphQLObject from '../castGraphQLObject';
import constructContent from './constructContent';

export default function copyTemplateForContent({ template, channel }: any) {
  // A deep clone is necessary to bypass template caching issue
  template = castGraphQLObject(JSON.parse(JSON.stringify(template)));

  const baseContent = constructContent({
    contentType: template.type,
    channel,
  });

  // copy template to current content.
  const {
    actions,
    backgroundColor,
    backgroundImage,
    block,
    card,
    category,
    color,
    data,
    description,
    description_l10n,
    endDate,
    features,
    icon,
    iconSet,
    iconWeight,
    isEvent,
    isInteractive,
    lastReset,
    logo,
    name,
    name_l10n,
    nextReset,
    properties,
    renderer,
    reportingGroup,
    resetPeriod,
    startDate,
    state,
    subtitle,
    subtitle_l10n,
    tags,
    contentTags,
    type,
    version,
  } = template;

  // if there are any features, or actions, give them new ids.
  if (features) {
    features.forEach((feature: any) => {
      feature._id = uuid();
    });
  }

  if (actions) {
    actions.forEach((action: any) => {
      action._id = uuid();
    });
  }

  if (block) {
    block._id = uuid();
  }

  return {
    ...baseContent,
    backgroundColor,
    backgroundImage,
    block,
    card,
    category,
    color,
    data,
    description,
    description_l10n,
    endDate,
    features,
    icon,
    iconSet,
    iconWeight,
    isEvent,
    isInteractive,
    lastReset,
    logo,
    name,
    name_l10n,
    nextReset,
    properties,
    renderer,
    reportingGroup,
    resetPeriod,
    startDate,
    state,
    subtitle,
    subtitle_l10n,
    tags,
    contentTags,
    type,
    version,
  };
}
