import gql from 'graphql-tag';

import { PublicUserFragment, ContentFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ContentFragment}

  query getChannelNotices($id: UUID!) {
    channel(_id: $id) {
      _id
      description
      slug
      type
      notices {
        ...ContentFragment
      }
    }
  }
`;
