import gql from 'graphql-tag';

import { MediaFragment } from '../fragments';

export default gql`
  ${MediaFragment}

  query mediaOnUserQuery(
    $userId: UUID!
    $search: MediaSearch
    $tags: StringListSearch
    $path: Path
    $pagination: PaginationInput
    $mediaTypes: [MediaType]
  ) {
    mediaOnUser(
      userId: $userId
      search: $search
      tags: $tags
      path: $path
      pagination: $pagination
      mediaTypes: $mediaTypes
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        _updated
        tags
        path
        media {
          ...MediaFragment
        }
      }
    }
  }
`;
