import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class OAuthIdentityExpiredError extends CodedError {
  static get errorName() {
    return errorCodes.oAuthIdentityExpiredError.code;
  }

  constructor(message = errorCodes.oAuthIdentityExpiredError.message) {
    super(message);
    this.code = errorCodes.oAuthIdentityExpiredError.code;
    this.message = `${message} ${errorCodes.oAuthIdentityExpiredError.code}`;
    this.name = errorCodes.oAuthIdentityExpiredError.name;
    this.status = errorCodes.oAuthIdentityExpiredError.status;
  }
}
