import { useQuery } from '@apollo/client';

import { getHostsByUserTenantRelationship } from '../../../graphql/visitorManagement';
import { HostType } from '../types';

export default function useHostByTenantRelationship(channelId: string) {
  const { data, loading, error } = useQuery(getHostsByUserTenantRelationship, {
    variables: {
      channelId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const hosts: HostType[] = data?.hostsByUserTenantRelationship;

  return {
    hosts,
    loading,
    error,
  };
}
