import gql from 'graphql-tag';

export default gql`
  mutation AutoSetupCES($request: AutoSetupCesEntityRequest!) {
    autoSetupCES(request: $request) {
      success
      message
    }
  }
`;
