import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class ShopifyMultipassIntegrationSetupError extends CodedError {
  static get errorName() {
    return errorCodes.shopifyMultipassIntegrationSetupError.code;
  }

  constructor(
    message = errorCodes.shopifyMultipassIntegrationSetupError.message
  ) {
    super(message);
    this.code = errorCodes.shopifyMultipassIntegrationSetupError.code;
    this.message = `${message} ${errorCodes.shopifyMultipassIntegrationSetupError.code}`;
    this.name = errorCodes.shopifyMultipassIntegrationSetupError.name;
  }
}
