import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  mutation createMultipleMeterReadingValueMutation(
    $createMultipleMeterReadingValue: [MeterReadingValueInput!]
    $channelId: String!
  ) {
    createMultipleMeterReadingValue(
      meterReadingValues: $createMultipleMeterReadingValue
      channelId: $channelId
    )
  }
`);
