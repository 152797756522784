/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon, H2, H3, H4, P, S, Table, TagInput } from 'design-system-web';
import { Button, Loading, Thumbnail } from 'components';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { routes } from 'lane-shared/config';
import {
  IntervalTypeEnum,
  RecurrenceData,
  RecurrenceEventStatus,
  ScheduleTypeEnum,
  VisitorPassQueryResponse,
} from 'lane-shared/domains/visitorManagement/types';
import {
  cancelSubmission,
  getRecurrenceVisitorPassDetailsById,
  getVisitorById,
  getVisitorPassDetailsById,
} from 'lane-shared/graphql/visitorManagement';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import {
  SHORT_TIME_WITH_TZ,
  LONG_DAY_LONG_DATE,
  SHORT_TIME,
  SIMPLE_DATE,
  SHORT_DATE,
} from 'lane-shared/helpers/constants/dates';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  dateFormatter,
  addressFormatter,
  imageUrl,
} from 'lane-shared/helpers/formatters';
import { formatIncompletePhoneNumber } from 'lane-shared/helpers/phoneNumbers';
import {
  useFlag,
  useContentQuery,
  useVisitorScheduleForm,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { AdminPage } from 'components/layout';
import { BreadCrumbs, Alert, AlertType, Modal } from 'components/lds';
import { useStatusSelector } from '../../hooks/useStatusSelector';
import styles from './styles.scss';
import { rrulestr } from 'rrule';
import { RecurrenceStatusListItems } from '../../types/RecurrenceEventStatus';
import { ChipSelect } from 'components/ads';
import TextArea from 'components/form/TextArea';
import ScheduleDateTimeRepeat from '../../components/ScheduleRepeat/ScheduleDateTimeRepeat';
import { settings } from 'lane-shared/domains/visitorManagement/fixtures/VisitorFeatureFixtures';
import ScheduleDateTimeRange from '../../components/ScheduleDateTimeRange/ScheduleDateTimeRange';
import { createDefaultDateTime } from 'lane-shared/domains/visitorManagement/helpers';
import { START_HOUR } from 'lane-shared/domains/visitorManagement/features/VisitorManagementFeatureValues';
import { useEditRecurrencePassesDetails } from '../../hooks/useEditRecurrencePassesDetails';
import * as yup from 'yup';

type QueryResponse = {
  visitorPass: VisitorPassQueryResponse;
  recurrence: RecurrenceData;
  visitor?: any;
};

interface VisitorData {
  id: string;
  name: string;
}

interface RouterState {
  data: any;
}
interface FetchVisitorsDataResult {
  visitorsData: VisitorData[];
  loading: boolean;
  error?: Error;
}

const combineNames = (names: (string | undefined)[]): string => {
  return names.filter(name => Boolean(name)).join(' ');
};

const TRANSLATION_KEYS = {
  recurrenceDetail:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.breadcrumb.recurrenceDetail',
  notFound: 'web.admin.channel.visitor.passDetails.recurrenceDetails.notFound',
  error: 'web.admin.channel.visitor.passDetails.recurrenceDetails.error',
  cancelModalTitle:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.cancelModal.title',
  cancelModalMessage:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.cancelModal.message',
  cancelModalWarning:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.cancelModal.warning',
  cancelModalCancelText:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.cancelModal.cancelText',
  cancelModalCancelButton:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.cancelModal.cancelButton',
  host: 'web.admin.channel.visitor.passDetails.recurrenceDetails.host.title',
  repeats: 'web.admin.channel.visitor.passDetails.recurrenceDetails.repeats',
  dates: 'web.admin.channel.visitor.passDetails.recurrenceDetails.dates',
  timeWindow:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.timeWindow',
  recurrenceViewInfo:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.recurrence.view.info',
  recurrenceEditInfo:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.recurrence.edit.info',
  noteToStaff:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.noteToStaff',
  noteToVisitor:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.noteToVisitor',
  visitorTableTitle:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.visitorTable.title',
  visitorTableColumnName:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.visitorTable.column.name',
  visitorTableColumnEmail:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.visitorTable.column.email',
  visitorTableColumnPhone:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.visitorTable.column.phone',
  visitorTableColumnCompany:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.visitorTable.column.company',
  cancelationSuccess:
    'web.admin.channel.visitor.passDetails.recurrenceDetails.cancelation.success',
  cancelationFailure:
    'web.admin.channel.visitor.passDetails.cancelation.failure',
  cancelModalReturnButton:
    'web.admin.channel.visitor.passDetails.cancelModal.returnButton',
  breadcrumbVisitorLog:
    'web.admin.channel.visitor.passDetails.breadcrumb.visitorLog',
  breadcrumbPassDetails:
    'web.admin.channel.visitor.passDetails.breadcrumb.passDetails',
  createdOn: 'web.admin.channel.visitor.passDetails.createdOn',
};
export function VisitorRecurrenceDetailsPage({ channel }: { channel: any }) {
  const { t } = useTranslation();
  const [, channelSlug] = useIsAdminView();
  const timeZone = useMemo(
    () =>
      getTimeZoneByGeoLocation({
        latitude: channel.address.geo[1],
        longitude: channel.address.geo[0],
      }),
    [channel]
  );

  const location = useLocation<RouterState>();
  const routerStateData = location.state?.data;

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { recurrenceId } = useParams<{ recurrenceId: string }>();
  const passUUID = convertToUUID(recurrenceId);
  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );
  const multipleNotificationRecipientsEnabled = useFlag(
    FeatureFlag.VisitorManagementMultipleNotificationRecipients,
    false
  );
  const { data, loading, error, refetch } = useQuery<QueryResponse>(
    getRecurrenceVisitorPassDetailsById,
    {
      variables: { id: passUUID },
      fetchPolicy: 'network-only',
    }
  );
  const {
    content,
    loading: isContentLoading,
    error: contentError,
  } = useContentQuery({
    contentId: data?.visitorPass?.sourceId,
  });
  const {
    data: visitorPassDetails,
    loading: visitorPassLoading,
    error: visitorPassError,
    refetch: refetchVisitorPass,
  } = useQuery(getVisitorPassDetailsById, {
    variables: {
      id: routerStateData?.visitorPass?.id,
      channelId: channel._id,
    },
    fetchPolicy: 'network-only',
  });

  const { loadingStates, updateLoadingState } = useStatusSelector();

  const editRecurrenceEnabled = useFlag(
    FeatureFlag.VisitorManagementEditRecurrence,
    false
  );

  const {
    isLoading,
    recurrenceType,
    schedule,
    isPristine,
    staffNote,
    visitorNote,
    additionalRecipients,
    updateVisitorNote,
    updateStaffNote,
    updateAdditionalRecipients,
    handleAdditionalRecipientsErrorChange,
    reset,
    onChange,
    onSave,
  } = useEditRecurrencePassesDetails({
    recurrenceDetail: data?.recurrence,
    isAllDayPassEnabled: false,
    timeZone,
  });

  const {
    monthIntervalType,
    handleDateTimeRange,
    handleRepeat,
  } = useVisitorScheduleForm({
    properties: settings,
    value: null,
    timeZone,
    onChange,
  });

  const minDate = useMemo(
    () => createDefaultDateTime(START_HOUR, 0, timeZone),
    [timeZone]
  );
  const maxDate = useMemo(() => {
    let date;
    if (content?.features) {
      const result = explodeFeatures(content?.features);
      if (result.visitorManagementFeature) {
        date = new Date();
        date.setMonth(
          date.getMonth() +
            (result?.visitorManagementFeature?.scheduleInAdvanceAmount ?? 0)
        );
      }
    }

    return date;
  }, [content?.features]);

  const shouldRender = useMemo(() => {
    return (
      !loading &&
      !isContentLoading &&
      !visitorPassLoading &&
      !error &&
      !contentError &&
      !visitorPassError &&
      Boolean(data?.recurrence?.recurrenceId)
    );
  }, [
    loading,
    isContentLoading,
    visitorPassLoading,
    error,
    contentError,
    visitorPassError,
    data,
  ]);

  const shouldDisplayNotFound = useMemo(() => {
    return (
      !loading &&
      !isContentLoading &&
      !visitorPassLoading &&
      !error &&
      !contentError &&
      !visitorPassError &&
      !data?.recurrence?.visitorIds?.length
    );
  }, [
    loading,
    isContentLoading,
    visitorPassLoading,
    error,
    contentError,
    visitorPassError,
    data,
  ]);

  const isCancelStatus = useMemo(
    () =>
      data?.recurrence?.status ===
      RecurrenceEventStatus.RECURRENCE_EVENT_STATUS_CANCELLED.toString(),
    [data?.recurrence?.status]
  );

  const [cancelSubmissionMutation] = useMutation(cancelSubmission, {
    variables: {
      submissionId: data?.recurrence?.submissionId
        ? convertToUUID(data?.recurrence?.submissionId || '')
        : undefined,
    },
  });

  const handleModalCancelOnClick = async () => {
    if (!shouldRender) return;
    try {
      updateLoadingState(data?.recurrence?.recurrenceId || '', true);
      await cancelSubmissionMutation();
      setTimeout(() => {
        refetch();
      }, 3000);
      const message = t(TRANSLATION_KEYS.cancelationSuccess);
      window.Toast.show(message, 5000);
    } catch (error) {
      const message = t(TRANSLATION_KEYS.cancelationFailure);
      window.Toast.show(message, 5000);
    } finally {
      updateLoadingState(data?.recurrence?.recurrenceId || '', false);
      setIsCancelModalOpen(false);
    }
  };

  const onEditCancel = () => {
    setIsEditMode(false);
    reset();
  };

  const onEditSave = async () => {
    try {
      await onSave();

      const visitorName = combineNames([
        visitorPassDetails?.visitorPass?.visitor.firstName,
        visitorPassDetails?.visitorPass?.visitor.lastName,
      ]);
      const message = t(
        'web.admin.channel.visitor.passDetails.update.success',
        {
          visitorName,
        }
      );

      window.Toast.show(message, 5000);
      setIsEditMode(false);
      refetch();
      refetchVisitorPass();
    } catch (err) {
      const message = t('web.admin.channel.visitor.passDetails.update.failure');
      window.Toast.show(message, 5000);
    }
  };

  const emailValidator = yup.string().email().nullable();
  const validateEmail = (email: string) => {
    try {
      emailValidator.validateSync(email);
      return true;
    } catch (err) {
      return false;
    }
  };

  const getCreatedOnInfo = (): string => {
    const createdDate = visitorPassDetails?.visitorPass?.createdAtDatetime
      ? dateFormatter(
          visitorPassDetails?.visitorPass?.createdAtDatetime,
          LONG_DAY_LONG_DATE,
          timeZone
        )
      : '-';
    const createdTime = visitorPassDetails?.visitorPass?.createdAtDatetime
      ? dateFormatter(
          visitorPassDetails?.visitorPass?.createdAtDatetime,
          SHORT_TIME_WITH_TZ,
          timeZone
        )
      : '-';
    const createdBy = visitorPassDetails?.visitorPass?.submittedBy;

    return t(TRANSLATION_KEYS.createdOn, {
      createdDate,
      createdTime,
      createdBy,
    });
  };

  const getTimeWindow = (
    startDatetime: string | undefined,
    endDatetime: string | undefined
  ): string => {
    const startTime = startDatetime
      ? dateFormatter(startDatetime, SHORT_TIME, timeZone)
      : '-';
    const endTime = endDatetime
      ? dateFormatter(endDatetime, SHORT_TIME_WITH_TZ, timeZone)
      : '-';

    return `${startTime} - ${endTime}`;
  };

  const getRRuleOptions = (schedule: any) => {
    return rrulestr(schedule || '').options;
  };

  const getRRule = (schedule: any) => {
    return rrulestr(schedule || '');
  };

  const getTitle = (): string => {
    const rrule = getRRule(data?.recurrence?.schedule);
    const scheduleText = rrule.toText().toLowerCase();

    const frequencyMap = {
      week: 'Weekly recurrence',
      day: 'Daily recurrence',
      month: 'Monthly recurrence',
    };
    for (const [key, value] of Object.entries(frequencyMap)) {
      if (scheduleText.includes(key)) {
        return value;
      }
    }
    return 'Daily recurrence';
  };

  const getRepeatText = () => {
    const rrule = getRRule(data?.recurrence?.schedule);
    let scheduleText = rrule.toText().split('until')[0];
    scheduleText = scheduleText.charAt(0).toUpperCase() + scheduleText.slice(1);
    return scheduleText || '-';
  };

  const getStartAndEndDates = (schedule: any) => {
    const rruleOptions = getRRuleOptions(schedule);
    const recurrenceStartDate = rruleOptions.dtstart;
    const recurrenceEndDate = rruleOptions.until;

    const startYear = recurrenceStartDate
      ? new Date(recurrenceStartDate).getFullYear()
      : '';
    const endYear = recurrenceEndDate
      ? new Date(recurrenceEndDate).getFullYear()
      : '';

    const startDateFormat = startYear !== endYear ? SIMPLE_DATE : SHORT_DATE;
    const endDateFormat = SIMPLE_DATE;

    const startDateFormatted = recurrenceStartDate
      ? dateFormatter(recurrenceStartDate, startDateFormat, timeZone)
      : '';
    const endDateFormatted = recurrenceEndDate
      ? dateFormatter(recurrenceEndDate, endDateFormat, timeZone)
      : '';

    return {
      startDateFormatted,
      endDateFormatted,
    };
  };

  const getDates = (schedule: string) => {
    const { startDateFormatted, endDateFormatted } = getStartAndEndDates(
      schedule
    );
    const scheduleString = `${startDateFormatted} to ${endDateFormatted}`;
    return scheduleString || '-';
  };

  const getHostEmailAndPhone = (): string => {
    const emailAndPhoneList: string[] = [];
    const joinPattern = ' | ';

    if (visitorPassDetails?.visitorPass?.host?.profile.email)
      emailAndPhoneList.push(
        visitorPassDetails?.visitorPass?.host.profile.email
      );
    if (visitorPassDetails?.visitorPass?.host?.profile.phone)
      emailAndPhoneList.push(
        formatIncompletePhoneNumber(
          visitorPassDetails?.visitorPass?.host.profile.phone
        )
      );

    return emailAndPhoneList.length ? emailAndPhoneList.join(joinPattern) : '';
  };

  const fetchVisitorsData = (visitorIds: string[]): FetchVisitorsDataResult => {
    const [visitorsData, setVisitorsData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const [
      fetchVisitorData,
      { loading: queryLoading, error: queryError },
    ] = useLazyQuery(getVisitorById);

    useEffect(() => {
      const fetchData = async () => {
        if (visitorIds.length > 0) {
          setLoading(true);
          try {
            const visitorsData = await Promise.all(
              visitorIds.map(async id => {
                const { data } = await fetchVisitorData({ variables: { id } });
                return data?.visitor;
              })
            );
            setVisitorsData(visitorsData);
          } catch (error) {
            setError(error);
            console.error('Error fetching visitor data:', error);
          } finally {
            setLoading(false);
          }
        }
      };

      fetchData();
    }, [visitorIds, fetchVisitorData]);

    return {
      visitorsData,
      loading: loading || queryLoading,
      error: error || queryError,
    };
  };

  // Use fetchVisitorsData in your component
  const visitorIds = data?.recurrence?.visitorIds || [];

  const { visitorsData, loading: visitorDataLoading } = fetchVisitorsData(
    visitorIds
  );

  const renderStatus = (): React.ReactElement | null => {
    const statusListItem = RecurrenceStatusListItems.find(
      item => item.value === data?.recurrence?.status
    );
    if (!statusListItem || !isCancelStatus) {
      return null;
    }

    return (
      <ChipSelect.NonInteractive
        value={statusListItem.label}
        type={statusListItem.type}
      />
    );
  };

  const renderScheduleComponent = useCallback((): React.ReactElement => {
    switch (recurrenceType) {
      case ScheduleTypeEnum.Repeat:
        return (
          <ScheduleDateTimeRepeat
            allDayPassEnabled={false}
            defaultIsAllDay={false}
            defaultStartDate={schedule?.startDate as Date}
            defaultEndDate={schedule?.endDate as Date}
            defaultEndRepeatDate={schedule?.endRepeatDate as Date}
            defaultStartTime={schedule?.startTime}
            defaultEndTime={schedule?.endTime}
            defaultIntervalCount={schedule?.intervalCount ?? 1}
            defaultInterval={schedule?.intervalType as IntervalTypeEnum}
            defaultWeekdayRepeats={schedule?.weekdayRepeats as number[]}
            defaultMonthIntervalType={monthIntervalType}
            timeZone={timeZone}
            minDate={minDate}
            maxDate={maxDate}
            onChange={value => handleRepeat(value)}
          />
        );
      case ScheduleTypeEnum.DateRange:
        return (
          <ScheduleDateTimeRange
            allDayPassEnabled={false}
            defaultIsAllDay={false}
            defaultStartDate={schedule?.startDate as Date}
            defaultEndDate={schedule?.endDate as Date}
            defaultStartTime={schedule?.startTime}
            defaultEndTime={schedule?.endTime}
            timeZone={timeZone}
            minDate={minDate}
            maxDate={maxDate}
            onChange={value => handleDateTimeRange(value)}
          />
        );
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  }, [
    handleDateTimeRange,
    handleRepeat,
    maxDate,
    minDate,
    monthIntervalType,
    recurrenceType,
    schedule,
    timeZone,
  ]);

  const ReturnButton = (
    <Button
      key="1"
      onClick={() => setIsCancelModalOpen(false)}
      fullWidth
      testId="returnToPageButton"
    >
      {t(TRANSLATION_KEYS.cancelModalReturnButton)}
    </Button>
  );

  const CancelButton = (
    <Button
      key="2"
      variant="contained"
      color="default"
      onClick={handleModalCancelOnClick}
      fullWidth
      testId="cancelPassButton"
      loading={loadingStates[data?.recurrence?.recurrenceId || '']}
    >
      {t(TRANSLATION_KEYS.cancelModalCancelButton)}
    </Button>
  );

  return (
    <AdminPage>
      <BreadCrumbs
        links={[
          {
            label: t(TRANSLATION_KEYS.breadcrumbVisitorLog),
            url: routes.visitorManagementVisitorLog.replace(
              ':id',
              channelSlug || ''
            ),
          },
          {
            label: t(TRANSLATION_KEYS.breadcrumbPassDetails),
            url: routes.visitorManagementVisitorPassDetails
              .replace(':id', channelSlug || '')
              .replace(
                ':visitorPassId',
                visitorPassDetails?.visitorPass?.id
                  ? visitorPassDetails?.visitorPass?.id
                  : ''
              ),
          },
          {
            label: t(TRANSLATION_KEYS.recurrenceDetail),
          },
        ]}
      />
      {loading && <Loading />}
      {error && (
        <Alert type={AlertType.error} fullWidth>
          {t(TRANSLATION_KEYS.error)}
        </Alert>
      )}
      {shouldDisplayNotFound && (
        <Alert type={AlertType.error} fullWidth>
          {t(TRANSLATION_KEYS.notFound)}
        </Alert>
      )}
      {shouldRender && (
        <>
          <Modal
            isOpen={isCancelModalOpen}
            onClose={() => setIsCancelModalOpen(false)}
            actions={[CancelButton, ReturnButton]}
            testId=""
            size="large"
            className={styles.modal}
          >
            <H3 mb={4}>{t(TRANSLATION_KEYS.cancelModalTitle)}</H3>
            <S className={styles.text} mb={4}>
              {t(TRANSLATION_KEYS.cancelModalMessage)}
            </S>
            <S className={styles.text} mb={4}>
              {t(TRANSLATION_KEYS.cancelModalWarning)}
            </S>
          </Modal>
          <div className={styles.passDetailsPageContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.buttonContainer}>
                <div className={styles.titleTextContainer}>
                  <H2 className={styles.title}>{getTitle()}</H2>
                  <div className={styles.statusText}>
                    {renderStatus() || ''}
                  </div>
                </div>
                {!isCancelStatus && (
                  <div>
                    <Button
                      testId="cancelButton"
                      onClick={() => setIsCancelModalOpen(true)}
                    >
                      {t(TRANSLATION_KEYS.cancelModalCancelText)}
                    </Button>
                  </div>
                )}
              </div>

              <S className={styles.info}>{getCreatedOnInfo()}</S>
            </div>
            <div className={styles.host}>
              <H4>{t(TRANSLATION_KEYS.host)}</H4>
              <div className={styles.itemContainer}>
                <div className={styles.item}>
                  <Thumbnail
                    className={styles.thumbnail}
                    name={visitorPassDetails?.visitorPass?.host?.name || '-'}
                    src={imageUrl(
                      visitorPassDetails?.visitorPass?.host?.profile?.image
                    )}
                  />
                  <div className={styles.textWrapper}>
                    <P>{visitorPassDetails?.visitorPass?.host?.name || '-'}</P>
                    <S>{getHostEmailAndPhone()}</S>
                  </div>
                </div>
                <div className={styles.item}>
                  <Thumbnail
                    className={styles.thumbnail}
                    name={visitorPassDetails?.visitorPass?.tenantName || '-'}
                    src={imageUrl(
                      visitorPassDetails?.visitorPass?.tenant?.profile?.logo ||
                        visitorPassDetails?.visitorPass?.tenant?.profile?.image
                    )}
                  />
                  <div className={styles.textWrapper}>
                    <P>{visitorPassDetails?.visitorPass?.tenantName || '-'}</P>
                    <S>
                      {addressFormatter(
                        visitorPassDetails?.visitorPass?.tenant?.address,
                        [
                          'name',
                          'street1',
                          'street2',
                          'street3',
                          'city',
                          'state',
                        ]
                      )}
                    </S>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsTitleContainer}>
                <H4>
                  {t(
                    'web.admin.visitorManagement.navigation.recurrenceDetails'
                  )}
                </H4>
                {editRecurrenceEnabled && !isEditMode && !isCancelStatus && (
                  <Button
                    onClick={() => setIsEditMode(true)}
                    testId="editButton"
                    size="small"
                    startIcon={<Icon name="pen" set="FontAwesome" type="far" />}
                  >
                    {t('Edit')}
                  </Button>
                )}
              </div>
              <div>
                {isEditMode && !isCancelStatus && (
                  <Alert type={AlertType.warning}>
                    {t(TRANSLATION_KEYS.recurrenceEditInfo)}
                  </Alert>
                )}
              </div>
              <div>
                {!isEditMode && !isCancelStatus && (
                  <Alert type={AlertType.info}>
                    {t(TRANSLATION_KEYS.recurrenceViewInfo)}
                  </Alert>
                )}
              </div>
              {!isEditMode && (
                <div className={styles.rowsContainer}>
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <S mb={1}>{t(TRANSLATION_KEYS.repeats)}</S>
                      <P>{getRepeatText()}</P>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <S mb={1}>{t(TRANSLATION_KEYS.dates)}</S>
                      <P>{getDates(data?.recurrence?.schedule || '')}</P>
                    </div>
                    <div className={styles.rowItem}>
                      <S mb={1}>{t(TRANSLATION_KEYS.timeWindow)}</S>
                      <P>
                        {getTimeWindow(
                          schedule?.startTime?.toISOString(),
                          schedule?.endTime?.toISOString()
                        )}
                      </P>
                    </div>
                  </div>
                  {visitorNotesEnabled && (
                    <div className={styles.row}>
                      <div className={styles.rowItem}>
                        <S mb={1}>{t(TRANSLATION_KEYS.noteToStaff)}</S>
                        <P className={styles.notes}>
                          {data?.recurrence?.staffNote || '-'}
                        </P>
                      </div>
                      <div className={styles.rowItem}>
                        <S mb={1}>{t(TRANSLATION_KEYS.noteToVisitor)}</S>
                        <P className={styles.notes}>
                          {data?.recurrence?.visitorNote || '-'}
                        </P>
                      </div>
                    </div>
                  )}
                  {multipleNotificationRecipientsEnabled && (
                    <div className={styles.row}>
                      <div className={styles.rowItem}>
                        <S mb={1}>
                          {t(
                            'web.admin.channel.visitor.passDetails.visitDetails.additionalRecipients'
                          )}
                        </S>
                        <P className={styles.notes}>
                          {data?.recurrence?.additionalRecipients?.length
                            ? data?.recurrence?.additionalRecipients.join(', ')
                            : '-'}
                        </P>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isEditMode && (
                <div className={styles.rowsContainer}>
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      {renderScheduleComponent()}
                    </div>
                  </div>
                  <div>
                    {visitorNotesEnabled && (
                      <div>
                        <div className={styles.row}>
                          <div className={styles.rowItem}>
                            <S mb={1}>
                              {t(
                                'web.admin.channel.visitor.passDetails.visitDetails.noteToStaff'
                              )}
                            </S>
                            <TextArea
                              className={styles.textArea}
                              value={staffNote || ''}
                              onChange={updateStaffNote}
                              showClear={false}
                              maxLength={200}
                              showLengthIndicator
                              testId="staffNoteInput"
                            />
                          </div>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.rowItem}>
                            <S mb={1}>
                              {t(
                                'web.admin.channel.visitor.passDetails.visitDetails.noteToVisitor'
                              )}
                            </S>
                            <TextArea
                              className={styles.textArea}
                              value={visitorNote || ''}
                              onChange={updateVisitorNote}
                              showClear={false}
                              maxLength={200}
                              showLengthIndicator
                              testId="visitorNoteInput"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {multipleNotificationRecipientsEnabled && (
                      <div>
                        <div className={styles.row}>
                          <div className={styles.rowItem}>
                            <S mb={1}>
                              {t(
                                'web.admin.channel.visitor.passDetails.visitDetails.additionalRecipients'
                              )}
                            </S>
                            <TagInput
                              tags={additionalRecipients || []}
                              setTags={updateAdditionalRecipients}
                              validateTag={validateEmail}
                              onValidationChange={
                                handleAdditionalRecipientsErrorChange
                              }
                              placeholder={t(
                                'shared.content.feature.visitorManagement.additional.recipients.notify.input.placeholder'
                              )}
                              maxTags={20}
                              maxTagInfo={t(
                                'shared.content.feature.visitorManagement.additional.recipients.notify.input.maximum.email'
                              )}
                              errorText={t(
                                'shared.content.feature.visitorManagement.additional.recipients.notify.input.error'
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isEditMode && (
                <div className={styles.buttonContainer}>
                  <Button
                    onClick={onEditSave}
                    testId="saveButton"
                    variant="activate-contained"
                    loading={isLoading}
                    disabled={isPristine}
                  >
                    {t('Save')}
                  </Button>
                  <Button onClick={onEditCancel} testId="cancelButton">
                    {t('Cancel')}
                  </Button>
                </div>
              )}
            </div>

            <div className={styles.visitorTableContainer}>
              <div className={styles.visitorTitleContainer}>
                <H4>{t(TRANSLATION_KEYS.visitorTableTitle)}</H4>
              </div>
              <VisitorScheduledComponent
                visitorsData={visitorsData}
                loading={visitorDataLoading}
              />
            </div>
          </div>
        </>
      )}
    </AdminPage>
  );
}

function VisitorScheduledComponent({
  visitorsData,
  loading,
}: {
  visitorsData: any;
  loading: boolean;
}) {
  const { t } = useTranslation();

  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );

  const getFullName = (row: any) => {
    return `${row.firstName} ${row.lastName}`;
  };

  let columns = [
    {
      header: t(TRANSLATION_KEYS.visitorTableColumnName),
      key: 'firstName',
      renderCell: (_: any, row: any) => <span>{getFullName(row)}</span>,
    },
    {
      header: t(TRANSLATION_KEYS.visitorTableColumnEmail),
      key: 'email',
      renderCell: (_: string, row: any) => <span>{row?.email}</span>,
    },
    {
      header: t(TRANSLATION_KEYS.visitorTableColumnPhone),
      key: 'phone',
      renderCell: (_: string, row: any) => <span>{row?.phone}</span>,
    },
    {
      header: t(TRANSLATION_KEYS.visitorTableColumnCompany),
      key: 'company',
      renderCell: (_: string, row: any) => <span>{row?.company}</span>,
    },
  ];

  if (!visitorNotesEnabled) {
    columns = columns.filter(column => column.key !== 'staffNote');
  }

  return (
    <Table isLoading={loading} columns={columns} data={visitorsData || []} />
  );
}
