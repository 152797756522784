import { gql } from 'graphql-query-contracts';

export const getProductExceptionQuery = gql(/* GraphQL */ `
  query getProductException(
    $getProductExceptionRequest: GetProductExceptionRequest!
  ) {
    getProductException(
      getProductExceptionRequest: $getProductExceptionRequest
    ) {
      id
      payer {
        id
        type
      }
      group {
        id
        type
      }
      markup {
        value
        markupAmountType
      }
      tax {
        value
        taxAmountType
      }
      amount
      productCategory {
        productCategoryId
        name
        description
        productType
        groupId
        groupType
      }
      products {
        id
        name
        description
        groupId
        groupType
        amount
      }
    }
  }
`);
