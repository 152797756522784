const REQUIREMENT_ADMIN = 'admin';
const REQUIREMENT_USER_PRESENCE = 'user.presence';
const REQUIREMENT_USER_LOCATION = 'user.location';
const REQUIREMENT_PROFILE_NAME = 'profile.name';
const REQUIREMENT_PROFILE_EMAIL = 'profile.email';
const REQUIREMENT_PROFILE_ADDRESS = 'profile.address';
const REQUIREMENT_PROFILE_SOCIAL = 'profile.social';
const REQUIREMENT_PROFILE_IMAGES = 'profile.images';
const REQUIREMENT_PROFILE_PHONE = 'profile.phone';
const REQUIREMENT_PROFILE_EMPLOYMENT = 'profile.employment';
const REQUIREMENT_PROFILE_SALARY = 'profile.salary';
const REQUIREMENT_PROFILE_DEMOGRAPHICS = 'profile.demographics';
const REQUIREMENT_PROFILE_BIRTHDAY = 'profile.birthday';
const REQUIREMENT_PROFILE_INTERESTS = 'profile.interests';
const REQUIREMENT_PROFILE_ANALYTICS = 'profile.analytics';
const REQUIREMENT_PROMOTIONS_INTERESTS = 'promotions.interests';
const REQUIREMENT_PROMOTIONS_ANALYTICS = 'promotions.analytics';
const REQUIREMENT_SUBSCRIPTIONS_ANALYTICS = 'subscriptions.analytics';
const REQUIREMENT_SUBSCRIPTIONS_PROPERTY = 'subscriptions.property';
const REQUIREMENT_SUBSCRIPTIONS_COMPANY = 'subscriptions.company';
const REQUIREMENT_SUBSCRIPTIONS_RETAIL = 'subscriptions.retail';
const REQUIREMENT_SUBSCRIPTIONS_RESTAURANT = 'subscriptions.restaurant';
const REQUIREMENT_SUBSCRIPTIONS_CURATED = 'subscriptions.curated';
const REQUIREMENT_SUBSCRIPTIONS_MAGAZINE = 'subscriptions.magazine';
const REQUIREMENT_NOTIFICATION_USER = 'notification.user';
const REQUIREMENT_NOTIFICATION_PUSH = 'notification.push';
const REQUIREMENT_NOTIFICATION_EMAIL = 'notification.email';
const REQUIREMENT_NOTIFICATION_SMS = 'notification.sms';
const REQUIREMENT_NOTIFICATION_VOICE = 'notification.voice';

export {
  REQUIREMENT_ADMIN,
  REQUIREMENT_USER_PRESENCE,
  REQUIREMENT_USER_LOCATION,
  REQUIREMENT_PROFILE_NAME,
  REQUIREMENT_PROFILE_EMAIL,
  REQUIREMENT_PROFILE_ADDRESS,
  REQUIREMENT_PROFILE_SOCIAL,
  REQUIREMENT_PROFILE_IMAGES,
  REQUIREMENT_PROFILE_PHONE,
  REQUIREMENT_PROFILE_EMPLOYMENT,
  REQUIREMENT_PROFILE_SALARY,
  REQUIREMENT_PROFILE_DEMOGRAPHICS,
  REQUIREMENT_PROFILE_BIRTHDAY,
  REQUIREMENT_PROFILE_INTERESTS,
  REQUIREMENT_PROFILE_ANALYTICS,
  REQUIREMENT_PROMOTIONS_INTERESTS,
  REQUIREMENT_PROMOTIONS_ANALYTICS,
  REQUIREMENT_SUBSCRIPTIONS_ANALYTICS,
  REQUIREMENT_SUBSCRIPTIONS_PROPERTY,
  REQUIREMENT_SUBSCRIPTIONS_COMPANY,
  REQUIREMENT_SUBSCRIPTIONS_RETAIL,
  REQUIREMENT_SUBSCRIPTIONS_RESTAURANT,
  REQUIREMENT_SUBSCRIPTIONS_CURATED,
  REQUIREMENT_SUBSCRIPTIONS_MAGAZINE,
  REQUIREMENT_NOTIFICATION_USER,
  REQUIREMENT_NOTIFICATION_PUSH,
  REQUIREMENT_NOTIFICATION_EMAIL,
  REQUIREMENT_NOTIFICATION_SMS,
  REQUIREMENT_NOTIFICATION_VOICE,
};

const requirements = [
  {
    key: 'admin',
    description: 'This group role has admin level access to this User',
  },
  {
    key: 'user.presence',
    description: 'Channel can access the users online status.',
  },
  {
    key: 'user.location',
    description: 'Channel can access the users geo-location',
  },
  {
    key: 'profile.name',
    description: 'Channel can access the users name',
  },
  {
    key: 'profile.email',
    description: 'Channel can access the users email addresses',
  },
  {
    key: 'profile.address',
    description: 'Channel can access the users addresses',
  },
  {
    key: 'profile.social',
    description:
      'Channel can access info about the users social media accounts',
  },
  {
    key: 'profile.images',
    description: 'Channel can access the users profile images.',
  },
  {
    key: 'profile.phone',
    description: 'Channel can access the users phone number',
  },
  {
    key: 'profile.employment',
    description: 'Channel can access the users employment information',
  },
  {
    key: 'profile.salary',
    description: 'Channel can access a users salary information.',
  },
  {
    key: 'profile.demographics',
    description: 'Channel can access a users demographic information.',
  },
  {
    key: 'profile.birthday',
    description: 'Channel can access a users birthday.',
  },
  {
    key: 'profile.interests',
    description: 'Channel can access a users general interests.',
  },
  {
    key: 'profile.analytics',
    description: 'Channel can access a users personal usage analytics.',
  },
  {
    key: 'promotions.interests',
    description: 'Channel can access a users interests in promotions.',
  },
  {
    key: 'promotions.analytics',
    description: 'Channel can access a users analytics related to promotions',
  },
  {
    key: 'subscriptions.analytics',
    description: 'Channel can access a analytics about the users subscriptions',
  },
  {
    key: 'subscriptions.property',
    description:
      'Channel can get a list of what property channels this user subscribes to.',
  },
  {
    key: 'subscriptions.company',
    description:
      'Channel can get a list of what company channels this user subscribes to.',
  },
  {
    key: 'subscriptions.retail',
    description:
      'Channel can get a list of what retail channels this user subscribes to.',
  },
  {
    key: 'subscriptions.restaurant',
    description:
      'Channel can get a list of what restaurant channels this user subscribes to.',
  },
  {
    key: 'subscriptions.curated',
    description:
      'Channel can get a list of what curated channels this user subscribes to.',
  },
  {
    key: 'subscriptions.magazine',
    description:
      'Channel can get a list of what magazine channels this user subscribes to.',
  },
  {
    key: 'notification.user',
    description:
      'This channel can send notifications to the user, based on their preferences.',
  },
  {
    key: 'notification.push',
    description:
      'This channel can send push notifications to the user, regardless of their preferences.',
  },
  {
    key: 'notification.email',
    description:
      'This channel can send email notifications to the user, regardless of their preferences.',
  },
  {
    key: 'notification.sms',
    description:
      'This channel can send sms notifications to the user, regardless of their preferences.',
  },
  {
    key: 'notification.voice',
    description:
      'This channel can send voice notifications to the user, regardless of their preferences.',
  },
];

export default requirements;
