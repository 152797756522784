import gql from 'graphql-tag';

export default gql(`
  fragment ContentTemplateFragment on ContentTemplate {
    _id
    _created
    _updated
    templateName
    notes
    name
    name_l10n
    description
    description_l10n
    channel {
      _id
    }
    subtitle
    subtitle_l10n
    type
    tags
    category
    reportingGroup
    color
    isInteractive
    backgroundImage
    icon
    iconSet
    iconWeight
    backgroundColor
    liveDate
    startDate
    endDate
    unpublishDate
    version
    renderer
    card {
      _id
    }
    isEvent
  }
`);
