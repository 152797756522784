import { DateTime } from 'luxon';

/**
 * Gets the relative today's date in the time zone
 * @param timeZone
 * @returns a Date
 */
export function getToday(timeZone?: string): Date {
  return new Date(
    DateTime.fromObject({ zone: timeZone })
      .startOf('day')
      .toISO({ includeOffset: false })
  );
}
