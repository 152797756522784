import React from 'react';

import { BaseTable } from './BaseTable';
import { Filters } from '../Filters/Filters';
import { DataViewControls } from '../DataViewControls/DataViewControls';
import { Pagination } from '../Pagination/Pagination';

import { useTable, TableProps } from './hooks/useTable';

import styles from './Table.scss';

type TableComponentProps<TDataShape> = TableProps<TDataShape> & {
  /*
   ** The maximum height of the table, controls and pagination wrapper. Defaults to '80vh'.
   */
  maxHeight?: string;
};

export const Table = <TDataShape,>({
  maxHeight = '80vh',
  ...props
}: TableComponentProps<TDataShape>) => {
  const { tableProps, paginationProps, filtersProps, dataViewControlsProps } =
    useTable(props);

  return (
    <div className={styles.TableAndControlsWrapper}>
      <Filters {...filtersProps} />
      <div
        className={styles.TableControlsAndPaginationWrapper}
        style={{
          maxHeight,
        }}
      >
        <DataViewControls {...dataViewControlsProps} />
        <BaseTable {...tableProps} />
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};
