import { useEffect } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../../apollo';
import { PublicUserFragment, ProfileFragment } from '../fragments';

const getInteraction = gql`
  ${PublicUserFragment}
  ${ProfileFragment}

  query getInteraction($id: UUID!) {
    me {
      interaction(_id: $id) {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        user {
          _id
          profile {
            ...ProfileFragment
          }
        }
        startDate
        endDate
        geo
        data
        state
        features
        actions
        contentData
        content {
          _id
        }
        version
        status
      }
    }
  }
`;
export default getInteraction;

export const useGetInteraction = (uciId: string) => {
  const [getUCI, result] = useLazyQuery(getInteraction, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (uciId) {
      getUCI({
        variables: {
          id: uciId,
        },
      });
    }
  }, [uciId]);

  const item = result?.data?.me?.interaction ?? undefined;

  return {
    item,
    loading: result?.loading,
    error: result?.error,
  };
};
