import errorCodes from '../../constants/errorCodes';
import { GoogleCalendarError } from './GoogleCalendarError';

const ERROR = errorCodes.googleCalendarExistingEventNotFound!;

export class GoogleCalendarExistingEventNotFound extends GoogleCalendarError {
  static get errorName() {
    return ERROR.code;
  }

  constructor(message?: string) {
    super(ERROR, message);
  }
}
