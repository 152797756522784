import gql from 'graphql-tag';

export default gql`
  query getContentFeatureReservableAvailability(
    $id: UUID!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    contentFeatureReservableAvailability(
      _id: $id
      start: $startDate
      end: $endDate
    ) {
      startDate
      endDate
      eventType
    }
  }
`;
