import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class EssensysIntegrationSetupError extends CodedError {
  static get errorName() {
    return errorCodes.essensysIntegrationSetupError.code;
  }

  constructor(message = errorCodes.essensysIntegrationSetupError.message) {
    super(message);
    this.code = errorCodes.essensysIntegrationSetupError.code;
    this.message = `${message} ${errorCodes.essensysIntegrationSetupError.code}`;
    this.name = errorCodes.essensysIntegrationSetupError.name;
  }
}
