import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { PaymentDetailsType } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import { useEssensysPaymentMethodSelect } from 'lane-shared/hooks';
import { PaymentAccountTypeSummary } from 'lane-shared/types/payment/PaymentAccount';
import { EssensysPaymentSettingsType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';

import { Text } from 'components/typography';

import Option from '../form/Option';
import ErrorMessage from '../general/ErrorMessage';
import Loading from '../general/Loading';
import Alert, { AlertType } from '../lds/Alert';

import styles from './EssensysPaymentMethodSelect.scss';

type EssensysPaymentMethodSelectProps<T extends PaymentAccountTypeSummary> = {
  essensysInfo: EssensysPaymentSettingsType | undefined;
  essensysError: any;
  // todo: need a type for a Stripe card
  selectedCard?: any | null;
  paymentAccounts?: T[];
  selectedPaymentAccount?: T;
  onPaymentAccountSelected: (paymentAccount: PaymentAccountTypeSummary) => void;
  paymentDetails: PaymentDetailsType | undefined;
  onCardSelected: (card: any) => void;
  forInvoice?: boolean;
  isEssensysAdmin?: boolean;
  paymentType: PaymentTypeEnum;
};

export default function EssensysPaymentMethodSelect<
  T extends PaymentAccountTypeSummary
>({
  paymentType,
  paymentAccounts,
  essensysInfo,
  essensysError,
  selectedCard = null,
  selectedPaymentAccount,
  paymentDetails,
  onCardSelected = () => undefined,
  onPaymentAccountSelected,
  forInvoice = false,
  isEssensysAdmin = false,
}: EssensysPaymentMethodSelectProps<T>) {
  const { t } = useTranslation();

  const {
    error,
    loading: paymentMethodLoading,
    paymentAccounts: queriedPaymentAccounts,
    message,
    showPaymentAccounts,
    requiresPayment,
    stripeAccount,
    cards,
  } = useEssensysPaymentMethodSelect({
    essensysInfo,
    paymentDetails,
    onPaymentAccountSelected,
    onCardSelected,
    forInvoice,
    translate: t,
    paymentAccounts,
  });
  const essensysCreditMessage =
    essensysInfo && !forInvoice ? <Text>{message}</Text> : null;

  if (paymentType === PaymentTypeEnum.EssensysCredits) {
    return essensysCreditMessage;
  }

  const preferredPaymentAccounts = paymentAccounts ?? queriedPaymentAccounts;

  const loading = (!essensysInfo && !essensysError) || paymentMethodLoading;
  const noAvailableAccount = preferredPaymentAccounts?.length === 0;
  const accountIsRequired = forInvoice || !essensysInfo?.isOnAccount;

  if (
    preferredPaymentAccounts?.length === 1 &&
    selectedPaymentAccount?.type === PaymentProviderEnum.PlacePay &&
    preferredPaymentAccounts.find(
      paymentAccount => paymentAccount._id === selectedPaymentAccount._id
    )
  ) {
    return essensysCreditMessage;
  }

  return loading ? (
    <Loading />
  ) : (
    <div className={styles.EssensysPaymentMethodSelect}>
      {essensysError && <ErrorMessage error={essensysError} />}
      {error && <ErrorMessage error={error} />}
      {essensysInfo && (
        <>
          {!forInvoice && <Text>{message}</Text>}

          {!error &&
            noAvailableAccount &&
            accountIsRequired &&
            (isEssensysAdmin ? (
              <Alert type={AlertType.warning}>
                {t(
                  'No payment account is selected. If none are available you may need to create one.'
                )}
                &nbsp;
                <NavLink to={routes.meWallet}>{t('Go to Wallet.')}</NavLink>
              </Alert>
            ) : (
              <Alert type={AlertType.error}>
                  {t(
                    'No payment account is selected. If none are available please contact your administrator.'
                  )}
                </Alert>
            ))}

          {showPaymentAccounts && requiresPayment && (
            <div className={styles.accounts}>
              {preferredPaymentAccounts?.map(paymentAccount => (
                <Option
                  className={styles.option}
                  key={paymentAccount._id}
                  onClick={e => {
                    e.preventDefault();
                    onPaymentAccountSelected(paymentAccount);
                  }}
                  selected={paymentAccount._id === selectedPaymentAccount?._id}
                  hasIcon={false}
                >
                  <h2>{paymentAccount.name}</h2>
                </Option>
              ))}
            </div>
          )}

          {requiresPayment && stripeAccount && (
            <>
              {!loading && cards.length === 0 && (
                <div className={styles.setupPayment}>
                  <p>{t('You have no credit cards setup yet.')}</p>
                </div>
              )}

              {cards.length > 0 && (
                <div className={styles.accounts}>
                  {cards.map((card: any) => (
                    <Option
                      key={card.id}
                      onClick={() => onCardSelected(card)}
                      selected={card.id === selectedCard?.id}
                      hasIcon
                    >
                      <h2>{card.brand}</h2>
                      <p>**** **** **** {card.last4}</p>
                    </Option>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
