import React, { useRef, useState, useMemo } from 'react';

import cx from 'classnames';
import GeoJSON from 'geojson';
import { useTranslation } from 'react-i18next';
import { Layer, Source } from 'react-mapbox-gl';
import { useHistory, generatePath } from 'react-router-dom';

import { colors, routes } from 'lane-shared/config';
import { MIN_ZOOM_LEVEL } from 'lane-shared/helpers/integrations/FloorMaps/constants';
import {
  availableFeaturesHelper,
  unAvailableFeaturesHelper,
  preselectedFeatureHelper,
} from 'lane-shared/helpers/integrations/FloorMaps/featuresHelpers';
import useFloorContent from 'lane-shared/hooks/integrations/useFloorContent';
import useFloorMaps from 'lane-shared/hooks/integrations/useFloorMaps';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { ContentType } from 'lane-shared/types/content/Content';

import HorizontalContentCard from 'components/cards/HorizontalContentCard';
import SearchBarLegacy from 'components/general/SearchBarLegacy';
import FloorMapFilterBar from 'components/integrations/FloorMaps/FloorMapFilterBar';
import { filtersOnPills } from 'components/integrations/FloorMaps/constants';
import SectionSearchOptionsMenu from 'components/lane/SectionSearchOptionsMenu';
import { Modal } from 'components/lds';
import MapboxIconPoint from 'components/mapBox/MapboxIconPoint';
import MapboxMap from 'components/mapBox/MapboxMap';
import MapboxPoint from 'components/mapBox/MapboxPoint';
import { H3, M, XL } from 'components/typography';

import Button from '../../general/Button';

import styles from './FloorMapsContentRenderer.scss';

// 186px is the original size of the imported image
const UNAVAILABLE_ICON_SIZE_NORMALIZED = 1 / 186;
const POINT_SIZE = 20;

const animationOptions = {
  animate: false,
};

const containerStyle = {
  display: 'flex',
  minWidth: 200,
  minHeight: 700,
  height: '100%',
  flex: 1,
};

type Props = {
  className?: string;
  style: React.CSSProperties;
  content: ContentType;
};

const selectedFloorPaintOptions = {
  'raster-opacity': 1,
};

export default function FloorMapsContentRenderer({
  className,
  style,
  content,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { location } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const mapRef = useRef(null);

  const {
    settings,
    updateZoom,
    updateCenter,
    tileSources,
    floorMaps,
    floorMapItems,
    selectFloor,
    selectedFloor,
    selectedFloorBounds,
    lastMapPosition,
    mapBounds,
    bearing,
  } = useFloorMaps({ content, defaultZoom: 19 });

  const {
    sectionProcessors,
    loading,
    sectionsData,
    floorInitialSearchOptions,
    floorSearchOptions,
    hasSearch,
    floorContent,
    metatags,
    metatagId,
    updateSearchOptions,
    resetSearchOptions,
    applySearchOptions,
    selectSectionIds,
    draftFloorSearchOptions,
    selectContent,
    selectedContentId,
  } = useFloorContent({
    settings,
    selectedFloor,
    location,
  });

  const fitBounds = useMemo(() => {
    if (!selectedFloorBounds) {
      return;
    }

    return [
      [selectedFloorBounds[0], selectedFloorBounds[1]],
      [selectedFloorBounds[2], selectedFloorBounds[3]],
    ];
  }, [selectedFloorBounds]);

  function closeModalHandler() {
    setIsOpen(false);
    updateSearchOptions(floorSearchOptions);
  }

  function openModalHandler() {
    setIsOpen(true);
  }

  function resetSearchOptionsHandler() {
    resetSearchOptions();
    applySearchOptions();
    setIsOpen(false);
  }

  function searchChangeHandler(search: any) {
    updateSearchOptions({ search });
    applySearchOptions();
  }

  function applySearchOptionsHandler() {
    applySearchOptions();
    setIsOpen(false);
  }

  function contentCardClicked(selectedContentId: any) {
    // used set timeout due to current issue with react-mapbox-gl https://github.com/alex3165/react-mapbox-gl/issues/609
    setTimeout(() => {
      history.push(
        generatePath(routes.content, {
          id: selectedContentId,
        })
      );
    }, 0);
  }

  function contentClicked(content: any) {
    selectContent(content.features[0].properties.id);
  }

  function mapReady(map: any) {
    // store the map control for later
    mapRef.current = map;
  }

  const floorIndex = floorMaps.findIndex(
    elem => elem._id === selectedFloor?._id
  );
  const tileSource = tileSources[floorIndex];

  const availableFeatures = useMemo((): GeoJSON.FeatureCollection<GeoJSON.Point> | null => {
    return availableFeaturesHelper(floorContent, metatagId, selectedContentId);
  }, [floorContent, metatagId, selectedFloor, selectedContentId]);

  const unAvailableFeatures = useMemo((): GeoJSON.FeatureCollection<GeoJSON.Point> | null => {
    return unAvailableFeaturesHelper(
      floorContent,
      metatagId,
      selectedContentId
    );
  }, [floorContent, metatagId, selectedFloor, selectedContentId]);

  const selectedFeature = useMemo((): GeoJSON.Feature<GeoJSON.Point> | null => {
    return preselectedFeatureHelper(floorContent, metatagId, selectedContentId);
  }, [floorContent, metatagId, selectedContentId]);

  return (
    <div
      className={cx(styles.FloorMapsContentRenderer, className)}
      style={style}
    >
      {sectionProcessors}
      <div className={styles.desks}>
        <div className={styles.leftColumn}>
          <div className={styles.searchOptions}>
            <H3 bold className={styles.header} mb={2} mt={0}>
              {settings?.name}
            </H3>
            <div className={styles.controls}>
              <SearchBarLegacy
                className={styles.searchBar}
                searchOptions={floorSearchOptions}
                onSearchChange={searchChangeHandler}
                showSearch={hasSearch}
                searchInputClassName={styles.searchInput}
                searchInputPlaceholder={t('Search for a desk')}
                showAllLocations
                isFiltersEnabled={Boolean(
                  floorSearchOptions?.search ||
                    floorSearchOptions?.areFiltersApplied
                )}
              />
              <FloorMapFilterBar
                loading={loading}
                sortings={floorSearchOptions.sorts}
                filters={floorSearchOptions.filters}
                searchOptions={floorSearchOptions}
                initialSearchOptions={floorInitialSearchOptions}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(SectionType | null)[]' is not assignable to... Remove this comment to see the full error message
                sections={
                  loading ? [] : sectionsData?.map(item => item.section)
                }
                selectSections={selectSectionIds}
                onSearchOptionsUpdated={props => {
                  updateSearchOptions({
                    ...props,
                  });
                }}
                onApplySearchOptions={applySearchOptions}
                selectFloor={selectFloor}
                selectedFloor={selectedFloor}
                floorMapItems={floorMapItems}
                onOpenMoreFilters={openModalHandler}
              />
            </div>
          </div>

          <hr className={styles.divider} />

          <M mb={5} className={styles.desksOnFloor}>
            {t('{{numberOfDesks}} desks found on {{floorName}}', {
              numberOfDesks: floorContent.length,
              floorName: selectedFloor?.name,
            })}
          </M>

          <div className={styles.deskCards}>
            {floorContent.map((content: any) => (
              <button
                key={content._id}
                className={cx(styles.contentCard)}
                onClick={() => contentCardClicked(content._id)}
              >
                <HorizontalContentCard
                  content={content}
                  style={{ width: 'auto' }}
                />
              </button>
            ))}
          </div>
        </div>

        <div className={styles.map} id="mapbox-wrapper">
          <MapboxMap
            animationOptions={animationOptions}
            fitBounds={fitBounds}
            maxBounds={mapBounds}
            containerStyle={containerStyle}
            onStyleLoad={mapReady}
            onZoom={updateZoom}
            onCenter={updateCenter}
            minZoom={MIN_ZOOM_LEVEL}
            bearing={[bearing]}
            isPositionReady={lastMapPosition.isReady}
            center={lastMapPosition.center}
            zoom={lastMapPosition.zoom ? [lastMapPosition.zoom] : undefined}
            fullScreenControlContainer={
              document.getElementById('mapbox-wrapper')!
            }
          >
            {selectedFloor && (
              <>
                <XL className={styles.floorName}>
                  {t('Floor {{floorName}}', {
                    floorName: selectedFloor.name,
                  })}
                </XL>
                {tileSource ? (
                  <>
                    <Source
                      id={`${selectedFloor._id}-map`}
                      tileJsonSource={tileSource}
                    />
                    <Layer
                      type="raster"
                      sourceId={`${selectedFloor._id}-map`}
                      paint={selectedFloorPaintOptions}
                    />
                  </>
                ) : null}
              </>
            )}

            {selectedFeature && selectedFloor && (
              <MapboxPoint
                radius={POINT_SIZE / 2}
                draggable={false}
                color={colors.interactiveBlue}
                isSelected
                id={`${selectedFloor._id}-selected-content`}
                onFocus={contentClicked}
                source={selectedFeature}
              />
            )}

            {availableFeatures && selectedFloor && (
              <MapboxPoint
                radius={POINT_SIZE / 2}
                draggable={false}
                color={colors.interactiveBlue}
                isSelected={false}
                id={`${selectedFloor._id}-available-content`}
                onFocus={contentClicked}
                source={availableFeatures}
              />
            )}

            {unAvailableFeatures && selectedFloor && (
              <MapboxIconPoint
                name={content?.name}
                iconImage="unavailable"
                size={POINT_SIZE * UNAVAILABLE_ICON_SIZE_NORMALIZED}
                draggable={false}
                id={`${selectedFloor._id}-unAvailable-content`}
                onFocus={contentClicked}
                source={unAvailableFeatures}
              />
            )}

            {selectedContentId && (
              <button
                key={selectedContentId}
                className={styles.selectedDesk}
                onClick={() => contentCardClicked(selectedContentId)}
              >
                <HorizontalContentCard
                  content={floorContent.find(
                    (cont: any) => cont._id === selectedContentId
                  )}
                  style={{ width: 'auto', margin: '0 24px' }}
                />
              </button>
            )}
          </MapboxMap>
        </div>
      </div>

      <Modal
        size="large"
        className={cx(styles.wrapper)}
        onClose={closeModalHandler}
        isOpen={isOpen}
        actions={
          <>
            <Button onClick={resetSearchOptionsHandler}>Clear</Button>
            <Button onClick={applySearchOptionsHandler} variant="contained">
              Apply
            </Button>
          </>
        }
      >
        <SectionSearchOptionsMenu
          className={styles.filters}
          metatags={metatags}
          filters={draftFloorSearchOptions?.filters
            .filter(filter => !filtersOnPills.includes(filter.type))
            .map(item => item.type)}
          searchOptions={draftFloorSearchOptions}
          allowDisableFilter
          onSearchOptionsUpdated={props =>
            updateSearchOptions({
              ...props,
            })
          }
        />
      </Modal>
    </div>
  );
}
