import React from 'react';

import cx from 'classnames';
import { Dropdown, Input } from 'components';
import { useTranslation } from 'react-i18next';

import {
  ScheduleAction,
  ScheduleActionType,
  InternalScheduleType,
} from 'lane-shared/domains/visitorManagement/reducers';
import { MonthIntervalTypeEnum } from 'lane-shared/domains/visitorManagement/types';

import { getMonthIntervalOptions } from '../../helpers/getMonthIntervalOptions';

import styles from './MonthlyInterval.scss';

type Props = {
  state: InternalScheduleType;
  dispatch: (value: ScheduleAction) => void;
  defaultIntervalCount: number;
  defaultMonthIntervalType: MonthIntervalTypeEnum;
  timeZone?: string;
  className?: string;
};

export function MonthlyInterval({
  state,
  dispatch,
  defaultIntervalCount,
  defaultMonthIntervalType,
  timeZone,
  className,
}: Props) {
  const { t } = useTranslation();

  const monthlyIntervalOptions = getMonthIntervalOptions(
    state.startDate,
    t,
    timeZone
  );

  return (
    <div className={cx(styles.monthlyInterval, className)}>
      <div className={styles.inputContainer}>
        <Input
          className={styles.input}
          label={t('web.content.feature.visitorManagement.form.label.every')}
          fixedLabel
          type="number"
          onChange={value => {
            dispatch({
              type: ScheduleActionType.SetIntervalCount,
              value: { number: Number(value) },
            });
          }}
          value={String(state.intervalCount)}
          onBlur={() => {
            if (!state.intervalCount) {
              dispatch({
                type: ScheduleActionType.SetIntervalCount,
                value: { number: defaultIntervalCount },
              });
            }
          }}
          rightText={t(
            'web.content.feature.visitorManagement.form.label.month'
          )}
          testId="monthlyIntervalCount"
        />
      </div>
      <div className={cx(styles.dropdownContainer, styles.inputContainer)}>
        <Dropdown
          className={styles.dropdown}
          isSearchable={false}
          initialValue={defaultMonthIntervalType}
          value={state.monthIntervalType}
          onChange={value => {
            dispatch({
              type: ScheduleActionType.SetMonthIntervalType,
              value: { string: value.value },
            });
          }}
          items={monthlyIntervalOptions}
          testId="monthlyIntervalDropdown"
        />
      </div>
    </div>
  );
}
