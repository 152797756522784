import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query interactionsOnContent(
    $contentId: UUID!
    $search: UserContentInteractionSearch
    $pagination: PaginationInput
  ) {
    interactionsOnContent(
      contentId: $contentId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        user {
          _id
          name
          profile {
            _id
            name
            email
            image
            logo
          }
        }
        startDate
        endDate
        geo
        data
        state
        features
        actions
        contentData
        version
        status
        lastStatus
      }
    }
  }
`;
