export const routes = {
  hardwareDevices: 'hardware-management/devices',
  hardwareAccessLogs: 'hardware-management/access-logs',
  hardwareDetails: 'hardware-management/hardware/:hardwareId',
  hardwareAdd: 'hardware-management/new',
  relayList: 'hardware-management/relays',
  relayDetails: 'hardware-management/relays/:relayId',
  hardwarePinCodes: 'hardware-management/pin-codes',
  hardwareAddPinCodes: 'hardware-management/pin-codes/new',
  hardwareDetailPinCodes: 'hardware-management/pin-codes/:pinCodeId',
};
