import gql from 'graphql-tag';

export default gql`
  query getActiveUsersByGroupRole(
    $channelId: UUID
    $groupRole: String
    $beforeDate: String!
  ) {
    portal {
      activeUsersByGroupRole(
        channelId: $channelId
        groupRole: $groupRole
        beforeDate: $beforeDate
      ) {
        name
        count
      }
    }
  }
`;
