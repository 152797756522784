import { DateTime } from 'luxon';
import QRCode from 'qrcode';

import { AddressType } from 'lane-shared/types/AddressType';

export const formatTime = (date: DateTime): string =>
  date.toLocaleString(DateTime.TIME_SIMPLE);

export const capitalize = (name: string): string =>
  name.charAt(0).toUpperCase() + name.slice(1);

export const formatName = (name?: string): [string, string] => [
  capitalize(name?.split(' ')[0] ?? ''),
  capitalize(name?.split(' ').slice(1).join(' ') ?? ''),
];

export const formatDate = (
  date: DateTime,
  options?: Intl.DateTimeFormatOptions
): string =>
  date.toLocaleString({
    weekday: 'long',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    ...options,
  });

export const interpretUTCDate = (
  date: string | undefined,
  locale: string,
  timezone: string
): DateTime =>
  DateTime.fromISO(date ?? new Date().toString(), {
    zone: 'utc',
    locale,
  }).setZone(timezone);

export const formatAddress = (
  address: AddressType | undefined
): [string, string] => {
  if (!address) {
    return ['', ''];
  }

  return [
    `${address?.street1}${address?.street2 ? ` ${address?.street2}` : ''}`,
    `${address?.city}, ${address?.state} ${address?.code}`,
  ];
};

export const createQRCodeData = (data: Uint8Array): Promise<[string, Buffer]> =>
  Promise.all([
    QRCode.toDataURL(data.toString(), {
      scale: 8,
    }),
    QRCode.toBuffer(data.toString(), {
      scale: 10,
    }),
  ]);

export const formatToShortDate = (date: DateTime): string =>
  date.toLocaleString({
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

  export const formatDateToMonthDay = (date: DateTime): string =>
    date.toLocaleString({
      month: 'short',
      day: 'numeric',
    });
