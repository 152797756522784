import gql from 'graphql-tag';

export default gql`
  query getChannelAccessControlGroups($channelId: UUID!, $acgIds: [String]) {
    getChannelAccessControlGroups(channelId: $channelId, acgIds: $acgIds) {
      id
      name
      description
      userCount
      provider
      channelId
    }
  }
`;
