import gql from 'graphql-tag';

import { PaymentAccountFragment } from '../fragments';

export default gql`
  ${PaymentAccountFragment}

  query myChannelPaymentAccounts {
    me {
      channels {
        paymentAccounts {
          _id
          ...PaymentAccountFragment
          channel {
            _id
          }
        }
      }
    }
  }
`;
