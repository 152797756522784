import React, { useState, useEffect, useContext, useCallback } from 'react';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button } from 'design-system-web';
import Checkbox from 'components/form/Checkbox';

import { getClient } from 'lane-shared/apollo';
import { disableMobileAccess } from 'lane-shared/graphql/accessControl';
import { ChannelsContext } from 'lane-shared/contexts';
import { UserInfo } from 'lane-web/src/domains/accessControl/types/AccessControl';

import styles from './styles.scss';
import { useConnectedBuildingChannel } from '../../../hooks/useConnectedBuildingChannel';
import {
  MobileAccessAction,
  useTrackMobileAccessEvents,
} from 'lane-web/src/domains/accessControl/analytics/mobile-access';
import { formatAccessManagerRole } from 'lane-web/src/domains/accessControl/helpers/formatAccessManagerRole';

const TRANSLATION_KEYS = {
  description:
    'web.admin.accessControl.userAccess.mobileAccess.singleDisable.description',
  descriptionPlural:
    'web.admin.accessControl.userAccess.mobileAccess.multipleDisable.description',
  note: 'web.admin.accessControl.userAccess.mobileAccess.singleDisable.note',
  noteDescription:
    'web.admin.accessControl.userAccess.mobileAccess.singleDisable.noteDescription',
};

const defaultIdNameModelValue = {
  id: '',
  name: '',
};

type IdNameModel = {
  id: string;
  name: string;
};

type props = {
  currentUser: UserInfo;
  channelId: string;
  users: UserInfo[];
  accessManagerLocations: IdNameModel[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (usersUpdated: UserInfo[]) => void;
};

export function MobileAccessDisableModal({
  currentUser,
  channelId,
  users,
  accessManagerLocations,
  isOpen,
  onClose,
  onSubmit,
}: props) {
  const { t } = useTranslation();
  const {
    trackMobileAccessEditCompleted,
    trackMobileAccessEditFailed,
  } = useTrackMobileAccessEvents();
  const { channels } = useContext(ChannelsContext);
  const { connectedChannel, loading } = useConnectedBuildingChannel(
    channelId,
    currentUser
  );

  const [channelsToProcess, setChannelsToProcess] = useState<any[]>([]);
  const [currentChannel, setCurrentChannel] = useState<IdNameModel>(
    defaultIdNameModelValue
  );
  const [buildingChannel, setBuildingChannel] = useState<IdNameModel>(
    defaultIdNameModelValue
  );
  const [userOtherLocations, setUserOtherLocations] = useState<IdNameModel[]>(
    []
  );
  const [options, setOptions] = useState<any>({
    buildingChannel: true,
    otherChannels: false,
  });

  const userCount = users.length;

  function updateOptions(props: Partial<any>) {
    setOptions({
      ...options,
      ...props,
    });
  }

  function renderOption(key: any, name: any) {
    return (
      <li>
        <Checkbox
          name={key}
          onChange={() => updateOptions({ [key]: !options[key] })}
          value=""
          selected={options[key]}
          text={t(name)}
        />
      </li>
    );
  }

  const onDisableMobileAccessSubmit = async () => {
    try {
      const userIds = users.map(user => user._id);

      await Promise.all(
        channelsToProcess.map(channel =>
          getClient().mutate({
            mutation: disableMobileAccess,
            variables: {
              channelId: channel.id,
              userIds,
            },
          })
        )
      );

      trackMobileAccessEditCompleted({
        action: MobileAccessAction.DISABLE,
        accessRole: formatAccessManagerRole(currentUser.roles ?? []),
        attemptedChannelId: channelId,
      });
      onSubmit(users);
      onClose();

      window.Toast.show(
        userCount === 1
          ? t(
              'web.admin.accessControl.userAccess.mobileAccess.disableMobileAccess.toast.success',
              {
                userName: users[0].name,
              }
            )
          : t(
              'web.admin.accessControl.userAccess.mobileAccess.disableMobileAccess.toast.success.multiple',
              { userCount }
            )
      );
    } catch (err) {
      trackMobileAccessEditFailed({
        action: MobileAccessAction.DISABLE,
        accessRole: formatAccessManagerRole(currentUser.roles ?? []),
        attemptedChannelId: channelId,
      });
      console.error(err);
    }
  };

  const setAccessDisableChannels = useCallback(() => {
    const accessDisableChannels = [currentChannel];
    if (options.buildingChannel) {
      if (
        buildingChannel.id &&
        !accessDisableChannels.some(
          channel => channel.id === buildingChannel.id
        )
      ) {
        accessDisableChannels.push(buildingChannel);
      }
    }

    if (options.otherChannels) {
      userOtherLocations.forEach(channel => {
        if (
          channel.id &&
          !accessDisableChannels.some(
            existingChannel => existingChannel.id === channel.id
          )
        ) {
          accessDisableChannels.push(channel);
        }
      });
    }

    setChannelsToProcess(prevState => {
      if (!arraysAreEqualById(prevState, accessDisableChannels)) {
        return accessDisableChannels;
      }
      return prevState;
    });
  }, [
    buildingChannel,
    currentChannel,
    options.buildingChannel,
    options.otherChannels,
    userOtherLocations,
  ]);

  const setAccessUsersChannels = useCallback(
    (users: UserInfo[], managerLocations: IdNameModel[]) => {
      const currentRelatedChannels = [currentChannel?.id, buildingChannel?.id];
      const connectedChannelIds = users.flatMap(user =>
        user?.multiIntegrationAcgs
          ? user.multiIntegrationAcgs.map(acg => convertTo62(acg.channelId))
          : []
      );
      const userAccessLocations = managerLocations.filter(
        location =>
          connectedChannelIds.includes(location.id) &&
          !currentRelatedChannels.includes(location.id)
      );
      setUserOtherLocations(prevState => {
        if (!arraysAreEqualById(prevState, userAccessLocations)) {
          return userAccessLocations;
        }
        return prevState;
      });
    },
    [buildingChannel?.id]
  );

  const convertToIdNameModel = (data: any) => {
    return {
      id: data?._id || data?.id,
      name: data?.name,
    };
  };

  const arraysAreEqualById = (arr1: IdNameModel[], arr2: IdNameModel[]) => {
    if (arr1.length !== arr2.length) return false;
    const ids1 = arr1.map(item => item.id).sort();
    const ids2 = arr2.map(item => item.id).sort();
    return ids1.every((id, index) => id === ids2[index]);
  };

  useEffect(() => {
    const currentSelectedChannel = channels.find(
      channel => channel._id === channelId
    );
    const parsedCurrentChannel = convertToIdNameModel(currentSelectedChannel);
    const parsedBuildingChannel = convertToIdNameModel(connectedChannel);

    if (currentChannel.id !== parsedCurrentChannel.id) {
      setCurrentChannel(parsedCurrentChannel);
    }

    if (buildingChannel.id !== parsedBuildingChannel.id) {
      setBuildingChannel(parsedBuildingChannel);
    }

    setAccessUsersChannels(users, accessManagerLocations);
    setAccessDisableChannels();
  }, [
    channelId,
    connectedChannel,
    channels,
    currentChannel.id,
    buildingChannel.id,
    setAccessDisableChannels,
    setAccessUsersChannels,
    users,
    accessManagerLocations,
  ]);

  return (
    <Modal
      isOpen={
        isOpen && !loading && channelsToProcess.length > 0 && userCount >= 1
      }
      onClose={onClose}
      className={styles.modal}
      size="large"
      title={
        userCount === 1
          ? t(
              'web.admin.accessControl.userAccess.mobileAccess.singleDisable.title',
              { channelName: currentChannel?.name, userName: users[0].name }
            )
          : t(
              'web.admin.accessControl.userAccess.mobileAccess.multipleDisable.title',
              { channelName: currentChannel?.name, userCount: users.length }
            )
      }
    >
      <div
        data-test="access-control-disable-modal"
        className={styles.description}
      >
        <Trans
          i18nKey={
            userCount === 1
              ? TRANSLATION_KEYS.description
              : TRANSLATION_KEYS.descriptionPlural
          }
        />
        <p className={styles.note}>
          <strong>
            <Trans i18nKey={TRANSLATION_KEYS.note} />{' '}
          </strong>
          <Trans i18nKey={TRANSLATION_KEYS.noteDescription} />
        </p>

        <div className={styles.note}>
          <ul>
            {buildingChannel?.name &&
              renderOption(
                'buildingChannel',
                t(
                  'web.admin.accessControl.userAccess.mobileAccess.disableBuildingOptionText',
                  {
                    buildingChannelName: buildingChannel?.name,
                  }
                )
              )}
            {userOtherLocations.length > 0 &&
              renderOption(
                'otherChannels',
                t(
                  'web.admin.accessControl.userAccess.mobileAccess.disableOtherChannelsText',
                  {
                    locationsCount: userOtherLocations.length,
                  }
                )
              )}
          </ul>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          testId="mobileAccessDisableModalEnableButton"
          onClick={onDisableMobileAccessSubmit}
          size="large"
        >
          {t(
            'web.admin.accessControl.userAccess.mobileAccess.disableMobileAccess'
          )}
        </Button>
        <Button
          variant="text"
          onClick={onClose}
          testId="mobileAccessDisableModalCancelButton"
          size="large"
          className={styles.cancelButton}
        >
          {t('Cancel')}
        </Button>
      </div>
    </Modal>
  );
}
