import { DateTime } from 'luxon';

/**
 * Combines two input Date objects where date is the desired date
 * and time is the desired time. The output is a Date object with both
 * the desired date and time.
 * timeZone should be the same as the input time
 * @param date a JS Date object with the desired calendar date
 * @param time a JS Date object with the desired time
 * @param timeZone optional - recommended
 */
export function combineDateAndTime(
  date: Date,
  time: Date,
  timeZone?: string
): Date {
  let _date = DateTime.fromJSDate(date);
  let _time = DateTime.fromJSDate(time);

  if (timeZone) {
    _date = _date.setZone(timeZone);
    _time = _time.setZone(timeZone);
  }

  const dateTime = DateTime.fromObject({
    year: _date.year,
    month: _date.month,
    day: _date.day,
    hour: _time.hour,
    minute: _time.minute,
    second: _time.second,
    millisecond: _time.millisecond,
    zone: timeZone,
  }).toJSDate();

  return dateTime;
}
