/* eslint-disable react/jsx-no-literals */
import React from 'react';
import EmailText from '../../../../emails/components/EmailText';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { MjmlColumn, MjmlSection } from '../../../../emails/MjmlComponentsV2';
import { SHORT_TIME, SIMPLE_DATE } from '../../../constants/dates';
import { AssigneeChangedWebhookResponse, WorkOrder } from '../types';

type TemplateProps = {
  workOrderDetail: WorkOrder;
  webhookResData: AssigneeChangedWebhookResponse;
};

export const WoAssigneeChangedTemplate = ({
  workOrderDetail,
  webhookResData,
}: TemplateProps) => {
  return (
    <>
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <h3>
              {`Work order ${workOrderDetail.identifier} for ${workOrderDetail.building.name} has changed`}
            </h3>
          </EmailText>
          <EmailText>
            <p
              data-test="wo-assignee-changed-label"
              style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              Assignee:
            </p>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection padding-top="12px">
        {renderAssigneeText(webhookResData.old_assignee)}
        <MjmlColumn width="64px">
          <EmailText>
            <h3 style={{ textAlign: 'center' }}>&#8594;</h3>
          </EmailText>
        </MjmlColumn>
        {renderAssigneeText(webhookResData.new_assignee)}
      </MjmlSection>
      <MjmlSection padding-top="24px">
        <MjmlColumn>
          <EmailText>
            <MjmlColumn>
              <p
                data-test="wo-assignee-changed-footer"
                style={{ textAlign: 'center', fontWeight: '400' }}
              >
                {`Changed by property management team on ${dateFormatter(
                  webhookResData.createdDate,
                  SIMPLE_DATE
                )} at ${dateFormatter(webhookResData.createdDate, SHORT_TIME)}`}
              </p>
            </MjmlColumn>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
    </>
  );
};

const renderAssigneeText = (value: string) => {
  let name = 'Unassigned';
  let email;
  if (value && value.indexOf('(') !== -1) {
    name = value.substring(0, value.indexOf('(')).trim();
    email = value.substring(value.indexOf('('));
  }
  return (
    <MjmlColumn>
      <EmailText>
        <h3>{name}</h3>
        <p style={{ fontWeight: 400 }}>{email}</p>
      </EmailText>
    </MjmlColumn>
  );
};
