import i18next from 'i18next';

import {
  LONG_DAY,
  LONG_MONTH_DATE_YEAR,
  SHORT_DATE_FULL_MONTH,
} from '../../../helpers/constants/dates';
import { dateFormatter } from '../../../helpers/formatters';
import { isToday } from './isToday';

const COMMA = ', ';

export function formatDate(
  date: Date | null | undefined,
  translator: (key: string, params?: any) => string = i18next.t,
  displayYear: boolean = false,
  displayDay: boolean = false,
  timeZone?: string
) {
  if (date) {
    let formattedDate = '';

    if (displayDay) {
      formattedDate = isToday(date, timeZone)
        ? translator('shared.common.visitorManagement.datePicker.today')
        : dateFormatter(date, LONG_DAY, timeZone);
      formattedDate += COMMA;
    }
    if (!displayYear) {
      formattedDate += dateFormatter(date, SHORT_DATE_FULL_MONTH, timeZone);
    } else {
      formattedDate += dateFormatter(date, LONG_MONTH_DATE_YEAR, timeZone);
    }
    return formattedDate;
  }
  return '';
}
