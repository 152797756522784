import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useVisitorsList, useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { ContentType } from 'lane-shared/types/content/Content';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { H5 } from 'components/typography';

import history from '../../../helpers/history';
import {
  VisitorInviteForm,
  VisitorManagementVisitorListItems,
} from '../components';
import { BulkVisitorsItem } from '../components/BulkVisitosItem/BulkVisitorsItem';
import { DownloadVisitorInfoSample } from '../components/DownloadVisitorInfoSample';
import LeaveConfirmationPrompt from '../components/LeaveConfirmationPrompt/LeaveConfirmationPrompt';
import { VisitorInviteBulkUpload } from '../components/VisitorInviteBulkUpload';

import styles from './AddVisitorsBlock.scss';

export default function AddVisitorsBlock({
  className,
  onChange,
  properties,
  value,
  style,
  show,
  isPreview = false,
  content,
  submissionCompletedAt,
  submitAttempted,
}: {
  className?: string;
  onChange: (value: VisitorType[]) => void;
  properties: VisitorManagementFeatureProperties;
  value: VisitorType[] | null;
  style?: React.CSSProperties;
  show: boolean;
  isPreview?: boolean;
  content?: ContentType;
  submissionCompletedAt?: Date;
  submitAttempted?: boolean;
}) {
  const { t } = useTranslation();

  const [isPristine, setIsPristine] = useState(true);
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [existingBulkVisitors, setExistingBulkVisitors] = useState(
    [] as VisitorType[]
  );

  const isBulkImportEnabled =
    properties.bulkImportSetting === undefined
      ? true
      : properties.bulkImportSetting;

  function onBulkVisitorsChange(bulkVisitors: VisitorType[]) {
    setExistingBulkVisitors(bulkVisitors);
  }

  const {
    visitors,
    handleAdd,
    handleBulkAdd,
    handleEditSave,
    handleDelete,
    handleBulkDelete,
    resetForm,
  } = useVisitorsList({
    visitors: value,
    bulkVisitors: existingBulkVisitors,
    onBulkVisitorsChange,
    onChange,
    contentType: content?.type,
    channelId: content?.channel?._id,
  });

  const visitorInviteBulkUploadFlag = useFlag(
    FeatureFlag.VisitorInviteBulkUpload,
    false
  );

  useEffect(() => {
    setShowInviteForm(false);
  }, [visitors.length]);

  useEffect(() => {
    resetForm();
  }, [submissionCompletedAt]);

  if (!show) return null;

  return (
    <>
      <LeaveConfirmationPrompt
        testId="visitorLeaveConfirmation"
        when={!submitAttempted && (visitors.length > 0 || !isPristine)}
        navigate={path => history.push(path)}
      />
      <div className={cx(className, styles.addVisitorsBlock)} style={style}>
        <H5 className={styles.visitorInviteTitle}>
          {t('web.content.feature.visitorManagement.visitorInvite.form.title')}
        </H5>
        <div className={styles.guestListContainer}>
          {Boolean(visitors.length) && (
            <VisitorManagementVisitorListItems
              visitors={visitors}
              properties={properties}
              allowEdit
              onEditSave={handleEditSave}
              onDelete={handleDelete}
            />
          )}
        </div>
        <div className={styles.guestListContainer}>
          {Boolean(existingBulkVisitors.length) && (
            <BulkVisitorsItem
              bulkVisitors={existingBulkVisitors}
              onDelete={handleBulkDelete}
            />
          )}
        </div>
        <br />
        {Boolean(visitors.length === 0 || showInviteForm) && (
          <VisitorInviteForm
            onAdd={handleAdd}
            value={visitors}
            properties={properties}
            onFieldChange={(changed: boolean) => setIsPristine(changed)}
            isPreview={isPreview}
          />
        )}

        <div className={styles.buttons}>
          {Boolean(visitors.length !== 0) && !showInviteForm && (
            <Button
              onClick={() => setShowInviteForm(true)}
              disabled={isPreview}
              className={styles.addButton}
              testId="addAnotherVisitor"
              startIcon={
                <Icon
                  name="plus-circle"
                  testId="deleteIcon"
                  set={ICON_SET_FONTAWESOME}
                />
              }
            >
              {t(
                'web.content.feature.visitorManagement.visitorInvite.form.addAnother'
              )}
            </Button>
          )}
          {isBulkImportEnabled &&
          (visitorInviteBulkUploadFlag || Boolean(visitors.length !== 0)) ? (
            <div className={styles.bulkUploadSection}>
              <VisitorInviteBulkUpload
                handleBulkAdd={handleBulkAdd}
                properties={properties}
                isPreview={isPreview}
              />
              <DownloadVisitorInfoSample isPreview={isPreview} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
