import gql from 'graphql-tag';

export default gql`
  query getRecentJobs($recentJobsRequest: RecentJobsRequest) {
    getRecentJobs(recentJobsRequest: $recentJobsRequest) {
      jobs {
        id
        status
        type
        created
        updated
      }
    }
  }
`;
