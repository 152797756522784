import gql from 'graphql-tag';

export default gql`
  query getMemberGroupRoles($id: UUID!) {
    memberGroupRoles(_id: $id) {
      groupRole {
        _id
        name
        isPublic
        channel {
          _id
          name
          slug
          type
          isPublic
          isSub
          parent {
            _id
          }
        }
      }
    }
  }
`;
