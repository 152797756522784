import React from 'react';

/**
 * Mjml is meant to run on server side only, but we also want to show previews
 * of emails in web and mobile.  So by importing our Mjml components from here
 * allows us to setup emails on the server side by passing in mjml-react
 * components and setup emails on the client side by passing on our react
 * stubs of these components.
 */

type GenericMjmlProps = Record<string, any>;

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let Mjml: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlAttributes: React.FunctionComponent<GenericMjmlProps> = ({
  children,
}) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlAll: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlGroup: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlSection: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlColumn: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlButton: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlImage: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlHead: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlTitle: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlPreview: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlBody: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlStyle: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlRaw: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlSocial: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlDivider: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlSocialElement: React.FunctionComponent<GenericMjmlProps> = ({
  children,
}) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlWrapper: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlText: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

/**
 * @deprecated Use component from ./MjmlComponentsV2 instead
 */
let MjmlSpacer: React.FunctionComponent<GenericMjmlProps> = ({ children }) => {
  return <>{children}</>;
};

export function setMjmlComponent(name: string, component: any) {
  switch (name) {
    case 'Mjml':
      Mjml = component;
      break;
    case 'MjmlAttributes':
      MjmlAttributes = component;
      break;
    case 'MjmlAll':
      MjmlAll = component;
      break;
    case 'MjmlGroup':
      MjmlGroup = component;
      break;
    case 'MjmlSection':
      MjmlSection = component;
      break;
    case 'MjmlColumn':
      MjmlColumn = component;
      break;
    case 'MjmlButton':
      MjmlButton = component;
      break;
    case 'MjmlImage':
      MjmlImage = component;
      break;
    case 'MjmlHead':
      MjmlHead = component;
      break;
    case 'MjmlTitle':
      MjmlTitle = component;
      break;
    case 'MjmlPreview':
      MjmlPreview = component;
      break;
    case 'MjmlBody':
      MjmlBody = component;
      break;
    case 'MjmlStyle':
      MjmlStyle = component;
      break;
    case 'MjmlSocial':
      MjmlSocial = component;
      break;
    case 'MjmlDivider':
      MjmlDivider = component;
      break;
    case 'MjmlSocialElement':
      MjmlSocialElement = component;
      break;
    case 'MjmlWrapper':
      MjmlWrapper = component;
      break;
    case 'MjmlRaw':
      MjmlRaw = component;
      break;
    case 'MjmlText':
      MjmlText = component;
      break;
    case 'MjmlSpacer':
      MjmlSpacer = component;
      break;
  }
}

const MjmlComponents = {
  Mjml,
  MjmlAttributes,
  MjmlAll,
  MjmlGroup,
  MjmlSection,
  MjmlColumn,
  MjmlButton,
  MjmlImage,
  MjmlHead,
  MjmlTitle,
  MjmlPreview,
  MjmlBody,
  MjmlStyle,
  MjmlRaw,
  MjmlSocial,
  MjmlDivider,
  MjmlSocialElement,
  MjmlWrapper,
  MjmlText,
  MjmlSpacer,
};

export {
  Mjml,
  MjmlAttributes,
  MjmlAll,
  MjmlGroup,
  MjmlSection,
  MjmlColumn,
  MjmlButton,
  MjmlImage,
  MjmlHead,
  MjmlTitle,
  MjmlPreview,
  MjmlBody,
  MjmlStyle,
  MjmlRaw,
  MjmlSocial,
  MjmlDivider,
  MjmlSocialElement,
  MjmlWrapper,
  MjmlText,
  MjmlSpacer,
};

export default MjmlComponents;
