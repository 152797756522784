import { useEffect, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const useRenderTime = (componentName: string) => {
  const hasRendered = useRef(false);
  const useRenderTimeDisabled = useRef<boolean>(
    process.env.DISABLE_USE_RENDER_TIME === 'true'
  );

  useEffect(() => {
    if (useRenderTimeDisabled.current) {
      return;
    }

    if (!hasRendered.current) {
      if (
        typeof performance !== 'undefined' &&
        typeof performance.mark === 'function' &&
        typeof performance.measure === 'function'
      ) {
        hasRendered.current = true;
        const renderMarkName = `${componentName}-rendered`;
        performance.mark(renderMarkName);

        const measureName = `time-to-${componentName}`;
        performance.measure(measureName, 'app-start', renderMarkName);

        const measure = performance.getEntriesByName(measureName)[0];

        performance.clearMarks(renderMarkName);
        performance.clearMeasures(measureName);
        datadogRum.addAction(`rendered_${componentName}`, {
          duration: measure.duration,
        });
      } else {
        datadogRum.addAction(`rendered_${componentName}`, {
          message: 'performance is not available',
        });
      }
    }
  }, []);
};

export default useRenderTime;
