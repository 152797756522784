import React from 'react';

import { imageUrl } from '../../helpers/formatters';
import { WhiteLabelType } from '../../types/WhiteLabelType';
import { MjmlSection, MjmlColumn, MjmlImage } from '../MjmlComponentsV2';
import componentsByWhitelabel from './whitelabels';

type EmailHeaderProps = {
  whiteLabel: WhiteLabelType;
  i18n: any;
};

export default function EmailHeader({ whiteLabel, i18n }: EmailHeaderProps) {
  const WhitelabeledComponents = componentsByWhitelabel[whiteLabel.instance];

  if (WhitelabeledComponents) {
    return (
      <WhitelabeledComponents.Header whiteLabel={whiteLabel} i18n={i18n} />
    );
  }

  return (
    <MjmlSection paddingTop={50}>
      <MjmlColumn width={150}>
        <MjmlImage width={150} src={imageUrl(whiteLabel.profile.logo)} />
      </MjmlColumn>
      <MjmlColumn width={425} />
    </MjmlSection>
  );
}
