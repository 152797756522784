import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class UnitNameMinimumCharacterError extends CodedError {
  static get errorName() {
    return errorCodeTypes.unitNameMinimumCharacterError.code;
  }

  constructor(message = errorCodeTypes.unitNameMinimumCharacterError.message) {
    super(message);
    this.code = errorCodeTypes.unitNameMinimumCharacterError.code;
    this.message = `${message} ${errorCodeTypes.unitNameMinimumCharacterError.code}`;
    this.name = errorCodeTypes.unitNameMinimumCharacterError.name;
  }
}
