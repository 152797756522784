import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import { Tooltip } from 'components/general';
import { H3 } from 'components/typography';

import { getBaseFontSize } from '../../helpers';

import styles from './DashboardWidget.scss';

// charts need a specific px height set, but we use ems.
const BASE_WIDTH = 19;
const BASE_HEIGHT = 11;

type OwnProps = {
  Header?: React.ReactNode;
  rows?: number;
  cols?: number;
  className?: string;
  style?: React.CSSProperties;
  tooltipText?: string;
  Menu: React.ReactNode;
  children: React.ReactNode;
};

type Props = OwnProps;

function DashboardWidget({
  className,
  style,
  children,
  Header = null,
  Menu,
  tooltipText,
  rows = 1,
  cols = 1,
}: Props) {
  return (
    <div
      className={cx(styles.DashboardWidget, className)}
      style={style}
      data-rows={rows}
      data-cols={cols}
    >
      <section className={styles.header}>
        {Header && (
          <div className={styles.headerTitleContainer}>
            <H3 bold mb={2}>
              {Header}
            </H3>
            {tooltipText && (
              <div className={styles.headerTooltipContainer}>
                <Tooltip
                  className={styles.headerTooltip}
                  wrapperClassName={styles.headerTooltipWrapper}
                  placement="right"
                  TooltipComponent={tooltipText}
                >
                  <Icon set={ICON_SET_FONTAWESOME} name="question-circle" />
                </Tooltip>
              </div>
            )}
          </div>
        )}
        {Menu}
      </section>
      <div className={styles.contents}>{children}</div>
    </div>
  );
}

// calculate the width of this widget using the baseFontSize of the page
DashboardWidget.getWidth = (cols: any) => getBaseFontSize() * cols * BASE_WIDTH;
// calculate the height of this widget using the baseFontSize of the page
DashboardWidget.getHeight = (rows: any) =>
  getBaseFontSize() * rows * BASE_HEIGHT;

export default DashboardWidget;
