import gql from 'graphql-tag';

export default gql`
  query getScheduledVisitsByHostUserId(
    $startDate: DateTime
    $endDate: DateTime
  ) {
    scheduledVisitsByHostUserId(startDate: $startDate, endDate: $endDate) {
      result {
        visits {
          id
          visitor {
            firstName
            lastName
            company
            email
          }
          accessGrantBuildingId
          hostName
          tenantName
          floor
          companyName
          status
          visitorNote
          staffNote
          startDatetime
          endDatetime
          submittedBy
          eventName
          createdAtDatetime
          sourceId
          submissionId
          validStateTransitions
          recurrenceId
        }
        eventNames
        tenantNames
      }
      totalCount
    }
  }
`;
