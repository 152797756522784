import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';

export type HostType = {
  _id: string;
  name: string;
  profile: {
    email: string;
    image: string;
  };
};

export type TenantChannelType = {
  _id: string;
  name: string;
  address: {
    street1: string;
    street2: string;
    street3: string;
    city: string;
    state: string;
    country: string;
  };
  profile: {
    name: string;
  };
};

type getUserResponse = {
  user: HostType;
};

type getChannelResponse = {
  channel: TenantChannelType;
};

const getUserQuery = gql`
  query getUser($id: UUID!) {
    user(_id: $id) {
      _id
      name
      profile {
        email
        image
      }
    }
  }
`;

const getChannelQuery = gql`
  query getChannelForVisitorManagement($id: UUID!) {
    channel(_id: $id) {
      _id
      name
      address {
        street1
        street2
        street3
        city
        state
        country
      }
      profile {
        name
      }
    }
  }
`;

export const fetchHostAndTenantInformation = async (
  hostId: string,
  tenantId: string
) => {
  const client = getClient();
  const userRequest = client.query<getUserResponse>({
    query: getUserQuery,
    variables: {
      id: hostId,
    },
  });

  const tenantChannelRequest = client.query<getChannelResponse>({
    query: getChannelQuery,
    variables: {
      id: tenantId,
    },
  });

  const [host, tenantChannel] = await Promise.all([
    userRequest,
    tenantChannelRequest,
  ]);

  return {
    host: host.data.user,
    tenantChannel: tenantChannel.data.channel,
  };
};
