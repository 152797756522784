import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class InviteClaimedError extends CodedError {
  static get errorName() {
    return errorCodes.inviteClaimedError.code;
  }

  constructor(message = errorCodes.inviteClaimedError.message) {
    super(message);
    this.code = errorCodes.inviteClaimedError.code;
    this.message = `${message} ${errorCodes.inviteClaimedError.code}`;
    this.name = errorCodes.inviteClaimedError.name;
  }
}
