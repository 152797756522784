import React, { useState, useMemo, useContext } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { getSubmitInteractionErrorMessage } from 'lane-shared/helpers/content';
import {
  AttachmentImageContentTypeEnum,
  AttachmentDocumentContentTypeEnum,
  AttachmentResponse,
  AttachmentPreview,
} from 'lane-shared/types/attachment';
import { validateUploadableAsset } from 'lane-shared/validation/validateUploadableAsset';

import { AttachmentThumbnail } from 'components/cards';
import { AttachmentPreviewModal } from 'components/cards/AttachmentPreviewModal';
import { FileInput } from 'components/form';
import { Button, Loading } from 'components/general';
import { M, XS } from 'components/typography';

import {
  AttachmentContext,
  AttachmentDispatchContext,
} from '../../contexts/AttachmentContext';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import { useAttachmentByEntityData } from 'hooks/useAttachmentByEntityData';
import { useAttachmentDelete } from 'hooks/useAttachmentDelete';

import styles from './AddAttachment.scss';

type Props = {
  onChange?: (prop: any) => void;
  entityId: string;
  editMode?: boolean;
};

export function AttachmentContainer({
  onChange,
  entityId,
  editMode = true,
}: Props) {
  const { t } = useTranslation();
  const attachmentsFromContext = useContext(AttachmentContext);
  const dispatch = useContext(AttachmentDispatchContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const {
    uploadableAssetMaxFileSizeValidator,
    attachmentMaxFilesValidator,
  } = validateUploadableAsset(t);

  const attachmentContextId: string = useMemo(() => {
    return entityId || uuid();
  }, [entityId]);

  const { loading, fetchAttachments, attachments } = useAttachmentByEntityData({
    entityId,
    editMode: false,
  });

  const { deleteAttachmentHandler } = useAttachmentDelete({
    fetchAttachments,
  });

  const attachmentsByField = [
    ...attachments,
    ...attachmentsFromContext.filter(
      (i: any) => i.entityId === attachmentContextId
    ),
  ];

  const acceptedFileTypes = Object.values({
    ...AttachmentImageContentTypeEnum,
    ...AttachmentDocumentContentTypeEnum,
  }).toString();

  const defaultCallback = (
    currentAttachments: Array<AttachmentResponse | AttachmentPreview>
  ) => {
    if (onChange instanceof Function) {
      const onChangeParams = {
        customGeneratedId: attachmentContextId,
        attachments: currentAttachments,
      };
      onChange(onChangeParams);
    }
  };

  const onAddCallback = async (
    items: Array<AttachmentResponse> | Array<AttachmentPreview>
  ) => {
    try {
      const totalAttachmentsIncludingNew = [
        ...attachmentsByField,
        ...items,
      ] as Array<AttachmentPreview | AttachmentResponse>;
      await attachmentMaxFilesValidator.validate([
        ...attachmentsByField,
        ...items,
      ]);
      dispatch({
        type: 'added',
        payload: items,
      });
      return defaultCallback(totalAttachmentsIncludingNew);
    } catch (e) {
      onError(e);
    }
  };

  const onDeleteCallback = async (id: string) => {
    const filteredAttachments = attachmentsByField.filter(
      (item: any) => item.id !== id
    );
    dispatch({
      type: 'deleted',
      id,
    });

    // Deleting saved attachment from database
    if (entityId && attachments.find(a => a.id === id)) {
      await deleteAttachmentHandler(id);
    }

    // Resetting the modal state when there is nothing left to show
    if (filteredAttachments.length === 0) {
      setModalVisible(false);
      setCurrentModalIndex(0);
    }
    return defaultCallback(filteredAttachments);
  };
  const onPreviewModalCallback = (index: number) => {
    setCurrentModalIndex(index);
    setModalVisible(true);
  };

  const handlePreviousAttachment = () => {
    const lastItem = attachmentsByField.length - 1;
    const previousItem = currentModalIndex! - 1;
    const startPoint = currentModalIndex === 0;
    setCurrentModalIndex(startPoint ? lastItem : previousItem);
  };

  const handleNextAttachment = () => {
    const nextItem = currentModalIndex! + 1;
    const lastItem = attachmentsByField.length - 1;
    const firstItem = 0;
    const endPoint = currentModalIndex === lastItem;
    setCurrentModalIndex(endPoint ? firstItem : nextItem);
  };

  const attachmentsSelectedHandler = async (files: File[]) => {
    try {
      for (const file of files) {
        await uploadableAssetMaxFileSizeValidator.validate(file.size);
      }

      const selectedFiles = files.map(
        (file: File): AttachmentPreview => {
          return {
            id: uuid(),
            entityId: attachmentContextId,
            fileUrl: URL.createObjectURL(file),
            file,
          };
        }
      );

      onAddCallback(selectedFiles);
    } catch (e) {
      onError(e);
    }
  };

  function onError(err: any) {
    const { title } = getSubmitInteractionErrorMessage(err);

    window.Alert.alert({ title: t(title), error: err });
  }

  return (
    <div>
      {editMode && (
        <div data-test="attachmentFileInput" data-has-file="file">
          <FileInput
            testId="attachmentFileInputComponent"
            accept={acceptedFileTypes}
            type={FileReturnTypeEnum.File}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(files: any) => Promise<void>' is not assign... Remove this comment to see the full error message
            onFilesSelected={attachmentsSelectedHandler}
            enableMultiUpload
          >
            <Button className={styles.buttonAttachments}>
              <div className={styles.row}>
                <div className={styles.attachmentsIconContainer}>
                  <Icon
                    className={styles.emptyImageIcon}
                    set={ICON_SET_FONTAWESOME}
                    name="paperclip"
                    type="fal"
                    style={{ width: '1.5em', height: '1.5em' }}
                  />
                </div>
                <div className={styles.attachmentsTextContainer}>
                  <div className={styles.clickToUploadContainer}>
                    <M className={styles.buttonAttachmentsLabel}>
                      {t('web.components.lane.Attachments.upload.text')}
                    </M>
                  </div>
                  <div>
                    <XS className={styles.buttonAttachmentsLabel}>
                      {t('web.components.lane.Attachments.upload.fileTypes')}
                    </XS>
                  </div>
                  <div>
                    <XS className={styles.buttonAttachmentsLabel}>
                      {t('web.components.lane.Attachments.upload.fileLimits')}
                    </XS>
                  </div>
                </div>
              </div>
            </Button>
          </FileInput>
        </div>
      )}

      {loading && (
        <div className={styles.loader}>
          <Loading />
        </div>
      )}

      {attachmentsByField.length > 0 && (
        <div className={styles.attachmentThumbnail} data-test="attachmentList">
          {attachmentsByField.map(
            (
              attachment: AttachmentResponse | AttachmentPreview,
              index: number
            ) => {
              return (
                <div key={index} className={styles.attachmentThumbnailItem}>
                  <AttachmentThumbnail
                    attachment={attachment}
                    deleteAttachmentHandler={onDeleteCallback}
                    preventDelete={!editMode}
                    onPreviewModal={() => onPreviewModalCallback(index)}
                  />
                </div>
              );
            }
          )}
        </div>
      )}

      {currentModalIndex !== null && attachmentsByField.length > 0 && (
        <AttachmentPreviewModal
          attachment={attachmentsByField[currentModalIndex]!}
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          onDelete={onDeleteCallback}
          onPrevious={handlePreviousAttachment}
          onNext={handleNextAttachment}
          isDeletable={editMode}
        />
      )}
    </div>
  );
}
