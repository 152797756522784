import gql from 'graphql-tag';

import { MetatagFragment } from '../fragments';

export default gql`
  ${MetatagFragment}

  query getMetatagsOnChannel(
    $channelId: UUID!
    $search: MetatagSearch
    $pagination: PaginationInput
  ) {
    metatagsOnChannel(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        metatag {
          ...MetatagFragment
        }
      }
    }
  }
`;
