import gql from 'graphql-tag';

export const updateUserGrantSuper = gql`
  mutation grantSuper($userId: UUID!) {
    updateUserGrantSuper(userId: $userId) {
      _id
      isSuperUser
    }
  }
`;
