import gql from 'graphql-tag';

export default gql`
  mutation enableMobileAccess(
    $channelId: UUID!
    $userClearances: [UserClearanceInput!]!
    $connectedChannelIds: [UUID]
  ) {
    enableMobileAccess(
      channelId: $channelId
      userClearances: $userClearances
      connectedChannelIds: $connectedChannelIds
    ) {
      status
      userId
    }
  }
`;
