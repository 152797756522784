import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class ContentStartedError extends CodedError {
  static get errorName() {
    return errorCodes.contentStartedError.code;
  }

  constructor(message = errorCodes.contentStartedError.message) {
    super(message);
    this.code = errorCodes.contentStartedError.code;
    this.message = `${message} ${errorCodes.contentStartedError.code}`;
    this.name = errorCodes.contentStartedError.name;
  }
}
