import { gql } from '@apollo/client';

export const getFinalPriceForProducts = gql`
  query getFinalPriceForProducts(
    $getFinalPriceForProductsRequest: GetFinalPriceForProductsRequest!
  ) {
    getFinalPriceForProducts(
      getFinalPriceForProductsRequest: $getFinalPriceForProductsRequest
    ) {
      productFinalPrices {
        productId
        exceptionId
        finalPrice {
          amount
          markup {
            value
            markupAmountType
          }
          tax {
            value
            taxAmountType
          }
        }
      }
    }
  }
`;
