import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import Badge from 'components/general/Badge';

import { QuantityFeatureProperties } from 'lane-shared/types/features/QuantityFeatureProperties';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { useQuantityAllotments } from 'lane-shared/hooks/contentState';

import styles from './styles.scss';

export type QuantityContent = {
  _id: LaneType.UUID;
  features: Array<{
    feature: QuantityFeatureProperties;
    type: FeatureNameEnum.Quantity;
  }>;
  state: {
    Quantity: {
      total?: number;
      quantityAllocations: Array<{
        units: number;
        channelIds: string[];
        forPrimaryChannelUsersOnly: boolean;
        canClaimBeyondAllocatedUnits: boolean;
      }>;
    };
  };
};

type Props<T> = {
  content: T;
  quantityClaimed?: number;
};

export function QuantityAdvancedRulesBadge<T extends QuantityContent>({
  content,
  quantityClaimed,
}: Props<T>) {
  const { t } = useTranslation();
  const {
    fetchAllotments,
    ruleAllotments,
    ruleMaxAllottedUnits,
  } = useQuantityAllotments(content?._id);
  const { total } = content.state.Quantity;
  const contentQuantity = content.features?.find(
    f => f.type === FeatureNameEnum.Quantity
  );
  const quantityAllocations = contentQuantity?.feature?.quantityAllocations;
  const contentId = content?._id;
  const hasQuantityAdvancedRules = !!quantityAllocations?.length;

  useEffect(() => {
    const fetchRuleAllotments = async () => {
      try {
        await fetchAllotments();
      } catch (error) {
        // ignore
      }
    };

    fetchRuleAllotments();
  }, [contentId]);

  return (
    <>
      <Badge
        key="totalUnitsClaimed"
        title={t('web.content.features.quantity.totalUnitsClaimed')}
        value={`${quantityClaimed ?? 0} / ${total}`}
      />
      {hasQuantityAdvancedRules ? (
        <Badge
          key="allotedUnits"
          title={t('web.content.features.quantity.unitsAlloted')}
          value={`${ruleAllotments} / ${ruleMaxAllottedUnits}`}
          className={styles.allotedUnits}
        />
      ) : null}
    </>
  );
}
