import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { createShapeFromProperties } from '../../../properties';
import Types from '../../../properties/Types';
import { ObjectTypeBase } from '../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../types/properties/Property';

export interface HostInformationType {
  hostId?: string;
  hostTenantId?: string;
  floor?: string;
}

export interface HostType {
  id: string;
  username: string;
}

export interface TenantType {
  id: string;
  name: string;
}

const properties: PropertiesInterface = {
  hostId: {
    name: 'Host',
    type: 'UUID',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  hostTenantId: {
    name: 'Tenant company',
    type: 'UUID',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  floor: {
    name: 'Floor',
    type: 'String',
    editable: true,
    default: '',
    validators: [],
  },
};

export class HostInformationObjectType extends ObjectTypeBase {
  name = 'HostInformationType';

  friendlyName = 'Host information for visitor passes';

  properties = properties;

  contexts = [];

  get example(): HostInformationType {
    return {
      hostId: uuid(),
      hostTenantId: uuid(),
      floor: '1',
    };
  }

  get default(): any {
    return {};
  }

  buildSchema() {
    const shape = createShapeFromProperties(properties);
    return yup.object().shape(shape).nullable();
  }
}

const definition = new HostInformationObjectType();

Types.addType(definition);
export default definition;
