import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  CreateUserResponse,
  CreateUserRequest,
  ExternalUserSource,
} from 'lane-shared/domains/billingPayments/types';

const createUserMutation = gql`
  mutation createUser($createUserRequest: CreateUserRequest!) {
    accounts {
      createUser(createUserRequest: $createUserRequest) {
        accountsUserId
        externalUserId
        paymentProcessorCustomerId
      }
    }
  }
`;

export async function createAccountsUser(
  externalUserId: string
): Promise<CreateUserResponse> {
  const { data } = await getClient().mutate({
    mutation: createUserMutation,
    variables: {
      createUserRequest: {
        externalUserId,
        externalUserSource: ExternalUserSource.EXTERNAL_USER_SOURCE_ACTIVATE,
      } as CreateUserRequest,
    },
  });

  return data.accounts.createUser;
}
