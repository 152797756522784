import {
  ChannelTypeEnum,
  ChannelExperienceTypeEnum,
} from '../../types/ChannelType';
import { deriveResidentPermissionGroupName } from '../channel/deriveResidentPermissionGroupName';

import * as events from './events';
import * as permissions from './permissions';
import * as requirements from './requirements';

type GroupRolePermissionsSetType = {
  permissions: string[];
  requirements: string[];
  isSystem?: boolean;
};

type ChannelGroupRolePermissionsSetType = Partial<
  Record<STANDARD_GROUP_ROLES_NAMES, GroupRolePermissionsSetType>
>;

type ChannelExperiencePermissionSetType = Partial<
  Record<string, GroupRolePermissionsSetType>
>;

export enum CHANNEL_DIRECTORY_TYPE {
  Retail = 'Retail',
  Office = 'Office',
  User = 'User',
}

export const CHANNEL_DIRECTORY_TYPES = Object.values(CHANNEL_DIRECTORY_TYPE);

export enum CHANNEL_DIRECTORY_LIST_VIEW {
  Thumbnail = 'Thumbnail',
  List = 'ListView',
}
export const CHANNEL_DIRECTORY_LIST_VIEWS = Object.values(
  CHANNEL_DIRECTORY_LIST_VIEW
);

export enum SPECIAL_GROUP_ROLES {
  ADMIN = 'Admin',
  WORKPLACE_MEMBER = 'Workplace Member',
  ACCESS_MANAGER = 'Access admin',
  COMPANY_ACCESS_ADMIN = 'Access admin (company channel)',
}

export enum STANDARD_GROUP_ROLES_NAMES {
  MARKETING = 'Marketing',
  RETAIL_MANAGER = 'Retail Manager',
  WORKPLACE_ADMIN = 'Workplace Admin',
  WORKPLACE_MEMBER = 'Workplace Member',
}

export enum MULTIFAMILY_USER_TYPE_NAMES {
  PRIMARY_RESIDENT = 'Primary resident',
  SECONDARY_RESIDENT = 'Secondary resident',
  GUARANTOR = 'Guarantor',
}

export enum MULTIFAMILY_GROUP_ROLES_NAMES {
  ADMIN = 'Admin',
  PROPERTY_STAFF = 'Property staff',
  FUTURE_RESIDENT = 'Future',
  CURRENT_RESIDENT = 'Current',
  RESIDENT = 'Resident',
  PAST_RESIDENT = 'Past',
  NOTICE = 'Notice',
  EVICTION = 'Under eviction',
}
// setup special group role permissions for Admin and Workplace Member
export const SPECIAL_GROUP_ROLE_PERMISSIONS: Readonly<
  Record<SPECIAL_GROUP_ROLES, string[]>
> = Object.freeze({
  [SPECIAL_GROUP_ROLES.ADMIN]: [permissions.PERMISSION_ADMIN],
  [SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER]: [
    permissions.PERMISSION_CONTENT_PROMOTION_READ,
    permissions.PERMISSION_CONTENT_READ,
    permissions.PERMISSION_USERS_WORKPLACE_MEMBERS_INVITE,
    permissions.PERMISSION_SECTIONS_READ,
  ],
  [SPECIAL_GROUP_ROLES.ACCESS_MANAGER]: [
    permissions.PERMISSION_ACCESS_CONTROL,
    permissions.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
    permissions.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
    permissions.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN,
    permissions.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE,
    permissions.PERMISSION_PORTAL_ACCESS,
    permissions.PERMISSION_PORTAL_ACCESS_MOBILE,
    permissions.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    permissions.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
    permissions.PERMISSION_ACCESS_CONTROL_HID,
    permissions.PERMISSION_ACCESS_CONTROL_CCURE,
    permissions.PERMISSION_USERS_DELETE,
    permissions.PERMISSION_USERS_READ,
    permissions.PERMISSION_USERS_INVITE,
    permissions.PERMISSION_USERS_INFO,
    permissions.PERMISSION_USERS_SEARCH,
  ],
  [SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN]: [
    permissions.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    permissions.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
    permissions.PERMISSION_ACCESS_CONTROL_HID,
    permissions.PERMISSION_ACCESS_CONTROL_CCURE,
  ],
});

export const SPECIAL_GROUP_ROLE_REQUIREMENTS: Readonly<
  Partial<Record<SPECIAL_GROUP_ROLES, string[]>>
> = Object.freeze({
  [SPECIAL_GROUP_ROLES.ADMIN]: [
    requirements.REQUIREMENT_USER_PRESENCE,
    requirements.REQUIREMENT_USER_LOCATION,
    requirements.REQUIREMENT_PROFILE_NAME,
    requirements.REQUIREMENT_PROFILE_EMAIL,
    requirements.REQUIREMENT_PROFILE_ADDRESS,
    requirements.REQUIREMENT_PROFILE_PHONE,
    requirements.REQUIREMENT_NOTIFICATION_EMAIL,
    requirements.REQUIREMENT_NOTIFICATION_PUSH,
  ],
  [SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER]: [
    requirements.REQUIREMENT_USER_PRESENCE,
    requirements.REQUIREMENT_USER_LOCATION,
    requirements.REQUIREMENT_PROFILE_NAME,
  ],
});

// standard event subscriptions we should sign up people to.
export const STANDARD_EVENT_SUBSCRIPTIONS = Object.freeze([
  events.EVENT_CONTENT_NOTIFICATION,
  events.EVENT_CONTENT_STARTED,
  events.EVENT_CONTENT_ENDED,
  events.EVENT_CONTENT_INTERACTION_STARTED,
  events.EVENT_CONTENT_INTERACTION_ENDED,
  events.EVENT_CONTENT_INTERACTION_CREATED,
  events.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  events.EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  events.EVENT_CONTENT_INTERACTION_UPDATED,
  events.EVENT_CONTENT_INTERACTION_DELETED,
]);

// create some standard group roles that will be added to a channel on create
export const STANDARD_GROUP_ROLES: ChannelGroupRolePermissionsSetType = Object.freeze(
  {
    [STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]: {
      permissions: [
        permissions.PERMISSION_ANALYTICS,
        permissions.PERMISSION_AUDIENCE_CREATE,
        permissions.PERMISSION_AUDIENCE_DELETE,
        permissions.PERMISSION_AUDIENCE_READ,
        permissions.PERMISSION_AUDIENCE_UPDATE_MANAGE,
        permissions.PERMISSION_AUDIENCE_UPDATE_USE,
        permissions.PERMISSION_PORTAL_ACCESS,
        permissions.PERMISSION_CHANNEL_RELATIONSHIP_READ,
        permissions.PERMISSION_CHANNEL_RELATIONSHIP_UPDATE,
        permissions.PERMISSION_CHANNEL_RELATIONSHIP_CREATE,
        permissions.PERMISSION_CHANNEL_RELATIONSHIP_DELETE,
        permissions.PERMISSION_CONTENT_CREATE,
        permissions.PERMISSION_CONTENT_PUBLISH,
        permissions.PERMISSION_CONTENT_UPDATE,
        permissions.PERMISSION_CONTENT_READ,
        permissions.PERMISSION_CONTENT_DELETE,
        permissions.PERMISSION_CONTENT_INTERACTIONS_READ,
        permissions.PERMISSION_CONTENT_INTERACTIONS_UPDATE,
        permissions.PERMISSION_CONTENT_INTERACTIONS_DELETE,
        permissions.PERMISSION_DOCUMENTS_CREATE,
        permissions.PERMISSION_DOCUMENTS_DELETE,
        permissions.PERMISSION_DOCUMENTS_READ,
        permissions.PERMISSION_DOCUMENTS_UPDATE,
        permissions.PERMISSION_EMAIL_SEND,
        permissions.PERMISSION_EMAIL_CREATE,
        permissions.PERMISSION_GROUPS_CREATE,
        permissions.PERMISSION_GROUPS_UPDATE,
        permissions.PERMISSION_GROUPS_DELETE,
        permissions.PERMISSION_LIBRARY_CREATE,
        permissions.PERMISSION_LIBRARY_READ,
        permissions.PERMISSION_LIBRARY_DELETE,
        permissions.PERMISSION_LIBRARY_UPDATE,
        permissions.PERMISSION_MEDIA_DELETE,
        permissions.PERMISSION_MEDIA_READ,
        permissions.PERMISSION_MEDIA_CREATE,
        permissions.PERMISSION_MEDIA_UPDATE,
        permissions.PERMISSION_PROFILE_UPDATE,
        permissions.PERMISSION_SECTIONS_CREATE,
        permissions.PERMISSION_SECTIONS_DELETE,
        permissions.PERMISSION_SECTIONS_UPDATE,
        permissions.PERMISSION_SECTIONS_READ,
        permissions.PERMISSION_SECTIONS_FILTERS,
        permissions.PERMISSION_TEMPLATES_READ,
        permissions.PERMISSION_TEMPLATES_CREATE,
        permissions.PERMISSION_USERS_SEARCH,
        permissions.PERMISSION_USERS_DELETE,
        permissions.PERMISSION_USERS_INFO,
        permissions.PERMISSION_USERS_INVITE,
        permissions.PERMISSION_USERS_READ,
        permissions.PERMISSION_METATAG_READ,
        permissions.PERMISSION_ACCESS_CONTROL_HID,
        permissions.PERMISSION_ACCESS_CONTROL_CCURE,
        permissions.PERMISSION_PORTAL_ACCESS,
        permissions.PERMISSION_PORTAL_ACCESS_MOBILE,
        permissions.PERMISSION_ANALYTICS_ADVANCED,
        permissions.PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL,
        permissions.PERMISSION_VISITOR_MANAGEMENT_LOGS_READ,
        permissions.PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL,
        permissions.PERMISSION_VISITOR_MANAGEMENT_LOGS_PRINT_VISITOR_PASS,
        permissions.PERMISSION_CHANNEL_MODULE_UPDATE,
        permissions.PERMISSION_ADDRESS_UPDATE,
        permissions.PERMISSION_ANALYTICS_WORK_ORDERS,
        permissions.PERMISSION_CHARGE_CODE_MANAGE,
        permissions.PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE,
        permissions.PERMISSION_BILLING_PAYMENTS_COMPANY_RECURRING_CHARGES,
        permissions.PERMISSION_BILLING_PAYMENTS_CHARGE_VOID,
        permissions.PERMISSION_CREDITS_MANAGE,
        permissions.PERMISSION_PRODUCT_EXCEPTIONS_MANAGE,
        permissions.PERMISSION_COMPANY_INVOICES,
        permissions.PERMISSION_CAN_CANCEL_MEMBERSHIPS,
        permissions.PERMISSION_PRODUCTS_MANAGE,
        permissions.PERMISSION_GROUPS_READ,
        permissions.PERMISSION_PROPERTY_GUESTS_CREATE,
        permissions.PERMISSION_PROPERTY_GUESTS_DELETE,
        permissions.PERMISSION_PROPERTY_GUESTS_READ,
        permissions.PERMISSION_PROPERTY_GUESTS_UPDATE,
        permissions.PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE,
        permissions.PERMISSION_PROPERTY_MAINTENANCE_CREATE,
        permissions.PERMISSION_PROPERTY_MAINTENANCE_READ,
        permissions.PERMISSION_PROPERTY_MAINTENANCE_DELETE,
        permissions.PERMISSION_PROPERTY_MAINTENANCE_UPDATE,
        permissions.PERMISSION_PROPERTY_MANAGE_COMPANY,
        permissions.PERMISSION_PROPERTY_MANAGE_RETAIL,
        permissions.PERMISSION_RESERVATIONS_MODULE_ACCESS,
        permissions.PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE,
        permissions.PERMISSION_USERS_ROLE_INVITE,
        permissions.PERMISSION_USERS_ROLE_READ,
        permissions.PERMISSION_USERS_ROLE_SEARCH,
        permissions.PERMISSION_USERS_WORKPLACE_MEMBERS_READ,
        permissions.PERMISSION_USERS_WORKPLACE_MEMBERS_SEARCH,
        permissions.PERMISSION_WORK_ORDERS_MODULE_ACCESS,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
        permissions.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE,
        permissions.PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
        permissions.PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS,
        permissions.PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT,
        permissions.PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
        permissions.PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
        permissions.PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
        permissions.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM,
        permissions.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE,
        permissions.PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE,
        permissions.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS,
      ],
      requirements: [
        requirements.REQUIREMENT_NOTIFICATION_PUSH,
        requirements.REQUIREMENT_NOTIFICATION_EMAIL,
        requirements.REQUIREMENT_PROFILE_ADDRESS,
        requirements.REQUIREMENT_PROFILE_EMAIL,
        requirements.REQUIREMENT_PROFILE_NAME,
        requirements.REQUIREMENT_PROFILE_PHONE,
        requirements.REQUIREMENT_USER_PRESENCE,
        requirements.REQUIREMENT_USER_LOCATION,
      ],
    },
  }
);

export const CHANNEL_TYPE_GROUP_ROLE_MAP = new Map<
  ChannelTypeEnum,
  Readonly<ChannelGroupRolePermissionsSetType>
>();

// add in some extra permissions for property channels
CHANNEL_TYPE_GROUP_ROLE_MAP.set(ChannelTypeEnum.Property, {
  [STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]: {
    permissions: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.permissions ?? []),
      permissions.PERMISSION_PROPERTY_GUESTS_CREATE,
      permissions.PERMISSION_PROPERTY_GUESTS_READ,
      permissions.PERMISSION_PROPERTY_GUESTS_UPDATE,
      permissions.PERMISSION_PROPERTY_MANAGE_RETAIL,
      permissions.PERMISSION_PROPERTY_MANAGE_COMPANY,
      permissions.PERMISSION_PROPERTY_MAINTENANCE_CREATE,
      permissions.PERMISSION_PROPERTY_MAINTENANCE_READ,
      permissions.PERMISSION_PROPERTY_MAINTENANCE_UPDATE,
      permissions.PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE,
      permissions.PERMISSION_EQUIPMENT_CREATE,
      permissions.PERMISSION_EQUIPMENT_DELETE,
      permissions.PERMISSION_EQUIPMENT_READ,
      permissions.PERMISSION_EQUIPMENT_UPDATE,
    ],
    requirements: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.requirements ?? []),
    ],
  },

  [STANDARD_GROUP_ROLES_NAMES.RETAIL_MANAGER]: {
    permissions: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.permissions ?? []),
      permissions.PERMISSION_PROPERTY_MANAGE_RETAIL,
    ],
    requirements: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.requirements ?? []),
    ],
  },
});

export const CHANNEL_EXPERIENCE_TYPE_PERMISSION_GROUP_MAP = new Map<
  ChannelExperienceTypeEnum,
  Readonly<ChannelExperiencePermissionSetType>
>();

CHANNEL_EXPERIENCE_TYPE_PERMISSION_GROUP_MAP.set(
  ChannelExperienceTypeEnum.multifamily,
  {
    ...Object.entries(MULTIFAMILY_USER_TYPE_NAMES).reduce(
      // For each user type, generate permission groups for most Multifamily group roles
      (prev, [_, userType]) => {
        return {
          ...prev,
          // Permissions groups except admin and property staff will have CRE workplace member permissions
          // as a placeholder until permissions are decided by product later
          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.FUTURE_RESIDENT
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.CURRENT_RESIDENT
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.PAST_RESIDENT
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.NOTICE
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.EVICTION
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },
        };
      },
      {
        // Property staff and base resident groups are always included, and not replicated per user type
        [MULTIFAMILY_GROUP_ROLES_NAMES.PROPERTY_STAFF]: {
          permissions: [
            ...(CHANNEL_TYPE_GROUP_ROLE_MAP.get(ChannelTypeEnum.Property)?.[
              STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN
            ]?.permissions ?? []),
            permissions.PERMISSION_UNIT_MANAGE,
            permissions.PERMISSION_FLOOR_MANAGE,
          ],
          requirements: [
            ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
              ?.requirements ?? []),
          ],
        },
        [MULTIFAMILY_GROUP_ROLES_NAMES.RESIDENT]: {
          permissions:
            SPECIAL_GROUP_ROLE_PERMISSIONS[
              SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
            ],
          requirements: [
            ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
              SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
            ] ?? []),
          ],
        },
      }
    ),
  }
);

export const CHILDREN_NAMES = {
  [ChannelTypeEnum.Property]: 'Properties',
  [ChannelTypeEnum.Company]: 'Locations',
  [ChannelTypeEnum.Retail]: 'Locations',
  [ChannelTypeEnum.Service]: 'Locations',
  [ChannelTypeEnum.Entertainment]: 'Locations',
  [ChannelTypeEnum.Restaurant]: 'Locations',
  [ChannelTypeEnum.Professional]: 'Chapters',
  [ChannelTypeEnum.Charity]: 'Locations',
  [ChannelTypeEnum.Meta]: 'Meta',
  [ChannelTypeEnum.Curated]: 'Magazine',
};

export const CHILDREN_NAMES_SINGULAR: Readonly<
  Record<ChannelTypeEnum, string>
> = {
  [ChannelTypeEnum.Property]: 'Property',
  [ChannelTypeEnum.Company]: 'Location',
  [ChannelTypeEnum.Retail]: 'Location',
  [ChannelTypeEnum.Service]: 'Location',
  [ChannelTypeEnum.Entertainment]: 'Location',
  [ChannelTypeEnum.Restaurant]: 'Location',
  [ChannelTypeEnum.Professional]: 'Chapter',
  [ChannelTypeEnum.Charity]: 'Location',
  [ChannelTypeEnum.Meta]: 'Meta',
  [ChannelTypeEnum.Curated]: 'Magazine',
};

export const TENANT_TYPES: ChannelTypeEnum[] = [
  ChannelTypeEnum.Retail,
  ChannelTypeEnum.Restaurant,
  ChannelTypeEnum.Company,
  ChannelTypeEnum.Service,
  ChannelTypeEnum.Entertainment,
  ChannelTypeEnum.Professional,
  ChannelTypeEnum.Charity,
];

export const PROPERTY_TYPES: ChannelTypeEnum[] = [ChannelTypeEnum.Property];

export const OFFICE_TYPES: ChannelTypeEnum[] = [
  ChannelTypeEnum.Company,
  ChannelTypeEnum.Professional,
  ChannelTypeEnum.Charity,
];

export const RETAIL_TYPES: ChannelTypeEnum[] = [
  ChannelTypeEnum.Retail,
  ChannelTypeEnum.Restaurant,
  ChannelTypeEnum.Service,
  ChannelTypeEnum.Entertainment,
];

export enum CHANNEL_DATA_TAGS {
  demo = 'demo',
  contentDistribution = 'content-distribution',
  physicalBuilding = 'physical-building',
  flexCompany = 'flex-company',
}

export function getSingularChildrenName(parentType: ChannelTypeEnum): string {
  return CHILDREN_NAMES_SINGULAR[parentType] || '';
}

export function getChildrenName(parentType: ChannelTypeEnum): string {
  return CHILDREN_NAMES[parentType] || '';
}
