import React, { useContext, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { AppContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { useWhitelabelQuery } from 'lane-shared/hooks';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import ErrorMessage from 'components/general/ErrorMessage';
import Loading from 'components/general/Loading';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';

import styles from './WhitelabelSelectorButton.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  showSearch?: boolean;
  whitelabelId?: string | null;
  storageKey?: string;
  disableStorage?: boolean;
  onWhitelabelSelected: (whitelabel: WhiteLabelType | null) => void;
};

type Props = OwnProps;

export default function WhitelabelSelectorButton({
  className,
  style,
  disabled,
  whitelabelId = null,
  onWhitelabelSelected = () => null,
  storageKey = 'WhitelabelSelectorButton',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disableStorage = false,
}: Props) {
  const { t } = useTranslation();
  const { whitelabels } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const { whitelabel, loading, error } = useWhitelabelQuery({
    whitelabelId,
  });

  function render() {
    if (whitelabelId) {
      return (
        <>
          <span>{getDisplayName(whitelabel)}</span>
          <Icon
            className={styles.remove}
            name="times"
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: any) => void' is not assignable to type ... Remove this comment to see the full error message
            onClick={(e: any) => {
              if (disabled) {
                return;
              }

              onWhitelabelSelected(null);
              e.stopPropagation();
            }}
          />
        </>
      );
    }

    return (
      <>
        <Icon className={styles.search} name={t('search')} />
        <span>{t('Select a Whitelabel')}</span>
      </>
    );
  }

  return (
    <>
      <div
        className={cx(styles.WhitelabelSelectorButton, className)}
        data-is-selected={!!whitelabelId}
        role="button"
        data-test="whitelabelSelectorButton"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => !disabled && setIsOpen(true)}
        style={style}
      >
        <ErrorMessage error={error} />

        {whitelabelId && loading && !whitelabel && (
          <Loading className={styles.loader} />
        )}

        {render()}
      </div>
      <ModalBackground
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        className={styles.background}
      >
        <ResizableWindow
          name={storageKey}
          className={styles.window}
          onClose={() => setIsOpen(false)}
          defaultPosition={ResizableWindow.centerPosition()}
          showHeader
        >
          {whitelabels.map(whitelabel => (
            <button
              key={whitelabel._id}
              onClick={() => {
                onWhitelabelSelected(whitelabel);
                setIsOpen(false);
              }}
            >
              {getDisplayName(whitelabel)}
            </button>
          ))}
        </ResizableWindow>
      </ModalBackground>
    </>
  );
}
