import gql from 'graphql-tag';

export default gql`
  mutation RedeemOneTimePassword($otp: String!) {
    redeemOneTimePassword(otp: $otp) {
      token
      jti
    }
  }
`;
