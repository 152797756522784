import React, { forwardRef } from 'react';

import { Button } from 'design-system-web';

export type ButtonVariants =
  | 'outlined'
  | 'outlined-light'
  | 'contained'
  | 'activate-contained'
  | 'contained-dark'
  | 'text'
  | 'text-icon'
  | 'icon';

type Props = {
  className?: string;
  labelClassName?: string;
  style?: React.CSSProperties;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: ButtonVariants;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  color?: 'inherit' | 'default' | 'tertiary';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  testId?: string;
  borderRadius?: 'round' | 'square';
  type?: 'button' | 'reset' | 'submit' | undefined;
  children?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  interfaceStyle?: 'light' | 'dark';
  isPreview?: boolean;
};

const variantMap = {
  outlined: 'secondary',
  'outlined-light': 'secondary',
  contained: 'primary',
  'contained-dark': 'primary',
  'activate-contained': 'primary',
  text: 'text',
  'text-icon': 'text',
  icon: 'text',
} as const;

// TODO: remove this component once all implementations are replaced with Button from design-system-web

/**
 * @deprecated This component is deprecated - use `Button` exported from `design-system-web` instead.
 */
const LegacyButton = forwardRef<HTMLButtonElement | null, Props>(
  (
    {
      onClick = () => null,
      children,
      disabled = false,
      startIcon,
      endIcon,
      loading = false,
      fullWidth,
      className,
      style,
      variant = 'outlined',
      color,
      testId,
      type = 'button',
      size = 'medium',
      interfaceStyle = 'light',
    },
    ref
  ) => {
    const mappedSize = size === 'small' ? 'medium' : 'large';
    const mappedVariant =
      color === 'tertiary' ? 'tertiary' : variantMap[variant];

    return (
      <Button
        {...{
          ref,
          onClick,
          children,
          disabled,
          startIcon,
          endIcon,
          loading,
          fullWidth,
          className,
          style,
          variant: mappedVariant,
          testId,
          type,
          size: mappedSize,
          interfaceStyle,
        }}
      >
        {children}
      </Button>
    );
  }
);

export default LegacyButton;
