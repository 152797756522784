import React from 'react';

import { Button, ButtonProps } from '../Button/Button';
import { Icon } from '../Icon';
import { PopupMenu, MenuItemType } from './PopupMenu';

type OwnProps = {
  zIndex?: number;
  items: MenuItemType[];
};

type Props = OwnProps & Omit<ButtonProps, 'endIcon' | 'onClick'>;

export const PopupButton = ({
  zIndex,
  items,
  children,
  ...buttonProps
}: Props) => {
  return (
    <PopupMenu
      zIndex={zIndex}
      trigger={({ isOpen, ...triggerProps }) => (
        <Button
          endIcon={<Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />}
          {...buttonProps}
          {...triggerProps}
        >
          {children}
        </Button>
      )}
      items={items}
    />
  );
};

export default PopupButton;
