import { createContext, useContext } from 'react';

import { SignUpContextType } from './SignUpContextType';
import defaultSignUpContext from './defaultSignUpContext';

const SignUpContext = createContext<SignUpContextType>(defaultSignUpContext);

export function useSignUpContext() {
  return useContext(SignUpContext);
}

export default SignUpContext;
