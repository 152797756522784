import React from 'react';

import { useTranslation } from 'react-i18next';

import { getValidationMessages } from 'lane-shared/helpers';
import { SubtitleOptions } from 'lane-shared/helpers/content/subtitleHelpers';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';

import CardContainer from 'components/cards/CardContainer';
import { Toggle } from 'components/form';
import { TimeIntervals, DatePickerButton } from 'design-system-web';

import Label from '../../../general/Label';
import TimeZoneDropdown from '../../TimeZoneDropdown';

import styles from '../DraftInfo.scss';
import type { StepperTrackerType } from 'lane-shared/hooks/analytics/useDraftContentAnalytics';

const TRANSLATION_KEYS = {
  contentTemplateMessage:
    'web.admin.content.draftContent.info.draftContentEvent.contentTemplateMessage',
  toggleText:
    'web.admin.content.draftContent.info.draftContentEvent.toggles.isEvent',
  timezoneHeader:
    'web.admin.content.draftContent.info.draftContentEvent.headers.timezone',
  startDateHeader:
    'web.admin.content.draftContent.info.draftContentEvent.headers.startDate',
  endDateHeader:
    'web.admin.content.draftContent.info.draftContentEvent.headers.endDate',
  startDateLabel:
    'web.admin.content.draftContent.info.draftContentEvent.labels.startDate',
  startTimeLabel:
    'web.admin.content.draftContent.info.draftContentEvent.labels.startTime',
  endDateLabel:
    'web.admin.content.draftContent.info.draftContentEvent.labels.endDate',
  endTimeLabel:
    'web.admin.content.draftContent.info.draftContentEvent.labels.endTime',
};

type Props = {
  availableTimeZones: string[];
  timeZone: string;
  content: {
    startDate?: Date | null;
    endDate?: Date | null;
    templateName?: string;
    isEvent?: boolean;
  };
  onTimeZoneUpdated: (value: string) => void;
  onContentUpdated: (content: {
    startDate?: Date | null;
    endDate?: Date | null;
  }) => Partial<DraftContentType>;
  validation?: any;
  stepperTracker?: StepperTrackerType;
};

export default function DraftContentEvent({
  availableTimeZones,
  timeZone,
  content,
  validation,
  onTimeZoneUpdated,
  onContentUpdated,
  stepperTracker,
}: Props) {
  const requiredSpan = <span className={styles.required}>*</span>;
  const { t } = useTranslation();

  function onToggleEvent(isEvent: boolean) {
    const contentUpdates: Partial<DraftContentType> = {
      isEvent,
      subtitle: SubtitleOptions.DATE,
    };

    // if we're currently untoggling isEvent, set start and endDate to null and subtitle to None
    if (!isEvent) {
      contentUpdates.startDate = null;
      contentUpdates.endDate = null;
      contentUpdates.subtitle = SubtitleOptions.NONE;
    }
    const newDraft = onContentUpdated(contentUpdates);
    stepperTracker?.Info.IsEvent(newDraft);
  }

  const displayTemplateEventMessage = !!content.templateName;

  return (
    <div className={styles.formField}>
      <Toggle
        value={content.isEvent}
        onChange={() => onToggleEvent(!content.isEvent)}
        text={t(TRANSLATION_KEYS.toggleText)}
        valuetestId="isEventToggle"
      />
      {content.isEvent && (
        <CardContainer className={styles.event}>
          {displayTemplateEventMessage && (
            <span className={styles.contentTemplateMessage}>
              {t(TRANSLATION_KEYS.contentTemplateMessage)}
            </span>
          )}
          {!displayTemplateEventMessage && (
            <>
              <Label>{t(TRANSLATION_KEYS.timezoneHeader)}</Label>
              <TimeZoneDropdown
                timeZones={availableTimeZones}
                value={timeZone}
                onValueChange={onTimeZoneUpdated}
                className={styles.eventField}
              />
              <Label>
                {t(TRANSLATION_KEYS.startDateHeader)} {requiredSpan}
              </Label>
              <DatePickerButton
                wrapperClassName={styles.dateAndTimePickerWrapper}
                className={styles.eventField}
                timeZone={timeZone}
                value={content.startDate}
                includeTime
                timeInterval={TimeIntervals.QUARTER_HOUR}
                onChange={(date: Date) => {
                  const newDraft = onContentUpdated({
                    startDate: date || null,
                  });
                  stepperTracker?.Info.EventStartDate(newDraft);
                }}
                dateInputLabel={t(TRANSLATION_KEYS.startDateLabel)}
                timeInputLabel={t(TRANSLATION_KEYS.startTimeLabel)}
                dateError={getValidationMessages(validation, 'startDate') || []}
                timeError={getValidationMessages(validation, 'startDate') || []}
              />
              <Label>
                {t(TRANSLATION_KEYS.endDateHeader)} {requiredSpan}
              </Label>
              <DatePickerButton
                wrapperClassName={styles.dateAndTimePickerWrapper}
                className={styles.eventField}
                timeZone={timeZone}
                value={content.endDate}
                onChange={(date: Date | null) => {
                  const newDraft = onContentUpdated({
                    endDate: date || null,
                  });
                  stepperTracker?.Info.EventEndDate(newDraft);
                }}
                dateInputLabel={t(TRANSLATION_KEYS.endDateLabel)}
                timeInputLabel={t(TRANSLATION_KEYS.endTimeLabel)}
                includeTime
                timeInterval={TimeIntervals.QUARTER_HOUR}
                dateError={getValidationMessages(validation, 'endDate') || []}
                timeError={getValidationMessages(validation, 'endDate') || []}
              />
            </>
          )}
        </CardContainer>
      )}
    </div>
  );
}
