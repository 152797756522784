import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import Input from '../form/Input';
import Loading from '../general/Loading';
import Pagination from '../general/Pagination';

import styles from './ChannelRelationshipsSearch.scss';

export const ALL = 'All';

type SearchType = {
  name: string;
  type: ChannelTypeEnum | 'All';
  page: number;
  perPage: number;
};

const DEBOUNCE_THROTTLE = 250;

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  channelId: LaneType.UUID | undefined | null;
  excludeType?: ChannelTypeEnum;
  renderRelationship: ({
    _id,
    channel,
    relatedTo,
  }: {
    _id: LaneType.UUID;
    channel: ChannelType;
    relatedTo: ChannelType;
  }) => React.ReactNode;
  types?: ChannelTypeEnum[];
  onTypeChanged?: (type: ChannelTypeEnum | 'All') => void;
  mode?: 'relatedTo' | 'channel';
};

type Props = OwnProps;

export default function ChannelRelationshipsSearch({
  className,
  style,
  channelId,
  renderRelationship,
  types = Object.values(ChannelTypeEnum),
  onTypeChanged = () => null,
  mode = 'channel',
}: Props) {
  const { t } = useTranslation();
  const [search, setSearch] = useState<SearchType>({
    name: '',
    type: ALL,
    page: 0,
    perPage: 25,
  });

  function updateSearch(props: Partial<SearchType>) {
    if (!props.page) {
      props.page = 0;
    }

    setSearch({
      ...search,
      ...props,
    });
  }

  function getVariables() {
    const pagination = {
      start: search.page * search.perPage,
      perPage: search.perPage,
    };

    const channelSearch = {
      sortBy: { key: 'name', dir: 'asc' },
      name: search.name ? { type: 'like', value: search.name } : undefined,
      type: search.type === ALL ? undefined : { any: [search.type] },
      isSub: false,
    };

    const idSearch = { _id: channelId };

    return {
      pagination,
      search: {
        relatedTo: mode === 'relatedTo' ? channelSearch : idSearch,
        channel: mode === 'relatedTo' ? idSearch : channelSearch,
      },
    };
  }

  const [fetchRelationships, { data, loading }] = useLazyQuery(
    queryChannelsByRelationship,
    {
      fetchPolicy: 'network-only',
    }
  );

  const debouncedFetch = useDebouncedCallback(() => {
    fetchRelationships({
      variables: getVariables(),
    });
  }, DEBOUNCE_THROTTLE).callback;

  useEffect(() => {
    if (channelId) {
      debouncedFetch();
    }
  }, [channelId, search]);

  useEffect(() => {
    if (onTypeChanged) {
      onTypeChanged(search.type);
    }
  }, [search.type]);

  const relationships = data?.channelsByRelationship?.items || [];
  const pageInfo = data?.channelsByRelationship?.pageInfo || {
    total: 0,
  };

  return (
    <div
      data-test="channelSearchResults"
      className={cx(styles.ChannelRelationshipsSearch, className)}
      style={style}
    >
      {types.length > 1 && (
        <menu>
          <button
            data-is-selected={ALL === search.type}
            onClick={() => updateSearch({ type: ALL })}
          >
            {t(
              'web.components.lane.ChannelRelationshipsSearch.ChannelTypeEnum.ALL'
            )}
          </button>
          {types
            .filter(type => types.includes(type))
            .map(type => (
              <button
                key={type}
                data-is-selected={type === search.type}
                onClick={() => updateSearch({ type })}
              >
                {t(
                  `web.components.lane.ChannelRelationshipsSearch.ChannelTypeEnum.${type}`
                )}
              </button>
            ))}
        </menu>
      )}
      <div className={styles.channels}>
        <Input
          value={search.name}
          className={styles.search}
          onChange={name => updateSearch({ name })}
          showClear
          icon="search"
          placeholder={t(
            'web.admin.content.draftContent.target.tenantModal.Search'
          )}
        />

        <Pagination
          className={styles.pagination}
          loading={loading}
          total={pageInfo.total}
          page={search.page}
          perPage={search.perPage}
          onPage={page => updateSearch({ page })}
        />

        {loading && <Loading className={styles.loading} />}
        {relationships.map(renderRelationship)}
      </div>
    </div>
  );
}
