import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getMeterReadingValuesByMeterReadingIds(
    $meterReadingIds: [UUID!]!
    $pagination: PaginationInput
    $includeArchived: Boolean
  ) {
    getMeterReadingValuesByMeterReadingIds(
      meterReadingIds: $meterReadingIds
      pagination: $pagination
      includeArchived: $includeArchived
    ) {
      meterReadingValues {
        id
        meterReadingId
        createdBy {
          name
          id
        }
        createdAt
        sourceType
        value
        isArchived
        stepExecutionId
        notes
        taskId
        taskUserFriendlyId
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`);
