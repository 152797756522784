import gql from 'graphql-tag';

export type ListUnitsQueryResponse = {
  property: {
    units: {
      id: string;
      name: string;
      description: string;
      floors: {
        id: string;
        index: number;
        name: string;
      }[];
    }[];
  };
};

export const listUnits = gql`
  query ListUnits($propertyId: UUID!) {
    property(propertyId: $propertyId) {
      units {
        id
        name
        description
        floors {
          id
          index
          name
        }
      }
    }
  }
`;
