import gql from 'graphql-tag';

import { PublicUserFragment, ProfileFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ProfileFragment}

  query getWorkOrderInteraction($id: UUID!) {
    workOrderInteraction(_id: $id) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      user {
        _id
        name
        profile {
          ...ProfileFragment
        }
      }
      channel {
        _id
      }
      content {
        _id
      }
      startDate
      endDate
      data
      state
      features
      actions
      contentData
      status
      lastStatus
    }
  }
`;
