import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { AcceptedCardsHeader } from './AcceptedCardsHeader';
import { H4 } from 'design-system-web';

import styles from './PaymentOptionsTitle.scss';

export function PaymentOptionsTitle() {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.paymentOptionsTitleContainer)}>
      <div className={styles.paymentOptionsTitle}>
        <H4>{t('abp.payment.paymentOptions')}</H4>
      </div>
      <AcceptedCardsHeader />
    </div>
  );
}
