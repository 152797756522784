import { Password, Number } from 'lane-shared/properties/baseTypes';
import { PropertiesInterface } from '../../../types/properties/Property';

const YARDI_7_S = 'YARDI_7_S';
const YARDI_7_S_COMMERCIAL = 'YARDI_7_S_COMMERCIAL';

export const IntegrationType = {
  Residential: YARDI_7_S,
  Commercial: YARDI_7_S_COMMERCIAL,
};

const CommonYardiProperties: PropertiesInterface = {
  url: {
    friendlyName: 'Base URL',
    type: 'Url',
    description: 'Base URL',
    validators: [{ name: 'Required', value: true }],
    tags: ['baseField'],
  },
  serverName: {
    friendlyName: 'Server Name',
    type: 'String',
    description: 'Server name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    tags: ['baseField'],
  },
  databaseName: {
    friendlyName: 'Database Name',
    type: 'String',
    description: 'Database name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    tags: ['baseField'],
  },
  propertyId: {
    friendlyName: 'Property ID',
    type: 'String',
    description: 'Yardi Commercial property ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    tags: ['baseField'],
  },
  username: {
    friendlyName: 'Username',
    type: 'String',
    description: 'Yardi Commercial Username',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    tags: ['coreCredentialsField'],
  },
  password: {
    friendlyName: 'Password',
    type: Password.name,
    description: 'Yardi Commercial Password',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    tags: ['coreCredentialsField'],
  },
  syncPastUsersEnabled: {
    friendlyName: 'Sync Past Residents',
    type: 'Boolean',
    default: false,
    tags: ['usersSyncField'],
  },
  daysPostMoveOut: {
    friendlyName: 'Import Past Residents: Enter days post Move-Out Date',
    type: Number.name,
    default: undefined,
    validators: [],
    tags: ['usersSyncField'],
  },
  syncFutureUsersEnabled: {
    friendlyName: 'Sync Future Residents',
    type: 'Boolean',
    default: false,
    tags: ['usersSyncField'],
  },
  daysPriorMoveIn: {
    friendlyName: 'Import Future Residents: Enter days prior to Move-In Date',
    type: Number.name,
    default: undefined,
    validators: [],
    tags: ['usersSyncField'],
  },
  workOrdersEnabled: {
    friendlyName: 'Work orders',
    type: 'Boolean',
    default: false,
    tags: ['workOrdersSwitch'],
  },
  financialEnabled: {
    friendlyName: 'Financial',
    type: 'Boolean',
    default: false,
    tags: ['financialsSwitch'],
  },
  financialUsername: {
    friendlyName: 'Financial Username',
    type: 'String',
    description: 'Financial Username',
    validators: [],
    tags: ['financialsCredentialsField'],
  },
  financialPassword: {
    friendlyName: 'Financial Password',
    type: Password.name,
    description: 'Financial Password',
    validators: [],
    tags: ['financialsCredentialsField'],
  },
  checkPermissionsButton: {
    friendlyName: 'Check Permissions',
    type: String.name,
    validators: [],
    tags: ['financialsCheckBtn'],
  },
};

export const YardiResidentialProperties = {
  ...CommonYardiProperties,
};

export const YardiCommercialProperties = {
  ...CommonYardiProperties,
};

export const RequiredFieldsForPaymentPermissions = (): string[] => {
  return Object.entries(CommonYardiProperties)
    .filter(
      ([_, property]) =>
        property.tags?.includes('baseField') ||
        property.tags?.includes('financialsCredentialsField')
    )
    .map(([key, _]) => key);
};

export const PaymentFields = (): string[] => {
  return Object.entries(CommonYardiProperties)
    .filter(([_, property]) =>
      property.tags?.includes('financialsCredentialsField')
    )
    .map(([key, _]) => key);
};

export enum IntegrationInputs {
  CheckPermissionButton = 'checkPermissionsButton',
}
