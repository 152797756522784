import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ContentRequestedMoreThanMaxError extends CodedError {
  static get errorName() {
    return errorCodes.contentRequestedMoreThanMax.code;
  }

  constructor(message = errorCodes.contentRequestedMoreThanMax.message) {
    super(message);
    this.code = errorCodes.contentRequestedMoreThanMax.code;
    this.message = `${message} ${errorCodes.contentRequestedMoreThanMax.code}`;
    this.name = errorCodes.contentRequestedMoreThanMax.name;
  }
}
