import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  mutation leaveReservableWaitlist($enrollmentId: UUID!) {
    leaveReservableWaitlist(enrollmentId: $enrollmentId) {
      startDate
      endDate
    }
  }
`);
