import React, { ReactNode, useMemo, useState } from 'react';

import { Auth0Context } from '.';

type Props = {
  children: ReactNode;
};

export const Auth0ContextProvider: React.FC<Props> = ({ children }) => {
  const [
    isAuth0LogoutInProgress,
    setIsAuth0LogoutInProgress,
  ] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      isAuth0LogoutInProgress,
      setIsAuth0LogoutInProgress,
    }),
    [isAuth0LogoutInProgress, setIsAuth0LogoutInProgress]
  );

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};
