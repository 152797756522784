import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ContentOnChannelViaAudiencesSearchError extends CodedError {
  static get errorName() {
    return errorCodes.contentOnChannelViaAudiencesSearchError.code;
  }

  constructor(
    message = errorCodes.contentOnChannelViaAudiencesSearchError.message
  ) {
    super(message);
    this.code = errorCodes.contentOnChannelViaAudiencesSearchError.code;
    this.message = `${message} ${errorCodes.contentOnChannelViaAudiencesSearchError.code}`;
    this.name = errorCodes.contentOnChannelViaAudiencesSearchError.name;
  }
}
