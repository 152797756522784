import { createContext } from 'react';

import { colors } from '../config';

export type ThemeContextType = {
  primary: string;
  secondary: string;
  accentColor: string;
  tertiary: string;
  text: string;
  success: string;
  warning: string;
  black: string;
  background: string;
  link: string;
  inputText: string;
  logo: null | string;
  instance: string;
  name: string;
};

const ThemeContext = createContext<ThemeContextType>({
  primary: colors.primary,
  secondary: colors.secondary,
  accentColor: colors.secondary,
  tertiary: colors.tertiary,
  text: colors.text,
  success: colors.success,
  warning: colors.warningOLD,
  black: colors.black,
  background: colors.background,
  link: colors.link,
  inputText: colors.inputText,
  logo: null,
  instance: 'lane',
  name: 'Lane',
});

export default ThemeContext;
