import gql from 'graphql-tag';

export type UpdateFloorMutationResponse = {
  updateFloor: string;
};

export const updateFloor = gql`
  mutation UpdateFloor(
    $propertyId: UUID!
    $floorId: UUID!
    $floor: FloorInput!
  ) {
    updateFloor(propertyId: $propertyId, floorId: $floorId, floor: $floor)
  }
`;
