export enum Auth0Prompt {
  // https://auth0.com/docs/customize/universal-login-pages/customize-login-text-prompts#prompt-values
  none = 'none', // To initiate a silent authentication request,
  login = 'login', // User session exists or not, it will redirect to login/signup page base on screen hint
}

export enum Auth0ScreenHint {
  // https://auth0.com/docs/authenticate/login/auth0-universal-login/new-experience
  login = 'login',
  signup = 'signup',
}

export type Auth0Parameter = {
  prompt?: Auth0Prompt;
  screen_hint: Auth0ScreenHint;
  login_hint?: string;
  organization?: string;
  'ext-page-title'?: string;
  'ext-primary-color'?: string;
  'ext-primary-button-color'?: string;
  'ext-background-image'?: string;
  'ext-background-logo'?: string;
  invitation?: string;
  aiid?: string;
  connection?: string;
};
