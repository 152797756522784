import gql from 'graphql-tag';

export default gql`
  mutation createAttachment($attachment: AttachmentInput) {
    createAttachment(attachment: $attachment) {
      id
      fileSignedUrl
      thumbnailSignedUrl
      entityId
      entityType
      file
      thumbnail
      created
      deletedAt
      s3Bucket
    }
  }
`;
