import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  mutation updateInteraction($interaction: UserContentInteractionInput!) {
    updateContentInteraction(interaction: $interaction) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      user {
        _id
        name
      }
      channel {
        _id
      }
      content {
        _id
      }
      startDate
      endDate
      geo
      data
      state
      features
      actions
      contentData
      version
      status
      adminNote
    }
  }
`;
