import { gql } from '@apollo/client';

export const createSubscriptionMutation = gql`
  mutation createRecurringCharge(
    $createRecurringChargeRequest: CreateRecurringChargeRequest!
  ) {
    accounts {
      createRecurringCharge(
        createRecurringChargeRequest: $createRecurringChargeRequest
      ) {
        recurrenceId
      }
    }
  }
`;
