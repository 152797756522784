import { DateTime } from 'luxon';

export function createDefaultDateTime(
  hours: number,
  minutes: number,
  timeZone?: string
): Date {
  // default date is always today
  const dateTime = DateTime.fromObject({ zone: timeZone }).set({
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0,
  });

  return dateTime.toJSDate();
}
