import i18n from 'localization';

export const errorCodeTypes = {
  unitNameAlreadyExistsError: {
    code: 'UN01',
    name: 'UnitNameAlreadyExistsError',
    message: i18n.t('shared.units.errors.nameExists'),
  },
  unitNameMinimumCharacterError: {
    code: 'UN02',
    name: 'UnitNameMinimumCharacterError',
    message: i18n.t('shared.units.errors.nameMinimumCharacter'),
  },
  unitNotFoundError: {
    code: 'UN03',
    name: 'UnitNotFoundError',
    message: i18n.t('shared.units.errors.notFound'),
  },
};
