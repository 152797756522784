import {
  DataStatus,
  Item,
  UserAccessGroupIds,
} from '../components/user-access/MixedStateList/types';
import { UserInfo } from '../types/AccessControl';

interface Options {
  userAccessGroups: UserAccessGroupIds[];
  lockExistingAccessGroups?: boolean;
}

const getStatusByUserInfo = (users: UserInfo[], item: Item) => {
  if (users.length === 0) return DataStatus.unselected;

  const filtered = users.filter(user =>
    user.accessControlGroupIds.includes(item.value)
  );

  if (filtered.length === users.length) {
    return DataStatus.selected;
  }
  return filtered.length === 0 ? DataStatus.unselected : DataStatus.mixed;
};

const getStatusByAccessGroups = (
  userAccessGroups: UserAccessGroupIds[],
  item: Item
) => {
  if (userAccessGroups.length === 0) return DataStatus.unselected;

  const filtered = userAccessGroups.filter(user =>
    user.accessGroupIds.includes(item.value)
  );

  if (filtered.length === userAccessGroups.length) {
    return DataStatus.selected;
  }
  return filtered.length === 0 ? DataStatus.unselected : DataStatus.mixed;
};

const isAccessGroupLocked = (
  lockExistingAccessGroups: boolean = false,
  status: DataStatus
) => {
  return lockExistingAccessGroups && status === DataStatus.selected;
};

export const buildMixedStateData = (
  items: Item[],
  users: UserInfo[],
  opt?: Options
) => {
  return items.map(item => {
    const { userAccessGroups = [], lockExistingAccessGroups } = opt || {};
    const status = userAccessGroups.length
      ? getStatusByAccessGroups(userAccessGroups, item)
      : getStatusByUserInfo(users, item);

    return {
      label: item.label,
      value: item.value,
      provider: item.provider,
      channelId: item.channelId,
      status,
      isDisabled:
        item.isDisabled ||
        isAccessGroupLocked(lockExistingAccessGroups, status),
    };
  });
};
