import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useModalPosition } from 'hooks';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LONG_MONTH_DATE_YEAR } from 'lane-shared/helpers/constants/dates';
import { copyTimeToDate, parseDateTime } from 'lane-shared/helpers/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import ModalBackground from '../../general/ModalBackground';
import Calendar from '../DatePickers/components/Calendar';

import styles from './DatePickerButtonV3.scss';

const displayFormat = LONG_MONTH_DATE_YEAR;

type Props = {
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  onSubmit: (date: Date) => void;
  onChange?: (date: Date) => void;
  disabled?: boolean;
  timezone?: string;
  isLoading?: boolean;
  existingValue?: DateRangeType;
  onFocusChange?: (date: Date) => void;
  unavailableDateRanges?: Array<DateRangeType>;
  disabledWeekDays?: number[];
};

export default function DatePickerButtonV3({
  value,
  minDate,
  maxDate,
  onSubmit,
  disabled,
  timezone,
  onChange,
  isLoading,
  existingValue,
  onFocusChange,
  unavailableDateRanges = [],
  disabledWeekDays,
}: Props) {
  const {
    buttonRef,
    modalRef,
    childrenRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } = useModalPosition();
  const { t } = useTranslation();
  const [dateUpdated, setDateUpdated] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);
  const [inputText, setInputText] = useState<string>(t('Date'));

  useEffect(() => {
    if (dateUpdated) {
      if (date) {
        setInputText(dateFormatter(date, displayFormat, timezone));
      }
    } else if (value) {
      if (value?.getTime?.() !== date?.getTime?.()) {
        setDate(value);
        setDateUpdated(false);
      }

      setInputText(dateFormatter(value, displayFormat, timezone));
    }
  }, [date, value]);

  function onDateClick(inputDay: Date) {
    let resolvedDate = inputDay;

    const day = parseDateTime(inputDay, timezone);
    const selectedDate = parseDateTime(date, timezone);

    if (selectedDate && day) {
      // keep the time of day of the selected date
      resolvedDate = copyTimeToDate(selectedDate, day);
    }

    setDate(resolvedDate);
    setDateUpdated(true);

    if (onChange) {
      onChange(resolvedDate);
    }
  }

  function handleSubmit() {
    if (onSubmit && date) {
      onSubmit(date);
    }

    onClose();
  }

  function handleClose() {
    if (value) {
      setDate(value);
    }
    onClose();
  }

  return (
    <span ref={buttonRef} className={cx(styles.DatePickerButtons)}>
      <div
        onClick={onOpen}
        role="presentation"
        className={cx(styles.DateInputContainer)}
        ref={childrenRef}
      >
        <Icon name="calendar-check" className={cx(styles.CalendarIcon)} />
        <input
          id="dateInput"
          type="text"
          value={inputText}
          tabIndex={0}
          disabled={disabled}
          className={cx(styles.DateInput)}
          data-test="date-picker-input"
          data-has-value={!!inputText}
        />
        <label className={cx(styles.DateInputLabel)} htmlFor="dateInput">
          Date
        </label>
      </div>
      <ModalBackground
        className={styles.background}
        onClose={handleClose}
        isOpen={isOpen}
      />
      {isOpen &&
        ReactDOM.createPortal(
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
          <div ref={modalRef} className={styles.container} style={position}>
            <Calendar
              className={styles.calendar}
              onChange={(date: Date) => {
                onDateClick(date);
              }}
              onSubmit={() => {
                handleSubmit();
              }}
              onFocusChange={onFocusChange}
              loading={isLoading}
              minDate={minDate}
              maxDate={maxDate}
              startDate={dateUpdated ? date : value}
              endDate={dateUpdated ? date : value}
              includeTime={false}
              existingValue={existingValue}
              unavailableDateRanges={unavailableDateRanges}
              timeZone={timezone}
              disabledWeekDays={disabledWeekDays}
            />
          </div>,
          document.body
        )}
    </span>
  );
}
