import gql from 'graphql-tag';

export const upsertUserGroupRoles = gql`
  mutation upsertUserGroupRoles(
    $userId: UUID!
    $groupRoleIds: [UUID!]!
    $companyId: UUID
  ) {
    upsertUserGroupRoles(
      userId: $userId
      groupRoleIds: $groupRoleIds
      companyId: $companyId
    )
  }
`;
