import gql from 'graphql-tag';

export type ResendInviteResponse = {
  resendInvite: boolean;
};

export const resendInvite = gql`
  mutation resendInvite($id: UUID!) {
    resendInvite(_id: $id)
  }
`;
