import React from 'react';

import { Icon } from 'design-system-web';

import styles from './tag.scss';

type OwnProps = {
  color?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'green'
    | 'yellow'
    | 'red'
    | 'gray';
  isDismissible?: boolean;
  onClose?: (...args: any[]) => any;
  children: React.ReactElement;
  name?: string;
  style?: any;
  className?: string;
};

type Props = OwnProps;

const Tag = ({
  color = 'primary',
  isDismissible = false,
  children,
  onClose = () => {},
  name = '',
  style = {},
  className = '',
}: Props) => (
  <div
    className={`${styles.tag} ${styles[`${color}`]} ${className}`}
    style={style}
  >
    {children}
    {isDismissible && (
      <button
        className={styles.iconWrapper}
        onClick={() => onClose(name)}
        type="button"
      >
        <Icon name="times" className={`${styles[`${color}`]} ${styles.icon}`} />
      </button>
    )}
  </div>
);

export default React.memo(Tag);
