import React from 'react';

import { Popup } from '../PopupMenu/Popup';
import { Icon } from '../Icon';
import { Button } from '../Button/Button';
import { Text } from '../Typography/Typography';

import type { Column, ColumnVisibilityState } from '../Table/hooks/useTable';
import { useTranslation } from 'react-i18next';

import { IndeterminateCheckbox } from '../Table/IndeterminateCheckbox';

import styles from './ColumnVisibility.scss';

type ColumnVisibilityProps<TDataShape> = {
  columns: Column<TDataShape>[];
  columnVisibility: ColumnVisibilityState;
  setColumnVisibility: (columnVisibility: ColumnVisibilityState) => void;
};

export const ColumnVisibility = <TDataShape,>({
  columns,
  columnVisibility,
  setColumnVisibility,
}: ColumnVisibilityProps<TDataShape>) => {
  const { t } = useTranslation();

  return (
    <Popup
      trigger={
        <Button variant="text" size="large" startIcon={<Icon name="cog" />}>
          {t('web.table.editColumns')}
        </Button>
      }
      listNavigation
    >
      {({ getItemProps, listRef }) => (
        <div className={styles.DropdownWrapper}>
          <Text bold>{t('web.table.columnSelection')}</Text>
          {columns.map(column => (
            <div key={column.key} className={styles.CheckboxWrapper}>
              <IndeterminateCheckbox
                disabled={column.disableVisibilityToggle}
                checked={columnVisibility[column.key] !== false}
                label={column.header}
                ref={node => {
                  listRef.current[0] = node;
                }}
                onChange={() => {
                  setColumnVisibility({
                    ...columnVisibility,
                    [column.key]: columnVisibility[column.key] === false,
                  });
                }}
                {...getItemProps()}
              />
            </div>
          ))}
        </div>
      )}
    </Popup>
  );
};
