import { ModuleDefinition } from 'lane-shared/types/modules/moduleDefinition';

import { AddressType } from './AddressType';
import { ChannelPage } from './ChannelPage';
import { DocumentType } from './DocumentType';
import { GroupRoleJoinRuleType } from './GroupRoleJoinRuleType';
import { ProfileType } from './ProfileType';
import { UserGroupRoleType } from './UserGroupRole';
import { RequirementsFeatureProperties } from './features/RequirementsFeatureProperties';

export enum ChannelRelationshipTypeEnum {
  Tenant = 'Tenant',
}

export enum ActiveChannelTypeEnum {
  Property = 'Property',
  Company = 'Company',
  Retail = 'Retail',
  Restaurant = 'Restaurant',
  Service = 'Service',
  Entertainment = 'Entertainment',
}

export enum DeprecatedChannelTypeEnum {
  Professional = 'Organization',
  Charity = 'Charity',
  Meta = 'Meta',
  Curated = 'Curated',
}

export const ChannelTypeEnum = {
  ...ActiveChannelTypeEnum,
  ...DeprecatedChannelTypeEnum,
};
// Have to manually redeclare type to match merged enum signature
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ChannelTypeEnum = typeof ChannelTypeEnum[keyof typeof ChannelTypeEnum];

export type ChannelLanguagesType = { primary?: string; secondary?: string };

export type EnabledLanguagesForMembersType =
  | Record<string, boolean>
  | null
  | undefined;

export type ChannelSettingsType = {
  multiLanguageEnabled?: boolean;
  channelLanguages?: ChannelLanguagesType;
  enabledLanguagesForMembers?: EnabledLanguagesForMembersType;
  maxUsers: number;
  offboardingDelayDays?: number;
  subscribersJoinParent: boolean;
  subscribersAreDecoyed: boolean;
  groupRoleJoinRules: GroupRoleJoinRuleType[];
  hasPerksEnabled: boolean;
  areUserTemplatesAllowed: boolean;
  requirements?: RequirementsFeatureProperties;
  effectiveParentRequirements?: RequirementsFeatureProperties;
  hasBillingPaymentsEnabled: boolean;
  hasVisitorManagementEnabled: boolean;
  hasHardwareManagementEnabled: boolean;
  hasHardwarePinManagementEnabled: boolean;
  hasWorkOrdersEnabled: boolean;
  hasWorkOrderServiceRequestsEnabled: boolean;
  hasWorkOrderPreventiveMaintenanceEnabled: boolean;
  hasWorkOrderEquipmentEnabled: boolean;
  hasWorkOrderCrossPropertyEnabled: boolean;
  hasReservableManagementEnabled: boolean;
  hasSurveysEnabled: boolean;
  hasAccessControlEnabled: boolean;
  hasMessagingAndMarketplaceEnabled: boolean;
  hasSmartUserManagementEnabled: boolean;
};

export type ChannelType = DocumentType & {
  // client only type
  _hasAdmin?: boolean;
  // client only type
  _distance?: number;
  // client only type
  _shortestDistance?: number;
  // client only type
  channelLocations?: ChannelType[];
  // client only type
  subs: ChannelType[];

  description?: string | null;
  type: ChannelTypeEnum;
  parent?: Partial<ChannelType> | null;
  tags: string[];
  filters: string[];
  roles: UserGroupRoleType[];
  groupRoles: UserGroupRoleType[];
  settings: ChannelSettingsType;
  effectiveParentRequirements: RequirementsFeatureProperties;
  address?: AddressType;
  profile?: ProfileType;
  pages?: ChannelPage[];
  slug: string;
  isPublic: boolean;
  isDiscoverable: boolean;
  isLive: boolean;
  isSub: boolean;
  isCustomer?: boolean;
  inviteOnly: boolean;
  stats: {
    subscribers: number;
    sf: number;
  };
  channelModules: ModuleDefinition[];
  website?: string | null;
  experienceType?: ChannelExperienceTypeEnum | null;
  parentId?: string | null;
  isArchived?: boolean;
  hasCustomOffboarding?: boolean;
};

export enum ChannelExperienceTypeEnum {
  office = 'office',
  multifamily = 'multifamily',
}

export type CloneChannelOptionsType = {
  includeSections: boolean;
  includeSectionContent: boolean;
  includePages: boolean;
  includeContent: boolean;
  includeGroupRoles: boolean;
  includeLibraries: boolean;
  includeChannelPages: boolean;
  includeMultiLanguage: boolean;
  newParentId: string | null;
  newAddress: AddressType | null;
  newName: string | null;
};

export type ChannelServiceRequestFeaturesType = {
  createServiceRequest: boolean;
  updateServiceRequest: boolean;
};
