import React from 'react';

import { DatePickerButton } from '../DatePicker/DatePickerButton';

type Props = {
  onChange: (value: Date | null) => void;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
};

export const DateFilter = ({ onChange, value, maxDate, minDate }: Props) => {
  return (
    <DatePickerButton
      onChange={onChange}
      value={value}
      maxDate={maxDate}
      minDate={minDate}
      hideLabel
    />
  );
};
