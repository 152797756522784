import { useEffect, useRef, useState } from 'react';

import gql from 'graphql-tag';

import { ApolloError } from '@apollo/client';

import { getClient } from '../apollo';
import { PublicUserFragment } from '../graphql/fragments';
import { FeatureInstance } from '../types/FeatureInstance';
import { PublicUserType } from '../types/User';
import { ContentType } from '../types/content/Content';
import { IntegrationProviderEnum } from '../types/integrations/IntegrationEnums';
import { useMultiLanguage } from './useMultiLanguage';
import useFlag from './useFlag';
import { FeatureFlag } from '../types/FeatureFlag';

export type ContentForCardType = Pick<
  ContentType,
  | '_created'
  | '_id'
  | '_updated'
  | 'backgroundColor'
  | 'backgroundImage'
  | 'channel'
  | 'color'
  | 'deepLink'
  | 'description'
  | 'endDate'
  | 'externalUrl'
  | 'icon'
  | 'iconSet'
  | 'iconWeight'
  | 'interactionEndDate'
  | 'isInteractive'
  | 'liveDate'
  | 'name'
  | 'renderer'
  | 'slug'
  | 'startDate'
  | 'subtitle'
  | 'tags'
  | 'type'
  | 'unpublishDate'
  | 'version'
> & {
  card: Pick<ContentType['card'], '_id'>;
  integration?: {
    _id: string;
    settings: any;
    integration: {
      _id: string;
      name: IntegrationProviderEnum;
      settings: any;
    };
  };
  features: Pick<FeatureInstance, '_id' | 'type' | 'feature'>[];
  publishedBy: PublicUserType;
};

export const ContentForCardFragment = `
  fragment ContentForCardFragment on Content {
    _id
    _created
    _updated
    name
    name_l10n
    description
    description_l10n
    subtitle
    slug
    type
    tags
    color
    isInteractive
    backgroundImage
    channel {
      _id
      settings {
        multiLanguageEnabled
        channelLanguages
        enabledLanguagesForMembers
      }
    }
    icon
    iconSet
    iconWeight
    backgroundColor
    liveDate
    startDate
    endDate
    unpublishDate
    interactionEndDate
    version
    renderer
    externalUrl {
      _id
      url
      openingMode
    }
    signedExternalUrl
    deepLink {
      _id
      deepLinkUrl
      appStoreUrl
      googlePlayStoreUrl
    }
    card {
      _id
    }

    integration {
      _id
      settings
      integration {
        _id
        name
        settings
      }
    }

    features {
      _id
      type
      feature
    }

    publishedBy {
      ...PublicUserFragment
    }

    channel {
      _id
    }
  }
`;

const getContentForCardQuery = gql`
  ${PublicUserFragment}
  ${ContentForCardFragment}

  query getContentForCardQuery($contentId: UUID!) {
    content(_id: $contentId) {
      ...ContentForCardFragment
    }
  }
`;

export default function useContentForCardQuery({
  contentId,
}: {
  contentId: string | null | undefined;
}): {
  content: ContentForCardType | null;
  loading: boolean;
  error: ApolloError | null | undefined;
} {
  const { translate } = useMultiLanguage();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<any>(undefined);
  const [error, setError] = useState<any>(undefined);
  const isMounted = useRef(false);
  const isCacheFlagEnabled = useFlag<boolean>(
    FeatureFlag.ContentUsesCache,
    false
  );

  async function fetchContent(cId: any) {
    setLoading(true);
    if (cId) {
      try {
        const { data } = await getClient().query({
          query: getContentForCardQuery,
          variables: { contentId: cId },
          fetchPolicy: isCacheFlagEnabled ? 'cache-first' : 'network-only',
        });
        // in case unmounting happens while the data is being retrieved:
        if (isMounted.current) {
          setContent(data.content);
          setError(undefined);
        }
      } catch (err) {
        setContent(undefined);
        setError(err);
      }
    } else {
      setContent(undefined);
    }
    setLoading(false);
  }

  // set isMounted to true on mounting, and do cleanup once unmounted
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchContent(contentId);
  }, [contentId]);
  return {
    content: translate({
      model: content,
      columns: ['name', 'description', 'subtitle'],
    }),
    loading,
    error,
  };
}
