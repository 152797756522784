import React, { useRef, useMemo } from 'react';

import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import useLocation from 'lane-shared/hooks/location/useLocation';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import MapboxMap from 'components/mapBox/MapboxMap';
import MapboxPoint from 'components/mapBox/MapboxPoint';

import styles from './GeoPolygonInput.scss';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  value: GeoCoordinateType | undefined | null;
  name?: string | undefined | null;
  center?: GeoCoordinateType;
  disabled?: boolean;
  onChange: (value: GeoCoordinateType | undefined | null) => void;
};

const animationOptions = {
  animate: false,
};

export default function GeoCoordinateInput({
  className,
  style,
  center,
  value,
  onChange,
}: Props) {
  // generate a unique id to track features
  const idRef = useRef(uuid());
  const { location, locationHelper } = useLocation();
  const mapBoxStyle = useMemo(
    () => ({ minWidth: 200, minHeight: 200, ...style }),
    [style]
  );

  let _center: GeoCoordinateType;

  if (value) {
    _center = value;
  } else if (center) {
    _center = center;
  } else if (locationHelper.isWatching()) {
    _center = [location.longitude, location.latitude];
  } else {
    _center = [0, 0];
  }

  let _value: GeoCoordinateType;

  if (value) {
    _value = value;
  } else if (_center) {
    _value = _center;
  }

  return (
    <div className={cx(styles.GeoPolygonInput, className)}>
      <MapboxMap
        animationOptions={animationOptions}
        center={_center}
        containerStyle={mapBoxStyle}
      >
        <MapboxPoint
          id={idRef.current}
          onCoordinatesUpdated={(id: any, coordinates: any) =>
            onChange(coordinates)
          }
          onFocus={() => null}
          opacity={1}
          // @ts-expect-error ts-migrate(2454) FIXME: Variable '_value' is used before being assigned.
          coordinates={_value}
        />
      </MapboxMap>
    </div>
  );
}
