import gql from 'graphql-tag';

import { LaneType } from 'common-types';
import { AddressType } from '../../types/AddressType';
import { ChannelType } from '../../types/ChannelType';
import { GroupRole } from '../../types/GroupRole';
import { ProfileFragment } from '../fragments';
import { ProfileFragmentType } from '../fragments/ProfileFragment';

type GetInviteResponseChannelType = Pick<
  ChannelType,
  '_id' | 'name' | 'type' | 'address'
> & {
  profile: ProfileFragmentType;
  address: Pick<
    AddressType,
    | '_id'
    | 'name'
    | 'street1'
    | 'street2'
    | 'street3'
    | 'city'
    | 'state'
    | 'code'
    | 'country'
    | 'geo'
  >;
};

export type GetInviteResponse = {
  invite: {
    _id: LaneType.UUID;
    name: string;
    email: string;
    status: string;
    expires: Date;
    groupRole: Pick<GroupRole, '_id' | 'name'>;
    company?: GetInviteResponseChannelType;
    channel: GetInviteResponseChannelType;
  };
};

export default gql`
  ${ProfileFragment}

  query getInvite($id: UUID!) {
    invite(_id: $id) {
      _id
      name
      email
      status
      expires
      groupRole {
        _id
        name
      }
      company {
        _id
        name
        type
        profile {
          ...ProfileFragment
        }
        address {
          _id
          name
          street1
          street2
          street3
          city
          state
          code
          country
          geo
        }
      }
      channel {
        _id
        name
        type
        profile {
          ...ProfileFragment
        }
        address {
          _id
          name
          street1
          street2
          street3
          city
          state
          code
          country
          geo
        }
      }
    }
  }
`;
