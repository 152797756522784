import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getMeterReadingValueForExport(
    $meterReadingId: UUID!
    $channelId: String!
    $includeArchived: Boolean
  ) {
    getMeterReadingValueForExport(
      meterReadingId: $meterReadingId
      channelId: $channelId
      includeArchived: $includeArchived
    ) {
      meterReadingValues {
        id
        meterReadingId
        createdBy{
          name
          id
        }
        createdAt
        sourceType
        value
        isArchived
        stepExecutionId
        notes
        taskId
        taskUserFriendlyId
      }
    }
  }
`);
