import {
  resetDateFunctions,
  TimeResetFrequencyEnum,
} from '../../renderers/v5/features/Reset';
import parseDate from '../dates/parseDate';

export const EARLIEST_DATE = new Date(1);
export const LATEST_DATE = new Date(6307200000000);

/**
 * Gets the reset date/time for a Reset Feature.
 *
 * @param lastReset the last known last reset time
 * @param nextReset the last known next reset time
 * @param resetFeature the reset feature to base this off of
 * @param forSetup is this to setup the first time?
 * @returns {{nextReset: Date, lastReset: Date}}
 */
export default function getResetTimes({
  lastReset,
  nextReset,
  resetFeature,
  forSetup,
}: any) {
  if (
    !resetFeature ||
    !resetFeature?.period ||
    resetFeature?.period === TimeResetFrequencyEnum.Never
  ) {
    return {
      lastReset: EARLIEST_DATE,
      nextReset: LATEST_DATE,
    };
  }

  let _lastReset = lastReset || EARLIEST_DATE;
  let _nextReset = forSetup
    ? parseDate(resetFeature.date)
    : nextReset || parseDate(resetFeature.date);

  if (_nextReset > new Date()) {
    // We are not due for a reset yet
    return { lastReset: _lastReset, nextReset: _nextReset };
  }

  // We are going to perform a reset
  let resetFromDate = parseDate(_nextReset);

  // We need to loop here in case one increment is not enough to "catch up"
  // to the current reset period
  while (_nextReset < new Date()) {
    _lastReset = parseDate(resetFromDate);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    _nextReset = resetDateFunctions[resetFeature.period](resetFromDate, 1);
    resetFromDate = _nextReset;
  }

  return { lastReset: _lastReset, nextReset: _nextReset };
}
