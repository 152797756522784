import { createContext } from 'react';

export type UserBillingAndPaymentContextType = {
  isInvoiceAutopayEnabled: boolean;
  updateInvoiceAutopayEnabled: (isEnabled: boolean) => void;
};

export const UserBillingAndPaymentContext = createContext<UserBillingAndPaymentContextType>(
  {
    isInvoiceAutopayEnabled: false,
    updateInvoiceAutopayEnabled: (_: boolean) => {},
  }
);
