import React from 'react';

import {
  BarChart as ReChartBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import COLORS from '../colors';

type OwnProps = {
  height?: string | number;
  width?: string | number;
  margin?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  data: any[];
  bars: any[];
  xAxis?: any;
  yAxis?: any;
  legend?: 'top' | 'middle' | 'bottom';
  tooltipProps?: any;
  legendProps?: any;
  layout?: 'horizontal' | 'vertical';
  background?: string;
  barProps?: any;
  colors?: any[];
};

type Props = OwnProps;

function BarChart({
  data,
  bars,
  margin =  {
    top: 10,
    right: 30,
    left: 0,
    bottom: 0,
  },
  height = 400,
  width = '100%',
  tooltipProps = {},
  legend,
  legendProps = {},
  layout = 'horizontal',
  background = '',
  barProps = {},
  xAxis = {},
  yAxis = {},
  colors = COLORS,
}: Props) {
  const Bars: any = [];
  const gradients: any = [];

  bars.forEach((b, i) => {
    const color = colors[i];

    gradients.push(
      <linearGradient
        key={`linearFradient_for_${b.dataKey}`}
        id={`bar_color_${b.dataKey}`}
        x1="0"
        y1="0"
        x2="0"
        y2="1"
      >
        <stop
          offset="5%"
          stopColor={color.start || color.base}
          stopOpacity={1}
        />
        <stop
          offset="80%"
          stopColor={color.stop || color.base}
          stopOpacity={0.7}
        />
      </linearGradient>
    );

    Bars.push(
      <Bar
        key={`bar_for_${b.dataKey}_${i + 1}`}
        dataKey={b.dataKey}
        name={b.label}
        fill={`url(#bar_color_${b.dataKey})`}
        background={background ? { fill: background } : null}
        {...barProps}
      />
    );
  });

  return (
    <ResponsiveContainer height={height} width={width}>
      <ReChartBarChart data={data} margin={margin} layout={layout}>
        <defs>{gradients}</defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis {...xAxis} />
        <YAxis {...yAxis} />
        <Tooltip {...tooltipProps} />
        {legend && (
          <Legend verticalAlign={legend} height={36} {...legendProps} />
        )}
        {Bars}
      </ReChartBarChart>
    </ResponsiveContainer>
  );
}

export default React.memo(BarChart);
