import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Button } from 'design-system-web';

import {
  ContentWorkflowType,
  DataValidationRuleType,
} from 'lane-shared/types/ContentWorkflowType';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import { MaybeDraftDataValidationRuleType } from 'components/workflows/dataValidationSchema/type';

import { DataValidationRuleV2 } from './DataValidationRuleV2';

import style from './DataValidationSchema.scss';

export type Props = {
  data: PropertiesInterface;
  dataValidationSchema?: ContentWorkflowType['dataValidationSchema'];
  onSchemaUpdated: (
    dataValidationSchema: ContentWorkflowType['dataValidationSchema']
  ) => void;
  editMode?: boolean;
};

export function DataValidationSchemaV2({
  data,
  dataValidationSchema = [],
  onSchemaUpdated,
  editMode = true,
}: Props) {
  const { t } = useTranslation();

  const [draftDataValidationRules, setDraftDataValidationRules] = useState<
    Array<MaybeDraftDataValidationRuleType>
  >([]);

  function addDraftDataValidationRule() {
    setDraftDataValidationRules([...draftDataValidationRules, { _id: uuid() }]);
  }

  function onRuleUpdated(updatedRule: DataValidationRuleType) {
    setDraftDataValidationRules(
      draftDataValidationRules.filter(
        draftRule => draftRule._id !== updatedRule._id
      )
    );
    onSchemaUpdated([
      ...dataValidationSchema?.filter(rule => rule._id !== updatedRule._id),
      updatedRule,
    ]);
  }

  function onRuleDeleted(deletedRule: MaybeDraftDataValidationRuleType) {
    setDraftDataValidationRules(
      draftDataValidationRules.filter(
        draftRule => draftRule._id !== deletedRule._id
      )
    );
    onSchemaUpdated(
      dataValidationSchema?.filter(rule => rule._id !== deletedRule._id)
    );
  }

  return (
    <div className={style.workflowToggleBody}>
      {editMode && (
        <div>
          <Button
            onClick={addDraftDataValidationRule}
            testId="button-add-rule"
            variant="secondary"
            size="medium"
            className={style.addRuleButton}
          >
            {t(
              'web.admin.channel.content.workflow.editor.dataValidationSchema.addRule.button'
            )}
          </Button>
        </div>
      )}
      {dataValidationSchema &&
        dataValidationSchema.map((rule, index) => (
          <div key={rule._id} role="listitem">
            <DataValidationRuleV2
              maybeDraftRule={rule}
              data={data}
              onRuleUpdated={onRuleUpdated}
              onRuleDeleted={onRuleDeleted}
              editMode={editMode}
              ruleNumber={index + 1}
            />
          </div>
        ))}
      {draftDataValidationRules.map(draftRule => (
        <div key={draftRule._id} role="listitem">
          <DataValidationRuleV2
            maybeDraftRule={draftRule}
            data={data}
            onRuleUpdated={onRuleUpdated}
            onRuleDeleted={onRuleDeleted}
            isDraft
            editMode={editMode}
          />
        </div>
      ))}
    </div>
  );
}
