import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query equipmentSettingsOnChannel($channelId: UUID!) {
    equipmentSettingsOnChannel(channelId: $channelId) {
      id
      channel {
        _id
      }
      categories
      locations
    }
  }
`);
