import * as INTERACTION_STATES from '../interactions';

export const ANGUS_ORDER_STATUS = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  DELAYED: 'Delayed',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
};

export const ANGUS_ORDER_STATUS_COLOR = {
  Open: '#E5C34C',
  'In Progress': '#5AC2F9',
  Delayed: '#F0923F',
  Declined: '#E51F05',
  Cancelled: '#E51F05',
  Complete: '#76E599',
  Success: '#72DF95',
  Failure: '#D05462',
};

export function mapAngusStatusToLaneState(angusStatus: string): string {
  switch (angusStatus) {
    case ANGUS_ORDER_STATUS.OPEN:
      return INTERACTION_STATES.INTERACTION_CREATED;
    case ANGUS_ORDER_STATUS.DELAYED:
      return INTERACTION_STATES.INTERACTION_WAITING;
    case ANGUS_ORDER_STATUS.DECLINED:
      return INTERACTION_STATES.INTERACTION_REJECTED;
    case ANGUS_ORDER_STATUS.CANCELLED:
      return INTERACTION_STATES.INTERACTION_CLOSED;
    case ANGUS_ORDER_STATUS.COMPLETED:
      return INTERACTION_STATES.INTERACTION_COMPLETE;
    case ANGUS_ORDER_STATUS.IN_PROGRESS:
    case 'In-Progress':
    default:
      return INTERACTION_STATES.INTERACTION_IN_PROGRESS;
  }
}

export const ANGUS_ORDER_STATUS_LIST = Object.values(ANGUS_ORDER_STATUS);

export const ANGUS_ORDER_FINAL_STATUSES = [
  ANGUS_ORDER_STATUS.DECLINED,
  ANGUS_ORDER_STATUS.CANCELLED,
  ANGUS_ORDER_STATUS.COMPLETED,
];
