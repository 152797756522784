import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class ContinuedAuthForbiddenError extends CodedError {
  static get errorName() {
    return errorCodes.continuedAuthForbiddenError.code;
  }

  constructor(message = errorCodes.continuedAuthForbiddenError.message) {
    super(message);
    this.code = errorCodes.continuedAuthForbiddenError.code;
    this.message = `${message} ${errorCodes.continuedAuthForbiddenError.code}`;
    this.name = errorCodes.continuedAuthForbiddenError.name;
  }
}
