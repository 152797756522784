import React from 'react';

import urlJoin from 'url-join';

import { colors, routes } from '../../config';
import { CURRENCY_CONVERSION } from '../../helpers/constants';
import { convertTo62 } from '../../helpers/convertId';
import {
  generateMenuFeatureQuote,
  getPaymentFeatureCurrency,
} from '../../helpers/features';
import { currencyFormatter } from '../../helpers/formatters';
import i18nStub from '../../helpers/i18nStub';
import { MjmlSection, MjmlColumn, MjmlWrapper } from '../MjmlComponentsV2';
import EmailLabelWithValue from './EmailLabelWithValue';
import EmailMenuOrderCartItem from './EmailMenuOrderCartItem';
import EmailText from './EmailText';

export default function EmailMenuOrderCart({
  menuFeature,
  paymentFeature,
  currency,
  order,
  showSupport,
  userLocale,
  whiteLabel,
  i18n = i18nStub,
  emailBuilderPreview = false,
}: any) {
  const currencyFormat = currencyFormatter({
    locale: userLocale,
    currency: currency || getPaymentFeatureCurrency(paymentFeature),
  });

  const percentageFormat = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  const quote = generateMenuFeatureQuote({
    order,
    menuFeature,
    paymentFeature,
  });

  function renderItem(orderItem: any) {
    // get the original item from the menu
    const item = menuFeature.items.find(
      (item: any) => item._id === orderItem.itemId
    );

    return (
      <EmailMenuOrderCartItem
        key={orderItem._id}
        orderItem={orderItem}
        item={item}
        currencyFormat={currencyFormat}
        i18n={i18n}
      />
    );
  }

  return (
    <MjmlWrapper
      backgroundColor={colors.gray4}
      padding={emailBuilderPreview ? 16 : 0}
      borderRadius="20px"
      border="1px solid #eee"
    >
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <h2>{i18n.t('Your Order')}</h2>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      {order?.items?.map(renderItem)}
      <EmailLabelWithValue
        label={<h3>{i18n.t('Sub Total')}</h3>}
        backgroundColor={colors.gray4}
      >
        <h3>{currencyFormat(quote.total / CURRENCY_CONVERSION)}</h3>
      </EmailLabelWithValue>

      {quote.taxes.map(tax => (
        <EmailLabelWithValue
          key={tax.name}
          label={
            <h3>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              {tax.name} {percentageFormat(tax.rate)}%
            </h3>
          }
          backgroundColor={colors.gray4}
        >
          <h3>{currencyFormat(tax.amount / CURRENCY_CONVERSION)}</h3>
        </EmailLabelWithValue>
      ))}

      <EmailLabelWithValue
        label={<h3>{i18n.t('Total')}</h3>}
        backgroundColor={colors.gray4}
      >
        <h3>
          {currencyFormat((quote.tax + quote.total) / CURRENCY_CONVERSION)}
        </h3>
      </EmailLabelWithValue>

      {showSupport && menuFeature.supportContent && (
        <MjmlSection>
          <MjmlColumn>
            <EmailText fontSize={10}>
              Need help?{' '}
              <a
                href={urlJoin(
                  whiteLabel.baseUrl,
                  routes.content.replace(
                    ':id',
                    convertTo62(menuFeature.supportContent._id)
                  )
                )}
              >
                Contact Support
              </a>
            </EmailText>
          </MjmlColumn>
        </MjmlSection>
      )}
    </MjmlWrapper>
  );
}
