import React from 'react';

import { useIsAdminView } from 'hooks';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { usePublicUserQuery } from 'lane-shared/hooks';
import { useTranslation } from 'react-i18next';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  userId: string;
  // display the admin link? Or the public link?
  forAdmin?: boolean;
};

type Props = OwnProps;

export default function PublicUserLink({
  className,
  style,
  userId,
  forAdmin = false,
}: Props) {
  const { user } = usePublicUserQuery({ userId });
  const [, channelSlug] = useIsAdminView();
  const { t } = useTranslation();

  let url;

  if (forAdmin) {
    url = routes.channelAdminTeamMember
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      .replace(':id', channelSlug)
      .replace(':userId', userId);
  } else {
    url = routes.user.replace(':id', userId);
  }

  return (
    <Link className={className} style={style} to={url}>
      {' '}
      {t('shared.common.byUsername', {
        userName: user?.name,
      })}
    </Link>
  );
}
