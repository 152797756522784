import React from 'react';

import cx from 'classnames';
import { DateTime, Interval } from 'luxon';

import { M } from '../../../index';

import styles from './MonthCell.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  // when element is clicked, returns selected day
  onClick: (date: DateTime) => void;
  // start date
  startDate: DateTime | null;
  // end date
  endDate: DateTime | null;
  // current month
  month: DateTime;
  // formatted day presentation
  text: string;
  timeUnit?: string;
  // is this button enabled or not
  disabled?: boolean;
};

export const YearMonthCell = ({
  className,
  style,
  startDate,
  endDate,
  month,
  onClick,
  timeUnit = 'month',
  text,
  disabled = false,
}: Props) => {
  const isBetween =
    startDate &&
    endDate &&
    startDate < endDate &&
    Interval.fromDateTimes(startDate, endDate).contains(month);

  const currentMonth = DateTime.fromJSDate(new Date());

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const isStartMonth = endDate && startDate?.hasSame(month, timeUnit);

  return (
    <div
      className={cx(styles.MonthCell, className)}
      style={style}
      data-is-selected={isStartMonth}
    >
      <div
        className={cx(styles.buttonContainer, {
          [styles.firstSelected]: isStartMonth,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          [styles.lastSelected]: endDate?.hasSame(month, timeUnit),
          [styles.between]: startDate && endDate && isBetween,
          [styles.monthSelected]:
            endDate &&
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            startDate?.hasSame(endDate, timeUnit) &&
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            startDate?.hasSame(month, timeUnit),
          [styles.unavailable]: disabled,
        })}
      >
        <button
          className={cx(styles.button, {
            [styles.selected]:
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              startDate?.hasSame(month, timeUnit) ||
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              endDate?.hasSame(month, timeUnit),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            [styles.currentMonth]: currentMonth.hasSame(month, timeUnit),
          })}
          onClick={() => onClick(month)}
        >
          <M>{text}</M>
        </button>
      </div>
    </div>
  );
};
