/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable react/forbid-component-props */ // Allows passing component instances as props

import React, { useState, useEffect } from 'react';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import { S } from 'design-system-web';

import { DaySelector } from '../../../visitorManagement/components/DaySelector/DaySelector';

import styles from './WeeklyInterval.scss';

type OnChangeParams = {
  intervalCount: number;
  weekdayRepeats: number[];
};

type Props = {
  defaultIntervalCount: number;
  defaultWeekdayRepeats: number[]; // Assuming default weekday repeats are passed
  className?: string;
  daySelectorClass?: string;
  onChange: (updatedState: OnChangeParams) => void;
};

export function WeeklyInterval({
  defaultIntervalCount,
  defaultWeekdayRepeats,
  onChange,
}: Props) {
  const { t } = useTranslation();

  // Internal state management
  const [intervalCount, setIntervalCount] = useState(defaultIntervalCount);
  const [weekdayRepeats, setWeekdayRepeats] = useState(defaultWeekdayRepeats);

  useEffect(() => {
    onChange({
      intervalCount,
      weekdayRepeats,
    });
  }, [intervalCount, weekdayRepeats, onChange]);

  return (
    <div className={styles.weeklyInterval}>
      <S>{t('web.content.feature.visitorManagement.form.label.every')}</S>
      <Input
        className={styles.input}
        fixedLabel
        type="number"
        onChange={value => {
          setIntervalCount(Number(value));
        }}
        value={String(intervalCount)}
        onBlur={() => {
          if (!intervalCount) {
            setIntervalCount(defaultIntervalCount);
          }
        }}
        rightText={t('web.content.feature.visitorManagement.form.label.week')}
        testId="weeklyIntervalCount"
      />
      <DaySelector
        className={styles.day}
        daySelectorClass={styles.daySelector}
        selected={weekdayRepeats}
        onChange={days => {
          setWeekdayRepeats(days);
        }}
        testId="weeklyDaySelector"
      />
    </div>
  );
}
