import React from 'react';

import { checkSecurityRulesAgainstObject } from '../helpers';
import definitionToProperties from '../helpers/properties/definitionToProperties';
import { PropertiesInterface } from '../types/properties/Property';
import EmailLabelWithValue from './components/EmailLabelWithValue';
import EmailPropertyValue from './components/EmailPropertyValue';

export default function mapPropertyDefinition({
  definition,
  obj,
  creatorId,
  ownerId,
  sourceId,
  user,
}: {
  definition: PropertiesInterface;
  obj: any;
  creatorId: string;
  ownerId: string;
  sourceId: string;
  user: any;
}) {
  return definitionToProperties(definition).map(([key, field]) => {
    if (obj[key] !== undefined) {
      const value = obj[key];

      if (
        !checkSecurityRulesAgainstObject({
          user,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'PropertySecurityRule[] | undefined' is not a... Remove this comment to see the full error message
          rules: field.secure?.read,
          creatorId,
          ownerId,
          sourceId,
        })
      ) {
        return null;
      }

      return (
        <EmailLabelWithValue
          key={key}
          label={field.friendlyName || field.name || key}
        >
          <EmailPropertyValue value={value} field={field} />
        </EmailLabelWithValue>
      );
    }

    return null;
  });
}
