import { useContext, useMemo } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getClient } from '../apollo';
import ChannelsContext from '../contexts/ChannelsContext';
import UserDataContext from '../contexts/UserDataContext';
import { getUserMobileAccess } from '../graphql/user';
import {
  ImplementedAccessControlProvider,
  ImplementedAccessControlProviderValues,
} from '../types/AccessControlType';
import { SearchModeEnum, StringSearchTypeEnum } from '../types/graphql/search';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
} from '../types/integrations/IntegrationEnums';
import { ChannelProviderType } from './types';

type FocusOnChannels = {
  _id: string;
  integrations: ChannelProviderType[] | null;
};

type ChannelsByRelationship = {
  channel: {
    _created: string;
    _id: string;
    integrations: ChannelProviderType[] | null;
  };
};

const queryMyChannelsIntegrations = gql`
  query getMyChannelIntegrations(
    $channelId: UUID!
    $search: ChannelSearch
    $relationshipSearch: ChannelRelationshipSearch
    $skipPagination: Boolean
  ) {
    me {
      switchChannel(channelId: $channelId, search: $search) {
        focusOnChannels {
          integrations {
            _id
            settings
            channel {
              _id
              name
              profile {
                _id
                name
                logo
              }
            }
            integration {
              _id
              name
              type
              category
            }
          }
        }
      }
      channels {
        _id
      }
    }
    channelsByRelationship(
      search: $relationshipSearch
      skipPagination: $skipPagination
    ) {
      items {
        channel {
          _id
          _created
          integrations {
            _id
            settings
            channel {
              _id
              name
              profile {
                _id
                name
                logo
              }
            }
            integration {
              _id
              name
              type
              category
            }
          }
        }
      }
    }
  }
`;

/**
 * Returns all access control integrations from all integrations on the
 * currently focused channels. `channelIntegrations` has all the properties.
 * Use `accessControlProviders` for a unique set of the integration names.
 */
export default function useMyChannelsAccessControlIntegrationsQuery() {
  const { user } = useContext(UserDataContext);
  const { primaryId } = useContext(ChannelsContext);
  const useTenantsAccessIntegrations =
    useFlag(FeatureFlag.UseTenantsAccessIntegrations, false) ?? false;
  const isSwiftConnectEnabled =
    useFlag(FeatureFlag.EnableSwiftConnect, false) ?? false;
  const isMobileKeycardListUpdated =
    useFlag(FeatureFlag.MobileKeycardListUpdated, false) ?? false;
  const isAccessLaneRefactor =
    useFlag(FeatureFlag.AccessLaneRefactor, false) ?? false;

  const { data, loading: loadingQuery, error: errorQuery } = useQuery<{
    me: {
      switchChannel: {
        focusOnChannels: FocusOnChannels[];
      };
      channels: [
        {
          _id: string;
        }
      ];
    };
    channelsByRelationship: {
      items: ChannelsByRelationship[];
    };
  }>(queryMyChannelsIntegrations, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    skip: !user || !primaryId,
    variables: {
      channelId: primaryId,
      search: {
        _id: primaryId,
        integrations: {
          deleted: false,
          integration: {
            category: {
              type: StringSearchTypeEnum.Equal,
              value: IntegrationCategoryEnum.AccessControl,
            },
          },
        },
      },
      relationshipSearch: {
        relatedTo: {
          _id: primaryId,
        },
        channel: {
          integrations: {
            deleted: false,
            integration: {
              category: {
                type: StringSearchTypeEnum.Equal,
                value: IntegrationCategoryEnum.AccessControl,
              },
            },
          },
        },
        searchMode: SearchModeEnum.And,
      },
      skipPagination: true,
    },
  });

  // get a filtered list of all AccessControl channelIntegrations that
  // are active on the channels the user is focused on
  const channelIntegrations = useMemo<ChannelProviderType[]>(() => {
    const integrations = Array.from(
      data?.me?.switchChannel?.focusOnChannels ?? []
    ).flatMap(channel => channel.integrations?.map(mapIntegration) ?? []);
    const userChannelIds = Array.from(
      data?.me?.channels?.map(item => item._id) ?? []
    );

    if (useTenantsAccessIntegrations) {
      Array.from(data?.channelsByRelationship?.items ?? [])
        // Filtering out tenant channels that the user is not part of
        .filter(item => userChannelIds.includes(item.channel._id))
        // Sort from oldest to newest channel
        .sort((a, b) => a.channel._created.localeCompare(b.channel._created))
        .flatMap(item => item.channel.integrations?.map(mapIntegration) ?? [])
        .forEach(tenantItem => {
          if (
            !integrations.find(
              channelItem =>
                channelItem.integration.name === tenantItem.integration.name
            )
          ) {
            // Add tenant integration to channel integrations if not already present
            integrations.push(tenantItem);
          }
        });
    }

    return integrations;
  }, [
    data?.me?.switchChannel?.focusOnChannels,
    data?.channelsByRelationship?.items,
    data?.me?.channels,
    useTenantsAccessIntegrations,
  ]);

  const accessManagementChannel = useMemo<
    ChannelProviderType | undefined
  >(() => {
    return channelIntegrations.find(item => !item.isLegacy);
  }, [channelIntegrations]);

  const {
    data: dataMobileAccess,
    loading: loadingMobileAccess,
    error: errorMobileAccess,
  } = useQuery<{
    me: {
      user: {
        mobileAccess: {
          enabled: boolean;
        };
      };
    };
  }>(getUserMobileAccess, {
    client: getClient(),
    fetchPolicy: 'network-only',
    skip:
      !user || !accessManagementChannel?.settings?.accessControlService?.value,
    variables: {
      channelId: accessManagementChannel?.channel?._id,
      provider: accessManagementChannel?.settings?.accessControlService?.value,
    },
  });

  const userMobileAccessEnabled = useMemo<boolean>(() => {
    return !!dataMobileAccess?.me?.user?.mobileAccess?.enabled;
  }, [dataMobileAccess?.me?.user?.mobileAccess?.enabled]);

  const filteredChannelIntegrations = useMemo<ChannelProviderType[]>(() => {
    // Filtering out integrations that are not part of ImplementedAccessControlProviderValues.
    // This case should not happen.
    let integrations = channelIntegrations.filter(item =>
      ImplementedAccessControlProviderValues.includes(
        item.integration.name as ImplementedAccessControlProvider
      )
    );

    if (!isSwiftConnectEnabled) {
      // Filtering out SwiftConnect integrations
      integrations = integrations.filter(
        item => item.integration.name !== IntegrationProviderEnum.SwiftConnect
      );
    }

    if (isMobileKeycardListUpdated || isAccessLaneRefactor) {
      // Filtering out non-legacy integration when userMobileAccessEnabled is false
      integrations = integrations.filter(
        item => userMobileAccessEnabled || item.isLegacy
      );
    }

    // Filtering out duplicated integrations
    return integrations.filter(onlyUniqueIntegrations);
  }, [
    channelIntegrations,
    isMobileKeycardListUpdated,
    isSwiftConnectEnabled,
    isAccessLaneRefactor,
    userMobileAccessEnabled,
  ]);

  // get a unique set of all access control providers live at these channels.
  const accessControlProviders = useMemo<
    ImplementedAccessControlProvider[]
  >(() => {
    return [
      ...new Set(
        filteredChannelIntegrations.map(
          item => item.integration?.name as ImplementedAccessControlProvider
        )
      ),
    ].sort();
  }, [filteredChannelIntegrations]);

  const loading = useMemo<boolean>(() => {
    if (isMobileKeycardListUpdated || isAccessLaneRefactor) {
      // We use data from mobile access query
      return loadingQuery || loadingMobileAccess;
    }
    return loadingQuery;
  }, [
    isMobileKeycardListUpdated,
    loadingMobileAccess,
    loadingQuery,
    isAccessLaneRefactor,
  ]);

  const error = useMemo<Error | undefined | null>(() => {
    if (isMobileKeycardListUpdated || isAccessLaneRefactor) {
      // We use data from mobile access query
      return errorQuery ?? errorMobileAccess;
    }
    return errorQuery;
  }, [
    errorMobileAccess,
    errorQuery,
    isMobileKeycardListUpdated,
    isAccessLaneRefactor,
  ]);

  return {
    channelIntegrations: filteredChannelIntegrations,
    accessControlProviders,
    loading,
    error,
  };
}

function mapIntegration(
  integrationProvider: ChannelProviderType
): ChannelProviderType {
  if (
    integrationProvider.integration.name ===
    IntegrationProviderEnum.AccessManagement
  ) {
    return {
      ...integrationProvider,
      isLegacy: false,
      integration: {
        ...integrationProvider.integration,
        name: integrationProvider?.settings?.identityProviderService
          ?.name as IntegrationProviderEnum.AccessManagement,
      },
    };
  }

  return { ...integrationProvider, isLegacy: true };
}

function onlyUniqueIntegrations(
  value: ChannelProviderType,
  index: number,
  array: ChannelProviderType[]
) {
  return (
    array.findIndex(
      item => item.integration.name === value.integration.name
    ) === index
  );
}
