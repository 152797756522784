import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class SecureIdRequiredError extends CodedError {
  static get errorName() {
    return errorCodes.secureIdRequiredError.code;
  }

  constructor(message = errorCodes.secureIdRequiredError.message) {
    super(message);
    this.code = errorCodes.secureIdRequiredError.code;
    this.message = `${message} ${errorCodes.secureIdRequiredError.code}`;
    this.name = errorCodes.secureIdRequiredError.name;
  }
}
