import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class AudienceNotFoundError extends CodedError {
  static get errorName() {
    return errorCodes.audienceNotFoundError.code;
  }

  constructor(message = errorCodes.audienceNotFoundError.message) {
    super(message);
    this.code = errorCodes.audienceNotFoundError.code;
    this.message = `${message} ${errorCodes.audienceNotFoundError.code}`;
    this.name = errorCodes.audienceNotFoundError.name;
    this.status = errorCodes.audienceNotFoundError.status;
  }
}
