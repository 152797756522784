import React from 'react';

import { useTranslation } from 'react-i18next';

import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';
import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';

import { Button } from 'components/general';
import PaymentMethodSelectV2 from 'components/payments/PaymentMethodSelectV2';

import PlacePayPaymentModal from '../../payments/PlacePayPaymentModal';
import MenuOrderCart from '../Menu/MenuOrderCart';

type ActionProps = {
  onSubmit: () => void;
  purchaseDisabled: boolean;
};

export function MenuFeatureModalConfirmationMainActions({
  onSubmit,
  purchaseDisabled,
}: ActionProps) {
  const { t } = useTranslation();
  return (
    <Button
      testId="purchaseButton"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"primary"' is not assignable to type '"inher... Remove this comment to see the full error message
      color="primary"
      disabled={purchaseDisabled}
      onClick={onSubmit}
      fullWidth
    >
      {t('abp.payment.orderSummary.purchase')}
    </Button>
  );
}

type Props = {
  interaction: any;
  menuFeature: any;
  currency: string | undefined;
  paymentFeature: any;
  onRemoveOrderItem: (...args: any) => void;
  paymentAccounts: PaymentAccountType[];
  selectedPaymentAccount: PaymentAccountType | null;
  setSelectedPaymentAccount: React.Dispatch<
    React.SetStateAction<PaymentAccountType | null>
  >;
  paymentMethods?: any[];
  selectedPaymentMethod: any;
  setSelectedPaymentMethod: React.Dispatch<any>;
  setModalHistory: React.Dispatch<any>;
  isPlacePayOpen: boolean;
  setIsPlacePayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  placePayRef: React.MutableRefObject<{
    reject: () => null;
    resolve: () => null;
  }>;
  quote: FeatureQuoteType | null;
  content: any;
  setInitialOrder: (props: any) => void;
  onUpdateOrder: (props: any) => void;
};

export function MenuFeatureModalConfirmationMain({
  interaction,
  menuFeature,
  currency,
  paymentFeature,
  onRemoveOrderItem,
  paymentAccounts,
  selectedPaymentAccount,
  setSelectedPaymentAccount,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  setModalHistory,
  isPlacePayOpen,
  setIsPlacePayOpen,
  placePayRef,
  quote,
  content,
  setInitialOrder,
  onUpdateOrder,
}: Props) {
  return (
    <>
      <div>
        <MenuOrderCart
          order={interaction.features.Menu.order}
          menuFeature={menuFeature}
          currency={currency as any}
          paymentFeature={paymentFeature}
          onRemoveOrderItem={onRemoveOrderItem}
          setInitialOrder={setInitialOrder}
          onUpdateOrder={onUpdateOrder}
        />

        {(paymentFeature || menuFeature) && (
          <PaymentMethodSelectV2
            paymentAccounts={paymentAccounts}
            selectedPaymentAccount={selectedPaymentAccount ?? undefined}
            onSelectPaymentAccount={setSelectedPaymentAccount}
            paymentMethods={paymentMethods}
            selectedPaymentMethod={selectedPaymentMethod ?? undefined}
            onSelectPaymentMethod={setSelectedPaymentMethod}
            onConfigure={(configureProvider, options) => {
              switch (configureProvider) {
                case PaymentProviderEnum.Stripe: {
                  if (options.flow != null) {
                    setModalHistory((prevModalHistory: any) => [
                      ...prevModalHistory,
                      options.flow,
                    ]);
                  }
                }
              }
            }}
          />
        )}
      </div>

      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'mode' is missing in type '{ isOpen: bool... Remove this comment to see the full error message */}
      <PlacePayPaymentModal
        isOpen={isPlacePayOpen}
        paymentAccountId={selectedPaymentAccount?._id}
        payeeId={(quote as any)?.data?.orgpayeeid}
        amount={
          (quote as any)?.data?.overage
            ? (quote as any)?.data?.overage?.finalPriceInCents
            : // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              quote?.total + quote?.tax
        }
        description={content?.name}
        onClose={() => {
          setIsPlacePayOpen(false);
          placePayRef.current.reject();
        }}
        onPaymentSuccess={data => {
          setIsPlacePayOpen(false);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.resolve(data);
        }}
        onPaymentFailed={err => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.reject(err);
          setIsPlacePayOpen(false);
        }}
      />
    </>
  );
}
