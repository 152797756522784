import React, { useImperativeHandle, useState, forwardRef } from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import XPlacesAutocomplete, {
  geocodeByAddress,
  getLatLng, // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
} from 'react-places-autocomplete';

import { AddressType } from 'lane-shared/types/AddressType';

import useModalPosition, { ModalPositionEnum } from 'hooks/useModalPosition';

import styles from './PlacesAutocomplete.scss';

function getComponent(components: any, name: any) {
  const component = components
    .filter((c: any) => c)
    .find((component: any) =>
      component.types.find((type: any) => type === name)
    );

  return component && component.long_name ? component.long_name : '';
}

export type PlacesAutocompleteHandle = {
  focus: () => void;
  blur: () => void;
  click: () => void;
};

type Props = {
  style?: React.CSSProperties;
  className?: string;
  value: string;
  placeholder?: string;
  onChange: (address: AddressType) => void;
  disabled?: boolean;
};

const PlacesAutocomplete = forwardRef<PlacesAutocompleteHandle, Props>(
  ({ value, placeholder, disabled, onChange, style, className }, ref) => {
    const [search, setSearch] = useState(value);
    const {
      buttonRef,
      modalRef,
      childrenRef,
      onOpen,
      position,
    } = useModalPosition({
      autoFocus: false,
      align: ModalPositionEnum.Dropdown,
    });
    const { t } = useTranslation();

    placeholder = t(
      'web.admin.content.metatag.editComplexProperties.addItemModal.address.searchPlaces'
    );

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (childrenRef.current) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          childrenRef.current.focus();
        }
      },
      blur: () => {
        if (childrenRef.current) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          childrenRef.current.blur();
        }
      },
      click: () => {
        if (childrenRef.current) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          childrenRef.current.click();
        }
      },
    }));

    async function selected(item: any) {
      const results = await geocodeByAddress(item);
      const location = await getLatLng(results[0]);
      const components = results[0].address_components;

      const address: AddressType = {
        street1: `${getComponent(components, 'street_number')} ${getComponent(
          components,
          'route'
        )}`,
        city: getComponent(components, 'locality'),
        state: getComponent(components, 'administrative_area_level_1'),
        country: getComponent(components, 'country'),
        code: getComponent(components, 'postal_code'),
        geo: {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ longitude: any; latitude: any; }' is not a... Remove this comment to see the full error message
          longitude: location.lng,
          latitude: location.lat,
        },
      };

      setSearch(item);
      onChange(address);
    }

    return (
      <div
        className={cx(styles.PlacesAutocomplete, className)}
        style={style}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
        ref={buttonRef}
      >
        <XPlacesAutocomplete
          value={search}
          onChange={setSearch}
          onSelect={selected}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
            <div className={styles.search}>
              <input
                {...getInputProps({
                  placeholder,
                  className: styles.input,
                })}
                onClick={onOpen}
                disabled={disabled}
                ref={childrenRef}
                data-test="placeAutocompleteSearch"
              />
              {suggestions.length > 0 &&
                ReactDOM.createPortal(
                  <ul
                    className={styles.results}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
                    ref={modalRef}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ top: number; left: number; height?: number... Remove this comment to see the full error message
                    style={position}
                    data-test="addressDropdown"
                  >
                    {suggestions.map((suggestion: any) => (
                      <li
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion)}
                        data-is-active={suggestion.active}
                      >
                        <span>{suggestion.description}</span>
                      </li>
                    ))}
                  </ul>,
                  document.body
                )}
            </div>
          )}
        </XPlacesAutocomplete>
      </div>
    );
  }
);

export default PlacesAutocomplete;
