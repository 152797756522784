import gql from 'graphql-tag';

export default gql`
  mutation updateChannelModule($channelModule: ChannelModuleInput!) {
    updateChannelModule(channelModule: $channelModule) {
      _id
      name
      friendlyName
      description
      availableFeatures
      allowsMultipleInstances
      autoCreatesContent
      createsAdminPage
      disabled
      dataDefinition
      contentType
      channelId
    }
  }
`;
