import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class BadRequestError extends CodedError {
  static get errorName() {
    return errorCodes.badRequestError.code;
  }

  constructor(message = errorCodes.badRequestError.message) {
    super(message);
    this.code = errorCodes.badRequestError.code;
    this.message = `${message} ${errorCodes.badRequestError.code}`;
    this.name = errorCodes.badRequestError.name;
    this.status = errorCodes.badRequestError.status;
  }
}
