import React, { useState, useRef, useEffect, useContext } from 'react';

import cx from 'classnames';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { Query } from '@apollo/client/react/components';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getMetatagsOnChannel } from 'lane-shared/graphql/query';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import { useLibraryPattern } from 'lane-shared/hooks';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import ArrayElement from 'lane-shared/types/utility/ArrayElement';

import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import CopyButton from 'components/general/CopyButton';
import IconButton from 'components/general/IconButton';
import Loading from 'components/general/Loading';
import Pagination from 'components/general/Pagination';
import MetatagListItem from 'components/lane/MetatagListItem';

import styles from './MetatagLibrary.scss';

const views = ['list', 'th-large'];
const [VIEW_LIST, VIEW_GRID] = views;

const DEBOUNCE_THROTTLE = 500;
const PER_PAGE = 25;

const SORT_BY = [
  { label: 'Created', value: '_created' },
  { label: 'Updated', value: '_updated' },
  { label: 'Name', value: 'name' },
] as const;

type ValueType = ArrayElement<typeof SORT_BY>['value'];

const sortOrders = ['asc', 'desc'] as const;
const [SORT_ASC, SORT_DESC] = sortOrders;

type Props = {
  libraries: LibraryType[];
  library?: LibraryType;
  className?: string;
  style?: React.CSSProperties;
  channelId?: string;
  onMetatagSelected: (metatag: any) => void;
};

export default function MetatagLibrary({
  libraries,
  library,
  className,
  style,
  channelId,
  onMetatagSelected,
}: Props) {
  const {
    selectedLibrary,
    setSelectedLibrary,
    availableLibraries,
  } = useLibraryPattern({
    libraries,
    library,
    storageKey: library?._id || channelId,
  });
  const { t } = useTranslation();
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);
  const { user } = useContext(UserDataContext);
  const [selectedMetatag, setSelectedMetatag] = useState(null);
  const [isAdminView, channelSlug] = useIsAdminView();
  const [view, setView] = useState(VIEW_GRID);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState<ValueType>(SORT_BY[0].value);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(SORT_DESC);
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);
  const refetchRef = useRef(null);

  useEffect(() => {
    setPage(0);
  }, [search]);

  function onChangeLibrary(value: any) {
    const newLibrary = availableLibraries.find(
      library => library._id === value
    );
    setPage(0);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'LibraryType | undefined' is not ... Remove this comment to see the full error message
    setSelectedLibrary(newLibrary);
  }

  function onMetatagClicked(metatag: any) {
    setSelectedMetatag(metatag);
    onMetatagSelected(metatag);
  }

  let addUrl;

  if (isAdminView) {
    const createFilterRoute = areContentTagsEnabled
      ? routes.channelAdminMetatagNew
      : routes.legacyChannelAdminMetatagNew;
    addUrl = createFilterRoute.replace(':id', channelSlug!);
  }

  const variables = {
    pagination: {
      start: page * PER_PAGE,
      perPage: PER_PAGE,
    },
    channelId: selectedLibrary?._id,
    search: {
      name: { type: 'like', value: debouncedSearch },
      sortBy: { key: sortBy, dir: sortOrder },
    },
  };

  if (!selectedLibrary) {
    return <Loading />;
  }

  return (
    <div className={cx(styles.MetatagLibrary, className)} style={style}>
      <ControlMenu>
        {availableLibraries.length > 1 && (
          <Dropdown
            items={availableLibraries.map(library => ({
              label: library.name,
              value: library._id,
            }))}
            className={styles.libraries}
            placeholder={t('web.admin.content.metatag.index.selectALibrary')}
            value={selectedLibrary && selectedLibrary._id}
            onValueChange={onChangeLibrary}
            testId="libraryDropdown"
          />
        )}
        <Input
          className={styles.input}
          icon="search"
          onChange={search => setSearch(search)}
          value={search}
          placeholder={t('web.admin.content.metatag.index.search')}
          showClear
        />

        <Dropdown
          className={styles.sortBy}
          onValueChange={sortBy => setSortBy(sortBy)}
          items={[...SORT_BY]}
          value={sortBy}
        />

        <IconButton
          inverted
          icon={sortOrder === SORT_ASC ? 'chevron-down' : 'chevron-up'}
          onClick={() =>
            setSortOrder(sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC)
          }
        />

        {views.map(v => (
          <IconButton
            key={v}
            icon={v}
            selected={view === v}
            onClick={() => setView(v)}
            inverted
          />
        ))}

        {addUrl && (
          <Link to={addUrl}>
            <Button variant="contained" testId="buttonNew">
              {t('web.admin.content.metatag.index.new')}
            </Button>
          </Link>
        )}
      </ControlMenu>

      {selectedLibrary && (
        <Query
          query={getMetatagsOnChannel}
          skip={!selectedLibrary?._id}
          variables={variables}
          fetchPolicy="network-only"
        >
          {({ data, refetch }: any) => {
            const metatags = (data?.metatagsOnChannel?.items || [])
              .map((item: any) => item.metatag)
              .filter((item: any) => item != null);

            const total = data?.metatagsOnChannel?.pageInfo?.total || 0;

            refetchRef.current = refetch;

            return (
              <>
                {VIEW_GRID === view && (
                  <ul className={styles.results}>
                    {metatags.map((metatag: any) => (
                      <MetatagListItem
                        key={metatag._id}
                        className={styles.metatagListItem}
                        isSelected={
                          metatag._id === (selectedMetatag as any)?._id
                        }
                        onSelected={() => onMetatagClicked(metatag)}
                        metatag={metatag}
                        testId="metatag"
                      />
                    ))}
                  </ul>
                )}

                {view === VIEW_LIST && (
                  <div className={styles.tableWrapper}>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            {t('web.admin.content.metatag.index.created')}
                          </th>
                          <th>
                            {t('web.admin.content.metatag.index.lastUpdate')}
                          </th>
                          <th>{t('web.admin.content.metatag.index.name')}</th>
                          <th aria-label="utility icon section" />
                        </tr>
                      </thead>
                      <tbody>
                        {metatags.map((metatag: any) => (
                          <tr
                            key={metatag._id}
                            onClick={() => onMetatagClicked(metatag)}
                            data-is-selected={
                              metatag._id === (selectedMetatag as any)?._id
                            }
                          >
                            <td>
                              {simpleDate(
                                metatag._created,
                                undefined,
                                user?.locale
                              )}
                            </td>
                            <td>
                              {fromNow(
                                metatag._updated,
                                undefined,
                                user?.locale
                              )}
                            </td>
                            <td>{metatag.name}</td>
                            <td>
                              <CopyButton value={convertToUUID(metatag?._id)} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  loading={data?.loading}
                  total={total}
                  perPage={PER_PAGE}
                  page={page}
                  onPage={setPage}
                />
              </>
            );
          }}
        </Query>
      )}
    </div>
  );
}
