import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class TabIconExistsError extends CodedError {
  static get errorName() {
    return errorCodes.tabIconExistsError.code;
  }

  constructor(message = errorCodes.tabIconExistsError.message) {
    super(message);
    this.code = errorCodes.tabIconExistsError.code;
    this.message = `${message} ${errorCodes.tabIconExistsError.code}`;
    this.name = errorCodes.tabIconExistsError.name;
  }
}
