import gql from 'graphql-tag';

/**
 * GQL Update Preventive Maintenance Task Mutation
 */

export default gql`
  mutation updatePMTaskMutation($task: UpdateTask!) {
    updateTask(task: $task) {
      id
      updatedAt
    }
  }
`;
