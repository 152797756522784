import { ErrorCodeType } from '../constants/errorCodes';

export const bpErrorsCodes = {
  chargeVoidFailed: {
    message: 'abp.charges.chargeVoidFailed',
  } as ErrorCodeType,
  chargeAlreadyVoided: {
    code: 'Current state: VOIDED',
    message: 'abp.charges.chargeAlreadyVoided',
  } as ErrorCodeType,
  chargeAlreadyPaid: {
    code: 'Invoice is already PAID',
    message: 'abp.charges.chargeAlreadyPaid',
  } as ErrorCodeType,
  invoiceAlreadyPaid: {
    code: 'Current state: PAID',
    message: 'abp.invoice.markAsPaidSuccessMessage',
  },
  invoiceNotIssued: {
    code: 'Current state: CHARGE_ADDED',
    message: 'abp.invoice.invoiceNotIssued',
  },
} as const;

export const bpGRPCErrorMessages = {
  statusUnavailable: {
    message: 'abp.errors.grpc.statusUnavailable',
  } as ErrorCodeType,
  statusInternal: {
    message: 'abp.errors.grpc.statusInternal',
  } as ErrorCodeType,
  statusNotFound: {
    message: 'abp.errors.grpc.statusNotFound',
  },
  statusDefaultError: {
    message: 'abp.errors.grpc.statusDefaultError',
  },
} as const;
