import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class OpenpathInvalidFieldError extends CodedError {
  static get errorName() {
    return errorCodes.openpathInvalidFieldError.code;
  }

  constructor(message = errorCodes.openpathInvalidFieldError.message) {
    super(message);
    this.code = errorCodes.openpathInvalidFieldError.code;
    this.message = `${message} ${errorCodes.openpathInvalidFieldError.code}`;
    this.name = errorCodes.openpathInvalidFieldError.name;
  }
}
