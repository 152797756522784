import {
  ContentWorkflowEventType,
  ContentWorkflowType,
  ContentWorkflowWorkflowDataType,
} from '../../types/ContentWorkflowType';
import {
  WorkflowTargetEnum,
  WorkflowActionEnum,
  WorkflowWhenEnum,
  WorkflowWhenContextEnum,
} from '../../types/Workflows';
import { EVENT_CONTENT_INTERACTION_CREATED } from '../constants/events';
import { INTERACTION_CREATED } from '../constants/interactions';
import { v4 as uuid } from 'uuid';

export function constructDefaultWorkflowData(): ContentWorkflowWorkflowDataType {
  return {
    on: 'status',
    value: INTERACTION_CREATED,
  };
}

export type ConstructWorkflowProps = {
  userId: string;
  contentId: string;
  groupRoleId?: string;
  toUserId?: string;
  when?: WorkflowWhenEnum;
  whenContext?: WorkflowWhenContextEnum;
  action?: WorkflowActionEnum;
  target?: WorkflowTargetEnum;
  event?: ContentWorkflowEventType;
  dataValidationSchema?: ContentWorkflowType['dataValidationSchema'];
  time?: number;
};

export default function constructWorkflow({
  userId,
  contentId,
  groupRoleId,
  toUserId,
  when = WorkflowWhenEnum.Immediate,
  whenContext = WorkflowWhenContextEnum.UserContentInteraction,
  action = WorkflowActionEnum.Notification,
  target = WorkflowTargetEnum.InteractionCreator,
  event = EVENT_CONTENT_INTERACTION_CREATED,
  dataValidationSchema = undefined,
  time,
}: ConstructWorkflowProps): ContentWorkflowType {
  const workflow: ContentWorkflowType = {
    _id: uuid(),
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    content: {
      _id: contentId,
    },
    when,
    whenContext,
    event,
    action,
    target,
    groupRole: groupRoleId ? { _id: groupRoleId } : null,
    user: toUserId ? { _id: toUserId } : null,
    workflow: constructDefaultWorkflowData(),
    dataValidationSchema,
    inStatus: null,
    payload: {
      intro: undefined,
      outtro: undefined,
      body: undefined,
      qrCodeCheckIn: false,
      showContentName: false,
      showDateTime: false,
    },
  };
  if (time) workflow.time = time;
  return workflow;
}
