import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import PropertyValue from './PropertyValue';

import styles from './PropertiesDataTable.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  fields: {
    _id: string;
    friendlyName?: string;
    name: string;
  }[];
  items: Array<{
    _id: string;
    [key: string]: string;
  }>;
  OptionsControl?: React.ReactNode;
};

type Props = OwnProps;

export default function PropertiesDataTable({
  className,
  style,
  fields,
  items,
  OptionsControl,
}: Props) {
  return (
    <table className={cx(styles.PropertiesDataTable, className)} style={style}>
      <thead>
        <tr>
          {fields?.map(field => (
            <th key={field._id}>{field.name}</th>
          ))}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          {OptionsControl && <th />}
        </tr>
      </thead>
      <tbody>
        {items?.map(item => (
          <tr key={item._id}>
            {fields.map(field => (
              <td key={field._id}>
                {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'type' is missing in type '{ _id: string;... Remove this comment to see the full error message */}
                <PropertyValue field={field} value={item[field.name]} />
              </td>
            ))}
            {OptionsControl && (
              <td>
                {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'OptionsControl' does not have an... Remove this comment to see the full error message */}
                <OptionsControl value={item} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

PropertiesDataTable.propTypes = {
  fields: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  OptionsControl: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};
