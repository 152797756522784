import gql from 'graphql-tag';

import {
  ChannelLanguagesType,
  EnabledLanguagesForMembersType,
} from '../../types/ChannelType';

export type ChannelSettingsRequirementsFragmentType = {
  // TODO: these are optional based on RequirementsFeatureProperties but I think that's wrong...
  profilePictureRequired?: boolean;
  fullNameRequired?: boolean;
  phoneNumberRequired?: boolean;
  companyNameRequired?: boolean;
  verifiedEmailRequired?: boolean;
  multiLanguageEnabled?: boolean;
  channelLanguages?: ChannelLanguagesType;
  enabledLanguagesForMembers?: EnabledLanguagesForMembersType;
};

export default gql`
  fragment ChannelSettingsRequirementsFragment on RequirementsFeatureProperties {
    profilePictureRequired
    fullNameRequired
    phoneNumberRequired
    companyNameRequired
    multiLanguageEnabled
    channelLanguages
    enabledLanguagesForMembers
    contentInteractionRequired {
      _id
      content {
        _id
      }
      header
      description
    }
  }
`;
