import { DropdownItem } from 'design-system-web';
import { UserStatusEnum } from 'lane-shared/types/User';
import { ValidationError } from 'yup';

export type BulkUserType = {
  name: string;
  email: string;
  validation?: null | ValidationError;
  wasSent?: boolean;
  error?: null | Error;
};

export type InputType = {
  label: string;
  placeholder: string;
  key: string;
  type: InputTypeEnum;
  items?: DropdownItem<string>[];
};

export enum InputTypeEnum {
  String = 'string',
  Boolean = 'boolean',
  Dropdown = 'dropdown',
  MultiSelect = 'multiSelect',
}

export type AddUserType = BulkUserType & {
  phoneNumber: string;
  marketingOptIn: boolean;
  teams: DropdownItem<string>[];
  userStatus: UserStatusEnum;
  tenant: string;
};
