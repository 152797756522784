import gql from 'graphql-tag';

export default gql`
  mutation revokeCredentialGroup(
    $channelId: UUID!
    $credentialGroupId: String!
    $provider: String!
  ) {
    revokeCredentialGroup(
      channelId: $channelId
      credentialGroupId: $credentialGroupId
      provider: $provider
    )
  }
`;
