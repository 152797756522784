import gql from 'graphql-tag';

export default gql`
  query channelUsers(
    $channelId: UUID!
    $search: UserSearch
    $pagination: PaginationInput
  ) {
    channelPublicUsers(
      channelId: $channelId
      pagination: $pagination
      search: $search
    ) {
      pageInfo {
        perPage
        total
      }
      items {
        _id
        profile {
          _id
          name
          email
          image
          phone
          description
          isPublic
        }
      }
    }
  }
`;
