import React from 'react';

import cx from 'classnames';
import { Dropdown } from '../Dropdown/Dropdown';
import { IconButton } from '../IconButton/IconButton';
import { useTranslation } from 'react-i18next';

import styles from './Pagination.scss';
import { numberFormatter } from 'lane-shared/helpers/formatters/numberFormatter';

export type PaginationProps = {
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  page: number;
  perPage: number;
  perPageOptions?: number[];
  total: number;
  pagesToShow?: number;
  testId?: string;
  onPage: (page: number) => void;
  onPerPage?: (perPage: number) => void;
  showPagination?: boolean;
};

export const Pagination = ({
  className,
  style,
  loading = false,
  page,
  perPage = 10,
  perPageOptions = [10, 15, 20, 25],
  total,
  pagesToShow = 10,
  onPage,
  onPerPage,
  showPagination = true,
}: PaginationProps) => {
  const pages = Math.ceil(total / perPage);
  const { t } = useTranslation();

  const onPageForward = () => onPage(page + 1);
  const onFirstPage = () => onPage(0);
  const onLastPage = () => onPage(pages - 1);
  const onPageBack = () => onPage(page - 1);

  const control = (forPage: any) => (
    <button
      key={forPage}
      title={`Results ${forPage * perPage} to ${(forPage + 1) * perPage}`}
      data-selected={forPage === page}
      onClick={() => onPage(forPage)}
      className={styles.pageNumber}
      data-test={`page-${forPage}`}
    >
      {forPage + 1}
    </button>
  );

  function renderPages() {
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    const controls: React.ReactElement[] = [];

    if (pages <= pagesToShow) {
      return new Array(pages).fill(0).map((a, i) => control(i));
    }

    if (page <= halfPagesToShow) {
      for (let i = 0; i < pagesToShow; i++) {
        controls.push(control(i));
      }

      controls.push(<span key="more-right">...</span>);

      controls.push(control(pages - 1));
    } else if (page + halfPagesToShow >= pages) {
      controls.push(control(0));
      controls.push(<span key="more-left">...</span>);
      for (let i = pages - pagesToShow; i < pages; i++) {
        controls.push(control(i));
      }
    } else {
      controls.push(control(0));
      controls.push(<span key="more-left">...</span>);

      for (let i = page - halfPagesToShow; i < page + halfPagesToShow; i++) {
        controls.push(control(i));
      }

      controls.push(<span key="more-right">...</span>);
      controls.push(control(pages - 1));
    }

    return controls;
  }

  if (!showPagination || (!onPerPage && pages < 2)) {
    return null;
  }

  const spaceBetween = onPerPage ? styles.spaceBetween : '';

  return (
    <menu
      className={cx(styles.pagination, className, spaceBetween)}
      style={style}
    >
      {onPerPage && (
        <div className={styles.perPageContainer}>
          <label className={styles.numberOfItems}>
            {t('web.components.table.totalItems', {
              count: total,
              totalItems: numberFormatter(total),
            })}
          </label>
          <div className={styles.dropdownWrapper}>
            <Dropdown
              items={perPageOptions.map(option => ({
                value: option,
                label: option.toString(),
              }))}
              value={perPage}
              onChange={item => {
                onPerPage(item.value);
              }}
              className={styles.dropdown}
              ariaLabel={t('web.pagination.selectResultsPerPage')}
              isFullWidth
              isSearchable={false}
            />
          </div>
          <label className={styles.perPageLabel}>
            {t('web.pagination.perPage')}
          </label>
        </div>
      )}
      <div className={styles.pageNumbersWrapper}>
        <IconButton
          inverted
          disabled={loading || page < 1}
          className={styles.arrowIcon}
          onClick={onFirstPage}
          label={t('First page')}
          icon="chevron-double-left"
        />
        <IconButton
          inverted
          disabled={loading || page < 1}
          className={styles.arrowIcon}
          onClick={onPageBack}
          label={t('Previous page')}
          icon="chevron-left"
        />
        <div className={styles.pages}>{renderPages()}</div>
        <IconButton
          inverted
          disabled={loading || page >= pages - 1}
          className={styles.arrowIcon}
          onClick={onPageForward}
          icon="chevron-right"
          label={t('Next page')}
          testId="nextPageArrow"
        />
        <IconButton
          inverted
          disabled={loading || page >= pages - 1}
          className={styles.arrowIcon}
          onClick={onLastPage}
          label={t('Last page')}
          testId="lastPageArrow"
          icon="chevron-double-right"
        />
      </div>
    </menu>
  );
};
