import React from 'react';

import { GuestInviteGuestType } from '../../renderers/v5/features/types/GuestInviteGuestType';
import { MjmlSection, MjmlColumn } from '../MjmlComponentsV2';
import EmailText from './EmailText';

export default function EmailGuestInviteGuest({
  guest,
}: {
  guest: GuestInviteGuestType;
}) {
  return (
    <MjmlSection paddingTop={0} paddingBottom={0}>
      <MjmlColumn>
        <EmailText>
          <b>Name</b>
        </EmailText>
      </MjmlColumn>
      <MjmlColumn>
        <EmailText>{guest.name}</EmailText>
      </MjmlColumn>
      <MjmlColumn>
        <EmailText>
          <b>Email</b>
        </EmailText>
      </MjmlColumn>
      <MjmlColumn>
        <EmailText>{guest.email}</EmailText>
      </MjmlColumn>
      {guest.company && (
        <>
          <MjmlColumn>
            <EmailText>
              <b>Company</b>
            </EmailText>
          </MjmlColumn>
          <MjmlColumn>
            <EmailText>{guest.company}</EmailText>
          </MjmlColumn>
        </>
      )}
    </MjmlSection>
  );
}
