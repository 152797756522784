import gql from 'graphql-tag';

export type DeleteInviteResponse = {
  deleteInvite: boolean;
};

export const deleteInvite = gql`
  mutation deleteInvite($id: UUID!) {
    deleteInvite(_id: $id)
  }
`;
