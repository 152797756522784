import gql from 'graphql-tag';

export default gql`
  query getServiceRequestsByContentIdQuery($contentId: String!) {
    serviceRequests(contentId: $contentId) {
      serviceRequests {
        serviceRequestId
        createdBy {
          name
          id
        }
        createdAt
        updatedAt
        updatedBy {
          name
          id
        }
        description
        category
        issue
        location
        floor
        company {
          name
          id
        }
        suite
        assignee {
          name
          id
        }
        status
        customQuestions {
          Question
          Answer
        }
        isBillable
        friendlyID
        userFriendlyID
        nextStatuses
      }
    }
  }
`;
