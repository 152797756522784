import gql from 'graphql-tag';

import { getClient } from '../apollo';
import BatchHelper from './BatchHelper';

const cache = {};

export type Action = 'viewed' | 'details' | 'interaction';
export type ObjectType = 'Content' | 'Channel';

const eventMap = {
  view: 'viewed',
  details: 'details',
  interaction: 'interaction',
};

/**
 * We want to track views of Content, Channels, Users, etc from the User.
 *
 * But this will generate a lot of traffic from the web / mobile device. This
 * will batch those mutations and send them out in groups.
 */
class ObjectTouchHelper extends BatchHelper {
  constructor() {
    super({
      batchSize: 20,
      maxTimeout: 10 * 1000, // 10 second time limit
    });
  }

  mapAction(event: 'view' | 'details' | 'interaction'): Action {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Action'.
    return eventMap[event];
  }

  touch(obj: any, action: Action = 'viewed', type: ObjectType = 'Content') {
    if (!obj || !obj._id) {
      return;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!cache[obj._id] && (!obj.info || (obj.info && !obj.info[action]))) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      cache[obj._id] = true;

      this.push(`
        c${obj._id}: touch${type}(_id: "${obj._id}", touch: { ${action}: true }) {
          _id 
          info {
            _id 
            ${action}
          }
        }
      `);
    }
  }

  async doAction(items: any) {
    const query = `mutation TouchObjects {
      ${items.join('\n')}
    }`;

    await getClient().mutate({
      mutation: gql(query),
    });
  }
}

export default new ObjectTouchHelper();
