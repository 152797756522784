import gql from 'graphql-tag';

import {
  UserGroupRoleFragment,
  ProfileFragment,
  AddressFragment,
} from '../fragments';

export default gql`
  ${ProfileFragment}
  ${AddressFragment}
  ${UserGroupRoleFragment}

  query initializeUser {
    me {
      sessionId
      user {
        _id
        _created
        _updated
        status
        name
        locale
        launchDarklyHash
        isSuperUser
        requestAccountDeletionByDate
        logins {
          _id
          _created
          _updated
          type
          provider
          isPrimary
          key
          status
          tokenExpiresAt
        }
        roles {
          ...UserGroupRoleFragment
        }
        addresses {
          ...AddressFragment
        }
        profile {
          ...ProfileFragment
        }
      }
    }
  }
`;
