import gql from 'graphql-tag';

/**
 * GQL Update Preventive Maintenance Task Mutation
 */

export default gql`
  mutation updatePMTaskStepsMutation($task: UpdateTaskSteps!) {
    updateTaskSteps(task: $task) {
      id
      updatedAt
    }
  }
`;
