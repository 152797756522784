import gql from 'graphql-tag';

import { MetatagFragment, ObjectMetatagFragment } from '../fragments';

export default gql`
  ${MetatagFragment}
  ${ObjectMetatagFragment}

  mutation UpdateDraftContentMetatags($draft: DraftContentInput!) {
    updateDraft(draft: $draft) {
      _id
      contentMetatags {
        ...ObjectMetatagFragment
      }
    }
  }
`;
