import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Flex } from '../../index';
import { useTranslation } from 'react-i18next';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { DatePickerButton } from './DatePickerButton';
import { TimePicker } from './TimePicker';
import { handleTimeClick } from './helpers';

import styles from './DateTimeRangePicker.scss';

type DateTimeRangePickerProps = {
  value?: DateRangeType;
  timeZone?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange?: ((value: DateRangeType) => void) | undefined;
  unavailableDateRanges?: DateRangeType[];
  className?: string;
  unit?: number;
  style?: React.CSSProperties;
};

export const DateTimeRangePicker = ({
  value,
  timeZone,
  disabled = false,
  onChange = () => {},
  unavailableDateRanges,
  className,
  style,
  unit,
}: DateTimeRangePickerProps) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<DateRangeType>();
  const [time, setTime] = useState<DateRangeType>();

  useEffect(() => {
    if (date?.startDate && date?.endDate) {
      onChange(date);
    }
  }, [date?.startDate, date?.endDate]);

  function onHandleClickTime(timeValue: any, unit: any) {
    handleTimeClick(timeValue, 'startDate', setDate, value, date, timeZone);
    setTime(prev => ({ ...prev, [unit]: timeValue }));
  }

  return (
    <div className={cx(styles.DateTimeRangePicker, className)} style={style}>
      <Flex gap={2}>
        <DatePickerButton
          disabled={disabled}
          timeZone={timeZone}
          onChange={(date: any) =>
            setDate(prev => ({ ...prev, startDate: date, endDate: date }))
          }
          value={date?.startDate}
          unavailableDateRanges={unavailableDateRanges}
          includeTime={false}
        />
        <TimePicker
          className={styles.timePicker}
          timeZone={timeZone}
          value={time?.startTime}
          label={t('Start time')}
          unit={unit}
          onChange={startTime => onHandleClickTime(startTime, 'startTime')}
        />
        <TimePicker
          className={styles.timePicker}
          value={time?.endTime}
          timeZone={timeZone}
          label={t('End Time')}
          unit={unit}
          onChange={endTime => onHandleClickTime(endTime, 'endTime')}
        />
      </Flex>
    </div>
  );
};
