import gql from 'graphql-tag';

export default gql`
  mutation revokeSuper($userId: UUID!) {
    updateUserRevokeSuper(userId: $userId) {
      _id
      isSuperUser
    }
  }
`;
