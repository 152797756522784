import styles from './PermissionGroupMembers.scss';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import {
  Icon,
  Table,
  RowAction,
  Tooltip,
  NativeFilterTypes,
  CustomFilterType,
} from 'design-system-web';
import { appUrl, routes } from 'lane-shared/config';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import { isChannelForCRE } from 'lane-shared/helpers/channel';

import { useRemoveUserFromPermissionGroup } from 'lane-web/src/domains/userManagement/hooks';
import { DateTime, Duration } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { GroupRole } from 'lane-shared/types/GroupRole';
import { ChipSelect, ChipStyle } from 'components/ads';
import { useQueryString, useChannelForAdminQuery } from 'hooks';
import { stringify } from 'query-string';
import Papa from 'papaparse';
import { makeFileDownload } from 'helpers';
import downloadFile from 'helpers/downloadFile';
import {
  GetUsersByChannelIdQuery,
  UserLoginStatusType,
} from 'graphql-query-contracts';
import { useCompaniesForChannel } from 'pages/portal/admin/channel/users/hooks';

type Props = {
  channel: ReturnType<typeof useChannelForAdminQuery>['channel'];
  members?: GetUsersByChannelIdQuery;
  groupRole: Pick<GroupRole, '_id' | 'name' | 'isSystem'> | undefined;
  onAddUser: (() => void) | undefined;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

type Row = {
  id: string;
  userGroupRoleId: string;
  name: string;
  email: string;
  isEmailVerified: boolean;
  activity: string;
  created: string;
  company: { _id: string; name: string }[];
};

export const PermissionGroupMembers = ({
  channel,
  groupRole,
  members,
  onAddUser,
  refetch,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useQueryString();

  const history = useHistory();

  const {
    handleRemoveUserFromPermissionGroup,
  } = useRemoveUserFromPermissionGroup();

  const channelIsOfficeExperience = isChannelForCRE(channel?.experienceType);

  const userGroupRoles = members?.usersByChannelId?.items || [];
  const total = members?.usersByChannelId?.pageInfo?.total || 0;

  const { companyOptions } = useCompaniesForChannel(channel!._id);

  const companyFilterOptions =
    companyOptions?.flatMap((c: any) =>
      c
        ? [
            {
              label: c.name,
              value: c._id,
            },
          ]
        : []
    ) ?? [];

  const availableFilters: CustomFilterType<String>[] = [
    {
      type: NativeFilterTypes.Select,
      key: 'company',
      label: t('web.admin.channel.users.pendingInvites.table.company'),
      options: companyFilterOptions,
      customFilterFn: nameListFilterIncludeSome,
      isPromoted: true,
    },
    {
      type: NativeFilterTypes.DateRange,
      key: 'created',
      label: 'Joined between',
    },
  ];

  function activityHelper(lastSeen: string) {
    const lastSeenDt: DateTime = DateTime.fromISO(lastSeen);
    const now: DateTime = DateTime.local();
    const timeDiff: Duration = now.diff(lastSeenDt, ['minute']);

    if ((timeDiff as any).values?.minutes <= 2) {
      return t('web.admin.channel.permissionGroups.detail.activity.online');
    }

    return fromNow(lastSeen, undefined, i18n.language);
  }

  function emailHelper(email: string, isVerified: boolean) {
    if (!email) {
      return <span>{t('-')}</span>;
    }

    return (
      <div className={styles.userEmailCell}>
        {email}
        {isVerified && (
          <Icon
            testId="verified-badge-icon"
            set={ICON_SET_FONTAWESOME}
            type="far"
            name="badge-check"
            className={styles.icon}
          />
        )}
      </div>
    );
  }

  function companyHelper(
    relatedChannels: Array<{ _id: string; name: string }>
  ) {
    if (relatedChannels?.length > 1) {
      return (
        <Tooltip
          wrapperClassName={styles.alignTooltip}
          placement="top"
          TooltipComponent={
            <ul className={styles.tooltipItems}>
              {relatedChannels?.map(c => {
                return (
                  <li key={c._id + c.name}>
                    <span>{c.name}</span>
                  </li>
                );
              })}
            </ul>
          }
        >
          <ChipSelect.NonInteractive
            value={t('web.admin.channel.users.all.table.companiesAssigned', {
              count: relatedChannels.length,
            })}
            type={ChipStyle.Grey}
            withStatusIcon={false}
            doTranslate={false}
          />
        </Tooltip>
      );
    }

    return (
      <span className={styles.commaSeparatedCell}>
        {(relatedChannels?.length > 0 && relatedChannels[0].name) || '--'}
      </span>
    );
  }

  const columns = [
    {
      key: 'name',
      header: t('web.admin.channel.permissionGroups.detail.column.name'),
      renderCell: (name: string, user: Row) => (
        <Link
          className={styles.link}
          to={{
            pathname: routes.channelAdminTeamMember
              .replace(':id', channel?.slug || '')
              .replace(':userId', user.id),
          }}
        >
          {name}
        </Link>
      ),
      renderForCSV: (row: Row) => row.name,
    },
    {
      key: 'email',
      header: t('web.admin.channel.permissionGroups.detail.column.email'),
      renderCell: (email: string, user: Row) =>
        emailHelper(email, user.isEmailVerified),
      renderForCSV: (row: Row) => row.email,
    },
    {
      key: 'company',
      header: t('web.admin.channel.permissionGroups.detail.column.company'),
      disableSorting: true,
      renderCell: (cell: Array<{ _id: string; name: string }>) =>
        companyHelper(cell),
      renderForCSV: (row: Row) =>
        row.company.map(c => c.name).join(', ') || '--',
    },
    {
      key: 'activity',
      header: t('web.admin.channel.permissionGroups.detail.column.activity'),
      renderCell: (cell: string | undefined) => (
        <span>{cell ? activityHelper(cell) : '--'}</span>
      ),
      type: 'date',
      renderForCSV: (row: Row) => activityHelper(row.activity) || '--',
    },
    {
      key: 'created',
      disableSorting: true, // TODO: Enable sorting when server can support it
      header: t(
        channelIsOfficeExperience
          ? 'web.admin.channel.team.detail.column.joinedOn'
          : 'web.admin.channel.permissionGroups.detail.column.joinedOn'
      ),
      renderCell: (cell: string | undefined) => (
        <span>{simpleDate(cell) || '--'}</span>
      ),
      type: 'date',
      renderForCSV: (row: Row) => simpleDate(row.created) || '--',
    },
  ];

  const rows: Row[] = userGroupRoles.map(userGroupRole => ({
    id: userGroupRole._id,
    userGroupRoleId: userGroupRole.roles?.find(
      r => r.groupRole._id === groupRole?._id
    )?._id,
    name: userGroupRole.name,
    email:
      (userGroupRole.logins || []).find(login => login.isPrimary)?.key || '',
    isEmailVerified:
      (userGroupRole.logins || []).find(login => login.isPrimary)?.status ===
        UserLoginStatusType.Verified || false,
    activity: userGroupRole.lastSeen,
    created: userGroupRole.roles?.find(r => r.groupRole._id === groupRole?._id)
      ?._created,
    company:
      userGroupRole.companies?.flatMap(c =>
        c && c.name && c._id
          ? [
              {
                name: c.name,
                _id: c._id,
              },
            ]
          : []
      ) ?? [],
  }));

  const rowActions: RowAction<Row>[] = groupRole
    ? [
        {
          label: t(
            'web.admin.channel.permissionGroups.detail.rowActions.viewProfile'
          ),
          onClick: (user: Row) => {
            const url = routes.channelAdminTeamMember
              .replace(':id', channel?.slug || '')
              .replace(':userId', user?.id);
            history.push(url);
          },
        },
        {
          label: t(
            'web.admin.channel.permissionGroups.detail.rowActions.removeUser'
          ),
          onClick: async (user: Row) => {
            handleRemoveUserFromPermissionGroup({
              userGroupRoleId: user.userGroupRoleId,
              userName: user.name,
              userId: user.id,
              permissionGroupName: groupRole.name,
              permissionGroupId: groupRole._id,
              refetch,
            });
          },
          isDisabled: groupRole.isSystem,
        },
      ]
    : [];

  const handleExportToCSV = (users: Row[]) => {
    const csvData = [];
    const headers = columns.map(column => column.header);
    csvData.push(headers);

    users.forEach(user => {
      const rowData: any[] = [];
      columns.map(column => {
        // @ts-ignore
        const value = column.renderForCSV(user);
        rowData.push(value);
      });

      csvData.push(rowData);
    });

    const csv = Papa.unparse(csvData);
    const filename = `active-users-${Date.now()}.csv`;

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  const exportAll = async () => {
    window.Alert.loading({
      children: (
        <div style={{ textAlign: 'center', fontSize: '16px' }}>
          {t(
            'web.admin.channel.userManagement.table.export.all.preparingYourFile'
          )}
          <br />
          {t('web.admin.channel.userManagement.table.export.all.itMayTakeTime')}
        </div>
      ),
      disableCloseOnBackgroundClick: true,
    });

    try {
      const url = `${appUrl}${generatePath(routes.exportChannelUsers, {
        channelId: channel?._id || '',
      })}?${stringify({ groupRoles: [groupRole?._id], ...searchParams })}`;
      await downloadFile(url);
    } finally {
      window.Alert.hide();
    }
  };

  const exportCurrentPage = (currentPageData: Row[] = []) => {
    handleExportToCSV(currentPageData);
  };

  const exportOptions = [
    {
      label: t('web.admin.channel.userManagement.table.export.currentPage'),
      onClick: exportCurrentPage,
    },
    {
      label: t('web.admin.channel.userManagement.table.export.all'),
      onClick: exportAll,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        exportOptions={exportOptions}
        data={rows}
        rowActions={rowActions}
        pagination="server"
        queryStringsEnabled
        totalRows={total}
        hasKeywordFilter
        filters={availableFilters}
        onPrimaryAction={onAddUser}
        primaryActionLabel={
          onAddUser && t('web.pages.portal.admin.channel.team.addUserButton')
        }
      />
    </div>
  );
};

type NameList = { name: string }[];

const nameListFilterIncludeSome = (
  row: any,
  columnId: string,
  filterValue: any[]
) => {
  if (!filterValue || !filterValue.length) {
    return true;
  }

  const cellValue: NameList = row.getValue(columnId);

  return cellValue.some(value => filterValue.includes(value.name));
};
