import React from 'react';

import styles from './invoiceAutopayment.scss';
import { Button } from 'design-system-web';
import { CardFormInputFields } from 'components/features/Payment/PaymentSummaryV2/PaymentOptions/CardFormInputFields';
import { M } from 'components/typography';
import { useTranslation } from 'react-i18next';

export function AddNewCardAutopaymentModal({
  isAddNewCardClicked,
  setIsAddNewCardClicked,
  InputController,
}: {
  isAddNewCardClicked: boolean;
  setIsAddNewCardClicked: (isClicked: boolean) => void;
  InputController: {
    control: any;
    setValue: any;
  };
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.addNewCard}>
      {isAddNewCardClicked ? (
        <Button
          className={styles.backToPaymentSourcesButton}
          testId="BackToPaymentSourcesButton"
          onClick={() => setIsAddNewCardClicked(false)}
          variant="text"
          size="large"
        >
          {t('abp.routes.accountInvoices.autopayment.modal.backToSavedCards')}
        </Button>
      ) : (
        <M mb={5} mt={5}>
          {t(
            'abp.routes.accountInvoices.autopayment.modal.addCreditCardSubtitle'
          )}
        </M>
      )}

      <div className={styles.inputFieldsWrapper}>
        <M bold>
          {t(
            'abp.routes.accountInvoices.autopayment.modal.creditCardInformation'
          )}
        </M>
        <CardFormInputFields InputController={InputController} />
      </div>
    </div>
  );
}
