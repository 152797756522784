import React from 'react';

import { TextareaAutosizeProps } from 'react-textarea-autosize';

import { useValidatedControl } from 'lane-shared/hooks';

import ValidationMessage from '../general/ValidationMessage';
import TextArea from './TextArea';

type Props = {
  onChange: (text: string) => void;
  onBlur?: (value: any) => void;
  className?: string;
  style?: TextareaAutosizeProps['style'];
  label?: string;
  value: string;
  placeholder?: string;
  validationClassName?: string;
  disabled?: boolean;
  validation: any;
  isPristine?: boolean;
  testId?: string;
  showLengthIndicator?: boolean;
  maxLength?: number;
  minRows?: number;
  showClear?: boolean;
};

function ValidatedTextArea({
  onChange,
  onBlur,
  validationClassName,
  className,
  style,
  label,
  value,
  placeholder,
  disabled,
  validation,
  isPristine = true,
  testId,
  showLengthIndicator = false,
  maxLength,
  minRows,
  showClear,
  ...otherProps
}: Props) {
  const [validate, validationMessages, success, error] = useValidatedControl(
    value,
    isPristine,
    validation
  );

  function handleChange(text: any) {
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    validate(text);
    onChange(text);
  }

  return (
    <>
      <TextArea
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        errors={error}
        success={success}
        style={style}
        label={label}
        className={className}
        placeholder={placeholder}
        testId={testId}
        showLengthIndicator={showLengthIndicator}
        maxLength={maxLength}
        disabled={disabled}
        minRows={minRows}
        showClear={showClear}
        {...otherProps}
      />
      {!isPristine && (
        <ValidationMessage
          className={validationClassName}
          errors={validationMessages}
        />
      )}
    </>
  );
}

export default ValidatedTextArea;
