import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class UnsupportedAttachmentTypeError extends CodedError {
  static get errorName() {
    return errorCodes.unsupportedAttachmentTypeError.code;
  }

  constructor(message = errorCodes.unsupportedAttachmentTypeError.message) {
    super(message);
    this.code = errorCodes.unsupportedAttachmentTypeError.code;
    this.message = `${message} ${errorCodes.unsupportedAttachmentTypeError.code}`;
    this.name = errorCodes.unsupportedAttachmentTypeError.name;
  }
}
