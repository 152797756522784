import gql from 'graphql-tag';

export default gql`
  query getSubscriberCountPerClient($parentId: UUID) {
    portal {
      subscriberCountPerClient(parentId: $parentId) {
        name
        count
      }
    }
  }
`;
