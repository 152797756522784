import gql from 'graphql-tag';

export type RemoveUserFromChannelMutationResponse = {
  removeUserFromChannel: boolean;
};

export const removeUserFromChannel = gql`
  mutation RemoveUserFromChannel($userId: UUID!, $channelId: UUID!) {
    removeUserFromChannel(channelId: $channelId, userId: $userId)
  }
`;
