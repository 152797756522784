import { gql } from '@apollo/client';

export const updateDraftChargeMutation = gql`
  mutation updateDraftCharge(
    $updateDraftChargeRequest: UpdateDraftChargeRequest!
  ) {
    accounts {
      updateDraftCharge(updateDraftChargeRequest: $updateDraftChargeRequest)
    }
  }
`;
