import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  GetUserByExternalIdRequest,
  GetUserByExternalIdResponse,
  ExternalUserSource,
} from 'lane-shared/domains/billingPayments/types';

const getUserByExternalId = gql`
  query getUserByExternalId(
    $getUserByExternalIdRequest: GetUserByExternalIdRequest!
  ) {
    accounts {
      getUserByExternalId(
        getUserByExternalIdRequest: $getUserByExternalIdRequest
      ) {
        accountsUserId
        externalUserId
        paymentProcessorCustomerId
        paymentSources {
          paymentMethodId
          brand
          expiryMonth
          expiryYear
          last4
        }
        invoiceAutopayment {
          isInvoiceAutopaymentEnabled
          paymentSource {
            paymentMethodId
            brand
            expiryMonth
            expiryYear
            last4
          }
        }
      }
    }
  }
`;

export async function fetchAccountsUser(
  externalUserId: string
): Promise<GetUserByExternalIdResponse> {
  const { data } = await getClient().query({
    query: getUserByExternalId,
    variables: {
      getUserByExternalIdRequest: {
        externalUserId,
        externalUserSource: ExternalUserSource.EXTERNAL_USER_SOURCE_ACTIVATE,
      } as GetUserByExternalIdRequest,
    },
    fetchPolicy: 'network-only',
  });

  return data.accounts.getUserByExternalId;
}
