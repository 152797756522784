import React, { forwardRef, MouseEvent, KeyboardEvent } from 'react';

import { Icon } from '../Icon';

import styles from './style.scss';

type Props = {
  iconName?: string;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'critical';
  onClick?: (event: MouseEvent | KeyboardEvent) => void;
  padding?: 'none';
  disabled?: boolean;
};

export const TextButton = forwardRef<HTMLButtonElement, Props>(
  (
    { children, onClick, iconName, variant = 'primary', padding, disabled },
    ref
  ) => {
    return (
      <button
        onClick={onClick}
        ref={ref}
        className={styles.TextButton}
        data-variant={variant}
        data-padding={padding}
        disabled={disabled}
      >
        {children}
        {iconName && <Icon name={iconName} className={styles.Icon} />}
      </button>
    );
  }
);
