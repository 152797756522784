import gql from 'graphql-tag';

export default gql`
  query getVisitorEmailTemplate($channelId: UUID!, $templateType: String!) {
    visitorEmailTemplate(channelId: $channelId, templateType: $templateType) {
      organizationId
      templateType
      logoId
      backgroundId
      entryInstructions
      policies
      createdByUserId
      updatedByUserId
      qrEnabled
      createdAtDatetime
      updatedAtDatetime
    }
  }
`;
