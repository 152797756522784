import { useEffect, useRef, useLayoutEffect } from 'react';

type Options = {
  top?: number;
  left?: number;
  behavior?: 'smooth' | 'auto';
};

function scrollTo(
  el: Element | null,
  { top, left, behavior = 'smooth' }: Options
) {
  if (!el) {
    return;
  }

  el.scrollTo({ top, left, behavior });
}
/**
 * Scrolls a component to a child that is marked by data-is-selected=true.
 *
 * Pass in a value to determine when to update the component and scroll
 * to the child.
 */
export function useScrollToSelectedChild(value: any, isVisible?: boolean) {
  const isFirstRender = useRef(false);
  const elementRef = useRef(null);

  useEffect(() => {
    // stops the time picker from jumping around on the first render.
    isFirstRender.current = true;
  }, []);

  useLayoutEffect(() => {
    if (![undefined, null].includes(value) && elementRef.current) {
      // scroll to current value.
      // const i = options.findIndex(opt => opt.selected);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const child = elementRef.current.querySelector(
        '*[data-is-selected=true]'
      );

      if (!child) {
        return;
      }

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const menuHeight = elementRef.current.clientHeight;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const menuScrollTop = elementRef.current.scrollTop;
      const optionHeight = child.offsetHeight;
      const top = child.offsetTop;

      if (menuScrollTop < top || menuScrollTop + optionHeight > top) {
        scrollTo(elementRef.current, {
          top: top - menuHeight / 2 + optionHeight / 2,
          behavior: isFirstRender ? 'auto' : 'smooth',
        });
      }
    }

    isFirstRender.current = false;
  }, [value, isVisible]);

  return [elementRef];
}
