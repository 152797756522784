import React, { useRef } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import DateRangePickerButton from 'components/form/DatePickers/DateRangePickerButton';
import Button from 'components/general/Button';
import { Flex } from 'components/layout';

import FeatureOptionToggle from '../components/FeatureOptionToggle';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  excludedDatesTitle:
    'web.content.features.TimeAvailability.TimeAvailabilityExcluded.excludedDates.title',
  excludedDatesDescription:
    'web.content.features.TimeAvailability.TimeAvailabilityExcluded.excludedDates.description',
  addAnother: 'web.content.features.TimeAvailability.addAnother',
};

export default function TimeAvailabilityExcluded({
  onToggleFeature,
  unavailableDates,
  hasFeatureToggled,
  onAddUnavailableDate,
  onRemoveUnavailableDate,
  updateDateRange,
  timeZone,
}: any) {
  const minDateRef = useRef(new Date()).current;

  const { t } = useTranslation();
  return (
    <FeatureOptionToggle
      title={t(TRANSLATION_KEYS.excludedDatesTitle)}
      description={t(TRANSLATION_KEYS.excludedDatesDescription)}
      isExpanded={hasFeatureToggled}
      onChange={onToggleFeature}
    >
      <>
        <div className={styles.availabilityRulesContainer}>
          {unavailableDates.map((unavailableDate: any) => (
            <div
              key={unavailableDate._id}
              className={cx(styles.availabilityRule, styles.excludedRule)}
            >
              <div className={styles.inputRow}>
                <DateRangePickerButton
                  className={styles.dateRangeButton}
                  timeZone={timeZone}
                  minDate={minDateRef}
                  minRangeSize={1}
                  onChange={(value: any) =>
                    updateDateRange(value, unavailableDate._id)
                  }
                  startDate={unavailableDate?.dateRange?.startDate}
                  endDate={unavailableDate?.dateRange?.endDate}
                />

                <Icon
                  onClick={() => onRemoveUnavailableDate(unavailableDate._id)}
                  name="times"
                  className={styles.removeIconExclude}
                  set={ICON_SET_FONTAWESOME}
                />
              </div>
            </div>
          ))}
        </div>
        <Flex justify="center">
          <Button
            variant="outlined"
            startIcon={<Icon name="plus" />}
            onClick={onAddUnavailableDate}
            testId="addNewRule"
            className={styles.addAnother}
          >
            {t(TRANSLATION_KEYS.addAnother)}
          </Button>
        </Flex>
      </>
    </FeatureOptionToggle>
  );
}
