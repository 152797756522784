import { onError } from '@apollo/client/link/error';

export default onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(
      ({ message, locations, path, originalError, extensions }) =>
        console.log(
          `[GraphQL error]:`,
          message,
          locations,
          path,
          extensions,
          originalError
        )
    );
  }

  if (networkError) {
    console.log(`[Network error]`, networkError);
  }
});
