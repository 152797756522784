import gql from 'graphql-tag';

import { ProfileFragment } from '../fragments';

export default gql`
  ${ProfileFragment}

  query queryChannelUsersByGroupRoles(
    $groupRoleIds: [UUID]!
    $search: UserGroupRoleSearch
  ) {
    channelUsersByGroupRoles(groupRoleIds: $groupRoleIds, search: $search) {
      items {
        _id
        relatedChannels {
          _id
          name
        }
        groupRole {
          name
        }
        user {
          _id
          _created
          _updated
          status
          lastSeen
          name
          profile {
            ...ProfileFragment
          }
          logins {
            _id
            key
            isPrimary
            status
          }
        }
      }
    }
  }
`;
