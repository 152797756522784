import React, { PropsWithChildren } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';

import styles from './MessageBox.scss';

const iconName = {
  error: 'times-circle',
} as const;

type MessageBoxProps = PropsWithChildren<{
  title: string;
  type: keyof typeof iconName;
  className?: string;
}>;

export default function MessageBox({
  className,
  title,
  type,
  children,
}: MessageBoxProps) {
  return (
    <div className={cx(styles.MessageBox, styles[type], className)}>
      <div className={styles.title}>
        <Icon
          name={iconName[type]}
          className={cx(styles.icon)}
          set={ICON_SET_FONTAWESOME}
          type={FONT_AWESOME_REGULAR}
          testId="message-box-icon"
        />

        {title}
      </div>
      {children ? <div className={styles.children}>{children}</div> : null}
    </div>
  );
}
