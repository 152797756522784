import urlJoin from 'url-join';

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  HttpOptions,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { appUrl } from '../config';
import * as WHITELABELS from '../config/whitelabels';
import Storage from '../helpers/Storage';
import { HEADER_WHITELABEL } from '../helpers/constants/headers';
import { authorizationHeader } from '../helpers/formatters';
import cache from './cache';
import errorLink from './errorLink';
import { getOperationNameParam } from './utils';

type ApolloType = {
  headers: {
    [key: string]: string;
  };
  publicClient: ApolloClient<any> | null;
  batchPublicClient: ApolloClient<any> | null;
  adminClient: ApolloClient<any> | null;
  whitelabel: Pick<WhiteLabelType, 'instance'>;
};

const apollo: ApolloType = {
  headers: {},
  publicClient: null,
  batchPublicClient: null,
  adminClient: null,
  whitelabel: WHITELABELS.cache.find(
    whitelabel => whitelabel.instance === WHITELABELS.WHITELABEL_LANE
  )!,
};

type Params = {
  cache: InMemoryCache;
  headers: any;
  whitelabel?: WhiteLabelType;
};

const fetcher: HttpOptions['fetch'] = async (uri, options: any) => {
  options.headers[HEADER_WHITELABEL] = apollo.whitelabel.instance;

  try {
    const token = await Storage.getItem(Storage.AUTH_TOKEN);
    options.headers.Authorization = authorizationHeader(token);
  } catch (err) {
    // no token found.
  }

  Object.entries(apollo.headers).forEach(
    ([key, value]) => (options.headers[key] = value)
  );

  try {
    options.headers['Accept-Language'] = await Storage.getItem(
      Storage.USER_LOCALE
    );
  } catch (err) {
    // no locale found, don't set.
  }

  const operationNameParam = getOperationNameParam(options);
  return fetch(`${uri}${operationNameParam}`, options);
};

type CreateApolloClientParams = {
  cache: InMemoryCache;
  uri: string;
  enableBatching?: boolean;
};

function createApolloClient({
  cache,
  uri,
  enableBatching = false,
}: CreateApolloClientParams) {
  const httpLink = new HttpLink({
    uri,
    fetch: fetcher,
    // https://www.apollographql.com/docs/react/networking/authentication/#cookie
    credentials: 'include',
  });

  const batchHttpLink = new BatchHttpLink({
    uri,
    fetch: fetcher,
    batchMax: 10, // No more than 10 operations per batch
    batchInterval: 20, // Wait no more than 20ms after first batched operation
    credentials: 'include',
  });

  const terminatingLink = enableBatching ? batchHttpLink : httpLink;

  const link = ApolloLink.from([errorLink, terminatingLink]);

  return new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    /**
     * This reverts behavior to ensure we have cloned objects,
     * which are mutable. Once we are confident that we aren't
     * mutating data pulled from the Apollo client cache,
     * we should turn this back to `true` (the new default),
     * as it will improve front-end performance.
     */
    assumeImmutableResults: false,
  });
}

export default function initializeApolloClient({
  cache,
  whitelabel,
  headers,
}: Params) {
  if (whitelabel) {
    apollo.whitelabel = whitelabel;
  }

  if (headers) {
    apollo.headers = {
      ...apollo.headers,
      ...headers,
    };
  }

  apollo.publicClient = createApolloClient({
    cache,
    uri: urlJoin(appUrl, '/graphql'),
  });

  apollo.batchPublicClient = createApolloClient({
    cache,
    uri: urlJoin(appUrl, '/graphql'),
    enableBatching: true,
  });

  apollo.adminClient = createApolloClient({
    cache,
    uri: urlJoin(appUrl, '/graphql-admin'),
  });

  return apollo.publicClient;
}

export { cache };

export function setHeader(header: string, value: string) {
  apollo.headers[header] = value;
}

export function resetHeaders() {
  apollo.headers = {};
}

export function getWhiteLabel() {
  return apollo.whitelabel;
}

export function getClient() {
  return apollo.publicClient!;
}

/* Use this client for batching multiple requests into one  */
export function getBatchPublicClient() {
  return apollo.batchPublicClient!;
}

export function getAdminClient() {
  return apollo.adminClient!;
}
