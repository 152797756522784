import gql from 'graphql-tag';

export default gql`
  mutation updateUserAccessControlGroups(
    $channelId: UUID!
    $userId: UUID!
    $assignedAccessControlGroups: [AccessControlGroupInput!]!
    $revokedAccessControlGroups: [AccessControlGroupInput!]!
  ) {
    updateUserAccessControlGroups(
      channelId: $channelId
      userId: $userId
      assignedAccessControlGroups: $assignedAccessControlGroups
      revokedAccessControlGroups: $revokedAccessControlGroups
    )
  }
`;
