import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export default class ChannelModuleAlreadyExistError extends CodedError {
  static get errorName() {
    return errorCodes.channelModuleAlreadyExistError.code;
  }

  constructor(message = errorCodes.channelModuleAlreadyExistError.message) {
    super(message);
    this.code = errorCodes.channelModuleAlreadyExistError.code;
    this.message = `${message} ${errorCodes.channelModuleAlreadyExistError.code}`;
    this.name = errorCodes.channelModuleAlreadyExistError.name;
    this.status = errorCodes.channelModuleAlreadyExistError.status;
  }
}
