import gql from 'graphql-tag';

export default gql(`
  fragment MediaFragment on Media {
    _id
    _created
    _updated
    type
    tags
    name
    description
    file
    thumbnail
    contentType
    s3Bucket
    previewUrl
    _createdBy {
      _id
    }
    _updatedBy {
      _id
    }
  }
`);
