import { v4 as uuid } from 'uuid';

type Params = {
  userId?: string;
  contentId?: string;
};

export default function constructPlacement({ userId, contentId }: Params) {
  return {
    _id: uuid(),
    _created: new Date(),
    _createdBy: userId,
    _updated: new Date(),
    _updatedBy: userId,
    type: 'Syndicated',
    content: {
      _id: contentId,
    },
  };
}
