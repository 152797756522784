import { useEffect, useState } from 'react';

import { ApolloError, useLazyQuery } from '@apollo/client';

import { LaneType } from '../__generated__/staticTypes';
import { getClient } from '../apollo';
import getChannelForProfile from '../graphql/query/getChannelForProfile';
import isProbablyUUID from '../helpers/isProbablyUUID';
import { AddressType } from '../types/AddressType';
import {
  ChannelLanguagesType,
  ChannelTypeEnum,
  EnabledLanguagesForMembersType,
} from '../types/ChannelType';
import { ProfileType } from '../types/ProfileType';

type Params = {
  channelId?: string | null;
  channelSlug?: string | null;
};

export type ChannelProfileType = {
  _id: LaneType.UUID;
  _created: string;
  _updated: string;
  name: string;
  description: string;
  slug: string;
  type: ChannelTypeEnum;
  isPublic: boolean;
  isDiscoverable: boolean;
  isLive: boolean;
  isSub: boolean;
  inviteOnly: boolean;
  address: AddressType;
  profile: ProfileType;
  settings: {
    hasBillingPaymentsEnabled: boolean;
    hasVisitorManagementEnabled: boolean;
    hasWorkOrderServiceRequestsEnabled: boolean;
    hasWorkOrderPreventiveMaintenanceEnabled: boolean;
    hasWorkOrderEquipmentEnabled: boolean;
    multiLanguageEnabled?: boolean;
    channelLanguages?: ChannelLanguagesType;
    enabledLanguagesForMembers?: EnabledLanguagesForMembersType;
    hasMessagingAndMarketplaceEnabled: boolean;
  };
};

export default function useChannelProfileQuery({
  channelId,
  channelSlug,
}: Params): {
  channel: ChannelProfileType | null;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const [channel, setChannel] = useState<ChannelProfileType | null>(null);
  const [fetchChannel, { data, loading, error, called }] = useLazyQuery(
    getChannelForProfile,
    {
      client: getClient(),
    }
  );

  useEffect(() => {
    if (channelId || channelSlug) {
      // may be a slug or a id passed in
      const variables = {
        id: channelId,
        slug: channelSlug,
      };

      if (!isProbablyUUID(channelId)) {
        // do the ol switcheroo, this is actually a slug.
        delete variables.id;
        variables.slug = channelId;
      }

      fetchChannel({
        variables,
      });
    }
  }, [channelId, channelSlug]);

  useEffect(() => {
    // Defer update if there is no data or we are re-fetching or it has been called
    if (!data || loading || !called) return;

    setChannel(data?.channel);
  }, [data, loading, called]);

  return {
    channel,
    loading,
    error,
  } as const;
}
