import gql from 'graphql-tag';

export default gql`
  mutation deleteAttachment($id: UUID) {
    deleteAttachment(_id: $id) {
      created
      deletedAt
      entityId
      file
      id
      thumbnail
    }
  }
`;
