import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ChannelIntegrationNotSetupError extends CodedError {
  static get errorName() {
    return errorCodes.channelIntegrationNotSetupError.code;
  }

  constructor(message = errorCodes.channelIntegrationNotSetupError.message) {
    super(message);
    this.code = errorCodes.channelIntegrationNotSetupError.code;
    this.message = `${message} ${errorCodes.channelIntegrationNotSetupError.code}`;
    this.name = errorCodes.channelIntegrationNotSetupError.name;
  }
}
