import gql from 'graphql-tag';

export default gql`
  query getRelatedSpacesList(
    $channelId: UUID!
    $contentId: UUID!
    $search: ContentSearch!
    $pagination: PaginationInput!
  ) {
    contentForRelatedSpaces(
      channelId: $channelId
      contentId: $contentId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        name
      }
    }
  }
`;
