import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class FloorUnitsAssociationExistsError extends CodedError {
  static get errorName() {
    return errorCodeTypes.floorUnitsAssociationExistsError.code;
  }

  constructor(
    message = errorCodeTypes.floorUnitsAssociationExistsError.message
  ) {
    super(message);
    this.code = errorCodeTypes.floorUnitsAssociationExistsError.code;
    this.message = `${message} ${errorCodeTypes.floorUnitsAssociationExistsError.code}`;
    this.name = errorCodeTypes.floorUnitsAssociationExistsError.name;
  }
}
