import { ContentType } from 'lane-shared/types/content/Content';
import {
  OrderSummaryWithProducts,
  Product,
} from 'lane-shared/domains/billingPayments/types';
import {
  CreateRecurringChargeRequest,
  CreateRecurringChargeResponse,
  ExternalActorType,
  ExternalEntityType,
  GroupType,
  InvoiceType,
  ExternalPayerType,
  RecurrenceType,
  ProductItemInput,
  Currency,
  RecurrenceInterval,
} from 'graphql-query-contracts';
import { getClient } from 'lane-shared/apollo';
import { createSubscriptionMutation } from '../../queries/createSubscriptionMutation';
import convertToUUID from 'lane-shared/helpers/convertId/convertToUUID';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';

export async function processSubscription(
  content: ContentType,
  orderSummaryWithProductsData: OrderSummaryWithProducts,
  primaryId: string,
  userId: string,
  paymentQuote: FeatureQuoteType
) {
  if (!orderSummaryWithProductsData.subscriptionDetails) {
    throw new Error(
      'Subscription details are required to process subscription'
    );
  }
  const createRecurringChargeRequest = formCreateRecurringChargeRequest(
    content,
    orderSummaryWithProductsData,
    primaryId,
    userId,
    paymentQuote
  );
  const client = getClient();
  type CreateSubscriptionResponse = {
    accounts: {
      createRecurringCharge: CreateRecurringChargeResponse;
    };
  };
  const response = await client.mutate<CreateSubscriptionResponse>({
    mutation: createSubscriptionMutation,
    variables: {
      createRecurringChargeRequest,
    },
  });

  const createdSubscriptionId =
    response.data?.accounts.createRecurringCharge.recurrenceId;

  if (!createdSubscriptionId) {
    throw new Error(
      'Subscription not created correctly - mutation returned without recurrenceId'
    );
  }

  return createdSubscriptionId;
}

function formCreateRecurringChargeRequest(
  content: ContentType,
  orderSummaryWithProductsData: OrderSummaryWithProducts,
  primaryId: string,
  userId: string,
  paymentQuote: FeatureQuoteType
): CreateRecurringChargeRequest {
  const totalCounts =
    orderSummaryWithProductsData.subscriptionDetails?.totalCounts;
  const endDate = orderSummaryWithProductsData.subscriptionDetails?.endDate;
  return {
    charge: {
      name: content.name,
      description: content.description,
      amount: orderSummaryWithProductsData.cashTotal,
      // hard coding the prefix for now until we switch over to the generated gql enums fully
      currency: ('CURRENCY_' +
        orderSummaryWithProductsData.currency) as Currency,
      groupId: convertToUUID(primaryId),
      groupType: GroupType.GroupTypeActivateChannel,
      externalEntityId: '',
      externalEntityType: ExternalEntityType.ExternalEntityTypeActivateUci,
      externalActorId: convertToUUID(userId),
      externalActorType: ExternalActorType.ExternalActorTypeActivateUser,
      externalPayerId: convertToUUID(userId),
      externalPayerType: ExternalPayerType.ExternalPayerTypeActivateUser,
      invoiceType: InvoiceType.InvoiceTypeOneoff,
      metadata: '',
      invoiceDueInDays: 1, // due one day after issue
      items: dtoDomainProductToGqlProductItemInput(
        orderSummaryWithProductsData.products,
        paymentQuote.quantity
      ),
    },
    recurrenceType: RecurrenceType.RecurrenceTypeCharges,
    recurrenceInterval:
      // Typing issue because one type is from the resolver and other is from the query libs
      (orderSummaryWithProductsData!.subscriptionDetails!
        .recurrenceInterval as unknown) as RecurrenceInterval,
    startDate: new Date(new Date().toUTCString()),
    totalCounts,
    ...(!totalCounts ? { endDate } : {}), // only include endDate if totalCounts is not present
    anyoneCanCancel:
      orderSummaryWithProductsData.subscriptionDetails?.anyoneCanCancel,
  };
}

function dtoDomainProductToGqlProductItemInput(
  products: Product[],
  quantity = 1
): ProductItemInput[] {
  return products.map(product => {
    return {
      chargeCodeId: product.chargeCode?.id,
      product: {
        amount: product.amount,
        creditAmount: product.creditAmount,
        description: product.description,
        groupId: product.groupId,
        groupType: GroupType.GroupTypeActivateChannel,
        id: product.id,
        markup: product.markup
          ? {
              value: product.markup.value,
              markupAmountType: product.markup.markupAmountType,
            }
          : undefined,
        name: product.name,
        originalPrice: product.originalPrice,
        productCategoryId: product.productCategory.productCategoryId,
        tax: product.tax
          ? {
              value: product.tax.value,
              taxAmountType: product.tax.taxAmountType,
            }
          : undefined,
      },
      productCategoryId: product.productCategory.productCategoryId,
      quantity,
    };
  });
}
