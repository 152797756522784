import React from 'react';

import { PopupMenu } from '../PopupMenu/PopupMenu';
import { Icon } from '../Icon';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';

export type ExportOption<TDataShape = any> = {
  label: string;
  onClick: (data?: TDataShape[]) => void;
  loading?: boolean;
};

type Props<TDataShape> = {
  exportOptions: ExportOption<TDataShape>[];
  data?: TDataShape[];
};

export const ExportButton = <TDataShape,>({
  exportOptions,
  data,
}: Props<TDataShape>) => {
  const { t } = useTranslation();

  if (exportOptions.length === 0) {
    return null;
  }

  if (exportOptions.length === 1) {
    return (
      <Button
        variant="text"
        onClick={() =>
          data ? exportOptions[0]?.onClick(data) : exportOptions[0]?.onClick()
        }
        size="large"
        startIcon={<Icon name="download" />}
        loading={exportOptions[0]?.loading}
      >
        {exportOptions[0]?.label}
      </Button>
    );
  }

  return (
    <PopupMenu
      trigger={
        <Button
          variant="text"
          size="large"
          startIcon={<Icon name="download" />}
          loading={exportOptions.some(({loading}) => loading)}
        >
          {t('web.table.export')}
        </Button>
      }
      items={exportOptions.map(option => ({
        label: option.label,
        onSelect: () => (data ? option.onClick(data) : option.onClick()),
      }))}
    />
  );
};

export default ExportButton;
