import { useLayoutEffect } from 'react';

import GeoJSON from 'geojson';

import { colors } from 'lane-shared/config';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import useMapboxPoint from 'hooks/useMapboxPoint';

type OwnProps = {
  id: string;
  opacity?: number;
  radius?: number;
  color?: string;
  draggable?: boolean;
  selectedColor?: string;
  isSelected?: boolean;
  onCoordinatesUpdated?: (
    pointId: string,
    coordinates: GeoCoordinateType
  ) => void;
  onFocus: (rectangleId: string) => void;
  source?:
    | GeoJSON.FeatureCollection<GeoJSON.Point>
    | GeoJSON.Feature<GeoJSON.Point>;
  coordinates?: GeoCoordinateType;
};

type Props = OwnProps;

export default function MapboxPoint({
  id,
  source,
  opacity = 1,
  radius = 10,
  color = colors.interactiveBlue,
  selectedColor = colors.success,
  draggable = false,
  isSelected,
  onCoordinatesUpdated = () => null,
  onFocus = () => null,
  coordinates,
}: Props) {
  const layerId = `${id}-layer`;
  const sourceId = `${id}-source`;

  const { map } = useMapboxPoint({
    layerId,
    sourceId,
    onFocus,
    onCoordinatesUpdated,
    draggable,
    source,
    coordinates,
    onLayerLoad: map => {
      map.addLayer({
        id: layerId,
        type: 'circle',
        source: sourceId,
        paint: {
          'circle-radius': radius,
          'circle-color': isSelected ? selectedColor : color,
          'circle-opacity': opacity,
        },
      });
    },
  });

  function updatePaintProperty(property: any, value: any) {
    if (!map) {
      return;
    }

    const layer = map.getLayer(layerId);

    if (!layer) {
      return;
    }

    try {
      map.setPaintProperty(layerId, property, value);
    } catch (err) {
      // ok to ignore
    }
  }

  useLayoutEffect(() => {
    updatePaintProperty('circle-color', isSelected ? selectedColor : color);
  }, [isSelected, selectedColor, color]);

  useLayoutEffect(() => {
    updatePaintProperty('circle-opacity', opacity);
  }, [opacity]);

  useLayoutEffect(() => {
    updatePaintProperty('circle-radius', radius);
  }, [radius]);

  return null;
}
