import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class CompleteSignupExpiredError extends CodedError {
  static get errorName() {
    return errorCodes.completeSignupExpiredError.code;
  }

  constructor(message = errorCodes.completeSignupExpiredError.message) {
    super(message);
    this.code = errorCodes.completeSignupExpiredError.code;
    this.message = `${message} ${errorCodes.completeSignupExpiredError.code}`;
    this.name = errorCodes.completeSignupExpiredError.name;
  }
}
