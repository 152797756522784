import gql from 'graphql-tag';

import {
  CustomColorPaletteType,
  LayoutDesignSystemType,
  ThemeColorPaletteType,
  ThemeType,
  TypographicDesignSystemType,
} from '../../types/Theme';

// TODO: we need a proper ts type for this
export type TypographyLayoutElementFragmentType = any;

export type ThemeFragmentType = Pick<
  ThemeType,
  '_id' | '_created' | '_updated' | 'name'
> & {
  user: any; // TODO: fix type
  channel: any; // TODO: fix type
  palette?: Pick<
    ThemeColorPaletteType,
    | '_id'
    | 'name'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'text'
    | 'background'
  > & {
    custom: Pick<CustomColorPaletteType, '_id' | 'name' | 'color'>[];
  };
  typography?: Pick<TypographicDesignSystemType, '_id'> & {
    base?: TypographyLayoutElementFragmentType;
    h1?: TypographyLayoutElementFragmentType;
    h2?: TypographyLayoutElementFragmentType;
    h3?: TypographyLayoutElementFragmentType;
    h4?: TypographyLayoutElementFragmentType;
    h5?: TypographyLayoutElementFragmentType;
    h6?: TypographyLayoutElementFragmentType;
    p?: TypographyLayoutElementFragmentType;
    label?: TypographyLayoutElementFragmentType;
    quote?: TypographyLayoutElementFragmentType;
    strong?: TypographyLayoutElementFragmentType;
    emphasis?: TypographyLayoutElementFragmentType;
  };
  layout?: Pick<
    LayoutDesignSystemType,
    | '_id'
    | 'marginTop'
    | 'marginRight'
    | 'marginBottom'
    | 'marginLeft'
    | 'paddingTop'
    | 'paddingRight'
    | 'paddingBottom'
    | 'paddingLeft'
  >;
};

export default gql(`
  fragment TypographyLayoutElementFragment on TypographyLayoutElement {
    _id
    fontFamily
    fontWeight
    fontSize
    lineHeight
    letterSpacing
    marginTop
    marginRight
    marginBottom
    marginLeft
    paddingTop
    paddingRight
    paddingBottom
    paddingLeft
  }

  fragment ThemeFragment on Theme {
    _id
    _created
    _updated
    name
    user {
      _id 
    }
    channel {
      _id 
    }
    palette {
      _id
      name
      primary
      secondary
      tertiary
      text
      background
      custom {
        _id
        name
        color
      }
    }

    typography {
      _id
      base {
        ...TypographyLayoutElementFragment
      }
      h1 {
        ...TypographyLayoutElementFragment
      }
      h2 {
        ...TypographyLayoutElementFragment
      }
      h3 {
        ...TypographyLayoutElementFragment
      }
      h4 {
        ...TypographyLayoutElementFragment
      }
      h5 {
        ...TypographyLayoutElementFragment
      }
      h6 {
        ...TypographyLayoutElementFragment
      }
      p {
        ...TypographyLayoutElementFragment
      }
      label {
        ...TypographyLayoutElementFragment
      }
      quote {
        ...TypographyLayoutElementFragment
      }
      strong {
        ...TypographyLayoutElementFragment
      }
      emphasis {
        ...TypographyLayoutElementFragment
      }
    }
    layout {
      _id
      marginTop
      marginRight
      marginBottom
      marginLeft
      paddingTop
      paddingRight
      paddingBottom
      paddingLeft
    }
  }
`);
