/**
 * What is the general category of this integration? Will be used in the future
 * as meta-data to provide suggestions, or group things.
 */
export enum IntegrationCategoryEnum {
  Authentication = 'Authentication',
  AccessControl = 'AccessControl',
  Amenity = 'Amenity',
  Content = 'Content',
  Concierge = 'Concierge',
  Commerce = 'Commerce',
  Communication = 'Communication',
  IOT = 'IOT',
  Delivery = 'Delivery',
  Displays = 'Displays',
  Events = 'Events',
  FlexSpace = 'FlexSpace',
  FoodOrdering = 'FoodOrdering',
  Maintenance = 'Maintenance',
  Parking = 'Parking',
  RoomBooking = 'RoomBooking',
  Safety = 'Safety',
  Service = 'Service',
  VisitorRegistration = 'VisitorRegistration',
  Custom = 'Custom',
  Accounting = 'Accounting',
}

/**
 * What is the general type of this integration?  Is it a full API integration
 * is it an SDK that only exists on mobile? Is it just a webview that we open?
 */
export enum IntegrationTypeEnum {
  Api = 'API',
  WebView = 'Webview',
  SDK = 'SDK',
  ContentTargeting = 'ContentTargeting',
  /** This integration is custom UI/UX for Lane or a Customer */
  Custom = 'Custom',
}

export enum IntegrationProviderEnum {
  AccessManagement = 'AccessManagement',
  AngusServiceRequests = 'AngusServiceRequests',
  Captivate = 'Captivate',
  Cineplex = 'Cineplex',
  ControlByWeb = 'ControlByWeb',
  Essensys = 'Essensys',
  FCPBot = 'FCPBot',
  FloorMaps = 'FloorMaps',
  Gallagher = 'Gallagher',
  GoogleWorkplace = 'GoogleWorkplace',
  GoogleCalendar = 'GoogleCalendar',
  HID = 'HID',
  HOM = 'HOM',
  Kastle = 'Kastle',
  LiveSafe = 'LiveSafe',
  MicrosoftAzure = 'MicrosoftAzure',
  Newstand = 'Newstand',
  Openpath = 'Openpath',
  OutlookCalendar = 'OutlookCalendar',
  ParkWhiz = 'ParkWhiz',
  Pattison = 'Pattison',
  ProxyClick = 'ProxyClick',
  Ritual = 'Ritual',
  SAML = 'SAML',
  Auth0SAML = 'Auth0SAML',
  Safetrust = 'Safetrust',
  SaltoSVN = 'Salto SVN',
  ServiceRequest = 'ServiceRequest',
  ShopifyBurst = 'ShopifyBurst',
  ShopifyRsvp = 'ShopifyRsvp',
  ShopifyMultipass = 'ShopifyMultipass',
  ThreeTenServ = 'ThreeTenServ',
  YouRHere = 'YouRHere',
  BuildingEnginesPrism = 'BuildingEnginesPrism',
  BuildingEnginesPrismVisitors = 'BuildingEnginesPrismVisitors',
  BuildingEnginesClassic = 'BuildingEnginesClassic',
  LifeStart = 'LifeStart',
  Yardi = 'Yardi',
  YardiCommercial = 'YardiCommercial',
  Entrata = 'Entrata',
  RealPage = 'RealPage',
  MailChimp = 'MailChimp',
  Bilt = 'Bilt',
  OIDC = 'OIDC',
  SwiftConnect = 'SwiftConnect',
}
