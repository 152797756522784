import gql from 'graphql-tag';

import {
  UserGroupRoleFragment,
  ProfileFragment,
  AddressFragment,
} from '../fragments';

export default gql`
  ${ProfileFragment}
  ${AddressFragment}
  ${UserGroupRoleFragment}

  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      _id
      _created
      _updated
      status
      name
      locale
      isSuperUser
      logins {
        _id
        _created
        _updated
        type
        provider
        isPrimary
        key
        status
        tokenExpiresAt
      }
      roles {
        ...UserGroupRoleFragment
      }
      addresses {
        ...AddressFragment
      }
      profile {
        ...ProfileFragment
      }
    }
  }
`;
