import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class FloorNotFoundError extends CodedError {
  static get errorName() {
    return errorCodeTypes.floorNotFoundError.code;
  }

  constructor(message = errorCodeTypes.floorNotFoundError.message) {
    super(message);
    this.code = errorCodeTypes.floorNotFoundError.code;
    this.message = `${message} ${errorCodeTypes.floorNotFoundError.code}`;
    this.name = errorCodeTypes.floorNotFoundError.name;
  }
}
