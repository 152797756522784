import gql from 'graphql-tag';

export default gql`
  mutation AddUserLogin($userId: UUID!, $userLogin: UserLoginInput!) {
    updateUser(user: { _id: $userId, logins: [$userLogin] }) {
      _id
      logins {
        _id
        key
        status
        type
      }
    }
  }
`;
