import { v4 as uuid } from 'uuid';

import Payment from '../../renderers/v5/features/Payment';
import { FeatureDefinition } from '../../types/FeatureDefinition';
import {
  FeatureInstance,
  FeatureProperties,
  TAnyFeatureType,
} from '../../types/FeatureInstance';
import getCurrencyByGeoLocation from '../getCurrencyByGeoLocation';
import constructProperty from './constructProperty';

export default function constructFeature<
  T extends FeatureProperties = TAnyFeatureType
>({
  featureDefinition,
  location,
  userId,
}: {
  featureDefinition: FeatureDefinition;
  location: number[];
  userId: string;
}): FeatureInstance<T> {
  // generate feature data from the definition.

  const data: any = {};

  if (featureDefinition.properties) {
    Object.entries(featureDefinition.properties).forEach(
      ([key, property]) => (data[key] = constructProperty(property))
    );
  }

  // special value set for Payment feature.

  if (featureDefinition.name === Payment.name && location) {
    data.currency = getCurrencyByGeoLocation({
      longitude: location[0],
      latitude: location[1],
    });
  }

  return {
    _id: uuid(),
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    type: featureDefinition.name,
    feature: data,
  };
}
