import gql from 'graphql-tag';

import {
  PublicUserFragment,
  ThemeFragment,
  FullProfileWithThemeFragment,
  AddressFragment,
} from 'lane-shared/graphql/fragments';

export default gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${AddressFragment}

  query getChannel($id: UUID) {
    channel(_id: $id) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }

      name
      website
      description
      slug
      type
      experienceType
      isPublic
      isDiscoverable
      isLive
      isSub
      inviteOnly
      filters

      settings {
        hasPerksEnabled
        hasWorkOrdersEnabled
        hasWorkOrderServiceRequestsEnabled
        hasWorkOrderPreventiveMaintenanceEnabled
        hasWorkOrderEquipmentEnabled
        hasBillingPaymentsEnabled
        hasVisitorManagementEnabled
        hasHardwareManagementEnabled
        hasHardwarePinManagementEnabled
        hasWorkOrderCrossPropertyEnabled
        hasReservableManagementEnabled
        hasSmartUserManagementEnabled
        hasSurveysEnabled
        hasAccessControlEnabled
        areUserTemplatesAllowed
        enabledLanguagesForMembers
        channelLanguages
        multiLanguageEnabled
        subscribersJoinParent
        subscribersAreDecoyed
        groupRoleJoinRules {
          _id
          groupRoleId
          joinGroupRoleId
          channelId
        }
      }
      stats {
        sf
        subscribers
      }

      address {
        ...AddressFragment
      }

      profile {
        ...FullProfileWithThemeFragment
      }

      parent {
        _id
      }
    }
  }
`;
