import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class MagicLinkExpiredError extends CodedError {
  static get errorName() {
    return errorCodes.magicLinkExpiredError.code;
  }

  constructor(message = errorCodes.magicLinkExpiredError.message) {
    super(message);
    this.code = errorCodes.magicLinkExpiredError.code;
    this.message = `${message} ${errorCodes.magicLinkExpiredError.code}`;
    this.name = errorCodes.magicLinkExpiredError.name;
    this.status = errorCodes.magicLinkExpiredError.status;
  }
}
