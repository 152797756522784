import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class SecureIdInvalidError extends CodedError {
  static get errorName() {
    return errorCodes.secureIdInvalidError.code;
  }

  constructor(message = errorCodes.secureIdInvalidError.message) {
    super(message);
    this.code = errorCodes.secureIdInvalidError.code;
    this.message = `${message} ${errorCodes.secureIdInvalidError.code}`;
    this.name = errorCodes.secureIdInvalidError.name;
  }
}
