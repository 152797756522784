import gql from 'graphql-tag';

export default gql`
  query FlatfileToken($portal: FlatfilePortal) {
    flatfileToken(portal: $portal) {
      token
      embedId
    }
  }
`;
