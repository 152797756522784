import { IntegrationProviderEnum } from '../../../types/integrations/IntegrationEnums';

export function isRitualEnabled(integrationsList: any) {
  return integrationsList.some(
    (integrations: any) =>
      integrations.integration?.name === IntegrationProviderEnum.Ritual
  );
}

export function RitualFilter(integrationsList: any) {
  return integrationsList.filter(
    (integrations: any) =>
      integrations.integration?.name !== IntegrationProviderEnum.Ritual
  );
}
