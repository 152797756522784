import { CIRCUMFERENCE_OF_EARTH } from './constants';
import distance from './distance';

export default function calculateChannelDistance({
  latitude,
  longitude,
  channel,
}: {
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  channel: {
    address?: {
      geo: number[];
    };
  };
}): number {
  if (
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
    ![undefined, null].includes(latitude) &&
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
    ![undefined, null].includes(longitude) &&
    channel?.address?.geo
  ) {
    return distance(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
      latitude,
      longitude,
      channel.address.geo[1],
      channel.address.geo[0]
    );
  }

  // if no distance is found then set the distance of the channel to the
  // farthest distance possible on earth.
  return CIRCUMFERENCE_OF_EARTH;
}
