import gql from 'graphql-tag';

export default gql`
  query queryContent($id: UUID!) {
    content(_id: $id) {
      _id
      _created
      _createdBy {
        _id
      }
      _updated
      _updatedBy {
        _id
      }
      name
      subtitle
      startDate
      endDate
      block
      data
      integration {
        _id
        integration {
          _id
          name
        }
      }
      generator {
        _id
      }
    }
  }
`;
