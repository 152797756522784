import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class OAuthClientSecretError extends CodedError {
  static get errorName() {
    return errorCodes.oAuthClientSecretError.code;
  }

  constructor(message = errorCodes.oAuthClientSecretError.message) {
    super(message);
    this.code = errorCodes.oAuthClientSecretError.code;
    this.message = `${message} ${errorCodes.oAuthClientSecretError.code}`;
    this.name = errorCodes.oAuthClientSecretError.name;
    this.status = errorCodes.oAuthClientSecretError.status;
  }
}
