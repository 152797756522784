import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Flex } from 'components';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ContentForCards } from 'lane-shared/types/content/ContentForCards';

import { H5, XS } from 'components/typography';

import styles from './CompactContentCard.scss';

type Props = {
  content: ContentForCards;
  className?: string;
  style?: React.CSSProperties;
};

export default function CompactContentCard({
  content,
  className,
  style,
}: Props) {
  const backgroundImage =
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'backgroundImageId' does not exist on typ... Remove this comment to see the full error message
    content?.backgroundImage || content?.backgroundImageId;
  return (
    <Flex
      p={3}
      data-test="CompactContentCard"
      align="center"
      className={cx(styles.CompactContentCard, className)}
      gap={3}
      style={style}
    >
      {backgroundImage && (
        <div
          className={cx(styles.hero)}
          style={{
            backgroundImage: `url(${imageUrl(backgroundImage)})`,
          }}
        />
      )}
      <div style={{ flex: 1 }}>
        <H5 className={styles.title}>{content.name}</H5>
        <XS className={styles.description} variant="secondary">
          {content.description}
        </XS>
      </div>

      <Link to={routes.content.replace(':id', content._id)} target="_blank">
        <Icon name="ExternalLink" style={{ fontSize: '1em' }} />
      </Link>
    </Flex>
  );
}
