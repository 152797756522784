/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useRef } from 'react';

import { Icon } from '../Icon';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import cx from 'classnames';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select, { components } from 'react-select';
import { v4 as uuid } from 'uuid';

import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

import { M, XS } from '../Typography/Typography';

import ValidationMessage from '../ValidationMessage/ValidationMessage';
import {
  getStyles,
  primaryColors,
  secondaryColors,
  invisibleColors,
  darkModeColors,
} from './colors';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import AsyncSelect from 'react-select/async';

import styles from './dropdown.scss';
import { Loading } from '../Loading/Loading';
// @ts-expect-error Could not find a declaration file for module 'react-select/creatable'
import Creatable from 'react-select/creatable';
import { Checkbox } from '../Checkbox/Checkbox';

type Color = 'primary' | 'secondary' | 'invisible';

export type DropdownItem<T> = {
  label: string;
  value: T;
  isDisabled?: boolean;
  isItemDisabled?: boolean;
  isValueDisabled?: boolean;
};

export type BaseProps<T> = {
  onKeyPress?: (e: any) => void;
  onBlur?: (e: any) => void;
  /** optional if you want to add an identification to be returned in the onChange() */
  name?: string;
  /** dropdown theme */
  color?: Color;
  /** dropdown items */
  items?: DropdownItem<T>[];
  initialTouched?: boolean;
  // is the dropdown searchable.
  isSearchable?: boolean;
  /** placeholder if init value is not provided */
  placeholder?: string;
  className?: string | string[];
  rightIconClassName?: string;
  icon?: string;
  iconType?: string;
  rightIcon?: string;
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
  /** DEPRECATED: use invalid instead, or use the ValidatedDropdown */
  errors?: string[] | null;
  success?: boolean;
  error?: boolean;
  id?: string;
  interfaceStyle?: 'dark' | 'light';
  isFullWidth?: boolean;
  menuPosition?: 'absolute' | 'fixed';
  testId?: string;
  ariaLabel?: string;
  fixedLabel?: boolean;
  closeMenuOnSelect?: boolean;
  loadOptions?: (inputValue: string) => Promise<DropdownItem<T>[]>;
  defaultOptions?: DropdownItem<T>[];
  doTranslation?: boolean;
  noOptionsMessage?: string;
  isRequired?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  onCreateOption?: (name: string) => void;
};

type DropdownProps<T> = BaseProps<T> & {
  isMulti?: false;
  loadOptions?: never; // if load options is not passed
  value?: T | null; // value should expect this type
  onChange?: (item: DropdownItem<T>) => void;
  onValueChange?: (value: T) => void;
  /** default initial value for dropdown */
  initialValue?: T | null;
  truncateSelectedItems?: never;
  hideSelectedOptions?: never;
};

type MultiDropdownProps<T> = BaseProps<T> & {
  isMulti?: true;
  value?: DropdownItem<T>[] | null;
  onChange?: (item: DropdownItem<T>[]) => void;
  onValueChange?: (value: T[]) => void;
  /** default initial value for dropdown */
  initialValue?: T[] | null;
  truncateSelectedItems?: boolean;
  hideSelectedOptions?: boolean;
};

type AsyncDropdownProps<T> = BaseProps<T> & {
  isMulti?: false;
  loadOptions: (inputValue: string) => Promise<DropdownItem<T>[]>; // if loadOptions is passed
  value?: null | DropdownItem<T>; // value should expect this type
  onChange?: (item: DropdownItem<T>) => void;
  onValueChange?: (value: T) => void;
  initialValue?: T | null;
  truncateSelectedItems?: never;
  hideSelectedOptions?: never;
};

export type Props<T> =
  | DropdownProps<T>
  | MultiDropdownProps<T>
  | AsyncDropdownProps<T>;

const isMultiDropdown = <T extends unknown>(
  props: Props<T>
): props is MultiDropdownProps<T> => !!props.isMulti;

BaseDropdown.defaultProps = {
  name: `Dropdown-${uuid()}`,
  color: 'primary',
  onKeyPress: () => null,
  onChange: () => null,
  onValueChange: () => null,
  onBlur: () => null,
  isSearchable: true,
  initialValue: null,
  initialTouched: false,
  placeholder: 'web.components.dropdown.selectOption',
  value: null,
  errors: null,
  success: false,
  error: false,
  id: 'dropdown',
  interfaceStyle: 'light',
  isFullWidth: false,
  menuPosition: 'fixed',
  testId: 'dropdown',
  doTranslation: true,
  isLoading: false,
};

function CustomValueContainer({
  children,
  hasValue,
  getValue,
  ...props
}: components.ValueContainerProps) {
  const { t } = useTranslation();

  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  const CHIPS_LIMIT = 1;
  const [chips, otherChildren] = children;
  const chipsCount = getValue().length;

  return (
    <components.ValueContainer {...props}>
      {chipsCount > CHIPS_LIMIT ? (
        <span className={styles.MultiValueContainer}>
          <span className={styles.SelectedItemsLabel}>
            {t('web.components.dropdown.itemsSelected', {
              count: chipsCount,
            })}
          </span>
        </span>
      ) : (
        chips
      )}

      {otherChildren}
    </components.ValueContainer>
  );
}

export function BaseDropdown<T>(props: Props<T>) {
  const {
    className,
    items = [],
    name,
    color,
    label,
    value,
    onKeyPress,
    onBlur,
    disabled,
    errors,
    success,
    invalid,
    initialValue,
    initialTouched,
    placeholder,
    isSearchable,
    icon,
    iconType,
    rightIcon,
    rightIconClassName,
    interfaceStyle,
    id,
    isFullWidth,
    menuPosition,
    testId,
    ariaLabel,
    fixedLabel = false,
    isMulti = false,
    closeMenuOnSelect = true,
    loadOptions,
    defaultOptions,
    truncateSelectedItems = false,
    doTranslation,
    noOptionsMessage,
    isRequired,
    isClearable,
    isLoading,
    onCreateOption,
    hideSelectedOptions = false,
  } = props;
  const [isTouched, setIsTouched] = React.useState(initialTouched);
  const [isOpened, setIsOpened] = React.useState(false);
  const { t } = useTranslation();

  let SelectComponent = loadOptions ? AsyncSelect : Select;

  if (onCreateOption) {
    SelectComponent = Creatable;
  }

  const dropdownStyles = useMemo(() => {
    switch (color) {
      case 'secondary':
        return getStyles({ colors: secondaryColors, success, invalid });
      case 'invisible':
        return getStyles({ colors: invisibleColors, success, invalid });
      default:
        return getStyles({
          colors: interfaceStyle === 'light' ? primaryColors : darkModeColors,
          success,
          invalid,
          disabled,
          icon: !!icon,
        });
    }
  }, [color, success, errors, value, disabled]);

  const options = useMemo(
    () =>
      items.map(item => ({
        label: doTranslation ? t(item.label) : item.label,
        value: item.value,
        isDisabled: item?.isDisabled,
        isItemDisabled: item?.isItemDisabled,
        isValueDisabled: item?.isValueDisabled,
      })),
    [items]
  );

  const { Option } = components;

  function IconOption(props: any) {
    return (
      <Option {...props}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        {icon ? <Icon set="FontAwesome" name={icon} type={iconType} /> : null}
        {props?.data?.label}
      </Option>
    );
  }

  function CustomSelectValue(props: any) {
    return (
      <div
        className={
          isOpened ? styles.hideCustomSelectValue : styles.customSelectValue
        }
        data-interface-style={interfaceStyle}
        data-option-with-icon={Boolean(icon)}
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        {icon ? <Icon set="FontAwesome" name={icon} type={iconType} /> : null}
        <M>{props?.data?.label}</M>
      </div>
    );
  }

  function CustomPlaceHolder() {
    const isLabelShown = label && !isTouched && !fixedLabel;
    const placeHolderText =
      doTranslation || placeholder === BaseDropdown.defaultProps.placeholder
        ? t(placeholder || '')
        : placeholder;
    const placeholderToUse = isLabelShown ? label : placeHolderText;

    return (
      <div
        className={
          isOpened ? styles.hideCustomSelectValue : styles.customSelectValue
        }
        data-option-with-icon={Boolean(icon)}
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <Icon set="FontAwesome" name={icon} type={iconType} />
        {placeholderToUse}
      </div>
    );
  }

  function CustomIndicatorError() {
    return (
      <div className={styles.CustomIndicatorError}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <Icon set="FontAwesome" name="exclamation-circle" type={iconType} />
      </div>
    );
  }

  function CustomIndicator() {
    return (
      <div className={cx(styles.CustomIndicator, rightIconClassName)}>
        <Icon
          set="FontAwesome"
          name={rightIcon || 'angle-down'}
          // @ts-ignore Type 'string | undefined' is not assignable to typ...
          type={iconType || FONT_AWESOME_REGULAR}
          testId="angle-down"
        />
      </div>
    );
  }

  function CustomMultiValueContainer({
    children,
    innerProps,
    data,
  }: components.MultiValueContainerProps) {
    return (
      <span
        {...innerProps}
        className={cx(
          styles.MultiValueContainer,
          data?.isValueDisabled && styles.DisabledItem
        )}
      >
        {children}
      </span>
    );
  }

  function CustomMultiOption(props: components.OptionProps) {
    return (
      !props.data?.isDisabled && (
        <M
          className={cx(props.data?.isItemDisabled && styles.DisabledItem)}
          ref={props.innerRef}
          {...props.innerProps}
          style={props.getStyles('option', props)}
        >
          <Checkbox
            style={dropdownStyles.optionCheckboxContainer()}
            selected={props.isSelected}
            value={props.getValue()}
            onChange={() => props.selectOption(props.data)}
          />
          <p className="text-nowrap overflow-hidden text-ellipsis">
            {props.children}
          </p>
        </M>
      )
    );
  }

  function CustomMultiValueRemove(props: components.MultiValueRemoveProps) {
    return (
      <div
        data-test="customMultiValueRemoveIcon"
        style={{ backgroundColor: 'none' }}
        {...props.innerProps}
        className=""
      >
        {!props.data?.isDisabled && <Icon name="times" />}
      </div>
    );
  }

  const getValue = () => {
    if (loadOptions) {
      return value;
    }
    if (isMulti) {
      if (options.length === 0) {
        return value;
      }
      return Array.isArray(value)
        ? options.filter(option =>
            value.some(
              selectValue =>
                (selectValue as DropdownItem<T>).value === option.value
            )
          )
        : [];
    }
    return value && options.find(option => isEqual(option.value, value));
  };

  const containerRef = useRef(null);
  // todo: remove ValidationMessage and errors prop once code base is refactored

  return (
    <InputWrapper
      label={fixedLabel ? label : undefined}
      isRequired={isRequired}
      id={id}
    >
      <div
        className={styles.mainContainer}
        ref={containerRef}
        data-test={testId}
      >
        <SelectComponent
          isLoading={isLoading}
          minMenuHeight={`${Math.min(options.length, 5) * 41}px`}
          loadingMessage={Loading}
          inputId={id}
          isDisabled={disabled}
          openMenuOnFocus
          className={cx(
            styles.Dropdown,
            isFullWidth && styles.fullWidth,
            className
          )}
          placeholder={placeholder}
          defaultValue={
            initialValue &&
            options.find(option => isEqual(option.value, initialValue))
          }
          value={getValue()}
          onChange={(selectedOption: DropdownItem<T> | DropdownItem<T>[]) => {
            if (isMultiDropdown(props)) {
              if (props.onChange)
                props.onChange(selectedOption as DropdownItem<T>[]);
              if (props.onValueChange)
                props.onValueChange(
                  selectedOption
                    ? (selectedOption as DropdownItem<T>[]).map(
                        option => option.value
                      )
                    : selectedOption
                );
            } else {
              if (props.onChange)
                props.onChange(selectedOption as DropdownItem<T>);
              if (props.onValueChange) {
                props.onValueChange(
                  selectedOption
                    ? (selectedOption as DropdownItem<T>).value
                    : selectedOption
                );
              }
            }
          }}
          onKeyPress={onKeyPress}
          tabSelectsValue={!isMulti}
          onMenuOpen={() => {
            setIsOpened(true);
          }}
          onMenuClose={() => {
            setIsOpened(false);
          }}
          onFocus={() => {
            if (!isTouched) setIsTouched(true);
          }}
          onBlur={onBlur}
          isSearchable={isSearchable}
          name={name}
          options={options}
          components={{
            SingleValue: CustomSelectValue,
            Option: IconOption,
            Placeholder: CustomPlaceHolder,
            IndicatorSeparator: invalid && CustomIndicatorError,
            DropdownIndicator: CustomIndicator,
            ...(isMulti
              ? {
                  Option: CustomMultiOption,
                  ValueContainer: truncateSelectedItems
                    ? CustomValueContainer
                    : components.ValueContainer,
                  MultiValueContainer: CustomMultiValueContainer,
                  MultiValueRemove: CustomMultiValueRemove,
                }
              : {}),
          }}
          styles={dropdownStyles}
          closeMenuOnScroll={(e: any) => {
            return e.target.contains(containerRef.current);
          }}
          menuPosition={menuPosition}
          menuShouldScrollIntoView={false}
          menuPlacement="auto"
          aria-label={ariaLabel}
          noOptionsMessage={() => noOptionsMessage ?? t('No options')}
          hideSelectedOptions={hideSelectedOptions}
          closeMenuOnSelect={closeMenuOnSelect}
          isMulti={isMulti}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          isClearable={isClearable}
          onCreateOption={onCreateOption}
        />
        {label && !fixedLabel && (
          <label
            htmlFor={id}
            className={styles.label}
            data-touched={isTouched}
            data-interface-style={interfaceStyle}
          >
            <XS>{label}</XS>
          </label>
        )}

        {invalid && (
          <ValidationMessage
            errors={errors}
            iconType={iconType}
            withoutIcon
            doTranslation={doTranslation}
          />
        )}
      </div>
    </InputWrapper>
  );
}

export const Dropdown = <T,>(
  props: DropdownProps<T> | AsyncDropdownProps<T>
) => <BaseDropdown {...props} />;
