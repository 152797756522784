import gql from 'graphql-tag';

export default gql`
  query audiences(
    $channelId: UUID!
    $pagination: PaginationInput
    $search: AudienceSearch
  ) {
    audiences(channelId: $channelId, pagination: $pagination, search: $search) {
      items {
        name
        id
        createdAt
        updatedAt
        Channel {
          name
        }
        channelTargetingRules {
          id
        }
        groupRoleTargetingRules {
          id
        }
        reachSize
      }
      pageInfo {
        total
        start
        perPage
      }
    }
  }
`;
