import React from 'react';

import Calendar from './components/Calendar';

import styles from './DatePickers.scss';

type OwnProps = {
  onChange: (...args: any[]) => any;
  value?: any;
  includeTime?: boolean;
  minDate?: any;
  maxDate?: any;
  timeZone?: string;
};

type Props = OwnProps;

/*
  @deprecated use DatePickerButton in design-system-web instead.  
*/
function DatePicker({
  value = null,
  includeTime = false,
  onChange,
  minDate = null,
  maxDate = null,
  timeZone,
}: Props) {
  return (
    <div className={styles.DatePicker}>
      <Calendar
        onChange={onChange}
        startDate={value}
        includeTime={includeTime}
        minDate={minDate}
        maxDate={maxDate}
        timeZone={timeZone}
      />
    </div>
  );
}

export default DatePicker;
