import gql from 'graphql-tag';

export default gql`
  fragment DraftContentFragment on DraftContent {
    _id
    _created
    _updated
    name
    name_l10n
    description
    description_l10n
    subtitle
    slug
    type
    tags
    category
    reportingGroup
    color
    isInteractive
    isPublished
    isEvent
    backgroundImage
    icon
    iconSet
    iconWeight
    backgroundColor
    liveDate
    startDate
    endDate
    unpublishDate
    interactionEndDate
    version
    renderer
    channel {
      _id
      settings {
        multiLanguageEnabled
        channelLanguages
        enabledLanguagesForMembers
      }
    }
    card {
      _id
    }
    externalUrl {
      _id
      url
      openingMode
    }
    deepLink {
      _id
      deepLinkUrl
      appStoreUrl
      googlePlayStoreUrl
    }
    integration {
      _id
      settings
      integration {
        _id
        name
        settings
      }
    }
    generator {
      _id
    }
    generatedChildren {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        name
      }
    }
  }
`;
