import { gql } from 'graphql-query-contracts';

/**
 * GQL Create Preventive Maintenance Schedule Mutation
 */

export default gql(/* GraphQL */ `
  mutation createPMScheduleMutation($createPMSchedule: CreatePMScheduleDTO!) {
    createPMSchedule(schedule: $createPMSchedule) {
      scheduleId
      extRefId {
        id
        name
      }
      title
      nextDueDate
      untilDate
      repeats
      daysAhead
      weekday
      weekNo
      monthNo
      interval
      assignee {
        id
        name
      }
      equipmentIds
      notes
      timeToComplete
      completeWithin
      steps
      meterReading
      createdBy
      updatedBy
      createdAt
      updatedAt
      isArchived
    }
  }
`);
