import { gql } from 'graphql-query-contracts';

export const getResidentProfile = gql(`
query getResidentProfile($personId: UUID!, $propertyId: UUID!) {
    resident(personId: $personId, propertyId: $propertyId) {
        id
        status
        unitIds
        moveInDate
        moveOutDate
    }
}
`);
