import gql from 'graphql-tag';

export const updateAudience = gql`
  mutation updateAudience($audience: UpdateAudienceInput) {
    updateAudience(audience: $audience) {
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
      name
      archived
      createdByChannel
      managementChannels
      shareChannels
      contentToTarget
      channelTargetingRules {
        id
        name
        channelId
        audienceId
      }
      groupRoleTargetingRules {
        id
        name
        groupRoleId
        audienceId
      }
    }
  }
`;
