import gql from 'graphql-tag';

export default gql`
  query Blocks {
    contentBlocks {
      _id
      _created
      _updated
      name
      description
      type
      availableForContentTypes
      dependencies
      for
      tags
      version
      renderer
      data
      properties
      block
      publishes
      state
      settings
      styleState
    }

    contentCards {
      _id
      _created
      _updated
      name
      description
      for
      type
    }
  }
`;
