export enum SupportedLocaleEnum {
  English = 'en-US',
  Portuguese = 'pt-PT',
  PortugueseBrazil = 'pt-BR',
  German = 'de-DE',
  French = 'fr-FR',
  FrenchCanadian = 'fr-CA',
  SpanishSpain = 'es-ES',
  SpanishMexico = 'es-MX',
  EnglishUK = 'en-GB',
  Italian = 'it-IT',
  ChineseTraditional = 'zh-HK',
  Dutch = 'nl-NL',
  GermanAustrian = 'de-AT',
  Thai = 'th-TH',
  Japanese = 'ja-JP',
  SpanishCatalan = 'ca-ES',
}

export type SupportedLocales = `${SupportedLocaleEnum}`;

export type LocalizedField = { [key in SupportedLocales]?: string };

export const SupportedLocalesFriendlyNames = {
  [SupportedLocaleEnum.SpanishCatalan]: 'Català',
  [SupportedLocaleEnum.German]: 'Deutsch',
  [SupportedLocaleEnum.GermanAustrian]: 'Deutsch (Österreich)',
  [SupportedLocaleEnum.English]: 'English',
  [SupportedLocaleEnum.EnglishUK]: 'English (UK)',
  [SupportedLocaleEnum.SpanishSpain]: 'Español (España)',
  [SupportedLocaleEnum.SpanishMexico]: 'Español (México)',
  [SupportedLocaleEnum.French]: 'Français',
  [SupportedLocaleEnum.FrenchCanadian]: 'Français (Canada)',
  [SupportedLocaleEnum.Italian]: 'Italiano',
  [SupportedLocaleEnum.Dutch]: 'Nederlands',
  [SupportedLocaleEnum.Portuguese]: 'Português',
  [SupportedLocaleEnum.PortugueseBrazil]: 'Português (Brasileiro)',
  [SupportedLocaleEnum.ChineseTraditional]: '中文(繁體)',
  [SupportedLocaleEnum.Japanese]: '日本語',
  [SupportedLocaleEnum.Thai]: 'ภาษาไทย',
} as const;

export const friendlyLocaleItems = typedObjectEntriesMap(
  SupportedLocalesFriendlyNames,
  ([value, label]) => ({
    value,
    label,
  })
);

export const DefaultLocale: SupportedLocaleEnum = SupportedLocaleEnum.English;

export function parseSupportedLocaleEnum(value: string) {
  return Object.values(SupportedLocaleEnum).find(locale => locale === value);
}

/**
 * if you have a map of enum keys to string values, ie.
 * SupportedLocalesFriendlyNames = {
 *   [SupportedLocaleEnum.English]: 'English',
 *   [SupportedLocaleEnum.Portuguese]: 'Português',
 *   [SupportedLocaleEnum.Spanish]: 'Español',
 * }
 * this function works as you would expect the following to:
 * Object.entries(SupportedLocalesFriendlyNames).map(([key, value]) => (...))
 * except that it keeps the key's SupportedLocaleEnum type in the callback function, where as the above forces it to be a string
 */
function typedObjectEntriesMap<K extends string, V, T>(
  object: Record<K, V>,
  callback: (value: [K, V], index: number, array: [K, V][]) => T,
  thisArg?: any
) {
  return Object.entries(object).map(
    ([key, value], index, array) =>
      callback([key as K, value as V], index, array as [K, V][]),
    thisArg
  );
}

export function isSupportedLocale(value: string): value is SupportedLocales {
  return Object.values(SupportedLocaleEnum).includes(value as any);
}
