import { DataStatus } from '../types';

export const getNextAccessGroupStatus = (
  currentStatus: DataStatus,
  prevStatus: DataStatus,
  mixedSelectOnly: boolean = false
) => {
  const statusMapper = {
    [DataStatus.mixed]: mixedSelectOnly
      ? DataStatus.selected
      : DataStatus.unselected,
    [DataStatus.selected]:
      prevStatus === DataStatus.mixed
        ? DataStatus.mixed
        : DataStatus.unselected,
    [DataStatus.unselected]: DataStatus.selected,
    default: DataStatus.selected,
  };

  return statusMapper[currentStatus] ?? statusMapper.default;
};
