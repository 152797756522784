import React from 'react';

import { OrderSummaryType } from 'lane-shared/domains/billingPayments/types';
import { imageUrl } from 'lane-shared/helpers/formatters';

import { OrderSummaryItemList } from './OrderSummaryItemList';

// @ts-ignore - issue with typescript resolving scss paths
import styles from './OrderPreview.scss';

export type OrderPreviewProps = {
  backgroundImage: string;
  orderSummary: OrderSummaryType;
};

/**
 * @deprecated 5.190.0 - use OrderSummaryWithProducts.
 */
export function OrderPreview({
  backgroundImage,
  orderSummary,
}: OrderPreviewProps) {
  return (
    <div className={styles.previewContainer}>
      {backgroundImage && (
        <div>
          <div
            className={styles.previewSectionImage}
            data-test="order-preview-image"
            style={{
              backgroundImage: `url(${imageUrl(backgroundImage)})`,
            }}
          />
        </div>
      )}
      <OrderSummaryItemList orderSummaryItems={orderSummary.items} />
    </div>
  );
}
