import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { DeleteProductExceptionRequest } from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { GroupType } from 'graphql-query-contracts';

export const mutation = gql`
  mutation deleteProductException(
    $deleteProductExceptionRequest: DeleteProductExceptionRequest!
  ) {
    deleteProductException(
      deleteProductExceptionRequest: $deleteProductExceptionRequest
    )
  }
`;

export async function deleteProductExceptionMutation(
  productId: string,
  channelId: string
) {
  return await getClient().mutate<{
    deleteProductExceptionRequest: DeleteProductExceptionRequest;
  }>({
    mutation,
    variables: {
      deleteProductExceptionRequest: {
        id: productId,
        group: {
          id: convertToUUID(channelId),
          type: GroupType.GroupTypeActivateChannel,
        },
      },
    },
  });
}
