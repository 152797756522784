import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ContentFeatureCancelableError extends CodedError {
  static get errorName() {
    return errorCodes.contentFeatureCancelableError.code;
  }

  constructor(message = errorCodes.contentFeatureCancelableError.message) {
    super(message);
    this.code = errorCodes.contentFeatureCancelableError.code;
    this.message = `${message} ${errorCodes.contentFeatureCancelableError.code}`;
    this.name = errorCodes.contentFeatureCancelableError.name;
  }
}
