import React, { useState } from 'react';

import cx from 'classnames';
import { Button, ControlMenu, Input } from 'components';
import { useTranslation } from 'react-i18next';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { H4, S } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import EmailOptInLegacy from './EmailOptInLegacy';
import WhitelabelTermsAndPolicy from './WhitelabelTermsAndPolicy';

import styles from './Signup.scss';

export type Props = {
  name: string;
  whitelabel: WhiteLabelType;
  onNext: (options: {
    isSSOUsed: boolean;
    name: string;
    emailOptIn: boolean;
  }) => void;
};

export function SignUpOAuthInterstitial({ name, whitelabel, onNext }: Props) {
  const { t } = useTranslation();

  const [fullName, setFullName] = useState(name);
  const [emailOptIn, setEmailOptIn] = useState<boolean>(false);

  function handleSubmit() {
    onNext({ isSSOUsed: true, emailOptIn, name: fullName });
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className={cx(welcomeFormStyles.formContents, styles.formContents)}>
        <div className={welcomeFormStyles.infoContainer}>
          <H4 mb={4} className={welcomeFormStyles.formTitle} v2>
            {t('Please confirm your name.')}
          </H4>
          <S mb={4}>{t('Start improving your workplace experience.')}</S>
          <div className={welcomeFormStyles.inputContainer}>
            <Input
              className={welcomeFormStyles.inputMargin}
              type="text"
              label={t('Full Name')}
              onChange={setFullName}
              value={fullName}
              error={null}
            />
          </div>
        </div>
        <WhitelabelTermsAndPolicy whitelabel={whitelabel} />
        <ControlMenu className={welcomeFormStyles.menu}>
          <Button
            className={cx(
              welcomeFormStyles.actionButton,
              styles.agreeAndContinue
            )}
            disabled={false}
            testId="signUpButton"
            variant="contained"
            color="inherit"
            type="submit"
          >
            {t('Agree and continue')}
          </Button>
        </ControlMenu>
        <EmailOptInLegacy
          emailOptIn={emailOptIn}
          onChange={() => {
            setEmailOptIn(prevEmailOptIn => !prevEmailOptIn);
          }}
        />
      </div>
    </form>
  );
}
