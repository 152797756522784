import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ChannelMaxUsersError extends CodedError {
  static get errorName() {
    return errorCodes.channelMaxUsersError.code;
  }

  constructor(message = errorCodes.channelMaxUsersError.message) {
    super(message);
    this.code = errorCodes.channelMaxUsersError.code;
    this.message = `${message} ${errorCodes.channelMaxUsersError.code}`;
    this.name = errorCodes.channelMaxUsersError.name;
  }
}
