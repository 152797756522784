import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export default class FeatureNameNotFoundError extends CodedError {
  static get errorName() {
    return errorCodes.featureNameNotFoundError.code;
  }

  constructor(message = errorCodes.featureNameNotFoundError.message) {
    super(message);
    this.code = errorCodes.featureNameNotFoundError.code;
    this.message = `${message} ${errorCodes.featureNameNotFoundError.code}`;
    this.name = errorCodes.featureNameNotFoundError.name;
    this.status = errorCodes.featureNameNotFoundError.status;
  }
}
