import { useContext } from 'react';
import {
  UserBillingAndPaymentContext,
  UserBillingAndPaymentContextType,
} from '../userBillingAndPaymentContext';

export const useUserBillingAndPaymentContext = (): UserBillingAndPaymentContextType => {
  const context = useContext(UserBillingAndPaymentContext);

  if (!context) {
    throw new Error(
      'UserBillingAndPaymentontext must be used within UserBillingAndPaymentontext scope'
    );
  }

  return context;
};
