import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';

export function ButtonCreateGroup({ onCreate, loading }: any) {
  const { t } = useTranslation();

  return (
    <Button
      loading={loading}
      onClick={onCreate}
      variant="contained"
      testId="buttonCreateOrSave"
    >
      {t('web.components.lane.Team.Header.ButtonCreateGroup')}
    </Button>
  );
}
