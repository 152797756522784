import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export default class HardwareManagementNotEnabledError extends CodedError {
  static get errorName() {
    return errorCodes.hardwareManagementNotEnabledError.code;
  }

  constructor(message = errorCodes.hardwareManagementNotEnabledError.message) {
    super(message);
    this.code = errorCodes.hardwareManagementNotEnabledError.code;
    this.message = `${message} ${errorCodes.hardwareManagementNotEnabledError.code}`;
    this.name = errorCodes.hardwareManagementNotEnabledError.name;
    this.status = errorCodes.hardwareManagementNotEnabledError.status;
  }
}
