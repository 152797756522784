import gql from 'graphql-tag';

import { SectionFragment } from '../fragments';

export default gql`
  ${SectionFragment}

  query getSectionsByChannel(
    $id: UUID
    $slug: String
    $sectionNameIncludes: String
    $pagination: PaginationInput
    $order: String
    $orderBy: [String]
  ) {
    channelSections(
      _id: $id
      slug: $slug
      sectionNameIncludes: $sectionNameIncludes
      pagination: $pagination
      order: $order
      orderBy: $orderBy
    ) {
      sections {
        ...SectionFragment
        channel {
          _id
        }
      }
      total
    }
  }
`;
