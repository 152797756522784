import gql from 'graphql-tag';

export default gql`
  mutation manageUserCompanies(
    $addedChannelIds: [UUID!]
    $removedChannelIds: [UUID!]
    $userId: UUID!
    $propertyChannelId: UUID!
  ) {
    manageUserCompanies(
      addedChannelIds: $addedChannelIds
      removedChannelIds: $removedChannelIds
      userId: $userId
      propertyChannelId: $propertyChannelId
    )
  }
`;
