import React from 'react';

import cx from 'classnames';
import { RichText } from 'components';

import RichTextDefault from './RichTextField.default.json';

import styles from './RichTextField.scss';

RichTextField.defaultValue = RichTextDefault;

export default function RichTextField({
  className,
  style,
  value,
  onChange,
  maxCharacters,
  testId,
}: {
  className?: string;
  style?: React.CSSProperties;
  value: any;
  onChange: any;
  maxCharacters?: number;
  testId?: string;
}) {
  return (
    <RichText
      value={value}
      onChange={onChange}
      maxCharacters={maxCharacters}
      className={cx(styles.richTextField, className)}
      style={style}
      inputClassName={styles.richTextInput}
      toolBarClassName={styles.richTextToolbar}
      testId={testId}
    />
  );
}
