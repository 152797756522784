import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  mutation archiveMeterReadingValueMutation(
    $meterReadingValueId: UUID!
    $channelId: String!
  ) {
    archiveMeterReadingValue(
      meterReadingValueId: $meterReadingValueId
      channelId: $channelId
    )
  }
`);
