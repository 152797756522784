export const WORK_ORDER_CONTENT_NAME = 'Service requests';
export const WORK_ORDER_CONTENT_NAME_COMPANY = 'Workplace request';
export const DEFAULT_WO_CONTENT_COLOR = 'rgba(1,42,45,1)';
export const DEFAULT_WO_CONTENT_ICON = 'tools';
export const DEFAULT_WO_CONTENT_ICON_SET = 'FontAwesome';

export enum ServiceRequestStatusEnum {
  Created = 'Created',
  Open = 'Open',
  Accepted = 'Accepted',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Closed = 'Closed',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
}

export enum TaskStatusEnum {
  Created = 'Created',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Withdrawn = 'Withdrawn',
  Completed = 'Completed',
}

export enum MeterReadingValueType {
  ADHOC = 'ADHOC',
  TASK = 'TASK',
}

export enum EquipmentStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
