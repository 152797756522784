import { STALE_CHANNEL_NOTIIFICATION_FLAG } from './constants';
import store from './store';

/*
 * Check whether or not we should show the stale channel notification
 *
 * Example:
 *
 * import * as staleChannelNotification from '...';
 *
 * if(staleChannelNotification.shouldShow()) {...}
 *
 * * */
export default function shouldShow(): boolean {
  const flag: string | null = store.getItem(STALE_CHANNEL_NOTIIFICATION_FLAG);
  if (flag === 'true') return true;
  // Anything else return false including 'false' value
  return false;
}
