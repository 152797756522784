import gql from 'graphql-tag';

export default gql`
  query ProxyclickListVisits(
    $channelIntegrationId: UUID!
    $from: DateTime
    $to: DateTime
    $companyId: ID!
    $page: Int
  ) {
    proxyclickListVisits(
      channelIntegrationId: $channelIntegrationId
      from: $from
      to: $to
      companyId: $companyId
      page: $page
    ) {
      items {
        expectedAt
        leavingAt
        visitor {
          firstName
          lastName
          picture
        }
        meeting {
          _id
          host {
            email
            picture
            firstName
            lastName
          }
        }
        status {
          value
        }
        company {
          _id
          logoOriginal
          name
        }
      }
    }
  }
`;
