import React from 'react';

import cx from 'classnames';

import styles from './style.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  fullscreen?: boolean;
  fullcover?: boolean;
  testId?: string;
};

export const Loading = ({
  className,
  style,
  fullscreen = false,
  fullcover = false,
  testId = 'loading-icon',
}: Props) => (
  <div
    className={cx(styles.Loading, className)}
    style={style}
    data-fullscreen={fullscreen}
    data-fullcover={fullcover}
    data-test={testId}
  >
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  </div>
);
