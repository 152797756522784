import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ContentMissingCompleteWorkflowError extends CodedError {
  static get errorName() {
    return errorCodes.contentMissingCompleteWorkflowError.code;
  }

  constructor(
    message = errorCodes.contentMissingCompleteWorkflowError.message
  ) {
    super(message);
    this.code = errorCodes.contentMissingCompleteWorkflowError.code;
    this.message = `${message} ${errorCodes.contentMissingCompleteWorkflowError.code}`;
    this.name = errorCodes.contentMissingCompleteWorkflowError.name;
  }
}
