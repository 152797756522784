import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

const ERROR = errorCodes.outlookCalendarChannelIntegrationSettingsNotFound;

export class OutlookCalendarChannelIntegrationSettingsNotFound extends CodedError {
  static get errorName() {
    return ERROR.code;
  }

  constructor(message = ERROR.message) {
    super(message);
    this.code = ERROR.code;
    this.message = `${message} ${ERROR.code}`;
    this.name = ERROR.name;
  }
}
