import React, { memo } from 'react';

import BlockWireFrame from './BlockWireFrame';

import styles from './BlockHierarchy.scss';

function BlockHierarchy({
  hierarchy,
  focusBlock,
  activeBlock,
  dragOverBlock,
}: any) {
  return (
    <menu className={styles.BlockHierarchy} data-test="blockHierarchy">
      <BlockWireFrame
        block={hierarchy}
        focusBlock={focusBlock}
        activeBlock={activeBlock}
        dragOverBlock={dragOverBlock}
        isTop
      />
    </menu>
  );
}

export default memo(BlockHierarchy);
