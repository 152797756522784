import gql from 'graphql-tag';

export default gql(`
  fragment UserGroupRoleFragment on UserGroupRole {
    _id
    _created
    _updated
    name
    description
    isPublic
    settings
    groupRole {
      _id
      name
      permissions
      requirements
      isPublic
      channel {
        _id
        type
        hierarchy {
          hierarchyUp
          hierarchyDown
        }        
      }
    }
  }
`);
