import gql from 'graphql-tag';

export type CreateUnitMutationResponse = {
  createUnit: string;
};

export const createUnit = gql`
  mutation CreateUnit($propertyId: UUID!, $unit: UnitInput!) {
    createUnit(propertyId: $propertyId, unit: $unit)
  }
`;
