import gql from 'graphql-tag';

export type UpdateUnitMutationResponse = {
  updateUnit: string;
};

export const updateUnit = gql`
  mutation UpdateUnit($propertyId: UUID!, $unitId: UUID!, $unit: UnitInput!) {
    updateUnit(propertyId: $propertyId, unitId: $unitId, unit: $unit)
  }
`;
