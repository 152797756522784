import gql from 'graphql-tag';

export default gql`
  query getUserIntegration($channelIntegrationId: UUID!) {
    userIntegration(channelIntegrationId: $channelIntegrationId) {
      _id
      userId
      userKey
      key
      channelIntegrationId
    }
  }
`;
