import i18n from 'localization';

export const errorCodeTypes = {
  floorNameAlreadyExistsError: {
    code: 'FL01',
    name: 'FloorNameAlreadyExistsError',
    message: i18n.t('shared.floors.errors.nameExists'),
  },
  floorNameMinimumCharacterError: {
    code: 'FL02',
    name: 'FloorNameMinimumCharacterError',
    message: i18n.t('shared.floors.errors.nameMinimumCharacter'),
  },
  floorNotFoundError: {
    code: 'FL03',
    name: 'FloorNotFoundError',
    message: i18n.t('shared.floors.errors.notFound'),
  },
  floorUnitsAssociationExistsError: {
    code: 'FL04',
    name: 'FloorUnitsAssociationExistsError',
    message: i18n.t('shared.floors.errors.floorUnitsAssociationExists'),
  },
};
