import { DateTime } from 'luxon';

/**
 * Removes the time values from date and essentially sets it to midnight
 * @param date
 * @param timeZone
 */
export function removeTime(date: Date, timeZone?: string) {
  let dateTime = DateTime.fromJSDate(date);
  if (timeZone) {
    dateTime = dateTime.setZone(timeZone);
  }
  dateTime = dateTime.startOf('day');

  return dateTime.toJSDate();
}
