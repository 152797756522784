import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  mutation updateMeterReadingValueMutation(
    $updateMeterReadingValue: UpdateMeterReadingValueInput
    $channelId: String!
  ) {
    updateMeterReadingValue(
      meterReadingValue: $updateMeterReadingValue
      channelId: $channelId
    ) {
      id
    }
  }
`);
