import { Workflow, WorkflowTypeEnum } from 'lane-shared/types/workflow';
import { constructWorkflow } from 'lane-shared/helpers/workflows/constructWorkflow';
import {
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
} from 'lane-shared/types/Workflows';
import {
  EVENT_SERVICE_REQUEST_ASSIGNED,
  EVENT_SERVICE_REQUEST_CREATED,
  EVENT_SERVICE_REQUEST_STATUS_UPDATED,
  EVENT_TASK_CREATED,
  EVENT_TASK_ASSIGNED,
  EVENT_TASK_STATUS_UPDATED,
  EVENT_TASK_NON_STATUS_UPDATED,
  EVENT_TASK_UNASSIGNED,
  EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
  EVENT_SERVICE_REQUEST_UNASSIGNED,
} from 'lane-shared/helpers/constants/events';

export function constructDefaultWorkflowsForServiceRequestNotifications({
  channelId,
  userId,
}: {
  channelId: string;
  userId: string;
}) {
  const workflows: Workflow[] = [];

  // Service Request Created
  // Email the work order admin when a service request is created
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderAdmin,
      event: EVENT_SERVICE_REQUEST_CREATED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );
  // Email the work order assignment team when a service request is created and auto-assigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_SERVICE_REQUEST_CREATED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );
  // Email the end user when a service request is created
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderCreator,
      event: EVENT_SERVICE_REQUEST_CREATED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );

  // Service Request Assigned
  // Email the work order team when a service request is assigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_SERVICE_REQUEST_ASSIGNED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );

  // Service Request Status Updated
  // Email the work order team when a service request status is updated
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_SERVICE_REQUEST_STATUS_UPDATED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );
  // Email the end user when a service request status is updated
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderCreator,
      event: EVENT_SERVICE_REQUEST_STATUS_UPDATED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );
  // Service Request Non-Status Updated
  // Email the work order team when a service request is updated (non-status change)
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );
  // Email the end user when a service request is updated (non-status change)
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderCreator,
      event: EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );

  // Service Request Unassigned
  // Email the work order admin when a service request is unassigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderAdmin,
      event: EVENT_SERVICE_REQUEST_UNASSIGNED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );
  // Email the work order team when a service request is unassigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.ServiceRequest,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_SERVICE_REQUEST_UNASSIGNED,
      type: WorkflowTypeEnum.ServiceRequest,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.ServiceRequest,
        value: 'events',
      },
    })
  );
  return workflows;
}

export function constructDefaultWorkflowsForTaskNotifications({
  channelId,
  userId,
}: {
  channelId: string;
  userId: string;
}) {
  const workflows: Workflow[] = [];

  // Task Created
  // Email the work order admin when a task is created
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderAdmin,
      event: EVENT_TASK_CREATED,
      type: WorkflowTypeEnum.Task,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    })
  );
  // Email the work order assignment team when a task is created and auto-assigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_TASK_CREATED,
      type: WorkflowTypeEnum.Task,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    })
  );

  // Task Assigned
  // Email the work order team when a task is assigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_TASK_ASSIGNED,
      type: WorkflowTypeEnum.Task,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    })
  );

  // Task Status Updated
  // Email the work order team when a task status is updated
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_TASK_STATUS_UPDATED,
      type: WorkflowTypeEnum.Task,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    })
  );

  // Task Non-Status Updated
  // Email the work order team when a task is updated (non-status change)
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_TASK_NON_STATUS_UPDATED,
      type: WorkflowTypeEnum.Task,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    })
  );

  // Task Unassigned
  // Email the work order admin when a task is unassigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderAdmin,
      event: EVENT_TASK_UNASSIGNED,
      type: WorkflowTypeEnum.Task,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    })
  );
  // Email the work order team when a task is unassigned
  workflows.push(
    constructWorkflow({
      whenContext: WorkflowWhenContextEnum.Task,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.WorkOrderTeam,
      event: EVENT_TASK_UNASSIGNED,
      type: WorkflowTypeEnum.Task,
      channelId,
      order: workflows.length,
      userId,
      workflow: {
        on: WorkflowTypeEnum.Task,
        value: 'events',
      },
    })
  );
  return workflows;
}
