import {
  ANALYTIC_MOBILE_ACCESS_VISIT,
  ANALYTIC_MOBILE_ACCESS_EDIT_INITIATED,
  ANALYTIC_MOBILE_ACCESS_EDIT_COMPLETED,
  ANALYTIC_MOBILE_ACCESS_EDIT_FAILED,
} from 'lane-shared/helpers/constants/analytics';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { useCallback } from 'react';

export const enum MobileAccessAction {
  ENABLE = 'Enable',
  DISABLE = 'Disable',
}

type MobileAccessEdit = {
  action: MobileAccessAction;
  syncStatus?: string;
  accessRole?: string;
  attemptedChannelId?: string;
};

export function useTrackMobileAccessEvents() {
  const simpleTrack = useSimpleTrack();

  const trackMobileAccessVisit = useCallback(
    (data: Record<string, any> = {}) => {
      simpleTrack(ANALYTIC_MOBILE_ACCESS_VISIT, data);
    },
    [simpleTrack]
  );

  const trackMobileAccessEditInitiated = useCallback(
    (data: MobileAccessEdit) => {
      simpleTrack(ANALYTIC_MOBILE_ACCESS_EDIT_INITIATED, data);
    },
    [simpleTrack]
  );

  const trackMobileAccessEditCompleted = useCallback(
    (data: MobileAccessEdit) => {
      simpleTrack(ANALYTIC_MOBILE_ACCESS_EDIT_COMPLETED, data);
    },
    [simpleTrack]
  );

  const trackMobileAccessEditFailed = useCallback(
    (data: MobileAccessEdit) => {
      simpleTrack(ANALYTIC_MOBILE_ACCESS_EDIT_FAILED, data);
    },
    [simpleTrack]
  );

  return {
    trackMobileAccessVisit,
    trackMobileAccessEditInitiated,
    trackMobileAccessEditCompleted,
    trackMobileAccessEditFailed,
  };
}
