import gql from 'graphql-tag';

export const getUserSettings = gql`
  query GetUserSettings {
    me {
      user {
        _id
        settings
      }
    }
  }
`;
