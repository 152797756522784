import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query searchMeterReadingValue(
    $meterReadingId: UUID!
    $search: MeterReadingValueSearch
    $pagination: PaginationInput!
    $channelId: String
    $includeArchived: Boolean
  ) {
    searchMeterReadingValue(
      meterReadingId: $meterReadingId
      search: $search
      pagination: $pagination
      channelId: $channelId
      includeArchived: $includeArchived
    ) {
      meterReadingValues {
        id
        meterReadingId
        createdBy{
          name
          id
        }
        createdAt
        sourceType
        value
        isArchived
        stepExecutionId
        notes
        taskId
        taskUserFriendlyId
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`);
