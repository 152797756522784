import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { getTextColor } from 'lane-shared/helpers';
import { capitalize } from 'lane-shared/helpers/formatters';

import ColorPicker from 'components/form/ColorPicker';
import { S, Text } from 'components/typography';

import styles from './ThemePaletteColorEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  colorName: string;
  colorDescription?: string;
  color: string;
  onColorUpdated: (color: string | undefined) => void;
  onColorDeleted?: () => void;
};

export default function ThemePaletteColorEdit({
  className,
  style,
  colorName,
  colorDescription,
  color,
  onColorUpdated,
  onColorDeleted,
}: Props) {
  return (
    <div className={cx(styles.ThemePaletteColorEdit, className)} style={style}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(color: string | undefined) => void' is not ... Remove this comment to see the full error message */}
      <ColorPicker value={color} onChange={onColorUpdated}>
        <button
          className={styles.themeColorSwatch}
          style={{ backgroundColor: color }}
          data-test="colorSwatch"
        >
          <Icon name="edit" style={{ color: getTextColor(color) }} />
        </button>
      </ColorPicker>
      {Boolean(colorName) && <Text>{capitalize(colorName)}</Text>}
      {colorDescription && (
        <S variant="secondary" className={styles.subtitle}>
          {colorDescription}
        </S>
      )}
      {onColorDeleted && (
        <Icon
          className={styles.removeButton}
          testId="removeColor"
          name="times"
          style={{ color: getTextColor(color) }}
          onClick={onColorDeleted}
        />
      )}
    </div>
  );
}
