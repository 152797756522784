import React, { useState, useEffect } from 'react';

import getBlockKey from 'lane-shared/renderers/v5/getBlockKey';
import * as Primitives from 'lane-shared/renderers/v5/primitives';

import { getScrollX, getScrollY } from '../../../helpers';
import BlockEditMenu from './BlockEditMenu';
import BlockLinkEdit from './BlockLinkEdit';
import BlockPropertiesWindow from './BlockPropertiesWindow';

import styles from './BlockEditFrame.scss';

const DEFAULT_POSITION = {
  x: 0,
  y: 0,
  width: 200,
  height: 200,
};

function BlockEditFrame({
  user,
  channel,
  library,
  content,
  theme,
  blocks,
  block,
  isActive,
  forTemplate,
  position = DEFAULT_POSITION,
  onDeleteBlock,
  onBlockClone,
  onBlockUpdated,
  onBlockMoveUp,
  onBlockMoveDown,
  onContentUpdated,
  onClose,
}: any) {
  const [isLinkOpen, setIsLinkOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isPropertiesOpen, setIsPropertiesOpen] = useState(true);

  useEffect(() => {
    setIsEditing(false);
    setIsMoving(false);
    setIsPropertiesOpen(true);
  }, [block]);

  if (!block) {
    return null;
  }

  let showProperties = false;

  // get the block definition. its either a primitive or a Block
  const Block = block.primitive
    ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      Primitives[block.primitive]
    : blocks[getBlockKey(block)];

  const properties = block.primitive ? Block.properties : Block.def.properties;
  const name = isEditing
    ? block.name || ''
    : block.name || Block.blockName || Block.name;

  const _position = position || DEFAULT_POSITION;
  const top = getScrollY() + _position.y;
  const left = getScrollX() + _position.x;
  const { width } = _position;
  const { height } = _position;
  const isTop = content.block._id === block._id;

  function onRemoveLink() {
    delete block.link;
    onBlockUpdated();
    closeLinkWindow();
  }

  function closeLinkWindow() {
    setIsLinkOpen(false);
    setIsPropertiesOpen(true);
  }

  if (
    !block.for &&
    properties &&
    Object.keys(properties).filter(key => key !== 'children').length > 0
  ) {
    showProperties = true;
  }

  return (
    <div
      className={styles.BlockEditFrame}
      style={{
        top,
        left,
        width,
        height,
      }}
      data-test="blockHover"
      data-is-visible={!!block}
      data-is-active={isActive}
    >
      <div
        className={styles.frame}
        style={{
          width,
          height,
        }}
      >
        <label>{name}</label>
      </div>

      {isActive && !isMoving && showProperties && isPropertiesOpen && (
        <BlockPropertiesWindow
          user={user}
          channel={channel}
          library={library}
          theme={theme}
          blocks={blocks}
          block={block}
          onContentUpdated={onContentUpdated}
          onClose={onClose}
        />
      )}

      {isActive && !isMoving && isLinkOpen && (
        <BlockLinkEdit
          block={block}
          channelId={channel?._id}
          channelSlug={channel?.slug}
          onBlockUpdated={onBlockUpdated}
          forTemplate={forTemplate}
          onRemoveLink={onRemoveLink}
          onClose={closeLinkWindow}
        />
      )}

      {!isTop && isActive && (
        <BlockEditMenu
          block={block}
          forTemplate={forTemplate}
          onDeleteBlock={onDeleteBlock}
          onBlockClone={onBlockClone}
          onBlockUpdated={onBlockUpdated}
          onBlockMoveUp={onBlockMoveUp}
          onBlockMoveDown={onBlockMoveDown}
          setIsMoving={setIsMoving}
          setIsPropertiesOpen={setIsPropertiesOpen}
          setIsLinkOpen={setIsLinkOpen}
          isLinkOpen={isLinkOpen}
        />
      )}
    </div>
  );
}

export default BlockEditFrame;
