import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class EssensysBookingConflictError extends CodedError {
  static get errorName() {
    return errorCodes.essensysBookingConflictError.code;
  }

  constructor(message = errorCodes.essensysBookingConflictError.message) {
    super(message);
    this.code = errorCodes.essensysBookingConflictError.code;
    this.message = `${message} ${errorCodes.essensysBookingConflictError.code}`;
    this.name = errorCodes.essensysBookingConflictError.name;
  }
}
