import { PermissionGroupMembers } from './PermissionGroupMembers';
import styles from './styles.scss';
import { Button, Loading } from 'design-system-web';
import {
  ModalBackground,
  ResizableWindow,
  TabStrip,
  UserSearchOnChannel,
  ChannelSelectorButton,
} from 'components';
import { ChannelSelectorModesEnum } from 'components/lane/ChannelSelector';
import AdminPage from 'components/layout/AdminPage';
import { BreadCrumbs } from 'components/lds';
import { useChannelForAdminQuery, useQueryString } from 'hooks';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';

import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import { addUserGroupRole } from 'lane-shared/graphql/mutation';
import { hasPermission, pause } from 'lane-shared/helpers';
import { isChannelForCRE } from 'lane-shared/helpers/channel';
import { emitMemberAddedToGroupRole } from 'lane-shared/helpers/analytics/emitGroupRole';
import {
  PERMISSION_ADMIN,
  PERMISSION_USERS_INVITE,
} from 'lane-shared/helpers/constants/permissions';
import { useFlag, useGroupRoleQuery } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';
import { H3 } from 'lane-web/src/components/typography';
import { usePermissionGroupMembers } from 'lane-web/src/domains/userManagement/hooks';
import { useNewPendingInvites } from 'lane-web/src/pages/portal/admin/channel/users/hooks/usePendingInvites';
import { PendingInvitesView } from 'lane-web/src/pages/portal/admin/channel/users/view/index';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { INVITE_STATUS } from 'lane-shared/helpers/constants/invites';
import { ReformedChannelInvites } from 'lane-web/src/pages/portal/admin/channel/users/view/PendingInvites';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getUsersByChannelId } from 'lane-shared/graphql/query';

type Props = {
  channel: ReturnType<typeof useChannelForAdminQuery>['channel'];
};

export const PermissionGroupView = ({ channel }: Props) => {
  const { t } = useTranslation();
  const { groupRoleId } = useParams<{ groupRoleId: string }>();
  const { user } = useContext(UserDataContext);
  const analytics = useContext(AnalyticsContext);
  const [_loading, setLoading] = useState(false);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [selectedChannelId, setSelectedChannel] = useState(channel?._id);
  const [selectedUser, setSelectedUser] = useState<UserType>();
  const { groupRole } = useGroupRoleQuery({ groupRoleId });
  const [query] = useQueryString();

  const {
    channelInvites,
    loading: invitesLoading,
    refetch: pendingInvitesRefetch,
  } = useNewPendingInvites(channel?._id || '', groupRoleId);

  const TAB_USERS = 'users';
  const TAB_PENDING_INVITES = 'pending-invites';

  const { members, membersLoading, refetch } = usePermissionGroupMembers(
    channel?._id,
    groupRoleId,
    {
      ...query,
    }
  );

  const pendingInvitesCount = channelInvites.filter((i: { status: string }) =>
    [INVITE_STATUS.CREATED, INVITE_STATUS.SENT, INVITE_STATUS.RESENT].includes(
      i.status
    )
  ).length;

  const tabs = [
    {
      label: t(
        'web.pages.portal.admin.channel.permissionGroups.detail.tabHeader.users.active'
      ),
      value: TAB_USERS,
    },
    {
      label: t(
        'web.pages.portal.admin.channel.permissionGroups.detail.tabHeader.users.pending',
        { pendingInvitesCount }
      ),
      value: TAB_PENDING_INVITES,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const inviteAddUserSeparationFlag = useFlag(
    FeatureFlag.InviteAddUserSeparation,
    false
  );
  const canAddUserPermissions = inviteAddUserSeparationFlag
    ? [PERMISSION_ADMIN]
    : [PERMISSION_ADMIN, PERMISSION_USERS_INVITE];

  const canAddUser =
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    user.isSuperUser ||
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    hasPermission(user.roles, canAddUserPermissions, channel?._id);

  const channelIsOfficeExperience = isChannelForCRE(channel?.experienceType);

  async function onAddUser(user: any) {
    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        refetchQueries: [getUsersByChannelId],
        mutation: addUserGroupRole,
        variables: {
          userGroupRole: {
            user: {
              _id: user?._id,
            },
            groupRole: {
              _id: groupRole?._id,
            },
          },
        },
      });

      window.Toast.show(
        <p>
          {t('web.pages.portal.admin.channel.team.toastSuccess', {
            userProfileName: user.profile.name,
          })}
        </p>
      );

      if (selectedTab.value !== TAB_USERS) setSelectedTab(tabs[0]);

      emitMemberAddedToGroupRole({
        userId: user._id,
        groupRoleId: groupRole?._id,
        groupRoleName: groupRole?.name,
        analytics,
      });
    } catch (err) {
      window.Alert.alert({
        title: t('web.pages.portal.admin.channel.team.toastErrorTitle', {
          userProfileName: user.profile.name,
        }),
        message: t('web.pages.portal.admin.channel.team.toastErrorMessage', {
          userProfileName: user.profile.name,
          channelName: channel?.name,
        }),
        error: err,
      });
    }

    setIsAddUserOpen(false);
    setLoading(false);
  }

  function onCloseAddUser() {
    setIsAddUserOpen(false);
    setSelectedUser(undefined);
    setSelectedChannel(undefined);
  }

  function onAddUserClick(channelId: string | undefined) {
    setIsAddUserOpen(true);
    setSelectedChannel(channelId);
  }

  const tables =
    selectedTab.value === TAB_USERS
      ? !membersLoading && (
          <PermissionGroupMembers
            groupRole={groupRole}
            members={members}
            refetch={refetch}
            onAddUser={
              canAddUser
                ? () => {
                    onAddUserClick(channel?._id);
                  }
                : undefined
            }
            channel={channel}
          />
        )
      : !invitesLoading && (
          <PendingInvitesView
            invites={(channelInvites as unknown) as ReformedChannelInvites[]}
            refetch={pendingInvitesRefetch}
            inDetailsPage
            channelSlug={channel?.slug || ''}
            onAddUser={
              canAddUser
                ? () => {
                    onAddUserClick(channel?._id);
                  }
                : undefined
            }
          />
        );

  const renderTables = () => {
    if (membersLoading || invitesLoading) {
      return <Loading />;
    }

    return tables;
  };

  return (
    <AdminPage className={styles.PermissionGroupView}>
      <BreadCrumbs
        links={[
          {
            label: t(
              channelIsOfficeExperience
                ? 'web.pages.portal.admin.channel.teams.breadCrumbs.back'
                : 'web.pages.portal.admin.channel.permissionGroups.breadCrumbs.back'
            ),
            url: (channelIsOfficeExperience
              ? routes.channelAdminTeamsList
              : routes.channelAdminPermissionGroupsList
            ).replace(':id', channel?.slug || ''),
          },
          {
            label: t(
              channelIsOfficeExperience
                ? 'web.pages.portal.admin.channel.teams.breadCrumbs.currentPage'
                : 'web.pages.portal.admin.channel.permissionGroups.breadCrumbs.currentPage'
            ),
          },
        ]}
      />
      <div className={styles.header}>
        <H3>{groupRole?.name}</H3>
        <div className={styles.buttons}>
          {canAddUser && (
            <Link
              to={(channelIsOfficeExperience
                ? routes.channelAdminTeamEdit
                : routes.channelAdminPermissionGroupEdit
              )
                .replace(':id', channel?.slug || '')
                .replace(':groupRoleId', groupRole?._id || '')}
            >
              <Button testId="permissionsSettings" variant="secondary">
                {t(
                  'web.pages.portal.admin.channel.permissionGroups.editPermissions'
                )}
              </Button>
            </Link>
          )}
        </div>
      </div>
      <TabStrip
        tabs={tabs}
        selected={selectedTab}
        onSelectTab={tab => setSelectedTab(tab)}
        className={styles.tabStrip}
        skipLabelTranslation
        fullWidth
      />

      {renderTables()}

      <ModalBackground
        className={styles.modal}
        onClose={onCloseAddUser}
        isOpen={isAddUserOpen}
        hasOpaqueBackground
      >
        <ResizableWindow
          name="teamMembersAdd"
          showHeader
          className={styles.window}
          onClose={onCloseAddUser}
          defaultPosition={ResizableWindow.centerPosition()}
        >
          <div className={styles.wrapper}>
            <ChannelSelectorButton
              channelId={selectedChannelId}
              modes={[
                ChannelSelectorModesEnum.Geo,
                ChannelSelectorModesEnum.Subscriptions,
              ]}
              className={styles.channel}
              onChannelSelected={(channel: any) =>
                setSelectedChannel(channel?._id)
              }
            />

            {selectedChannelId && (
              <UserSearchOnChannel
                className={styles.userSearch}
                selectedUserId={selectedUser?._id}
                channelId={selectedChannelId}
                onUserSelected={(user: any) =>
                  setSelectedUser(user ?? undefined)
                }
                includeWorkplaceMember
              />
            )}

            <menu>
              <Button
                disabled={!selectedUser}
                onClick={() => onAddUser(selectedUser)}
                testId="windowAddUser"
              >
                {t('web.pages.portal.admin.channel.team.addUserButton')}
              </Button>
            </menu>
          </div>
        </ResizableWindow>
      </ModalBackground>
    </AdminPage>
  );
};
