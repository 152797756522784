import gql from 'graphql-tag';

import { PublicUserFragment, ContentFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ContentFragment}

  query contentsOnChannels($channelIds: [UUID]!) {
    me {
      contentsOnChannels(channelIds: $channelIds) {
        ...ContentFragment
      }
    }
  }
`;
