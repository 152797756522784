import React from 'react';

import { Key } from 'ts-key-enum';

import { SortIcon } from './SortIcon';

import styles from './TableHeader.scss';

import { getCommonPinningStyles } from './getCommonPinningStyles';
import { Header } from '@tanstack/react-table';

type onSortChange = (
  e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent
) => void;

type TableHeaderProps = {
  colSpan: number;
  isPlaceholder: boolean;
  canSort: boolean;
  onSortChange?: onSortChange;
  children: React.ReactNode;
  isSortedDirection: 'asc' | 'desc' | false;
  header: Header<any, any>;
};

export const TableHeader = ({
  colSpan,
  isPlaceholder,
  canSort,
  onSortChange,
  children,
  isSortedDirection,
  header,
}: TableHeaderProps) => {
  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (onSortChange && e.key === Key.Enter) {
      onSortChange(e);
    }
  };

  if (isPlaceholder) {
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    return <th colSpan={colSpan} className={styles.TableHeader} />;
  }

  return (
    <th
      colSpan={colSpan}
      className={styles.TableHeader}
      data-sortable={canSort}
      style={{ ...getCommonPinningStyles(header.column, true) }}
    >
      {canSort ? (
        <div
          role="button"
          tabIndex={0}
          className={styles.SortableTableHeaderContent}
          onClick={onSortChange}
          onKeyPress={onKeyPress}
        >
          {children}
          <SortIcon direction={isSortedDirection || undefined} />
        </div>
      ) : (
        <div className={styles.TableHeaderContent}>{children}</div>
      )}
    </th>
  );
};
