import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export default class ChannelModuleTypeError extends CodedError {
  static get errorName() {
    return errorCodes.channelModuleTypeError.code;
  }

  constructor(message = errorCodes.channelModuleTypeError.message) {
    super(message);
    this.code = errorCodes.channelModuleTypeError.code;
    this.message = `${message} ${errorCodes.channelModuleTypeError.code}`;
    this.name = errorCodes.channelModuleTypeError.name;
    this.status = errorCodes.channelModuleTypeError.status;
  }
}
