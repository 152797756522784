import gql from 'graphql-tag';

export default gql`
  query pagesOnChannels($channelIds: [UUID]!) {
    me {
      pagesOnChannels(channelIds: $channelIds) {
        channelId
        channelPages {
          _id
          _created
          _updated
          _order
          name
          slug
          icon
          iconSet
          iconWeight
          label
          label_l10n
          content {
            _id
            _created
            _updated
            integration {
              _id
              integration {
                _id
                name
              }
            }
          }
        }
      }
    }
  }
`;
