import React from 'react';

import { CURRENCY_CONVERSION } from '../../helpers/constants';
import {
  getMenuFeatureItemConfiguredPrice,
  getMenuFeatureSelectedOptions,
} from '../../helpers/features';
import i18nStub from '../../helpers/i18nStub';
import { MjmlColumn, MjmlSection } from '../MjmlComponentsV2';
import EmailText from './EmailText';

export default function EmailMenuOrderCartItem({
  orderItem,
  item,
  currencyFormat,
  i18n = i18nStub,
}: any) {
  const optionLists = getMenuFeatureSelectedOptions({ item, orderItem });

  if (!item) {
    return null;
  }

  return (
    <MjmlSection paddingTop={0} paddingBottom={0} textAlign="right">
      <MjmlColumn>
        <EmailText>
          <h4>
            {orderItem.quantity}x {item.name}
          </h4>
          <ul>
            {optionLists.map(optionList =>
              optionList.options.map(option => (
                <li key={option._id}>
                  {option.name}{' '}
                  {option.price
                    ? currencyFormat(option.price / CURRENCY_CONVERSION)
                    : ''}
                </li>
              ))
            )}
          </ul>
          {orderItem.notes ? (
            <p>
              {i18n.t('Notes')}: {orderItem.notes}
            </p>
          ) : null}
        </EmailText>
      </MjmlColumn>
      <MjmlColumn>
        <EmailText align="right">
          <h4>
            {currencyFormat(
              getMenuFeatureItemConfiguredPrice(item, orderItem) /
                CURRENCY_CONVERSION
            )}
          </h4>
        </EmailText>
      </MjmlColumn>
    </MjmlSection>
  );
}
