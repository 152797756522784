import gql from 'graphql-tag';

import {
  FullContentTemplateFragment,
  ThemeFragment,
  PublicUserFragment,
} from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullContentTemplateFragment}

  mutation UpdateTemplate($template: ContentTemplateInput!) {
    updateContentTemplate(template: $template) {
      ...FullContentTemplateFragment
    }
  }
`;
