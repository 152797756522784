import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { CardElement, injectStripe } from 'react-stripe-elements';

import Button from 'components/general/Button';
import StripeLegal from 'components/payments/StripeLegal';

import styles from './PaymentFeatureStripeAddCard.scss';

// NOTE: Based on https://github.com/stripe/react-stripe-elements/issues/138#issuecomment-353158966
// to provide autofocus within `ref` of CardElement

export default function PaymentFeatureStripeAddCard() {
  return (
    <div className={cx(styles.stripeAddCard)}>
      <div className={cx(styles.stripeContainer)}>
        <CardElement
          style={{ base: { fontSize: '16px' } }}
          ref={(instance: any) => {
            if (instance?._element) {
              instance._element.on('ready', () => {
                instance._element.focus();
              });
            }
          }}
        />
      </div>
      <StripeLegal className={styles.stripeLegal} />
    </div>
  );
}

type ActionProps = {
  onDone: () => void;
  onAddCard: (token: { id: string }) => Promise<void>;
  working: boolean;
};

export function PaymentFeatureStripeAddCardActions({
  onDone,
  onAddCard,
  working,
}: ActionProps) {
  const { t } = useTranslation();

  async function handleAdd(stripe: any) {
    try {
      const { token, error } = await stripe.createToken();

      if (token) {
        await onAddCard(token);
        onDone();
      }

      if (error) {
        window.Alert.alert({
          title: error.message,
        });
      }
    } catch (err) {
      window.Alert.alert({
        title: t('An error occurred!'),
        error: err,
      });
    }
  }

  const ActionButtons = injectStripe(({ stripe }: any) => {
    return (
      <div className={cx(styles.stripeAddCardActions)}>
        <Button
          variant="outlined"
          onClick={onDone}
          disabled={working}
          testId="cancelButton"
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleAdd(stripe);
          }}
          disabled={!stripe || working}
          testId="addCardButton"
        >
          {t('Add card')}
        </Button>
      </div>
    );
  });

  return <ActionButtons />;
}
