import React from 'react';

import cx from 'classnames';

import convertMjmlStyleProps from './convertMjmlStyleProps';

import styles from './styles.scss';

export default function MjmlImage({
  className,
  style,
  src,
  ...otherProps
}: any) {
  return (
    <img
      alt={src}
      className={cx(styles.MjmlImage, className)}
      style={{ ...style, ...convertMjmlStyleProps(otherProps) }}
      src={src}
      data-test="qrCode"
    />
  );
}
