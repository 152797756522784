import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query getChannelIntegration($id: UUID!) {
    channelIntegration(_id: $id) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      integration {
        _id
        name
        type
        properties
        platforms
      }
      settings
      deletedAt
    }
  }
`;
