import React, { useEffect } from 'react';

import { Popup } from '../PopupMenu/Popup';
import { Flex } from '../Layout/Flex';
import { Input } from '../Input/Input';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { parseDateTime } from 'lane-shared/helpers/dates';
import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';
import { TimeUnitEnum } from 'lane-shared/types/TimeUnitEnum';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { Calendar } from './components/Calendar';
import { MonthCalendar } from './components/MonthCalendar';
import { YearCalendar } from './components/YearCalendar';
import { getFormatDate, renderCalendar } from './helpers';
import useDateRangePicker from './hooks/useDateRangePicker';

import styles from './DatePickers.scss';

type DateRangePickerButtonProps = {
  /** function to be called when user sets new date */
  onChange?: (dateRange: DateRangeType) => void;
  /** start Date as an JS object */
  startDate?: Date;
  /** end date as an JS object */
  endDate?: Date;
  /** the minimum date allowed to be selected */
  minDate?: Date;
  /** the maximum date allowed to be selected */
  maxDate?: Date;
  /** an array of date ranges that are not available */
  unavailableDateRanges?: DateRangeType[];
  /** include a time picker? or just for date range */
  includeTime?: boolean;
  timeUnit?: TimeUnitEnum;
  timeZone?: string;
  /** the minimum range size in days, i.e. you must select 2 days */
  minRangeSize?: number;
  /** the maximum range size in days, i.e. you can only select 7 days */
  maxRangeSize?: number;
  className?: string;
  style?: React.CSSProperties;
  weekdayOnly?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  fixedLabel?: boolean;
  longFormat?: boolean;
  disabledWeekDays?: number[];
  startDateLabel?: string;
  endDateLabel?: string;
};

export const DateRangePickerButton = ({
  className,
  style,
  startDate,
  endDate,
  onChange = () => null,
  timeUnit = TimeUnitEnum.Day,
  timeZone,
  minDate,
  maxDate,
  unavailableDateRanges = [],
  minRangeSize,
  maxRangeSize,
  weekdayOnly = false,
  disabled = false,
  hideLabel,
  fixedLabel,
  longFormat = false,
  disabledWeekDays = [],
  startDateLabel,
  endDateLabel,
}: DateRangePickerButtonProps) => {
  const { t } = useTranslation();

  const [internalRange, setDate, resetRange] = useDateRangePicker({
    startDate,
    endDate,
    minRangeSize,
    maxRangeSize,
    timeZone,
    timeUnit,
  });

  // reset internal range when outside values are cleared
  useEffect(() => {
    if (!startDate && !endDate) {
      resetRange();
    }
  }, [startDate, endDate]);

  // Declaring display values, depending on props and internal values
  // Prefer internal range over props
  const _startDate = internalRange.startDate || startDate;
  const _endDate = internalRange.endDate || endDate;
  function onDateClicked(date: any) {
    setDate(date);
  }

  const sharedProps = {
    className: styles.calendar,
    onChange: onDateClicked,
    onSubmit,
    minDate,
    maxDate,
    startDate: internalRange.startDate,
    endDate: internalRange.endDate,
    unavailableDateRanges,
    minRangeSize,
    maxRangeSize,
    weekdayOnly,
    disabled,
    timeZone,
    disabledWeekDays,
  };

  function renderYearCalendarComponent() {
    return <YearCalendar {...sharedProps} />;
  }
  function renderMonthCalendarComponent() {
    return <MonthCalendar {...sharedProps} />;
  }
  function renderCalendarComponent() {
    return <Calendar {...sharedProps} dateRangePicker />;
  }

  function onSubmit() {
    if (!internalRange.endDate) {
      const endOfAStartDate = parseDateTime(internalRange.startDate)
        ?.endOf('day')
        .toJSDate();
      onChange({
        startDate: parseDateTime(internalRange.startDate)?.toJSDate(),
        endDate: endOfAStartDate,
      });
    } else {
      onChange({
        startDate: parseDateTime(internalRange.startDate)?.toJSDate(),
        endDate: parseDateTime(internalRange.endDate)?.toJSDate(),
      });
    }
  }

  const buttonStartDate = dateFormatter(
    _startDate,
    getFormatDate({ timeUnit, isLongFormat: longFormat }),
    timeZone
  );
  const buttonEndDate = dateFormatter(
    _endDate,
    getFormatDate({ timeUnit, isLongFormat: longFormat }),
    timeZone
  );

  return (
    <span className={cx(styles.DatePickerButtons, className)} style={style}>
      <Flex gap={2}>
        <Popup
          zIndex={6}
          onClose={onSubmit}
          trigger={
            <button className={cx(styles.button, styles.buttonLongFormat)}>
              <Input
                className={styles.input}
                icon="calendar"
                iconRight
                value={buttonStartDate}
                placeholder="DD/MM/YYYY"
                onChange={() => null}
                ariaLabel={startDateLabel || t('Start date')}
                label={hideLabel ? '' : startDateLabel || t('Start date')}
                boldBorder
                showClear={false}
                fixedLabel={fixedLabel}
              />
            </button>
          }
        >
          <div>
            {renderCalendar(
              timeUnit,
              renderYearCalendarComponent,
              renderMonthCalendarComponent,
              renderCalendarComponent
            )}
          </div>
        </Popup>
        <Popup
          zIndex={6}
          onClose={onSubmit}
          trigger={
            <button className={cx(styles.button, styles.buttonLongFormat)}>
              <Input
                className={styles.input}
                icon="calendar"
                iconRight
                value={buttonEndDate}
                placeholder="DD/MM/YYYY"
                onChange={() => null}
                ariaLabel={endDateLabel || t('End date')}
                label={hideLabel ? '' : endDateLabel || t('End date')}
                boldBorder
                showClear={false}
                fixedLabel={fixedLabel}
              />
            </button>
          }
        >
          <div>
            {renderCalendar(
              timeUnit,
              renderYearCalendarComponent,
              renderMonthCalendarComponent,
              renderCalendarComponent
            )}
          </div>
        </Popup>
      </Flex>
    </span>
  );
};
