import gql from 'graphql-tag';

import { ProfileFragment } from '../fragments';

export default gql`
  ${ProfileFragment}

  query publicUserForMemberInfo($id: UUID!) {
    user(_id: $id) {
      _id
      _created
      name
      online
      lastSeen
      primaryLocation {
        _id
        name
      }

      profile {
        _id
        name
        phone
        email
        logo
        image
      }
    }

    person: personForUser(userId: $id) {
      id
    }

    memberGroupRoles(_id: $id) {
      _id
      _created
      name
      description
      isPublic
      expires
      groupRole {
        _id
        name
        isPublic
        channel {
          _id
          name
          slug
          type
          isPublic
          isSub
          parent {
            _id
          }
          profile {
            ...ProfileFragment
          }
        }
      }
    }
  }
`;
