import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class NotificationValidationError extends CodedError {
  static get errorName() {
    return errorCodes.notificationValidationError.code;
  }

  constructor(message = errorCodes.notificationValidationError.message) {
    super(message);
    this.code = errorCodes.notificationValidationError.code;
    this.message = `${message} ${errorCodes.notificationValidationError.code}`;
    this.name = errorCodes.notificationValidationError.name;
  }
}
