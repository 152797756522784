import gql from 'graphql-tag';

export default gql`
  mutation resumeCredentialGroup(
    $channelId: UUID!
    $credentialGroupId: String!
    $provider: String!
  ) {
    resumeCredentialGroup(
      channelId: $channelId
      credentialGroupId: $credentialGroupId
      provider: $provider
    )
  }
`;
