import gql from 'graphql-tag';

export default gql`
  query getSchedulesForExport($refId: String!) {
    pmSchedulesForExport(refId: $refId) {
      schedules {
        scheduleId
        extRefId {
          id
          name
        }
        title
        location
        nextDueDate
        untilDate
        repeats
        daysAhead
        weekday
        weekNo
        monthNo
        interval
        assignee {
          id
          name
        }
        equipmentIds
        notes
        timeToComplete
        completeWithin
        steps
        meterReading
        createdBy
        updatedBy
        createdAt
        updatedAt
        isArchived
        attachments
        assigneeGroups
      }
    }
  }
`;
