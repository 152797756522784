import {
  MarkupAmountType,
  TaxAmountType,
  PriceInput,
} from 'lane-shared/domains/billingPayments/types';
import { PriceComponents } from 'lane-shared/domains/productCatalog/types';

export function priceInputToComponents(
  priceInput: PriceInput
): PriceComponents {
  const { rate, markup, tax, netPrice } = priceInput;

  return {
    amount: Number(rate),
    markup: {
      value: Number(markup?.value || 0),
      markupAmountType:
        markup?.type || MarkupAmountType.MarkupAmountTypePercentage,
    },
    tax: {
      value: Number(tax?.value || 0),
      taxAmountType: tax?.type || TaxAmountType.TaxAmountTypePercentage,
    },
    netPrice: Number(netPrice),
  };
}
