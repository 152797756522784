import { STALE_CHANNEL_NOTIIFICATION_FLAG } from './constants';
import store from './store';

/*
 * Show the stale channel notification
 *
 * * */
export default function show(): void {
  store.setItem(STALE_CHANNEL_NOTIIFICATION_FLAG, true);
}
