import { ChannelType } from '../../../types/ChannelType';
import { channelCollectables } from '../collectables';

export const convertChannelArrayIntoChannelCollectableObjects = (
  channelArray: ChannelType[]
) => {
  const channelsCollectableObject = channelArray.reduce((acc, channel) => {
    if (!channel) return acc;
    acc.push(channelCollectables(channel));
    return acc;
  }, [] as ReturnType<typeof channelCollectables>[]);
  return channelsCollectableObject;
};
