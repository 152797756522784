import React from 'react';

import cx from 'classnames';
import { ValidatedInput } from 'components';

import { S } from 'components/typography';

import styles from './VisitorInput.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  displayName: string;
  value: number | string | null | undefined;
  placeholder: string;
  type?: 'number' | 'text' | 'email' | 'password' | 'tel' | undefined;
  onChange: (value: string) => void;
  validation?: {};
  isPristine?: boolean;
  isHidden?: boolean;
  isRequired?: boolean;
  testId?: string;
  isDisabled?: boolean;
};

export default function VisitorInput({
  className,
  style,
  displayName,
  value,
  placeholder,
  type = 'text',
  onChange,
  validation,
  isPristine,
  isHidden = false,
  isRequired = false,
  testId,
  isDisabled = false,
}: Props) {
  if (isHidden) return null;
  return (
    <div className={cx(styles.visitorInput, className)} style={style}>
      <S>
        {displayName}
        {isRequired ? <span className={styles.required}>*</span> : ''}
      </S>
      <ValidatedInput
        testId={testId}
        className={styles.inputField}
        value={value}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        validation={validation}
        isPristine={isPristine}
        disabled={isDisabled}
      />
    </div>
  );
}
