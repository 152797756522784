import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class InvalidCardError extends CodedError {
  static get errorName() {
    return errorCodes.invalidCardError.code;
  }

  constructor(message = errorCodes.invalidCardError.message) {
    super(message);
    this.code = errorCodes.invalidCardError.code;
    this.message = `${message} ${errorCodes.invalidCardError.code}`;
    this.name = errorCodes.invalidCardError.name;
  }
}
