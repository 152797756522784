import React from 'react';

import { v4 as uuid } from 'uuid';

import { BaseProps, BaseDropdown } from './ChannelDropdown';

type ItemSchema = {
  label: string;
  value: any;
};

type OwnProps = {
  schema?: ItemSchema;
  value?: any[];
  onChange: (items: any[]) => void;
  Menu?: React.FC<any>;
  items?: any[];
  label?: string;
  isError?: boolean;
  isMenuOpen?: boolean;
  onChannelRemoved: (channelId: string) => void;
  onClear: () => void;
  onMenuClose: () => void;
  onMenuOpen: () => void;
};

type Props<T extends unknown> = OwnProps & Omit<BaseProps<T>, 'items'>;

export const ChannelMultiselect = <T,>({
  items = [],
  name = `MultiselectField-${uuid()}`,
  value = [],
  onChange,
  onChannelRemoved,
  onClear,
  onMenuClose,
  onMenuOpen,
  label,
  isError,
  isMenuOpen,
  schema = { label: 'label', value: 'value' },
  errors,
  Menu,
  ...props
}: Props<T>) => {
  function constructOption(item: any) {
    return {
      label: item[schema.label],
      value: item[schema.value],
    };
  }

  return (
    <BaseDropdown
      value={value}
      name={name}
      isMulti
      isFullWidth
      label={label}
      onChange={onChange}
      onChannelRemoved={onChannelRemoved}
      onClear={onClear}
      closeMenuOnSelect
      items={items && items.map(constructOption)}
      errors={errors}
      invalid={isError}
      Menu={Menu}
      isMenuOpen={isMenuOpen}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      {...props}
    />
  );
};
