import gql from 'graphql-tag';

export default gql`
  mutation updateWorkflow($workflowId: ID!, $workflow: WorkflowInput) {
    updateWorkflow(workflowId: $workflowId, workflow: $workflow) {
      _id
      _created
      _updated
      _createdBy
      _updatedBy
      event
      name
      when
      whenContext
      inStatus
      time
      action
      target
      targetType
      payload
      workflow
      dataValidationSchema
      type
      channelId
      deletedAt
    }
  }
`;
