import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Tags.scss';

type OwnProps = {
  tags: string[];
  selected?: string[];
  onClick?: (...args: any[]) => any;
  className?: string;
  style?: React.CSSProperties;
};

type Props = OwnProps;

function Tags({ tags, selected = [], onClick = () => {}, className, style }: Props) {
  const { t } = useTranslation();
  return (
    <div className={cx(styles.Tags, className)} style={style}>
      {tags.map(tag => (
        <button
          key={tag}
          className={styles.tag}
          data-is-selected={selected && selected.includes(tag)}
          onClick={() => onClick(tag)}
        >
          {t(tag)}
        </button>
      ))}
    </div>
  );
}

export default Tags;
