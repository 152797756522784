import { useLazyQuery } from '@apollo/client';
import { getContentTotalQuantityClaimed } from 'graphql-queries';
import { explodeFeatures } from '../../helpers/features';

type QueryDataResult = {
  contentFeatureQuantityClaimed: number;
};

type QueryVariables = {
  contentId: string;
};

export function useRemainingQuantity(content: any) {
  const { quantityFeature } = explodeFeatures(content?.features);

  const [fetchTotalQuantityClaimedCount, { loading, data }] = useLazyQuery<
    QueryDataResult,
    QueryVariables
  >(getContentTotalQuantityClaimed, {
    variables: {
      contentId: content?._id,
    },
  });

  const total = quantityFeature?.total ?? 0;
  const totalQuantityClaimed = data?.contentFeatureQuantityClaimed || 0;

  const handleFetchTotalQuantityClaimedCount = async () => {
    fetchTotalQuantityClaimedCount();
  };

  return {
    quantityRemaining: Math.max(0, total - totalQuantityClaimed),
    quantityClaimed: totalQuantityClaimed,
    fetchTotalQuantityClaimedCount: handleFetchTotalQuantityClaimedCount,
    loading,
  };
}
