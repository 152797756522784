import gql from 'graphql-tag';

export default gql`
  mutation createWorkOrderMutation(
    $createServiceRequest: CreateServiceRequestDTO
  ) {
    createServiceRequest(serviceRequestRequest: $createServiceRequest) {
      serviceRequestId
      createdBy {
        name
        id
      }
      createdAt
      updatedAt
      updatedBy {
        name
        id
      }
      description
      category
      issue
      location
      floor
      company {
        name
        id
      }
      suite
      contentId
      assignee {
        name
        id
      }
    }
  }
`;
