import React, { PureComponent } from 'react';

import cx from 'classnames';

import { S } from 'components/typography';

import styles from './Toast.scss';

const DURATION = 2500;

type State = {
  message: React.ReactNode;
  isOpen: boolean;
  resolve: null | ((b: boolean) => void);
};

type Props = {};

/**
 * This class is intended to be used as a singleton
 * global toast attached to the window object.
 *
 * This is useful to use programatically when it is not always easy to create
 * a toast in the "React" way.
 */
export default class Toast extends PureComponent<Props, State> {
  timeout: typeof setTimeout | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      message: '',
      isOpen: false,
      resolve: null,
    };

    this.hide = this.hide.bind(this);
  }

  async show(message: any, duration = DURATION) {
    this.setState({
      message,
      isOpen: true,
    });

    // @ts-expect-error ts-migrate(2741) FIXME: Property '__promisify__' is missing in type 'Timeo... Remove this comment to see the full error message
    this.timeout = setTimeout(() => this.hide(), duration);

    await new Promise(resolve =>
      this.setState({
        resolve,
      })
    );
  }

  _hide() {
    this.setState({
      isOpen: false,
      resolve: null,
    });
  }

  async hide() {
    const { isOpen, resolve } = this.state;

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    clearTimeout(this.timeout);

    if (!isOpen) {
      return;
    }

    if (resolve) {
      resolve(true);
    }

    this._hide();
  }

  render() {
    const { isOpen, message } = this.state;

    return (
      <div className={styles.container}>
        <div
          role="presentation"
          className={cx(styles.Toast, { [styles.visible]: isOpen })}
          onClick={() => this.hide()}
          data-test="toast"
        >
          <S>{message}</S>
          <button className={styles.dismissButton} onClick={this.hide}>
            <S>dismiss</S>
          </button>
        </div>
      </div>
    );
  }
}
