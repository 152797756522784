import { WorkflowTargetEnum } from '../../types/Workflows';

export const EVENT_TASK_CREATED = 'task.created';
export const EVENT_TASK_STATUS_UPDATED = 'task.updated.status';
export const EVENT_TASK_NON_STATUS_UPDATED = 'task.updated.nonstatus';
export const EVENT_TASK_ASSIGNED = 'task.assigned';
export const EVENT_TASK_UNASSIGNED = 'task.unassigned';

export const taskEventKeys = [
  EVENT_TASK_CREATED,
  EVENT_TASK_STATUS_UPDATED,
  EVENT_TASK_NON_STATUS_UPDATED,
  EVENT_TASK_ASSIGNED,
  EVENT_TASK_UNASSIGNED,
];
export const taskEvents: {
  key: string;
  description: string;
  userPermitted?: undefined;
}[] = [
  {
    key: EVENT_TASK_CREATED,
    description: 'A task has been created',
  },
  {
    key: EVENT_TASK_STATUS_UPDATED,
    description: "A task's status has been updated",
  },
  {
    key: EVENT_TASK_NON_STATUS_UPDATED,
    description: 'A task has been updated',
  },
  {
    key: EVENT_TASK_ASSIGNED,
    description: 'A task has been assigned',
  },
  {
    key: EVENT_TASK_UNASSIGNED,
    description: 'A task has been unassigned',
  },
];

export const taskWorkflowEventLabels = {
  [EVENT_TASK_CREATED]: 'shared.workflow.eventLabel.task.created',
  [EVENT_TASK_STATUS_UPDATED]: 'shared.workflow.eventLabel.task.statusUpdated',
  [EVENT_TASK_NON_STATUS_UPDATED]: 'shared.workflow.eventLabel.task.updated',
  [EVENT_TASK_ASSIGNED]: 'shared.workflow.eventLabel.task.assigned',
  [EVENT_TASK_UNASSIGNED]: 'shared.workflow.eventLabel.task.unassigned',
};

export const taskWorkflowTargetLabels = {
  [WorkflowTargetEnum.WorkOrderTeam]: 'shared.workflow.targetLabel.task.team',
  [WorkflowTargetEnum.WorkOrderAdmin]: 'shared.workflow.targetLabel.task.admin',
};
