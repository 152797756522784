import colors from './colors';
import baseConfig from './config.json';
import appUrl from './getAppUrl';
import env from './getEnv';
import gradients from './gradients';
import isBrowser from './isBrowser';
import isNode from './isNode';
import isReactNative from './isReactNative';
import routes from './routes';
import shadows from './shadows';
import spacing from './spacing';
import * as whitelabels from './whitelabels';

type AvailableEnvironments = keyof typeof baseConfig;

const config = {
  ...baseConfig.default,
  ...baseConfig[env as AvailableEnvironments],
};

if (env === 'local') {
  if (process.env.LD_CLIENT_ID) {
    config.launchDarkly.clientId = process.env.LD_CLIENT_ID;
  }

  if (process.env.LD_MOBILE_ID) {
    config.launchDarkly.mobileId = process.env.LD_MOBILE_ID;
  }

  if (process.env.LOCAL_AWS_REGION) {
    config.aws_region = process.env.LOCAL_AWS_REGION;
  }

  if (process.env.AUTH0_DOMAIN) {
    config.auth0.domain = process.env.AUTH0_DOMAIN;
  }

  if (process.env.AUTH0_CLIENT_ID) {
    config.auth0.clientId = process.env.AUTH0_CLIENT_ID;
  }
}

if (process.env.GITHUB_SHA_SHORT) {
  config.mobileVersion.githubSha1 = process.env.GITHUB_SHA_SHORT;
}

if (env !== 'test')
  console.log(
    `[config] Using APP_ENV="${env}" appUrl="${appUrl}" aws_region="${config.aws_region}"`
  );

export {
  config,
  appUrl,
  colors,
  gradients,
  shadows,
  routes,
  whitelabels,
  env,
  isReactNative,
  isNode,
  isBrowser,
  spacing,
};

export default {
  ...config,
  colors,
  gradients,
  shadows,
  routes,
  whitelabels,
  env,
  isReactNative,
  isNode,
  isBrowser,
};
