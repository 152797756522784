import gql from 'graphql-tag';

export const getServiceRequestFeaturesByChanneld = gql`
  query getServiceRequestFeaturesByChannelId($channelId: String!) {
    getServiceRequestFeaturesByChannelId(channelId: $channelId) {
      features {
        createServiceRequest
        updateServiceRequest
      }
    }
  }
`;
