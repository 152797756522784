import gql from 'graphql-tag';

import {
  ThemeFragment,
  FullContentTemplateFragment,
  PublicUserFragment,
} from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullContentTemplateFragment}

  mutation CreateTemplate($libraryItem: LibraryItemInput!) {
    createContentTemplate(libraryItem: $libraryItem) {
      _id
      template {
        ...FullContentTemplateFragment
      }
    }
  }
`;
