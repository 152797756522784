import { createContext } from 'react';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { whitelabels } from '../config';

export type AppContextType = {
  blocks: any[];
  cards: any[];
  whitelabel: WhiteLabelType;
  whitelabels: WhiteLabelType[];
  hasWhiteLabelLoaded: boolean;
  isTransitioning: boolean;
  transition: (duration?: number, onFinishTransition?: () => void) => void;
  clearTransition: () => void;
  isBlockingLoad: boolean;
  setIsBlockingLoad: (blockingLoad: boolean) => void;
  fetchWhiteLabels: () => Promise<void>;
  fetchBlocks: () => Promise<void>;
};

const AppContext = createContext<AppContextType>({
  blocks: [],
  cards: [],
  isTransitioning: false,
  transition: () => undefined,
  clearTransition: () => null,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'WhiteLabelTyp... Remove this comment to see the full error message
  whitelabel: {},
  // @ts-expect-error ts-migrate(2740) FIXME: Type 'typeof import("/Users/william/lane/lane-next... Remove this comment to see the full error message
  whitelabels,
  hasWhiteLabelLoaded: false,
  isBlockingLoad: true,
  setIsBlockingLoad: () => undefined,
  fetchWhiteLabels: async () => {},
  fetchBlocks: async () => {},
});

export default AppContext;
