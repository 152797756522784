import React from 'react';

import { Route } from 'react-router-dom';

import { hasPermission } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  PERMISSION_ACCESS_CONTROL,
  PERMISSION_ADMIN,
} from 'lane-shared/helpers/constants/permissions';
import { SIDE_BAR_LINKS_ORDER } from 'lane-shared/helpers/constants/sidebarLinksOrder';
import i18n from 'localization';
import { UserType } from 'lane-shared/types/User';

import {
  UserAccessPageConfig,
  AccessControlPage,
  AccessControlGroupsPageConfig,
  AccessControlGroupDetailsPageConfig,
  ManageUserAccessPageConfig,
} from './pageConfig';
import { SidebarLinkType } from 'hooks/useChannelSidebarLinks';

const hasAnyPermission = (
  user: UserType,
  permissions: string[],
  channelId: string
) => {
  return (
    user.isSuperUser ||
    (channelId && hasPermission(user.roles, permissions, channelId))
  );
};

export const AccessControlRoutes = ({
  urlPath,
  channel,
  user,
}: {
  urlPath: string;
  channel: any;
  user: UserType;
}): JSX.Element | null => {
  const hasAccessControlPageAccess = hasAnyPermission(
    user,
    [PERMISSION_ADMIN, PERMISSION_ACCESS_CONTROL],
    channel?._id
  );
  const entries: [string, AccessControlPage][] = [];

  if (hasAccessControlPageAccess) {
    entries.push(
      [UserAccessPageConfig.key, UserAccessPageConfig],
      [ManageUserAccessPageConfig.key, ManageUserAccessPageConfig],
      [AccessControlGroupsPageConfig.key, AccessControlGroupsPageConfig],
      [
        AccessControlGroupDetailsPageConfig.key,
        AccessControlGroupDetailsPageConfig,
      ]
    );
  }

  return (
    <>
      {entries.map(([key, { Page, path }]) => {
        return (
          <Route key={key} exact path={`${urlPath}/${path}`}>
            <Page
              channel={channel}
              hasAnyPermission={(permissions: string[]): boolean => {
                return (
                  channel?._id &&
                  hasPermission(user.roles, permissions, channel?._id)
                );
              }}
            />
          </Route>
        );
      })}
    </>
  );
};

const buildLinks = (url: string, channelId: string, user: UserType) => {
  const links: SidebarLinkType[] = [];

  const hasAccessControlPageAccess = hasAnyPermission(
    user,
    [PERMISSION_ADMIN, PERMISSION_ACCESS_CONTROL],
    channelId
  );

  if (hasAccessControlPageAccess) {
    links.push({
      exact: false,
      route: `${url}/${UserAccessPageConfig.path}`,
      name: UserAccessPageConfig.name(),
      headerMessage: UserAccessPageConfig.headerMessage(),
      order: UserAccessPageConfig.order,
    });
  }

  if (hasAccessControlPageAccess) {
    links.push({
      exact: false,
      route: `${url}/${AccessControlGroupsPageConfig.path}`,
      name: AccessControlGroupsPageConfig.name(),
      headerMessage: AccessControlGroupsPageConfig.headerMessage(),
      order: AccessControlGroupsPageConfig.order,
    });
  }

  return links;
};

export const accessControlNavigation = (
  url: string,
  channelId: string,
  user: UserType
) => {
  return {
    icon: 'lock-open',
    iconSet: ICON_SET_FONTAWESOME,
    name: i18n.t('web.admin.accessControl.header'),
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.ACCESS_CONTROL,
    links: buildLinks(url, channelId, user),
  };
};
