import { ContentTypeEnum } from '../../types/content/ContentTypeEnum';
import { STEP_EDITOR, STEP_INFO, STEP_PUBLISH, STEP_TARGETING } from './steps';

export const ONE_MINUTE = 1000 * 60; // ms
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;

/**
 * @deprecated use `ContentTypeEnum` instead
 */
export const CONTENT_TYPES = {
  STATIC: ContentTypeEnum.Static,
  CONTENT: ContentTypeEnum.Content,
  NOTICE: ContentTypeEnum.Notice,
  SCHEDULED_NOTICE: ContentTypeEnum.ScheduledNotice,
  SCHEDULED_CONTENT: ContentTypeEnum.ScheduledContent,
  PROMOTION: ContentTypeEnum.Promotion,
  PERK: ContentTypeEnum.Perk,
  WORK_ORDER: ContentTypeEnum.WorkOrder,
  VISITOR_MANAGEMENT: ContentTypeEnum.VisitorManagement,
} as const;

export const FRIENDLY_CONTENT_TYPES = {
  [CONTENT_TYPES.STATIC]: 'Page',
  [CONTENT_TYPES.CONTENT]: 'Post',
  [CONTENT_TYPES.NOTICE]: 'Notice',
  [CONTENT_TYPES.SCHEDULED_NOTICE]: 'Schedule Notice',
  [CONTENT_TYPES.SCHEDULED_CONTENT]: 'Scheduled Content',
  [CONTENT_TYPES.PROMOTION]: 'Promotion',
  [CONTENT_TYPES.PERK]: 'Perk',
};

export const CONTENT_LOCATION_NAMES = [
  'Content Center',
  'Page Center',
  'From a Section',
] as const;

export const [
  CONTENT_LOCATION_LIVE,
  CONTENT_LOCATION_PAGE_CENTER,
  CONTENT_LOCATION_IN_SECTION,
] = CONTENT_LOCATION_NAMES;

export const CONTENT_LOCATIONS = [
  {
    label: CONTENT_LOCATION_LIVE,
    value: CONTENT_LOCATION_LIVE,
  },
  {
    label: CONTENT_LOCATION_PAGE_CENTER,
    value: CONTENT_LOCATION_PAGE_CENTER,
  },
  {
    label: CONTENT_LOCATION_IN_SECTION,
    value: CONTENT_LOCATION_IN_SECTION,
  },
];

export const CONTENT_SEARCH_TYPES = ['channel'] as const;
export const [CONTENT_SEARCH_TYPE_CHANNEL] = CONTENT_SEARCH_TYPES;

export const CONTENT_SEARCH_TYPES_ITEMS = [
  {
    label: 'On this Channel…',
    value: CONTENT_SEARCH_TYPE_CHANNEL,
  },
];

export const BUILDER_STEPS = {
  [CONTENT_TYPES.STATIC]: [STEP_INFO, STEP_EDITOR, STEP_TARGETING],
  [CONTENT_TYPES.CONTENT]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [CONTENT_TYPES.NOTICE]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [CONTENT_TYPES.SCHEDULED_NOTICE]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [CONTENT_TYPES.SCHEDULED_CONTENT]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [CONTENT_TYPES.PROMOTION]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [CONTENT_TYPES.PERK]: [STEP_INFO, STEP_EDITOR, STEP_TARGETING, STEP_PUBLISH],
  [CONTENT_TYPES.WORK_ORDER]: [
    STEP_EDITOR,
    STEP_INFO,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [CONTENT_TYPES.VISITOR_MANAGEMENT]: [STEP_INFO, STEP_EDITOR, STEP_TARGETING],
};

export const AVAILABLE_TEMPLATE_TYPES = [
  CONTENT_TYPES.STATIC,
  CONTENT_TYPES.CONTENT,
  CONTENT_TYPES.PERK,
  CONTENT_TYPES.PROMOTION,
];

// todo: this will change in the future as we use the rendering engine to display cards.
export const CARD_LAYOUTS = [
  'Standard Card',
  'Hero Card',
  'Horizontal Card',
  'Notice Card',
  'Message Card',
] as const;

export const PIN_LAYOUTS = [
  'Standard Pin',
  'Circle Pin',
  'Square Pin',
  'List View Pin',
  'Image Pin',
] as const;

export const [
  CONTENT_CARD_STANDARD,
  CONTENT_CARD_HERO,
  CONTENT_CARD_HORIZONTAL,
  NOTICE_CARD,
  MESSAGE_CARD,
] = CARD_LAYOUTS;

// todo: this will change in the future and be pulled from the database
// but at the moment these values never change and there is a small amount
// of them
export const CONTENT_CARD_ID = {
  [CONTENT_CARD_STANDARD]: '4Hj2AbFK28ddUz8myGPPLd',
  [CONTENT_CARD_HORIZONTAL]: '0MDtYW6AbxQd5b2Zx7VcV1',
  [CONTENT_CARD_HERO]: '5jDlkbaSaeXMraWQdFo18H',
  [NOTICE_CARD]: '2MdkTpqSsGLVg5Ur8IWdki',
  [MESSAGE_CARD]: '49Dwxk2d8LIfSlA8YNUqPY',
};

export const [
  PIN_STANDARD,
  PIN_CIRCLE,
  PIN_SQUARE,
  PIN_LIST_VIEW,
  PIN_IMAGE,
] = PIN_LAYOUTS;

export const CONTENT_TYPES_WITH_PIN_LAYOUT = [
  ContentTypeEnum.Static,
  ContentTypeEnum.VisitorManagement,
  ContentTypeEnum.WorkOrder,
];
