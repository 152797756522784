import gql from 'graphql-tag';

export type DeleteUnitMutationResponse = {
  deleteUnit: boolean;
};

export const deleteUnit = gql`
  mutation DeleteUnit($propertyId: UUID!, $unitId: UUID!) {
    deleteUnit(propertyId: $propertyId, unitId: $unitId)
  }
`;
