import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import styles from './style.scss';

type OwnRadioProps<T> = {
  /** function to return provided value and name(radio field id) on select */
  onChange: (value: T, name: string | undefined) => void;
  /** unique value for radio select */
  value: T;
  /** text to be show as radio label */
  text?: string;
  /** mutual name for radio buttons to group them */
  name?: string;
  /** selected instance of radio button */
  selected?: T;
  /** flag to mark radio button as disabled */
  disabled?: boolean;
  className?: string;
  color?: string;
  style?: React.CSSProperties;
  interfaceStyle?: 'dark' | undefined;
  showBorder?: boolean;
  subtext?: string;
  testId?: string;
};

type RadioProps<T> = OwnRadioProps<T> & {
  text?: string;
  selected?: string;
  disabled?: boolean;
  doTranslate?: boolean;
  showBorder?: boolean;
  subtext?: string;
};

function Radio<T extends string>({
  name,
  value,
  text = '',
  selected = '' as T,
  onChange,
  disabled = false,
  className,
  style,
  color,
  interfaceStyle,
  doTranslate = true,
  showBorder = false,
  subtext = '',
  testId,
}: RadioProps<T>) {
  const { t } = useTranslation();

  return (
    <label
      className={cx(
        styles.container,
        { [styles.disabled]: disabled },
        { [styles.radioBorder]: showBorder },
        { [styles.active]: showBorder && selected === value },
        className
      )}
      style={style}
      htmlFor={value}
      onClick={() => onChange(value, name)}
      onKeyDown={e =>
        (e.key === Key.Enter || e.key === ' ') && onChange(value, name)
      }
      data-interface-style={interfaceStyle}
      tabIndex={disabled ? undefined : 0}
    >
      <div className={styles.checkboxContainer}>
        <input
          data-test={testId}
          type="radio"
          name={name}
          value={value}
          checked={selected === value}
          onChange={() => {}}
          tabIndex={-1}
        />
        <span
          className={styles.radio}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ color: string; } | null' is not assignable... Remove this comment to see the full error message
          style={color && selected === value ? { color } : null}
        />
      </div>
      <div className={styles.textContainer}>
        <span className={cx(styles.text, { [styles.disabled]: disabled })}>
          {doTranslate ? t(text) : text}
        </span>
        {subtext && (
          <p className={cx(styles.subtext, { [styles.disabled]: disabled })}>
            {doTranslate ? t(subtext) : subtext}
          </p>
        )}
      </div>
    </label>
  );
}

export default Radio;
