import gql from 'graphql-tag';

import { ProfileType } from '../../types/ProfileType';

export type ProfileFragmentType = Pick<
  ProfileType,
  | '_id'
  | '_created'
  | '_updated'
  | 'name'
  | 'description'
  | 'email'
  | 'phone'
  | 'image'
  | 'wordmark'
  | 'color'
  | 'isPublic'
  | 'logo'
  | 'backgroundColor'
>;

export default gql(`
  fragment ProfileFragment on Profile {
    _id
    _created
    _updated
    name
    description
    email
    phone
    image
    wordmark
    color
    isPublic
    logo
    backgroundColor
  }
`);
