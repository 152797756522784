import React from 'react';

import { useTranslation } from 'react-i18next';

import { NotesType } from 'lane-shared/domains/visitorManagement/types';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { H4 } from 'components/typography';

import styles from './UCIReceiptNotes.scss';

type Props = {
  notes?: NotesType;
};

export function UCIReceiptNotes({ notes }: Props) {
  const { t } = useTranslation();
  const noteToStaff = notes?.noteToStaff;
  const noteToVisitor = notes?.noteToVisitor;
  const additionalRecipients = notes?.additionalRecipients ?? [];
  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );
  const multipleNotificationRecipientsEnabled = useFlag(
    FeatureFlag.VisitorManagementMultipleNotificationRecipients,
    false
  );

  if (!visitorNotesEnabled && !multipleNotificationRecipientsEnabled) {
    return null;
  }

  return (
    <div>
      <div className={styles.header} data-test="header">
        <H4>
          {t('web.content.feature.visitorManagement.receipt.notes.header')}
        </H4>
      </div>
      {visitorNotesEnabled && (
        <div className={styles.notesRow}>
          <div>
            <div className={styles.subheader} data-test="staffHeader">
              {t(
                'web.content.feature.visitorManagement.receipt.notes.notesToStaff'
              )}
            </div>
            <p className={styles.notes} data-test="staffNote">
              {noteToStaff}
            </p>
          </div>
          <div>
            <div className={styles.subheader} data-test="visitorHeader">
              {t(
                'web.content.feature.visitorManagement.receipt.notes.notesToVisitor'
              )}
            </div>
            <p className={styles.notes} data-test="visitorNote">
              {noteToVisitor}
            </p>
          </div>
        </div>
      )}
      {multipleNotificationRecipientsEnabled && (
        <div className={styles.notesRow}>
          <div>
            <div
              className={styles.subheader}
              data-test="additionalRecipientsHeader"
            >
              {t(
                'web.content.feature.visitorManagement.receipt.notes.additionalRecipients'
              )}
            </div>
            <p className={styles.notes} data-test="additionalRecipients">
              {additionalRecipients?.length
                ? additionalRecipients.join(', ')
                : ''}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
