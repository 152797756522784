import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  mutation createMeterReadingValueMutation(
    $createMeterReadingValue: MeterReadingValueInput
    $channelId: String!
  ) {
    createMeterReadingValue(
      meterReadingValue: $createMeterReadingValue
      channelId: $channelId
    ) {
      id
    }
  }
`);
