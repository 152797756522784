import gql from 'graphql-tag';

import { DraftContentFragment } from '../fragments';

export default gql`
  ${DraftContentFragment}

  query DraftContentOnChannel(
    $pagination: PaginationInput
    $search: DraftContentSearch
    $channelId: UUID!
  ) {
    draftContentOnChannel(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        ...DraftContentFragment
        analytics {
          _id
          ratings
          rating
          views
          details
          hides
          deletes
          pins
          stars
          likes
          shares
          interactions
        }
      }
    }
  }
`;
