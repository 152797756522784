import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class AngusApiError extends CodedError {
  static get errorName() {
    return errorCodes.angusApiError.code;
  }

  constructor(message = errorCodes.angusApiError.message) {
    super(message);
    this.code = errorCodes.angusApiError.code;
    this.message = `${message} ${errorCodes.angusApiError.code}`;
    this.name = errorCodes.angusApiError.name;
  }
}
