import gql from 'graphql-tag';

export default gql`
  query TaskAuditLogs($taskId: String!) {
    taskAuditLogs(taskId: $taskId) {
      userName
      userImageUrl
      actions {
        field
        title
        previousState
        currentState
      }
      date
    }
  }
`;
