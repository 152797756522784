import { Dispatch, SetStateAction, ReactNode } from 'react';

import { DateTime, Interval } from 'luxon';

import spacing from 'lane-shared/config/spacing';
import {
  LONG_MONTH,
  TIMEZONE,
  LONG_MONTH_DATE_YEAR,
  LONG_TIME,
} from 'lane-shared/helpers/constants/dates';
import { copyTimeToDate, parseDateTime } from 'lane-shared/helpers/dates';
import { TimeUnitEnum } from 'lane-shared/types/TimeUnitEnum';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { TimeIntervals } from './TimePicker';

export const formatFullDate = 'DD/MM/YYYY';
export const formatYearDate = 'yyyy';
export const CALENDAR_HEIGHT = 385;
export const CALENDAR_MONTH_YEAR_HEIGHT = 280;
export const CALENDAR_WIDTH = 625;
export const DISTANCE_FROM_PARENT = `${spacing[7] + spacing[2]}px`;
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;

export function getFormatDate({
  timeUnit,
  isLongFormat = false,
}: {
  timeUnit: TimeUnitEnum;
  isLongFormat?: boolean;
}) {
  switch (timeUnit) {
    case TimeUnitEnum.Month:
      return `${LONG_MONTH} ${formatYearDate}`;
    case TimeUnitEnum.Year:
      return formatYearDate;
    case TimeUnitEnum.Day:
      return isLongFormat ? LONG_MONTH_DATE_YEAR : 'dd/MM/yyyy';
    default:
      return 'dd/MM/yyyy';
  }
}

type CalendarProps = (
  timeUnit: string,
  yearCalendar: () => ReactNode,
  monthCalendar: () => ReactNode,
  calendar: () => ReactNode
) => ReactNode;

export type TimeOption = {
  date: Date;
  disabled?: boolean;
};

export type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

export const renderCalendar: CalendarProps = (
  timeUnit,
  yearCalendar,
  monthCalendar,
  calendar
) => {
  switch (timeUnit) {
    case TimeUnitEnum.Year:
      return yearCalendar();
    case TimeUnitEnum.Quarter:
    case TimeUnitEnum.Month:
      return monthCalendar();
    case TimeUnitEnum.Week:
    case TimeUnitEnum.Day:
    case TimeUnitEnum.Hour:
    default:
      return calendar();
  }
};

export function formatTime(
  date: DateTime,
  showTimezone?: boolean,
  timeZone?: string | null
) {
  const formatString = showTimezone ? `${LONG_TIME} ${TIMEZONE}` : LONG_TIME;

  return timeZone
    ? date.setZone(timeZone).toFormat(formatString)
    : date.toFormat(formatString);
}

export function handleOpenDropdown(
  e: MouseEvent,
  heightOfPage: number,
  dropdownHeight: number,
  handleOpen: Dispatch<
    SetStateAction<{
      opened: boolean;
      openedAbove: boolean;
      openedFromRight?: boolean;
    }>
  >,
  dropdownWidth: number,
  secondInput?: boolean
) {
  const {
    bottom: toBottom,
    height,
    width,
    left,
  } = (e.target as any).getBoundingClientRect();
  const conditionToOpenVertically =
    heightOfPage - height - dropdownHeight < toBottom;
  const conditionToOpenHorizontally = dropdownWidth - left > CALENDAR_WIDTH;
  const conditionToOpenHorizontallyWithSecondInput =
    dropdownWidth - left + width > CALENDAR_WIDTH;
  handleOpen(prev => ({
    opened: !prev.opened,
    openedAbove: conditionToOpenVertically,
    openedFromRight: !secondInput
      ? conditionToOpenHorizontally
      : conditionToOpenHorizontallyWithSecondInput,
  }));
}

export function handleTimeClick(
  inputDay: DateTime,
  dayUnit: 'startDate' | 'endDate',
  handleDate: Dispatch<SetStateAction<DateRangeType | undefined>>,
  value?: DateRangeType,
  date?: DateRangeType,
  timeZone?: string
) {
  const _date = inputDay;

  const day =
    (parseDateTime(date?.[dayUnit], timeZone) as DateTime) || new Date();

  const selectedDate = parseDateTime(_date, timeZone);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
  const resolvedDate = copyTimeToDate(selectedDate, day);

  handleDate(prev => ({ ...prev, ...value, [dayUnit]: resolvedDate }));
}

export function toDateTime(
  value: Date | string,
  timeZone: string | null | undefined
) {
  return typeof value === 'string'
    ? DateTime.fromISO(value, { zone: timeZone ?? undefined })
    : DateTime.fromJSDate(value, { zone: timeZone ?? undefined });
}

export function isOptionSelectedTime(
  option: Option,
  valueToCheck: DateTime,
  timeZone?: string | null
) {
  if (!valueToCheck) {
    return false;
  }

  // convert the option into a date object to do a check.
  const optionDate = DateTime.fromFormat(option.value, LONG_TIME, {
    zone: timeZone ?? undefined,
  }).set({
    day: valueToCheck.day,
    month: valueToCheck.month,
    year: valueToCheck.year,
  });

  const safeBuffer = { second: 1 };
  const interval = Interval.fromDateTimes(
    optionDate.minus(safeBuffer),
    optionDate.plus(safeBuffer)
  );

  return interval.contains(valueToCheck);
}

export function getTimesFromUnit(
  unit: TimeIntervals,
  date: DateTime,
  showTimezone?: boolean
) {
  const units = (HOURS_IN_DAY * MINUTES_IN_HOUR) / unit;

  // cycle through the unit of time to build out the options array.
  return new Array(units).fill(unit).map((u, i) => {
    const dateTime = date.plus({ minutes: u * i });

    return {
      label: formatTime(dateTime, showTimezone),
      value: dateTime.toISO(),
    };
  });
}

export function mapTimesToTimeOptions(
  times: TimeOption[],
  showTimezone?: boolean,
  timeZone?: string | null
) {
  return times.map(time => {
    const dateTime = DateTime.fromJSDate(time.date);

    return {
      label: formatTime(dateTime, showTimezone, timeZone),
      value: dateTime.toISO(),
      disabled: time.disabled,
    };
  });
}
