import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query getMedia($id: UUID!) {
    media(_id: $id) {
      _id
      _created
      _updated
      type
      tags
      name
      description
      file
      thumbnail
      inUse
      previewUrl
      s3Bucket
      contentType
      _createdBy {
        _id
      }
      _updatedBy {
        _id
      }
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
    }
  }
`;
