import React, { useState } from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { pause } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { getFriendlyBytes } from 'lane-shared/helpers/files';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import { useFlag } from 'lane-shared/hooks';
import useMediaLibrary, {
  SORT_ASC,
  SORT_DESC,
  SORT_BY,
  MediaType,
} from 'lane-shared/hooks/useMediaLibrary';

import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import {
  AmazonS3Buckets,
  DocumentMediaOptions,
  MediaDocumentContentTypeEnum,
  MediaTypeEnum,
} from 'lane-shared/types/media';

import Dropdown from 'components/form/Dropdown';
import FileDrop from 'components/form/FileDrop';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import IconButton from 'components/general/IconButton';
import Loading from 'components/general/Loading';
import Pagination from 'components/general/Pagination';
import LibraryPathCreateModal from 'components/lane/LibraryPathCreateModal';
import PathListItem from 'components/lane/PathListItem';
import PathSelector from 'components/navigation/PathSelector';

import SearchBar from '../general/SearchBar';
import MediaLibraryAddMedia from './MediaLibraryAddMedia';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useMediaUpload from 'hooks/useMediaUpload';
import type { Media } from 'graphql-query-contracts';

import styles from './MediaLibrary.scss';

// TODO: more views will be added in a future ticket
enum Views {
  LIST = 'list',
}

type Props = {
  className?: string;
  style?: React.CSSProperties;
  onMediaSelected?: (media: Media | null | undefined) => void;
  onMediaCreatedCallback?: (media: Media | null | undefined) => void;
  onMediaDoubleClicked?: (
    media: Media | MediaType,
    libraryItemId: string
  ) => void;
  library: LibraryType | null;
  libraries?: LibraryType[] | null;
  storageKey?: string;
  newImageMaxWidth?: number;
  newImageMaxHeight?: number;
};

type ItemType = {
  _id: string;
  media: Media;
  path?: string;
  tags?: string[];
};

const FILE_INPUT_ACCEPT_TYPES = [MediaDocumentContentTypeEnum.pdf];

export default function DocumentsLibrary({
  libraries,
  library,
  className,
  style,
  onMediaSelected,
  onMediaDoubleClicked,
  storageKey,
  onMediaCreatedCallback,
}: Props) {
  const mediaOptions: DocumentMediaOptions = {
    /* options will be added in a future ticket */
  };
  const { t } = useTranslation();
  const {
    items,
    paths,
    availablePaths,
    pageInfo,
    loading,
    search,
    updateSearch,
    onChangeLibrary,
    selectedLibrary,
    availableLibraries,
    refetchMedia,
    getLibraryInfo,
  } = useMediaLibrary({
    libraries,
    library,
    storageKey,
    mediaTypes: [MediaTypeEnum.Document],
  });

  const saveToPrivateS3Bucket = useFlag(FeatureFlag.PrivateS3Media, false);

  const s3Bucket = saveToPrivateS3Bucket
    ? AmazonS3Buckets.Activate
    : AmazonS3Buckets.Lane;

  const { filesSelectedHandler } = useMediaUpload({
    selectedLibrary,
    onMediaCreated,
    tags: search.tags,
    path: search.path,
    mediaOptions,
    s3Bucket,
  });

  const [selectedItem, setSelectedItem] = useState<ItemType | null>(null);
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([]);
  const [isAddingPath, setIsAddingPath] = useState(false);
  const [atPath, setAtPath] = useState<string | null>(null);
  const [view, setView] = useState(Views.LIST);
  const [isMoving, setIsMoving] = useState(false);

  async function onMediaCreated(media: Media) {
    await pause();
    refetchMedia();

    if (onMediaCreatedCallback) {
      onMediaCreatedCallback(media);
    }
  }

  function onItemClicked(item: any) {
    setSelectedItems([]);

    // media is already selected, unselect
    if (item?._id === selectedItem?._id) {
      setSelectedItem(null);
      if (onMediaSelected) onMediaSelected(null);
      return;
    }

    setSelectedItem(item);
    if (onMediaSelected) onMediaSelected(item.media);
  }

  async function moveMedia(path: string) {
    if (!selectedItem && selectedItems.length === 0) {
      return;
    }

    setIsMoving(true);

    // move media or medias to the path
    // either one media is selected, or several are.  Use an array either way
    // to make the code more reusable.
    const toMove = selectedItem ? [selectedItem] : selectedItems;

    // map all the moves to one graphql statement
    const mutationInner = toMove
      .map(
        item =>
          `c${item._id}: updateLibraryItem(libraryItem: {_id: "${item._id}", path: "${path}"}) {
          _id
          path
        }
      `
      )
      .join('\n');

    const mutation = gql`
        mutation updateLibraryItems {
          ${mutationInner}
        }
    `;

    try {
      await getClient().mutate({
        mutation,
      });

      setSelectedItem(null);
      setSelectedItems([]);
      refetchMedia();
    } catch (err) {
      window.Toast.show(<ErrorMessage error={err} />);
    } finally {
      setIsMoving(false);
    }
  }

  function pathDropHandler(e: React.DragEvent, path: string) {
    if (!e.dataTransfer.getData('media')) {
      // some other drop event that we aren't interested in
      return;
    }

    moveMedia(path);
  }

  function pathCreatedHandler() {
    setIsAddingPath(false);
    getLibraryInfo();
  }

  if (!selectedLibrary) {
    return <Loading />;
  }

  return (
    <div className={cx(styles.MediaLibrary, className)} style={style}>
      <ControlMenu className={styles.menu}>
        <PathSelector
          rootPaths={availableLibraries}
          selectedRootPath={selectedLibrary}
          paths={paths}
          selectedPath={search.path}
          onDrop={pathDropHandler}
          onPathSelected={(path: any) => updateSearch({ path })}
          onRootPathSelected={rootPath => onChangeLibrary(rootPath._id)}
          onPathCreate={atPath => {
            setIsAddingPath(true);
            setAtPath(atPath);
          }}
        />

        <hr />

        <MediaLibraryAddMedia
          className={styles.addMedia}
          selectedLibrary={selectedLibrary}
          onMediaCreated={async media => await onMediaCreated(media)}
          path={search.path}
          tags={search.tags}
          accept={FILE_INPUT_ACCEPT_TYPES.toString()}
          buttonText={t('Add Document')}
          type={FileReturnTypeEnum.File}
          mediaOptions={mediaOptions}
          s3Bucket={s3Bucket}
        />
      </ControlMenu>
      <ControlMenu className={styles.menu}>
        <SearchBar
          className={styles.input}
          searchInputClassName={styles.inputWrapper}
          searchOptions={search}
          onSearchChange={search => updateSearch({ search, page: 0 })}
        />

        <Dropdown
          className={styles.sortBy}
          onValueChange={sortBy => updateSearch({ sortBy, page: 0 })}
          items={SORT_BY}
          value={search.sortBy}
        />

        <IconButton
          className={styles.iconButton}
          inverted
          icon={search.sortOrder === SORT_ASC ? 'chevron-down' : 'chevron-up'}
          onClick={() =>
            updateSearch({
              sortOrder: search.sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC,
              page: 0,
            })
          }
        />

        {Object.values(Views).map(v => (
          <IconButton
            className={styles.iconButton}
            key={v}
            icon={v}
            iconSet={ICON_SET_FONTAWESOME}
            selected={view === v}
            onClick={() => setView(v)}
            inverted
          />
        ))}
      </ControlMenu>

      <FileDrop
        className={styles.fileDrop}
        type={FileReturnTypeEnum.File}
        onFilesSelected={filesSelectedHandler}
        enableMultiUpload
        accept={FILE_INPUT_ACCEPT_TYPES.toString()}
      >
        <div className={styles.resultsWrapper}>
          <ul className={styles.paths}>
            {availablePaths.map(path => (
              <PathListItem
                key={path}
                className={styles.listItem}
                path={path}
                onSelected={() => updateSearch({ path })}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: DragEvent<Element>, path: string) => voi... Remove this comment to see the full error message
                onDrop={pathDropHandler}
                small
              />
            ))}
          </ul>
          {view === Views.LIST && (
            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t('Created')}</th>
                    <th>{t('Last Update')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Media Type')}</th>
                    <th>{t('File Type')}</th>
                    <th>{t('Tags')}</th>
                    <th>{t('Size')}</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(item => (
                    <tr
                      tabIndex={0}
                      onKeyPress={key =>
                        key.code === 'Enter' &&
                        onMediaDoubleClicked &&
                        onMediaDoubleClicked(item.media, item._id)
                      }
                      key={item._id}
                      onClick={() => onItemClicked(item)}
                      data-is-selected={item._id === selectedItem?._id}
                      onDoubleClick={() =>
                        onMediaDoubleClicked &&
                        onMediaDoubleClicked(item.media, item._id)
                      }
                    >
                      <td>{simpleDate(item.media._created)}</td>
                      <td>{fromNow(item.media._updated)}</td>
                      <td>{item.media.name}</td>
                      <td>{item.media.type}</td>
                      <td>{item.media.contentType}</td>
                      <td>{item.tags && item.tags.join(', ')}</td>
                      <td>
                        {item.media.file?.size &&
                          getFriendlyBytes(item.media.file.size)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {isMoving && <Loading className={styles.movingLoader} />}
        </div>

        <Pagination
          loading={loading}
          total={pageInfo.total}
          perPage={search.perPage}
          page={search.page}
          onPage={page => updateSearch({ page })}
        />
      </FileDrop>
      <LibraryPathCreateModal
        isOpen={isAddingPath}
        atPath={atPath}
        selectedLibrary={selectedLibrary}
        onClose={() => setIsAddingPath(false)}
        onPathCreated={pathCreatedHandler}
      />
    </div>
  );
}
