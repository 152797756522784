import gql from 'graphql-tag';

export default gql`
  query getServiceRequestFilterAttributesQuery($channelIds: [String]!) {
    serviceRequestFilterAttributes(channelIds: $channelIds) {
      companies {
        name
        id
      }
      categories
      statuses
      issues
      locations
      assignees {
        name
        id
      }
      equipment {
        name
        id
      }
    }
  }
`;
