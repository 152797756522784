import gql from 'graphql-tag';

export default gql`
  query getDraftContentHistory($id: UUID!, $pagination: PaginationInput) {
    draftContentHistory(_id: $id, pagination: $pagination) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        _created
        _updated
        _createdBy
        _updatedBy
        name
        name_l10n
        description
        description_l10n
        subtitle
        slug
        type
        tags
        logo
        color
        isInteractive
        isSyndicateable
        backgroundImage
        backgroundColor
        icon
        iconSet
        iconWeight
        liveDate
        startDate
        endDate
        unpublishDate
        resetPeriod
        lastReset
        nextReset
        version
        renderer
        block
        properties
        placements {
          _id
          type
          channel {
            _id
          }
          content {
            _id
          }
          groupRole {
            _id
          }
          user {
            _id
          }
        }
        audiences {
          id
        }

        features {
          _id
          type
          feature
        }
        data
        state
        actions {
          _id
          _created
          _updated
          _order
          name
          when
          whenContext
          inStatus
          time
          event
          action
          target
          workflow
          payload
          dataValidationSchema
          content {
            _id
          }
          groupRole {
            _id
          }
          user {
            _id
          }
          dataField {
            _id
          }
        }
        notifications {
          _id
          _created
          _updated
          title
          sent
          sendAt
          content {
            _id
          }
        }
        isEvent
      }
    }
  }
`;
