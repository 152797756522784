import gql from 'graphql-tag';

export default gql`
  query getUserMobileAccess($channelId: UUID!, $provider: String!) {
    me {
      user {
        mobileAccess(channelId: $channelId, provider: $provider) {
          enabled
        }
      }
    }
  }
`;
