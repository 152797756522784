import { loadStripe } from '@stripe/stripe-js';
import { config as sharedConfig } from 'lane-shared/config';

export function usePaymentProvider() {
  const stripePromise = loadStripe(sharedConfig.stripe.publishableKey ?? '');

  return {
    provider: stripePromise,
  };
}
