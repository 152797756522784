import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query Team($id: UUID!) {
    team(_id: $id) {
      _id
      name
      users {
        ...PublicUserFragment
      }
    }
  }
`;
