import { LaneType } from 'common-types';
import isProbablyUUID from '../isProbablyUUID';
import APP_URL from 'lane-shared/config/getAppUrl';
import mediaUrl from './mediaUrl';
/**
 * The default image to be return would be the optimized version
 * We can also get the thumbnail if specified.
 */
const defaultOptions = {
  notFromCache: false,
  useGetMediaUrlFF: true, // TODO: remove this after speed tests
};

export default function getImageUrl(
  media:
    | LaneType.UUID
    | {
        _id: LaneType.UUID;
        previewUrl?: string | undefined | null;
      }
    | null
    | undefined,
  options?: {
    notFromCache?: boolean;
    type?: 'original' | 'thumbnail';
    privateS3MediaEnabled?: boolean;
    useGetMediaUrlFF?: boolean;
  }
): string {
  const resultOptions = { ...defaultOptions, ...options };
  if (!media) {
    return '';
  }
  // check to see if this is an object being passed in.
  const id = typeof media === 'string' ? media : media._id;

  if (options?.privateS3MediaEnabled) {
    const previewUrl =
      typeof media === 'object' && media?.previewUrl ? media.previewUrl : '';

    if (previewUrl) return previewUrl;
  }

  if (resultOptions.useGetMediaUrlFF) {
    const mediaOptions: {
      notFromCache?: boolean;
      suffix?: string;
      privateS3MediaEnabled?: boolean;
    } = {};
    if (options?.notFromCache) {
      mediaOptions.notFromCache = options?.notFromCache;
    }
    if (options?.privateS3MediaEnabled) {
      mediaOptions.privateS3MediaEnabled = options?.privateS3MediaEnabled;
    }
    return mediaUrl(media, mediaOptions);
  }

  // add notFromCache parameter
  const parameters: { [key: string]: string }[] = [];
  if (resultOptions.notFromCache) {
    parameters.push({ notFromCache: 'true' });
  }
  if (resultOptions.type) {
    parameters.push({ type: resultOptions.type });
  }
  if (resultOptions.privateS3MediaEnabled) {
    parameters.push({ privateS3MediaEnabled: 'true' });
  }

  if (isProbablyUUID(id)) {
    return `${APP_URL}/api/v5/getImageUrl/${id}${objectToQueryString(
      parameters
    )}`;
  }

  // if it doesn't match any of the above format's this is probably an SVG
  // we do not allow sftp, ssh, ftp, http, https so we can defend against
  // SSRF attacks. See `lane-server/src/routes/api/v5/media.ts`.
  return id;
}

function objectToQueryString(params: { [key: string]: string }[]) {
  const parsedParams = params
    .map(paramObj => {
      const key = Object.keys(paramObj)[0];
      const value = paramObj[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return parsedParams === '' ? '' : `?${parsedParams}`;
}
