import gql from 'graphql-tag';

export const getVisitorPassDetailsForHostByPassId = gql`
  query getVisitorPassDetailsForHostByPassId($id: UUID!) {
    visitorPassByHost(id: $id) {
      id
      visitor {
        firstName
        lastName
        company
        email
        phone
      }
      hostName
      host {
        name
        profile {
          email
          phone
          image
        }
      }
      tenantName
      tenant {
        name
        address {
          name
          street1
          street2
          street3
          city
          state
          country
        }
        profile {
          logo
        }
      }
      accessGrantBuildingId
      floor
      companyName
      status
      visitorNote
      staffNote
      startDatetime
      endDatetime
      submittedBy
      updatedBy
      eventName
      createdAtDatetime
      updatedAtDatetime
      sourceId
      submissionId
      validStateTransitions
      recurrenceId
      additionalRecipients
    }
  }
`;
