import { WorkflowTargetEnum } from '../../types/Workflows';

export const EVENT_SERVICE_REQUEST_CREATED = 'servicerequest.created';
export const EVENT_SERVICE_REQUEST_STATUS_UPDATED =
  'servicerequest.updated.status';
export const EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED =
  'servicerequest.updated.nonstatus';
export const EVENT_SERVICE_REQUEST_ASSIGNED = 'servicerequest.assigned';
export const EVENT_SERVICE_REQUEST_UNASSIGNED = 'servicerequest.unassigned';

export const serviceRequestEventKeys = [
  EVENT_SERVICE_REQUEST_CREATED,
  EVENT_SERVICE_REQUEST_STATUS_UPDATED,
  EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
  EVENT_SERVICE_REQUEST_ASSIGNED,
  EVENT_SERVICE_REQUEST_UNASSIGNED,
];
export const serviceRequestEvents: {
  key: string;
  description: string;
  userPermitted?: undefined;
}[] = [
  {
    key: EVENT_SERVICE_REQUEST_CREATED,
    description: 'A service request has been created',
  },
  {
    key: EVENT_SERVICE_REQUEST_STATUS_UPDATED,
    description: "A service request's status has been updated",
  },
  {
    key: EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED,
    description: 'A service request has been updated',
  },
  {
    key: EVENT_SERVICE_REQUEST_ASSIGNED,
    description: 'A service request has been assigned',
  },
  {
    key: EVENT_SERVICE_REQUEST_UNASSIGNED,
    description: 'A service request has been unassigned',
  },
];

export const serviceRequestWorkflowEventLabels = {
  [EVENT_SERVICE_REQUEST_CREATED]:
    'shared.workflow.eventLabel.serviceRequest.created',
  [EVENT_SERVICE_REQUEST_STATUS_UPDATED]:
    'shared.workflow.eventLabel.serviceRequest.statusUpdated',
  [EVENT_SERVICE_REQUEST_NON_STATUS_UPDATED]:
    'shared.workflow.eventLabel.serviceRequest.updated',
  [EVENT_SERVICE_REQUEST_ASSIGNED]:
    'shared.workflow.eventLabel.serviceRequest.assigned',
  [EVENT_SERVICE_REQUEST_UNASSIGNED]:
    'shared.workflow.eventLabel.serviceRequest.unassigned',
};

export const serviceRequestWorkflowTargetLabels = {
  [WorkflowTargetEnum.WorkOrderTeam]:
    'shared.workflow.targetLabel.serviceRequest.team',
  [WorkflowTargetEnum.WorkOrderAdmin]:
    'shared.workflow.targetLabel.serviceRequest.admin',
  [WorkflowTargetEnum.WorkOrderCreator]:
    'shared.workflow.targetLabel.serviceRequest.creator',
};
