import React from 'react';

import cx from 'classnames';
import { DateTime, Interval } from 'luxon';

import { M } from '../../../index';

import styles from './MonthCell.scss';

type MonthCellProps = {
  className?: string;
  style?: React.CSSProperties;
  // when element is clicked, returns selected day
  onClick: (date: DateTime) => void;
  // start date
  startDate: DateTime | null;
  // end date
  endDate?: DateTime | null;
  // current month
  month: DateTime;
  // formatted day presentation
  text: string;
  // is this button enabled or not
  disabled?: boolean;
};

export const MonthCell = ({
  className,
  style,
  startDate,
  endDate,
  month,
  onClick,
  text,
  disabled = false,
}: MonthCellProps) => {
  const isBetween =
    startDate &&
    endDate &&
    startDate < endDate &&
    Interval.fromDateTimes(startDate, endDate).contains(month);

  const isStartMonth = endDate && startDate?.hasSame(month, 'month');

  return (
    <div
      className={cx(styles.MonthCell, className)}
      style={style}
      data-is-selected={isStartMonth}
    >
      <div
        className={cx(styles.buttonContainer, {
          [styles.firstSelected]: isStartMonth,
          [styles.lastSelected]: (endDate as any)?.hasSame(month, 'month'),
          [styles.between]: startDate && endDate && isBetween,
          [styles.monthSelected]:
            endDate &&
            startDate?.hasSame(endDate, 'month') &&
            startDate?.hasSame(month, 'month'),
          [styles.unavailable]: disabled,
        })}
      >
        <button
          className={cx(styles.button, {
            [styles.selected]:
              startDate?.hasSame(month, 'month') ||
              (endDate as any)?.hasSame(month, 'month'),
          })}
          onClick={() => onClick(month)}
        >
          <M>{text}</M>
        </button>
      </div>
    </div>
  );
};
