import { routes as hardwareManagementRoutes } from '../domains/hardwareManagement/config';
import { routes as userManagementRoutes } from '../domains/userManagement/config';
import { routes as visitorManagementRoutes } from '../domains/visitorManagement/config';
import { FeatureNameEnum } from '../types/features/FeatureNameEnum';

export default {
  login: '/login',
  logout: '/logout',
  signUp: '/sign-up',
  signUpBuilding: '/sign-up/building',
  manualLogin: '/login/manual',
  ssoLogin: '/login/sso',
  magicLinkRequest: '/login/magic-link',
  support: '/l/support',
  signUpName: '/sign-up/name',
  signUpCompany: '/sign-up/company',
  signUpDetails: '/sign-up/details',
  signUpConfirm: '/sign-up/confirm',
  signUpValidateEmail: '/sign-up/validate-email',
  androidAssetLinks: '/.well-known/assetlinks.json',
  appleAppSiteAssociation: '/apple-app-site-association',
  manifestFile: '/manifest.json',
  wellKnownAppleAppSiteAssociation: '/.well-known/apple-app-site-association',
  completeSignup: '/complete-signup/:guid/:verify',
  forgotPassword: '/forgot-password',
  unsubscribe: '/api/v5/unsubscribe/:guid',
  verifyEmail: '/api/v5/verify-email/:loginId/:guid',
  passwordReset: '/l/reset-password/:verify/:guid',
  inviteSuccess: '/l/claim-invite/success',
  invite: '/l/claim-invite/:inviteId',
  home: '/l/home',
  customTab: '/l/tab/:channelSlug/:tabSlug',
  addSubscription: '/l/add-subscription',
  section: '/l/section/:sectionId',
  interactionsOfSection: '/l/interactionsOfSection/:sectionId/',
  retail: '/l/retail',
  perks: '/l/retail/perks',
  qrCodeScanner: '/l/qr-code-scanner',
  me: '/l/me',
  meQRCode: '/l/me/qr',
  meMedia: '/l/me/media',
  meMediaEdit: '/l/me/media/:mediaId/edit',
  meTemplates: '/l/me/templates',
  meTemplateEdit: '/l/me/templates/:templateId/edit',
  meTemplateNew: '/l/me/templates/new',
  meWallet: '/l/me/billing-and-payments/wallet',
  meWalletV2: '/l/me/billing-and-payments/walletV2',
  meAccountInvoices: '/l/me/billing-and-payments/account-invoices',
  meCompanyInvoiceDetails:
    '/l/me/billing-and-payments/invoice/:invoiceDisplayId',
  meBillingAndPayments: '/l/me/billing-and-payments',
  meEssensysInvoices: '/l/me/billing-and-payments/essensys-invoices',
  meEssensysCredits: '/l/me/billing-and-payments/essensys-credits',
  meInvoicePayment: '/l/me/billing-and-payments/pay-invoice',
  meRecurringCharges: '/l/me/billing-and-payments/recurring-charges',
  meCredits: '/l/me/billing-and-payments/credits',
  meSettings: '/l/me/settings',
  meChannelSettings: '/l/settings/channel',
  meAccounts: '/l/me/accounts',
  meRecentActivity: '/l/me/recent-activity',
  meSubscriptions: '/l/me/subscriptions',
  meSubscriptionAdd: '/l/me/add-subscription',
  privacyTermsAndCondition: '/l/me/privacy-terms-and-conditions',
  essensysInvoice:
    '/l/me/billing-and-payments/essensys-invoices/invoice/:invoiceId',
  user: '/l/user/:id',
  mobileAccessHIDCredential: '/l/mobile-access/hid/:invitationCode',
  mobileAccessGallagherCredential: '/l/mobile-access/gallagher/:invitationCode',
  portalManagementVTSTemplates: '/l/portal-management/vts-templates',
  portalManagementVTSTemplatesNew: '/l/portal-management/vts-templates/new',
  portalManagementVTSTemplatesEdit:
    '/l/portal-management/vts-templates/:templateId/edit',
  portalManagement: '/l/portal-management',
  portalManagementNewChannel: '/l/portal-management/new-channel',
  portalManagementIntegrations: '/l/portal-management/integrations',
  portalManagementIntegrationsNew: '/l/portal-management/integrations/new',
  portalManagementIntegrationsEdit:
    '/l/portal-management/integrations/:integrationId/edit',
  portalManagementWhitelabels: '/l/portal-management/whitelabels',
  portalManagementWhitelabelsNew: '/l/portal-management/whitelabels/new',
  portalManagementWhitelabelsEdit:
    '/l/portal-management/whitelabels/:whitelabelId/edit',
  portalManagementChannels: '/l/portal-management/channels',
  portalManagementUsers: '/l/portal-management/users',
  portalManagementUserEdit: '/l/portal-management/users/:userId/edit',
  portalManagementQueries: '/l/portal-management/queries',
  portalManagementContracts: '/l/portal-management/contracts',
  portalManagementChannelManagement: '/l/portal-management/channel-management',
  portalManagementContractEdit:
    '/l/portal-management/contract/:contractId/edit',
  portalManagementContractNew: '/l/portal-management/contracts/new',
  portalManagementRecentJobs: '/l/portal-management/auto-setup-recent-jobs',
  channel: '/l/channel/:id',
  channelFeed: '/l/channel/:id/feed',
  channelRetail: '/l/channel/:id/retail',
  channelOffice: '/l/channel/:id/office',
  channelRelationships: '/l/channel/:id/relationships',
  channelProfile: '/l/channel/:id/profile',
  channelSections: '/l/channel/:id/sections',
  channelNotices: '/l/channel/:id/notices',
  channelContent: '/l/channel/:id/content',
  channelMembers: '/l/channel/:id/members',
  content: '/l/post/:id',
  contentRenderer: '/l/post/:id/renderer',
  contentInteractionNew: '/l/post/:id/new',
  contentInteractions: '/l/post/:id/interactions',
  contentFeatureQRCodeCheckIn: `/l/post/:id/${FeatureNameEnum.QRCodeCheckin}/check-in`,
  contentFeatureQRCodeCheckOut: `/l/post/:id/${FeatureNameEnum.QRCodeCheckin}/check-out`,
  contentIntegrationLogin: '/l/post/:id/login',
  contentIntegrationInvoice: '/l/post/:id/invoice/:invoiceId',
  interaction: '/l/interaction/:id',
  interactionEdit: '/l/interaction/:id/edit',
  visitEdit: '/l/post/:id/visit/:visitId/visitor/:visitorId',
  contentFeatureQRCodeCheckInInteraction: `/l/interaction/:interactionId/${FeatureNameEnum.QRCodeCheckin}/check-in`,
  contentFeatureQRCodeCheckOutInteraction: `/l/interaction/:interactionId/${FeatureNameEnum.QRCodeCheckin}/check-out`,
  channelAdmin: '/l/channel/:id/admin',
  channelAdminDashboard: '/l/channel/:id/admin/dashboard',
  channelAdminContentCenter: '/l/channel/:id/admin/content-center',
  channelAdminDataImportListView: '/l/channel/:id/admin/data-import',
  channelAdminDataImportDetailView: '/l/channel/:id/admin/data-import/:jobId',
  channelAdminDataImportNew: '/l/channel/:id/admin/data-import/new',
  channelAdminDataImportConverter: '/l/channel/:id/admin/data-import/converter',
  channelAdminPageCenter: '/l/channel/:id/admin/page-center',
  channelAdminPerkCenter: '/l/channel/:id/admin/perk-center',
  channelAdminPromotionCenter: '/l/channel/:id/admin/promotion-center',
  channelAdminSections: '/l/channel/:id/admin/sections',
  channelAdminSectionsNew: '/l/channel/:id/admin/sections/new',
  channelAdminSectionsEdit: '/l/channel/:id/admin/sections/:sectionId/edit',
  channelAdminSectionsView: '/l/channel/:id/admin/sections/:sectionId/view',
  channelAdminRelationships: '/l/channel/:id/admin/relationships',
  channelAdminRelationshipsTenantView:
    '/l/channel/:id/admin/relationships/tenant/:tenantId',
  channelAdminProfile: '/l/channel/:id/admin/profile',
  channelAdminInsights: '/l/channel/:id/admin/insights',
  channelAdminInsightsReport: '/l/channel/:id/admin/insights/:selectedReport',
  channelAdminTheme: '/l/channel/:id/admin/theme',
  channelAdminTeams: '/l/channel/:id/admin/teams',
  channelAdminTeamsNew: '/l/channel/:id/admin/teams/new',
  channelAdminTeamsBulkInvite:
    '/l/channel/:id/admin/teams/:groupRoleId/bulkinvite',
  channelAdminTeamsBulkAdd: '/l/channel/:id/admin/teams/bulkadd',
  channelAdminTeamsEdit: '/l/channel/:id/admin/teams/:groupRoleId/edit',
  channelAdminTeamsView: '/l/channel/:id/admin/teams/:groupRoleId/view',
  channelAdminTeamMember: '/l/channel/:id/admin/member/:userId',
  channelAdminTeamMemberBuildingAccess:
    '/l/channel/:id/admin/member/:userId?tab=BuildingAccess',
  channelAdminCreateContent: '/l/channel/:id/admin/create-post',
  channelAdminCreateNotice: '/l/channel/:id/admin/create-notice',
  channelAdminCreatePage: '/l/channel/:id/admin/create-page',
  channelAdminCreatePromotion: '/l/channel/:id/admin/create-promotion',
  channelAdminCreatePerk: '/l/channel/:id/admin/create-perk',
  channelAdminCreateVisitorForm: '/l/channel/:id/admin/create-visitor-form',
  channelAdminDraft: '/l/channel/:id/admin/draft/:draftId',
  channelAdminDraftPreview: '/l/channel/:id/admin/draftpost/:draftId',
  channelAdminContent: '/l/channel/:id/admin/post/:contentId',
  channelAdminGeneratorContent: '/l/channel/:id/admin/generator/:contentId',
  channelAdminContentInteractions:
    '/l/channel/:id/admin/post/:contentId?tab=Table',
  channelAdminInteraction: '/l/channel/:id/admin/interaction/:interactionId',
  channelAdminCharges: '/l/channel/:id/admin/charges',
  channelAdminInvoices: '/l/channel/:id/admin/invoices',
  channelAdminInvoiceView: '/l/channel/:id/admin/invoice/:invoiceDisplayId',
  channelAdminLibrary: '/l/channel/:id/admin/library',
  channelAdminLibraryMedia: '/l/channel/:id/admin/library/media',
  channelAdminLibraryDocuments: '/l/channel/:id/admin/library/documents',
  channelAdminLibraryDocumentsEdit:
    '/l/channel/:id/admin/library/documents/:documentId/edit',
  channelAdminLibraryData: '/l/channel/:id/admin/library/data',
  channelAdminLibraryTemplates: '/l/channel/:id/admin/library/templates',
  channelAdminLibraryTags: '/l/channel/:id/admin/library/tags',
  channelAdminLibraryTagShow: '/l/channel/:id/admin/library/tags/:tagId',
  channelAdminLibraryTagNew: '/l/channel/:id/admin/library/tags/new',
  channelAdminLibraryTagEdit: '/l/channel/:id/admin/library/tags/:tagId/edit',
  channelAdminLibraryFilters: '/l/channel/:id/admin/library/filters',
  channelAdminLibraryWebhooks: '/l/channel/:id/admin/library/webhooks',
  channelAdminDatasetLibrary:
    '/l/channel/:id/admin/library/data/:datasetSchemaId',
  channelAdminSubscriptions: '/l/channel/:id/admin/subscriptions',
  channelAdminSubscriptionsCreate: '/l/channel/:id/admin/subscriptions/create',
  channelAdminSubscriptionDetails:
    '/l/channel/:id/admin/subscriptions/:membershipId',
  legacyChannelAdminMetatags: '/l/channel/:id/admin/meta-tag',
  legacyChannelAdminMetatagEdit:
    '/l/channel/:id/admin/meta-tag/:metatagId/edit',
  legacyChannelAdminMetatagNew: '/l/channel/:id/admin/meta-tag/new',
  channelAdminMetatags: '/l/channel/:id/admin/library/filters',
  channelAdminMetatagEdit:
    '/l/channel/:id/admin/library/filters/:metatagId/edit',
  channelAdminMetatagNew: '/l/channel/:id/admin/library/filters/new',
  channelAdminWebhooks: '/l/channel/:id/admin/webhooks',
  channelAdminWebhookEdit: '/l/channel/:id/admin/webhooks/:webhookId/edit',
  channelAdminWebhookNew: '/l/channel/:id/admin/webhooks/new',
  channelAdminPaymentAccounts: '/l/channel/:id/admin/payment-accounts',
  channelAdminPaymentAccountTransactions:
    '/l/channel/:id/admin/payment-accounts/:paymentAccountId/transactions',
  channelAdminTabs: '/l/channel/:id/admin/tab-center',
  channelAdminTabsEdit: '/l/channel/:id/admin/tab-center/:tabId/edit',
  channelAdminTabsNew: '/l/channel/:id/admin/tab-center/new',
  channelAdminWorkOrders: '/l/channel/:id/admin/work-orders',
  channelAdminProperties: '/l/channel/:id/admin/children',
  channelAdminWorkOrdersEquipment: '/l/channel/:id/admin/work-orders/equipment',
  channelAdminWorkOrdersEquipmentSettings:
    '/l/channel/:id/admin/work-orders/equipment/settings',
  channelAdminWorkOrdersEquipmentCreate:
    '/l/channel/:id/admin/work-orders/equipment/new',
  channelAdminWorkOrdersEquipmentEdit:
    '/l/channel/:id/admin/work-orders/equipment/:equipmentId/edit',
  channelAdminWorkOrdersEquipmentDetails:
    '/l/channel/:id/admin/work-orders/equipment/:equipmentId',
  channelAdminWorkOrdersServiceRequests:
    '/l/channel/:id/admin/work-orders/service-requests',
  channelAdminWorkOrdersServiceRequestDetails:
    '/l/channel/:id/admin/work-orders/service-requests/:serviceRequestId/view',
  channelAdminWorkOrdersServiceRequestEdit:
    '/l/channel/:id/admin/work-orders/service-requests/:serviceRequestId/edit',
  channelAdminWorkOrdersServiceRequestsSettings:
    '/l/channel/:id/admin/work-orders/service-requests/settings',
  channelAdminWorkOrdersServiceRequestsBillingSettings:
    '/l/channel/:id/admin/work-orders/service-requests/settings/billing',
  channelAdminWorkOrdersServiceRequestsBillingCreateCategory:
    '/l/channel/:id/admin/work-orders/service-requests/settings/billing/create-category',
  channelAdminWorkOrdersServiceRequestsBillingCreateProduct:
    '/l/channel/:id/admin/work-orders/service-requests/settings/billing/create-product',
  channelAdminWorkOrdersServiceRequestsBillingCreateChargeCode:
    '/l/channel/:id/admin/work-orders/service-requests/settings/billing/create-charge-code',
  channelAdminWorkOrdersServiceRequestsCreate:
    '/l/channel/:id/admin/work-orders/service-requests/new',
  channelMemberWorkOrderServiceRequestDetails:
    '/l/channel/:id/work-orders/service-requests/:interactionId',
  channelAdminWorkOrdersPM:
    '/l/channel/:id/admin/work-orders/preventive-maintenance',
  channelAdminWorkOrdersPMSchedules:
    '/l/channel/:id/admin/work-orders/preventive-maintenance?tab=schedules',
  channelAdminWorkOrdersPMTasks:
    '/l/channel/:id/admin/work-orders/preventive-maintenance?tab=tasks',
  channelAdminWorkOrdersPMTaskSettings:
    '/l/channel/:id/admin/work-orders/preventive-maintenance/settings',
  channelStaffWorkOrdersPMTasks:
    '/l/channel/:id/admin/work-orders/preventive-maintenance?tab=assignedToMe',
  channelAdminWorkOrdersPMScheduleCreate:
    '/l/channel/:id/admin/work-orders/preventive-maintenance/schedules/new',
  channelAdminWorkOrdersPMScheduleDetails:
    '/l/channel/:id/admin/work-orders/preventive-maintenance/schedules/:scheduleId',
  channelAdminWorkOrdersPMScheduleEdit:
    '/l/channel/:id/admin/work-orders/preventive-maintenance/schedules/:scheduleId/edit',
  channelAdminWorkOrdersPMTaskDetails:
    '/l/channel/:id/admin/work-orders/preventive-maintenance/tasks/:taskId/details',

  channelAdminHardwareDevices: `/l/channel/:id/admin/${hardwareManagementRoutes.hardwareDevices}`,
  channelAdminHardwareNew: `/l/channel/:id/admin/${hardwareManagementRoutes.hardwareAdd}`,
  channelAdminHardwareDetails: `/l/channel/:id/admin/${hardwareManagementRoutes.hardwareDetails}`,
  channelAdminHardwareAccessLogs: `/l/channel/:id/admin/${hardwareManagementRoutes.hardwareAccessLogs}`,

  channelVMPinCodes: `/l/channel/:id/admin/${hardwareManagementRoutes.hardwarePinCodes}`,
  channelVMAddPinCodes: `/l/channel/:id/admin/${hardwareManagementRoutes.hardwareAddPinCodes}`,

  channelAdminReservableManagement:
    '/l/channel/:id/admin/reservable-management',
  channelAdminAudiences: '/l/channel/:id/admin/audiences',
  channelAdminAudiencesNew: '/l/channel/:id/admin/audiences/new',
  channelAdminAudiencesDetails: '/l/channel/:id/admin/audiences/:audienceId',
  channelAdminUserAccess: '/l/channel/:id/admin/access-control/user-access',
  channelAdminAccessControlGroups:
    '/l/channel/:id/admin/access-control/access-control-groups',
  channelAdminManageUserAccess:
    '/l/channel/:id/admin/access-control/user-access/user/:userId',
  channelAdminCreateCategoryForm:
    '/l/channel/:id/admin/products-services/create-category',
  channelAdminCreateChargeCodeForm:
    '/l/channel/:id/admin/products-services/create-charge-code',
  channelAdminCreateChargeForm: '/l/channel/:id/admin/charges/create-charge',
  channelAdminCreateExceptionForm:
    '/l/channel/:id/admin/products-services/create-exception',
  channelAdminCreateProductForm:
    '/l/channel/:id/admin/products-services/create-product',
  channelAdminEditProductForm:
    '/l/channel/:id/admin/products-services/edit-product/:productId',
  channelAdminEditExceptionForm:
    '/l/channel/:id/admin/products-services/edit-exception/:exceptionId',
  channelAdminProductsServices: '/l/channel/:id/admin/products-services',
  channelAdminCreditBundles: '/l/channel/:id/admin/credit-bundles',
  channelAdminCreateCreditBundles:
    '/l/channel/:id/admin/credit-bundles/create-credit-bundle',
  channelAdminAccessControlGroupDetails:
    '/l/channel/:id/admin/access-control/access-control-groups/:acgId',
  channelAdminMesagingAndMarketplace:
    '/l/channel/:id/admin/messaging-and-marketplace',
  saml2: '/sso/saml2',
  auth0Saml: '/sso/auth0/saml',
  oauth2: '/sso/oauth2/:provider',
  oauth2AccessToken: '/sso/oauth2-access-token',
  restricted: '/l/restricted',
  restrictedAddChannel: '/l/restricted/addChannel',
  restrictedLogin: '/l/restricted/login',
  magicLink: '/l/magicLink/:verify/:guid',
  authTriage: '/l/authTriage',
  magicLinkSent: '/l/magicLinkSent',
  shopifyDisableUser: '/api/v5/shopify/user/disable/:email',
  shopifyUpsertUser: '/api/v5/shopify/user/upsert',
  downgradePaymentAccountUpsert: '/api/v5/downgrade-paymentAccount',
  exportGroupRoleMembers:
    '/admin/channel/:channelId/groupRole/:groupRoleId/exportMembers',
  exportChannelUsers: '/admin/channel/:channelId/exportUsers',
  visitorManagementVisitorLog: `/l/channel/:id/admin/${visitorManagementRoutes.visitorLog}`,
  visitorManagementVisitorPassDetails: `/l/channel/:id/admin/${visitorManagementRoutes.visitorPassDetails}`,
  visitorManagementRegistrationForms: `/l/channel/:id/admin/${visitorManagementRoutes.registrationForm}`,
  reservableBookingsCSVExport:
    '/api/v5/admin/channel/:channelId/reservable-bookings/export',
  channelAdminUnitsListView: '/l/channel/:id/admin/units',
  channelAdminUnitsNew: '/l/channel/:id/admin/units/new',
  channelAdminUnitDetails: '/l/channel/:id/admin/units/:unitId',
  channelAdminFloorsListView: '/l/channel/:id/admin/floors',
  channelAdminFloorsNew: '/l/channel/:id/admin/floors/new',
  channelAdminFloorDetails: '/l/channel/:id/admin/floors/:floorId',
  // TODO: Remove this route and replace with channelAdminUsersList
  channelAdminUsers: '/l/channel/:id/admin/users',
  channelAdminUsersList: `/l/channel/:id/admin/${userManagementRoutes.UsersList}`,
  channelAdminUsersListPendingInviteTab: `/l/channel/:id/admin/${userManagementRoutes.UsersList}?tab=pending-invites`,
  channelAdminUsersInvite: `/l/channel/:id/admin/${userManagementRoutes.UsersInvite}`,
  channelAdminUsersAdd: `/l/channel/:id/admin/${userManagementRoutes.UsersAdd}`,
  channelAdminPermissionGroupsList: `/l/channel/:id/admin/${userManagementRoutes.PermissionGroupsList}`,
  channelAdminPermissionGroupView: `/l/channel/:id/admin/${userManagementRoutes.PermissionGroupView}`,
  channelAdminPermissionGroupEdit: `/l/channel/:id/admin/${userManagementRoutes.PermissionGroupEdit}`,
  channelAdminPermissionGroupCreate: `/l/channel/:id/admin/${userManagementRoutes.PermissionGroupCreate}`,
  channelAdminTeamsCreate: `/l/channel/:id/admin/${userManagementRoutes.TeamCreate}`,
  channelAdminTeamsList: `/l/channel/:id/admin/${userManagementRoutes.TeamsList}`,
  channelAdminTeamView: `/l/channel/:id/admin/${userManagementRoutes.TeamView}`,
  channelAdminTeamEdit: `/l/channel/:id/admin/${userManagementRoutes.TeamEdit}`,
  v5IntenalRoutes: '/api/v5/internal',
  // auth0 related routes
  identifier: '/identifier',
  onboarding: '/onboarding',
  languageSettings: '/l/settings/language',
  signup: '/signup',
  channelAdminRelayDetails: `/l/channel/:id/admin/${hardwareManagementRoutes.relayDetails}`,
  visitorSmsLink: '/visit/:submissionId?visitor=visitorId',
} as const;
