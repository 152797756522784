import gql from 'graphql-tag';

export default gql(`
  fragment ChannelFragment on Channel {
    _id
    _created
    _updated
    name
    description
    slug
    type
    isPublic
    isDiscoverable
    isLive
    isSub
    inviteOnly
    experienceType
    settings {
      channelLanguages
      multiLanguageEnabled
      enabledLanguagesForMembers
    }
    address {
      ...AddressFragment
    }
    profile {
      ...ProfileFragment
    }
    parent {
      _id
    }
    groupRoles {
      _id
      name
    }
    stats {
      sf
      subscribers
    }
  }
`);
