import { AnalyticsInterface } from '../../types/Analytics';
import {
  EVENT_JOINED_CHANNEL,
  EVENT_SWITCHED_CHANNEL,
} from '../constants/events';

type EmitChannelType = {
  userId: string;
  channelId: string;
  channelName: string;
  analytics: AnalyticsInterface;
};

export function emitChannelJoined({
  userId,
  channelId,
  channelName,
  analytics,
}: EmitChannelType) {
  analytics.track(EVENT_JOINED_CHANNEL, { userId, channelId, channelName });
}

export function emitChannelSwitched({
  userId,
  channelId,
  channelName,
  analytics,
}: EmitChannelType) {
  analytics.track(EVENT_SWITCHED_CHANNEL, { userId, channelId, channelName });
}
