/* istanbul ignore file */
import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class ShopifyMultipassNoVerifiedEmailError extends CodedError {
  static get errorName() {
    return errorCodes.shopifyMultipassNoVerifiedEmailError.code;
  }

  constructor(
    message = errorCodes.shopifyMultipassNoVerifiedEmailError.message
  ) {
    super(message);
    this.code = errorCodes.shopifyMultipassNoVerifiedEmailError.code;
    this.message = `${message} ${errorCodes.shopifyMultipassNoVerifiedEmailError.code}`;
    this.name = errorCodes.shopifyMultipassNoVerifiedEmailError.name;
  }
}
