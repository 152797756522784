import React from 'react';
import { Modal } from 'components/lds';
import styles from './ConfirmDeleteWorkflowModal.scss';
import { M, Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';

type Props = {
  isModalOpen: boolean;
  setModalClose: (value: boolean) => void;
  onConfirm: () => void;
};

export const ConfirmDeleteWorkflowModal = ({
  isModalOpen,
  setModalClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation();

  const TRANSLATION_KEYS = {
    title:
      'web.admin.channel.content.workflow.editor.v2.workflow.delete.modal.title',
    message:
      'web.admin.channel.content.workflow.editor.v2.workflow.delete.modal.message',
    confirm:
      'web.admin.channel.content.workflow.editor.v2.workflow.delete.modal.confirm',
    cancel:
      'web.admin.channel.content.workflow.editor.v2.workflow.delete.modal.cancel',
  };

  return (
    <Modal
      testId="deleteWorkflowConfirmModal"
      className={styles.ConfirmDeleteWorkflowModal}
      isOpen={isModalOpen}
      onClose={() => setModalClose(true)}
      isCloseButtonHidden
      includeHeader={false}
    >
      <M className={styles.title}>{t(TRANSLATION_KEYS.title)}</M>

      <M className={styles.confirmMessage}>{t(TRANSLATION_KEYS.message)}</M>

      <div className={styles.buttons}>
        <Button
          variant="secondary"
          size="large"
          className={styles.confirmButton}
          onClick={onConfirm}
          testId="deleteWorkflowConfirmButton"
        >
          {t(TRANSLATION_KEYS.confirm)}
        </Button>
        <Button
          testId="deleteWorkflowCancelButton"
          variant="secondary"
          size="large"
          className={styles.cancelButton}
          onClick={() => setModalClose(false)}
        >
          {t(TRANSLATION_KEYS.cancel)}
        </Button>
      </div>
    </Modal>
  );
};
