import { IStore } from './types';

let store: IStore | null = null;

if (typeof window !== 'undefined') {
  store = window.sessionStorage as IStore;
}

if (global?.sessionStorage) {
  store = global.sessionStorage as IStore;
}

/*
 * Wrapper for managing the temporary storage
 *
 * * */
class Store implements IStore {
  public getItem<T>(key: string): T | null {
    return store?.getItem(key) ?? null;
  }

  public setItem(key: string, value: any): void {
    return store?.setItem(key, value);
  }

  public removeItem(key: string): void {
    return store?.removeItem(key);
  }
}

export default new Store();
