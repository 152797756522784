import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { FriendlyType } from 'lane-shared/helpers/properties/friendlyTypeOptions';
import { ContentWorkflowType } from 'lane-shared/types/ContentWorkflowType';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import {
  PropertiesInterface,
  PropertyType,
} from 'lane-shared/types/properties/Property';
import { TypeContextEnum } from 'lane-shared/types/properties/TypeContextEnum';
import { PropertyDependency } from 'lane-shared/types/properties/propertyInterfaceOptions/propertyDependency';

import FieldDependencyEdit from 'components/builder/properties/creation/FieldDependencyEdit';

import Toggle from '../../../../form/Toggle';
import Alert from '../../../../general/Alert';
import Label from '../../../../general/Label';
import PropertyComponent from '../../input/Property';
import FriendlyPropertyFiltersCreate from './FriendlyPropertyFiltersCreate';
import FriendlyPropertyGeneralCreate from './FriendlyPropertyGeneralCreate';
import FriendlyPropertyOptionsCreate from './FriendlyPropertyOptionsCreate';
import FriendlyPropertySecurityCreate from './FriendlyPropertySecurityCreate';
import FriendlyPropertyValidationCreate from './FriendlyPropertyValidationCreate';
import { FRIENDLY_PROPERTY_TEXTS } from './constants';
import useFriendlyPropertyTypes from './hooks/useFriendlyPropertyTypes';

import styles from './FriendlyPropertyCreate.scss';

export type FriendlyPropertyCreateProps = {
  className?: string;
  style?: React.CSSProperties;
  user?: { _id: LaneType.UUID; isSuperUser: boolean };
  contexts: TypeContextEnum[];
  forCreate: boolean;
  property: PropertyType;
  showDependency?: boolean;
  showSecurity?: boolean;
  showValidation?: boolean;
  onPropertyUpdated: (
    property: Partial<PropertyType>,
    resetField?: boolean
  ) => void;
  properties?: PropertiesInterface;
  propertyDependency?: PropertyDependency;
  onPropertyDependencyUpdated: (dependency: PropertyDependency) => void;
  onContentUpdated: (content: object) => void;
  content?: { _id: LaneType.UUID; actions?: ContentWorkflowType[] };
};

export default function FriendlyPropertyCreate({
  className,
  style,
  user,
  contexts,
  forCreate,
  property,
  showSecurity = true,
  showDependency = true,
  showValidation = true,
  onPropertyUpdated,
  properties,
  propertyDependency,
  onPropertyDependencyUpdated,
  onContentUpdated,
  content,
}: FriendlyPropertyCreateProps) {
  const { t } = useTranslation();

  const [friendlyType, setFriendlyType] = useState<FriendlyType | null>(null);
  const [example, setExample] = useState<any>(null);

  const {
    availableFriendlyTypes,
    allPackagedTypes,
    optionTypes,
    placeholderTypes,
    minMaxValidatorTypes,
    arrayMinMaxValidatorTypes,
    multiSelectTypes,
    filterTypes,
  } = useFriendlyPropertyTypes({ user, contexts });

  useEffect(() => {
    if (!forCreate && property) {
      if (
        property.packagedType &&
        property.packagedType !== PackagedTypeEnum.None
      ) {
        setFriendlyType(property.packagedType);
      } else {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setFriendlyType(property.type);
      }
    }
  }, [forCreate, property?.type, property?.packagedType]);

  if (!friendlyType) {
    return null;
  }

  const hasPlaceholder =
    !allPackagedTypes.includes(friendlyType) &&
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    placeholderTypes.includes(property.type);

  const hasOptions = optionTypes.includes(friendlyType);
  const hasFilters = filterTypes.includes(friendlyType);
  const hasMultiSelect = multiSelectTypes.includes(friendlyType);

  const hasMinMaxValidators = minMaxValidatorTypes.includes(friendlyType);

  const hasArrayMinMaxValidators = arrayMinMaxValidatorTypes.includes(
    friendlyType
  );
  const isSelectUser = friendlyType === 'SelectUser';
  const isRequired =
    property?.validators?.find((i: any) => i.name === 'Required')?.value ===
    true;

  return (
    <div>
      {property.alertText && (
        <Alert color="danger">{t(property.alertText)}</Alert>
      )}
      <div
        className={cx(styles.FriendlyPropertyCreate, className)}
        style={style}
      >
        <div className={styles.entry}>
          <FriendlyPropertyGeneralCreate
            setExample={setExample}
            hasPlaceholder={hasPlaceholder}
            availableFriendlyTypes={availableFriendlyTypes}
            friendlyType={friendlyType}
            property={property}
            onPropertyUpdated={onPropertyUpdated}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'number |... Remove this comment to see the full error message
            isSelectUser={isSelectUser}
          />
          {hasOptions && (
            <FriendlyPropertyOptionsCreate
              property={property}
              onPropertyUpdated={onPropertyUpdated}
              onContentUpdated={onContentUpdated}
              content={content}
            />
          )}
          {showValidation && (
            <FriendlyPropertyValidationCreate
              property={property}
              onPropertyUpdated={onPropertyUpdated}
              hasMinMaxValidators={hasMinMaxValidators}
              hasArrayMinMaxValidators={hasArrayMinMaxValidators}
            />
          )}
          {hasMultiSelect && (
            <>
              <Label
                h2
                TooltipComponent={t(
                  FRIENDLY_PROPERTY_TEXTS.tooltip.multiSelect
                )}
              >
                {t(FRIENDLY_PROPERTY_TEXTS.label.multiSelect)}
              </Label>
              <Toggle
                value={property.isMultiSelect}
                onChange={(value: boolean) =>
                  onPropertyUpdated({ isMultiSelect: value })
                }
                testId="validator-required-toggle"
              />
            </>
          )}
          {hasFilters && property.filters && (
            <FriendlyPropertyFiltersCreate
              onPropertyUpdated={onPropertyUpdated}
              property={property}
            />
          )}
          {showDependency && properties !== undefined && (
            <>
              <hr />
              <Label
                h1
                TooltipComponent={t(FRIENDLY_PROPERTY_TEXTS.tooltip.dependency)}
              >
                {t(FRIENDLY_PROPERTY_TEXTS.label.dependency)}
              </Label>
              <FieldDependencyEdit
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                fieldId={property.name}
                properties={properties}
                propertyDependency={propertyDependency}
                onPropertyDependencyUpdated={onPropertyDependencyUpdated}
              />
            </>
          )}
          {showSecurity && (
            <>
              <hr />

              <FriendlyPropertySecurityCreate
                onPropertyUpdated={onPropertyUpdated}
                property={property}
              />
            </>
          )}
        </div>
        <div className={styles.preview} data-test="preview-container">
          <fieldset className={styles.field}>
            <Label
              h1
              TooltipComponent={t(FRIENDLY_PROPERTY_TEXTS.tooltip.preview)}
            >
              {t(FRIENDLY_PROPERTY_TEXTS.label.preview)}
            </Label>
          </fieldset>
          {!property.hidePropertyLabel ? (
            <Label>
              {property.friendlyName || property.name}
              {isRequired && (
                <span className="text-text-critical text-md align-top ml-0.25">
                  *
                </span>
              )}
            </Label>
          ) : null}
          {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ value: any; property: PropertyType<Propert... Remove this comment to see the full error message */}
          <PropertyComponent
            value={example}
            property={property}
            onPropertyChange={() => null}
            onChange={value => setExample(value)}
          />
        </div>
      </div>
    </div>
  );
}
