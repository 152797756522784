import { gql } from 'graphql-query-contracts';

export const getContentWaitlistEnrollments = gql(/* GraphQL */ `
  query getContentWaitlistEnrollments($userId: UUID!, $contentId: UUID!) {
    contentWaitlistEnrollments(userId: $userId, contentId: $contentId) {
      id
      startDate
      endDate
    }
  }
`);
