import gql from 'graphql-tag';

export const getCompaniesByChannelId = gql`
    query getCompaniesByChannelId(
        $channelId: UUID!
    ) {
        companiesByChannelId(
            channelId: $channelId
        ) {
            _id
            name
        }
    } 
`;