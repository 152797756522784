import gql from 'graphql-tag';

export default gql`
  query contentFeaturePaymentQuote(
    $id: UUID!
    $userId: UUID
    $companyId: UUID
    $paymentType: PaymentType
    $quantity: Int! = 1
    $interactionTimes: DateRangeInput
  ) {
    contentFeaturePaymentQuote(
      _id: $id
      userId: $userId
      companyId: $companyId
      paymentType: $paymentType
      quantity: $quantity
      interactionTimes: $interactionTimes
    ) {
      _id
      type
      data
      items {
        name
        amount
      }
      taxes {
        name
        amount
        rate
      }
      total
      overriddenAmount
      tax
      currency
      minQuantity
      maxQuantity
      cashTotal
      creditTotal
      taxTotal
      products {
        id
        name
        description
        groupId
        amount
        creditAmount
        markup {
          value
          markupAmountType
        }
        tax {
          value
          taxAmountType
        }
        productCategory {
          productCategoryId
        }
      }
      quantity
      subscriptionDetails {
        subscriptionDetailsId
        recurrenceInterval
        totalCounts
        endDate
        anyoneCanCancel
      }
    }
  }
`;
