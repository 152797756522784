import gql from 'graphql-tag';

export default gql`
  mutation createMedia($libraryItem: LibraryItemInput!) {
    createMedia(libraryItem: $libraryItem) {
      libraryItem {
        _id
        media {
          _id
          name
          description
          file
          fileSignedUrl
          thumbnail
          thumbnailSignedUrl
          contentType
        }
      }
      originalExtension
    }
  }
`;
