import { CodedError } from '../../CodedError';
import { ErrorCodeType, errorCodes } from '../../constants/errorCodes';

/**
 * This class accepts the following error codes:
 * gallagherInvalidFieldError (default)
 * gallagherInvalidClientCertificateError
 * gallagherInvalidCertificatePassphraseError
 * gallagherClientCertificateValidationError
 */

export class GallagherInvalidFieldError extends CodedError {
  static get errorName() {
    return this.name;
  }

  constructor(error: ErrorCodeType = errorCodes.gallagherInvalidFieldError) {
    super(error.message);
    this.code = error.code;
    this.message = `${error.message} ${error.code}`;
    this.name = error.name;
  }
}
