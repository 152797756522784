import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

export const secondaryColors = {
  background: tokens.color.background.primary,
  primaryColor: tokens.color.text.brand,
  onPressControlBackgroundColor: tokens.color.background.primary,
  onPressControlTextColor: tokens.color.text.brand,
  hover: tokens.color.background.primary,
  hoverText: tokens.color.text.brand,
  borderColor: tokens.color.border.brand,
  dropdownColor: tokens.color.text.brand,
  dropdownColorOpen: tokens.color.text.brand,
};
