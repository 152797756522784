import gql from 'graphql-tag';

import { PublicUser } from 'graphql-resolver-contracts';
import { GroupRole } from 'lane-shared/types/GroupRole';

export type ReformedChannelResponse = {
  reformedChannel: {
    id: string;
    channelInvites: {
      _id: string;
      name: string;
      email: string;
      result: JSON;
      _createdBy: PublicUser;
      _updatedBy: PublicUser;
      groupRole: GroupRole;
      groupRoles: GroupRole[];
      _created: Date;
      status: string;
      _updated: Date;
      expires: Date;
      sent: Date;
      company?: {
        name: string;
        profile?: {
          name: string;
        };
      };
    }[];
  };
};

export const queryReformedChannel = gql`
  query ReformedChannel($channelId: UUID!) {
    reformedChannel(channelId: $channelId) {
      id
      channelInvites {
        _id
        name
        email
        sent
        result
        _createdBy {
          _created
          _id
          _updated
          lastSeen
          name
          online
          profile {
            _id
            _updated
            name
          }
        }
        _updatedBy {
          _created
          _id
          _updated
          lastSeen
          name
          online
          profile {
            _id
            _updated
            name
          }
        }
        groupRole {
          _id
          name
        }
        groupRoles {
          _id
          name
        }
        _created
        status
        _updated
        expires
        company {
          name
          profile {
            name
          }
        }
      }
    }
  }
`;
