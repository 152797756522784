import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class EmailAlreadyInUseError extends CodedError {
  static get errorName() {
    return errorCodes.emailAlreadyInUseError.code;
  }

  constructor(message = errorCodes.emailAlreadyInUseError.message) {
    super(message);
    this.code = errorCodes.emailAlreadyInUseError.code;
    this.message = `${message} ${errorCodes.emailAlreadyInUseError.code}`;
    this.name = errorCodes.emailAlreadyInUseError.name;
  }
}
