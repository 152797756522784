import gql from 'graphql-tag';

export default gql`
  query RelatedSpace($contentId: UUID!) {
    content(_id: $contentId) {
      _id
      name
      description
      backgroundImage
      channel {
        _id
        slug
      }
      features {
        _id
        type
        feature
      }
    }
  }
`;
