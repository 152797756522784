import { createContext } from 'react';

import { PlatformEnum } from '../types/PlatformEnum';
import { LinkType } from '../types/baseTypes/LinkType';
import {
  BlockRenderInterface,
  BlockType,
} from '../types/blocks/BlockInterface';

export type RendererContextType = {
  primitives: any;
  blocks: {
    [key: string]: BlockRenderInterface;
  };
  blockArray: BlockRenderInterface[];
  availableBlocks: BlockRenderInterface[];
  tags: string[];
  linkHandler: ({ value }: { value: LinkType }) => Promise<void>;
  registerBlocks: (blockDefinitions: BlockType[]) => void;
  registerBlock: (blockDefinition: BlockType) => BlockRenderInterface;
  constructBaseBlock: () => any;
  findBlock: (name: string) => BlockRenderInterface | undefined;
  platform: PlatformEnum;
};

const RendererContext = createContext({} as RendererContextType);

export default RendererContext;
