import gql from 'graphql-tag';

/**
 * GQL Get Preventive Maintenance Schedule Query
 */
export default gql`
  query getTaskByIdQuery($id: String!) {
    task(id: $id) {
      id
      groupBy {
        name
        id
      }
      dueDate
      repeats {
        label
        value
      }
      estimateTimeToComplete
      assignee {
        _id
        name
      }
      assigneeGroup {
        _id
        name
      }
      notes
      steps
      meterReading
      createdBy
      updatedBy
      completedBy
      equipment {
        id
        location
        name
        category
        floor
        suite
      }
      attachments
      createdAt
      updatedAt
      completedAt
      isArchived
      location
      actualTimeToComplete
      completeNotes
      status
      nextStatuses
      schedule {
        id
        title
      }
      friendlyID
      scheduleNotes
      hours
      minutes
      userFriendlyID
      stepExecution {
        id
        taskId
        name
        type
        order
        isComplete
        response
        meterReadingId
        options {
          choices
          description
          subtype
          unit
        }
      }
    }
  }
`;
