import gql from 'graphql-tag';

export default gql`
  query getContentFeaturePaymentSummary(
    $id: UUID!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    contentFeaturePaymentSummary(
      _id: $id
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
