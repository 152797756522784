import { hasPermission } from '../../../helpers';
import {
  PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE,
  PERMISSION_BILLING_PAYMENTS_CHARGE_VOID,
} from '../../../helpers/constants/permissions';
import { useUserDataContext, useChannelsContext } from '../../../hooks';

export const useBillingPaymentsPermissions = () => {
  const { user } = useUserDataContext();
  const { primaryChannel } = useChannelsContext();

  const hasPermissionOrSuper = (permission: string) =>
    user?.isSuperUser ||
    hasPermission(user?.roles, [permission], primaryChannel?._id);

  return {
    canVoidCharges: hasPermissionOrSuper(
      PERMISSION_BILLING_PAYMENTS_CHARGE_VOID
    ),
    canSubmitCharges: hasPermissionOrSuper(
      PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE
    ),
  };
};
