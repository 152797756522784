import React, { useState } from 'react';

import cx from 'classnames';
import { useScrollToSelectedChild } from '../../../hooks/useScrollToSelectedChild';
import { DateTime, Interval } from 'luxon';

import { SHORT_MONTH } from 'lane-shared/helpers/constants/dates';
import { parseDateTime } from 'lane-shared/helpers/dates';
import { TimeUnitEnum } from 'lane-shared/types/TimeUnitEnum';

import { DatePickerRow } from '../DatePickerRow';
import { MonthCell } from './MonthCell';

import styles from './MonthCalendar.scss';

const MIN_DATE = DateTime.local().set({ year: 2014 }).startOf('year');
const MAX_DATE = DateTime.local().plus({ years: 2 }).endOf('year');

type MonthCalendarProps = {
  // callback for date has changed
  onChange?: (date: Date) => void;
  // start date
  startDate?: string | Date | null;
  // end date
  endDate?: string | Date | null;
  // max date allowed
  maxDate?: Date;
  // min date allowed
  minDate?: Date;
  timeUnit?: TimeUnitEnum.Day;
  // limits the range available to be selected, in months
  rangeLimit?: number | null;
  className?: string;
  style?: React.CSSProperties;
};

export const MonthCalendar = ({
  className,
  style,
  startDate,
  endDate,
  rangeLimit,
  timeUnit,
  onChange = () => null,
  maxDate = MAX_DATE.toJSDate(),
  minDate = MIN_DATE.toJSDate(),
}: MonthCalendarProps) => {
  const [currentMonth, setCurrentMonth] = useState(DateTime.local());
  const startDateTime = parseDateTime(startDate);
  const endDateTime = parseDateTime(endDate);
  const minDateTime = parseDateTime(minDate) || MIN_DATE;
  const maxDateTime = parseDateTime(maxDate) || MAX_DATE;

  const month = startDateTime || currentMonth;
  const [elementRef] = useScrollToSelectedChild(month.toISO());

  function enforceDate(newDate: any) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateTime | null' is not assignable to type '... Remove this comment to see the full error message
    const _newDate: DateTime = parseDateTime(newDate);
    if (
      (maxDateTime && _newDate > maxDateTime) ||
      (minDateTime && _newDate < minDateTime)
    ) {
      return false;
    }

    setCurrentMonth(_newDate);
    return true;
  }

  function enforceRangeLimit(newDate: DateTime) {
    if (!rangeLimit || !startDateTime) {
      return true;
    }

    return (
      Interval.fromDateTimes(newDate, startDateTime).count('months') <
      rangeLimit
    );
  }

  function renderMonthCells() {
    const yearStart = currentMonth.startOf('year');
    const yearEnd = yearStart.endOf('year');
    const months: React.ReactNode[] = [];

    let m: DateTime = yearStart;

    while (m <= yearEnd) {
      const formattedDate = m.toFormat(SHORT_MONTH);

      months.push(
        <MonthCell
          key={m.toISO()}
          className={styles.monthCell}
          startDate={startDateTime}
          endDate={endDateTime}
          month={m}
          onClick={date => {
            if (enforceDate(date) && enforceRangeLimit(date)) {
              onChange(date.toJSDate());
            }
          }}
          text={formattedDate}
        />
      );

      m = m.plus({ month: 1 });
    }

    return months;
  }

  return (
    <div className={cx(styles.MonthCalendar, className)} style={style}>
      <DatePickerRow
        value={currentMonth.toJSDate()}
        maxDate={maxDate}
        minDate={minDate}
        quickTimeUnit="month"
        showDays={false}
        onChange={enforceDate}
        showMonths={!!timeUnit}
        hideMonthChevron
      />
      <div className={styles.calendarWrapper} ref={elementRef}>
        {renderMonthCells()}
      </div>
    </div>
  );
};
