import gql from 'graphql-tag';

export default gql`
  query getBuildingChannelIntegration($channelId: UUID!) {
    getBuildingChannelIntegration(channelId: $channelId) {
      channelId
      channelName
      integration {
        id
        channelId
      }
      connectedIntegrations {
        id
        channelId
      }
    }
  }
`;
