import gql from 'graphql-tag';

export const getIntegrationProcesses = gql`
  query getIntegrationProcesses($input: GetIntegrationProcessesInput!) {
    getIntegrationProcesses(input: $input) {
      processes {
        id
        processGroupId
        vtsIntegrationId
        metadata
        createdAt
        startedAt
        succeededAt
        failedAt
        totalCreatedRecords
        totalUpdatedRecords
        totalFailedRecords
      }
    }
  }
`;
