import React, { useState } from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { pause } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { getFriendlyBytes } from 'lane-shared/helpers/files';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import useMediaLibrary, {
  SORT_ASC,
  SORT_DESC,
  SORT_BY,
  MediaImageType,
  MediaType,
} from 'lane-shared/hooks/useMediaLibrary';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import type { ImageMediaOptions } from 'lane-shared/types/media';

import Dropdown from 'components/form/Dropdown';
import FileDrop from 'components/form/FileDrop';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import IconButton from 'components/general/IconButton';
import Loading from 'components/general/Loading';
import Pagination from 'components/general/Pagination';
import LibraryPathCreateModal from 'components/lane/LibraryPathCreateModal';
import PathListItem from 'components/lane/PathListItem';
import PathSelector from 'components/navigation/PathSelector';

import SearchBar from '../general/SearchBar';
import MediaLibraryAddMedia from './MediaLibraryAddMedia';
import MediaListItem, { MediaListItemType } from './MediaListItem';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useMediaUpload from 'hooks/useMediaUpload';

import styles from './MediaLibrary.scss';

const views = ['list', 'th-large', 'th'];
const [VIEW_LIST, VIEW_GRID, VIEW_APPS] = views;

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  onMediaSelected?: (media: DocumentType | null | undefined) => void;
  onMediaDoubleClicked: (media: MediaType, libraryItemId: string) => void;
  library: LibraryType | null;
  libraries?: LibraryType[] | null;
  storageKey?: string;
  newImageMaxWidth?: number;
  newImageMaxHeight?: number;
};

type ItemType = {
  _id: string;
  media: DocumentType;
  path?: string;
  tags?: string[];
};

type Props = OwnProps;

export default function MediaLibrary({
  libraries = null,
  library = null,
  className,
  style,
  onMediaSelected = () => null,
  onMediaDoubleClicked = () => null,
  storageKey,
  newImageMaxWidth,
  newImageMaxHeight,
}: Props) {
  const mediaOptions: ImageMediaOptions = {
    maxHeight: newImageMaxHeight,
    maxWidth: newImageMaxWidth,
  };
  const { t } = useTranslation();
  const {
    items,
    paths,
    availablePaths,
    pageInfo,
    loading,
    search,
    updateSearch,
    onChangeLibrary,
    selectedLibrary,
    availableLibraries,
    refetchMedia,
    getLibraryInfo,
  } = useMediaLibrary({ libraries, library, storageKey });

  const { filesSelectedHandler } = useMediaUpload({
    selectedLibrary,
    onMediaCreated,
    mediaOptions,
    tags: search.tags,
    path: search.path,
  });

  const [selectedItem, setSelectedItem] = useState<ItemType | null>(null);
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([]);
  const [isAddingPath, setIsAddingPath] = useState(false);
  const [atPath, setAtPath] = useState<string | null>(null);
  const [view, setView] = useState(VIEW_GRID);
  const [isMoving, setIsMoving] = useState(false);

  async function onMediaCreated() {
    await pause();
    refetchMedia();
  }

  function onItemClicked(item: any) {
    setSelectedItems([]);

    // media is already selected, unselect
    if (item?._id === selectedItem?._id) {
      setSelectedItem(null);
      onMediaSelected(null);
      return;
    }

    setSelectedItem(item);
    onMediaSelected(item.media);
  }

  function onItemMultiSelect(item: any) {
    setSelectedItem(null);
    setSelectedItems(prevState =>
      prevState.some(({ _id }) => item._id === _id)
        ? prevState.filter(({ _id }) => item._id !== _id)
        : [...prevState, item]
    );
  }

  function itemDragStartHandler(e: React.DragEvent, media: MediaListItemType) {
    if (
      !(
        selectedItems.length > 0 ||
        (selectedItem && media._id === selectedItem.media._id)
      )
    ) {
      e.preventDefault();
      return false;
    }

    e.dataTransfer.setDragImage(new Image(), 0, 0);
    return true;
  }

  async function moveMedia(path: string) {
    if (!selectedItem && selectedItems.length === 0) {
      return;
    }

    setIsMoving(true);

    // move media or medias to the path
    // either one media is selected, or several are.  Use an array either way
    // to make the code more reusable.
    const toMove = selectedItem ? [selectedItem] : selectedItems;

    // map all the moves to one graphql statement
    const mutationInner = toMove
      .map(
        item =>
          `c${item._id}: updateLibraryItem(libraryItem: {_id: "${item._id}", path: "${path}"}) {
          _id
          path
        }
      `
      )
      .join('\n');

    const mutation = gql`
        mutation updateLibraryItems {
          ${mutationInner}
        }
    `;

    try {
      await getClient().mutate({
        mutation,
      });

      setSelectedItem(null);
      setSelectedItems([]);
      refetchMedia();
    } catch (err) {
      window.Toast.show(<ErrorMessage error={err} />);
    } finally {
      setIsMoving(false);
    }
  }

  function pathDropHandler(e: React.DragEvent, path: string) {
    if (!e.dataTransfer.getData('media')) {
      // some other drop event that we aren't interested in
      return;
    }

    moveMedia(path);
  }

  function pathCreatedHandler() {
    setIsAddingPath(false);
    getLibraryInfo();
  }

  if (!selectedLibrary) {
    return <Loading />;
  }

  return (
    <div className={cx(styles.MediaLibrary, className)} style={style}>
      <ControlMenu className={styles.menu}>
        <PathSelector
          rootPaths={availableLibraries}
          selectedRootPath={selectedLibrary}
          paths={paths}
          selectedPath={search.path}
          onDrop={pathDropHandler}
          onPathSelected={(path: any) => updateSearch({ path })}
          onRootPathSelected={rootPath => onChangeLibrary(rootPath._id)}
          onPathCreate={atPath => {
            setIsAddingPath(true);
            setAtPath(atPath);
          }}
        />

        <hr />

        <MediaLibraryAddMedia
          className={styles.addMedia}
          selectedLibrary={selectedLibrary}
          onMediaCreated={onMediaCreated}
          mediaOptions={mediaOptions}
          path={search.path}
          tags={search.tags}
        />
      </ControlMenu>
      <ControlMenu className={styles.menu}>
        <SearchBar
          className={styles.input}
          searchInputClassName={styles.inputWrapper}
          searchOptions={search}
          onSearchChange={search => updateSearch({ search, page: 0 })}
        />

        <Dropdown
          className={styles.sortBy}
          onValueChange={sortBy => updateSearch({ sortBy, page: 0 })}
          items={SORT_BY}
          value={search.sortBy}
        />

        <IconButton
          className={styles.iconButton}
          inverted
          icon={search.sortOrder === SORT_ASC ? 'chevron-down' : 'chevron-up'}
          onClick={() =>
            updateSearch({
              sortOrder: search.sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC,
              page: 0,
            })
          }
        />

        {views.map(v => (
          <IconButton
            className={styles.iconButton}
            key={v}
            icon={v}
            iconSet={ICON_SET_FONTAWESOME}
            selected={view === v}
            onClick={() => setView(v)}
            inverted
          />
        ))}
      </ControlMenu>

      <FileDrop
        className={styles.fileDrop}
        type={FileReturnTypeEnum.Image}
        onFilesSelected={filesSelectedHandler}
        enableMultiUpload
      >
        <div className={styles.resultsWrapper}>
          <ul className={styles.paths}>
            {availablePaths.map(path => (
              <PathListItem
                key={path}
                className={styles.listItem}
                path={path}
                onSelected={() => updateSearch({ path })}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: DragEvent<Element>, path: string) => voi... Remove this comment to see the full error message
                onDrop={pathDropHandler}
                small
              />
            ))}
          </ul>

          {[VIEW_APPS, VIEW_GRID].includes(view) && (
            <ul className={styles.results}>
              {items.map(item => (
                <MediaListItem
                  key={item._id}
                  small={view === VIEW_APPS}
                  className={styles.listItem}
                  isSelected={item._id === selectedItem?._id}
                  isMultiSelected={selectedItems.some(
                    ({ _id }) => _id === item._id
                  )}
                  onDragStart={itemDragStartHandler}
                  onSelected={() => onItemClicked(item)}
                  onDoubleClick={() =>
                    onMediaDoubleClicked(item.media as MediaType, item._id)
                  }
                  onMultiSelect={() => onItemMultiSelect(item)}
                  media={item.media as unknown as MediaListItemType}
                />
              ))}
            </ul>
          )}

          {view === VIEW_LIST && (
            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t('Created')}</th>
                    <th>{t('Last Update')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Media Type')}</th>
                    <th>{t('File Type')}</th>
                    <th>{t('Tags')}</th>
                    <th>{t('Size')}</th>
                    <th>{t('Dimensions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(item => {
                    const media = item.media as MediaImageType;
                    return (
                      <tr
                        key={item._id}
                        onClick={() => onItemClicked(item)}
                        onDoubleClick={() =>
                          onMediaDoubleClicked(media as MediaType, item._id)
                        }
                        data-is-selected={item._id === selectedItem?._id}
                      >
                        <td>{simpleDate(media._created)}</td>
                        <td>{fromNow(media._updated)}</td>
                        <td>{media.name}</td>
                        <td>{media.type}</td>
                        <td>{media.contentType}</td>
                        <td>{item.tags && item.tags.join(', ')}</td>
                        <td>
                          {media.file?.size &&
                            getFriendlyBytes(media.file.size)}
                        </td>
                        <td>
                          {media.file?.width &&
                            `${media.file.width}x${media.file.height}`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {isMoving && <Loading className={styles.movingLoader} />}
        </div>

        <Pagination
          loading={loading}
          total={pageInfo.total}
          perPage={search.perPage}
          page={search.page}
          onPage={page => updateSearch({ page })}
        />
      </FileDrop>
      <LibraryPathCreateModal
        isOpen={isAddingPath}
        atPath={atPath}
        selectedLibrary={selectedLibrary}
        onClose={() => setIsAddingPath(false)}
        onPathCreated={pathCreatedHandler}
        onCreateFailed={() => {}}
      />
    </div>
  );
}
