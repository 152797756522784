import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { LaneType } from 'common-types';
import { getDisplayName } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';
import {
  useChannelProfileQuery,
  useGroupRoleQuery,
  usePublicUserQuery,
  usePublicAudienceQuery,
} from 'lane-shared/hooks';

import Loading from '../general/Loading';
import ChannelCircleListView from './ChannelCircleListView';
import CircleListView from './CircleListView';

import styles from './ContentPlacementListItem.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channelId?: LaneType.UUID;
  groupRoleId?: LaneType.UUID;
  userId?: LaneType.UUID;
  audienceData?: { audienceId: LaneType.UUID; channelId: LaneType.UUID };
  onRemovePlacement?: (removedPlacement?: any) => void;
};

export default function ContentPlacementListItem({
  className,
  style,
  channelId,
  groupRoleId,
  userId,
  audienceData,
  onRemovePlacement,
}: Props) {
  const { channel } = useChannelProfileQuery({ channelId });
  const { groupRole } = useGroupRoleQuery({ groupRoleId });
  const { user } = usePublicUserQuery({ userId });
  const { audience } = usePublicAudienceQuery(audienceData || {});

  function renderRemove() {
    if (!onRemovePlacement) {
      return null;
    }

    return (
      <a
        data-test="removeButton"
        role="presentation"
        onClick={() => {
          onRemovePlacement(audience);
        }}
      >
        <Icon
          className={styles.remove}
          name="times-circle"
          set={ICON_SET_FONTAWESOME}
          testId="removeChannelTarget"
        />
      </a>
    );
  }

  return (
    <div
      className={cx(styles.ContentPlacementListItem, className)}
      style={style}
    >
      {channelId && (
        <>
          {channelId && !channel && <Loading />}
          {channel && <ChannelCircleListView channel={channel} />}
          {renderRemove()}
        </>
      )}
      {groupRoleId && (
        <>
          {groupRoleId && !groupRole && <Loading />}
          {groupRole && (
            <CircleListView
              image={imageUrl(groupRole.channel?.profile?.image)}
              logo={imageUrl(groupRole.channel?.profile?.logo)}
            >
              <h2>{groupRole.name}</h2>
              <p>{getDisplayName(groupRole.channel)}</p>
            </CircleListView>
          )}
          {renderRemove()}
        </>
      )}
      {userId && (
        <>
          {userId && !user && <Loading />}
          {user && (
            <CircleListView
              image={imageUrl(user.profile?.image)}
              logo={imageUrl(user.profile?.logo)}
            >
              <h2>{getDisplayName(user)}</h2>
              <p>{user.profile?.email}</p>
            </CircleListView>
          )}
          {renderRemove()}
        </>
      )}
      {!!audienceData && (
        <>
          {audienceData.audienceId && !audience && <Loading />}
          {audience?.name && (
            <CircleListView image="" logo="" name={audience?.name}>
              <h2>{audience?.name}</h2>
            </CircleListView>
          )}
          {renderRemove()}
        </>
      )}
    </div>
  );
}
