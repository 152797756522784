export enum VisitorFieldEnum {
  Required = 'required',
  Optional = 'optional',
  Hide = 'hide',
}

export enum ScheduleTypeEnum {
  SpecificDate = 'specific_date',
  CustomDates = 'custom_dates',
  DateRange = 'date_range',
  Repeat = 'repeat',
}

export enum IntervalTypeEnum {
  EveryDay = 'every_day',
  EveryWeekday = 'every_weekday',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum MonthIntervalTypeEnum {
  OnDate = 'on_date',
  FirstOfDay = 'first_of_day',
  SecondOfDay = 'second_of_day',
  ThirdOfDay = 'third_of_day',
  FourthOfDay = 'fourth_of_day',
  LastOfDay = 'last_of_day',
}

export enum VisitorMenuEnum {
  General = 'General',
  Scheduling = 'Scheduling Options',
}

export enum ScheduleInAdvanceEnum {
  One = '1 Month',
  Two = '2 Months',
  Three = '3 Months',
  Four = '4 Months',
  Five = '5 Months',
  Six = '6 Months',
  Seven = '7 Months',
  Eight = '8 Months',
  Nine = '9 Months',
  Ten = '10 Months',
  Eleven = '11 Months',
  Twelve = '12 Months',
}

export const repeatDropdownItems = [
  {
    label:
      'shared.content.feature.visitorManagement.form.repeatDropdown.everyday',
    value: IntervalTypeEnum.EveryDay,
  },
  {
    label:
      'shared.content.feature.visitorManagement.form.repeatDropdown.everyWeekday',
    value: IntervalTypeEnum.EveryWeekday,
  },
  {
    label:
      'shared.content.feature.visitorManagement.form.repeatDropdown.weekly',
    value: IntervalTypeEnum.Weekly,
  },
  {
    label:
      'shared.content.feature.visitorManagement.form.repeatDropdown.monthly',
    value: IntervalTypeEnum.Monthly,
  },
];
