export enum EmailTemplateType {
  INVITE = 'EMAIL_TEMPLATE_TYPE_INVITE',
}

export interface ActivateEmailTemplate {
  organizationId: string;
  templateType: string;
  logoId?: string;
  backgroundId?: string;
  entryInstructions?: string;
  policies?: string;
  qrEnabled?: boolean;
}
