export default function tryParseInt(
  value: string | undefined,
  radix: number = 10
): number | undefined {
  const number = Number.parseInt(value ?? '', radix);
  if (Number.isNaN(number)) {
    return undefined;
  }

  return number;
}
