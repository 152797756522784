import { PaymentCurrencyEnum } from '../../types/payment/PaymentCurrencyEnum';

/**
 * @deprecated use `PaymentCurrencyEnum` instead
 */
export const CURRENCY_USD = PaymentCurrencyEnum.USD;
/**
 * @deprecated use `PaymentCurrencyEnum` instead
 */
export const CURRENCY_CAD = PaymentCurrencyEnum.CAD;

export default {
  CURRENCY_USD,
  CURRENCY_CAD,
};
