import * as yup from 'yup';

export const validateStripeCardForm = yup.object().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  saveCard: yup.boolean().optional(),
});

export const validateBillingDetails = yup.object().shape({
  billingAddress: yup.string().required(),
  companyName: yup.string().trim().optional(),
  taxId: yup.string().trim().optional(),
  saveCard: yup.boolean().optional(),
});

export const validateBillingAddress = yup.object().shape({
  name: yup.string().required(),
  address: yup.object().required(),
});
