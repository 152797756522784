import gql from 'graphql-tag';

export default gql`
  mutation SignIn(
    $loginKey: ID!
    $password: String!
    $recaptchaToken: String
    $loginType: UserLoginType!
  ) {
    signIn(
      loginKey: $loginKey
      password: $password
      recaptchaToken: $recaptchaToken
      loginType: $loginType
    ) {
      token
      jti
    }
  }
`;
