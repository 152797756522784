import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { LaneType } from 'common-types';
import { imageUrl } from 'lane-shared/helpers/formatters';
import {
  MediaImageContentTypeEnum,
  MediaTypeEnum,
} from 'lane-shared/types/media';

import ContextMenu from 'components/general/ContextMenu';
import { H5 } from 'components/typography';

import makeFileDownload from 'helpers/makeFileDownload';
import { ModalPositionEnum } from 'hooks/useModalPosition';

import styles from './MediaListItem.scss';

export type MediaListItemType = {
  _id: LaneType.UUID;
  contentType: MediaImageContentTypeEnum;
  description: string;
  file: { size: number; type: MediaImageContentTypeEnum };
  name: string;
  tags: string[];
  thumbnail: {
    name: string;
    size: number;
    type: MediaImageContentTypeEnum;
    width: number;
    height: number;
  };
  type: MediaTypeEnum;
  _created: Date | string;
  _updated: Date | string;
};

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  media: MediaListItemType;
  small?: boolean;
  isSelected?: boolean;
  isMultiSelected?: boolean;
  onDragStart: (e: React.DragEvent, media: MediaListItemType) => void;
  onSelected: (media: MediaListItemType) => void;
  onMultiSelect: (media: MediaListItemType) => void;
  onDoubleClick: (media: MediaListItemType) => void;
};

type Props = OwnProps;

export default function MediaListItem({
  media,
  small = false,
  isSelected = false,
  isMultiSelected,
  onDragStart = () => null,
  onSelected = () => null,
  onMultiSelect = () => null,
  onDoubleClick = () => null,
  className,
  style,
}: Props) {
  function dragStartHandler(e: React.DragEvent) {
    e.dataTransfer.setData('media', JSON.stringify(media));
    onDragStart(e, media);
  }

  async function downloadFile() {
    const fileResponse = await fetch(`/api/v5/media/${media._id}`, {
      method: 'GET',
    });

    const blob = await fileResponse.blob();

    makeFileDownload({
      type: media.type,
      name: media.name,
      blob,
    });
  }

  return (
    <ContextMenu
      id="log-out-menu"
      clickAction="right"
      items={[
        <button className={styles.menuItem} onClick={downloadFile}>
          <Icon name="download" />
          <H5>Download</H5>
        </button>,
      ]}
      align={ModalPositionEnum.Right}
      autoFocus={false}
      autoClose
      hasOpaqueBackground={false}
    >
      <div
        className={cx(styles.MediaListItem, className)}
        data-selected={isSelected}
        data-multi-selected={isMultiSelected}
        data-is-small={small}
        style={style}
        role="button"
        draggable
        tabIndex={0}
        onDragStart={dragStartHandler}
        onKeyPress={e => {
          if (e.key === Key.Enter) {
            onSelected(media);
          }
        }}
        onClick={e => (e.shiftKey ? onMultiSelect(media) : onSelected(media))}
        onDoubleClick={() => onDoubleClick(media)}
      >
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${imageUrl(media, {
              type: 'thumbnail',
            })})`,
          }}
        />
        {!small && (
          <div className={styles.details}>
            <span>{media.name}</span>
          </div>
        )}
      </div>
    </ContextMenu>
  );
}
