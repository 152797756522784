import gql from 'graphql-tag';

import { LaneType } from 'common-types';
import { ChannelType } from 'lane-shared/types/ChannelType';

import {
  PublicUserFragment,
  ContentFragment,
  ProfileFragment,
  AddressFragment,
  SectionFragment,
} from '../fragments';

export type SwitchChannelResult = {
  me: {
    switchChannel: {
      primaryId: LaneType.UUID;
      secondaryId: LaneType.UUID | null;
      focusOnChannels: ChannelType[];
    };
  };
};

export default gql`
  ${PublicUserFragment}
  ${ContentFragment}
  ${ProfileFragment}
  ${AddressFragment}
  ${SectionFragment}

  query switchChannel($channelId: UUID!, $search: ChannelSearch) {
    me {
      switchChannel(channelId: $channelId, search: $search) {
        primaryId
        secondaryId
        focusOnChannels {
          _id
          _created
          _updated
          name
          description
          slug
          type
          isPublic
          isSub

          settings {
            hasPerksEnabled
            areUserTemplatesAllowed
            channelLanguages
            enabledLanguagesForMembers
            multiLanguageEnabled
          }

          address {
            ...AddressFragment
          }

          profile {
            ...ProfileFragment
          }

          parent {
            _id
          }

          notices {
            ...ContentFragment
          }

          content {
            ...ContentFragment
          }

          pages {
            _id
            _created
            _updated
            _order
            name
            slug
            icon
            iconSet
            iconWeight
            label
            label_l10n
            content {
              _id
              _created
              _updated
              integration {
                _id
                integration {
                  _id
                  name
                }
              }
            }
          }

          interactions {
            _id
            _created
            _updated
            status
            startDate
            endDate
            geo
            data
            features
            version
            contentData
            user {
              _id
              name
              profile {
                _id
                image
                name
              }
            }
          }

          sections {
            ...SectionFragment
          }
        }
      }
    }
  }
`;
