import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import { Button, Thumbnail } from 'components/general';
import { H5 } from 'components/typography';

import styles from './VisitorListItem.scss';

type BulkVisitorsItemProps = {
  bulkVisitors: VisitorType[];
  onDelete?: (bulkVisitors: VisitorType[]) => void;
};

export function BulkVisitorsItem({
  bulkVisitors,
  onDelete,
}: BulkVisitorsItemProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  const bulkVisitorsCount = bulkVisitors.length.toString();

  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(bulkVisitors);
    }
  };

  const renderDeleteButton = () => (
    <div>
      <Button
        testId="deleteBulkButtonTrigger"
        className={styles.button}
        size="small"
        onClick={() => setIsDeleting(true)}
        startIcon={
          <Icon
            name="trash-alt"
            testId="deleteIcon"
            set={ICON_SET_FONTAWESOME}
          />
        }
      >
        {t('Remove')}
      </Button>
    </div>
  );

  const renderDeleteActionButtons = () => (
    <>
      <div>
        <Button
          testId="deleteBulkButton"
          className={styles.button}
          size="small"
          variant="contained"
          onClick={handleOnDelete}
        >
          {t('web.content.feature.visitorManagement.visitorInvite.form.remove')}
        </Button>
      </div>
      <div>
        <Button
          testId="cancelDeleteBulkButton"
          className={styles.button}
          size="small"
          onClick={() => setIsDeleting(false)}
        >
          {t('web.content.feature.visitorManagement.visitorInvite.form.cancel')}
        </Button>
      </div>
    </>
  );

  return (
    <div className={cs(styles.visitorListItem)}>
      <div className={styles.user}>
        <Thumbnail className={styles.thumbnail} name={bulkVisitorsCount} text />
        <div className={styles.textWrapper}>
          <H5>
            {t(
              'web.content.feature.visitorManagement.visitorInvite.form.bulkImportItem',
              { count: bulkVisitors.length }
            )}
          </H5>
        </div>
      </div>
      {!isDeleting && renderDeleteButton()}
      {isDeleting && renderDeleteActionButtons()}
    </div>
  );
}
