import gql from 'graphql-tag';

export default gql`
  query getVisitorPassAggregatesBySubmissionId($submissionId: UUID!) {
    visitorPassesBySubmissionId(submissionId: $submissionId) {
      items {
        id
        visitor {
          firstName
          lastName
          company
          email
        }
        hostName
        tenantName
        floor
        companyName
        status
        visitorNote
        staffNote
        startDatetime
        endDatetime
        submittedBy
        eventName
        createdAtDatetime
        sourceId
        submissionId
        validStateTransitions
        recurrenceId
      }
    }
  }
`;
