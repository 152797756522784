import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ContentFeatureDoesntExistError extends CodedError {
  static get errorName() {
    return errorCodes.contentFeatureDoesntExistError.code;
  }

  constructor(message = errorCodes.contentFeatureDoesntExistError.message) {
    super(message);
    this.code = errorCodes.contentFeatureDoesntExistError.code;
    this.message = `${message} ${errorCodes.contentFeatureDoesntExistError.code}`;
    this.name = errorCodes.contentFeatureDoesntExistError.name;
  }
}
