import { createContext } from 'react';

import { Auth0ContextProvider } from './Auth0ContextProvider';
import { useAuth0Context } from './useAuth0Context';

export type Auth0ContextType = {
  isAuth0LogoutInProgress: boolean;
  setIsAuth0LogoutInProgress: (isAuth0LogoutInProgress: boolean) => void;
};

export const Auth0Context = createContext<Auth0ContextType | undefined>(
  undefined
);

export { Auth0ContextProvider, useAuth0Context };
