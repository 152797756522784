/**
 * Parses a rich text JSON string into JSON
 * @param richText
 * @returns a rich text JSON
 */
export function parseRichTextField(richText?: string) {
  if (!richText) return null;

  try {
    return JSON.parse(richText);
  } catch (e) {
    console.error('Error: invalid JSON string', e);
    return null;
  }
}
