import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class HomIntegrationSetupError extends CodedError {
  static get errorName() {
    return errorCodes.homIntegrationSetupError.code;
  }

  constructor(message = errorCodes.homIntegrationSetupError.message) {
    super(message);
    this.code = errorCodes.homIntegrationSetupError.code;
    this.message = `${message} ${errorCodes.homIntegrationSetupError.code}`;
    this.name = errorCodes.homIntegrationSetupError.name;
  }
}
