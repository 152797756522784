import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getUserBookingsCountForContent(
    $userId: UUID!
    $contentId: UUID!
    $selectedDate: DateTime!
  ) {
    userBookingsCountForContent(
      userId: $userId
      contentId: $contentId
      selectedDate: $selectedDate
    ) {
      count
      startDate
      endDate
    }
  }
`);
