export interface samlDataType {
  url: string;
  SAMLResponse: string;
}

export const handleRedirect = (samlData: samlDataType) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = samlData.url;
  form.target = '_blank';
  const formData = {
    SAMLResponse: samlData.SAMLResponse,
  };

  Object.entries(formData).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
