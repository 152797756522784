import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getReservableWaitlistsForAdmin(
    $pagination: PaginationInput
    $contentId: UUID!
    $channelId: UUID!
    $search: ReservableWaitlistSearch
  ) {
    reservableWaitlistForAdmin(
      contentId: $contentId
      channelId: $channelId
      pagination: $pagination
      search: $search
    ) {
      items {
        id
        createdAt
        startDate
        endDate
        user { 
          name
          email
        }
        companies {
          name
        }
      }
      pageInfo {
        start
        perPage
        total
      }
    }
  }
`);
