import gql from 'graphql-tag';

import {
  FullDraftContentFragment,
  PublicUserFragment,
  ThemeFragment,
  AddressFragment,
  ProfileFragment,
  MetatagFragment,
  ObjectMetatagFragment,
} from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${AddressFragment}
  ${ProfileFragment}
  ${MetatagFragment}
  ${ObjectMetatagFragment}
  ${ThemeFragment}
  ${FullDraftContentFragment}

  mutation CreateDraft($draft: DraftContentInput!) {
    createDraft(draft: $draft) {
      ...FullDraftContentFragment
    }
  }
`;
