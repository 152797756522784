import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class InvalidQRCodeError extends CodedError {
  static get errorName() {
    return errorCodes.invalidQRCodeError.code;
  }

  constructor(message = errorCodes.invalidQRCodeError.message) {
    super(message);
    this.code = errorCodes.invalidQRCodeError.code;
    this.message = `${message} ${errorCodes.invalidQRCodeError.code}`;
    this.name = errorCodes.invalidQRCodeError.name;
  }
}
