import gql from 'graphql-tag';

export default gql`
  query ProxyclickUsers($companyId: ID!) {
    proxyclickCompanyUsers(companyId: $companyId) {
      _id
      email
    }
  }
`;
