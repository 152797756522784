import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Loading, Thumbnail } from 'components';
import { useTranslation } from 'react-i18next';

import { fetchHostAndTenantInformation } from 'lane-shared/domains/visitorManagement/helpers';
import type {
  HostType,
  TenantChannelType,
} from 'lane-shared/domains/visitorManagement/helpers/fetchHostAndTenantInformation';
import { HostInformationType } from 'lane-shared/domains/visitorManagement/types/HostInformationType';
import { addressFormatter, imageUrl } from 'lane-shared/helpers/formatters';

import { Alert, AlertType } from 'components/lds';
import { H5, S, H4 } from 'components/typography';

import styles from './UCIReceiptHostInformation.scss';

type Props = {
  createdById: string;
  channelId: string | undefined | null;
  hostInformation: HostInformationType | undefined | null;
};

function UCIReceiptHostInformation({
  channelId,
  createdById,
  hostInformation,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [host, setHost] = useState<HostType>();
  const [channel, setChannel] = useState<TenantChannelType>();

  const hostId = hostInformation?.hostId || createdById;
  const hostTenantId = hostInformation?.hostTenantId || channelId;

  const fetchHostInformation = useCallback(async () => {
    if (!hostId || !hostTenantId) {
      return;
    }

    setIsLoading(true);

    try {
      const { host, tenantChannel } = await fetchHostAndTenantInformation(
        hostId,
        hostTenantId!
      );
      setHost(host);
      setChannel(tenantChannel);
    } catch (_) {
      // Intentionally failing silently. Alert error is displayed if host or channel is null
    }

    setIsLoading(false);
  }, [hostId, hostTenantId]);

  useEffect(() => {
    fetchHostInformation();
  }, [fetchHostInformation]);

  const shouldRenderHost = useMemo(() => Boolean(host?.name), [host?.name]);
  const shouldRenderTenantChannel = useMemo(
    () => Boolean(channel?.name && channel.address),
    [channel?.name, channel?.address]
  );

  if (!hostId || !hostTenantId) {
    return null;
  }

  return (
    <div className={styles.container}>
      <H4>
        {t(
          'web.content.feature.visitorManagement.receipt.hostInformation.header'
        )}
      </H4>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {(!shouldRenderHost || !shouldRenderTenantChannel) && (
            <Alert type={AlertType.warning} fullWidth>
              {t(
                'web.content.feature.visitorManagement.receipt.hostInformation.error'
              )}
            </Alert>
          )}
          <div className={styles.itemContainer}>
            {shouldRenderHost && (
              <div className={styles.item} data-test="hostItem">
                <Thumbnail
                  className={styles.thumbnail}
                  name={host!.name}
                  src={imageUrl(host!.profile.image)}
                />
                <div className={styles.textWrapper}>
                  <H5>{host!.name}</H5>
                </div>
              </div>
            )}
            {shouldRenderTenantChannel && (
              <div className={styles.item} data-test="tenantChannelItem">
                <Thumbnail
                  className={styles.thumbnail}
                  name={channel!.profile.name}
                />
                <div className={styles.textWrapper}>
                  <H5>{channel!.profile.name}</H5>
                  <S>
                    {addressFormatter(channel!.address, [
                      'name',
                      'street1',
                      'street2',
                      'street3',
                      'city',
                      'state',
                    ])}
                  </S>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default UCIReceiptHostInformation;
