import { WorkOrderStatusTypes } from '../../integrations/BuildingEnginesPrism/client/workOrders';
import * as INTERACTION_STATES from '../interactions';

export const FORGOT_PASSWORD_URL =
  'https://connect.buildingengines.com/forgot-password';

export const ORDER_STATUS = {
  NEW: WorkOrderStatusTypes.New,
  OPEN: WorkOrderStatusTypes.Open,
  ONHOLD: WorkOrderStatusTypes.OnHold,
  COMPLETED: WorkOrderStatusTypes.Completed,
  CANCELLED: WorkOrderStatusTypes.Cancelled,
  CLOSED: WorkOrderStatusTypes.Closed,
  REJECTED: WorkOrderStatusTypes.Rejected,
};

export const ORDER_STATUS_COLOR = {
  new: '#E5C34C',
  open: '#5AC2F9',
  onhold: '#F0923F',
  completed: '#76E599',
  cancelled: '#E51F05',
  closed: '#E51F05',
  rejected: '#E51F05',
  scheduled: '#F0923F',
};

export const PRIORITY_MAP: { [key: string]: string } = {
  '1': '1 - Urgent',
  '2': '2 - High',
  '3': '3 - Medium',
  '4': '4 - Low',
};

export const PRIORITY_COLOR_MAP: { [key: string]: string } = {
  '1': 'red',
  '2': 'black',
  '3': 'black',
  '4': 'black',
};

const ORDER_STATUS_TO_INTERACTION_STATE = {
  [ORDER_STATUS.NEW]: INTERACTION_STATES.INTERACTION_CREATED,
  [ORDER_STATUS.OPEN]: INTERACTION_STATES.INTERACTION_OPEN,
  [ORDER_STATUS.ONHOLD]: INTERACTION_STATES.INTERACTION_ON_HOLD,
  [ORDER_STATUS.CANCELLED]: INTERACTION_STATES.INTERACTION_CANCELLED,
  [ORDER_STATUS.COMPLETED]: INTERACTION_STATES.INTERACTION_COMPLETE,
  [ORDER_STATUS.CLOSED]: INTERACTION_STATES.INTERACTION_CLOSED,
  [ORDER_STATUS.REJECTED]: INTERACTION_STATES.INTERACTION_REJECTED,
};

export function mapStatusToLaneStatus(status: string): string | undefined {
  return ORDER_STATUS_TO_INTERACTION_STATE[status];
}

export const getPillCircleColor = (statusColor: string): string => {
  if (statusColor === 'honey') {
    return 'orange';
  }
  if (statusColor === 'meadow') {
    return 'green';
  }
  return statusColor;
};

export const isWorkOrderOpen = ({
  status,
}: {
  status: WorkOrderStatusTypes;
}) => {
  return (
    status === WorkOrderStatusTypes.New ||
    status === WorkOrderStatusTypes.Open ||
    status === WorkOrderStatusTypes.OnHold
  );
};

export const isWorkOrderClosed = ({
  status,
}: {
  status: WorkOrderStatusTypes;
}) => {
  return (
    status === WorkOrderStatusTypes.Closed ||
    status === WorkOrderStatusTypes.Completed ||
    status === WorkOrderStatusTypes.Cancelled ||
    status === WorkOrderStatusTypes.Rejected
  );
};

export const ORDER_STATUS_LIST = Object.values(ORDER_STATUS);

export const ORDER_FINAL_STATUSES = [
  ORDER_STATUS.REJECTED,
  ORDER_STATUS.CANCELLED,
  ORDER_STATUS.COMPLETED,
];
