import React, { useState, useMemo, useContext } from 'react';

import cx from 'classnames';
import { DateTime, Interval } from 'luxon';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { SHORT_DAY } from 'lane-shared/helpers/constants/dates';
import { parseDateTime } from 'lane-shared/helpers/dates';
import findClosestLocale from 'lane-shared/helpers/findClosestLocale';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import Button from '../../../general/Button';
import DatePickerRow from '../DatePickerRow';
import TimePicker from '../TimePicker';
import DateCell from './DateCell';

import styles from './Calendar.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  // function to be called when element is clicked, returns selected day
  onChange: (date: Date) => void;
  // submit callback when user click on 'set date'
  onSubmit?: ((date: Date) => void) | null;
  // callback when user changes months
  onFocusChange?: (date: Date) => void;
  // JS date object for start date
  startDate?: Date | null;
  // JS date object for end date
  endDate?: Date | null;
  // JS date object for max date
  maxDate?: Date;
  // JS date object for min date
  minDate?: Date;
  // an existing value
  existingValue?: DateRangeType;
  // include the time picker
  includeTime?: boolean;
  // date format for the day
  dateFormat?: string;
  // the timezone to display dates in
  timeZone?: string;
  // limits the range available to be selected, in days
  rangeLimit?: number;
  // unavailable ranges
  unavailableDateRanges?: DateRangeType[];
  weekdayOnly?: boolean;
  dropdownPosition?: 'absolute' | 'fixed';
  disabledWeekDays?: number[];
};

// NOTE: Luxon uses 1-7 for weekdays, where 1 is Monday and 7 is Sunday
// Activate uses 0-6 for weekdays, where 0 is Sunday and 6 is Saturday,
// however, that's only true for "Disabled" days.
// In this 0-6 system, 0 is Monday and 6 is Sunday. I know, it's confusing.
const ACTIVATE_DISABLED_DAY_TO_LUXON_WEEKDAY = {
  1: 1, // Monday
  2: 2, // Tuesday
  3: 3, // Wednesday
  4: 4, // Thursday
  5: 5, // Friday
  6: 6, // Saturday
  0: 7, // Sunday
} as const;

type ACTIVATE_WEEKDAYS = keyof typeof ACTIVATE_DISABLED_DAY_TO_LUXON_WEEKDAY;
type LANE_WEEKDAYS = typeof ACTIVATE_DISABLED_DAY_TO_LUXON_WEEKDAY[ACTIVATE_WEEKDAYS];

function isDisabledDay(weekday: number, disabledDayIndices: number[]) {
  const disabledLuxonWeekdays = (disabledDayIndices as ACTIVATE_WEEKDAYS[]).map(
    dayIndex => ACTIVATE_DISABLED_DAY_TO_LUXON_WEEKDAY[dayIndex]
  );

  return disabledLuxonWeekdays.includes(weekday as LANE_WEEKDAYS);
}

export default function Calendar({
  className,
  style,
  timeZone,
  loading,
  disabled,
  startDate,
  endDate,
  existingValue,
  rangeLimit,
  onSubmit,
  onChange,
  onFocusChange = () => {},
  maxDate = new Date(2069, 0, 1),
  minDate = new Date(2014, 0, 1),
  includeTime = false,
  dateFormat = 'd',
  unavailableDateRanges = [],
  weekdayOnly = false,
  dropdownPosition,
  disabledWeekDays = [],
}: Props) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [dateUpdated, setDateUpdated] = useState(false);
  const { t } = useTranslation();

  const month = dateUpdated || !startDate ? currentMonth : startDate;
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const monthStart = parseDateTime(month, timeZone).startOf('month');
  const monthEnd = monthStart.endOf('month');
  const startWeek = monthStart.startOf('week');
  const endWeek = monthEnd.endOf('week');
  const { user } = useContext(UserDataContext);
  const locale = findClosestLocale(user?.locale);

  const _unavailableDateRanges = useMemo(
    () =>
      (unavailableDateRanges || []).map(range =>
        Interval.fromDateTimes(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
          parseDateTime(range.startDate, timeZone),
          parseDateTime(range.endDate, timeZone)
        )
      ),
    [unavailableDateRanges, timeZone]
  );

  function enforceDate(newDate: any) {
    const _newDate = parseDateTime(newDate, timeZone) as DateTime;

    const dayDisabled =
      // @ts-expect-error ts-migrate(2365) FIXME: Operator '<' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
      (minDate && _newDate.endOf('day') < minDate) ||
      // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
      (maxDate && _newDate.startOf('day') > maxDate);

    const unavailable =
      _unavailableDateRanges.some(range => range.contains(newDate)) ||
      (weekdayOnly && [6, 7].includes(newDate.weekday));

    if (unavailable || dayDisabled) {
      return false;
    }
    setDateUpdated(true);
    setCurrentMonth(_newDate.toJSDate());
    onFocusChange(_newDate.toJSDate());
    return true;
  }

  function enforceMonth(newDate: any) {
    setDateUpdated(true);
    const _newDate = parseDateTime(newDate, timeZone) as DateTime;
    setCurrentMonth(_newDate.toJSDate());
    onFocusChange(_newDate.toJSDate());
  }

  function enforceRangeLimit(newDate: any) {
    if (!rangeLimit) {
      return true;
    }

    const interval = Interval.fromDateTimes(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
      parseDateTime(newDate, timeZone),
      parseDateTime(startDate, timeZone)
    );

    return Math.abs(interval.length('days')) < rangeLimit;
  }

  const dayNames = useMemo(() => {
    const days: React.ReactNode[] = [];

    for (let i = 0; i < 7; i += 1) {
      days.push(
        <div key={i}>
          {dateFormatter(
            startWeek.plus({ day: i }),
            SHORT_DAY,
            timeZone,
            locale
          )}
        </div>
      );
    }

    return <div className={styles.dateNames}>{days}</div>;
  }, [startWeek?.toMillis(), timeZone]);

  const dayCells = useMemo(() => {
    const rows: any = [];

    let days: React.ReactNode[] = [];
    let day: DateTime = startWeek;

    while (day <= endWeek) {
      for (let i = 0; i < 7; i += 1) {
        const formattedDate = dateFormatter(day, dateFormat, timeZone);

        // is this unavailable?
        const unavailable =
          _unavailableDateRanges.some(range => range.contains(day)) ||
          (weekdayOnly && [6, 7].includes(day.weekday));

        const disabled =
          isDisabledDay(day.weekday, disabledWeekDays) ||
          // @ts-expect-error ts-migrate(2365) FIXME: Operator '<' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
          (minDate && day.endOf('day') < minDate) ||
          // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'DateTime'... Remove this comment to see the full error message
          (maxDate && day.startOf('day') > maxDate);

        const existing =
          existingValue &&
          Interval.fromDateTimes(
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
            parseDateTime(existingValue.startDate, timeZone),
            parseDateTime(existingValue.endDate, timeZone)
          ).contains(day);

        days.push(
          <DateCell
            key={day.toMillis()}
            timeZone={timeZone}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
            startDate={startDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
            endDate={endDate}
            day={day.toJSDate()}
            monthStart={monthStart.toJSDate()}
            onClick={(date: any) => {
              if (enforceDate(date) && enforceRangeLimit(date) && !disabled) {
                onChange(date);
              }
            }}
            existing={existing}
            unavailable={unavailable}
            disabled={disabled}
            text={formattedDate}
          />
        );

        day = day.plus({ day: 1 });
      }

      rows.push(
        <div className={styles.cells} key={day.toMillis()}>
          {days}
        </div>
      );

      days = [];
    }

    return rows;
  }, [startWeek?.toMillis(), timeZone, startDate, endDate]);

  return (
    <div
      data-test="datePicker"
      className={cx(styles.calendar, className, disabled && styles.disabled)}
      style={style}
    >
      <div className={styles.wrapper}>
        <div className={styles.calendarWrapper}>
          <DatePickerRow
            value={month}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            timeZone={timeZone}
            maxDate={maxDate}
            minDate={minDate}
            quickTimeUnit="month"
            showDays={false}
            onChange={enforceDate}
            onMonthChange={enforceMonth}
            dropdownPosition={dropdownPosition}
          />
          {dayNames}
          {dayCells}
        </div>
        {includeTime && (
          <TimePicker
            timeZone={timeZone}
            value={startDate}
            onChange={onChange}
            className={styles.timePicker}
          />
        )}
      </div>
      {onSubmit && (
        <Button
          testId="setDate"
          loading={loading}
          disabled={disabled}
          className={styles.button}
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ startDate: Date | null | undef... Remove this comment to see the full error message
          onClick={() => onSubmit({ startDate, endDate })}
          variant="contained"
        >
          {t('Set Date')}
        </Button>
      )}
    </div>
  );
}
