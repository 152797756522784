import { v4 as uuid } from 'uuid';

import { AddressType } from 'lane-shared/types/AddressType';

import { LaneType } from 'common-types';

export default function constructAddress({
  userId,
}: {
  userId?: LaneType.UUID;
}): AddressType {
  return {
    _id: uuid(),
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    _order: 0,
    name: '',
    street1: '',
    street2: '',
    street3: '',
    city: '',
    state: '',
    code: '',
    country: '',
    geo: [0, 0],
  };
}
