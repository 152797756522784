import gql from 'graphql-tag';

export default gql`
  mutation createChannelIntegration(
    $channelIntegration: ChannelIntegrationInput!
  ) {
    createChannelIntegration(channelIntegration: $channelIntegration) {
      _id
    }
  }
`;
