import gql from 'graphql-tag';

import {
  FullProfileWithThemeFragment,
  ThemeFragment,
  AddressFragment,
} from '../fragments';

export default gql`
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${AddressFragment}

  query getChannelForProfile($id: UUID, $slug: String) {
    channel(_id: $id, slug: $slug) {
      _id
      _created
      _updated

      name
      description
      slug
      type
      isPublic
      isDiscoverable
      isLive
      isSub
      inviteOnly
      settings {
        hasBillingPaymentsEnabled
        hasVisitorManagementEnabled
        hasWorkOrderServiceRequestsEnabled
        hasWorkOrderPreventiveMaintenanceEnabled
        hasWorkOrderEquipmentEnabled
        multiLanguageEnabled
        channelLanguages
        enabledLanguagesForMembers
        hasMessagingAndMarketplaceEnabled
      }

      address {
        ...AddressFragment
      }

      profile {
        ...FullProfileWithThemeFragment
      }
    }
  }
`;
