import gql from 'graphql-tag';

export default gql`
  mutation updateUserChannelSettings(
    $id: UUID
    $userId: UUID!
    $channelId: UUID!
    $language: String!
  ) {
    updateUserChannelSettings(
      id: $id
      userId: $userId
      channelId: $channelId
      language: $language
    ) {
      id
      channelId
      userId
      language
    }
  }
`;
