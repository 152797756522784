import gql from 'graphql-tag';

export default gql(`
  fragment FullProfileFragment on Profile {
    _id
    _created
    _updated
    name
    description
    email
    phone
    backgroundImage
    image
    logo
    wordmark
    color
    backgroundColor

    theme {
      _id
    }
    
    content {
      _id
      _created
      _updated
      name
      description
      subtitle
      slug
      type
      tags
      data
      liveDate
      startDate
      endDate
      category
      logo
      color
      geo
      block
      isInteractive
      backgroundImage
      icon
      iconSet
      iconWeight
      backgroundColor
      version
      renderer
      card {
        _id
      }
    }
}`);
