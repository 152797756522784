import gql from 'graphql-tag';

export default gql(`
  fragment PaymentAccountFragment on PaymentAccount {
    _id
    _created
    _updated
    name
    description
    type
    paymentProcessor {
      _id
      integrationProvider
      type
      isSandbox
      publicKey 
      channel {
        _id
      }
    }
    rules {
      _id
      _created
      _updated
      allContent
      contentCategories
      noSinglePurchaseLimit
      singlePurchaseLimit
      noPurchaseLimit
      purchaseLimit
      anyTimes
      startTime
      endTime
      allRoles
      groupRole {
        _id
      }
    }
  }
`);
