import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductCategory } from 'graphql-query-contracts';
import useUserLocale from 'lane-shared/hooks/useUserLocale';
import { ChannelType } from 'lane-shared/types/ChannelType';
import {
  CreditBundle,
  CREDIT_CATEGORY_NAME,
  CREDIT_CATEGORY_DESCRIPTION,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import {
  createCreditCategory,
  fetchCreditCategory,
  createCreditBundle,
} from 'lane-shared/domains/billingPayments/hooks/helpers';
import { getPricing } from '../../productCatalog/helpers';

export type Product = {};

type Props = {
  channel: Pick<ChannelType, '_id'> | undefined;
};

export function useCreateCreditBundle({ channel }: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const [error, setError] = useState<Error | null>(null);
  const [creditCategoryId, setCreditCategoryId] = useState<string | null>(null);

  const findCategory = (categories: ProductCategory[]) => {
    return categories.find(
      category =>
        category.name === CREDIT_CATEGORY_NAME &&
        category.description === CREDIT_CATEGORY_DESCRIPTION
    );
  };

  const createOrFetchCategory = async () => {
    try {
      const { listProductCategories } = await fetchCreditCategory(
        channel?._id || ''
      );

      if (listProductCategories?.productCategory?.length > 0) {
        const category = findCategory(listProductCategories?.productCategory);
        if (category) {
          setCreditCategoryId(category.productCategoryId);
          return;
        }
      }

      const category = await createCreditCategory(channel?._id || '');
      setCreditCategoryId(category.productCategoryId);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const saveCreditBundle = async (
    creditBundle: CreditBundle,
    currency: SupportedPaymentCurrency
  ): Promise<boolean> => {
    if (!channel?._id) {
      setError(new Error(t('abp.errors.creditBundle.channelInfoMissing')));
      return false;
    }

    if (creditCategoryId === null) {
      await createOrFetchCategory();
      setError(new Error(t('abp.errors.creditBundle.categoryCreationFailed')));
      return false;
    }

    const { grossTax, grossMarkup, listPrice, netPrice } = getPricing({
      currency,
      locale,
      priceComponents: {
        amount: creditBundle.rate ?? 0,
        tax: {
          value: creditBundle.taxValue ?? 0,
          taxAmountType: creditBundle.taxType,
        },
      },
    });

    try {
      const { createProduct } = await createCreditBundle(
        creditBundle,
        channel?._id,
        creditCategoryId,
        { grossTax, grossMarkup, listPrice, netPrice }
      );

      if (!createProduct?.id) {
        setError(
          new Error(t('abp.errors.creditBundle.creditBundleCreationFailed'))
        );
        return false;
      }

      setError(null);
    } catch (error) {
      console.error(error);
      setError(error);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (channel?._id && !creditCategoryId) {
      createOrFetchCategory();
    }
  }, [channel]);

  return {
    creditBundleError: error,
    createCreditBundle: saveCreditBundle,
  } as const;
}
