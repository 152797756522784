import gql from 'graphql-tag';

export type ListPropertyFloorsQueryResponse = {
  property: {
    floors: {
      id: string;
      name: string;
      index: number;
      units: {
        id: string;
        name: string;
      }[];
    }[];
  };
};

export const listPropertyFloors = gql`
  query ListPropertyFloors($propertyId: UUID!) {
    property(propertyId: $propertyId) {
      floors {
        id
        name
        index
        units {
          id
          name
        }
      }
    }
  }
`;
