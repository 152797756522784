import React, { useEffect, useState } from 'react';

import { Icon, XS } from 'design-system-web';
import cx from 'classnames';
import { scrollTo } from 'helpers';
import { NavLink, useLocation } from 'react-router-dom';

import {
  ICON_SET_FONTAWESOME,
  IconSet,
} from 'lane-shared/helpers/constants/icons';

import styles from './SidebarItem.scss';

export type SidebarItemProps = {
  icon?: string;
  iconSet?: IconSet;
  iconWeight?: string;
  route?: string;
  exact?: boolean;
  headerMessage?: string;
  isCategory?: boolean;
  mobileOnly?: boolean;
  links?: any[];
  children: string;
  type?: string;
  isSidebarCollapsed?: boolean;
  isMemberView?: boolean;
};

// scroll the page back to the top when clicking the side nav
function scrollToTop() {
  scrollTo(window.document.scrollingElement, { top: 0, behavior: 'auto' });
}

export default function SidebarItem({
  children,
  icon = '',
  iconSet = ICON_SET_FONTAWESOME,
  iconWeight,
  isCategory = false,
  links = [],
  route,
  exact,
  mobileOnly,
  headerMessage = '',
  type = 'subscriber',
  isSidebarCollapsed = false,
  isMemberView = false,
}: SidebarItemProps) {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const renderIcon = icon && (
    <Icon
      name={icon}
      className={styles.headerIcon}
      set={iconSet}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      type={iconWeight}
    />
  );

  function onCategoryClicked(e: any) {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  }

  const routeMatch = links
    ?.map(link => link.route)
    .find(route => location.pathname.includes(route));

  useEffect(() => {
    if (routeMatch) {
      setIsCollapsed(false);
    }
  }, [routeMatch]);

  if (!isCategory) {
    return (
      <NavLink
        className={cx(
          styles.SidebarItem,
          mobileOnly && styles.mobileOnly,
          isSidebarCollapsed && styles.sidebarCollapsed
        )}
        data-type={type}
        onClick={scrollToTop}
        data-test={children}
        exact={exact}
        to={{
          pathname: route,
          state: {
            headerMessage,
            title: headerMessage,
          },
        }}
      >
        {renderIcon}
        {isMemberView || mobileOnly ? (
          <label className={cx(styles.isMemberViewTab)} title={children}>
            <XS>{children}</XS>
          </label>
        ) : (
          <span>{children}</span>
        )}
      </NavLink>
    );
  }

  return (
    <div className={styles.wrapper}>
      <NavLink
        data-type={type}
        data-test={children}
        className={cx(styles.SidebarItem, {
          [styles.categoryActive]: Boolean(routeMatch),
          [styles.sidebarCollapsed]: isSidebarCollapsed,
          [styles.selected]: !isCollapsed,
        })}
        onClick={onCategoryClicked}
        to={{
          pathname: undefined,
          state: {
            headerMessage,
            title: headerMessage,
          },
        }}
      >
        {renderIcon}
        <span>{children}</span>
        <Icon
          name={isCollapsed ? 'chevron-down' : 'chevron-up'}
          set={ICON_SET_FONTAWESOME}
          className={styles.chevronIcon}
        />
      </NavLink>
      {!isCollapsed && links?.length > 0 && (
        <div className={styles.linksContainer}>
          {links?.map(link => (
            <NavLink
              data-type={type}
              key={link.route}
              className={cx(styles.link, {
                [styles.selected]: location.pathname.includes(link.route),
              })}
              exact={link.exact}
              onClick={scrollToTop}
              to={{
                pathname: link.route,
                state: {
                  headerMessage: link.headerMessage,
                  title: link.headerMessage,
                },
              }}
            >
              <div className={styles.activeLinkIconWrapper}>
                <Icon
                  name="circle"
                  className={styles.activeLinkIcon}
                  set={ICON_SET_FONTAWESOME}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  type=""
                />
              </div>
              <span className={styles.linkName}>{link.name}</span>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}
