import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Key } from 'ts-key-enum';

import getPathLabel from 'lane-shared/helpers/formatters/getPathLabel';

import { H6 } from 'components/typography';

import useSimpleDragHelper from 'hooks/useSimpleDragHelper';

import styles from './PathListItem.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  path: string;
  small?: boolean;
  isSelected?: boolean;
  onSelected: (path: string) => void;
  onDrop: (e: Event, path: string) => void;
};

type Props = OwnProps;

export default function PathListItem({
  path,
  small = false,
  isSelected = false,
  onSelected = () => null,
  onDrop = () => null,
  className,
  style,
}: Props) {
  function _dropHandler(e: any) {
    onDrop(e, path);
  }

  const {
    dragLeaveHandler,
    dragEnterHandler,
    dragOverHandler,
    dropHandler,
    isDropping,
  } = useSimpleDragHelper({ onDrop: _dropHandler });

  return (
    <div
      className={cx(styles.PathListItem, className)}
      data-selected={isSelected}
      data-is-small={small}
      style={style}
      role="button"
      tabIndex={0}
      data-dropping={isDropping}
      onDragOver={dragOverHandler}
      onDragEnter={dragEnterHandler}
      onDragLeave={dragLeaveHandler}
      onDrop={dropHandler}
      onKeyPress={e => {
        if (e.key === Key.Enter) {
          onSelected(path);
        }
      }}
      onClick={() => onSelected(path)}
    >
      <Icon
        className={styles.icon}
        name="folder"
        type="fal"
        set="FontAwesome"
      />
      <H6>{getPathLabel(path)}</H6>
    </div>
  );
}
