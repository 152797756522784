import gql from 'graphql-tag';

export default gql`
  query exportCSVTasks(
    $groupId: String
    $groupIds: [String]
    $search: FindTasksSearch
    $filter: TaskFilterDTO
    $preFilter: TaskPreFilterDTO
  ) {
    exportCSVTasks(
      groupId: $groupId
      groupIds: $groupIds
      search: $search
      filter: $filter
      preFilter: $preFilter
    ) {
      tasks {
        id
        groupBy {
          id
          name
        }
        dueDate
        repeats {
          label
          value
        }
        estimateTimeToComplete
        assignee {
          _id
          name
        }
        assigneeGroup {
          _id
          name
        }
        notes
        steps
        meterReading
        createdBy
        updatedBy
        completedBy
        equipment {
          id
          location
          name
          category
          floor
          suite
        }
        createdAt
        updatedAt
        completedAt
        isArchived
        location
        actualTimeToComplete
        completeNotes
        status
        nextStatuses
        schedule {
          id
          title
        }
        friendlyID
        userFriendlyID
      }
    }
  }
`;
