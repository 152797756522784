import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

// Custom merge function for handling cache-merging
// todo: this mergeObjects does not check to see if the _ids are the same, so will just merge any two objects together
function mergeObjects(existing: any, incoming: any) {
  return { ...existing, ...incoming };
}

export default new InMemoryCache({
  typePolicies: {
    Me: { merge: mergeObjects },
    ThemePalette: {
      fields: {
        custom: { merge: mergeObjects },
      },
    },
    User: {
      fields: {
        logins: { merge: mergeObjects },
      },
    },
    Channel: {
      fields: {
        settings: { merge: mergeObjects },
      },
    },
  },
  possibleTypes: {
    ContentInterface: ['Content'],
    Document: [
      'Channel',
      'Profile',
      'Address',
      'Section',
      'SectionContent',
      'Content',
      'Block',
      'ContentPlacement',
      'User',
      'UserLogin',
      'UserGroupRole',
      'GroupRole',
      'EventSubscription',
      'DeviceToken',
      'Whitelabel',
      'ContentWorkflow',
      'ContentFeature',
      'ContentNotification',
      'DraftContent',
      'ChannelIntegration',
      'Integration',
      'UserContentInteraction',
      'ChannelRelationship',
      'ContentTemplate',
      'LibraryItem',
      'Library',
      'LibraryAccess',
      'Media',
      'ChannelInvite',
      'ChannelMerchant',
      'FeedbackForm',
      'ClaimedChannelInvite',
      'PaymentAccount',
    ],
  },
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'UserContentInteractionHistory':
        return `${object._id}${object._updated}`;
      case 'DraftContentHistory':
        return `${object._id}${object._updated}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});
