import gql from 'graphql-tag';

import { SectionFragment } from '../fragments';

export default gql`
  ${SectionFragment}

  query getChannelSections($id: UUID, $slug: String) {
    channel(_id: $id, slug: $slug) {
      _id
      settings {
        enabledLanguagesForMembers
        multiLanguageEnabled
        channelLanguages
      }
      sections {
        ...SectionFragment
      }
    }
  }
`;
