import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { InviteContext } from '.';
import { useInvites } from '../../hooks';

type Props = {
  children: ReactNode;
};

export const InviteContextProvider: React.FC<Props> = ({ children }) => {
  const [inviteId, setInviteId] = useState<string | undefined>(undefined);

  const {
    invite: inviteInfo,
    loading,
    error,
    claimInvite: hookClaimInvite,
    claiming,
    claimError,
  } = useInvites({
    inviteId,
  });

  const setInviteIdCallback = useCallback(
    (inviteId: string) => {
      setInviteId(inviteId);
    },
    [inviteId]
  );

  const clearInvite = useCallback(() => {
    setInviteId(undefined);
  }, [inviteId]);

  const claimInvite = useCallback(() => {
    if (!inviteInfo?.channel?._id || !inviteId) {
      return;
    }

    return hookClaimInvite(inviteId, inviteInfo?.channel?._id);
  }, [hookClaimInvite, inviteId, inviteInfo?.channel._id]);

  const contextValue = useMemo(
    () => ({
      inviteId,
      email: inviteInfo?.email,
      userName: inviteInfo?.name,
      company: inviteInfo?.company,
      building: inviteInfo?.channel,
      loading,
      setInviteId: setInviteIdCallback,
      claimInvite,
      clearInvite,
      claiming,
      claimError,
      error,
    }),
    [inviteInfo, inviteId, error, claimError, claiming, loading]
  );

  return (
    <InviteContext.Provider value={contextValue}>
      {children}
    </InviteContext.Provider>
  );
};
