import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AnalyticType: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Binary: { input: any; output: any; }
  Byte: { input: any; output: any; }
  Color: { input: any; output: any; }
  ContentCategoryType: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  EventType: { input: any; output: any; }
  GeoPoint: { input: any; output: any; }
  Image: { input: any; output: any; }
  JSON: { input: any; output: any; }
  MediaContentType: { input: any; output: any; }
  Path: { input: any; output: any; }
  Permission: { input: any; output: any; }
  PhoneNumber: { input: any; output: any; }
  PostalCode: { input: any; output: any; }
  Requirement: { input: any; output: any; }
  StudioGroupRoleName: { input: any; output: any; }
  SupportedLocale: { input: any; output: any; }
  URL: { input: any; output: any; }
  UUID: { input: any; output: any; }
  UserContentInteractionStatus: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export type AccessControlChannelType = {
  __typename?: 'AccessControlChannelType';
  channelId: Scalars['UUID']['output'];
  isConnectedByTemplate?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  profile: AccessControlProfileType;
  propertyChannelId?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AccessControlGroup = {
  __typename?: 'AccessControlGroup';
  channelId: Scalars['UUID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userCount: Scalars['Int']['output'];
};

export type AccessControlGroupInput = {
  channelId: Scalars['UUID']['input'];
  id: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};

export type AccessControlGroups = {
  __typename?: 'AccessControlGroups';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AccessControlGroupsForInvite = {
  acgIds?: InputMaybe<Array<Scalars['String']['input']>>;
  channelId: Scalars['UUID']['input'];
};

export type AccessControlGroupsMapInput = {
  accessControlGroupIds: Array<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
};

export type AccessControlProfileType = {
  __typename?: 'AccessControlProfileType';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AccessIntegration = {
  __typename?: 'AccessIntegration';
  channelId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
};

export type AccessLocation = {
  __typename?: 'AccessLocation';
  backgroundImage?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  integrations: Array<Maybe<Scalars['String']['output']>>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AccessPoint = {
  __typename?: 'AccessPoint';
  buildingId: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AccessUser = {
  __typename?: 'AccessUser';
  accessControlGroups?: Maybe<Array<Maybe<AccessUserControlGroups>>>;
  channelId: Scalars['UUID']['output'];
  mobileAccess?: Maybe<Scalars['Boolean']['output']>;
  syncStatus: Scalars['String']['output'];
};

export type AccessUserChannel = {
  __typename?: 'AccessUserChannel';
  channelId: Scalars['UUID']['output'];
  channelName: Scalars['String']['output'];
};

export type AccessUserControlGroups = {
  __typename?: 'AccessUserControlGroups';
  id: Scalars['String']['output'];
};

export type AccessUserRole = {
  __typename?: 'AccessUserRole';
  accessRole: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AccountContactPair = {
  __typename?: 'AccountContactPair';
  account: Scalars['JSON']['output'];
  clientType: Scalars['JSON']['output'];
  contact: Scalars['JSON']['output'];
};

export type AccountsMutations = {
  __typename?: 'AccountsMutations';
  /** Cancels a recurring charge */
  cancelRecurringCharge?: Maybe<CancelRecurringChargeResponse>;
  /** Create BillingPayments Settings */
  createBillingPaymentSettings?: Maybe<GroupSettingResponse>;
  /** Creates a new charge for an invoicee */
  createCharge?: Maybe<CreateChargeResponse>;
  /**
   * Creates charge code, must be validated within channel hierarchy
   * returns id of the created charge code
   */
  createChargeCode?: Maybe<CreateChargeCodeResponse>;
  /** Creates a new recurring charge */
  createRecurringCharge?: Maybe<CreateRecurringChargeResponse>;
  /** Creates a transaction and returns the transaction id if successful */
  createTransaction?: Maybe<CreateTransactionResponse>;
  /** Creates an accounts user and returns the user object if successful */
  createUser?: Maybe<CreateUserResponse>;
  /** Deletes a draft charge for an invoicee - does not return value */
  deleteDraftCharge?: Maybe<Scalars['Void']['output']>;
  /** Deletes autopayment payment method for a user - does not return a value */
  deleteInvoiceAutopayment?: Maybe<Scalars['Void']['output']>;
  /** Refund a transaction and returns the transaction id if no error */
  refundTransaction?: Maybe<RefundTransactionResponse>;
  /** Removes a payment method from stripe customer */
  removePaymentMethod?: Maybe<RemovePaymentMethodResponse>;
  /** Attaches payment method to stripe customer */
  savePaymentMethod?: Maybe<SavePaymentMethodResponse>;
  /** Update BillingPayments Settings */
  updateBillingPaymentSettings?: Maybe<GroupSettingResponse>;
  /** Updates a draft charge for an invoicee - does not return value */
  updateDraftCharge?: Maybe<Scalars['Void']['output']>;
  /** Updates autopayment payment method for a user */
  updateInvoiceAutopaymentDetails?: Maybe<UpdateInvoiceAutopaymentDetailsResponse>;
  /** Update Invoice to Paid */
  updateInvoiceToPaid?: Maybe<UpdateInvoiceToPaidResponse>;
  /** Creates or edits existing subscription details based on whether existing subscription details id is provided */
  upsertSubscriptionDetails: UpsertSubscriptionDetailsResponse;
  /** Void a particular charge */
  voidCharge?: Maybe<VoidChargeResponse>;
};


export type AccountsMutationsCancelRecurringChargeArgs = {
  cancelRecurringChargeRequest?: InputMaybe<CancelRecurringChargeRequest>;
};


export type AccountsMutationsCreateBillingPaymentSettingsArgs = {
  groupSettingRequest?: InputMaybe<GroupSettingRequest>;
};


export type AccountsMutationsCreateChargeArgs = {
  createChargeRequest: CreateChargeRequest;
};


export type AccountsMutationsCreateChargeCodeArgs = {
  createChargeCodeRequest?: InputMaybe<CreateChargeCodeRequest>;
};


export type AccountsMutationsCreateRecurringChargeArgs = {
  createRecurringChargeRequest?: InputMaybe<CreateRecurringChargeRequest>;
};


export type AccountsMutationsCreateTransactionArgs = {
  contentChannelId: Scalars['String']['input'];
  createTransactionRequest: CreateTransactionRequest;
};


export type AccountsMutationsCreateUserArgs = {
  createUserRequest?: InputMaybe<CreateUserRequest>;
};


export type AccountsMutationsDeleteDraftChargeArgs = {
  deleteDraftChargeRequest?: InputMaybe<DeleteDraftChargeRequest>;
};


export type AccountsMutationsDeleteInvoiceAutopaymentArgs = {
  deleteInvoiceAutopaymentRequest?: InputMaybe<DeleteInvoiceAutopaymentRequest>;
};


export type AccountsMutationsRefundTransactionArgs = {
  refundTransactionRequest: RefundTransactionRequest;
};


export type AccountsMutationsRemovePaymentMethodArgs = {
  removePaymentMethodRequest?: InputMaybe<RemovePaymentMethodRequest>;
};


export type AccountsMutationsSavePaymentMethodArgs = {
  savePaymentMethodRequest?: InputMaybe<SavePaymentMethodRequest>;
};


export type AccountsMutationsUpdateBillingPaymentSettingsArgs = {
  groupSettingRequest?: InputMaybe<GroupSettingRequest>;
};


export type AccountsMutationsUpdateDraftChargeArgs = {
  updateDraftChargeRequest: UpdateDraftChargeRequest;
};


export type AccountsMutationsUpdateInvoiceAutopaymentDetailsArgs = {
  updateInvoiceAutopaymentDetailsRequest?: InputMaybe<UpdateInvoiceAutopaymentDetailsRequest>;
};


export type AccountsMutationsUpdateInvoiceToPaidArgs = {
  updateInvoiceToPaidRequest?: InputMaybe<UpdateInvoiceToPaidRequest>;
};


export type AccountsMutationsUpsertSubscriptionDetailsArgs = {
  upsertSubscriptionDetailsRequest: UpsertSubscriptionDetailsRequest;
};


export type AccountsMutationsVoidChargeArgs = {
  voidChargeRequest?: InputMaybe<VoidChargeRequest>;
};

export type AccountsQueries = {
  __typename?: 'AccountsQueries';
  /** Get BillingPayments Settings By Channel */
  getBillingPaymentSettings?: Maybe<GroupSettingResponse>;
  /** Get Invoice details */
  getInvoiceDetails?: Maybe<GetInvoiceDetailsResponse>;
  /** Get Recurring Charge */
  getRecurringCharge?: Maybe<GetRecurringChargeResponse>;
  getTransactionStatus?: Maybe<GetTransactionStatusResponse>;
  /** Returns the accounts user object if one exists matching the userId */
  getUserByExternalId?: Maybe<GetUserByExternalIdResponse>;
  /** Returns a list of credit wallets for a user */
  getUserCreditWallets?: Maybe<GetUserCreditWalletsResponse>;
  /** List Charges' Billing Reports */
  listBillingReports?: Maybe<ChargeBillingReportsResponse>;
  /** List ChargeCodes for Channel */
  listChargeCode?: Maybe<ListChargeCodeResponse>;
  /** List Charges for a channel */
  listCharges?: Maybe<ListChargesResponse>;
  /** List Invoices for a channel */
  listInvoices?: Maybe<ListInvoicesResponse>;
  /** List Invoices for Companies */
  listInvoicesByCompanies?: Maybe<ListInvoicesByPayersResponse>;
  /** List Invoices for User */
  listInvoicesByUsers?: Maybe<ListInvoicesByPayersResponse>;
  /** List RecurringCharges for Channels */
  listRecurringChargesForChannels?: Maybe<ListRecurringChargesResponse>;
  /** List Recurring Charges for Payers */
  listRecurringChargesForPayers?: Maybe<ListRecurringChargesResponse>;
  /** Returns a list of transaction objects that match the group id and type */
  listTransactions?: Maybe<ListTransactionsResponse>;
  /** Saved subscription details based on id */
  subscriptionDetails: SubscriptionDetails;
  /** Subscription details */
  transaction: Transaction;
};


export type AccountsQueriesGetBillingPaymentSettingsArgs = {
  getGroupSettingRequest?: InputMaybe<GetGroupSettingRequest>;
};


export type AccountsQueriesGetInvoiceDetailsArgs = {
  getInvoiceDetailsRequest?: InputMaybe<GetInvoiceDetailsRequest>;
};


export type AccountsQueriesGetRecurringChargeArgs = {
  getRecurringChargeRequest?: InputMaybe<GetRecurringChargeRequest>;
};


export type AccountsQueriesGetTransactionStatusArgs = {
  getTransactionStatusRequest?: InputMaybe<GetTransactionStatusRequest>;
};


export type AccountsQueriesGetUserByExternalIdArgs = {
  getUserByExternalIdRequest?: InputMaybe<GetUserByExternalIdRequest>;
};


export type AccountsQueriesGetUserCreditWalletsArgs = {
  getUserCreditWalletsRequest?: InputMaybe<GetUserCreditWalletsRequest>;
};


export type AccountsQueriesListBillingReportsArgs = {
  filters?: InputMaybe<ChargeReportFilters>;
  forExport?: InputMaybe<Scalars['Boolean']['input']>;
  group: GroupInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortBy>;
};


export type AccountsQueriesListChargeCodeArgs = {
  listChargeCodeRequest: ListChargeCodeRequest;
};


export type AccountsQueriesListChargesArgs = {
  listChargesRequest?: InputMaybe<ListChargesRequest>;
};


export type AccountsQueriesListInvoicesArgs = {
  listInvoicesRequest?: InputMaybe<ListInvoicesRequest>;
};


export type AccountsQueriesListInvoicesByCompaniesArgs = {
  listInvoicesByPayersRequest?: InputMaybe<ListInvoicesByPayersRequest>;
};


export type AccountsQueriesListInvoicesByUsersArgs = {
  listInvoicesByPayersRequest?: InputMaybe<ListInvoicesByPayersRequest>;
};


export type AccountsQueriesListRecurringChargesForChannelsArgs = {
  listRecurringChargesForChannelsRequest?: InputMaybe<ListRecurringChargesRequest>;
};


export type AccountsQueriesListRecurringChargesForPayersArgs = {
  listRecurringChargesForPayersRequest?: InputMaybe<ListRecurringChargesRequest>;
};


export type AccountsQueriesListTransactionsArgs = {
  listTransactionsRequest?: InputMaybe<ListTransactionsRequest>;
};


export type AccountsQueriesSubscriptionDetailsArgs = {
  subscriptionDetailsId: Scalars['String']['input'];
};


export type AccountsQueriesTransactionArgs = {
  channelId: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};

export enum ActivateType {
  Account = 'account',
  Building = 'building',
  Tenant = 'tenant',
  TenantLease = 'tenantLease'
}

export type ActivateUser = {
  __typename?: 'ActivateUser';
  _id: Scalars['UUID']['output'];
  accessControlGroupIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  companies: Array<UserCompanyDetails>;
  credentials?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  email: Scalars['String']['output'];
  image?: Maybe<Scalars['Image']['output']>;
  integrationChannelId: Scalars['UUID']['output'];
  logo?: Maybe<Scalars['Image']['output']>;
  mobileAccess?: Maybe<Scalars['Boolean']['output']>;
  multiIntegrationAcgs?: Maybe<Array<Maybe<MultiChannelAcgs>>>;
  name: Scalars['String']['output'];
  role?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  source: Scalars['String']['output'];
  syncStatus: Scalars['String']['output'];
};

export type Address = Document & {
  __typename?: 'Address';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order?: Maybe<Scalars['Int']['output']>;
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  street3?: Maybe<Scalars['String']['output']>;
  weather?: Maybe<Weather>;
};

export type AddressInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _order?: InputMaybe<Scalars['Int']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<Scalars['GeoPoint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  street3?: InputMaybe<Scalars['String']['input']>;
};

export type AddressSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  city?: InputMaybe<StringSearch>;
  code?: InputMaybe<StringSearch>;
  country?: InputMaybe<StringSearch>;
  geo?: InputMaybe<GeoSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  state?: InputMaybe<StringSearch>;
  street1?: InputMaybe<StringSearch>;
  street2?: InputMaybe<StringSearch>;
  street3?: InputMaybe<StringSearch>;
};

export type AdminUserSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  deviceTokens?: InputMaybe<DeviceTokenSearch>;
  logins?: InputMaybe<UserLoginSearch>;
  name?: InputMaybe<StringSearch>;
  profile?: InputMaybe<ProfileSearch>;
  roles?: InputMaybe<UserGroupRoleSearch>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  status?: InputMaybe<UserStatus>;
};

export type AllowedFeatures = {
  __typename?: 'AllowedFeatures';
  migrateAmenities?: Maybe<Scalars['Boolean']['output']>;
  migrateCatUsers?: Maybe<Scalars['Boolean']['output']>;
  migrateCommunityNews?: Maybe<Scalars['Boolean']['output']>;
  migrateDiscoveryLinks?: Maybe<Scalars['Boolean']['output']>;
  migrateEvents?: Maybe<Scalars['Boolean']['output']>;
  migrateForms?: Maybe<Scalars['Boolean']['output']>;
  migrateNonReservableAmenities?: Maybe<Scalars['Boolean']['output']>;
  migrateTenants?: Maybe<Scalars['Boolean']['output']>;
  migrateUsers?: Maybe<Scalars['Boolean']['output']>;
};

export type AngusBuilding = {
  __typename?: 'AngusBuilding';
  _id: Scalars['ID']['output'];
  floors?: Maybe<Array<Maybe<AngusFloor>>>;
  name: Scalars['String']['output'];
};

export type AngusFloor = {
  __typename?: 'AngusFloor';
  _id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  suites: Array<Maybe<AngusSuite>>;
};

export type AngusLocation = {
  __typename?: 'AngusLocation';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AngusRequestType = {
  __typename?: 'AngusRequestType';
  _id: Scalars['ID']['output'];
  canCancel?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AngusRequester = {
  __typename?: 'AngusRequester';
  _id: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
};

export type AngusSuite = {
  __typename?: 'AngusSuite';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AngusTenant = {
  __typename?: 'AngusTenant';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AngusWorkOrder = {
  __typename?: 'AngusWorkOrder';
  _id: Scalars['ID']['output'];
  buildingId?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  requestTypeDescription?: Maybe<Scalars['String']['output']>;
  requestTypeId?: Maybe<Scalars['String']['output']>;
  requesterId?: Maybe<Scalars['String']['output']>;
  requesterName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<WorkOrderStatus>;
  suite?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
};

export type AngusWorkOrderConfirmation = {
  __typename?: 'AngusWorkOrderConfirmation';
  _id: Scalars['ID']['output'];
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ApiKey = Document & {
  __typename?: 'ApiKey';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _lastUsed: Scalars['DateTime']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  apiKey: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  status: ApiKeyStatusEnum;
  title: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
};

export type ApiKeyCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type ApiKeyDisableInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
};

export type ApiKeyInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first4Chars?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ApiKeyStatusEnum>;
  title: Scalars['String']['input'];
  userId: UserIdInput;
};

export enum ApiKeyStatusEnum {
  Active = 'Active',
  Disabled = 'Disabled'
}

export type AppleWalletIssuanceToken = {
  __typename?: 'AppleWalletIssuanceToken';
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  issuanceToken?: Maybe<Scalars['String']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  created: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entityId: Scalars['UUID']['output'];
  entityType?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['JSON']['output']>;
  fileSignedUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  s3Bucket?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['JSON']['output']>;
  thumbnailSignedUrl?: Maybe<Scalars['String']['output']>;
};

export type AttachmentInput = {
  entityId: Scalars['UUID']['input'];
  entityType: Scalars['String']['input'];
  file: Scalars['JSON']['input'];
  s3Bucket?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['JSON']['input']>;
};

export type Audience = {
  __typename?: 'Audience';
  Channel: Channel;
  archived: Scalars['Boolean']['output'];
  channelTargetingRules: Array<Maybe<TargetingRule>>;
  contentToTarget: Array<Maybe<Scalars['UUID']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['UUID']['output'];
  createdByChannel: Scalars['UUID']['output'];
  groupRoleTargetingRules: Array<Maybe<TargetingRule>>;
  id: Scalars['UUID']['output'];
  isDefault: Scalars['Boolean']['output'];
  managementChannels: Array<Maybe<Scalars['UUID']['output']>>;
  name: Scalars['String']['output'];
  reachSize?: Maybe<Scalars['Int']['output']>;
  shareChannels: Array<Maybe<Scalars['UUID']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type AudienceIdInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AudiencePagination = {
  __typename?: 'AudiencePagination';
  items: Array<Audience>;
  pageInfo: PageInfo;
};

export type AudienceSearch = {
  name?: InputMaybe<Scalars['String']['input']>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type AudiencesToAttachContentTo = {
  __typename?: 'AudiencesToAttachContentTo';
  id: Scalars['UUID']['output'];
};

export type AudiencesToAttachContentToInput = {
  id: Scalars['UUID']['input'];
};

export type Auth0Connection = {
  __typename?: 'Auth0Connection';
  connection?: Maybe<Scalars['String']['output']>;
  connectionId?: Maybe<Scalars['String']['output']>;
  domainAlias?: Maybe<Scalars['String']['output']>;
};

export type Auth0DomainHomeRealmDiscovery = {
  __typename?: 'Auth0DomainHomeRealmDiscovery';
  connections?: Maybe<Array<Auth0Connection>>;
  hasHRD: Scalars['Boolean']['output'];
};

export type Auth0MembershipInvitation = {
  __typename?: 'Auth0MembershipInvitation';
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  ticketId: Scalars['ID']['output'];
};

export type Auth0Organization = {
  __typename?: 'Auth0Organization';
  auth0OrganizationId: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Auth0ProvisionUserToOrganization = {
  __typename?: 'Auth0ProvisionUserToOrganization';
  organisation?: Maybe<Auth0Organization>;
  user?: Maybe<Auth0User>;
};

export type Auth0User = {
  __typename?: 'Auth0User';
  connection: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type AutoSetupCesEntityRequest = {
  activateChannelTemplateId: Scalars['String']['input'];
  cesAccountId: Scalars['String']['input'];
  parentChannelId: Scalars['String']['input'];
  vcmBuildingIds: Array<Scalars['ID']['input']>;
};

export type AutoSetupCesEntityResponse = {
  __typename?: 'AutoSetupCesEntityResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BiltSso = {
  __typename?: 'BiltSso';
  ssoLink?: Maybe<Scalars['String']['output']>;
};

export type Block = Document & {
  __typename?: 'Block';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  availableForContentTypes?: Maybe<Array<Maybe<ContentType>>>;
  block?: Maybe<Scalars['JSON']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  dependencies?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  for?: Maybe<BlockForType>;
  name: Scalars['String']['output'];
  properties?: Maybe<Scalars['JSON']['output']>;
  propertiesOptions?: Maybe<Scalars['JSON']['output']>;
  publishes?: Maybe<Scalars['JSON']['output']>;
  renderer: Scalars['Int']['output'];
  settings?: Maybe<Scalars['JSON']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  styleState?: Maybe<Scalars['JSON']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<BlockType>;
  version: Scalars['Int']['output'];
};

export enum BlockForType {
  Channel = 'Channel',
  Content = 'Content',
  Notice = 'Notice',
  Perk = 'Perk',
  Promotion = 'Promotion',
  Section = 'Section',
  Static = 'Static',
  User = 'User'
}

export type BlockIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BlockInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  availableForContentTypes?: InputMaybe<StringListInput>;
  block?: InputMaybe<Scalars['JSON']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  dependencies?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  for?: InputMaybe<BlockForType>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  propertiesOptions?: InputMaybe<Scalars['JSON']['input']>;
  publishes?: InputMaybe<Scalars['JSON']['input']>;
  renderer?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  state?: InputMaybe<Scalars['JSON']['input']>;
  styleState?: InputMaybe<Scalars['JSON']['input']>;
  tags?: InputMaybe<StringListInput>;
  type?: InputMaybe<BlockType>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

/** Content search input type */
export type BlockSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  availableForContentTypes?: InputMaybe<Array<InputMaybe<ContentType>>>;
  description?: InputMaybe<StringSearch>;
  for?: InputMaybe<BlockForType>;
  name?: InputMaybe<StringSearch>;
  renderer?: InputMaybe<NumericSearchType>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  tags?: InputMaybe<StringListSearch>;
  type?: InputMaybe<StringSearch>;
  version?: InputMaybe<NumericSearchType>;
};

export enum BlockType {
  Block = 'Block',
  Card = 'Card',
  Container = 'Container',
  Divider = 'Divider',
  List = 'List'
}

export type BluetoothInvitationCode = {
  __typename?: 'BluetoothInvitationCode';
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  invitationCode?: Maybe<Scalars['String']['output']>;
};

export type BookingCompany = {
  __typename?: 'BookingCompany';
  id: Scalars['UUID']['output'];
  joinedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type Building = Document & {
  __typename?: 'Building';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalBuildingId?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Scalars['JSON']['output']>;
  suites?: Maybe<Array<Maybe<Suite>>>;
};

export type BuildingInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  externalBuildingId?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  suites?: InputMaybe<Array<InputMaybe<SuiteInput>>>;
};

export type BuildingTenantChannel = {
  __typename?: 'BuildingTenantChannel';
  channelId: Scalars['UUID']['output'];
  hasFilters?: Maybe<Scalars['Boolean']['output']>;
  inviteOnly?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CancelRecurringChargeRequest = {
  externalActorId: Scalars['String']['input'];
  externalActorType: ExternalActorType;
  recurrenceId: Scalars['String']['input'];
};

export type CancelRecurringChargeResponse = {
  __typename?: 'CancelRecurringChargeResponse';
  recurrenceId: Scalars['String']['output'];
};

export type CancelSubmissionResponse = {
  __typename?: 'CancelSubmissionResponse';
  id: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  autoAssigneeTeam?: Maybe<Scalars['String']['output']>;
  autoAssigneeUser?: Maybe<Scalars['String']['output']>;
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CategoryUpsertRequestDto = {
  autoAssigneeTeam?: InputMaybe<Scalars['String']['input']>;
  autoAssigneeUser?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CategoryUpsertResponseDto = {
  __typename?: 'CategoryUpsertResponseDTO';
  id: Scalars['String']['output'];
};

export type Channel = Document & {
  __typename?: 'Channel';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  address?: Maybe<Address>;
  analytics: ObjectAnalytics;
  building?: Maybe<Array<Maybe<Building>>>;
  channelModules?: Maybe<Array<ChannelModule>>;
  content?: Maybe<Array<Content>>;
  description?: Maybe<Scalars['String']['output']>;
  experienceType?: Maybe<ChannelExperienceTypeEnum>;
  filters?: Maybe<Array<Scalars['String']['output']>>;
  groupRoles?: Maybe<Array<GroupRole>>;
  hasCustomOffboarding?: Maybe<Scalars['Boolean']['output']>;
  hierarchy?: Maybe<ChannelHierarchy>;
  info?: Maybe<UserObjectInfo>;
  integrations?: Maybe<Array<ChannelIntegration>>;
  interactions?: Maybe<Array<UserContentInteraction>>;
  inviteOnly: Scalars['Boolean']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isCustomer?: Maybe<Scalars['Boolean']['output']>;
  isDiscoverable: Scalars['Boolean']['output'];
  isLive: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSub: Scalars['Boolean']['output'];
  libraryPaths?: Maybe<Array<Scalars['Path']['output']>>;
  libraryTags?: Maybe<Array<Scalars['String']['output']>>;
  merchantAccount?: Maybe<ChannelMerchant>;
  name: Scalars['String']['output'];
  notices?: Maybe<Array<Content>>;
  pages?: Maybe<Array<ChannelPage>>;
  parent?: Maybe<Channel>;
  paymentAccounts?: Maybe<Array<PaymentAccount>>;
  paymentProcessors?: Maybe<Array<PaymentProcessor>>;
  perks?: Maybe<Array<Content>>;
  profile: Profile;
  promotions?: Maybe<Array<Content>>;
  /** Get a list of all the reservations for a given channel in reservable management calendar */
  reservablesOnChannel?: Maybe<ReservableConnection>;
  roles?: Maybe<Array<UserGroupRole>>;
  sections?: Maybe<Array<Section>>;
  settings?: Maybe<ChannelSettings>;
  slug: Scalars['String']['output'];
  special?: Maybe<Scalars['JSON']['output']>;
  /** Gets any special permissions the current user may have on this channe; */
  specialPermissions?: Maybe<Array<Scalars['Permission']['output']>>;
  stats?: Maybe<ChannelStats>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  team?: Maybe<Array<PublicTeam>>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  theme?: Maybe<Theme>;
  type: ChannelType;
  website?: Maybe<Scalars['String']['output']>;
  whitelabels?: Maybe<Array<WhitelabelChannel>>;
};


export type ChannelContentArgs = {
  search?: InputMaybe<ContentSearch>;
};


export type ChannelReservablesOnChannelArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  search?: InputMaybe<ReservablesOnChannelSearchInput>;
};

export type ChannelAccessIntegration = {
  __typename?: 'ChannelAccessIntegration';
  channelId: Scalars['UUID']['output'];
  channelName: Scalars['String']['output'];
  connectedIntegrations?: Maybe<Array<Maybe<AccessIntegration>>>;
  integration?: Maybe<AccessIntegration>;
};

export type ChannelContract = Document & {
  __typename?: 'ChannelContract';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel: Channel;
  contactUser?: Maybe<User>;
  lastContact: Scalars['DateTime']['output'];
  lineItems: Array<ChannelContractLineItem>;
  managerUser?: Maybe<User>;
  name?: Maybe<Scalars['String']['output']>;
  salesUser?: Maybe<User>;
  settings?: Maybe<Scalars['JSON']['output']>;
  supportUser?: Maybe<User>;
};

export type ChannelContractIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChannelContractInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  contactUser?: InputMaybe<UserIdInput>;
  lastContact?: InputMaybe<Scalars['DateTime']['input']>;
  lineItems?: InputMaybe<Array<InputMaybe<ChannelContractLineItemInput>>>;
  managerUser?: InputMaybe<UserIdInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  salesUser?: InputMaybe<UserIdInput>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  supportUser?: InputMaybe<UserIdInput>;
};

export type ChannelContractLineItem = Document & {
  __typename?: 'ChannelContractLineItem';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channelContract: ChannelContract;
  currency?: Maybe<Scalars['String']['output']>;
  estimatedSubscriptionStartDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pricePerUnit?: Maybe<Scalars['Int']['output']>;
  settings?: Maybe<Scalars['JSON']['output']>;
  subscriptionStartDate?: Maybe<Scalars['DateTime']['output']>;
  termLengthMonths?: Maybe<Scalars['Int']['output']>;
  unitType: ContractUnitType;
  units?: Maybe<Scalars['Int']['output']>;
};

export type ChannelContractLineItemIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChannelContractLineItemInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channelContract?: InputMaybe<ChannelIdInput>;
  currency?: InputMaybe<Scalars['String']['input']>;
  estimatedSubscriptionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pricePerUnit?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  subscriptionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  termLengthMonths?: InputMaybe<Scalars['Int']['input']>;
  unitType?: InputMaybe<ContractUnitType>;
  units?: InputMaybe<Scalars['Int']['input']>;
};

/** Channel Contract Search input. */
export type ChannelContractLineItemSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channelContract?: InputMaybe<ChannelContractSearch>;
  currency?: InputMaybe<StringSearch>;
  estimatedSubscriptionStartDate?: InputMaybe<DateTimeSearch>;
  name?: InputMaybe<StringSearch>;
  pricePerUnit?: InputMaybe<IntSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  subscriptionStartDate?: InputMaybe<DateTimeSearch>;
  termLengthMonths?: InputMaybe<IntSearch>;
  unitType?: InputMaybe<ContractUnitType>;
  units?: InputMaybe<IntSearch>;
};

export type ChannelContractPagination = {
  __typename?: 'ChannelContractPagination';
  items?: Maybe<Array<ChannelContract>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Channel Contract Search input. */
export type ChannelContractSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  contactUser?: InputMaybe<AdminUserSearch>;
  lastContact?: InputMaybe<DateTimeSearch>;
  lineItems?: InputMaybe<ChannelContractLineItemSearch>;
  managerUser?: InputMaybe<AdminUserSearch>;
  name?: InputMaybe<StringSearch>;
  salesUser?: InputMaybe<AdminUserSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  supportUser?: InputMaybe<AdminUserSearch>;
};

export type ChannelEquipmentSettings = {
  __typename?: 'ChannelEquipmentSettings';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  channel: Channel;
  id: Scalars['UUID']['output'];
  locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ChannelEquipmentSettingsInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum ChannelExperienceTypeEnum {
  Multifamily = 'multifamily',
  Office = 'office'
}

export type ChannelGroupRoleInput = {
  externalId?: InputMaybe<Scalars['UUID']['input']>;
  externalType?: InputMaybe<Scalars['String']['input']>;
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  permissions?: InputMaybe<PermissionListInput>;
  user?: InputMaybe<UserIdInput>;
};

export type ChannelHierarchy = {
  __typename?: 'ChannelHierarchy';
  _id: Scalars['UUID']['output'];
  hierarchyDown?: Maybe<Array<Scalars['UUID']['output']>>;
  hierarchyUp?: Maybe<Array<Scalars['UUID']['output']>>;
};

export type ChannelIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChannelInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  address?: InputMaybe<AddressInput>;
  analytics?: InputMaybe<ObjectAnalyticsInput>;
  building?: InputMaybe<BuildingInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  experienceType?: InputMaybe<ChannelExperienceTypeEnum>;
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  groupRoles?: InputMaybe<Array<InputMaybe<GroupRoleInput>>>;
  hasCustomOffboarding?: InputMaybe<Scalars['Boolean']['input']>;
  info?: InputMaybe<UserObjectInfoInput>;
  inviteOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  isDiscoverable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSub?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pages?: InputMaybe<Array<ChannelPageInput>>;
  parent?: InputMaybe<ChannelIdInput>;
  profile?: InputMaybe<ProfileInput>;
  settings?: InputMaybe<ChannelSettingsInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  special?: InputMaybe<Scalars['JSON']['input']>;
  stats?: InputMaybe<ChannelStatsInput>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<ChannelType>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ChannelIntegration = Document & {
  __typename?: 'ChannelIntegration';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel: Channel;
  content?: Maybe<Content>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  integration: Integration;
  settings?: Maybe<Scalars['JSON']['output']>;
};

export type ChannelIntegrationIdInput = {
  _id: Scalars['UUID']['input'];
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChannelIntegrationInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  integration?: InputMaybe<IntegrationInput>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
};

export type ChannelIntegrationSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  integration?: InputMaybe<IntegrationSearch>;
  integrationId?: InputMaybe<Scalars['UUID']['input']>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

/**
 * ChannelInvite has email field of String type instead of EmailAddress scalar type to
 * avoid issues arising from unnecessary email validations when returning data
 * to the client. Because of this, ChannelInvite type is only intended to be
 * used in the return type of any query or mutation. It should not be used in the input
 * type or any mutation parameters due to lack of email validation.
 */
export type ChannelInvite = Document & {
  __typename?: 'ChannelInvite';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel: Channel;
  company?: Maybe<Channel>;
  email: Scalars['String']['output'];
  expires: Scalars['DateTime']['output'];
  groupRole: GroupRole;
  groupRoleIds?: Maybe<Array<Scalars['UUID']['output']>>;
  groupRoles?: Maybe<Array<GroupRole>>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  postInviteActions?: Maybe<Scalars['JSON']['output']>;
  result?: Maybe<Scalars['JSON']['output']>;
  sent: Scalars['DateTime']['output'];
  status: ChannelInviteStatusType;
};

export type ChannelInviteInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  company?: InputMaybe<ChannelIdInput>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  /** @deprecated groupRoleId is deprecated. Use groupRoleIds instead to support inviting user to multiple groups. */
  groupRole?: InputMaybe<GroupRoleIdInput>;
  groupRoleIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  postInviteActions?: InputMaybe<Scalars['JSON']['input']>;
  result?: InputMaybe<Scalars['JSON']['input']>;
  sent?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<ChannelInviteStatusType>;
};

export type ChannelInvitePagination = {
  __typename?: 'ChannelInvitePagination';
  items?: Maybe<Array<ChannelInvite>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChannelInviteSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  company?: InputMaybe<ChannelSearch>;
  email?: InputMaybe<StringSearch>;
  expires?: InputMaybe<DateTimeSearch>;
  groupRole?: InputMaybe<GroupRoleSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  status?: InputMaybe<ChannelInviteStatusType>;
};

export enum ChannelInviteStatusType {
  Accepted = 'Accepted',
  Created = 'Created',
  Declined = 'Declined',
  Failed = 'Failed',
  Rejected = 'Rejected',
  Resent = 'Resent',
  Sent = 'Sent'
}

export type ChannelMerchant = Document & {
  __typename?: 'ChannelMerchant';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  account: Scalars['ID']['output'];
  channel: Channel;
  flatFee: Scalars['Int']['output'];
  onboardingLink?: Maybe<StripeAccountLink>;
  percentageFee: Scalars['Int']['output'];
  status?: Maybe<Scalars['String']['output']>;
  stripeAccount?: Maybe<StripeAccount>;
  taxes?: Maybe<Array<Tax>>;
};

export type ChannelMerchantIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChannelMerchantInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  account?: InputMaybe<Scalars['ID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  enabled: Scalars['Boolean']['input'];
  flatFee?: InputMaybe<Scalars['Int']['input']>;
  percentageFee?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  taxes?: InputMaybe<Scalars['JSON']['input']>;
};

/** ChannelMerchant Search input */
export type ChannelMerchantSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  account?: InputMaybe<StringSearch>;
  channel?: InputMaybe<ChannelSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type ChannelModule = Document & {
  __typename?: 'ChannelModule';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  allowsMultipleInstances: Scalars['Boolean']['output'];
  autoCreatesContent: Scalars['Boolean']['output'];
  availableFeatures?: Maybe<Array<Maybe<FeatureName>>>;
  channelId?: Maybe<Scalars['String']['output']>;
  contentType: ContentType;
  createsAdminPage: Scalars['Boolean']['output'];
  dataDefinition?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  friendlyName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<Scalars['JSON']['output']>;
  template?: Maybe<ContentTemplate>;
};

export type ChannelModuleInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  allowsMultipleInstances?: InputMaybe<Scalars['Boolean']['input']>;
  autoCreatesContent?: InputMaybe<Scalars['Boolean']['input']>;
  availableFeatures?: InputMaybe<Array<InputMaybe<FeatureName>>>;
  channel?: InputMaybe<ChannelIdInput>;
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  contentType?: InputMaybe<ContentType>;
  createsAdminPage?: InputMaybe<Scalars['Boolean']['input']>;
  dataDefinition?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  friendlyName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  template?: InputMaybe<ContentTemplateInput>;
};

export type ChannelPage = Document & {
  __typename?: 'ChannelPage';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order: Scalars['Int']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel: Channel;
  content: Content;
  icon: Scalars['String']['output'];
  iconSet: Scalars['String']['output'];
  iconWeight?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  label_l10n?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ChannelPageInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _order?: InputMaybe<Scalars['Int']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  content?: InputMaybe<ContentIdInput>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconSet?: InputMaybe<Scalars['String']['input']>;
  iconWeight?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_l10n?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ChannelPagination = {
  __typename?: 'ChannelPagination';
  items?: Maybe<Array<Channel>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Channel Search input.  Allows you to search channels by permission. */
export type ChannelPermissionSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  address?: InputMaybe<AddressSearch>;
  description?: InputMaybe<StringSearch>;
  groupRoles?: InputMaybe<GroupRoleSearch>;
  hierarchyDown?: InputMaybe<Scalars['UUID']['input']>;
  hierarchyUp?: InputMaybe<Scalars['UUID']['input']>;
  includeChannelsFromHierarchy?: InputMaybe<Scalars['Boolean']['input']>;
  integrations?: InputMaybe<ChannelIntegrationSearch>;
  inviteOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  isDiscoverable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSub?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringSearch>;
  parent?: InputMaybe<ChannelSearch>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  profile?: InputMaybe<ProfileSearch>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortBy>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<StringSearch>;
  userRoles?: InputMaybe<UserGroupRoleSearch>;
  whitelabels?: InputMaybe<WhitelabelChannelSearch>;
};

export type ChannelRelationship = Document & {
  __typename?: 'ChannelRelationship';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel: Channel;
  relatedTo: Channel;
  suiteIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<ChannelRelationshipType>;
};

export type ChannelRelationshipInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel: ChannelInput;
  relatedTo: ChannelIdInput;
  suiteIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: ChannelRelationshipType;
};

export type ChannelRelationshipPagination = {
  __typename?: 'ChannelRelationshipPagination';
  items?: Maybe<Array<ChannelRelationship>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Channel Relationship Search input.  Allows you to search channels. */
export type ChannelRelationshipSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  relatedTo?: InputMaybe<ChannelSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  type?: InputMaybe<ChannelRelationshipType>;
};

export enum ChannelRelationshipType {
  Tenant = 'Tenant'
}

/** Channel Search input.  Allows you to search channels. */
export type ChannelSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  address?: InputMaybe<AddressSearch>;
  description?: InputMaybe<StringSearch>;
  groupRoles?: InputMaybe<GroupRoleSearch>;
  hierarchyDown?: InputMaybe<Scalars['UUID']['input']>;
  hierarchyUp?: InputMaybe<Scalars['UUID']['input']>;
  includeChannelsFromHierarchy?: InputMaybe<Scalars['Boolean']['input']>;
  integrations?: InputMaybe<ChannelIntegrationSearch>;
  inviteOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  isDiscoverable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSub?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringSearch>;
  parent?: InputMaybe<ChannelSearch>;
  profile?: InputMaybe<ProfileSearch>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortBy>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<StringSearch>;
  userRoles?: InputMaybe<UserGroupRoleSearch>;
  whitelabels?: InputMaybe<WhitelabelChannelSearch>;
};

export type ChannelSettings = {
  __typename?: 'ChannelSettings';
  areUserTemplatesAllowed?: Maybe<Scalars['Boolean']['output']>;
  channelLanguages?: Maybe<Scalars['JSON']['output']>;
  effectiveParentRequirements?: Maybe<RequirementsFeatureProperties>;
  enabledLanguagesForMembers?: Maybe<Scalars['JSON']['output']>;
  groupRoleJoinRules?: Maybe<Array<GroupRoleJoinRule>>;
  hasAccessControlEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasBillingPaymentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasHardwareManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasHardwarePinManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated only used briefly - keeping it for backward compatibility with specific version of the app 5.187.x */
  hasInstantMessagingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  hasLocalRetailPromotions?: Maybe<Scalars['Boolean']['output']>;
  hasMessagingAndMarketplaceEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  hasOfficeDirectory?: Maybe<Scalars['Boolean']['output']>;
  hasPerksEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasReservableManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  hasRetailDirectory?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  hasRetailPromotions?: Maybe<Scalars['Boolean']['output']>;
  hasSmartUserManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasSurveysEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasVisitorManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasWorkOrderCrossPropertyEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasWorkOrderEquipmentEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasWorkOrderPreventiveMaintenanceEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasWorkOrderServiceRequestsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated replaced by granular work order toggles */
  hasWorkOrdersEnabled?: Maybe<Scalars['Boolean']['output']>;
  maxUsers?: Maybe<Scalars['Int']['output']>;
  multiLanguageEnabled?: Maybe<Scalars['Boolean']['output']>;
  offboardingDelayDays?: Maybe<Scalars['Int']['output']>;
  requirements?: Maybe<RequirementsFeatureProperties>;
  /** @deprecated since 5.19.0, replaced by groupRoleJoinRules */
  subscribersAlsoJoin?: Maybe<Array<Scalars['UUID']['output']>>;
  subscribersAreDecoyed?: Maybe<Scalars['Boolean']['output']>;
  subscribersJoinParent?: Maybe<Scalars['Boolean']['output']>;
};

export type ChannelSettingsInput = {
  areUserTemplatesAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  channelLanguages?: InputMaybe<Scalars['JSON']['input']>;
  enabledLanguagesForMembers?: InputMaybe<Scalars['JSON']['input']>;
  groupRoleJoinRules?: InputMaybe<Array<GroupRoleJoinRuleInput>>;
  hasAccessControlEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasBillingPaymentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasHardwareManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasHardwarePinManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasInstantMessagingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasMessagingAndMarketplaceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasPerksEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasReservableManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasSmartUserManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasSurveysEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasVisitorManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasWorkOrderCrossPropertyEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasWorkOrderEquipmentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasWorkOrderPreventiveMaintenanceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasWorkOrderServiceRequestsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasWorkOrdersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  maxUsers?: InputMaybe<Scalars['Int']['input']>;
  multiLanguageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  offboardingDelayDays?: InputMaybe<Scalars['Int']['input']>;
  requirements?: InputMaybe<RequirementsFeaturePropertiesInput>;
  subscribersAreDecoyed?: InputMaybe<Scalars['Boolean']['input']>;
  subscribersJoinParent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChannelStats = {
  __typename?: 'ChannelStats';
  sf?: Maybe<Scalars['Int']['output']>;
  subscribers?: Maybe<Scalars['Int']['output']>;
};

export type ChannelStatsInput = {
  sf?: InputMaybe<Scalars['Int']['input']>;
  subscribers?: InputMaybe<Scalars['Int']['input']>;
};

export enum ChannelType {
  Charity = 'Charity',
  Company = 'Company',
  Curated = 'Curated',
  Entertainment = 'Entertainment',
  Meta = 'Meta',
  Organization = 'Organization',
  Property = 'Property',
  Restaurant = 'Restaurant',
  Retail = 'Retail',
  Service = 'Service'
}

export type Charge = {
  __typename?: 'Charge';
  amount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']['output']>;
  externalActorId: Scalars['String']['output'];
  externalActorType: ExternalActorType;
  externalEntityId?: Maybe<Scalars['String']['output']>;
  externalEntityType?: Maybe<ExternalEntityType>;
  externalPayerId: Scalars['String']['output'];
  externalPayerType: ExternalPayerType;
  groupId: Scalars['String']['output'];
  groupType?: Maybe<GroupType>;
  id?: Maybe<Scalars['String']['output']>;
  invoice?: Maybe<InvoicePartial>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<ProductItem>>>;
  metadata?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChargeBillingReport = {
  __typename?: 'ChargeBillingReport';
  chargeCode?: Maybe<Scalars['String']['output']>;
  chargeId: Scalars['String']['output'];
  chargeMonth: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Currency;
  description: Scalars['String']['output'];
  entity?: Maybe<Entity>;
  entityDisplayId?: Maybe<Scalars['String']['output']>;
  entityDisplayType?: Maybe<Scalars['String']['output']>;
  glCode?: Maybe<Scalars['String']['output']>;
  group: Group;
  leaseId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  payer: Payer;
  propertyId?: Maybe<Scalars['String']['output']>;
  suiteId?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['Float']['output'];
};

export type ChargeBillingReportsResponse = {
  __typename?: 'ChargeBillingReportsResponse';
  pageInfo?: Maybe<PageInfo>;
  reports?: Maybe<Array<Maybe<ChargeBillingReport>>>;
};

export type ChargeCode = {
  __typename?: 'ChargeCode';
  /** The user friendly name of the charge code */
  chargeCode: Scalars['String']['output'];
  /** The description */
  description?: Maybe<Scalars['String']['output']>;
  /** The GL color of the charge code */
  glCode: Scalars['String']['output'];
  /** The channel id the charge code was created at */
  groupId: Scalars['String']['output'];
  groupType: GroupType;
  /** The UUID */
  id: Scalars['String']['output'];
};

export enum ChargeCodeSource {
  ChargeCodeSourceActivate = 'CHARGE_CODE_SOURCE_ACTIVATE',
  ChargeCodeSourceIntegration = 'CHARGE_CODE_SOURCE_INTEGRATION'
}

export type ChargeReportFilters = {
  chargeCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdDateRange?: InputMaybe<DateRangeInput>;
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  entityType?: InputMaybe<ExternalEntityType>;
  leaseIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payers?: InputMaybe<Array<InputMaybe<PayerFilter>>>;
  propertyIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ChargeTemplate = {
  __typename?: 'ChargeTemplate';
  amount: Scalars['Float']['output'];
  currency: Currency;
  description?: Maybe<Scalars['String']['output']>;
  externalActorId?: Maybe<Scalars['String']['output']>;
  externalActorType?: Maybe<ExternalActorType>;
  externalEntityId?: Maybe<Scalars['String']['output']>;
  externalEntityType?: Maybe<ExternalEntityType>;
  externalPayerId: Scalars['String']['output'];
  externalPayerType: ExternalPayerType;
  groupId: Scalars['String']['output'];
  groupType: GroupType;
  items?: Maybe<Array<Maybe<ProductItem>>>;
  name: Scalars['String']['output'];
};

export type ChargesFilters = {
  externalEntityIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalEntityType?: InputMaybe<ExternalEntityType>;
};

export type ClaimedChannelInvite = Document & {
  __typename?: 'ClaimedChannelInvite';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  claimed: Scalars['Boolean']['output'];
  claimedBy?: Maybe<PublicUser>;
  claimedOn?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['EmailAddress']['output'];
  expires: Scalars['DateTime']['output'];
  groupRole: GroupRole;
  name: Scalars['String']['output'];
  result?: Maybe<Scalars['JSON']['output']>;
  sent: Scalars['DateTime']['output'];
  status: ChannelInviteStatusType;
};

export type ClaimedChannelInviteInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  acsUserMappingObject?: InputMaybe<Scalars['JSON']['input']>;
  claimed?: InputMaybe<Scalars['Boolean']['input']>;
  claimedBy?: InputMaybe<Scalars['UUID']['input']>;
  claimedOn?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  /** @deprecated groupRoleId is deprecated. Use groupRoleIds instead to support inviting user to multiple groups. */
  groupRole?: InputMaybe<GroupRoleIdInput>;
  groupRoleIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  result?: InputMaybe<Scalars['JSON']['input']>;
  sent?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<ChannelInviteStatusType>;
};

export type ClaimedChannelInviteSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  claimedBy?: InputMaybe<Scalars['UUID']['input']>;
  claimedOn?: InputMaybe<DateTimeSearch>;
  email?: InputMaybe<StringSearch>;
  expires?: InputMaybe<DateTimeSearch>;
  groupRole?: InputMaybe<GroupRoleSearch>;
  groupRoleId?: InputMaybe<Scalars['UUID']['input']>;
  guid?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sent?: InputMaybe<DateTimeSearch>;
  status?: InputMaybe<ChannelInviteStatusType>;
};

export type ClientPmSchedule = {
  __typename?: 'ClientPMSchedule';
  assignee?: Maybe<IdNamePair>;
  assigneeGroups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  completeWithin?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  daysAhead?: Maybe<Scalars['Int']['output']>;
  equipmentIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  extRefId?: Maybe<IdNamePair>;
  interval?: Maybe<Scalars['Int']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  meterReading?: Maybe<Scalars['String']['output']>;
  monthNo?: Maybe<Scalars['Int']['output']>;
  nextDueDate?: Maybe<Scalars['DateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  repeats?: Maybe<Scalars['String']['output']>;
  scheduleId?: Maybe<Scalars['String']['output']>;
  stepTemplate?: Maybe<Array<Maybe<StepTemplate>>>;
  steps?: Maybe<Scalars['String']['output']>;
  timeToComplete?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  untilDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  weekNo?: Maybe<Scalars['Int']['output']>;
  weekday?: Maybe<Scalars['Int']['output']>;
};

export type ClientTask = {
  __typename?: 'ClientTask';
  actualTimeToComplete?: Maybe<Scalars['Int']['output']>;
  assignee?: Maybe<User>;
  assigneeGroup?: Maybe<Array<GroupRole>>;
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  completeNotes?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  equipment?: Maybe<Array<TaskEquipmentDto>>;
  estimateTimeToComplete: Scalars['Float']['output'];
  friendlyID: Scalars['String']['output'];
  groupBy: IdNamePair;
  hours?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  location: Scalars['String']['output'];
  meterReading?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  nextStatuses?: Maybe<Array<Scalars['String']['output']>>;
  notes?: Maybe<Scalars['String']['output']>;
  repeats: TaskRepeatDto;
  schedule?: Maybe<TaskScheduleDto>;
  scheduleNotes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stepExecution?: Maybe<Array<StepExecution>>;
  steps?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userFriendlyID: Scalars['String']['output'];
};

export type CloneChannelOptionsInput = {
  includeChannelPages?: InputMaybe<Scalars['Boolean']['input']>;
  includeContent?: InputMaybe<Scalars['Boolean']['input']>;
  includeGroupRoles?: InputMaybe<Scalars['Boolean']['input']>;
  includeLibraries?: InputMaybe<Scalars['Boolean']['input']>;
  includeMultiLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  includePages?: InputMaybe<Scalars['Boolean']['input']>;
  includeSectionContent?: InputMaybe<Scalars['Boolean']['input']>;
  includeSections?: InputMaybe<Scalars['Boolean']['input']>;
  newAddress?: InputMaybe<AddressInput>;
  newName?: InputMaybe<Scalars['String']['input']>;
  newParentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CompleteSignupData = {
  __typename?: 'CompleteSignupData';
  _id: Scalars['UUID']['output'];
  clientTypeName: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  name: Scalars['String']['output'];
};

export type CompleteTask = {
  actualTimeToComplete?: InputMaybe<Scalars['Int']['input']>;
  completeNotes?: InputMaybe<Scalars['String']['input']>;
  completedBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteTaskResponseDto = {
  __typename?: 'CompleteTaskResponseDTO';
  completedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
};

export type Configuration = {
  __typename?: 'Configuration';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type Content = ContentInterface & {
  __typename?: 'Content';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order: Scalars['Int']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  actions?: Maybe<Array<ContentWorkflow>>;
  analytics: ObjectAnalytics;
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  block?: Maybe<Scalars['JSON']['output']>;
  card: Block;
  category?: Maybe<Scalars['ContentCategoryType']['output']>;
  channel: Channel;
  color?: Maybe<Scalars['Color']['output']>;
  contentMetatags?: Maybe<Array<ObjectMetatag>>;
  data?: Maybe<Scalars['JSON']['output']>;
  deepLink?: Maybe<DeepLink>;
  description?: Maybe<Scalars['String']['output']>;
  description_l10n?: Maybe<Scalars['JSON']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalKey?: Maybe<Scalars['ID']['output']>;
  externalUrl?: Maybe<ExternalUrl>;
  features?: Maybe<Array<ContentFeature>>;
  /** @deprecated No longer supported */
  generatedChildren?: Maybe<ContentPagination>;
  generator?: Maybe<ContentGenerator>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconSet?: Maybe<Scalars['String']['output']>;
  iconWeight?: Maybe<Scalars['String']['output']>;
  info?: Maybe<UserObjectInfo>;
  integration?: Maybe<ChannelIntegration>;
  interactionEndDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Deprecated, use userInteractions
   * @deprecated No longer supported
   */
  interactions?: Maybe<Array<UserContentInteraction>>;
  isInteractive?: Maybe<Scalars['Boolean']['output']>;
  isSyndicateable?: Maybe<Scalars['Boolean']['output']>;
  lastReset: Scalars['DateTime']['output'];
  liveDate?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  name_l10n?: Maybe<Scalars['JSON']['output']>;
  nextReset: Scalars['DateTime']['output'];
  notifications?: Maybe<Array<ContentNotification>>;
  placements?: Maybe<Array<ContentPlacement>>;
  properties?: Maybe<Scalars['JSON']['output']>;
  propertiesOptions?: Maybe<Scalars['JSON']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedBy?: Maybe<PublicUser>;
  relatedChannelIntegrationIds?: Maybe<Array<Scalars['UUID']['output']>>;
  renderer?: Maybe<Scalars['Int']['output']>;
  reportingGroup?: Maybe<Scalars['ContentCategoryType']['output']>;
  resetPeriod: ResetPeriodType;
  sectionContent?: Maybe<Array<SectionContent>>;
  signedExternalUrl?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  subtitle_l10n?: Maybe<Scalars['JSON']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  theme?: Maybe<Theme>;
  type: ContentType;
  unpublishDate?: Maybe<Scalars['DateTime']['output']>;
  userAccess?: Maybe<ContentAccessType>;
  userInteractions: UserContentInteractionPagination;
  version?: Maybe<Scalars['Int']['output']>;
};


export type ContentGeneratedChildrenArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ContentSearch>;
};


export type ContentUserInteractionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<UserContentInteractionSearch>;
};

export enum ContentAccessType {
  Interact = 'Interact',
  Restricted = 'Restricted',
  View = 'View'
}

export type ContentDateRanges = {
  __typename?: 'ContentDateRanges';
  _id: Scalars['UUID']['output'];
  contentId: Scalars['UUID']['output'];
  dateRanges: Array<DateRange>;
};

export type ContentFeature = Document & {
  __typename?: 'ContentFeature';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  feature: Scalars['JSON']['output'];
  type: FeatureName;
};

export type ContentFeatureInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  content?: InputMaybe<DraftContentIdInput>;
  feature: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type ContentFeatureStatusSummary = {
  __typename?: 'ContentFeatureStatusSummary';
  count: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export enum ContentFilterType {
  AvailableNow = 'AvailableNow',
  ByChannel = 'ByChannel',
  ByChannelLocations = 'ByChannelLocations',
  ByEventDate = 'ByEventDate',
  FeaturePaymentPrice = 'FeaturePaymentPrice',
  FeatureQuantityRemaining = 'FeatureQuantityRemaining',
  FeatureReservableAvailableDays = 'FeatureReservableAvailableDays',
  FeatureReservableAvailableMinutes = 'FeatureReservableAvailableMinutes',
  FeatureReservableAvailableMonths = 'FeatureReservableAvailableMonths',
  FeatureReservableAvailableWeeks = 'FeatureReservableAvailableWeeks',
  Location = 'Location',
  SearchBox = 'SearchBox'
}

export type ContentGenerator = Document & {
  __typename?: 'ContentGenerator';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  content: DraftContent;
  datasetSchema: DatasetSchema;
};

export type ContentGeneratorInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  content?: InputMaybe<DraftContentIdInput>;
  datasetSchema?: InputMaybe<DatasetSchemaInput>;
};

export enum ContentGroupByType {
  Category = 'Category',
  Channel = 'Channel',
  None = 'None'
}

export type ContentIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _order?: InputMaybe<Scalars['Int']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  actions?: InputMaybe<Array<ContentWorkflowInput>>;
  analytics?: InputMaybe<ObjectAnalyticsInput>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  backgroundImage?: InputMaybe<Scalars['Image']['input']>;
  block?: InputMaybe<Scalars['JSON']['input']>;
  card?: InputMaybe<BlockIdInput>;
  category?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  color?: InputMaybe<Scalars['Color']['input']>;
  contentMetatags?: InputMaybe<Array<ObjectMetatagInput>>;
  contentTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deepLink?: InputMaybe<DeepLinkInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_l10n?: InputMaybe<Scalars['JSON']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  externalKey?: InputMaybe<Scalars['ID']['input']>;
  externalUrl?: InputMaybe<ExternalUrlInput>;
  features?: InputMaybe<Array<ContentFeatureInput>>;
  geo?: InputMaybe<Scalars['GeoPoint']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconSet?: InputMaybe<Scalars['String']['input']>;
  iconWeight?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<UserObjectInfoInput>;
  integration?: InputMaybe<ChannelIntegrationIdInput>;
  interactionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  isSyndicateable?: InputMaybe<Scalars['Boolean']['input']>;
  lastReset?: InputMaybe<Scalars['DateTime']['input']>;
  liveDate?: InputMaybe<Scalars['DateTime']['input']>;
  logo?: InputMaybe<Scalars['Image']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_l10n?: InputMaybe<Scalars['JSON']['input']>;
  nextReset?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<Array<ContentNotificationInput>>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  propertiesOptions?: InputMaybe<Scalars['JSON']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedBy?: InputMaybe<Scalars['UUID']['input']>;
  relatedChannelIntegrationIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  renderer?: InputMaybe<Scalars['Int']['input']>;
  reportingGroup?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  resetPeriod?: InputMaybe<ResetPeriodType>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['JSON']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  theme?: InputMaybe<ThemeIdInput>;
  type?: InputMaybe<ContentType>;
  unpublishDate?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentInterface = {
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order: Scalars['Int']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  actions?: Maybe<Array<ContentWorkflow>>;
  analytics: ObjectAnalytics;
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  block?: Maybe<Scalars['JSON']['output']>;
  card: Block;
  category?: Maybe<Scalars['ContentCategoryType']['output']>;
  channel: Channel;
  color?: Maybe<Scalars['Color']['output']>;
  contentMetatags?: Maybe<Array<ObjectMetatag>>;
  data?: Maybe<Scalars['JSON']['output']>;
  deepLink?: Maybe<DeepLink>;
  description?: Maybe<Scalars['String']['output']>;
  description_l10n?: Maybe<Scalars['JSON']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalKey?: Maybe<Scalars['ID']['output']>;
  externalUrl?: Maybe<ExternalUrl>;
  features?: Maybe<Array<ContentFeature>>;
  generator?: Maybe<ContentGenerator>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconSet?: Maybe<Scalars['String']['output']>;
  iconWeight?: Maybe<Scalars['String']['output']>;
  info?: Maybe<UserObjectInfo>;
  integration?: Maybe<ChannelIntegration>;
  interactionEndDate?: Maybe<Scalars['DateTime']['output']>;
  isInteractive?: Maybe<Scalars['Boolean']['output']>;
  isSyndicateable?: Maybe<Scalars['Boolean']['output']>;
  lastReset: Scalars['DateTime']['output'];
  liveDate?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  name_l10n?: Maybe<Scalars['JSON']['output']>;
  nextReset: Scalars['DateTime']['output'];
  notifications?: Maybe<Array<ContentNotification>>;
  placements?: Maybe<Array<ContentPlacement>>;
  properties?: Maybe<Scalars['JSON']['output']>;
  propertiesOptions?: Maybe<Scalars['JSON']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedBy?: Maybe<PublicUser>;
  renderer?: Maybe<Scalars['Int']['output']>;
  reportingGroup?: Maybe<Scalars['ContentCategoryType']['output']>;
  resetPeriod: ResetPeriodType;
  sectionContent?: Maybe<Array<SectionContent>>;
  signedExternalUrl?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  subtitle_l10n?: Maybe<Scalars['JSON']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  theme?: Maybe<Theme>;
  type: ContentType;
  unpublishDate?: Maybe<Scalars['DateTime']['output']>;
  userInteractions: UserContentInteractionPagination;
  version?: Maybe<Scalars['Int']['output']>;
};


export type ContentInterfaceUserInteractionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<UserContentInteractionSearch>;
};

export type ContentNotification = Document & {
  __typename?: 'ContentNotification';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  content?: Maybe<DraftContent>;
  contentGoesLiveNotification?: Maybe<Scalars['Boolean']['output']>;
  sendAt: Scalars['DateTime']['output'];
  sent: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  title_l10n?: Maybe<Scalars['JSON']['output']>;
};

export type ContentNotificationInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  content?: InputMaybe<DraftContentIdInput>;
  contentGoesLiveNotification?: InputMaybe<Scalars['Boolean']['input']>;
  sendAt?: InputMaybe<Scalars['DateTime']['input']>;
  sent?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_l10n?: InputMaybe<Scalars['JSON']['input']>;
};

export type ContentPagination = {
  __typename?: 'ContentPagination';
  items?: Maybe<Array<Content>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ContentPlacement = Document & {
  __typename?: 'ContentPlacement';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel?: Maybe<Channel>;
  content?: Maybe<Content>;
  groupRole?: Maybe<GroupRole>;
  settings?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type ContentPlacementInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  content?: InputMaybe<DraftContentIdInput>;
  groupRole?: InputMaybe<GroupRoleIdInput>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UserIdInput>;
};

export type ContentQuantityAllotments = {
  __typename?: 'ContentQuantityAllotments';
  ruleMaxAllottedUnits: Scalars['Int']['output'];
  totalGeneralClaimedAllotments: Scalars['Int']['output'];
  totalRemainingAllotments: Scalars['Int']['output'];
  totalRuleClaimedAllotments: Scalars['Int']['output'];
  totalUserInteractions: Scalars['Int']['output'];
};

/** Content search input type */
export type ContentSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  category?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  contentMetatags?: InputMaybe<ObjectMetatagSearch>;
  description?: InputMaybe<StringSearch>;
  endDate?: InputMaybe<DateTimeSearch>;
  externalKey?: InputMaybe<Scalars['ID']['input']>;
  geo?: InputMaybe<GeoSearch>;
  includeGeneratedBatchContent?: InputMaybe<Scalars['Boolean']['input']>;
  integration?: InputMaybe<ChannelIntegrationSearch>;
  interactionEndDate?: InputMaybe<DateTimeSearch>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  liveDate?: InputMaybe<DateTimeSearch>;
  name?: InputMaybe<StringSearch>;
  name_l10n?: InputMaybe<StringSearch>;
  publishedAt?: InputMaybe<DateTimeSearch>;
  reportingGroup?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  searchMode?: InputMaybe<SearchMode>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortBy>;
  startDate?: InputMaybe<DateTimeSearch>;
  tags?: InputMaybe<StringListSearch>;
  type?: InputMaybe<StringSearch>;
  unpublishDate?: InputMaybe<DateTimeSearch>;
};

export enum ContentSortType {
  FeaturePaymentPrice = 'FeaturePaymentPrice',
  FeatureQuantityRemaining = 'FeatureQuantityRemaining',
  Location = 'Location',
  Name = 'Name',
  None = 'None',
  TimeEnding = 'TimeEnding',
  TimeStarting = 'TimeStarting'
}

export type ContentTemplate = Document & {
  __typename?: 'ContentTemplate';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  actions?: Maybe<Scalars['JSON']['output']>;
  analytics: ObjectAnalytics;
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  block?: Maybe<Scalars['JSON']['output']>;
  card: Block;
  category?: Maybe<Scalars['ContentCategoryType']['output']>;
  channel?: Maybe<Channel>;
  color?: Maybe<Scalars['Color']['output']>;
  contentTags?: Maybe<Array<Scalars['ID']['output']>>;
  data?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  description_l10n?: Maybe<Scalars['JSON']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  features?: Maybe<Scalars['JSON']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconSet?: Maybe<Scalars['String']['output']>;
  iconWeight?: Maybe<Scalars['String']['output']>;
  interactionEndDate?: Maybe<Scalars['DateTime']['output']>;
  isEvent?: Maybe<Scalars['Boolean']['output']>;
  isInteractive?: Maybe<Scalars['Boolean']['output']>;
  lastReset: Scalars['DateTime']['output'];
  liveDate?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  name_l10n?: Maybe<Scalars['JSON']['output']>;
  nextReset: Scalars['DateTime']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Scalars['JSON']['output']>;
  propertiesOptions?: Maybe<Scalars['JSON']['output']>;
  renderer?: Maybe<Scalars['Int']['output']>;
  reportingGroup?: Maybe<Scalars['ContentCategoryType']['output']>;
  resetPeriod: ResetPeriodType;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  subtitle_l10n?: Maybe<Scalars['JSON']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  templateName: Scalars['String']['output'];
  theme?: Maybe<Theme>;
  type: ContentType;
  unpublishDate?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type ContentTemplateInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  actions?: InputMaybe<Scalars['JSON']['input']>;
  analytics?: InputMaybe<ObjectAnalyticsInput>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  backgroundImage?: InputMaybe<Scalars['Image']['input']>;
  block?: InputMaybe<Scalars['JSON']['input']>;
  card?: InputMaybe<BlockIdInput>;
  category?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  color?: InputMaybe<Scalars['Color']['input']>;
  contentTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_l10n?: InputMaybe<Scalars['JSON']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  features?: InputMaybe<Scalars['JSON']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconSet?: InputMaybe<Scalars['String']['input']>;
  iconWeight?: InputMaybe<Scalars['String']['input']>;
  interactionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  isEvent?: InputMaybe<Scalars['Boolean']['input']>;
  isInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  lastReset?: InputMaybe<Scalars['DateTime']['input']>;
  liveDate?: InputMaybe<Scalars['DateTime']['input']>;
  logo?: InputMaybe<Scalars['Image']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_l10n?: InputMaybe<Scalars['JSON']['input']>;
  nextReset?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  propertiesOptions?: InputMaybe<Scalars['JSON']['input']>;
  renderer?: InputMaybe<Scalars['Int']['input']>;
  reportingGroup?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  resetPeriod?: InputMaybe<ResetPeriodType>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['JSON']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_l10n?: InputMaybe<Scalars['JSON']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<ThemeIdInput>;
  type?: InputMaybe<ContentType>;
  unpublishDate?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentTemplatePagination = {
  __typename?: 'ContentTemplatePagination';
  items?: Maybe<Array<ContentTemplate>>;
  pageInfo: PageInfo;
};

export type ContentTemplateSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  channel?: InputMaybe<ChannelSearch>;
  color?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<StringSearch>;
  endDate?: InputMaybe<DateTimeSearch>;
  geo?: InputMaybe<GeoSearch>;
  interactionEndDate?: InputMaybe<DateTimeSearch>;
  isInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  liveDate?: InputMaybe<DateTimeSearch>;
  name?: InputMaybe<StringSearch>;
  notes?: InputMaybe<StringSearch>;
  renderer?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<StringSearch>;
  sortBy?: InputMaybe<SortBy>;
  startDate?: InputMaybe<DateTimeSearch>;
  tags?: InputMaybe<StringListSearch>;
  templateName?: InputMaybe<StringSearch>;
  type?: InputMaybe<ContentType>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContentType {
  Content = 'Content',
  Notice = 'Notice',
  Perk = 'Perk',
  Promotion = 'Promotion',
  ScheduledContent = 'ScheduledContent',
  ScheduledNotice = 'ScheduledNotice',
  Static = 'Static',
  VisitorManagement = 'VisitorManagement',
  WorkOrder = 'WorkOrder'
}

export type ContentWaitlistEnrollment = {
  __typename?: 'ContentWaitlistEnrollment';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type ContentWorkflow = Document & {
  __typename?: 'ContentWorkflow';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order?: Maybe<Scalars['Int']['output']>;
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  action: WorkflowActionType;
  content?: Maybe<DraftContent>;
  dataField?: Maybe<DataField>;
  dataValidationSchema?: Maybe<Scalars['JSON']['output']>;
  event: Scalars['EventType']['output'];
  groupRole?: Maybe<GroupRole>;
  inStatus?: Maybe<Array<Scalars['UserContentInteractionStatus']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  target: WorkflowTargetType;
  time?: Maybe<Scalars['BigInt']['output']>;
  user?: Maybe<User>;
  webhook?: Maybe<Webhook>;
  when: WorkflowWhenType;
  whenContext: WorkflowWhenContextType;
  workflow?: Maybe<Scalars['JSON']['output']>;
};

export type ContentWorkflowInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _order?: InputMaybe<Scalars['Int']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  action?: InputMaybe<WorkflowActionType>;
  content?: InputMaybe<DraftContentIdInput>;
  dataField?: InputMaybe<DataFieldInput>;
  dataValidationSchema?: InputMaybe<Scalars['JSON']['input']>;
  event?: InputMaybe<Scalars['EventType']['input']>;
  groupRole?: InputMaybe<GroupRoleIdInput>;
  inStatus?: InputMaybe<Array<Scalars['UserContentInteractionStatus']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  target?: InputMaybe<WorkflowTargetType>;
  time?: InputMaybe<Scalars['BigInt']['input']>;
  user?: InputMaybe<UserIdInput>;
  webhook?: InputMaybe<WebhookIdInput>;
  when?: InputMaybe<WorkflowWhenType>;
  whenContext?: InputMaybe<WorkflowWhenContextType>;
  workflow?: InputMaybe<Scalars['JSON']['input']>;
  workflowTargetedChannelId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum ContractUnitType {
  Fee = 'Fee',
  Flat = 'Flat',
  Licence = 'Licence',
  Sf = 'SF',
  Site = 'Site',
  User = 'User'
}

export type CreateAccessPointRequest = {
  buildingId: Scalars['UUID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAudienceChannelTargetingRule = {
  channelId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAudienceGroupRoleTargetingRule = {
  groupRoleId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAudienceInput = {
  channelTargetingRules?: InputMaybe<Array<InputMaybe<CreateAudienceChannelTargetingRule>>>;
  contentToTarget?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  createdByChannel?: InputMaybe<Scalars['UUID']['input']>;
  currentChannelId?: InputMaybe<Scalars['UUID']['input']>;
  groupRoleTargetingRules?: InputMaybe<Array<InputMaybe<CreateAudienceGroupRoleTargetingRule>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  managementChannels?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareChannels?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type CreateAudienceResponse = {
  __typename?: 'CreateAudienceResponse';
  archived: Scalars['Boolean']['output'];
  channelTargetingRules: Array<Maybe<TargetingRule>>;
  contentToTarget: Array<Maybe<Scalars['UUID']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['UUID']['output'];
  createdByChannel: Scalars['UUID']['output'];
  groupRoleTargetingRules: Array<Maybe<TargetingRule>>;
  id: Scalars['UUID']['output'];
  managementChannels: Array<Maybe<Scalars['UUID']['output']>>;
  name: Scalars['String']['output'];
  shareChannels: Array<Maybe<Scalars['UUID']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type CreateAutoSetupDto = {
  blankBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  features?: InputMaybe<RiseMigrationFeatures>;
  isDryRun?: InputMaybe<Scalars['Boolean']['input']>;
  parentChannelId?: InputMaybe<Scalars['String']['input']>;
  parentChannelName?: InputMaybe<Scalars['String']['input']>;
  parentChannelSlug?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceLocations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  templateChannelId?: InputMaybe<Scalars['String']['input']>;
  tenantsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateChargeCodeRequest = {
  chargeCode: Scalars['String']['input'];
  chargeCodeSource: ChargeCodeSource;
  description?: InputMaybe<Scalars['String']['input']>;
  glCode: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  groupType: GroupType;
};

export type CreateChargeCodeResponse = {
  __typename?: 'CreateChargeCodeResponse';
  id: Scalars['String']['output'];
};

export type CreateChargeRequest = {
  amount: Scalars['Float']['input'];
  chargeCode?: InputMaybe<Scalars['String']['input']>;
  currency: Currency;
  description?: InputMaybe<Scalars['String']['input']>;
  externalActorId: Scalars['String']['input'];
  externalActorType: ExternalActorType;
  externalEntityId?: InputMaybe<Scalars['String']['input']>;
  externalEntityType?: InputMaybe<ExternalEntityType>;
  externalPayerId: Scalars['String']['input'];
  externalPayerType: ExternalPayerType;
  groupId: Scalars['String']['input'];
  groupType?: InputMaybe<GroupType>;
  invoiceDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceDueInDays?: InputMaybe<Scalars['Int']['input']>;
  invoiceType?: InputMaybe<InvoiceType>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<ProductItemInput>>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateChargeResponse = {
  __typename?: 'CreateChargeResponse';
  chargeId: Scalars['String']['output'];
};

export type CreateFloorInput = {
  index: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CreatePmScheduleDto = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  completeWithin?: InputMaybe<Scalars['Int']['input']>;
  daysAhead?: InputMaybe<Scalars['Int']['input']>;
  equipmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  extRefId?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meterReading?: InputMaybe<Scalars['String']['input']>;
  monthNo?: InputMaybe<Scalars['Int']['input']>;
  nextDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  repeats?: InputMaybe<Scalars['String']['input']>;
  stepTemplate?: InputMaybe<Array<InputMaybe<StepTemplateDto>>>;
  steps?: InputMaybe<Scalars['String']['input']>;
  timeToComplete?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  untilDate?: InputMaybe<Scalars['DateTime']['input']>;
  weekNo?: InputMaybe<Scalars['Int']['input']>;
  weekday?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePaymentAccountOptionsInput = {
  operateAccountId?: InputMaybe<Scalars['ID']['input']>;
  operateOrganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateProductCategoryRequest = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['String']['input'];
  groupType?: InputMaybe<GroupType>;
  name: Scalars['String']['input'];
  productType: ProductType;
};

export type CreateProductCategoryResponse = {
  __typename?: 'CreateProductCategoryResponse';
  description?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  groupType?: Maybe<GroupType>;
  name?: Maybe<Scalars['String']['output']>;
  productCategoryId: Scalars['String']['output'];
  productType?: Maybe<ProductType>;
};

export type CreateProductExceptionRequest = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  group: GroupInput;
  markup?: InputMaybe<MarkupInput>;
  payer: PayerInput;
  productCategoryId?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  tax?: InputMaybe<ProductTaxInput>;
};

export type CreateProductExceptionResponse = {
  __typename?: 'CreateProductExceptionResponse';
  amount: Scalars['Float']['output'];
  group: Group;
  id: Scalars['String']['output'];
  markup: Markup;
  payer: Payer;
  productCategory?: Maybe<ProductCategory>;
  products?: Maybe<Array<Maybe<Product>>>;
  tax: ProductTax;
};

export type CreateProductRequest = {
  amount: Scalars['Float']['input'];
  chargeCodeId?: InputMaybe<Scalars['String']['input']>;
  creditAmount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['String']['input'];
  groupType?: InputMaybe<GroupType>;
  markup?: InputMaybe<MarkupInput>;
  name: Scalars['String']['input'];
  productCategoryId: Scalars['String']['input'];
  receivables?: InputMaybe<Array<InputMaybe<ReceivableInput>>>;
  sellingPrice?: InputMaybe<SellingPriceInput>;
  tax?: InputMaybe<ProductTaxInput>;
};

export type CreateProductResponse = {
  __typename?: 'CreateProductResponse';
  amount: Scalars['Float']['output'];
  chargeCode?: Maybe<ChargeCode>;
  creditAmount?: Maybe<Scalars['Float']['output']>;
  description: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  groupType: GroupType;
  id: Scalars['String']['output'];
  markup: Markup;
  name: Scalars['String']['output'];
  productCategory: ProductCategory;
  receivables?: Maybe<Array<Maybe<Receivable>>>;
  sellingPrice?: Maybe<SellingPrice>;
  tax: ProductTax;
};

export type CreateRecurringChargeRequest = {
  anyoneCanCancel?: InputMaybe<Scalars['Boolean']['input']>;
  charge: CreateChargeRequest;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  recurrenceInterval: RecurrenceInterval;
  recurrenceType: RecurrenceType;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalCounts?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateRecurringChargeResponse = {
  __typename?: 'CreateRecurringChargeResponse';
  chargeTemplateId: Scalars['String']['output'];
  recurrenceId: Scalars['String']['output'];
};

export type CreateServiceRequestDto = {
  channelId: Scalars['String']['input'];
  contentInteractionId: Scalars['String']['input'];
  serviceRequest: ServiceRequestDto;
};

export type CreateTargetingRuleInput = {
  audienceId: Scalars['UUID']['input'];
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  fromChannel: Scalars['UUID']['input'];
  groupRoleId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  targetingRuleType: TargetingRuleEntityType;
};

export type CreateTargetingRuleResponse = {
  __typename?: 'CreateTargetingRuleResponse';
  audienceId: Scalars['UUID']['output'];
  channelId?: Maybe<Scalars['UUID']['output']>;
  groupRoleId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type CreateTask = {
  actualTimeToComplete?: InputMaybe<Scalars['Int']['input']>;
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  equipment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  estimateTimeToComplete?: InputMaybe<Scalars['Float']['input']>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meterReading?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  repeats?: InputMaybe<Scalars['String']['input']>;
  scheduleNotes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  steps?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTransactionRequest = {
  amount: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency: Currency;
  externalEntityId?: InputMaybe<Scalars['String']['input']>;
  externalEntityType?: InputMaybe<ExternalEntityType>;
  groupId: Scalars['String']['input'];
  groupType: GroupType;
  metadata: Scalars['String']['input'];
  paidBy: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  productItems?: InputMaybe<Array<ProductItemInput>>;
  productName: Scalars['String']['input'];
};

export type CreateTransactionResponse = {
  __typename?: 'CreateTransactionResponse';
  transactionId: Scalars['String']['output'];
};

export type CreateUserRequest = {
  externalUserId: Scalars['String']['input'];
  externalUserSource: ExternalUserSource;
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  accountsUserId: Scalars['String']['output'];
  externalUserId: Scalars['String']['output'];
  externalUserSource: ExternalUserSource;
  paymentProcessorCustomerId: Scalars['String']['output'];
};

export type Credential = {
  __typename?: 'Credential';
  activatedAt?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  credential?: Maybe<Scalars['Binary']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  credentialType?: Maybe<CredentialType>;
  mapId: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  revokedAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CredentialStatus>;
  uri?: Maybe<Scalars['String']['output']>;
  vtsOrganizationId?: Maybe<Scalars['String']['output']>;
};

export type CredentialGroups = {
  __typename?: 'CredentialGroups';
  credentials: Array<Maybe<Credential>>;
  id: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  vtsOrganizationId: Scalars['String']['output'];
  vtsUserId: Scalars['String']['output'];
};

export enum CredentialStatus {
  Active = 'ACTIVE',
  Failed = 'FAILED',
  Issuing = 'ISSUING',
  Processing = 'PROCESSING',
  Resuming = 'RESUMING',
  Revoked = 'REVOKED',
  Revoking = 'REVOKING',
  Suspended = 'SUSPENDED',
  Suspending = 'SUSPENDING',
  Unknown = 'UNKNOWN',
  Unrecognized = 'UNRECOGNIZED',
  UserResuming = 'USER_RESUMING',
  UserRevoking = 'USER_REVOKING',
  UserSuspended = 'USER_SUSPENDED',
  UserSuspending = 'USER_SUSPENDING'
}

export enum CredentialType {
  MobileAppleWallet = 'MOBILE_APPLE_WALLET',
  MobileBle = 'MOBILE_BLE',
  Phone = 'PHONE',
  Unrecognized = 'UNRECOGNIZED',
  Watch = 'WATCH',
  WatchAppleWallet = 'WATCH_APPLE_WALLET'
}

export type CreditBundle = {
  __typename?: 'CreditBundle';
  /** The number of credits in the bundle */
  creditCount: Scalars['Float']['output'];
  /** The dollar value of each credit */
  creditValue: Scalars['Float']['output'];
  /** What expiry type the credit bundle has, e.g end of month, at a specific day of the month or never expires */
  expiryType: ExpiryType;
  /** If credit bundle expires at a specific day of the month, this is the day */
  expiryValue: Scalars['Float']['output'];
  /** The UUID */
  id: Scalars['String']['output'];
};

export type CreditBundleInput = {
  creditCount: Scalars['Float']['input'];
  creditValue: Scalars['Float']['input'];
  expiryType: ExpiryType;
  expiryValue: Scalars['Float']['input'];
};

export type CreditCard = {
  __typename?: 'CreditCard';
  brand: Scalars['String']['output'];
  exp_month: Scalars['Int']['output'];
  exp_year: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
};

export type CreditWallet = {
  __typename?: 'CreditWallet';
  channelProfile: CreditWalletChannelProfile;
  group: Group;
  id: Scalars['String']['output'];
  numberOfCredits: Scalars['Int']['output'];
  owner: Owner;
  valueOfEachCredit: Scalars['Int']['output'];
};

export type CreditWalletChannelProfile = {
  __typename?: 'CreditWalletChannelProfile';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum Currency {
  CurrencyAud = 'CURRENCY_AUD',
  CurrencyBrl = 'CURRENCY_BRL',
  CurrencyCad = 'CURRENCY_CAD',
  CurrencyEur = 'CURRENCY_EUR',
  CurrencyGbp = 'CURRENCY_GBP',
  CurrencyHkd = 'CURRENCY_HKD',
  CurrencyHuf = 'CURRENCY_HUF',
  CurrencyUnknown = 'CURRENCY_UNKNOWN',
  CurrencyUsd = 'CURRENCY_USD'
}

export type CurrencyDetail = {
  __typename?: 'CurrencyDetail';
  currencyCode: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  operateCurrencyId?: Maybe<Scalars['String']['output']>;
};

export type CursorPageInfo = {
  __typename?: 'CursorPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type CustomColorPalette = {
  __typename?: 'CustomColorPalette';
  _id?: Maybe<Scalars['UUID']['output']>;
  color?: Maybe<Scalars['Color']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CustomColorPaletteInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  color?: InputMaybe<Scalars['Color']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CustomQuestion = {
  __typename?: 'CustomQuestion';
  Answer?: Maybe<Scalars['String']['output']>;
  Question?: Maybe<Scalars['String']['output']>;
};

export type CustomQuestionInput = {
  Answer?: InputMaybe<Scalars['String']['input']>;
  Question?: InputMaybe<Scalars['String']['input']>;
};

export type DataField = {
  __typename?: 'DataField';
  _id?: Maybe<Scalars['UUID']['output']>;
};

export type DataFieldInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
};

export type DatasetAutomationInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  datasetSchema: DatasetSchemaIdInput;
};

export type DatasetRecord = Document & {
  __typename?: 'DatasetRecord';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  datasetSchema: DatasetSchema;
  value: Scalars['JSON']['output'];
};

export type DatasetRecordInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  datasetSchema?: InputMaybe<DatasetSchemaIdInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type DatasetRecordPagination = {
  __typename?: 'DatasetRecordPagination';
  items?: Maybe<Array<DatasetRecord>>;
  pageInfo: PageInfo;
};

export type DatasetRecordSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type DatasetRecordUpdateInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  datasetSchema?: InputMaybe<DatasetSchemaIdInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type DatasetSchema = Document & {
  __typename?: 'DatasetSchema';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  datasetRecords: DatasetRecordPagination;
  description?: Maybe<Scalars['String']['output']>;
  generator?: Maybe<ContentGenerator>;
  name?: Maybe<Scalars['String']['output']>;
  properties: Scalars['JSON']['output'];
};


export type DatasetSchemaDatasetRecordsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<DatasetRecordSearch>;
};

export type DatasetSchemaIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DatasetSchemaInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  datasetRecords?: InputMaybe<Array<DatasetRecordInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
};

export type DatasetSchemaSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  description?: InputMaybe<StringSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type DateRange = {
  __typename?: 'DateRange';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type DateRangeInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeSearch = {
  type?: InputMaybe<NumericSearchType>;
  value: Scalars['DateTime']['input'];
  valueB?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeepLink = {
  __typename?: 'DeepLink';
  _id: Scalars['UUID']['output'];
  appStoreUrl?: Maybe<Scalars['URL']['output']>;
  deepLinkUrl?: Maybe<Scalars['URL']['output']>;
  googlePlayStoreUrl?: Maybe<Scalars['URL']['output']>;
};

export type DeepLinkInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  appStoreUrl?: InputMaybe<Scalars['URL']['input']>;
  deepLinkUrl?: InputMaybe<Scalars['URL']['input']>;
  googlePlayStoreUrl?: InputMaybe<Scalars['URL']['input']>;
};

export type DeleteDraftChargeRequest = {
  id: Scalars['String']['input'];
};

export type DeleteInvoiceAutopaymentRequest = {
  externalUserId: Scalars['String']['input'];
  externalUserSource: ExternalUserSource;
  paymentMethodId: Scalars['String']['input'];
};

export type DeleteProductExceptionRequest = {
  group: GroupInput;
  id: Scalars['String']['input'];
};

export type DeleteProductRequest = {
  group: GroupInput;
  id: Scalars['String']['input'];
};

export type DeleteTargetingRuleInput = {
  audienceId: Scalars['UUID']['input'];
  fromChannel: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  targetingRuleType: TargetingRuleEntityType;
};

export type DeviceToken = Document & {
  __typename?: 'DeviceToken';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  session?: Maybe<Session>;
  token: Scalars['ID']['output'];
  type: DeviceTokenType;
  user?: Maybe<User>;
  whitelabel?: Maybe<Whitelabel>;
};

export type DeviceTokenInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['ID']['input'];
  type: DeviceTokenType;
};

export type DeviceTokenSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  searchMode?: InputMaybe<SearchMode>;
  token?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<DeviceTokenType>;
};

export enum DeviceTokenType {
  Apns = 'apns',
  Gcm = 'gcm',
  Web = 'web'
}

export type DisplayMetadata = {
  __typename?: 'DisplayMetadata';
  friendlyId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
};

export type Document = {
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
};

export type DraftContent = Document & {
  __typename?: 'DraftContent';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  actions?: Maybe<Array<DraftContentWorkflow>>;
  analytics?: Maybe<ObjectAnalytics>;
  audiences?: Maybe<Array<AudiencesToAttachContentTo>>;
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  block?: Maybe<Scalars['JSON']['output']>;
  card: Block;
  category: Scalars['ContentCategoryType']['output'];
  channel: Channel;
  color?: Maybe<Scalars['Color']['output']>;
  contentMetatags?: Maybe<Array<ObjectMetatag>>;
  contentTags?: Maybe<Array<Scalars['String']['output']>>;
  data?: Maybe<Scalars['JSON']['output']>;
  deepLink?: Maybe<DeepLink>;
  description?: Maybe<Scalars['String']['output']>;
  description_l10n?: Maybe<Scalars['JSON']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalKey?: Maybe<Scalars['ID']['output']>;
  externalUrl?: Maybe<ExternalUrl>;
  features?: Maybe<Array<ContentFeature>>;
  generatedChildren?: Maybe<DraftContentPagination>;
  generator?: Maybe<ContentGenerator>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconSet?: Maybe<Scalars['String']['output']>;
  iconWeight?: Maybe<Scalars['String']['output']>;
  info?: Maybe<UserObjectInfo>;
  integration?: Maybe<ChannelIntegration>;
  interactionEndDate?: Maybe<Scalars['DateTime']['output']>;
  interactions?: Maybe<Array<UserContentInteraction>>;
  isEvent?: Maybe<Scalars['Boolean']['output']>;
  isInteractive?: Maybe<Scalars['Boolean']['output']>;
  isPublished: Scalars['Boolean']['output'];
  isSyndicateable?: Maybe<Scalars['Boolean']['output']>;
  lastReset: Scalars['DateTime']['output'];
  liveDate?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  name_l10n?: Maybe<Scalars['JSON']['output']>;
  nextReset: Scalars['DateTime']['output'];
  notifications?: Maybe<Array<DraftContentNotification>>;
  placements?: Maybe<Array<ContentPlacement>>;
  properties?: Maybe<Scalars['JSON']['output']>;
  propertiesOptions?: Maybe<Scalars['JSON']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  relatedChannelIntegrationIds?: Maybe<Array<Scalars['UUID']['output']>>;
  renderer?: Maybe<Scalars['Int']['output']>;
  reportingGroup: Scalars['ContentCategoryType']['output'];
  resetPeriod: ResetPeriodType;
  signedExternalUrl?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  subtitle_l10n?: Maybe<Scalars['JSON']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  theme?: Maybe<Theme>;
  type: ContentType;
  unpublishDate?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type DraftContentGeneratedChildrenArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<DraftContentSearch>;
};

export type DraftContentHistory = {
  __typename?: 'DraftContentHistory';
  _created: Scalars['DateTime']['output'];
  _createdBy: Scalars['UUID']['output'];
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: Scalars['UUID']['output'];
  actions?: Maybe<Array<DraftContentWorkflow>>;
  audiences?: Maybe<Array<Audience>>;
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  block?: Maybe<Scalars['JSON']['output']>;
  category?: Maybe<Scalars['ContentCategoryType']['output']>;
  color?: Maybe<Scalars['Color']['output']>;
  contentTags?: Maybe<Array<Scalars['String']['output']>>;
  data?: Maybe<Scalars['JSON']['output']>;
  deepLink?: Maybe<DeepLink>;
  description?: Maybe<Scalars['String']['output']>;
  description_l10n?: Maybe<Scalars['JSON']['output']>;
  emails?: Maybe<Scalars['JSON']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalUrl?: Maybe<ExternalUrl>;
  features?: Maybe<Array<ContentFeature>>;
  icon?: Maybe<Scalars['String']['output']>;
  iconSet?: Maybe<Scalars['String']['output']>;
  iconWeight?: Maybe<Scalars['String']['output']>;
  isEvent?: Maybe<Scalars['Boolean']['output']>;
  isInteractive?: Maybe<Scalars['Boolean']['output']>;
  isPublished: Scalars['Boolean']['output'];
  isSyndicateable?: Maybe<Scalars['Boolean']['output']>;
  lastReset: Scalars['DateTime']['output'];
  liveDate?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  name_l10n?: Maybe<Scalars['JSON']['output']>;
  nextReset: Scalars['DateTime']['output'];
  notifications?: Maybe<Array<DraftContentNotification>>;
  placements?: Maybe<Array<ContentPlacement>>;
  properties?: Maybe<Scalars['JSON']['output']>;
  propertiesOptions?: Maybe<Scalars['JSON']['output']>;
  renderer?: Maybe<Scalars['Int']['output']>;
  reportingGroup?: Maybe<Scalars['ContentCategoryType']['output']>;
  resetPeriod: ResetPeriodType;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  subtitle_l10n?: Maybe<Scalars['JSON']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  theme?: Maybe<Theme>;
  type: ContentType;
  unpublishDate?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type DraftContentHistoryPagination = {
  __typename?: 'DraftContentHistoryPagination';
  items?: Maybe<Array<DraftContentHistory>>;
  pageInfo?: Maybe<PageInfo>;
};

/** DraftContentHistory search input type */
export type DraftContentHistorySearch = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  sortBy?: InputMaybe<SortBy>;
};

export type DraftContentIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DraftContentInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _order?: InputMaybe<Scalars['Int']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  actions?: InputMaybe<Array<ContentWorkflowInput>>;
  analytics?: InputMaybe<ObjectAnalyticsInput>;
  audiences?: InputMaybe<Array<AudiencesToAttachContentToInput>>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  backgroundImage?: InputMaybe<Scalars['Image']['input']>;
  block?: InputMaybe<Scalars['JSON']['input']>;
  card?: InputMaybe<BlockIdInput>;
  category?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  color?: InputMaybe<Scalars['Color']['input']>;
  contentMetatags?: InputMaybe<Array<ObjectMetatagInput>>;
  contentTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deepLink?: InputMaybe<DeepLinkInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_l10n?: InputMaybe<Scalars['JSON']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  externalKey?: InputMaybe<Scalars['ID']['input']>;
  externalUrl?: InputMaybe<ExternalUrlInput>;
  features?: InputMaybe<Array<ContentFeatureInput>>;
  generator?: InputMaybe<ContentGeneratorInput>;
  geo?: InputMaybe<Scalars['GeoPoint']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconSet?: InputMaybe<Scalars['String']['input']>;
  iconWeight?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<UserObjectInfoInput>;
  integration?: InputMaybe<ChannelIntegrationIdInput>;
  interactionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  isEvent?: InputMaybe<Scalars['Boolean']['input']>;
  isInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isSyndicateable?: InputMaybe<Scalars['Boolean']['input']>;
  lastReset?: InputMaybe<Scalars['DateTime']['input']>;
  liveDate?: InputMaybe<Scalars['DateTime']['input']>;
  logo?: InputMaybe<Scalars['Image']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_l10n?: InputMaybe<Scalars['JSON']['input']>;
  nextReset?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<Array<ContentNotificationInput>>;
  placements?: InputMaybe<Array<ContentPlacementInput>>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  propertiesOptions?: InputMaybe<Scalars['JSON']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedBy?: InputMaybe<Scalars['UUID']['input']>;
  relatedChannelIntegrationIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  renderer?: InputMaybe<Scalars['Int']['input']>;
  reportingGroup?: InputMaybe<Scalars['ContentCategoryType']['input']>;
  requiredLastInteractiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  resetPeriod?: InputMaybe<ResetPeriodType>;
  sectionContent?: InputMaybe<SectionContentInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['JSON']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_l10n?: InputMaybe<Scalars['JSON']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  theme?: InputMaybe<ThemeIdInput>;
  type?: InputMaybe<ContentType>;
  unpublishDate?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type DraftContentNotification = Document & {
  __typename?: 'DraftContentNotification';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  content?: Maybe<DraftContent>;
  contentGoesLiveNotification?: Maybe<Scalars['Boolean']['output']>;
  sendAt: Scalars['DateTime']['output'];
  sent: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  title_l10n?: Maybe<Scalars['JSON']['output']>;
};

export type DraftContentPagination = {
  __typename?: 'DraftContentPagination';
  items?: Maybe<Array<DraftContent>>;
  pageInfo?: Maybe<PageInfo>;
};

/** DraftContent search input type */
export type DraftContentSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  contentMetatags?: InputMaybe<ObjectMetatagSearch>;
  description?: InputMaybe<StringSearch>;
  endDate?: InputMaybe<DateTimeSearch>;
  externalKey?: InputMaybe<Scalars['ID']['input']>;
  geo?: InputMaybe<GeoSearch>;
  interactionEndDate?: InputMaybe<DateTimeSearch>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isInteractive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  liveDate?: InputMaybe<DateTimeSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortBy>;
  startDate?: InputMaybe<DateTimeSearch>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<StringSearch>;
  unpublishDate?: InputMaybe<DateTimeSearch>;
};

export type DraftContentWorkflow = Document & {
  __typename?: 'DraftContentWorkflow';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order?: Maybe<Scalars['Int']['output']>;
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  action?: Maybe<WorkflowActionType>;
  content?: Maybe<DraftContent>;
  dataField?: Maybe<DataField>;
  dataValidationSchema?: Maybe<Scalars['JSON']['output']>;
  event?: Maybe<Scalars['EventType']['output']>;
  groupRole?: Maybe<GroupRole>;
  inStatus?: Maybe<Array<Scalars['UserContentInteractionStatus']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  target: WorkflowTargetType;
  time?: Maybe<Scalars['BigInt']['output']>;
  user?: Maybe<User>;
  webhook?: Maybe<Webhook>;
  when: WorkflowWhenType;
  whenContext: WorkflowWhenContextType;
  workflow?: Maybe<Scalars['JSON']['output']>;
  workflowTargetedChannelId?: Maybe<Scalars['UUID']['output']>;
};

export type DraftUserContentInteraction = Document & {
  __typename?: 'DraftUserContentInteraction';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  content: DraftContent;
  contentPublishedAt: Scalars['Date']['output'];
  interaction?: Maybe<Scalars['JSON']['output']>;
  user: User;
};

export type DraftUserContentInteractionInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  adminNote?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<ContentIdInput>;
  contentPublishedAt?: InputMaybe<Scalars['Date']['input']>;
  interaction?: InputMaybe<Scalars['JSON']['input']>;
  user?: InputMaybe<UserIdInput>;
};

export type DraftUserContentInteractionSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  content?: InputMaybe<ContentSearch>;
  contentPublishedAt?: InputMaybe<DateTimeSearch>;
  sortBy?: InputMaybe<SortBy>;
  user?: InputMaybe<UserSearch>;
};

export type DynamicSectionInfo = {
  __typename?: 'DynamicSectionInfo';
  _id: Scalars['UUID']['output'];
  channels: Array<Channel>;
  defaultSearchOptions: SearchOptions;
};

export type Entity = {
  __typename?: 'Entity';
  id: Scalars['String']['output'];
  type: ExternalEntityType;
};

export type Equipment = {
  __typename?: 'Equipment';
  asset?: Maybe<Scalars['String']['output']>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  category: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  hyperlinks?: Maybe<Array<Maybe<HyperLink>>>;
  id: Scalars['String']['output'];
  installDate?: Maybe<Scalars['DateTime']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  meterReadingSettings?: Maybe<Array<Maybe<MeterReadingSetting>>>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  suite?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  warrantyExpirationDate?: Maybe<Scalars['DateTime']['output']>;
};

export type EquipmentForExport = {
  __typename?: 'EquipmentForExport';
  equipment?: Maybe<Array<Equipment>>;
};

export type EquipmentInput = {
  asset?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category: Scalars['String']['input'];
  channelId?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  hyperlinks?: InputMaybe<Array<InputMaybe<HyperLinkInput>>>;
  installDate?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  meterReadingSettings?: InputMaybe<Array<InputMaybe<MeterReadingSettingInput>>>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  suite?: InputMaybe<Scalars['String']['input']>;
  warrantyExpirationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EquipmentSearch = {
  category?: InputMaybe<StringSearch>;
  identification?: InputMaybe<StringSearch>;
  location?: InputMaybe<StringSearch>;
  name?: InputMaybe<StringSearch>;
  notes?: InputMaybe<StringSearch>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  specification?: InputMaybe<StringSearch>;
};

export type EquipmentSearchResults = {
  __typename?: 'EquipmentSearchResults';
  equipment?: Maybe<Array<Equipment>>;
  pageInfo?: Maybe<PageInfo>;
};

export type EscalationRule = {
  __typename?: 'EscalationRule';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['UUID']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  extRefId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timeToEscalate?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['UUID']['output']>;
  workflowId?: Maybe<Scalars['UUID']['output']>;
};

export type Essensys = {
  __typename?: 'Essensys';
  account: EssensysAccount;
  accountContactPairs: Array<AccountContactPair>;
  accounts: Array<EssensysAccount>;
  calendar: Array<EssensysCalendar>;
  contact: EssensysContact;
  invoice: EssensysInvoice;
  invoicePdfUrl: Scalars['String']['output'];
  invoices: Array<EssensysInvoice>;
  location: Scalars['JSON']['output'];
  onlinePlanQuote: OnlinePlanQuote;
  organizations: Array<Scalars['JSON']['output']>;
  paymentProcessor: PaymentProcessor;
  paymentSettings: EssensysPaymentSettings;
  products: Array<EssensysProduct>;
};


export type EssensysAccountArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
};


export type EssensysAccountContactPairsArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type EssensysAccountsArgs = {
  contactIds: Array<Scalars['String']['input']>;
};


export type EssensysCalendarArgs = {
  endDate: Scalars['DateTime']['input'];
  productId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  status?: InputMaybe<Scalars['Int']['input']>;
};


export type EssensysContactArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
};


export type EssensysInvoiceArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  invoiceId: Scalars['String']['input'];
};


export type EssensysInvoicePdfUrlArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  invoiceId: Scalars['String']['input'];
};


export type EssensysInvoicesArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['UUID']['input']>;
};


export type EssensysLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type EssensysOnlinePlanQuoteArgs = {
  objectDatasetRecordId: Scalars['UUID']['input'];
};


export type EssensysOrganizationsArgs = {
  clientTypeId?: InputMaybe<Scalars['ID']['input']>;
  clientTypeName?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
};


export type EssensysPaymentProcessorArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
};


export type EssensysPaymentSettingsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type EssensysProductsArgs = {
  productIds: Array<Scalars['ID']['input']>;
};

export type EssensysAccount = {
  __typename?: 'EssensysAccount';
  _id: Scalars['String']['output'];
  accountsId?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  clientType?: Maybe<Scalars['String']['output']>;
  clientTypeName: Scalars['StudioGroupRoleName']['output'];
  credit?: Maybe<Scalars['Float']['output']>;
  creditsRemaining?: Maybe<Scalars['Float']['output']>;
  creditsUsed?: Maybe<Scalars['Float']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  placepayaccountid?: Maybe<Scalars['String']['output']>;
  printingBlackAndWhiteCredit?: Maybe<Scalars['Float']['output']>;
  printingColorCredit?: Maybe<Scalars['Float']['output']>;
  salesTaxExempt?: Maybe<Scalars['Boolean']['output']>;
  shopperreference?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type EssensysCalendar = {
  __typename?: 'EssensysCalendar';
  _id: Scalars['ID']['output'];
  accountId: Scalars['ID']['output'];
  contactId: Scalars['ID']['output'];
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
  status: Scalars['Int']['output'];
};

export type EssensysContact = {
  __typename?: 'EssensysContact';
  _id: Scalars['String']['output'];
  accountId: Scalars['String']['output'];
  location: Scalars['String']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  printingPin?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type EssensysInvoice = {
  __typename?: 'EssensysInvoice';
  _id: Scalars['String']['output'];
  accountId: Scalars['String']['output'];
  accountName: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  bfwd?: Maybe<Scalars['Float']['output']>;
  clientTypeId?: Maybe<Scalars['Int']['output']>;
  clientTypeName: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  emailAddress?: Maybe<Array<Maybe<Scalars['EmailAddress']['output']>>>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  endingBalance?: Maybe<Scalars['Float']['output']>;
  gross?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  net?: Maybe<Scalars['Float']['output']>;
  number: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  outstanding?: Maybe<Scalars['Float']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  tax?: Maybe<Scalars['String']['output']>;
};

export type EssensysMutation = {
  __typename?: 'EssensysMutation';
  createOperateAccountAndContact: AccountContactPair;
  sendInvoicePayment: EssensysPayment;
  setAutoPay: Scalars['Boolean']['output'];
};


export type EssensysMutationCreateOperateAccountAndContactArgs = {
  companyName: Scalars['String']['input'];
  contentId?: InputMaybe<Scalars['UUID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};


export type EssensysMutationSendInvoicePaymentArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  currency: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  paymentAccountId: Scalars['UUID']['input'];
  paymentAmount?: InputMaybe<Scalars['Float']['input']>;
  placePayData?: InputMaybe<Scalars['JSON']['input']>;
  stripeData?: InputMaybe<Scalars['JSON']['input']>;
};


export type EssensysMutationSetAutoPayArgs = {
  accountId: Scalars['ID']['input'];
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  enroll: Scalars['Boolean']['input'];
  paymentAccountId: Scalars['UUID']['input'];
};

export type EssensysPayment = {
  __typename?: 'EssensysPayment';
  accountname?: Maybe<Scalars['String']['output']>;
  allocateto?: Maybe<Scalars['String']['output']>;
  amountpaid?: Maybe<Scalars['Float']['output']>;
  glcode?: Maybe<Scalars['Int']['output']>;
  paymentcurrencycode?: Maybe<Scalars['String']['output']>;
  paymentdate?: Maybe<Scalars['String']['output']>;
  paymenttype?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
};

export type EssensysPaymentProcessor = {
  __typename?: 'EssensysPaymentProcessor';
  publicKey: Scalars['String']['output'];
  type: PaymentProviderType;
};

export type EssensysPaymentSettings = {
  __typename?: 'EssensysPaymentSettings';
  _id: Scalars['ID']['output'];
  accountId?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['String']['output']>;
  contactId?: Maybe<Scalars['String']['output']>;
  credits?: Maybe<Scalars['Float']['output']>;
  creditsRemaining?: Maybe<Scalars['String']['output']>;
  creditsUser?: Maybe<Scalars['String']['output']>;
  isCash: Scalars['Boolean']['output'];
  isCashNotOnAccount: Scalars['Boolean']['output'];
  isCredits: Scalars['Boolean']['output'];
  isEssensys: Scalars['Boolean']['output'];
  isOnAccount: Scalars['Boolean']['output'];
  locationName?: Maybe<Scalars['String']['output']>;
  orgpayeeid?: Maybe<Scalars['String']['output']>;
  overageproductid?: Maybe<Scalars['String']['output']>;
  paymentProcessor: EssensysPaymentProcessor;
  placepayaccountid?: Maybe<Scalars['String']['output']>;
  shopperreference?: Maybe<Scalars['String']['output']>;
};

export type EssensysProduct = {
  __typename?: 'EssensysProduct';
  availableAt: Array<Scalars['String']['output']>;
  enableOnline: Scalars['Boolean']['output'];
  hideFromPOS: Scalars['Boolean']['output'];
  location: Scalars['String']['output'];
  locationName: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type EventDetail = {
  __typename?: 'EventDetail';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type EventSubscription = Document & {
  __typename?: 'EventSubscription';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['UUID']['output']>;
  contentId?: Maybe<Scalars['UUID']['output']>;
  delivery?: Maybe<Array<Maybe<NotificationDeliveryType>>>;
  event: Scalars['EventType']['output'];
  groupRoleId?: Maybe<Scalars['UUID']['output']>;
  interactionId?: Maybe<Scalars['UUID']['output']>;
  subscriberId: Scalars['UUID']['output'];
  subscriberType: Scalars['String']['output'];
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type EventSubscriptionInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  contentId?: InputMaybe<Scalars['UUID']['input']>;
  delivery?: InputMaybe<StringListInput>;
  event?: InputMaybe<Scalars['EventType']['input']>;
  groupRoleId?: InputMaybe<Scalars['UUID']['input']>;
  interactionId?: InputMaybe<Scalars['UUID']['input']>;
  subscriberId?: InputMaybe<Scalars['UUID']['input']>;
  subscriberType?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type Exception = {
  __typename?: 'Exception';
  amount?: Maybe<Scalars['Float']['output']>;
  group: Group;
  id: Scalars['String']['output'];
  markup?: Maybe<Markup>;
  payer: Payer;
  productCategory?: Maybe<ProductCategory>;
  productCount?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<ProductTax>;
};

export enum ExpiryType {
  ExpireDayOfMonth = 'EXPIRE_DAY_OF_MONTH',
  ExpireEndOfMonth = 'EXPIRE_END_OF_MONTH',
  ExpireNever = 'EXPIRE_NEVER',
  ExpiryTypeUnknown = 'EXPIRY_TYPE_UNKNOWN'
}

export type ExportCsvTasksReponseDto = {
  __typename?: 'ExportCSVTasksReponseDTO';
  tasks?: Maybe<Array<ClientTask>>;
};

export enum ExternalActorType {
  ExternalActorTypeActivateUser = 'EXTERNAL_ACTOR_TYPE_ACTIVATE_USER',
  ExternalActorTypeSystem = 'EXTERNAL_ACTOR_TYPE_SYSTEM',
  ExternalActorTypeUnknown = 'EXTERNAL_ACTOR_TYPE_UNKNOWN'
}

export enum ExternalEntityType {
  ExternalEntityTypeActivateInvoice = 'EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE',
  ExternalEntityTypeActivateUci = 'EXTERNAL_ENTITY_TYPE_ACTIVATE_UCI',
  ExternalEntityTypeUnknown = 'EXTERNAL_ENTITY_TYPE_UNKNOWN',
  ExternalEntityTypeWorkorder = 'EXTERNAL_ENTITY_TYPE_WORKORDER'
}

export enum ExternalPayerType {
  ExternalPayerTypeActivateCompany = 'EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY',
  ExternalPayerTypeActivateUser = 'EXTERNAL_PAYER_TYPE_ACTIVATE_USER',
  ExternalPayerTypeUnknown = 'EXTERNAL_PAYER_TYPE_UNKNOWN'
}

export enum ExternalType {
  RiseBuilding = 'riseBuilding',
  Salesforce = 'salesforce',
  Unknown = 'unknown',
  VcmAccount = 'vcmAccount',
  VcmGlobalTenant = 'vcmGlobalTenant',
  VcmProperty = 'vcmProperty'
}

export type ExternalUrl = {
  __typename?: 'ExternalUrl';
  _id: Scalars['UUID']['output'];
  mediaId?: Maybe<Scalars['UUID']['output']>;
  openingMode?: Maybe<ExternalUrlOpeningMode>;
  url?: Maybe<Scalars['URL']['output']>;
};

export type ExternalUrlInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  mediaId?: InputMaybe<Scalars['UUID']['input']>;
  openingMode?: InputMaybe<ExternalUrlOpeningMode>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export enum ExternalUrlOpeningMode {
  External = 'External',
  InApp = 'InApp',
  InAppWithSso = 'InAppWithSso',
  Ritual = 'Ritual'
}

export enum ExternalUserSource {
  ExternalUserSourceActivate = 'EXTERNAL_USER_SOURCE_ACTIVATE',
  ExternalUserSourceUnknown = 'EXTERNAL_USER_SOURCE_UNKNOWN'
}

export enum FeatureName {
  Cancelable = 'Cancelable',
  Deliverable = 'Deliverable',
  Entries = 'Entries',
  EssensysProduct = 'EssensysProduct',
  GoogleCalendar = 'GoogleCalendar',
  GuestInvite = 'GuestInvite',
  Inventory = 'Inventory',
  Menu = 'Menu',
  OutlookCalendar = 'OutlookCalendar',
  Payment = 'Payment',
  QrCodeCheckin = 'QRCodeCheckin',
  Quantity = 'Quantity',
  RemoteFetch = 'RemoteFetch',
  Requirements = 'Requirements',
  Reservable = 'Reservable',
  Reset = 'Reset',
  Scheduled = 'Scheduled',
  Shipping = 'Shipping',
  SocialOptions = 'SocialOptions',
  Statuses = 'Statuses',
  SubmitOnBehalfOf = 'SubmitOnBehalfOf',
  Surveys = 'Surveys',
  TimeAvailability = 'TimeAvailability',
  UseCompanyPermissions = 'UseCompanyPermissions',
  VisitorManagement = 'VisitorManagement',
  WorkOrder = 'WorkOrder'
}

export type Features = {
  __typename?: 'Features';
  createServiceRequest?: Maybe<Scalars['Boolean']['output']>;
  updateServiceRequest?: Maybe<Scalars['Boolean']['output']>;
};

export type FeedbackForm = Document & {
  __typename?: 'FeedbackForm';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  comment: Scalars['String']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
};

export type FeedbackFormInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  comment: Scalars['String']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
};

export type FindSchedulesFilter = {
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  equipment?: InputMaybe<Scalars['String']['input']>;
  equipments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nextDueDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  nextDueDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  recurrences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FindSchedulesFilterOptions = {
  __typename?: 'FindSchedulesFilterOptions';
  assignees?: Maybe<Array<Maybe<PublicUser>>>;
  equipments?: Maybe<Array<Maybe<IdNamePair>>>;
  locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recurrences?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FindSchedulesReponseDto = {
  __typename?: 'FindSchedulesReponseDTO';
  filterOptions?: Maybe<FindSchedulesFilterOptions>;
  pageInfo?: Maybe<PageInfo>;
  schedules?: Maybe<Array<ClientPmSchedule>>;
};

export type FindSchedulesSearch = {
  search?: InputMaybe<StringSearch>;
  sortBy?: InputMaybe<SortBy>;
};

export type FindTasksSearch = {
  search?: InputMaybe<StringSearch>;
  sortBy?: InputMaybe<SortBy>;
};

export enum FlatfilePortal {
  AllFieldsRequired = 'ALL_FIELDS_REQUIRED',
  NameCellCompanyRequired = 'NAME_CELL_COMPANY_REQUIRED',
  NameCellRequired = 'NAME_CELL_REQUIRED',
  NameCompanyRequired = 'NAME_COMPANY_REQUIRED',
  NameEmailCellRequired = 'NAME_EMAIL_CELL_REQUIRED',
  NameEmailCompanyRequired = 'NAME_EMAIL_COMPANY_REQUIRED',
  NameEmailRequired = 'NAME_EMAIL_REQUIRED',
  NameRequired = 'NAME_REQUIRED'
}

export type FlatfileToken = {
  __typename?: 'FlatfileToken';
  embedId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type FloatSearch = {
  type?: InputMaybe<NumericSearchType>;
  value: Scalars['Float']['input'];
  valueB?: InputMaybe<Scalars['Float']['input']>;
};

export type Floor = {
  __typename?: 'Floor';
  id: Scalars['UUID']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  property?: Maybe<Property>;
  propertyId: Scalars['UUID']['output'];
  units?: Maybe<Array<Maybe<Unit>>>;
};

export type FloorInput = {
  index: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  units?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FloorMapGenerationStatus = {
  __typename?: 'FloorMapGenerationStatus';
  _id: Scalars['UUID']['output'];
  percentComplete: Scalars['Int']['output'];
  status: Scalars['String']['output'];
};

export type GeoSearch = {
  distance?: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type GetConfigRequest = {
  provider: Scalars['String']['input'];
  vts_organization_id?: InputMaybe<Scalars['UUID']['input']>;
};

export type GetConfigResponse = {
  __typename?: 'GetConfigResponse';
  config?: Maybe<Scalars['JSON']['output']>;
  connectedProviders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GetFinalPriceForProductsRequest = {
  group: GroupInput;
  payer: PayerInput;
  productIds: Array<Scalars['String']['input']>;
};

export type GetFinalPriceForProductsResponse = {
  __typename?: 'GetFinalPriceForProductsResponse';
  productFinalPrices: Array<ProductFinalPrice>;
};

export type GetGroupSettingRequest = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  groupType?: InputMaybe<GroupType>;
};

export type GetIntegrationProcessesInput = {
  integrationIdentifier?: InputMaybe<Scalars['JSON']['input']>;
  pagination?: InputMaybe<Scalars['JSON']['input']>;
  search?: InputMaybe<Scalars['JSON']['input']>;
};

export type GetIntegrationProcessesResponse = {
  __typename?: 'GetIntegrationProcessesResponse';
  processes?: Maybe<Array<Maybe<IntegrationProcess>>>;
};

export type GetInvoiceDetailsRequest = {
  groupId: Scalars['String']['input'];
  groupType: GroupType;
  invoiceDisplayId?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['String']['input']>;
};

export type GetInvoiceDetailsResponse = {
  __typename?: 'GetInvoiceDetailsResponse';
  charges: Array<Maybe<Charge>>;
  invoice: Invoice;
};

export type GetProductExceptionRequest = {
  id: Scalars['String']['input'];
};

export type GetProductExceptionResponse = {
  __typename?: 'GetProductExceptionResponse';
  amount?: Maybe<Scalars['Float']['output']>;
  group: Group;
  id: Scalars['String']['output'];
  markup?: Maybe<Markup>;
  payer: Payer;
  productCategory?: Maybe<ProductCategory>;
  products?: Maybe<Array<Maybe<Product>>>;
  tax?: Maybe<ProductTax>;
};

export type GetProductRequest = {
  group: GroupInput;
  productId: Scalars['String']['input'];
};

export type GetProductResponse = {
  __typename?: 'GetProductResponse';
  product: Product;
};

export type GetRecurringChargeRequest = {
  groupId: Scalars['String']['input'];
  groupType: GroupType;
  recurrenceId: Scalars['String']['input'];
};

export type GetRecurringChargeResponse = {
  __typename?: 'GetRecurringChargeResponse';
  recurringCharge: RecurringCharge;
};

export type GetRelayOutputList = {
  __typename?: 'GetRelayOutputList';
  pageInfo?: Maybe<PageInfo>;
  relayOutputs: Array<RelayOutput>;
};

export type GetTransactionStatusRequest = {
  transactionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type GetTransactionStatusResponse = {
  __typename?: 'GetTransactionStatusResponse';
  transactionId: Scalars['String']['output'];
  transactionStatus: Scalars['String']['output'];
};

export type GetUserByExternalIdRequest = {
  externalUserId: Scalars['String']['input'];
  externalUserSource: ExternalUserSource;
};

export type GetUserByExternalIdResponse = {
  __typename?: 'GetUserByExternalIdResponse';
  accountsUserId?: Maybe<Scalars['String']['output']>;
  externalUserId?: Maybe<Scalars['String']['output']>;
  externalUserSource?: Maybe<ExternalUserSource>;
  invoiceAutopayment?: Maybe<InvoiceAutopaymentDetails>;
  paymentProcessorCustomerId?: Maybe<Scalars['String']['output']>;
  paymentSources?: Maybe<Array<Maybe<PaymentSource>>>;
};

export type GetUserCreditWalletsRequest = {
  groups?: InputMaybe<Array<InputMaybe<GroupInput>>>;
  owner: OwnerInput;
  pagination: PaginationInput;
};

export type GetUserCreditWalletsResponse = {
  __typename?: 'GetUserCreditWalletsResponse';
  creditWallets: Array<Maybe<CreditWallet>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['String']['output'];
  type: GroupType;
};

export type GroupInput = {
  id: Scalars['String']['input'];
  type: GroupType;
};

export type GroupRole = Document & {
  __typename?: 'GroupRole';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel?: Maybe<Channel>;
  eventSubscriptions?: Maybe<Array<EventSubscription>>;
  isPublic: Scalars['Boolean']['output'];
  isSystem: Scalars['Boolean']['output'];
  maxUsers?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Scalars['Permission']['output']>>;
  requirements?: Maybe<Array<Scalars['Requirement']['output']>>;
  settings?: Maybe<Scalars['JSON']['output']>;
  user?: Maybe<User>;
};

export type GroupRoleIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupRoleInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  maxUsers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<PermissionListInput>;
  requirements?: InputMaybe<RequirementListInput>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  user?: InputMaybe<UserIdInput>;
};

export type GroupRoleJoinRule = {
  __typename?: 'GroupRoleJoinRule';
  _id: Scalars['UUID']['output'];
  channelId: Scalars['UUID']['output'];
  groupRoleId: Scalars['UUID']['output'];
  joinGroupRoleId: Scalars['UUID']['output'];
};

export type GroupRoleJoinRuleInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  channelId: Scalars['UUID']['input'];
  groupRoleId: Scalars['UUID']['input'];
  joinGroupRoleId: Scalars['UUID']['input'];
};

export type GroupRoleSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<PermissionListSearch>;
  requirements?: InputMaybe<RequirementListSearch>;
  searchMode?: InputMaybe<SearchMode>;
};

export type GroupSettingRequest = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  groupSettingId?: InputMaybe<Scalars['String']['input']>;
  groupType?: InputMaybe<GroupType>;
  settings: Scalars['JSON']['input'];
};

export type GroupSettingResponse = {
  __typename?: 'GroupSettingResponse';
  groupId: Scalars['String']['output'];
  groupSettingId: Scalars['String']['output'];
  groupType: GroupType;
  settings: Scalars['JSON']['output'];
};

export enum GroupType {
  GroupTypeActivateChannel = 'GROUP_TYPE_ACTIVATE_CHANNEL',
  GroupTypeUnknown = 'GROUP_TYPE_UNKNOWN'
}

export type HomBuilding = {
  __typename?: 'HOMBuilding';
  _id: Scalars['String']['output'];
  buildingName: Scalars['String']['output'];
};

export type HomDate = {
  __typename?: 'HOMDate';
  date: Scalars['String']['output'];
  time: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
};

export type HomEvent = {
  __typename?: 'HOMEvent';
  _id: Scalars['UUID']['output'];
  dateTime?: Maybe<HomDate>;
  description?: Maybe<Scalars['String']['output']>;
  eventId: Scalars['String']['output'];
  eventType: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
  teacher: HomTeacher;
  title: Scalars['String']['output'];
  venue?: Maybe<HomVenue>;
  waitlistActive: Scalars['Boolean']['output'];
};

export type HomTeacher = {
  __typename?: 'HOMTeacher';
  firstName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
};

export type HomVenue = {
  __typename?: 'HOMVenue';
  extraInformation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Hardware = {
  __typename?: 'Hardware';
  apiKey?: Maybe<Scalars['String']['output']>;
  channel: Channel;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['UUID']['output'];
  deviceInfo?: Maybe<HardwareDeviceInfo>;
  hardwareSettings: HardwareSettings;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  status: HardwareStatus;
  type: HardwareType;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type HardwareAccessLog = {
  __typename?: 'HardwareAccessLog';
  accessHostId?: Maybe<Scalars['String']['output']>;
  accessStatusDetail?: Maybe<HardwarePassError>;
  accessSuccess: Scalars['Boolean']['output'];
  accessedAt: Scalars['DateTime']['output'];
  accessedById?: Maybe<Scalars['String']['output']>;
  accessedByName?: Maybe<Scalars['String']['output']>;
  hardware?: Maybe<Hardware>;
  id: Scalars['ID']['output'];
  visitorPassId?: Maybe<Scalars['String']['output']>;
};

export type HardwareAccessLogPagination = {
  __typename?: 'HardwareAccessLogPagination';
  items: Array<HardwareAccessLog>;
  pageInfo: PageInfo;
};

/** HardwareAccessLogs Search input. */
export type HardwareAccessLogsSearch = {
  accessHostIds?: InputMaybe<Array<Scalars['String']['input']>>;
  accessStatusDetails?: InputMaybe<Array<HardwarePassError>>;
  accessSuccess?: InputMaybe<Scalars['Boolean']['input']>;
  accessedByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  hardwareIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  sortBy?: InputMaybe<SortBy>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type HardwareDeviceInfo = {
  __typename?: 'HardwareDeviceInfo';
  appVersion?: Maybe<Scalars['String']['output']>;
  batteryLevel?: Maybe<Scalars['String']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  osVersion?: Maybe<Scalars['String']['output']>;
  thermalState?: Maybe<HardwareThermalState>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Hardware input type */
export type HardwareInput = {
  /** ID of the Channel this Hardware will belong to */
  channelId: Scalars['UUID']['input'];
  /** Optional location details */
  location?: InputMaybe<Scalars['String']['input']>;
  /** Hardware name */
  name: Scalars['String']['input'];
  /** Optional notes for this hardware device */
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type HardwarePagination = {
  __typename?: 'HardwarePagination';
  items: Array<Hardware>;
  pageInfo: PageInfo;
};

export enum HardwarePassError {
  HardwarePassErrorExpired = 'HARDWARE_PASS_ERROR_EXPIRED',
  HardwarePassErrorInvalid = 'HARDWARE_PASS_ERROR_INVALID',
  HardwarePassErrorNotYetValid = 'HARDWARE_PASS_ERROR_NOT_YET_VALID',
  HardwarePassErrorPassInvalid = 'HARDWARE_PASS_ERROR_PASS_INVALID',
  HardwarePassErrorWrongLocation = 'HARDWARE_PASS_ERROR_WRONG_LOCATION'
}

/** Hardware Search input.  Allows you to search hardware. */
export type HardwareSearch = {
  sortBy?: InputMaybe<SortBy>;
};

export type HardwareSettings = {
  __typename?: 'HardwareSettings';
  kioskTimeoutInSeconds?: Maybe<Scalars['Int']['output']>;
};

export enum HardwareStatus {
  HardwareStatusActive = 'HARDWARE_STATUS_ACTIVE',
  HardwareStatusDisabled = 'HARDWARE_STATUS_DISABLED',
  HardwareStatusInvalid = 'HARDWARE_STATUS_INVALID'
}

export enum HardwareThermalState {
  HardwareThermalStateCritical = 'HARDWARE_THERMAL_STATE_CRITICAL',
  HardwareThermalStateFair = 'HARDWARE_THERMAL_STATE_FAIR',
  HardwareThermalStateInvalid = 'HARDWARE_THERMAL_STATE_INVALID',
  HardwareThermalStateNominal = 'HARDWARE_THERMAL_STATE_NOMINAL',
  HardwareThermalStateSerious = 'HARDWARE_THERMAL_STATE_SERIOUS'
}

export enum HardwareType {
  HardwareTypeInvalid = 'HARDWARE_TYPE_INVALID',
  HardwareTypeKiosk = 'HARDWARE_TYPE_KIOSK'
}

export enum HardwareVisitorPassScanStatus {
  HardwareVisitorPassExpired = 'HARDWARE_VISITOR_PASS_EXPIRED',
  HardwareVisitorPassInvalid = 'HARDWARE_VISITOR_PASS_INVALID',
  HardwareVisitorPassNotYetValid = 'HARDWARE_VISITOR_PASS_NOT_YET_VALID',
  HardwareVisitorPassValid = 'HARDWARE_VISITOR_PASS_VALID',
  HardwareVisitorPassWrongLocation = 'HARDWARE_VISITOR_PASS_WRONG_LOCATION'
}

export type Host = {
  __typename?: 'Host';
  id?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type HyperLink = {
  __typename?: 'HyperLink';
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type HyperLinkInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type IdNamePair = {
  __typename?: 'IdNamePair';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type InputCreateEscalationRule = {
  entityType?: InputMaybe<Scalars['String']['input']>;
  extRefId?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeToEscalate?: InputMaybe<Scalars['Int']['input']>;
  workflowId?: InputMaybe<Scalars['UUID']['input']>;
};

export type InputUpdateEscalationRule = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeToEscalate?: InputMaybe<Scalars['Int']['input']>;
};

export type IntSearch = {
  any?: InputMaybe<Array<Scalars['Int']['input']>>;
  type?: InputMaybe<NumericSearchType>;
  value?: InputMaybe<Scalars['Int']['input']>;
  valueB?: InputMaybe<Scalars['Int']['input']>;
};

export type Integration = Document & {
  __typename?: 'Integration';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  category: IntegrationCategory;
  name: Scalars['String']['output'];
  platforms?: Maybe<Array<PlatformType>>;
  properties?: Maybe<Scalars['JSON']['output']>;
  settings?: Maybe<Scalars['JSON']['output']>;
  type: IntegrationType;
  workflows?: Maybe<Scalars['JSON']['output']>;
};

export enum IntegrationCategory {
  AccessControl = 'AccessControl',
  Accounting = 'Accounting',
  Amenity = 'Amenity',
  Authentication = 'Authentication',
  Commerce = 'Commerce',
  Communication = 'Communication',
  Concierge = 'Concierge',
  Content = 'Content',
  Custom = 'Custom',
  Delivery = 'Delivery',
  Displays = 'Displays',
  Events = 'Events',
  FlexSpace = 'FlexSpace',
  FoodOrdering = 'FoodOrdering',
  Iot = 'IOT',
  Maintenance = 'Maintenance',
  Parking = 'Parking',
  RoomBooking = 'RoomBooking',
  Safety = 'Safety',
  Service = 'Service',
  VisitorRegistration = 'VisitorRegistration'
}

export type IntegrationInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  category?: InputMaybe<IntegrationCategory>;
  name?: InputMaybe<Scalars['String']['input']>;
  platforms?: InputMaybe<Array<PlatformType>>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<IntegrationType>;
  workflows?: InputMaybe<Scalars['JSON']['input']>;
};

export type IntegrationProcess = {
  __typename?: 'IntegrationProcess';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  processGroupId?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  succeededAt?: Maybe<Scalars['DateTime']['output']>;
  totalCreatedRecords?: Maybe<Scalars['Int']['output']>;
  totalFailedRecords?: Maybe<Scalars['Int']['output']>;
  totalUpdatedRecords?: Maybe<Scalars['Int']['output']>;
  vtsIntegrationId?: Maybe<Scalars['String']['output']>;
};

export enum IntegrationProvider {
  AccessManagement = 'AccessManagement',
  AngusServiceRequests = 'AngusServiceRequests',
  Auth0Saml = 'Auth0SAML',
  Bilt = 'Bilt',
  BuildingEnginesClassic = 'BuildingEnginesClassic',
  BuildingEnginesPrism = 'BuildingEnginesPrism',
  BuildingEnginesPrismVisitors = 'BuildingEnginesPrismVisitors',
  Captivate = 'Captivate',
  Cineplex = 'Cineplex',
  ControlByWeb = 'ControlByWeb',
  Entrata = 'Entrata',
  Essensys = 'Essensys',
  FcpBot = 'FCPBot',
  FloorMaps = 'FloorMaps',
  Gallagher = 'Gallagher',
  GoogleCalendar = 'GoogleCalendar',
  GoogleWorkplace = 'GoogleWorkplace',
  Hid = 'HID',
  Hom = 'HOM',
  Kastle = 'Kastle',
  LifeStart = 'LifeStart',
  LiveSafe = 'LiveSafe',
  MailChimp = 'MailChimp',
  MicrosoftAzure = 'MicrosoftAzure',
  Newstand = 'Newstand',
  Oidc = 'OIDC',
  Openpath = 'Openpath',
  OutlookCalendar = 'OutlookCalendar',
  ParkWhiz = 'ParkWhiz',
  Pattison = 'Pattison',
  ProxyClick = 'ProxyClick',
  RealPage = 'RealPage',
  Ritual = 'Ritual',
  Saml = 'SAML',
  Svn = 'SVN',
  Safetrust = 'Safetrust',
  Salto = 'Salto',
  ServiceRequest = 'ServiceRequest',
  ShopifyBurst = 'ShopifyBurst',
  ShopifyMultipass = 'ShopifyMultipass',
  ShopifyRsvp = 'ShopifyRsvp',
  SwiftConnect = 'SwiftConnect',
  ThreeTenServ = 'ThreeTenServ',
  Yardi = 'Yardi',
  YardiCommercial = 'YardiCommercial',
  YouRHere = 'YouRHere'
}

export type IntegrationSearch = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  category?: InputMaybe<StringSearch>;
  name?: InputMaybe<StringSearch>;
};

export enum IntegrationType {
  Api = 'API',
  ContentTargeting = 'ContentTargeting',
  Custom = 'Custom',
  Sdk = 'SDK',
  Webview = 'Webview'
}

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Currency;
  displayId: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  externalPayerId: Scalars['String']['output'];
  externalPayerName?: Maybe<Scalars['String']['output']>;
  externalPayerType: ExternalPayerType;
  groupId: Scalars['String']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  groupType: GroupType;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceAutopaymentDetails = {
  __typename?: 'InvoiceAutopaymentDetails';
  isInvoiceAutopaymentEnabled?: Maybe<Scalars['Boolean']['output']>;
  paymentSource?: Maybe<PaymentSource>;
};

export type InvoiceFilter = {
  dueDate?: InputMaybe<DateRangeInput>;
  invoicedDate?: InputMaybe<DateRangeInput>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InvoicePartial = {
  __typename?: 'InvoicePartial';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceDisplayId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum InvoiceType {
  InvoiceTypeMonthly = 'INVOICE_TYPE_MONTHLY',
  InvoiceTypeNoInvoice = 'INVOICE_TYPE_NO_INVOICE',
  InvoiceTypeOneoff = 'INVOICE_TYPE_ONEOFF',
  InvoiceTypeUnkown = 'INVOICE_TYPE_UNKOWN'
}

export type Issue = {
  __typename?: 'Issue';
  categoryId?: Maybe<Scalars['String']['output']>;
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IssueUpsertRequestDto = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IssueUpsertResponseDto = {
  __typename?: 'IssueUpsertResponseDTO';
  id: Scalars['String']['output'];
};

export type JobResponse = {
  __typename?: 'JobResponse';
  allowedFeatures?: Maybe<AllowedFeatures>;
  created?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type JobsResponse = {
  __typename?: 'JobsResponse';
  jobs: Array<Maybe<JobResponse>>;
};

export type LayoutDesignSystem = {
  __typename?: 'LayoutDesignSystem';
  _id?: Maybe<Scalars['UUID']['output']>;
  marginBottom?: Maybe<Scalars['String']['output']>;
  marginLeft?: Maybe<Scalars['String']['output']>;
  marginRight?: Maybe<Scalars['String']['output']>;
  marginTop?: Maybe<Scalars['String']['output']>;
  paddingBottom?: Maybe<Scalars['String']['output']>;
  paddingLeft?: Maybe<Scalars['String']['output']>;
  paddingRight?: Maybe<Scalars['String']['output']>;
  paddingTop?: Maybe<Scalars['String']['output']>;
};

export type LayoutDesignSystemInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  marginBottom?: InputMaybe<Scalars['String']['input']>;
  marginLeft?: InputMaybe<Scalars['String']['input']>;
  marginRight?: InputMaybe<Scalars['String']['input']>;
  marginTop?: InputMaybe<Scalars['String']['input']>;
  paddingBottom?: InputMaybe<Scalars['String']['input']>;
  paddingLeft?: InputMaybe<Scalars['String']['input']>;
  paddingRight?: InputMaybe<Scalars['String']['input']>;
  paddingTop?: InputMaybe<Scalars['String']['input']>;
};

export type Library = Document & {
  __typename?: 'Library';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  access?: Maybe<Array<LibraryAccess>>;
  description?: Maybe<Scalars['String']['output']>;
  isEditable: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  items?: Maybe<Array<LibraryItem>>;
  name: Scalars['String']['output'];
  paths?: Maybe<Array<Scalars['Path']['output']>>;
  settings?: Maybe<Scalars['JSON']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<LibraryType>;
};

/**
 * Describes who has access to a Library.  Works similar to GroupRoles, but is only about library access.
 *
 * You can give an entire Channels access to a library, GroupRoles, or specific users.
 */
export type LibraryAccess = Document & {
  __typename?: 'LibraryAccess';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel?: Maybe<Channel>;
  groupRole?: Maybe<GroupRole>;
  library?: Maybe<Library>;
  user?: Maybe<User>;
};

export type LibraryAccessInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  groupRole?: InputMaybe<GroupRoleIdInput>;
  library?: InputMaybe<LibraryInput>;
  user?: InputMaybe<UserIdInput>;
};

export type LibraryAccessSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  groupRole?: InputMaybe<GroupRoleSearch>;
  library?: InputMaybe<LibrarySearch>;
  user?: InputMaybe<UserSearch>;
};

export type LibraryIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
};

export type LibraryInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<LibraryType>;
};

export type LibraryItem = Document & {
  __typename?: 'LibraryItem';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  block?: Maybe<Block>;
  channel?: Maybe<Channel>;
  datasetSchema?: Maybe<DatasetSchema>;
  library?: Maybe<Library>;
  libraryType?: Maybe<LibraryType>;
  media?: Maybe<Media>;
  metatag?: Maybe<Metatag>;
  path?: Maybe<Scalars['Path']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  template?: Maybe<ContentTemplate>;
  user?: Maybe<User>;
  webhook?: Maybe<Webhook>;
};

export type LibraryItemInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  block?: InputMaybe<BlockInput>;
  channel?: InputMaybe<ChannelIdInput>;
  datasetSchema?: InputMaybe<DatasetSchemaInput>;
  library?: InputMaybe<LibraryIdInput>;
  media?: InputMaybe<MediaInput>;
  metatag?: InputMaybe<MetatagInput>;
  path?: InputMaybe<Scalars['Path']['input']>;
  tags?: InputMaybe<StringListInput>;
  template?: InputMaybe<ContentTemplateInput>;
  user?: InputMaybe<UserIdInput>;
  webhook?: InputMaybe<WebhookInput>;
};

export type LibraryItemPagination = {
  __typename?: 'LibraryItemPagination';
  items?: Maybe<Array<LibraryItem>>;
  pageInfo?: Maybe<PageInfo>;
};

export type LibraryItemSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  datasetSchema?: InputMaybe<DatasetSchemaSearch>;
  library?: InputMaybe<LibrarySearch>;
  media?: InputMaybe<MediaSearch>;
  metatag?: InputMaybe<MetatagSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  template?: InputMaybe<ContentTemplateSearch>;
  user?: InputMaybe<UserSearch>;
  webhook?: InputMaybe<WebhookSearch>;
};

export type LibraryItemWithOriginalExtension = {
  __typename?: 'LibraryItemWithOriginalExtension';
  libraryItem: LibraryItem;
  originalExtension: Scalars['String']['output'];
};

export type LibraryPagination = {
  __typename?: 'LibraryPagination';
  items?: Maybe<Array<Library>>;
  pageInfo?: Maybe<PageInfo>;
};

export type LibrarySearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  description?: InputMaybe<StringSearch>;
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringSearch>;
  type?: InputMaybe<LibraryType>;
};

export enum LibraryType {
  Block = 'Block',
  ContentTemplate = 'ContentTemplate',
  DatasetSchema = 'DatasetSchema',
  Media = 'Media',
  Metatag = 'Metatag',
  Webhook = 'Webhook'
}

export type LinkedEntity = {
  __typename?: 'LinkedEntity';
  _created?: Maybe<Scalars['DateTime']['output']>;
  _createdBy?: Maybe<PublicUser>;
  _updated?: Maybe<Scalars['DateTime']['output']>;
  _updatedBy?: Maybe<PublicUser>;
  activateId?: Maybe<Scalars['UUID']['output']>;
  activateType?: Maybe<ActivateType>;
  building?: Maybe<Building>;
  channel?: Maybe<Channel>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalType?: Maybe<ExternalType>;
  tenantLease?: Maybe<TenantLease>;
};

export type LinkedEntityInput = {
  activateId: Scalars['String']['input'];
  activateType: ActivateType;
  externalId: Scalars['String']['input'];
  externalType: ExternalType;
};

export type LinkedEntityPagination = {
  __typename?: 'LinkedEntityPagination';
  items?: Maybe<Array<LinkedEntity>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Linked Entity Search input. */
export type LinkedEntitySearch = {
  activateId?: InputMaybe<Scalars['String']['input']>;
  activateType?: InputMaybe<ActivateType>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalType?: InputMaybe<ExternalType>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type ListChargeCodeFilter = {
  chargeCode?: InputMaybe<Scalars['String']['input']>;
};

export type ListChargeCodeRequest = {
  chargeCodesFilter?: InputMaybe<ListChargeCodeFilter>;
  groupId: Scalars['String']['input'];
  groupType: GroupType;
  pagination: PaginationInput;
  sortBy?: InputMaybe<SortBy>;
};

export type ListChargeCodeResponse = {
  __typename?: 'ListChargeCodeResponse';
  chargeCodes: Array<Maybe<ChargeCode>>;
  pageInfo: PageInfo;
};

export type ListChargesRequest = {
  chargesFilters?: InputMaybe<ChargesFilters>;
  groupId: Scalars['String']['input'];
  groupType?: InputMaybe<GroupType>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationInput>;
};

export type ListChargesResponse = {
  __typename?: 'ListChargesResponse';
  charges: Array<Maybe<Charge>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ListInvoicesByPayersFilter = {
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ListInvoicesByPayersRequest = {
  externalPayerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalPayerType?: InputMaybe<ExternalPayerType>;
  filter?: InputMaybe<ListInvoicesByPayersFilter>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  groupType?: InputMaybe<GroupType>;
  pagination?: InputMaybe<PaginationInput>;
};

export type ListInvoicesByPayersResponse = {
  __typename?: 'ListInvoicesByPayersResponse';
  invoices: Array<Maybe<Invoice>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ListInvoicesRequest = {
  filter?: InputMaybe<InvoiceFilter>;
  groupId: Scalars['String']['input'];
  groupType: GroupType;
  pagination?: InputMaybe<PaginationInput>;
};

export type ListInvoicesResponse = {
  __typename?: 'ListInvoicesResponse';
  invoices: Array<Maybe<Invoice>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ListProductCategoriesRequest = {
  filter: ProductCategoriesFilter;
  pagination?: InputMaybe<PaginationInput>;
};

export type ListProductCategoriesResponse = {
  __typename?: 'ListProductCategoriesResponse';
  pageInfo?: Maybe<PageInfo>;
  productCategory: Array<Maybe<ProductCategory>>;
};

export type ListProductExceptionsRequest = {
  filter?: InputMaybe<ProductExceptionsFilter>;
  pagination?: InputMaybe<PaginationInput>;
};

export type ListProductExceptionsResponse = {
  __typename?: 'ListProductExceptionsResponse';
  exceptions: Array<Maybe<Exception>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ListProductsResponse = {
  __typename?: 'ListProductsResponse';
  pageInfo?: Maybe<PageInfo>;
  products: Array<Product>;
};

export type ListRecurringChargesRequest = {
  pagination: PaginationInput;
  recurringChargesFilters: RecurringChargesFilters;
};

export type ListRecurringChargesResponse = {
  __typename?: 'ListRecurringChargesResponse';
  pageInfo: PageInfo;
  recurringCharges: Array<Maybe<RecurringCharge>>;
};

export type ListTransactionsRequest = {
  groupId: Scalars['String']['input'];
  groupType: GroupType;
  pagination?: InputMaybe<PaginationInput>;
  sortBy?: InputMaybe<SortBy>;
};

export type ListTransactionsResponse = {
  __typename?: 'ListTransactionsResponse';
  pageInfo?: Maybe<PageInfo>;
  transactions: Array<Maybe<Transaction>>;
};

export type Location = {
  __typename?: 'Location';
  channelId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LocationUpsertRequestDto = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LocationUpsertResponseDto = {
  __typename?: 'LocationUpsertResponseDTO';
  id: Scalars['String']['output'];
};

export enum LookerReportType {
  AccessAnalytics = 'access_analytics',
  AdminDashboardAnalytics = 'admin_dashboard_analytics',
  CompanyAnalytics = 'company_analytics',
  Content = 'content',
  EventAnalytics = 'event_analytics',
  Portfolio = 'portfolio',
  PostAnalytics = 'post_analytics',
  Reservation = 'reservation',
  TenantSentimentAnalytics = 'tenant_sentiment_analytics',
  User = 'user',
  UserEngagementAnalytics = 'user_engagement_analytics',
  UserSearch = 'user_search',
  VisitorManagementAnalytics = 'visitor_management_analytics',
  WorkOrdersAnalytics = 'work_orders_analytics'
}

export type Markup = {
  __typename?: 'Markup';
  /** The type of the markup - fixed or percentage */
  markupAmountType: MarkupAmountType;
  /** The dollar value of the markup */
  value: Scalars['Float']['output'];
};

export enum MarkupAmountType {
  MarkupAmountTypeFixed = 'MARKUP_AMOUNT_TYPE_FIXED',
  MarkupAmountTypePercentage = 'MARKUP_AMOUNT_TYPE_PERCENTAGE',
  MarkupAmountTypeUnknown = 'MARKUP_AMOUNT_TYPE_UNKNOWN'
}

export type MarkupInput = {
  markupAmountType: MarkupAmountType;
  value: Scalars['Float']['input'];
};

export type Me = {
  __typename?: 'Me';
  /** All of the subscriptions that this user has joined */
  channels?: Maybe<Array<Channel>>;
  /** Gets a specific piece of content, from this users perspective. */
  content?: Maybe<Content>;
  /**
   * Gets the Content Interactions for this logged in user.
   *
   * i.e. show me my last 10 interactions.
   */
  contentInteractions: UserContentInteractionPagination;
  contentsOnChannels?: Maybe<Array<Maybe<Content>>>;
  /** Gets a draft interaction for this user. */
  draftInteraction?: Maybe<DraftUserContentInteraction>;
  /**
   * ------------------------- DEPRECATED ---------------------------------------------
   * Returns a list of channels based on what a user would like to focus on.
   *
   * This would usually be a building as the primary and a company as the secondary.
   *
   * This will return any related channels upwards on the hierarchy.  i.e. if you focus
   * on Bay Adelaide Centre, you will also get Brookfield returned in the list.
   *
   * You can connect them by looking at Channel.parent._id
   * @deprecated use switchChannel instead
   */
  focusOnChannels?: Maybe<Array<Channel>>;
  /** Gets a specific UserContentInteration for this logged in user. */
  interaction: UserContentInteraction;
  /** The libraries this user has explicit access to. */
  libraries?: Maybe<LibraryPagination>;
  lookerEmbedURL?: Maybe<Scalars['URL']['output']>;
  /** Gets this users media library items. */
  media: MediaPagination;
  pagesOnChannels?: Maybe<Array<Maybe<PagesOnChannel>>>;
  /** Returns a secureId for this user */
  secureId: Scalars['UUID']['output'];
  /** The JTI of the requesting session */
  sessionId: Scalars['UUID']['output'];
  shopifyMultipassLogin: ShopifyMultipassLoginDetail;
  /**
   * Returns the list of focusOnChannels
   *
   * This will return any related channels upwards on the hierarchy.  i.e. if you focus
   * on Bay Adelaide Centre, you will also get Brookfield returned in the list.
   *
   * You can connect them by looking at Channel.parent._id
   */
  switchChannel?: Maybe<SwitchChannel>;
  /** The currently logged in user */
  user?: Maybe<User>;
  /** Other users on the platform that the logged in user knows. */
  users: PublicUserPagination;
};


export type MeChannelsArgs = {
  search?: InputMaybe<ChannelSearch>;
};


export type MeContentArgs = {
  _id: Scalars['UUID']['input'];
  search?: InputMaybe<ContentSearch>;
};


export type MeContentInteractionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<UserContentInteractionSearch>;
  sectionId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MeContentsOnChannelsArgs = {
  channelIds: Array<InputMaybe<Scalars['UUID']['input']>>;
  contentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MeDraftInteractionArgs = {
  contentId: Scalars['UUID']['input'];
  includesOutdated?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MeFocusOnChannelsArgs = {
  primary: Scalars['UUID']['input'];
  secondary?: InputMaybe<Scalars['UUID']['input']>;
};


export type MeInteractionArgs = {
  _id: Scalars['UUID']['input'];
};


export type MeLibrariesArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<LibrarySearch>;
};


export type MeLookerEmbedUrlArgs = {
  channelId: Scalars['UUID']['input'];
  channelName: Scalars['String']['input'];
  contentName?: InputMaybe<Scalars['String']['input']>;
  selectedReport: LookerReportType;
  timezone: Scalars['String']['input'];
};


export type MeMediaArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<MediaSearch>;
};


export type MePagesOnChannelsArgs = {
  channelIds: Array<InputMaybe<Scalars['UUID']['input']>>;
};


export type MeShopifyMultipassLoginArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
  createLoginRecord: Scalars['Boolean']['input'];
  externalUrl?: InputMaybe<Scalars['URL']['input']>;
};


export type MeSwitchChannelArgs = {
  channelId: Scalars['UUID']['input'];
  search?: InputMaybe<ChannelSearch>;
};


export type MeUsersArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<PublicUserSearch>;
};

export type MeMutation = {
  __typename?: 'MeMutation';
  /** Claims the given invite for the current logged in user. */
  claimInvite: PublicUserGroupRole;
  clearDraftInteraction?: Maybe<Scalars['Boolean']['output']>;
  syncShopifyMultipassMarketingPreference?: Maybe<Scalars['Boolean']['output']>;
  updateDeviceToken?: Maybe<Scalars['Boolean']['output']>;
  updateDraftInteraction?: Maybe<Scalars['Boolean']['output']>;
};


export type MeMutationClaimInviteArgs = {
  _id: Scalars['UUID']['input'];
};


export type MeMutationClearDraftInteractionArgs = {
  contentId: Scalars['UUID']['input'];
};


export type MeMutationSyncShopifyMultipassMarketingPreferenceArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
  maxRetries?: InputMaybe<Scalars['Int']['input']>;
};


export type MeMutationUpdateDeviceTokenArgs = {
  deviceToken: DeviceTokenInput;
};


export type MeMutationUpdateDraftInteractionArgs = {
  contentId: Scalars['UUID']['input'];
  interaction?: InputMaybe<Scalars['JSON']['input']>;
};

export type Media = Document & {
  __typename?: 'Media';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  analytics: ObjectAnalytics;
  contentType?: Maybe<Scalars['MediaContentType']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['JSON']['output']>;
  fileSignedUrl?: Maybe<Scalars['String']['output']>;
  inUse?: Maybe<Scalars['Boolean']['output']>;
  info?: Maybe<UserObjectInfo>;
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  s3Bucket?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  thumbnail?: Maybe<Scalars['JSON']['output']>;
  thumbnailSignedUrl?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MediaType>;
};

export type MediaInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  analytics?: InputMaybe<ObjectAnalyticsInput>;
  contentType?: InputMaybe<Scalars['MediaContentType']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['JSON']['input']>;
  fileSignedUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  s3Bucket?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<StringListInput>;
  thumbnail?: InputMaybe<Scalars['JSON']['input']>;
  thumbnailSignedUrl?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MediaType>;
};

export type MediaPagination = {
  __typename?: 'MediaPagination';
  items?: Maybe<Array<Media>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MediaSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  contentType?: InputMaybe<Scalars['MediaContentType']['input']>;
  description?: InputMaybe<StringSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  tags?: InputMaybe<StringListSearch>;
  type?: InputMaybe<MediaType>;
};

export enum MediaType {
  Audio = 'Audio',
  Document = 'Document',
  Image = 'Image',
  Video = 'Video'
}

export type MediaWithOriginalExtension = {
  __typename?: 'MediaWithOriginalExtension';
  media: Media;
  originalExtension: Scalars['String']['output'];
};

export type Metatag = Document & {
  __typename?: 'Metatag';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  properties: Scalars['JSON']['output'];
  showDescription: Scalars['Boolean']['output'];
  type: MetatagType;
  values?: Maybe<Array<MetatagValue>>;
};

export type MetatagFilter = {
  __typename?: 'MetatagFilter';
  _id: Scalars['UUID']['output'];
  metatagId: Scalars['UUID']['output'];
  metatagType: MetatagType;
  value?: Maybe<Scalars['JSON']['output']>;
  valueType?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Scalars['UUID']['output']>>;
};

export type MetatagFilterInput = {
  _id: Scalars['UUID']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  metatagId: Scalars['UUID']['input'];
  metatagType: MetatagType;
  value?: InputMaybe<Scalars['JSON']['input']>;
  valueType?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type MetatagIdInput = {
  _id: Scalars['UUID']['input'];
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetatagInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
  showDescription?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<MetatagType>;
  values?: InputMaybe<Array<MetatagValueInput>>;
};

export type MetatagPagination = {
  __typename?: 'MetatagPagination';
  items?: Maybe<Array<Metatag>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MetatagSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  description?: InputMaybe<StringSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  tags?: InputMaybe<StringListSearch>;
  type?: InputMaybe<StringSearch>;
};

export enum MetatagType {
  Complex = 'Complex',
  Simple = 'Simple'
}

export type MetatagUse = {
  __typename?: 'MetatagUse';
  _id: Scalars['UUID']['output'];
  type: Scalars['String']['output'];
};

export type MetatagValue = Document & {
  __typename?: 'MetatagValue';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  metatag: Metatag;
  value: Scalars['JSON']['output'];
};

export type MetatagValueInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  metatag?: InputMaybe<MetatagInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MetatagValueSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  metatag?: InputMaybe<MetatagSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type MeterReadingSetting = {
  __typename?: 'MeterReadingSetting';
  enableNotification?: Maybe<Scalars['Boolean']['output']>;
  equipmentId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lowerBound?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notificationTrigger?: Maybe<Scalars['String']['output']>;
  notificationTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  unit?: Maybe<Scalars['String']['output']>;
  upperBound?: Maybe<Scalars['Float']['output']>;
};

export type MeterReadingSettingInput = {
  enableNotification?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lowerBound?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationTrigger?: InputMaybe<Scalars['String']['input']>;
  notificationTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unit?: InputMaybe<Scalars['String']['input']>;
  upperBound?: InputMaybe<Scalars['Float']['input']>;
};

export type MeterReadingValue = {
  __typename?: 'MeterReadingValue';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  meterReadingId: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  sourceType: Scalars['String']['output'];
  stepExecutionId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
  taskUserFriendlyId?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type MeterReadingValueForExport = {
  __typename?: 'MeterReadingValueForExport';
  meterReadingValues?: Maybe<Array<MeterReadingValueResponseType>>;
};

export type MeterReadingValueInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  meterReadingId: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  sourceType: Scalars['String']['input'];
  stepExecutionId?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  taskUserFriendlyId?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type MeterReadingValueResponseType = {
  __typename?: 'MeterReadingValueResponseType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<IdNamePair>;
  id: Scalars['String']['output'];
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  meterReadingId: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  sourceType: Scalars['String']['output'];
  stepExecutionId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
  taskUserFriendlyId?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type MeterReadingValueSearch = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type MeterReadingValueSearchResults = {
  __typename?: 'MeterReadingValueSearchResults';
  meterReadingValues: Array<MeterReadingValueResponseType>;
  pageInfo?: Maybe<PageInfo>;
};

export type MobileAccess = {
  __typename?: 'MobileAccess';
  enabled: Scalars['Boolean']['output'];
};

export type MobileAccessChannel = {
  __typename?: 'MobileAccessChannel';
  channelId: Scalars['UUID']['output'];
  channelName: Scalars['String']['output'];
  mobileAccess?: Maybe<Scalars['Boolean']['output']>;
};

export enum MobilePermission {
  AccessFineLocation = 'AccessFineLocation',
  AlwaysLocation = 'AlwaysLocation',
  BackgroundTask = 'BackgroundTask',
  Bluetooth = 'Bluetooth',
  BluetoothPeripheral = 'BluetoothPeripheral',
  Calendar = 'Calendar',
  Location = 'Location',
  ScheduleExactAlarm = 'ScheduleExactAlarm',
  SecureDevice = 'SecureDevice'
}

export type MultiChannelAcgs = {
  __typename?: 'MultiChannelAcgs';
  accessControlGroups?: Maybe<Array<Maybe<AccessControlGroups>>>;
  channelId?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  accounts?: Maybe<AccountsMutations>;
  /** Activate Relay Module */
  activateRelayModule?: Maybe<RelayModule>;
  /** Add integration to a channel */
  addChannelIntegration: ChannelIntegration;
  /** Adds a channel merchant account to this channel, if one doesn't already exist. */
  addChannelMerchantAccount: ChannelMerchant;
  /** Adds a relationship between one channel and another. */
  addChannelRelationship: ChannelRelationship;
  addContentTemplateToLibrary: LibraryItem;
  /** @deprecated Use payments.addCreditCard */
  addCreditCard?: Maybe<CreditCard>;
  addGroupRole: GroupRole;
  addLibraryAccess: LibraryAccess;
  addMediaToLibrary: LibraryItem;
  /**
   * Attempts to add an oAuth login to a the currently logged in user.  The loginType indicates if the login
   * should be added to their UserLogins if successful.  i.e. if the loginType is Email, we will also add this
   * email to the list of verified logins for that user.
   */
  addOAuthLogin: Scalars['Boolean']['output'];
  addSectionContent: SectionContent;
  /** Adds a new section to a channel */
  addSectionToChannel: Section;
  addUserGroupRole: Scalars['Boolean']['output'];
  /** Set archived flag on audience to true */
  archiveAudience: Scalars['Boolean']['output'];
  /** Archives a Channel. */
  archiveChannel: Scalars['Boolean']['output'];
  /** Archive Escalation Rule */
  archiveEscalationRule?: Maybe<Scalars['Boolean']['output']>;
  archiveMeterReadingValue: Scalars['String']['output'];
  assignAccessManager: Scalars['Boolean']['output'];
  /** Auto Setup CES property in Activate */
  autoSetupCES: AutoSetupCesEntityResponse;
  /** Cancel submission */
  cancelSubmission: CancelSubmissionResponse;
  /** Clones an existing channel */
  cloneChannel: Channel;
  completeSignup: Token;
  /** Complete Task */
  completeTask: CompleteTaskResponseDto;
  /** Create Access Point */
  createAccessPoint?: Maybe<AccessPoint>;
  createApiKey: ApiKey;
  /** Creates issuances token for mobile ios apple wallet integration */
  createAppleWalletIssuanceToken: AppleWalletIssuanceToken;
  createAppleWatchIssuanceToken: AppleWalletIssuanceToken;
  createAttachment: Attachment;
  /** Creates audience */
  createAudience: CreateAudienceResponse;
  createAuth0MembershipInvitation: Auth0MembershipInvitation;
  /** Creates Auto Setup configuration */
  createAutoSetupConfiguration: JobResponse;
  createBluetoothInvitationCode: BluetoothInvitationCode;
  /** Creates a Channel. */
  createChannel: Channel;
  createChannelContract: ChannelContract;
  createChannelIntegration?: Maybe<ChannelIntegration>;
  /** Create Channel module */
  createChannelModule: ChannelModule;
  createContentInteraction: UserContentInteraction;
  /**
   * Creates a ContentTemplate on a specific library (channel library, user library, regular library) etc.  Because
   * a Template can't exist just on its own.
   */
  createContentTemplate: LibraryItem;
  createDatasetRecord: DatasetRecord;
  createDatasetSchema: DatasetSchema;
  createDraft: DraftContent;
  createEquipment: Equipment;
  /** Create Escalation Rule */
  createEscalationRule?: Maybe<Scalars['String']['output']>;
  /** Create floor */
  createFloor: Scalars['UUID']['output'];
  /** Create Hardware */
  createHardware: Hardware;
  /** Creates historical UCI for Rise->Activate migration */
  createHistoricalUCI: UserContentInteraction;
  createIntegration: Integration;
  createLibrary: Library;
  createLibraryPath: LibraryItem;
  /**
   * Creates a Media on a specific library (channel library, user library, regular library) etc.  Because
   * Media can't exist just on its own.
   */
  createMedia: LibraryItemWithOriginalExtension;
  createMetatag: LibraryItem;
  createMeterReadingValue: MeterReadingValue;
  createMultipleMeterReadingValue: Array<Scalars['String']['output']>;
  /** Creates a new Null Entity Organization for Parent Channel */
  createNullEntityOrganization: Scalars['UUID']['output'];
  createOIDCServiceProviderDefinition: OidcServiceProviderDefinition;
  /** Creates a one time password for the given user. Part of the two factor authentication flow */
  createOneTimePassword: OneTimePassword;
  /** Creates Preventive Maintenance Schedule */
  createPMSchedule: ClientPmSchedule;
  /** Creates a product and returns the new product details if successful */
  createProduct?: Maybe<CreateProductResponse>;
  /** Creates a product category and returns the transaction id if successful */
  createProductCategory?: Maybe<CreateProductCategoryResponse>;
  createProductException?: Maybe<CreateProductExceptionResponse>;
  /** Creates a new Property Building Entity for Channel */
  createPropertyBuilding: Scalars['UUID']['output'];
  createSAMLServiceProviderDefinition: SamlServiceProviderDefinition;
  /** Creates ServiceRequest */
  createServiceRequest: ServiceRequest;
  createTag: Tag;
  createTargetingRule: CreateTargetingRuleResponse;
  /** Creates Task */
  createTask: Task;
  /** Create tenant */
  createTenant: Scalars['String']['output'];
  /** Create unit */
  createUnit: Scalars['UUID']['output'];
  createUserAndAddToTeams?: Maybe<Scalars['ID']['output']>;
  /**
   * Create a UserIntegration for a specific ChannelIntegration, this is a link between a User and a 3rd party integration
   * This method is only used for legacy integrations that don't support OAuth or some other SSO method.
   */
  createUserIntegration: UserIntegration;
  /** Creates visitor */
  createVisitor: Visitor;
  /** Bulk creates visitors */
  createVisitors: Array<Visitor>;
  createWebhook: LibraryItem;
  createWhitelabel: Whitelabel;
  createWorkflow: Workflow;
  /** Deactivate Relay Module */
  deactivateRelayModule?: Maybe<RelayModule>;
  deleteAttachment: Attachment;
  deleteAudience: Scalars['Boolean']['output'];
  deleteChannelIntegration: Scalars['Boolean']['output'];
  /** Delete Channel module */
  deleteChannelModule: Scalars['Boolean']['output'];
  /** Removes an existing relationship between two channels */
  deleteChannelRelationship: Scalars['Boolean']['output'];
  deleteContentTemplate?: Maybe<Scalars['Boolean']['output']>;
  deleteDatasetRecord: Scalars['Boolean']['output'];
  deleteDatasetSchema: Scalars['Boolean']['output'];
  deleteDraft: Scalars['Boolean']['output'];
  deleteEquipment: Scalars['Boolean']['output'];
  /** Delete floor */
  deleteFloor: Scalars['Boolean']['output'];
  deleteGroupRole?: Maybe<Scalars['Boolean']['output']>;
  deleteIntegration: Scalars['Boolean']['output'];
  deleteInvite: Scalars['Boolean']['output'];
  deleteLibrary: Library;
  deleteMedia?: Maybe<Scalars['Boolean']['output']>;
  deleteMetatag: Scalars['Boolean']['output'];
  /** Deletes Preventive Maintenance Schedule */
  deletePMSchedule: Scalars['Boolean']['output'];
  /** Deletes a product and returns nothing if successful */
  deleteProduct?: Maybe<Scalars['Void']['output']>;
  /** Deletes a product exception and returns nothing if successful */
  deleteProductException?: Maybe<Scalars['Void']['output']>;
  deleteSection?: Maybe<Scalars['Boolean']['output']>;
  deleteTag: Scalars['Boolean']['output'];
  deleteTargetingRule: Scalars['Boolean']['output'];
  /** Deletes Task */
  deleteTask: Scalars['Boolean']['output'];
  /** Delete unit */
  deleteUnit: Scalars['Boolean']['output'];
  /** Disables a user and deletes all their personal information */
  deleteUser: Scalars['Boolean']['output'];
  deleteUserGroupRole: Scalars['Boolean']['output'];
  deleteWebhook: Scalars['Boolean']['output'];
  deleteWorkflow: Scalars['String']['output'];
  disableApiKey: Scalars['Boolean']['output'];
  disableMobileAccess: Scalars['Boolean']['output'];
  /** Disables a user */
  disableUser: Scalars['Boolean']['output'];
  enableMobileAccess: Array<EnableMobileAccessResponse>;
  /** Re-enables a disabled user */
  enableUser: Scalars['Boolean']['output'];
  essensys?: Maybe<EssensysMutation>;
  /** executes a database operation that fixes channel associations for whutelabel channels */
  finalizeTenantOnboarding: Scalars['Boolean']['output'];
  forgotPassword: Scalars['Boolean']['output'];
  generateFloorMapChannelIntegration: Scalars['Boolean']['output'];
  /** get currrent auth0 connection for organization */
  getCurrentAuth0Connection?: Maybe<Auth0Connection>;
  /**
   * Joins the currently logged in user to a Channel as a Subscriber.
   *
   * joinChannel may not return the expected userGroupRole because of channel settings.
   */
  joinChannel: PublicUserGroupRole;
  /** Join a Content Waitlist for which the Quantity or Reservable feature are enabled */
  joinWaitlist: ContentWaitlistEnrollment;
  /** Given the id of a users group role at a channel, this will remove them from that channel. */
  leaveChannel: Scalars['Boolean']['output'];
  /** Leave Reservable Content Waitlist */
  leaveReservableWaitlist: ReservableWaitlistUnrollment;
  /** Leave a Content Waitlist for which the Quantity feature is enabled */
  leaveWaitlist: ContentWaitlistEnrollment;
  /** Creates REFERENCE relationship between parent Channel and provided "top level" Entity in CES */
  linkActivateOrganizationToCesAccount: Scalars['Boolean']['output'];
  /** Creates/updates Linkage between an Activate entity and its external counterpart */
  linkEntity: LinkedEntity;
  /** Adds or removes the user from the property's tenant channels (companies) */
  manageUserCompanies: Scalars['Boolean']['output'];
  me?: Maybe<MeMutation>;
  mergeUsers: Scalars['Boolean']['output'];
  migrateRiseUser: Scalars['Boolean']['output'];
  /** Adds ParkWhiz Vehicle to logged in user */
  parkwhizAddVehicle: ParkWhizVehicle;
  payments?: Maybe<PaymentsMutation>;
  pinSectionContent: Scalars['Boolean']['output'];
  placeContent: ContentPlacement;
  /**
   * Provision a user to an organization in Auth0
   * @deprecated This is dreprecated and use getCurrentAuth0Connection instead
   */
  provisionAuth0UserToOrganization?: Maybe<Auth0ProvisionUserToOrganization>;
  publishDraft: Content;
  pullContent: Scalars['Boolean']['output'];
  /** Redeems the one time password sent by a given user. Part of the two factor authentication flow */
  redeemOneTimePassword: Token;
  /** Regenerate Hardware API Key */
  regenerateHardwareApiKey: Hardware;
  /** Register Kiosk Visitor Pass. Only accessible by hardware application (e.g. Kiosk). */
  registerKioskVisitorPassScan: RegisterKioskVisitorPassScanResponse;
  /** Creates IS relationship between Property Channel and provided Building Entity in CES */
  relateActivateChannelToCesProperty: Scalars['Boolean']['output'];
  /** Add and remove spaces to Tenant */
  relateSpacesToTenant: Scalars['Boolean']['output'];
  removeContentTemplateFromLibrary: Scalars['Boolean']['output'];
  /** @deprecated Use payments.removeCreditCard */
  removeCreditCard: Scalars['Boolean']['output'];
  removeLibraryAccess: Scalars['Boolean']['output'];
  /** Removes media from a library given the LibraryItem _id */
  removeMediaFromLibrary: Scalars['Boolean']['output'];
  removeSectionContent: Scalars['Boolean']['output'];
  /** Remove tenant */
  removeTenant: Scalars['Boolean']['output'];
  removeUserFromChannel: Scalars['Boolean']['output'];
  /** Remove  a user Integration for specific ChannelIntegration */
  removeUserIntegration: Scalars['Boolean']['output'];
  reorderSection: Scalars['Boolean']['output'];
  /** Request to have the user's account deleted */
  requestAccountDeletion: Scalars['DateTime']['output'];
  /** Sends verification text to phone number */
  requestPhoneNumberVerification: Scalars['Boolean']['output'];
  resendInvite: Scalars['Boolean']['output'];
  /** Resend User verification email */
  resendVerificationEmail: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  resetUserPasswordsAdmin: Array<Scalars['JSON']['output']>;
  resumeCredential: Scalars['Boolean']['output'];
  resumeCredentialGroup: Scalars['Boolean']['output'];
  /** Reverts the user's request to have their account deleted */
  revertAccountDeletion: Scalars['Boolean']['output'];
  revokeAccessManager: Scalars['Boolean']['output'];
  revokeCredential: Scalars['Boolean']['output'];
  revokeCredentialGroup: Scalars['Boolean']['output'];
  /** save the file import config */
  saveConfig: SaveConfigResponse;
  sendCompleteSignup: Scalars['Boolean']['output'];
  sendEvent?: Maybe<Scalars['Boolean']['output']>;
  sendInvite: ChannelInvite;
  sendMagicLink: Scalars['Boolean']['output'];
  /** Sends opt-int text to phone number */
  sendOptInMessage: Scalars['Boolean']['output'];
  sendUrgentCommunication: Scalars['String']['output'];
  signIn: Token;
  signOut: Scalars['Boolean']['output'];
  /**
   * Signs up a user with a loginKey, loginType, loginProvider combination.
   * IE. loginType = Email, loginKey = 'test@test.com', loginProvider = 'google'
   *
   * The included list of channelIds will be attempted to be joined in a Workplace Member role.
   *
   * If inviteID is included, the user will claim that invite after being created.
   */
  signUp: Token;
  softDeleteChannelIntegration: Scalars['Boolean']['output'];
  /** start the file import */
  startImportJob: StartImportJobResponse;
  /** General feedback form */
  submitFeedbackForm?: Maybe<FeedbackForm>;
  subscribeUserToEvent?: Maybe<EventSubscription>;
  suspendCredential: Scalars['Boolean']['output'];
  suspendCredentialGroup: Scalars['Boolean']['output'];
  syncGroupRoleForExistingUsers: Scalars['Boolean']['output'];
  /**
   * Tracks a users general social/analytic action on a channel.
   * pin, like, share, details, etc.
   */
  touchChannel: Channel;
  touchContent: Content;
  touchUser: User;
  trackAnalytics?: Maybe<Scalars['Boolean']['output']>;
  /** Trigger Access Point */
  triggerAccessPoint?: Maybe<TriggerAccessPointResponse>;
  unPublishContent: Scalars['Boolean']['output'];
  unpinSectionContent: Scalars['Boolean']['output'];
  unsubscribeFromEvent?: Maybe<Scalars['Boolean']['output']>;
  /** Update Access Point */
  updateAccessPoint?: Maybe<AccessPoint>;
  /** Updates audience */
  updateAudience: UpdateAudienceResponse;
  updateBulkUsersAccessControlGroups: Scalars['Boolean']['output'];
  /** Update a Channel. */
  updateChannel: Channel;
  updateChannelContract: ChannelContract;
  updateChannelGroupRoles: Scalars['Boolean']['output'];
  updateChannelIntegration?: Maybe<ChannelIntegration>;
  /** Update Channel module */
  updateChannelModule: ChannelModule;
  updateContentInteraction: UserContentInteraction;
  updateContentTemplate: ContentTemplate;
  updateDatasetRecord: DatasetRecord;
  updateDatasetSchema: DatasetSchema;
  updateDraft: DraftContent;
  updateEquipment: Equipment;
  updateEquipmentSettingsOnChannel: ChannelEquipmentSettings;
  /** Update Escalation Rule */
  updateEscalationRule?: Maybe<Scalars['String']['output']>;
  /** Update floor */
  updateFloor: Scalars['UUID']['output'];
  updateGroupRole: GroupRole;
  /** Update Hardware */
  updateHardware: Hardware;
  /** Update device Info of Hardware */
  updateHardwareDeviceInfo: UpdateHardwareDeviceInfoResponse;
  updateIntegration: Integration;
  updateLibrary: Library;
  updateLibraryItem: LibraryItem;
  updateMedia: MediaWithOriginalExtension;
  updateMetatag: Metatag;
  updateMeterReadingValue: MeterReadingValue;
  /** Updates Preventive Maintenance Schedule */
  updatePMSchedule: UpdatePmScheduleResponseDto;
  /** Updates a product and returns the product details if successful */
  updateProduct?: Maybe<UpdateProductResponse>;
  /** Updates a product exception and returns the product exception details if successful */
  updateProductException: UpdateProductExceptionResponse;
  /** Update recurrence visitor passes */
  updateRecurrencePasses: UpdateRecurrencePassesResponse;
  /** Update Relay Module */
  updateRelayModule: RelayModule;
  /** Update Relay Output */
  updateRelayOutput: RelayOutput;
  updateSection: Section;
  updateSectionContent: SectionContent;
  /** Updates ServiceRequest */
  updateServiceRequest: ServiceRequestUpdateResponseDto;
  /** Update Service Request Status by the reference UCI Id */
  updateServiceRequestStatusByUCIId?: Maybe<Scalars['Void']['output']>;
  updateTag: Tag;
  updateTargetingRule: UpdateTargetingRuleResponse;
  /** Updates Task */
  updateTask: UpdateTaskResponseDto;
  /** Updates Task Status */
  updateTaskStatus: UpdateTaskResponseDto;
  /** Updates Task Steps */
  updateTaskSteps: UpdateTaskResponseDto;
  /** Updates a theme */
  updateTheme: Theme;
  /** Update unit */
  updateUnit: Scalars['UUID']['output'];
  updateUser: User;
  updateUserAccessControlGroups: Scalars['Boolean']['output'];
  updateUserChannelSettings?: Maybe<UserChannelSettings>;
  updateUserGrantSuper: User;
  updateUserRevokeSuper: User;
  /** Updates the status of all users in a channel */
  updateUserStatusesForChannel?: Maybe<Scalars['Int']['output']>;
  /** Update visitor pass */
  updateVisitorPass: VisitorPassUpdateResponse;
  /** Update visitor pass status */
  updateVisitorPassStatus: VisitorPassUpdateResponse;
  updateWebhook: Webhook;
  updateWhitelabel: Whitelabel;
  updateWorkflow: Workflow;
  /** Uploads CSV for Auto Import */
  uploadCSV: UploadCsvResponse;
  upsertDatasetRecords: Array<DatasetRecord>;
  /** upsert service request Category */
  upsertServiceRequestCategory: CategoryUpsertResponseDto;
  /** upsert service request Issue */
  upsertServiceRequestIssue: IssueUpsertResponseDto;
  /** upsert service request Location */
  upsertServiceRequestLocation: LocationUpsertResponseDto;
  upsertUserGroupRoles: Scalars['Boolean']['output'];
  /** Upsert vistior email template */
  upsertVisitorEmailTemplate: VisitorEmailTemplate;
  /** Upsert vistior organization settings */
  upsertVisitorOrganizationSettings: VisitorOrganizationSettings;
  verifyEmailOnBehalfOfUser: Scalars['Boolean']['output'];
  /** Sends verification text to phone number */
  verifyPhoneNumber: Scalars['Boolean']['output'];
};


export type MutationActivateRelayModuleArgs = {
  input: RelayAction;
};


export type MutationAddChannelIntegrationArgs = {
  channelId: Scalars['UUID']['input'];
  integrationId: Scalars['UUID']['input'];
  settings?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationAddChannelMerchantAccountArgs = {
  merchantAccount: ChannelMerchantInput;
};


export type MutationAddChannelRelationshipArgs = {
  relationship: ChannelRelationshipInput;
};


export type MutationAddContentTemplateToLibraryArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationAddCreditCardArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  source: Scalars['String']['input'];
};


export type MutationAddGroupRoleArgs = {
  groupRole: GroupRoleInput;
};


export type MutationAddLibraryAccessArgs = {
  libraryAccess: LibraryAccessInput;
};


export type MutationAddMediaToLibraryArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationAddOAuthLoginArgs = {
  channelIntegrationId?: InputMaybe<Scalars['UUID']['input']>;
  loginType?: UserLoginType;
  password?: InputMaybe<Scalars['String']['input']>;
  provider: UserLoginProvider;
  userKey: Scalars['String']['input'];
};


export type MutationAddSectionContentArgs = {
  sectionContent: SectionContentInput;
};


export type MutationAddSectionToChannelArgs = {
  section: SectionInput;
};


export type MutationAddUserGroupRoleArgs = {
  userGroupRole: UserGroupRoleInput;
};


export type MutationArchiveAudienceArgs = {
  audienceId: Scalars['UUID']['input'];
  channelId: Scalars['UUID']['input'];
};


export type MutationArchiveChannelArgs = {
  channelId: Scalars['UUID']['input'];
};


export type MutationArchiveEscalationRuleArgs = {
  escalationRuleId: Scalars['UUID']['input'];
};


export type MutationArchiveMeterReadingValueArgs = {
  channelId: Scalars['String']['input'];
  meterReadingValueId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationAssignAccessManagerArgs = {
  channelId: Scalars['UUID']['input'];
  companyChannelIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  isCompanyAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['UUID']['input'];
};


export type MutationAutoSetupCesArgs = {
  request: AutoSetupCesEntityRequest;
};


export type MutationCancelSubmissionArgs = {
  submissionId: Scalars['UUID']['input'];
};


export type MutationCloneChannelArgs = {
  channelId: Scalars['UUID']['input'];
  options: CloneChannelOptionsInput;
};


export type MutationCompleteSignupArgs = {
  guid: Scalars['String']['input'];
  isPublic: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  verify: Scalars['String']['input'];
};


export type MutationCompleteTaskArgs = {
  task: CompleteTask;
};


export type MutationCreateAccessPointArgs = {
  input: CreateAccessPointRequest;
};


export type MutationCreateApiKeyArgs = {
  apiKey: ApiKeyCreateInput;
};


export type MutationCreateAppleWalletIssuanceTokenArgs = {
  channelId: Scalars['UUID']['input'];
  provider: Scalars['String']['input'];
};


export type MutationCreateAppleWatchIssuanceTokenArgs = {
  channelId: Scalars['UUID']['input'];
  provider: Scalars['String']['input'];
};


export type MutationCreateAttachmentArgs = {
  attachment?: InputMaybe<AttachmentInput>;
};


export type MutationCreateAudienceArgs = {
  audience?: InputMaybe<CreateAudienceInput>;
};


export type MutationCreateAuth0MembershipInvitationArgs = {
  email: Scalars['String']['input'];
};


export type MutationCreateAutoSetupConfigurationArgs = {
  autoSetupData?: InputMaybe<CreateAutoSetupDto>;
};


export type MutationCreateBluetoothInvitationCodeArgs = {
  channelId: Scalars['UUID']['input'];
  provider: Scalars['String']['input'];
};


export type MutationCreateChannelArgs = {
  channel: ChannelInput;
};


export type MutationCreateChannelContractArgs = {
  channelContract: ChannelContractInput;
};


export type MutationCreateChannelIntegrationArgs = {
  channelIntegration: ChannelIntegrationInput;
};


export type MutationCreateChannelModuleArgs = {
  channel: Scalars['JSON']['input'];
  channelModule: ChannelModuleInput;
};


export type MutationCreateContentInteractionArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  contentId: Scalars['UUID']['input'];
  interaction: UserContentInteractionInput;
  meChannelId?: InputMaybe<Scalars['UUID']['input']>;
  submittingAsWorkplaceMember?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateContentTemplateArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationCreateDatasetRecordArgs = {
  datasetRecord: DatasetRecordInput;
};


export type MutationCreateDatasetSchemaArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationCreateDraftArgs = {
  draft?: InputMaybe<DraftContentInput>;
};


export type MutationCreateEquipmentArgs = {
  equipment?: InputMaybe<EquipmentInput>;
};


export type MutationCreateEscalationRuleArgs = {
  escalationRule: InputCreateEscalationRule;
};


export type MutationCreateFloorArgs = {
  floor: CreateFloorInput;
  propertyId: Scalars['UUID']['input'];
};


export type MutationCreateHardwareArgs = {
  input: HardwareInput;
};


export type MutationCreateHistoricalUciArgs = {
  contentId: Scalars['UUID']['input'];
  created: Scalars['DateTime']['input'];
  creatorId: Scalars['UUID']['input'];
  interaction: UserContentInteractionInput;
};


export type MutationCreateIntegrationArgs = {
  integration: IntegrationInput;
};


export type MutationCreateLibraryArgs = {
  library: LibraryInput;
};


export type MutationCreateLibraryPathArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationCreateMediaArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationCreateMetatagArgs = {
  libraryItem: LibraryItemInput;
  sectionId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCreateMeterReadingValueArgs = {
  channelId: Scalars['String']['input'];
  meterReadingValue?: InputMaybe<MeterReadingValueInput>;
};


export type MutationCreateMultipleMeterReadingValueArgs = {
  channelId: Scalars['String']['input'];
  meterReadingValues?: InputMaybe<Array<MeterReadingValueInput>>;
};


export type MutationCreateNullEntityOrganizationArgs = {
  parentChannelId: Scalars['UUID']['input'];
};


export type MutationCreateOidcServiceProviderDefinitionArgs = {
  channelId: Scalars['UUID']['input'];
  oidcServiceProviderDefinition: OidcServiceProviderDefinitionInput;
};


export type MutationCreateOneTimePasswordArgs = {
  userId: Scalars['UUID']['input'];
};


export type MutationCreatePmScheduleArgs = {
  schedule: CreatePmScheduleDto;
};


export type MutationCreateProductArgs = {
  createProductRequest: CreateProductRequest;
};


export type MutationCreateProductCategoryArgs = {
  createProductCategoryRequest?: InputMaybe<CreateProductCategoryRequest>;
};


export type MutationCreateProductExceptionArgs = {
  createProductExceptionRequest?: InputMaybe<CreateProductExceptionRequest>;
};


export type MutationCreatePropertyBuildingArgs = {
  channelId: Scalars['UUID']['input'];
};


export type MutationCreateSamlServiceProviderDefinitionArgs = {
  SAMLServiceProviderDefinition: SamlServiceProviderDefinitionInput;
  channelId: Scalars['UUID']['input'];
};


export type MutationCreateServiceRequestArgs = {
  serviceRequestRequest?: InputMaybe<CreateServiceRequestDto>;
};


export type MutationCreateTagArgs = {
  tag: TagInput;
};


export type MutationCreateTargetingRuleArgs = {
  targetingRule?: InputMaybe<CreateTargetingRuleInput>;
};


export type MutationCreateTaskArgs = {
  task: CreateTask;
};


export type MutationCreateTenantArgs = {
  propertyId: Scalars['UUID']['input'];
  tenantId: Scalars['UUID']['input'];
};


export type MutationCreateUnitArgs = {
  propertyId: Scalars['UUID']['input'];
  unit: UnitInput;
};


export type MutationCreateUserAndAddToTeamsArgs = {
  channelId: Scalars['ID']['input'];
  email: Scalars['EmailAddress']['input'];
  emailOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  teamsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tenant?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<UserStatus>;
};


export type MutationCreateUserIntegrationArgs = {
  addToLogins?: Scalars['Boolean']['input'];
  channelIntegrationId: Scalars['UUID']['input'];
  loginType?: InputMaybe<UserLoginType>;
  password?: InputMaybe<Scalars['String']['input']>;
  userKey: Scalars['String']['input'];
};


export type MutationCreateVisitorArgs = {
  visitor: VisitorInput;
};


export type MutationCreateVisitorsArgs = {
  visitors?: InputMaybe<Array<VisitorInput>>;
};


export type MutationCreateWebhookArgs = {
  libraryItem: LibraryItemInput;
  sectionId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCreateWhitelabelArgs = {
  whitelabel: WhitelabelInput;
};


export type MutationCreateWorkflowArgs = {
  workflow?: InputMaybe<WorkflowInput>;
};


export type MutationDeactivateRelayModuleArgs = {
  input: RelayAction;
};


export type MutationDeleteAttachmentArgs = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteAudienceArgs = {
  audienceId: Scalars['UUID']['input'];
  channelId: Scalars['UUID']['input'];
};


export type MutationDeleteChannelIntegrationArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteChannelModuleArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteChannelRelationshipArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteContentTemplateArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteDatasetRecordArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteDatasetSchemaArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteDraftArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteEquipmentArgs = {
  equipmentId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteFloorArgs = {
  floorId: Scalars['UUID']['input'];
  propertyId: Scalars['UUID']['input'];
};


export type MutationDeleteGroupRoleArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteIntegrationArgs = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteInviteArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteLibraryArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteMediaArgs = {
  _id: Scalars['UUID']['input'];
  libraryId: Scalars['UUID']['input'];
  libraryItemId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteMetatagArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeletePmScheduleArgs = {
  scheduleId: Scalars['String']['input'];
};


export type MutationDeleteProductArgs = {
  deleteProductRequest?: InputMaybe<DeleteProductRequest>;
};


export type MutationDeleteProductExceptionArgs = {
  deleteProductExceptionRequest: DeleteProductExceptionRequest;
};


export type MutationDeleteSectionArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteTargetingRuleArgs = {
  targetingRule?: InputMaybe<DeleteTargetingRuleInput>;
};


export type MutationDeleteTaskArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUnitArgs = {
  propertyId: Scalars['UUID']['input'];
  unitId: Scalars['UUID']['input'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['UUID']['input'];
};


export type MutationDeleteUserGroupRoleArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteWebhookArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationDeleteWorkflowArgs = {
  workflowId: Scalars['ID']['input'];
};


export type MutationDisableApiKeyArgs = {
  apiKey: ApiKeyDisableInput;
};


export type MutationDisableMobileAccessArgs = {
  channelId: Scalars['UUID']['input'];
  userIds: Array<Scalars['UUID']['input']>;
};


export type MutationDisableUserArgs = {
  userId: Scalars['UUID']['input'];
};


export type MutationEnableMobileAccessArgs = {
  channelId: Scalars['UUID']['input'];
  connectedChannelIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  userClearances: Array<UserClearanceInput>;
};


export type MutationEnableUserArgs = {
  userId: Scalars['UUID']['input'];
};


export type MutationFinalizeTenantOnboardingArgs = {
  channelIds: Array<Scalars['UUID']['input']>;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['EmailAddress']['input'];
};


export type MutationGenerateFloorMapChannelIntegrationArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
  toRestart?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationGetCurrentAuth0ConnectionArgs = {
  email: Scalars['String']['input'];
};


export type MutationJoinChannelArgs = {
  channelId: Scalars['UUID']['input'];
};


export type MutationJoinWaitlistArgs = {
  contentId: Scalars['UUID']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  userId: Scalars['UUID']['input'];
};


export type MutationLeaveChannelArgs = {
  userGroupRoleId: Scalars['UUID']['input'];
};


export type MutationLeaveReservableWaitlistArgs = {
  enrollmentId: Scalars['UUID']['input'];
};


export type MutationLeaveWaitlistArgs = {
  contentId: Scalars['UUID']['input'];
  enrollmentId: Scalars['UUID']['input'];
};


export type MutationLinkActivateOrganizationToCesAccountArgs = {
  parentChannelId: Scalars['UUID']['input'];
  targetSourceId: SourceEntityIdentifierInput;
};


export type MutationLinkEntityArgs = {
  linkedEntity?: InputMaybe<LinkedEntityInput>;
};


export type MutationManageUserCompaniesArgs = {
  addedChannelIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  propertyChannelId: Scalars['UUID']['input'];
  removedChannelIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  userId: Scalars['UUID']['input'];
};


export type MutationMergeUsersArgs = {
  destinationUserId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type MutationMigrateRiseUserArgs = {
  channelId: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  teamsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationParkwhizAddVehicleArgs = {
  label: Scalars['String']['input'];
  plate_number: Scalars['String']['input'];
  plate_state?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPinSectionContentArgs = {
  contentId: Scalars['UUID']['input'];
  sectionId: Scalars['UUID']['input'];
};


export type MutationPlaceContentArgs = {
  placement: ContentPlacementInput;
};


export type MutationProvisionAuth0UserToOrganizationArgs = {
  connection: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationPublishDraftArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationPullContentArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  contentId: Scalars['UUID']['input'];
  groupRole?: InputMaybe<Scalars['UUID']['input']>;
  userId: Scalars['UUID']['input'];
};


export type MutationRedeemOneTimePasswordArgs = {
  otp: Scalars['String']['input'];
};


export type MutationRegenerateHardwareApiKeyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRegisterKioskVisitorPassScanArgs = {
  qrCode: Array<InputMaybe<Scalars['Byte']['input']>>;
};


export type MutationRelateActivateChannelToCesPropertyArgs = {
  channelId: Scalars['UUID']['input'];
  targetSourceId: SourceEntityIdentifierInput;
};


export type MutationRelateSpacesToTenantArgs = {
  propertyId: Scalars['UUID']['input'];
  suitesToAdd: Array<Scalars['String']['input']>;
  suitesToRemove: Array<Scalars['String']['input']>;
  tenantId: Scalars['UUID']['input'];
};


export type MutationRemoveContentTemplateFromLibraryArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationRemoveCreditCardArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['String']['input'];
};


export type MutationRemoveLibraryAccessArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationRemoveMediaFromLibraryArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationRemoveSectionContentArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationRemoveTenantArgs = {
  tenantId: Scalars['UUID']['input'];
  unitId: Scalars['UUID']['input'];
};


export type MutationRemoveUserFromChannelArgs = {
  channelId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type MutationRemoveUserIntegrationArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
};


export type MutationReorderSectionArgs = {
  contentId: Scalars['UUID']['input'];
  order: Scalars['Int']['input'];
  sectionId: Scalars['UUID']['input'];
};


export type MutationRequestAccountDeletionArgs = {
  userLoginId: Scalars['UUID']['input'];
};


export type MutationRequestPhoneNumberVerificationArgs = {
  userLoginId: Scalars['UUID']['input'];
};


export type MutationResendInviteArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationResendVerificationEmailArgs = {
  email: Scalars['EmailAddress']['input'];
};


export type MutationResetPasswordArgs = {
  guid: Scalars['UUID']['input'];
  password: Scalars['String']['input'];
  verify?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationResetUserPasswordsAdminArgs = {
  _ids: Array<Scalars['UUID']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResumeCredentialArgs = {
  channelId: Scalars['UUID']['input'];
  mapId: Scalars['String']['input'];
};


export type MutationResumeCredentialGroupArgs = {
  channelId: Scalars['UUID']['input'];
  credentialGroupId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};


export type MutationRevertAccountDeletionArgs = {
  jti: Scalars['UUID']['input'];
};


export type MutationRevokeAccessManagerArgs = {
  channelId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type MutationRevokeCredentialArgs = {
  channelId: Scalars['UUID']['input'];
  mapId: Scalars['String']['input'];
};


export type MutationRevokeCredentialGroupArgs = {
  channelId: Scalars['UUID']['input'];
  credentialGroupId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};


export type MutationSaveConfigArgs = {
  config?: InputMaybe<SaveConfigRequest>;
};


export type MutationSendCompleteSignupArgs = {
  userGroupRoleId: Scalars['UUID']['input'];
};


export type MutationSendEventArgs = {
  data: Scalars['JSON']['input'];
  event: Scalars['EventType']['input'];
};


export type MutationSendInviteArgs = {
  accessControlGroups?: InputMaybe<Array<AccessControlGroupsForInvite>>;
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  email: Scalars['EmailAddress']['input'];
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  groupRoleId?: InputMaybe<Scalars['UUID']['input']>;
  groupRoleIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  mobileAccess?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  residentType?: InputMaybe<ResidentType>;
  unitsForResident?: InputMaybe<UnitsForResident>;
};


export type MutationSendMagicLinkArgs = {
  email: Scalars['EmailAddress']['input'];
};


export type MutationSendOptInMessageArgs = {
  userId: Scalars['UUID']['input'];
};


export type MutationSendUrgentCommunicationArgs = {
  body: Scalars['String']['input'];
  propertyId: Scalars['UUID']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignInArgs = {
  loginKey: Scalars['ID']['input'];
  loginProvider?: InputMaybe<UserLoginProvider>;
  loginType?: InputMaybe<UserLoginType>;
  password?: InputMaybe<Scalars['String']['input']>;
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignOutArgs = {
  jti: Scalars['UUID']['input'];
};


export type MutationSignUpArgs = {
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  emailOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  inviteId?: InputMaybe<Scalars['UUID']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['SupportedLocale']['input']>;
  loginKey: Scalars['ID']['input'];
  loginProvider?: InputMaybe<UserLoginProvider>;
  loginType: UserLoginType;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSoftDeleteChannelIntegrationArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationStartImportJobArgs = {
  job?: InputMaybe<StartImportJobRequest>;
};


export type MutationSubmitFeedbackFormArgs = {
  feedback?: InputMaybe<FeedbackFormInput>;
};


export type MutationSubscribeUserToEventArgs = {
  eventSubscription: EventSubscriptionInput;
};


export type MutationSuspendCredentialArgs = {
  channelId: Scalars['UUID']['input'];
  mapId: Scalars['String']['input'];
};


export type MutationSuspendCredentialGroupArgs = {
  channelId: Scalars['UUID']['input'];
  credentialGroupId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
};


export type MutationSyncGroupRoleForExistingUsersArgs = {
  syncGroupRole: SyncGroupRoleInput;
};


export type MutationTouchChannelArgs = {
  _id: Scalars['UUID']['input'];
  touch?: InputMaybe<UserObjectInfoInput>;
};


export type MutationTouchContentArgs = {
  _id: Scalars['UUID']['input'];
  touch?: InputMaybe<UserObjectInfoInput>;
};


export type MutationTouchUserArgs = {
  _id: Scalars['UUID']['input'];
  touch?: InputMaybe<UserObjectInfoInput>;
};


export type MutationTrackAnalyticsArgs = {
  track: Array<TrackAnalytic>;
};


export type MutationTriggerAccessPointArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnPublishContentArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationUnpinSectionContentArgs = {
  contentId: Scalars['UUID']['input'];
  sectionId: Scalars['UUID']['input'];
};


export type MutationUnsubscribeFromEventArgs = {
  _id: Scalars['UUID']['input'];
};


export type MutationUpdateAccessPointArgs = {
  input: UpdateAccessPointRequest;
};


export type MutationUpdateAudienceArgs = {
  audience?: InputMaybe<UpdateAudienceInput>;
};


export type MutationUpdateBulkUsersAccessControlGroupsArgs = {
  assignedAccessControlGroups: Array<AccessControlGroupsMapInput>;
  channelId: Scalars['UUID']['input'];
  revokedAccessControlGroups: Array<AccessControlGroupsMapInput>;
  userIds: Array<Scalars['UUID']['input']>;
};


export type MutationUpdateChannelArgs = {
  channel: ChannelInput;
};


export type MutationUpdateChannelContractArgs = {
  channelContract: ChannelContractInput;
};


export type MutationUpdateChannelGroupRolesArgs = {
  groupRole: ChannelGroupRoleInput;
};


export type MutationUpdateChannelIntegrationArgs = {
  channelIntegration: ChannelIntegrationInput;
};


export type MutationUpdateChannelModuleArgs = {
  channelModule: ChannelModuleInput;
};


export type MutationUpdateContentInteractionArgs = {
  interaction: UserContentInteractionInput;
  meChannelId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUpdateContentTemplateArgs = {
  template: ContentTemplateInput;
};


export type MutationUpdateDatasetRecordArgs = {
  datasetRecord: DatasetRecordUpdateInput;
};


export type MutationUpdateDatasetSchemaArgs = {
  datasetSchema: DatasetSchemaInput;
};


export type MutationUpdateDraftArgs = {
  draft?: InputMaybe<DraftContentInput>;
};


export type MutationUpdateEquipmentArgs = {
  equipment?: InputMaybe<UpdateEquipmentInput>;
};


export type MutationUpdateEquipmentSettingsOnChannelArgs = {
  settings: ChannelEquipmentSettingsInput;
};


export type MutationUpdateEscalationRuleArgs = {
  escalationRule: InputUpdateEscalationRule;
};


export type MutationUpdateFloorArgs = {
  floor: FloorInput;
  floorId: Scalars['UUID']['input'];
  propertyId: Scalars['UUID']['input'];
};


export type MutationUpdateGroupRoleArgs = {
  groupRole: GroupRoleInput;
};


export type MutationUpdateHardwareArgs = {
  id: Scalars['ID']['input'];
  input: UpdateHardwareInput;
};


export type MutationUpdateHardwareDeviceInfoArgs = {
  input: UpdateHardwareDeviceInfoInput;
};


export type MutationUpdateIntegrationArgs = {
  integration?: InputMaybe<IntegrationInput>;
};


export type MutationUpdateLibraryArgs = {
  library: LibraryInput;
};


export type MutationUpdateLibraryItemArgs = {
  libraryItem: LibraryItemInput;
};


export type MutationUpdateMediaArgs = {
  media: MediaInput;
};


export type MutationUpdateMetatagArgs = {
  metatag?: InputMaybe<MetatagInput>;
};


export type MutationUpdateMeterReadingValueArgs = {
  channelId: Scalars['String']['input'];
  meterReadingValue?: InputMaybe<UpdateMeterReadingValueInput>;
};


export type MutationUpdatePmScheduleArgs = {
  schedule: UpdatePmScheduleDto;
};


export type MutationUpdateProductArgs = {
  updateProductRequest?: InputMaybe<UpdateProductRequest>;
};


export type MutationUpdateProductExceptionArgs = {
  updateProductExceptionRequest: UpdateProductExceptionRequest;
};


export type MutationUpdateRecurrencePassesArgs = {
  additionalRecipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newSchedule: Scalars['String']['input'];
  newScheduleDuration: Scalars['String']['input'];
  recurrenceId: Scalars['String']['input'];
  staffNote?: InputMaybe<Scalars['String']['input']>;
  visitorNote?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRelayModuleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRelayModuleInput;
};


export type MutationUpdateRelayOutputArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRelayOutputInput;
};


export type MutationUpdateSectionArgs = {
  section: SectionInput;
};


export type MutationUpdateSectionContentArgs = {
  sectionContent: SectionContentInput;
};


export type MutationUpdateServiceRequestArgs = {
  serviceRequest: UpdateServiceRequestDto;
};


export type MutationUpdateServiceRequestStatusByUciIdArgs = {
  status: Scalars['String']['input'];
  uciId: Scalars['String']['input'];
};


export type MutationUpdateTagArgs = {
  tag: UpdateTagInput;
};


export type MutationUpdateTargetingRuleArgs = {
  targetingRule?: InputMaybe<UpdateTargetingRuleInput>;
};


export type MutationUpdateTaskArgs = {
  task: UpdateTask;
};


export type MutationUpdateTaskStatusArgs = {
  task: UpdateTaskStatus;
};


export type MutationUpdateTaskStepsArgs = {
  task: UpdateTaskSteps;
};


export type MutationUpdateThemeArgs = {
  theme?: InputMaybe<ThemeInput>;
};


export type MutationUpdateUnitArgs = {
  propertyId: Scalars['UUID']['input'];
  unit: UnitInput;
  unitId: Scalars['UUID']['input'];
};


export type MutationUpdateUserArgs = {
  user: UserInput;
};


export type MutationUpdateUserAccessControlGroupsArgs = {
  assignedAccessControlGroups: Array<AccessControlGroupInput>;
  channelId: Scalars['UUID']['input'];
  revokedAccessControlGroups: Array<AccessControlGroupInput>;
  userId: Scalars['UUID']['input'];
};


export type MutationUpdateUserChannelSettingsArgs = {
  channelId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['UUID']['input'];
};


export type MutationUpdateUserGrantSuperArgs = {
  userId: Scalars['UUID']['input'];
};


export type MutationUpdateUserRevokeSuperArgs = {
  userId: Scalars['UUID']['input'];
};


export type MutationUpdateUserStatusesForChannelArgs = {
  channelId: Scalars['UUID']['input'];
  userStatus?: InputMaybe<UserStatus>;
};


export type MutationUpdateVisitorPassArgs = {
  additionalRecipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  staffNote?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  visitorNote?: InputMaybe<Scalars['String']['input']>;
  visitorPassId: Scalars['UUID']['input'];
};


export type MutationUpdateVisitorPassStatusArgs = {
  status: Scalars['String']['input'];
  visitorPassId: Scalars['UUID']['input'];
};


export type MutationUpdateWebhookArgs = {
  webhook: WebhookInput;
};


export type MutationUpdateWhitelabelArgs = {
  whitelabel: WhitelabelInput;
};


export type MutationUpdateWorkflowArgs = {
  workflow?: InputMaybe<WorkflowInput>;
  workflowId: Scalars['ID']['input'];
};


export type MutationUploadCsvArgs = {
  uploadCSVData?: InputMaybe<UploadCsvData>;
};


export type MutationUpsertDatasetRecordsArgs = {
  datasetRecords: Array<DatasetRecordUpdateInput>;
  datasetSchemaId: Scalars['UUID']['input'];
};


export type MutationUpsertServiceRequestCategoryArgs = {
  category: CategoryUpsertRequestDto;
};


export type MutationUpsertServiceRequestIssueArgs = {
  issue: IssueUpsertRequestDto;
};


export type MutationUpsertServiceRequestLocationArgs = {
  location: LocationUpsertRequestDto;
};


export type MutationUpsertUserGroupRolesArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  groupRoleIds: Array<Scalars['UUID']['input']>;
  userId: Scalars['UUID']['input'];
};


export type MutationUpsertVisitorEmailTemplateArgs = {
  emailTemplate: VisitorEmailTemplateInput;
};


export type MutationUpsertVisitorOrganizationSettingsArgs = {
  organizationSettings: VisitorOrganizationSettingsInput;
};


export type MutationVerifyEmailOnBehalfOfUserArgs = {
  channelId: Scalars['UUID']['input'];
  loginId: Scalars['UUID']['input'];
};


export type MutationVerifyPhoneNumberArgs = {
  code: Scalars['String']['input'];
  userLoginId: Scalars['UUID']['input'];
};

export enum NotificationDeliveryType {
  Email = 'Email',
  Push = 'Push',
  Sms = 'SMS',
  Voice = 'Voice'
}

export enum NumericSearchType {
  Eq = 'eq',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  Lt = 'lt',
  Lte = 'lte',
  Ne = 'ne',
  Overlap = 'overlap'
}

export type OAuth = {
  __typename?: 'OAuth';
  accessToken: Scalars['String']['output'];
  expiresIn?: Maybe<Scalars['Int']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type OAuthConfig = {
  __typename?: 'OAuthConfig';
  _id?: Maybe<Scalars['UUID']['output']>;
  androidClientId: Scalars['ID']['output'];
  androidRedirectUrl: Scalars['String']['output'];
  buttonLabel?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  iosClientId: Scalars['ID']['output'];
  iosRedirectUrl: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  tenantId?: Maybe<Scalars['ID']['output']>;
  webClientId: Scalars['ID']['output'];
  webClientSecret?: Maybe<Scalars['String']['output']>;
  webRedirectUrl: Scalars['URL']['output'];
};

export type OAuthConfigInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  androidClientId?: InputMaybe<Scalars['String']['input']>;
  androidRedirectUrl?: InputMaybe<Scalars['URL']['input']>;
  buttonLabel?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  iosClientId?: InputMaybe<Scalars['String']['input']>;
  iosRedirectUrl?: InputMaybe<Scalars['URL']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  webClientId?: InputMaybe<Scalars['String']['input']>;
  webClientSecret?: InputMaybe<Scalars['String']['input']>;
  webRedirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OidcServiceProviderDefinitionInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ObjectAnalytics = {
  __typename?: 'ObjectAnalytics';
  _id: Scalars['UUID']['output'];
  deletes: Scalars['Int']['output'];
  details: Scalars['Int']['output'];
  hides: Scalars['Int']['output'];
  interactions: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  pins: Scalars['Int']['output'];
  rating: Scalars['Float']['output'];
  ratings: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  stars: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type ObjectAnalyticsInput = {
  _id: Scalars['UUID']['input'];
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  deletes: Scalars['Int']['input'];
  details: Scalars['Int']['input'];
  hides: Scalars['Int']['input'];
  interactions: Scalars['Int']['input'];
  likes: Scalars['Int']['input'];
  pins: Scalars['Int']['input'];
  rating: Scalars['Float']['input'];
  shares: Scalars['Int']['input'];
  stars: Scalars['Int']['input'];
  stats?: InputMaybe<Scalars['JSON']['input']>;
  views: Scalars['Int']['input'];
};

export type ObjectCount = {
  __typename?: 'ObjectCount';
  _id: Scalars['UUID']['output'];
  count: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ObjectDatasetRecord = Document & {
  __typename?: 'ObjectDatasetRecord';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  datasetRecord?: Maybe<DatasetRecord>;
};

export type ObjectMetatag = Document & {
  __typename?: 'ObjectMetatag';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  content?: Maybe<Content>;
  metatag: Metatag;
  section?: Maybe<Section>;
  value?: Maybe<Scalars['JSON']['output']>;
  values?: Maybe<Array<ObjectMetatagValue>>;
};

export type ObjectMetatagIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ObjectMetatagInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  content?: InputMaybe<ContentIdInput>;
  metatag?: InputMaybe<MetatagIdInput>;
  section?: InputMaybe<SectionIdInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
  values?: InputMaybe<Array<ObjectMetatagValueInput>>;
};

export type ObjectMetatagSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  content?: InputMaybe<ContentSearch>;
  metatag?: InputMaybe<MetatagSearch>;
  searchMode?: InputMaybe<SearchMode>;
  section?: InputMaybe<SectionSearch>;
  sortBy?: InputMaybe<SortBy>;
};

export type ObjectMetatagValue = Document & {
  __typename?: 'ObjectMetatagValue';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  metatagValue: MetatagValue;
  objectMetatag: ObjectMetatag;
};

export type ObjectMetatagValueInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  metatagValue?: InputMaybe<MetatagValueInput>;
  objectMetatag?: InputMaybe<ObjectMetatagIdInput>;
};

export type ObjectMetatagValueSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  metatagValue?: InputMaybe<MetatagValueSearch>;
  objectMetatag?: InputMaybe<ObjectMetatagSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type OidcServiceProviderDefinition = {
  __typename?: 'OidcServiceProviderDefinition';
  auth0ClientId?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy: PublicUser;
  customFields?: Maybe<Scalars['JSON']['output']>;
  entityId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  updatedBy: PublicUser;
};

export type OneTimePassword = {
  __typename?: 'OneTimePassword';
  success: Scalars['Boolean']['output'];
};

export type OnlinePlanQuote = {
  __typename?: 'OnlinePlanQuote';
  currencyDetail: CurrencyDetail;
  productLineItems: Array<ProductLineItem>;
  totals: Totals;
};

export type OpenpathSetupMobileToken = {
  __typename?: 'OpenpathSetupMobileToken';
  setupMobileToken?: Maybe<Scalars['String']['output']>;
};

export type OrderItemDetails = {
  __typename?: 'OrderItemDetails';
  description?: Maybe<Scalars['String']['output']>;
  dollarAmount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type OrderSummary = {
  __typename?: 'OrderSummary';
  items?: Maybe<Array<Maybe<OrderItemDetails>>>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type Owner = {
  __typename?: 'Owner';
  id: Scalars['String']['output'];
  type: OwnerType;
};

export type OwnerInput = {
  id: Scalars['String']['input'];
  type: OwnerType;
};

export enum OwnerType {
  OwnerTypeActivateCompany = 'OWNER_TYPE_ACTIVATE_COMPANY',
  OwnerTypeActivateUser = 'OWNER_TYPE_ACTIVATE_USER',
  OwnerTypeUnknown = 'OWNER_TYPE_UNKNOWN'
}

export type PmSchedule = {
  __typename?: 'PMSchedule';
  assignee?: Maybe<Scalars['String']['output']>;
  assigneeGroups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  completeWithin?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  daysAhead?: Maybe<Scalars['Int']['output']>;
  equipmentIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  extRefId?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<Scalars['Int']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  meterReading?: Maybe<Scalars['String']['output']>;
  monthNo?: Maybe<Scalars['Int']['output']>;
  nextDueDate?: Maybe<Scalars['DateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  repeats?: Maybe<Scalars['String']['output']>;
  scheduleId?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Scalars['String']['output']>;
  timeToComplete?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  untilDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  weekNo?: Maybe<Scalars['Int']['output']>;
  weekday?: Maybe<Scalars['Int']['output']>;
};

export type PmSchedulesForExportResponseDto = {
  __typename?: 'PMSchedulesForExportResponseDTO';
  schedules?: Maybe<Array<ClientPmSchedule>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  perPage: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PagesOnChannel = {
  __typename?: 'PagesOnChannel';
  channelId: Scalars['UUID']['output'];
  channelPages?: Maybe<Array<Maybe<ChannelPage>>>;
};

export type PaginatedSections = {
  __typename?: 'PaginatedSections';
  sections?: Maybe<Array<Section>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginationInput = {
  perPage?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type ParkWhizAmenitiy = {
  __typename?: 'ParkWhizAmenitiy';
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type ParkWhizBookPreview = {
  __typename?: 'ParkWhizBookPreview';
  coupon_amount?: Maybe<Scalars['JSON']['output']>;
  final_price?: Maybe<Scalars['JSON']['output']>;
  quote_price?: Maybe<Scalars['JSON']['output']>;
  subtotal?: Maybe<Scalars['JSON']['output']>;
  user_credit_amount?: Maybe<Scalars['JSON']['output']>;
};

export type ParkWhizBooking = {
  __typename?: 'ParkWhizBooking';
  _embedded?: Maybe<ParkWhizBookingEmbedded>;
  business_purchase?: Maybe<Scalars['Boolean']['output']>;
  cancellable?: Maybe<Scalars['Boolean']['output']>;
  /** on error this is returned, not sure if this belongs here */
  code?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['Int']['output']>;
  end_time?: Maybe<Scalars['DateTime']['output']>;
  full_price?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  location_id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  on_demand?: Maybe<Scalars['Boolean']['output']>;
  price_paid?: Maybe<Scalars['JSON']['output']>;
  pricing_ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  purchased_at?: Maybe<Scalars['DateTime']['output']>;
  qrCode: Scalars['String']['output'];
  start_time?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ParkWhizBookingEmbedded = {
  __typename?: 'ParkWhizBookingEmbedded';
  _links?: Maybe<Scalars['JSON']['output']>;
  /** for now we should rework response, flatten it and get only some stuff we need. This could change in the future */
  add_ons?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  email?: Maybe<Scalars['String']['output']>;
  marketing_allowed?: Maybe<Scalars['Boolean']['output']>;
  parking_pass?: Maybe<ParkWhizParkingPass>;
};

export type ParkWhizByGeoLocation = {
  __typename?: 'ParkWhizByGeoLocation';
  _embedded?: Maybe<ParkWhizLocation>;
  distance?: Maybe<ParkWhizDistance>;
  end_time?: Maybe<Scalars['DateTime']['output']>;
  location_id: Scalars['String']['output'];
  max_end?: Maybe<Scalars['DateTime']['output']>;
  min_start?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  purchase_options?: Maybe<Array<Maybe<ParkWhizPurchaseOption>>>;
  quote_type?: Maybe<Scalars['String']['output']>;
  seller_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['DateTime']['output']>;
};

export type ParkWhizCoordinate = {
  __typename?: 'ParkWhizCoordinate';
  coordinates?: Maybe<Array<Scalars['Float']['output']>>;
};

export type ParkWhizDistance = {
  __typename?: 'ParkWhizDistance';
  straight_line: ParkWhizStraightLine;
};

export type ParkWhizIcon = {
  __typename?: 'ParkWhizIcon';
  path?: Maybe<Scalars['String']['output']>;
};

export type ParkWhizLocation = {
  __typename?: 'ParkWhizLocation';
  address1?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  entrances?: Maybe<Array<Maybe<ParkWhizCoordinate>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photos?: Maybe<Array<Maybe<ParkWhizPhotos>>>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ParkWhizParkingPass = {
  __typename?: 'ParkWhizParkingPass';
  active?: Maybe<Scalars['Boolean']['output']>;
  amanities?: Maybe<Array<Maybe<ParkWhizAmenitiy>>>;
  disclaimers?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['DateTime']['output']>;
  full_price?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pass_type?: Maybe<Scalars['String']['output']>;
  price_paid?: Maybe<Scalars['JSON']['output']>;
  reseller_pass?: Maybe<Scalars['Boolean']['output']>;
  seller_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  validation?: Maybe<ParkWhizValidation>;
};

export type ParkWhizPhoto = {
  __typename?: 'ParkWhizPhoto';
  URL?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type ParkWhizPhotoSizes = {
  __typename?: 'ParkWhizPhotoSizes';
  galery?: Maybe<ParkWhizPhoto>;
  gallery_thumb?: Maybe<ParkWhizPhoto>;
  hub_frontpage?: Maybe<ParkWhizPhoto>;
  logo?: Maybe<ParkWhizPhoto>;
  original?: Maybe<ParkWhizPhoto>;
  res_ticket?: Maybe<ParkWhizPhoto>;
  search_thumb?: Maybe<ParkWhizPhoto>;
};

export type ParkWhizPhotos = {
  __typename?: 'ParkWhizPhotos';
  position?: Maybe<Scalars['Int']['output']>;
  sizes?: Maybe<ParkWhizPhotoSizes>;
};

export type ParkWhizPricingSegments = {
  __typename?: 'ParkWhizPricingSegments';
  end_time: Scalars['DateTime']['output'];
  event?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pricing_type?: Maybe<Scalars['String']['output']>;
  space_availability?: Maybe<ParkWhizSpaceAvailability>;
  start_time?: Maybe<Scalars['DateTime']['output']>;
};

export type ParkWhizPurchaseOption = {
  __typename?: 'ParkWhizPurchaseOption';
  amenities?: Maybe<Array<ParkWhizAmenitiy>>;
  curation_score: Scalars['Float']['output'];
  end_time?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  max_end?: Maybe<Scalars['DateTime']['output']>;
  min_start?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<Scalars['JSON']['output']>;
  price_advisory?: Maybe<Scalars['String']['output']>;
  pricing_segments?: Maybe<Array<Maybe<ParkWhizPricingSegments>>>;
  space_availability?: Maybe<ParkWhizSpaceAvailability>;
  start_time?: Maybe<Scalars['DateTime']['output']>;
  validation?: Maybe<ParkWhizValidation>;
};

export type ParkWhizScanCode = {
  __typename?: 'ParkWhizScanCode';
  code?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  qr_error_correction_level?: Maybe<Scalars['String']['output']>;
};

export type ParkWhizSpaceAvailability = {
  __typename?: 'ParkWhizSpaceAvailability';
  spaces_remaining?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ParkWhizStraightLine = {
  __typename?: 'ParkWhizStraightLine';
  feet?: Maybe<Scalars['Int']['output']>;
  meter?: Maybe<Scalars['Int']['output']>;
};

export type ParkWhizValidation = {
  __typename?: 'ParkWhizValidation';
  require_license_plate?: Maybe<Scalars['Boolean']['output']>;
  scan_code?: Maybe<ParkWhizScanCode>;
  steps?: Maybe<Array<Maybe<ParkWhizValidationStep>>>;
  validation_steps?: Maybe<Array<Maybe<ParkWhizValidationStep>>>;
};

export type ParkWhizValidationStep = {
  __typename?: 'ParkWhizValidationStep';
  icon?: Maybe<ParkWhizIcon>;
  instructions?: Maybe<Scalars['String']['output']>;
};

export type ParkWhizVehicle = {
  __typename?: 'ParkWhizVehicle';
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  plate_number?: Maybe<Scalars['String']['output']>;
  plate_state?: Maybe<Scalars['String']['output']>;
};

export type PastVisitorPass = {
  __typename?: 'PastVisitorPass';
  result: PastVisitorPassResult;
  totalCount: Scalars['Int']['output'];
};

export type PastVisitorPassResult = {
  __typename?: 'PastVisitorPassResult';
  eventNames?: Maybe<Array<Scalars['String']['output']>>;
  tenantNames?: Maybe<Array<Scalars['String']['output']>>;
  visits?: Maybe<Array<VisitorPass>>;
};

export type Payer = {
  __typename?: 'Payer';
  id: Scalars['String']['output'];
  type: ExternalPayerType;
};

export type PayerFilter = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  type: ExternalPayerType;
};

export type PayerInput = {
  id: Scalars['String']['input'];
  type: ExternalPayerType;
};

export type PaymentAccount = Document & {
  __typename?: 'PaymentAccount';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel?: Maybe<Channel>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentProcessor?: Maybe<PaymentProcessor>;
  previousPaymentMethod?: Maybe<Scalars['JSON']['output']>;
  providerData?: Maybe<Scalars['JSON']['output']>;
  providerKey: Scalars['ID']['output'];
  remotePaymentProcessor?: Maybe<Scalars['JSON']['output']>;
  rules?: Maybe<Array<PaymentAccountRule>>;
  stale?: Maybe<Scalars['Boolean']['output']>;
  type: PaymentProviderType;
  user?: Maybe<User>;
};

export type PaymentAccountIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaymentAccountInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentProcessor?: InputMaybe<PaymentProcessorIdInput>;
  providerData?: InputMaybe<Scalars['JSON']['input']>;
  providerKey?: InputMaybe<Scalars['ID']['input']>;
  remotePaymentProcessor?: InputMaybe<Scalars['JSON']['input']>;
  rules?: InputMaybe<Array<PaymentAccountRuleInput>>;
  type?: InputMaybe<PaymentProviderType>;
  user?: InputMaybe<UserIdInput>;
};

export type PaymentAccountRule = {
  __typename?: 'PaymentAccountRule';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  allContent: Scalars['Boolean']['output'];
  allRoles: Scalars['Boolean']['output'];
  anyTimes: Scalars['Boolean']['output'];
  contentCategories?: Maybe<Array<Scalars['String']['output']>>;
  endTime?: Maybe<Scalars['String']['output']>;
  groupRole?: Maybe<GroupRole>;
  noPurchaseLimit: Scalars['Boolean']['output'];
  noSinglePurchaseLimit: Scalars['Boolean']['output'];
  paymentAccount: PaymentAccount;
  purchaseLimit?: Maybe<Scalars['Int']['output']>;
  purchaseLimitTimeUnit?: Maybe<Scalars['String']['output']>;
  singlePurchaseLimit?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export type PaymentAccountRuleInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  allContent?: InputMaybe<Scalars['Boolean']['input']>;
  allRoles?: InputMaybe<Scalars['Boolean']['input']>;
  anyTimes?: InputMaybe<Scalars['Boolean']['input']>;
  contentCategories?: InputMaybe<StringListInput>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  groupRole?: InputMaybe<GroupRoleIdInput>;
  noPurchaseLimit?: InputMaybe<Scalars['Boolean']['input']>;
  noSinglePurchaseLimit?: InputMaybe<Scalars['Boolean']['input']>;
  paymentAccount?: InputMaybe<PaymentAccountIdInput>;
  purchaseLimit?: InputMaybe<Scalars['Int']['input']>;
  purchaseLimitTimeUnit?: InputMaybe<Scalars['String']['input']>;
  singlePurchaseLimit?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentAccountSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  stale?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<StringSearch>;
  user?: InputMaybe<UserSearch>;
};

export type PaymentFeatureLineItem = {
  __typename?: 'PaymentFeatureLineItem';
  /** The currency amount of the line item in cents */
  amount: Scalars['Int']['output'];
  /** Name of the line item */
  name: Scalars['String']['output'];
  /** The rate of the line item, as a float */
  rate?: Maybe<Scalars['Float']['output']>;
};

export type PaymentFeatureQuote = {
  __typename?: 'PaymentFeatureQuote';
  /** The Content ID */
  _id: Scalars['UUID']['output'];
  /** v2: The cash total in this quote in dollars */
  cashTotal: Scalars['Float']['output'];
  /** v2: The credit total in this quote */
  creditTotal?: Maybe<Scalars['Float']['output']>;
  /** The currency it was charged in */
  currency?: Maybe<Scalars['String']['output']>;
  /** Data for arbitrary values */
  data?: Maybe<Scalars['JSON']['output']>;
  /**
   * The line items of this quote
   * @deprecated 5.190.0 - use products
   */
  items?: Maybe<Array<PaymentFeatureLineItem>>;
  /** The max Quantity set from quantity block */
  maxQuantity?: Maybe<Scalars['Int']['output']>;
  /** The min Quantity set from quantity block */
  minQuantity?: Maybe<Scalars['Int']['output']>;
  /** The Original Total in cents (without tax) */
  overriddenAmount?: Maybe<Scalars['Int']['output']>;
  /** v2: The products in this quote */
  products: Array<Product>;
  /** Payment provider, e.g. Stripe, PlacePay, Essensys */
  provider?: Maybe<PaymentProviderType>;
  /** v2: The quantity of the products in this quote */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** v2: The subscription details for this quote */
  subscriptionDetails?: Maybe<SubscriptionDetails>;
  /**
   * The total tax in cents
   * @deprecated 5.190.0 - use products and taxTotal
   */
  tax?: Maybe<Scalars['Int']['output']>;
  /** v2: The tax total in this quote */
  taxTotal?: Maybe<Scalars['Float']['output']>;
  /**
   * A collection of taxes included in this amount
   * @deprecated 5.190.0 - use products
   */
  taxes?: Maybe<Array<PaymentFeatureLineItem>>;
  /**
   * The Total in cents (without tax)
   * @deprecated 5.190.0 - use cashTotal or creditTotal
   */
  total?: Maybe<Scalars['Int']['output']>;
  /** Payment type, e.g. Cash, Essensys */
  type?: Maybe<PaymentType>;
};

export enum PaymentMethod {
  PaymentMethodApplePay = 'PAYMENT_METHOD_APPLE_PAY',
  PaymentMethodCredits = 'PAYMENT_METHOD_CREDITS',
  PaymentMethodCreditCard = 'PAYMENT_METHOD_CREDIT_CARD',
  PaymentMethodUnknown = 'PAYMENT_METHOD_UNKNOWN'
}

export type PaymentProcessor = Document & {
  __typename?: 'PaymentProcessor';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel?: Maybe<Channel>;
  externalKey?: Maybe<Scalars['ID']['output']>;
  integrationProvider?: Maybe<IntegrationProvider>;
  isSandbox: Scalars['Boolean']['output'];
  privateKey?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<Scalars['JSON']['output']>;
  type: PaymentProviderType;
  user?: Maybe<Scalars['String']['output']>;
};

export type PaymentProcessorIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentProcessorInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  externalKey?: InputMaybe<Scalars['ID']['input']>;
  integrationProvider?: InputMaybe<IntegrationProvider>;
  isSandbox: Scalars['Boolean']['input'];
  privateKey?: InputMaybe<Scalars['String']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<PaymentProviderType>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentProcessorSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelSearch>;
  integrationProvider?: InputMaybe<IntegrationProvider>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  type?: InputMaybe<PaymentProviderType>;
};

export enum PaymentProviderType {
  Cash = 'Cash',
  Essensys = 'Essensys',
  PlacePay = 'PlacePay',
  Stripe = 'Stripe'
}

export type PaymentSource = {
  __typename?: 'PaymentSource';
  brand: Scalars['String']['output'];
  expiryMonth: Scalars['Int']['output'];
  expiryYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
  method: PaymentMethod;
  paymentMethodId: Scalars['String']['output'];
};

export enum PaymentType {
  Cash = 'Cash',
  EssensysCredits = 'EssensysCredits',
  MoneyMovement = 'MoneyMovement'
}

export type Payments = {
  __typename?: 'Payments';
  /**
   * Gets a list of credit cards for the provided payment account
   * @deprecated Use payments.paymentMethods
   */
  creditCards?: Maybe<Array<CreditCard>>;
  merchantAccount?: Maybe<StripeAccount>;
  /** Return Order Summary Details */
  orderSummary?: Maybe<OrderSummary>;
  /** Gets a payment account */
  paymentAccount: PaymentAccount;
  /** Gets a list of payment methods for provided payment accounts */
  paymentMethods: Array<Scalars['JSON']['output']>;
  /** Gets the relevant placepay keys */
  placePay?: Maybe<PlacePayInfo>;
  /** Gets the relevant Stripe keys */
  stripe?: Maybe<StripeInfo>;
};


export type PaymentsCreditCardsArgs = {
  paymentAccountId: Scalars['UUID']['input'];
};


export type PaymentsMerchantAccountArgs = {
  channelId: Scalars['UUID']['input'];
};


export type PaymentsPaymentAccountArgs = {
  paymentAccountId: Scalars['UUID']['input'];
};


export type PaymentsPaymentMethodsArgs = {
  paymentAccountIds: Array<Scalars['UUID']['input']>;
};


export type PaymentsPlacePayArgs = {
  paymentAccountId?: InputMaybe<Scalars['UUID']['input']>;
};


export type PaymentsStripeArgs = {
  paymentAccountId?: InputMaybe<Scalars['UUID']['input']>;
};

export type PaymentsMutation = {
  __typename?: 'PaymentsMutation';
  /**
   * Adds a credit card to a Channel or the current User.
   * @deprecated Use payments.addPaymentMethod
   */
  addCreditCard?: Maybe<CreditCard>;
  /** Adds a PaymentMethod to a PaymentAccount */
  addPaymentMethod?: Maybe<Scalars['JSON']['output']>;
  /** Creates a Payment Account */
  createPaymentAccount: PaymentAccount;
  /** Deletes a Payment Account */
  deletePaymentAccount: Scalars['Boolean']['output'];
  /**
   * Removes a credit card from a Channel or the currently logged in User.
   * @deprecated Use payments.removePaymentMethod
   */
  removeCreditCard: Scalars['Boolean']['output'];
  /** Removes a merchant account from a channel. */
  removeMerchantAccount: Scalars['Boolean']['output'];
  /** Remove a PaymentMethod from a PaymentAccount */
  removePaymentMethod: Scalars['Boolean']['output'];
  /** Updates a Payment Account */
  updatePaymentAccount: PaymentAccount;
};


export type PaymentsMutationAddCreditCardArgs = {
  paymentAccountId: Scalars['UUID']['input'];
  source: Scalars['String']['input'];
};


export type PaymentsMutationAddPaymentMethodArgs = {
  paymentAccountId: Scalars['UUID']['input'];
  source: Scalars['String']['input'];
};


export type PaymentsMutationCreatePaymentAccountArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  options?: InputMaybe<CreatePaymentAccountOptionsInput>;
  paymentAccount: PaymentAccountInput;
  stripeSourceId?: InputMaybe<Scalars['ID']['input']>;
};


export type PaymentsMutationDeletePaymentAccountArgs = {
  paymentAccountId: Scalars['UUID']['input'];
};


export type PaymentsMutationRemoveCreditCardArgs = {
  id: Scalars['String']['input'];
  paymentAccountId: Scalars['UUID']['input'];
};


export type PaymentsMutationRemoveMerchantAccountArgs = {
  channelId: Scalars['UUID']['input'];
};


export type PaymentsMutationRemovePaymentMethodArgs = {
  id: Scalars['String']['input'];
  paymentAccountId: Scalars['UUID']['input'];
};


export type PaymentsMutationUpdatePaymentAccountArgs = {
  paymentAccount: PaymentAccountInput;
};

export type PermissionDetail = {
  __typename?: 'PermissionDetail';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

/** A special input type for modyfying Lists of Permissions. */
export type PermissionListInput = {
  add?: InputMaybe<Array<Scalars['Permission']['input']>>;
  remove?: InputMaybe<Array<Scalars['Permission']['input']>>;
  set?: InputMaybe<Array<Scalars['Permission']['input']>>;
};

export type PermissionListSearch = {
  all?: InputMaybe<Array<Scalars['Permission']['input']>>;
  any?: InputMaybe<Array<Scalars['Permission']['input']>>;
  not?: InputMaybe<Array<Scalars['Permission']['input']>>;
};

export type Person = {
  __typename?: 'Person';
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  resident?: Maybe<Resident>;
  units?: Maybe<Array<Unit>>;
  user?: Maybe<PublicUser>;
  userId?: Maybe<Scalars['UUID']['output']>;
};


export type PersonResidentArgs = {
  propertyId: Scalars['UUID']['input'];
};


export type PersonUnitsArgs = {
  relationships?: InputMaybe<Array<PersonUnitRelationshipType>>;
};

export enum PersonUnitRelationshipType {
  Leases = 'LEASES',
  Manages = 'MANAGES',
  Occupies = 'OCCUPIES',
  Owns = 'OWNS'
}

export type PlacePayInfo = {
  __typename?: 'PlacePayInfo';
  _id: Scalars['ID']['output'];
  accessToken?: Maybe<Scalars['String']['output']>;
  publicKey: Scalars['ID']['output'];
};

export enum PlatformType {
  Mobile = 'mobile',
  Web = 'web'
}

export type Portal = {
  __typename?: 'Portal';
  activeUsersByGroupRole?: Maybe<Array<ObjectCount>>;
  subscriberCountPerClient?: Maybe<Array<ObjectCount>>;
  subscriptionsOverTimeForChannel?: Maybe<Array<ObjectCount>>;
  usersWithPayment?: Maybe<ObjectCount>;
};


export type PortalActiveUsersByGroupRoleArgs = {
  beforeDate: Scalars['String']['input'];
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  groupRole?: InputMaybe<Scalars['String']['input']>;
};


export type PortalSubscriberCountPerClientArgs = {
  parentId?: InputMaybe<Scalars['UUID']['input']>;
};


export type PortalSubscriptionsOverTimeForChannelArgs = {
  channelId: Scalars['UUID']['input'];
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

/**
 * The price that the seller applied to the product.
 * This price can be composed of flat amounts or percentages.
 * This price SHOULD NOT be displayed to purchasers. See: SellingPrice
 */
export type Price = {
  __typename?: 'Price';
  /** The rate of the product without any tax or markup */
  amount: Scalars['Float']['output'];
  /** The markup applied to the product */
  markup?: Maybe<Markup>;
  /** The tax applied to the product */
  tax?: Maybe<ProductTax>;
};

export type PriceInput = {
  amount: Scalars['Float']['input'];
  markup?: InputMaybe<MarkupInput>;
  tax?: InputMaybe<ProductTaxInput>;
};

export type Product = {
  __typename?: 'Product';
  /** The rate of the product without any tax or markup */
  amount: Scalars['Float']['output'];
  /** The charge code of the product */
  chargeCode?: Maybe<ChargeCode>;
  /** The credit price of the product */
  creditAmount?: Maybe<Scalars['Float']['output']>;
  /** The description of the product */
  description: Scalars['String']['output'];
  /** The UUID of the channel on which the product was created */
  groupId: Scalars['String']['output'];
  /** groupType - Activate Channel */
  groupType: GroupType;
  /** The UUID */
  id: Scalars['String']['output'];
  /** The markup applied to the product */
  markup?: Maybe<Markup>;
  /** The name of the product */
  name: Scalars['String']['output'];
  /** The original price of the product before exceptions were applied to it */
  originalPrice?: Maybe<Price>;
  /** The category the product belongs too */
  productCategory: ProductCategory;
  /** Any receivables (e.g. credit bundles) attached to the product */
  receivables?: Maybe<Array<Maybe<Receivable>>>;
  /** The price at which the product is being sold for */
  sellingPrice?: Maybe<SellingPrice>;
  /** The tax applied to the product */
  tax?: Maybe<ProductTax>;
};

export type ProductCategoriesFilter = {
  groupIds: Array<InputMaybe<Scalars['String']['input']>>;
  groupType: GroupType;
  productType?: InputMaybe<ProductType>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  /** The description  of the product category */
  description: Scalars['String']['output'];
  /** The UUID of the channel on which the category was created */
  groupId: Scalars['String']['output'];
  /** groupType - Activate Channel */
  groupType: GroupType;
  /** The name of the product category */
  name: Scalars['String']['output'];
  /** The UUID */
  productCategoryId: Scalars['String']['output'];
  /** The productType the category belongs to */
  productType: ProductType;
};

export type ProductExceptionsFilter = {
  group: GroupInput;
};

export type ProductFinalPrice = {
  __typename?: 'ProductFinalPrice';
  exceptionId?: Maybe<Scalars['String']['output']>;
  finalPrice: Price;
  productId: Scalars['String']['output'];
};

export type ProductInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  creditAmount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  groupType?: InputMaybe<GroupType>;
  id?: InputMaybe<Scalars['String']['input']>;
  markup?: InputMaybe<MarkupInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalPrice?: InputMaybe<PriceInput>;
  productCategoryId?: InputMaybe<Scalars['String']['input']>;
  tax?: InputMaybe<ProductTaxInput>;
};

export type ProductItem = {
  __typename?: 'ProductItem';
  product: Product;
  quantity: Scalars['Int']['output'];
};

export type ProductItemInput = {
  chargeCodeId?: InputMaybe<Scalars['String']['input']>;
  product: ProductInput;
  productCategoryId?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type ProductLineItem = {
  __typename?: 'ProductLineItem';
  key: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  taxRate: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
};

export type ProductTax = {
  __typename?: 'ProductTax';
  /** The dollar value of the tax */
  taxAmountType: TaxAmountType;
  /** The dollar value of the tax */
  value: Scalars['Float']['output'];
};

export type ProductTaxInput = {
  taxAmountType: TaxAmountType;
  value: Scalars['Float']['input'];
};

export enum ProductType {
  ProductTypeCreditBundle = 'PRODUCT_TYPE_CREDIT_BUNDLE',
  ProductTypeServiceLabour = 'PRODUCT_TYPE_SERVICE_LABOUR',
  ProductTypeServiceMaterials = 'PRODUCT_TYPE_SERVICE_MATERIALS',
  ProductTypeUnknown = 'PRODUCT_TYPE_UNKNOWN'
}

export type ProductsFilter = {
  groupIds: Array<Scalars['String']['input']>;
  productCategoryId?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  productName?: InputMaybe<Scalars['String']['input']>;
  productTypes?: InputMaybe<Array<ProductType>>;
};

export type Profile = Document & {
  __typename?: 'Profile';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order?: Maybe<Scalars['Int']['output']>;
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  block?: Maybe<Scalars['JSON']['output']>;
  color?: Maybe<Scalars['Color']['output']>;
  content?: Maybe<Content>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  image?: Maybe<Scalars['Image']['output']>;
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  noReplyEmail?: Maybe<Scalars['EmailAddress']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  properties?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  renderer?: Maybe<Scalars['Int']['output']>;
  theme?: Maybe<Theme>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  version?: Maybe<Scalars['Int']['output']>;
  wordmark?: Maybe<Scalars['Image']['output']>;
};

export type ProfileInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _order?: InputMaybe<Scalars['Int']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  backgroundImage?: InputMaybe<Scalars['Image']['input']>;
  color?: InputMaybe<Scalars['Color']['input']>;
  content?: InputMaybe<ContentIdInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  image?: InputMaybe<Scalars['Image']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['Image']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noReplyEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  theme?: InputMaybe<ThemeIdInput>;
  wordmark?: InputMaybe<Scalars['Image']['input']>;
};

export type ProfileSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  color?: InputMaybe<StringSearch>;
  description?: InputMaybe<StringSearch>;
  email?: InputMaybe<StringSearch>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringSearch>;
  phone?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
};

export type Property = {
  __typename?: 'Property';
  address: Array<Maybe<VtsAddress>>;
  floors?: Maybe<Array<Maybe<Floor>>>;
  id: Scalars['UUID']['output'];
  name: Array<Maybe<Scalars['String']['output']>>;
  residents?: Maybe<Array<Maybe<Resident>>>;
  units?: Maybe<Array<Maybe<Unit>>>;
};

export type ProxyClickCompany = {
  __typename?: 'ProxyClickCompany';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  logoOriginal?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type ProxyClickHost = {
  __typename?: 'ProxyClickHost';
  _id: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  originalCompany: ProxyClickCompany;
  phone?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
};

export type ProxyClickMeeting = {
  __typename?: 'ProxyClickMeeting';
  _id: Scalars['String']['output'];
  company: ProxyClickCompany;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endAt: Scalars['DateTime']['output'];
  host: ProxyClickHost;
  startAt: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  visitors?: Maybe<Array<Maybe<ProxyClickVisitor>>>;
};

export type ProxyClickVisit = {
  __typename?: 'ProxyClickVisit';
  _id: Scalars['String']['output'];
  arrivedAt?: Maybe<Scalars['DateTime']['output']>;
  company: ProxyClickCompany;
  createdAt: Scalars['DateTime']['output'];
  expectedAt?: Maybe<Scalars['DateTime']['output']>;
  leavingAt?: Maybe<Scalars['DateTime']['output']>;
  leftAt?: Maybe<Scalars['DateTime']['output']>;
  meeting: ProxyClickMeeting;
  partOfGroup?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<ProxyClickVisitStatus>;
  timezone?: Maybe<Scalars['DateTime']['output']>;
  visitor: ProxyClickVisitor;
};

export type ProxyClickVisitPagination = {
  __typename?: 'ProxyClickVisitPagination';
  items?: Maybe<Array<ProxyClickVisit>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ProxyClickVisitStatus = {
  __typename?: 'ProxyClickVisitStatus';
  _id?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type ProxyClickVisitor = {
  __typename?: 'ProxyClickVisitor';
  _id?: Maybe<Scalars['String']['output']>;
  about?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName: Scalars['String']['output'];
  function?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
};

export type ProxyClickVisitorInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName: Scalars['String']['input'];
  function?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
};

export type PublicProfile = {
  __typename?: 'PublicProfile';
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  color?: Maybe<Scalars['Color']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  image?: Maybe<Scalars['Image']['output']>;
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  wordmark?: Maybe<Scalars['Image']['output']>;
};

/** Public Team of Users that belong to a GroupRole on a Channel. */
export type PublicTeam = {
  __typename?: 'PublicTeam';
  _id: Scalars['UUID']['output'];
  channel: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<PublicUser>>;
};

/**
 * Public User object.
 *
 * PublicUser contains a small subset of fields that are publically visible to any user on the platform,
 * or even anonymous users.
 */
export type PublicUser = {
  __typename?: 'PublicUser';
  _created: Scalars['DateTime']['output'];
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  lastSeen: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  online: Scalars['Boolean']['output'];
  primaryLocation?: Maybe<Channel>;
  profile: PublicProfile;
  role?: Maybe<PublicUserGroupRole>;
  roles?: Maybe<Array<PublicUserGroupRole>>;
};

/**
 * Public User Group Role.
 *
 * Shows any publically available info about this Users group role..
 */
export type PublicUserGroupRole = {
  __typename?: 'PublicUserGroupRole';
  _id: Scalars['UUID']['output'];
  channel?: Maybe<Scalars['UUID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  groupRole?: Maybe<Scalars['UUID']['output']>;
  groupRoleName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['UUID']['output']>;
};

export type PublicUserPagination = {
  __typename?: 'PublicUserPagination';
  items?: Maybe<Array<PublicUser>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PublicUserSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  groupRoleIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name?: InputMaybe<StringSearch>;
  profile?: InputMaybe<ProfileSearch>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type QrCode = {
  __typename?: 'QRCode';
  qrCode?: Maybe<Scalars['String']['output']>;
};

export type QuantityWaitlistByContent = {
  __typename?: 'QuantityWaitlistByContent';
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  startDate: Scalars['DateTime']['output'];
  user?: Maybe<QuantityWaitlistUser>;
};

export type QuantityWaitlistCompany = {
  __typename?: 'QuantityWaitlistCompany';
  name?: Maybe<Scalars['String']['output']>;
};

export type QuantityWaitlistPagination = {
  __typename?: 'QuantityWaitlistPagination';
  items: Array<QuantityWaitlistByContent>;
  pageInfo: PageInfo;
};

export type QuantityWaitlistSearch = {
  afterEndDate?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortBy>;
};

export type QuantityWaitlistUser = {
  __typename?: 'QuantityWaitlistUser';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']['output']>;
  accounts?: Maybe<AccountsQueries>;
  /** Search ServiceRequests by contentId for admin. */
  adminSearchServiceRequests: ServiceRequestSearchResults;
  /** Gets the Live notifications count for this piece of content. */
  allSentNotificationsForContentCount: Scalars['Int']['output'];
  apiKeys?: Maybe<Array<ApiKey>>;
  /**
   * The `client_secret` for a given `client_id` related to an Apple Service
   * See: https://developer.apple.com/account/resources/identifiers/list/serviceId
   */
  appleIDClientSecret: Scalars['String']['output'];
  /** Find Audience by id. */
  audience: Audience;
  /** Find Audiences by channel id. */
  audiences: AudiencePagination;
  availableIntegrations?: Maybe<Array<Integration>>;
  biltSsoLink?: Maybe<BiltSso>;
  /** Search for one channel by its id. */
  channel?: Maybe<Channel>;
  channelContract: ChannelContract;
  channelContracts: ChannelContractPagination;
  /** Gets a count of all group role users for a channel */
  channelGroupRolesUserCount?: Maybe<Array<ObjectCount>>;
  /** Get paginated hardwares belonging to a specific channel. */
  channelHardwares: HardwarePagination;
  channelIntegration: ChannelIntegration;
  channelIntegrationFloorMapGenerationStatus?: Maybe<FloorMapGenerationStatus>;
  channelInvites?: Maybe<Array<Maybe<ChannelInvite>>>;
  /** Get Channel module by id */
  channelModule: ChannelModule;
  /** Get Channel module by channel id */
  channelModuleByChannelId: ChannelModule;
  /** Get users by channel */
  channelPublicUsers?: Maybe<PublicUserPagination>;
  /** Search for one channel by its id. */
  channelSections: PaginatedSections;
  /** Search users on this channel. */
  channelUserSearch: UserPagination;
  /** Get users by a specific group role on this channel */
  channelUsersByGroupRole: UserGroupRolePagination;
  /** Get users by a array of group role on this channel */
  channelUsersByGroupRoles: UserGroupRolePagination;
  /**
   * General searches for channels based on a number on inputs with pagination. An example of its usage is finding
   * channels to join.
   */
  channels: ChannelPagination;
  /** Query for channels/subchannels that the user has a specific user permission on. */
  channelsByPermission: ChannelPagination;
  /**
   * Searches for channels based on their relationship to another channel.
   * You would use this to get the Companies at a Property location for example.
   */
  channelsByRelationship: ChannelRelationshipPagination;
  /**
   * Query for channels/subchannels that the user is an admin, super users will have all channels returned.
   * This query should be used for when targeting content or in blocks.
   */
  channelsForBlocksAndTargeting: ChannelPagination;
  /** Checks if the channel exists */
  checkIfChannelExists: Scalars['Boolean']['output'];
  /** Get contents from a multiple sections */
  combinedSectionsContent?: Maybe<Array<Maybe<SectionContent>>>;
  companiesByChannelId?: Maybe<Array<Maybe<Channel>>>;
  completeSignupData: CompleteSignupData;
  confirmEmail: Scalars['Boolean']['output'];
  /** Gets a specific piece of content, if the user has the rights to see it. */
  content?: Maybe<Content>;
  /** Gets all the possible content blocks */
  contentBlocks?: Maybe<Array<Block>>;
  contentByAudience: ContentPagination;
  /** Gets all the possible content card layout styles */
  contentCards?: Maybe<Array<Block>>;
  /** Gets the remaining entries the current user has for Content with entries feature. */
  contentFeatureEntriesRemaining: Scalars['Int']['output'];
  /** Gets the available inventory for content with the Inventory feature enabled. */
  contentFeatureInventoryAvailable: Array<Scalars['JSON']['output']>;
  /** Generates a quote for content with the Payment feature enabled */
  contentFeaturePaymentQuote: PaymentFeatureQuote;
  /** Gets a summary of payments made to this content.  For admin users only. */
  contentFeaturePaymentSummary?: Maybe<Scalars['JSON']['output']>;
  /** Gets the quantity allotments for a content */
  contentFeatureQuantityAllotments: ContentQuantityAllotments;
  /** Gets the quantity claimed for Content with the quantity feature. */
  contentFeatureQuantityClaimed: Scalars['Int']['output'];
  /** Gets available timeslots for content with the Reservable feature enabled. */
  contentFeatureReservableAvailability: Array<ReservableAvailability>;
  /** Gets a summary of what interactions are in what status for this content.  For admin users only. */
  contentFeatureStatusesSummary: Array<ContentFeatureStatusSummary>;
  /** Gets related spaces content */
  contentForRelatedSpaces: ContentPagination;
  /**
   * Paginates through published content on this specific channel.
   *
   * If you want to see what published content is active an relevant for an end user,
   * query me.channels.content.  This end point is for admins of this channel.
   */
  contentOnChannel: ContentPagination;
  contentTemplate: ContentTemplate;
  contentTemplatesOnChannel?: Maybe<LibraryItemPagination>;
  contentTemplatesOnLibrary?: Maybe<LibraryItemPagination>;
  contentTemplatesOnUser?: Maybe<LibraryItemPagination>;
  /** Returns the list of waitlists a user is enrolled in for a given content */
  contentWaitlistEnrollments: Array<ContentWaitlistEnrollment>;
  /** Gets all the contents linked to an integration */
  contentsForIntegration: Array<Content>;
  /** Helper end point to convert a UUID <> base62. */
  convertId: Scalars['String']['output'];
  /** Get pass credential */
  credential: Credential;
  /** @deprecated Use payments.paymentMethods */
  creditCards?: Maybe<Array<CreditCard>>;
  datasetRecord: DatasetRecord;
  datasetSchema?: Maybe<DatasetSchema>;
  datasetSchemas?: Maybe<LibraryItemPagination>;
  /**
   * Given a ChannelIntegration, this will attempt to discover any valid UserIntegrations that are possible for the
   * currently signed in user.
   */
  discoverUserIntegrations?: Maybe<Array<UserLogin>>;
  doUsersExistByEmail: Array<Maybe<UserEmail>>;
  /** Gets a specific piece of draft content, if the user has the rights to see it. */
  draftContent: DraftContent;
  /** Gets a specific piece of draft content, if the user has the rights to see it. */
  draftContentHistory: DraftContentHistoryPagination;
  /** Gets all of the content for this specific channel, if the user has the rights to see it. */
  draftContentOnChannel: DraftContentPagination;
  /** Gets the Draft contents for a channel integration. */
  draftContentsForIntegration: Array<DraftContent>;
  equipmentSettingsOnChannel?: Maybe<ChannelEquipmentSettings>;
  /** Get Escalation Rule */
  escalationRule: EscalationRule;
  essensys?: Maybe<Essensys>;
  estimateContentAudienceSize: Scalars['Int']['output'];
  events?: Maybe<Array<EventDetail>>;
  /** Find out all the login methods for user by email */
  existingUserLogins: Array<Maybe<UserLoginMethod>>;
  exportCSVTasks: ExportCsvTasksReponseDto;
  /** Find schedules by referenceId */
  findSchedules: FindSchedulesReponseDto;
  flatfileToken: FlatfileToken;
  floor?: Maybe<Floor>;
  getAccessLocations?: Maybe<Array<Maybe<AccessLocation>>>;
  /** Get Access Point by id */
  getAccessPointById: AccessPoint;
  /** Get Access Point List */
  getAccessPointList: Array<AccessPoint>;
  getAllEquipment?: Maybe<Array<Equipment>>;
  /** List Escalation Rules */
  getAllEscalationRules?: Maybe<Array<EscalationRule>>;
  getAttachment?: Maybe<Attachment>;
  /**
   * Entity in context to attachments refers to either WorkOrder or Content
   * The function takes as input entityId as _id and returns attachments
   */
  getAttachmentsByEntity?: Maybe<Array<Maybe<Attachment>>>;
  /**
   * Check domain exists in Auth0 Home Realm Discovery.
   * @deprecated This is deprecated and use getCurrentAuth0Connection instead
   */
  getAuth0HRDConnectionsByDomainAlias: Auth0DomainHomeRealmDiscovery;
  getBuildingChannelIntegration?: Maybe<ChannelAccessIntegration>;
  /** Get the tenant channels metadata of a building channel */
  getBuildingTenantChannels?: Maybe<Array<Maybe<BuildingTenantChannel>>>;
  getChannelAccessControlGroups?: Maybe<Array<Maybe<AccessControlGroup>>>;
  getChannelUsers?: Maybe<Array<Maybe<ActivateUser>>>;
  getChannelsForAccessManager?: Maybe<Array<Maybe<AccessControlChannelType>>>;
  getChannelsForAccessUser?: Maybe<Array<Maybe<AccessUserChannel>>>;
  /** Get the config */
  getConfig?: Maybe<GetConfigResponse>;
  getConfigMetadata?: Maybe<Configuration>;
  getCredentialGroups?: Maybe<Array<Maybe<CredentialGroups>>>;
  getEquipment?: Maybe<Equipment>;
  getEquipmentForExport?: Maybe<EquipmentForExport>;
  /** Get final price for products filtered Payer, Group and ProductIds by */
  getFinalPriceForProducts?: Maybe<GetFinalPriceForProductsResponse>;
  getIntegrationProcesses: GetIntegrationProcessesResponse;
  getMeterReadingValue?: Maybe<MeterReadingValue>;
  getMeterReadingValueForExport?: Maybe<MeterReadingValueForExport>;
  getMeterReadingValuesByMeterReadingIds?: Maybe<MeterReadingValueSearchResults>;
  /** Get a product */
  getProduct?: Maybe<GetProductResponse>;
  /** Get a product exception */
  getProductException?: Maybe<GetProductExceptionResponse>;
  /** Gets last x recent jobs */
  getRecentJobs?: Maybe<JobsResponse>;
  /** Get Relay Output by id */
  getRelayOutputById: RelayOutput;
  /** Get Relay Output List */
  getRelayOutputList: GetRelayOutputList;
  /** Find ServiceRequest categories by channelId. */
  getServiceRequestCategories: ServiceRequestCategoriesList;
  /** Find enabled service request features for a channel. */
  getServiceRequestFeaturesByChannelId: ServiceRequestFeatures;
  /** Find ServiceRequest issues by channelId, */
  getServiceRequestIssues: ServiceRequestIssuesList;
  /** Find ServiceRequest locations by channelId. */
  getServiceRequestLocations: ServiceRequestLocationsList;
  /** Get the building channel metadata of a tenant channel */
  getTenantBuildingIntegrationChannel?: Maybe<TenantBuildingChannel>;
  getUserAccessDetails?: Maybe<Array<Maybe<UserAccessDetails>>>;
  getUserChannelsWithMobileAccess?: Maybe<Array<Maybe<MobileAccessChannel>>>;
  /** If you want to see what published VM content is active to relevant only Admin or Admin like user, */
  getVMContent: ContentPagination;
  getWorkflow?: Maybe<Workflow>;
  /**
   * Gets all non-content Workflows on a channel
   * Does not return ContentWorkflows
   */
  getWorkflowsByChannel?: Maybe<Array<Maybe<Workflow>>>;
  /**
   * Gets all non-content Workflows on a channel of a specific type
   * Does not return ContentWorkflows
   */
  getWorkflowsByChannelAndType?: Maybe<Array<Maybe<Workflow>>>;
  /** Gets a group role by its id */
  groupRole: GroupRole;
  /** Get a hardware by it's id. */
  hardware: Hardware;
  /** Get paginated access logs */
  hardwareAccessLogs: HardwareAccessLogPagination;
  /** Get hardware profile by it's api key. Only accessible by hardware application (e.g. Kiosk). */
  hardwareMe: Hardware;
  /** Get available hosts of the current user by channel relationship */
  hostsByUserTenantRelationship: Array<Maybe<Host>>;
  integration: Integration;
  integrations?: Maybe<Array<Integration>>;
  /** Gets a specific UserContentInteration if the user has the rights to see it. */
  interaction: UserContentInteraction;
  /** Gets the historical data for a UserContentInteraction if the user has rights to see it. */
  interactionHistory: UserContentInteractionHistoryPagination;
  /** Gets the UCIs on this channel */
  interactionsOnChannel: UserContentInteractionPagination;
  /** Gets the Content interactions for this piece of content. */
  interactionsOnContent: UserContentInteractionPagination;
  invite: ChannelInvite;
  /** @deprecated uses queryBuilder, resulting in unexpected behaviors. Use `reformedChannel.invites` query instead */
  invitesOnChannel?: Maybe<ChannelInvitePagination>;
  libraries: LibraryPagination;
  librariesOnChannel: LibraryPagination;
  library?: Maybe<Library>;
  /** Get a list of linked/"linkable" entities */
  linkedEntities: LinkedEntityPagination;
  /** Lists all product categories filtered by ProductCategoriesFilter */
  listProductCategories?: Maybe<ListProductCategoriesResponse>;
  /** Lists all product exceptions filtered ProductExceptionsFilter by */
  listProductExceptions?: Maybe<ListProductExceptionsResponse>;
  /** Lists all products filtered by ProductsFilter */
  listProducts?: Maybe<ListProductsResponse>;
  /** Find out if the email is already used for login */
  loginEmailExists: Scalars['Boolean']['output'];
  me?: Maybe<Me>;
  media?: Maybe<Media>;
  mediaOnChannel?: Maybe<LibraryItemPagination>;
  mediaOnLibrary?: Maybe<LibraryItemPagination>;
  mediaOnUser?: Maybe<LibraryItemPagination>;
  /** For admin users on a channel, will return all the GroupRoles this user has that you are allowed to know about. */
  memberGroupRoles?: Maybe<Array<UserGroupRole>>;
  metatag?: Maybe<Metatag>;
  metatagUsage: Array<MetatagUse>;
  metatagsOnChannel?: Maybe<LibraryItemPagination>;
  /**
   * This is now deprecated. Query the mobile permissions from the Integration type.
   * @deprecated Moved to Integration
   */
  mobilePermissions?: Maybe<Array<MobilePermission>>;
  objectDatasetRecord: ObjectDatasetRecord;
  oidcServiceProviderDefinitionList: Array<Maybe<OidcServiceProviderDefinition>>;
  openpathSetupMobileToken?: Maybe<OpenpathSetupMobileToken>;
  /** Returns vehicles for the logged in user */
  parkWhizVehicles?: Maybe<Array<Maybe<ParkWhizVehicle>>>;
  /** Get final pricing for selected parking spot */
  parkwhizBookingPreview?: Maybe<ParkWhizBookPreview>;
  parkwhizLocations?: Maybe<Array<ParkWhizByGeoLocation>>;
  /** Get QR code for parking booking in custom format */
  parkwhizParkingQrCode?: Maybe<QrCode>;
  /** Get past visits */
  pastVisits: PastVisitorPass;
  /** Get past visits by hostUserId */
  pastVisitsByHostUserId: PastVisitorPass;
  payments?: Maybe<Payments>;
  permissions?: Maybe<Array<PermissionDetail>>;
  personForUser?: Maybe<Person>;
  /** Get Preventive Maintenance Schedule by scheduleId. */
  pmSchedule: ClientPmSchedule;
  /** Get schedules for export */
  pmSchedulesForExport: PmSchedulesForExportResponseDto;
  portal: Portal;
  property?: Maybe<Property>;
  proxyclickCompanies?: Maybe<Array<ProxyClickCompany>>;
  proxyclickCompanyUsers?: Maybe<Array<ProxyClickHost>>;
  proxyclickListVisits: ProxyClickVisitPagination;
  /** Returns a list of waitlists enrolled for a given content */
  quantityWaitlistForAdmin: QuantityWaitlistPagination;
  /** Get recurrence by id */
  recurrence?: Maybe<VisitorPassRecurrence>;
  reformedChannel?: Maybe<ReformedChannel>;
  requirements?: Maybe<Array<RequirementDetail>>;
  /** Get reservable bookings by channel id */
  reservableBookings: ReservableBookingPagination;
  /** Returns a list of waitlists a user is enrolled in for a given content */
  reservableWaitlistEnrollments: Array<ReservableWaitlistEnrollment>;
  /** Returns a list of waitlists enrolled for a given content */
  reservableWaitlistForAdmin: ReservableWaitlistPagination;
  resident?: Maybe<Resident>;
  residentByUserId?: Maybe<Resident>;
  residents?: Maybe<Array<Maybe<Resident>>>;
  samlData?: Maybe<SamlData>;
  samlServiceProviderDefinitions?: Maybe<Array<Maybe<SamlServiceProviderDefinition>>>;
  samlServiceProviderDefinitionsByIdp?: Maybe<Array<Maybe<SamlServiceProviderDefinition>>>;
  /** Get scheduled visits */
  scheduledVisits: ScheduledVisitorPass;
  /** Get scheduled visits By hostUserId */
  scheduledVisitsByHostUserId: ScheduledVisitorPass;
  searchEquipment: EquipmentSearchResults;
  searchMeterReadingValue: MeterReadingValueSearchResults;
  /** Search ServiceRequests by contentId. */
  searchServiceRequests: ServiceRequestSearchResults;
  searchTasks: SearchTasksReponseDto;
  /** Gets a section by its id and also search the content in this section by the given search options */
  section: Section;
  /** Gets a section drafts by its Id */
  sectionDrafts: DraftContentPagination;
  /** Gets available timeslots for a section with content that has the Reservable feature enabled. */
  sectionFeatureReservableAvailability: Array<ContentDateRanges>;
  /** Find ServiceRequest by serviceRequestId. */
  serviceRequest: ServiceRequest;
  /** Get all service request audit logs by serviceRequestId */
  serviceRequestAuditLogs?: Maybe<Array<ServiceRequestAuditLog>>;
  /** Find ServiceRequest by UCI Id. */
  serviceRequestByUCI: ServiceRequest;
  /**
   * Get the work order content ID for a channel if they have the rights to see it.
   * Not for use for the end user.
   */
  serviceRequestContentId: Scalars['UUID']['output'];
  /** Find ServiceRequest filter fields. */
  serviceRequestFilterAttributes: ServiceRequestsFilterFieldsDto;
  /** Find ServiceRequests by contentId. */
  serviceRequests: ServiceRequestsList;
  /** Returns ad-hoc settings for this user */
  settings?: Maybe<Scalars['JSON']['output']>;
  shopifyCustomer?: Maybe<ShopifyCustomer>;
  specialInvite?: Maybe<SpecialInvite>;
  tag: Tag;
  tags?: Maybe<Array<Tag>>;
  targetingRule: TargetingRule;
  targetingRulesForAudience: TargetingRulesForAudience;
  /** Get Task by id. */
  task: ClientTask;
  /** Get all task audit logs by taskId */
  taskAuditLogs?: Maybe<Array<TaskAuditLog>>;
  /** Gets a public team */
  team: PublicTeam;
  tenant?: Maybe<Tenant>;
  /** Get tenant channels of a specific user and channel */
  tenantChannelsByUser?: Maybe<Array<Maybe<Tenant>>>;
  /** Gets a theme by id */
  theme: Theme;
  unit?: Maybe<Unit>;
  unlinkedDatasetSchemas?: Maybe<Array<LibraryItem>>;
  user?: Maybe<PublicUser>;
  userAccessDetails?: Maybe<UserAccessDetails>;
  userAdmin: User;
  /** Returns a list of timeslot count for a given user and content */
  userBookingsCountForContent: Array<UserBooking>;
  userBuildingAccess?: Maybe<Array<Maybe<UserBuildingAccess>>>;
  userChannelSettings?: Maybe<UserChannelSettings>;
  userChannelSettingsForMultipleChannels?: Maybe<Array<Maybe<UserChannelSettings>>>;
  /**
   * Gets a UserIntegration for specific a ChannelIntegration for the currently logged in user.  This method may
   * also attempt to create a UserIntegration if one does not exist, and return that.  This will vary depending on
   * the type of Integration.
   */
  userIntegration?: Maybe<UserIntegration>;
  users: UserPagination;
  usersByChannelId?: Maybe<UserPagination>;
  verifyConfig?: Maybe<Configuration>;
  /** Verifies that a secure id for a specific user is valid. */
  verifySecureId?: Maybe<Scalars['Boolean']['output']>;
  /** Find visitor by id. */
  visitor: Visitor;
  /** Find visitor by email and channel id. */
  visitorByEmailChannelId: Visitor;
  /** Get email templates by channelId and templateType */
  visitorEmailTemplate?: Maybe<VisitorEmailTemplate>;
  /** Get email templates by channelId */
  visitorOrganizationSettings?: Maybe<VisitorOrganizationSettings>;
  /** Get visitor pass by id */
  visitorPass?: Maybe<VisitorPass>;
  /** Get visitor pass belonging to a host by visitor pass id */
  visitorPassByHost?: Maybe<VisitorPass>;
  /** Get visitor passes by submission id */
  visitorPassesBySubmissionId: VisitorPassAggregatesBySubmissionId;
  webhook?: Maybe<Webhook>;
  webhooksOnChannel?: Maybe<LibraryItemPagination>;
  whitelabel?: Maybe<Whitelabel>;
  whitelabels?: Maybe<Array<Whitelabel>>;
  /** Get list of work orders (3rd party service) */
  workOrderDetail?: Maybe<AngusWorkOrder>;
  /** Fetch Available Entities for Property Manager that contains building(Channels) */
  workOrderEntity: Array<Maybe<AngusLocation>>;
  /** Get the work order interaction by UCI Id if they have the rights to see it */
  workOrderInteraction: UserContentInteraction;
  /** Fetch Available Channels(Buildings) for Property Manager for given Entity */
  workOrderProperties: Array<Maybe<AngusLocation>>;
  /** Get List of available areas/suites/floors on a property (3rd party service) */
  workOrderPropertyFloors: Array<Maybe<AngusFloor>>;
  /** Get a list of tenants on a property (3rd party service) */
  workOrderPropertyTenants: Array<Maybe<AngusTenant>>;
  /** Get a list of locations where user is able to made maintenance requests */
  workOrderRequesterLocations: Array<Maybe<AngusBuilding>>;
  /** Get a list of users available for property or tenant (3rd party service) */
  workOrderTenantRequesters: Array<Maybe<AngusRequester>>;
  /** Get a list of possible services for a given property (3rd party service) */
  workOrderTypes: Array<Maybe<AngusRequestType>>;
  /** Gets user profiles for a provided GroupId if they have the rights to see it. */
  workOrderUsersByGroupRoles: UserGroupRolePagination;
};


export type QueryAdminSearchServiceRequestsArgs = {
  channelIds: Array<InputMaybe<Scalars['String']['input']>>;
  filter?: InputMaybe<ServiceRequestFilter>;
  forExport?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  preFilter?: InputMaybe<ServiceRequestPreFilter>;
  search?: InputMaybe<SearchServiceRequest>;
};


export type QueryAllSentNotificationsForContentCountArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryAppleIdClientSecretArgs = {
  clientId: Scalars['ID']['input'];
};


export type QueryAudienceArgs = {
  audienceId: Scalars['UUID']['input'];
  channelId: Scalars['UUID']['input'];
};


export type QueryAudiencesArgs = {
  channelId: Scalars['UUID']['input'];
  includeDefaultAudiences?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<AudienceSearch>;
};


export type QueryAvailableIntegrationsArgs = {
  channelId: Scalars['UUID']['input'];
  search?: InputMaybe<IntegrationSearch>;
};


export type QueryBiltSsoLinkArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryChannelArgs = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChannelContractArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryChannelContractsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ChannelContractSearch>;
};


export type QueryChannelGroupRolesUserCountArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryChannelHardwaresArgs = {
  channelId: Scalars['UUID']['input'];
  pagination: PaginationInput;
  search?: InputMaybe<HardwareSearch>;
};


export type QueryChannelIntegrationArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryChannelIntegrationFloorMapGenerationStatusArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
};


export type QueryChannelInvitesArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryChannelModuleArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryChannelModuleByChannelIdArgs = {
  channelId: Scalars['UUID']['input'];
  contentType?: InputMaybe<ContentType>;
};


export type QueryChannelPublicUsersArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<UserSearch>;
};


export type QueryChannelSectionsArgs = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pagination?: InputMaybe<PaginationInput>;
  sectionNameIncludes?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChannelUserSearchArgs = {
  _id: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search: UserSearch;
};


export type QueryChannelUsersByGroupRoleArgs = {
  groupRoleId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<UserGroupRoleSearch>;
  skipPagination?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryChannelUsersByGroupRolesArgs = {
  groupRoleIds: Array<InputMaybe<Scalars['UUID']['input']>>;
  search?: InputMaybe<UserGroupRoleSearch>;
};


export type QueryChannelsArgs = {
  pagination: PaginationInput;
  search?: InputMaybe<ChannelSearch>;
};


export type QueryChannelsByPermissionArgs = {
  pagination: PaginationInput;
  search?: InputMaybe<ChannelPermissionSearch>;
};


export type QueryChannelsByRelationshipArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ChannelRelationshipSearch>;
  skipPagination?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryChannelsForBlocksAndTargetingArgs = {
  pagination: PaginationInput;
  search?: InputMaybe<ChannelSearch>;
};


export type QueryCheckIfChannelExistsArgs = {
  id: Scalars['String']['input'];
};


export type QueryCombinedSectionsContentArgs = {
  searchOptions?: InputMaybe<SearchOptionsInput>;
  sectionIds: Array<Scalars['UUID']['input']>;
};


export type QueryCompaniesByChannelIdArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryCompleteSignupDataArgs = {
  guid: Scalars['String']['input'];
  verify: Scalars['String']['input'];
};


export type QueryConfirmEmailArgs = {
  email: Scalars['EmailAddress']['input'];
};


export type QueryContentArgs = {
  _id: Scalars['UUID']['input'];
  search?: InputMaybe<ContentSearch>;
};


export type QueryContentByAudienceArgs = {
  audienceId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ContentSearch>;
};


export type QueryContentFeatureEntriesRemainingArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryContentFeatureInventoryAvailableArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryContentFeaturePaymentQuoteArgs = {
  _id: Scalars['UUID']['input'];
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  interactionTimes?: InputMaybe<DateRangeInput>;
  paymentType?: PaymentType;
  quantity?: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryContentFeaturePaymentSummaryArgs = {
  _id: Scalars['UUID']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryContentFeatureQuantityAllotmentsArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryContentFeatureQuantityClaimedArgs = {
  _id: Scalars['UUID']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryContentFeatureReservableAvailabilityArgs = {
  _id: Scalars['UUID']['input'];
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};


export type QueryContentFeatureStatusesSummaryArgs = {
  _id: Scalars['UUID']['input'];
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};


export type QueryContentForRelatedSpacesArgs = {
  channelId: Scalars['UUID']['input'];
  contentId: Scalars['UUID']['input'];
  pagination: PaginationInput;
  search: ContentSearch;
};


export type QueryContentOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ContentSearch>;
};


export type QueryContentTemplateArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryContentTemplatesOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  path?: InputMaybe<Scalars['Path']['input']>;
  search?: InputMaybe<ContentTemplateSearch>;
  tags?: InputMaybe<StringListSearch>;
};


export type QueryContentTemplatesOnLibraryArgs = {
  libraryId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  path?: InputMaybe<Scalars['Path']['input']>;
  search?: InputMaybe<ContentTemplateSearch>;
  tags?: InputMaybe<StringListSearch>;
};


export type QueryContentTemplatesOnUserArgs = {
  pagination?: InputMaybe<PaginationInput>;
  path?: InputMaybe<Scalars['Path']['input']>;
  search?: InputMaybe<ContentTemplateSearch>;
  tags?: InputMaybe<StringListSearch>;
  userId: Scalars['UUID']['input'];
};


export type QueryContentWaitlistEnrollmentsArgs = {
  contentId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type QueryContentsForIntegrationArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryConvertIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryCredentialArgs = {
  visitorId: Scalars['UUID']['input'];
};


export type QueryCreditCardsArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  paymentAccountId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryDatasetRecordArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryDatasetSchemaArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryDatasetSchemasArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<DatasetSchemaSearch>;
};


export type QueryDiscoverUserIntegrationsArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
};


export type QueryDoUsersExistByEmailArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type QueryDraftContentArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryDraftContentHistoryArgs = {
  _id: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryDraftContentOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<DraftContentSearch>;
};


export type QueryDraftContentsForIntegrationArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryEquipmentSettingsOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryEscalationRuleArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryEstimateContentAudienceSizeArgs = {
  audiences?: InputMaybe<Array<AudienceIdInput>>;
  placements?: InputMaybe<Array<ContentPlacementInput>>;
};


export type QueryExistingUserLoginsArgs = {
  email: Scalars['EmailAddress']['input'];
  type?: InputMaybe<UserLoginType>;
};


export type QueryExportCsvTasksArgs = {
  filter?: InputMaybe<TaskFilterDto>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preFilter?: InputMaybe<TaskPreFilterDto>;
  search?: InputMaybe<FindTasksSearch>;
};


export type QueryFindSchedulesArgs = {
  filter?: InputMaybe<FindSchedulesFilter>;
  forExport?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  refIds: Array<InputMaybe<Scalars['String']['input']>>;
  search?: InputMaybe<FindSchedulesSearch>;
};


export type QueryFlatfileTokenArgs = {
  portal?: InputMaybe<FlatfilePortal>;
};


export type QueryFloorArgs = {
  floorId: Scalars['UUID']['input'];
  propertyId: Scalars['UUID']['input'];
};


export type QueryGetAccessPointByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAccessPointListArgs = {
  buildingId: Scalars['ID']['input'];
};


export type QueryGetAllEquipmentArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetAllEscalationRulesArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetAttachmentArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryGetAttachmentsByEntityArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryGetAuth0HrdConnectionsByDomainAliasArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetBuildingChannelIntegrationArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetBuildingTenantChannelsArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetChannelAccessControlGroupsArgs = {
  acgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelId: Scalars['UUID']['input'];
};


export type QueryGetChannelUsersArgs = {
  accessControlGroupId?: InputMaybe<Scalars['String']['input']>;
  channelId: Scalars['UUID']['input'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryGetChannelsForAccessManagerArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetChannelsForAccessUserArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryGetConfigArgs = {
  config?: InputMaybe<GetConfigRequest>;
};


export type QueryGetConfigMetadataArgs = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiUrl?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  cardholderGroupsFilterEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  channelId: Scalars['UUID']['input'];
  clientCertificate?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  clientVersion?: InputMaybe<Scalars['String']['input']>;
  connectionId?: InputMaybe<Scalars['String']['input']>;
  database?: InputMaybe<Scalars['String']['input']>;
  financialEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  financialPassword?: InputMaybe<Scalars['String']['input']>;
  financialUsername?: InputMaybe<Scalars['String']['input']>;
  locationUuid?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  privateKey?: InputMaybe<Scalars['String']['input']>;
  propertyId?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  serverDomain?: InputMaybe<Scalars['String']['input']>;
  serverName?: InputMaybe<Scalars['String']['input']>;
  serverUrl?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  visitorConnectOwnerGuid?: InputMaybe<Scalars['String']['input']>;
  visitorConnectPassword?: InputMaybe<Scalars['String']['input']>;
  visitorConnectUsername?: InputMaybe<Scalars['String']['input']>;
  visitorManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetCredentialGroupsArgs = {
  vtsOrganizationIds: Array<InputMaybe<Scalars['UUID']['input']>>;
  vtsUserId: Scalars['UUID']['input'];
};


export type QueryGetEquipmentArgs = {
  equipmentId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryGetEquipmentForExportArgs = {
  channelId: Scalars['UUID']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetFinalPriceForProductsArgs = {
  getFinalPriceForProductsRequest: GetFinalPriceForProductsRequest;
};


export type QueryGetIntegrationProcessesArgs = {
  input?: InputMaybe<GetIntegrationProcessesInput>;
};


export type QueryGetMeterReadingValueArgs = {
  meterReadingValueId: Scalars['UUID']['input'];
};


export type QueryGetMeterReadingValueForExportArgs = {
  channelId: Scalars['String']['input'];
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  meterReadingId: Scalars['UUID']['input'];
};


export type QueryGetMeterReadingValuesByMeterReadingIdsArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  meterReadingIds: Array<Scalars['UUID']['input']>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetProductArgs = {
  getProductRequest?: InputMaybe<GetProductRequest>;
};


export type QueryGetProductExceptionArgs = {
  getProductExceptionRequest: GetProductExceptionRequest;
};


export type QueryGetRecentJobsArgs = {
  recentJobsRequest?: InputMaybe<RecentJobsRequest>;
};


export type QueryGetRelayOutputByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryGetRelayOutputListArgs = {
  buildingId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<RelaySearch>;
};


export type QueryGetServiceRequestCategoriesArgs = {
  channelIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetServiceRequestFeaturesByChannelIdArgs = {
  channelId: Scalars['String']['input'];
};


export type QueryGetServiceRequestIssuesArgs = {
  channelIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetServiceRequestLocationsArgs = {
  channelIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetTenantBuildingIntegrationChannelArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetUserAccessDetailsArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetUserChannelsWithMobileAccessArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  userId: Scalars['UUID']['input'];
};


export type QueryGetVmContentArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ContentSearch>;
};


export type QueryGetWorkflowArgs = {
  workflowId: Scalars['UUID']['input'];
};


export type QueryGetWorkflowsByChannelArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryGetWorkflowsByChannelAndTypeArgs = {
  channelId: Scalars['UUID']['input'];
  type: Scalars['String']['input'];
};


export type QueryGroupRoleArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryHardwareArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHardwareAccessLogsArgs = {
  channelId: Scalars['UUID']['input'];
  pagination: PaginationInput;
  search?: InputMaybe<HardwareAccessLogsSearch>;
};


export type QueryHostsByUserTenantRelationshipArgs = {
  channelId: Scalars['UUID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryIntegrationArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryIntegrationsArgs = {
  search?: InputMaybe<IntegrationSearch>;
};


export type QueryInteractionArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryInteractionHistoryArgs = {
  _id: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryInteractionsOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  merchantAccountId?: InputMaybe<Scalars['UUID']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  paymentAccountId?: InputMaybe<Scalars['UUID']['input']>;
  search?: InputMaybe<UserContentInteractionSearch>;
  sectionId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryInteractionsOnContentArgs = {
  contentId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<UserContentInteractionSearch>;
};


export type QueryInviteArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryInvitesOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ChannelInviteSearch>;
  skipPagination?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLibrariesArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<LibrarySearch>;
};


export type QueryLibrariesOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<LibrarySearch>;
};


export type QueryLibraryArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryLinkedEntitiesArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search: LinkedEntitySearch;
};


export type QueryListProductCategoriesArgs = {
  listProductCategoriesRequest?: InputMaybe<ListProductCategoriesRequest>;
};


export type QueryListProductExceptionsArgs = {
  listProductExceptionsRequest?: InputMaybe<ListProductExceptionsRequest>;
};


export type QueryListProductsArgs = {
  filter: ProductsFilter;
  pagination: PaginationInput;
};


export type QueryLoginEmailExistsArgs = {
  email: Scalars['EmailAddress']['input'];
};


export type QueryMediaArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryMediaOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  mediaTypes?: InputMaybe<Array<InputMaybe<MediaType>>>;
  pagination?: InputMaybe<PaginationInput>;
  path?: InputMaybe<Scalars['Path']['input']>;
  search?: InputMaybe<MediaSearch>;
  tags?: InputMaybe<StringListSearch>;
};


export type QueryMediaOnLibraryArgs = {
  libraryId: Scalars['UUID']['input'];
  mediaTypes?: InputMaybe<Array<InputMaybe<MediaType>>>;
  pagination?: InputMaybe<PaginationInput>;
  path?: InputMaybe<Scalars['Path']['input']>;
  search?: InputMaybe<MediaSearch>;
  tags?: InputMaybe<StringListSearch>;
};


export type QueryMediaOnUserArgs = {
  mediaTypes?: InputMaybe<Array<InputMaybe<MediaType>>>;
  pagination?: InputMaybe<PaginationInput>;
  path?: InputMaybe<Scalars['Path']['input']>;
  search?: InputMaybe<MediaSearch>;
  tags?: InputMaybe<StringListSearch>;
  userId: Scalars['UUID']['input'];
};


export type QueryMemberGroupRolesArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryMetatagArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMetatagUsageArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMetatagsOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<MetatagSearch>;
};


export type QueryMobilePermissionsArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryObjectDatasetRecordArgs = {
  externalId: Scalars['UUID']['input'];
};


export type QueryOpenpathSetupMobileTokenArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
};


export type QueryParkwhizBookingPreviewArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryParkwhizLocationsArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  geo?: InputMaybe<GeoSearch>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryParkwhizParkingQrCodeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  scanCodeFormat?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPastVisitsArgs = {
  channelId: Scalars['UUID']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryPastVisitsByHostUserIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryPersonForUserArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryPmScheduleArgs = {
  scheduleId: Scalars['String']['input'];
};


export type QueryPmSchedulesForExportArgs = {
  refId: Scalars['String']['input'];
};


export type QueryPropertyArgs = {
  propertyId: Scalars['UUID']['input'];
};


export type QueryProxyclickCompanyUsersArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryProxyclickListVisitsArgs = {
  companyId: Scalars['ID']['input'];
  from?: InputMaybe<Scalars['DateTime']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryQuantityWaitlistForAdminArgs = {
  channelId: Scalars['UUID']['input'];
  contentId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<QuantityWaitlistSearch>;
};


export type QueryRecurrenceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryReformedChannelArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryReservableBookingsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search: ReservableBookingSearch;
};


export type QueryReservableWaitlistEnrollmentsArgs = {
  contentId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type QueryReservableWaitlistForAdminArgs = {
  channelId: Scalars['UUID']['input'];
  contentId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<ReservableWaitlistSearch>;
};


export type QueryResidentArgs = {
  personId: Scalars['UUID']['input'];
  propertyId: Scalars['UUID']['input'];
};


export type QueryResidentByUserIdArgs = {
  propertyId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type QueryResidentsArgs = {
  propertyId: Scalars['UUID']['input'];
};


export type QuerySamlDataArgs = {
  integrationId: Scalars['UUID']['input'];
};


export type QuerySamlServiceProviderDefinitionsArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type QuerySamlServiceProviderDefinitionsByIdpArgs = {
  idp: Scalars['String']['input'];
};


export type QueryScheduledVisitsArgs = {
  channelId: Scalars['UUID']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryScheduledVisitsByHostUserIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QuerySearchEquipmentArgs = {
  category?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  installDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  installDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<EquipmentSearch>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  warrantyExpirationDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  warrantyExpirationDateStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QuerySearchMeterReadingValueArgs = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  meterReadingId?: InputMaybe<Scalars['UUID']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<MeterReadingValueSearch>;
};


export type QuerySearchServiceRequestsArgs = {
  channelId: Scalars['String']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<SearchServiceRequest>;
};


export type QuerySearchTasksArgs = {
  filter?: InputMaybe<TaskFilterDto>;
  groupIds: Array<InputMaybe<Scalars['String']['input']>>;
  pagination?: InputMaybe<PaginationInput>;
  preFilter?: InputMaybe<TaskPreFilterDto>;
  search?: InputMaybe<FindTasksSearch>;
};


export type QuerySectionArgs = {
  _id: Scalars['UUID']['input'];
  searchOptions?: InputMaybe<SearchOptionsInput>;
};


export type QuerySectionDraftsArgs = {
  _id: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
};


export type QuerySectionFeatureReservableAvailabilityArgs = {
  end: Scalars['DateTime']['input'];
  searchOptions?: InputMaybe<SearchOptionsInput>;
  sectionId: Scalars['UUID']['input'];
  start: Scalars['DateTime']['input'];
};


export type QueryServiceRequestArgs = {
  serviceRequestId: Scalars['String']['input'];
};


export type QueryServiceRequestAuditLogsArgs = {
  serviceRequestId: Scalars['String']['input'];
};


export type QueryServiceRequestByUciArgs = {
  contentInteractionId: Scalars['String']['input'];
};


export type QueryServiceRequestContentIdArgs = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryServiceRequestFilterAttributesArgs = {
  channelIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryServiceRequestsArgs = {
  contentId: Scalars['String']['input'];
};


export type QueryShopifyCustomerArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
  email: Scalars['EmailAddress']['input'];
};


export type QuerySpecialInviteArgs = {
  guid: Scalars['UUID']['input'];
  verify: Scalars['UUID']['input'];
};


export type QueryTagArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTagsArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryTargetingRuleArgs = {
  targetingRule?: InputMaybe<TargetingRuleInput>;
};


export type QueryTargetingRulesForAudienceArgs = {
  audienceId: Scalars['UUID']['input'];
  filter?: InputMaybe<TargetingRuleEntityType>;
  fromChannel: Scalars['UUID']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['String']['input'];
};


export type QueryTaskAuditLogsArgs = {
  taskId: Scalars['String']['input'];
};


export type QueryTeamArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryTenantArgs = {
  propertyId: Scalars['UUID']['input'];
  tenantId: Scalars['UUID']['input'];
};


export type QueryTenantChannelsByUserArgs = {
  channelId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type QueryThemeArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUnitArgs = {
  propertyId: Scalars['UUID']['input'];
  unitId: Scalars['UUID']['input'];
};


export type QueryUnlinkedDatasetSchemasArgs = {
  channelId: Scalars['UUID']['input'];
  search?: InputMaybe<DatasetSchemaSearch>;
};


export type QueryUserArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryUserAccessDetailsArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryUserAdminArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryUserBookingsCountForContentArgs = {
  contentId: Scalars['UUID']['input'];
  selectedDate: Scalars['DateTime']['input'];
  userId: Scalars['UUID']['input'];
};


export type QueryUserBuildingAccessArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryUserChannelSettingsArgs = {
  channelId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type QueryUserChannelSettingsForMultipleChannelsArgs = {
  channelIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  userId: Scalars['UUID']['input'];
};


export type QueryUserIntegrationArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
};


export type QueryUsersArgs = {
  pagination: PaginationInput;
  search: AdminUserSearch;
};


export type QueryUsersByChannelIdArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<UsersByChannelIdSearch>;
};


export type QueryVerifyConfigArgs = {
  channelId: Scalars['UUID']['input'];
  config?: InputMaybe<Scalars['JSON']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVerifySecureIdArgs = {
  secureId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type QueryVisitorArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVisitorByEmailChannelIdArgs = {
  channelId: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type QueryVisitorEmailTemplateArgs = {
  channelId: Scalars['UUID']['input'];
  templateType: Scalars['String']['input'];
};


export type QueryVisitorOrganizationSettingsArgs = {
  channelId: Scalars['UUID']['input'];
};


export type QueryVisitorPassArgs = {
  channelId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type QueryVisitorPassByHostArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVisitorPassesBySubmissionIdArgs = {
  submissionId: Scalars['UUID']['input'];
};


export type QueryWebhookArgs = {
  _id: Scalars['UUID']['input'];
};


export type QueryWebhooksOnChannelArgs = {
  channelId: Scalars['UUID']['input'];
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<WebhookSearch>;
};


export type QueryWhitelabelArgs = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  instance?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWhitelabelsArgs = {
  search?: InputMaybe<WhitelabelSearch>;
};


export type QueryWorkOrderDetailArgs = {
  channelIntegrationId: Scalars['UUID']['input'];
  workOrderId: Scalars['String']['input'];
};


export type QueryWorkOrderEntityArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryWorkOrderInteractionArgs = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryWorkOrderPropertiesArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryWorkOrderPropertyFloorsArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryWorkOrderPropertyTenantsArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryWorkOrderRequesterLocationsArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryWorkOrderTenantRequestersArgs = {
  contentId: Scalars['UUID']['input'];
  tenantId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWorkOrderTypesArgs = {
  contentId: Scalars['UUID']['input'];
};


export type QueryWorkOrderUsersByGroupRolesArgs = {
  groupRoleIds: Array<InputMaybe<Scalars['UUID']['input']>>;
  search?: InputMaybe<UserGroupRoleSearch>;
};

export type Receivable = {
  __typename?: 'Receivable';
  /** The details of the receivable when type is credit bundle */
  creditBundle?: Maybe<CreditBundle>;
  /** The type of the receivable */
  type?: Maybe<ReceivableType>;
};

export type ReceivableInput = {
  creditBundle?: InputMaybe<CreditBundleInput>;
  type?: InputMaybe<ReceivableType>;
};

export enum ReceivableType {
  ReceivableTypeCreditBundle = 'RECEIVABLE_TYPE_CREDIT_BUNDLE',
  ReceivableTypeUnknown = 'RECEIVABLE_TYPE_UNKNOWN'
}

export type RecentJobsRequest = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  anyoneCanCancel?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalActorId?: Maybe<Scalars['String']['output']>;
  externalActorType?: Maybe<ExternalActorType>;
  groupId: Scalars['String']['output'];
  groupType: GroupType;
  intervalCount?: Maybe<Scalars['Int']['output']>;
  isActive: Scalars['Boolean']['output'];
  lastOccurrence?: Maybe<Scalars['DateTime']['output']>;
  nextOccurrence?: Maybe<Scalars['DateTime']['output']>;
  recurrenceId: Scalars['String']['output'];
  recurrenceInterval: RecurrenceInterval;
  recurrenceType: RecurrenceType;
  startDate: Scalars['DateTime']['output'];
  totalCounts?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum RecurrenceInterval {
  RecurrenceIntervalDaily = 'RECURRENCE_INTERVAL_DAILY',
  RecurrenceIntervalMonthly = 'RECURRENCE_INTERVAL_MONTHLY',
  RecurrenceIntervalUnknown = 'RECURRENCE_INTERVAL_UNKNOWN',
  RecurrenceIntervalWeekly = 'RECURRENCE_INTERVAL_WEEKLY',
  RecurrenceIntervalYearly = 'RECURRENCE_INTERVAL_YEARLY'
}

export enum RecurrenceType {
  RecurrenceTypeCharges = 'RECURRENCE_TYPE_CHARGES',
  RecurrenceTypeUnknown = 'RECURRENCE_TYPE_UNKNOWN'
}

export type RecurringCharge = {
  __typename?: 'RecurringCharge';
  charge: ChargeTemplate;
  recurrence: Recurrence;
};

export type RecurringChargesFilters = {
  externalPayerIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  externalPayerType?: InputMaybe<ExternalPayerType>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupType?: InputMaybe<GroupType>;
};

export type ReformedChannel = {
  __typename?: 'ReformedChannel';
  channelInvites?: Maybe<Array<Maybe<ChannelInvite>>>;
  id: Scalars['UUID']['output'];
};

export type RefundTransactionRequest = {
  transactionId: Scalars['String']['input'];
};

export type RefundTransactionResponse = {
  __typename?: 'RefundTransactionResponse';
  transactionId: Scalars['String']['output'];
};

export type RegisterKioskVisitorPassScanResponse = {
  __typename?: 'RegisterKioskVisitorPassScanResponse';
  scanStatus: HardwareVisitorPassScanStatus;
};

export enum RelationshipKeyType {
  Join = 'join',
  This = 'this'
}

export enum RelationshipPatternType {
  External = 'external',
  Named = 'named'
}

export type RelayAccessPoint = {
  __typename?: 'RelayAccessPoint';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RelayAction = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type RelayModule = {
  __typename?: 'RelayModule';
  buildingId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  deviceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastSeen: Scalars['DateTime']['output'];
  macAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: RelayModuleStatus;
};

export enum RelayModuleStatus {
  RelayModuleStatusConnected = 'RELAY_MODULE_STATUS_CONNECTED',
  RelayModuleStatusInvalid = 'RELAY_MODULE_STATUS_INVALID',
  RelayModuleStatusOffline = 'RELAY_MODULE_STATUS_OFFLINE',
  Unrecognized = 'UNRECOGNIZED'
}

export type RelayOutput = {
  __typename?: 'RelayOutput';
  accessPoint?: Maybe<RelayAccessPoint>;
  buildingId: Scalars['ID']['output'];
  delay?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pulse?: Maybe<Scalars['Int']['output']>;
  relayModule: RelayModule;
};

export type RelaySearch = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type RemovePaymentMethodRequest = {
  accountsUserId: Scalars['String']['input'];
  paymentProcessorSourceId: Scalars['String']['input'];
};

export type RemovePaymentMethodResponse = {
  __typename?: 'RemovePaymentMethodResponse';
  accountsUserId: Scalars['String']['output'];
};

export enum ReportGroupBy {
  ChannelId = 'channelId',
  ContentId = 'contentId',
  Date = 'date',
  GroupRoleId = 'groupRoleId',
  InteractionId = 'interactionId',
  UserId = 'userId'
}

export enum ReportOrderBy {
  ChannelId = 'channelId',
  ContentId = 'contentId',
  Count = 'count',
  GroupRoleId = 'groupRoleId',
  InteractionId = 'interactionId',
  UserId = 'userId'
}

export type ReportSearch = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  contentId?: InputMaybe<Scalars['UUID']['input']>;
  endDate: Scalars['DateTime']['input'];
  event: Scalars['String']['input'];
  groupBy?: InputMaybe<ReportGroupBy>;
  groupRoleId?: InputMaybe<Scalars['UUID']['input']>;
  interactionId?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  me?: InputMaybe<Scalars['UUID']['input']>;
  orderBy?: InputMaybe<ReportOrderBy>;
  orderDirection?: InputMaybe<SortDirection>;
  startDate: Scalars['DateTime']['input'];
  timeUnit?: InputMaybe<ReportTimeUnit>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum ReportTimeUnit {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year'
}

export type RequirementDetail = {
  __typename?: 'RequirementDetail';
  description: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type RequirementFeatureContentInteractionProperties = {
  __typename?: 'RequirementFeatureContentInteractionProperties';
  _id?: Maybe<Scalars['String']['output']>;
  content?: Maybe<RequirementFeatureContentInteractionPropertiesId>;
  description?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Scalars['String']['output']>;
};

export type RequirementFeatureContentInteractionPropertiesId = {
  __typename?: 'RequirementFeatureContentInteractionPropertiesId';
  _id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RequirementFeatureContentInteractionPropertiesIdInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RequirementFeatureContentInteractionPropertiesInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<RequirementFeatureContentInteractionPropertiesIdInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
};

/** A special input type for modyfying Lists of Requirements. */
export type RequirementListInput = {
  add?: InputMaybe<Array<Scalars['Requirement']['input']>>;
  remove?: InputMaybe<Array<Scalars['Requirement']['input']>>;
  set?: InputMaybe<Array<Scalars['Requirement']['input']>>;
};

export type RequirementListSearch = {
  all?: InputMaybe<Array<Scalars['Requirement']['input']>>;
  any?: InputMaybe<Array<Scalars['Requirement']['input']>>;
  not?: InputMaybe<Array<Scalars['Requirement']['input']>>;
};

export type RequirementsFeatureProperties = {
  __typename?: 'RequirementsFeatureProperties';
  channelLanguages?: Maybe<Scalars['JSON']['output']>;
  companyNameRequired?: Maybe<Scalars['Boolean']['output']>;
  contentInteractionRequired?: Maybe<Array<Maybe<RequirementFeatureContentInteractionProperties>>>;
  enabledLanguagesForMembers?: Maybe<Scalars['JSON']['output']>;
  fullNameRequired?: Maybe<Scalars['Boolean']['output']>;
  multiLanguageEnabled?: Maybe<Scalars['Boolean']['output']>;
  phoneNumberRequired?: Maybe<Scalars['Boolean']['output']>;
  profilePictureRequired?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<RequirementsSource>;
  verifiedEmailRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type RequirementsFeaturePropertiesInput = {
  channelLanguages?: InputMaybe<Scalars['JSON']['input']>;
  companyNameRequired?: InputMaybe<Scalars['Boolean']['input']>;
  contentInteractionRequired?: InputMaybe<Array<InputMaybe<RequirementFeatureContentInteractionPropertiesInput>>>;
  enabledLanguagesForMembers?: InputMaybe<Scalars['JSON']['input']>;
  fullNameRequired?: InputMaybe<Scalars['Boolean']['input']>;
  multiLanguageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberRequired?: InputMaybe<Scalars['Boolean']['input']>;
  profilePictureRequired?: InputMaybe<Scalars['Boolean']['input']>;
  verifiedEmailRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequirementsSource = {
  __typename?: 'RequirementsSource';
  _id: Scalars['String']['output'];
  profileName: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type Reservable = {
  __typename?: 'Reservable';
  features?: Maybe<Array<ContentFeature>>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  reservableBookings: Array<ReservableBooking>;
  timeZone?: Maybe<Scalars['String']['output']>;
  type: ContentType;
};


export type ReservableReservableBookingsArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type ReservableAvailability = {
  __typename?: 'ReservableAvailability';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type ReservableBooking = {
  __typename?: 'ReservableBooking';
  adminNote?: Maybe<Scalars['String']['output']>;
  channel: Channel;
  companies: Array<BookingCompany>;
  content: DraftContent;
  contentData?: Maybe<Scalars['JSON']['output']>;
  created: Scalars['DateTime']['output'];
  createdBy: PublicUser;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  features?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['UUID']['output'];
  lastStatus?: Maybe<Scalars['UserContentInteractionStatus']['output']>;
  lastStatusDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['UserContentInteractionStatus']['output'];
  statusDate: Scalars['DateTime']['output'];
  updated: Scalars['DateTime']['output'];
  updatedBy: PublicUser;
  user: User;
};

export type ReservableBookingPagination = {
  __typename?: 'ReservableBookingPagination';
  items?: Maybe<Array<ReservableBooking>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ReservableBookingSearch = {
  channelId: Scalars['UUID']['input'];
  dateRange?: InputMaybe<DateRangeInput>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
};

export type ReservableConnection = {
  __typename?: 'ReservableConnection';
  edges: Array<ReservableEdge>;
  pageInfo: CursorPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReservableEdge = {
  __typename?: 'ReservableEdge';
  cursor: Scalars['String']['output'];
  node: Reservable;
};

export type ReservableWaitlistByContent = {
  __typename?: 'ReservableWaitlistByContent';
  companies?: Maybe<Array<Maybe<ReservableWaitlistCompany>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  startDate: Scalars['DateTime']['output'];
  user?: Maybe<ReservableWaitlistUser>;
};

export type ReservableWaitlistCompany = {
  __typename?: 'ReservableWaitlistCompany';
  name?: Maybe<Scalars['String']['output']>;
};

export type ReservableWaitlistEnrollment = {
  __typename?: 'ReservableWaitlistEnrollment';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type ReservableWaitlistPagination = {
  __typename?: 'ReservableWaitlistPagination';
  items: Array<ReservableWaitlistByContent>;
  pageInfo: PageInfo;
};

export type ReservableWaitlistSearch = {
  afterEndDate?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortBy>;
};

export type ReservableWaitlistUnrollment = {
  __typename?: 'ReservableWaitlistUnrollment';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type ReservableWaitlistUser = {
  __typename?: 'ReservableWaitlistUser';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Researvable content search on channel input type */
export type ReservablesOnChannelSearchInput = {
  unpublishDate?: InputMaybe<DateTimeSearch>;
};

export enum ResetPeriodType {
  Daily = 'Daily',
  Hourly = 'Hourly',
  Monthly = 'Monthly',
  Never = 'Never',
  Weekly = 'Weekly',
  Yearly = 'Yearly'
}

export type Resident = {
  __typename?: 'Resident';
  floorIds: Array<Maybe<Scalars['UUID']['output']>>;
  floors?: Maybe<Array<Maybe<Floor>>>;
  id: Scalars['UUID']['output'];
  moveInDate?: Maybe<Scalars['DateTime']['output']>;
  moveOutDate?: Maybe<Scalars['DateTime']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unitIds: Array<Maybe<Scalars['UUID']['output']>>;
  units?: Maybe<Array<Maybe<Unit>>>;
};

export enum ResidentStatus {
  Current = 'CURRENT',
  Eviction = 'EVICTION',
  Future = 'FUTURE',
  Invalid = 'INVALID',
  Notice = 'NOTICE',
  NoPriorStatus = 'NO_PRIOR_STATUS',
  Past = 'PAST',
  Unrecognized = 'UNRECOGNIZED'
}

export enum ResidentType {
  Guarantor = 'GUARANTOR',
  Invalid = 'INVALID',
  PrimaryResident = 'PRIMARY_RESIDENT',
  SecondaryResident = 'SECONDARY_RESIDENT',
  Unrecognized = 'UNRECOGNIZED'
}

export type RiseMigrationFeatures = {
  addRandomSuffixToSlug?: InputMaybe<Scalars['Boolean']['input']>;
  migrateAmenities?: InputMaybe<Scalars['Boolean']['input']>;
  migrateCatUsers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  migrateCommunityNews?: InputMaybe<Scalars['Boolean']['input']>;
  migrateDiscoveryLinks?: InputMaybe<Scalars['Boolean']['input']>;
  migrateEvents?: InputMaybe<Scalars['Boolean']['input']>;
  migrateForms?: InputMaybe<Scalars['Boolean']['input']>;
  migrateNonReservableAmenities?: InputMaybe<Scalars['Boolean']['input']>;
  migrateTenants?: InputMaybe<Scalars['Boolean']['input']>;
  migrateUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SamlData = {
  __typename?: 'SAMLData';
  SAMLResponse: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SamlServiceProviderDefinition = {
  __typename?: 'SAMLServiceProviderDefinition';
  customFields?: Maybe<Scalars['JSON']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  idp?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type SamlServiceProviderDefinitionInput = {
  customFields?: InputMaybe<Scalars['JSON']['input']>;
  entityId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  idp: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SaveConfigRequest = {
  config: Scalars['JSON']['input'];
  provider: Scalars['String']['input'];
  vts_organization_id: Scalars['ID']['input'];
};

export type SaveConfigResponse = {
  __typename?: 'SaveConfigResponse';
  _id?: Maybe<Scalars['UUID']['output']>;
};

export type SavePaymentMethodRequest = {
  accountsUserId: Scalars['String']['input'];
  paymentProcessorSourceId: Scalars['String']['input'];
};

export type SavePaymentMethodResponse = {
  __typename?: 'SavePaymentMethodResponse';
  paymentProcessorCustomerId: Scalars['String']['output'];
  paymentProcessorSourceId: Scalars['String']['output'];
};

export type ScheduledVisitorPass = {
  __typename?: 'ScheduledVisitorPass';
  result: ScheduledVisitorPassResult;
  totalCount: Scalars['Int']['output'];
};

export type ScheduledVisitorPassResult = {
  __typename?: 'ScheduledVisitorPassResult';
  eventNames?: Maybe<Array<Scalars['String']['output']>>;
  tenantNames?: Maybe<Array<Scalars['String']['output']>>;
  visits?: Maybe<Array<VisitorPass>>;
};

export enum SearchMode {
  And = 'and',
  Or = 'or'
}

export type SearchOptionFilter = {
  __typename?: 'SearchOptionFilter';
  filter?: Maybe<Scalars['JSON']['output']>;
  type: ContentFilterType;
};

export type SearchOptionFilterInput = {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  type: ContentFilterType;
};

/** Search Options input type for more advanced filtering and sorting */
export type SearchOptions = {
  __typename?: 'SearchOptions';
  areFiltersApplied: Scalars['Boolean']['output'];
  channelId?: Maybe<Scalars['UUID']['output']>;
  filters?: Maybe<Array<SearchOptionFilter>>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  includedMLSColumns?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  metatagFilters?: Maybe<Array<MetatagFilter>>;
  search?: Maybe<Scalars['String']['output']>;
  sorts?: Maybe<Array<ContentSortType>>;
};

/** Search Options input type for more advanced filtering and sorting */
export type SearchOptionsInput = {
  areFiltersApplied?: Scalars['Boolean']['input'];
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  contentTags?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<SearchOptionFilterInput>>;
  geo?: InputMaybe<Scalars['GeoPoint']['input']>;
  includedMLSColumns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metatagFilters?: InputMaybe<Array<MetatagFilterInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorts?: InputMaybe<Array<ContentSortType>>;
};

export type SearchServiceRequest = {
  assignee?: InputMaybe<StringSearch>;
  category?: InputMaybe<StringSearch>;
  company?: InputMaybe<StringSearch>;
  createdAt?: InputMaybe<DateTimeSearch>;
  createdBy?: InputMaybe<StringSearch>;
  description?: InputMaybe<StringSearch>;
  floor?: InputMaybe<StringSearch>;
  issue?: InputMaybe<StringSearch>;
  location?: InputMaybe<StringSearch>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  serviceRequestId?: InputMaybe<StringSearch>;
  sortBy?: InputMaybe<SortBy>;
  suite?: InputMaybe<StringSearch>;
  updatedAt?: InputMaybe<DateTimeSearch>;
  updatedBy?: InputMaybe<StringSearch>;
};

export type SearchTasksReponseDto = {
  __typename?: 'SearchTasksReponseDTO';
  filterOptions?: Maybe<TaskFilterOptionDto>;
  pageInfo?: Maybe<PageInfo>;
  tasks?: Maybe<Array<ClientTask>>;
};

export type Section = Document & {
  __typename?: 'Section';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  backgroundColor?: Maybe<Scalars['Color']['output']>;
  backgroundImage?: Maybe<Scalars['Image']['output']>;
  channel?: Maybe<Channel>;
  color?: Maybe<Scalars['Color']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<ContentFilterType>>;
  groupBy?: Maybe<ContentGroupByType>;
  icon?: Maybe<Scalars['String']['output']>;
  iconSet?: Maybe<Scalars['String']['output']>;
  iconWeight?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  name_l10n?: Maybe<Scalars['JSON']['output']>;
  object: SectionObjectType;
  query?: Maybe<Scalars['JSON']['output']>;
  renderType?: Maybe<BlockType>;
  /**
   * If this is section has search and filters enabled, this will contain helpful
   * info about this searching, filtering this section.
   */
  searchInfo: DynamicSectionInfo;
  sectionContent?: Maybe<Array<SectionContent>>;
  sectionMetatags?: Maybe<Array<ObjectMetatag>>;
  /**
   * Deprecated
   * @deprecated use sorts instead
   */
  sort?: Maybe<Scalars['String']['output']>;
  /** @deprecated use sorts instead */
  sortOrder?: Maybe<SortDirection>;
  sorts?: Maybe<Array<ContentSortType>>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  tagsOnSection?: Maybe<Array<Tag>>;
  type: SectionType;
};


export type SectionSectionContentArgs = {
  view?: InputMaybe<Scalars['String']['input']>;
};

export type SectionContent = Document & {
  __typename?: 'SectionContent';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _order: Scalars['Int']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  content: Content;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  section: Section;
};

export type SectionContentInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _order: Scalars['Int']['input'];
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  content: ContentIdInput;
  section: SectionIdInput;
};

export type SectionContentSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  content?: InputMaybe<ContentSearch>;
  searchMode?: InputMaybe<SearchMode>;
  section?: InputMaybe<SectionSearch>;
  sortBy?: InputMaybe<SortBy>;
};

export type SectionIdInput = {
  _id: Scalars['UUID']['input'];
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SectionInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  backgroundImage?: InputMaybe<Scalars['Image']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  color?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_l10n?: InputMaybe<Scalars['JSON']['input']>;
  filters?: InputMaybe<Array<ContentFilterType>>;
  groupBy?: InputMaybe<ContentGroupByType>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconSet?: InputMaybe<Scalars['String']['input']>;
  iconWeight?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Image']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_l10n?: InputMaybe<Scalars['JSON']['input']>;
  object?: InputMaybe<SectionObjectType>;
  query?: InputMaybe<Scalars['JSON']['input']>;
  renderType?: InputMaybe<BlockType>;
  sectionMetatags?: InputMaybe<Array<ObjectMetatagInput>>;
  sorts?: InputMaybe<Array<ContentSortType>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsOnSection?: InputMaybe<Array<InputMaybe<TagOnSection>>>;
  type?: InputMaybe<SectionType>;
};

export enum SectionObjectType {
  Channel = 'Channel',
  Content = 'Content',
  Section = 'Section',
  User = 'User'
}

export type SectionSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  description?: InputMaybe<StringSearch>;
  name?: InputMaybe<StringSearch>;
  object?: InputMaybe<SectionObjectType>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  tags?: InputMaybe<StringListSearch>;
  type?: InputMaybe<SectionType>;
};

export enum SectionType {
  Dynamic = 'Dynamic',
  Static = 'Static'
}

/**
 * The price at which the product is being sold.
 * This price is composed of gross amounts only.
 * This price is should be displayed to purchasers.
 */
export type SellingPrice = {
  __typename?: 'SellingPrice';
  /** The gross amount of markup on the product */
  grossMarkup: Scalars['Float']['output'];
  /** The gross amount of tax on the product */
  grossTax: Scalars['Float']['output'];
  /** The sum of the product's price and the gross markup amount */
  listPrice: Scalars['Float']['output'];
  /** The sum of the product's price and the gross markup and tax amounts */
  netPrice: Scalars['Float']['output'];
};

export type SellingPriceInput = {
  grossMarkup: Scalars['Float']['input'];
  grossTax: Scalars['Float']['input'];
  listPrice: Scalars['Float']['input'];
  netPrice: Scalars['Float']['input'];
};

export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  assignee?: Maybe<IdNamePair>;
  assigneeGroups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attachmentRefId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  channelId?: Maybe<IdNamePair>;
  company?: Maybe<IdNamePair>;
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<IdNamePair>;
  customQuestions?: Maybe<Array<Maybe<CustomQuestion>>>;
  description?: Maybe<Scalars['String']['output']>;
  displayMetadata?: Maybe<Array<Maybe<DisplayMetadata>>>;
  equipment?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  extRefId?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  friendlyID?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['Int']['output']>;
  isBillable?: Maybe<Scalars['Boolean']['output']>;
  issue?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  nextStatuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  notes?: Maybe<Scalars['String']['output']>;
  serviceRequestId: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  submitOnBehalfOf?: Maybe<IdNamePair>;
  suite?: Maybe<Scalars['String']['output']>;
  suiteIds?: Maybe<Array<Maybe<SourceEntityIdentifier>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<IdNamePair>;
  userFriendlyID?: Maybe<Scalars['String']['output']>;
};

export type ServiceRequestAction = {
  __typename?: 'ServiceRequestAction';
  currentState?: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  previousState?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ServiceRequestAuditLog = {
  __typename?: 'ServiceRequestAuditLog';
  actions?: Maybe<Array<ServiceRequestAction>>;
  date: Scalars['DateTime']['output'];
  userImageUrl?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type ServiceRequestCategoriesList = {
  __typename?: 'ServiceRequestCategoriesList';
  categories?: Maybe<Array<Category>>;
};

export type ServiceRequestDto = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  attachmentRefId?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  customQuestions?: InputMaybe<Array<InputMaybe<CustomQuestionInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  equipment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  extRefId?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suite?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceRequestFeatures = {
  __typename?: 'ServiceRequestFeatures';
  features?: Maybe<Features>;
};

export type ServiceRequestFilter = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  companies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  company?: InputMaybe<Scalars['String']['input']>;
  createDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  createDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  equipment?: InputMaybe<Scalars['String']['input']>;
  equipments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isBillable?: InputMaybe<Scalars['Boolean']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  issues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ServiceRequestIssuesList = {
  __typename?: 'ServiceRequestIssuesList';
  issues?: Maybe<Array<Issue>>;
};

export type ServiceRequestLocationsList = {
  __typename?: 'ServiceRequestLocationsList';
  locations?: Maybe<Array<Location>>;
};

export type ServiceRequestPreFilter = {
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currentUser?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ServiceRequestSearchResults = {
  __typename?: 'ServiceRequestSearchResults';
  pageInfo?: Maybe<PageInfo>;
  serviceRequests?: Maybe<Array<ServiceRequest>>;
};

export type ServiceRequestUpdateResponseDto = {
  __typename?: 'ServiceRequestUpdateResponseDTO';
  serviceRequestId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ServiceRequestsFilterFieldsDto = {
  __typename?: 'ServiceRequestsFilterFieldsDTO';
  assignees?: Maybe<Array<Maybe<IdNamePair>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  companies?: Maybe<Array<Maybe<IdNamePair>>>;
  equipment?: Maybe<Array<Maybe<IdNamePair>>>;
  issues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  statuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ServiceRequestsList = {
  __typename?: 'ServiceRequestsList';
  serviceRequests?: Maybe<Array<ServiceRequest>>;
};

export type Session = {
  __typename?: 'Session';
  _created: Scalars['DateTime']['output'];
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  device?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  hostname?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  user?: Maybe<PublicUser>;
  version?: Maybe<Scalars['String']['output']>;
};

export enum SessionDevice {
  Android = 'android',
  Chrome = 'chrome',
  Firefox = 'firefox',
  Ie = 'ie',
  Ios = 'ios',
  Other = 'other',
  Safari = 'safari'
}

export type SessionInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  device?: InputMaybe<SessionDevice>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  geo?: InputMaybe<Scalars['GeoPoint']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<SessionPlatform>;
  user?: InputMaybe<UserIdInput>;
  version?: InputMaybe<Scalars['String']['input']>;
  whitelabel?: InputMaybe<WhitelabelInput>;
};

export enum SessionPlatform {
  Api = 'api',
  Mobile = 'mobile',
  Other = 'other',
  Tablet = 'tablet',
  Web = 'web'
}

export type SessionSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  expires?: InputMaybe<DateTimeSearch>;
  geo?: InputMaybe<GeoSearch>;
  searchMode?: InputMaybe<SearchMode>;
};

export type ShopifyCustomer = {
  __typename?: 'ShopifyCustomer';
  shopifyCustomerId?: Maybe<Scalars['ID']['output']>;
};

export type ShopifyMultipassLoginDetail = {
  __typename?: 'ShopifyMultipassLoginDetail';
  loginUrl?: Maybe<Scalars['URL']['output']>;
  multipassId?: Maybe<Scalars['UUID']['output']>;
  shopifyCustomerId?: Maybe<Scalars['String']['output']>;
  shopifyStoreUrl?: Maybe<Scalars['URL']['output']>;
};

/** ReservableBooking search input type */
export type Sort = {
  direction?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type SortBy = {
  dir?: InputMaybe<SortDirection>;
  key?: Scalars['String']['input'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SourceEntityIdentifier = {
  __typename?: 'SourceEntityIdentifier';
  sourceEntityId?: Maybe<Scalars['String']['output']>;
  sourceEntityType?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['Int']['output']>;
};

/**
 * SourceEntityIdentifier for Central Entities Service.
 * Clients of Activate Server may need to establish relationships in CES via Activate.
 */
export type SourceEntityIdentifierInput = {
  sourceEntityId: Scalars['ID']['input'];
  sourceEntityType: Scalars['String']['input'];
  sourceType: SourceType;
};

/**
 * This is GraphQL equivalent of SourceType enum from CES, check:
 * 'service-contracts/gen/vts/central_entities/v1/service/entity'
 */
export enum SourceType {
  SourceTypeActivate = 'SOURCE_TYPE_ACTIVATE',
  SourceTypeEntrata = 'SOURCE_TYPE_ENTRATA',
  SourceTypeInvalid = 'SOURCE_TYPE_INVALID',
  SourceTypeMri = 'SOURCE_TYPE_MRI',
  SourceTypeRealPage = 'SOURCE_TYPE_REAL_PAGE',
  SourceTypeVcm = 'SOURCE_TYPE_VCM',
  SourceTypeYardi = 'SOURCE_TYPE_YARDI',
  Unrecognized = 'UNRECOGNIZED'
}

export enum SpecialGroupRole {
  Admin = 'Admin',
  Member = 'Member',
  Workplace = 'Workplace'
}

export type SpecialInvite = {
  __typename?: 'SpecialInvite';
  email?: Maybe<Scalars['EmailAddress']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type StartImportJobRequest = {
  jobId?: InputMaybe<Scalars['ID']['input']>;
  provider: Scalars['String']['input'];
  vts_organization_id: Scalars['ID']['input'];
};

export type StartImportJobResponse = {
  __typename?: 'StartImportJobResponse';
  jobId: Scalars['ID']['output'];
};

export type StepExecution = {
  __typename?: 'StepExecution';
  id?: Maybe<Scalars['String']['output']>;
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  meterReadingId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<StepExecutionOption>;
  order?: Maybe<Scalars['Int']['output']>;
  response?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taskId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type StepExecutionOption = {
  __typename?: 'StepExecutionOption';
  choices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type StepTemplate = {
  __typename?: 'StepTemplate';
  id?: Maybe<Scalars['String']['output']>;
  meterReadingId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<StepTemplateOption>;
  type?: Maybe<Scalars['String']['output']>;
};

export type StepTemplateDto = {
  id?: InputMaybe<Scalars['String']['input']>;
  meterReadingId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<StepTemplateOptionDto>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type StepTemplateOption = {
  __typename?: 'StepTemplateOption';
  choices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type StepTemplateOptionDto = {
  choices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type StepsRequest = {
  id?: InputMaybe<Scalars['String']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  meterReadingId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<StepsRequestOption>;
  order?: InputMaybe<Scalars['Int']['input']>;
  response?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type StepsRequestOption = {
  choices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  subtype?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

/** A special input type for modfying Lists of strings. */
export type StringListInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StringListSearch = {
  all?: InputMaybe<Array<Scalars['String']['input']>>;
  any?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StringSearch = {
  any?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<StringSearchType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum StringSearchType {
  Any = 'any',
  Eq = 'eq',
  Like = 'like',
  Ne = 'ne'
}

export type StripeAccount = {
  __typename?: 'StripeAccount';
  business_logo?: Maybe<Scalars['ID']['output']>;
  business_logo_large?: Maybe<Scalars['ID']['output']>;
  business_name?: Maybe<Scalars['String']['output']>;
  business_primary_color?: Maybe<Scalars['Color']['output']>;
  business_url?: Maybe<Scalars['URL']['output']>;
  charges_enabled?: Maybe<Scalars['Boolean']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  debit_negative_balances?: Maybe<Scalars['Boolean']['output']>;
  decline_charge_on?: Maybe<StripeDeclineCharge>;
  default_currency?: Maybe<Scalars['String']['output']>;
  details_submitted: Scalars['Boolean']['output'];
  display_name?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  id: Scalars['ID']['output'];
  legal_entity?: Maybe<StripeLegalEntity>;
  object: Scalars['String']['output'];
  payout_schedule?: Maybe<StripePayoutSchedule>;
  payout_statement_descriptor?: Maybe<Scalars['String']['output']>;
  payouts_enabled?: Maybe<Scalars['Boolean']['output']>;
  product_description?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  support_address?: Maybe<StripeAddress>;
  support_email?: Maybe<Scalars['EmailAddress']['output']>;
  support_phone?: Maybe<Scalars['String']['output']>;
  support_url?: Maybe<Scalars['URL']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  type: StripeAccountType;
};

export type StripeAccountInput = {
  business_logo?: InputMaybe<Scalars['ID']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  business_primary_color?: InputMaybe<Scalars['Color']['input']>;
  business_url?: InputMaybe<Scalars['URL']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  debit_negative_balances?: InputMaybe<Scalars['Boolean']['input']>;
  decline_charge_on?: InputMaybe<StripeDeclineChargeInput>;
  default_currency?: InputMaybe<Scalars['String']['input']>;
  details_submitted?: InputMaybe<Scalars['Boolean']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  external_account: StripeBankAccountInput;
  legal_entity: StripeLegalEntityInput;
  payout_schedule?: InputMaybe<StripePayoutScheduleInput>;
  payout_statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  product_description?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  support_email?: InputMaybe<Scalars['EmailAddress']['input']>;
  support_phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  support_url?: InputMaybe<Scalars['URL']['input']>;
  type?: InputMaybe<StripeAccountType>;
};

export type StripeAccountLink = {
  __typename?: 'StripeAccountLink';
  created: Scalars['Int']['output'];
  expires_at: Scalars['Int']['output'];
  object: Scalars['String']['output'];
  url?: Maybe<Scalars['URL']['output']>;
};

export enum StripeAccountType {
  Custom = 'custom',
  Express = 'express',
  Standard = 'standard'
}

export type StripeAccountVerification = {
  __typename?: 'StripeAccountVerification';
  disabled_reason?: Maybe<Scalars['String']['output']>;
  due_by?: Maybe<StripeDate>;
  fields_needed?: Maybe<Array<Scalars['String']['output']>>;
};

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type StripeAddressInput = {
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postal_code: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type StripeBankAccount = {
  __typename?: 'StripeBankAccount';
  account_holder_name: Scalars['String']['output'];
  account_holder_type: StripeAccountType;
  account_number: Scalars['String']['output'];
  bank_name: Scalars['String']['output'];
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  fingerprint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last4?: Maybe<Scalars['String']['output']>;
  routing_number?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type StripeBankAccountInput = {
  account_holder_name?: InputMaybe<Scalars['String']['input']>;
  account_holder_type?: InputMaybe<StripeLegalEntityType>;
  account_number: Scalars['String']['input'];
  country: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  object?: StripeExternalAccountObject;
  routing_number?: InputMaybe<Scalars['String']['input']>;
};

export type StripeDate = {
  __typename?: 'StripeDate';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type StripeDateInput = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export enum StripeDayEnum {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type StripeDeclineCharge = {
  __typename?: 'StripeDeclineCharge';
  avs_failure?: Maybe<Scalars['Boolean']['output']>;
  cvc_failure?: Maybe<Scalars['Boolean']['output']>;
};

export type StripeDeclineChargeInput = {
  avs_failure: Scalars['Boolean']['input'];
  cvc_failure: Scalars['Boolean']['input'];
};

export enum StripeExternalAccountObject {
  BankAccount = 'bank_account'
}

export enum StripeGenderEnum {
  Female = 'female',
  Male = 'male'
}

export type StripeInfo = {
  __typename?: 'StripeInfo';
  _id: Scalars['ID']['output'];
  clientId?: Maybe<Scalars['ID']['output']>;
  publicKey: Scalars['ID']['output'];
};

export enum StripeIntervalEnum {
  Daily = 'daily',
  Manual = 'manual',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export type StripeLegalEntity = {
  __typename?: 'StripeLegalEntity';
  additional_owners?: Maybe<Array<Scalars['ID']['output']>>;
  address?: Maybe<StripeAddress>;
  business_name?: Maybe<Scalars['String']['output']>;
  business_taxt_id_provided?: Maybe<Scalars['Boolean']['output']>;
  dob?: Maybe<StripeDate>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<StripeGenderEnum>;
  last_name?: Maybe<Scalars['String']['output']>;
  personal_address?: Maybe<StripeAddress>;
  personal_id_number_provided?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['PhoneNumber']['output']>;
  ssn_last_4_provided?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<StripeLegalEntityType>;
  verification?: Maybe<StripeVerification>;
};

export type StripeLegalEntityInput = {
  additional_owners?: InputMaybe<Array<Scalars['ID']['input']>>;
  address: StripeAddressInput;
  business_name?: InputMaybe<Scalars['String']['input']>;
  business_tax_id?: InputMaybe<Scalars['String']['input']>;
  business_vat_id?: InputMaybe<Scalars['String']['input']>;
  dob: StripeDateInput;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<StripeGenderEnum>;
  last_name: Scalars['String']['input'];
  maiden_name?: InputMaybe<Scalars['String']['input']>;
  personal_address?: InputMaybe<StripeAddressInput>;
  personal_id_number?: InputMaybe<Scalars['ID']['input']>;
  phone_number?: InputMaybe<Scalars['PhoneNumber']['input']>;
  ssn_last_4: Scalars['Int']['input'];
  type: StripeLegalEntityType;
  verifcation?: InputMaybe<StripeVeriticationInput>;
};

export enum StripeLegalEntityType {
  Company = 'company',
  Individual = 'individual'
}

export type StripePayoutSchedule = {
  __typename?: 'StripePayoutSchedule';
  delay_days?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<StripeIntervalEnum>;
  monthly_anchor?: Maybe<Scalars['Int']['output']>;
  weekly_anchor?: Maybe<StripeDayEnum>;
};

export type StripePayoutScheduleInput = {
  delay_days?: Scalars['Int']['input'];
  interval?: StripeIntervalEnum;
  monthly_anchor?: InputMaybe<Scalars['Int']['input']>;
  weekly_anchor?: InputMaybe<StripeDayEnum>;
};

export type StripeVerification = {
  __typename?: 'StripeVerification';
  details?: Maybe<Scalars['JSON']['output']>;
  details_code?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['ID']['output']>;
  document_back?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type StripeVeriticationInput = {
  document: Scalars['ID']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  anyoneCanCancel: Scalars['Boolean']['output'];
  channelId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  intervalCount: Scalars['Int']['output'];
  recurrenceInterval: RecurrenceInterval;
  subscriptionDetailsId: Scalars['String']['output'];
  totalCounts?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type Suite = Document & {
  __typename?: 'Suite';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalSuiteId?: Maybe<Scalars['String']['output']>;
  numberOrName: Scalars['String']['output'];
  properties?: Maybe<Scalars['JSON']['output']>;
  tenantLease?: Maybe<TenantLease>;
};

export type SuiteInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  externalSuiteId?: InputMaybe<Scalars['String']['input']>;
  numberOrName: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['JSON']['input']>;
  tenantLease?: InputMaybe<TenantLeaseInput>;
};

export type SwitchChannel = {
  __typename?: 'SwitchChannel';
  focusOnChannels?: Maybe<Array<Channel>>;
  primaryId: Scalars['UUID']['output'];
  secondaryId?: Maybe<Scalars['UUID']['output']>;
};

/** Sync Group Role input type */
export type SyncGroupRoleInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  groupRole: GroupRoleIdInput;
  joinGroupRole: GroupRoleIdInput;
};

export type Tag = {
  __typename?: 'Tag';
  channelId: Scalars['UUID']['output'];
  contents?: Maybe<Array<Content>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  name_l10n?: Maybe<Scalars['JSON']['output']>;
  sections?: Maybe<Array<Section>>;
};

export type TagInput = {
  channelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  name_l10n?: InputMaybe<Scalars['JSON']['input']>;
};

export type TagOnSection = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type TagOnSectionInput = {
  channelId: Scalars['ID']['input'];
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  name_l10n?: InputMaybe<Scalars['JSON']['input']>;
};

export enum TargetLibraryType {
  Channel = 'Channel',
  Library = 'Library',
  User = 'User'
}

export type TargetingRule = {
  __typename?: 'TargetingRule';
  audienceId: Scalars['UUID']['output'];
  channelId?: Maybe<Scalars['UUID']['output']>;
  groupRoleId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export enum TargetingRuleEntityType {
  Channel = 'CHANNEL',
  GroupRole = 'GROUP_ROLE'
}

export type TargetingRuleInput = {
  audienceId: Scalars['UUID']['input'];
  fromChannel: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  targetingRuleType: TargetingRuleEntityType;
};

export type TargetingRulesForAudience = {
  __typename?: 'TargetingRulesForAudience';
  channelTargetingRules?: Maybe<Array<Maybe<TargetingRule>>>;
  groupRoleTargetingRules?: Maybe<Array<Maybe<TargetingRule>>>;
};

export type Task = {
  __typename?: 'Task';
  actualTimeToComplete?: Maybe<Scalars['Int']['output']>;
  assignee?: Maybe<Scalars['String']['output']>;
  assigneeGroup?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  completeNotes?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  equipment?: Maybe<Array<Maybe<TaskEquipmentDto>>>;
  estimateTimeToComplete?: Maybe<Scalars['Float']['output']>;
  friendlyID?: Maybe<Scalars['String']['output']>;
  groupBy?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  meterReading?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  nextStatuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  notes?: Maybe<Scalars['String']['output']>;
  repeats?: Maybe<TaskRepeatDto>;
  schedule?: Maybe<TaskScheduleDto>;
  scheduleNotes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userFriendlyID?: Maybe<Scalars['String']['output']>;
};

export type TaskAction = {
  __typename?: 'TaskAction';
  currentState?: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  previousState?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type TaskAuditLog = {
  __typename?: 'TaskAuditLog';
  actions?: Maybe<Array<TaskAction>>;
  date: Scalars['DateTime']['output'];
  userImageUrl?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type TaskEquipmentDto = {
  __typename?: 'TaskEquipmentDTO';
  category?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  suite?: Maybe<Scalars['String']['output']>;
};

export type TaskFilterDto = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroup?: InputMaybe<Scalars['String']['input']>;
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  completedDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  completedDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  dueDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dueDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  equipment?: InputMaybe<Scalars['String']['input']>;
  equipments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<Scalars['String']['input']>;
  schedules?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskFilterOptionDto = {
  __typename?: 'TaskFilterOptionDTO';
  assignee?: Maybe<Array<Maybe<PublicUser>>>;
  equipment?: Maybe<Array<Maybe<IdNamePair>>>;
  locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  schedule?: Maybe<Array<Maybe<IdNamePair>>>;
  status?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TaskPreFilterDto = {
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  assignees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currentUser?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskRepeatDto = {
  __typename?: 'TaskRepeatDTO';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TaskScheduleDto = {
  __typename?: 'TaskScheduleDTO';
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type Tax = {
  __typename?: 'Tax';
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export enum TaxAmountType {
  TaxAmountTypeFixed = 'TAX_AMOUNT_TYPE_FIXED',
  TaxAmountTypePercentage = 'TAX_AMOUNT_TYPE_PERCENTAGE',
  TaxAmountTypeUnknown = 'TAX_AMOUNT_TYPE_UNKNOWN'
}

export type TaxInput = {
  name: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
};

export type Tenant = {
  __typename?: 'Tenant';
  floors?: Maybe<Array<Maybe<Floor>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  suites?: Maybe<Array<Maybe<Unit>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TenantBuildingChannel = {
  __typename?: 'TenantBuildingChannel';
  _id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type TenantLease = Document & {
  __typename?: 'TenantLease';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  externalLeaseId?: Maybe<Scalars['String']['output']>;
  externalSource?: Maybe<Scalars['String']['output']>;
  externalTenantId: Scalars['String']['output'];
  properties?: Maybe<Scalars['JSON']['output']>;
};

export type TenantLeaseInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  externalLeaseId?: InputMaybe<Scalars['String']['input']>;
  externalSource?: InputMaybe<Scalars['String']['input']>;
  externalTenantId: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['JSON']['input']>;
};

export type Theme = Document & {
  __typename?: 'Theme';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel?: Maybe<Channel>;
  /** @deprecated since 5.19.0 - Jan 2020 */
  colors?: Maybe<ThemeColors>;
  layout?: Maybe<LayoutDesignSystem>;
  name?: Maybe<Scalars['String']['output']>;
  palette?: Maybe<ThemePalette>;
  typography?: Maybe<TypographicDesignSystem>;
  user?: Maybe<User>;
};

export type ThemeColors = {
  __typename?: 'ThemeColors';
  custom1?: Maybe<Scalars['Color']['output']>;
  custom2?: Maybe<Scalars['Color']['output']>;
  custom3?: Maybe<Scalars['Color']['output']>;
  primary: Scalars['Color']['output'];
  secondary: Scalars['Color']['output'];
  tertiary: Scalars['Color']['output'];
};

export type ThemeIdInput = {
  _id: Scalars['UUID']['input'];
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ThemeInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  layout?: InputMaybe<LayoutDesignSystemInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  palette?: InputMaybe<ThemePaletteInput>;
  typography?: InputMaybe<TypographicDesignSystemInput>;
  user?: InputMaybe<UserIdInput>;
};

export type ThemePalette = {
  __typename?: 'ThemePalette';
  _id?: Maybe<Scalars['UUID']['output']>;
  background?: Maybe<Scalars['Color']['output']>;
  custom?: Maybe<Array<CustomColorPalette>>;
  /** @deprecated No longer supported */
  inputText?: Maybe<Scalars['Color']['output']>;
  /** @deprecated No longer supported */
  link?: Maybe<Scalars['Color']['output']>;
  /** @deprecated No longer supported */
  logo?: Maybe<Scalars['Color']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['Color']['output']>;
  /** @deprecated No longer supported */
  quaternary?: Maybe<Scalars['Color']['output']>;
  secondary?: Maybe<Scalars['Color']['output']>;
  /** @deprecated No longer supported */
  success?: Maybe<Scalars['Color']['output']>;
  tertiary?: Maybe<Scalars['Color']['output']>;
  text?: Maybe<Scalars['Color']['output']>;
  /** @deprecated No longer supported */
  warning?: Maybe<Scalars['Color']['output']>;
};

export type ThemePaletteInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  background?: InputMaybe<Scalars['Color']['input']>;
  custom?: InputMaybe<Array<CustomColorPaletteInput>>;
  /** @deprecated No longer supported */
  inputText?: InputMaybe<Scalars['Color']['input']>;
  /** @deprecated No longer supported */
  link?: InputMaybe<Scalars['Color']['input']>;
  /** @deprecated No longer supported */
  logo?: InputMaybe<Scalars['Color']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Color']['input']>;
  /** @deprecated No longer supported */
  quaternary?: InputMaybe<Scalars['Color']['input']>;
  secondary?: InputMaybe<Scalars['Color']['input']>;
  /** @deprecated No longer supported */
  success?: InputMaybe<Scalars['Color']['input']>;
  tertiary?: InputMaybe<Scalars['Color']['input']>;
  text?: InputMaybe<Scalars['Color']['input']>;
  /** @deprecated No longer supported */
  warning?: InputMaybe<Scalars['Color']['input']>;
};

export type ThemeSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  user?: InputMaybe<UserSearch>;
};

export type Token = {
  __typename?: 'Token';
  jti: Scalars['UUID']['output'];
  token: Scalars['ID']['output'];
  warnings?: Maybe<Array<Scalars['String']['output']>>;
};

export type Totals = {
  __typename?: 'Totals';
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  totalTax: Scalars['Float']['output'];
};

export type TrackAnalytic = {
  data: Scalars['JSON']['input'];
  event: Scalars['AnalyticType']['input'];
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Float']['output'];
  billingAddress?: Maybe<Address>;
  channelId: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Currency;
  externalEntityId?: Maybe<Scalars['String']['output']>;
  externalEntityType?: Maybe<ExternalEntityType>;
  paidBy: Scalars['String']['output'];
  paymentSource?: Maybe<PaymentSource>;
  paymentType: PaymentMethod;
  productItems?: Maybe<Array<Maybe<ProductItem>>>;
  productName: Scalars['String']['output'];
  status: Scalars['String']['output'];
  taxId?: Maybe<Scalars['String']['output']>;
  transactionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TriggerAccessPointResponse = {
  __typename?: 'TriggerAccessPointResponse';
  message: Scalars['String']['output'];
};

export type TypographicDesignSystem = {
  __typename?: 'TypographicDesignSystem';
  _id?: Maybe<Scalars['UUID']['output']>;
  base?: Maybe<TypographyLayoutElement>;
  emphasis?: Maybe<TypographyLayoutElement>;
  h1?: Maybe<TypographyLayoutElement>;
  h2?: Maybe<TypographyLayoutElement>;
  h3?: Maybe<TypographyLayoutElement>;
  h4?: Maybe<TypographyLayoutElement>;
  h5?: Maybe<TypographyLayoutElement>;
  h6?: Maybe<TypographyLayoutElement>;
  label?: Maybe<TypographyLayoutElement>;
  p?: Maybe<TypographyLayoutElement>;
  quote?: Maybe<TypographyLayoutElement>;
  strong?: Maybe<TypographyLayoutElement>;
};

export type TypographicDesignSystemInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  base?: InputMaybe<TypographyLayoutElementInput>;
  emphasis?: InputMaybe<TypographyLayoutElementInput>;
  h1?: InputMaybe<TypographyLayoutElementInput>;
  h2?: InputMaybe<TypographyLayoutElementInput>;
  h3?: InputMaybe<TypographyLayoutElementInput>;
  h4?: InputMaybe<TypographyLayoutElementInput>;
  h5?: InputMaybe<TypographyLayoutElementInput>;
  h6?: InputMaybe<TypographyLayoutElementInput>;
  label?: InputMaybe<TypographyLayoutElementInput>;
  p?: InputMaybe<TypographyLayoutElementInput>;
  quote?: InputMaybe<TypographyLayoutElementInput>;
  strong?: InputMaybe<TypographyLayoutElementInput>;
};

export type TypographyElement = {
  __typename?: 'TypographyElement';
  _id?: Maybe<Scalars['UUID']['output']>;
  fontFamily?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['String']['output']>;
  fontWeight?: Maybe<Scalars['String']['output']>;
  letterSpacing?: Maybe<Scalars['Int']['output']>;
  lineHeight?: Maybe<Scalars['String']['output']>;
};

export type TypographyElementInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  fontSize?: InputMaybe<Scalars['String']['input']>;
  fontWeight?: InputMaybe<Scalars['String']['input']>;
  letterSpacing?: InputMaybe<Scalars['Int']['input']>;
  lineHeight?: InputMaybe<Scalars['String']['input']>;
};

export type TypographyLayoutElement = {
  __typename?: 'TypographyLayoutElement';
  _id?: Maybe<Scalars['UUID']['output']>;
  fontFamily?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['String']['output']>;
  fontWeight?: Maybe<Scalars['String']['output']>;
  letterSpacing?: Maybe<Scalars['Int']['output']>;
  lineHeight?: Maybe<Scalars['String']['output']>;
  marginBottom?: Maybe<Scalars['String']['output']>;
  marginLeft?: Maybe<Scalars['String']['output']>;
  marginRight?: Maybe<Scalars['String']['output']>;
  marginTop?: Maybe<Scalars['String']['output']>;
  paddingBottom?: Maybe<Scalars['String']['output']>;
  paddingLeft?: Maybe<Scalars['String']['output']>;
  paddingRight?: Maybe<Scalars['String']['output']>;
  paddingTop?: Maybe<Scalars['String']['output']>;
};

export type TypographyLayoutElementInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
  fontSize?: InputMaybe<Scalars['String']['input']>;
  fontWeight?: InputMaybe<Scalars['String']['input']>;
  letterSpacing?: InputMaybe<Scalars['Int']['input']>;
  lineHeight?: InputMaybe<Scalars['String']['input']>;
  marginBottom?: InputMaybe<Scalars['String']['input']>;
  marginLeft?: InputMaybe<Scalars['String']['input']>;
  marginRight?: InputMaybe<Scalars['String']['input']>;
  marginTop?: InputMaybe<Scalars['String']['input']>;
  paddingBottom?: InputMaybe<Scalars['String']['input']>;
  paddingLeft?: InputMaybe<Scalars['String']['input']>;
  paddingRight?: InputMaybe<Scalars['String']['input']>;
  paddingTop?: InputMaybe<Scalars['String']['input']>;
};

export type UuidListSearch = {
  all?: InputMaybe<Array<Scalars['UUID']['input']>>;
  any?: InputMaybe<Array<Scalars['UUID']['input']>>;
  not?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type Unit = {
  __typename?: 'Unit';
  description?: Maybe<Scalars['String']['output']>;
  floors?: Maybe<Array<Maybe<Floor>>>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  people?: Maybe<Array<Maybe<Person>>>;
  property?: Maybe<Property>;
  propertyId: Scalars['UUID']['output'];
  tenants?: Maybe<Array<Maybe<Tenant>>>;
};

export type UnitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  floors?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name: Scalars['String']['input'];
};

export type UnitsForResident = {
  moveInDate: Scalars['DateTime']['input'];
  moveOutDate?: InputMaybe<Scalars['DateTime']['input']>;
  unitIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateAccessPointRequest = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAudienceChannelTargetingRule = {
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  channelId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateAudienceGroupRoleTargetingRule = {
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  groupRoleId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateAudienceInput = {
  channelTargetingRules?: InputMaybe<Array<InputMaybe<UpdateAudienceChannelTargetingRule>>>;
  contentToTarget?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  currentChannelId?: InputMaybe<Scalars['UUID']['input']>;
  groupRoleTargetingRules?: InputMaybe<Array<InputMaybe<UpdateAudienceGroupRoleTargetingRule>>>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  managementChannels?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareChannels?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type UpdateAudienceResponse = {
  __typename?: 'UpdateAudienceResponse';
  archived: Scalars['Boolean']['output'];
  channelTargetingRules: Array<Maybe<TargetingRule>>;
  contentToTarget: Array<Maybe<Scalars['UUID']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['UUID']['output'];
  createdByChannel: Scalars['UUID']['output'];
  groupRoleTargetingRules: Array<Maybe<TargetingRule>>;
  id: Scalars['UUID']['output'];
  managementChannels: Array<Maybe<Scalars['UUID']['output']>>;
  name: Scalars['String']['output'];
  shareChannels: Array<Maybe<Scalars['UUID']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['UUID']['output'];
};

export type UpdateDraftChargeRequest = {
  amount: Scalars['Float']['input'];
  chargeId: Scalars['String']['input'];
  externalActorId: Scalars['String']['input'];
  externalActorType: ExternalActorType;
  items?: InputMaybe<Array<ProductItemInput>>;
};

export type UpdateEquipmentInput = {
  asset?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  hyperlinks?: InputMaybe<Array<InputMaybe<HyperLinkInput>>>;
  id: Scalars['String']['input'];
  installDate?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  meterReadingSettings?: InputMaybe<Array<InputMaybe<MeterReadingSettingInput>>>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  suite?: InputMaybe<Scalars['String']['input']>;
  warrantyExpirationDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Hardware Device Info input type for update */
export type UpdateHardwareDeviceInfoInput = {
  /** App version of hardware device */
  appVersion?: InputMaybe<Scalars['String']['input']>;
  /** Battery Level of hardware device */
  batteryLevel?: InputMaybe<Scalars['String']['input']>;
  /** Model of hardware device */
  model?: InputMaybe<Scalars['String']['input']>;
  /** OS version of hardware device */
  osVersion?: InputMaybe<Scalars['String']['input']>;
  /** ThermalState of hardware device */
  thermalState?: InputMaybe<HardwareThermalState>;
};

export type UpdateHardwareDeviceInfoResponse = {
  __typename?: 'UpdateHardwareDeviceInfoResponse';
  hardwareId?: Maybe<Scalars['ID']['output']>;
};

/** Hardware input type for update */
export type UpdateHardwareInput = {
  /** Hardware Settings json */
  hardwareSettings?: InputMaybe<UpdateHardwareSettingsInput>;
  /** Location details */
  location?: InputMaybe<Scalars['String']['input']>;
  /** Hardware name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Notes for this hardware device */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Hardware status */
  status?: InputMaybe<HardwareStatus>;
};

export type UpdateHardwareSettingsInput = {
  kioskTimeoutInSeconds?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInvoiceAutopaymentDetailsRequest = {
  externalUserId: Scalars['String']['input'];
  externalUserSource: ExternalUserSource;
  paymentMethodId: Scalars['String']['input'];
};

export type UpdateInvoiceAutopaymentDetailsResponse = {
  __typename?: 'UpdateInvoiceAutopaymentDetailsResponse';
  autopaymentId: Scalars['String']['output'];
};

export type UpdateInvoiceToPaidRequest = {
  externalActorId: Scalars['String']['input'];
  externalActorType: ExternalActorType;
  invoiceId: Scalars['String']['input'];
};

export type UpdateInvoiceToPaidResponse = {
  __typename?: 'UpdateInvoiceToPaidResponse';
  invoiceId: Scalars['String']['output'];
};

export type UpdateMeterReadingValueInput = {
  id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type UpdatePmScheduleDto = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  completeWithin?: InputMaybe<Scalars['Int']['input']>;
  daysAhead?: InputMaybe<Scalars['Int']['input']>;
  equipmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  interval?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meterReading?: InputMaybe<Scalars['String']['input']>;
  monthNo?: InputMaybe<Scalars['Int']['input']>;
  nextDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  repeats?: InputMaybe<Scalars['String']['input']>;
  scheduleId?: InputMaybe<Scalars['String']['input']>;
  stepTemplate?: InputMaybe<Array<InputMaybe<StepTemplateDto>>>;
  steps?: InputMaybe<Scalars['String']['input']>;
  timeToComplete?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  untilDate?: InputMaybe<Scalars['DateTime']['input']>;
  weekNo?: InputMaybe<Scalars['Int']['input']>;
  weekday?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePmScheduleResponseDto = {
  __typename?: 'UpdatePMScheduleResponseDTO';
  scheduleId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UpdateProductExceptionRequest = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  group: GroupInput;
  id: Scalars['String']['input'];
  markup?: InputMaybe<MarkupInput>;
  payer: PayerInput;
  productCategoryId?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  tax?: InputMaybe<ProductTaxInput>;
};

export type UpdateProductExceptionResponse = {
  __typename?: 'UpdateProductExceptionResponse';
  amount: Scalars['Float']['output'];
  group: Group;
  id: Scalars['String']['output'];
  markup: Markup;
  payer: Payer;
  productCategory?: Maybe<ProductCategory>;
  products?: Maybe<Array<Maybe<Product>>>;
  tax: ProductTax;
};

export type UpdateProductRequest = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  chargeCodeId?: InputMaybe<Scalars['String']['input']>;
  creditAmount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  group: GroupInput;
  id: Scalars['String']['input'];
  markup?: InputMaybe<MarkupInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  sellingPrice?: InputMaybe<SellingPriceInput>;
  tax?: InputMaybe<ProductTaxInput>;
};

export type UpdateProductResponse = {
  __typename?: 'UpdateProductResponse';
  product: Product;
};

export type UpdateRecurrencePassesResponse = {
  __typename?: 'UpdateRecurrencePassesResponse';
  id: Scalars['String']['output'];
};

export type UpdateRelayModuleInput = {
  buildingId?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['UUID']['input']>;
  lastSeen?: InputMaybe<Scalars['DateTime']['input']>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRelayOutputInput = {
  accessPointId?: InputMaybe<Scalars['UUID']['input']>;
  delay?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pulse?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateServiceRequestDto = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  customQuestions?: InputMaybe<Array<InputMaybe<CustomQuestionInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  equipment?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  extRefId?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  isBillable?: InputMaybe<Scalars['Boolean']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  suite?: InputMaybe<Scalars['String']['input']>;
  suiteIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateTagInput = {
  channelId: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  name_l10n?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateTargetingRuleInput = {
  audienceId: Scalars['UUID']['input'];
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  fromChannel: Scalars['UUID']['input'];
  groupRoleId?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  targetingRuleType: TargetingRuleEntityType;
};

export type UpdateTargetingRuleResponse = {
  __typename?: 'UpdateTargetingRuleResponse';
  audienceId: Scalars['UUID']['output'];
  channelId?: Maybe<Scalars['UUID']['output']>;
  groupRoleId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type UpdateTask = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  assigneeGroup?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskResponseDto = {
  __typename?: 'UpdateTaskResponseDTO';
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UpdateTaskStatus = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskSteps = {
  id?: InputMaybe<Scalars['String']['input']>;
  stepExecution?: InputMaybe<Array<InputMaybe<StepsRequest>>>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type UploadCsvData = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UploadCsvResponse = {
  __typename?: 'UploadCSVResponse';
  fileUrl?: Maybe<Scalars['String']['output']>;
};

export type UpsertSubscriptionDetailsRequest = {
  anyoneCanCancel?: InputMaybe<Scalars['Boolean']['input']>;
  channelId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  recurrenceInterval: RecurrenceInterval;
  subscriptionDetailsId?: InputMaybe<Scalars['String']['input']>;
  totalCounts?: InputMaybe<Scalars['Int']['input']>;
};

export type UpsertSubscriptionDetailsResponse = {
  __typename?: 'UpsertSubscriptionDetailsResponse';
  subscriptionDetailsId: Scalars['String']['output'];
};

export type User = Document & {
  __typename?: 'User';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  addresses?: Maybe<Array<Address>>;
  analytics: ObjectAnalytics;
  companies?: Maybe<Array<Maybe<UserGroupRoleRelatedChannel>>>;
  deviceTokens?: Maybe<Array<DeviceToken>>;
  eventSubscriptions?: Maybe<Array<EventSubscription>>;
  guid?: Maybe<Scalars['UUID']['output']>;
  info?: Maybe<UserObjectInfo>;
  isSuperUser: Scalars['Boolean']['output'];
  lastGeo?: Maybe<Scalars['GeoPoint']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastSeen?: Maybe<Scalars['DateTime']['output']>;
  lastTimeZone?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer required */
  launchDarklyHash?: Maybe<Scalars['String']['output']>;
  libraryPaths?: Maybe<Array<Scalars['Path']['output']>>;
  libraryTags?: Maybe<Array<Scalars['String']['output']>>;
  locale?: Maybe<Scalars['String']['output']>;
  logins?: Maybe<Array<UserLogin>>;
  mobileAccess?: Maybe<MobileAccess>;
  name: Scalars['String']['output'];
  online?: Maybe<Scalars['Boolean']['output']>;
  paymentAccounts?: Maybe<Array<PaymentAccount>>;
  person?: Maybe<Person>;
  primaryLocation?: Maybe<Channel>;
  privateKey: Scalars['String']['output'];
  profile: Profile;
  publicKey: Scalars['String']['output'];
  requestAccountDeletionByDate?: Maybe<Scalars['DateTime']['output']>;
  roles?: Maybe<Array<UserGroupRole>>;
  sessions?: Maybe<Array<Session>>;
  settings?: Maybe<Scalars['JSON']['output']>;
  status: UserStatus;
  /** @deprecated v5.8 Use paymentAccounts now instead */
  stripe?: Maybe<Scalars['ID']['output']>;
};


export type UserMobileAccessArgs = {
  channelId: Scalars['UUID']['input'];
  provider: Scalars['String']['input'];
};


export type UserPaymentAccountsArgs = {
  primaryId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UserAccessCompany = {
  __typename?: 'UserAccessCompany';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserAccessDetails = {
  __typename?: 'UserAccessDetails';
  accessRole: Array<AccessUserRole>;
  accessUsers: Array<AccessUser>;
  companies: Array<UserAccessCompany>;
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  primaryEmail?: Maybe<Scalars['String']['output']>;
};

export type UserBooking = {
  __typename?: 'UserBooking';
  count: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type UserBuildingAccess = {
  __typename?: 'UserBuildingAccess';
  channelId: Scalars['UUID']['output'];
  channelName: Scalars['String']['output'];
  credentials: Array<Credential>;
  mappings: Array<UserBuildingAccessMapping>;
};

export type UserBuildingAccessMapping = {
  __typename?: 'UserBuildingAccessMapping';
  email?: Maybe<Scalars['String']['output']>;
  mappedDate?: Maybe<Scalars['String']['output']>;
  mappingId: Scalars['String']['output'];
  status: UserMappingStatus;
};

export type UserChannelSettings = {
  __typename?: 'UserChannelSettings';
  channelId: Scalars['UUID']['output'];
  created: Scalars['DateTime']['output'];
  createdBy: PublicUser;
  id: Scalars['UUID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  updatedBy: PublicUser;
  userId: Scalars['UUID']['output'];
};

export type UserChannelSettingsInput = {
  _created: Scalars['DateTime']['input'];
  _createdBy: Scalars['UUID']['input'];
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated: Scalars['DateTime']['input'];
  _updatedBy: Scalars['UUID']['input'];
  channelId: Scalars['UUID']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['UUID']['input'];
};

export type UserClearanceInput = {
  accessControlGroups: Array<AccessControlGroupInput>;
  userId: Scalars['UUID']['input'];
};

export type UserCompanyDetails = {
  __typename?: 'UserCompanyDetails';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type UserContentInteraction = Document & {
  __typename?: 'UserContentInteraction';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  actions?: Maybe<Scalars['JSON']['output']>;
  adminNote?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<ObjectAnalytics>;
  authorizedUserIds?: Maybe<Array<Scalars['UUID']['output']>>;
  channel: Channel;
  channelIntegration?: Maybe<ChannelIntegration>;
  content: DraftContent;
  contentData?: Maybe<Scalars['JSON']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  features?: Maybe<Scalars['JSON']['output']>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  info?: Maybe<UserObjectInfo>;
  integrationProvider?: Maybe<Scalars['String']['output']>;
  lastStatus?: Maybe<Scalars['UserContentInteractionStatus']['output']>;
  lastStatusDate?: Maybe<Scalars['DateTime']['output']>;
  moduleData?: Maybe<Scalars['JSON']['output']>;
  paymentAccount?: Maybe<PaymentAccount>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  status: Scalars['UserContentInteractionStatus']['output'];
  statusDate: Scalars['DateTime']['output'];
  user: User;
  version: Scalars['Int']['output'];
};

export type UserContentInteractionHistory = Document & {
  __typename?: 'UserContentInteractionHistory';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  actions?: Maybe<Scalars['JSON']['output']>;
  adminNote?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<ObjectAnalytics>;
  authorizedUserIds?: Maybe<Array<Scalars['UUID']['output']>>;
  channel: Channel;
  channelIntegration?: Maybe<ChannelIntegration>;
  content: DraftContent;
  contentData?: Maybe<Scalars['JSON']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  features?: Maybe<Scalars['JSON']['output']>;
  geo?: Maybe<Scalars['GeoPoint']['output']>;
  info?: Maybe<UserObjectInfo>;
  integrationProvider?: Maybe<Scalars['String']['output']>;
  lastStatus?: Maybe<Scalars['UserContentInteractionStatus']['output']>;
  lastStatusDate?: Maybe<Scalars['DateTime']['output']>;
  paymentAccount?: Maybe<PaymentAccount>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  status: Scalars['UserContentInteractionStatus']['output'];
  statusDate: Scalars['DateTime']['output'];
  user: User;
  version: Scalars['Int']['output'];
};

export type UserContentInteractionHistoryPagination = {
  __typename?: 'UserContentInteractionHistoryPagination';
  items?: Maybe<Array<UserContentInteractionHistory>>;
  pageInfo: PageInfo;
};

/** UserContentInteractionHistory search input type */
export type UserContentInteractionHistorySearch = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  authorizedUserIds?: InputMaybe<UuidListSearch>;
  sortBy?: InputMaybe<SortBy>;
};

export type UserContentInteractionInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  adminNote?: InputMaybe<Scalars['String']['input']>;
  analytics?: InputMaybe<ObjectAnalyticsInput>;
  analyticsMetadata?: InputMaybe<Scalars['JSON']['input']>;
  authorizedUserIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  cancelDate?: InputMaybe<Scalars['DateTime']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  channelMerchant?: InputMaybe<ChannelMerchantIdInput>;
  content?: InputMaybe<ContentInput>;
  contentData?: InputMaybe<Scalars['JSON']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  features?: InputMaybe<Scalars['JSON']['input']>;
  geo?: InputMaybe<Scalars['GeoPoint']['input']>;
  info?: InputMaybe<UserObjectInfoInput>;
  integrationKey?: InputMaybe<Scalars['String']['input']>;
  integrationProvider?: InputMaybe<Scalars['String']['input']>;
  lastStatus?: InputMaybe<Scalars['UserContentInteractionStatus']['input']>;
  lastStatusDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentAccount?: InputMaybe<PaymentAccountIdInput>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<Scalars['UserContentInteractionStatus']['input']>;
  statusDate?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserIdInput>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type UserContentInteractionPagination = {
  __typename?: 'UserContentInteractionPagination';
  items?: Maybe<Array<UserContentInteraction>>;
  pageInfo: PageInfo;
};

export type UserContentInteractionSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  authorizedUserIds?: InputMaybe<UuidListSearch>;
  channel?: InputMaybe<ChannelSearch>;
  channelIntegration?: InputMaybe<ChannelIntegrationSearch>;
  content?: InputMaybe<ContentSearch>;
  endDate?: InputMaybe<DateTimeSearch>;
  geo?: InputMaybe<GeoSearch>;
  integrationProvider?: InputMaybe<StringSearch>;
  paymentAccount?: InputMaybe<PaymentAccountSearch>;
  sortBy?: InputMaybe<SortBy>;
  startDate?: InputMaybe<DateTimeSearch>;
  state?: InputMaybe<UserContentInteractionStateSearch>;
  status?: InputMaybe<StringSearch>;
  user?: InputMaybe<UserSearch>;
  version?: InputMaybe<IntSearch>;
};

export type UserContentInteractionStateSearch = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEmail = {
  __typename?: 'UserEmail';
  email: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserFilters = {
  __typename?: 'UserFilters';
  companies?: Maybe<Array<Maybe<Channel>>>;
  groupRoles?: Maybe<Array<Maybe<GroupRole>>>;
};

/** User Group Role */
export type UserGroupRole = Document & {
  __typename?: 'UserGroupRole';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  description?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['UUID']['output'];
  groupRole: GroupRole;
  isPublic: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  relatedChannels: Array<Maybe<UserGroupRoleRelatedChannel>>;
  settings?: Maybe<Scalars['JSON']['output']>;
  user: User;
};

/** User Group Role input type */
export type UserGroupRoleInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  account?: InputMaybe<UserIdInput>;
  channel?: InputMaybe<ChannelIdInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  groupRole?: InputMaybe<GroupRoleIdInput>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  user?: InputMaybe<UserIdInput>;
};

export type UserGroupRolePagination = {
  __typename?: 'UserGroupRolePagination';
  items?: Maybe<Array<UserGroupRole>>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserGroupRoleRelatedChannel = {
  __typename?: 'UserGroupRoleRelatedChannel';
  _id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type UserGroupRoleSearch = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  expires?: InputMaybe<DateTimeSearch>;
  groupRole?: InputMaybe<GroupRoleSearch>;
  groupRoleId?: InputMaybe<Scalars['UUID']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  user?: InputMaybe<UserSearch>;
};

export type UserIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  addresses?: InputMaybe<Array<InputMaybe<AddressInput>>>;
  analytics?: InputMaybe<ObjectAnalyticsInput>;
  deviceTokens?: InputMaybe<Array<InputMaybe<DeviceTokenInput>>>;
  eventSubscriptions?: InputMaybe<Array<EventSubscriptionInput>>;
  guid?: InputMaybe<Scalars['UUID']['input']>;
  info?: InputMaybe<UserObjectInfoInput>;
  isSuperUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastGeo?: InputMaybe<Scalars['GeoPoint']['input']>;
  lastLogin?: InputMaybe<Scalars['DateTime']['input']>;
  lastSeen?: InputMaybe<Scalars['DateTime']['input']>;
  lastTimeZone?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  logins?: InputMaybe<Array<InputMaybe<UserLoginInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  paymentAccounts?: InputMaybe<Array<PaymentAccountInput>>;
  primaryLocationId?: InputMaybe<Scalars['UUID']['input']>;
  privateKey?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<ProfileInput>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<UserGroupRoleInput>>>;
  sessions?: InputMaybe<Array<SessionInput>>;
  settings?: InputMaybe<UserSettingsInput>;
  status?: InputMaybe<UserStatus>;
  whitelabel?: InputMaybe<WhitelabelInput>;
};

export type UserIntegration = {
  __typename?: 'UserIntegration';
  _created: Scalars['DateTime']['output'];
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  channelIntegrationId: Scalars['UUID']['output'];
  key: Scalars['ID']['output'];
  userId: Scalars['UUID']['output'];
  userKey: Scalars['ID']['output'];
};

export type UserLogin = Document & {
  __typename?: 'UserLogin';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  accessToken?: Maybe<Scalars['String']['output']>;
  autoRefreshToken?: Maybe<Scalars['Boolean']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['ID']['output'];
  provider?: Maybe<UserLoginProvider>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserLoginStatusType>;
  tokenExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  type: UserLoginType;
  user: User;
};

export type UserLoginInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  accessToken?: InputMaybe<Scalars['String']['input']>;
  autoRefreshToken?: InputMaybe<Scalars['Boolean']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['ID']['input']>;
  provider?: InputMaybe<UserLoginProvider>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserLoginStatusType>;
  tokenExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<UserLoginType>;
  user?: InputMaybe<UserIdInput>;
};

export type UserLoginMethod = {
  __typename?: 'UserLoginMethod';
  _id: Scalars['UUID']['output'];
  provider?: Maybe<UserLoginProvider>;
  type: UserLoginType;
};

export enum UserLoginProvider {
  Apple = 'Apple',
  Auth0 = 'Auth0',
  Essensys = 'Essensys',
  GoogleWorkplace = 'GoogleWorkplace',
  LinkedIn = 'LinkedIn',
  Microsoft = 'Microsoft',
  MicrosoftAzure = 'MicrosoftAzure',
  Okta = 'Okta',
  ParkWhiz = 'ParkWhiz',
  ProxyClick = 'ProxyClick',
  Safetrust = 'Safetrust'
}

export type UserLoginSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['ID']['input']>;
  provider?: InputMaybe<UserLoginProvider>;
  searchMode?: InputMaybe<SearchMode>;
  status?: InputMaybe<UserLoginStatusType>;
  type?: InputMaybe<UserLoginType>;
};

export enum UserLoginStatusType {
  Active = 'Active',
  Bounced = 'Bounced',
  Complaint = 'Complaint',
  Disabled = 'Disabled',
  Pending = 'Pending',
  System = 'System',
  Verified = 'Verified'
}

export enum UserLoginType {
  Auth0 = 'Auth0',
  Email = 'Email',
  MagicLink = 'MagicLink',
  OAuth = 'OAuth',
  Phone = 'Phone',
  ShopifyMultipass = 'ShopifyMultipass'
}

export enum UserMappingStatus {
  Unrecognized = 'UNRECOGNIZED',
  UserDeleted = 'USER_DELETED',
  UserMapped = 'USER_MAPPED',
  UserProcessing = 'USER_PROCESSING',
  UserSyncFailed = 'USER_SYNC_FAILED',
  UserUnknown = 'USER_UNKNOWN',
  UserUnmapped = 'USER_UNMAPPED'
}

export type UserObjectInfo = {
  __typename?: 'UserObjectInfo';
  _id: Scalars['UUID']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedOn?: Maybe<Scalars['DateTime']['output']>;
  details?: Maybe<Scalars['Boolean']['output']>;
  detailsOn?: Maybe<Scalars['DateTime']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  hiddenOn?: Maybe<Scalars['DateTime']['output']>;
  liked?: Maybe<Scalars['Boolean']['output']>;
  likedOn?: Maybe<Scalars['DateTime']['output']>;
  pinned?: Maybe<Scalars['Boolean']['output']>;
  pinnedOn?: Maybe<Scalars['DateTime']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  ratingOn?: Maybe<Scalars['DateTime']['output']>;
  shared?: Maybe<Scalars['Boolean']['output']>;
  sharedOn?: Maybe<Scalars['DateTime']['output']>;
  starred?: Maybe<Scalars['Boolean']['output']>;
  starredOn?: Maybe<Scalars['DateTime']['output']>;
  stats?: Maybe<Scalars['JSON']['output']>;
  viewed?: Maybe<Scalars['Boolean']['output']>;
  viewedOn?: Maybe<Scalars['DateTime']['output']>;
};

export type UserObjectInfoInput = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deletedOn?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['Boolean']['input']>;
  detailsOn?: InputMaybe<Scalars['DateTime']['input']>;
  externalId?: InputMaybe<Scalars['UUID']['input']>;
  externalType?: InputMaybe<Scalars['UUID']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenOn?: InputMaybe<Scalars['DateTime']['input']>;
  liked?: InputMaybe<Scalars['Boolean']['input']>;
  likedOn?: InputMaybe<Scalars['DateTime']['input']>;
  pinned?: InputMaybe<Scalars['Boolean']['input']>;
  pinnedOn?: InputMaybe<Scalars['DateTime']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  ratingOn?: InputMaybe<Scalars['DateTime']['input']>;
  shared?: InputMaybe<Scalars['Boolean']['input']>;
  sharedOn?: InputMaybe<Scalars['DateTime']['input']>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  starredOn?: InputMaybe<Scalars['DateTime']['input']>;
  stats?: InputMaybe<Scalars['JSON']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  viewedOn?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserPagination = {
  __typename?: 'UserPagination';
  filters?: Maybe<UserFilters>;
  items?: Maybe<Array<User>>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  groupRoleIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  logins?: InputMaybe<UserLoginSearch>;
  name?: InputMaybe<StringSearch>;
  profile?: InputMaybe<ProfileSearch>;
  search?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  status?: InputMaybe<UserStatus>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  emailOptIn: Scalars['Boolean']['output'];
  previousPaymentAccountId?: Maybe<Scalars['UUID']['output']>;
};

export type UserSettingsInput = {
  emailOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  previousPaymentAccountId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum UserStatus {
  Active = 'Active',
  Disabled = 'Disabled',
  System = 'System'
}

export type UsersByChannelIdSearch = {
  company?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  groupRoleIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  joinedOnOrAfter?: InputMaybe<Scalars['DateTime']['input']>;
  joinedOnOrBefore?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
};

export type Visitor = {
  __typename?: 'Visitor';
  channelId: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  createdAtDatetime: Scalars['DateTime']['output'];
  createdByUserId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAtDatetime: Scalars['DateTime']['output'];
  updatedByUserId?: Maybe<Scalars['String']['output']>;
};

export type VisitorEmailTemplate = {
  __typename?: 'VisitorEmailTemplate';
  backgroundId?: Maybe<Scalars['String']['output']>;
  createdAtDatetime: Scalars['DateTime']['output'];
  createdByUserId: Scalars['String']['output'];
  entryInstructions?: Maybe<Scalars['String']['output']>;
  logoId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  policies?: Maybe<Scalars['String']['output']>;
  qrEnabled?: Maybe<Scalars['Boolean']['output']>;
  templateType: Scalars['String']['output'];
  updatedAtDatetime: Scalars['DateTime']['output'];
  updatedByUserId: Scalars['String']['output'];
};

export type VisitorEmailTemplateInput = {
  backgroundId?: InputMaybe<Scalars['String']['input']>;
  entryInstructions?: InputMaybe<Scalars['String']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  policies?: InputMaybe<Scalars['String']['input']>;
  qrEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  templateType: Scalars['String']['input'];
};

export type VisitorInput = {
  channelId?: InputMaybe<Scalars['UUID']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type VisitorLog = {
  __typename?: 'VisitorLog';
  host: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  visitor: Visitor;
};

export type VisitorOrganizationSettings = {
  __typename?: 'VisitorOrganizationSettings';
  bufferTime?: Maybe<Scalars['Int']['output']>;
  createdAtDatetime: Scalars['DateTime']['output'];
  createdByUserId: Scalars['String']['output'];
  hostEmailOnCancel: Scalars['Boolean']['output'];
  hostEmailOnCheckIn: Scalars['Boolean']['output'];
  hostEmailOnConfirm: Scalars['Boolean']['output'];
  hostEmailOnUpdate: Scalars['Boolean']['output'];
  hostNotificationOnCheckIn: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
  updatedAtDatetime: Scalars['DateTime']['output'];
  updatedByUserId: Scalars['String']['output'];
  visitorEmailOnCancel: Scalars['Boolean']['output'];
  visitorEmailOnCreate: Scalars['Boolean']['output'];
  visitorEmailOnUpdate: Scalars['Boolean']['output'];
  visitorSmsNotificationOnCreate: Scalars['Boolean']['output'];
};

export type VisitorOrganizationSettingsInput = {
  hostEmailOnCancel: Scalars['Boolean']['input'];
  hostEmailOnCheckIn: Scalars['Boolean']['input'];
  hostEmailOnConfirm: Scalars['Boolean']['input'];
  hostEmailOnUpdate: Scalars['Boolean']['input'];
  hostNotificationOnCheckIn: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  visitorEmailOnCancel: Scalars['Boolean']['input'];
  visitorEmailOnCreate: Scalars['Boolean']['input'];
  visitorEmailOnUpdate: Scalars['Boolean']['input'];
  visitorSmsNotificationOnCreate: Scalars['Boolean']['input'];
};

export type VisitorPass = {
  __typename?: 'VisitorPass';
  accessGrantBuildingId: Scalars['String']['output'];
  additionalRecipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checkedInDatetime?: Maybe<Scalars['DateTime']['output']>;
  checkedOutDatetime?: Maybe<Scalars['DateTime']['output']>;
  companyName: Scalars['String']['output'];
  createdAtDatetime: Scalars['DateTime']['output'];
  createdByUserId: Scalars['String']['output'];
  currentVersion: Scalars['Int']['output'];
  endDatetime: Scalars['DateTime']['output'];
  eventName: Scalars['String']['output'];
  floor?: Maybe<Scalars['String']['output']>;
  host?: Maybe<VisitorPassHost>;
  hostName: Scalars['String']['output'];
  hostTenantId: Scalars['String']['output'];
  hostUserId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  recurrenceId?: Maybe<Scalars['String']['output']>;
  sourceId: Scalars['String']['output'];
  staffNote?: Maybe<Scalars['String']['output']>;
  startDatetime: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  submissionId: Scalars['String']['output'];
  submittedBy: Scalars['String']['output'];
  tenant?: Maybe<VisitorPassTenant>;
  tenantName: Scalars['String']['output'];
  updatedAtDatetime: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
  validStateTransitions: Array<Maybe<Scalars['String']['output']>>;
  visitor: Visitor;
  visitorNote?: Maybe<Scalars['String']['output']>;
};

export type VisitorPassAggregatesBySubmissionId = {
  __typename?: 'VisitorPassAggregatesBySubmissionId';
  items?: Maybe<Array<VisitorPass>>;
};

export type VisitorPassHost = {
  __typename?: 'VisitorPassHost';
  _id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<VisitorPassHostProfile>;
};

export type VisitorPassHostProfile = {
  __typename?: 'VisitorPassHostProfile';
  email?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type VisitorPassRecurrence = {
  __typename?: 'VisitorPassRecurrence';
  accessGrantBuildingId: Scalars['String']['output'];
  additionalRecipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAtDatetime?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  hostTenantId: Scalars['String']['output'];
  hostUserId: Scalars['String']['output'];
  lastUpdatedByUserId?: Maybe<Scalars['String']['output']>;
  recurrenceId: Scalars['String']['output'];
  schedule: Scalars['String']['output'];
  scheduleDuration: Scalars['String']['output'];
  scheduleType: Scalars['String']['output'];
  sourceId?: Maybe<Scalars['String']['output']>;
  staffNote?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  submissionId?: Maybe<Scalars['String']['output']>;
  updatedAtDatetime?: Maybe<Scalars['String']['output']>;
  visitorIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visitorNote?: Maybe<Scalars['String']['output']>;
};

export type VisitorPassTenant = {
  __typename?: 'VisitorPassTenant';
  _id: Scalars['UUID']['output'];
  address?: Maybe<VisitorPassTenantAddress>;
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<VisitorPassTenantProfile>;
};

export type VisitorPassTenantAddress = {
  __typename?: 'VisitorPassTenantAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  street3?: Maybe<Scalars['String']['output']>;
};

export type VisitorPassTenantProfile = {
  __typename?: 'VisitorPassTenantProfile';
  image?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
};

export type VisitorPassUpdateResponse = {
  __typename?: 'VisitorPassUpdateResponse';
  id: Scalars['String']['output'];
};

export type VoidChargeRequest = {
  chargeId: Scalars['String']['input'];
  externalActorId: Scalars['String']['input'];
  externalActorType: ExternalActorType;
};

export type VoidChargeResponse = {
  __typename?: 'VoidChargeResponse';
  chargeId: Scalars['String']['output'];
};

export type VtsAddress = {
  __typename?: 'VtsAddress';
  administrativeArea?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type WaitlistUnEnrollment = {
  __typename?: 'WaitlistUnEnrollment';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type Weather = {
  __typename?: 'Weather';
  alerts?: Maybe<Array<WeatherAlert>>;
  currently: WeatherDataPoint;
  flags: WeatherFlags;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  timezone: Scalars['String']['output'];
};

export type WeatherAlert = {
  __typename?: 'WeatherAlert';
  description: Scalars['String']['output'];
  expires: Scalars['DateTime']['output'];
  regions?: Maybe<Array<Scalars['String']['output']>>;
  severity: Scalars['String']['output'];
  time: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  uri: Scalars['URL']['output'];
};

export type WeatherDataPoint = {
  __typename?: 'WeatherDataPoint';
  apparentTemperature: Scalars['Float']['output'];
  cloudCover: Scalars['Float']['output'];
  dewPoint: Scalars['Float']['output'];
  humidity: Scalars['Float']['output'];
  icon: Scalars['String']['output'];
  nearestStormBearing: Scalars['Float']['output'];
  nearestStormDistance: Scalars['Float']['output'];
  ozone: Scalars['Float']['output'];
  precipIntensity: Scalars['Float']['output'];
  precipProbability: Scalars['Float']['output'];
  pressure: Scalars['Float']['output'];
  summary: Scalars['String']['output'];
  temperature: Scalars['Float']['output'];
  time: Scalars['DateTime']['output'];
  uvIndex: Scalars['Float']['output'];
  visibility: Scalars['Float']['output'];
  windBearing: Scalars['Float']['output'];
  windSpeed: Scalars['Float']['output'];
};

export type WeatherFlags = {
  __typename?: 'WeatherFlags';
  units: Scalars['String']['output'];
};

export type Webhook = Document & {
  __typename?: 'Webhook';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel: Channel;
  description?: Maybe<Scalars['String']['output']>;
  events: Array<Scalars['EventType']['output']>;
  name: Scalars['String']['output'];
  signingKey: Scalars['String']['output'];
  status: WebhookStatusEnum;
  url: Scalars['URL']['output'];
  version: Scalars['String']['output'];
};

export type WebhookIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WebhookInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<Array<Scalars['EventType']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  signingKey?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WebhookStatusEnum>;
  url?: InputMaybe<Scalars['URL']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type WebhookSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channel?: InputMaybe<ChannelSearch>;
  description?: InputMaybe<StringSearch>;
  events?: InputMaybe<StringListSearch>;
  name?: InputMaybe<StringSearch>;
  searchMode?: InputMaybe<SearchMode>;
  sortBy?: InputMaybe<SortBy>;
  status?: InputMaybe<StringListInput>;
  url?: InputMaybe<StringSearch>;
  version?: InputMaybe<StringSearch>;
};

export enum WebhookStatusEnum {
  Disabled = 'Disabled',
  Live = 'Live'
}

export type Whitelabel = Document & {
  __typename?: 'Whitelabel';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  address: Address;
  appStoreId: Scalars['String']['output'];
  auth0OrganizationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to channels in 5.30 */
  channel?: Maybe<Channel>;
  channels?: Maybe<Array<WhitelabelChannel>>;
  commonName: Scalars['String']['output'];
  complianceContact?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disableLocationPrecision: Scalars['Boolean']['output'];
  eula: Scalars['URL']['output'];
  hosts?: Maybe<Array<Scalars['String']['output']>>;
  instance: Scalars['String']['output'];
  iosTeamId?: Maybe<Scalars['String']['output']>;
  is2FAEnabled: Scalars['Boolean']['output'];
  isAppleIDEnabled: Scalars['Boolean']['output'];
  isMagicLinkEnabled: Scalars['Boolean']['output'];
  locale?: Maybe<Scalars['SupportedLocale']['output']>;
  mobileAndroid: Scalars['URL']['output'];
  mobileIos: Scalars['URL']['output'];
  name: Scalars['String']['output'];
  oAuthConfig?: Maybe<Array<Maybe<OAuthConfig>>>;
  operatingName: Scalars['String']['output'];
  /** @deprecated Use `privacyPolicyUrls`. */
  privacyPolicy: Scalars['URL']['output'];
  privacyPolicyUrls: Array<Scalars['URL']['output']>;
  profile: Profile;
  restrict: Scalars['Boolean']['output'];
  settings?: Maybe<Scalars['JSON']['output']>;
  sha256Fingerprint?: Maybe<Scalars['String']['output']>;
  shortOperatingName: Scalars['String']['output'];
  /** @deprecated Use `termsAndConditionsUrls`. */
  termsAndConditions: Scalars['URL']['output'];
  termsAndConditionsUrls: Array<Scalars['URL']['output']>;
  /** @deprecated Moved to Profile themes in 5.19 */
  theme?: Maybe<ThemePalette>;
  /** @deprecated Moved to Profile themes in 5.19 */
  typography?: Maybe<Scalars['JSON']['output']>;
  url: Scalars['URL']['output'];
  urlDevelopment: Scalars['URL']['output'];
  urlStaging: Scalars['URL']['output'];
};

export type WhitelabelChannel = Document & {
  __typename?: 'WhitelabelChannel';
  _created: Scalars['DateTime']['output'];
  _createdBy: PublicUser;
  _id: Scalars['UUID']['output'];
  _updated: Scalars['DateTime']['output'];
  _updatedBy: PublicUser;
  channel: Channel;
  isPrimary: Scalars['Boolean']['output'];
  whitelabel: Whitelabel;
};

export type WhitelabelChannelInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  channel?: InputMaybe<ChannelIdInput>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  whitelabel?: InputMaybe<WhitelabelIdInput>;
};

export type WhitelabelChannelSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  channels?: InputMaybe<ChannelSearch>;
  whitelabels?: InputMaybe<WhitelabelSearch>;
};

export type WhitelabelIdInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _pull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WhitelabelInput = {
  _created?: InputMaybe<Scalars['DateTime']['input']>;
  _createdBy?: InputMaybe<Scalars['UUID']['input']>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<Scalars['DateTime']['input']>;
  _updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  address?: InputMaybe<AddressInput>;
  appStoreId?: InputMaybe<Scalars['String']['input']>;
  auth0OrganizationId?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<Array<InputMaybe<WhitelabelChannelInput>>>;
  commonName?: InputMaybe<Scalars['String']['input']>;
  complianceContact?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disableLocationPrecision?: InputMaybe<Scalars['Boolean']['input']>;
  eula?: InputMaybe<Scalars['URL']['input']>;
  hosts?: InputMaybe<Array<Scalars['String']['input']>>;
  instance?: InputMaybe<Scalars['String']['input']>;
  is2FAEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isAppleIDEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isMagicLinkEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['SupportedLocale']['input']>;
  mobileAndroid?: InputMaybe<Scalars['URL']['input']>;
  mobileIos?: InputMaybe<Scalars['URL']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oAuthConfig?: InputMaybe<Array<InputMaybe<OAuthConfigInput>>>;
  operatingName?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated use privacyPolicyUrls */
  privacyPolicy?: InputMaybe<Scalars['URL']['input']>;
  privacyPolicyUrls?: InputMaybe<Array<Scalars['URL']['input']>>;
  profile?: InputMaybe<ProfileInput>;
  restrict?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<WhitelabelSettingsInput>;
  sha256Fingerprint?: InputMaybe<Scalars['String']['input']>;
  shortOperatingName?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated use termsAndConditionsUrls */
  termsAndConditions?: InputMaybe<Scalars['URL']['input']>;
  termsAndConditionsUrls?: InputMaybe<Array<Scalars['URL']['input']>>;
  theme?: InputMaybe<ThemePaletteInput>;
  typography?: InputMaybe<Scalars['JSON']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
  urlDevelopment?: InputMaybe<Scalars['URL']['input']>;
  urlStaging?: InputMaybe<Scalars['URL']['input']>;
};

export type WhitelabelSearch = {
  _created?: InputMaybe<DateTimeSearch>;
  _id?: InputMaybe<Scalars['UUID']['input']>;
  _updated?: InputMaybe<DateTimeSearch>;
  address?: InputMaybe<AddressSearch>;
  channels?: InputMaybe<WhitelabelChannelSearch>;
  commonName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<StringSearch>;
  email?: InputMaybe<Scalars['String']['input']>;
  instance?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringSearch>;
  profile?: InputMaybe<ProfileSearch>;
  restrict?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type WhitelabelSettingsInput = {
  allowSignUps?: InputMaybe<Scalars['Boolean']['input']>;
  emailSubscriptionOptIn?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum WorkOrderStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Declined = 'Declined',
  Delayed = 'Delayed',
  In = 'In',
  Open = 'Open',
  Progress = 'Progress'
}

export type Workflow = {
  __typename?: 'Workflow';
  _created: Scalars['DateTime']['output'];
  _createdBy: Scalars['String']['output'];
  _id: Scalars['UUID']['output'];
  _order?: Maybe<Scalars['Int']['output']>;
  _updated: Scalars['DateTime']['output'];
  _updatedBy: Scalars['String']['output'];
  action: WorkflowActionType;
  channelId?: Maybe<Scalars['String']['output']>;
  dataValidationSchema?: Maybe<Scalars['JSON']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  event: Scalars['EventType']['output'];
  inStatus?: Maybe<Array<Scalars['UserContentInteractionStatus']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  target?: Maybe<WorkflowTargetType>;
  targetType?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['BigInt']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  when: WorkflowWhenType;
  whenContext: WorkflowWhenContextType;
  workflow?: Maybe<Scalars['JSON']['output']>;
};

export enum WorkflowActionType {
  Content = 'Content',
  Email = 'Email',
  GroupRole = 'GroupRole',
  Notification = 'Notification',
  NotificationAndEmail = 'NotificationAndEmail',
  Sms = 'SMS',
  Webhook = 'Webhook'
}

export type WorkflowInput = {
  action: Scalars['String']['input'];
  channelId: Scalars['UUID']['input'];
  createdBy?: InputMaybe<Scalars['UUID']['input']>;
  dataValidationSchema?: InputMaybe<Scalars['JSON']['input']>;
  event: Scalars['String']['input'];
  inStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  payload?: InputMaybe<Scalars['JSON']['input']>;
  target: Scalars['String']['input'];
  targetType: Scalars['String']['input'];
  time?: InputMaybe<Scalars['BigInt']['input']>;
  type: WorkflowType;
  updatedBy?: InputMaybe<Scalars['UUID']['input']>;
  when: Scalars['String']['input'];
  whenContext: Scalars['String']['input'];
  workflow?: InputMaybe<Scalars['JSON']['input']>;
};

export enum WorkflowTargetType {
  All = 'All',
  Creator = 'Creator',
  GroupRole = 'GroupRole',
  GuestInviteFeature = 'GuestInviteFeature',
  Owner = 'Owner',
  SelectUser = 'SelectUser',
  User = 'User',
  Webhook = 'Webhook',
  WorkOrderAdmin = 'WorkOrderAdmin',
  WorkOrderCreator = 'WorkOrderCreator',
  WorkOrderTeam = 'WorkOrderTeam'
}

export enum WorkflowType {
  ServiceRequest = 'ServiceRequest',
  ServiceRequestEscalation = 'ServiceRequestEscalation',
  Task = 'Task'
}

export enum WorkflowWhenContextType {
  Content = 'Content',
  Escalation = 'Escalation',
  ServiceRequest = 'ServiceRequest',
  Task = 'Task',
  UserContentInteraction = 'UserContentInteraction'
}

export enum WorkflowWhenType {
  After = 'After',
  Before = 'Before',
  Immediate = 'Immediate'
}

export type EnableMobileAccessResponse = {
  __typename?: 'enableMobileAccessResponse';
  status: Scalars['String']['output'];
  userId: Scalars['UUID']['output'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;


/** Mapping of interface types */
export type ResolversInterfaceTypes<RefType extends Record<string, unknown>> = {
  ContentInterface: ( Content );
  Document: ( Address ) | ( ApiKey ) | ( Block ) | ( Building ) | ( Channel ) | ( ChannelContract ) | ( ChannelContractLineItem ) | ( ChannelIntegration ) | ( ChannelInvite ) | ( ChannelMerchant ) | ( ChannelModule ) | ( ChannelPage ) | ( ChannelRelationship ) | ( ClaimedChannelInvite ) | ( ContentFeature ) | ( ContentGenerator ) | ( ContentNotification ) | ( ContentPlacement ) | ( ContentTemplate ) | ( ContentWorkflow ) | ( DatasetRecord ) | ( DatasetSchema ) | ( DeviceToken ) | ( DraftContent ) | ( DraftContentNotification ) | ( DraftContentWorkflow ) | ( DraftUserContentInteraction ) | ( EventSubscription ) | ( FeedbackForm ) | ( GroupRole ) | ( Integration ) | ( Library ) | ( LibraryAccess ) | ( LibraryItem ) | ( Media ) | ( Metatag ) | ( MetatagValue ) | ( ObjectDatasetRecord ) | ( ObjectMetatag ) | ( ObjectMetatagValue ) | ( PaymentAccount ) | ( PaymentProcessor ) | ( Profile ) | ( Section ) | ( SectionContent ) | ( Suite ) | ( TenantLease ) | ( Theme ) | ( User ) | ( UserContentInteraction ) | ( UserContentInteractionHistory ) | ( UserGroupRole ) | ( UserLogin ) | ( Webhook ) | ( Whitelabel ) | ( WhitelabelChannel );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccessControlChannelType: ResolverTypeWrapper<AccessControlChannelType>;
  AccessControlGroup: ResolverTypeWrapper<AccessControlGroup>;
  AccessControlGroupInput: AccessControlGroupInput;
  AccessControlGroups: ResolverTypeWrapper<AccessControlGroups>;
  AccessControlGroupsForInvite: AccessControlGroupsForInvite;
  AccessControlGroupsMapInput: AccessControlGroupsMapInput;
  AccessControlProfileType: ResolverTypeWrapper<AccessControlProfileType>;
  AccessIntegration: ResolverTypeWrapper<AccessIntegration>;
  AccessLocation: ResolverTypeWrapper<AccessLocation>;
  AccessPoint: ResolverTypeWrapper<AccessPoint>;
  AccessUser: ResolverTypeWrapper<AccessUser>;
  AccessUserChannel: ResolverTypeWrapper<AccessUserChannel>;
  AccessUserControlGroups: ResolverTypeWrapper<AccessUserControlGroups>;
  AccessUserRole: ResolverTypeWrapper<AccessUserRole>;
  AccountContactPair: ResolverTypeWrapper<AccountContactPair>;
  AccountsMutations: ResolverTypeWrapper<AccountsMutations>;
  AccountsQueries: ResolverTypeWrapper<AccountsQueries>;
  ActivateType: ActivateType;
  ActivateUser: ResolverTypeWrapper<ActivateUser>;
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  AddressSearch: AddressSearch;
  AdminUserSearch: AdminUserSearch;
  AllowedFeatures: ResolverTypeWrapper<AllowedFeatures>;
  AnalyticType: ResolverTypeWrapper<Scalars['AnalyticType']['output']>;
  AngusBuilding: ResolverTypeWrapper<AngusBuilding>;
  AngusFloor: ResolverTypeWrapper<AngusFloor>;
  AngusLocation: ResolverTypeWrapper<AngusLocation>;
  AngusRequestType: ResolverTypeWrapper<AngusRequestType>;
  AngusRequester: ResolverTypeWrapper<AngusRequester>;
  AngusSuite: ResolverTypeWrapper<AngusSuite>;
  AngusTenant: ResolverTypeWrapper<AngusTenant>;
  AngusWorkOrder: ResolverTypeWrapper<AngusWorkOrder>;
  AngusWorkOrderConfirmation: ResolverTypeWrapper<AngusWorkOrderConfirmation>;
  ApiKey: ResolverTypeWrapper<ApiKey>;
  ApiKeyCreateInput: ApiKeyCreateInput;
  ApiKeyDisableInput: ApiKeyDisableInput;
  ApiKeyInput: ApiKeyInput;
  ApiKeyStatusEnum: ApiKeyStatusEnum;
  AppleWalletIssuanceToken: ResolverTypeWrapper<AppleWalletIssuanceToken>;
  Attachment: ResolverTypeWrapper<Attachment>;
  AttachmentInput: AttachmentInput;
  Audience: ResolverTypeWrapper<Audience>;
  AudienceIdInput: AudienceIdInput;
  AudiencePagination: ResolverTypeWrapper<AudiencePagination>;
  AudienceSearch: AudienceSearch;
  AudiencesToAttachContentTo: ResolverTypeWrapper<AudiencesToAttachContentTo>;
  AudiencesToAttachContentToInput: AudiencesToAttachContentToInput;
  Auth0Connection: ResolverTypeWrapper<Auth0Connection>;
  Auth0DomainHomeRealmDiscovery: ResolverTypeWrapper<Auth0DomainHomeRealmDiscovery>;
  Auth0MembershipInvitation: ResolverTypeWrapper<Auth0MembershipInvitation>;
  Auth0Organization: ResolverTypeWrapper<Auth0Organization>;
  Auth0ProvisionUserToOrganization: ResolverTypeWrapper<Auth0ProvisionUserToOrganization>;
  Auth0User: ResolverTypeWrapper<Auth0User>;
  AutoSetupCesEntityRequest: AutoSetupCesEntityRequest;
  AutoSetupCesEntityResponse: ResolverTypeWrapper<AutoSetupCesEntityResponse>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']['output']>;
  BiltSso: ResolverTypeWrapper<BiltSso>;
  Binary: ResolverTypeWrapper<Scalars['Binary']['output']>;
  Block: ResolverTypeWrapper<Block>;
  BlockForType: BlockForType;
  BlockIdInput: BlockIdInput;
  BlockInput: BlockInput;
  BlockSearch: BlockSearch;
  BlockType: BlockType;
  BluetoothInvitationCode: ResolverTypeWrapper<BluetoothInvitationCode>;
  BookingCompany: ResolverTypeWrapper<BookingCompany>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Building: ResolverTypeWrapper<Building>;
  BuildingInput: BuildingInput;
  BuildingTenantChannel: ResolverTypeWrapper<BuildingTenantChannel>;
  Byte: ResolverTypeWrapper<Scalars['Byte']['output']>;
  CancelRecurringChargeRequest: CancelRecurringChargeRequest;
  CancelRecurringChargeResponse: ResolverTypeWrapper<CancelRecurringChargeResponse>;
  CancelSubmissionResponse: ResolverTypeWrapper<CancelSubmissionResponse>;
  Category: ResolverTypeWrapper<Category>;
  CategoryUpsertRequestDTO: CategoryUpsertRequestDto;
  CategoryUpsertResponseDTO: ResolverTypeWrapper<CategoryUpsertResponseDto>;
  Channel: ResolverTypeWrapper<Channel>;
  ChannelAccessIntegration: ResolverTypeWrapper<ChannelAccessIntegration>;
  ChannelContract: ResolverTypeWrapper<ChannelContract>;
  ChannelContractIdInput: ChannelContractIdInput;
  ChannelContractInput: ChannelContractInput;
  ChannelContractLineItem: ResolverTypeWrapper<ChannelContractLineItem>;
  ChannelContractLineItemIdInput: ChannelContractLineItemIdInput;
  ChannelContractLineItemInput: ChannelContractLineItemInput;
  ChannelContractLineItemSearch: ChannelContractLineItemSearch;
  ChannelContractPagination: ResolverTypeWrapper<ChannelContractPagination>;
  ChannelContractSearch: ChannelContractSearch;
  ChannelEquipmentSettings: ResolverTypeWrapper<ChannelEquipmentSettings>;
  ChannelEquipmentSettingsInput: ChannelEquipmentSettingsInput;
  ChannelExperienceTypeEnum: ChannelExperienceTypeEnum;
  ChannelGroupRoleInput: ChannelGroupRoleInput;
  ChannelHierarchy: ResolverTypeWrapper<ChannelHierarchy>;
  ChannelIdInput: ChannelIdInput;
  ChannelInput: ChannelInput;
  ChannelIntegration: ResolverTypeWrapper<ChannelIntegration>;
  ChannelIntegrationIdInput: ChannelIntegrationIdInput;
  ChannelIntegrationInput: ChannelIntegrationInput;
  ChannelIntegrationSearch: ChannelIntegrationSearch;
  ChannelInvite: ResolverTypeWrapper<ChannelInvite>;
  ChannelInviteInput: ChannelInviteInput;
  ChannelInvitePagination: ResolverTypeWrapper<ChannelInvitePagination>;
  ChannelInviteSearch: ChannelInviteSearch;
  ChannelInviteStatusType: ChannelInviteStatusType;
  ChannelMerchant: ResolverTypeWrapper<ChannelMerchant>;
  ChannelMerchantIdInput: ChannelMerchantIdInput;
  ChannelMerchantInput: ChannelMerchantInput;
  ChannelMerchantSearch: ChannelMerchantSearch;
  ChannelModule: ResolverTypeWrapper<ChannelModule>;
  ChannelModuleInput: ChannelModuleInput;
  ChannelPage: ResolverTypeWrapper<ChannelPage>;
  ChannelPageInput: ChannelPageInput;
  ChannelPagination: ResolverTypeWrapper<ChannelPagination>;
  ChannelPermissionSearch: ChannelPermissionSearch;
  ChannelRelationship: ResolverTypeWrapper<ChannelRelationship>;
  ChannelRelationshipInput: ChannelRelationshipInput;
  ChannelRelationshipPagination: ResolverTypeWrapper<ChannelRelationshipPagination>;
  ChannelRelationshipSearch: ChannelRelationshipSearch;
  ChannelRelationshipType: ChannelRelationshipType;
  ChannelSearch: ChannelSearch;
  ChannelSettings: ResolverTypeWrapper<ChannelSettings>;
  ChannelSettingsInput: ChannelSettingsInput;
  ChannelStats: ResolverTypeWrapper<ChannelStats>;
  ChannelStatsInput: ChannelStatsInput;
  ChannelType: ChannelType;
  Charge: ResolverTypeWrapper<Charge>;
  ChargeBillingReport: ResolverTypeWrapper<ChargeBillingReport>;
  ChargeBillingReportsResponse: ResolverTypeWrapper<ChargeBillingReportsResponse>;
  ChargeCode: ResolverTypeWrapper<ChargeCode>;
  ChargeCodeSource: ChargeCodeSource;
  ChargeReportFilters: ChargeReportFilters;
  ChargeTemplate: ResolverTypeWrapper<ChargeTemplate>;
  ChargesFilters: ChargesFilters;
  ClaimedChannelInvite: ResolverTypeWrapper<ClaimedChannelInvite>;
  ClaimedChannelInviteInput: ClaimedChannelInviteInput;
  ClaimedChannelInviteSearch: ClaimedChannelInviteSearch;
  ClientPMSchedule: ResolverTypeWrapper<ClientPmSchedule>;
  ClientTask: ResolverTypeWrapper<ClientTask>;
  CloneChannelOptionsInput: CloneChannelOptionsInput;
  Color: ResolverTypeWrapper<Scalars['Color']['output']>;
  CompleteSignupData: ResolverTypeWrapper<CompleteSignupData>;
  CompleteTask: CompleteTask;
  CompleteTaskResponseDTO: ResolverTypeWrapper<CompleteTaskResponseDto>;
  Configuration: ResolverTypeWrapper<Configuration>;
  Content: ResolverTypeWrapper<Content>;
  ContentAccessType: ContentAccessType;
  ContentCategoryType: ResolverTypeWrapper<Scalars['ContentCategoryType']['output']>;
  ContentDateRanges: ResolverTypeWrapper<ContentDateRanges>;
  ContentFeature: ResolverTypeWrapper<ContentFeature>;
  ContentFeatureInput: ContentFeatureInput;
  ContentFeatureStatusSummary: ResolverTypeWrapper<ContentFeatureStatusSummary>;
  ContentFilterType: ContentFilterType;
  ContentGenerator: ResolverTypeWrapper<ContentGenerator>;
  ContentGeneratorInput: ContentGeneratorInput;
  ContentGroupByType: ContentGroupByType;
  ContentIdInput: ContentIdInput;
  ContentInput: ContentInput;
  ContentInterface: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['ContentInterface']>;
  ContentNotification: ResolverTypeWrapper<ContentNotification>;
  ContentNotificationInput: ContentNotificationInput;
  ContentPagination: ResolverTypeWrapper<ContentPagination>;
  ContentPlacement: ResolverTypeWrapper<ContentPlacement>;
  ContentPlacementInput: ContentPlacementInput;
  ContentQuantityAllotments: ResolverTypeWrapper<ContentQuantityAllotments>;
  ContentSearch: ContentSearch;
  ContentSortType: ContentSortType;
  ContentTemplate: ResolverTypeWrapper<ContentTemplate>;
  ContentTemplateInput: ContentTemplateInput;
  ContentTemplatePagination: ResolverTypeWrapper<ContentTemplatePagination>;
  ContentTemplateSearch: ContentTemplateSearch;
  ContentType: ContentType;
  ContentWaitlistEnrollment: ResolverTypeWrapper<ContentWaitlistEnrollment>;
  ContentWorkflow: ResolverTypeWrapper<ContentWorkflow>;
  ContentWorkflowInput: ContentWorkflowInput;
  ContractUnitType: ContractUnitType;
  CreateAccessPointRequest: CreateAccessPointRequest;
  CreateAudienceChannelTargetingRule: CreateAudienceChannelTargetingRule;
  CreateAudienceGroupRoleTargetingRule: CreateAudienceGroupRoleTargetingRule;
  CreateAudienceInput: CreateAudienceInput;
  CreateAudienceResponse: ResolverTypeWrapper<CreateAudienceResponse>;
  CreateAutoSetupDTO: CreateAutoSetupDto;
  CreateChargeCodeRequest: CreateChargeCodeRequest;
  CreateChargeCodeResponse: ResolverTypeWrapper<CreateChargeCodeResponse>;
  CreateChargeRequest: CreateChargeRequest;
  CreateChargeResponse: ResolverTypeWrapper<CreateChargeResponse>;
  CreateFloorInput: CreateFloorInput;
  CreatePMScheduleDTO: CreatePmScheduleDto;
  CreatePaymentAccountOptionsInput: CreatePaymentAccountOptionsInput;
  CreateProductCategoryRequest: CreateProductCategoryRequest;
  CreateProductCategoryResponse: ResolverTypeWrapper<CreateProductCategoryResponse>;
  CreateProductExceptionRequest: CreateProductExceptionRequest;
  CreateProductExceptionResponse: ResolverTypeWrapper<CreateProductExceptionResponse>;
  CreateProductRequest: CreateProductRequest;
  CreateProductResponse: ResolverTypeWrapper<CreateProductResponse>;
  CreateRecurringChargeRequest: CreateRecurringChargeRequest;
  CreateRecurringChargeResponse: ResolverTypeWrapper<CreateRecurringChargeResponse>;
  CreateServiceRequestDTO: CreateServiceRequestDto;
  CreateTargetingRuleInput: CreateTargetingRuleInput;
  CreateTargetingRuleResponse: ResolverTypeWrapper<CreateTargetingRuleResponse>;
  CreateTask: CreateTask;
  CreateTransactionRequest: CreateTransactionRequest;
  CreateTransactionResponse: ResolverTypeWrapper<CreateTransactionResponse>;
  CreateUserRequest: CreateUserRequest;
  CreateUserResponse: ResolverTypeWrapper<CreateUserResponse>;
  Credential: ResolverTypeWrapper<Credential>;
  CredentialGroups: ResolverTypeWrapper<CredentialGroups>;
  CredentialStatus: CredentialStatus;
  CredentialType: CredentialType;
  CreditBundle: ResolverTypeWrapper<CreditBundle>;
  CreditBundleInput: CreditBundleInput;
  CreditCard: ResolverTypeWrapper<CreditCard>;
  CreditWallet: ResolverTypeWrapper<CreditWallet>;
  CreditWalletChannelProfile: ResolverTypeWrapper<CreditWalletChannelProfile>;
  Currency: Currency;
  CurrencyDetail: ResolverTypeWrapper<CurrencyDetail>;
  CursorPageInfo: ResolverTypeWrapper<CursorPageInfo>;
  CustomColorPalette: ResolverTypeWrapper<CustomColorPalette>;
  CustomColorPaletteInput: CustomColorPaletteInput;
  CustomQuestion: ResolverTypeWrapper<CustomQuestion>;
  CustomQuestionInput: CustomQuestionInput;
  DataField: ResolverTypeWrapper<DataField>;
  DataFieldInput: DataFieldInput;
  DatasetAutomationInput: DatasetAutomationInput;
  DatasetRecord: ResolverTypeWrapper<DatasetRecord>;
  DatasetRecordInput: DatasetRecordInput;
  DatasetRecordPagination: ResolverTypeWrapper<DatasetRecordPagination>;
  DatasetRecordSearch: DatasetRecordSearch;
  DatasetRecordUpdateInput: DatasetRecordUpdateInput;
  DatasetSchema: ResolverTypeWrapper<DatasetSchema>;
  DatasetSchemaIdInput: DatasetSchemaIdInput;
  DatasetSchemaInput: DatasetSchemaInput;
  DatasetSchemaSearch: DatasetSchemaSearch;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateRange: ResolverTypeWrapper<DateRange>;
  DateRangeInput: DateRangeInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DateTimeSearch: DateTimeSearch;
  DeepLink: ResolverTypeWrapper<DeepLink>;
  DeepLinkInput: DeepLinkInput;
  DeleteDraftChargeRequest: DeleteDraftChargeRequest;
  DeleteInvoiceAutopaymentRequest: DeleteInvoiceAutopaymentRequest;
  DeleteProductExceptionRequest: DeleteProductExceptionRequest;
  DeleteProductRequest: DeleteProductRequest;
  DeleteTargetingRuleInput: DeleteTargetingRuleInput;
  DeviceToken: ResolverTypeWrapper<DeviceToken>;
  DeviceTokenInput: DeviceTokenInput;
  DeviceTokenSearch: DeviceTokenSearch;
  DeviceTokenType: DeviceTokenType;
  DisplayMetadata: ResolverTypeWrapper<DisplayMetadata>;
  Document: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Document']>;
  DraftContent: ResolverTypeWrapper<DraftContent>;
  DraftContentHistory: ResolverTypeWrapper<DraftContentHistory>;
  DraftContentHistoryPagination: ResolverTypeWrapper<DraftContentHistoryPagination>;
  DraftContentHistorySearch: DraftContentHistorySearch;
  DraftContentIdInput: DraftContentIdInput;
  DraftContentInput: DraftContentInput;
  DraftContentNotification: ResolverTypeWrapper<DraftContentNotification>;
  DraftContentPagination: ResolverTypeWrapper<DraftContentPagination>;
  DraftContentSearch: DraftContentSearch;
  DraftContentWorkflow: ResolverTypeWrapper<DraftContentWorkflow>;
  DraftUserContentInteraction: ResolverTypeWrapper<DraftUserContentInteraction>;
  DraftUserContentInteractionInput: DraftUserContentInteractionInput;
  DraftUserContentInteractionSearch: DraftUserContentInteractionSearch;
  DynamicSectionInfo: ResolverTypeWrapper<DynamicSectionInfo>;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']['output']>;
  Entity: ResolverTypeWrapper<Entity>;
  Equipment: ResolverTypeWrapper<Equipment>;
  EquipmentForExport: ResolverTypeWrapper<EquipmentForExport>;
  EquipmentInput: EquipmentInput;
  EquipmentSearch: EquipmentSearch;
  EquipmentSearchResults: ResolverTypeWrapper<EquipmentSearchResults>;
  EscalationRule: ResolverTypeWrapper<EscalationRule>;
  Essensys: ResolverTypeWrapper<Essensys>;
  EssensysAccount: ResolverTypeWrapper<EssensysAccount>;
  EssensysCalendar: ResolverTypeWrapper<EssensysCalendar>;
  EssensysContact: ResolverTypeWrapper<EssensysContact>;
  EssensysInvoice: ResolverTypeWrapper<EssensysInvoice>;
  EssensysMutation: ResolverTypeWrapper<EssensysMutation>;
  EssensysPayment: ResolverTypeWrapper<EssensysPayment>;
  EssensysPaymentProcessor: ResolverTypeWrapper<EssensysPaymentProcessor>;
  EssensysPaymentSettings: ResolverTypeWrapper<EssensysPaymentSettings>;
  EssensysProduct: ResolverTypeWrapper<EssensysProduct>;
  EventDetail: ResolverTypeWrapper<EventDetail>;
  EventSubscription: ResolverTypeWrapper<EventSubscription>;
  EventSubscriptionInput: EventSubscriptionInput;
  EventType: ResolverTypeWrapper<Scalars['EventType']['output']>;
  Exception: ResolverTypeWrapper<Exception>;
  ExpiryType: ExpiryType;
  ExportCSVTasksReponseDTO: ResolverTypeWrapper<ExportCsvTasksReponseDto>;
  ExternalActorType: ExternalActorType;
  ExternalEntityType: ExternalEntityType;
  ExternalPayerType: ExternalPayerType;
  ExternalType: ExternalType;
  ExternalUrl: ResolverTypeWrapper<ExternalUrl>;
  ExternalUrlInput: ExternalUrlInput;
  ExternalUrlOpeningMode: ExternalUrlOpeningMode;
  ExternalUserSource: ExternalUserSource;
  FeatureName: FeatureName;
  Features: ResolverTypeWrapper<Features>;
  FeedbackForm: ResolverTypeWrapper<FeedbackForm>;
  FeedbackFormInput: FeedbackFormInput;
  FindSchedulesFilter: FindSchedulesFilter;
  FindSchedulesFilterOptions: ResolverTypeWrapper<FindSchedulesFilterOptions>;
  FindSchedulesReponseDTO: ResolverTypeWrapper<FindSchedulesReponseDto>;
  FindSchedulesSearch: FindSchedulesSearch;
  FindTasksSearch: FindTasksSearch;
  FlatfilePortal: FlatfilePortal;
  FlatfileToken: ResolverTypeWrapper<FlatfileToken>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FloatSearch: FloatSearch;
  Floor: ResolverTypeWrapper<Floor>;
  FloorInput: FloorInput;
  FloorMapGenerationStatus: ResolverTypeWrapper<FloorMapGenerationStatus>;
  GeoPoint: ResolverTypeWrapper<Scalars['GeoPoint']['output']>;
  GeoSearch: GeoSearch;
  GetConfigRequest: GetConfigRequest;
  GetConfigResponse: ResolverTypeWrapper<GetConfigResponse>;
  GetFinalPriceForProductsRequest: GetFinalPriceForProductsRequest;
  GetFinalPriceForProductsResponse: ResolverTypeWrapper<GetFinalPriceForProductsResponse>;
  GetGroupSettingRequest: GetGroupSettingRequest;
  GetIntegrationProcessesInput: GetIntegrationProcessesInput;
  GetIntegrationProcessesResponse: ResolverTypeWrapper<GetIntegrationProcessesResponse>;
  GetInvoiceDetailsRequest: GetInvoiceDetailsRequest;
  GetInvoiceDetailsResponse: ResolverTypeWrapper<GetInvoiceDetailsResponse>;
  GetProductExceptionRequest: GetProductExceptionRequest;
  GetProductExceptionResponse: ResolverTypeWrapper<GetProductExceptionResponse>;
  GetProductRequest: GetProductRequest;
  GetProductResponse: ResolverTypeWrapper<GetProductResponse>;
  GetRecurringChargeRequest: GetRecurringChargeRequest;
  GetRecurringChargeResponse: ResolverTypeWrapper<GetRecurringChargeResponse>;
  GetRelayOutputList: ResolverTypeWrapper<GetRelayOutputList>;
  GetTransactionStatusRequest: GetTransactionStatusRequest;
  GetTransactionStatusResponse: ResolverTypeWrapper<GetTransactionStatusResponse>;
  GetUserByExternalIdRequest: GetUserByExternalIdRequest;
  GetUserByExternalIdResponse: ResolverTypeWrapper<GetUserByExternalIdResponse>;
  GetUserCreditWalletsRequest: GetUserCreditWalletsRequest;
  GetUserCreditWalletsResponse: ResolverTypeWrapper<GetUserCreditWalletsResponse>;
  Group: ResolverTypeWrapper<Group>;
  GroupInput: GroupInput;
  GroupRole: ResolverTypeWrapper<GroupRole>;
  GroupRoleIdInput: GroupRoleIdInput;
  GroupRoleInput: GroupRoleInput;
  GroupRoleJoinRule: ResolverTypeWrapper<GroupRoleJoinRule>;
  GroupRoleJoinRuleInput: GroupRoleJoinRuleInput;
  GroupRoleSearch: GroupRoleSearch;
  GroupSettingRequest: GroupSettingRequest;
  GroupSettingResponse: ResolverTypeWrapper<GroupSettingResponse>;
  GroupType: GroupType;
  HOMBuilding: ResolverTypeWrapper<HomBuilding>;
  HOMDate: ResolverTypeWrapper<HomDate>;
  HOMEvent: ResolverTypeWrapper<HomEvent>;
  HOMTeacher: ResolverTypeWrapper<HomTeacher>;
  HOMVenue: ResolverTypeWrapper<HomVenue>;
  Hardware: ResolverTypeWrapper<Hardware>;
  HardwareAccessLog: ResolverTypeWrapper<HardwareAccessLog>;
  HardwareAccessLogPagination: ResolverTypeWrapper<HardwareAccessLogPagination>;
  HardwareAccessLogsSearch: HardwareAccessLogsSearch;
  HardwareDeviceInfo: ResolverTypeWrapper<HardwareDeviceInfo>;
  HardwareInput: HardwareInput;
  HardwarePagination: ResolverTypeWrapper<HardwarePagination>;
  HardwarePassError: HardwarePassError;
  HardwareSearch: HardwareSearch;
  HardwareSettings: ResolverTypeWrapper<HardwareSettings>;
  HardwareStatus: HardwareStatus;
  HardwareThermalState: HardwareThermalState;
  HardwareType: HardwareType;
  HardwareVisitorPassScanStatus: HardwareVisitorPassScanStatus;
  Host: ResolverTypeWrapper<Host>;
  HyperLink: ResolverTypeWrapper<HyperLink>;
  HyperLinkInput: HyperLinkInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IdNamePair: ResolverTypeWrapper<IdNamePair>;
  Image: ResolverTypeWrapper<Scalars['Image']['output']>;
  InputCreateEscalationRule: InputCreateEscalationRule;
  InputUpdateEscalationRule: InputUpdateEscalationRule;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntSearch: IntSearch;
  Integration: ResolverTypeWrapper<Integration>;
  IntegrationCategory: IntegrationCategory;
  IntegrationInput: IntegrationInput;
  IntegrationProcess: ResolverTypeWrapper<IntegrationProcess>;
  IntegrationProvider: IntegrationProvider;
  IntegrationSearch: IntegrationSearch;
  IntegrationType: IntegrationType;
  Invoice: ResolverTypeWrapper<Invoice>;
  InvoiceAutopaymentDetails: ResolverTypeWrapper<InvoiceAutopaymentDetails>;
  InvoiceFilter: InvoiceFilter;
  InvoicePartial: ResolverTypeWrapper<InvoicePartial>;
  InvoiceType: InvoiceType;
  Issue: ResolverTypeWrapper<Issue>;
  IssueUpsertRequestDTO: IssueUpsertRequestDto;
  IssueUpsertResponseDTO: ResolverTypeWrapper<IssueUpsertResponseDto>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JobResponse: ResolverTypeWrapper<JobResponse>;
  JobsResponse: ResolverTypeWrapper<JobsResponse>;
  LayoutDesignSystem: ResolverTypeWrapper<LayoutDesignSystem>;
  LayoutDesignSystemInput: LayoutDesignSystemInput;
  Library: ResolverTypeWrapper<Library>;
  LibraryAccess: ResolverTypeWrapper<LibraryAccess>;
  LibraryAccessInput: LibraryAccessInput;
  LibraryAccessSearch: LibraryAccessSearch;
  LibraryIdInput: LibraryIdInput;
  LibraryInput: LibraryInput;
  LibraryItem: ResolverTypeWrapper<LibraryItem>;
  LibraryItemInput: LibraryItemInput;
  LibraryItemPagination: ResolverTypeWrapper<LibraryItemPagination>;
  LibraryItemSearch: LibraryItemSearch;
  LibraryItemWithOriginalExtension: ResolverTypeWrapper<LibraryItemWithOriginalExtension>;
  LibraryPagination: ResolverTypeWrapper<LibraryPagination>;
  LibrarySearch: LibrarySearch;
  LibraryType: LibraryType;
  LinkedEntity: ResolverTypeWrapper<LinkedEntity>;
  LinkedEntityInput: LinkedEntityInput;
  LinkedEntityPagination: ResolverTypeWrapper<LinkedEntityPagination>;
  LinkedEntitySearch: LinkedEntitySearch;
  ListChargeCodeFilter: ListChargeCodeFilter;
  ListChargeCodeRequest: ListChargeCodeRequest;
  ListChargeCodeResponse: ResolverTypeWrapper<ListChargeCodeResponse>;
  ListChargesRequest: ListChargesRequest;
  ListChargesResponse: ResolverTypeWrapper<ListChargesResponse>;
  ListInvoicesByPayersFilter: ListInvoicesByPayersFilter;
  ListInvoicesByPayersRequest: ListInvoicesByPayersRequest;
  ListInvoicesByPayersResponse: ResolverTypeWrapper<ListInvoicesByPayersResponse>;
  ListInvoicesRequest: ListInvoicesRequest;
  ListInvoicesResponse: ResolverTypeWrapper<ListInvoicesResponse>;
  ListProductCategoriesRequest: ListProductCategoriesRequest;
  ListProductCategoriesResponse: ResolverTypeWrapper<ListProductCategoriesResponse>;
  ListProductExceptionsRequest: ListProductExceptionsRequest;
  ListProductExceptionsResponse: ResolverTypeWrapper<ListProductExceptionsResponse>;
  ListProductsResponse: ResolverTypeWrapper<ListProductsResponse>;
  ListRecurringChargesRequest: ListRecurringChargesRequest;
  ListRecurringChargesResponse: ResolverTypeWrapper<ListRecurringChargesResponse>;
  ListTransactionsRequest: ListTransactionsRequest;
  ListTransactionsResponse: ResolverTypeWrapper<ListTransactionsResponse>;
  Location: ResolverTypeWrapper<Location>;
  LocationUpsertRequestDTO: LocationUpsertRequestDto;
  LocationUpsertResponseDTO: ResolverTypeWrapper<LocationUpsertResponseDto>;
  LookerReportType: LookerReportType;
  Markup: ResolverTypeWrapper<Markup>;
  MarkupAmountType: MarkupAmountType;
  MarkupInput: MarkupInput;
  Me: ResolverTypeWrapper<Me>;
  MeMutation: ResolverTypeWrapper<MeMutation>;
  Media: ResolverTypeWrapper<Media>;
  MediaContentType: ResolverTypeWrapper<Scalars['MediaContentType']['output']>;
  MediaInput: MediaInput;
  MediaPagination: ResolverTypeWrapper<MediaPagination>;
  MediaSearch: MediaSearch;
  MediaType: MediaType;
  MediaWithOriginalExtension: ResolverTypeWrapper<MediaWithOriginalExtension>;
  Metatag: ResolverTypeWrapper<Metatag>;
  MetatagFilter: ResolverTypeWrapper<MetatagFilter>;
  MetatagFilterInput: MetatagFilterInput;
  MetatagIdInput: MetatagIdInput;
  MetatagInput: MetatagInput;
  MetatagPagination: ResolverTypeWrapper<MetatagPagination>;
  MetatagSearch: MetatagSearch;
  MetatagType: MetatagType;
  MetatagUse: ResolverTypeWrapper<MetatagUse>;
  MetatagValue: ResolverTypeWrapper<MetatagValue>;
  MetatagValueInput: MetatagValueInput;
  MetatagValueSearch: MetatagValueSearch;
  MeterReadingSetting: ResolverTypeWrapper<MeterReadingSetting>;
  MeterReadingSettingInput: MeterReadingSettingInput;
  MeterReadingValue: ResolverTypeWrapper<MeterReadingValue>;
  MeterReadingValueForExport: ResolverTypeWrapper<MeterReadingValueForExport>;
  MeterReadingValueInput: MeterReadingValueInput;
  MeterReadingValueResponseType: ResolverTypeWrapper<MeterReadingValueResponseType>;
  MeterReadingValueSearch: MeterReadingValueSearch;
  MeterReadingValueSearchResults: ResolverTypeWrapper<MeterReadingValueSearchResults>;
  MobileAccess: ResolverTypeWrapper<MobileAccess>;
  MobileAccessChannel: ResolverTypeWrapper<MobileAccessChannel>;
  MobilePermission: MobilePermission;
  MultiChannelAcgs: ResolverTypeWrapper<MultiChannelAcgs>;
  Mutation: ResolverTypeWrapper<{}>;
  NotificationDeliveryType: NotificationDeliveryType;
  NumericSearchType: NumericSearchType;
  OAuth: ResolverTypeWrapper<OAuth>;
  OAuthConfig: ResolverTypeWrapper<OAuthConfig>;
  OAuthConfigInput: OAuthConfigInput;
  OIDCServiceProviderDefinitionInput: OidcServiceProviderDefinitionInput;
  ObjectAnalytics: ResolverTypeWrapper<ObjectAnalytics>;
  ObjectAnalyticsInput: ObjectAnalyticsInput;
  ObjectCount: ResolverTypeWrapper<ObjectCount>;
  ObjectDatasetRecord: ResolverTypeWrapper<ObjectDatasetRecord>;
  ObjectMetatag: ResolverTypeWrapper<ObjectMetatag>;
  ObjectMetatagIdInput: ObjectMetatagIdInput;
  ObjectMetatagInput: ObjectMetatagInput;
  ObjectMetatagSearch: ObjectMetatagSearch;
  ObjectMetatagValue: ResolverTypeWrapper<ObjectMetatagValue>;
  ObjectMetatagValueInput: ObjectMetatagValueInput;
  ObjectMetatagValueSearch: ObjectMetatagValueSearch;
  OidcServiceProviderDefinition: ResolverTypeWrapper<OidcServiceProviderDefinition>;
  OneTimePassword: ResolverTypeWrapper<OneTimePassword>;
  OnlinePlanQuote: ResolverTypeWrapper<OnlinePlanQuote>;
  OpenpathSetupMobileToken: ResolverTypeWrapper<OpenpathSetupMobileToken>;
  OrderItemDetails: ResolverTypeWrapper<OrderItemDetails>;
  OrderSummary: ResolverTypeWrapper<OrderSummary>;
  Owner: ResolverTypeWrapper<Owner>;
  OwnerInput: OwnerInput;
  OwnerType: OwnerType;
  PMSchedule: ResolverTypeWrapper<PmSchedule>;
  PMSchedulesForExportResponseDTO: ResolverTypeWrapper<PmSchedulesForExportResponseDto>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PagesOnChannel: ResolverTypeWrapper<PagesOnChannel>;
  PaginatedSections: ResolverTypeWrapper<PaginatedSections>;
  PaginationInput: PaginationInput;
  ParkWhizAmenitiy: ResolverTypeWrapper<ParkWhizAmenitiy>;
  ParkWhizBookPreview: ResolverTypeWrapper<ParkWhizBookPreview>;
  ParkWhizBooking: ResolverTypeWrapper<ParkWhizBooking>;
  ParkWhizBookingEmbedded: ResolverTypeWrapper<ParkWhizBookingEmbedded>;
  ParkWhizByGeoLocation: ResolverTypeWrapper<ParkWhizByGeoLocation>;
  ParkWhizCoordinate: ResolverTypeWrapper<ParkWhizCoordinate>;
  ParkWhizDistance: ResolverTypeWrapper<ParkWhizDistance>;
  ParkWhizIcon: ResolverTypeWrapper<ParkWhizIcon>;
  ParkWhizLocation: ResolverTypeWrapper<ParkWhizLocation>;
  ParkWhizParkingPass: ResolverTypeWrapper<ParkWhizParkingPass>;
  ParkWhizPhoto: ResolverTypeWrapper<ParkWhizPhoto>;
  ParkWhizPhotoSizes: ResolverTypeWrapper<ParkWhizPhotoSizes>;
  ParkWhizPhotos: ResolverTypeWrapper<ParkWhizPhotos>;
  ParkWhizPricingSegments: ResolverTypeWrapper<ParkWhizPricingSegments>;
  ParkWhizPurchaseOption: ResolverTypeWrapper<ParkWhizPurchaseOption>;
  ParkWhizScanCode: ResolverTypeWrapper<ParkWhizScanCode>;
  ParkWhizSpaceAvailability: ResolverTypeWrapper<ParkWhizSpaceAvailability>;
  ParkWhizStraightLine: ResolverTypeWrapper<ParkWhizStraightLine>;
  ParkWhizValidation: ResolverTypeWrapper<ParkWhizValidation>;
  ParkWhizValidationStep: ResolverTypeWrapper<ParkWhizValidationStep>;
  ParkWhizVehicle: ResolverTypeWrapper<ParkWhizVehicle>;
  PastVisitorPass: ResolverTypeWrapper<PastVisitorPass>;
  PastVisitorPassResult: ResolverTypeWrapper<PastVisitorPassResult>;
  Path: ResolverTypeWrapper<Scalars['Path']['output']>;
  Payer: ResolverTypeWrapper<Payer>;
  PayerFilter: PayerFilter;
  PayerInput: PayerInput;
  PaymentAccount: ResolverTypeWrapper<PaymentAccount>;
  PaymentAccountIdInput: PaymentAccountIdInput;
  PaymentAccountInput: PaymentAccountInput;
  PaymentAccountRule: ResolverTypeWrapper<PaymentAccountRule>;
  PaymentAccountRuleInput: PaymentAccountRuleInput;
  PaymentAccountSearch: PaymentAccountSearch;
  PaymentFeatureLineItem: ResolverTypeWrapper<PaymentFeatureLineItem>;
  PaymentFeatureQuote: ResolverTypeWrapper<PaymentFeatureQuote>;
  PaymentMethod: PaymentMethod;
  PaymentProcessor: ResolverTypeWrapper<PaymentProcessor>;
  PaymentProcessorIdInput: PaymentProcessorIdInput;
  PaymentProcessorInput: PaymentProcessorInput;
  PaymentProcessorSearch: PaymentProcessorSearch;
  PaymentProviderType: PaymentProviderType;
  PaymentSource: ResolverTypeWrapper<PaymentSource>;
  PaymentType: PaymentType;
  Payments: ResolverTypeWrapper<Payments>;
  PaymentsMutation: ResolverTypeWrapper<PaymentsMutation>;
  Permission: ResolverTypeWrapper<Scalars['Permission']['output']>;
  PermissionDetail: ResolverTypeWrapper<PermissionDetail>;
  PermissionListInput: PermissionListInput;
  PermissionListSearch: PermissionListSearch;
  Person: ResolverTypeWrapper<Person>;
  PersonUnitRelationshipType: PersonUnitRelationshipType;
  PhoneNumber: ResolverTypeWrapper<Scalars['PhoneNumber']['output']>;
  PlacePayInfo: ResolverTypeWrapper<PlacePayInfo>;
  PlatformType: PlatformType;
  Portal: ResolverTypeWrapper<Portal>;
  PostalCode: ResolverTypeWrapper<Scalars['PostalCode']['output']>;
  Price: ResolverTypeWrapper<Price>;
  PriceInput: PriceInput;
  Product: ResolverTypeWrapper<Product>;
  ProductCategoriesFilter: ProductCategoriesFilter;
  ProductCategory: ResolverTypeWrapper<ProductCategory>;
  ProductExceptionsFilter: ProductExceptionsFilter;
  ProductFinalPrice: ResolverTypeWrapper<ProductFinalPrice>;
  ProductInput: ProductInput;
  ProductItem: ResolverTypeWrapper<ProductItem>;
  ProductItemInput: ProductItemInput;
  ProductLineItem: ResolverTypeWrapper<ProductLineItem>;
  ProductTax: ResolverTypeWrapper<ProductTax>;
  ProductTaxInput: ProductTaxInput;
  ProductType: ProductType;
  ProductsFilter: ProductsFilter;
  Profile: ResolverTypeWrapper<Profile>;
  ProfileInput: ProfileInput;
  ProfileSearch: ProfileSearch;
  Property: ResolverTypeWrapper<Property>;
  ProxyClickCompany: ResolverTypeWrapper<ProxyClickCompany>;
  ProxyClickHost: ResolverTypeWrapper<ProxyClickHost>;
  ProxyClickMeeting: ResolverTypeWrapper<ProxyClickMeeting>;
  ProxyClickVisit: ResolverTypeWrapper<ProxyClickVisit>;
  ProxyClickVisitPagination: ResolverTypeWrapper<ProxyClickVisitPagination>;
  ProxyClickVisitStatus: ResolverTypeWrapper<ProxyClickVisitStatus>;
  ProxyClickVisitor: ResolverTypeWrapper<ProxyClickVisitor>;
  ProxyClickVisitorInput: ProxyClickVisitorInput;
  PublicProfile: ResolverTypeWrapper<PublicProfile>;
  PublicTeam: ResolverTypeWrapper<PublicTeam>;
  PublicUser: ResolverTypeWrapper<PublicUser>;
  PublicUserGroupRole: ResolverTypeWrapper<PublicUserGroupRole>;
  PublicUserPagination: ResolverTypeWrapper<PublicUserPagination>;
  PublicUserSearch: PublicUserSearch;
  QRCode: ResolverTypeWrapper<QrCode>;
  QuantityWaitlistByContent: ResolverTypeWrapper<QuantityWaitlistByContent>;
  QuantityWaitlistCompany: ResolverTypeWrapper<QuantityWaitlistCompany>;
  QuantityWaitlistPagination: ResolverTypeWrapper<QuantityWaitlistPagination>;
  QuantityWaitlistSearch: QuantityWaitlistSearch;
  QuantityWaitlistUser: ResolverTypeWrapper<QuantityWaitlistUser>;
  Query: ResolverTypeWrapper<{}>;
  Receivable: ResolverTypeWrapper<Receivable>;
  ReceivableInput: ReceivableInput;
  ReceivableType: ReceivableType;
  RecentJobsRequest: RecentJobsRequest;
  Recurrence: ResolverTypeWrapper<Recurrence>;
  RecurrenceInterval: RecurrenceInterval;
  RecurrenceType: RecurrenceType;
  RecurringCharge: ResolverTypeWrapper<RecurringCharge>;
  RecurringChargesFilters: RecurringChargesFilters;
  ReformedChannel: ResolverTypeWrapper<ReformedChannel>;
  RefundTransactionRequest: RefundTransactionRequest;
  RefundTransactionResponse: ResolverTypeWrapper<RefundTransactionResponse>;
  RegisterKioskVisitorPassScanResponse: ResolverTypeWrapper<RegisterKioskVisitorPassScanResponse>;
  RelationshipKeyType: RelationshipKeyType;
  RelationshipPatternType: RelationshipPatternType;
  RelayAccessPoint: ResolverTypeWrapper<RelayAccessPoint>;
  RelayAction: RelayAction;
  RelayModule: ResolverTypeWrapper<RelayModule>;
  RelayModuleStatus: RelayModuleStatus;
  RelayOutput: ResolverTypeWrapper<RelayOutput>;
  RelaySearch: RelaySearch;
  RemovePaymentMethodRequest: RemovePaymentMethodRequest;
  RemovePaymentMethodResponse: ResolverTypeWrapper<RemovePaymentMethodResponse>;
  ReportGroupBy: ReportGroupBy;
  ReportOrderBy: ReportOrderBy;
  ReportSearch: ReportSearch;
  ReportTimeUnit: ReportTimeUnit;
  Requirement: ResolverTypeWrapper<Scalars['Requirement']['output']>;
  RequirementDetail: ResolverTypeWrapper<RequirementDetail>;
  RequirementFeatureContentInteractionProperties: ResolverTypeWrapper<RequirementFeatureContentInteractionProperties>;
  RequirementFeatureContentInteractionPropertiesId: ResolverTypeWrapper<RequirementFeatureContentInteractionPropertiesId>;
  RequirementFeatureContentInteractionPropertiesIdInput: RequirementFeatureContentInteractionPropertiesIdInput;
  RequirementFeatureContentInteractionPropertiesInput: RequirementFeatureContentInteractionPropertiesInput;
  RequirementListInput: RequirementListInput;
  RequirementListSearch: RequirementListSearch;
  RequirementsFeatureProperties: ResolverTypeWrapper<RequirementsFeatureProperties>;
  RequirementsFeaturePropertiesInput: RequirementsFeaturePropertiesInput;
  RequirementsSource: ResolverTypeWrapper<RequirementsSource>;
  Reservable: ResolverTypeWrapper<Reservable>;
  ReservableAvailability: ResolverTypeWrapper<ReservableAvailability>;
  ReservableBooking: ResolverTypeWrapper<ReservableBooking>;
  ReservableBookingPagination: ResolverTypeWrapper<ReservableBookingPagination>;
  ReservableBookingSearch: ReservableBookingSearch;
  ReservableConnection: ResolverTypeWrapper<ReservableConnection>;
  ReservableEdge: ResolverTypeWrapper<ReservableEdge>;
  ReservableWaitlistByContent: ResolverTypeWrapper<ReservableWaitlistByContent>;
  ReservableWaitlistCompany: ResolverTypeWrapper<ReservableWaitlistCompany>;
  ReservableWaitlistEnrollment: ResolverTypeWrapper<ReservableWaitlistEnrollment>;
  ReservableWaitlistPagination: ResolverTypeWrapper<ReservableWaitlistPagination>;
  ReservableWaitlistSearch: ReservableWaitlistSearch;
  ReservableWaitlistUnrollment: ResolverTypeWrapper<ReservableWaitlistUnrollment>;
  ReservableWaitlistUser: ResolverTypeWrapper<ReservableWaitlistUser>;
  ReservablesOnChannelSearchInput: ReservablesOnChannelSearchInput;
  ResetPeriodType: ResetPeriodType;
  Resident: ResolverTypeWrapper<Resident>;
  ResidentStatus: ResidentStatus;
  ResidentType: ResidentType;
  RiseMigrationFeatures: RiseMigrationFeatures;
  SAMLData: ResolverTypeWrapper<SamlData>;
  SAMLServiceProviderDefinition: ResolverTypeWrapper<SamlServiceProviderDefinition>;
  SAMLServiceProviderDefinitionInput: SamlServiceProviderDefinitionInput;
  SaveConfigRequest: SaveConfigRequest;
  SaveConfigResponse: ResolverTypeWrapper<SaveConfigResponse>;
  SavePaymentMethodRequest: SavePaymentMethodRequest;
  SavePaymentMethodResponse: ResolverTypeWrapper<SavePaymentMethodResponse>;
  ScheduledVisitorPass: ResolverTypeWrapper<ScheduledVisitorPass>;
  ScheduledVisitorPassResult: ResolverTypeWrapper<ScheduledVisitorPassResult>;
  SearchMode: SearchMode;
  SearchOptionFilter: ResolverTypeWrapper<SearchOptionFilter>;
  SearchOptionFilterInput: SearchOptionFilterInput;
  SearchOptions: ResolverTypeWrapper<SearchOptions>;
  SearchOptionsInput: SearchOptionsInput;
  SearchServiceRequest: SearchServiceRequest;
  SearchTasksReponseDTO: ResolverTypeWrapper<SearchTasksReponseDto>;
  Section: ResolverTypeWrapper<Section>;
  SectionContent: ResolverTypeWrapper<SectionContent>;
  SectionContentInput: SectionContentInput;
  SectionContentSearch: SectionContentSearch;
  SectionIdInput: SectionIdInput;
  SectionInput: SectionInput;
  SectionObjectType: SectionObjectType;
  SectionSearch: SectionSearch;
  SectionType: SectionType;
  SellingPrice: ResolverTypeWrapper<SellingPrice>;
  SellingPriceInput: SellingPriceInput;
  ServiceRequest: ResolverTypeWrapper<ServiceRequest>;
  ServiceRequestAction: ResolverTypeWrapper<ServiceRequestAction>;
  ServiceRequestAuditLog: ResolverTypeWrapper<ServiceRequestAuditLog>;
  ServiceRequestCategoriesList: ResolverTypeWrapper<ServiceRequestCategoriesList>;
  ServiceRequestDTO: ServiceRequestDto;
  ServiceRequestFeatures: ResolverTypeWrapper<ServiceRequestFeatures>;
  ServiceRequestFilter: ServiceRequestFilter;
  ServiceRequestIssuesList: ResolverTypeWrapper<ServiceRequestIssuesList>;
  ServiceRequestLocationsList: ResolverTypeWrapper<ServiceRequestLocationsList>;
  ServiceRequestPreFilter: ServiceRequestPreFilter;
  ServiceRequestSearchResults: ResolverTypeWrapper<ServiceRequestSearchResults>;
  ServiceRequestUpdateResponseDTO: ResolverTypeWrapper<ServiceRequestUpdateResponseDto>;
  ServiceRequestsFilterFieldsDTO: ResolverTypeWrapper<ServiceRequestsFilterFieldsDto>;
  ServiceRequestsList: ResolverTypeWrapper<ServiceRequestsList>;
  Session: ResolverTypeWrapper<Session>;
  SessionDevice: SessionDevice;
  SessionInput: SessionInput;
  SessionPlatform: SessionPlatform;
  SessionSearch: SessionSearch;
  ShopifyCustomer: ResolverTypeWrapper<ShopifyCustomer>;
  ShopifyMultipassLoginDetail: ResolverTypeWrapper<ShopifyMultipassLoginDetail>;
  Sort: Sort;
  SortBy: SortBy;
  SortDirection: SortDirection;
  SourceEntityIdentifier: ResolverTypeWrapper<SourceEntityIdentifier>;
  SourceEntityIdentifierInput: SourceEntityIdentifierInput;
  SourceType: SourceType;
  SpecialGroupRole: SpecialGroupRole;
  SpecialInvite: ResolverTypeWrapper<SpecialInvite>;
  StartImportJobRequest: StartImportJobRequest;
  StartImportJobResponse: ResolverTypeWrapper<StartImportJobResponse>;
  StepExecution: ResolverTypeWrapper<StepExecution>;
  StepExecutionOption: ResolverTypeWrapper<StepExecutionOption>;
  StepTemplate: ResolverTypeWrapper<StepTemplate>;
  StepTemplateDTO: StepTemplateDto;
  StepTemplateOption: ResolverTypeWrapper<StepTemplateOption>;
  StepTemplateOptionDTO: StepTemplateOptionDto;
  StepsRequest: StepsRequest;
  StepsRequestOption: StepsRequestOption;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringListInput: StringListInput;
  StringListSearch: StringListSearch;
  StringSearch: StringSearch;
  StringSearchType: StringSearchType;
  StripeAccount: ResolverTypeWrapper<StripeAccount>;
  StripeAccountInput: StripeAccountInput;
  StripeAccountLink: ResolverTypeWrapper<StripeAccountLink>;
  StripeAccountType: StripeAccountType;
  StripeAccountVerification: ResolverTypeWrapper<StripeAccountVerification>;
  StripeAddress: ResolverTypeWrapper<StripeAddress>;
  StripeAddressInput: StripeAddressInput;
  StripeBankAccount: ResolverTypeWrapper<StripeBankAccount>;
  StripeBankAccountInput: StripeBankAccountInput;
  StripeDate: ResolverTypeWrapper<StripeDate>;
  StripeDateInput: StripeDateInput;
  StripeDayEnum: StripeDayEnum;
  StripeDeclineCharge: ResolverTypeWrapper<StripeDeclineCharge>;
  StripeDeclineChargeInput: StripeDeclineChargeInput;
  StripeExternalAccountObject: StripeExternalAccountObject;
  StripeGenderEnum: StripeGenderEnum;
  StripeInfo: ResolverTypeWrapper<StripeInfo>;
  StripeIntervalEnum: StripeIntervalEnum;
  StripeLegalEntity: ResolverTypeWrapper<StripeLegalEntity>;
  StripeLegalEntityInput: StripeLegalEntityInput;
  StripeLegalEntityType: StripeLegalEntityType;
  StripePayoutSchedule: ResolverTypeWrapper<StripePayoutSchedule>;
  StripePayoutScheduleInput: StripePayoutScheduleInput;
  StripeVerification: ResolverTypeWrapper<StripeVerification>;
  StripeVeriticationInput: StripeVeriticationInput;
  StudioGroupRoleName: ResolverTypeWrapper<Scalars['StudioGroupRoleName']['output']>;
  SubscriptionDetails: ResolverTypeWrapper<SubscriptionDetails>;
  Suite: ResolverTypeWrapper<Suite>;
  SuiteInput: SuiteInput;
  SupportedLocale: ResolverTypeWrapper<Scalars['SupportedLocale']['output']>;
  SwitchChannel: ResolverTypeWrapper<SwitchChannel>;
  SyncGroupRoleInput: SyncGroupRoleInput;
  Tag: ResolverTypeWrapper<Tag>;
  TagInput: TagInput;
  TagOnSection: TagOnSection;
  TagOnSectionInput: TagOnSectionInput;
  TargetLibraryType: TargetLibraryType;
  TargetingRule: ResolverTypeWrapper<TargetingRule>;
  TargetingRuleEntityType: TargetingRuleEntityType;
  TargetingRuleInput: TargetingRuleInput;
  TargetingRulesForAudience: ResolverTypeWrapper<TargetingRulesForAudience>;
  Task: ResolverTypeWrapper<Task>;
  TaskAction: ResolverTypeWrapper<TaskAction>;
  TaskAuditLog: ResolverTypeWrapper<TaskAuditLog>;
  TaskEquipmentDTO: ResolverTypeWrapper<TaskEquipmentDto>;
  TaskFilterDTO: TaskFilterDto;
  TaskFilterOptionDTO: ResolverTypeWrapper<TaskFilterOptionDto>;
  TaskPreFilterDTO: TaskPreFilterDto;
  TaskRepeatDTO: ResolverTypeWrapper<TaskRepeatDto>;
  TaskScheduleDTO: ResolverTypeWrapper<TaskScheduleDto>;
  Tax: ResolverTypeWrapper<Tax>;
  TaxAmountType: TaxAmountType;
  TaxInput: TaxInput;
  Tenant: ResolverTypeWrapper<Tenant>;
  TenantBuildingChannel: ResolverTypeWrapper<TenantBuildingChannel>;
  TenantLease: ResolverTypeWrapper<TenantLease>;
  TenantLeaseInput: TenantLeaseInput;
  Theme: ResolverTypeWrapper<Theme>;
  ThemeColors: ResolverTypeWrapper<ThemeColors>;
  ThemeIdInput: ThemeIdInput;
  ThemeInput: ThemeInput;
  ThemePalette: ResolverTypeWrapper<ThemePalette>;
  ThemePaletteInput: ThemePaletteInput;
  ThemeSearch: ThemeSearch;
  Token: ResolverTypeWrapper<Token>;
  Totals: ResolverTypeWrapper<Totals>;
  TrackAnalytic: TrackAnalytic;
  Transaction: ResolverTypeWrapper<Transaction>;
  TriggerAccessPointResponse: ResolverTypeWrapper<TriggerAccessPointResponse>;
  TypographicDesignSystem: ResolverTypeWrapper<TypographicDesignSystem>;
  TypographicDesignSystemInput: TypographicDesignSystemInput;
  TypographyElement: ResolverTypeWrapper<TypographyElement>;
  TypographyElementInput: TypographyElementInput;
  TypographyLayoutElement: ResolverTypeWrapper<TypographyLayoutElement>;
  TypographyLayoutElementInput: TypographyLayoutElementInput;
  URL: ResolverTypeWrapper<Scalars['URL']['output']>;
  UUID: ResolverTypeWrapper<Scalars['UUID']['output']>;
  UUIDListSearch: UuidListSearch;
  Unit: ResolverTypeWrapper<Unit>;
  UnitInput: UnitInput;
  UnitsForResident: UnitsForResident;
  UpdateAccessPointRequest: UpdateAccessPointRequest;
  UpdateAudienceChannelTargetingRule: UpdateAudienceChannelTargetingRule;
  UpdateAudienceGroupRoleTargetingRule: UpdateAudienceGroupRoleTargetingRule;
  UpdateAudienceInput: UpdateAudienceInput;
  UpdateAudienceResponse: ResolverTypeWrapper<UpdateAudienceResponse>;
  UpdateDraftChargeRequest: UpdateDraftChargeRequest;
  UpdateEquipmentInput: UpdateEquipmentInput;
  UpdateHardwareDeviceInfoInput: UpdateHardwareDeviceInfoInput;
  UpdateHardwareDeviceInfoResponse: ResolverTypeWrapper<UpdateHardwareDeviceInfoResponse>;
  UpdateHardwareInput: UpdateHardwareInput;
  UpdateHardwareSettingsInput: UpdateHardwareSettingsInput;
  UpdateInvoiceAutopaymentDetailsRequest: UpdateInvoiceAutopaymentDetailsRequest;
  UpdateInvoiceAutopaymentDetailsResponse: ResolverTypeWrapper<UpdateInvoiceAutopaymentDetailsResponse>;
  UpdateInvoiceToPaidRequest: UpdateInvoiceToPaidRequest;
  UpdateInvoiceToPaidResponse: ResolverTypeWrapper<UpdateInvoiceToPaidResponse>;
  UpdateMeterReadingValueInput: UpdateMeterReadingValueInput;
  UpdatePMScheduleDTO: UpdatePmScheduleDto;
  UpdatePMScheduleResponseDTO: ResolverTypeWrapper<UpdatePmScheduleResponseDto>;
  UpdateProductExceptionRequest: UpdateProductExceptionRequest;
  UpdateProductExceptionResponse: ResolverTypeWrapper<UpdateProductExceptionResponse>;
  UpdateProductRequest: UpdateProductRequest;
  UpdateProductResponse: ResolverTypeWrapper<UpdateProductResponse>;
  UpdateRecurrencePassesResponse: ResolverTypeWrapper<UpdateRecurrencePassesResponse>;
  UpdateRelayModuleInput: UpdateRelayModuleInput;
  UpdateRelayOutputInput: UpdateRelayOutputInput;
  UpdateServiceRequestDTO: UpdateServiceRequestDto;
  UpdateTagInput: UpdateTagInput;
  UpdateTargetingRuleInput: UpdateTargetingRuleInput;
  UpdateTargetingRuleResponse: ResolverTypeWrapper<UpdateTargetingRuleResponse>;
  UpdateTask: UpdateTask;
  UpdateTaskResponseDTO: ResolverTypeWrapper<UpdateTaskResponseDto>;
  UpdateTaskStatus: UpdateTaskStatus;
  UpdateTaskSteps: UpdateTaskSteps;
  UploadCSVData: UploadCsvData;
  UploadCSVResponse: ResolverTypeWrapper<UploadCsvResponse>;
  UpsertSubscriptionDetailsRequest: UpsertSubscriptionDetailsRequest;
  UpsertSubscriptionDetailsResponse: ResolverTypeWrapper<UpsertSubscriptionDetailsResponse>;
  User: ResolverTypeWrapper<User>;
  UserAccessCompany: ResolverTypeWrapper<UserAccessCompany>;
  UserAccessDetails: ResolverTypeWrapper<UserAccessDetails>;
  UserBooking: ResolverTypeWrapper<UserBooking>;
  UserBuildingAccess: ResolverTypeWrapper<UserBuildingAccess>;
  UserBuildingAccessMapping: ResolverTypeWrapper<UserBuildingAccessMapping>;
  UserChannelSettings: ResolverTypeWrapper<UserChannelSettings>;
  UserChannelSettingsInput: UserChannelSettingsInput;
  UserClearanceInput: UserClearanceInput;
  UserCompanyDetails: ResolverTypeWrapper<UserCompanyDetails>;
  UserContentInteraction: ResolverTypeWrapper<UserContentInteraction>;
  UserContentInteractionHistory: ResolverTypeWrapper<UserContentInteractionHistory>;
  UserContentInteractionHistoryPagination: ResolverTypeWrapper<UserContentInteractionHistoryPagination>;
  UserContentInteractionHistorySearch: UserContentInteractionHistorySearch;
  UserContentInteractionInput: UserContentInteractionInput;
  UserContentInteractionPagination: ResolverTypeWrapper<UserContentInteractionPagination>;
  UserContentInteractionSearch: UserContentInteractionSearch;
  UserContentInteractionStateSearch: UserContentInteractionStateSearch;
  UserContentInteractionStatus: ResolverTypeWrapper<Scalars['UserContentInteractionStatus']['output']>;
  UserEmail: ResolverTypeWrapper<UserEmail>;
  UserFilters: ResolverTypeWrapper<UserFilters>;
  UserGroupRole: ResolverTypeWrapper<UserGroupRole>;
  UserGroupRoleInput: UserGroupRoleInput;
  UserGroupRolePagination: ResolverTypeWrapper<UserGroupRolePagination>;
  UserGroupRoleRelatedChannel: ResolverTypeWrapper<UserGroupRoleRelatedChannel>;
  UserGroupRoleSearch: UserGroupRoleSearch;
  UserIdInput: UserIdInput;
  UserInput: UserInput;
  UserIntegration: ResolverTypeWrapper<UserIntegration>;
  UserLogin: ResolverTypeWrapper<UserLogin>;
  UserLoginInput: UserLoginInput;
  UserLoginMethod: ResolverTypeWrapper<UserLoginMethod>;
  UserLoginProvider: UserLoginProvider;
  UserLoginSearch: UserLoginSearch;
  UserLoginStatusType: UserLoginStatusType;
  UserLoginType: UserLoginType;
  UserMappingStatus: UserMappingStatus;
  UserObjectInfo: ResolverTypeWrapper<UserObjectInfo>;
  UserObjectInfoInput: UserObjectInfoInput;
  UserPagination: ResolverTypeWrapper<UserPagination>;
  UserSearch: UserSearch;
  UserSettings: ResolverTypeWrapper<UserSettings>;
  UserSettingsInput: UserSettingsInput;
  UserStatus: UserStatus;
  UsersByChannelIdSearch: UsersByChannelIdSearch;
  Visitor: ResolverTypeWrapper<Visitor>;
  VisitorEmailTemplate: ResolverTypeWrapper<VisitorEmailTemplate>;
  VisitorEmailTemplateInput: VisitorEmailTemplateInput;
  VisitorInput: VisitorInput;
  VisitorLog: ResolverTypeWrapper<VisitorLog>;
  VisitorOrganizationSettings: ResolverTypeWrapper<VisitorOrganizationSettings>;
  VisitorOrganizationSettingsInput: VisitorOrganizationSettingsInput;
  VisitorPass: ResolverTypeWrapper<VisitorPass>;
  VisitorPassAggregatesBySubmissionId: ResolverTypeWrapper<VisitorPassAggregatesBySubmissionId>;
  VisitorPassHost: ResolverTypeWrapper<VisitorPassHost>;
  VisitorPassHostProfile: ResolverTypeWrapper<VisitorPassHostProfile>;
  VisitorPassRecurrence: ResolverTypeWrapper<VisitorPassRecurrence>;
  VisitorPassTenant: ResolverTypeWrapper<VisitorPassTenant>;
  VisitorPassTenantAddress: ResolverTypeWrapper<VisitorPassTenantAddress>;
  VisitorPassTenantProfile: ResolverTypeWrapper<VisitorPassTenantProfile>;
  VisitorPassUpdateResponse: ResolverTypeWrapper<VisitorPassUpdateResponse>;
  Void: ResolverTypeWrapper<Scalars['Void']['output']>;
  VoidChargeRequest: VoidChargeRequest;
  VoidChargeResponse: ResolverTypeWrapper<VoidChargeResponse>;
  VtsAddress: ResolverTypeWrapper<VtsAddress>;
  WaitlistUnEnrollment: ResolverTypeWrapper<WaitlistUnEnrollment>;
  Weather: ResolverTypeWrapper<Weather>;
  WeatherAlert: ResolverTypeWrapper<WeatherAlert>;
  WeatherDataPoint: ResolverTypeWrapper<WeatherDataPoint>;
  WeatherFlags: ResolverTypeWrapper<WeatherFlags>;
  Webhook: ResolverTypeWrapper<Webhook>;
  WebhookIdInput: WebhookIdInput;
  WebhookInput: WebhookInput;
  WebhookSearch: WebhookSearch;
  WebhookStatusEnum: WebhookStatusEnum;
  Whitelabel: ResolverTypeWrapper<Whitelabel>;
  WhitelabelChannel: ResolverTypeWrapper<WhitelabelChannel>;
  WhitelabelChannelInput: WhitelabelChannelInput;
  WhitelabelChannelSearch: WhitelabelChannelSearch;
  WhitelabelIdInput: WhitelabelIdInput;
  WhitelabelInput: WhitelabelInput;
  WhitelabelSearch: WhitelabelSearch;
  WhitelabelSettingsInput: WhitelabelSettingsInput;
  WorkOrderStatus: WorkOrderStatus;
  Workflow: ResolverTypeWrapper<Workflow>;
  WorkflowActionType: WorkflowActionType;
  WorkflowInput: WorkflowInput;
  WorkflowTargetType: WorkflowTargetType;
  WorkflowType: WorkflowType;
  WorkflowWhenContextType: WorkflowWhenContextType;
  WorkflowWhenType: WorkflowWhenType;
  enableMobileAccessResponse: ResolverTypeWrapper<EnableMobileAccessResponse>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccessControlChannelType: AccessControlChannelType;
  AccessControlGroup: AccessControlGroup;
  AccessControlGroupInput: AccessControlGroupInput;
  AccessControlGroups: AccessControlGroups;
  AccessControlGroupsForInvite: AccessControlGroupsForInvite;
  AccessControlGroupsMapInput: AccessControlGroupsMapInput;
  AccessControlProfileType: AccessControlProfileType;
  AccessIntegration: AccessIntegration;
  AccessLocation: AccessLocation;
  AccessPoint: AccessPoint;
  AccessUser: AccessUser;
  AccessUserChannel: AccessUserChannel;
  AccessUserControlGroups: AccessUserControlGroups;
  AccessUserRole: AccessUserRole;
  AccountContactPair: AccountContactPair;
  AccountsMutations: AccountsMutations;
  AccountsQueries: AccountsQueries;
  ActivateUser: ActivateUser;
  Address: Address;
  AddressInput: AddressInput;
  AddressSearch: AddressSearch;
  AdminUserSearch: AdminUserSearch;
  AllowedFeatures: AllowedFeatures;
  AnalyticType: Scalars['AnalyticType']['output'];
  AngusBuilding: AngusBuilding;
  AngusFloor: AngusFloor;
  AngusLocation: AngusLocation;
  AngusRequestType: AngusRequestType;
  AngusRequester: AngusRequester;
  AngusSuite: AngusSuite;
  AngusTenant: AngusTenant;
  AngusWorkOrder: AngusWorkOrder;
  AngusWorkOrderConfirmation: AngusWorkOrderConfirmation;
  ApiKey: ApiKey;
  ApiKeyCreateInput: ApiKeyCreateInput;
  ApiKeyDisableInput: ApiKeyDisableInput;
  ApiKeyInput: ApiKeyInput;
  AppleWalletIssuanceToken: AppleWalletIssuanceToken;
  Attachment: Attachment;
  AttachmentInput: AttachmentInput;
  Audience: Audience;
  AudienceIdInput: AudienceIdInput;
  AudiencePagination: AudiencePagination;
  AudienceSearch: AudienceSearch;
  AudiencesToAttachContentTo: AudiencesToAttachContentTo;
  AudiencesToAttachContentToInput: AudiencesToAttachContentToInput;
  Auth0Connection: Auth0Connection;
  Auth0DomainHomeRealmDiscovery: Auth0DomainHomeRealmDiscovery;
  Auth0MembershipInvitation: Auth0MembershipInvitation;
  Auth0Organization: Auth0Organization;
  Auth0ProvisionUserToOrganization: Auth0ProvisionUserToOrganization;
  Auth0User: Auth0User;
  AutoSetupCesEntityRequest: AutoSetupCesEntityRequest;
  AutoSetupCesEntityResponse: AutoSetupCesEntityResponse;
  BigInt: Scalars['BigInt']['output'];
  BiltSso: BiltSso;
  Binary: Scalars['Binary']['output'];
  Block: Block;
  BlockIdInput: BlockIdInput;
  BlockInput: BlockInput;
  BlockSearch: BlockSearch;
  BluetoothInvitationCode: BluetoothInvitationCode;
  BookingCompany: BookingCompany;
  Boolean: Scalars['Boolean']['output'];
  Building: Building;
  BuildingInput: BuildingInput;
  BuildingTenantChannel: BuildingTenantChannel;
  Byte: Scalars['Byte']['output'];
  CancelRecurringChargeRequest: CancelRecurringChargeRequest;
  CancelRecurringChargeResponse: CancelRecurringChargeResponse;
  CancelSubmissionResponse: CancelSubmissionResponse;
  Category: Category;
  CategoryUpsertRequestDTO: CategoryUpsertRequestDto;
  CategoryUpsertResponseDTO: CategoryUpsertResponseDto;
  Channel: Channel;
  ChannelAccessIntegration: ChannelAccessIntegration;
  ChannelContract: ChannelContract;
  ChannelContractIdInput: ChannelContractIdInput;
  ChannelContractInput: ChannelContractInput;
  ChannelContractLineItem: ChannelContractLineItem;
  ChannelContractLineItemIdInput: ChannelContractLineItemIdInput;
  ChannelContractLineItemInput: ChannelContractLineItemInput;
  ChannelContractLineItemSearch: ChannelContractLineItemSearch;
  ChannelContractPagination: ChannelContractPagination;
  ChannelContractSearch: ChannelContractSearch;
  ChannelEquipmentSettings: ChannelEquipmentSettings;
  ChannelEquipmentSettingsInput: ChannelEquipmentSettingsInput;
  ChannelGroupRoleInput: ChannelGroupRoleInput;
  ChannelHierarchy: ChannelHierarchy;
  ChannelIdInput: ChannelIdInput;
  ChannelInput: ChannelInput;
  ChannelIntegration: ChannelIntegration;
  ChannelIntegrationIdInput: ChannelIntegrationIdInput;
  ChannelIntegrationInput: ChannelIntegrationInput;
  ChannelIntegrationSearch: ChannelIntegrationSearch;
  ChannelInvite: ChannelInvite;
  ChannelInviteInput: ChannelInviteInput;
  ChannelInvitePagination: ChannelInvitePagination;
  ChannelInviteSearch: ChannelInviteSearch;
  ChannelMerchant: ChannelMerchant;
  ChannelMerchantIdInput: ChannelMerchantIdInput;
  ChannelMerchantInput: ChannelMerchantInput;
  ChannelMerchantSearch: ChannelMerchantSearch;
  ChannelModule: ChannelModule;
  ChannelModuleInput: ChannelModuleInput;
  ChannelPage: ChannelPage;
  ChannelPageInput: ChannelPageInput;
  ChannelPagination: ChannelPagination;
  ChannelPermissionSearch: ChannelPermissionSearch;
  ChannelRelationship: ChannelRelationship;
  ChannelRelationshipInput: ChannelRelationshipInput;
  ChannelRelationshipPagination: ChannelRelationshipPagination;
  ChannelRelationshipSearch: ChannelRelationshipSearch;
  ChannelSearch: ChannelSearch;
  ChannelSettings: ChannelSettings;
  ChannelSettingsInput: ChannelSettingsInput;
  ChannelStats: ChannelStats;
  ChannelStatsInput: ChannelStatsInput;
  Charge: Charge;
  ChargeBillingReport: ChargeBillingReport;
  ChargeBillingReportsResponse: ChargeBillingReportsResponse;
  ChargeCode: ChargeCode;
  ChargeReportFilters: ChargeReportFilters;
  ChargeTemplate: ChargeTemplate;
  ChargesFilters: ChargesFilters;
  ClaimedChannelInvite: ClaimedChannelInvite;
  ClaimedChannelInviteInput: ClaimedChannelInviteInput;
  ClaimedChannelInviteSearch: ClaimedChannelInviteSearch;
  ClientPMSchedule: ClientPmSchedule;
  ClientTask: ClientTask;
  CloneChannelOptionsInput: CloneChannelOptionsInput;
  Color: Scalars['Color']['output'];
  CompleteSignupData: CompleteSignupData;
  CompleteTask: CompleteTask;
  CompleteTaskResponseDTO: CompleteTaskResponseDto;
  Configuration: Configuration;
  Content: Content;
  ContentCategoryType: Scalars['ContentCategoryType']['output'];
  ContentDateRanges: ContentDateRanges;
  ContentFeature: ContentFeature;
  ContentFeatureInput: ContentFeatureInput;
  ContentFeatureStatusSummary: ContentFeatureStatusSummary;
  ContentGenerator: ContentGenerator;
  ContentGeneratorInput: ContentGeneratorInput;
  ContentIdInput: ContentIdInput;
  ContentInput: ContentInput;
  ContentInterface: ResolversInterfaceTypes<ResolversParentTypes>['ContentInterface'];
  ContentNotification: ContentNotification;
  ContentNotificationInput: ContentNotificationInput;
  ContentPagination: ContentPagination;
  ContentPlacement: ContentPlacement;
  ContentPlacementInput: ContentPlacementInput;
  ContentQuantityAllotments: ContentQuantityAllotments;
  ContentSearch: ContentSearch;
  ContentTemplate: ContentTemplate;
  ContentTemplateInput: ContentTemplateInput;
  ContentTemplatePagination: ContentTemplatePagination;
  ContentTemplateSearch: ContentTemplateSearch;
  ContentWaitlistEnrollment: ContentWaitlistEnrollment;
  ContentWorkflow: ContentWorkflow;
  ContentWorkflowInput: ContentWorkflowInput;
  CreateAccessPointRequest: CreateAccessPointRequest;
  CreateAudienceChannelTargetingRule: CreateAudienceChannelTargetingRule;
  CreateAudienceGroupRoleTargetingRule: CreateAudienceGroupRoleTargetingRule;
  CreateAudienceInput: CreateAudienceInput;
  CreateAudienceResponse: CreateAudienceResponse;
  CreateAutoSetupDTO: CreateAutoSetupDto;
  CreateChargeCodeRequest: CreateChargeCodeRequest;
  CreateChargeCodeResponse: CreateChargeCodeResponse;
  CreateChargeRequest: CreateChargeRequest;
  CreateChargeResponse: CreateChargeResponse;
  CreateFloorInput: CreateFloorInput;
  CreatePMScheduleDTO: CreatePmScheduleDto;
  CreatePaymentAccountOptionsInput: CreatePaymentAccountOptionsInput;
  CreateProductCategoryRequest: CreateProductCategoryRequest;
  CreateProductCategoryResponse: CreateProductCategoryResponse;
  CreateProductExceptionRequest: CreateProductExceptionRequest;
  CreateProductExceptionResponse: CreateProductExceptionResponse;
  CreateProductRequest: CreateProductRequest;
  CreateProductResponse: CreateProductResponse;
  CreateRecurringChargeRequest: CreateRecurringChargeRequest;
  CreateRecurringChargeResponse: CreateRecurringChargeResponse;
  CreateServiceRequestDTO: CreateServiceRequestDto;
  CreateTargetingRuleInput: CreateTargetingRuleInput;
  CreateTargetingRuleResponse: CreateTargetingRuleResponse;
  CreateTask: CreateTask;
  CreateTransactionRequest: CreateTransactionRequest;
  CreateTransactionResponse: CreateTransactionResponse;
  CreateUserRequest: CreateUserRequest;
  CreateUserResponse: CreateUserResponse;
  Credential: Credential;
  CredentialGroups: CredentialGroups;
  CreditBundle: CreditBundle;
  CreditBundleInput: CreditBundleInput;
  CreditCard: CreditCard;
  CreditWallet: CreditWallet;
  CreditWalletChannelProfile: CreditWalletChannelProfile;
  CurrencyDetail: CurrencyDetail;
  CursorPageInfo: CursorPageInfo;
  CustomColorPalette: CustomColorPalette;
  CustomColorPaletteInput: CustomColorPaletteInput;
  CustomQuestion: CustomQuestion;
  CustomQuestionInput: CustomQuestionInput;
  DataField: DataField;
  DataFieldInput: DataFieldInput;
  DatasetAutomationInput: DatasetAutomationInput;
  DatasetRecord: DatasetRecord;
  DatasetRecordInput: DatasetRecordInput;
  DatasetRecordPagination: DatasetRecordPagination;
  DatasetRecordSearch: DatasetRecordSearch;
  DatasetRecordUpdateInput: DatasetRecordUpdateInput;
  DatasetSchema: DatasetSchema;
  DatasetSchemaIdInput: DatasetSchemaIdInput;
  DatasetSchemaInput: DatasetSchemaInput;
  DatasetSchemaSearch: DatasetSchemaSearch;
  Date: Scalars['Date']['output'];
  DateRange: DateRange;
  DateRangeInput: DateRangeInput;
  DateTime: Scalars['DateTime']['output'];
  DateTimeSearch: DateTimeSearch;
  DeepLink: DeepLink;
  DeepLinkInput: DeepLinkInput;
  DeleteDraftChargeRequest: DeleteDraftChargeRequest;
  DeleteInvoiceAutopaymentRequest: DeleteInvoiceAutopaymentRequest;
  DeleteProductExceptionRequest: DeleteProductExceptionRequest;
  DeleteProductRequest: DeleteProductRequest;
  DeleteTargetingRuleInput: DeleteTargetingRuleInput;
  DeviceToken: DeviceToken;
  DeviceTokenInput: DeviceTokenInput;
  DeviceTokenSearch: DeviceTokenSearch;
  DisplayMetadata: DisplayMetadata;
  Document: ResolversInterfaceTypes<ResolversParentTypes>['Document'];
  DraftContent: DraftContent;
  DraftContentHistory: DraftContentHistory;
  DraftContentHistoryPagination: DraftContentHistoryPagination;
  DraftContentHistorySearch: DraftContentHistorySearch;
  DraftContentIdInput: DraftContentIdInput;
  DraftContentInput: DraftContentInput;
  DraftContentNotification: DraftContentNotification;
  DraftContentPagination: DraftContentPagination;
  DraftContentSearch: DraftContentSearch;
  DraftContentWorkflow: DraftContentWorkflow;
  DraftUserContentInteraction: DraftUserContentInteraction;
  DraftUserContentInteractionInput: DraftUserContentInteractionInput;
  DraftUserContentInteractionSearch: DraftUserContentInteractionSearch;
  DynamicSectionInfo: DynamicSectionInfo;
  EmailAddress: Scalars['EmailAddress']['output'];
  Entity: Entity;
  Equipment: Equipment;
  EquipmentForExport: EquipmentForExport;
  EquipmentInput: EquipmentInput;
  EquipmentSearch: EquipmentSearch;
  EquipmentSearchResults: EquipmentSearchResults;
  EscalationRule: EscalationRule;
  Essensys: Essensys;
  EssensysAccount: EssensysAccount;
  EssensysCalendar: EssensysCalendar;
  EssensysContact: EssensysContact;
  EssensysInvoice: EssensysInvoice;
  EssensysMutation: EssensysMutation;
  EssensysPayment: EssensysPayment;
  EssensysPaymentProcessor: EssensysPaymentProcessor;
  EssensysPaymentSettings: EssensysPaymentSettings;
  EssensysProduct: EssensysProduct;
  EventDetail: EventDetail;
  EventSubscription: EventSubscription;
  EventSubscriptionInput: EventSubscriptionInput;
  EventType: Scalars['EventType']['output'];
  Exception: Exception;
  ExportCSVTasksReponseDTO: ExportCsvTasksReponseDto;
  ExternalUrl: ExternalUrl;
  ExternalUrlInput: ExternalUrlInput;
  Features: Features;
  FeedbackForm: FeedbackForm;
  FeedbackFormInput: FeedbackFormInput;
  FindSchedulesFilter: FindSchedulesFilter;
  FindSchedulesFilterOptions: FindSchedulesFilterOptions;
  FindSchedulesReponseDTO: FindSchedulesReponseDto;
  FindSchedulesSearch: FindSchedulesSearch;
  FindTasksSearch: FindTasksSearch;
  FlatfileToken: FlatfileToken;
  Float: Scalars['Float']['output'];
  FloatSearch: FloatSearch;
  Floor: Floor;
  FloorInput: FloorInput;
  FloorMapGenerationStatus: FloorMapGenerationStatus;
  GeoPoint: Scalars['GeoPoint']['output'];
  GeoSearch: GeoSearch;
  GetConfigRequest: GetConfigRequest;
  GetConfigResponse: GetConfigResponse;
  GetFinalPriceForProductsRequest: GetFinalPriceForProductsRequest;
  GetFinalPriceForProductsResponse: GetFinalPriceForProductsResponse;
  GetGroupSettingRequest: GetGroupSettingRequest;
  GetIntegrationProcessesInput: GetIntegrationProcessesInput;
  GetIntegrationProcessesResponse: GetIntegrationProcessesResponse;
  GetInvoiceDetailsRequest: GetInvoiceDetailsRequest;
  GetInvoiceDetailsResponse: GetInvoiceDetailsResponse;
  GetProductExceptionRequest: GetProductExceptionRequest;
  GetProductExceptionResponse: GetProductExceptionResponse;
  GetProductRequest: GetProductRequest;
  GetProductResponse: GetProductResponse;
  GetRecurringChargeRequest: GetRecurringChargeRequest;
  GetRecurringChargeResponse: GetRecurringChargeResponse;
  GetRelayOutputList: GetRelayOutputList;
  GetTransactionStatusRequest: GetTransactionStatusRequest;
  GetTransactionStatusResponse: GetTransactionStatusResponse;
  GetUserByExternalIdRequest: GetUserByExternalIdRequest;
  GetUserByExternalIdResponse: GetUserByExternalIdResponse;
  GetUserCreditWalletsRequest: GetUserCreditWalletsRequest;
  GetUserCreditWalletsResponse: GetUserCreditWalletsResponse;
  Group: Group;
  GroupInput: GroupInput;
  GroupRole: GroupRole;
  GroupRoleIdInput: GroupRoleIdInput;
  GroupRoleInput: GroupRoleInput;
  GroupRoleJoinRule: GroupRoleJoinRule;
  GroupRoleJoinRuleInput: GroupRoleJoinRuleInput;
  GroupRoleSearch: GroupRoleSearch;
  GroupSettingRequest: GroupSettingRequest;
  GroupSettingResponse: GroupSettingResponse;
  HOMBuilding: HomBuilding;
  HOMDate: HomDate;
  HOMEvent: HomEvent;
  HOMTeacher: HomTeacher;
  HOMVenue: HomVenue;
  Hardware: Hardware;
  HardwareAccessLog: HardwareAccessLog;
  HardwareAccessLogPagination: HardwareAccessLogPagination;
  HardwareAccessLogsSearch: HardwareAccessLogsSearch;
  HardwareDeviceInfo: HardwareDeviceInfo;
  HardwareInput: HardwareInput;
  HardwarePagination: HardwarePagination;
  HardwareSearch: HardwareSearch;
  HardwareSettings: HardwareSettings;
  Host: Host;
  HyperLink: HyperLink;
  HyperLinkInput: HyperLinkInput;
  ID: Scalars['ID']['output'];
  IdNamePair: IdNamePair;
  Image: Scalars['Image']['output'];
  InputCreateEscalationRule: InputCreateEscalationRule;
  InputUpdateEscalationRule: InputUpdateEscalationRule;
  Int: Scalars['Int']['output'];
  IntSearch: IntSearch;
  Integration: Integration;
  IntegrationInput: IntegrationInput;
  IntegrationProcess: IntegrationProcess;
  IntegrationSearch: IntegrationSearch;
  Invoice: Invoice;
  InvoiceAutopaymentDetails: InvoiceAutopaymentDetails;
  InvoiceFilter: InvoiceFilter;
  InvoicePartial: InvoicePartial;
  Issue: Issue;
  IssueUpsertRequestDTO: IssueUpsertRequestDto;
  IssueUpsertResponseDTO: IssueUpsertResponseDto;
  JSON: Scalars['JSON']['output'];
  JobResponse: JobResponse;
  JobsResponse: JobsResponse;
  LayoutDesignSystem: LayoutDesignSystem;
  LayoutDesignSystemInput: LayoutDesignSystemInput;
  Library: Library;
  LibraryAccess: LibraryAccess;
  LibraryAccessInput: LibraryAccessInput;
  LibraryAccessSearch: LibraryAccessSearch;
  LibraryIdInput: LibraryIdInput;
  LibraryInput: LibraryInput;
  LibraryItem: LibraryItem;
  LibraryItemInput: LibraryItemInput;
  LibraryItemPagination: LibraryItemPagination;
  LibraryItemSearch: LibraryItemSearch;
  LibraryItemWithOriginalExtension: LibraryItemWithOriginalExtension;
  LibraryPagination: LibraryPagination;
  LibrarySearch: LibrarySearch;
  LinkedEntity: LinkedEntity;
  LinkedEntityInput: LinkedEntityInput;
  LinkedEntityPagination: LinkedEntityPagination;
  LinkedEntitySearch: LinkedEntitySearch;
  ListChargeCodeFilter: ListChargeCodeFilter;
  ListChargeCodeRequest: ListChargeCodeRequest;
  ListChargeCodeResponse: ListChargeCodeResponse;
  ListChargesRequest: ListChargesRequest;
  ListChargesResponse: ListChargesResponse;
  ListInvoicesByPayersFilter: ListInvoicesByPayersFilter;
  ListInvoicesByPayersRequest: ListInvoicesByPayersRequest;
  ListInvoicesByPayersResponse: ListInvoicesByPayersResponse;
  ListInvoicesRequest: ListInvoicesRequest;
  ListInvoicesResponse: ListInvoicesResponse;
  ListProductCategoriesRequest: ListProductCategoriesRequest;
  ListProductCategoriesResponse: ListProductCategoriesResponse;
  ListProductExceptionsRequest: ListProductExceptionsRequest;
  ListProductExceptionsResponse: ListProductExceptionsResponse;
  ListProductsResponse: ListProductsResponse;
  ListRecurringChargesRequest: ListRecurringChargesRequest;
  ListRecurringChargesResponse: ListRecurringChargesResponse;
  ListTransactionsRequest: ListTransactionsRequest;
  ListTransactionsResponse: ListTransactionsResponse;
  Location: Location;
  LocationUpsertRequestDTO: LocationUpsertRequestDto;
  LocationUpsertResponseDTO: LocationUpsertResponseDto;
  Markup: Markup;
  MarkupInput: MarkupInput;
  Me: Me;
  MeMutation: MeMutation;
  Media: Media;
  MediaContentType: Scalars['MediaContentType']['output'];
  MediaInput: MediaInput;
  MediaPagination: MediaPagination;
  MediaSearch: MediaSearch;
  MediaWithOriginalExtension: MediaWithOriginalExtension;
  Metatag: Metatag;
  MetatagFilter: MetatagFilter;
  MetatagFilterInput: MetatagFilterInput;
  MetatagIdInput: MetatagIdInput;
  MetatagInput: MetatagInput;
  MetatagPagination: MetatagPagination;
  MetatagSearch: MetatagSearch;
  MetatagUse: MetatagUse;
  MetatagValue: MetatagValue;
  MetatagValueInput: MetatagValueInput;
  MetatagValueSearch: MetatagValueSearch;
  MeterReadingSetting: MeterReadingSetting;
  MeterReadingSettingInput: MeterReadingSettingInput;
  MeterReadingValue: MeterReadingValue;
  MeterReadingValueForExport: MeterReadingValueForExport;
  MeterReadingValueInput: MeterReadingValueInput;
  MeterReadingValueResponseType: MeterReadingValueResponseType;
  MeterReadingValueSearch: MeterReadingValueSearch;
  MeterReadingValueSearchResults: MeterReadingValueSearchResults;
  MobileAccess: MobileAccess;
  MobileAccessChannel: MobileAccessChannel;
  MultiChannelAcgs: MultiChannelAcgs;
  Mutation: {};
  OAuth: OAuth;
  OAuthConfig: OAuthConfig;
  OAuthConfigInput: OAuthConfigInput;
  OIDCServiceProviderDefinitionInput: OidcServiceProviderDefinitionInput;
  ObjectAnalytics: ObjectAnalytics;
  ObjectAnalyticsInput: ObjectAnalyticsInput;
  ObjectCount: ObjectCount;
  ObjectDatasetRecord: ObjectDatasetRecord;
  ObjectMetatag: ObjectMetatag;
  ObjectMetatagIdInput: ObjectMetatagIdInput;
  ObjectMetatagInput: ObjectMetatagInput;
  ObjectMetatagSearch: ObjectMetatagSearch;
  ObjectMetatagValue: ObjectMetatagValue;
  ObjectMetatagValueInput: ObjectMetatagValueInput;
  ObjectMetatagValueSearch: ObjectMetatagValueSearch;
  OidcServiceProviderDefinition: OidcServiceProviderDefinition;
  OneTimePassword: OneTimePassword;
  OnlinePlanQuote: OnlinePlanQuote;
  OpenpathSetupMobileToken: OpenpathSetupMobileToken;
  OrderItemDetails: OrderItemDetails;
  OrderSummary: OrderSummary;
  Owner: Owner;
  OwnerInput: OwnerInput;
  PMSchedule: PmSchedule;
  PMSchedulesForExportResponseDTO: PmSchedulesForExportResponseDto;
  PageInfo: PageInfo;
  PagesOnChannel: PagesOnChannel;
  PaginatedSections: PaginatedSections;
  PaginationInput: PaginationInput;
  ParkWhizAmenitiy: ParkWhizAmenitiy;
  ParkWhizBookPreview: ParkWhizBookPreview;
  ParkWhizBooking: ParkWhizBooking;
  ParkWhizBookingEmbedded: ParkWhizBookingEmbedded;
  ParkWhizByGeoLocation: ParkWhizByGeoLocation;
  ParkWhizCoordinate: ParkWhizCoordinate;
  ParkWhizDistance: ParkWhizDistance;
  ParkWhizIcon: ParkWhizIcon;
  ParkWhizLocation: ParkWhizLocation;
  ParkWhizParkingPass: ParkWhizParkingPass;
  ParkWhizPhoto: ParkWhizPhoto;
  ParkWhizPhotoSizes: ParkWhizPhotoSizes;
  ParkWhizPhotos: ParkWhizPhotos;
  ParkWhizPricingSegments: ParkWhizPricingSegments;
  ParkWhizPurchaseOption: ParkWhizPurchaseOption;
  ParkWhizScanCode: ParkWhizScanCode;
  ParkWhizSpaceAvailability: ParkWhizSpaceAvailability;
  ParkWhizStraightLine: ParkWhizStraightLine;
  ParkWhizValidation: ParkWhizValidation;
  ParkWhizValidationStep: ParkWhizValidationStep;
  ParkWhizVehicle: ParkWhizVehicle;
  PastVisitorPass: PastVisitorPass;
  PastVisitorPassResult: PastVisitorPassResult;
  Path: Scalars['Path']['output'];
  Payer: Payer;
  PayerFilter: PayerFilter;
  PayerInput: PayerInput;
  PaymentAccount: PaymentAccount;
  PaymentAccountIdInput: PaymentAccountIdInput;
  PaymentAccountInput: PaymentAccountInput;
  PaymentAccountRule: PaymentAccountRule;
  PaymentAccountRuleInput: PaymentAccountRuleInput;
  PaymentAccountSearch: PaymentAccountSearch;
  PaymentFeatureLineItem: PaymentFeatureLineItem;
  PaymentFeatureQuote: PaymentFeatureQuote;
  PaymentProcessor: PaymentProcessor;
  PaymentProcessorIdInput: PaymentProcessorIdInput;
  PaymentProcessorInput: PaymentProcessorInput;
  PaymentProcessorSearch: PaymentProcessorSearch;
  PaymentSource: PaymentSource;
  Payments: Payments;
  PaymentsMutation: PaymentsMutation;
  Permission: Scalars['Permission']['output'];
  PermissionDetail: PermissionDetail;
  PermissionListInput: PermissionListInput;
  PermissionListSearch: PermissionListSearch;
  Person: Person;
  PhoneNumber: Scalars['PhoneNumber']['output'];
  PlacePayInfo: PlacePayInfo;
  Portal: Portal;
  PostalCode: Scalars['PostalCode']['output'];
  Price: Price;
  PriceInput: PriceInput;
  Product: Product;
  ProductCategoriesFilter: ProductCategoriesFilter;
  ProductCategory: ProductCategory;
  ProductExceptionsFilter: ProductExceptionsFilter;
  ProductFinalPrice: ProductFinalPrice;
  ProductInput: ProductInput;
  ProductItem: ProductItem;
  ProductItemInput: ProductItemInput;
  ProductLineItem: ProductLineItem;
  ProductTax: ProductTax;
  ProductTaxInput: ProductTaxInput;
  ProductsFilter: ProductsFilter;
  Profile: Profile;
  ProfileInput: ProfileInput;
  ProfileSearch: ProfileSearch;
  Property: Property;
  ProxyClickCompany: ProxyClickCompany;
  ProxyClickHost: ProxyClickHost;
  ProxyClickMeeting: ProxyClickMeeting;
  ProxyClickVisit: ProxyClickVisit;
  ProxyClickVisitPagination: ProxyClickVisitPagination;
  ProxyClickVisitStatus: ProxyClickVisitStatus;
  ProxyClickVisitor: ProxyClickVisitor;
  ProxyClickVisitorInput: ProxyClickVisitorInput;
  PublicProfile: PublicProfile;
  PublicTeam: PublicTeam;
  PublicUser: PublicUser;
  PublicUserGroupRole: PublicUserGroupRole;
  PublicUserPagination: PublicUserPagination;
  PublicUserSearch: PublicUserSearch;
  QRCode: QrCode;
  QuantityWaitlistByContent: QuantityWaitlistByContent;
  QuantityWaitlistCompany: QuantityWaitlistCompany;
  QuantityWaitlistPagination: QuantityWaitlistPagination;
  QuantityWaitlistSearch: QuantityWaitlistSearch;
  QuantityWaitlistUser: QuantityWaitlistUser;
  Query: {};
  Receivable: Receivable;
  ReceivableInput: ReceivableInput;
  RecentJobsRequest: RecentJobsRequest;
  Recurrence: Recurrence;
  RecurringCharge: RecurringCharge;
  RecurringChargesFilters: RecurringChargesFilters;
  ReformedChannel: ReformedChannel;
  RefundTransactionRequest: RefundTransactionRequest;
  RefundTransactionResponse: RefundTransactionResponse;
  RegisterKioskVisitorPassScanResponse: RegisterKioskVisitorPassScanResponse;
  RelayAccessPoint: RelayAccessPoint;
  RelayAction: RelayAction;
  RelayModule: RelayModule;
  RelayOutput: RelayOutput;
  RelaySearch: RelaySearch;
  RemovePaymentMethodRequest: RemovePaymentMethodRequest;
  RemovePaymentMethodResponse: RemovePaymentMethodResponse;
  ReportSearch: ReportSearch;
  Requirement: Scalars['Requirement']['output'];
  RequirementDetail: RequirementDetail;
  RequirementFeatureContentInteractionProperties: RequirementFeatureContentInteractionProperties;
  RequirementFeatureContentInteractionPropertiesId: RequirementFeatureContentInteractionPropertiesId;
  RequirementFeatureContentInteractionPropertiesIdInput: RequirementFeatureContentInteractionPropertiesIdInput;
  RequirementFeatureContentInteractionPropertiesInput: RequirementFeatureContentInteractionPropertiesInput;
  RequirementListInput: RequirementListInput;
  RequirementListSearch: RequirementListSearch;
  RequirementsFeatureProperties: RequirementsFeatureProperties;
  RequirementsFeaturePropertiesInput: RequirementsFeaturePropertiesInput;
  RequirementsSource: RequirementsSource;
  Reservable: Reservable;
  ReservableAvailability: ReservableAvailability;
  ReservableBooking: ReservableBooking;
  ReservableBookingPagination: ReservableBookingPagination;
  ReservableBookingSearch: ReservableBookingSearch;
  ReservableConnection: ReservableConnection;
  ReservableEdge: ReservableEdge;
  ReservableWaitlistByContent: ReservableWaitlistByContent;
  ReservableWaitlistCompany: ReservableWaitlistCompany;
  ReservableWaitlistEnrollment: ReservableWaitlistEnrollment;
  ReservableWaitlistPagination: ReservableWaitlistPagination;
  ReservableWaitlistSearch: ReservableWaitlistSearch;
  ReservableWaitlistUnrollment: ReservableWaitlistUnrollment;
  ReservableWaitlistUser: ReservableWaitlistUser;
  ReservablesOnChannelSearchInput: ReservablesOnChannelSearchInput;
  Resident: Resident;
  RiseMigrationFeatures: RiseMigrationFeatures;
  SAMLData: SamlData;
  SAMLServiceProviderDefinition: SamlServiceProviderDefinition;
  SAMLServiceProviderDefinitionInput: SamlServiceProviderDefinitionInput;
  SaveConfigRequest: SaveConfigRequest;
  SaveConfigResponse: SaveConfigResponse;
  SavePaymentMethodRequest: SavePaymentMethodRequest;
  SavePaymentMethodResponse: SavePaymentMethodResponse;
  ScheduledVisitorPass: ScheduledVisitorPass;
  ScheduledVisitorPassResult: ScheduledVisitorPassResult;
  SearchOptionFilter: SearchOptionFilter;
  SearchOptionFilterInput: SearchOptionFilterInput;
  SearchOptions: SearchOptions;
  SearchOptionsInput: SearchOptionsInput;
  SearchServiceRequest: SearchServiceRequest;
  SearchTasksReponseDTO: SearchTasksReponseDto;
  Section: Section;
  SectionContent: SectionContent;
  SectionContentInput: SectionContentInput;
  SectionContentSearch: SectionContentSearch;
  SectionIdInput: SectionIdInput;
  SectionInput: SectionInput;
  SectionSearch: SectionSearch;
  SellingPrice: SellingPrice;
  SellingPriceInput: SellingPriceInput;
  ServiceRequest: ServiceRequest;
  ServiceRequestAction: ServiceRequestAction;
  ServiceRequestAuditLog: ServiceRequestAuditLog;
  ServiceRequestCategoriesList: ServiceRequestCategoriesList;
  ServiceRequestDTO: ServiceRequestDto;
  ServiceRequestFeatures: ServiceRequestFeatures;
  ServiceRequestFilter: ServiceRequestFilter;
  ServiceRequestIssuesList: ServiceRequestIssuesList;
  ServiceRequestLocationsList: ServiceRequestLocationsList;
  ServiceRequestPreFilter: ServiceRequestPreFilter;
  ServiceRequestSearchResults: ServiceRequestSearchResults;
  ServiceRequestUpdateResponseDTO: ServiceRequestUpdateResponseDto;
  ServiceRequestsFilterFieldsDTO: ServiceRequestsFilterFieldsDto;
  ServiceRequestsList: ServiceRequestsList;
  Session: Session;
  SessionInput: SessionInput;
  SessionSearch: SessionSearch;
  ShopifyCustomer: ShopifyCustomer;
  ShopifyMultipassLoginDetail: ShopifyMultipassLoginDetail;
  Sort: Sort;
  SortBy: SortBy;
  SourceEntityIdentifier: SourceEntityIdentifier;
  SourceEntityIdentifierInput: SourceEntityIdentifierInput;
  SpecialInvite: SpecialInvite;
  StartImportJobRequest: StartImportJobRequest;
  StartImportJobResponse: StartImportJobResponse;
  StepExecution: StepExecution;
  StepExecutionOption: StepExecutionOption;
  StepTemplate: StepTemplate;
  StepTemplateDTO: StepTemplateDto;
  StepTemplateOption: StepTemplateOption;
  StepTemplateOptionDTO: StepTemplateOptionDto;
  StepsRequest: StepsRequest;
  StepsRequestOption: StepsRequestOption;
  String: Scalars['String']['output'];
  StringListInput: StringListInput;
  StringListSearch: StringListSearch;
  StringSearch: StringSearch;
  StripeAccount: StripeAccount;
  StripeAccountInput: StripeAccountInput;
  StripeAccountLink: StripeAccountLink;
  StripeAccountVerification: StripeAccountVerification;
  StripeAddress: StripeAddress;
  StripeAddressInput: StripeAddressInput;
  StripeBankAccount: StripeBankAccount;
  StripeBankAccountInput: StripeBankAccountInput;
  StripeDate: StripeDate;
  StripeDateInput: StripeDateInput;
  StripeDeclineCharge: StripeDeclineCharge;
  StripeDeclineChargeInput: StripeDeclineChargeInput;
  StripeInfo: StripeInfo;
  StripeLegalEntity: StripeLegalEntity;
  StripeLegalEntityInput: StripeLegalEntityInput;
  StripePayoutSchedule: StripePayoutSchedule;
  StripePayoutScheduleInput: StripePayoutScheduleInput;
  StripeVerification: StripeVerification;
  StripeVeriticationInput: StripeVeriticationInput;
  StudioGroupRoleName: Scalars['StudioGroupRoleName']['output'];
  SubscriptionDetails: SubscriptionDetails;
  Suite: Suite;
  SuiteInput: SuiteInput;
  SupportedLocale: Scalars['SupportedLocale']['output'];
  SwitchChannel: SwitchChannel;
  SyncGroupRoleInput: SyncGroupRoleInput;
  Tag: Tag;
  TagInput: TagInput;
  TagOnSection: TagOnSection;
  TagOnSectionInput: TagOnSectionInput;
  TargetingRule: TargetingRule;
  TargetingRuleInput: TargetingRuleInput;
  TargetingRulesForAudience: TargetingRulesForAudience;
  Task: Task;
  TaskAction: TaskAction;
  TaskAuditLog: TaskAuditLog;
  TaskEquipmentDTO: TaskEquipmentDto;
  TaskFilterDTO: TaskFilterDto;
  TaskFilterOptionDTO: TaskFilterOptionDto;
  TaskPreFilterDTO: TaskPreFilterDto;
  TaskRepeatDTO: TaskRepeatDto;
  TaskScheduleDTO: TaskScheduleDto;
  Tax: Tax;
  TaxInput: TaxInput;
  Tenant: Tenant;
  TenantBuildingChannel: TenantBuildingChannel;
  TenantLease: TenantLease;
  TenantLeaseInput: TenantLeaseInput;
  Theme: Theme;
  ThemeColors: ThemeColors;
  ThemeIdInput: ThemeIdInput;
  ThemeInput: ThemeInput;
  ThemePalette: ThemePalette;
  ThemePaletteInput: ThemePaletteInput;
  ThemeSearch: ThemeSearch;
  Token: Token;
  Totals: Totals;
  TrackAnalytic: TrackAnalytic;
  Transaction: Transaction;
  TriggerAccessPointResponse: TriggerAccessPointResponse;
  TypographicDesignSystem: TypographicDesignSystem;
  TypographicDesignSystemInput: TypographicDesignSystemInput;
  TypographyElement: TypographyElement;
  TypographyElementInput: TypographyElementInput;
  TypographyLayoutElement: TypographyLayoutElement;
  TypographyLayoutElementInput: TypographyLayoutElementInput;
  URL: Scalars['URL']['output'];
  UUID: Scalars['UUID']['output'];
  UUIDListSearch: UuidListSearch;
  Unit: Unit;
  UnitInput: UnitInput;
  UnitsForResident: UnitsForResident;
  UpdateAccessPointRequest: UpdateAccessPointRequest;
  UpdateAudienceChannelTargetingRule: UpdateAudienceChannelTargetingRule;
  UpdateAudienceGroupRoleTargetingRule: UpdateAudienceGroupRoleTargetingRule;
  UpdateAudienceInput: UpdateAudienceInput;
  UpdateAudienceResponse: UpdateAudienceResponse;
  UpdateDraftChargeRequest: UpdateDraftChargeRequest;
  UpdateEquipmentInput: UpdateEquipmentInput;
  UpdateHardwareDeviceInfoInput: UpdateHardwareDeviceInfoInput;
  UpdateHardwareDeviceInfoResponse: UpdateHardwareDeviceInfoResponse;
  UpdateHardwareInput: UpdateHardwareInput;
  UpdateHardwareSettingsInput: UpdateHardwareSettingsInput;
  UpdateInvoiceAutopaymentDetailsRequest: UpdateInvoiceAutopaymentDetailsRequest;
  UpdateInvoiceAutopaymentDetailsResponse: UpdateInvoiceAutopaymentDetailsResponse;
  UpdateInvoiceToPaidRequest: UpdateInvoiceToPaidRequest;
  UpdateInvoiceToPaidResponse: UpdateInvoiceToPaidResponse;
  UpdateMeterReadingValueInput: UpdateMeterReadingValueInput;
  UpdatePMScheduleDTO: UpdatePmScheduleDto;
  UpdatePMScheduleResponseDTO: UpdatePmScheduleResponseDto;
  UpdateProductExceptionRequest: UpdateProductExceptionRequest;
  UpdateProductExceptionResponse: UpdateProductExceptionResponse;
  UpdateProductRequest: UpdateProductRequest;
  UpdateProductResponse: UpdateProductResponse;
  UpdateRecurrencePassesResponse: UpdateRecurrencePassesResponse;
  UpdateRelayModuleInput: UpdateRelayModuleInput;
  UpdateRelayOutputInput: UpdateRelayOutputInput;
  UpdateServiceRequestDTO: UpdateServiceRequestDto;
  UpdateTagInput: UpdateTagInput;
  UpdateTargetingRuleInput: UpdateTargetingRuleInput;
  UpdateTargetingRuleResponse: UpdateTargetingRuleResponse;
  UpdateTask: UpdateTask;
  UpdateTaskResponseDTO: UpdateTaskResponseDto;
  UpdateTaskStatus: UpdateTaskStatus;
  UpdateTaskSteps: UpdateTaskSteps;
  UploadCSVData: UploadCsvData;
  UploadCSVResponse: UploadCsvResponse;
  UpsertSubscriptionDetailsRequest: UpsertSubscriptionDetailsRequest;
  UpsertSubscriptionDetailsResponse: UpsertSubscriptionDetailsResponse;
  User: User;
  UserAccessCompany: UserAccessCompany;
  UserAccessDetails: UserAccessDetails;
  UserBooking: UserBooking;
  UserBuildingAccess: UserBuildingAccess;
  UserBuildingAccessMapping: UserBuildingAccessMapping;
  UserChannelSettings: UserChannelSettings;
  UserChannelSettingsInput: UserChannelSettingsInput;
  UserClearanceInput: UserClearanceInput;
  UserCompanyDetails: UserCompanyDetails;
  UserContentInteraction: UserContentInteraction;
  UserContentInteractionHistory: UserContentInteractionHistory;
  UserContentInteractionHistoryPagination: UserContentInteractionHistoryPagination;
  UserContentInteractionHistorySearch: UserContentInteractionHistorySearch;
  UserContentInteractionInput: UserContentInteractionInput;
  UserContentInteractionPagination: UserContentInteractionPagination;
  UserContentInteractionSearch: UserContentInteractionSearch;
  UserContentInteractionStateSearch: UserContentInteractionStateSearch;
  UserContentInteractionStatus: Scalars['UserContentInteractionStatus']['output'];
  UserEmail: UserEmail;
  UserFilters: UserFilters;
  UserGroupRole: UserGroupRole;
  UserGroupRoleInput: UserGroupRoleInput;
  UserGroupRolePagination: UserGroupRolePagination;
  UserGroupRoleRelatedChannel: UserGroupRoleRelatedChannel;
  UserGroupRoleSearch: UserGroupRoleSearch;
  UserIdInput: UserIdInput;
  UserInput: UserInput;
  UserIntegration: UserIntegration;
  UserLogin: UserLogin;
  UserLoginInput: UserLoginInput;
  UserLoginMethod: UserLoginMethod;
  UserLoginSearch: UserLoginSearch;
  UserObjectInfo: UserObjectInfo;
  UserObjectInfoInput: UserObjectInfoInput;
  UserPagination: UserPagination;
  UserSearch: UserSearch;
  UserSettings: UserSettings;
  UserSettingsInput: UserSettingsInput;
  UsersByChannelIdSearch: UsersByChannelIdSearch;
  Visitor: Visitor;
  VisitorEmailTemplate: VisitorEmailTemplate;
  VisitorEmailTemplateInput: VisitorEmailTemplateInput;
  VisitorInput: VisitorInput;
  VisitorLog: VisitorLog;
  VisitorOrganizationSettings: VisitorOrganizationSettings;
  VisitorOrganizationSettingsInput: VisitorOrganizationSettingsInput;
  VisitorPass: VisitorPass;
  VisitorPassAggregatesBySubmissionId: VisitorPassAggregatesBySubmissionId;
  VisitorPassHost: VisitorPassHost;
  VisitorPassHostProfile: VisitorPassHostProfile;
  VisitorPassRecurrence: VisitorPassRecurrence;
  VisitorPassTenant: VisitorPassTenant;
  VisitorPassTenantAddress: VisitorPassTenantAddress;
  VisitorPassTenantProfile: VisitorPassTenantProfile;
  VisitorPassUpdateResponse: VisitorPassUpdateResponse;
  Void: Scalars['Void']['output'];
  VoidChargeRequest: VoidChargeRequest;
  VoidChargeResponse: VoidChargeResponse;
  VtsAddress: VtsAddress;
  WaitlistUnEnrollment: WaitlistUnEnrollment;
  Weather: Weather;
  WeatherAlert: WeatherAlert;
  WeatherDataPoint: WeatherDataPoint;
  WeatherFlags: WeatherFlags;
  Webhook: Webhook;
  WebhookIdInput: WebhookIdInput;
  WebhookInput: WebhookInput;
  WebhookSearch: WebhookSearch;
  Whitelabel: Whitelabel;
  WhitelabelChannel: WhitelabelChannel;
  WhitelabelChannelInput: WhitelabelChannelInput;
  WhitelabelChannelSearch: WhitelabelChannelSearch;
  WhitelabelIdInput: WhitelabelIdInput;
  WhitelabelInput: WhitelabelInput;
  WhitelabelSearch: WhitelabelSearch;
  WhitelabelSettingsInput: WhitelabelSettingsInput;
  Workflow: Workflow;
  WorkflowInput: WorkflowInput;
  enableMobileAccessResponse: EnableMobileAccessResponse;
};

export type AdminDirectiveArgs = { };

export type AdminDirectiveResolver<Result, Parent, ContextType = any, Args = AdminDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AuthenticatedDirectiveArgs = { };

export type AuthenticatedDirectiveResolver<Result, Parent, ContextType = any, Args = AuthenticatedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CombineDirectiveArgs = {
  keys?: Maybe<Array<Scalars['String']['input']>>;
};

export type CombineDirectiveResolver<Result, Parent, ContextType = any, Args = CombineDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DbViewDirectiveArgs = { };

export type DbViewDirectiveResolver<Result, Parent, ContextType = any, Args = DbViewDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type GeoDistanceDirectiveArgs = {
  on?: Maybe<Scalars['String']['input']>;
};

export type GeoDistanceDirectiveResolver<Result, Parent, ContextType = any, Args = GeoDistanceDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type HardwareAuthenticatedDirectiveArgs = { };

export type HardwareAuthenticatedDirectiveResolver<Result, Parent, ContextType = any, Args = HardwareAuthenticatedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type JsonDirectiveArgs = { };

export type JsonDirectiveResolver<Result, Parent, ContextType = any, Args = JsonDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type KeyDirectiveArgs = {
  a: Scalars['String']['input'];
  b?: Maybe<Scalars['String']['input']>;
  c?: Maybe<Scalars['String']['input']>;
};

export type KeyDirectiveResolver<Result, Parent, ContextType = any, Args = KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type MeDirectiveArgs = { };

export type MeDirectiveResolver<Result, Parent, ContextType = any, Args = MeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type PrivateDirectiveArgs = { };

export type PrivateDirectiveResolver<Result, Parent, ContextType = any, Args = PrivateDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type RelDirectiveArgs = {
  key?: Maybe<RelationshipKeyType>;
  name?: Maybe<Scalars['String']['input']>;
  pattern?: Maybe<RelationshipPatternType>;
};

export type RelDirectiveResolver<Result, Parent, ContextType = any, Args = RelDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TableNameDirectiveArgs = {
  name: Scalars['String']['input'];
};

export type TableNameDirectiveResolver<Result, Parent, ContextType = any, Args = TableNameDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type UniqueDirectiveArgs = {
  with?: Maybe<Scalars['String']['input']>;
};

export type UniqueDirectiveResolver<Result, Parent, ContextType = any, Args = UniqueDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type VirtualDirectiveArgs = { };

export type VirtualDirectiveResolver<Result, Parent, ContextType = any, Args = VirtualDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AccessControlChannelTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessControlChannelType'] = ResolversParentTypes['AccessControlChannelType']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isConnectedByTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['AccessControlProfileType'], ParentType, ContextType>;
  propertyChannelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userRoles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessControlGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessControlGroup'] = ResolversParentTypes['AccessControlGroup']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessControlGroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessControlGroups'] = ResolversParentTypes['AccessControlGroups']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessControlProfileTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessControlProfileType'] = ResolversParentTypes['AccessControlProfileType']> = {
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessIntegration'] = ResolversParentTypes['AccessIntegration']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessLocation'] = ResolversParentTypes['AccessLocation']> = {
  backgroundImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  integrations?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessPoint'] = ResolversParentTypes['AccessPoint']> = {
  buildingId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessUser'] = ResolversParentTypes['AccessUser']> = {
  accessControlGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessUserControlGroups']>>>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  mobileAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  syncStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessUserChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessUserChannel'] = ResolversParentTypes['AccessUserChannel']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessUserControlGroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessUserControlGroups'] = ResolversParentTypes['AccessUserControlGroups']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccessUserRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccessUserRole'] = ResolversParentTypes['AccessUserRole']> = {
  accessRole?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactPairResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactPair'] = ResolversParentTypes['AccountContactPair']> = {
  account?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  clientType?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  contact?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountsMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountsMutations'] = ResolversParentTypes['AccountsMutations']> = {
  cancelRecurringCharge?: Resolver<Maybe<ResolversTypes['CancelRecurringChargeResponse']>, ParentType, ContextType, Partial<AccountsMutationsCancelRecurringChargeArgs>>;
  createBillingPaymentSettings?: Resolver<Maybe<ResolversTypes['GroupSettingResponse']>, ParentType, ContextType, Partial<AccountsMutationsCreateBillingPaymentSettingsArgs>>;
  createCharge?: Resolver<Maybe<ResolversTypes['CreateChargeResponse']>, ParentType, ContextType, RequireFields<AccountsMutationsCreateChargeArgs, 'createChargeRequest'>>;
  createChargeCode?: Resolver<Maybe<ResolversTypes['CreateChargeCodeResponse']>, ParentType, ContextType, Partial<AccountsMutationsCreateChargeCodeArgs>>;
  createRecurringCharge?: Resolver<Maybe<ResolversTypes['CreateRecurringChargeResponse']>, ParentType, ContextType, Partial<AccountsMutationsCreateRecurringChargeArgs>>;
  createTransaction?: Resolver<Maybe<ResolversTypes['CreateTransactionResponse']>, ParentType, ContextType, RequireFields<AccountsMutationsCreateTransactionArgs, 'contentChannelId' | 'createTransactionRequest'>>;
  createUser?: Resolver<Maybe<ResolversTypes['CreateUserResponse']>, ParentType, ContextType, Partial<AccountsMutationsCreateUserArgs>>;
  deleteDraftCharge?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, Partial<AccountsMutationsDeleteDraftChargeArgs>>;
  deleteInvoiceAutopayment?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, Partial<AccountsMutationsDeleteInvoiceAutopaymentArgs>>;
  refundTransaction?: Resolver<Maybe<ResolversTypes['RefundTransactionResponse']>, ParentType, ContextType, RequireFields<AccountsMutationsRefundTransactionArgs, 'refundTransactionRequest'>>;
  removePaymentMethod?: Resolver<Maybe<ResolversTypes['RemovePaymentMethodResponse']>, ParentType, ContextType, Partial<AccountsMutationsRemovePaymentMethodArgs>>;
  savePaymentMethod?: Resolver<Maybe<ResolversTypes['SavePaymentMethodResponse']>, ParentType, ContextType, Partial<AccountsMutationsSavePaymentMethodArgs>>;
  updateBillingPaymentSettings?: Resolver<Maybe<ResolversTypes['GroupSettingResponse']>, ParentType, ContextType, Partial<AccountsMutationsUpdateBillingPaymentSettingsArgs>>;
  updateDraftCharge?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<AccountsMutationsUpdateDraftChargeArgs, 'updateDraftChargeRequest'>>;
  updateInvoiceAutopaymentDetails?: Resolver<Maybe<ResolversTypes['UpdateInvoiceAutopaymentDetailsResponse']>, ParentType, ContextType, Partial<AccountsMutationsUpdateInvoiceAutopaymentDetailsArgs>>;
  updateInvoiceToPaid?: Resolver<Maybe<ResolversTypes['UpdateInvoiceToPaidResponse']>, ParentType, ContextType, Partial<AccountsMutationsUpdateInvoiceToPaidArgs>>;
  upsertSubscriptionDetails?: Resolver<ResolversTypes['UpsertSubscriptionDetailsResponse'], ParentType, ContextType, RequireFields<AccountsMutationsUpsertSubscriptionDetailsArgs, 'upsertSubscriptionDetailsRequest'>>;
  voidCharge?: Resolver<Maybe<ResolversTypes['VoidChargeResponse']>, ParentType, ContextType, Partial<AccountsMutationsVoidChargeArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountsQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountsQueries'] = ResolversParentTypes['AccountsQueries']> = {
  getBillingPaymentSettings?: Resolver<Maybe<ResolversTypes['GroupSettingResponse']>, ParentType, ContextType, Partial<AccountsQueriesGetBillingPaymentSettingsArgs>>;
  getInvoiceDetails?: Resolver<Maybe<ResolversTypes['GetInvoiceDetailsResponse']>, ParentType, ContextType, Partial<AccountsQueriesGetInvoiceDetailsArgs>>;
  getRecurringCharge?: Resolver<Maybe<ResolversTypes['GetRecurringChargeResponse']>, ParentType, ContextType, Partial<AccountsQueriesGetRecurringChargeArgs>>;
  getTransactionStatus?: Resolver<Maybe<ResolversTypes['GetTransactionStatusResponse']>, ParentType, ContextType, Partial<AccountsQueriesGetTransactionStatusArgs>>;
  getUserByExternalId?: Resolver<Maybe<ResolversTypes['GetUserByExternalIdResponse']>, ParentType, ContextType, Partial<AccountsQueriesGetUserByExternalIdArgs>>;
  getUserCreditWallets?: Resolver<Maybe<ResolversTypes['GetUserCreditWalletsResponse']>, ParentType, ContextType, Partial<AccountsQueriesGetUserCreditWalletsArgs>>;
  listBillingReports?: Resolver<Maybe<ResolversTypes['ChargeBillingReportsResponse']>, ParentType, ContextType, RequireFields<AccountsQueriesListBillingReportsArgs, 'group'>>;
  listChargeCode?: Resolver<Maybe<ResolversTypes['ListChargeCodeResponse']>, ParentType, ContextType, RequireFields<AccountsQueriesListChargeCodeArgs, 'listChargeCodeRequest'>>;
  listCharges?: Resolver<Maybe<ResolversTypes['ListChargesResponse']>, ParentType, ContextType, Partial<AccountsQueriesListChargesArgs>>;
  listInvoices?: Resolver<Maybe<ResolversTypes['ListInvoicesResponse']>, ParentType, ContextType, Partial<AccountsQueriesListInvoicesArgs>>;
  listInvoicesByCompanies?: Resolver<Maybe<ResolversTypes['ListInvoicesByPayersResponse']>, ParentType, ContextType, Partial<AccountsQueriesListInvoicesByCompaniesArgs>>;
  listInvoicesByUsers?: Resolver<Maybe<ResolversTypes['ListInvoicesByPayersResponse']>, ParentType, ContextType, Partial<AccountsQueriesListInvoicesByUsersArgs>>;
  listRecurringChargesForChannels?: Resolver<Maybe<ResolversTypes['ListRecurringChargesResponse']>, ParentType, ContextType, Partial<AccountsQueriesListRecurringChargesForChannelsArgs>>;
  listRecurringChargesForPayers?: Resolver<Maybe<ResolversTypes['ListRecurringChargesResponse']>, ParentType, ContextType, Partial<AccountsQueriesListRecurringChargesForPayersArgs>>;
  listTransactions?: Resolver<Maybe<ResolversTypes['ListTransactionsResponse']>, ParentType, ContextType, Partial<AccountsQueriesListTransactionsArgs>>;
  subscriptionDetails?: Resolver<ResolversTypes['SubscriptionDetails'], ParentType, ContextType, RequireFields<AccountsQueriesSubscriptionDetailsArgs, 'subscriptionDetailsId'>>;
  transaction?: Resolver<ResolversTypes['Transaction'], ParentType, ContextType, RequireFields<AccountsQueriesTransactionArgs, 'channelId' | 'transactionId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivateUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivateUser'] = ResolversParentTypes['ActivateUser']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  accessControlGroupIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  companies?: Resolver<Array<ResolversTypes['UserCompanyDetails']>, ParentType, ContextType>;
  credentials?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  integrationChannelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  mobileAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  multiIntegrationAcgs?: Resolver<Maybe<Array<Maybe<ResolversTypes['MultiChannelAcgs']>>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  syncStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  addressLine1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressLine2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weather?: Resolver<Maybe<ResolversTypes['Weather']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllowedFeaturesResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllowedFeatures'] = ResolversParentTypes['AllowedFeatures']> = {
  migrateAmenities?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateCatUsers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateCommunityNews?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateDiscoveryLinks?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateEvents?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateForms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateNonReservableAmenities?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateTenants?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  migrateUsers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AnalyticTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AnalyticType'], any> {
  name: 'AnalyticType';
}

export type AngusBuildingResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusBuilding'] = ResolversParentTypes['AngusBuilding']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  floors?: Resolver<Maybe<Array<Maybe<ResolversTypes['AngusFloor']>>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusFloorResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusFloor'] = ResolversParentTypes['AngusFloor']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suites?: Resolver<Array<Maybe<ResolversTypes['AngusSuite']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusLocation'] = ResolversParentTypes['AngusLocation']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusRequestTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusRequestType'] = ResolversParentTypes['AngusRequestType']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  canCancel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusRequesterResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusRequester'] = ResolversParentTypes['AngusRequester']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusSuiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusSuite'] = ResolversParentTypes['AngusSuite']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusTenantResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusTenant'] = ResolversParentTypes['AngusTenant']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusWorkOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusWorkOrder'] = ResolversParentTypes['AngusWorkOrder']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  buildingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buildingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  requestTypeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requesterId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requesterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['WorkOrderStatus']>, ParentType, ContextType>;
  suite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AngusWorkOrderConfirmationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AngusWorkOrderConfirmation'] = ResolversParentTypes['AngusWorkOrderConfirmation']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiKeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiKey'] = ResolversParentTypes['ApiKey']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _lastUsed?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ApiKeyStatusEnum'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppleWalletIssuanceTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppleWalletIssuanceToken'] = ResolversParentTypes['AppleWalletIssuanceToken']> = {
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  issuanceToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  entityId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  entityType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  fileSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  s3Bucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  thumbnailSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Audience'] = ResolversParentTypes['Audience']> = {
  Channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  channelTargetingRules?: Resolver<Array<Maybe<ResolversTypes['TargetingRule']>>, ParentType, ContextType>;
  contentToTarget?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  createdByChannel?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  groupRoleTargetingRules?: Resolver<Array<Maybe<ResolversTypes['TargetingRule']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  managementChannels?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reachSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shareChannels?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudiencePaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudiencePagination'] = ResolversParentTypes['AudiencePagination']> = {
  items?: Resolver<Array<ResolversTypes['Audience']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudiencesToAttachContentToResolvers<ContextType = any, ParentType extends ResolversParentTypes['AudiencesToAttachContentTo'] = ResolversParentTypes['AudiencesToAttachContentTo']> = {
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth0ConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth0Connection'] = ResolversParentTypes['Auth0Connection']> = {
  connection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  connectionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  domainAlias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth0DomainHomeRealmDiscoveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth0DomainHomeRealmDiscovery'] = ResolversParentTypes['Auth0DomainHomeRealmDiscovery']> = {
  connections?: Resolver<Maybe<Array<ResolversTypes['Auth0Connection']>>, ParentType, ContextType>;
  hasHRD?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth0MembershipInvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth0MembershipInvitation'] = ResolversParentTypes['Auth0MembershipInvitation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ticketId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth0OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth0Organization'] = ResolversParentTypes['Auth0Organization']> = {
  auth0OrganizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth0ProvisionUserToOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth0ProvisionUserToOrganization'] = ResolversParentTypes['Auth0ProvisionUserToOrganization']> = {
  organisation?: Resolver<Maybe<ResolversTypes['Auth0Organization']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Auth0User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth0UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth0User'] = ResolversParentTypes['Auth0User']> = {
  connection?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AutoSetupCesEntityResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AutoSetupCesEntityResponse'] = ResolversParentTypes['AutoSetupCesEntityResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BiltSsoResolvers<ContextType = any, ParentType extends ResolversParentTypes['BiltSso'] = ResolversParentTypes['BiltSso']> = {
  ssoLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BinaryScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Binary'], any> {
  name: 'Binary';
}

export type BlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['Block'] = ResolversParentTypes['Block']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  availableForContentTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContentType']>>>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  dependencies?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  for?: Resolver<Maybe<ResolversTypes['BlockForType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  propertiesOptions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  publishes?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  renderer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  styleState?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['BlockType']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BluetoothInvitationCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BluetoothInvitationCode'] = ResolversParentTypes['BluetoothInvitationCode']> = {
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invitationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingCompany'] = ResolversParentTypes['BookingCompany']> = {
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  joinedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuildingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Building'] = ResolversParentTypes['Building']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalBuildingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  suites?: Resolver<Maybe<Array<Maybe<ResolversTypes['Suite']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuildingTenantChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuildingTenantChannel'] = ResolversParentTypes['BuildingTenantChannel']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  hasFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  inviteOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ByteScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Byte'], any> {
  name: 'Byte';
}

export type CancelRecurringChargeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelRecurringChargeResponse'] = ResolversParentTypes['CancelRecurringChargeResponse']> = {
  recurrenceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelSubmissionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelSubmissionResponse'] = ResolversParentTypes['CancelSubmissionResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  autoAssigneeTeam?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autoAssigneeUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryUpsertResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryUpsertResponseDTO'] = ResolversParentTypes['CategoryUpsertResponseDTO']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Channel'] = ResolversParentTypes['Channel']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  analytics?: Resolver<ResolversTypes['ObjectAnalytics'], ParentType, ContextType>;
  building?: Resolver<Maybe<Array<Maybe<ResolversTypes['Building']>>>, ParentType, ContextType>;
  channelModules?: Resolver<Maybe<Array<ResolversTypes['ChannelModule']>>, ParentType, ContextType>;
  content?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType, Partial<ChannelContentArgs>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  experienceType?: Resolver<Maybe<ResolversTypes['ChannelExperienceTypeEnum']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  groupRoles?: Resolver<Maybe<Array<ResolversTypes['GroupRole']>>, ParentType, ContextType>;
  hasCustomOffboarding?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hierarchy?: Resolver<Maybe<ResolversTypes['ChannelHierarchy']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  integrations?: Resolver<Maybe<Array<ResolversTypes['ChannelIntegration']>>, ParentType, ContextType>;
  interactions?: Resolver<Maybe<Array<ResolversTypes['UserContentInteraction']>>, ParentType, ContextType>;
  inviteOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isDiscoverable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSub?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  libraryPaths?: Resolver<Maybe<Array<ResolversTypes['Path']>>, ParentType, ContextType>;
  libraryTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  merchantAccount?: Resolver<Maybe<ResolversTypes['ChannelMerchant']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notices?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>;
  pages?: Resolver<Maybe<Array<ResolversTypes['ChannelPage']>>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  paymentAccounts?: Resolver<Maybe<Array<ResolversTypes['PaymentAccount']>>, ParentType, ContextType>;
  paymentProcessors?: Resolver<Maybe<Array<ResolversTypes['PaymentProcessor']>>, ParentType, ContextType>;
  perks?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  promotions?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>;
  reservablesOnChannel?: Resolver<Maybe<ResolversTypes['ReservableConnection']>, ParentType, ContextType, RequireFields<ChannelReservablesOnChannelArgs, 'first'>>;
  roles?: Resolver<Maybe<Array<ResolversTypes['UserGroupRole']>>, ParentType, ContextType>;
  sections?: Resolver<Maybe<Array<ResolversTypes['Section']>>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['ChannelSettings']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  special?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  specialPermissions?: Resolver<Maybe<Array<ResolversTypes['Permission']>>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['ChannelStats']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  team?: Resolver<Maybe<Array<ResolversTypes['PublicTeam']>>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['Theme']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ChannelType'], ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whitelabels?: Resolver<Maybe<Array<ResolversTypes['WhitelabelChannel']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelAccessIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelAccessIntegration'] = ResolversParentTypes['ChannelAccessIntegration']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  connectedIntegrations?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessIntegration']>>>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['AccessIntegration']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelContract'] = ResolversParentTypes['ChannelContract']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  contactUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  lastContact?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lineItems?: Resolver<Array<ResolversTypes['ChannelContractLineItem']>, ParentType, ContextType>;
  managerUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  supportUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelContractLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelContractLineItem'] = ResolversParentTypes['ChannelContractLineItem']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channelContract?: Resolver<ResolversTypes['ChannelContract'], ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimatedSubscriptionStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pricePerUnit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subscriptionStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  termLengthMonths?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  unitType?: Resolver<ResolversTypes['ContractUnitType'], ParentType, ContextType>;
  units?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelContractPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelContractPagination'] = ResolversParentTypes['ChannelContractPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['ChannelContract']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelEquipmentSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelEquipmentSettings'] = ResolversParentTypes['ChannelEquipmentSettings']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelHierarchyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelHierarchy'] = ResolversParentTypes['ChannelHierarchy']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  hierarchyDown?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  hierarchyUp?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelIntegration'] = ResolversParentTypes['ChannelIntegration']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  integration?: Resolver<ResolversTypes['Integration'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelInvite'] = ResolversParentTypes['ChannelInvite']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expires?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  groupRole?: Resolver<ResolversTypes['GroupRole'], ParentType, ContextType>;
  groupRoleIds?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  groupRoles?: Resolver<Maybe<Array<ResolversTypes['GroupRole']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  postInviteActions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  sent?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ChannelInviteStatusType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelInvitePaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelInvitePagination'] = ResolversParentTypes['ChannelInvitePagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['ChannelInvite']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelMerchantResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelMerchant'] = ResolversParentTypes['ChannelMerchant']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  account?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  flatFee?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onboardingLink?: Resolver<Maybe<ResolversTypes['StripeAccountLink']>, ParentType, ContextType>;
  percentageFee?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeAccount?: Resolver<Maybe<ResolversTypes['StripeAccount']>, ParentType, ContextType>;
  taxes?: Resolver<Maybe<Array<ResolversTypes['Tax']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelModuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelModule'] = ResolversParentTypes['ChannelModule']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  allowsMultipleInstances?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  autoCreatesContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  availableFeatures?: Resolver<Maybe<Array<Maybe<ResolversTypes['FeatureName']>>>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>;
  createsAdminPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dataDefinition?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  friendlyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['ContentTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelPageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelPage'] = ResolversParentTypes['ChannelPage']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iconSet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iconWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelPagination'] = ResolversParentTypes['ChannelPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['Channel']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelRelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelRelationship'] = ResolversParentTypes['ChannelRelationship']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  relatedTo?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  suiteIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ChannelRelationshipType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelRelationshipPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelRelationshipPagination'] = ResolversParentTypes['ChannelRelationshipPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['ChannelRelationship']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelSettings'] = ResolversParentTypes['ChannelSettings']> = {
  areUserTemplatesAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  channelLanguages?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  effectiveParentRequirements?: Resolver<Maybe<ResolversTypes['RequirementsFeatureProperties']>, ParentType, ContextType>;
  enabledLanguagesForMembers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  groupRoleJoinRules?: Resolver<Maybe<Array<ResolversTypes['GroupRoleJoinRule']>>, ParentType, ContextType>;
  hasAccessControlEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasBillingPaymentsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasHardwareManagementEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasHardwarePinManagementEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasInstantMessagingEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasLocalRetailPromotions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasMessagingAndMarketplaceEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasOfficeDirectory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPerksEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasReservableManagementEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRetailDirectory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRetailPromotions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSmartUserManagementEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSurveysEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasVisitorManagementEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasWorkOrderCrossPropertyEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasWorkOrderEquipmentEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasWorkOrderPreventiveMaintenanceEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasWorkOrderServiceRequestsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasWorkOrdersEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  maxUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  multiLanguageEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  offboardingDelayDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requirements?: Resolver<Maybe<ResolversTypes['RequirementsFeatureProperties']>, ParentType, ContextType>;
  subscribersAlsoJoin?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  subscribersAreDecoyed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  subscribersJoinParent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelStats'] = ResolversParentTypes['ChannelStats']> = {
  sf?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscribers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Charge'] = ResolversParentTypes['Charge']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalActorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalActorType?: Resolver<ResolversTypes['ExternalActorType'], ParentType, ContextType>;
  externalEntityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityType?: Resolver<Maybe<ResolversTypes['ExternalEntityType']>, ParentType, ContextType>;
  externalPayerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalPayerType?: Resolver<ResolversTypes['ExternalPayerType'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<Maybe<ResolversTypes['GroupType']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['InvoicePartial']>, ParentType, ContextType>;
  invoiceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductItem']>>>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeBillingReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeBillingReport'] = ResolversParentTypes['ChargeBillingReport']> = {
  chargeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chargeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chargeMonth?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entity?: Resolver<Maybe<ResolversTypes['Entity']>, ParentType, ContextType>;
  entityDisplayId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entityDisplayType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  glCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  leaseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payer?: Resolver<ResolversTypes['Payer'], ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeBillingReportsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeBillingReportsResponse'] = ResolversParentTypes['ChargeBillingReportsResponse']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  reports?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeBillingReport']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeCode'] = ResolversParentTypes['ChargeCode']> = {
  chargeCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  glCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeTemplate'] = ResolversParentTypes['ChargeTemplate']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalActorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalActorType?: Resolver<Maybe<ResolversTypes['ExternalActorType']>, ParentType, ContextType>;
  externalEntityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityType?: Resolver<Maybe<ResolversTypes['ExternalEntityType']>, ParentType, ContextType>;
  externalPayerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalPayerType?: Resolver<ResolversTypes['ExternalPayerType'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductItem']>>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimedChannelInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClaimedChannelInvite'] = ResolversParentTypes['ClaimedChannelInvite']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  claimed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  claimedBy?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  claimedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
  expires?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  groupRole?: Resolver<ResolversTypes['GroupRole'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  sent?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ChannelInviteStatusType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientPmScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientPMSchedule'] = ResolversParentTypes['ClientPMSchedule']> = {
  assignee?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  assigneeGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  completeWithin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  daysAhead?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipmentIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  extRefId?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meterReading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthNo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repeats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stepTemplate?: Resolver<Maybe<Array<Maybe<ResolversTypes['StepTemplate']>>>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeToComplete?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  untilDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekNo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekday?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTask'] = ResolversParentTypes['ClientTask']> = {
  actualTimeToComplete?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  assigneeGroup?: Resolver<Maybe<Array<ResolversTypes['GroupRole']>>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  completeNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  completedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  equipment?: Resolver<Maybe<Array<ResolversTypes['TaskEquipmentDTO']>>, ParentType, ContextType>;
  estimateTimeToComplete?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  friendlyID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupBy?: Resolver<ResolversTypes['IdNamePair'], ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meterReading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextStatuses?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repeats?: Resolver<ResolversTypes['TaskRepeatDTO'], ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['TaskScheduleDTO']>, ParentType, ContextType>;
  scheduleNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stepExecution?: Resolver<Maybe<Array<ResolversTypes['StepExecution']>>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userFriendlyID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ColorScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Color'], any> {
  name: 'Color';
}

export type CompleteSignupDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompleteSignupData'] = ResolversParentTypes['CompleteSignupData']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  clientTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompleteTaskResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompleteTaskResponseDTO'] = ResolversParentTypes['CompleteTaskResponseDTO']> = {
  completedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Configuration'] = ResolversParentTypes['Configuration']> = {
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  actions?: Resolver<Maybe<Array<ResolversTypes['ContentWorkflow']>>, ParentType, ContextType>;
  analytics?: Resolver<ResolversTypes['ObjectAnalytics'], ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  card?: Resolver<ResolversTypes['Block'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  contentMetatags?: Resolver<Maybe<Array<ResolversTypes['ObjectMetatag']>>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deepLink?: Resolver<Maybe<ResolversTypes['DeepLink']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalKey?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  externalUrl?: Resolver<Maybe<ResolversTypes['ExternalUrl']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['ContentFeature']>>, ParentType, ContextType>;
  generatedChildren?: Resolver<Maybe<ResolversTypes['ContentPagination']>, ParentType, ContextType, Partial<ContentGeneratedChildrenArgs>>;
  generator?: Resolver<Maybe<ResolversTypes['ContentGenerator']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['ChannelIntegration']>, ParentType, ContextType>;
  interactionEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  interactions?: Resolver<Maybe<Array<ResolversTypes['UserContentInteraction']>>, ParentType, ContextType>;
  isInteractive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSyndicateable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  liveDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  nextReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  notifications?: Resolver<Maybe<Array<ResolversTypes['ContentNotification']>>, ParentType, ContextType>;
  placements?: Resolver<Maybe<Array<ResolversTypes['ContentPlacement']>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  propertiesOptions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  relatedChannelIntegrationIds?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  renderer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reportingGroup?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  resetPeriod?: Resolver<ResolversTypes['ResetPeriodType'], ParentType, ContextType>;
  sectionContent?: Resolver<Maybe<Array<ResolversTypes['SectionContent']>>, ParentType, ContextType>;
  signedExternalUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['Theme']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>;
  unpublishDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userAccess?: Resolver<Maybe<ResolversTypes['ContentAccessType']>, ParentType, ContextType>;
  userInteractions?: Resolver<ResolversTypes['UserContentInteractionPagination'], ParentType, ContextType, Partial<ContentUserInteractionsArgs>>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ContentCategoryTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ContentCategoryType'], any> {
  name: 'ContentCategoryType';
}

export type ContentDateRangesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentDateRanges'] = ResolversParentTypes['ContentDateRanges']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  contentId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  dateRanges?: Resolver<Array<ResolversTypes['DateRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentFeatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentFeature'] = ResolversParentTypes['ContentFeature']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  feature?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FeatureName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentFeatureStatusSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentFeatureStatusSummary'] = ResolversParentTypes['ContentFeatureStatusSummary']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentGeneratorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentGenerator'] = ResolversParentTypes['ContentGenerator']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType>;
  datasetSchema?: Resolver<ResolversTypes['DatasetSchema'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentInterface'] = ResolversParentTypes['ContentInterface']> = {
  __resolveType: TypeResolveFn<'Content', ParentType, ContextType>;
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  actions?: Resolver<Maybe<Array<ResolversTypes['ContentWorkflow']>>, ParentType, ContextType>;
  analytics?: Resolver<ResolversTypes['ObjectAnalytics'], ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  card?: Resolver<ResolversTypes['Block'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  contentMetatags?: Resolver<Maybe<Array<ResolversTypes['ObjectMetatag']>>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deepLink?: Resolver<Maybe<ResolversTypes['DeepLink']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalKey?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  externalUrl?: Resolver<Maybe<ResolversTypes['ExternalUrl']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['ContentFeature']>>, ParentType, ContextType>;
  generator?: Resolver<Maybe<ResolversTypes['ContentGenerator']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['ChannelIntegration']>, ParentType, ContextType>;
  interactionEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isInteractive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSyndicateable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  liveDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  nextReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  notifications?: Resolver<Maybe<Array<ResolversTypes['ContentNotification']>>, ParentType, ContextType>;
  placements?: Resolver<Maybe<Array<ResolversTypes['ContentPlacement']>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  propertiesOptions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  renderer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reportingGroup?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  resetPeriod?: Resolver<ResolversTypes['ResetPeriodType'], ParentType, ContextType>;
  sectionContent?: Resolver<Maybe<Array<ResolversTypes['SectionContent']>>, ParentType, ContextType>;
  signedExternalUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['Theme']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>;
  unpublishDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userInteractions?: Resolver<ResolversTypes['UserContentInteractionPagination'], ParentType, ContextType, Partial<ContentInterfaceUserInteractionsArgs>>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
};

export type ContentNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentNotification'] = ResolversParentTypes['ContentNotification']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['DraftContent']>, ParentType, ContextType>;
  contentGoesLiveNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sendAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  sent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPagination'] = ResolversParentTypes['ContentPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentPlacementResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPlacement'] = ResolversParentTypes['ContentPlacement']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>;
  groupRole?: Resolver<Maybe<ResolversTypes['GroupRole']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentQuantityAllotmentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentQuantityAllotments'] = ResolversParentTypes['ContentQuantityAllotments']> = {
  ruleMaxAllottedUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalGeneralClaimedAllotments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalRemainingAllotments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalRuleClaimedAllotments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUserInteractions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentTemplate'] = ResolversParentTypes['ContentTemplate']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  actions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  analytics?: Resolver<ResolversTypes['ObjectAnalytics'], ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  card?: Resolver<ResolversTypes['Block'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  contentTags?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  features?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interactionEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isInteractive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  liveDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  nextReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  propertiesOptions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  renderer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reportingGroup?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  resetPeriod?: Resolver<ResolversTypes['ResetPeriodType'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  templateName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['Theme']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>;
  unpublishDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentTemplatePaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentTemplatePagination'] = ResolversParentTypes['ContentTemplatePagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['ContentTemplate']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentWaitlistEnrollmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentWaitlistEnrollment'] = ResolversParentTypes['ContentWaitlistEnrollment']> = {
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentWorkflowResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentWorkflow'] = ResolversParentTypes['ContentWorkflow']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['WorkflowActionType'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['DraftContent']>, ParentType, ContextType>;
  dataField?: Resolver<Maybe<ResolversTypes['DataField']>, ParentType, ContextType>;
  dataValidationSchema?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  event?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  groupRole?: Resolver<Maybe<ResolversTypes['GroupRole']>, ParentType, ContextType>;
  inStatus?: Resolver<Maybe<Array<ResolversTypes['UserContentInteractionStatus']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  target?: Resolver<ResolversTypes['WorkflowTargetType'], ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  webhook?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType>;
  when?: Resolver<ResolversTypes['WorkflowWhenType'], ParentType, ContextType>;
  whenContext?: Resolver<ResolversTypes['WorkflowWhenContextType'], ParentType, ContextType>;
  workflow?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAudienceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAudienceResponse'] = ResolversParentTypes['CreateAudienceResponse']> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  channelTargetingRules?: Resolver<Array<Maybe<ResolversTypes['TargetingRule']>>, ParentType, ContextType>;
  contentToTarget?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  createdByChannel?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  groupRoleTargetingRules?: Resolver<Array<Maybe<ResolversTypes['TargetingRule']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  managementChannels?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shareChannels?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateChargeCodeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateChargeCodeResponse'] = ResolversParentTypes['CreateChargeCodeResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateChargeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateChargeResponse'] = ResolversParentTypes['CreateChargeResponse']> = {
  chargeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductCategoryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateProductCategoryResponse'] = ResolversParentTypes['CreateProductCategoryResponse']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupType?: Resolver<Maybe<ResolversTypes['GroupType']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productType?: Resolver<Maybe<ResolversTypes['ProductType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductExceptionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateProductExceptionResponse'] = ResolversParentTypes['CreateProductExceptionResponse']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  markup?: Resolver<ResolversTypes['Markup'], ParentType, ContextType>;
  payer?: Resolver<ResolversTypes['Payer'], ParentType, ContextType>;
  productCategory?: Resolver<Maybe<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  products?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  tax?: Resolver<ResolversTypes['ProductTax'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateProductResponse'] = ResolversParentTypes['CreateProductResponse']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  chargeCode?: Resolver<Maybe<ResolversTypes['ChargeCode']>, ParentType, ContextType>;
  creditAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  markup?: Resolver<ResolversTypes['Markup'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCategory?: Resolver<ResolversTypes['ProductCategory'], ParentType, ContextType>;
  receivables?: Resolver<Maybe<Array<Maybe<ResolversTypes['Receivable']>>>, ParentType, ContextType>;
  sellingPrice?: Resolver<Maybe<ResolversTypes['SellingPrice']>, ParentType, ContextType>;
  tax?: Resolver<ResolversTypes['ProductTax'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateRecurringChargeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateRecurringChargeResponse'] = ResolversParentTypes['CreateRecurringChargeResponse']> = {
  chargeTemplateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recurrenceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTargetingRuleResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTargetingRuleResponse'] = ResolversParentTypes['CreateTargetingRuleResponse']> = {
  audienceId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  groupRoleId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTransactionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTransactionResponse'] = ResolversParentTypes['CreateTransactionResponse']> = {
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUserResponse'] = ResolversParentTypes['CreateUserResponse']> = {
  accountsUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalUserSource?: Resolver<ResolversTypes['ExternalUserSource'], ParentType, ContextType>;
  paymentProcessorCustomerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CredentialResolvers<ContextType = any, ParentType extends ResolversParentTypes['Credential'] = ResolversParentTypes['Credential']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cardNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credential?: Resolver<Maybe<ResolversTypes['Binary']>, ParentType, ContextType>;
  credentialId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credentialType?: Resolver<Maybe<ResolversTypes['CredentialType']>, ParentType, ContextType>;
  mapId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revokedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CredentialStatus']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vtsOrganizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CredentialGroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CredentialGroups'] = ResolversParentTypes['CredentialGroups']> = {
  credentials?: Resolver<Array<Maybe<ResolversTypes['Credential']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vtsOrganizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vtsUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditBundleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditBundle'] = ResolversParentTypes['CreditBundle']> = {
  creditCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  creditValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  expiryType?: Resolver<ResolversTypes['ExpiryType'], ParentType, ContextType>;
  expiryValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditCard'] = ResolversParentTypes['CreditCard']> = {
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exp_month?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  exp_year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditWalletResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditWallet'] = ResolversParentTypes['CreditWallet']> = {
  channelProfile?: Resolver<ResolversTypes['CreditWalletChannelProfile'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Owner'], ParentType, ContextType>;
  valueOfEachCredit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditWalletChannelProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditWalletChannelProfile'] = ResolversParentTypes['CreditWalletChannelProfile']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrencyDetail'] = ResolversParentTypes['CurrencyDetail']> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operateCurrencyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CursorPageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CursorPageInfo'] = ResolversParentTypes['CursorPageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomColorPaletteResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomColorPalette'] = ResolversParentTypes['CustomColorPalette']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomQuestion'] = ResolversParentTypes['CustomQuestion']> = {
  Answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Question?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataField'] = ResolversParentTypes['DataField']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['DatasetRecord'] = ResolversParentTypes['DatasetRecord']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  datasetSchema?: Resolver<ResolversTypes['DatasetSchema'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetRecordPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DatasetRecordPagination'] = ResolversParentTypes['DatasetRecordPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['DatasetRecord']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DatasetSchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['DatasetSchema'] = ResolversParentTypes['DatasetSchema']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  datasetRecords?: Resolver<ResolversTypes['DatasetRecordPagination'], ParentType, ContextType, Partial<DatasetSchemaDatasetRecordsArgs>>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  generator?: Resolver<Maybe<ResolversTypes['ContentGenerator']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  properties?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DateRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DateRange'] = ResolversParentTypes['DateRange']> = {
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeepLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeepLink'] = ResolversParentTypes['DeepLink']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  appStoreUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  deepLinkUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  googlePlayStoreUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeviceToken'] = ResolversParentTypes['DeviceToken']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  session?: Resolver<Maybe<ResolversTypes['Session']>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DeviceTokenType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  whitelabel?: Resolver<Maybe<ResolversTypes['Whitelabel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisplayMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisplayMetadata'] = ResolversParentTypes['DisplayMetadata']> = {
  friendlyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  __resolveType: TypeResolveFn<'Address' | 'ApiKey' | 'Block' | 'Building' | 'Channel' | 'ChannelContract' | 'ChannelContractLineItem' | 'ChannelIntegration' | 'ChannelInvite' | 'ChannelMerchant' | 'ChannelModule' | 'ChannelPage' | 'ChannelRelationship' | 'ClaimedChannelInvite' | 'ContentFeature' | 'ContentGenerator' | 'ContentNotification' | 'ContentPlacement' | 'ContentTemplate' | 'ContentWorkflow' | 'DatasetRecord' | 'DatasetSchema' | 'DeviceToken' | 'DraftContent' | 'DraftContentNotification' | 'DraftContentWorkflow' | 'DraftUserContentInteraction' | 'EventSubscription' | 'FeedbackForm' | 'GroupRole' | 'Integration' | 'Library' | 'LibraryAccess' | 'LibraryItem' | 'Media' | 'Metatag' | 'MetatagValue' | 'ObjectDatasetRecord' | 'ObjectMetatag' | 'ObjectMetatagValue' | 'PaymentAccount' | 'PaymentProcessor' | 'Profile' | 'Section' | 'SectionContent' | 'Suite' | 'TenantLease' | 'Theme' | 'User' | 'UserContentInteraction' | 'UserContentInteractionHistory' | 'UserGroupRole' | 'UserLogin' | 'Webhook' | 'Whitelabel' | 'WhitelabelChannel', ParentType, ContextType>;
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
};

export type DraftContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftContent'] = ResolversParentTypes['DraftContent']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  actions?: Resolver<Maybe<Array<ResolversTypes['DraftContentWorkflow']>>, ParentType, ContextType>;
  analytics?: Resolver<Maybe<ResolversTypes['ObjectAnalytics']>, ParentType, ContextType>;
  audiences?: Resolver<Maybe<Array<ResolversTypes['AudiencesToAttachContentTo']>>, ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  card?: Resolver<ResolversTypes['Block'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['ContentCategoryType'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  contentMetatags?: Resolver<Maybe<Array<ResolversTypes['ObjectMetatag']>>, ParentType, ContextType>;
  contentTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deepLink?: Resolver<Maybe<ResolversTypes['DeepLink']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalKey?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  externalUrl?: Resolver<Maybe<ResolversTypes['ExternalUrl']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['ContentFeature']>>, ParentType, ContextType>;
  generatedChildren?: Resolver<Maybe<ResolversTypes['DraftContentPagination']>, ParentType, ContextType, Partial<DraftContentGeneratedChildrenArgs>>;
  generator?: Resolver<Maybe<ResolversTypes['ContentGenerator']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['ChannelIntegration']>, ParentType, ContextType>;
  interactionEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  interactions?: Resolver<Maybe<Array<ResolversTypes['UserContentInteraction']>>, ParentType, ContextType>;
  isEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isInteractive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPublished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSyndicateable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  liveDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  nextReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  notifications?: Resolver<Maybe<Array<ResolversTypes['DraftContentNotification']>>, ParentType, ContextType>;
  placements?: Resolver<Maybe<Array<ResolversTypes['ContentPlacement']>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  propertiesOptions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  relatedChannelIntegrationIds?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  renderer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reportingGroup?: Resolver<ResolversTypes['ContentCategoryType'], ParentType, ContextType>;
  resetPeriod?: Resolver<ResolversTypes['ResetPeriodType'], ParentType, ContextType>;
  signedExternalUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['Theme']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>;
  unpublishDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftContentHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftContentHistory'] = ResolversParentTypes['DraftContentHistory']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  actions?: Resolver<Maybe<Array<ResolversTypes['DraftContentWorkflow']>>, ParentType, ContextType>;
  audiences?: Resolver<Maybe<Array<ResolversTypes['Audience']>>, ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  contentTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deepLink?: Resolver<Maybe<ResolversTypes['DeepLink']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalUrl?: Resolver<Maybe<ResolversTypes['ExternalUrl']>, ParentType, ContextType>;
  features?: Resolver<Maybe<Array<ResolversTypes['ContentFeature']>>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isInteractive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPublished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSyndicateable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  liveDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  nextReset?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  notifications?: Resolver<Maybe<Array<ResolversTypes['DraftContentNotification']>>, ParentType, ContextType>;
  placements?: Resolver<Maybe<Array<ResolversTypes['ContentPlacement']>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  propertiesOptions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  renderer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reportingGroup?: Resolver<Maybe<ResolversTypes['ContentCategoryType']>, ParentType, ContextType>;
  resetPeriod?: Resolver<ResolversTypes['ResetPeriodType'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['Theme']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>;
  unpublishDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftContentHistoryPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftContentHistoryPagination'] = ResolversParentTypes['DraftContentHistoryPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['DraftContentHistory']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftContentNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftContentNotification'] = ResolversParentTypes['DraftContentNotification']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['DraftContent']>, ParentType, ContextType>;
  contentGoesLiveNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sendAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  sent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftContentPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftContentPagination'] = ResolversParentTypes['DraftContentPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['DraftContent']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftContentWorkflowResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftContentWorkflow'] = ResolversParentTypes['DraftContentWorkflow']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  action?: Resolver<Maybe<ResolversTypes['WorkflowActionType']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['DraftContent']>, ParentType, ContextType>;
  dataField?: Resolver<Maybe<ResolversTypes['DataField']>, ParentType, ContextType>;
  dataValidationSchema?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['EventType']>, ParentType, ContextType>;
  groupRole?: Resolver<Maybe<ResolversTypes['GroupRole']>, ParentType, ContextType>;
  inStatus?: Resolver<Maybe<Array<ResolversTypes['UserContentInteractionStatus']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  target?: Resolver<ResolversTypes['WorkflowTargetType'], ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  webhook?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType>;
  when?: Resolver<ResolversTypes['WorkflowWhenType'], ParentType, ContextType>;
  whenContext?: Resolver<ResolversTypes['WorkflowWhenContextType'], ParentType, ContextType>;
  workflow?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  workflowTargetedChannelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftUserContentInteractionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftUserContentInteraction'] = ResolversParentTypes['DraftUserContentInteraction']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType>;
  contentPublishedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  interaction?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DynamicSectionInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['DynamicSectionInfo'] = ResolversParentTypes['DynamicSectionInfo']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channels?: Resolver<Array<ResolversTypes['Channel']>, ParentType, ContextType>;
  defaultSearchOptions?: Resolver<ResolversTypes['SearchOptions'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export type EntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Entity'] = ResolversParentTypes['Entity']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ExternalEntityType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EquipmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Equipment'] = ResolversParentTypes['Equipment']> = {
  asset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hyperlinks?: Resolver<Maybe<Array<Maybe<ResolversTypes['HyperLink']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  installDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meterReadingSettings?: Resolver<Maybe<Array<Maybe<ResolversTypes['MeterReadingSetting']>>>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  suite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  warrantyExpirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EquipmentForExportResolvers<ContextType = any, ParentType extends ResolversParentTypes['EquipmentForExport'] = ResolversParentTypes['EquipmentForExport']> = {
  equipment?: Resolver<Maybe<Array<ResolversTypes['Equipment']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EquipmentSearchResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EquipmentSearchResults'] = ResolversParentTypes['EquipmentSearchResults']> = {
  equipment?: Resolver<Maybe<Array<ResolversTypes['Equipment']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EscalationRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['EscalationRule'] = ResolversParentTypes['EscalationRule']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  entityType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extRefId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeToEscalate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  workflowId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysResolvers<ContextType = any, ParentType extends ResolversParentTypes['Essensys'] = ResolversParentTypes['Essensys']> = {
  account?: Resolver<ResolversTypes['EssensysAccount'], ParentType, ContextType, Partial<EssensysAccountArgs>>;
  accountContactPairs?: Resolver<Array<ResolversTypes['AccountContactPair']>, ParentType, ContextType, Partial<EssensysAccountContactPairsArgs>>;
  accounts?: Resolver<Array<ResolversTypes['EssensysAccount']>, ParentType, ContextType, RequireFields<EssensysAccountsArgs, 'contactIds'>>;
  calendar?: Resolver<Array<ResolversTypes['EssensysCalendar']>, ParentType, ContextType, RequireFields<EssensysCalendarArgs, 'endDate' | 'productId' | 'startDate'>>;
  contact?: Resolver<ResolversTypes['EssensysContact'], ParentType, ContextType, Partial<EssensysContactArgs>>;
  invoice?: Resolver<ResolversTypes['EssensysInvoice'], ParentType, ContextType, RequireFields<EssensysInvoiceArgs, 'invoiceId'>>;
  invoicePdfUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<EssensysInvoicePdfUrlArgs, 'invoiceId'>>;
  invoices?: Resolver<Array<ResolversTypes['EssensysInvoice']>, ParentType, ContextType, Partial<EssensysInvoicesArgs>>;
  location?: Resolver<ResolversTypes['JSON'], ParentType, ContextType, RequireFields<EssensysLocationArgs, 'locationId'>>;
  onlinePlanQuote?: Resolver<ResolversTypes['OnlinePlanQuote'], ParentType, ContextType, RequireFields<EssensysOnlinePlanQuoteArgs, 'objectDatasetRecordId'>>;
  organizations?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<EssensysOrganizationsArgs, 'locationId'>>;
  paymentProcessor?: Resolver<ResolversTypes['PaymentProcessor'], ParentType, ContextType, Partial<EssensysPaymentProcessorArgs>>;
  paymentSettings?: Resolver<ResolversTypes['EssensysPaymentSettings'], ParentType, ContextType, Partial<EssensysPaymentSettingsArgs>>;
  products?: Resolver<Array<ResolversTypes['EssensysProduct']>, ParentType, ContextType, RequireFields<EssensysProductsArgs, 'productIds'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysAccount'] = ResolversParentTypes['EssensysAccount']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clientType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientTypeName?: Resolver<ResolversTypes['StudioGroupRoleName'], ParentType, ContextType>;
  credit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditsRemaining?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditsUsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  placepayaccountid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  printingBlackAndWhiteCredit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  printingColorCredit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTaxExempt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shopperreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysCalendarResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysCalendar'] = ResolversParentTypes['EssensysCalendar']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  accountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  contactId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysContact'] = ResolversParentTypes['EssensysContact']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  printingPin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysInvoice'] = ResolversParentTypes['EssensysInvoice']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bfwd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clientTypeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clientTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailAddress']>>>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  endingBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gross?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  net?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outstanding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysMutation'] = ResolversParentTypes['EssensysMutation']> = {
  createOperateAccountAndContact?: Resolver<ResolversTypes['AccountContactPair'], ParentType, ContextType, RequireFields<EssensysMutationCreateOperateAccountAndContactArgs, 'companyName'>>;
  sendInvoicePayment?: Resolver<ResolversTypes['EssensysPayment'], ParentType, ContextType, RequireFields<EssensysMutationSendInvoicePaymentArgs, 'currency' | 'invoiceId' | 'paymentAccountId'>>;
  setAutoPay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<EssensysMutationSetAutoPayArgs, 'accountId' | 'enroll' | 'paymentAccountId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysPayment'] = ResolversParentTypes['EssensysPayment']> = {
  accountname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allocateto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amountpaid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  glcode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paymentcurrencycode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentdate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymenttype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysPaymentProcessorResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysPaymentProcessor'] = ResolversParentTypes['EssensysPaymentProcessor']> = {
  publicKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentProviderType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysPaymentSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysPaymentSettings'] = ResolversParentTypes['EssensysPaymentSettings']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  accountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  balance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditsRemaining?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditsUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCash?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCashNotOnAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCredits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEssensys?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOnAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  locationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orgpayeeid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overageproductid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentProcessor?: Resolver<ResolversTypes['EssensysPaymentProcessor'], ParentType, ContextType>;
  placepayaccountid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopperreference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EssensysProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['EssensysProduct'] = ResolversParentTypes['EssensysProduct']> = {
  availableAt?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  enableOnline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hideFromPOS?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventDetail'] = ResolversParentTypes['EventDetail']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventSubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventSubscription'] = ResolversParentTypes['EventSubscription']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  contentId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  delivery?: Resolver<Maybe<Array<Maybe<ResolversTypes['NotificationDeliveryType']>>>, ParentType, ContextType>;
  event?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  groupRoleId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  interactionId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  subscriberId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  subscriberType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EventTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EventType'], any> {
  name: 'EventType';
}

export type ExceptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Exception'] = ResolversParentTypes['Exception']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  markup?: Resolver<Maybe<ResolversTypes['Markup']>, ParentType, ContextType>;
  payer?: Resolver<ResolversTypes['Payer'], ParentType, ContextType>;
  productCategory?: Resolver<Maybe<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  productCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['ProductTax']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportCsvTasksReponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportCSVTasksReponseDTO'] = ResolversParentTypes['ExportCSVTasksReponseDTO']> = {
  tasks?: Resolver<Maybe<Array<ResolversTypes['ClientTask']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalUrl'] = ResolversParentTypes['ExternalUrl']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  openingMode?: Resolver<Maybe<ResolversTypes['ExternalUrlOpeningMode']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Features'] = ResolversParentTypes['Features']> = {
  createServiceRequest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updateServiceRequest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackFormResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackForm'] = ResolversParentTypes['FeedbackForm']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FindSchedulesFilterOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FindSchedulesFilterOptions'] = ResolversParentTypes['FindSchedulesFilterOptions']> = {
  assignees?: Resolver<Maybe<Array<Maybe<ResolversTypes['PublicUser']>>>, ParentType, ContextType>;
  equipments?: Resolver<Maybe<Array<Maybe<ResolversTypes['IdNamePair']>>>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  recurrences?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FindSchedulesReponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['FindSchedulesReponseDTO'] = ResolversParentTypes['FindSchedulesReponseDTO']> = {
  filterOptions?: Resolver<Maybe<ResolversTypes['FindSchedulesFilterOptions']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  schedules?: Resolver<Maybe<Array<ResolversTypes['ClientPMSchedule']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatfileTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatfileToken'] = ResolversParentTypes['FlatfileToken']> = {
  embedId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FloorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Floor'] = ResolversParentTypes['Floor']> = {
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  property?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType>;
  propertyId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  units?: Resolver<Maybe<Array<Maybe<ResolversTypes['Unit']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FloorMapGenerationStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['FloorMapGenerationStatus'] = ResolversParentTypes['FloorMapGenerationStatus']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  percentComplete?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GeoPointScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GeoPoint'], any> {
  name: 'GeoPoint';
}

export type GetConfigResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetConfigResponse'] = ResolversParentTypes['GetConfigResponse']> = {
  config?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  connectedProviders?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetFinalPriceForProductsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetFinalPriceForProductsResponse'] = ResolversParentTypes['GetFinalPriceForProductsResponse']> = {
  productFinalPrices?: Resolver<Array<ResolversTypes['ProductFinalPrice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetIntegrationProcessesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetIntegrationProcessesResponse'] = ResolversParentTypes['GetIntegrationProcessesResponse']> = {
  processes?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntegrationProcess']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetInvoiceDetailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetInvoiceDetailsResponse'] = ResolversParentTypes['GetInvoiceDetailsResponse']> = {
  charges?: Resolver<Array<Maybe<ResolversTypes['Charge']>>, ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetProductExceptionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetProductExceptionResponse'] = ResolversParentTypes['GetProductExceptionResponse']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  markup?: Resolver<Maybe<ResolversTypes['Markup']>, ParentType, ContextType>;
  payer?: Resolver<ResolversTypes['Payer'], ParentType, ContextType>;
  productCategory?: Resolver<Maybe<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  products?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['ProductTax']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetProductResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetProductResponse'] = ResolversParentTypes['GetProductResponse']> = {
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetRecurringChargeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetRecurringChargeResponse'] = ResolversParentTypes['GetRecurringChargeResponse']> = {
  recurringCharge?: Resolver<ResolversTypes['RecurringCharge'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetRelayOutputListResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetRelayOutputList'] = ResolversParentTypes['GetRelayOutputList']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  relayOutputs?: Resolver<Array<ResolversTypes['RelayOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetTransactionStatusResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetTransactionStatusResponse'] = ResolversParentTypes['GetTransactionStatusResponse']> = {
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetUserByExternalIdResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetUserByExternalIdResponse'] = ResolversParentTypes['GetUserByExternalIdResponse']> = {
  accountsUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalUserSource?: Resolver<Maybe<ResolversTypes['ExternalUserSource']>, ParentType, ContextType>;
  invoiceAutopayment?: Resolver<Maybe<ResolversTypes['InvoiceAutopaymentDetails']>, ParentType, ContextType>;
  paymentProcessorCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentSources?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaymentSource']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetUserCreditWalletsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetUserCreditWalletsResponse'] = ResolversParentTypes['GetUserCreditWalletsResponse']> = {
  creditWallets?: Resolver<Array<Maybe<ResolversTypes['CreditWallet']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupRole'] = ResolversParentTypes['GroupRole']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  eventSubscriptions?: Resolver<Maybe<Array<ResolversTypes['EventSubscription']>>, ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSystem?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<ResolversTypes['Permission']>>, ParentType, ContextType>;
  requirements?: Resolver<Maybe<Array<ResolversTypes['Requirement']>>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupRoleJoinRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupRoleJoinRule'] = ResolversParentTypes['GroupRoleJoinRule']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  groupRoleId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  joinGroupRoleId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupSettingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupSettingResponse'] = ResolversParentTypes['GroupSettingResponse']> = {
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupSettingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomBuildingResolvers<ContextType = any, ParentType extends ResolversParentTypes['HOMBuilding'] = ResolversParentTypes['HOMBuilding']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buildingName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['HOMDate'] = ResolversParentTypes['HOMDate']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['HOMEvent'] = ResolversParentTypes['HOMEvent']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  dateTime?: Resolver<Maybe<ResolversTypes['HOMDate']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  teacher?: Resolver<ResolversTypes['HOMTeacher'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venue?: Resolver<Maybe<ResolversTypes['HOMVenue']>, ParentType, ContextType>;
  waitlistActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomTeacherResolvers<ContextType = any, ParentType extends ResolversParentTypes['HOMTeacher'] = ResolversParentTypes['HOMTeacher']> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HomVenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['HOMVenue'] = ResolversParentTypes['HOMVenue']> = {
  extraInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HardwareResolvers<ContextType = any, ParentType extends ResolversParentTypes['Hardware'] = ResolversParentTypes['Hardware']> = {
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  deviceInfo?: Resolver<Maybe<ResolversTypes['HardwareDeviceInfo']>, ParentType, ContextType>;
  hardwareSettings?: Resolver<ResolversTypes['HardwareSettings'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['HardwareStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['HardwareType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HardwareAccessLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['HardwareAccessLog'] = ResolversParentTypes['HardwareAccessLog']> = {
  accessHostId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accessStatusDetail?: Resolver<Maybe<ResolversTypes['HardwarePassError']>, ParentType, ContextType>;
  accessSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  accessedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  accessedById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accessedByName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hardware?: Resolver<Maybe<ResolversTypes['Hardware']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  visitorPassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HardwareAccessLogPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HardwareAccessLogPagination'] = ResolversParentTypes['HardwareAccessLogPagination']> = {
  items?: Resolver<Array<ResolversTypes['HardwareAccessLog']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HardwareDeviceInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['HardwareDeviceInfo'] = ResolversParentTypes['HardwareDeviceInfo']> = {
  appVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  batteryLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActivityAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thermalState?: Resolver<Maybe<ResolversTypes['HardwareThermalState']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HardwarePaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HardwarePagination'] = ResolversParentTypes['HardwarePagination']> = {
  items?: Resolver<Array<ResolversTypes['Hardware']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HardwareSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['HardwareSettings'] = ResolversParentTypes['HardwareSettings']> = {
  kioskTimeoutInSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Host'] = ResolversParentTypes['Host']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HyperLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['HyperLink'] = ResolversParentTypes['HyperLink']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdNamePairResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdNamePair'] = ResolversParentTypes['IdNamePair']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ImageScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Image'], any> {
  name: 'Image';
}

export type IntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Integration'] = ResolversParentTypes['Integration']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['IntegrationCategory'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platforms?: Resolver<Maybe<Array<ResolversTypes['PlatformType']>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['IntegrationType'], ParentType, ContextType>;
  workflows?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationProcessResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationProcess'] = ResolversParentTypes['IntegrationProcess']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  failedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  processGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  succeededAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  totalCreatedRecords?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalFailedRecords?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUpdatedRecords?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vtsIntegrationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  displayId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalPayerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalPayerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalPayerType?: Resolver<ResolversTypes['ExternalPayerType'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceAutopaymentDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceAutopaymentDetails'] = ResolversParentTypes['InvoiceAutopaymentDetails']> = {
  isInvoiceAutopaymentEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  paymentSource?: Resolver<Maybe<ResolversTypes['PaymentSource']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoicePartialResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoicePartial'] = ResolversParentTypes['InvoicePartial']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceDisplayId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IssueResolvers<ContextType = any, ParentType extends ResolversParentTypes['Issue'] = ResolversParentTypes['Issue']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IssueUpsertResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['IssueUpsertResponseDTO'] = ResolversParentTypes['IssueUpsertResponseDTO']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type JobResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobResponse'] = ResolversParentTypes['JobResponse']> = {
  allowedFeatures?: Resolver<Maybe<ResolversTypes['AllowedFeatures']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobsResponse'] = ResolversParentTypes['JobsResponse']> = {
  jobs?: Resolver<Array<Maybe<ResolversTypes['JobResponse']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LayoutDesignSystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['LayoutDesignSystem'] = ResolversParentTypes['LayoutDesignSystem']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  marginBottom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginLeft?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginRight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginTop?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingBottom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingLeft?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingRight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingTop?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LibraryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Library'] = ResolversParentTypes['Library']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  access?: Resolver<Maybe<Array<ResolversTypes['LibraryAccess']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['LibraryItem']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paths?: Resolver<Maybe<Array<ResolversTypes['Path']>>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LibraryType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LibraryAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['LibraryAccess'] = ResolversParentTypes['LibraryAccess']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  groupRole?: Resolver<Maybe<ResolversTypes['GroupRole']>, ParentType, ContextType>;
  library?: Resolver<Maybe<ResolversTypes['Library']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LibraryItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['LibraryItem'] = ResolversParentTypes['LibraryItem']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['Block']>, ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  datasetSchema?: Resolver<Maybe<ResolversTypes['DatasetSchema']>, ParentType, ContextType>;
  library?: Resolver<Maybe<ResolversTypes['Library']>, ParentType, ContextType>;
  libraryType?: Resolver<Maybe<ResolversTypes['LibraryType']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  metatag?: Resolver<Maybe<ResolversTypes['Metatag']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['Path']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['ContentTemplate']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  webhook?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LibraryItemPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LibraryItemPagination'] = ResolversParentTypes['LibraryItemPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['LibraryItem']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LibraryItemWithOriginalExtensionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LibraryItemWithOriginalExtension'] = ResolversParentTypes['LibraryItemWithOriginalExtension']> = {
  libraryItem?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType>;
  originalExtension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LibraryPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LibraryPagination'] = ResolversParentTypes['LibraryPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['Library']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkedEntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkedEntity'] = ResolversParentTypes['LinkedEntity']> = {
  _created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  _createdBy?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  _updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  _updatedBy?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  activateId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  activateType?: Resolver<Maybe<ResolversTypes['ActivateType']>, ParentType, ContextType>;
  building?: Resolver<Maybe<ResolversTypes['Building']>, ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalType?: Resolver<Maybe<ResolversTypes['ExternalType']>, ParentType, ContextType>;
  tenantLease?: Resolver<Maybe<ResolversTypes['TenantLease']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkedEntityPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkedEntityPagination'] = ResolversParentTypes['LinkedEntityPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['LinkedEntity']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListChargeCodeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListChargeCodeResponse'] = ResolversParentTypes['ListChargeCodeResponse']> = {
  chargeCodes?: Resolver<Array<Maybe<ResolversTypes['ChargeCode']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListChargesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListChargesResponse'] = ResolversParentTypes['ListChargesResponse']> = {
  charges?: Resolver<Array<Maybe<ResolversTypes['Charge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListInvoicesByPayersResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListInvoicesByPayersResponse'] = ResolversParentTypes['ListInvoicesByPayersResponse']> = {
  invoices?: Resolver<Array<Maybe<ResolversTypes['Invoice']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListInvoicesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListInvoicesResponse'] = ResolversParentTypes['ListInvoicesResponse']> = {
  invoices?: Resolver<Array<Maybe<ResolversTypes['Invoice']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListProductCategoriesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListProductCategoriesResponse'] = ResolversParentTypes['ListProductCategoriesResponse']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  productCategory?: Resolver<Array<Maybe<ResolversTypes['ProductCategory']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListProductExceptionsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListProductExceptionsResponse'] = ResolversParentTypes['ListProductExceptionsResponse']> = {
  exceptions?: Resolver<Array<Maybe<ResolversTypes['Exception']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListProductsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListProductsResponse'] = ResolversParentTypes['ListProductsResponse']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListRecurringChargesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListRecurringChargesResponse'] = ResolversParentTypes['ListRecurringChargesResponse']> = {
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  recurringCharges?: Resolver<Array<Maybe<ResolversTypes['RecurringCharge']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListTransactionsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListTransactionsResponse'] = ResolversParentTypes['ListTransactionsResponse']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  transactions?: Resolver<Array<Maybe<ResolversTypes['Transaction']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationUpsertResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationUpsertResponseDTO'] = ResolversParentTypes['LocationUpsertResponseDTO']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarkupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Markup'] = ResolversParentTypes['Markup']> = {
  markupAmountType?: Resolver<ResolversTypes['MarkupAmountType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Me'] = ResolversParentTypes['Me']> = {
  channels?: Resolver<Maybe<Array<ResolversTypes['Channel']>>, ParentType, ContextType, Partial<MeChannelsArgs>>;
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType, RequireFields<MeContentArgs, '_id'>>;
  contentInteractions?: Resolver<ResolversTypes['UserContentInteractionPagination'], ParentType, ContextType, Partial<MeContentInteractionsArgs>>;
  contentsOnChannels?: Resolver<Maybe<Array<Maybe<ResolversTypes['Content']>>>, ParentType, ContextType, RequireFields<MeContentsOnChannelsArgs, 'channelIds'>>;
  draftInteraction?: Resolver<Maybe<ResolversTypes['DraftUserContentInteraction']>, ParentType, ContextType, RequireFields<MeDraftInteractionArgs, 'contentId'>>;
  focusOnChannels?: Resolver<Maybe<Array<ResolversTypes['Channel']>>, ParentType, ContextType, RequireFields<MeFocusOnChannelsArgs, 'primary'>>;
  interaction?: Resolver<ResolversTypes['UserContentInteraction'], ParentType, ContextType, RequireFields<MeInteractionArgs, '_id'>>;
  libraries?: Resolver<Maybe<ResolversTypes['LibraryPagination']>, ParentType, ContextType, Partial<MeLibrariesArgs>>;
  lookerEmbedURL?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType, RequireFields<MeLookerEmbedUrlArgs, 'channelId' | 'channelName' | 'selectedReport' | 'timezone'>>;
  media?: Resolver<ResolversTypes['MediaPagination'], ParentType, ContextType, Partial<MeMediaArgs>>;
  pagesOnChannels?: Resolver<Maybe<Array<Maybe<ResolversTypes['PagesOnChannel']>>>, ParentType, ContextType, RequireFields<MePagesOnChannelsArgs, 'channelIds'>>;
  secureId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  sessionId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  shopifyMultipassLogin?: Resolver<ResolversTypes['ShopifyMultipassLoginDetail'], ParentType, ContextType, RequireFields<MeShopifyMultipassLoginArgs, 'channelIntegrationId' | 'createLoginRecord'>>;
  switchChannel?: Resolver<Maybe<ResolversTypes['SwitchChannel']>, ParentType, ContextType, RequireFields<MeSwitchChannelArgs, 'channelId'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  users?: Resolver<ResolversTypes['PublicUserPagination'], ParentType, ContextType, Partial<MeUsersArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeMutation'] = ResolversParentTypes['MeMutation']> = {
  claimInvite?: Resolver<ResolversTypes['PublicUserGroupRole'], ParentType, ContextType, RequireFields<MeMutationClaimInviteArgs, '_id'>>;
  clearDraftInteraction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MeMutationClearDraftInteractionArgs, 'contentId'>>;
  syncShopifyMultipassMarketingPreference?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MeMutationSyncShopifyMultipassMarketingPreferenceArgs, 'channelIntegrationId'>>;
  updateDeviceToken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MeMutationUpdateDeviceTokenArgs, 'deviceToken'>>;
  updateDraftInteraction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MeMutationUpdateDraftInteractionArgs, 'contentId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  analytics?: Resolver<ResolversTypes['ObjectAnalytics'], ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['MediaContentType']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  fileSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inUse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  previewUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  s3Bucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  thumbnailSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['MediaType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MediaContentTypeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['MediaContentType'], any> {
  name: 'MediaContentType';
}

export type MediaPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaPagination'] = ResolversParentTypes['MediaPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['Media']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaWithOriginalExtensionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaWithOriginalExtension'] = ResolversParentTypes['MediaWithOriginalExtension']> = {
  media?: Resolver<ResolversTypes['Media'], ParentType, ContextType>;
  originalExtension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetatagResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metatag'] = ResolversParentTypes['Metatag']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  properties?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  showDescription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MetatagType'], ParentType, ContextType>;
  values?: Resolver<Maybe<Array<ResolversTypes['MetatagValue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetatagFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetatagFilter'] = ResolversParentTypes['MetatagFilter']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  metatagId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  metatagType?: Resolver<ResolversTypes['MetatagType'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  valueType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  values?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetatagPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetatagPagination'] = ResolversParentTypes['MetatagPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['Metatag']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetatagUseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetatagUse'] = ResolversParentTypes['MetatagUse']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetatagValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetatagValue'] = ResolversParentTypes['MetatagValue']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  metatag?: Resolver<ResolversTypes['Metatag'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeterReadingSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeterReadingSetting'] = ResolversParentTypes['MeterReadingSetting']> = {
  enableNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  equipmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lowerBound?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notificationTrigger?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notificationTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  upperBound?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeterReadingValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeterReadingValue'] = ResolversParentTypes['MeterReadingValue']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meterReadingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stepExecutionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskUserFriendlyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeterReadingValueForExportResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeterReadingValueForExport'] = ResolversParentTypes['MeterReadingValueForExport']> = {
  meterReadingValues?: Resolver<Maybe<Array<ResolversTypes['MeterReadingValueResponseType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeterReadingValueResponseTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeterReadingValueResponseType'] = ResolversParentTypes['MeterReadingValueResponseType']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meterReadingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stepExecutionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskUserFriendlyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeterReadingValueSearchResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeterReadingValueSearchResults'] = ResolversParentTypes['MeterReadingValueSearchResults']> = {
  meterReadingValues?: Resolver<Array<ResolversTypes['MeterReadingValueResponseType']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MobileAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['MobileAccess'] = ResolversParentTypes['MobileAccess']> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MobileAccessChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['MobileAccessChannel'] = ResolversParentTypes['MobileAccessChannel']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobileAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultiChannelAcgsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MultiChannelAcgs'] = ResolversParentTypes['MultiChannelAcgs']> = {
  accessControlGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessControlGroups']>>>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  accounts?: Resolver<Maybe<ResolversTypes['AccountsMutations']>, ParentType, ContextType>;
  activateRelayModule?: Resolver<Maybe<ResolversTypes['RelayModule']>, ParentType, ContextType, RequireFields<MutationActivateRelayModuleArgs, 'input'>>;
  addChannelIntegration?: Resolver<ResolversTypes['ChannelIntegration'], ParentType, ContextType, RequireFields<MutationAddChannelIntegrationArgs, 'channelId' | 'integrationId'>>;
  addChannelMerchantAccount?: Resolver<ResolversTypes['ChannelMerchant'], ParentType, ContextType, RequireFields<MutationAddChannelMerchantAccountArgs, 'merchantAccount'>>;
  addChannelRelationship?: Resolver<ResolversTypes['ChannelRelationship'], ParentType, ContextType, RequireFields<MutationAddChannelRelationshipArgs, 'relationship'>>;
  addContentTemplateToLibrary?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType, RequireFields<MutationAddContentTemplateToLibraryArgs, 'libraryItem'>>;
  addCreditCard?: Resolver<Maybe<ResolversTypes['CreditCard']>, ParentType, ContextType, RequireFields<MutationAddCreditCardArgs, 'source'>>;
  addGroupRole?: Resolver<ResolversTypes['GroupRole'], ParentType, ContextType, RequireFields<MutationAddGroupRoleArgs, 'groupRole'>>;
  addLibraryAccess?: Resolver<ResolversTypes['LibraryAccess'], ParentType, ContextType, RequireFields<MutationAddLibraryAccessArgs, 'libraryAccess'>>;
  addMediaToLibrary?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType, RequireFields<MutationAddMediaToLibraryArgs, 'libraryItem'>>;
  addOAuthLogin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddOAuthLoginArgs, 'loginType' | 'provider' | 'userKey'>>;
  addSectionContent?: Resolver<ResolversTypes['SectionContent'], ParentType, ContextType, RequireFields<MutationAddSectionContentArgs, 'sectionContent'>>;
  addSectionToChannel?: Resolver<ResolversTypes['Section'], ParentType, ContextType, RequireFields<MutationAddSectionToChannelArgs, 'section'>>;
  addUserGroupRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddUserGroupRoleArgs, 'userGroupRole'>>;
  archiveAudience?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationArchiveAudienceArgs, 'audienceId' | 'channelId'>>;
  archiveChannel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationArchiveChannelArgs, 'channelId'>>;
  archiveEscalationRule?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationArchiveEscalationRuleArgs, 'escalationRuleId'>>;
  archiveMeterReadingValue?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationArchiveMeterReadingValueArgs, 'channelId'>>;
  assignAccessManager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAssignAccessManagerArgs, 'channelId' | 'userId'>>;
  autoSetupCES?: Resolver<ResolversTypes['AutoSetupCesEntityResponse'], ParentType, ContextType, RequireFields<MutationAutoSetupCesArgs, 'request'>>;
  cancelSubmission?: Resolver<ResolversTypes['CancelSubmissionResponse'], ParentType, ContextType, RequireFields<MutationCancelSubmissionArgs, 'submissionId'>>;
  cloneChannel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType, RequireFields<MutationCloneChannelArgs, 'channelId' | 'options'>>;
  completeSignup?: Resolver<ResolversTypes['Token'], ParentType, ContextType, RequireFields<MutationCompleteSignupArgs, 'guid' | 'isPublic' | 'password' | 'verify'>>;
  completeTask?: Resolver<ResolversTypes['CompleteTaskResponseDTO'], ParentType, ContextType, RequireFields<MutationCompleteTaskArgs, 'task'>>;
  createAccessPoint?: Resolver<Maybe<ResolversTypes['AccessPoint']>, ParentType, ContextType, RequireFields<MutationCreateAccessPointArgs, 'input'>>;
  createApiKey?: Resolver<ResolversTypes['ApiKey'], ParentType, ContextType, RequireFields<MutationCreateApiKeyArgs, 'apiKey'>>;
  createAppleWalletIssuanceToken?: Resolver<ResolversTypes['AppleWalletIssuanceToken'], ParentType, ContextType, RequireFields<MutationCreateAppleWalletIssuanceTokenArgs, 'channelId' | 'provider'>>;
  createAppleWatchIssuanceToken?: Resolver<ResolversTypes['AppleWalletIssuanceToken'], ParentType, ContextType, RequireFields<MutationCreateAppleWatchIssuanceTokenArgs, 'channelId' | 'provider'>>;
  createAttachment?: Resolver<ResolversTypes['Attachment'], ParentType, ContextType, Partial<MutationCreateAttachmentArgs>>;
  createAudience?: Resolver<ResolversTypes['CreateAudienceResponse'], ParentType, ContextType, Partial<MutationCreateAudienceArgs>>;
  createAuth0MembershipInvitation?: Resolver<ResolversTypes['Auth0MembershipInvitation'], ParentType, ContextType, RequireFields<MutationCreateAuth0MembershipInvitationArgs, 'email'>>;
  createAutoSetupConfiguration?: Resolver<ResolversTypes['JobResponse'], ParentType, ContextType, Partial<MutationCreateAutoSetupConfigurationArgs>>;
  createBluetoothInvitationCode?: Resolver<ResolversTypes['BluetoothInvitationCode'], ParentType, ContextType, RequireFields<MutationCreateBluetoothInvitationCodeArgs, 'channelId' | 'provider'>>;
  createChannel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType, RequireFields<MutationCreateChannelArgs, 'channel'>>;
  createChannelContract?: Resolver<ResolversTypes['ChannelContract'], ParentType, ContextType, RequireFields<MutationCreateChannelContractArgs, 'channelContract'>>;
  createChannelIntegration?: Resolver<Maybe<ResolversTypes['ChannelIntegration']>, ParentType, ContextType, RequireFields<MutationCreateChannelIntegrationArgs, 'channelIntegration'>>;
  createChannelModule?: Resolver<ResolversTypes['ChannelModule'], ParentType, ContextType, RequireFields<MutationCreateChannelModuleArgs, 'channel' | 'channelModule'>>;
  createContentInteraction?: Resolver<ResolversTypes['UserContentInteraction'], ParentType, ContextType, RequireFields<MutationCreateContentInteractionArgs, 'contentId' | 'interaction'>>;
  createContentTemplate?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType, RequireFields<MutationCreateContentTemplateArgs, 'libraryItem'>>;
  createDatasetRecord?: Resolver<ResolversTypes['DatasetRecord'], ParentType, ContextType, RequireFields<MutationCreateDatasetRecordArgs, 'datasetRecord'>>;
  createDatasetSchema?: Resolver<ResolversTypes['DatasetSchema'], ParentType, ContextType, RequireFields<MutationCreateDatasetSchemaArgs, 'libraryItem'>>;
  createDraft?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType, Partial<MutationCreateDraftArgs>>;
  createEquipment?: Resolver<ResolversTypes['Equipment'], ParentType, ContextType, Partial<MutationCreateEquipmentArgs>>;
  createEscalationRule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationCreateEscalationRuleArgs, 'escalationRule'>>;
  createFloor?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationCreateFloorArgs, 'floor' | 'propertyId'>>;
  createHardware?: Resolver<ResolversTypes['Hardware'], ParentType, ContextType, RequireFields<MutationCreateHardwareArgs, 'input'>>;
  createHistoricalUCI?: Resolver<ResolversTypes['UserContentInteraction'], ParentType, ContextType, RequireFields<MutationCreateHistoricalUciArgs, 'contentId' | 'created' | 'creatorId' | 'interaction'>>;
  createIntegration?: Resolver<ResolversTypes['Integration'], ParentType, ContextType, RequireFields<MutationCreateIntegrationArgs, 'integration'>>;
  createLibrary?: Resolver<ResolversTypes['Library'], ParentType, ContextType, RequireFields<MutationCreateLibraryArgs, 'library'>>;
  createLibraryPath?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType, RequireFields<MutationCreateLibraryPathArgs, 'libraryItem'>>;
  createMedia?: Resolver<ResolversTypes['LibraryItemWithOriginalExtension'], ParentType, ContextType, RequireFields<MutationCreateMediaArgs, 'libraryItem'>>;
  createMetatag?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType, RequireFields<MutationCreateMetatagArgs, 'libraryItem'>>;
  createMeterReadingValue?: Resolver<ResolversTypes['MeterReadingValue'], ParentType, ContextType, RequireFields<MutationCreateMeterReadingValueArgs, 'channelId'>>;
  createMultipleMeterReadingValue?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationCreateMultipleMeterReadingValueArgs, 'channelId'>>;
  createNullEntityOrganization?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationCreateNullEntityOrganizationArgs, 'parentChannelId'>>;
  createOIDCServiceProviderDefinition?: Resolver<ResolversTypes['OidcServiceProviderDefinition'], ParentType, ContextType, RequireFields<MutationCreateOidcServiceProviderDefinitionArgs, 'channelId' | 'oidcServiceProviderDefinition'>>;
  createOneTimePassword?: Resolver<ResolversTypes['OneTimePassword'], ParentType, ContextType, RequireFields<MutationCreateOneTimePasswordArgs, 'userId'>>;
  createPMSchedule?: Resolver<ResolversTypes['ClientPMSchedule'], ParentType, ContextType, RequireFields<MutationCreatePmScheduleArgs, 'schedule'>>;
  createProduct?: Resolver<Maybe<ResolversTypes['CreateProductResponse']>, ParentType, ContextType, RequireFields<MutationCreateProductArgs, 'createProductRequest'>>;
  createProductCategory?: Resolver<Maybe<ResolversTypes['CreateProductCategoryResponse']>, ParentType, ContextType, Partial<MutationCreateProductCategoryArgs>>;
  createProductException?: Resolver<Maybe<ResolversTypes['CreateProductExceptionResponse']>, ParentType, ContextType, Partial<MutationCreateProductExceptionArgs>>;
  createPropertyBuilding?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationCreatePropertyBuildingArgs, 'channelId'>>;
  createSAMLServiceProviderDefinition?: Resolver<ResolversTypes['SAMLServiceProviderDefinition'], ParentType, ContextType, RequireFields<MutationCreateSamlServiceProviderDefinitionArgs, 'SAMLServiceProviderDefinition' | 'channelId'>>;
  createServiceRequest?: Resolver<ResolversTypes['ServiceRequest'], ParentType, ContextType, Partial<MutationCreateServiceRequestArgs>>;
  createTag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType, RequireFields<MutationCreateTagArgs, 'tag'>>;
  createTargetingRule?: Resolver<ResolversTypes['CreateTargetingRuleResponse'], ParentType, ContextType, Partial<MutationCreateTargetingRuleArgs>>;
  createTask?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationCreateTaskArgs, 'task'>>;
  createTenant?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateTenantArgs, 'propertyId' | 'tenantId'>>;
  createUnit?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationCreateUnitArgs, 'propertyId' | 'unit'>>;
  createUserAndAddToTeams?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateUserAndAddToTeamsArgs, 'channelId' | 'email' | 'name'>>;
  createUserIntegration?: Resolver<ResolversTypes['UserIntegration'], ParentType, ContextType, RequireFields<MutationCreateUserIntegrationArgs, 'addToLogins' | 'channelIntegrationId' | 'userKey'>>;
  createVisitor?: Resolver<ResolversTypes['Visitor'], ParentType, ContextType, RequireFields<MutationCreateVisitorArgs, 'visitor'>>;
  createVisitors?: Resolver<Array<ResolversTypes['Visitor']>, ParentType, ContextType, Partial<MutationCreateVisitorsArgs>>;
  createWebhook?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType, RequireFields<MutationCreateWebhookArgs, 'libraryItem'>>;
  createWhitelabel?: Resolver<ResolversTypes['Whitelabel'], ParentType, ContextType, RequireFields<MutationCreateWhitelabelArgs, 'whitelabel'>>;
  createWorkflow?: Resolver<ResolversTypes['Workflow'], ParentType, ContextType, Partial<MutationCreateWorkflowArgs>>;
  deactivateRelayModule?: Resolver<Maybe<ResolversTypes['RelayModule']>, ParentType, ContextType, RequireFields<MutationDeactivateRelayModuleArgs, 'input'>>;
  deleteAttachment?: Resolver<ResolversTypes['Attachment'], ParentType, ContextType, Partial<MutationDeleteAttachmentArgs>>;
  deleteAudience?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteAudienceArgs, 'audienceId' | 'channelId'>>;
  deleteChannelIntegration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteChannelIntegrationArgs, '_id'>>;
  deleteChannelModule?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteChannelModuleArgs, '_id'>>;
  deleteChannelRelationship?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteChannelRelationshipArgs, '_id'>>;
  deleteContentTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteContentTemplateArgs, '_id'>>;
  deleteDatasetRecord?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDatasetRecordArgs, 'id'>>;
  deleteDatasetSchema?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDatasetSchemaArgs, 'id'>>;
  deleteDraft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDraftArgs, '_id'>>;
  deleteEquipment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationDeleteEquipmentArgs>>;
  deleteFloor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFloorArgs, 'floorId' | 'propertyId'>>;
  deleteGroupRole?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteGroupRoleArgs, '_id'>>;
  deleteIntegration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationDeleteIntegrationArgs>>;
  deleteInvite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteInviteArgs, '_id'>>;
  deleteLibrary?: Resolver<ResolversTypes['Library'], ParentType, ContextType, RequireFields<MutationDeleteLibraryArgs, '_id'>>;
  deleteMedia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteMediaArgs, '_id' | 'libraryId'>>;
  deleteMetatag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteMetatagArgs, '_id'>>;
  deletePMSchedule?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePmScheduleArgs, 'scheduleId'>>;
  deleteProduct?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, Partial<MutationDeleteProductArgs>>;
  deleteProductException?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationDeleteProductExceptionArgs, 'deleteProductExceptionRequest'>>;
  deleteSection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSectionArgs, '_id'>>;
  deleteTag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTagArgs, 'id'>>;
  deleteTargetingRule?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationDeleteTargetingRuleArgs>>;
  deleteTask?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTaskArgs, 'id'>>;
  deleteUnit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUnitArgs, 'propertyId' | 'unitId'>>;
  deleteUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'userId'>>;
  deleteUserGroupRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUserGroupRoleArgs, '_id'>>;
  deleteWebhook?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteWebhookArgs, '_id'>>;
  deleteWorkflow?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationDeleteWorkflowArgs, 'workflowId'>>;
  disableApiKey?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDisableApiKeyArgs, 'apiKey'>>;
  disableMobileAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDisableMobileAccessArgs, 'channelId' | 'userIds'>>;
  disableUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDisableUserArgs, 'userId'>>;
  enableMobileAccess?: Resolver<Array<ResolversTypes['enableMobileAccessResponse']>, ParentType, ContextType, RequireFields<MutationEnableMobileAccessArgs, 'channelId' | 'userClearances'>>;
  enableUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationEnableUserArgs, 'userId'>>;
  essensys?: Resolver<Maybe<ResolversTypes['EssensysMutation']>, ParentType, ContextType>;
  finalizeTenantOnboarding?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationFinalizeTenantOnboardingArgs, 'channelIds'>>;
  forgotPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'email'>>;
  generateFloorMapChannelIntegration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationGenerateFloorMapChannelIntegrationArgs, 'channelIntegrationId'>>;
  getCurrentAuth0Connection?: Resolver<Maybe<ResolversTypes['Auth0Connection']>, ParentType, ContextType, RequireFields<MutationGetCurrentAuth0ConnectionArgs, 'email'>>;
  joinChannel?: Resolver<ResolversTypes['PublicUserGroupRole'], ParentType, ContextType, RequireFields<MutationJoinChannelArgs, 'channelId'>>;
  joinWaitlist?: Resolver<ResolversTypes['ContentWaitlistEnrollment'], ParentType, ContextType, RequireFields<MutationJoinWaitlistArgs, 'contentId' | 'endDate' | 'startDate' | 'userId'>>;
  leaveChannel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLeaveChannelArgs, 'userGroupRoleId'>>;
  leaveReservableWaitlist?: Resolver<ResolversTypes['ReservableWaitlistUnrollment'], ParentType, ContextType, RequireFields<MutationLeaveReservableWaitlistArgs, 'enrollmentId'>>;
  leaveWaitlist?: Resolver<ResolversTypes['ContentWaitlistEnrollment'], ParentType, ContextType, RequireFields<MutationLeaveWaitlistArgs, 'contentId' | 'enrollmentId'>>;
  linkActivateOrganizationToCesAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLinkActivateOrganizationToCesAccountArgs, 'parentChannelId' | 'targetSourceId'>>;
  linkEntity?: Resolver<ResolversTypes['LinkedEntity'], ParentType, ContextType, Partial<MutationLinkEntityArgs>>;
  manageUserCompanies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationManageUserCompaniesArgs, 'propertyChannelId' | 'userId'>>;
  me?: Resolver<Maybe<ResolversTypes['MeMutation']>, ParentType, ContextType>;
  mergeUsers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMergeUsersArgs, 'destinationUserId' | 'userId'>>;
  migrateRiseUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMigrateRiseUserArgs, 'channelId' | 'email' | 'name' | 'password'>>;
  parkwhizAddVehicle?: Resolver<ResolversTypes['ParkWhizVehicle'], ParentType, ContextType, RequireFields<MutationParkwhizAddVehicleArgs, 'label' | 'plate_number'>>;
  payments?: Resolver<Maybe<ResolversTypes['PaymentsMutation']>, ParentType, ContextType>;
  pinSectionContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPinSectionContentArgs, 'contentId' | 'sectionId'>>;
  placeContent?: Resolver<ResolversTypes['ContentPlacement'], ParentType, ContextType, RequireFields<MutationPlaceContentArgs, 'placement'>>;
  provisionAuth0UserToOrganization?: Resolver<Maybe<ResolversTypes['Auth0ProvisionUserToOrganization']>, ParentType, ContextType, RequireFields<MutationProvisionAuth0UserToOrganizationArgs, 'connection' | 'email'>>;
  publishDraft?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<MutationPublishDraftArgs, '_id'>>;
  pullContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPullContentArgs, 'contentId' | 'userId'>>;
  redeemOneTimePassword?: Resolver<ResolversTypes['Token'], ParentType, ContextType, RequireFields<MutationRedeemOneTimePasswordArgs, 'otp'>>;
  regenerateHardwareApiKey?: Resolver<ResolversTypes['Hardware'], ParentType, ContextType, RequireFields<MutationRegenerateHardwareApiKeyArgs, 'id'>>;
  registerKioskVisitorPassScan?: Resolver<ResolversTypes['RegisterKioskVisitorPassScanResponse'], ParentType, ContextType, RequireFields<MutationRegisterKioskVisitorPassScanArgs, 'qrCode'>>;
  relateActivateChannelToCesProperty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRelateActivateChannelToCesPropertyArgs, 'channelId' | 'targetSourceId'>>;
  relateSpacesToTenant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRelateSpacesToTenantArgs, 'propertyId' | 'suitesToAdd' | 'suitesToRemove' | 'tenantId'>>;
  removeContentTemplateFromLibrary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveContentTemplateFromLibraryArgs, 'libraryItem'>>;
  removeCreditCard?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveCreditCardArgs, 'id'>>;
  removeLibraryAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveLibraryAccessArgs, '_id'>>;
  removeMediaFromLibrary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveMediaFromLibraryArgs, '_id'>>;
  removeSectionContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveSectionContentArgs, '_id'>>;
  removeTenant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveTenantArgs, 'tenantId' | 'unitId'>>;
  removeUserFromChannel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveUserFromChannelArgs, 'channelId' | 'userId'>>;
  removeUserIntegration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveUserIntegrationArgs, 'channelIntegrationId'>>;
  reorderSection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationReorderSectionArgs, 'contentId' | 'order' | 'sectionId'>>;
  requestAccountDeletion?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType, RequireFields<MutationRequestAccountDeletionArgs, 'userLoginId'>>;
  requestPhoneNumberVerification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestPhoneNumberVerificationArgs, 'userLoginId'>>;
  resendInvite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResendInviteArgs, '_id'>>;
  resendVerificationEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResendVerificationEmailArgs, 'email'>>;
  resetPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'guid' | 'password'>>;
  resetUserPasswordsAdmin?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<MutationResetUserPasswordsAdminArgs, '_ids'>>;
  resumeCredential?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResumeCredentialArgs, 'channelId' | 'mapId'>>;
  resumeCredentialGroup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResumeCredentialGroupArgs, 'channelId' | 'credentialGroupId' | 'provider'>>;
  revertAccountDeletion?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRevertAccountDeletionArgs, 'jti'>>;
  revokeAccessManager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRevokeAccessManagerArgs, 'channelId' | 'userId'>>;
  revokeCredential?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRevokeCredentialArgs, 'channelId' | 'mapId'>>;
  revokeCredentialGroup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRevokeCredentialGroupArgs, 'channelId' | 'credentialGroupId' | 'provider'>>;
  saveConfig?: Resolver<ResolversTypes['SaveConfigResponse'], ParentType, ContextType, Partial<MutationSaveConfigArgs>>;
  sendCompleteSignup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendCompleteSignupArgs, 'userGroupRoleId'>>;
  sendEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSendEventArgs, 'data' | 'event'>>;
  sendInvite?: Resolver<ResolversTypes['ChannelInvite'], ParentType, ContextType, RequireFields<MutationSendInviteArgs, 'email' | 'name'>>;
  sendMagicLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendMagicLinkArgs, 'email'>>;
  sendOptInMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendOptInMessageArgs, 'userId'>>;
  sendUrgentCommunication?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSendUrgentCommunicationArgs, 'body' | 'propertyId'>>;
  signIn?: Resolver<ResolversTypes['Token'], ParentType, ContextType, RequireFields<MutationSignInArgs, 'loginKey'>>;
  signOut?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSignOutArgs, 'jti'>>;
  signUp?: Resolver<ResolversTypes['Token'], ParentType, ContextType, RequireFields<MutationSignUpArgs, 'loginKey' | 'loginType'>>;
  softDeleteChannelIntegration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSoftDeleteChannelIntegrationArgs, '_id'>>;
  startImportJob?: Resolver<ResolversTypes['StartImportJobResponse'], ParentType, ContextType, Partial<MutationStartImportJobArgs>>;
  submitFeedbackForm?: Resolver<Maybe<ResolversTypes['FeedbackForm']>, ParentType, ContextType, Partial<MutationSubmitFeedbackFormArgs>>;
  subscribeUserToEvent?: Resolver<Maybe<ResolversTypes['EventSubscription']>, ParentType, ContextType, RequireFields<MutationSubscribeUserToEventArgs, 'eventSubscription'>>;
  suspendCredential?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSuspendCredentialArgs, 'channelId' | 'mapId'>>;
  suspendCredentialGroup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSuspendCredentialGroupArgs, 'channelId' | 'credentialGroupId' | 'provider'>>;
  syncGroupRoleForExistingUsers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSyncGroupRoleForExistingUsersArgs, 'syncGroupRole'>>;
  touchChannel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType, RequireFields<MutationTouchChannelArgs, '_id'>>;
  touchContent?: Resolver<ResolversTypes['Content'], ParentType, ContextType, RequireFields<MutationTouchContentArgs, '_id'>>;
  touchUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationTouchUserArgs, '_id'>>;
  trackAnalytics?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationTrackAnalyticsArgs, 'track'>>;
  triggerAccessPoint?: Resolver<Maybe<ResolversTypes['TriggerAccessPointResponse']>, ParentType, ContextType, RequireFields<MutationTriggerAccessPointArgs, 'id'>>;
  unPublishContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUnPublishContentArgs, '_id'>>;
  unpinSectionContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUnpinSectionContentArgs, 'contentId' | 'sectionId'>>;
  unsubscribeFromEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUnsubscribeFromEventArgs, '_id'>>;
  updateAccessPoint?: Resolver<Maybe<ResolversTypes['AccessPoint']>, ParentType, ContextType, RequireFields<MutationUpdateAccessPointArgs, 'input'>>;
  updateAudience?: Resolver<ResolversTypes['UpdateAudienceResponse'], ParentType, ContextType, Partial<MutationUpdateAudienceArgs>>;
  updateBulkUsersAccessControlGroups?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateBulkUsersAccessControlGroupsArgs, 'assignedAccessControlGroups' | 'channelId' | 'revokedAccessControlGroups' | 'userIds'>>;
  updateChannel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType, RequireFields<MutationUpdateChannelArgs, 'channel'>>;
  updateChannelContract?: Resolver<ResolversTypes['ChannelContract'], ParentType, ContextType, RequireFields<MutationUpdateChannelContractArgs, 'channelContract'>>;
  updateChannelGroupRoles?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateChannelGroupRolesArgs, 'groupRole'>>;
  updateChannelIntegration?: Resolver<Maybe<ResolversTypes['ChannelIntegration']>, ParentType, ContextType, RequireFields<MutationUpdateChannelIntegrationArgs, 'channelIntegration'>>;
  updateChannelModule?: Resolver<ResolversTypes['ChannelModule'], ParentType, ContextType, RequireFields<MutationUpdateChannelModuleArgs, 'channelModule'>>;
  updateContentInteraction?: Resolver<ResolversTypes['UserContentInteraction'], ParentType, ContextType, RequireFields<MutationUpdateContentInteractionArgs, 'interaction'>>;
  updateContentTemplate?: Resolver<ResolversTypes['ContentTemplate'], ParentType, ContextType, RequireFields<MutationUpdateContentTemplateArgs, 'template'>>;
  updateDatasetRecord?: Resolver<ResolversTypes['DatasetRecord'], ParentType, ContextType, RequireFields<MutationUpdateDatasetRecordArgs, 'datasetRecord'>>;
  updateDatasetSchema?: Resolver<ResolversTypes['DatasetSchema'], ParentType, ContextType, RequireFields<MutationUpdateDatasetSchemaArgs, 'datasetSchema'>>;
  updateDraft?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType, Partial<MutationUpdateDraftArgs>>;
  updateEquipment?: Resolver<ResolversTypes['Equipment'], ParentType, ContextType, Partial<MutationUpdateEquipmentArgs>>;
  updateEquipmentSettingsOnChannel?: Resolver<ResolversTypes['ChannelEquipmentSettings'], ParentType, ContextType, RequireFields<MutationUpdateEquipmentSettingsOnChannelArgs, 'settings'>>;
  updateEscalationRule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUpdateEscalationRuleArgs, 'escalationRule'>>;
  updateFloor?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationUpdateFloorArgs, 'floor' | 'floorId' | 'propertyId'>>;
  updateGroupRole?: Resolver<ResolversTypes['GroupRole'], ParentType, ContextType, RequireFields<MutationUpdateGroupRoleArgs, 'groupRole'>>;
  updateHardware?: Resolver<ResolversTypes['Hardware'], ParentType, ContextType, RequireFields<MutationUpdateHardwareArgs, 'id' | 'input'>>;
  updateHardwareDeviceInfo?: Resolver<ResolversTypes['UpdateHardwareDeviceInfoResponse'], ParentType, ContextType, RequireFields<MutationUpdateHardwareDeviceInfoArgs, 'input'>>;
  updateIntegration?: Resolver<ResolversTypes['Integration'], ParentType, ContextType, Partial<MutationUpdateIntegrationArgs>>;
  updateLibrary?: Resolver<ResolversTypes['Library'], ParentType, ContextType, RequireFields<MutationUpdateLibraryArgs, 'library'>>;
  updateLibraryItem?: Resolver<ResolversTypes['LibraryItem'], ParentType, ContextType, RequireFields<MutationUpdateLibraryItemArgs, 'libraryItem'>>;
  updateMedia?: Resolver<ResolversTypes['MediaWithOriginalExtension'], ParentType, ContextType, RequireFields<MutationUpdateMediaArgs, 'media'>>;
  updateMetatag?: Resolver<ResolversTypes['Metatag'], ParentType, ContextType, Partial<MutationUpdateMetatagArgs>>;
  updateMeterReadingValue?: Resolver<ResolversTypes['MeterReadingValue'], ParentType, ContextType, RequireFields<MutationUpdateMeterReadingValueArgs, 'channelId'>>;
  updatePMSchedule?: Resolver<ResolversTypes['UpdatePMScheduleResponseDTO'], ParentType, ContextType, RequireFields<MutationUpdatePmScheduleArgs, 'schedule'>>;
  updateProduct?: Resolver<Maybe<ResolversTypes['UpdateProductResponse']>, ParentType, ContextType, Partial<MutationUpdateProductArgs>>;
  updateProductException?: Resolver<ResolversTypes['UpdateProductExceptionResponse'], ParentType, ContextType, RequireFields<MutationUpdateProductExceptionArgs, 'updateProductExceptionRequest'>>;
  updateRecurrencePasses?: Resolver<ResolversTypes['UpdateRecurrencePassesResponse'], ParentType, ContextType, RequireFields<MutationUpdateRecurrencePassesArgs, 'newSchedule' | 'newScheduleDuration' | 'recurrenceId'>>;
  updateRelayModule?: Resolver<ResolversTypes['RelayModule'], ParentType, ContextType, RequireFields<MutationUpdateRelayModuleArgs, 'id' | 'input'>>;
  updateRelayOutput?: Resolver<ResolversTypes['RelayOutput'], ParentType, ContextType, RequireFields<MutationUpdateRelayOutputArgs, 'id' | 'input'>>;
  updateSection?: Resolver<ResolversTypes['Section'], ParentType, ContextType, RequireFields<MutationUpdateSectionArgs, 'section'>>;
  updateSectionContent?: Resolver<ResolversTypes['SectionContent'], ParentType, ContextType, RequireFields<MutationUpdateSectionContentArgs, 'sectionContent'>>;
  updateServiceRequest?: Resolver<ResolversTypes['ServiceRequestUpdateResponseDTO'], ParentType, ContextType, RequireFields<MutationUpdateServiceRequestArgs, 'serviceRequest'>>;
  updateServiceRequestStatusByUCIId?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationUpdateServiceRequestStatusByUciIdArgs, 'status' | 'uciId'>>;
  updateTag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType, RequireFields<MutationUpdateTagArgs, 'tag'>>;
  updateTargetingRule?: Resolver<ResolversTypes['UpdateTargetingRuleResponse'], ParentType, ContextType, Partial<MutationUpdateTargetingRuleArgs>>;
  updateTask?: Resolver<ResolversTypes['UpdateTaskResponseDTO'], ParentType, ContextType, RequireFields<MutationUpdateTaskArgs, 'task'>>;
  updateTaskStatus?: Resolver<ResolversTypes['UpdateTaskResponseDTO'], ParentType, ContextType, RequireFields<MutationUpdateTaskStatusArgs, 'task'>>;
  updateTaskSteps?: Resolver<ResolversTypes['UpdateTaskResponseDTO'], ParentType, ContextType, RequireFields<MutationUpdateTaskStepsArgs, 'task'>>;
  updateTheme?: Resolver<ResolversTypes['Theme'], ParentType, ContextType, Partial<MutationUpdateThemeArgs>>;
  updateUnit?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, RequireFields<MutationUpdateUnitArgs, 'propertyId' | 'unit' | 'unitId'>>;
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'user'>>;
  updateUserAccessControlGroups?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateUserAccessControlGroupsArgs, 'assignedAccessControlGroups' | 'channelId' | 'revokedAccessControlGroups' | 'userId'>>;
  updateUserChannelSettings?: Resolver<Maybe<ResolversTypes['UserChannelSettings']>, ParentType, ContextType, RequireFields<MutationUpdateUserChannelSettingsArgs, 'channelId' | 'userId'>>;
  updateUserGrantSuper?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserGrantSuperArgs, 'userId'>>;
  updateUserRevokeSuper?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserRevokeSuperArgs, 'userId'>>;
  updateUserStatusesForChannel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationUpdateUserStatusesForChannelArgs, 'channelId'>>;
  updateVisitorPass?: Resolver<ResolversTypes['VisitorPassUpdateResponse'], ParentType, ContextType, RequireFields<MutationUpdateVisitorPassArgs, 'visitorPassId'>>;
  updateVisitorPassStatus?: Resolver<ResolversTypes['VisitorPassUpdateResponse'], ParentType, ContextType, RequireFields<MutationUpdateVisitorPassStatusArgs, 'status' | 'visitorPassId'>>;
  updateWebhook?: Resolver<ResolversTypes['Webhook'], ParentType, ContextType, RequireFields<MutationUpdateWebhookArgs, 'webhook'>>;
  updateWhitelabel?: Resolver<ResolversTypes['Whitelabel'], ParentType, ContextType, RequireFields<MutationUpdateWhitelabelArgs, 'whitelabel'>>;
  updateWorkflow?: Resolver<ResolversTypes['Workflow'], ParentType, ContextType, RequireFields<MutationUpdateWorkflowArgs, 'workflowId'>>;
  uploadCSV?: Resolver<ResolversTypes['UploadCSVResponse'], ParentType, ContextType, Partial<MutationUploadCsvArgs>>;
  upsertDatasetRecords?: Resolver<Array<ResolversTypes['DatasetRecord']>, ParentType, ContextType, RequireFields<MutationUpsertDatasetRecordsArgs, 'datasetRecords' | 'datasetSchemaId'>>;
  upsertServiceRequestCategory?: Resolver<ResolversTypes['CategoryUpsertResponseDTO'], ParentType, ContextType, RequireFields<MutationUpsertServiceRequestCategoryArgs, 'category'>>;
  upsertServiceRequestIssue?: Resolver<ResolversTypes['IssueUpsertResponseDTO'], ParentType, ContextType, RequireFields<MutationUpsertServiceRequestIssueArgs, 'issue'>>;
  upsertServiceRequestLocation?: Resolver<ResolversTypes['LocationUpsertResponseDTO'], ParentType, ContextType, RequireFields<MutationUpsertServiceRequestLocationArgs, 'location'>>;
  upsertUserGroupRoles?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpsertUserGroupRolesArgs, 'groupRoleIds' | 'userId'>>;
  upsertVisitorEmailTemplate?: Resolver<ResolversTypes['VisitorEmailTemplate'], ParentType, ContextType, RequireFields<MutationUpsertVisitorEmailTemplateArgs, 'emailTemplate'>>;
  upsertVisitorOrganizationSettings?: Resolver<ResolversTypes['VisitorOrganizationSettings'], ParentType, ContextType, RequireFields<MutationUpsertVisitorOrganizationSettingsArgs, 'organizationSettings'>>;
  verifyEmailOnBehalfOfUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationVerifyEmailOnBehalfOfUserArgs, 'channelId' | 'loginId'>>;
  verifyPhoneNumber?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationVerifyPhoneNumberArgs, 'code' | 'userLoginId'>>;
};

export type OAuthResolvers<ContextType = any, ParentType extends ResolversParentTypes['OAuth'] = ResolversParentTypes['OAuth']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiresIn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OAuthConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['OAuthConfig'] = ResolversParentTypes['OAuthConfig']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  androidClientId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  androidRedirectUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buttonLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  iosClientId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  iosRedirectUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  webClientId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  webClientSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webRedirectUrl?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectAnalytics'] = ResolversParentTypes['ObjectAnalytics']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  deletes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  details?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hides?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  interactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pins?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ratings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shares?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stars?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectCount'] = ResolversParentTypes['ObjectCount']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectDatasetRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectDatasetRecord'] = ResolversParentTypes['ObjectDatasetRecord']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  datasetRecord?: Resolver<Maybe<ResolversTypes['DatasetRecord']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectMetatagResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectMetatag'] = ResolversParentTypes['ObjectMetatag']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>;
  metatag?: Resolver<ResolversTypes['Metatag'], ParentType, ContextType>;
  section?: Resolver<Maybe<ResolversTypes['Section']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  values?: Resolver<Maybe<Array<ResolversTypes['ObjectMetatagValue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectMetatagValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectMetatagValue'] = ResolversParentTypes['ObjectMetatagValue']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  metatagValue?: Resolver<ResolversTypes['MetatagValue'], ParentType, ContextType>;
  objectMetatag?: Resolver<ResolversTypes['ObjectMetatag'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OidcServiceProviderDefinitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OidcServiceProviderDefinition'] = ResolversParentTypes['OidcServiceProviderDefinition']> = {
  auth0ClientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  customFields?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OneTimePasswordResolvers<ContextType = any, ParentType extends ResolversParentTypes['OneTimePassword'] = ResolversParentTypes['OneTimePassword']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnlinePlanQuoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnlinePlanQuote'] = ResolversParentTypes['OnlinePlanQuote']> = {
  currencyDetail?: Resolver<ResolversTypes['CurrencyDetail'], ParentType, ContextType>;
  productLineItems?: Resolver<Array<ResolversTypes['ProductLineItem']>, ParentType, ContextType>;
  totals?: Resolver<ResolversTypes['Totals'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpenpathSetupMobileTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpenpathSetupMobileToken'] = ResolversParentTypes['OpenpathSetupMobileToken']> = {
  setupMobileToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderItemDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderItemDetails'] = ResolversParentTypes['OrderItemDetails']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dollarAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderSummary'] = ResolversParentTypes['OrderSummary']> = {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['OrderItemDetails']>>>, ParentType, ContextType>;
  subtotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Owner'] = ResolversParentTypes['Owner']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['OwnerType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PmScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PMSchedule'] = ResolversParentTypes['PMSchedule']> = {
  assignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assigneeGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  completeWithin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  daysAhead?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  equipmentIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  extRefId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meterReading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthNo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repeats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeToComplete?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  untilDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weekNo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekday?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PmSchedulesForExportResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PMSchedulesForExportResponseDTO'] = ResolversParentTypes['PMSchedulesForExportResponseDTO']> = {
  schedules?: Resolver<Maybe<Array<ResolversTypes['ClientPMSchedule']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  perPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagesOnChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagesOnChannel'] = ResolversParentTypes['PagesOnChannel']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelPages?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChannelPage']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedSectionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedSections'] = ResolversParentTypes['PaginatedSections']> = {
  sections?: Resolver<Maybe<Array<ResolversTypes['Section']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizAmenitiyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizAmenitiy'] = ResolversParentTypes['ParkWhizAmenitiy']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizBookPreviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizBookPreview'] = ResolversParentTypes['ParkWhizBookPreview']> = {
  coupon_amount?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  final_price?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  quote_price?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subtotal?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  user_credit_amount?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizBookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizBooking'] = ResolversParentTypes['ParkWhizBooking']> = {
  _embedded?: Resolver<Maybe<ResolversTypes['ParkWhizBookingEmbedded']>, ParentType, ContextType>;
  business_purchase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cancellable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  full_price?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  location_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  on_demand?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  price_paid?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  pricing_ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  purchased_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  qrCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizBookingEmbeddedResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizBookingEmbedded'] = ResolversParentTypes['ParkWhizBookingEmbedded']> = {
  _links?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  add_ons?: Resolver<Maybe<Array<Maybe<ResolversTypes['JSON']>>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marketing_allowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  parking_pass?: Resolver<Maybe<ResolversTypes['ParkWhizParkingPass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizByGeoLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizByGeoLocation'] = ResolversParentTypes['ParkWhizByGeoLocation']> = {
  _embedded?: Resolver<Maybe<ResolversTypes['ParkWhizLocation']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['ParkWhizDistance']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  location_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  max_end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  min_start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchase_options?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizPurchaseOption']>>>, ParentType, ContextType>;
  quote_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seller_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizCoordinateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizCoordinate'] = ResolversParentTypes['ParkWhizCoordinate']> = {
  coordinates?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizDistanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizDistance'] = ResolversParentTypes['ParkWhizDistance']> = {
  straight_line?: Resolver<ResolversTypes['ParkWhizStraightLine'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizIconResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizIcon'] = ResolversParentTypes['ParkWhizIcon']> = {
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizLocation'] = ResolversParentTypes['ParkWhizLocation']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entrances?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizCoordinate']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizPhotos']>>>, ParentType, ContextType>;
  postal_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizParkingPassResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizParkingPass'] = ResolversParentTypes['ParkWhizParkingPass']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  amanities?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizAmenitiy']>>>, ParentType, ContextType>;
  disclaimers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  full_price?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pass_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price_paid?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  reseller_pass?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  seller_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validation?: Resolver<Maybe<ResolversTypes['ParkWhizValidation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizPhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizPhoto'] = ResolversParentTypes['ParkWhizPhoto']> = {
  URL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizPhotoSizesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizPhotoSizes'] = ResolversParentTypes['ParkWhizPhotoSizes']> = {
  galery?: Resolver<Maybe<ResolversTypes['ParkWhizPhoto']>, ParentType, ContextType>;
  gallery_thumb?: Resolver<Maybe<ResolversTypes['ParkWhizPhoto']>, ParentType, ContextType>;
  hub_frontpage?: Resolver<Maybe<ResolversTypes['ParkWhizPhoto']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['ParkWhizPhoto']>, ParentType, ContextType>;
  original?: Resolver<Maybe<ResolversTypes['ParkWhizPhoto']>, ParentType, ContextType>;
  res_ticket?: Resolver<Maybe<ResolversTypes['ParkWhizPhoto']>, ParentType, ContextType>;
  search_thumb?: Resolver<Maybe<ResolversTypes['ParkWhizPhoto']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizPhotosResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizPhotos'] = ResolversParentTypes['ParkWhizPhotos']> = {
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sizes?: Resolver<Maybe<ResolversTypes['ParkWhizPhotoSizes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizPricingSegmentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizPricingSegments'] = ResolversParentTypes['ParkWhizPricingSegments']> = {
  end_time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pricing_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  space_availability?: Resolver<Maybe<ResolversTypes['ParkWhizSpaceAvailability']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizPurchaseOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizPurchaseOption'] = ResolversParentTypes['ParkWhizPurchaseOption']> = {
  amenities?: Resolver<Maybe<Array<ResolversTypes['ParkWhizAmenitiy']>>, ParentType, ContextType>;
  curation_score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  end_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max_end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  min_start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  price_advisory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pricing_segments?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizPricingSegments']>>>, ParentType, ContextType>;
  space_availability?: Resolver<Maybe<ResolversTypes['ParkWhizSpaceAvailability']>, ParentType, ContextType>;
  start_time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  validation?: Resolver<Maybe<ResolversTypes['ParkWhizValidation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizScanCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizScanCode'] = ResolversParentTypes['ParkWhizScanCode']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qr_error_correction_level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizSpaceAvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizSpaceAvailability'] = ResolversParentTypes['ParkWhizSpaceAvailability']> = {
  spaces_remaining?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizStraightLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizStraightLine'] = ResolversParentTypes['ParkWhizStraightLine']> = {
  feet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  meter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizValidationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizValidation'] = ResolversParentTypes['ParkWhizValidation']> = {
  require_license_plate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  scan_code?: Resolver<Maybe<ResolversTypes['ParkWhizScanCode']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizValidationStep']>>>, ParentType, ContextType>;
  validation_steps?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizValidationStep']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizValidationStepResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizValidationStep'] = ResolversParentTypes['ParkWhizValidationStep']> = {
  icon?: Resolver<Maybe<ResolversTypes['ParkWhizIcon']>, ParentType, ContextType>;
  instructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParkWhizVehicleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParkWhizVehicle'] = ResolversParentTypes['ParkWhizVehicle']> = {
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plate_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plate_state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PastVisitorPassResolvers<ContextType = any, ParentType extends ResolversParentTypes['PastVisitorPass'] = ResolversParentTypes['PastVisitorPass']> = {
  result?: Resolver<ResolversTypes['PastVisitorPassResult'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PastVisitorPassResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PastVisitorPassResult'] = ResolversParentTypes['PastVisitorPassResult']> = {
  eventNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tenantNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  visits?: Resolver<Maybe<Array<ResolversTypes['VisitorPass']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PathScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Path'], any> {
  name: 'Path';
}

export type PayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payer'] = ResolversParentTypes['Payer']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ExternalPayerType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentAccount'] = ResolversParentTypes['PaymentAccount']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProcessor?: Resolver<Maybe<ResolversTypes['PaymentProcessor']>, ParentType, ContextType>;
  previousPaymentMethod?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  providerData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  providerKey?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  remotePaymentProcessor?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  rules?: Resolver<Maybe<Array<ResolversTypes['PaymentAccountRule']>>, ParentType, ContextType>;
  stale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentProviderType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentAccountRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentAccountRule'] = ResolversParentTypes['PaymentAccountRule']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  allContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  allRoles?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  anyTimes?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  contentCategories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupRole?: Resolver<Maybe<ResolversTypes['GroupRole']>, ParentType, ContextType>;
  noPurchaseLimit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  noSinglePurchaseLimit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  paymentAccount?: Resolver<ResolversTypes['PaymentAccount'], ParentType, ContextType>;
  purchaseLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  purchaseLimitTimeUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  singlePurchaseLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentFeatureLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentFeatureLineItem'] = ResolversParentTypes['PaymentFeatureLineItem']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentFeatureQuoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentFeatureQuote'] = ResolversParentTypes['PaymentFeatureQuote']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  cashTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  creditTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['PaymentFeatureLineItem']>>, ParentType, ContextType>;
  maxQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  overriddenAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['PaymentProviderType']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscriptionDetails?: Resolver<Maybe<ResolversTypes['SubscriptionDetails']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taxes?: Resolver<Maybe<Array<ResolversTypes['PaymentFeatureLineItem']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PaymentType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentProcessorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentProcessor'] = ResolversParentTypes['PaymentProcessor']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  externalKey?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  integrationProvider?: Resolver<Maybe<ResolversTypes['IntegrationProvider']>, ParentType, ContextType>;
  isSandbox?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  privateKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publicKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentProviderType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentSourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentSource'] = ResolversParentTypes['PaymentSource']> = {
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiryMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expiryYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType>;
  paymentMethodId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payments'] = ResolversParentTypes['Payments']> = {
  creditCards?: Resolver<Maybe<Array<ResolversTypes['CreditCard']>>, ParentType, ContextType, RequireFields<PaymentsCreditCardsArgs, 'paymentAccountId'>>;
  merchantAccount?: Resolver<Maybe<ResolversTypes['StripeAccount']>, ParentType, ContextType, RequireFields<PaymentsMerchantAccountArgs, 'channelId'>>;
  orderSummary?: Resolver<Maybe<ResolversTypes['OrderSummary']>, ParentType, ContextType>;
  paymentAccount?: Resolver<ResolversTypes['PaymentAccount'], ParentType, ContextType, RequireFields<PaymentsPaymentAccountArgs, 'paymentAccountId'>>;
  paymentMethods?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<PaymentsPaymentMethodsArgs, 'paymentAccountIds'>>;
  placePay?: Resolver<Maybe<ResolversTypes['PlacePayInfo']>, ParentType, ContextType, Partial<PaymentsPlacePayArgs>>;
  stripe?: Resolver<Maybe<ResolversTypes['StripeInfo']>, ParentType, ContextType, Partial<PaymentsStripeArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentsMutation'] = ResolversParentTypes['PaymentsMutation']> = {
  addCreditCard?: Resolver<Maybe<ResolversTypes['CreditCard']>, ParentType, ContextType, RequireFields<PaymentsMutationAddCreditCardArgs, 'paymentAccountId' | 'source'>>;
  addPaymentMethod?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<PaymentsMutationAddPaymentMethodArgs, 'paymentAccountId' | 'source'>>;
  createPaymentAccount?: Resolver<ResolversTypes['PaymentAccount'], ParentType, ContextType, RequireFields<PaymentsMutationCreatePaymentAccountArgs, 'paymentAccount'>>;
  deletePaymentAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<PaymentsMutationDeletePaymentAccountArgs, 'paymentAccountId'>>;
  removeCreditCard?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<PaymentsMutationRemoveCreditCardArgs, 'id' | 'paymentAccountId'>>;
  removeMerchantAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<PaymentsMutationRemoveMerchantAccountArgs, 'channelId'>>;
  removePaymentMethod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<PaymentsMutationRemovePaymentMethodArgs, 'id' | 'paymentAccountId'>>;
  updatePaymentAccount?: Resolver<ResolversTypes['PaymentAccount'], ParentType, ContextType, RequireFields<PaymentsMutationUpdatePaymentAccountArgs, 'paymentAccount'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PermissionScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Permission'], any> {
  name: 'Permission';
}

export type PermissionDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionDetail'] = ResolversParentTypes['PermissionDetail']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Person'] = ResolversParentTypes['Person']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resident?: Resolver<Maybe<ResolversTypes['Resident']>, ParentType, ContextType, RequireFields<PersonResidentArgs, 'propertyId'>>;
  units?: Resolver<Maybe<Array<ResolversTypes['Unit']>>, ParentType, ContextType, Partial<PersonUnitsArgs>>;
  user?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PhoneNumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PhoneNumber'], any> {
  name: 'PhoneNumber';
}

export type PlacePayInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlacePayInfo'] = ResolversParentTypes['PlacePayInfo']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publicKey?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortalResolvers<ContextType = any, ParentType extends ResolversParentTypes['Portal'] = ResolversParentTypes['Portal']> = {
  activeUsersByGroupRole?: Resolver<Maybe<Array<ResolversTypes['ObjectCount']>>, ParentType, ContextType, RequireFields<PortalActiveUsersByGroupRoleArgs, 'beforeDate'>>;
  subscriberCountPerClient?: Resolver<Maybe<Array<ResolversTypes['ObjectCount']>>, ParentType, ContextType, Partial<PortalSubscriberCountPerClientArgs>>;
  subscriptionsOverTimeForChannel?: Resolver<Maybe<Array<ResolversTypes['ObjectCount']>>, ParentType, ContextType, RequireFields<PortalSubscriptionsOverTimeForChannelArgs, 'channelId' | 'endDate' | 'startDate'>>;
  usersWithPayment?: Resolver<Maybe<ResolversTypes['ObjectCount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PostalCodeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PostalCode'], any> {
  name: 'PostalCode';
}

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  markup?: Resolver<Maybe<ResolversTypes['Markup']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['ProductTax']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  chargeCode?: Resolver<Maybe<ResolversTypes['ChargeCode']>, ParentType, ContextType>;
  creditAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  markup?: Resolver<Maybe<ResolversTypes['Markup']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  originalPrice?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  productCategory?: Resolver<ResolversTypes['ProductCategory'], ParentType, ContextType>;
  receivables?: Resolver<Maybe<Array<Maybe<ResolversTypes['Receivable']>>>, ParentType, ContextType>;
  sellingPrice?: Resolver<Maybe<ResolversTypes['SellingPrice']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['ProductTax']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductCategory'] = ResolversParentTypes['ProductCategory']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productType?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductFinalPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductFinalPrice'] = ResolversParentTypes['ProductFinalPrice']> = {
  exceptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalPrice?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductItem'] = ResolversParentTypes['ProductItem']> = {
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductLineItem'] = ResolversParentTypes['ProductLineItem']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  unitPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductTaxResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductTax'] = ResolversParentTypes['ProductTax']> = {
  taxAmountType?: Resolver<ResolversTypes['TaxAmountType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  noReplyEmail?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  renderer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['Theme']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wordmark?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Property'] = ResolversParentTypes['Property']> = {
  address?: Resolver<Array<Maybe<ResolversTypes['VtsAddress']>>, ParentType, ContextType>;
  floors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Floor']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  residents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resident']>>>, ParentType, ContextType>;
  units?: Resolver<Maybe<Array<Maybe<ResolversTypes['Unit']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyClickCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyClickCompany'] = ResolversParentTypes['ProxyClickCompany']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoOriginal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyClickHostResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyClickHost'] = ResolversParentTypes['ProxyClickHost']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  companyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalCompany?: Resolver<ResolversTypes['ProxyClickCompany'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyClickMeetingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyClickMeeting'] = ResolversParentTypes['ProxyClickMeeting']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['ProxyClickCompany'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  host?: Resolver<ResolversTypes['ProxyClickHost'], ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitors?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProxyClickVisitor']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyClickVisitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyClickVisit'] = ResolversParentTypes['ProxyClickVisit']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  arrivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  company?: Resolver<ResolversTypes['ProxyClickCompany'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expectedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  leavingAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  leftAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  meeting?: Resolver<ResolversTypes['ProxyClickMeeting'], ParentType, ContextType>;
  partOfGroup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProxyClickVisitStatus']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  visitor?: Resolver<ResolversTypes['ProxyClickVisitor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyClickVisitPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyClickVisitPagination'] = ResolversParentTypes['ProxyClickVisitPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['ProxyClickVisit']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyClickVisitStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyClickVisitStatus'] = ResolversParentTypes['ProxyClickVisitStatus']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProxyClickVisitorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProxyClickVisitor'] = ResolversParentTypes['ProxyClickVisitor']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  about?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  licensePlate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicProfile'] = ResolversParentTypes['PublicProfile']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  wordmark?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicTeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicTeam'] = ResolversParentTypes['PublicTeam']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<ResolversTypes['PublicUser']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicUser'] = ResolversParentTypes['PublicUser']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastSeen?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  online?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  primaryLocation?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['PublicProfile'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['PublicUserGroupRole']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['PublicUserGroupRole']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicUserGroupRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicUserGroupRole'] = ResolversParentTypes['PublicUserGroupRole']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  groupRole?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  groupRoleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicUserPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicUserPagination'] = ResolversParentTypes['PublicUserPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['PublicUser']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QrCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QRCode'] = ResolversParentTypes['QRCode']> = {
  qrCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityWaitlistByContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityWaitlistByContent'] = ResolversParentTypes['QuantityWaitlistByContent']> = {
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['QuantityWaitlistUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityWaitlistCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityWaitlistCompany'] = ResolversParentTypes['QuantityWaitlistCompany']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityWaitlistPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityWaitlistPagination'] = ResolversParentTypes['QuantityWaitlistPagination']> = {
  items?: Resolver<Array<ResolversTypes['QuantityWaitlistByContent']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityWaitlistUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityWaitlistUser'] = ResolversParentTypes['QuantityWaitlistUser']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  accounts?: Resolver<Maybe<ResolversTypes['AccountsQueries']>, ParentType, ContextType>;
  adminSearchServiceRequests?: Resolver<ResolversTypes['ServiceRequestSearchResults'], ParentType, ContextType, RequireFields<QueryAdminSearchServiceRequestsArgs, 'channelIds'>>;
  allSentNotificationsForContentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryAllSentNotificationsForContentCountArgs, 'contentId'>>;
  apiKeys?: Resolver<Maybe<Array<ResolversTypes['ApiKey']>>, ParentType, ContextType>;
  appleIDClientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryAppleIdClientSecretArgs, 'clientId'>>;
  audience?: Resolver<ResolversTypes['Audience'], ParentType, ContextType, RequireFields<QueryAudienceArgs, 'audienceId' | 'channelId'>>;
  audiences?: Resolver<ResolversTypes['AudiencePagination'], ParentType, ContextType, RequireFields<QueryAudiencesArgs, 'channelId'>>;
  availableIntegrations?: Resolver<Maybe<Array<ResolversTypes['Integration']>>, ParentType, ContextType, RequireFields<QueryAvailableIntegrationsArgs, 'channelId'>>;
  biltSsoLink?: Resolver<Maybe<ResolversTypes['BiltSso']>, ParentType, ContextType, RequireFields<QueryBiltSsoLinkArgs, 'channelId'>>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType, Partial<QueryChannelArgs>>;
  channelContract?: Resolver<ResolversTypes['ChannelContract'], ParentType, ContextType, RequireFields<QueryChannelContractArgs, 'id'>>;
  channelContracts?: Resolver<ResolversTypes['ChannelContractPagination'], ParentType, ContextType, Partial<QueryChannelContractsArgs>>;
  channelGroupRolesUserCount?: Resolver<Maybe<Array<ResolversTypes['ObjectCount']>>, ParentType, ContextType, RequireFields<QueryChannelGroupRolesUserCountArgs, '_id'>>;
  channelHardwares?: Resolver<ResolversTypes['HardwarePagination'], ParentType, ContextType, RequireFields<QueryChannelHardwaresArgs, 'channelId' | 'pagination'>>;
  channelIntegration?: Resolver<ResolversTypes['ChannelIntegration'], ParentType, ContextType, RequireFields<QueryChannelIntegrationArgs, '_id'>>;
  channelIntegrationFloorMapGenerationStatus?: Resolver<Maybe<ResolversTypes['FloorMapGenerationStatus']>, ParentType, ContextType, RequireFields<QueryChannelIntegrationFloorMapGenerationStatusArgs, 'channelIntegrationId'>>;
  channelInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChannelInvite']>>>, ParentType, ContextType, RequireFields<QueryChannelInvitesArgs, 'channelId'>>;
  channelModule?: Resolver<ResolversTypes['ChannelModule'], ParentType, ContextType, RequireFields<QueryChannelModuleArgs, '_id'>>;
  channelModuleByChannelId?: Resolver<ResolversTypes['ChannelModule'], ParentType, ContextType, RequireFields<QueryChannelModuleByChannelIdArgs, 'channelId'>>;
  channelPublicUsers?: Resolver<Maybe<ResolversTypes['PublicUserPagination']>, ParentType, ContextType, RequireFields<QueryChannelPublicUsersArgs, 'channelId'>>;
  channelSections?: Resolver<ResolversTypes['PaginatedSections'], ParentType, ContextType, Partial<QueryChannelSectionsArgs>>;
  channelUserSearch?: Resolver<ResolversTypes['UserPagination'], ParentType, ContextType, RequireFields<QueryChannelUserSearchArgs, '_id' | 'search'>>;
  channelUsersByGroupRole?: Resolver<ResolversTypes['UserGroupRolePagination'], ParentType, ContextType, RequireFields<QueryChannelUsersByGroupRoleArgs, 'groupRoleId'>>;
  channelUsersByGroupRoles?: Resolver<ResolversTypes['UserGroupRolePagination'], ParentType, ContextType, RequireFields<QueryChannelUsersByGroupRolesArgs, 'groupRoleIds'>>;
  channels?: Resolver<ResolversTypes['ChannelPagination'], ParentType, ContextType, RequireFields<QueryChannelsArgs, 'pagination'>>;
  channelsByPermission?: Resolver<ResolversTypes['ChannelPagination'], ParentType, ContextType, RequireFields<QueryChannelsByPermissionArgs, 'pagination'>>;
  channelsByRelationship?: Resolver<ResolversTypes['ChannelRelationshipPagination'], ParentType, ContextType, Partial<QueryChannelsByRelationshipArgs>>;
  channelsForBlocksAndTargeting?: Resolver<ResolversTypes['ChannelPagination'], ParentType, ContextType, RequireFields<QueryChannelsForBlocksAndTargetingArgs, 'pagination'>>;
  checkIfChannelExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryCheckIfChannelExistsArgs, 'id'>>;
  combinedSectionsContent?: Resolver<Maybe<Array<Maybe<ResolversTypes['SectionContent']>>>, ParentType, ContextType, RequireFields<QueryCombinedSectionsContentArgs, 'sectionIds'>>;
  companiesByChannelId?: Resolver<Maybe<Array<Maybe<ResolversTypes['Channel']>>>, ParentType, ContextType, RequireFields<QueryCompaniesByChannelIdArgs, 'channelId'>>;
  completeSignupData?: Resolver<ResolversTypes['CompleteSignupData'], ParentType, ContextType, RequireFields<QueryCompleteSignupDataArgs, 'guid' | 'verify'>>;
  confirmEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryConfirmEmailArgs, 'email'>>;
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType, RequireFields<QueryContentArgs, '_id'>>;
  contentBlocks?: Resolver<Maybe<Array<ResolversTypes['Block']>>, ParentType, ContextType>;
  contentByAudience?: Resolver<ResolversTypes['ContentPagination'], ParentType, ContextType, RequireFields<QueryContentByAudienceArgs, 'audienceId'>>;
  contentCards?: Resolver<Maybe<Array<ResolversTypes['Block']>>, ParentType, ContextType>;
  contentFeatureEntriesRemaining?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryContentFeatureEntriesRemainingArgs, '_id'>>;
  contentFeatureInventoryAvailable?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryContentFeatureInventoryAvailableArgs, '_id'>>;
  contentFeaturePaymentQuote?: Resolver<ResolversTypes['PaymentFeatureQuote'], ParentType, ContextType, RequireFields<QueryContentFeaturePaymentQuoteArgs, '_id' | 'paymentType' | 'quantity'>>;
  contentFeaturePaymentSummary?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryContentFeaturePaymentSummaryArgs, '_id'>>;
  contentFeatureQuantityAllotments?: Resolver<ResolversTypes['ContentQuantityAllotments'], ParentType, ContextType, RequireFields<QueryContentFeatureQuantityAllotmentsArgs, 'contentId'>>;
  contentFeatureQuantityClaimed?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryContentFeatureQuantityClaimedArgs, '_id'>>;
  contentFeatureReservableAvailability?: Resolver<Array<ResolversTypes['ReservableAvailability']>, ParentType, ContextType, RequireFields<QueryContentFeatureReservableAvailabilityArgs, '_id' | 'end' | 'start'>>;
  contentFeatureStatusesSummary?: Resolver<Array<ResolversTypes['ContentFeatureStatusSummary']>, ParentType, ContextType, RequireFields<QueryContentFeatureStatusesSummaryArgs, '_id' | 'end' | 'start'>>;
  contentForRelatedSpaces?: Resolver<ResolversTypes['ContentPagination'], ParentType, ContextType, RequireFields<QueryContentForRelatedSpacesArgs, 'channelId' | 'contentId' | 'pagination' | 'search'>>;
  contentOnChannel?: Resolver<ResolversTypes['ContentPagination'], ParentType, ContextType, RequireFields<QueryContentOnChannelArgs, 'channelId'>>;
  contentTemplate?: Resolver<ResolversTypes['ContentTemplate'], ParentType, ContextType, RequireFields<QueryContentTemplateArgs, '_id'>>;
  contentTemplatesOnChannel?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryContentTemplatesOnChannelArgs, 'channelId'>>;
  contentTemplatesOnLibrary?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryContentTemplatesOnLibraryArgs, 'libraryId'>>;
  contentTemplatesOnUser?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryContentTemplatesOnUserArgs, 'userId'>>;
  contentWaitlistEnrollments?: Resolver<Array<ResolversTypes['ContentWaitlistEnrollment']>, ParentType, ContextType, RequireFields<QueryContentWaitlistEnrollmentsArgs, 'contentId' | 'userId'>>;
  contentsForIntegration?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType, RequireFields<QueryContentsForIntegrationArgs, '_id'>>;
  convertId?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryConvertIdArgs, 'id'>>;
  credential?: Resolver<ResolversTypes['Credential'], ParentType, ContextType, RequireFields<QueryCredentialArgs, 'visitorId'>>;
  creditCards?: Resolver<Maybe<Array<ResolversTypes['CreditCard']>>, ParentType, ContextType, Partial<QueryCreditCardsArgs>>;
  datasetRecord?: Resolver<ResolversTypes['DatasetRecord'], ParentType, ContextType, RequireFields<QueryDatasetRecordArgs, 'id'>>;
  datasetSchema?: Resolver<Maybe<ResolversTypes['DatasetSchema']>, ParentType, ContextType, RequireFields<QueryDatasetSchemaArgs, 'id'>>;
  datasetSchemas?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryDatasetSchemasArgs, 'channelId'>>;
  discoverUserIntegrations?: Resolver<Maybe<Array<ResolversTypes['UserLogin']>>, ParentType, ContextType, RequireFields<QueryDiscoverUserIntegrationsArgs, 'channelIntegrationId'>>;
  doUsersExistByEmail?: Resolver<Array<Maybe<ResolversTypes['UserEmail']>>, ParentType, ContextType, RequireFields<QueryDoUsersExistByEmailArgs, 'emails'>>;
  draftContent?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType, RequireFields<QueryDraftContentArgs, '_id'>>;
  draftContentHistory?: Resolver<ResolversTypes['DraftContentHistoryPagination'], ParentType, ContextType, RequireFields<QueryDraftContentHistoryArgs, '_id'>>;
  draftContentOnChannel?: Resolver<ResolversTypes['DraftContentPagination'], ParentType, ContextType, RequireFields<QueryDraftContentOnChannelArgs, 'channelId'>>;
  draftContentsForIntegration?: Resolver<Array<ResolversTypes['DraftContent']>, ParentType, ContextType, RequireFields<QueryDraftContentsForIntegrationArgs, '_id'>>;
  equipmentSettingsOnChannel?: Resolver<Maybe<ResolversTypes['ChannelEquipmentSettings']>, ParentType, ContextType, RequireFields<QueryEquipmentSettingsOnChannelArgs, 'channelId'>>;
  escalationRule?: Resolver<ResolversTypes['EscalationRule'], ParentType, ContextType, RequireFields<QueryEscalationRuleArgs, 'id'>>;
  essensys?: Resolver<Maybe<ResolversTypes['Essensys']>, ParentType, ContextType>;
  estimateContentAudienceSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<QueryEstimateContentAudienceSizeArgs>>;
  events?: Resolver<Maybe<Array<ResolversTypes['EventDetail']>>, ParentType, ContextType>;
  existingUserLogins?: Resolver<Array<Maybe<ResolversTypes['UserLoginMethod']>>, ParentType, ContextType, RequireFields<QueryExistingUserLoginsArgs, 'email'>>;
  exportCSVTasks?: Resolver<ResolversTypes['ExportCSVTasksReponseDTO'], ParentType, ContextType, Partial<QueryExportCsvTasksArgs>>;
  findSchedules?: Resolver<ResolversTypes['FindSchedulesReponseDTO'], ParentType, ContextType, RequireFields<QueryFindSchedulesArgs, 'refIds'>>;
  flatfileToken?: Resolver<ResolversTypes['FlatfileToken'], ParentType, ContextType, Partial<QueryFlatfileTokenArgs>>;
  floor?: Resolver<Maybe<ResolversTypes['Floor']>, ParentType, ContextType, RequireFields<QueryFloorArgs, 'floorId' | 'propertyId'>>;
  getAccessLocations?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessLocation']>>>, ParentType, ContextType>;
  getAccessPointById?: Resolver<ResolversTypes['AccessPoint'], ParentType, ContextType, RequireFields<QueryGetAccessPointByIdArgs, 'id'>>;
  getAccessPointList?: Resolver<Array<ResolversTypes['AccessPoint']>, ParentType, ContextType, RequireFields<QueryGetAccessPointListArgs, 'buildingId'>>;
  getAllEquipment?: Resolver<Maybe<Array<ResolversTypes['Equipment']>>, ParentType, ContextType, RequireFields<QueryGetAllEquipmentArgs, 'channelId'>>;
  getAllEscalationRules?: Resolver<Maybe<Array<ResolversTypes['EscalationRule']>>, ParentType, ContextType, RequireFields<QueryGetAllEscalationRulesArgs, 'channelId'>>;
  getAttachment?: Resolver<Maybe<ResolversTypes['Attachment']>, ParentType, ContextType, RequireFields<QueryGetAttachmentArgs, '_id'>>;
  getAttachmentsByEntity?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attachment']>>>, ParentType, ContextType, RequireFields<QueryGetAttachmentsByEntityArgs, '_id'>>;
  getAuth0HRDConnectionsByDomainAlias?: Resolver<ResolversTypes['Auth0DomainHomeRealmDiscovery'], ParentType, ContextType, RequireFields<QueryGetAuth0HrdConnectionsByDomainAliasArgs, 'email'>>;
  getBuildingChannelIntegration?: Resolver<Maybe<ResolversTypes['ChannelAccessIntegration']>, ParentType, ContextType, RequireFields<QueryGetBuildingChannelIntegrationArgs, 'channelId'>>;
  getBuildingTenantChannels?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuildingTenantChannel']>>>, ParentType, ContextType, RequireFields<QueryGetBuildingTenantChannelsArgs, 'channelId'>>;
  getChannelAccessControlGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessControlGroup']>>>, ParentType, ContextType, RequireFields<QueryGetChannelAccessControlGroupsArgs, 'channelId'>>;
  getChannelUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['ActivateUser']>>>, ParentType, ContextType, RequireFields<QueryGetChannelUsersArgs, 'channelId'>>;
  getChannelsForAccessManager?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessControlChannelType']>>>, ParentType, ContextType, RequireFields<QueryGetChannelsForAccessManagerArgs, 'channelId'>>;
  getChannelsForAccessUser?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccessUserChannel']>>>, ParentType, ContextType, RequireFields<QueryGetChannelsForAccessUserArgs, 'userId'>>;
  getConfig?: Resolver<Maybe<ResolversTypes['GetConfigResponse']>, ParentType, ContextType, Partial<QueryGetConfigArgs>>;
  getConfigMetadata?: Resolver<Maybe<ResolversTypes['Configuration']>, ParentType, ContextType, RequireFields<QueryGetConfigMetadataArgs, 'channelId' | 'provider'>>;
  getCredentialGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['CredentialGroups']>>>, ParentType, ContextType, RequireFields<QueryGetCredentialGroupsArgs, 'vtsOrganizationIds' | 'vtsUserId'>>;
  getEquipment?: Resolver<Maybe<ResolversTypes['Equipment']>, ParentType, ContextType, Partial<QueryGetEquipmentArgs>>;
  getEquipmentForExport?: Resolver<Maybe<ResolversTypes['EquipmentForExport']>, ParentType, ContextType, RequireFields<QueryGetEquipmentForExportArgs, 'channelId'>>;
  getFinalPriceForProducts?: Resolver<Maybe<ResolversTypes['GetFinalPriceForProductsResponse']>, ParentType, ContextType, RequireFields<QueryGetFinalPriceForProductsArgs, 'getFinalPriceForProductsRequest'>>;
  getIntegrationProcesses?: Resolver<ResolversTypes['GetIntegrationProcessesResponse'], ParentType, ContextType, Partial<QueryGetIntegrationProcessesArgs>>;
  getMeterReadingValue?: Resolver<Maybe<ResolversTypes['MeterReadingValue']>, ParentType, ContextType, RequireFields<QueryGetMeterReadingValueArgs, 'meterReadingValueId'>>;
  getMeterReadingValueForExport?: Resolver<Maybe<ResolversTypes['MeterReadingValueForExport']>, ParentType, ContextType, RequireFields<QueryGetMeterReadingValueForExportArgs, 'channelId' | 'meterReadingId'>>;
  getMeterReadingValuesByMeterReadingIds?: Resolver<Maybe<ResolversTypes['MeterReadingValueSearchResults']>, ParentType, ContextType, RequireFields<QueryGetMeterReadingValuesByMeterReadingIdsArgs, 'meterReadingIds'>>;
  getProduct?: Resolver<Maybe<ResolversTypes['GetProductResponse']>, ParentType, ContextType, Partial<QueryGetProductArgs>>;
  getProductException?: Resolver<Maybe<ResolversTypes['GetProductExceptionResponse']>, ParentType, ContextType, RequireFields<QueryGetProductExceptionArgs, 'getProductExceptionRequest'>>;
  getRecentJobs?: Resolver<Maybe<ResolversTypes['JobsResponse']>, ParentType, ContextType, Partial<QueryGetRecentJobsArgs>>;
  getRelayOutputById?: Resolver<ResolversTypes['RelayOutput'], ParentType, ContextType, RequireFields<QueryGetRelayOutputByIdArgs, 'id'>>;
  getRelayOutputList?: Resolver<ResolversTypes['GetRelayOutputList'], ParentType, ContextType, RequireFields<QueryGetRelayOutputListArgs, 'buildingId'>>;
  getServiceRequestCategories?: Resolver<ResolversTypes['ServiceRequestCategoriesList'], ParentType, ContextType, RequireFields<QueryGetServiceRequestCategoriesArgs, 'channelIds'>>;
  getServiceRequestFeaturesByChannelId?: Resolver<ResolversTypes['ServiceRequestFeatures'], ParentType, ContextType, RequireFields<QueryGetServiceRequestFeaturesByChannelIdArgs, 'channelId'>>;
  getServiceRequestIssues?: Resolver<ResolversTypes['ServiceRequestIssuesList'], ParentType, ContextType, RequireFields<QueryGetServiceRequestIssuesArgs, 'channelIds'>>;
  getServiceRequestLocations?: Resolver<ResolversTypes['ServiceRequestLocationsList'], ParentType, ContextType, RequireFields<QueryGetServiceRequestLocationsArgs, 'channelIds'>>;
  getTenantBuildingIntegrationChannel?: Resolver<Maybe<ResolversTypes['TenantBuildingChannel']>, ParentType, ContextType, RequireFields<QueryGetTenantBuildingIntegrationChannelArgs, 'channelId'>>;
  getUserAccessDetails?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserAccessDetails']>>>, ParentType, ContextType, RequireFields<QueryGetUserAccessDetailsArgs, 'channelId'>>;
  getUserChannelsWithMobileAccess?: Resolver<Maybe<Array<Maybe<ResolversTypes['MobileAccessChannel']>>>, ParentType, ContextType, RequireFields<QueryGetUserChannelsWithMobileAccessArgs, 'userId'>>;
  getVMContent?: Resolver<ResolversTypes['ContentPagination'], ParentType, ContextType, RequireFields<QueryGetVmContentArgs, 'channelId'>>;
  getWorkflow?: Resolver<Maybe<ResolversTypes['Workflow']>, ParentType, ContextType, RequireFields<QueryGetWorkflowArgs, 'workflowId'>>;
  getWorkflowsByChannel?: Resolver<Maybe<Array<Maybe<ResolversTypes['Workflow']>>>, ParentType, ContextType, RequireFields<QueryGetWorkflowsByChannelArgs, 'channelId'>>;
  getWorkflowsByChannelAndType?: Resolver<Maybe<Array<Maybe<ResolversTypes['Workflow']>>>, ParentType, ContextType, RequireFields<QueryGetWorkflowsByChannelAndTypeArgs, 'channelId' | 'type'>>;
  groupRole?: Resolver<ResolversTypes['GroupRole'], ParentType, ContextType, RequireFields<QueryGroupRoleArgs, '_id'>>;
  hardware?: Resolver<ResolversTypes['Hardware'], ParentType, ContextType, RequireFields<QueryHardwareArgs, 'id'>>;
  hardwareAccessLogs?: Resolver<ResolversTypes['HardwareAccessLogPagination'], ParentType, ContextType, RequireFields<QueryHardwareAccessLogsArgs, 'channelId' | 'pagination'>>;
  hardwareMe?: Resolver<ResolversTypes['Hardware'], ParentType, ContextType>;
  hostsByUserTenantRelationship?: Resolver<Array<Maybe<ResolversTypes['Host']>>, ParentType, ContextType, RequireFields<QueryHostsByUserTenantRelationshipArgs, 'channelId'>>;
  integration?: Resolver<ResolversTypes['Integration'], ParentType, ContextType, RequireFields<QueryIntegrationArgs, '_id'>>;
  integrations?: Resolver<Maybe<Array<ResolversTypes['Integration']>>, ParentType, ContextType, Partial<QueryIntegrationsArgs>>;
  interaction?: Resolver<ResolversTypes['UserContentInteraction'], ParentType, ContextType, RequireFields<QueryInteractionArgs, '_id'>>;
  interactionHistory?: Resolver<ResolversTypes['UserContentInteractionHistoryPagination'], ParentType, ContextType, RequireFields<QueryInteractionHistoryArgs, '_id'>>;
  interactionsOnChannel?: Resolver<ResolversTypes['UserContentInteractionPagination'], ParentType, ContextType, RequireFields<QueryInteractionsOnChannelArgs, 'channelId'>>;
  interactionsOnContent?: Resolver<ResolversTypes['UserContentInteractionPagination'], ParentType, ContextType, RequireFields<QueryInteractionsOnContentArgs, 'contentId'>>;
  invite?: Resolver<ResolversTypes['ChannelInvite'], ParentType, ContextType, RequireFields<QueryInviteArgs, '_id'>>;
  invitesOnChannel?: Resolver<Maybe<ResolversTypes['ChannelInvitePagination']>, ParentType, ContextType, RequireFields<QueryInvitesOnChannelArgs, 'channelId'>>;
  libraries?: Resolver<ResolversTypes['LibraryPagination'], ParentType, ContextType, Partial<QueryLibrariesArgs>>;
  librariesOnChannel?: Resolver<ResolversTypes['LibraryPagination'], ParentType, ContextType, RequireFields<QueryLibrariesOnChannelArgs, 'channelId'>>;
  library?: Resolver<Maybe<ResolversTypes['Library']>, ParentType, ContextType, RequireFields<QueryLibraryArgs, '_id'>>;
  linkedEntities?: Resolver<ResolversTypes['LinkedEntityPagination'], ParentType, ContextType, RequireFields<QueryLinkedEntitiesArgs, 'search'>>;
  listProductCategories?: Resolver<Maybe<ResolversTypes['ListProductCategoriesResponse']>, ParentType, ContextType, Partial<QueryListProductCategoriesArgs>>;
  listProductExceptions?: Resolver<Maybe<ResolversTypes['ListProductExceptionsResponse']>, ParentType, ContextType, Partial<QueryListProductExceptionsArgs>>;
  listProducts?: Resolver<Maybe<ResolversTypes['ListProductsResponse']>, ParentType, ContextType, RequireFields<QueryListProductsArgs, 'filter' | 'pagination'>>;
  loginEmailExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryLoginEmailExistsArgs, 'email'>>;
  me?: Resolver<Maybe<ResolversTypes['Me']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType, RequireFields<QueryMediaArgs, '_id'>>;
  mediaOnChannel?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryMediaOnChannelArgs, 'channelId'>>;
  mediaOnLibrary?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryMediaOnLibraryArgs, 'libraryId'>>;
  mediaOnUser?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryMediaOnUserArgs, 'userId'>>;
  memberGroupRoles?: Resolver<Maybe<Array<ResolversTypes['UserGroupRole']>>, ParentType, ContextType, RequireFields<QueryMemberGroupRolesArgs, '_id'>>;
  metatag?: Resolver<Maybe<ResolversTypes['Metatag']>, ParentType, ContextType, RequireFields<QueryMetatagArgs, 'id'>>;
  metatagUsage?: Resolver<Array<ResolversTypes['MetatagUse']>, ParentType, ContextType, RequireFields<QueryMetatagUsageArgs, 'id'>>;
  metatagsOnChannel?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryMetatagsOnChannelArgs, 'channelId'>>;
  mobilePermissions?: Resolver<Maybe<Array<ResolversTypes['MobilePermission']>>, ParentType, ContextType, RequireFields<QueryMobilePermissionsArgs, 'channelId'>>;
  objectDatasetRecord?: Resolver<ResolversTypes['ObjectDatasetRecord'], ParentType, ContextType, RequireFields<QueryObjectDatasetRecordArgs, 'externalId'>>;
  oidcServiceProviderDefinitionList?: Resolver<Array<Maybe<ResolversTypes['OidcServiceProviderDefinition']>>, ParentType, ContextType>;
  openpathSetupMobileToken?: Resolver<Maybe<ResolversTypes['OpenpathSetupMobileToken']>, ParentType, ContextType, RequireFields<QueryOpenpathSetupMobileTokenArgs, 'channelIntegrationId'>>;
  parkWhizVehicles?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParkWhizVehicle']>>>, ParentType, ContextType>;
  parkwhizBookingPreview?: Resolver<Maybe<ResolversTypes['ParkWhizBookPreview']>, ParentType, ContextType, Partial<QueryParkwhizBookingPreviewArgs>>;
  parkwhizLocations?: Resolver<Maybe<Array<ResolversTypes['ParkWhizByGeoLocation']>>, ParentType, ContextType, Partial<QueryParkwhizLocationsArgs>>;
  parkwhizParkingQrCode?: Resolver<Maybe<ResolversTypes['QRCode']>, ParentType, ContextType, RequireFields<QueryParkwhizParkingQrCodeArgs, 'id'>>;
  pastVisits?: Resolver<ResolversTypes['PastVisitorPass'], ParentType, ContextType, RequireFields<QueryPastVisitsArgs, 'channelId'>>;
  pastVisitsByHostUserId?: Resolver<ResolversTypes['PastVisitorPass'], ParentType, ContextType, Partial<QueryPastVisitsByHostUserIdArgs>>;
  payments?: Resolver<Maybe<ResolversTypes['Payments']>, ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<ResolversTypes['PermissionDetail']>>, ParentType, ContextType>;
  personForUser?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<QueryPersonForUserArgs, 'userId'>>;
  pmSchedule?: Resolver<ResolversTypes['ClientPMSchedule'], ParentType, ContextType, RequireFields<QueryPmScheduleArgs, 'scheduleId'>>;
  pmSchedulesForExport?: Resolver<ResolversTypes['PMSchedulesForExportResponseDTO'], ParentType, ContextType, RequireFields<QueryPmSchedulesForExportArgs, 'refId'>>;
  portal?: Resolver<ResolversTypes['Portal'], ParentType, ContextType>;
  property?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType, RequireFields<QueryPropertyArgs, 'propertyId'>>;
  proxyclickCompanies?: Resolver<Maybe<Array<ResolversTypes['ProxyClickCompany']>>, ParentType, ContextType>;
  proxyclickCompanyUsers?: Resolver<Maybe<Array<ResolversTypes['ProxyClickHost']>>, ParentType, ContextType, RequireFields<QueryProxyclickCompanyUsersArgs, 'companyId'>>;
  proxyclickListVisits?: Resolver<ResolversTypes['ProxyClickVisitPagination'], ParentType, ContextType, RequireFields<QueryProxyclickListVisitsArgs, 'companyId'>>;
  quantityWaitlistForAdmin?: Resolver<ResolversTypes['QuantityWaitlistPagination'], ParentType, ContextType, RequireFields<QueryQuantityWaitlistForAdminArgs, 'channelId' | 'contentId'>>;
  recurrence?: Resolver<Maybe<ResolversTypes['VisitorPassRecurrence']>, ParentType, ContextType, RequireFields<QueryRecurrenceArgs, 'id'>>;
  reformedChannel?: Resolver<Maybe<ResolversTypes['ReformedChannel']>, ParentType, ContextType, RequireFields<QueryReformedChannelArgs, 'channelId'>>;
  requirements?: Resolver<Maybe<Array<ResolversTypes['RequirementDetail']>>, ParentType, ContextType>;
  reservableBookings?: Resolver<ResolversTypes['ReservableBookingPagination'], ParentType, ContextType, RequireFields<QueryReservableBookingsArgs, 'search'>>;
  reservableWaitlistEnrollments?: Resolver<Array<ResolversTypes['ReservableWaitlistEnrollment']>, ParentType, ContextType, RequireFields<QueryReservableWaitlistEnrollmentsArgs, 'contentId' | 'userId'>>;
  reservableWaitlistForAdmin?: Resolver<ResolversTypes['ReservableWaitlistPagination'], ParentType, ContextType, RequireFields<QueryReservableWaitlistForAdminArgs, 'channelId' | 'contentId'>>;
  resident?: Resolver<Maybe<ResolversTypes['Resident']>, ParentType, ContextType, RequireFields<QueryResidentArgs, 'personId' | 'propertyId'>>;
  residentByUserId?: Resolver<Maybe<ResolversTypes['Resident']>, ParentType, ContextType, RequireFields<QueryResidentByUserIdArgs, 'propertyId' | 'userId'>>;
  residents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Resident']>>>, ParentType, ContextType, RequireFields<QueryResidentsArgs, 'propertyId'>>;
  samlData?: Resolver<Maybe<ResolversTypes['SAMLData']>, ParentType, ContextType, RequireFields<QuerySamlDataArgs, 'integrationId'>>;
  samlServiceProviderDefinitions?: Resolver<Maybe<Array<Maybe<ResolversTypes['SAMLServiceProviderDefinition']>>>, ParentType, ContextType, Partial<QuerySamlServiceProviderDefinitionsArgs>>;
  samlServiceProviderDefinitionsByIdp?: Resolver<Maybe<Array<Maybe<ResolversTypes['SAMLServiceProviderDefinition']>>>, ParentType, ContextType, RequireFields<QuerySamlServiceProviderDefinitionsByIdpArgs, 'idp'>>;
  scheduledVisits?: Resolver<ResolversTypes['ScheduledVisitorPass'], ParentType, ContextType, RequireFields<QueryScheduledVisitsArgs, 'channelId'>>;
  scheduledVisitsByHostUserId?: Resolver<ResolversTypes['ScheduledVisitorPass'], ParentType, ContextType, Partial<QueryScheduledVisitsByHostUserIdArgs>>;
  searchEquipment?: Resolver<ResolversTypes['EquipmentSearchResults'], ParentType, ContextType, Partial<QuerySearchEquipmentArgs>>;
  searchMeterReadingValue?: Resolver<ResolversTypes['MeterReadingValueSearchResults'], ParentType, ContextType, Partial<QuerySearchMeterReadingValueArgs>>;
  searchServiceRequests?: Resolver<ResolversTypes['ServiceRequestSearchResults'], ParentType, ContextType, RequireFields<QuerySearchServiceRequestsArgs, 'channelId'>>;
  searchTasks?: Resolver<ResolversTypes['SearchTasksReponseDTO'], ParentType, ContextType, RequireFields<QuerySearchTasksArgs, 'groupIds'>>;
  section?: Resolver<ResolversTypes['Section'], ParentType, ContextType, RequireFields<QuerySectionArgs, '_id'>>;
  sectionDrafts?: Resolver<ResolversTypes['DraftContentPagination'], ParentType, ContextType, RequireFields<QuerySectionDraftsArgs, '_id'>>;
  sectionFeatureReservableAvailability?: Resolver<Array<ResolversTypes['ContentDateRanges']>, ParentType, ContextType, RequireFields<QuerySectionFeatureReservableAvailabilityArgs, 'end' | 'sectionId' | 'start'>>;
  serviceRequest?: Resolver<ResolversTypes['ServiceRequest'], ParentType, ContextType, RequireFields<QueryServiceRequestArgs, 'serviceRequestId'>>;
  serviceRequestAuditLogs?: Resolver<Maybe<Array<ResolversTypes['ServiceRequestAuditLog']>>, ParentType, ContextType, RequireFields<QueryServiceRequestAuditLogsArgs, 'serviceRequestId'>>;
  serviceRequestByUCI?: Resolver<ResolversTypes['ServiceRequest'], ParentType, ContextType, RequireFields<QueryServiceRequestByUciArgs, 'contentInteractionId'>>;
  serviceRequestContentId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType, Partial<QueryServiceRequestContentIdArgs>>;
  serviceRequestFilterAttributes?: Resolver<ResolversTypes['ServiceRequestsFilterFieldsDTO'], ParentType, ContextType, RequireFields<QueryServiceRequestFilterAttributesArgs, 'channelIds'>>;
  serviceRequests?: Resolver<ResolversTypes['ServiceRequestsList'], ParentType, ContextType, RequireFields<QueryServiceRequestsArgs, 'contentId'>>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  shopifyCustomer?: Resolver<Maybe<ResolversTypes['ShopifyCustomer']>, ParentType, ContextType, RequireFields<QueryShopifyCustomerArgs, 'channelIntegrationId' | 'email'>>;
  specialInvite?: Resolver<Maybe<ResolversTypes['SpecialInvite']>, ParentType, ContextType, RequireFields<QuerySpecialInviteArgs, 'guid' | 'verify'>>;
  tag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType, RequireFields<QueryTagArgs, 'id'>>;
  tags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType, RequireFields<QueryTagsArgs, 'channelId'>>;
  targetingRule?: Resolver<ResolversTypes['TargetingRule'], ParentType, ContextType, Partial<QueryTargetingRuleArgs>>;
  targetingRulesForAudience?: Resolver<ResolversTypes['TargetingRulesForAudience'], ParentType, ContextType, RequireFields<QueryTargetingRulesForAudienceArgs, 'audienceId' | 'fromChannel'>>;
  task?: Resolver<ResolversTypes['ClientTask'], ParentType, ContextType, RequireFields<QueryTaskArgs, 'id'>>;
  taskAuditLogs?: Resolver<Maybe<Array<ResolversTypes['TaskAuditLog']>>, ParentType, ContextType, RequireFields<QueryTaskAuditLogsArgs, 'taskId'>>;
  team?: Resolver<ResolversTypes['PublicTeam'], ParentType, ContextType, RequireFields<QueryTeamArgs, '_id'>>;
  tenant?: Resolver<Maybe<ResolversTypes['Tenant']>, ParentType, ContextType, RequireFields<QueryTenantArgs, 'propertyId' | 'tenantId'>>;
  tenantChannelsByUser?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tenant']>>>, ParentType, ContextType, RequireFields<QueryTenantChannelsByUserArgs, 'channelId' | 'userId'>>;
  theme?: Resolver<ResolversTypes['Theme'], ParentType, ContextType, RequireFields<QueryThemeArgs, 'id'>>;
  unit?: Resolver<Maybe<ResolversTypes['Unit']>, ParentType, ContextType, RequireFields<QueryUnitArgs, 'propertyId' | 'unitId'>>;
  unlinkedDatasetSchemas?: Resolver<Maybe<Array<ResolversTypes['LibraryItem']>>, ParentType, ContextType, RequireFields<QueryUnlinkedDatasetSchemasArgs, 'channelId'>>;
  user?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType, RequireFields<QueryUserArgs, '_id'>>;
  userAccessDetails?: Resolver<Maybe<ResolversTypes['UserAccessDetails']>, ParentType, ContextType, RequireFields<QueryUserAccessDetailsArgs, 'userId'>>;
  userAdmin?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryUserAdminArgs, '_id'>>;
  userBookingsCountForContent?: Resolver<Array<ResolversTypes['UserBooking']>, ParentType, ContextType, RequireFields<QueryUserBookingsCountForContentArgs, 'contentId' | 'selectedDate' | 'userId'>>;
  userBuildingAccess?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserBuildingAccess']>>>, ParentType, ContextType, RequireFields<QueryUserBuildingAccessArgs, 'userId'>>;
  userChannelSettings?: Resolver<Maybe<ResolversTypes['UserChannelSettings']>, ParentType, ContextType, RequireFields<QueryUserChannelSettingsArgs, 'channelId' | 'userId'>>;
  userChannelSettingsForMultipleChannels?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserChannelSettings']>>>, ParentType, ContextType, RequireFields<QueryUserChannelSettingsForMultipleChannelsArgs, 'userId'>>;
  userIntegration?: Resolver<Maybe<ResolversTypes['UserIntegration']>, ParentType, ContextType, RequireFields<QueryUserIntegrationArgs, 'channelIntegrationId'>>;
  users?: Resolver<ResolversTypes['UserPagination'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'pagination' | 'search'>>;
  usersByChannelId?: Resolver<Maybe<ResolversTypes['UserPagination']>, ParentType, ContextType, RequireFields<QueryUsersByChannelIdArgs, 'channelId'>>;
  verifyConfig?: Resolver<Maybe<ResolversTypes['Configuration']>, ParentType, ContextType, RequireFields<QueryVerifyConfigArgs, 'channelId'>>;
  verifySecureId?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryVerifySecureIdArgs, 'secureId' | 'userId'>>;
  visitor?: Resolver<ResolversTypes['Visitor'], ParentType, ContextType, RequireFields<QueryVisitorArgs, 'id'>>;
  visitorByEmailChannelId?: Resolver<ResolversTypes['Visitor'], ParentType, ContextType, RequireFields<QueryVisitorByEmailChannelIdArgs, 'channelId' | 'email'>>;
  visitorEmailTemplate?: Resolver<Maybe<ResolversTypes['VisitorEmailTemplate']>, ParentType, ContextType, RequireFields<QueryVisitorEmailTemplateArgs, 'channelId' | 'templateType'>>;
  visitorOrganizationSettings?: Resolver<Maybe<ResolversTypes['VisitorOrganizationSettings']>, ParentType, ContextType, RequireFields<QueryVisitorOrganizationSettingsArgs, 'channelId'>>;
  visitorPass?: Resolver<Maybe<ResolversTypes['VisitorPass']>, ParentType, ContextType, RequireFields<QueryVisitorPassArgs, 'channelId' | 'id'>>;
  visitorPassByHost?: Resolver<Maybe<ResolversTypes['VisitorPass']>, ParentType, ContextType, RequireFields<QueryVisitorPassByHostArgs, 'id'>>;
  visitorPassesBySubmissionId?: Resolver<ResolversTypes['VisitorPassAggregatesBySubmissionId'], ParentType, ContextType, RequireFields<QueryVisitorPassesBySubmissionIdArgs, 'submissionId'>>;
  webhook?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType, RequireFields<QueryWebhookArgs, '_id'>>;
  webhooksOnChannel?: Resolver<Maybe<ResolversTypes['LibraryItemPagination']>, ParentType, ContextType, RequireFields<QueryWebhooksOnChannelArgs, 'channelId'>>;
  whitelabel?: Resolver<Maybe<ResolversTypes['Whitelabel']>, ParentType, ContextType, Partial<QueryWhitelabelArgs>>;
  whitelabels?: Resolver<Maybe<Array<ResolversTypes['Whitelabel']>>, ParentType, ContextType, Partial<QueryWhitelabelsArgs>>;
  workOrderDetail?: Resolver<Maybe<ResolversTypes['AngusWorkOrder']>, ParentType, ContextType, RequireFields<QueryWorkOrderDetailArgs, 'channelIntegrationId' | 'workOrderId'>>;
  workOrderEntity?: Resolver<Array<Maybe<ResolversTypes['AngusLocation']>>, ParentType, ContextType, RequireFields<QueryWorkOrderEntityArgs, 'contentId'>>;
  workOrderInteraction?: Resolver<ResolversTypes['UserContentInteraction'], ParentType, ContextType, Partial<QueryWorkOrderInteractionArgs>>;
  workOrderProperties?: Resolver<Array<Maybe<ResolversTypes['AngusLocation']>>, ParentType, ContextType, RequireFields<QueryWorkOrderPropertiesArgs, 'contentId'>>;
  workOrderPropertyFloors?: Resolver<Array<Maybe<ResolversTypes['AngusFloor']>>, ParentType, ContextType, RequireFields<QueryWorkOrderPropertyFloorsArgs, 'contentId'>>;
  workOrderPropertyTenants?: Resolver<Array<Maybe<ResolversTypes['AngusTenant']>>, ParentType, ContextType, RequireFields<QueryWorkOrderPropertyTenantsArgs, 'contentId'>>;
  workOrderRequesterLocations?: Resolver<Array<Maybe<ResolversTypes['AngusBuilding']>>, ParentType, ContextType, RequireFields<QueryWorkOrderRequesterLocationsArgs, 'contentId'>>;
  workOrderTenantRequesters?: Resolver<Array<Maybe<ResolversTypes['AngusRequester']>>, ParentType, ContextType, RequireFields<QueryWorkOrderTenantRequestersArgs, 'contentId'>>;
  workOrderTypes?: Resolver<Array<Maybe<ResolversTypes['AngusRequestType']>>, ParentType, ContextType, RequireFields<QueryWorkOrderTypesArgs, 'contentId'>>;
  workOrderUsersByGroupRoles?: Resolver<ResolversTypes['UserGroupRolePagination'], ParentType, ContextType, RequireFields<QueryWorkOrderUsersByGroupRolesArgs, 'groupRoleIds'>>;
};

export type ReceivableResolvers<ContextType = any, ParentType extends ResolversParentTypes['Receivable'] = ResolversParentTypes['Receivable']> = {
  creditBundle?: Resolver<Maybe<ResolversTypes['CreditBundle']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ReceivableType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurrenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Recurrence'] = ResolversParentTypes['Recurrence']> = {
  anyoneCanCancel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalActorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalActorType?: Resolver<Maybe<ResolversTypes['ExternalActorType']>, ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupType?: Resolver<ResolversTypes['GroupType'], ParentType, ContextType>;
  intervalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastOccurrence?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  nextOccurrence?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  recurrenceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recurrenceInterval?: Resolver<ResolversTypes['RecurrenceInterval'], ParentType, ContextType>;
  recurrenceType?: Resolver<ResolversTypes['RecurrenceType'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  totalCounts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCharge'] = ResolversParentTypes['RecurringCharge']> = {
  charge?: Resolver<ResolversTypes['ChargeTemplate'], ParentType, ContextType>;
  recurrence?: Resolver<ResolversTypes['Recurrence'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReformedChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReformedChannel'] = ResolversParentTypes['ReformedChannel']> = {
  channelInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChannelInvite']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefundTransactionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundTransactionResponse'] = ResolversParentTypes['RefundTransactionResponse']> = {
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisterKioskVisitorPassScanResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisterKioskVisitorPassScanResponse'] = ResolversParentTypes['RegisterKioskVisitorPassScanResponse']> = {
  scanStatus?: Resolver<ResolversTypes['HardwareVisitorPassScanStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelayAccessPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelayAccessPoint'] = ResolversParentTypes['RelayAccessPoint']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelayModuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelayModule'] = ResolversParentTypes['RelayModule']> = {
  buildingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastSeen?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  macAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['RelayModuleStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelayOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelayOutput'] = ResolversParentTypes['RelayOutput']> = {
  accessPoint?: Resolver<Maybe<ResolversTypes['RelayAccessPoint']>, ParentType, ContextType>;
  buildingId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  delay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pulse?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  relayModule?: Resolver<ResolversTypes['RelayModule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemovePaymentMethodResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RemovePaymentMethodResponse'] = ResolversParentTypes['RemovePaymentMethodResponse']> = {
  accountsUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RequirementScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Requirement'], any> {
  name: 'Requirement';
}

export type RequirementDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequirementDetail'] = ResolversParentTypes['RequirementDetail']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequirementFeatureContentInteractionPropertiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequirementFeatureContentInteractionProperties'] = ResolversParentTypes['RequirementFeatureContentInteractionProperties']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['RequirementFeatureContentInteractionPropertiesId']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequirementFeatureContentInteractionPropertiesIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequirementFeatureContentInteractionPropertiesId'] = ResolversParentTypes['RequirementFeatureContentInteractionPropertiesId']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequirementsFeaturePropertiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequirementsFeatureProperties'] = ResolversParentTypes['RequirementsFeatureProperties']> = {
  channelLanguages?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  companyNameRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contentInteractionRequired?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequirementFeatureContentInteractionProperties']>>>, ParentType, ContextType>;
  enabledLanguagesForMembers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  fullNameRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  multiLanguageEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  phoneNumberRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profilePictureRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['RequirementsSource']>, ParentType, ContextType>;
  verifiedEmailRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequirementsSourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequirementsSource'] = ResolversParentTypes['RequirementsSource']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reservable'] = ResolversParentTypes['Reservable']> = {
  features?: Resolver<Maybe<Array<ResolversTypes['ContentFeature']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservableBookings?: Resolver<Array<ResolversTypes['ReservableBooking']>, ParentType, ContextType, RequireFields<ReservableReservableBookingsArgs, 'endDate' | 'startDate'>>;
  timeZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableAvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableAvailability'] = ResolversParentTypes['ReservableAvailability']> = {
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  eventType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableBookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableBooking'] = ResolversParentTypes['ReservableBooking']> = {
  adminNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  companies?: Resolver<Array<ResolversTypes['BookingCompany']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType>;
  contentData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  features?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  lastStatus?: Resolver<Maybe<ResolversTypes['UserContentInteractionStatus']>, ParentType, ContextType>;
  lastStatusDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserContentInteractionStatus'], ParentType, ContextType>;
  statusDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableBookingPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableBookingPagination'] = ResolversParentTypes['ReservableBookingPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['ReservableBooking']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableConnection'] = ResolversParentTypes['ReservableConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ReservableEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['CursorPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableEdge'] = ResolversParentTypes['ReservableEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Reservable'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableWaitlistByContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableWaitlistByContent'] = ResolversParentTypes['ReservableWaitlistByContent']> = {
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReservableWaitlistCompany']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['ReservableWaitlistUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableWaitlistCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableWaitlistCompany'] = ResolversParentTypes['ReservableWaitlistCompany']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableWaitlistEnrollmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableWaitlistEnrollment'] = ResolversParentTypes['ReservableWaitlistEnrollment']> = {
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableWaitlistPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableWaitlistPagination'] = ResolversParentTypes['ReservableWaitlistPagination']> = {
  items?: Resolver<Array<ResolversTypes['ReservableWaitlistByContent']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableWaitlistUnrollmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableWaitlistUnrollment'] = ResolversParentTypes['ReservableWaitlistUnrollment']> = {
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservableWaitlistUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReservableWaitlistUser'] = ResolversParentTypes['ReservableWaitlistUser']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResidentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Resident'] = ResolversParentTypes['Resident']> = {
  floorIds?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  floors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Floor']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  moveInDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  moveOutDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unitIds?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  units?: Resolver<Maybe<Array<Maybe<ResolversTypes['Unit']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SamlDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SAMLData'] = ResolversParentTypes['SAMLData']> = {
  SAMLResponse?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SamlServiceProviderDefinitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SAMLServiceProviderDefinition'] = ResolversParentTypes['SAMLServiceProviderDefinition']> = {
  customFields?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  entityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  idp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveConfigResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaveConfigResponse'] = ResolversParentTypes['SaveConfigResponse']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavePaymentMethodResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SavePaymentMethodResponse'] = ResolversParentTypes['SavePaymentMethodResponse']> = {
  paymentProcessorCustomerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProcessorSourceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledVisitorPassResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledVisitorPass'] = ResolversParentTypes['ScheduledVisitorPass']> = {
  result?: Resolver<ResolversTypes['ScheduledVisitorPassResult'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledVisitorPassResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledVisitorPassResult'] = ResolversParentTypes['ScheduledVisitorPassResult']> = {
  eventNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tenantNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  visits?: Resolver<Maybe<Array<ResolversTypes['VisitorPass']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchOptionFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchOptionFilter'] = ResolversParentTypes['SearchOptionFilter']> = {
  filter?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContentFilterType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchOptions'] = ResolversParentTypes['SearchOptions']> = {
  areFiltersApplied?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<Array<ResolversTypes['SearchOptionFilter']>>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  includedMLSColumns?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  metatagFilters?: Resolver<Maybe<Array<ResolversTypes['MetatagFilter']>>, ParentType, ContextType>;
  search?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorts?: Resolver<Maybe<Array<ResolversTypes['ContentSortType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchTasksReponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchTasksReponseDTO'] = ResolversParentTypes['SearchTasksReponseDTO']> = {
  filterOptions?: Resolver<Maybe<ResolversTypes['TaskFilterOptionDTO']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  tasks?: Resolver<Maybe<Array<ResolversTypes['ClientTask']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  backgroundColor?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<Array<ResolversTypes['ContentFilterType']>>, ParentType, ContextType>;
  groupBy?: Resolver<Maybe<ResolversTypes['ContentGroupByType']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  object?: Resolver<ResolversTypes['SectionObjectType'], ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  renderType?: Resolver<Maybe<ResolversTypes['BlockType']>, ParentType, ContextType>;
  searchInfo?: Resolver<ResolversTypes['DynamicSectionInfo'], ParentType, ContextType>;
  sectionContent?: Resolver<Maybe<Array<ResolversTypes['SectionContent']>>, ParentType, ContextType, Partial<SectionSectionContentArgs>>;
  sectionMetatags?: Resolver<Maybe<Array<ResolversTypes['ObjectMetatag']>>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['SortDirection']>, ParentType, ContextType>;
  sorts?: Resolver<Maybe<Array<ResolversTypes['ContentSortType']>>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tagsOnSection?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SectionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SectionContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SectionContent'] = ResolversParentTypes['SectionContent']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>;
  isPinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  section?: Resolver<ResolversTypes['Section'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SellingPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SellingPrice'] = ResolversParentTypes['SellingPrice']> = {
  grossMarkup?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  grossTax?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  listPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  netPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequest'] = ResolversParentTypes['ServiceRequest']> = {
  assignee?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  assigneeGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  attachmentRefId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  contentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  customQuestions?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomQuestion']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayMetadata?: Resolver<Maybe<Array<Maybe<ResolversTypes['DisplayMetadata']>>>, ParentType, ContextType>;
  equipment?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  extRefId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  friendlyID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isBillable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceRequestId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submitOnBehalfOf?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  suite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suiteIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['SourceEntityIdentifier']>>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['IdNamePair']>, ParentType, ContextType>;
  userFriendlyID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestAction'] = ResolversParentTypes['ServiceRequestAction']> = {
  currentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  field?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  previousState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestAuditLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestAuditLog'] = ResolversParentTypes['ServiceRequestAuditLog']> = {
  actions?: Resolver<Maybe<Array<ResolversTypes['ServiceRequestAction']>>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestCategoriesListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestCategoriesList'] = ResolversParentTypes['ServiceRequestCategoriesList']> = {
  categories?: Resolver<Maybe<Array<ResolversTypes['Category']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestFeaturesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestFeatures'] = ResolversParentTypes['ServiceRequestFeatures']> = {
  features?: Resolver<Maybe<ResolversTypes['Features']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestIssuesListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestIssuesList'] = ResolversParentTypes['ServiceRequestIssuesList']> = {
  issues?: Resolver<Maybe<Array<ResolversTypes['Issue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestLocationsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestLocationsList'] = ResolversParentTypes['ServiceRequestLocationsList']> = {
  locations?: Resolver<Maybe<Array<ResolversTypes['Location']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestSearchResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestSearchResults'] = ResolversParentTypes['ServiceRequestSearchResults']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  serviceRequests?: Resolver<Maybe<Array<ResolversTypes['ServiceRequest']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestUpdateResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestUpdateResponseDTO'] = ResolversParentTypes['ServiceRequestUpdateResponseDTO']> = {
  serviceRequestId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestsFilterFieldsDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestsFilterFieldsDTO'] = ResolversParentTypes['ServiceRequestsFilterFieldsDTO']> = {
  assignees?: Resolver<Maybe<Array<Maybe<ResolversTypes['IdNamePair']>>>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['IdNamePair']>>>, ParentType, ContextType>;
  equipment?: Resolver<Maybe<Array<Maybe<ResolversTypes['IdNamePair']>>>, ParentType, ContextType>;
  issues?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  statuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceRequestsListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceRequestsList'] = ResolversParentTypes['ServiceRequestsList']> = {
  serviceRequests?: Resolver<Maybe<Array<ResolversTypes['ServiceRequest']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Session'] = ResolversParentTypes['Session']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  hostname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['PublicUser']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopifyCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopifyCustomer'] = ResolversParentTypes['ShopifyCustomer']> = {
  shopifyCustomerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopifyMultipassLoginDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopifyMultipassLoginDetail'] = ResolversParentTypes['ShopifyMultipassLoginDetail']> = {
  loginUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  multipassId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  shopifyCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopifyStoreUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SourceEntityIdentifierResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceEntityIdentifier'] = ResolversParentTypes['SourceEntityIdentifier']> = {
  sourceEntityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceEntityType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpecialInvite'] = ResolversParentTypes['SpecialInvite']> = {
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartImportJobResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StartImportJobResponse'] = ResolversParentTypes['StartImportJobResponse']> = {
  jobId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StepExecutionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepExecution'] = ResolversParentTypes['StepExecution']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meterReadingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['StepExecutionOption']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  response?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StepExecutionOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepExecutionOption'] = ResolversParentTypes['StepExecutionOption']> = {
  choices?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StepTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepTemplate'] = ResolversParentTypes['StepTemplate']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meterReadingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['StepTemplateOption']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StepTemplateOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StepTemplateOption'] = ResolversParentTypes['StepTemplateOption']> = {
  choices?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeAccount'] = ResolversParentTypes['StripeAccount']> = {
  business_logo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  business_logo_large?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  business_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  business_primary_color?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  business_url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  charges_enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  debit_negative_balances?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  decline_charge_on?: Resolver<Maybe<ResolversTypes['StripeDeclineCharge']>, ParentType, ContextType>;
  default_currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  details_submitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  legal_entity?: Resolver<Maybe<ResolversTypes['StripeLegalEntity']>, ParentType, ContextType>;
  object?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payout_schedule?: Resolver<Maybe<ResolversTypes['StripePayoutSchedule']>, ParentType, ContextType>;
  payout_statement_descriptor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payouts_enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  product_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statement_descriptor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  support_address?: Resolver<Maybe<ResolversTypes['StripeAddress']>, ParentType, ContextType>;
  support_email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  support_phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  support_url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['StripeAccountType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeAccountLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeAccountLink'] = ResolversParentTypes['StripeAccountLink']> = {
  created?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expires_at?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  object?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeAccountVerificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeAccountVerification'] = ResolversParentTypes['StripeAccountVerification']> = {
  disabled_reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  due_by?: Resolver<Maybe<ResolversTypes['StripeDate']>, ParentType, ContextType>;
  fields_needed?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeAddress'] = ResolversParentTypes['StripeAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  line1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  line2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postal_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeBankAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeBankAccount'] = ResolversParentTypes['StripeBankAccount']> = {
  account_holder_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  account_holder_type?: Resolver<ResolversTypes['StripeAccountType'], ParentType, ContextType>;
  account_number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bank_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fingerprint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  last4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  routing_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeDate'] = ResolversParentTypes['StripeDate']> = {
  day?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeDeclineChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeDeclineCharge'] = ResolversParentTypes['StripeDeclineCharge']> = {
  avs_failure?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cvc_failure?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeInfo'] = ResolversParentTypes['StripeInfo']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  clientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  publicKey?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeLegalEntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeLegalEntity'] = ResolversParentTypes['StripeLegalEntity']> = {
  additional_owners?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['StripeAddress']>, ParentType, ContextType>;
  business_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  business_taxt_id_provided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['StripeDate']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['StripeGenderEnum']>, ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personal_address?: Resolver<Maybe<ResolversTypes['StripeAddress']>, ParentType, ContextType>;
  personal_id_number_provided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  phone_number?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>;
  ssn_last_4_provided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['StripeLegalEntityType']>, ParentType, ContextType>;
  verification?: Resolver<Maybe<ResolversTypes['StripeVerification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePayoutScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePayoutSchedule'] = ResolversParentTypes['StripePayoutSchedule']> = {
  delay_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['StripeIntervalEnum']>, ParentType, ContextType>;
  monthly_anchor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weekly_anchor?: Resolver<Maybe<ResolversTypes['StripeDayEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeVerificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeVerification'] = ResolversParentTypes['StripeVerification']> = {
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  details_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  document_back?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface StudioGroupRoleNameScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['StudioGroupRoleName'], any> {
  name: 'StudioGroupRoleName';
}

export type SubscriptionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionDetails'] = ResolversParentTypes['SubscriptionDetails']> = {
  anyoneCanCancel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  intervalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recurrenceInterval?: Resolver<ResolversTypes['RecurrenceInterval'], ParentType, ContextType>;
  subscriptionDetailsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalCounts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Suite'] = ResolversParentTypes['Suite']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalSuiteId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOrName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tenantLease?: Resolver<Maybe<ResolversTypes['TenantLease']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SupportedLocaleScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SupportedLocale'], any> {
  name: 'SupportedLocale';
}

export type SwitchChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwitchChannel'] = ResolversParentTypes['SwitchChannel']> = {
  focusOnChannels?: Resolver<Maybe<Array<ResolversTypes['Channel']>>, ParentType, ContextType>;
  primaryId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  secondaryId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  contents?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name_l10n?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  sections?: Resolver<Maybe<Array<ResolversTypes['Section']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetingRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetingRule'] = ResolversParentTypes['TargetingRule']> = {
  audienceId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  groupRoleId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetingRulesForAudienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetingRulesForAudience'] = ResolversParentTypes['TargetingRulesForAudience']> = {
  channelTargetingRules?: Resolver<Maybe<Array<Maybe<ResolversTypes['TargetingRule']>>>, ParentType, ContextType>;
  groupRoleTargetingRules?: Resolver<Maybe<Array<Maybe<ResolversTypes['TargetingRule']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = {
  actualTimeToComplete?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assigneeGroup?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  completeNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  completedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  equipment?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaskEquipmentDTO']>>>, ParentType, ContextType>;
  estimateTimeToComplete?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  friendlyID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meterReading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repeats?: Resolver<Maybe<ResolversTypes['TaskRepeatDTO']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['TaskScheduleDTO']>, ParentType, ContextType>;
  scheduleNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  steps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userFriendlyID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskAction'] = ResolversParentTypes['TaskAction']> = {
  currentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  field?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  previousState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskAuditLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskAuditLog'] = ResolversParentTypes['TaskAuditLog']> = {
  actions?: Resolver<Maybe<Array<ResolversTypes['TaskAction']>>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskEquipmentDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskEquipmentDTO'] = ResolversParentTypes['TaskEquipmentDTO']> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskFilterOptionDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskFilterOptionDTO'] = ResolversParentTypes['TaskFilterOptionDTO']> = {
  assignee?: Resolver<Maybe<Array<Maybe<ResolversTypes['PublicUser']>>>, ParentType, ContextType>;
  equipment?: Resolver<Maybe<Array<Maybe<ResolversTypes['IdNamePair']>>>, ParentType, ContextType>;
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<Array<Maybe<ResolversTypes['IdNamePair']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskRepeatDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskRepeatDTO'] = ResolversParentTypes['TaskRepeatDTO']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskScheduleDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskScheduleDTO'] = ResolversParentTypes['TaskScheduleDTO']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tax'] = ResolversParentTypes['Tax']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tenant'] = ResolversParentTypes['Tenant']> = {
  floors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Floor']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suites?: Resolver<Maybe<Array<Maybe<ResolversTypes['Unit']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantBuildingChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantBuildingChannel'] = ResolversParentTypes['TenantBuildingChannel']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantLeaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantLease'] = ResolversParentTypes['TenantLease']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalLeaseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalTenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Theme'] = ResolversParentTypes['Theme']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  colors?: Resolver<Maybe<ResolversTypes['ThemeColors']>, ParentType, ContextType>;
  layout?: Resolver<Maybe<ResolversTypes['LayoutDesignSystem']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  palette?: Resolver<Maybe<ResolversTypes['ThemePalette']>, ParentType, ContextType>;
  typography?: Resolver<Maybe<ResolversTypes['TypographicDesignSystem']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemeColorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemeColors'] = ResolversParentTypes['ThemeColors']> = {
  custom1?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  custom2?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  custom3?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['Color'], ParentType, ContextType>;
  secondary?: Resolver<ResolversTypes['Color'], ParentType, ContextType>;
  tertiary?: Resolver<ResolversTypes['Color'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThemePaletteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThemePalette'] = ResolversParentTypes['ThemePalette']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  background?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<Array<ResolversTypes['CustomColorPalette']>>, ParentType, ContextType>;
  inputText?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primary?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  quaternary?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  secondary?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  tertiary?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  warning?: Resolver<Maybe<ResolversTypes['Color']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token']> = {
  jti?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  warnings?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Totals'] = ResolversParentTypes['Totals']> = {
  subtotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTax?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  billingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  externalEntityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityType?: Resolver<Maybe<ResolversTypes['ExternalEntityType']>, ParentType, ContextType>;
  paidBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentSource?: Resolver<Maybe<ResolversTypes['PaymentSource']>, ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['PaymentMethod'], ParentType, ContextType>;
  productItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductItem']>>>, ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerAccessPointResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerAccessPointResponse'] = ResolversParentTypes['TriggerAccessPointResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypographicDesignSystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TypographicDesignSystem'] = ResolversParentTypes['TypographicDesignSystem']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  base?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  emphasis?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  h1?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  h2?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  h3?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  h4?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  h5?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  h6?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  p?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  quote?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  strong?: Resolver<Maybe<ResolversTypes['TypographyLayoutElement']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypographyElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['TypographyElement'] = ResolversParentTypes['TypographyElement']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  fontFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  letterSpacing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lineHeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypographyLayoutElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['TypographyLayoutElement'] = ResolversParentTypes['TypographyLayoutElement']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  fontFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  letterSpacing?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lineHeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginBottom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginLeft?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginRight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marginTop?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingBottom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingLeft?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingRight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paddingTop?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID';
}

export type UnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['Unit'] = ResolversParentTypes['Unit']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floors?: Resolver<Maybe<Array<Maybe<ResolversTypes['Floor']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  people?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType>;
  property?: Resolver<Maybe<ResolversTypes['Property']>, ParentType, ContextType>;
  propertyId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  tenants?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tenant']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAudienceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateAudienceResponse'] = ResolversParentTypes['UpdateAudienceResponse']> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  channelTargetingRules?: Resolver<Array<Maybe<ResolversTypes['TargetingRule']>>, ParentType, ContextType>;
  contentToTarget?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  createdByChannel?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  groupRoleTargetingRules?: Resolver<Array<Maybe<ResolversTypes['TargetingRule']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  managementChannels?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shareChannels?: Resolver<Array<Maybe<ResolversTypes['UUID']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateHardwareDeviceInfoResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateHardwareDeviceInfoResponse'] = ResolversParentTypes['UpdateHardwareDeviceInfoResponse']> = {
  hardwareId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateInvoiceAutopaymentDetailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateInvoiceAutopaymentDetailsResponse'] = ResolversParentTypes['UpdateInvoiceAutopaymentDetailsResponse']> = {
  autopaymentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateInvoiceToPaidResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateInvoiceToPaidResponse'] = ResolversParentTypes['UpdateInvoiceToPaidResponse']> = {
  invoiceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatePmScheduleResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePMScheduleResponseDTO'] = ResolversParentTypes['UpdatePMScheduleResponseDTO']> = {
  scheduleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateProductExceptionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateProductExceptionResponse'] = ResolversParentTypes['UpdateProductExceptionResponse']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['Group'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  markup?: Resolver<ResolversTypes['Markup'], ParentType, ContextType>;
  payer?: Resolver<ResolversTypes['Payer'], ParentType, ContextType>;
  productCategory?: Resolver<Maybe<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  products?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  tax?: Resolver<ResolversTypes['ProductTax'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateProductResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateProductResponse'] = ResolversParentTypes['UpdateProductResponse']> = {
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateRecurrencePassesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateRecurrencePassesResponse'] = ResolversParentTypes['UpdateRecurrencePassesResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTargetingRuleResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTargetingRuleResponse'] = ResolversParentTypes['UpdateTargetingRuleResponse']> = {
  audienceId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  groupRoleId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTaskResponseDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTaskResponseDTO'] = ResolversParentTypes['UpdateTaskResponseDTO']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UploadCsvResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadCSVResponse'] = ResolversParentTypes['UploadCSVResponse']> = {
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsertSubscriptionDetailsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpsertSubscriptionDetailsResponse'] = ResolversParentTypes['UpsertSubscriptionDetailsResponse']> = {
  subscriptionDetailsId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  addresses?: Resolver<Maybe<Array<ResolversTypes['Address']>>, ParentType, ContextType>;
  analytics?: Resolver<ResolversTypes['ObjectAnalytics'], ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserGroupRoleRelatedChannel']>>>, ParentType, ContextType>;
  deviceTokens?: Resolver<Maybe<Array<ResolversTypes['DeviceToken']>>, ParentType, ContextType>;
  eventSubscriptions?: Resolver<Maybe<Array<ResolversTypes['EventSubscription']>>, ParentType, ContextType>;
  guid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  isSuperUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastGeo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  lastLogin?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastTimeZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  launchDarklyHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  libraryPaths?: Resolver<Maybe<Array<ResolversTypes['Path']>>, ParentType, ContextType>;
  libraryTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logins?: Resolver<Maybe<Array<ResolversTypes['UserLogin']>>, ParentType, ContextType>;
  mobileAccess?: Resolver<Maybe<ResolversTypes['MobileAccess']>, ParentType, ContextType, RequireFields<UserMobileAccessArgs, 'channelId' | 'provider'>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  online?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  paymentAccounts?: Resolver<Maybe<Array<ResolversTypes['PaymentAccount']>>, ParentType, ContextType, Partial<UserPaymentAccountsArgs>>;
  person?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  primaryLocation?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  privateKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  publicKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestAccountDeletionByDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['UserGroupRole']>>, ParentType, ContextType>;
  sessions?: Resolver<Maybe<Array<ResolversTypes['Session']>>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  stripe?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccessCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAccessCompany'] = ResolversParentTypes['UserAccessCompany']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccessDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAccessDetails'] = ResolversParentTypes['UserAccessDetails']> = {
  accessRole?: Resolver<Array<ResolversTypes['AccessUserRole']>, ParentType, ContextType>;
  accessUsers?: Resolver<Array<ResolversTypes['AccessUser']>, ParentType, ContextType>;
  companies?: Resolver<Array<ResolversTypes['UserAccessCompany']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primaryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserBooking'] = ResolversParentTypes['UserBooking']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBuildingAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserBuildingAccess'] = ResolversParentTypes['UserBuildingAccess']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credentials?: Resolver<Array<ResolversTypes['Credential']>, ParentType, ContextType>;
  mappings?: Resolver<Array<ResolversTypes['UserBuildingAccessMapping']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBuildingAccessMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserBuildingAccessMapping'] = ResolversParentTypes['UserBuildingAccessMapping']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mappedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mappingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserMappingStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserChannelSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserChannelSettings'] = ResolversParentTypes['UserChannelSettings']> = {
  channelId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompanyDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCompanyDetails'] = ResolversParentTypes['UserCompanyDetails']> = {
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserContentInteractionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserContentInteraction'] = ResolversParentTypes['UserContentInteraction']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  actions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  adminNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  analytics?: Resolver<Maybe<ResolversTypes['ObjectAnalytics']>, ParentType, ContextType>;
  authorizedUserIds?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  channelIntegration?: Resolver<Maybe<ResolversTypes['ChannelIntegration']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType>;
  contentData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  features?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  integrationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastStatus?: Resolver<Maybe<ResolversTypes['UserContentInteractionStatus']>, ParentType, ContextType>;
  lastStatusDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  moduleData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  paymentAccount?: Resolver<Maybe<ResolversTypes['PaymentAccount']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserContentInteractionStatus'], ParentType, ContextType>;
  statusDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserContentInteractionHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserContentInteractionHistory'] = ResolversParentTypes['UserContentInteractionHistory']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  actions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  adminNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  analytics?: Resolver<Maybe<ResolversTypes['ObjectAnalytics']>, ParentType, ContextType>;
  authorizedUserIds?: Resolver<Maybe<Array<ResolversTypes['UUID']>>, ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  channelIntegration?: Resolver<Maybe<ResolversTypes['ChannelIntegration']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['DraftContent'], ParentType, ContextType>;
  contentData?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  features?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['GeoPoint']>, ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['UserObjectInfo']>, ParentType, ContextType>;
  integrationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastStatus?: Resolver<Maybe<ResolversTypes['UserContentInteractionStatus']>, ParentType, ContextType>;
  lastStatusDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentAccount?: Resolver<Maybe<ResolversTypes['PaymentAccount']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserContentInteractionStatus'], ParentType, ContextType>;
  statusDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserContentInteractionHistoryPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserContentInteractionHistoryPagination'] = ResolversParentTypes['UserContentInteractionHistoryPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['UserContentInteractionHistory']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserContentInteractionPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserContentInteractionPagination'] = ResolversParentTypes['UserContentInteractionPagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['UserContentInteraction']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UserContentInteractionStatusScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UserContentInteractionStatus'], any> {
  name: 'UserContentInteractionStatus';
}

export type UserEmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserEmail'] = ResolversParentTypes['UserEmail']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserFilters'] = ResolversParentTypes['UserFilters']> = {
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Channel']>>>, ParentType, ContextType>;
  groupRoles?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupRole']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserGroupRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserGroupRole'] = ResolversParentTypes['UserGroupRole']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  groupRole?: Resolver<ResolversTypes['GroupRole'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relatedChannels?: Resolver<Array<Maybe<ResolversTypes['UserGroupRoleRelatedChannel']>>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserGroupRolePaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserGroupRolePagination'] = ResolversParentTypes['UserGroupRolePagination']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['UserGroupRole']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserGroupRoleRelatedChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserGroupRoleRelatedChannel'] = ResolversParentTypes['UserGroupRoleRelatedChannel']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserIntegration'] = ResolversParentTypes['UserIntegration']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  channelIntegrationId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  userKey?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLoginResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLogin'] = ResolversParentTypes['UserLogin']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autoRefreshToken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  isPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['UserLoginProvider']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['UserLoginStatusType']>, ParentType, ContextType>;
  tokenExpiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UserLoginType'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLoginMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLoginMethod'] = ResolversParentTypes['UserLoginMethod']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['UserLoginProvider']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UserLoginType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserObjectInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserObjectInfo'] = ResolversParentTypes['UserObjectInfo']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  deletedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  detailsOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hidden?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hiddenOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  liked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  likedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pinnedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ratingOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shared?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sharedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  starred?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  starredOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  stats?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  viewed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  viewedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPagination'] = ResolversParentTypes['UserPagination']> = {
  filters?: Resolver<Maybe<ResolversTypes['UserFilters']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSettings'] = ResolversParentTypes['UserSettings']> = {
  emailOptIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  previousPaymentAccountId?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Visitor'] = ResolversParentTypes['Visitor']> = {
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorEmailTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorEmailTemplate'] = ResolversParentTypes['VisitorEmailTemplate']> = {
  backgroundId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entryInstructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policies?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qrEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  templateType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorLog'] = ResolversParentTypes['VisitorLog']> = {
  host?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitor?: Resolver<ResolversTypes['Visitor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorOrganizationSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorOrganizationSettings'] = ResolversParentTypes['VisitorOrganizationSettings']> = {
  bufferTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostEmailOnCancel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hostEmailOnCheckIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hostEmailOnConfirm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hostEmailOnUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hostNotificationOnCheckIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitorEmailOnCancel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  visitorEmailOnCreate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  visitorEmailOnUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  visitorSmsNotificationOnCreate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPass'] = ResolversParentTypes['VisitorPass']> = {
  accessGrantBuildingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  additionalRecipients?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  checkedInDatetime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  checkedOutDatetime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  companyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentVersion?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  host?: Resolver<Maybe<ResolversTypes['VisitorPassHost']>, ParentType, ContextType>;
  hostName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostTenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recurrenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  staffNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submissionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submittedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['VisitorPassTenant']>, ParentType, ContextType>;
  tenantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAtDatetime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validStateTransitions?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  visitor?: Resolver<ResolversTypes['Visitor'], ParentType, ContextType>;
  visitorNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassAggregatesBySubmissionIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassAggregatesBySubmissionId'] = ResolversParentTypes['VisitorPassAggregatesBySubmissionId']> = {
  items?: Resolver<Maybe<Array<ResolversTypes['VisitorPass']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassHostResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassHost'] = ResolversParentTypes['VisitorPassHost']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['VisitorPassHostProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassHostProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassHostProfile'] = ResolversParentTypes['VisitorPassHostProfile']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassRecurrenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassRecurrence'] = ResolversParentTypes['VisitorPassRecurrence']> = {
  accessGrantBuildingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  additionalRecipients?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  createdAtDatetime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hostTenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastUpdatedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrenceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schedule?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduleDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduleType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  staffNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submissionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAtDatetime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitorIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  visitorNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassTenantResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassTenant'] = ResolversParentTypes['VisitorPassTenant']> = {
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['VisitorPassTenantAddress']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['VisitorPassTenantProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassTenantAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassTenantAddress'] = ResolversParentTypes['VisitorPassTenantAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassTenantProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassTenantProfile'] = ResolversParentTypes['VisitorPassTenantProfile']> = {
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitorPassUpdateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitorPassUpdateResponse'] = ResolversParentTypes['VisitorPassUpdateResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type VoidChargeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoidChargeResponse'] = ResolversParentTypes['VoidChargeResponse']> = {
  chargeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VtsAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['VtsAddress'] = ResolversParentTypes['VtsAddress']> = {
  administrativeArea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WaitlistUnEnrollmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['WaitlistUnEnrollment'] = ResolversParentTypes['WaitlistUnEnrollment']> = {
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeatherResolvers<ContextType = any, ParentType extends ResolversParentTypes['Weather'] = ResolversParentTypes['Weather']> = {
  alerts?: Resolver<Maybe<Array<ResolversTypes['WeatherAlert']>>, ParentType, ContextType>;
  currently?: Resolver<ResolversTypes['WeatherDataPoint'], ParentType, ContextType>;
  flags?: Resolver<ResolversTypes['WeatherFlags'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeatherAlertResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeatherAlert'] = ResolversParentTypes['WeatherAlert']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expires?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  regions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  severity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeatherDataPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeatherDataPoint'] = ResolversParentTypes['WeatherDataPoint']> = {
  apparentTemperature?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cloudCover?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dewPoint?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  humidity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nearestStormBearing?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nearestStormDistance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ozone?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  precipIntensity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  precipProbability?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pressure?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  temperature?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uvIndex?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  windBearing?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  windSpeed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeatherFlagsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeatherFlags'] = ResolversParentTypes['WeatherFlags']> = {
  units?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookResolvers<ContextType = any, ParentType extends ResolversParentTypes['Webhook'] = ResolversParentTypes['Webhook']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['EventType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signingKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WebhookStatusEnum'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WhitelabelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Whitelabel'] = ResolversParentTypes['Whitelabel']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  appStoreId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  auth0OrganizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType>;
  channels?: Resolver<Maybe<Array<ResolversTypes['WhitelabelChannel']>>, ParentType, ContextType>;
  commonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  complianceContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disableLocationPrecision?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  eula?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  hosts?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  instance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iosTeamId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is2FAEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAppleIDEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMagicLinkEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['SupportedLocale']>, ParentType, ContextType>;
  mobileAndroid?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  mobileIos?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oAuthConfig?: Resolver<Maybe<Array<Maybe<ResolversTypes['OAuthConfig']>>>, ParentType, ContextType>;
  operatingName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  privacyPolicy?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  privacyPolicyUrls?: Resolver<Array<ResolversTypes['URL']>, ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  restrict?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  sha256Fingerprint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortOperatingName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  termsAndConditions?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  termsAndConditionsUrls?: Resolver<Array<ResolversTypes['URL']>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['ThemePalette']>, ParentType, ContextType>;
  typography?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  urlDevelopment?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  urlStaging?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WhitelabelChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['WhitelabelChannel'] = ResolversParentTypes['WhitelabelChannel']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['PublicUser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  whitelabel?: Resolver<ResolversTypes['Whitelabel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkflowResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workflow'] = ResolversParentTypes['Workflow']> = {
  _created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  _order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  _updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['WorkflowActionType'], ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataValidationSchema?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  event?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  inStatus?: Resolver<Maybe<Array<ResolversTypes['UserContentInteractionStatus']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['WorkflowTargetType']>, ParentType, ContextType>;
  targetType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  when?: Resolver<ResolversTypes['WorkflowWhenType'], ParentType, ContextType>;
  whenContext?: Resolver<ResolversTypes['WorkflowWhenContextType'], ParentType, ContextType>;
  workflow?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnableMobileAccessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['enableMobileAccessResponse'] = ResolversParentTypes['enableMobileAccessResponse']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccessControlChannelType?: AccessControlChannelTypeResolvers<ContextType>;
  AccessControlGroup?: AccessControlGroupResolvers<ContextType>;
  AccessControlGroups?: AccessControlGroupsResolvers<ContextType>;
  AccessControlProfileType?: AccessControlProfileTypeResolvers<ContextType>;
  AccessIntegration?: AccessIntegrationResolvers<ContextType>;
  AccessLocation?: AccessLocationResolvers<ContextType>;
  AccessPoint?: AccessPointResolvers<ContextType>;
  AccessUser?: AccessUserResolvers<ContextType>;
  AccessUserChannel?: AccessUserChannelResolvers<ContextType>;
  AccessUserControlGroups?: AccessUserControlGroupsResolvers<ContextType>;
  AccessUserRole?: AccessUserRoleResolvers<ContextType>;
  AccountContactPair?: AccountContactPairResolvers<ContextType>;
  AccountsMutations?: AccountsMutationsResolvers<ContextType>;
  AccountsQueries?: AccountsQueriesResolvers<ContextType>;
  ActivateUser?: ActivateUserResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AllowedFeatures?: AllowedFeaturesResolvers<ContextType>;
  AnalyticType?: GraphQLScalarType;
  AngusBuilding?: AngusBuildingResolvers<ContextType>;
  AngusFloor?: AngusFloorResolvers<ContextType>;
  AngusLocation?: AngusLocationResolvers<ContextType>;
  AngusRequestType?: AngusRequestTypeResolvers<ContextType>;
  AngusRequester?: AngusRequesterResolvers<ContextType>;
  AngusSuite?: AngusSuiteResolvers<ContextType>;
  AngusTenant?: AngusTenantResolvers<ContextType>;
  AngusWorkOrder?: AngusWorkOrderResolvers<ContextType>;
  AngusWorkOrderConfirmation?: AngusWorkOrderConfirmationResolvers<ContextType>;
  ApiKey?: ApiKeyResolvers<ContextType>;
  AppleWalletIssuanceToken?: AppleWalletIssuanceTokenResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  Audience?: AudienceResolvers<ContextType>;
  AudiencePagination?: AudiencePaginationResolvers<ContextType>;
  AudiencesToAttachContentTo?: AudiencesToAttachContentToResolvers<ContextType>;
  Auth0Connection?: Auth0ConnectionResolvers<ContextType>;
  Auth0DomainHomeRealmDiscovery?: Auth0DomainHomeRealmDiscoveryResolvers<ContextType>;
  Auth0MembershipInvitation?: Auth0MembershipInvitationResolvers<ContextType>;
  Auth0Organization?: Auth0OrganizationResolvers<ContextType>;
  Auth0ProvisionUserToOrganization?: Auth0ProvisionUserToOrganizationResolvers<ContextType>;
  Auth0User?: Auth0UserResolvers<ContextType>;
  AutoSetupCesEntityResponse?: AutoSetupCesEntityResponseResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  BiltSso?: BiltSsoResolvers<ContextType>;
  Binary?: GraphQLScalarType;
  Block?: BlockResolvers<ContextType>;
  BluetoothInvitationCode?: BluetoothInvitationCodeResolvers<ContextType>;
  BookingCompany?: BookingCompanyResolvers<ContextType>;
  Building?: BuildingResolvers<ContextType>;
  BuildingTenantChannel?: BuildingTenantChannelResolvers<ContextType>;
  Byte?: GraphQLScalarType;
  CancelRecurringChargeResponse?: CancelRecurringChargeResponseResolvers<ContextType>;
  CancelSubmissionResponse?: CancelSubmissionResponseResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  CategoryUpsertResponseDTO?: CategoryUpsertResponseDtoResolvers<ContextType>;
  Channel?: ChannelResolvers<ContextType>;
  ChannelAccessIntegration?: ChannelAccessIntegrationResolvers<ContextType>;
  ChannelContract?: ChannelContractResolvers<ContextType>;
  ChannelContractLineItem?: ChannelContractLineItemResolvers<ContextType>;
  ChannelContractPagination?: ChannelContractPaginationResolvers<ContextType>;
  ChannelEquipmentSettings?: ChannelEquipmentSettingsResolvers<ContextType>;
  ChannelHierarchy?: ChannelHierarchyResolvers<ContextType>;
  ChannelIntegration?: ChannelIntegrationResolvers<ContextType>;
  ChannelInvite?: ChannelInviteResolvers<ContextType>;
  ChannelInvitePagination?: ChannelInvitePaginationResolvers<ContextType>;
  ChannelMerchant?: ChannelMerchantResolvers<ContextType>;
  ChannelModule?: ChannelModuleResolvers<ContextType>;
  ChannelPage?: ChannelPageResolvers<ContextType>;
  ChannelPagination?: ChannelPaginationResolvers<ContextType>;
  ChannelRelationship?: ChannelRelationshipResolvers<ContextType>;
  ChannelRelationshipPagination?: ChannelRelationshipPaginationResolvers<ContextType>;
  ChannelSettings?: ChannelSettingsResolvers<ContextType>;
  ChannelStats?: ChannelStatsResolvers<ContextType>;
  Charge?: ChargeResolvers<ContextType>;
  ChargeBillingReport?: ChargeBillingReportResolvers<ContextType>;
  ChargeBillingReportsResponse?: ChargeBillingReportsResponseResolvers<ContextType>;
  ChargeCode?: ChargeCodeResolvers<ContextType>;
  ChargeTemplate?: ChargeTemplateResolvers<ContextType>;
  ClaimedChannelInvite?: ClaimedChannelInviteResolvers<ContextType>;
  ClientPMSchedule?: ClientPmScheduleResolvers<ContextType>;
  ClientTask?: ClientTaskResolvers<ContextType>;
  Color?: GraphQLScalarType;
  CompleteSignupData?: CompleteSignupDataResolvers<ContextType>;
  CompleteTaskResponseDTO?: CompleteTaskResponseDtoResolvers<ContextType>;
  Configuration?: ConfigurationResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  ContentCategoryType?: GraphQLScalarType;
  ContentDateRanges?: ContentDateRangesResolvers<ContextType>;
  ContentFeature?: ContentFeatureResolvers<ContextType>;
  ContentFeatureStatusSummary?: ContentFeatureStatusSummaryResolvers<ContextType>;
  ContentGenerator?: ContentGeneratorResolvers<ContextType>;
  ContentInterface?: ContentInterfaceResolvers<ContextType>;
  ContentNotification?: ContentNotificationResolvers<ContextType>;
  ContentPagination?: ContentPaginationResolvers<ContextType>;
  ContentPlacement?: ContentPlacementResolvers<ContextType>;
  ContentQuantityAllotments?: ContentQuantityAllotmentsResolvers<ContextType>;
  ContentTemplate?: ContentTemplateResolvers<ContextType>;
  ContentTemplatePagination?: ContentTemplatePaginationResolvers<ContextType>;
  ContentWaitlistEnrollment?: ContentWaitlistEnrollmentResolvers<ContextType>;
  ContentWorkflow?: ContentWorkflowResolvers<ContextType>;
  CreateAudienceResponse?: CreateAudienceResponseResolvers<ContextType>;
  CreateChargeCodeResponse?: CreateChargeCodeResponseResolvers<ContextType>;
  CreateChargeResponse?: CreateChargeResponseResolvers<ContextType>;
  CreateProductCategoryResponse?: CreateProductCategoryResponseResolvers<ContextType>;
  CreateProductExceptionResponse?: CreateProductExceptionResponseResolvers<ContextType>;
  CreateProductResponse?: CreateProductResponseResolvers<ContextType>;
  CreateRecurringChargeResponse?: CreateRecurringChargeResponseResolvers<ContextType>;
  CreateTargetingRuleResponse?: CreateTargetingRuleResponseResolvers<ContextType>;
  CreateTransactionResponse?: CreateTransactionResponseResolvers<ContextType>;
  CreateUserResponse?: CreateUserResponseResolvers<ContextType>;
  Credential?: CredentialResolvers<ContextType>;
  CredentialGroups?: CredentialGroupsResolvers<ContextType>;
  CreditBundle?: CreditBundleResolvers<ContextType>;
  CreditCard?: CreditCardResolvers<ContextType>;
  CreditWallet?: CreditWalletResolvers<ContextType>;
  CreditWalletChannelProfile?: CreditWalletChannelProfileResolvers<ContextType>;
  CurrencyDetail?: CurrencyDetailResolvers<ContextType>;
  CursorPageInfo?: CursorPageInfoResolvers<ContextType>;
  CustomColorPalette?: CustomColorPaletteResolvers<ContextType>;
  CustomQuestion?: CustomQuestionResolvers<ContextType>;
  DataField?: DataFieldResolvers<ContextType>;
  DatasetRecord?: DatasetRecordResolvers<ContextType>;
  DatasetRecordPagination?: DatasetRecordPaginationResolvers<ContextType>;
  DatasetSchema?: DatasetSchemaResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateRange?: DateRangeResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeepLink?: DeepLinkResolvers<ContextType>;
  DeviceToken?: DeviceTokenResolvers<ContextType>;
  DisplayMetadata?: DisplayMetadataResolvers<ContextType>;
  Document?: DocumentResolvers<ContextType>;
  DraftContent?: DraftContentResolvers<ContextType>;
  DraftContentHistory?: DraftContentHistoryResolvers<ContextType>;
  DraftContentHistoryPagination?: DraftContentHistoryPaginationResolvers<ContextType>;
  DraftContentNotification?: DraftContentNotificationResolvers<ContextType>;
  DraftContentPagination?: DraftContentPaginationResolvers<ContextType>;
  DraftContentWorkflow?: DraftContentWorkflowResolvers<ContextType>;
  DraftUserContentInteraction?: DraftUserContentInteractionResolvers<ContextType>;
  DynamicSectionInfo?: DynamicSectionInfoResolvers<ContextType>;
  EmailAddress?: GraphQLScalarType;
  Entity?: EntityResolvers<ContextType>;
  Equipment?: EquipmentResolvers<ContextType>;
  EquipmentForExport?: EquipmentForExportResolvers<ContextType>;
  EquipmentSearchResults?: EquipmentSearchResultsResolvers<ContextType>;
  EscalationRule?: EscalationRuleResolvers<ContextType>;
  Essensys?: EssensysResolvers<ContextType>;
  EssensysAccount?: EssensysAccountResolvers<ContextType>;
  EssensysCalendar?: EssensysCalendarResolvers<ContextType>;
  EssensysContact?: EssensysContactResolvers<ContextType>;
  EssensysInvoice?: EssensysInvoiceResolvers<ContextType>;
  EssensysMutation?: EssensysMutationResolvers<ContextType>;
  EssensysPayment?: EssensysPaymentResolvers<ContextType>;
  EssensysPaymentProcessor?: EssensysPaymentProcessorResolvers<ContextType>;
  EssensysPaymentSettings?: EssensysPaymentSettingsResolvers<ContextType>;
  EssensysProduct?: EssensysProductResolvers<ContextType>;
  EventDetail?: EventDetailResolvers<ContextType>;
  EventSubscription?: EventSubscriptionResolvers<ContextType>;
  EventType?: GraphQLScalarType;
  Exception?: ExceptionResolvers<ContextType>;
  ExportCSVTasksReponseDTO?: ExportCsvTasksReponseDtoResolvers<ContextType>;
  ExternalUrl?: ExternalUrlResolvers<ContextType>;
  Features?: FeaturesResolvers<ContextType>;
  FeedbackForm?: FeedbackFormResolvers<ContextType>;
  FindSchedulesFilterOptions?: FindSchedulesFilterOptionsResolvers<ContextType>;
  FindSchedulesReponseDTO?: FindSchedulesReponseDtoResolvers<ContextType>;
  FlatfileToken?: FlatfileTokenResolvers<ContextType>;
  Floor?: FloorResolvers<ContextType>;
  FloorMapGenerationStatus?: FloorMapGenerationStatusResolvers<ContextType>;
  GeoPoint?: GraphQLScalarType;
  GetConfigResponse?: GetConfigResponseResolvers<ContextType>;
  GetFinalPriceForProductsResponse?: GetFinalPriceForProductsResponseResolvers<ContextType>;
  GetIntegrationProcessesResponse?: GetIntegrationProcessesResponseResolvers<ContextType>;
  GetInvoiceDetailsResponse?: GetInvoiceDetailsResponseResolvers<ContextType>;
  GetProductExceptionResponse?: GetProductExceptionResponseResolvers<ContextType>;
  GetProductResponse?: GetProductResponseResolvers<ContextType>;
  GetRecurringChargeResponse?: GetRecurringChargeResponseResolvers<ContextType>;
  GetRelayOutputList?: GetRelayOutputListResolvers<ContextType>;
  GetTransactionStatusResponse?: GetTransactionStatusResponseResolvers<ContextType>;
  GetUserByExternalIdResponse?: GetUserByExternalIdResponseResolvers<ContextType>;
  GetUserCreditWalletsResponse?: GetUserCreditWalletsResponseResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupRole?: GroupRoleResolvers<ContextType>;
  GroupRoleJoinRule?: GroupRoleJoinRuleResolvers<ContextType>;
  GroupSettingResponse?: GroupSettingResponseResolvers<ContextType>;
  HOMBuilding?: HomBuildingResolvers<ContextType>;
  HOMDate?: HomDateResolvers<ContextType>;
  HOMEvent?: HomEventResolvers<ContextType>;
  HOMTeacher?: HomTeacherResolvers<ContextType>;
  HOMVenue?: HomVenueResolvers<ContextType>;
  Hardware?: HardwareResolvers<ContextType>;
  HardwareAccessLog?: HardwareAccessLogResolvers<ContextType>;
  HardwareAccessLogPagination?: HardwareAccessLogPaginationResolvers<ContextType>;
  HardwareDeviceInfo?: HardwareDeviceInfoResolvers<ContextType>;
  HardwarePagination?: HardwarePaginationResolvers<ContextType>;
  HardwareSettings?: HardwareSettingsResolvers<ContextType>;
  Host?: HostResolvers<ContextType>;
  HyperLink?: HyperLinkResolvers<ContextType>;
  IdNamePair?: IdNamePairResolvers<ContextType>;
  Image?: GraphQLScalarType;
  Integration?: IntegrationResolvers<ContextType>;
  IntegrationProcess?: IntegrationProcessResolvers<ContextType>;
  Invoice?: InvoiceResolvers<ContextType>;
  InvoiceAutopaymentDetails?: InvoiceAutopaymentDetailsResolvers<ContextType>;
  InvoicePartial?: InvoicePartialResolvers<ContextType>;
  Issue?: IssueResolvers<ContextType>;
  IssueUpsertResponseDTO?: IssueUpsertResponseDtoResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JobResponse?: JobResponseResolvers<ContextType>;
  JobsResponse?: JobsResponseResolvers<ContextType>;
  LayoutDesignSystem?: LayoutDesignSystemResolvers<ContextType>;
  Library?: LibraryResolvers<ContextType>;
  LibraryAccess?: LibraryAccessResolvers<ContextType>;
  LibraryItem?: LibraryItemResolvers<ContextType>;
  LibraryItemPagination?: LibraryItemPaginationResolvers<ContextType>;
  LibraryItemWithOriginalExtension?: LibraryItemWithOriginalExtensionResolvers<ContextType>;
  LibraryPagination?: LibraryPaginationResolvers<ContextType>;
  LinkedEntity?: LinkedEntityResolvers<ContextType>;
  LinkedEntityPagination?: LinkedEntityPaginationResolvers<ContextType>;
  ListChargeCodeResponse?: ListChargeCodeResponseResolvers<ContextType>;
  ListChargesResponse?: ListChargesResponseResolvers<ContextType>;
  ListInvoicesByPayersResponse?: ListInvoicesByPayersResponseResolvers<ContextType>;
  ListInvoicesResponse?: ListInvoicesResponseResolvers<ContextType>;
  ListProductCategoriesResponse?: ListProductCategoriesResponseResolvers<ContextType>;
  ListProductExceptionsResponse?: ListProductExceptionsResponseResolvers<ContextType>;
  ListProductsResponse?: ListProductsResponseResolvers<ContextType>;
  ListRecurringChargesResponse?: ListRecurringChargesResponseResolvers<ContextType>;
  ListTransactionsResponse?: ListTransactionsResponseResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationUpsertResponseDTO?: LocationUpsertResponseDtoResolvers<ContextType>;
  Markup?: MarkupResolvers<ContextType>;
  Me?: MeResolvers<ContextType>;
  MeMutation?: MeMutationResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  MediaContentType?: GraphQLScalarType;
  MediaPagination?: MediaPaginationResolvers<ContextType>;
  MediaWithOriginalExtension?: MediaWithOriginalExtensionResolvers<ContextType>;
  Metatag?: MetatagResolvers<ContextType>;
  MetatagFilter?: MetatagFilterResolvers<ContextType>;
  MetatagPagination?: MetatagPaginationResolvers<ContextType>;
  MetatagUse?: MetatagUseResolvers<ContextType>;
  MetatagValue?: MetatagValueResolvers<ContextType>;
  MeterReadingSetting?: MeterReadingSettingResolvers<ContextType>;
  MeterReadingValue?: MeterReadingValueResolvers<ContextType>;
  MeterReadingValueForExport?: MeterReadingValueForExportResolvers<ContextType>;
  MeterReadingValueResponseType?: MeterReadingValueResponseTypeResolvers<ContextType>;
  MeterReadingValueSearchResults?: MeterReadingValueSearchResultsResolvers<ContextType>;
  MobileAccess?: MobileAccessResolvers<ContextType>;
  MobileAccessChannel?: MobileAccessChannelResolvers<ContextType>;
  MultiChannelAcgs?: MultiChannelAcgsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OAuth?: OAuthResolvers<ContextType>;
  OAuthConfig?: OAuthConfigResolvers<ContextType>;
  ObjectAnalytics?: ObjectAnalyticsResolvers<ContextType>;
  ObjectCount?: ObjectCountResolvers<ContextType>;
  ObjectDatasetRecord?: ObjectDatasetRecordResolvers<ContextType>;
  ObjectMetatag?: ObjectMetatagResolvers<ContextType>;
  ObjectMetatagValue?: ObjectMetatagValueResolvers<ContextType>;
  OidcServiceProviderDefinition?: OidcServiceProviderDefinitionResolvers<ContextType>;
  OneTimePassword?: OneTimePasswordResolvers<ContextType>;
  OnlinePlanQuote?: OnlinePlanQuoteResolvers<ContextType>;
  OpenpathSetupMobileToken?: OpenpathSetupMobileTokenResolvers<ContextType>;
  OrderItemDetails?: OrderItemDetailsResolvers<ContextType>;
  OrderSummary?: OrderSummaryResolvers<ContextType>;
  Owner?: OwnerResolvers<ContextType>;
  PMSchedule?: PmScheduleResolvers<ContextType>;
  PMSchedulesForExportResponseDTO?: PmSchedulesForExportResponseDtoResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PagesOnChannel?: PagesOnChannelResolvers<ContextType>;
  PaginatedSections?: PaginatedSectionsResolvers<ContextType>;
  ParkWhizAmenitiy?: ParkWhizAmenitiyResolvers<ContextType>;
  ParkWhizBookPreview?: ParkWhizBookPreviewResolvers<ContextType>;
  ParkWhizBooking?: ParkWhizBookingResolvers<ContextType>;
  ParkWhizBookingEmbedded?: ParkWhizBookingEmbeddedResolvers<ContextType>;
  ParkWhizByGeoLocation?: ParkWhizByGeoLocationResolvers<ContextType>;
  ParkWhizCoordinate?: ParkWhizCoordinateResolvers<ContextType>;
  ParkWhizDistance?: ParkWhizDistanceResolvers<ContextType>;
  ParkWhizIcon?: ParkWhizIconResolvers<ContextType>;
  ParkWhizLocation?: ParkWhizLocationResolvers<ContextType>;
  ParkWhizParkingPass?: ParkWhizParkingPassResolvers<ContextType>;
  ParkWhizPhoto?: ParkWhizPhotoResolvers<ContextType>;
  ParkWhizPhotoSizes?: ParkWhizPhotoSizesResolvers<ContextType>;
  ParkWhizPhotos?: ParkWhizPhotosResolvers<ContextType>;
  ParkWhizPricingSegments?: ParkWhizPricingSegmentsResolvers<ContextType>;
  ParkWhizPurchaseOption?: ParkWhizPurchaseOptionResolvers<ContextType>;
  ParkWhizScanCode?: ParkWhizScanCodeResolvers<ContextType>;
  ParkWhizSpaceAvailability?: ParkWhizSpaceAvailabilityResolvers<ContextType>;
  ParkWhizStraightLine?: ParkWhizStraightLineResolvers<ContextType>;
  ParkWhizValidation?: ParkWhizValidationResolvers<ContextType>;
  ParkWhizValidationStep?: ParkWhizValidationStepResolvers<ContextType>;
  ParkWhizVehicle?: ParkWhizVehicleResolvers<ContextType>;
  PastVisitorPass?: PastVisitorPassResolvers<ContextType>;
  PastVisitorPassResult?: PastVisitorPassResultResolvers<ContextType>;
  Path?: GraphQLScalarType;
  Payer?: PayerResolvers<ContextType>;
  PaymentAccount?: PaymentAccountResolvers<ContextType>;
  PaymentAccountRule?: PaymentAccountRuleResolvers<ContextType>;
  PaymentFeatureLineItem?: PaymentFeatureLineItemResolvers<ContextType>;
  PaymentFeatureQuote?: PaymentFeatureQuoteResolvers<ContextType>;
  PaymentProcessor?: PaymentProcessorResolvers<ContextType>;
  PaymentSource?: PaymentSourceResolvers<ContextType>;
  Payments?: PaymentsResolvers<ContextType>;
  PaymentsMutation?: PaymentsMutationResolvers<ContextType>;
  Permission?: GraphQLScalarType;
  PermissionDetail?: PermissionDetailResolvers<ContextType>;
  Person?: PersonResolvers<ContextType>;
  PhoneNumber?: GraphQLScalarType;
  PlacePayInfo?: PlacePayInfoResolvers<ContextType>;
  Portal?: PortalResolvers<ContextType>;
  PostalCode?: GraphQLScalarType;
  Price?: PriceResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductCategory?: ProductCategoryResolvers<ContextType>;
  ProductFinalPrice?: ProductFinalPriceResolvers<ContextType>;
  ProductItem?: ProductItemResolvers<ContextType>;
  ProductLineItem?: ProductLineItemResolvers<ContextType>;
  ProductTax?: ProductTaxResolvers<ContextType>;
  Profile?: ProfileResolvers<ContextType>;
  Property?: PropertyResolvers<ContextType>;
  ProxyClickCompany?: ProxyClickCompanyResolvers<ContextType>;
  ProxyClickHost?: ProxyClickHostResolvers<ContextType>;
  ProxyClickMeeting?: ProxyClickMeetingResolvers<ContextType>;
  ProxyClickVisit?: ProxyClickVisitResolvers<ContextType>;
  ProxyClickVisitPagination?: ProxyClickVisitPaginationResolvers<ContextType>;
  ProxyClickVisitStatus?: ProxyClickVisitStatusResolvers<ContextType>;
  ProxyClickVisitor?: ProxyClickVisitorResolvers<ContextType>;
  PublicProfile?: PublicProfileResolvers<ContextType>;
  PublicTeam?: PublicTeamResolvers<ContextType>;
  PublicUser?: PublicUserResolvers<ContextType>;
  PublicUserGroupRole?: PublicUserGroupRoleResolvers<ContextType>;
  PublicUserPagination?: PublicUserPaginationResolvers<ContextType>;
  QRCode?: QrCodeResolvers<ContextType>;
  QuantityWaitlistByContent?: QuantityWaitlistByContentResolvers<ContextType>;
  QuantityWaitlistCompany?: QuantityWaitlistCompanyResolvers<ContextType>;
  QuantityWaitlistPagination?: QuantityWaitlistPaginationResolvers<ContextType>;
  QuantityWaitlistUser?: QuantityWaitlistUserResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Receivable?: ReceivableResolvers<ContextType>;
  Recurrence?: RecurrenceResolvers<ContextType>;
  RecurringCharge?: RecurringChargeResolvers<ContextType>;
  ReformedChannel?: ReformedChannelResolvers<ContextType>;
  RefundTransactionResponse?: RefundTransactionResponseResolvers<ContextType>;
  RegisterKioskVisitorPassScanResponse?: RegisterKioskVisitorPassScanResponseResolvers<ContextType>;
  RelayAccessPoint?: RelayAccessPointResolvers<ContextType>;
  RelayModule?: RelayModuleResolvers<ContextType>;
  RelayOutput?: RelayOutputResolvers<ContextType>;
  RemovePaymentMethodResponse?: RemovePaymentMethodResponseResolvers<ContextType>;
  Requirement?: GraphQLScalarType;
  RequirementDetail?: RequirementDetailResolvers<ContextType>;
  RequirementFeatureContentInteractionProperties?: RequirementFeatureContentInteractionPropertiesResolvers<ContextType>;
  RequirementFeatureContentInteractionPropertiesId?: RequirementFeatureContentInteractionPropertiesIdResolvers<ContextType>;
  RequirementsFeatureProperties?: RequirementsFeaturePropertiesResolvers<ContextType>;
  RequirementsSource?: RequirementsSourceResolvers<ContextType>;
  Reservable?: ReservableResolvers<ContextType>;
  ReservableAvailability?: ReservableAvailabilityResolvers<ContextType>;
  ReservableBooking?: ReservableBookingResolvers<ContextType>;
  ReservableBookingPagination?: ReservableBookingPaginationResolvers<ContextType>;
  ReservableConnection?: ReservableConnectionResolvers<ContextType>;
  ReservableEdge?: ReservableEdgeResolvers<ContextType>;
  ReservableWaitlistByContent?: ReservableWaitlistByContentResolvers<ContextType>;
  ReservableWaitlistCompany?: ReservableWaitlistCompanyResolvers<ContextType>;
  ReservableWaitlistEnrollment?: ReservableWaitlistEnrollmentResolvers<ContextType>;
  ReservableWaitlistPagination?: ReservableWaitlistPaginationResolvers<ContextType>;
  ReservableWaitlistUnrollment?: ReservableWaitlistUnrollmentResolvers<ContextType>;
  ReservableWaitlistUser?: ReservableWaitlistUserResolvers<ContextType>;
  Resident?: ResidentResolvers<ContextType>;
  SAMLData?: SamlDataResolvers<ContextType>;
  SAMLServiceProviderDefinition?: SamlServiceProviderDefinitionResolvers<ContextType>;
  SaveConfigResponse?: SaveConfigResponseResolvers<ContextType>;
  SavePaymentMethodResponse?: SavePaymentMethodResponseResolvers<ContextType>;
  ScheduledVisitorPass?: ScheduledVisitorPassResolvers<ContextType>;
  ScheduledVisitorPassResult?: ScheduledVisitorPassResultResolvers<ContextType>;
  SearchOptionFilter?: SearchOptionFilterResolvers<ContextType>;
  SearchOptions?: SearchOptionsResolvers<ContextType>;
  SearchTasksReponseDTO?: SearchTasksReponseDtoResolvers<ContextType>;
  Section?: SectionResolvers<ContextType>;
  SectionContent?: SectionContentResolvers<ContextType>;
  SellingPrice?: SellingPriceResolvers<ContextType>;
  ServiceRequest?: ServiceRequestResolvers<ContextType>;
  ServiceRequestAction?: ServiceRequestActionResolvers<ContextType>;
  ServiceRequestAuditLog?: ServiceRequestAuditLogResolvers<ContextType>;
  ServiceRequestCategoriesList?: ServiceRequestCategoriesListResolvers<ContextType>;
  ServiceRequestFeatures?: ServiceRequestFeaturesResolvers<ContextType>;
  ServiceRequestIssuesList?: ServiceRequestIssuesListResolvers<ContextType>;
  ServiceRequestLocationsList?: ServiceRequestLocationsListResolvers<ContextType>;
  ServiceRequestSearchResults?: ServiceRequestSearchResultsResolvers<ContextType>;
  ServiceRequestUpdateResponseDTO?: ServiceRequestUpdateResponseDtoResolvers<ContextType>;
  ServiceRequestsFilterFieldsDTO?: ServiceRequestsFilterFieldsDtoResolvers<ContextType>;
  ServiceRequestsList?: ServiceRequestsListResolvers<ContextType>;
  Session?: SessionResolvers<ContextType>;
  ShopifyCustomer?: ShopifyCustomerResolvers<ContextType>;
  ShopifyMultipassLoginDetail?: ShopifyMultipassLoginDetailResolvers<ContextType>;
  SourceEntityIdentifier?: SourceEntityIdentifierResolvers<ContextType>;
  SpecialInvite?: SpecialInviteResolvers<ContextType>;
  StartImportJobResponse?: StartImportJobResponseResolvers<ContextType>;
  StepExecution?: StepExecutionResolvers<ContextType>;
  StepExecutionOption?: StepExecutionOptionResolvers<ContextType>;
  StepTemplate?: StepTemplateResolvers<ContextType>;
  StepTemplateOption?: StepTemplateOptionResolvers<ContextType>;
  StripeAccount?: StripeAccountResolvers<ContextType>;
  StripeAccountLink?: StripeAccountLinkResolvers<ContextType>;
  StripeAccountVerification?: StripeAccountVerificationResolvers<ContextType>;
  StripeAddress?: StripeAddressResolvers<ContextType>;
  StripeBankAccount?: StripeBankAccountResolvers<ContextType>;
  StripeDate?: StripeDateResolvers<ContextType>;
  StripeDeclineCharge?: StripeDeclineChargeResolvers<ContextType>;
  StripeInfo?: StripeInfoResolvers<ContextType>;
  StripeLegalEntity?: StripeLegalEntityResolvers<ContextType>;
  StripePayoutSchedule?: StripePayoutScheduleResolvers<ContextType>;
  StripeVerification?: StripeVerificationResolvers<ContextType>;
  StudioGroupRoleName?: GraphQLScalarType;
  SubscriptionDetails?: SubscriptionDetailsResolvers<ContextType>;
  Suite?: SuiteResolvers<ContextType>;
  SupportedLocale?: GraphQLScalarType;
  SwitchChannel?: SwitchChannelResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  TargetingRule?: TargetingRuleResolvers<ContextType>;
  TargetingRulesForAudience?: TargetingRulesForAudienceResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  TaskAction?: TaskActionResolvers<ContextType>;
  TaskAuditLog?: TaskAuditLogResolvers<ContextType>;
  TaskEquipmentDTO?: TaskEquipmentDtoResolvers<ContextType>;
  TaskFilterOptionDTO?: TaskFilterOptionDtoResolvers<ContextType>;
  TaskRepeatDTO?: TaskRepeatDtoResolvers<ContextType>;
  TaskScheduleDTO?: TaskScheduleDtoResolvers<ContextType>;
  Tax?: TaxResolvers<ContextType>;
  Tenant?: TenantResolvers<ContextType>;
  TenantBuildingChannel?: TenantBuildingChannelResolvers<ContextType>;
  TenantLease?: TenantLeaseResolvers<ContextType>;
  Theme?: ThemeResolvers<ContextType>;
  ThemeColors?: ThemeColorsResolvers<ContextType>;
  ThemePalette?: ThemePaletteResolvers<ContextType>;
  Token?: TokenResolvers<ContextType>;
  Totals?: TotalsResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  TriggerAccessPointResponse?: TriggerAccessPointResponseResolvers<ContextType>;
  TypographicDesignSystem?: TypographicDesignSystemResolvers<ContextType>;
  TypographyElement?: TypographyElementResolvers<ContextType>;
  TypographyLayoutElement?: TypographyLayoutElementResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UUID?: GraphQLScalarType;
  Unit?: UnitResolvers<ContextType>;
  UpdateAudienceResponse?: UpdateAudienceResponseResolvers<ContextType>;
  UpdateHardwareDeviceInfoResponse?: UpdateHardwareDeviceInfoResponseResolvers<ContextType>;
  UpdateInvoiceAutopaymentDetailsResponse?: UpdateInvoiceAutopaymentDetailsResponseResolvers<ContextType>;
  UpdateInvoiceToPaidResponse?: UpdateInvoiceToPaidResponseResolvers<ContextType>;
  UpdatePMScheduleResponseDTO?: UpdatePmScheduleResponseDtoResolvers<ContextType>;
  UpdateProductExceptionResponse?: UpdateProductExceptionResponseResolvers<ContextType>;
  UpdateProductResponse?: UpdateProductResponseResolvers<ContextType>;
  UpdateRecurrencePassesResponse?: UpdateRecurrencePassesResponseResolvers<ContextType>;
  UpdateTargetingRuleResponse?: UpdateTargetingRuleResponseResolvers<ContextType>;
  UpdateTaskResponseDTO?: UpdateTaskResponseDtoResolvers<ContextType>;
  UploadCSVResponse?: UploadCsvResponseResolvers<ContextType>;
  UpsertSubscriptionDetailsResponse?: UpsertSubscriptionDetailsResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAccessCompany?: UserAccessCompanyResolvers<ContextType>;
  UserAccessDetails?: UserAccessDetailsResolvers<ContextType>;
  UserBooking?: UserBookingResolvers<ContextType>;
  UserBuildingAccess?: UserBuildingAccessResolvers<ContextType>;
  UserBuildingAccessMapping?: UserBuildingAccessMappingResolvers<ContextType>;
  UserChannelSettings?: UserChannelSettingsResolvers<ContextType>;
  UserCompanyDetails?: UserCompanyDetailsResolvers<ContextType>;
  UserContentInteraction?: UserContentInteractionResolvers<ContextType>;
  UserContentInteractionHistory?: UserContentInteractionHistoryResolvers<ContextType>;
  UserContentInteractionHistoryPagination?: UserContentInteractionHistoryPaginationResolvers<ContextType>;
  UserContentInteractionPagination?: UserContentInteractionPaginationResolvers<ContextType>;
  UserContentInteractionStatus?: GraphQLScalarType;
  UserEmail?: UserEmailResolvers<ContextType>;
  UserFilters?: UserFiltersResolvers<ContextType>;
  UserGroupRole?: UserGroupRoleResolvers<ContextType>;
  UserGroupRolePagination?: UserGroupRolePaginationResolvers<ContextType>;
  UserGroupRoleRelatedChannel?: UserGroupRoleRelatedChannelResolvers<ContextType>;
  UserIntegration?: UserIntegrationResolvers<ContextType>;
  UserLogin?: UserLoginResolvers<ContextType>;
  UserLoginMethod?: UserLoginMethodResolvers<ContextType>;
  UserObjectInfo?: UserObjectInfoResolvers<ContextType>;
  UserPagination?: UserPaginationResolvers<ContextType>;
  UserSettings?: UserSettingsResolvers<ContextType>;
  Visitor?: VisitorResolvers<ContextType>;
  VisitorEmailTemplate?: VisitorEmailTemplateResolvers<ContextType>;
  VisitorLog?: VisitorLogResolvers<ContextType>;
  VisitorOrganizationSettings?: VisitorOrganizationSettingsResolvers<ContextType>;
  VisitorPass?: VisitorPassResolvers<ContextType>;
  VisitorPassAggregatesBySubmissionId?: VisitorPassAggregatesBySubmissionIdResolvers<ContextType>;
  VisitorPassHost?: VisitorPassHostResolvers<ContextType>;
  VisitorPassHostProfile?: VisitorPassHostProfileResolvers<ContextType>;
  VisitorPassRecurrence?: VisitorPassRecurrenceResolvers<ContextType>;
  VisitorPassTenant?: VisitorPassTenantResolvers<ContextType>;
  VisitorPassTenantAddress?: VisitorPassTenantAddressResolvers<ContextType>;
  VisitorPassTenantProfile?: VisitorPassTenantProfileResolvers<ContextType>;
  VisitorPassUpdateResponse?: VisitorPassUpdateResponseResolvers<ContextType>;
  Void?: GraphQLScalarType;
  VoidChargeResponse?: VoidChargeResponseResolvers<ContextType>;
  VtsAddress?: VtsAddressResolvers<ContextType>;
  WaitlistUnEnrollment?: WaitlistUnEnrollmentResolvers<ContextType>;
  Weather?: WeatherResolvers<ContextType>;
  WeatherAlert?: WeatherAlertResolvers<ContextType>;
  WeatherDataPoint?: WeatherDataPointResolvers<ContextType>;
  WeatherFlags?: WeatherFlagsResolvers<ContextType>;
  Webhook?: WebhookResolvers<ContextType>;
  Whitelabel?: WhitelabelResolvers<ContextType>;
  WhitelabelChannel?: WhitelabelChannelResolvers<ContextType>;
  Workflow?: WorkflowResolvers<ContextType>;
  enableMobileAccessResponse?: EnableMobileAccessResponseResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  admin?: AdminDirectiveResolver<any, any, ContextType>;
  authenticated?: AuthenticatedDirectiveResolver<any, any, ContextType>;
  combine?: CombineDirectiveResolver<any, any, ContextType>;
  dbView?: DbViewDirectiveResolver<any, any, ContextType>;
  geoDistance?: GeoDistanceDirectiveResolver<any, any, ContextType>;
  hardwareAuthenticated?: HardwareAuthenticatedDirectiveResolver<any, any, ContextType>;
  json?: JsonDirectiveResolver<any, any, ContextType>;
  key?: KeyDirectiveResolver<any, any, ContextType>;
  me?: MeDirectiveResolver<any, any, ContextType>;
  private?: PrivateDirectiveResolver<any, any, ContextType>;
  rel?: RelDirectiveResolver<any, any, ContextType>;
  tableName?: TableNameDirectiveResolver<any, any, ContextType>;
  unique?: UniqueDirectiveResolver<any, any, ContextType>;
  virtual?: VirtualDirectiveResolver<any, any, ContextType>;
};
