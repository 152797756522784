import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class InviteInvalidEmailError extends CodedError {
  static get errorName() {
    return errorCodes.inviteInvalidEmailError.code;
  }

  constructor(message = errorCodes.inviteInvalidEmailError.message) {
    super(message);
    this.code = errorCodes.inviteInvalidEmailError.code;
    this.message = `${message} ${errorCodes.inviteInvalidEmailError.code}`;
    this.name = errorCodes.inviteInvalidEmailError.name;
  }
}
