import React from 'react';

import { Icon } from '../Icon';
import { Button } from '../Button/Button';

export type ImportAction<TDataShape = any> = {
  label: string;
  onClick: (data?: TDataShape[]) => void;
};

type Props<TDataShape> = {
  importAction: ImportAction<TDataShape>;
  data?: TDataShape[];
};

export const ImportButton = <TDataShape,>({
  importAction,
  data,
}: Props<TDataShape>) => {
  return (
    <Button
      variant="text"
      onClick={() =>
        data ? importAction.onClick(data) : importAction.onClick()
      }
      size="large"
      startIcon={<Icon name="upload" />}
    >
      {importAction.label}
    </Button>
  );
};

export default ImportButton;
