import React from 'react';

import cx from 'classnames';
import { Button } from 'components';
import { DayPicker, Matcher } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import {
  getToday,
  fromLocalDate,
  toLocalDate,
} from 'lane-shared/domains/visitorManagement/helpers';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { dateRangesToMatcher, formatters, weekends } from '../helpers/helpers';
import { classNames } from './CalendarClassNames';
import CalendarDay from './CalendarDay';
import CalendarHeadAdvanced from './CalendarHeadAdvanced';
import 'react-day-picker/dist/style.css';

import calendarStyles from './Calendar.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  selected?: Date;
  onChange: (date: Date | undefined) => void;
  onSubmit?: (() => void) | null;
  onFocusChange?: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
  unavailableDateRanges?: DateRangeType[];
  weekdayOnly?: boolean;
  placeholder?: string;
  submitLabel?: string;
  testId?: string;
  timeZone?: string;
};

export default function DateCalendar({
  className,
  style,
  loading,
  disabled,
  onChange,
  selected,
  onSubmit,
  onFocusChange,
  maxDate,
  minDate,
  unavailableDateRanges,
  weekdayOnly,
  submitLabel,
  testId,
  timeZone,
}: Props) {
  const { t } = useTranslation();

  const disabledDates = unavailableDateRanges
    ? dateRangesToMatcher(unavailableDateRanges)
    : [];

  if (weekdayOnly) {
    disabledDates.push(weekends);
  }

  const today = getToday(timeZone);

  function handleSelect(date?: Date) {
    onChange(fromLocalDate(date, timeZone));
  }

  const localSelected = toLocalDate(selected, timeZone);

  return (
    <div
      data-test={testId}
      className={cx(calendarStyles.datePicker, className)}
      style={style}
    >
      <DayPicker
        className={disabled ? calendarStyles.disabled : ''}
        classNames={classNames}
        mode="single"
        components={{
          Caption: CalendarHeadAdvanced,
          Day: CalendarDay,
        }}
        required
        disabled={disabledDates}
        fromDate={toLocalDate(minDate, timeZone)}
        toDate={toLocalDate(maxDate, timeZone)}
        defaultMonth={localSelected}
        selected={localSelected}
        modifiers={{ selected: localSelected as Matcher }}
        modifiersClassNames={{ selected: calendarStyles.selected }}
        onSelect={date => handleSelect(date)}
        onDayFocus={onFocusChange}
        formatters={formatters}
        today={today}
      />
      {onSubmit && (
        <div
          className={cx(calendarStyles.calendarSubmit, calendarStyles.single)}
        >
          <Button
            className={calendarStyles.button}
            testId="submitDate"
            loading={loading}
            disabled={disabled || !selected}
            onClick={onSubmit}
            variant="contained"
          >
            {!submitLabel
              ? t('web.content.feature.visitorManagement.form.setDate')
              : submitLabel}
          </Button>
        </div>
      )}
    </div>
  );
}
