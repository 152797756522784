import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ContentWorkflowPayloadType } from 'lane-shared/types/ContentWorkflowType';
import styles from './EmailBuilderV2Editor.scss';
import { H5, M } from 'design-system-web';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { EmailEditableEnum, TextOptionEnum } from '../helpers/constants';
import { TextEditor } from './TextEditor';
import { WorkflowNotification } from '../WorkflowNotification';
import { ValidationError } from 'yup';
import { Workflow } from 'lane-shared/types/workflow';

type Props = {
  className?: string;
  style: React.CSSProperties;
  workflow: Workflow;
  channel: ChannelType;
  emailEditable: EmailEditableEnum;
  validation: ValidationError | null;
  draftPayloadForEmailSettings: ContentWorkflowPayloadType;
  updateDraftPayloadForEmailSettings: (
    updates: Partial<ContentWorkflowPayloadType>
  ) => void;
  contentName: string;
};

function getSelectedOption({
  emailEditable,
  draftPayloadForEmailSettings,
}: {
  emailEditable: EmailEditableEnum;
  draftPayloadForEmailSettings: ContentWorkflowPayloadType;
}) {
  const emailFieldInPayload =
    (emailEditable as keyof ContentWorkflowPayloadType) in
    draftPayloadForEmailSettings;

  if (
    [
      EmailEditableEnum.Body,
      EmailEditableEnum.Intro,
      EmailEditableEnum.Subject,
    ].includes(emailEditable) &&
    emailFieldInPayload
  ) {
    return TextOptionEnum.Custom;
  }
  if (emailEditable === EmailEditableEnum.Outro) {
    return TextOptionEnum.Custom;
  }
  return TextOptionEnum.Standard;
}

export function EmailBuilderV2Editor({
  className,
  style,
  emailEditable,
  channel,
  workflow,
  validation,
  draftPayloadForEmailSettings,
  updateDraftPayloadForEmailSettings,
  contentName,
}: Props) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<TextOptionEnum>(
    TextOptionEnum.Standard
  );

  useEffect(() => {
    const initialOption = getSelectedOption({
      emailEditable,
      draftPayloadForEmailSettings,
    });

    setSelectedOption(initialOption);
  }, [emailEditable, Object.keys(draftPayloadForEmailSettings).length]);

  if (!workflow.payload) {
    workflow.payload = {};
  }

  return (
    <>
      {emailEditable === EmailEditableEnum.None && (
        <div
          className={cx(styles.placeholderContainer, className)}
          style={style}
        >
          <H5 className={cx(styles.customizationPanelText, className)}>
            {t(
              'web.admin.channel.content.workflow.editor.emailSettings.customizationPanel'
            )}
          </H5>
          <M
            className={cx(styles.customizationPanelPlaceholderText, className)}
            style={style}
          >
            {t(
              'web.admin.channel.content.workflow.editor.emailSettings.customizationPanelPlaceholderText'
            )}
          </M>
        </div>
      )}

      {emailEditable === EmailEditableEnum.Subject && (
        <div
          data-test="subjectEditorContainer"
          className={cx(styles.subjectEditorContainer, className)}
        >
          <WorkflowNotification
            isEditing
            workflowAction={workflow.action}
            value={draftPayloadForEmailSettings?.text}
            textOptionType={selectedOption}
            onChangeTextOptionType={(type: TextOptionEnum) => {
              setSelectedOption(type);
            }}
            validation={validation}
            payload={draftPayloadForEmailSettings}
            onTextUpdated={(textUpdates: any) => {
              updateDraftPayloadForEmailSettings(textUpdates);
            }}
            workflow={workflow}
            contentName={contentName}
          />
        </div>
      )}

      {[
        EmailEditableEnum.Body,
        EmailEditableEnum.Intro,
        EmailEditableEnum.Outro,
      ].includes(emailEditable) && (
        <TextEditor
          className={className}
          style={style}
          channel={channel}
          selectedOption={selectedOption}
          t={t}
          setSelectedOption={setSelectedOption}
          keyColumn={
            emailEditable as
              | EmailEditableEnum.Body
              | EmailEditableEnum.Intro
              | EmailEditableEnum.Outro
          }
          draftPayloadForEmailSettings={draftPayloadForEmailSettings}
          updateDraftPayloadForEmailSettings={
            updateDraftPayloadForEmailSettings
          }
        />
      )}
    </>
  );
}
