import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { DateRangePickerButton } from './DateRangePickerButton';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../index';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { TimePicker } from './TimePicker';
import { handleTimeClick } from './helpers';

import styles from './DatesTimeRangePicker.scss';

type DatesTimeRangePickerProps = {
  value?: DateRangeType;
  timeZone?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange?: (range: DateRangeType) => void;
  unavailableDateRanges?: DateRangeType[];
  className?: string;
  unit?: number;
  style?: React.CSSProperties;
};

export const DatesTimeRangePicker = ({
  value,
  timeZone,
  disabled = false,
  onChange = () => null,
  unavailableDateRanges,
  className,
  style,
  unit,
}: DatesTimeRangePickerProps) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<DateRangeType>();
  const [time, setTime] = useState<DateRangeType>();

  useEffect(() => {
    if (date?.startDate && date?.endDate) {
      onChange(date);
    }
  }, [date?.startDate, date?.endDate]);

  function onHandleClickTime(timeValue: any, timeUnit: any) {
    handleTimeClick(
      timeValue,
      timeUnit === 'startTime' ? 'startDate' : 'endDate',
      setDate,
      value,
      date,
      timeZone
    );
    setTime(prev => ({ ...prev, [timeUnit]: timeValue }));
  }

  return (
    <div className={cx(styles.DatesTimeRangePicker, className)} style={style}>
      <DateRangePickerButton
        className={styles.DatesTimeRangePickerButton}
        disabled={disabled}
        includeTime
        unavailableDateRanges={unavailableDateRanges}
        startDate={date?.startDate || undefined}
        endDate={date?.endDate || undefined}
        onChange={(dateRange: any) =>
          setDate(prev => ({ ...prev, ...dateRange }))
        }
        timeZone={timeZone}
      />
      <Flex gap={2} style={{ marginTop: '1rem' }}>
        <TimePicker
          className={styles.timePicker}
          timeZone={timeZone}
          value={time?.startTime}
          label={t('Start time')}
          unit={unit}
          onChange={startTime => onHandleClickTime(startTime, 'startTime')}
        />
        <TimePicker
          className={styles.timePicker}
          value={time?.endTime}
          timeZone={timeZone}
          label={t('End Time')}
          unit={unit}
          onChange={endTime => onHandleClickTime(endTime, 'endTime')}
        />
      </Flex>
    </div>
  );
};
