import React from 'react';

import Checkbox from '../Checkbox';

type OwnCheckboxGroupProps = {
  /** function to return provided value and name on select */
  onChange: (selected: any[], name: string) => void;
  /** dictionary to tell what fields from choices should be used for unique value and text */
  schema: {
    id: string | number;
    text: string;
  };
  /** list of possible choices as objects (from api) */
  items: any[];
  /** mutual name for elements to group them */
  name: string;
  /** array of selected values */
  selected: any[];
  /** flag to mark input element as disabled */
  disabled: boolean;
  className?: string;
  style?: React.CSSProperties;
};

type CheckboxGroupProps = OwnCheckboxGroupProps;

function CheckboxGroup({
  className,
  style,
  schema = {
    id: 'id',
    text: 'text',
  },
  items,
  name,
  onChange,
  selected = [],
  disabled = false,
}: CheckboxGroupProps) {
  function updateSelected(value: any) {
    onChange(
      selected?.includes(value)
        ? selected.filter(item => item !== value)
        : [...selected, value],
      name
    );
  }

  return (
    <>
      {items.map(item => (
        <Checkbox
          className={className}
          style={style}
          key={item[schema.id]}
          disabled={disabled}
          selected={selected?.some(id => id === item[schema.id])}
          onChange={updateSelected}
          name={name}
          value={item[schema.id]}
          text={item[schema.text]}
          description={item?.description}
        />
      ))}
    </>
  );
}

export default CheckboxGroup;
