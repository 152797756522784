import gql from 'graphql-tag';

export default gql(`
  fragment ChannelMerchantFragment on ChannelMerchant {
    _id
    channel {
      _id
    }
    account
    percentageFee
    flatFee
    status
    onboardingLink {
      expires_at
      url
    }
    stripeAccount {
      id
      type
      country
      details_submitted
      timezone
    }
  }
`);
