import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from 'design-system-web';

import Button from '../../general/Button';
import IconButton from '../../general/IconButton';
import Stepper from '../../general/Stepper';

import styles from './DraftContentHeader.scss';
import { useDraftContentAnalytics } from 'lane-shared/hooks/analytics';
import type { DraftContentType } from 'lane-shared/types/content/DraftContent';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

type Props = {
  extendedOptions?: React.ReactNode;
  loading?: boolean;
  hasChanged?: boolean;
  onDeleteClicked?: () => void;
  onUndoClicked?: () => void;
  onSaveClicked: (() => void) | null;
  onCreateClicked?: () => void;
  onPublishClicked?: () => void;
  onUnpublishClicked?: () => void;
  onCancelClicked: () => void;
  onGoStep: ({ step }: { step: string }) => void;
  steps: string[];
  currentStep: string;
  errorSteps?: number[];
  disableSaveAndNext?: boolean;
  content?: DraftContentType;
  forCreate?: boolean;
  forEdit?: boolean;
  interactionsCount?: number;
  liveNotificationsCount?: number;
};

const TRANSLATION_KEYS = {
  back: 'web.admin.content.draftContent.header.button.label.back',
  cancel: 'web.admin.content.draftContent.header.button.label.cancel',
  delete: 'web.admin.content.draftContent.header.button.label.delete',
  unpublish: 'web.admin.content.draftContent.header.button.label.unpublish',
  undo: 'web.admin.content.draftContent.header.button.label.undo',
  save: 'web.admin.content.draftContent.header.button.label.save',
  next: 'web.admin.content.draftContent.header.button.label.next',
  publish: 'web.admin.content.draftContent.header.button.label.publish',
  create: 'web.admin.content.draftContent.header.button.label.create',
  tooltip: 'web.admin.content.draftContent.header.button.delete.tooltip.text',
};

export default function DraftContentHeader({
  extendedOptions,
  loading = false,
  hasChanged = false,
  onUndoClicked,
  onSaveClicked,
  onDeleteClicked,
  onCreateClicked,
  onPublishClicked,
  onUnpublishClicked,
  onCancelClicked,
  onGoStep,
  steps,
  errorSteps,
  currentStep,
  disableSaveAndNext = false,
  content,
  forEdit,
  interactionsCount,
  liveNotificationsCount,
}: Props) {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const { t } = useTranslation();
  const { draftContentTracker } = useDraftContentAnalytics({
    draftContent: content,
  });

  const currentStepIndex = steps.indexOf(currentStep);

  function onNextClicked() {
    if (currentStepIndex + 1 < steps.length) {
      onGoStep({ step: steps[currentStepIndex + 1] });
    }
  }

  function onBackClicked() {
    if (currentStepIndex > 0) {
      onGoStep({ step: steps[currentStepIndex - 1] });
    }
  }

  const hasBack = currentStepIndex > 0;
  const hasNext = currentStepIndex < steps.length - 1;

  const canDeleteContent =
    interactionsCount === 0 && liveNotificationsCount === 0;

  const deleteContentButton = onDeleteClicked && (
    <Button
      onClick={() => {
        onDeleteClicked();
        if (forEdit) {
          draftContentTracker.Update.Delete(content);
        } else {
          draftContentTracker.Create.Delete(content);
        }
      }}
      loading={loading}
      testId="buttonDelete"
      size="small"
      disabled={!canDeleteContent}
    >
      {t(TRANSLATION_KEYS.delete)}
      {!canDeleteContent && (
        <Icon name="info-circle" set={ICON_SET_FONTAWESOME} type="far" />
      )}
    </Button>
  );

  const deleteContentButtonWithTooltip = (
    <Tooltip placement="bottom" TooltipComponent={t(TRANSLATION_KEYS.tooltip)}>
      {deleteContentButton}
    </Tooltip>
  );

  return (
    <div className={styles.DraftContentHeader}>
      <div className={styles.left}>
        {hasBack ? (
          <Button
            startIcon={<Icon name="arrow-left" />}
            onClick={onBackClicked}
            className={styles.noBorder}
            testId="buttonBack"
            size="small"
          >
            {t(TRANSLATION_KEYS.back)}
          </Button>
        ) : (
          <Button
            startIcon={<Icon name="times" />}
            onClick={onCancelClicked}
            className={styles.noBorder}
            testId="buttonCancel"
            size="small"
          >
            {t(TRANSLATION_KEYS.cancel)}
          </Button>
        )}
      </div>

      <div className={styles.center}>
        <Stepper
          className={styles.stepper}
          steps={steps}
          errorSteps={errorSteps}
          active={steps.findIndex(step => step === currentStep)}
          onClick={step => onGoStep({ step })}
        />
      </div>

      <div className={styles.right}>
        {extendedOptions && (
          <IconButton
            testId="moreOptions"
            inverted
            selected={isMenuExpanded}
            icon="ellipsis-h"
            onClick={() => setIsMenuExpanded(!isMenuExpanded)}
          />
        )}

        {isMenuExpanded && extendedOptions}

        {canDeleteContent
          ? deleteContentButton
          : deleteContentButtonWithTooltip}

        {onUnpublishClicked && !content?.generator && (
          <Button
            className="btnUnPublish"
            onClick={() => {
              onUnpublishClicked();
              draftContentTracker.Update.Unpublish(content);
            }}
            loading={loading}
            testId="buttonUnpublish"
            size="small"
          >
            {t(TRANSLATION_KEYS.unpublish)}
          </Button>
        )}

        {onUndoClicked && (
          <Button
            disabled={!hasChanged}
            onClick={onUndoClicked}
            loading={loading}
            testId="buttonUndo"
            size="small"
          >
            {t(TRANSLATION_KEYS.undo)}
          </Button>
        )}

        {onSaveClicked && (
          <Button
            className="btnSave"
            disabled={!hasChanged || disableSaveAndNext}
            loading={loading}
            onClick={() => {
              onSaveClicked();
              if (forEdit) {
                draftContentTracker.Update.Save(content);
              } else {
                draftContentTracker.Create.Save(content);
              }
            }}
            variant="contained"
            testId="buttonSave"
            size="small"
          >
            {t(TRANSLATION_KEYS.save)}
          </Button>
        )}

        {hasNext && (
          <Button
            disabled={disableSaveAndNext}
            variant="outlined-light"
            onClick={onNextClicked}
            endIcon={<Icon name="arrow-right" />}
            testId="buttonNext"
            size="small"
          >
            {t(TRANSLATION_KEYS.next)}
          </Button>
        )}

        {!hasNext && onPublishClicked && !content?.generator && (
          <Button
            className="btnPublish"
            disabled={hasChanged}
            variant="contained"
            loading={loading}
            onClick={onPublishClicked}
            testId="buttonPublish"
            size="small"
          >
            {t(TRANSLATION_KEYS.publish)}
          </Button>
        )}

        {!hasNext && onCreateClicked && (
          <Button
            disabled={hasChanged}
            loading={loading}
            onClick={onCreateClicked}
            variant="contained"
            testId="buttonCreate"
            size="small"
          >
            {t(TRANSLATION_KEYS.create)}
          </Button>
        )}
      </div>
    </div>
  );
}
