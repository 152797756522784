import gql from 'graphql-tag';

export default gql`
  mutation CreateBluetoothInvitationCode(
    $channelId: UUID!
    $provider: String!
  ) {
    createBluetoothInvitationCode(channelId: $channelId, provider: $provider) {
      invitationCode
      expiryDate
    }
  }
`;
