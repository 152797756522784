import React, { useRef } from 'react';

import { createPortal } from 'react-dom';
import FocusLock, { MoveFocusInside } from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../IconButton/IconButton';
import { Thumbnail, ThumbnailProps } from '../Thumbnail/Thumbnail';
import { H4, S } from '../Typography/Typography';
import { useClickOutside } from 'lane-shared/hooks';
import { FONT_AWESOME_SOLID } from 'lane-shared/helpers/constants/icons';
import { PopupMenu } from 'design-system-web';
import styles from './style.scss';
import { ModalBackground } from '../Modal/ModalBackground';
import { MenuItemType } from 'design-system-web/components/PopupMenu/PopupMenu';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  header?: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  id?: string;
  headerActions?: React.ReactNode;
  footerActions?: React.ReactNode;
  thumbnail?: ThumbnailProps;
  zIndex?: number;
  closeOnClickOutside?: boolean;
  onBack?: () => void;
  size?: 'small' | 'medium' | 'large';
  backdropEnabled?: boolean;
  menuOptions?: MenuItemType[];
};

const cloneThroughFragments = (
  children: React.ReactNode,
  props: { zIndex: number }
): React.ReactNode =>
  React.Children.map(children, component => {
    if (React.isValidElement(component)) {
      if (component.type === React.Fragment) {
        return cloneThroughFragments(component.props.children, props);
      }
      return React.cloneElement(component, { ...component.props, ...props });
    }
    return component;
  });

const renderButtonGroup = (children: React.ReactNode, zIndex: number) => (
  <div className={styles.buttonGroup}>
    {cloneThroughFragments(children, { zIndex })}
  </div>
);

const sizeToWidth = {
  small: '400px',
  medium: '768px',
  large: '960px',
};

export const SidePanel = ({
  isOpen,
  onClose,
  header,
  description,
  children,
  id,
  headerActions,
  footerActions,
  zIndex = 20000,
  thumbnail,
  closeOnClickOutside = false,
  onBack,
  size = 'small',
  backdropEnabled = false,
  menuOptions,
}: Props) => {
  const sidePanelId = id ? `side-panel-${id}` : `side-panel`;
  const sidePanelHeaderId = `${sidePanelId}-header`;
  const sidePanelRef = useRef<HTMLDivElement>(null);

  useClickOutside(sidePanelRef, () => {
    if (closeOnClickOutside) {
      onClose?.();
    }
  });
  const { t } = useTranslation();
  const showBackIcon = !!onBack;
  const headerWithIconClass = showBackIcon ? styles.headerWithIcon : '';

  return isOpen
    ? createPortal(
        <>
          {backdropEnabled && (
            <ModalBackground
              isOpen={isOpen}
              hasOpaqueBackground
              onClose={
                closeOnClickOutside && onClose ? onClose : () => undefined
              }
            />
          )}
          <div
            role="dialog"
            ref={sidePanelRef}
            aria-labelledby={sidePanelHeaderId}
            aria-modal="true"
            id={sidePanelId}
            className={styles.panel}
            style={{ zIndex, maxWidth: sizeToWidth[size] }}
          >
            <FocusLock
              returnFocus
              whiteList={node =>
                document.getElementById(sidePanelId)?.contains(node) || false
              }
              className={styles.focusLock}
            >
              <MoveFocusInside className={styles.panelContents}>
                <header className={styles.header}>
                  <div className={styles.headerWrapper}>
                    <div>
                      {thumbnail && (
                        <Thumbnail
                          {...thumbnail}
                          className={styles.thumbnail}
                        />
                      )}
                      <div className={headerWithIconClass}>
                        {showBackIcon && (
                          <IconButton
                            icon="chevron-left"
                            type={FONT_AWESOME_SOLID}
                            className={styles.icon}
                            onClick={onBack}
                          />
                        )}
                        <H4 id={sidePanelHeaderId}>{header}</H4>
                      </div>
                      {description &&
                        (typeof description === 'string' ? (
                          <S mt={2}>{description}</S>
                        ) : (
                          description
                        ))}
                    </div>
                    {menuOptions && (
                      <PopupMenu
                        zIndex={zIndex + 3}
                        items={menuOptions}
                        placement="bottom-end"
                        trigger={
                          <IconButton
                            className={styles.popupMenu}
                            icon="ellipsis-v"
                          />
                        }
                      />
                    )}
                    <IconButton
                      icon="times"
                      onClick={onClose}
                      aria-label={t('web.components.sidePanel.close')}
                      className={styles.closeButton}
                    />
                  </div>
                  {headerActions && (
                    <div className={styles.headerActionsWrapper}>
                      {renderButtonGroup(headerActions, zIndex + 1)}
                    </div>
                  )}
                </header>
                <section className={styles.body}>{children}</section>
                {footerActions && (
                  <footer className={styles.footer}>
                    {renderButtonGroup(footerActions, zIndex + 1)}
                  </footer>
                )}
              </MoveFocusInside>
            </FocusLock>
          </div>
        </>,
        document.body
      )
    : null;
};
