import { v4 as uuid } from 'uuid';

import colors from '../../config/colors';
import {
  ThemeType,
  ThemeColorPaletteType,
  LayoutDesignSystemType,
  TypographicDesignSystemType,
} from '../../types/Theme';

export const defaultPalette: ThemeColorPaletteType = {
  _id: uuid(),
  primary: colors.primary,
  secondary: colors.secondary,
  tertiary: colors.tertiary,
  text: colors.text,
  background: colors.background,
  custom: [],
};

export const defaultLayout: LayoutDesignSystemType = {
  _id: uuid(),
  marginTop: '0',
  marginBottom: '0',
  marginRight: '0',
  marginLeft: '0',
  paddingTop: '1em',
  paddingRight: '1em',
  paddingBottom: '1em',
  paddingLeft: '1em',
};

export const defaultTypography: TypographicDesignSystemType = {
  _id: uuid(),
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontFamily: string; fontWeigh... Remove this comment to see the full error message
  base: {
    _id: uuid(),
    fontFamily: 'Inter',
    fontWeight: '400',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  h1: {
    _id: uuid(),
    fontSize: '200%',
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '1.2em',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  h2: {
    _id: uuid(),
    fontSize: '150%',
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '1.2em',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  h3: {
    _id: uuid(),
    fontSize: '120%',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '1.2em',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  h4: {
    _id: uuid(),
    fontSize: '110%',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '1.2em',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  h5: {
    _id: uuid(),
    fontSize: '100%',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '1.2em',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  h6: {
    _id: uuid(),
    fontSize: '90%',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '1.2em',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  p: {
    _id: uuid(),
    fontSize: '100%',
    fontFamily: 'Inter',
    lineHeight: '1.4em',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontSize: string; fontFamily:... Remove this comment to see the full error message
  label: {
    _id: uuid(),
    fontSize: '120%',
    fontFamily: 'Inter',
    lineHeight: '1.1em',
  },
  quote: {
    _id: uuid(),
    fontFamily: 'Inter',
    fontStyle: 'italic',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontFamily: string; fontWeigh... Remove this comment to see the full error message
  strong: {
    _id: uuid(),
    fontFamily: 'Inter',
    fontWeight: '600',
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; fontFamily: string; }' is not... Remove this comment to see the full error message
  emphasis: {
    _id: uuid(),
    fontFamily: 'Inter',
  },
};

const defaultTheme: Partial<ThemeType> = {
  _id: uuid(),
  typography: defaultTypography,
  palette: defaultPalette,
  layout: defaultLayout,
};

export default defaultTheme;
