import React, { useContext } from 'react';
import { S } from 'lane-web/src/components/typography';
import { useQuery } from '@apollo/client';
import { getChannelAccessControlGroups } from 'lane-shared/graphql/accessControl';
import { Label, Loading } from 'components';
import { UserAccessMultiselect } from 'lane-web/src/domains/accessControl/components/user-access/UserAccessMultiselect';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';
import { UserDataContext } from 'lane-shared/contexts';
import { getConsolidatedChannelUsers } from 'lane-shared/graphql/user';
import { convertToUserAccessGroupSelectItem } from '../../../../../domains/accessControl/helpers/convertToUserAccessGroupSelectItem';
import { AccessGroupSelectItem } from '../../../../../domains/accessControl/types/AccessControl';

type Props = {
  channelId: string;
  integrationChannelId?: string;
  channelName: string;
  required?: boolean;
  onChange: (selected: AccessGroupSelectItem[], channelId: string) => void;
};
export const AcgMultiSelect = ({
  channelId,
  integrationChannelId,
  channelName,
  required = true,
  onChange = () => {},
}: Props) => {
  const { t } = useTranslation();
  const currentUserContext = useContext(UserDataContext);
  integrationChannelId = integrationChannelId || channelId;

  const { data: acgList, loading: acgLoading } = useQuery(
    getChannelAccessControlGroups,
    {
      variables: { channelId: integrationChannelId },
      fetchPolicy: 'network-only',
    }
  );

  const { data: userList, loading: userLoading } = useQuery(
    getConsolidatedChannelUsers,
    {
      variables: {
        channelId: integrationChannelId,
        userIds: [currentUserContext?.user?._id],
      },
    }
  );

  const requiredText = '*';

  if (acgLoading || userLoading) {
    return <Loading />;
  }

  const handleChange = (acgItems: AccessGroupSelectItem[]) => {
    onChange(acgItems, integrationChannelId);
  };

  return (
    <div data-test="acg-multiselect-component">
      <Label className={styles.acgLabel}>
        {channelName} &nbsp;
        {required && <S className={styles.required}>{requiredText}</S>}
      </Label>

      <UserAccessMultiselect
        items={convertToUserAccessGroupSelectItem(
          acgList?.getChannelAccessControlGroups || [],
          userList?.getChannelUsers?.[0]?.accessControlGroupIds || [],
          currentUserContext?.user?.roles || [],
          channelId,
          [],
          integrationChannelId
        )}
        onChange={handleChange}
        values={[]}
        placeholder={t(
          'web.admin.accessControl.userAccess.modal.dropdown.placeholder'
        )}
        isFullWidth
      />
    </div>
  );
};
