import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import friendlyDistance, {
  DISTANCE_UNIT_MILE,
  DISTANCE_UNIT_KM,
  KM_TO_MILES_CONVERSATION_RATIO,
} from 'lane-shared/helpers/formatters/friendlyDistance';

import { H5 } from '../typography';
import Slider from './Slider';
import useUserLocale from 'hooks/useUserLocale';

import styles from './DistanceSlider.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  // value in meters.
  value: number;
  // min value in meters
  min: number;
  // max value in meters
  max: number;
  disabled?: boolean;
  loading?: boolean;
  units?: 'metric' | 'imperial';
  onChange: (value: number) => void;
};

type Props = OwnProps;

export default function DistanceSlider({
  className,
  style,
  value = 0,
  onChange = () => null,
  min = 0,
  max = 10000,
  units = 'imperial',
  disabled = false,
  loading = false,
}: Props) {
  const locale = useUserLocale();
  const { t } = useTranslation();

  function formatLabel(value: any) {
    if (units === 'imperial' || locale.includes('-US')) {
      return friendlyDistance(
        (value / 1000) * KM_TO_MILES_CONVERSATION_RATIO,
        DISTANCE_UNIT_MILE
      );
    }

    return friendlyDistance(value / 1000, DISTANCE_UNIT_KM);
  }

  return (
    <div className={cx(styles.DistanceSlider, className)} style={style}>
      {value < 1 ? (
        <H5>
          {t('web.admin.content.draftContent.target.channelModal.anyDistance')}
        </H5>
      ) : (
        <H5>
          {t(
            'web.admin.content.draftContent.target.channelModal.withinDistance',
            { distance: formatLabel(value) }
          )}
        </H5>
      )}
      <Slider
        className={styles.slider}
        disabled={disabled || loading}
        step={100}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '((value: number) => void) & (() => null)' is... Remove this comment to see the full error message
        onChange={onChange}
        formatLabel={formatLabel}
        min={min}
        max={max}
        value={value}
      />
    </div>
  );
}
