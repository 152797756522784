import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export class ProxyClickApiError extends CodedError {
  static get errorName() {
    return errorCodes.proxyClickApiError.code;
  }

  constructor(message = errorCodes.proxyClickApiError.message) {
    super(message);
    this.code = errorCodes.proxyClickApiError.code;
    this.message = `${message} ${errorCodes.proxyClickApiError.code}`;
    this.name = errorCodes.proxyClickApiError.name;
  }
}
