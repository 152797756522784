import { UnsupportedAttachmentTypeError } from 'activate-errors';
import {
  AttachmentDocumentContentTypeEnum,
  AttachmentImageContentTypeEnum,
  AttachmentTypeEnum,
} from 'lane-shared/types/attachment';

export const getAttachmentType = (type: string): AttachmentTypeEnum => {
  if (
    Object.values(AttachmentImageContentTypeEnum).includes(
      type as AttachmentImageContentTypeEnum
    )
  ) {
    return AttachmentTypeEnum.Image;
  }
  if (
    Object.values(AttachmentDocumentContentTypeEnum).includes(
      type as AttachmentDocumentContentTypeEnum
    )
  ) {
    return AttachmentTypeEnum.Document;
  }
  throw new UnsupportedAttachmentTypeError();
};
