import React from 'react';

import { MjmlSection, MjmlColumn } from '../MjmlComponentsV2';
import EmailText from './EmailText';

export default function EmailLabelWithValue({
  label,
  children,
  ...props
}: any) {
  return (
    <MjmlSection paddingTop={0} paddingBottom={0} {...props}>
      <MjmlColumn>
        <EmailText>
          {typeof label === 'string' ? <b>{label}</b> : label}
        </EmailText>
      </MjmlColumn>
      <MjmlColumn>
        <EmailText>{children}</EmailText>
      </MjmlColumn>
    </MjmlSection>
  );
}
