import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class HIDInvalidFieldError extends CodedError {
  static get errorName() {
    return errorCodes.HIDInvalidFieldError.code;
  }

  constructor(message = errorCodes.HIDInvalidFieldError.message) {
    super(message);
    this.code = errorCodes.HIDInvalidFieldError.code;
    this.message = `${message} ${errorCodes.HIDInvalidFieldError.code}`;
    this.name = errorCodes.HIDInvalidFieldError.name;
  }
}
