import React, { useState } from 'react';

import { useForm, SubmitHandler } from 'react-hook-form';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

import {
  validateStripeCardForm,
  validateBillingDetails,
  validateBillingAddress,
} from 'lane-shared/validation/paymentV2/validateStripeCardForm';

import { ErrorMessage, Button } from 'lane-web/src/components/general';

import { CardFormInputFields } from './CardFormInputFields';
import { useBillingAndPaymentEuComplianceEnabled } from 'lane-shared/domains/billingPayments/hooks/useBillingAndPaymentEuComplianceEnabled';

import styles from './CardForm.scss';
import { CardFormInputs } from './types';
import { PaymentMethod, PaymentSource } from 'graphql-query-contracts';

export type CardFormProps = {
  handleParentSubmit: () => void;
  cards: PaymentSource[];
  updateCards: (cards: PaymentSource[]) => void;
  savePaymentMethod: (paymentMethodId: string) => Promise<void>;
  updatePaymentMethod: (id: string) => void;
  goBack: () => void;
  isWallet?: boolean;
};

export function CardForm({
  handleParentSubmit,
  cards,
  updateCards,
  savePaymentMethod,
  updatePaymentMethod,
  goBack,
  isWallet,
}: CardFormProps) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const PaymentEuComplianceEnabled = useBillingAndPaymentEuComplianceEnabled();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null
  );

  const { control, handleSubmit, setValue } = useForm<CardFormInputs>();

  const onSubmit: SubmitHandler<CardFormInputs> = async (
    data: CardFormInputs
  ) => {
    const validationError = await customValidation(data);
    if (validationError) {
      if (PaymentEuComplianceEnabled) {
        setErrorMessage(t('abp.payment.billingDetailsRequired'));
      } else {
        setErrorMessage(t('abp.payment.allFieldsRequired'));
      }

      return;
    }

    setErrorMessage(null);

    if (stripe && elements) {
      const fullName = PaymentEuComplianceEnabled
        ? `${JSON.parse(data.billingAddress).name}`
        : `${data.firstName} ${data.lastName}`;
      setLoading(true);
      try {
        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement('card') as StripeCardElement,
          billing_details: {
            name: fullName,
          },
          metadata: {
            billingAddress: data.billingAddress,
            companyName: data.companyName,
            taxId: data.taxId,
          },
        });

        if (payload.error) {
          setErrorMessage(payload.error.message);
        } else {
          const newCard: PaymentSource = {
            paymentMethodId: payload.paymentMethod.id,
            brand: payload.paymentMethod.card!.brand,
            expiryMonth: payload.paymentMethod.card!.exp_month,
            expiryYear: payload.paymentMethod.card!.exp_year,
            last4: payload.paymentMethod.card!.last4,
            method: PaymentMethod.PaymentMethodCreditCard,
          };

          if (isWallet || data.saveCard) {
            await savePaymentMethod(payload.paymentMethod.id);
          }
          handleParentSubmit();
          updateCards([newCard, ...cards]);
          updatePaymentMethod(payload.paymentMethod.id);
        }
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  async function customValidation(data: CardFormInputs) {
    try {
      if (PaymentEuComplianceEnabled) {
        await validateBillingDetails.validate(data);
        await validateBillingAddress.validate(JSON.parse(data.billingAddress));
      } else {
        await validateStripeCardForm.validate({
          firstName: data.firstName,
          lastName: data.lastName,
        });
      }
      return null;
    } catch (validationError) {
      return validationError;
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cx(styles.stripeAddCard)}>
        <div className={styles.inputFieldsWrapper}>
          <CardFormInputFields
            InputController={{ control, setValue }}
            allowOptionalCardSave={!isWallet}
          />
        </div>
        {errorMessage != null && <ErrorMessage error={errorMessage} />}
        <div className={cx(styles.stripeAddCardActions)}>
          <Button
            fullWidth
            type="submit"
            variant="activate-contained"
            disabled={!stripe || loading}
            testId="addCardButton"
          >
            {t('abp.payment.addCard')}
          </Button>
          <Button fullWidth variant="outlined" onClick={goBack}>
            {t('abp.payment.goBack')}
          </Button>
        </div>
      </div>
    </form>
  );
}
