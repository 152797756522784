const WEATHER_TYPES = {
  'clear-day': 'sun',
  'clear-night': 'moon',
  rain: 'cloud-rain',
  snow: 'cloud-snow',
  sleet: 'cloud-drizzle',
  wind: 'wind',
  fog: 'cloud',
  cloudy: 'cloud',
  'partly-cloudy-day': 'sun',
  'partly-cloudy-night': 'moon',
};

export { WEATHER_TYPES };
