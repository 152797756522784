import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { ICON_SET_FEATHER } from 'lane-shared/helpers/constants/icons';
import type { PillColor } from 'lane-shared/types/general/pill';

// @ts-ignore
import { S, XS } from 'components/typography';

// @ts-ignore
import styles from './Pill.scss';

type OwnPillProps = {
  text: string;
  size?: 'small' | 'medium';
  color: PillColor;
  className?: string;
  // Adds an Icon to the Pill before the text
  iconName?: string;
  iconColor?: string;
};

type PillProps = OwnPillProps;

export default function Pill({
  size = 'small',
  color,
  text,
  className,
  iconName,
  iconColor,
}: PillProps) {
  return (
    <div className={cx(styles.container, className)}>
      <span className={styles.Pill} data-size={size} data-variant={color}>
        {iconName && (
          <Icon
            name={iconName}
            set={ICON_SET_FEATHER}
            style={{
              fontSize: '8px',
              display: 'flex',
              alignSelf: 'center',
              marginRight: '4px',
              fill: iconColor,
              color: iconColor,
            }}
          />
        )}
        {size === 'medium' ? (
          <S className={styles.medium}>{text}</S>
        ) : (
          <XS className={styles.small}>{text}</XS>
        )}
      </span>
    </div>
  );
}
