/**
 * Spacing system used for web and mobile
 *
 * See https://storybook.joinlane.com/?path=/story/spacing--all
 */
export default {
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 24,
  6: 32,
  7: 48,
  8: 64,
  9: 96,
  10: 128,
  11: 192,
  12: 256,
  13: 384,
  14: 512,
  15: 640,
  16: 768,
};

export type SpacingType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
