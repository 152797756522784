import React, { useMemo, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import gql from 'graphql-tag';
import { v4 as uuid } from 'uuid';

import { getAdminClient } from 'lane-shared/apollo';
import { getValidationMessages } from 'lane-shared/helpers';
import { ValidationError } from 'yup';
import { DEFAULT_WHITELABEL } from 'lane-shared/helpers/constants';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { LANE_MEDIA_LIBRARY } from 'lane-shared/helpers/constants/library';
import { OAUTH_PROVIDERS } from 'lane-shared/helpers/constants/user';
import { getSAfterApostrophe } from 'lane-shared/helpers/whitelabel';
import {
  useDefaultWhitelabel,
  useFlag,
  useMagicLinksFeatureEnabled,
} from 'lane-shared/hooks';
import { EditorModeEnum } from 'lane-shared/types/EditorModeEnum';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import { friendlyLocaleItems } from 'localization';

import { Dropdown } from 'components/form';
import Input from 'components/form/Input';
import TextArea from 'components/form/TextArea';
import Toggle from 'components/form/Toggle';
import { Loading } from 'components/general';
import Label from 'components/general/Label';
import AddressEdit from 'components/lane/AddressEdit';
import ProfileEdit from 'components/lane/ProfileEdit';
import ShowId from 'components/lane/ShowId';
import WhitelabelChannelEdit from 'components/lane/WhitelabelChannelEdit';
import { M } from 'components/typography';

import WhitelabelInfoEditOAuth from './WhitelabelInfoEditOAuth';
import confirmWhitelabelSave from 'helpers/confirmWhitelabelSave';

import styles from './WhitelabelInfoEdit.scss';

const removeWhitelabelChannelMutation = gql`
  mutation removeWhitelabelChannelMutation(
    $whitelabelId: UUID!
    $whitelabelChannelId: UUID!
  ) {
    updateWhitelabel(
      whitelabel: {
        _id: $whitelabelId
        channels: { _pull: true, _id: $whitelabelChannelId }
      }
    ) {
      _id
    }
  }
`;

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  onWhitelabelUpdated: (whitelabel: Partial<WhiteLabelType>) => void;
  whitelabel: WhiteLabelType;
  validation?: ValidationError | ValidationError[] | null;
  mode: 'create' | 'edit';
};

type Props = OwnProps;

export default function WhitelabelInfoEdit({
  className,
  style,
  onWhitelabelUpdated,
  whitelabel,
  validation = null,
  mode = 'edit',
}: Props) {
  const { whitelabel: defaultWhitelabel, loading } = useDefaultWhitelabel();
  const twoFactorAuthFeatureFlag = useFlag('consumer-experience.2FA', false);
  const appleIDFeatureFlag = useFlag('consumer-experience.apple-id', false);
  const magicLinksFeatureFlag = useMagicLinksFeatureEnabled();

  const whiteLabelOAuthConfig = useMemo<OAuthConfigType[]>(() => {
    if (whitelabel.oAuthConfig) {
      const filteredProviders = whitelabel.oAuthConfig.filter(
        config => config.provider !== OAUTH_PROVIDERS.APPLE
      );

      return filteredProviders;
    }

    return [];
  }, [whitelabel]);

  function onAddHost() {
    const hosts = whitelabel.hosts || [];

    if (!hosts.includes('')) {
      hosts.push('');
    }

    onWhitelabelUpdated({ hosts });
  }

  function updateWhitelabelChannel(update: any, ix: any) {
    const channels = [...whitelabel.channels];
    channels[ix] = { ...channels[ix], ...update };

    onWhitelabelUpdated({
      channels,
    });
  }

  async function removeWhitelabelChannel(whitelabelChannel: any, ix: any) {
    if (mode === 'edit') {
      if (!(await confirmWhitelabelSave())) {
        return;
      }

      // remove this whitelabel channel
      await getAdminClient().mutate({
        mutation: removeWhitelabelChannelMutation,
        variables: {
          whitelabelId: whitelabel._id,
          whitelabelChannelId: whitelabelChannel._id,
        },
      });
    }

    onWhitelabelUpdated({
      channels: whitelabel.channels.filter((_, index) => index !== ix),
    });
  }

  function addWhitelabelChannel() {
    onWhitelabelUpdated({
      channels: [
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'WhitelabelChannelType | { channel: null; isP... Remove this comment to see the full error message
        ...(whitelabel.channels || []),
        {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ChannelType... Remove this comment to see the full error message
          channel: null,
          isPrimary: false,
        },
      ],
    });
  }

  function updateOAuthConfig(update: Partial<OAuthConfigType>, index?: number) {
    const oAuthConfigIndex =
      index ?? whiteLabelOAuthConfig.findIndex(oac => oac._id === update._id);

    if (oAuthConfigIndex !== -1) {
      const whiteLabelOAuthConfigUpdated = [...whiteLabelOAuthConfig];

      whiteLabelOAuthConfigUpdated[oAuthConfigIndex] = {
        ...whiteLabelOAuthConfigUpdated[oAuthConfigIndex],
        ...update,
      };

      onWhitelabelUpdated({
        oAuthConfig: whiteLabelOAuthConfigUpdated,
      });
    }
  }

  function removeOAuthConfig(oAuthConfig: any) {
    onWhitelabelUpdated({
      oAuthConfig: whiteLabelOAuthConfig.filter(
        oac => oac._id !== oAuthConfig._id
      ),
    });
  }

  function onAddOAuthConfig() {
    const oAuthConfig = whiteLabelOAuthConfig || [];

    oAuthConfig.push({
      _id: uuid(),
      webClientId: '',
      webClientSecret: '',
      webRedirectUrl: '',
      provider: '',
      iosClientId: '',
      androidClientId: '',
      iosRedirectUrl: '',
      androidRedirectUrl: '',
      tenantId: '',
      enabled: true,
      buttonLabel: '',
    });

    onWhitelabelUpdated({ oAuthConfig });
  }

  function getWhiteLabelUrls(laneUrl: any, whitelabelUrl = '') {
    return [laneUrl].concat(whitelabelUrl ? [whitelabelUrl] : []);
  }

  const [showWhiteLabelUrl, setShowWhiteLabelUrl] = useState(
    whitelabel.privacyPolicyUrls?.length > 1 ||
      whitelabel.termsAndConditionsUrls?.length > 1
  );

  const sAfterApostrophe = getSAfterApostrophe(whitelabel);

  if (loading) {
    return <Loading />;
  }

  return (
    <section className={cx(styles.WhitelabelInfoEdit, className)} style={style}>
      <Input
        testId="whitelabelName"
        error={getValidationMessages(validation, 'name')}
        className={styles.input}
        placeholder="Name…"
        label="Name"
        value={whitelabel.name}
        onChange={name => onWhitelabelUpdated({ name })}
      />

      <span>Description</span>
      <TextArea
        testId="whitelabelDescription"
        placeholder="Description…"
        errors={getValidationMessages(validation, 'description')}
        className={styles.textArea}
        minRows={3}
        value={whitelabel.description}
        onChange={description => onWhitelabelUpdated({ description })}
      />

      <span>Locale</span>
      <Dropdown
        testId="whitelabelLocale"
        name="localeDropdown"
        items={friendlyLocaleItems}
        onValueChange={locale =>
          onWhitelabelUpdated({
            locale,
          })
        }
        value={whitelabel.locale}
      />

      <h2 className={styles.infoSection} style={{ marginTop: '1rem' }}>
        <span>Technical settings</span>
      </h2>

      <Input
        testId="whitelabelInstance"
        className={styles.input}
        error={getValidationMessages(validation, 'instance')}
        placeholder={defaultWhitelabel.instance}
        label="Unique Instance"
        value={whitelabel.instance}
        onChange={instance => onWhitelabelUpdated({ instance })}
      />

      <Toggle
        testId="whitelabelrestrictChannels"
        className={styles.toggle}
        disabled={whitelabel.instance === DEFAULT_WHITELABEL}
        text="Restrict Channels"
        value={whitelabel.restrict}
        onChange={restrict => onWhitelabelUpdated({ restrict })}
        doTranslate
      />

      {whitelabel.instance !== DEFAULT_WHITELABEL && (
        <Label
          TooltipComponent={
            <M variant="secondary">
              Only channels in this hierarchy, or related to channels in this
              hierarchy, will appear in this whitelabel when restrict is
              enabled.
            </M>
          }
        >
          Related Parent Channels
          <Icon
            className={styles.addIcon}
            name="plus-circle"
            set={ICON_SET_FONTAWESOME}
            testId="relatedParentChannelButton"
            onClick={addWhitelabelChannel}
          />
        </Label>
      )}
      <ul className={styles.channels}>
        {whitelabel.channels?.map((whitelabelChannel, ix) => (
          <WhitelabelChannelEdit
            className={styles.listView}
            key={whitelabelChannel._id || ix}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '"channel"' is not assignable to type 'Editor... Remove this comment to see the full error message
            mode="channel"
            onWhitelabelChannelRemoved={() =>
              removeWhitelabelChannel(whitelabelChannel, ix)
            }
            onWhitelabelChannelUpdated={update =>
              updateWhitelabelChannel(update, ix)
            }
            whitelabelChannel={whitelabelChannel}
          />
        ))}
      </ul>

      <hr />

      {magicLinksFeatureFlag && (
        <Toggle
          className={styles.toggle}
          text="Enable magic links"
          value={whitelabel.isMagicLinkEnabled}
          onChange={isMagicLinkEnabled => {
            onWhitelabelUpdated({
              isMagicLinkEnabled,
            });
          }}
          doTranslate
        />
      )}

      {twoFactorAuthFeatureFlag && (
        <Toggle
          className={styles.toggle}
          text="Two Factor Authentication"
          value={whitelabel.is2FAEnabled}
          onChange={is2FAEnabled => {
            onWhitelabelUpdated({
              is2FAEnabled,
            });
          }}
          doTranslate
        />
      )}

      <Toggle
        className={styles.toggle}
        text="Allow sign ups"
        value={whitelabel.settings?.allowSignUps}
        onChange={allowSignUps => {
          onWhitelabelUpdated({
            settings: {
              ...whitelabel.settings,
              allowSignUps,
            },
          });
        }}
        doTranslate
      />

      <Toggle
        className={styles.toggle}
        text="Email subscription opt-in"
        value={whitelabel.settings?.emailSubscriptionOptIn}
        onChange={emailSubscriptionOptIn => {
          onWhitelabelUpdated({
            settings: {
              ...whitelabel.settings,
              emailSubscriptionOptIn,
            },
          });
        }}
        doTranslate
      />

      <Toggle
        testId="disableLocationPrecision"
        className={styles.toggle}
        text="Disable Location Precision"
        value={whitelabel.disableLocationPrecision}
        onChange={disableLocationPrecision =>
          onWhitelabelUpdated({ disableLocationPrecision })
        }
        doTranslate
        TooltipComponent={
          <M variant="secondary">
            This restricts any geolocation information sent from users to be
            limited to one decimal point in lattidue and longitude. This is
            useful for customers who have a legal obligation to limit the
            geographic location of their users.
          </M>
        }
      />

      <hr />

      <Input
        error={getValidationMessages(validation, 'commonName')}
        className={styles.input}
        placeholder={defaultWhitelabel.commonName}
        label="Common Name / Package Name"
        value={whitelabel.commonName}
        onChange={commonName => onWhitelabelUpdated({ commonName })}
      />

      <Input
        error={getValidationMessages(validation, 'appStoreId')}
        className={styles.input}
        placeholder={defaultWhitelabel.appStoreId}
        label="App Store Id"
        value={whitelabel.appStoreId}
        onChange={appStoreId => onWhitelabelUpdated({ appStoreId })}
      />

      <Input
        error={getValidationMessages(validation, 'sha256Fingerprint')}
        className={styles.input}
        placeholder="14:6D:E9:83:C5:73:06:50:D8:EE:B9:95:2F:34:FC:64:16:A0:83:42:E6:1D:BE:A8:8A:04:96:B2:3F:CF:44:E5"
        label="Android SHA256 fingerprints of signing certificate"
        value={whitelabel.sha256Fingerprint}
        onChange={sha256Fingerprint =>
          onWhitelabelUpdated({ sha256Fingerprint })
        }
      />

      <Input
        error={getValidationMessages(validation, 'mobileIos')}
        className={styles.input}
        placeholder={defaultWhitelabel.mobileIos}
        label="Mobile Ios URL"
        value={whitelabel.mobileIos}
        onChange={mobileIos => onWhitelabelUpdated({ mobileIos })}
      />

      <Input
        error={getValidationMessages(validation, 'mobileAndroid')}
        className={styles.input}
        placeholder={defaultWhitelabel.mobileAndroid}
        label="Mobile Android URL"
        value={whitelabel.mobileAndroid}
        onChange={mobileAndroid => onWhitelabelUpdated({ mobileAndroid })}
      />

      <Input
        error={getValidationMessages(validation, 'url')}
        className={styles.input}
        placeholder={defaultWhitelabel.privacyPolicy}
        label="Privacy Policy URL"
        value={
          whitelabel.privacyPolicyUrls ? whitelabel.privacyPolicyUrls[0] : ''
        }
        onChange={privacyPolicy =>
          onWhitelabelUpdated({
            privacyPolicy,
            privacyPolicyUrls: getWhiteLabelUrls(
              privacyPolicy,
              whitelabel.privacyPolicyUrls[1]
            ),
          })
        }
      />

      <Input
        error={getValidationMessages(validation, 'url')}
        className={styles.input}
        placeholder={defaultWhitelabel.termsAndConditions}
        label="Terms & Conditions URL"
        value={
          whitelabel.termsAndConditionsUrls
            ? whitelabel.termsAndConditionsUrls[0]
            : ''
        }
        onChange={termsAndConditions =>
          onWhitelabelUpdated({
            termsAndConditions,
            termsAndConditionsUrls: getWhiteLabelUrls(
              termsAndConditions,
              whitelabel.termsAndConditionsUrls[1]
            ),
          })
        }
      />

      <Input
        error={getValidationMessages(validation, 'url')}
        className={styles.input}
        placeholder={defaultWhitelabel.termsAndConditions}
        label="Contact"
        value={whitelabel.complianceContact}
        onChange={contact =>
          onWhitelabelUpdated({
            complianceContact: contact,
          })
        }
      />

      <Label>
        <span>
          {`${showWhiteLabelUrl ? 'Remove' : 'Add'} ${
            whitelabel.name
          }'${sAfterApostrophe} Policy URLs`}
        </span>
        <Icon
          name={`${showWhiteLabelUrl ? 'times-circle' : 'plus-circle'}`}
          className={showWhiteLabelUrl ? styles.removeIcon : styles.addIcon}
          set={ICON_SET_FONTAWESOME}
          onClick={() => {
            setShowWhiteLabelUrl(
              prevShowWhiteLabelUrl => !prevShowWhiteLabelUrl
            );
            // Toggle updates the state to leave only Lane urls both when
            // * fresh input is shown, to clear previous state, if any
            // * input is hidden, to clear whitelabel url state
            onWhitelabelUpdated({
              termsAndConditionsUrls: [whitelabel.termsAndConditionsUrls[0]],
              privacyPolicyUrls: [whitelabel.privacyPolicyUrls[0]],
            });
          }}
        />
      </Label>

      {showWhiteLabelUrl && (
        <>
          <Input
            error={getValidationMessages(validation, 'url')}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'string'.
            className={[styles.input, styles.urlInput]}
            placeholder={defaultWhitelabel.privatePolicy}
            label={`${whitelabel.name}’${sAfterApostrophe} Privacy Policy URL`}
            value={whitelabel.privacyPolicyUrls[1]}
            onChange={privacyPolicy =>
              onWhitelabelUpdated({
                privacyPolicyUrls: getWhiteLabelUrls(
                  whitelabel.privacyPolicyUrls[0],
                  privacyPolicy
                ),
              })
            }
          />

          <Input
            error={getValidationMessages(validation, 'url')}
            className={styles.input}
            placeholder={defaultWhitelabel.termsAndConditions}
            label={`${whitelabel.name}’${sAfterApostrophe} Terms & Conditions URL`}
            value={whitelabel.termsAndConditionsUrls[1]}
            onChange={termsAndConditions =>
              onWhitelabelUpdated({
                termsAndConditionsUrls: getWhiteLabelUrls(
                  whitelabel.termsAndConditionsUrls[0],
                  termsAndConditions
                ),
              })
            }
          />
        </>
      )}

      {appleIDFeatureFlag && (
        <Toggle
          className={styles.toggle}
          text="Sign in with Apple"
          value={whitelabel.isAppleIDEnabled}
          onChange={isAppleIDEnabled => {
            onWhitelabelUpdated({
              isAppleIDEnabled,
            });
          }}
          doTranslate
          mt={4}
          mb={2}
        />
      )}

      <Label>
        <span>OAuth Config</span>
        <Icon
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={onAddOAuthConfig}
        />
      </Label>

      {whiteLabelOAuthConfig.length > 0 && (
        <ul className={styles.oauths}>
          {whiteLabelOAuthConfig.map((oAuthConfig, idx) => (
            <WhitelabelInfoEditOAuth
              key={oAuthConfig._id}
              onUpdateOAuthConfig={updateOAuthConfig}
              removeOAuthConfig={removeOAuthConfig}
              oAuthConfig={oAuthConfig}
              validation={validation}
              index={idx}
            />
          ))}
        </ul>
      )}

      <Label>
        <span>Hosts</span>
        <Icon
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={onAddHost}
        />
      </Label>

      <ul className={styles.hosts}>
        {(whitelabel.hosts || []).map((host, i) => (
          <li key={i}>
            <Input
              error={getValidationMessages(validation, `hosts[${i}]`)}
              placeholder={
                defaultWhitelabel.hosts[defaultWhitelabel.hosts.length - 1]
              }
              value={host}
              onChange={newHost => {
                whitelabel.hosts[i] = newHost;
                onWhitelabelUpdated({ hosts: [...whitelabel.hosts] });
              }}
            />
            <Icon
              name="times-circle"
              set={ICON_SET_FONTAWESOME}
              className={styles.removeIcon}
              onClick={() =>
                onWhitelabelUpdated({
                  hosts: whitelabel.hosts.filter((filter, j) => j !== i),
                })
              }
            />
          </li>
        ))}
      </ul>

      <Input
        error={getValidationMessages(validation, 'urlDevelopment')}
        className={styles.input}
        placeholder={defaultWhitelabel.urlDevelopment}
        label="Development URL"
        value={whitelabel.urlDevelopment}
        onChange={urlDevelopment => onWhitelabelUpdated({ urlDevelopment })}
      />

      <Input
        error={getValidationMessages(validation, 'urlStaging')}
        className={styles.input}
        placeholder={defaultWhitelabel.urlStaging}
        label="Staging URL"
        value={whitelabel.urlStaging}
        onChange={urlStaging => onWhitelabelUpdated({ urlStaging })}
      />

      <Input
        error={getValidationMessages(validation, 'url')}
        className={styles.input}
        placeholder={defaultWhitelabel.url}
        label="Prod URL"
        value={whitelabel.url}
        onChange={url => onWhitelabelUpdated({ url })}
      />

      <Input
        error={getValidationMessages(validation, 'operatingName')}
        className={styles.input}
        placeholder={defaultWhitelabel.operatingName}
        testId="Operating Name"
        label="Operating Name"
        value={whitelabel.operatingName}
        onChange={operatingName => onWhitelabelUpdated({ operatingName })}
      />

      <Input
        error={getValidationMessages(validation, 'shortOperatingName')}
        className={styles.input}
        placeholder={defaultWhitelabel.shortOperatingName}
        label="Short Operating Name"
        value={whitelabel.shortOperatingName}
        onChange={shortOperatingName =>
          onWhitelabelUpdated({ shortOperatingName })
        }
      />

      <h2>Profile</h2>
      <ProfileEdit
        channel={whitelabel.channels?.[0].channel}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'LibraryType | null' is not assignable to typ... Remove this comment to see the full error message
        library={whitelabel.channels ? null : LANE_MEDIA_LIBRARY}
        className={styles.profile}
        profile={whitelabel.profile}
        validation={validation}
        mode={EditorModeEnum.Whitelabel}
        onProfileUpdated={profile => {
          if (profile.theme) {
            // @ts-expect-error ts-migrate(2740) FIXME: Type '{ _id: string; }' is missing the following p... Remove this comment to see the full error message
            profile.theme = {
              _id: profile.theme?._id,
            };
          }

          onWhitelabelUpdated({ profile });
        }}
      />

      <h2>Address</h2>
      <AddressEdit
        className={styles.address}
        address={whitelabel.address}
        onAddressUpdated={address => onWhitelabelUpdated({ address })}
      />

      <ShowId id={whitelabel._id} />
    </section>
  );
}
