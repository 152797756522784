import React, { useEffect, useContext, useState } from 'react';

import cx from 'classnames';
import { CircleListView } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import {
  enableMobileAccess,
  updateBulkUsersAccessControlGroups,
} from 'lane-shared/graphql/accessControl';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { AccessControlUserSyncStatusEnum } from 'lane-shared/types/AccessControlType';

import CardContainer from 'components/cards/CardContainer';
import { Modal } from 'components/lds';
import { S, H5, XS, M } from 'components/typography';

import { buildMixedStateData } from '../../helpers/buildMixedStateData';
import { buildProviderToACGsMap } from '../../helpers/buildProviderToACGsMap';
import { AccessGroupSelectItem, UserInfo } from '../../types/AccessControl';
import MixedStateList from './MixedStateList';
import { Data, DataStatus } from './MixedStateList/types';
import { Button } from 'design-system-web';

import styles from './styles.scss';
import {
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
} from 'lane-shared/helpers/constants/permissions';
import { UserDataContext } from 'lane-shared/contexts';
import { hasPermission } from 'lane-shared/helpers';
import { useTrackUserAccessGroupsEvents } from 'lane-web/src/domains/accessControl/analytics/user-access-groups';

type props = {
  channelId: string;
  users: UserInfo[];
  accessControlGroups: AccessGroupSelectItem[];
  isOpen: boolean;
  onClose: (userInfo: UserInfo[]) => void;
};

type BulkACGsInfo = {
  assignedACGs: AccessGroupSelectItem[];
  revokedACGs: AccessGroupSelectItem[];
};

export const ManageBulkACGsModal = ({
  channelId,
  accessControlGroups,
  users,
  isOpen,
  onClose,
}: props) => {
  const { t } = useTranslation();
  const {
    trackUserAccessGroupsEditCompleted,
    trackUserAccessGroupsEditFailed,
  } = useTrackUserAccessGroupsEvents();
  const [updatedACGs, setUpdatedACGs] = useState<BulkACGsInfo>({
    assignedACGs: [],
    revokedACGs: [],
  });
  const [usersToProcess, setUsersToProcess] = useState<UserInfo[]>(
    users.filter(
      user =>
        user.syncStatus === AccessControlUserSyncStatusEnum.SYNCED ||
        user.syncStatus === AccessControlUserSyncStatusEnum.UNMAPPED
    )
  );
  const [loading, setLoading] = useState(false);
  const [
    noMobileAccessAndUnmappedUsers,
    setNoMobileAccessAndUnmappedUsers,
  ] = useState<UserInfo[]>(
    usersToProcess.filter(
      (user: UserInfo) =>
        user.syncStatus === AccessControlUserSyncStatusEnum.UNMAPPED ||
        !user.mobileAccess
    )
  );

  const [mobileAccessSyncedUsers, setMobileAccessSyncedUsers] = useState<
    UserInfo[]
  >(
    usersToProcess.filter(
      (user: UserInfo) =>
        user.mobileAccess &&
        user.syncStatus === AccessControlUserSyncStatusEnum.SYNCED
    )
  );
  const [
    enableMobileAccessPromptStep,
    setEnableMobileAccessPromptStep,
  ] = useState(noMobileAccessAndUnmappedUsers.length > 0);
  const [mixedStateData, setMixedStateData] = useState<Data[]>(
    buildMixedStateData(accessControlGroups, usersToProcess)
  );
  const { user } = useContext(UserDataContext);

  useEffect(() => {
    if (usersToProcess.length <= 0) {
      onClose([]);
    }
  }, [onClose, usersToProcess]);

  const handleManageBulkACGsModalClose = () => {
    onClose([]);
  };

  useEffect(() => {
    setNoMobileAccessAndUnmappedUsers(
      usersToProcess.filter(
        (user: UserInfo) =>
          user.syncStatus === AccessControlUserSyncStatusEnum.UNMAPPED ||
          !user.mobileAccess
      )
    );

    setMobileAccessSyncedUsers(
      usersToProcess.filter(
        (user: UserInfo) =>
          user.mobileAccess &&
          user.syncStatus === AccessControlUserSyncStatusEnum.SYNCED
      )
    );
  }, [usersToProcess]);

  const handleMixedStateListChange = (updatedSelection: Data[]) => {
    const assignedACGs: AccessGroupSelectItem[] = [];
    const revokedACGs: AccessGroupSelectItem[] = [];

    updatedSelection.forEach((acg, idx) => {
      if (acg.status !== mixedStateData[idx].status) {
        const item: AccessGroupSelectItem = {
          label: acg.label,
          value: acg.value,
          provider: acg.provider,
          channelId: acg.channelId,
          isDisabled: acg.isDisabled,
        };

        if (acg.status === DataStatus.selected) {
          assignedACGs.push(item);
        }
        if (acg.status === DataStatus.unselected) {
          revokedACGs.push(item);
        }
      }
    });

    setUpdatedACGs({ assignedACGs, revokedACGs });
  };

  const enableBulkMobileAccessForUnmappedUsersWorkflow = async () => {
    if (
      noMobileAccessAndUnmappedUsers.length === 0 ||
      (updatedACGs.assignedACGs.length === 0 &&
        updatedACGs.revokedACGs.length === 0)
    )
      return;

    const users = noMobileAccessAndUnmappedUsers.filter(
      user => user.syncStatus === AccessControlUserSyncStatusEnum.UNMAPPED
    );

    if (users.length === 0) return;

    const assignedACGs = updatedACGs.assignedACGs.map(
      (acg: AccessGroupSelectItem) => ({
        id: acg.value,
        provider: acg.provider,
        channelId: acg.channelId,
      })
    );

    if (assignedACGs.length === 0) return;

    try {
      await getClient().mutate({
        mutation: enableMobileAccess,
        variables: {
          channelId,
          userClearances: users.map(user => ({
            userId: user._id,
            accessControlGroups: assignedACGs,
          })),
        },
      });

      return users.length;
    } catch (err) {
      // handle error
      return null;
    }
  };

  const enableBulkMobileAccessForSyncedUsersWorkflow = async () => {
    if (
      noMobileAccessAndUnmappedUsers.length === 0 ||
      (updatedACGs.assignedACGs.length === 0 &&
        updatedACGs.revokedACGs.length === 0)
    )
      return;

    const users = noMobileAccessAndUnmappedUsers.filter(
      user =>
        !user.mobileAccess &&
        user.syncStatus === AccessControlUserSyncStatusEnum.SYNCED
    );

    if (users.length === 0) return;

    const userIds = users.map(user => user._id);

    try {
      await getClient().mutate({
        mutation: enableMobileAccess,
        variables: {
          channelId,
          userClearances: users.map(user => ({
            userId: user._id,
            accessControlGroups: [],
          })),
        },
      });

      await getClient().mutate({
        mutation: updateBulkUsersAccessControlGroups,
        variables: {
          channelId,
          userIds,
          assignedAccessControlGroups: buildProviderToACGsMap(
            updatedACGs.assignedACGs
          ),
          revokedAccessControlGroups: buildProviderToACGsMap(
            updatedACGs.revokedACGs
          ),
        },
      });

      return users.length;
    } catch (err) {
      // handle error
      return null;
    }
  };

  const assignBulkUsersACGsWorkflow = async () => {
    if (
      mobileAccessSyncedUsers.length === 0 ||
      (updatedACGs.assignedACGs.length === 0 &&
        updatedACGs.revokedACGs.length === 0)
    )
      return;

    try {
      await getClient().mutate({
        mutation: updateBulkUsersAccessControlGroups,
        variables: {
          channelId,
          userIds: mobileAccessSyncedUsers.map(user => user._id),
          assignedAccessControlGroups: buildProviderToACGsMap(
            updatedACGs.assignedACGs
          ),
          revokedAccessControlGroups: buildProviderToACGsMap(
            updatedACGs.revokedACGs
          ),
        },
      });

      return mobileAccessSyncedUsers.length;
    } catch (err) {
      // TODO: handle error states
      return null;
    }
  };

  const handleSaveChanges = async () => {
    let enableBulkUnmapped;
    let enableAndAssignSynced;
    let assignBulk;

    try {
      setLoading(true);
      enableBulkUnmapped = await enableBulkMobileAccessForUnmappedUsersWorkflow();
      enableAndAssignSynced = await enableBulkMobileAccessForSyncedUsersWorkflow();
      assignBulk = await assignBulkUsersACGsWorkflow();
    } catch (err) {
      trackUserAccessGroupsEditFailed({ attemptedChannelId: channelId });
      // TODO: handle error states
    } finally {
      const enabledUsersCount =
        (enableBulkUnmapped ?? 0) + (enableAndAssignSynced ?? 0);
      if (enabledUsersCount) {
        window.Toast.show(
          enabledUsersCount > 1
            ? t(
                'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges.enableMobileAccess.successToast_plural',
                { userCount: enabledUsersCount }
              )
            : t(
                'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges.enableMobileAccess.successToast',
                { userCount: enabledUsersCount }
              )
        );
      }

      const assignedACGsUsersCount =
        (assignBulk ?? 0) + (enableAndAssignSynced ?? 0);
      if (assignedACGsUsersCount) {
        if (enabledUsersCount) {
          setTimeout(() => {
            window.Toast.show(
              assignedACGsUsersCount > 1
                ? t(
                    'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges.acgAssignment.successToast_plural',
                    { userCount: assignedACGsUsersCount }
                  )
                : t(
                    'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges.acgAssignment.successToast',
                    { userCount: assignedACGsUsersCount }
                  )
            );
          }, 3000);
        } else {
          window.Toast.show(
            assignedACGsUsersCount > 1
              ? t(
                  'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges.acgAssignment.successToast_plural',
                  { userCount: assignedACGsUsersCount }
                )
              : t(
                  'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges.acgAssignment.successToast',
                  { userCount: assignedACGsUsersCount }
                )
          );
        }
      }
      if (enabledUsersCount || assignedACGsUsersCount) {
        trackUserAccessGroupsEditCompleted({ attemptedChannelId: channelId });
      } else {
        trackUserAccessGroupsEditFailed({ attemptedChannelId: channelId });
      }
      setLoading(false);
      onClose(usersToProcess);
    }
  };

  const handleContinueWithEligibleUsers = () => {
    if (mobileAccessSyncedUsers.length <= 0) {
      onClose([]);
    }
    setUsersToProcess(mobileAccessSyncedUsers);
    setEnableMobileAccessPromptStep(false);
    setMixedStateData(
      buildMixedStateData(accessControlGroups, mobileAccessSyncedUsers)
    );
  };

  const hasPermissionToEnableMobileAccess = (): boolean => {
    const hasIntegrationChannelPermissions = !noMobileAccessAndUnmappedUsers.some(
      userInfo =>
        userInfo.integrationChannelId !== channelId &&
        !hasPermission(
          user?.roles,
          [PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY],
          userInfo.integrationChannelId,
          true
        )
    );

    const hasCurrentChannelPermissions = hasPermission(
      user?.roles,
      [PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE],
      channelId,
      true
    );
    return hasIntegrationChannelPermissions && hasCurrentChannelPermissions;
  };

  const handleEnableMobileAccess = () => {
    setUsersToProcess(usersToProcess);
    setEnableMobileAccessPromptStep(false);
  };

  if (!usersToProcess || usersToProcess.length <= 0) {
    return null;
  }

  const totalUserCount =
    usersToProcess.length - 1 > 0 ? usersToProcess.length - 1 : null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleManageBulkACGsModalClose}
      title={
        enableMobileAccessPromptStep
          ? t(
              'web.admin.accessControl.userAccess.modal.manageBulkACGs.enableMobileAccessPrompt.title'
            )
          : t('web.admin.accessControl.userAccess.modal.manageBulkACGs.title')
      }
      className={styles.modal}
      size="large"
      testId="manageBulkACGsModal"
    >
      <div data-test="access-control-modal">
        {enableMobileAccessPromptStep ? (
          <div>
            <M style={{ paddingBottom: '1rem' }}>
              {t(
                'web.admin.accessControl.userAccess.modal.manageBulkACGs.enableMobileAccessPrompt.description'
              )}
            </M>
            <div
              className={cx(styles.enableMobileAccessPromptUsersListPreview)}
            >
              <ul>
                {noMobileAccessAndUnmappedUsers.map(user => (
                  <li key={user._id}>
                    <M>{user.name}</M>
                  </li>
                ))}
              </ul>
            </div>
            <div className={cx(styles.mobileAccessModalButton)}>
              <Button
                variant="primary"
                className={styles.enableButton}
                testId="manageBulkACGsEnableMobileAccessButton"
                onClick={handleEnableMobileAccess}
                size="large"
                disabled={!hasPermissionToEnableMobileAccess()}
              >
                {t(
                  'web.admin.accessControl.userAccess.modal.manageBulkACGs.enableMobileAccessPrompt.enable'
                )}
              </Button>
              <Button
                variant="text"
                className={styles.cancelButton}
                testId="manageBulkACGsContinueWithEligibleUsersButton"
                onClick={handleContinueWithEligibleUsers}
                size="large"
              >
                {t(
                  'web.admin.accessControl.userAccess.modal.manageBulkACGs.enableMobileAccessPrompt.continueWithEligibleUsers'
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className={cx(styles.editBulkACGsContainer)}>
            <CardContainer>
              <div style={{ display: 'flex' }}>
                <CircleListView
                  style={{ padding: '0px' }}
                  image={imageUrl(usersToProcess[0]?.image)}
                  logo={imageUrl(usersToProcess[0]?.logo)}
                  name={usersToProcess[0]?.name}
                  className={cx(styles.manageBulkACGsUserPreview)}
                >
                  <div className={cx(styles.userPreviewDeatailsContainer)}>
                    {totalUserCount && (
                      <div className={cx(styles.multiUserPreviewCircle)}>
                        <XS className={cx(styles.multiUserPreviewCount)}>
                          {totalUserCount && `+${totalUserCount}`}
                        </XS>
                      </div>
                    )}
                    <div className={cx(styles.userPreviewDetails)}>
                      <div className={cx(styles.userPreviewDetailsNameCount)}>
                        <H5>{usersToProcess[0]?.name}</H5>
                        {totalUserCount && (
                          <S style={{ marginLeft: '10px' }}>
                            {t(
                              'web.admin.accessControl.userAccess.modal.manageBulkACGs.userDetails.multipleUsers',
                              { userCount: totalUserCount }
                            )}
                          </S>
                        )}
                      </div>
                      <XS variant="secondary">
                        {totalUserCount ||
                        usersToProcess.find(user => user.companies.length > 1)
                          ? t(
                              'web.admin.accessControl.userAccess.modal.manageBulkACGs.userDetails.multipleCompanies'
                            )
                          : usersToProcess.find(
                              user => user.companies.length > 0
                            )?.companies[0].name}
                      </XS>
                    </div>
                  </div>
                </CircleListView>
              </div>
            </CardContainer>
            {accessControlGroups.length > 0 && (
              <div>
                <S className={styles.mobileAccessModalDropdownTitle}>
                  {t(
                    'web.admin.accessControl.userAccess.modal.manageBulkACGs.accessGroups'
                  )}
                </S>
                <MixedStateList
                  mixedStateData={mixedStateData}
                  mixedStateLabel={`(${t(
                    'web.admin.accessControl.userAccess.modal.manageBulkACGs.mixedLabel'
                  )})`}
                  onChange={handleMixedStateListChange}
                  className={styles.mixedStateContainer}
                />
              </div>
            )}
            <div className={cx(styles.mobileAccessModalButton)}>
              <Button
                loading={loading}
                variant="primary"
                disabled={
                  updatedACGs.assignedACGs.length <= 0 &&
                  updatedACGs.revokedACGs.length <= 0
                }
                className={styles.enableButton}
                testId="manageBulkACGsSaveChangesButton"
                onClick={handleSaveChanges}
                size="large"
              >
                {t(
                  'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges'
                )}
              </Button>
              <Button
                variant="text"
                className={styles.cancelButton}
                testId="mobileAccessModalCancelButton"
                onClick={handleManageBulkACGsModalClose}
                size="large"
              >
                {t('Cancel')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
