import React from 'react';

import { useModalPosition } from 'hooks';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { SketchPicker } from 'react-color';
import ReactDOM from 'react-dom';
import { Key } from 'ts-key-enum';

import { colorToRGBA } from 'lane-shared/helpers/formatters';

import ModalBackground from '../general/ModalBackground';

import styles from './ColorPicker.scss';

type Props = {
  value?: string | null;
  onChange: (color?: string | null) => any;
  disableAlpha?: boolean;
  children: React.ReactNode;
  testId?: string;
};

/**
 * Wrap any component to open the color picker when clicked
 */
export default function ColorPicker({
  value,
  onChange,
  disableAlpha = true,
  children,
  testId,
}: Props) {
  const {
    buttonRef,
    modalRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } = useModalPosition();

  return (
    <div
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
      ref={buttonRef}
      role="button"
      tabIndex={0}
      onKeyPress={e => e.key === Key.Enter && onOpen(e)}
      onClick={onOpen}
      style={{ outline: 'none' }}
      data-test={testId}
    >
      {children}
      <ModalBackground
        className={styles.background}
        onClose={onClose}
        isOpen={isOpen}
      />
      {isOpen &&
        ReactDOM.createPortal(
          <div
            data-test="colorPicker"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
            ref={modalRef}
            className={styles.picker}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ top: number; left: number; height?: number... Remove this comment to see the full error message
            style={position}
          >
            <SketchPicker
              disableAlpha={disableAlpha}
              color={value || ''}
              onChange={(newColor: any) => onChange(colorToRGBA(newColor))}
            />
          </div>,
          document.body
        )}
    </div>
  );
}
