import { gql } from 'graphql-query-contracts';

export const joinWaitlistMutation = gql(`
  mutation joinWaitlist(
    $userId: UUID!
    $contentId: UUID!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    joinWaitlist(
      userId: $userId
      contentId: $contentId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      startDate
      endDate
    }
  }
`);
