import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup, TextLink } from 'design-system-web';
import { ContentType } from 'graphql-query-contracts';
import { FullCalendarEvent } from '../hooks/useReservableCalendarView';
import { EventDetails } from './EventDetails';
import styles from './CalendarViews.scss';
import { ResourceLabelContentArg } from '@fullcalendar/resource';
import { routes } from 'lane-shared/config';

enum DurationInMinutes {
  Fifteen = 15,
  Thirty = 30,
}

export type FullCalendarEventInfo = {
  event: FullCalendarEvent;
  timeText: string;
  extendedProps: {
    bookingDuration: number;
  };
};

export function EventContent({
  isDetailsEnabled,
  eventInfo,
  userLocale,
  onStatusUpdate,
}: {
  isDetailsEnabled: boolean;
  eventInfo: FullCalendarEventInfo;
  userLocale: string;
  onStatusUpdate: () => void;
}) {
  const bookingDuration = eventInfo.event.extendedProps.bookingDuration;

  if (bookingDuration < DurationInMinutes.Fifteen) {
    return (
      <EventDetailsPopup
        isDetailsEnabled={isDetailsEnabled}
        eventInfo={eventInfo}
        userLocale={userLocale}
        onStatusUpdate={onStatusUpdate}
      >
        <p style={{ fontWeight: '500', fontSize: '10px' }}>
          {eventInfo.event.title}
        </p>
      </EventDetailsPopup>
    );
  }

  if (bookingDuration < DurationInMinutes.Thirty) {
    return (
      <EventDetailsPopup
        isDetailsEnabled={isDetailsEnabled}
        eventInfo={eventInfo}
        userLocale={userLocale}
        onStatusUpdate={onStatusUpdate}
      >
        <p style={{ fontWeight: '500' }}>{eventInfo.event.title}</p>
      </EventDetailsPopup>
    );
  }

  return (
    <EventDetailsPopup
      isDetailsEnabled={isDetailsEnabled}
      eventInfo={eventInfo}
      userLocale={userLocale}
      onStatusUpdate={onStatusUpdate}
    >
      <>
        <p style={{ fontWeight: '500' }}>{eventInfo.event.title}</p>
        <p>{eventInfo.timeText}</p>
      </>
    </EventDetailsPopup>
  );
}

export function isFullCalendarEvent(event: any): event is FullCalendarEvent {
  return event?.start && event?.end && event?.extendedProps?.bookingDuration;
}

export function isFullCalendarEventInfo(
  eventInfo: any
): eventInfo is FullCalendarEventInfo {
  return eventInfo.timeText && eventInfo.event?.extendedProps;
}

export const ColumnHeader = ({
  resource,
  channelSlug,
}: {
  resource: ResourceLabelContentArg['resource'];
  channelSlug: string | undefined;
}) => {
  return (
    <div className={styles.columnHeader}>
      {channelSlug ? (
        <Link
          className={styles.link}
          component={TextLink}
          to={routes.channelAdminContent
            .replace(':id', channelSlug)
            .replace(':contentId', resource.id)}
        >
          {resource.title}
        </Link>
      ) : (
        resource.title
      )}
      {resource.extendedProps.type === ContentType.Content && (
        <Icon
          name="alarm-clock"
          testId="alarmClockIcon"
          className={styles.inline}
        />
      )}
    </div>
  );
};

function EventDetailsPopup({
  children,
  isDetailsEnabled,
  eventInfo,
  userLocale,
  onStatusUpdate,
}: {
  isDetailsEnabled: boolean;
  eventInfo: FullCalendarEventInfo;
  userLocale: string;
  onStatusUpdate: () => void;
  children: JSX.Element;
}) {
  if (!isDetailsEnabled) {
    return children;
  }

  const isCombinedEvent = !eventInfo.event.extendedProps.interactionId;

  if (isCombinedEvent) {
    return children;
  }

  return (
    <Popup
      trigger={<div style={{ width: '100%', height: '100%' }}>{children}</div>}
    >
      {({ close }) => (
        <EventDetails
          event={eventInfo.event}
          userLocale={userLocale}
          onStatusUpdate={onStatusUpdate}
          onClose={close}
        />
      )}
    </Popup>
  );
}
