import gql from 'graphql-tag';

import { ChannelSettingsRequirementsFragment } from 'lane-shared/graphql/fragments';

export default gql`
  ${ChannelSettingsRequirementsFragment}
  fragment FullContentFragment on Content {
    _id
    _created
    _updated
    _createdBy {
      _id
    }
    _updatedBy {
      _id
    }
    name
    name_l10n
    description
    description_l10n
    subtitle
    subtitle_l10n
    slug
    type
    tags
    category
    reportingGroup
    logo
    color
    isInteractive
    isSyndicateable
    geo
    publishedAt
    publishedBy {
      _id
    }
    backgroundImage
    backgroundColor
    icon
    iconSet
    iconWeight
    liveDate
    startDate
    endDate
    unpublishDate
    interactionEndDate
    resetPeriod
    lastReset
    nextReset
    externalUrl {
      _id
      url
      openingMode
      mediaId
    }
    signedExternalUrl
    deepLink {
      _id
      deepLinkUrl
      appStoreUrl
      googlePlayStoreUrl
    }
    card {
      _id
    }
    channel {
      _id
      slug
      address {
        _id
        name
        street1
      }
      profile {
        ...FullProfileWithThemeFragment
      }
      settings {
        requirements {
          ...ChannelSettingsRequirementsFragment
        }
        effectiveParentRequirements {
          ...ChannelSettingsRequirementsFragment
        }
      }
      integrations {
        _id
        settings
        integration {
          _id
          name
        }
      }
    }
    version
    renderer
    block
    properties
    propertiesOptions
    features {
      _id
      type
      feature
    }
    contentMetatags {
      _id
      value
      metatag {
        _id
        name
        properties
      }
    }
    data
    state
    theme {
      ...ThemeFragment
    }
    analytics {
      _id
      ratings
      rating
      views
      details
      hides
      deletes
      pins
      stars
      likes
      shares
      interactions
    }

    integration {
      _id
      settings
      integration {
        _id
        name
        settings
      }
    }
  }
`;
