import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class EssensysSubmitOnBehalfOfError extends CodedError {
  static get errorName() {
    return errorCodes.essensysSubmitOnBehalfOfError.code;
  }

  constructor(message = errorCodes.essensysSubmitOnBehalfOfError.message) {
    super(message);
    this.code = errorCodes.essensysSubmitOnBehalfOfError.code;
    this.message = `${message} ${errorCodes.essensysSubmitOnBehalfOfError.code}`;
    this.name = errorCodes.essensysSubmitOnBehalfOfError.name;
  }
}
