import gql from 'graphql-tag';

export default gql`
  query getReservableBookings(
    $search: ReservableBookingSearch!
    $pagination: PaginationInput
  ) {
    reservableBookings(search: $search, pagination: $pagination) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        id
        startDate
        endDate
        features
        contentData
        status
        adminNote
        user {
          name
          profile {
            email
          }
        }
        channel {
          profile {
            name
          }
        }
        companies {
          id
          name
          joinedAt
        }
      }
    }
  }
`;
