import { createContext } from 'react';

import { ChannelType } from '../types/ChannelType';

export type ChannelsContextType = {
  primaryId: any; // TODO: fix type
  secondaryId: any; // TODO: fix type
  channels: ChannelType[];
  primaryChannel: ChannelType | null;
  secondaryChannel: any; // TODO: fix type
  forAdmin: any; // TODO: fix type
  focusOnChannels: ChannelType[];
  contents: any; // TODO: fix type
  notices: any; // TODO: fix type
  interactions: any; // TODO: fix type
  pages: any; // TODO: fix type
  loading: any; // TODO: fix type
  error: any; // TODO: fix type
  storageInitialized: any; // TODO: fix type
  channelsInitialized: any; // TODO: fix type
  primaryChannelInitialized: any; // TODO: fix type
  switchingChannels: any; // TODO: fix type
  isReady: boolean;
  refetch: any; // TODO: fix type
  refetchFocus: any; // TODO: fix type
  switchChannel: any; // TODO: fix type
  startPolling: () => void;
};

export const emptyResults = [];
export const defaultContext: ChannelsContextType = {
  primaryId: null,
  secondaryId: null,
  channels: emptyResults,
  primaryChannel: null,
  secondaryChannel: null,
  forAdmin: false,
  focusOnChannels: emptyResults,
  contents: emptyResults,
  notices: emptyResults,
  interactions: emptyResults,
  pages: emptyResults,
  loading: false,
  error: null,
  storageInitialized: false,
  channelsInitialized: false,
  primaryChannelInitialized: false, // this is the flag that verifies all user/channel data is loaded
  switchingChannels: false,
  isReady: false,
  refetch: () => null,
  refetchFocus: () => null,
  switchChannel: async () => null,
  startPolling: () => null,
};

const ChannelsContext = createContext({
  ...defaultContext,
});

export default ChannelsContext;
