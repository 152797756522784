import gql from 'graphql-tag';

export default gql`
  mutation assignAccessManager(
    $channelId: UUID!
    $userId: UUID!
    $isCompanyAdmin: Boolean
    $companyChannelIds: [UUID!]
  ) {
    assignAccessManager(
      channelId: $channelId
      userId: $userId
      isCompanyAdmin: $isCompanyAdmin
      companyChannelIds: $companyChannelIds
    )
  }
`;
