import React from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import colorToHex from 'lane-shared/helpers/formatters/colorToHex';

import { Text } from 'components/typography';

import styles from './ThemePaletteColorDetails.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  color: string;
  isSelected: boolean;
  size: 'small' | 'medium' | 'large';
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export default function ThemePaletteColorDetails({
  className,
  style,
  color,
  isSelected,
  size = 'medium',
  onClick,
}: Props) {
  return (
    <div
      className={cx(styles.ThemePaletteColorDetails, className)}
      style={style}
      data-test="colorSwatchDetails"
      data-size={size}
      data-is-selected={isSelected}
      role="button"
      tabIndex={0}
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'KeyboardEvent<HTMLDivElement>' i... Remove this comment to see the full error message
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      <div
        className={styles.themeColorSwatch}
        style={{ backgroundColor: color }}
      />
      <Text className={styles.subtitle} size="small">
        {colorToHex(color)}
      </Text>
    </div>
  );
}
