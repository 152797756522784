import { v4 as uuid } from 'uuid';

export default function constructNotification({
  userId,
  sendAt,
  contentId,
  title = '',
  title_l10n = {},
  contentGoesLiveNotification = false,
}: any) {
  return {
    _id: uuid(),
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    contentGoesLiveNotification,
    title,
    title_l10n,
    content: {
      _id: contentId,
    },
    sent: false,
    sendAt: sendAt ? new Date(sendAt.valueOf()) : new Date(),
  };
}
