import { useEffect, useState, useRef } from 'react';

import qs from 'query-string';
import { useHistory } from 'react-router-dom';

import { getQueryString } from '../helpers';

type QueryStringResult<T> = [T, (props: any) => void, (props: any) => string];

export interface QueryObject {
  [key: string]: Date | string | number | boolean | null;
}
/**
 * Listens for a changes to the query string, and updates the state with a
 * parsed query object.
 */
export function useQueryString<
  T extends {
    [x: string]:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean)[]
      | null
      | undefined;
  }
>(defaultQuery: QueryObject = {}): QueryStringResult<T> {
  const history = useHistory();

  const initialQuery = useRef({
    ...defaultQuery,
    ...qs.parse(history?.location?.search, {}),
  }).current;

  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    goToUrl(initialQuery);
  }, []);

  useEffect(() => {
    const listener = history?.listen?.(location =>
      setQuery({
        ...qs.parse(location.search),
      })
    );

    return () => {
      if (listener) {
        listener();
      }
    };
  }, []);

  function makeUrl(props: any) {
    return (
      history?.location?.pathname +
      getQueryString({
        ...query,
        ...props,
      })
    );
  }

  function goToUrl(props: any) {
    history?.replace?.(makeUrl(props), history?.location?.state);
  }

  return [(query as any) as T, goToUrl, makeUrl];
}
