import gql from 'graphql-tag';

import SectionType from '../../types/sections/SectionType';

export type SectionFragmentType = Pick<
  SectionType,
  | '_id'
  | '_created'
  | '_updated'
  | 'name'
  | 'description'
  | 'type'
  | 'query'
  | 'tags'
  | 'backgroundImage'
  | 'backgroundColor'
  | 'icon'
  | 'iconSet'
  | 'iconWeight'
  | 'logo'
  | 'color'
  | 'filters'
  | 'sorts'
  | 'groupBy'
>;

export default gql(`
  fragment SectionFragment on Section {
    _id
    _created
    _updated
    name
    name_l10n
    channel {
      _id
      settings {
        multiLanguageEnabled
        channelLanguages
        enabledLanguagesForMembers
      }
    }
    description
    type
    query
    tags
    backgroundImage
    backgroundColor
    icon
    iconSet
    iconWeight
    logo
    color
    filters
    sorts
    groupBy
  }
`);
