import colors from '../../config/colors';
import { ENVIRONMENTS } from 'activate-constants';
import env from '../../config/getEnv';
import analytics from './analytics';
import * as blocks from './blocks';
import * as cards from './cards';
import * as channel from './channel';
import * as content from './content';
import currencyCodes from './currencyCodes';
import * as dates from './dates';
import * as defaultChannelPages from './defaultChannelPages';
import events from './events';
import * as headers from './headers';
import * as icons from './icons';
import * as interactions from './interactions';
import * as invites from './invites';
import * as library from './library';
import * as metatags from './metatags';
import * as pagination from './pagination';
import { permissions } from './permissions';
import * as proxyclick from './proxyclick';
import requirements from './requirements';
import * as user from './user';
import * as weather from './weather';

export {
  channel,
  dates,
  defaultChannelPages,
  content,
  invites,
  user,
  library,
  pagination,
  interactions,
  metatags,
  blocks,
  cards,
  icons,
  proxyclick,
  weather,
  headers,
  currencyCodes,
  requirements,
  permissions,
  events,
  analytics,
};

export const DEFAULT_COLOR = colors.midnightCharcoal;
export const DEFAULT_BACKGROUND = '#ffffff';
export const DEFAULT_BACKGROUND_DARK = '#888888';

export const DEFAULT_WHITELABEL = 'lane';
export const DEFAULT_WHITELABEL_ID = '9e4ab524-1279-4a21-a625-72f908573eeb';
export const DEFAULT_EMAIL = 'lane.doe@example.com';
export const DEFAULT_NAME = 'Lane Doe';
export const DEFAULT_PHONE = '213-555-1234';
export const DEFAULT_COMPANY = 'Example Company Inc';
export const DEFAULT_NOREPLY_EMAIL = 'no-reply@activate.vts.com';

// used for sending emails from Activate to Axiis
export const NOREPLY_EMAIL = 'info@mybtenantapp.com';

export const CURRENCY_CONVERSION = 100;
// useful for when you want to sort by geo-location, but not omit any results.
export const CIRCUMFERENCE_OF_EARTH = 40075000;

export const commonInteractionQueries = [
  'searchContentInteractions',
  'interactionsQuery',
  'interactionsOnContent',
  'interactionsOnSection',
];

// User which manages Database Entities which would be affected by an integration

export const INTEGRATION_SYNC_USER_ID = 'D7A404CA-A1CC-4AED-B9E3-37CA4F9CD9C1';

// When dealing with percentages (that are allowed to have decimals),
// we store the values as integers. When converting the integer to some kind of
// float, make sure to divide the value by the base. <3 JC
// https://dzone.com/articles/never-use-float-and-double-for-monetary-calculatio
export const PERCENTAGE_BASE = 10000;

// default poll interval for queries from the server in ms.
export const POLL_INTERVAL = [
  ENVIRONMENTS.LOCAL,
  ENVIRONMENTS.DEVELOPMENT,
].includes(env)
  ? 5 * 60 * 1000
  : 10 * 60 * 1000;
