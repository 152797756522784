import { createContext } from 'react';

import { AnalyticsInterface } from '../types/Analytics';

export type AnalyticsContextType = AnalyticsInterface;

const AnalyticsContext = createContext<AnalyticsContextType>({
  track: () => undefined,
  identify: () => undefined,
  update: () => undefined,
  trackMixpanel: () => undefined,
  updateMixpanel: () => undefined,
  setMixpanelSessionId: () => undefined,
  setChannel: () => undefined,
  deleteMixpanelUserProfile: () => undefined,
  onNavigationStateChange: () => undefined,
});

export default AnalyticsContext;
