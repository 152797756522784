import gql from 'graphql-tag';

export default gql`
  query getHostsByUserTenantRelationship($channelId: UUID!, $search: String) {
    hostsByUserTenantRelationship(channelId: $channelId, search: $search) {
      id
      username
    }
  }
`;
