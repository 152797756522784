import gql from 'graphql-tag';

export default gql`
  mutation updateUserStatusesForChannel(
    $channelId: UUID!
    $userStatus: UserStatus
  ) {
    updateUserStatusesForChannel(channelId: $channelId, userStatus: $userStatus)
  }
`;
