import gql from 'graphql-tag';

export const updateVisitorPass = gql`
  mutation updateVisitorPass(
    $visitorPassId: UUID!
    $startDate: DateTime
    $endDate: DateTime
    $staffNote: String
    $visitorNote: String
    $additionalRecipients: [String]
  ) {
    updateVisitorPass(
      visitorPassId: $visitorPassId
      startDate: $startDate
      endDate: $endDate
      staffNote: $staffNote
      visitorNote: $visitorNote
      additionalRecipients: $additionalRecipients
    ) {
      id
    }
  }
`;
