import { gql } from 'graphql-query-contracts';

export const chargeCodeListQuery =  gql(/* GraphQL */ `
  query listChargeCode($listChargeCodeRequest: ListChargeCodeRequest!) {
    accounts {
      listChargeCode(listChargeCodeRequest: $listChargeCodeRequest) {
        chargeCodes {
          id
          chargeCode
          description
          glCode
          groupId
          groupType
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`);

export const chargeCodeFilterQuery =  gql(/* GraphQL */ `
  query chargeCodesForFilters($listChargeCodeRequest: ListChargeCodeRequest!) {
    accounts {
      listChargeCode(listChargeCodeRequest: $listChargeCodeRequest) {
        chargeCodes {
          chargeCode
          glCode
          id
        }
      }
    }
  }
`);