import urlJoin from 'url-join';

import { routes } from '../../../config';
import { convertTo62 } from '../../../helpers/convertId';

export const createVisitorSmsLinkUrl = (
  whiteLabelUrl: string,
  submissionId: string,
  visitorId: string
): string => {
  return urlJoin(
    whiteLabelUrl,
    routes.visitorSmsLink
      .replace(':submissionId', convertTo62(submissionId))
      .replace('visitorId', convertTo62(visitorId))
  );
};
