import gql from 'graphql-tag';

export default gql`
  query getInteractiveContentOnChannel(
    $pagination: PaginationInput
    $search: ContentSearch
    $channelId: UUID!
  ) {
    contentOnChannel(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        _created
        _updated
        name
        name_l10n
        description
        description_l10n
        type
        tags
        isInteractive
        liveDate
        startDate
        endDate
        unpublishDate
        lastReset
        nextReset
        state
        channel {
          _id
        }
        features {
          _id
          type
          feature
        }
        integration {
          _id
          settings
          integration {
            _id
            name
            settings
            platforms
          }
        }
        analytics {
          _id
          ratings
          rating
          views
          details
          hides
          deletes
          pins
          stars
          likes
          shares
          interactions
        }
      }
    }
  }
`;
