import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class GroupRoleRequirementsError extends CodedError {
  static get errorName() {
    return errorCodes.groupRoleRequirementsError.code;
  }

  constructor(message = errorCodes.groupRoleRequirementsError.message) {
    super(message);
    this.code = errorCodes.groupRoleRequirementsError.code;
    this.message = `${message} ${errorCodes.groupRoleRequirementsError.code}`;
    this.name = errorCodes.groupRoleRequirementsError.name;
  }
}
