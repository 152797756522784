import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import FeatureOptionToggle from 'components/renderers/features/components/FeatureOptionToggle';

export default function VisitorHostSelectionSettings({
  feature,
  settings,
  className,
  style,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  settings: VisitorManagementFeatureProperties;
  className?: string;
  style?: React.CSSProperties;
  onFeatureUpdated: (
    feature: Partial<VisitorManagementFeatureProperties>
  ) => void;
}) {
  const { t } = useTranslation();

  if (!feature.properties) return null;

  return (
    <div className={cx(className)} style={style}>
      <FeatureOptionToggle
        title={t(
          'web.admin.content.features.visitor.management.general.host.information.host.selection'
        )}
        onChange={value =>
          onFeatureUpdated({
            ...settings,
            hostSelectionEnabled: value,
          })
        }
        isExpanded={settings.hostSelectionEnabled}
        testId="hostSelectionToggle"
      />
    </div>
  );
}
