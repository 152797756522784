import { ContentType } from '../../types/content/Content';
import byName from '../sort/byName';

type Content = ContentType & {
  info?: {
    pinned: boolean;
  };
};

type Channel = {
  sections?: {
    sectionContent?: {
      content: Content;
    }[];
  }[];
};

export default function getPinnedContent(channels: Channel[]) {
  const pinned: Content[] = [];

  if (channels) {
    channels.forEach(
      channel =>
        channel &&
        channel.sections &&
        channel.sections.forEach(
          section =>
            section?.sectionContent &&
            section.sectionContent.forEach(sectionContent => {
              if (sectionContent.content?.info?.pinned) {
                pinned.push(sectionContent.content);
              }
            })
        )
    );
  }

  pinned.sort(byName);

  return pinned;
}
