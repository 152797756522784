import React from 'react';

import cx from 'classnames';

import styles from './Badge.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  value: number | string | React.ReactNode;
  title?: string;
  color?: string | null;
};

type Props = OwnProps;

function Badge({ className, style, value, title = '', color = null }: Props) {
  return (
    <div
      className={cx(styles.Badge, className)}
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      style={{ ...style, backgroundColor: color }}
    >
      <h1>{value}</h1>
      {title && <h2>{title}</h2>}
    </div>
  );
}

export default Badge;
