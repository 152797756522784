import { pollTransactionStatus, createTransactionRequest } from './index';
import {
  GetTransactionStatusResponse,
  TransactionPaidStatus,
  Invoice,
  Product,
} from 'lane-shared/domains/billingPayments/types';
import { PaymentMethod } from 'graphql-query-contracts';

type ProcessTransactionParams = {
  contentChannelId: string;
  paymentMethod?: string;
  paymentMethodType: PaymentMethod;
  accountsUserId: string | null;
  productName: string;
  currency: string;
  amount: number;
  invoice?: Invoice;
  products?: Product[];
  quantity?: number;
};

export async function processTransaction({
  contentChannelId,
  paymentMethod,
  paymentMethodType,
  accountsUserId,
  productName,
  currency,
  amount,
  invoice,
  products,
  quantity = 1,
}: ProcessTransactionParams): Promise<GetTransactionStatusResponse> {
  let transactionId;

  try {
    const createTransactionResponse = await createTransactionRequest(
      paymentMethod,
      paymentMethodType,
      contentChannelId,
      accountsUserId,
      productName,
      currency,
      amount,
      invoice,
      products,
      quantity
    );
    transactionId =
      createTransactionResponse.accounts.createTransaction.transactionId;

    const paymentPollingResult = await pollTransactionStatus(
      transactionId,
      accountsUserId
    );

    if (
      paymentPollingResult.transactionStatus === TransactionPaidStatus.FAILURE
    ) {
      console.error(
        `Payment failed, unable to complete transaction ${paymentPollingResult.transactionId}`
      );
      throw new Error('abp.errors.payment.transactionFailed');
    }

    return paymentPollingResult;
  } catch (err) {
    console.error(`Processing transaction failed, ${err}`);
    throw err;
  }
}
