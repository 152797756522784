import { SupportedLocaleEnum } from 'localization/src/lib/i18n';

export const SupportedLocalesStripeMap = {
  [SupportedLocaleEnum.English]: 'en',
  [SupportedLocaleEnum.Portuguese]: 'pt',
  [SupportedLocaleEnum.PortugueseBrazil]: 'pt-BR',
  [SupportedLocaleEnum.German]: 'de',
  [SupportedLocaleEnum.French]: 'fr-FR',
  [SupportedLocaleEnum.FrenchCanadian]: 'fr',
  [SupportedLocaleEnum.SpanishSpain]: 'es-ES',
  [SupportedLocaleEnum.SpanishMexico]: 'es',
  [SupportedLocaleEnum.EnglishUK]: 'en-GB',
  [SupportedLocaleEnum.Italian]: 'it-IT',
  [SupportedLocaleEnum.ChineseTraditional]: 'zh-HK',
  [SupportedLocaleEnum.Dutch]: 'nl',
  [SupportedLocaleEnum.GermanAustrian]: 'de',
  [SupportedLocaleEnum.Thai]: 'th',
  [SupportedLocaleEnum.Japanese]: 'ja',
  [SupportedLocaleEnum.SpanishCatalan]: 'es',
};
