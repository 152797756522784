import gql from 'graphql-tag';

import { ChannelSettingsRequirementsFragment } from 'lane-shared/graphql/fragments';

export default gql`
  ${ChannelSettingsRequirementsFragment}
  fragment FullContentForAdminFragment on ContentInterface {
    _id
    _created
    _updated
    _createdBy {
      ...PublicUserFragment
    }
    _updatedBy {
      ...PublicUserFragment
    }
    name
    name_l10n
    description
    description_l10n
    subtitle
    slug
    type
    tags
    category
    reportingGroup
    logo
    color
    isInteractive
    isSyndicateable
    geo
    publishedAt
    publishedBy {
      ...PublicUserFragment
    }
    backgroundImage
    backgroundColor
    icon
    iconSet
    iconWeight
    liveDate
    startDate
    endDate
    unpublishDate
    interactionEndDate
    resetPeriod
    lastReset
    nextReset
    signedExternalUrl
    externalUrl {
      _id
      url
      openingMode
      mediaId
    }
    deepLink {
      _id
      deepLinkUrl
      appStoreUrl
      googlePlayStoreUrl
    }
    card {
      _id
    }
    theme {
      ...ThemeFragment
    }
    channel {
      _id
      name
      description
      slug
      type
      isPublic
      isDiscoverable
      isLive
      isSub

      address {
        ...AddressFragment
      }

      profile {
        ...FullProfileWithThemeFragment
      }

      settings {
        requirements {
          ...ChannelSettingsRequirementsFragment
        }
        effectiveParentRequirements {
          ...ChannelSettingsRequirementsFragment
        }
      }
    }
    version
    renderer
    block
    properties
    propertiesOptions
    features {
      _id
      type
      feature
    }
    data
    state
    notifications {
      _id
      _created
      _updated
      title
      title_l10n
      sent
      sendAt
      content {
        _id
      }
    }

    contentMetatags {
      ...ObjectMetatagFragment
    }

    analytics {
      _id
      ratings
      rating
      views
      details
      hides
      deletes
      pins
      stars
      likes
      shares
      interactions
    }
    integration {
      _id
      settings
      integration {
        _id
        name
        settings
      }
    }

    publishedBy {
      ...PublicUserFragment
    }
    generator {
      _id
    }
  }
`;
