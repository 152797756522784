import gql from 'graphql-tag';

export default gql`
  query getAllEscalationRules($channelId: UUID!) {
    getAllEscalationRules(channelId: $channelId) {
      id
      entityType
      status
      timeToEscalate
      workflowId
      extRefId
      createdAt
      createdBy
      updatedAt
      updatedBy
      isArchived
    }
  }
`;
