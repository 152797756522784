import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class MissingParametersError extends CodedError {
  static get errorName() {
    return errorCodes.missingParametersError.code;
  }

  constructor(message = errorCodes.missingParametersError.message) {
    super(message);
    this.code = errorCodes.missingParametersError.code;
    this.message = `${message} ${errorCodes.missingParametersError.code}`;
    this.name = errorCodes.missingParametersError.name;
  }
}
