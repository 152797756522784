import gql from 'graphql-tag';

export default gql`
  query getDraftInteraction($contentId: UUID!, $includesOutdated: Boolean) {
    me {
      draftInteraction(
        contentId: $contentId
        includesOutdated: $includesOutdated
      ) {
        _id
        _created
        _updated
        content {
          _id
          type
        }
        interaction
      }
    }
  }
`;
