import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';

export function ButtonUpdateGroup({ isLoading, onSave }: any) {
  const { t } = useTranslation();

  return (
    <Button
      loading={isLoading}
      onClick={onSave}
      variant="contained"
      testId="buttonCreateOrSave"
    >
      {t('web.components.lane.Team.Header.ButtonUpdateGroup')}
    </Button>
  );
}
