import gql from 'graphql-tag';

export default gql`
  mutation updateChannelIntegration(
    $channelIntegration: ChannelIntegrationInput!
  ) {
    updateChannelIntegration(channelIntegration: $channelIntegration) {
      _id
      _updated
      deletedAt
      settings
    }
  }
`;
