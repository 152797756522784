import React from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { usePaymentProvider } from 'lane-shared/domains/billingPayments/hooks/usePaymentProvider';
import { CardForm } from './CardForm';
import { PaymentSource } from 'graphql-query-contracts';
import useUserLocale from 'lane-shared/hooks/useUserLocale';
import { ElementOptions } from 'lane-web/src/domains/billingAndPayments/components/StripeElementOptions';

type AddStripeCardProps = {
  handleSubmit: () => void;
  cards: PaymentSource[];
  updateCards: (cards: PaymentSource[]) => void;
  savePaymentMethod: (paymentMethodId: string) => Promise<void>;
  updatePaymentMethod: (id: string) => void;
  goBack: () => void;
  isWallet?: boolean;
};

export function AddStripeCard({
  handleSubmit,
  cards,
  updateCards,
  savePaymentMethod,
  updatePaymentMethod,
  goBack,
  isWallet,
}: AddStripeCardProps) {
  const { provider } = usePaymentProvider();
  const userLocale = useUserLocale();
  const elementOptions = ElementOptions(userLocale);

  return (
    <Elements stripe={provider} options={elementOptions}>
      <CardForm
        handleParentSubmit={handleSubmit}
        goBack={goBack}
        cards={cards}
        updateCards={updateCards}
        updatePaymentMethod={updatePaymentMethod}
        savePaymentMethod={savePaymentMethod}
        isWallet={isWallet}
      />
    </Elements>
  );
}
