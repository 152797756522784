import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  SupportedPaymentCurrency,
  ProductType,
  AddProductToContentDetails,
} from 'lane-shared/domains/billingPayments/types';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

import { M, S, Column, Button, Modal } from 'design-system-web';

export function useTableColumnsAddedProducts({
  currency,
  removeProduct,
  inclusivePricing,
}: {
  currency: SupportedPaymentCurrency;
  removeProduct: (product: AddProductToContentDetails) => void;
  inclusivePricing: boolean;
}): Column<AddProductToContentDetails>[] {
  const { t } = useTranslation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const formatCurrency = currencyFormatter({
    currency,
    currencyDisplay: 'narrowSymbol',
  });

  const renderFixedAmountCell = (cell: string) => {
    return formatCurrency(Number(cell));
  };

  const renderCreditAmountCell = (cell: string) => {
    return Number(cell) || '-';
  };

  const renderRemoveButton = (data: AddProductToContentDetails) => {
    return (
      <>
        <Button
          onClick={() => setIsConfirmationModalOpen(true)}
          testId="removeButton"
          variant="secondary"
        >
          {t('web.content.features.paymentv2.addedProductsTable.remove')}
        </Button>
        <Modal
          isOpen={isConfirmationModalOpen}
          onClose={() => {
            setIsConfirmationModalOpen(false);
          }}
          title={t('web.content.features.paymentv2.removeProductAlert.title', {
            productName: data.name,
          })}
          actions={
            <>
              <Button
                testId="removeProductAlertRemoveButton"
                onClick={() => {
                  setIsConfirmationModalOpen(false);
                  removeProduct(data);
                }}
                size="large"
              >
                {t('web.content.features.paymentv2.removeProductAlert.remove')}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsConfirmationModalOpen(false);
                }}
                size="large"
              >
                {t('web.content.features.paymentv2.removeProductAlert.cancel')}
              </Button>
            </>
          }
        >
          <M>
            {t('web.content.features.paymentv2.removeProductAlert.message')}
          </M>
        </Modal>
      </>
    );
  };

  const renderNameCell = (cell: string, row: AddProductToContentDetails) => {
    return (
      <div>
        <div>
          <S variant="secondary">
            {t(`abp.productsServices.productType.${row.productCategoryType}`)}
          </S>
          {row.productCategoryType !==
            ProductType.PRODUCT_TYPE_CREDIT_BUNDLE && (
            <>
              {' '}
              <S variant="secondary">{`>`}</S>{' '}
              <S variant="secondary">{row.productCategoryName}</S>
            </>
          )}
        </div>
        <M>{cell}</M>
      </div>
    );
  };

  let taxHeader = t('web.content.features.paymentv2.addedProductsTable.tax');
  if (inclusivePricing) {
    taxHeader += ` ${t('web.content.features.paymentv2.tax.included')}`;
  }

  return [
    {
      key: 'name',
      header: t('web.content.features.paymentv2.addedProductsTable.name'),
      renderCell: (cell: string, row: AddProductToContentDetails) =>
        renderNameCell(cell, row),
    },
    {
      key: 'listPrice',
      header: t('web.content.features.paymentv2.addedProductsTable.subtotal'),
      renderCell: renderFixedAmountCell,
    },
    {
      key: 'grossTax',
      header: taxHeader,
      renderCell: renderFixedAmountCell,
    },
    {
      key: 'netPrice',
      header: t('web.content.features.paymentv2.addedProductsTable.total', {
        currency,
      }),
      renderCell: renderFixedAmountCell,
    },
    {
      key: 'creditAmount',
      header: t(
        'web.content.features.paymentv2.addedProductsTable.creditTotal'
      ),
      renderCell: renderCreditAmountCell,
    },
    {
      key: 'actions',
      header: '',
      renderCell: (_: unknown, row: AddProductToContentDetails) =>
        renderRemoveButton(row),
    },
  ];
}
