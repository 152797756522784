import { v4 as uuid } from 'uuid';

import { ProfileType } from '../types/ProfileType';
import { DEFAULT_BACKGROUND, DEFAULT_COLOR } from './constants';

export default function constructProfile({
  userId,
  email,
}: {
  userId?: string;
  email?: string;
}): ProfileType {
  return {
    _id: uuid(),
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    _order: 0,
    name: '',
    description: '',
    email,
    phone: null,
    image: null,
    logo: null,
    color: DEFAULT_COLOR,
    backgroundColor: DEFAULT_BACKGROUND,
    isPublic: false,
    content: null,
  } as any; // TODO: fix typing of this
}
