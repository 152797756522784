import { useContext } from 'react';

import BigNumber from 'bignumber.js';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import {
  CurrencyFormatMap,
  Invoice,
} from 'lane-shared/domains/billingPayments/types';
import { dates } from 'lane-shared/helpers/constants';
import currencyFormatter from 'lane-shared/helpers/formatters/currencyFormatter';
import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';
import { SupportedLocaleEnum } from 'localization';
import { PaymentMethod } from 'graphql-resolver-contracts';

import { processTransaction } from './helpers';

export type HookOptions = {
  invoice: Invoice;
  locale: SupportedLocaleEnum;
};

export function usePaymentV2FeatureInvoice({ invoice, locale }: HookOptions) {
  const { primaryId } = useContext(ChannelsContext);

  const invoiceDisplayName = invoice.displayId?.substring(0, 30);
  const dueDate = invoice.dueDate
    ? dateFormatter(
        invoice.dueDate,
        dates.LONG_MONTH_DATE_YEAR,
        undefined,
        locale
      )
    : '-';
  const currency = CurrencyFormatMap[invoice.currency];
  const amount = currencyFormatter({ currency, locale, style: 'currency' })(
    invoice.amount
  );
  const orderSummaryData = {
    items: [
      {
        name: invoiceDisplayName,
        description: dueDate,
        dollarAmount: amount,
        quantity: null,
      },
    ],
    subTotal: amount,
    taxes: [],
    total: amount,
    currency,
  };

  async function initiatePayment(
    accountsUserId: string | null,
    selectedPaymentMethod: string | undefined,
    paymentMethodType: PaymentMethod
  ) {
    if (!primaryId) {
      throw new Error('cannot submit payment without primary id');
    }
    if (!orderSummaryData.currency) {
      throw new Error('cannot submit payment without currency');
    }
    if (!orderSummaryData?.items[0]?.name) {
      throw new Error('cannot purchase product without product name');
    }

    const invoiceAmount = new BigNumber(invoice.amount).multipliedBy(100);

    // Could invoice.groupId and primaryId be different?
    // I.e. the channel where invoice is created is different from channel where payment is happening?
    return await processTransaction({
      contentChannelId: invoice.groupId,
      paymentMethod: selectedPaymentMethod,
      paymentMethodType,
      accountsUserId,
      productName: orderSummaryData.items[0].name,
      currency: orderSummaryData.currency,
      amount: Number(invoiceAmount),
      invoice,
    });
  }

  return {
    orderSummaryData,
    initiatePayment,
  };
}
