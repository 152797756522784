import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import convertToUUID from 'lane-shared/helpers/convertId/convertToUUID';
import { OwnerType } from 'graphql-resolver-contracts';

const getUserCreditWalletsQuery = gql`
  query GetUserCreditWallets(
    $getUserCreditWalletsRequest: GetUserCreditWalletsRequest
  ) {
    accounts {
      getUserCreditWallets(
        getUserCreditWalletsRequest: $getUserCreditWalletsRequest
      ) {
        creditWallets {
          id
          numberOfCredits
          valueOfEachCredit
          owner {
            id
            type
          }
          group {
            id
            type
          }
          channelProfile {
            id
            name
            logo
            image
          }
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`;

// Hardcode value as 10
// we are not expecting more than a couple for a really long time
// there are also no UI for pagination for now, just flex-wrap
export async function getUserCreditWallets(userId: string) {
  const { data } = await getClient().query({
    query: getUserCreditWalletsQuery,
    variables: {
      getUserCreditWalletsRequest: {
        owner: {
          id: convertToUUID(userId),
          type: OwnerType.OwnerTypeActivateUser,
        },
        pagination: {
          start: 0,
          perPage: 10,
        },
      },
    },
  });

  return data;
}
