import gql from 'graphql-tag';

export default gql`
  query existingUserLogins($email: EmailAddress!) {
    loginEmailExists(email: $email)
    oAuthLogins: existingUserLogins(email: $email, type: OAuth) {
      _id
      type
      provider
    }
  }
`;
