import { FileInput } from 'components';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { Invite } from './types';
import { v4 as uuid } from 'uuid';
import { Button, Icon } from 'design-system-web';
import styles from './styles.scss';

const MAX_IMPORT_ROWS = 1000;

function parseString(str: any) {
  if (!str) {
    return '';
  }

  return str.replace(/"/gi, '').trim();
}

type Props = {
  loading: boolean;
  groupRoleId: string;
  setInvites: (invites: any) => void;
};

export const TeamCsvImportButton = ({
  loading,
  groupRoleId,
  setInvites,
}: Props) => {
  const { t } = useTranslation();

  function tryByHeader(text: string): Invite[] {
    const newInvites: Invite[] = [];

    const parsed = Papa.parse(text, {
      header: true,
      skipEmptyLines: 'greedy',
    });

    // try to find headers.
    if (parsed?.data) {
      // try to find email header.
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const emailField = parsed.meta.fields.find(field =>
        field.toLowerCase().includes('email')
      );

      if (!emailField) {
        // try parsing by name,email
        throw new Error(
          t(
            'web.admin.channel.teamManagement.team.BulkSendInvite.emailMissingError'
          )
        );
      }

      // try to find a name header
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const nameField = parsed.meta.fields.find(field =>
        field.toLowerCase().includes('name')
      );

      parsed.data
        .filter(row => (row as any)[emailField])
        .forEach((row, i) => {
          if (i < MAX_IMPORT_ROWS) {
            newInvites.push({
              _id: uuid(),
              email: parseString((row as any)[emailField]),
              name:
                (nameField && parseString((row as any)[nameField])) ||
                parseString((row as any)[emailField]),
              groupRoleId,
            });
          }
        });

      if (parsed.data.length > MAX_IMPORT_ROWS) {
        window.Toast.show(
          <p>
            {t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.csvImportLowerLimitMessage',
              { maxImportRows: MAX_IMPORT_ROWS }
            )}
          </p>
        );
      }
    }

    return newInvites;
  }

  function tryByColumn(text: string): Invite[] {
    const newInvites: Invite[] = [];

    const parsed = Papa.parse(text);
    const emailIndex = (parsed.data as any)[0].findIndex(
      (col: any) => col.includes('@') && col.includes('.')
    );

    if (emailIndex < 0) {
      throw new Error(
        t(
          'web.admin.channel.teamManagement.team.BulkSendInvite.emailMissingError'
        )
      );
    }

    parsed.data.forEach((row, i) => {
      if (i < MAX_IMPORT_ROWS) {
        newInvites.push({
          _id: uuid(),
          email: parseString((row as any)[emailIndex]),
          name: parseString((row as any)[emailIndex]),
          groupRoleId,
        });
      }
    });

    if (parsed.data.length > MAX_IMPORT_ROWS) {
      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.BulkSendInviteV2.csvImportLowerLimitMessage',
            { maxImportRows: MAX_IMPORT_ROWS }
          )}
        </p>
      );
    }

    return newInvites;
  }

  function onUploadCsv(text: string) {
    try {
      setInvites(tryByHeader(text));
    } catch (err) {
      try {
        setInvites(tryByColumn(text));
      } catch (err2) {
        window.Alert.alert({
          title: t(
            'web.admin.channel.teamManagement.team.BulkSendInvite.invalidFileTitle'
          ),
          message: t(
            'web.admin.channel.teamManagement.team.BulkSendInvite.invalidFileMessage'
          ),
          error: err2,
        });
      }
    }
  }

  return (
    <FileInput
      disabled={loading}
      loading={loading}
      accept=".csv, text/plain"
      type={FileReturnTypeEnum.Text}
      onFileSelected={(text: any) => onUploadCsv(text)}
      testId="csv-import-button-for-user-input-list"
    >
      <Button
        className={styles.button}
        loading={loading}
        variant="secondary"
        startIcon={<Icon name="upload" />}
      >
        {t(
          'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.uploadCsvButtonText'
        )}
      </Button>
    </FileInput>
  );
};
