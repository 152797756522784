import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { parseDateTime } from 'lane-shared/helpers/dates';

export default function useDatePickerRow({
  timeZone,
  timeUnit,
  value,
  quickTimeUnit,
  maxDate,
  minDate,
  onChange,
}: any) {
  const [now, setNow] = useState(DateTime.local().setZone(timeZone));
  const [isSameDay, setIsSameDay] = useState(false);

  useEffect(() => {
    setNow(DateTime.local().setZone(timeZone));
  }, [timeZone]);

  const _value = parseDateTime(value);
  const month = _value ? _value?.month : now.month;
  const year = _value?.year || now.year;
  const day = _value?.day || now.day;

  useEffect(() => {
    if (minDate && maxDate) {
      const min = parseDateTime(minDate);
      const max = parseDateTime(maxDate);

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      setIsSameDay(min.hasSame(max, 'day'));
    }
  }, [maxDate, minDate]);

  function enforceDate(newDate: any) {
    if (maxDate && newDate > maxDate) {
      onChange(maxDate);
      return;
    }

    if (minDate && newDate < minDate) {
      onChange(minDate);
      return;
    }
    const _date = parseDateTime(newDate);
    onChange(_date);
  }

  const nextOneOrTenYears = timeUnit === 'year' ? 120 : 12;

  function nextUnit() {
    enforceDate((_value || now).plus({ [quickTimeUnit]: 1 }).toJSDate());
  }

  function previousUnit() {
    enforceDate((_value || now).minus({ [quickTimeUnit]: 1 }).toJSDate());
  }

  function nextYear() {
    enforceDate(
      (_value || now).plus({ [quickTimeUnit]: nextOneOrTenYears }).toJSDate()
    );
  }

  function previousYear() {
    enforceDate(
      (_value || now).minus({ [quickTimeUnit]: nextOneOrTenYears }).toJSDate()
    );
  }

  function updateDay(day: any) {
    enforceDate((_value || now).set({ day }).toJSDate());
  }

  function updateYear(year: any) {
    enforceDate((_value || now).set({ year }).toJSDate());
  }

  function updateMonth(month: any) {
    enforceDate((_value || now).set({ month }).toJSDate());
  }

  const dayItems: { label: string; value: string }[] = [];
  const daysInMonth = (_value || now).daysInMonth;

  for (let i = 0; i < daysInMonth; i++) {
    dayItems.push({
      label: `${i + 1}`,
      value: `${i + 1}`,
    });
  }

  return {
    dayItems,
    isSameDay,
    nextUnit,
    previousUnit,
    nextYear,
    previousYear,
    updateDay,
    updateMonth,
    updateYear,
    month,
    year,
    day,
  };
}
