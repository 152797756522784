import { useQuery } from '@apollo/client';
import {
  GroupRole,
  GetUsersByChannelIdQuery,
  User,
} from 'graphql-query-contracts';

import {
  getChannelGroupRoles,
  getUsersByChannelId,
} from 'lane-shared/graphql/query';

import { DateTime } from 'luxon';

import { UserLoginType } from 'lane-shared/types/UserLogin';

export type AllUsersRes = {
  _id: string;
  _created: string | DateTime | Date | null | undefined;
  status: string;
  lastSeen: string;
  logins: UserLoginType[];
  name: string;
  person?: {
    units: {
      name: string;
      floors?: { name: string }[];
    }[];
    resident: { status: string; type: string };
  };
  company: {
    _id: string;
    name: string;
  }[];
  roles: {
    _id: string;
    groupRole: {
      name: string;
    };
    relatedChannels: {
      _id: string;
      name: string;
    }[];
  };
};

export type AllUsersSearch = {
  groupRoles: string;
  company: string;
  keyword: string;
  sortBy: string;
  sortDirection: string;
};

export const useAllUsers = (
  channelId: string,
  _includeResidentInfo: boolean = false,
  {
    groupRoles: groupRolesSearch,
    company,
    keyword,
    sortBy,
    sortDirection,
  }: AllUsersSearch,
  pagination: { page: string; pageSize: string }
) => {
  const {
    data: groupRoles,
    loading: groupRolesLoading,
  }: {
    data?: {
      channel: {
        _id: string;
        _created: any;
        groupRoles: GroupRole[];
      };
    };
    loading: boolean;
  } = useQuery(getChannelGroupRoles, {
    variables: {
      id: channelId,
    },
    fetchPolicy: 'network-only',
  });
  const {
    data,
    loading: userLoading,
    refetch,
  } = useQuery<GetUsersByChannelIdQuery>(
    // Resident info query currently not in use
    // includeResidentInfo
    //   ? queryChannelUsersByGroupRolesIncludeResidentInfo
    //   : queryChannelUsersByGroupRoles,
    getUsersByChannelId,
    {
      variables: {
        search: {
          groupRoleIds:
            groupRolesSearch?.length > 0 ? groupRolesSearch.split(',') : [],
          company: company?.length > 0 ? company.split(',') : [],
          search: keyword,
          ...(sortBy
            ? {
                sortBy: {
                  key: sortBy,
                  dir: sortDirection,
                },
              }
            : {}),
        },
        channelId,
        pagination: {
          start: Number(pagination.page) * Number(pagination.pageSize),
          perPage: Number(pagination.pageSize),
        },
      },
      fetchPolicy: 'network-only',
    }
  );
  const { items, pageInfo } = data?.usersByChannelId ?? {};

  const loading = groupRolesLoading || userLoading;

  return {
    users: (items ?? []) as User[],
    total: pageInfo?.total ?? 0,
    groupRoles,
    loading,
    refetch,
  };
};
