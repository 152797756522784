import React, { useReducer } from 'react';

import cx from 'classnames';
import { Dropdown } from 'components';
import { useTranslation } from 'react-i18next';

import { removeTime } from 'lane-shared/domains/visitorManagement/helpers';
import {
  createScheduleReducer,
  ScheduleActionType,
  ScheduleReducerType,
} from 'lane-shared/domains/visitorManagement/reducers';
import {
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
  repeatDropdownItems,
} from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';

import Checkbox from 'components/form/Checkbox';
import { S, TimePicker, TimeIntervals } from 'design-system-web';

import DatePickerButton from '../DatePickers/DatePickerButton';
import { IntervalContent } from './components/IntervalContent';

import styles from './ScheduleRepeat.scss';

export type ScheduleRepeatHandle = {
  reset: () => void;
};

type Props = {
  className?: string;
  allDayPassEnabled: boolean;
  defaultIsAllDay: boolean;
  defaultStartDate: Date;
  defaultEndDate: Date;
  defaultStartTime?: Date;
  defaultEndTime?: Date;
  defaultInterval: IntervalTypeEnum;
  defaultEndRepeatDate: Date;
  defaultIntervalCount: number;
  defaultWeekdayRepeats: number[];
  defaultMonthIntervalType: MonthIntervalTypeEnum;
  timeZone?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: ScheduleReducerType) => void;
  isPreview?: boolean;
};

function ScheduleDateTimeRepeat({
  className,
  allDayPassEnabled,
  defaultIsAllDay,
  defaultStartDate,
  defaultEndDate,
  defaultStartTime,
  defaultEndTime,
  defaultInterval,
  defaultEndRepeatDate,
  defaultIntervalCount,
  defaultWeekdayRepeats,
  defaultMonthIntervalType,
  timeZone,
  minDate,
  maxDate,
  onChange,
  isPreview = false,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const reducerFunction = createScheduleReducer(
    {
      isAllDay: defaultIsAllDay,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      startTime: defaultStartTime ?? defaultStartDate,
      endTime: defaultEndTime ?? defaultEndDate,
      intervalType: defaultInterval,
      endRepeatDate: defaultEndRepeatDate,
      intervalCount: defaultIntervalCount,
      weekdayRepeats: defaultWeekdayRepeats,
      monthIntervalType: defaultMonthIntervalType,
    },
    onChange,
    timeZone
  );

  const [state, dispatch] = useReducer<typeof reducerFunction>(
    reducerFunction,
    {
      isAllDay: defaultIsAllDay,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      internalStartDate: removeTime(defaultStartDate, timeZone),
      internalEndDate: removeTime(defaultEndDate, timeZone),
      startTime: defaultStartTime ?? defaultStartDate,
      endTime: defaultEndTime ?? defaultEndDate,
      intervalType: defaultInterval,
      endRepeatDate: defaultEndRepeatDate,
      intervalCount: defaultIntervalCount,
      weekdayRepeats: defaultWeekdayRepeats,
      monthIntervalType: defaultMonthIntervalType,
    }
  );

  return (
    <div className={cx(styles.scheduleRepeat, className)}>
      {allDayPassEnabled && (
        <div className={styles.allDayCheckboxContainer}>
          <Checkbox
            selected={state.isAllDay}
            value={state.isAllDay}
            onChange={() => {
              dispatch({
                type: ScheduleActionType.ToggleAllDay,
              });
            }}
            className={styles.allDayCheckbox}
            labelStyles={{ fontSize: 16, paddingLeft: 7 }}
            text={t('web.content.feature.visitorManagement.form.allDay')}
            testId="allDayCheckbox"
            disabled={isPreview}
          />
        </div>
      )}
      <div className={cx(styles.dateOptions, styles.inputContainer)}>
        <div className={styles.dateContainer}>
          <div className={styles.date}>
            <S>{t('web.content.feature.visitorManagement.form.selectDate')}</S>
            <DatePickerButton
              value={state.startDate}
              hideLabel
              className={styles.datePicker}
              buttonClassName={styles.datePickerButton}
              onChange={date =>
                dispatch({
                  type: ScheduleActionType.SetDate,
                  value: { date },
                })
              }
              minDate={minDate}
              disabled={isPreview}
              timeZone={timeZone}
            />
          </div>
        </div>
        {(!allDayPassEnabled || !state.isAllDay) && (
          <div className={styles.timesContainer}>
            <div className={styles.times}>
              <div className={styles.group}>
                <S>
                  {t(
                    'web.content.feature.visitorManagement.scheduler.form.startTime'
                  )}
                </S>
                <TimePicker
                  className={styles.timePicker}
                  buttonClassName={styles.timePickerButton}
                  hideLabel
                  value={state.startTime}
                  onChange={value => {
                    dispatch({
                      type: ScheduleActionType.SetStartTime,
                      value: { date: new Date(value) },
                    });
                  }}
                  showClear={false}
                  showIcon={false}
                  timeZone={timeZone}
                  unit={TimeIntervals.QUARTER_HOUR}
                  testId="startTime"
                  disabled={isPreview}
                />
              </div>
              <div className={styles.group}>
                <S>
                  {t(
                    'web.content.feature.visitorManagement.scheduler.form.endTime'
                  )}
                </S>
                <TimePicker
                  hideLabel
                  className={styles.timePicker}
                  buttonClassName={styles.timePickerButton}
                  value={state.endTime}
                  onChange={value => {
                    dispatch({
                      type: ScheduleActionType.SetEndTime,
                      value: { date: new Date(value) },
                    });
                  }}
                  showClear={false}
                  showIcon={false}
                  timeZone={timeZone}
                  unit={TimeIntervals.QUARTER_HOUR}
                  testId="endTime"
                  disabled={isPreview}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={cx(styles.dropdownContainer, styles.inputContainer)}>
        <S>{t('web.content.feature.visitorManagement.form.repeats')}</S>
        <Dropdown
          onValueChange={value => {
            dispatch({
              type: ScheduleActionType.SetIntervalType,
              value: { string: value },
            });
          }}
          items={repeatDropdownItems.map(item => ({
            label: t(item.label),
            value: item.value,
          }))}
          isSearchable={false}
          className={styles.dropdown}
          value={state.intervalType}
          testId="RepeatInterval"
        />
      </div>
      <IntervalContent
        state={state}
        dispatch={dispatch}
        defaultIntervalCount={defaultIntervalCount}
        defaultMonthIntervalType={defaultMonthIntervalType}
        timeZone={timeZone}
      />
      <div className={styles.dropdownContainer}>
        <S>{t('web.content.feature.visitorManagement.form.endsOn')}</S>
        <div className={styles.endsContainer}>
          <DatePickerButton
            value={state.endRepeatDate}
            className={styles.endRepeatDate}
            onChange={date =>
              dispatch({
                type: ScheduleActionType.SetEndRepeatDate,
                value: { date },
              })
            }
            hideLabel
            maxDate={maxDate}
            minDate={state.startDate}
            wrapperClassName={styles.specificDateInput}
            disabled={isPreview}
            timeZone={timeZone}
          />
        </div>
      </div>
    </div>
  );
}

export default ScheduleDateTimeRepeat;
