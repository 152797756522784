import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

type PlacePayData = {
  error_code: number;
  plugin_id: string;
  type: 'ERROR' | string; // NOTE: Not sure what other enumerations may exist
};

export default class PlacePayError extends CodedError {
  data: PlacePayData | undefined;

  static get errorName() {
    return errorCodes.placePayError.code;
  }

  static get errorMessage() {
    return errorCodes.placePayError.message;
  }

  constructor(message = errorCodes.placePayError.message) {
    super(message);
    this.code = errorCodes.placePayError.code;
    this.message = `${message} ${errorCodes.placePayError.code}`;
    this.name = errorCodes.placePayError.name;
  }

  // PlacePay can provide a useful plugin error code
  // if this captured, it can possible assist in
  // troubleshooting.
  setData(data: any) {
    this.data = data;
    if (data.plugin_id) {
      this.message = this.message.replace(/PLP\d+/gi, data.plugin_id);
    }
  }
}
