import { DateTime, Duration } from 'luxon';
import { rrulestr } from 'rrule';
import { ParsedRecurringSchedule } from '../types';

export const parseDuration = (durationString: string): Duration => {
  return Duration.fromISO(durationString);
};

export const parseRRuleSchedule = (
  scheduleString: string,
  scheduleDuration: string
): ParsedRecurringSchedule => {
  const rrule = rrulestr(scheduleString);
  const rruleOptions = rrule.options;

  const scheduleText = `${rrule.toText().split('until')[0]}between`;

  const scheduleStartsOn = rruleOptions.dtstart.toISOString();
  const scheduleEndsOn = rruleOptions.until
    ? rruleOptions.until.toISOString()
    : '';

  const duration = parseDuration(scheduleDuration);
  const dayPassEndTime = DateTime.fromJSDate(rruleOptions.dtstart)
    .plus(duration)
    .toString();

  return {
    scheduleText,
    scheduleStartDatetime: scheduleStartsOn,
    scheduleEndDate: scheduleEndsOn,
    eventEndTime: dayPassEndTime,
  };
};
