const INVITE_STATUS = {
  CREATED: 'Created',
  SENT: 'Sent',
  FAILED: 'Failed',
  REJECTED: 'Rejected',
  DECLINED: 'Declined',
  ACCEPTED: 'Accepted',
  RESENT: 'Resent',
};

export { INVITE_STATUS };
