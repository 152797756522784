import { gql } from 'graphql-query-contracts';

export const listBillingReportsQuery = gql(/* GraphQL */ `
  query listBillingReports(
    $group: GroupInput!
    $filters: ChargeReportFilters
    $pagination: PaginationInput
    $forExport: Boolean
    $sort: SortBy
  ) {
    accounts {
      listBillingReports(
        group: $group
        filters: $filters
        pagination: $pagination
        forExport: $forExport
        sort: $sort
      ) {
        reports {
          payer {
            id
            type
          }
          chargeCode
          chargeId
          createdAt
          chargeMonth
          currency
          description
          glCode
          leaseId
          name
          propertyId
          suiteId
          entityDisplayId
          entityDisplayType
          totalAmount
          group {
            id
            type
          }
          entity {
            id
            type
          }
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`);
