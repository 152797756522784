import gql from 'graphql-tag';

export default gql`
  mutation updateSection($section: SectionInput!) {
    updateSection(section: $section) {
      _id
      _created
      _updated
      name
      description
      type
      query
      tags
      backgroundImage
      backgroundColor
      icon
      iconSet
      iconWeight
      logo
      color
      filters
      sorts
      groupBy
      sectionMetatags {
        _id
        metatag {
          _id
          name
          description
          type
          properties
        }
        value
      }
      tagsOnSection {
        id
        name
      }
    }
  }
`;
