export const DAY_KEYS_BY_NUMBER = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  7: 'SUNDAY',
};

export const DAYS_OF_THE_WEEK = {
  [DAY_KEYS_BY_NUMBER[7]]: {
    name: 'Sunday',
    numericValue: 7,
  },
  [DAY_KEYS_BY_NUMBER[1]]: {
    name: 'Monday',
    numericValue: 1,
  },
  [DAY_KEYS_BY_NUMBER[2]]: {
    name: 'Tuesday',
    numericValue: 2,
  },
  [DAY_KEYS_BY_NUMBER[3]]: {
    name: 'Wednesday',
    numericValue: 3,
  },
  [DAY_KEYS_BY_NUMBER[4]]: {
    name: 'Thursday',
    numericValue: 4,
  },
  [DAY_KEYS_BY_NUMBER[5]]: {
    name: 'Friday',
    numericValue: 5,
  },
  [DAY_KEYS_BY_NUMBER[6]]: {
    name: 'Saturday',
    numericValue: 6,
  },
};

export enum DayOfWeek {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}
