import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { DeleteProductRequest } from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'lane-shared/helpers/convertId';

export const mutation = gql`
  mutation deleteProduct($deleteProductRequest: DeleteProductRequest) {
    deleteProduct(deleteProductRequest: $deleteProductRequest)
  }
`;

export async function deleteProductMutation(
  productId: string,
  channelId: string
) {
  return await getClient().mutate<{
    deleteProductRequest: DeleteProductRequest;
  }>({
    mutation,
    variables: {
      deleteProductRequest: {
        id: productId,
        group: {
          id: convertToUUID(channelId),
          type: GroupType.GroupTypeActivateChannel,
        },
      },
    },
  });
}
