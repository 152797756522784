import gql from 'graphql-tag';

/**
 * GQL Update Preventive Maintenance Task Mutation
 */

export default gql`
  mutation updatePMTaskStatusMutation($task: UpdateTaskStatus!) {
    updateTaskStatus(task: $task) {
      id
      updatedAt
    }
  }
`;
