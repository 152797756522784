import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class LoginInvalidError extends CodedError {
  static get errorName() {
    return errorCodes.loginInvalidError.code;
  }

  constructor(message = errorCodes.loginInvalidError.message) {
    super(message);
    this.code = errorCodes.loginInvalidError.code;
    this.message = `${message} ${errorCodes.loginInvalidError.code}`;
    this.name = errorCodes.loginInvalidError.name;
  }
}
