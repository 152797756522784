import gql from 'graphql-tag';

export const createTag = gql`
  mutation createTag($tag: TagInput!) {
    createTag(tag: $tag) {
      id
      name
    }
  }
`;
