import gql from 'graphql-tag';

export default gql`
  query getUserLogins {
    me {
      user {
        _id
        logins {
          _id
          _created
          _updated
          type
          provider
          isPrimary
          key
          status
          tokenExpiresAt
        }
      }
    }
  }
`;
