import React from 'react';

import cx from 'classnames';

import { objectToArray } from 'lane-shared/helpers';
import { MetatagValue } from 'lane-shared/types/Metatag';
import { MetatagFilterUI } from 'lane-shared/types/filters/MetatagFilter';

import { Toggle } from 'components/form';
import { XS } from 'components/typography';

import PropertyValue from '../builder/properties/display/PropertyValue';
import Checkbox from '../form/Checkbox';

import styles from './MetatagComplexOption.scss';

type Props = {
  metatag: MetatagFilterUI;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  values?: MetatagValue[];
  isEnabled?: boolean;
  allowDisableFilter?: boolean;
  onChange?: (value: any) => void;
  onEnable?: (value: boolean) => void;
};

export default function MetatagComplexOption({
  className,
  style,
  loading,
  metatag,
  values = [],
  isEnabled = true,
  allowDisableFilter = false,
  onChange = () => null,
  onEnable = () => null,
}: Props) {
  const fields = objectToArray(metatag.properties);

  function checkboxClickHandler(
    isSelected: boolean,
    metatagValue: MetatagValue
  ) {
    if (loading || !onChange) {
      return;
    }

    onChange(
      isSelected
        ? // @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
          [...values.filter(_id => _id !== metatagValue._id)]
        : [...values, metatagValue._id]
    );
  }

  function renderOption(metatagValue: MetatagValue) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const isSelected = values?.includes(metatagValue._id);

    return (
      <li
        key={metatagValue._id}
        onClick={() => checkboxClickHandler(isSelected, metatagValue)}
        data-is-selected={isSelected}
      >
        <Checkbox
          selected={isSelected}
          value={metatagValue.value}
          onChange={() => checkboxClickHandler(isSelected, metatagValue)}
          testId={`checkbox-${metatagValue._id}`}
        />
        {fields.map((field, ix) => (
          <PropertyValue
            key={field._id || field.name || ix}
            field={field}
            // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
            value={metatagValue.value[field.name]}
          />
        ))}
      </li>
    );
  }

  return (
    <div className={cx(styles.MetatagComplexOption, className)} style={style}>
      <h1>
        {allowDisableFilter ? (
          <Toggle
            doTranslate
            value={isEnabled}
            text={metatag.name}
            onChange={value => {
              onEnable(value);
            }}
          />
        ) : (
          metatag.name
        )}
      </h1>
      {metatag.showDescription && <XS>{metatag.description}</XS>}
      <ul>{metatag.values?.map(renderOption)}</ul>
    </div>
  );
}
