import React, { useContext } from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { ChannelsContext } from 'lane-shared/contexts';
import { shortAddress } from 'lane-shared/helpers/formatters';

import ChannelCircleListView from './ChannelCircleListView';

import styles from './MySubscriptionsPicker.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  onChannelSelected?: (...args: any[]) => any;
};

type Props = OwnProps;

function MySubscriptionsPicker({ className, style, onChannelSelected = () =>{} }: Props) {
  const { channels } = useContext(ChannelsContext);

  return (
    <div className={cx(styles.ChannelPicker, className)} style={style}>
      {channels.map(channel => (
        <div
          key={channel._id}
          className={styles.button}
          tabIndex={0}
          role="button"
          onKeyPress={e => e.key === Key.Enter && onChannelSelected(channel)}
          onClick={() => onChannelSelected(channel)}
        >
          <ChannelCircleListView
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
            channel={channel}
            className={styles.channelList}
            description={shortAddress(channel.address!)}
          />
        </div>
      ))}
    </div>
  );
}

export default MySubscriptionsPicker;
