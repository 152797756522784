import React, { forwardRef } from 'react';

import cx from 'classnames';

import {
  IconSet,
  ICON_SET_FONTAWESOME,
  FONT_AWESOME_REGULAR,
  FontAwesomeType,
} from 'lane-shared/helpers/constants/icons';

import { Icon } from '../Icon';

import styles from './IconButton.scss';

type IconButtonProps = {
  icon?: string;
  iconSet?: IconSet;
  inverted?: boolean;
  selected?: boolean;
  className?: any;
  style?: any;
  loading?: boolean;
  disabled?: boolean;
  type?: FontAwesomeType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  interfaceStyle?: 'dark' | 'light';
  text?: string;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  testId?: string;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      iconSet = ICON_SET_FONTAWESOME,
      onClick,
      style,
      className,
      inverted = false,
      selected = false,
      loading = false,
      disabled = false,
      testId,
      type = FONT_AWESOME_REGULAR,
      interfaceStyle = 'light',
      text,
      size = 'medium',
      label,
      ...otherProps
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={cx(styles.IconButton, className)}
        data-is-inverted={`${inverted}`}
        data-is-selected={`${selected}`}
        style={style}
        onClick={onClick}
        disabled={loading || disabled}
        data-loading={`${loading}`}
        data-test={testId}
        data-interface-style={interfaceStyle}
        data-has-text={!!text}
        data-size={size}
        aria-label={label}
        {...otherProps}
      >
        {icon && (
          <Icon
            disabled={disabled}
            name={icon}
            set={iconSet}
            type={type}
            size={size}
          />
        )}
        {text && <span>{text}</span>}
      </button>
    );
  }
);
