import { ProfileFragment } from '../fragments';
import gql from 'graphql-tag';

export type ChannelUsersByGroupRole = {
  channelUsersByGroupRole: {
    pageInfo: {
      start: number;
      total: number;
      perPage: number;
    };
    items: {
      _id: string;
      relatedChannels: {
        _id: string;
        name: string;
      }[];
      _created: string;
      user: {
        _id: string;
        _created: string;
        _updated: string;
        status: string;
        lastSeen: string;
        name: string;
        profile: {
          _id: string;
          _created: string;
          _updated: string;
          name: string;
          description: string;
          email: string;
          phone: string;
          image: string;
          wordmark: string;
          color: string;
          isPublic: boolean;
          logo: string;
          backgroundColor: string;
        };
        logins: {
          _id: string;
          key: string;
          isPrimary: boolean;
          status: string;
        }[];
      };
    }[];
  };
};

export const queryChannelUsersByGroupRole = gql`
  ${ProfileFragment}

  query queryChannelUsersByGroupRole(
    $groupRoleId: UUID!
    $search: UserGroupRoleSearch
    $pagination: PaginationInput
    $skipPagination: Boolean
  ) {
    channelUsersByGroupRole(
      groupRoleId: $groupRoleId
      search: $search
      pagination: $pagination
      skipPagination: $skipPagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        relatedChannels {
          _id
          name
        }
        _created
        user {
          _id
          _created
          _updated
          status
          lastSeen
          name
          profile {
            ...ProfileFragment
          }
          logins {
            _id
            key
            isPrimary
            status
          }
        }
      }
    }
  }
`;
