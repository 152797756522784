import React from 'react';

import { Toggle } from 'components';

import styles from 'lane-web/src/components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type props = {
  channel: {
    settings: {
      hasVisitorManagementEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  t: (key: string, params?: any) => string;
  disabled: boolean;
};

export function VisitorManagementToggle({
  channel,
  onChannelUpdated,
  disabled = false,
  t,
}: props) {
  return (
    <div className={styles.setting}>
      <Toggle
        disabled={disabled}
        className={styles.toggle}
        testId="toggleVisitorManagement"
        value={channel.settings.hasVisitorManagementEnabled || false}
        onChange={enabled =>
          onChannelUpdated({
            settings: {
              ...channel.settings,
              hasVisitorManagementEnabled: enabled,
            },
          })
        }
        text={t('web.content.feature.visitorManagement.toggleFeature.title')}
        description={t(
          'web.content.feature.visitorManagement.toggleFeature.description'
        )}
      />
    </div>
  );
}
