import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query getChannelGroupRoles($id: UUID!) {
    channel(_id: $id) {
      _id
      _created
      groupRoles {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        channel {
          _id
        }
        name
        isPublic
        isSystem
        requirements
        permissions
      }
    }
  }
`;
