import gql from 'graphql-tag';

export default gql`
  query channelModuleByChannelId($channelId: UUID!, $contentType: ContentType) {
    channelModuleByChannelId(channelId: $channelId, contentType: $contentType) {
      _id
      name
      friendlyName
      description
      availableFeatures
      allowsMultipleInstances
      autoCreatesContent
      createsAdminPage
      disabled
      dataDefinition
      contentType
      channelId
      settings
    }
  }
`;
