import gql from 'graphql-tag';

export type GetFloorQueryResponse = {
  floor: {
    id: string;
    name: string;
    index: number;
    units: {
      id: string;
      name: string;
    }[];
  };
};

export const getFloor = gql`
  query GetFloor($propertyId: UUID!, $floorId: UUID!) {
    floor(propertyId: $propertyId, floorId: $floorId) {
      id
      name
      index
      units {
        id
        name
      }
    }
  }
`;
