import { useState, useEffect, useRef } from 'react';

import { DateTime, Interval } from 'luxon';

import { parseDateTime } from 'lane-shared/helpers/dates';
import { TimeUnitEnum } from 'lane-shared/types/TimeUnitEnum';

type UseDateRangePickerProps = {
  startDate?: Date;
  endDate?: Date;
  minRangeSize?: number;
  maxRangeSize?: number;
  timeZone?: string;
  timeUnit?: TimeUnitEnum;
};

function copyTimes(fromDate: DateTime, toDate: DateTime) {
  return toDate.set({
    hour: fromDate?.hour || 0,
    minute: fromDate?.minute || 0,
    second: fromDate?.second || 0,
  });
}

export default function useDateRangePicker({
  startDate,
  endDate,
  minRangeSize,
  maxRangeSize,
  timeZone,
  timeUnit = TimeUnitEnum.Day,
}: UseDateRangePickerProps) {
  const startDateTime = parseDateTime(startDate, timeZone);
  const endDateTime = parseDateTime(endDate, timeZone);

  const internal = useRef<{
    start: DateTime | null;
    end: DateTime | null;
    lastStart: DateTime | null;
    lastEnd: DateTime | null;
    lastSet: 'start' | 'end' | null;
  }>({
    start: startDateTime,
    end: endDateTime,
    lastStart: startDateTime,
    lastEnd: endDateTime,
    lastSet: null,
  }).current;

  const [returnedStart, setReturnedStart] = useState<DateTime | null>(
    startDateTime
  );
  const [returnedEnd, setReturnedEnd] = useState<DateTime | null>(endDateTime);

  function enforceTimes() {
    if (internal.start && startDateTime) {
      internal.start = copyTimes(startDateTime, internal.start);
    }

    if (internal.end && endDateTime) {
      internal.end = copyTimes(endDateTime, internal.end);
    } else if (internal.end && internal.lastEnd) {
      internal.end = copyTimes(internal.lastEnd, internal.end);
    }
  }

  // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  function getEndMinRangeDate(fromDate: DateTime): DateTime | null {
    if (!minRangeSize) {
      return null;
    }

    if (minRangeSize <= 0) {
      return fromDate.plus({ days: minRangeSize });
    }

    if (minRangeSize === 1) {
      return fromDate.endOf(timeUnit);
    }

    if (minRangeSize > 1) {
      return fromDate.plus({ days: minRangeSize - 1 }).endOf(timeUnit);
    }
  }

  // make sure that the new date selected is within the minRangeSize and
  // maxRangeSize provided. And if not, modify it
  function enforceRangeLimit(day: DateTime) {
    // nothing has been selected yet, or user is selecting a new range.
    if (!internal.start || !internal.end) {
      return;
    }

    const forStartDate =
      day.hasSame(internal.start, timeUnit) &&
      !internal.start.hasSame(internal.end, timeUnit);

    const diffDays = Interval.fromDateTimes(internal.start, internal.end).count(
      'days'
    );

    if (minRangeSize && diffDays < minRangeSize) {
      // the range selected is too short, we need to adjust the start
      // or end to fit the min range size.

      if (forStartDate) {
        internal.start = internal.end.minus({
          days: minRangeSize,
        });
      } else {
        internal.end = getEndMinRangeDate(internal.start);
      }
    }

    if (maxRangeSize && diffDays > maxRangeSize) {
      // the range selected is too long, we need to adjust the start or
      // end to fit the max range size.
      if (forStartDate) {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        internal.start = internal.end.minus({
          days: maxRangeSize - 1,
        });
      } else {
        internal.end = internal.start.plus({
          days: maxRangeSize - 1,
        });
      }
    }
  }

  function enforceDates() {
    if (internal.start && internal.end && internal.end < internal.start) {
      const switchDates = internal.start;
      internal.start = internal.end;
      internal.end = switchDates;
    }
  }

  function setDate(date: Date) {
    const dateTime = parseDateTime(date, timeZone) as DateTime;
    if (maxRangeSize === 1) {
      // if the maxRangeSize is 1, then don't make the user click twice.
      // the date selection is going to be the start and end time
      internal.start = dateTime.startOf(timeUnit);
      internal.end = dateTime.endOf(timeUnit);
      internal.lastSet = 'end';
    } else if (internal.lastSet === null || internal.lastSet === 'end') {
      // nothing has been selected yet, or user is selecting a new range.
      internal.start = dateTime;
      internal.end = getEndMinRangeDate(internal.start);
      internal.lastSet = 'start';
    } else if (internal.lastSet === 'start') {
      // date will be the new end date
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (internal?.start < dateTime) {
        internal.end = dateTime.endOf(timeUnit);
      } else {
        internal.end = dateTime.startOf(timeUnit);
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        internal.start = internal?.start.endOf(timeUnit);
      }
      internal.lastSet = 'end';
    }

    enforceDates();
    enforceRangeLimit(dateTime);
    enforceTimes();
    enforceDates();

    setReturnedStart(internal.start);
    setReturnedEnd(internal.end);
  }

  function resetRange() {
    internal.start = null;
    setReturnedStart(null);
    internal.end = null;
    setReturnedEnd(null);
    internal.lastSet = null;
  }

  useEffect(() => {
    if (internal.start?.toMillis() !== startDateTime?.toMillis()) {
      internal.start = startDateTime;
      internal.lastStart = startDateTime;
      setReturnedStart(internal.start);
    }
  }, [startDateTime?.toMillis()]);

  useEffect(() => {
    if (internal.end?.toMillis() !== endDateTime?.toMillis()) {
      internal.end = endDateTime;
      internal.lastEnd = endDateTime;
      setReturnedEnd(internal.end);
    }
  }, [endDateTime?.toMillis()]);

  const range = {
    startDate: returnedStart?.toISO(),
    endDate: returnedEnd?.toISO(),
  };

  return [range, setDate, resetRange] as const;
}
