import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  SavePaymentMethodResponse,
  SavePaymentMethodRequest,
} from 'lane-shared/domains/billingPayments/types';

const saveCardMutation = gql`
  mutation savePaymentMethod(
    $savePaymentMethodRequest: SavePaymentMethodRequest!
  ) {
    accounts {
      savePaymentMethod(savePaymentMethodRequest: $savePaymentMethodRequest) {
        paymentProcessorCustomerId
        paymentProcessorSourceId
      }
    }
  }
`;

export async function savePaymentMethod(
  accountsUserId: string,
  paymentProcessorSourceId: string
): Promise<SavePaymentMethodResponse> {
  const { data } = await getClient().mutate({
    mutation: saveCardMutation,
    variables: {
      savePaymentMethodRequest: {
        accountsUserId,
        paymentProcessorSourceId,
      } as SavePaymentMethodRequest,
    },
  });

  return data.accounts.savePaymentMethod;
}
