import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getReservableWaitlistEnrollments(
    $userId: UUID!
    $contentId: UUID!
  ) {
    reservableWaitlistEnrollments(
      userId: $userId
      contentId: $contentId
    ) {
      id
      startDate
      endDate
    }
  }
`);
