import React, { useState, useEffect, useContext, useRef } from 'react';

import cx from 'classnames';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ValidationError } from 'yup';

import { getClient } from 'lane-shared/apollo';
import {
  AppContext,
  ChannelsContext,
  RendererContext,
  UserDataContext,
} from 'lane-shared/contexts';
import {
  createTemplate,
  updateTemplate,
  deleteTemplate,
} from 'lane-shared/graphql/mutation';
import { getQueryString, pause, castGraphQLObject } from 'lane-shared/helpers';
import {
  CONTENT_TYPES,
  FRIENDLY_CONTENT_TYPES,
  AVAILABLE_TEMPLATE_TYPES,
} from 'lane-shared/helpers/constants/content';
import {
  constructTemplate,
  deriveSubtitleFromContent,
} from 'lane-shared/helpers/content';
import getValidationMessages from 'lane-shared/helpers/getValidationMessages';
import { useContentTemplateQuery } from 'lane-shared/hooks';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import { LibraryTypeEnum } from 'lane-shared/types/libraries/LibraryTypeEnum';
import { validateCreateContentTemplate } from 'lane-shared/validation';
import {
  validateTemplateType,
  validateTemplateInfo,
  validateTemplateEditor,
  validationConstants,
} from 'lane-shared/validation/content';

import TemplateExportModal from 'components/lane/TemplateExportModal';
import TemplateImportModal from 'components/lane/TemplateImportModal';

import Dropdown from '../form/Dropdown';
import Input from '../form/Input';
import TextArea from '../form/TextArea';
import Button from '../general/Button';
import ErrorMessage from '../general/ErrorMessage';
import Loading from '../general/Loading';
import AdminPage from '../layout/AdminPage';
import Builder from '../renderers/Builder';
import CreatedBy from './CreatedBy';
import DraftContentHeader from './DraftContent/DraftContentHeader';
import DraftInfo from './DraftContent/DraftInfo';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './TemplateEdit.scss';

const steps = ['Type', 'Info', 'Editor'];
const [STEP_TYPE, STEP_INFO, STEP_EDITOR] = steps;
const stepValidation = {
  [STEP_TYPE]: validateTemplateType,
  [STEP_INFO]: validateTemplateInfo,
  [STEP_EDITOR]: validateTemplateEditor,
};

const notesMaxLength = validationConstants.templateBaseLength.notes.max;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  library?: LibraryType;
  forCreate?: boolean;
  forEdit?: boolean;
};

export default function TemplateEdit({
  className,
  style,
  library,
  forCreate,
  forEdit,
}: Props) {
  const history = useHistory();
  const params = useParams<{ templateId: string }>();
  const { t } = useTranslation();
  const { blocks } = useContext(AppContext);
  const { user } = useContext(UserDataContext);
  const { constructBaseBlock } = useContext(RendererContext);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [query, goToUrl] = useQueryString({ step: STEP_TYPE });
  const [contentType, setContentType] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<
    ValidationError[] | ValidationError | null
  >(null);
  const [error, setError] = useState<Error | null>(null);
  const [content, setContent] = useState<any>(null);
  const [hasChanged, setHasChanged] = useState(false);
  const location = useLocation();
  const fromRef = useRef((location as any).state?.from);
  const { channel } = useChannelAdminContext();
  const {
    template: templateData,
    error: templateError,
    refetch,
  } = useContentTemplateQuery({
    templateId: params.templateId,
  });
  const { primaryChannel } = useContext(ChannelsContext);

  useEffect(() => {
    if (blocks && blocks.length > 0 && forCreate && contentType) {
      const newContent = constructTemplate({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
        channel,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'ContentTy... Remove this comment to see the full error message
        contentType,
        baseBlock: {
          ...constructBaseBlock(),
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          name: FRIENDLY_CONTENT_TYPES[contentType],
        },
      });

      setContent({
        ...newContent,
        templateName: content && content.templateName,
        notes: content && content.notes,
      });
    }

    return () => {};
  }, [blocks, contentType]);

  useEffect(() => {
    /**
     * If templateQuery changes, it means it was refetched, or user navigated to a new id,
     * reset the editor content in the local state.
     *
     * Clicking on 'Next' updates templateQuery for some reason which will override
     * all changes user made to content. !hasChanged condition added to fix this.
     */
    if (forEdit && !hasChanged && templateData) {
      setContent({
        ...castGraphQLObject(templateData),
        subtitle: deriveSubtitleFromContent(templateData),
      });
      setLoading(false);
    }
  }, [templateData]);

  async function validateStep() {
    // @ts-expect-error ts-migrate(2538) FIXME: Type '(string | number | boolean)[]' cannot be use... Remove this comment to see the full error message
    if (!stepValidation[query.step]) {
      return true;
    }

    try {
      // @ts-expect-error ts-migrate(2538) FIXME: Type '(string | number | boolean)[]' cannot be use... Remove this comment to see the full error message
      await stepValidation[query.step].validate(content, {
        abortEarly: false,
      });
      setValidation(null);
      return true;
    } catch (err) {
      setValidation(err as ValidationError);
      return false;
    }
  }

  async function goQuery(props: any) {
    if (!(await validateStep())) {
      return;
    }

    goToUrl(props);
  }

  if (templateError) {
    return <ErrorMessage error={templateError} />;
  }

  if (forCreate && !contentType && query.step !== STEP_TYPE) {
    history.push('new');
    return <Loading />;
  }

  if (forEdit && !content) {
    return <Loading />;
  }

  async function onUndoClicked() {
    await pause();
    setHasChanged(false);
    if (forEdit) {
      refetch();
    }
    window.Toast.show(t('Undo'));
  }

  async function onCreateTemplate() {
    setLoading(true);
    setError(null);

    try {
      await pause();
      const clonedContent = JSON.parse(JSON.stringify(content));

      const libraryItem = {
        template: clonedContent,
      };

      if (library) {
        if (library.type === LibraryTypeEnum.Channel) {
          (libraryItem as any).channel = {
            _id: library._id,
          };
        } else if (library.type === LibraryTypeEnum.User) {
          (libraryItem as any).user = {
            _id: library._id,
          };
        } else if (library.type === LibraryTypeEnum.Library) {
          (libraryItem as any).library = {
            _id: library._id,
          };
        }
      } else {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        (libraryItem as any).channel = { _id: channel._id };
      }

      const { data } = await getClient().mutate({
        mutation: createTemplate,
        variables: {
          libraryItem,
        },
      });

      const newTemplate = data.createContentTemplate.template;

      history.push(`${newTemplate._id}/edit${getQueryString(query)}`);

      const templateCreatedMessage = t('{{- templateName}} is created.', {
        templateName: clonedContent.templateName,
      });

      window.Toast.show(templateCreatedMessage);

      setHasChanged(false);
    } catch (err) {
      setError(err as Error);
    }

    setLoading(false);
  }

  async function onSaveTemplate() {
    setLoading(true);
    setError(null);

    try {
      const clonedContent = JSON.parse(JSON.stringify(content));
      await pause();
      await getClient().mutate({
        mutation: updateTemplate,
        variables: {
          template: {
            ...clonedContent,
            theme: clonedContent.theme
              ? {
                  _id: clonedContent.theme._id,
                }
              : null,
            _updatedBy: undefined,
            _createdBy: undefined,
            _created: undefined,
            _updated: undefined,
          },
        },
      });

      const templateSavedMessage = t('{{- templateName}} is saved.', {
        templateName: clonedContent.templateName,
      });
      window.Toast.show(templateSavedMessage);

      setHasChanged(false);
    } catch (err) {
      setError(err as Error);
    }

    setLoading(false);
  }
  async function onSaveClicked() {
    setValidation(null);

    try {
      await validateCreateContentTemplate.validate(content, {
        abortEarly: false,
      });
    } catch (err) {
      setValidation(err as ValidationError);
      return;
    }

    if (forCreate) {
      await onCreateTemplate();
    } else {
      await onSaveTemplate();
    }
  }

  async function onDeleteTemplate() {
    try {
      await window.Alert.confirm({
        title: t('Delete {{- templateName}}?', {
          templateName: content.templateName,
        }),
        message: t(
          'Are you sure you want to delete {{- templateName}}? You won’t be able to get it back.',
          {
            templateName: content.templateName,
          }
        ),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: deleteTemplate,
        variables: {
          id: content._id,
        },
      });

      history.push(`/l/channel/${channel?.slug}/admin/library/templates`);

      const templateDeletedMessage = t('{{- templateName}} deleted.', {
        templateName: content.templateName,
      });
      window.Toast.show(templateDeletedMessage);
    } catch (err) {
      setError(err as Error);
    }

    setLoading(false);
  }

  function onContentUpdated(props: any) {
    setHasChanged(true);
    const updates = { ...content, ...props };
    setContent(updates);

    if (validation) {
      validateStep();
    }
    return updates;
  }

  async function onCancelClicked() {
    if (fromRef.current) {
      history.push(fromRef.current);
    } else {
      history.goBack();
    }
  }

  return (
    <AdminPage className={cx(styles.TemplateEdit, className)} style={style}>
      <DraftContentHeader
        loading={loading}
        hasChanged={hasChanged}
        onUndoClicked={forEdit ? onUndoClicked : undefined}
        onDeleteClicked={forEdit ? onDeleteTemplate : undefined}
        onSaveClicked={
          forEdit || query.step !== STEP_TYPE ? onSaveClicked : null
        }
        onCancelClicked={onCancelClicked}
        onGoStep={goQuery}
        steps={steps}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number | boolean | Date | (string |... Remove this comment to see the full error message
        currentStep={query.step}
        extendedOptions={
          query.step !== STEP_TYPE && (
            <>
              <Button
                loading={loading}
                onClick={() => setIsImportOpen(true)}
                size="small"
              >
                Import
              </Button>
              <Button
                loading={loading}
                onClick={() => setIsExportOpen(true)}
                size="small"
              >
                Export
              </Button>
            </>
          )
        }
      />

      <ErrorMessage error={validation} />
      <ErrorMessage error={error} />

      {query.step === STEP_TYPE && (
        <section className={styles.contentType}>
          {forCreate && (
            <>
              <h1>{t('Select a template type')}</h1>
              <Dropdown
                id="dropdownTemplateEdit"
                className={styles.dropDown}
                placeholder={t('Select type…')}
                errors={getValidationMessages(validation, 'type', '')}
                onValueChange={value => setContentType(value)}
                items={Object.values(AVAILABLE_TEMPLATE_TYPES).map(type => ({
                  label: FRIENDLY_CONTENT_TYPES[type],
                  value: type,
                }))}
                value={contentType}
              />
            </>
          )}

          {(forEdit || contentType) && content && (
            <>
              <Input
                placeholder={t('Template name…')}
                className={styles.input}
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 4.
                error={getValidationMessages(validation, 'templateName', '', t)}
                value={content.templateName}
                onChange={templateName => onContentUpdated({ templateName })}
                testId="inputTemplateName"
              />
              <div className={styles.textAreaWrapper}>
                <TextArea
                  placeholder={t(
                    'Provide some notes about this template here…'
                  )}
                  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 4.
                  errors={getValidationMessages(validation, 'notes', '', t)}
                  className={styles.textArea}
                  value={content.notes}
                  minRows={3}
                  onChange={notes => onContentUpdated({ notes })}
                  maxLength={notesMaxLength}
                  showLengthIndicator
                  testId="textAreaTemplateNotes"
                />
              </div>
            </>
          )}
        </section>
      )}

      {query.step === STEP_INFO &&
        [
          CONTENT_TYPES.PROMOTION,
          CONTENT_TYPES.CONTENT,
          CONTENT_TYPES.PERK,
          CONTENT_TYPES.STATIC,
        ].includes(content.type) && (
          <DraftInfo
            validation={validation}
            channel={channel || primaryChannel}
            library={library}
            content={content}
            onContentUpdated={onContentUpdated}
          />
        )}

      {query.step === STEP_EDITOR && (
        <Builder
          user={user}
          channel={channel}
          content={content}
          library={library}
          onContentUpdated={onContentUpdated}
          forTemplate
        />
      )}

      {forEdit && (
        <CreatedBy className={styles.createdBy} object={content} forAdmin />
      )}
      <TemplateExportModal
        isOpen={isExportOpen}
        content={isExportOpen && content}
        onClose={() => setIsExportOpen(false)}
        mode="ContentTemplate"
      />
      <TemplateImportModal
        isOpen={isImportOpen}
        channel={channel}
        content={content}
        onClose={() => setIsImportOpen(false)}
        onContentUpdated={content => onContentUpdated(content)}
        mode="ContentTemplate"
      />
    </AdminPage>
  );
}
