import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class Auth0OrganizationNotFoundError extends CodedError {
  static get errorName() {
    return errorCodes.auth0OrganizationNotFoundError.code;
  }

  constructor(message = errorCodes.auth0OrganizationNotFoundError.message) {
    super(message);
    this.code = errorCodes.auth0OrganizationNotFoundError.code;
    this.message = `${message} ${errorCodes.auth0OrganizationNotFoundError.code}`;
    this.name = errorCodes.auth0OrganizationNotFoundError.name;
    this.status = errorCodes.auth0OrganizationNotFoundError.status;
  }
}
