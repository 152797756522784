import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  GetTransactionStatusResponse,
  TransactionPaidStatus,
} from 'lane-shared/domains/billingPayments/types';

export type TransactionStatusData = {
  accounts: {
    getTransactionStatus: GetTransactionStatusResponse;
  };
};

const getTransactionStatusQuery = gql`
  query getTransactionStatus(
    $getTransactionStatusRequest: GetTransactionStatusRequest!
  ) {
    accounts {
      getTransactionStatus(
        getTransactionStatusRequest: $getTransactionStatusRequest
      ) {
        transactionId
        transactionStatus
      }
    }
  }
`;

export async function pollTransactionStatus(
  transactionIdToPollFor: string,
  accountsUserId: string | null
): Promise<GetTransactionStatusResponse> {
  if (!accountsUserId) {
    throw new Error('User Id cannot be empty');
  }

  const {
    data: {
      accounts: { getTransactionStatus },
    },
  } = await getClient().query<TransactionStatusData>({
    query: getTransactionStatusQuery,
    variables: {
      getTransactionStatusRequest: {
        transactionId: transactionIdToPollFor,
        userId: accountsUserId,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (
    getTransactionStatus.transactionStatus === TransactionPaidStatus.SUCCESS ||
    getTransactionStatus.transactionStatus === TransactionPaidStatus.FAILURE
  ) {
    return getTransactionStatus;
  }

  await new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, 2000);
  });

  return await pollTransactionStatus(transactionIdToPollFor, accountsUserId);
}
