import { gql } from 'graphql-query-contracts';

export const updateRecurrencePassesMutation = gql(/* GraphQL */ `
  mutation updateRecurrencePassesMutation(
    $recurrenceId: String!
    $newSchedule: String!
    $newScheduleDuration: String!
    $staffNote: String
    $visitorNote: String
    $additionalRecipients: [String]
  ) {
    updateRecurrencePasses(
      recurrenceId: $recurrenceId
      newSchedule: $newSchedule
      newScheduleDuration: $newScheduleDuration
      staffNote: $staffNote
      visitorNote: $visitorNote
      additionalRecipients: $additionalRecipients
    ) {
      id
    }
  }
`);