import React, { useState, useEffect } from 'react';
import { Dropdown } from 'components';
import { Button, H5, Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { DataValidationRuleType } from 'lane-shared/types/ContentWorkflowType';
import { Validator } from 'lane-shared/types/Validator';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import DataValidationRuleValue from 'components/workflows/dataValidationSchema/dataValidationRuleValue';
import { MaybeDraftDataValidationRuleType } from 'components/workflows/dataValidationSchema/type';
import ValidationMessage from 'components/general/ValidationMessage';
import { validateDataValidationRule } from 'lane-shared/validation';

import style from './DataValidationRuleV2.scss';

export type Props = {
  data: PropertiesInterface;
  maybeDraftRule: MaybeDraftDataValidationRuleType;
  onRuleUpdated: (rule: DataValidationRuleType) => void;
  onRuleDeleted: (maybeDraftRule: MaybeDraftDataValidationRuleType) => void;
  isDraft?: boolean;
  editMode?: boolean;
  ruleNumber?: number;
};

export function DataValidationRuleV2({
  data,
  maybeDraftRule,
  onRuleUpdated,
  onRuleDeleted,
  isDraft = false,
  editMode,
  ruleNumber,
}: Props) {
  const { t } = useTranslation();
  const [draftRule, setDraftRule] = useState(maybeDraftRule);
  const [validationError, setValidationError] = useState<{
    path: string;
    message: string;
  } | null>(null);

  function onDataFieldChange(item: any) {
    setDraftRule({
      ...draftRule,
      fieldId: item.value,
    });
  }

  function onRuleValueUpdated(validator: Validator) {
    setDraftRule({
      ...draftRule,
      validator,
    });
  }

  useEffect(() => {
    if (draftRule.fieldId !== undefined || draftRule.validator !== undefined) {
      onSaveRule();
    }
  }, [
    draftRule.fieldId,
    (draftRule?.validator?.value as Array<{ value: any }>)?.[0]?.value,
  ]);

  async function onSaveRule() {
    try {
      await validateDataValidationRule(data).validate(draftRule);
      onRuleUpdated(draftRule as DataValidationRuleType);
      setValidationError(null);
    } catch (error) {
      setValidationError({ path: error.path, message: error.message });
    }
  }

  function onDeleteRule() {
    onRuleDeleted(draftRule);
  }

  const isInvalidDataField =
    editMode && validationError && validationError.path === 'fieldId';

  function renderRuleValue() {
    if (!draftRule.fieldId || !data[draftRule.fieldId]) {
      return null;
    }
    return (
      <DataValidationRuleValue
        maybeDraftRule={draftRule}
        property={data[draftRule.fieldId]}
        onRuleValueUpdated={onRuleValueUpdated}
        editMode={editMode}
        validationError={validationError}
      />
    );
  }

  function renderDataFieldSelector() {
    const items = Object.keys(data).map(dataFieldId => {
      const dataProperty = data[dataFieldId];
      return {
        label: t('web.admin.content.editor.dataValidationRule.selector.label', {
          name: dataProperty?.friendlyName,
          type: dataProperty?.packagedType || dataProperty?.type,
          id: dataProperty?.name || dataFieldId,
        }),
        value: dataFieldId,
      };
    });
    return (
      <>
        <Dropdown
          testId="rule-data-field-dropdown"
          className={style.workflowRuleSelectorInput}
          items={items}
          onChange={onDataFieldChange}
          value={draftRule.fieldId}
          doTranslation={false}
          placeholder={t('Select Option')}
          disabled={!editMode}
          label={t(
            'web.admin.content.editor.dataValidationRule.workFlowRuleLabel'
          )}
          fixedLabel
        />
        {isInvalidDataField && (
          <ValidationMessage
            doTranslation={false}
            errors={[validationError.message]}
          />
        )}
      </>
    );
  }

  return (
    <div className={style.workflowRule}>
      <H5>
        {isDraft
          ? t('web.admin.content.editor.dataValidationRule.draftTitle')
          : t('web.admin.content.editor.dataValidationRule.ruleTitle', {
              ruleNumber,
            })}
      </H5>
      <div data-test="rule-body">
        <div data-test="rule-data-field-row">{renderDataFieldSelector()}</div>
        <div className={style.workflowRuleValue} data-test="rule-value">
          {renderRuleValue()}
        </div>
      </div>
      {editMode && (
        <div className={style.workflowRuleActions}>
          <Button
            onClick={onDeleteRule}
            testId="button-rule-delete"
            variant="text"
            size="medium"
            startIcon={<Icon name="trash-alt" />}
            className={style.workflowRuleButton}
          >
            {t('Delete')}
          </Button>
        </div>
      )}
    </div>
  );
}
