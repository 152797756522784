import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class UnitNotFoundError extends CodedError {
  static get errorName() {
    return errorCodeTypes.unitNotFoundError.code;
  }

  constructor(message = errorCodeTypes.unitNotFoundError.message) {
    super(message);
    this.code = errorCodeTypes.unitNotFoundError.code;
    this.message = `${message} ${errorCodeTypes.unitNotFoundError.code}`;
    this.name = errorCodeTypes.unitNotFoundError.name;
  }
}
