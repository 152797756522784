import React from 'react';

import useStatusColor, { Status } from 'lane-shared/hooks/useStatusColor';

import Pill from './Pill';

type OwnStatusPillProps = {
  text: string;
  size?: 'small' | 'medium';
  status: Status;
  className?: string;
};

type StatusPillProps = OwnStatusPillProps;

export default function StatusPill({
  size = 'small',
  status,
  text,
  className,
}: StatusPillProps) {
  const color = useStatusColor({ status });
  return <Pill text={text} color={color} size={size} className={className} />;
}
