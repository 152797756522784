import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class GenetecInvalidFieldError extends CodedError {
  static get errorName() {
    return errorCodes.genetecInvalidFieldError.code;
  }

  constructor(message = errorCodes.genetecInvalidFieldError.message) {
    super(message);
    this.code = errorCodes.genetecInvalidFieldError.code;
    this.message = `${message} ${errorCodes.genetecInvalidFieldError.code}`;
    this.name = errorCodes.genetecInvalidFieldError.name;
  }
}
