import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { getLibraryOptions } from 'lane-shared/helpers';
import { META_TAG_TYPE_SIMPLE } from 'lane-shared/helpers/constants/metatags';
import { getContentMetatagsWithUpdatedComplexValues } from 'lane-shared/helpers/content/getContentMetatagsWithUpdatedComplexValues';
import getContentMetatagsWithUpdatedSimpleValue from 'lane-shared/helpers/content/getContentMetatagsWithUpdatedSimpleValue';

import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import IconButton from 'components/general/IconButton';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';

import MetatagComplexOption from '../../MetatagComplexOption';
import MetatagLibrary from '../../MetatagLibrary';
import MetatagSimpleOption from '../../MetatagSimpleOption';

import styles from './ContentSearchability.scss';

export function ContentSearchability({
  content,
  channel,
  onMetatagRemoved,
  onMetatagAdded,
  onContentUpdated,
}: any) {
  const { t } = useTranslation();
  const [isMetatagLibraryOpen, setIsMetatagLibraryOpen] = useState(false);
  const [selectedMetatag, setSelectedMetatag] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateSimpleValue = useDebouncedCallback(
    async function _updateSimpleValue({ contentMetatag, value }) {
      // updating a simple metatag value just means updating the value.
      try {
        onContentUpdated({
          contentMetatags: getContentMetatagsWithUpdatedSimpleValue({
            contentMetatag,
            value,
            content,
          }),
        });
      } catch (err) {
        window.Toast.show(
          t(
            'web.admin.content.draftContent.info.contentSearchability.filters.update.error'
          )
        );
      }
    },
    1000
  ).callback;

  function updateComplexValues({ values, contentMetatag }: any) {
    // updating a complex metatag can involve pushing or pulling values
    // from the values array.
    setLoading(true);

    try {
      onContentUpdated({
        contentMetatags: getContentMetatagsWithUpdatedComplexValues({
          values,
          contentMetatag,
          content,
        }),
      });
    } catch (err) {
      console.error(err);
      // do something on error.
    }

    setLoading(false);
  }

  return (
    <div className={styles.ContentSearchability}>
      <label>
        <span>
          {t(
            'web.admin.content.draftContent.info.contentSearchability.filters.inputLabel'
          )}
        </span>
        <IconButton
          icon="plus-circle"
          inverted
          onClick={() => setIsMetatagLibraryOpen(true)}
          testId="add-filter"
        />
      </label>
      <ul className={styles.filters}>
        {content.contentMetatags?.map((contentMetatag: any) => (
          <li key={contentMetatag._id}>
            {contentMetatag.metatag.type === META_TAG_TYPE_SIMPLE ? (
              <MetatagSimpleOption
                metatag={contentMetatag.metatag}
                channel={channel}
                value={
                  contentMetatag.value?.[contentMetatag.metatag.properties.type]
                }
                onChange={rawValue => {
                  // we want to store some metadata on what type this is
                  // to query it later in the database.
                  const value = {
                    [contentMetatag.metatag.properties.type]: rawValue,
                  };

                  updateSimpleValue({
                    contentMetatag,
                    value,
                  });

                  // update the UI right away
                  contentMetatag.value = value;
                  onContentUpdated({
                    contentMetatags: [...content.contentMetatags],
                  });
                }}
              />
            ) : (
              <MetatagComplexOption
                loading={loading}
                metatag={contentMetatag.metatag}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ loading: boolean; metatag: any; channel: a... Remove this comment to see the full error message
                channel={channel}
                values={contentMetatag.values
                  ?.filter(
                    (contentMetatagValue: any) => !contentMetatagValue._pull
                  )
                  .map(
                    (contentMetatagValue: any) =>
                      contentMetatagValue.metatagValue._id
                  )}
                onChange={values =>
                  updateComplexValues({
                    contentMetatag,
                    values,
                  })
                }
              />
            )}
            <IconButton
              inverted
              className={styles.deleteButton}
              icon="times"
              onClick={() => onMetatagRemoved(contentMetatag)}
              testId="remove-filter"
            />
          </li>
        ))}
      </ul>
      <ModalBackground
        onClose={() => setIsMetatagLibraryOpen(false)}
        isOpen={isMetatagLibraryOpen}
        className={styles.background}
      >
        <ResizableWindow
          name="contentMetatag"
          className={styles.wrapper}
          onClose={() => setIsMetatagLibraryOpen(false)}
          defaultPosition={ResizableWindow.mostlyFullScreen()}
          showHeader
        >
          <MetatagLibrary
            className={styles.metatagLibrary}
            libraries={getLibraryOptions({ channel })}
            channelId={channel?._id}
            onMetatagSelected={metatag => setSelectedMetatag(metatag)}
          />
          <ControlMenu>
            <hr />
            <Button onClick={() => setIsMetatagLibraryOpen(false)}>
              {t(
                'web.admin.content.draftContent.info.contentSearchability.cancelButton.text'
              )}
            </Button>
            <Button
              testId="addButton"
              disabled={!selectedMetatag}
              onClick={() => {
                setIsMetatagLibraryOpen(false);
                onMetatagAdded(selectedMetatag);
              }}
              variant="contained"
            >
              {t(
                'web.admin.content.draftContent.info.contentSearchability.addButton.text'
              )}
            </Button>
          </ControlMenu>
        </ResizableWindow>
      </ModalBackground>
    </div>
  );
}
