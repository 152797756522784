import { AddProductToChargeDetails } from 'lane-shared/domains/billingPayments/types';
import { Price, Product as gqlProduct } from 'graphql-query-contracts';

export function setFinalPriceOnProduct(
  finalPrice: Price,
  product: AddProductToChargeDetails,
  inclusivePricing: boolean = false
): AddProductToChargeDetails {
  const updatedProduct = {
    ...product,
    tax: {
      type: finalPrice.tax?.taxAmountType ?? product.tax.type,
      value:
        finalPrice.tax?.value !== undefined
          ? finalPrice.tax?.value.toString()
          : product.tax.value,
    },
    markup: {
      type: finalPrice.markup?.markupAmountType ?? product.markup.type,
      value:
        finalPrice.markup?.value !== undefined
          ? finalPrice.markup?.value.toString()
          : product.markup.value,
    },
    originalPrice: {
      rate: product.rate,
      tax: product.tax,
      markup: product.markup,
    },
  };

  return inclusivePricing
    ? { ...updatedProduct, netPrice: finalPrice.amount.toString() }
    : { ...updatedProduct, rate: finalPrice.amount.toString() };
}

export function setFinalPriceOnProductType(
  finalPrice: Price,
  product: gqlProduct
) {
  const updatedProduct = {
    ...product,
    amount: finalPrice.amount,
    tax: finalPrice.tax
      ? {
          taxAmountType: finalPrice.tax.taxAmountType,
          value: finalPrice.tax.value,
        }
      : product.tax,
    markup: finalPrice.markup
      ? {
          markupAmountType: finalPrice.markup.markupAmountType,
          value: finalPrice.markup.value,
        }
      : product.markup,
    originalPrice: {
      amount: product.amount || 0,
      tax: product.tax,
      markup: product.markup,
    },
  };

  return updatedProduct;
}
