import i18next from 'i18next';
import { DateTime, Settings, Zone } from 'luxon';

import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';

import {
  SHORT_DATE,
  SHORT_TIME,
  RECENT_DATE_V2,
  RECENT_DATE_FULL_MONTH,
  SHORT_DATE_FULL_MONTH,
} from '../constants/dates';
import parseDateTime from '../dates/parseDateTime';
import { year } from '../formatters';

/**
 * Gets the friendly display date for things like displaying on the
 * Content card.
 */
export default function getFriendlyDate({
  content,
  translator = i18next.t,
  locale,
  timeZone = Settings.defaultZone,
  showTime,
  showFullMonth,
}: {
  content: { startDate?: Date | null; endDate?: Date | null };
  translator: (translationKey: string, { time }?: any) => string;
  locale?: string;
  timeZone?: string | Zone;
  showTime?: boolean;
  showFullMonth?: boolean;
}) {
  if (!content || !content.startDate) {
    return '';
  }

  let startComponent = '';
  let endComponent = '';
  let yearComponent = '';
  let divider = ' ';
  let startDateFormat = '';
  let endDateFormat = '';

  const now = DateTime.local().setZone(timeZone);
  const startDate = parseDateTime(content.startDate, timeZone);
  const endDate = parseDateTime(content.endDate || new Date(), timeZone);

  // NOTE: this is a 'fix' for null type errors of the below code, there are
  // definitely bugs there. It already would have been throwing errors but this
  // makes the error more explicit.
  if (!startDate || !endDate) {
    throw new Error('getFriendlyDate unable to parse dates');
  }

  if (startDate < now) {
    startComponent = translator('Until');
  } else if (startDate.hasSame(now, 'day')) {
    startComponent = translator('Today {{- time}}', {
      time: dateFormatter(startDate, SHORT_TIME, timeZone, locale),
    });
    divider = ' - ';
  } else if (startDate.hasSame(endDate, 'day')) {
    startDateFormat = showFullMonth ? RECENT_DATE_FULL_MONTH : RECENT_DATE_V2;
    startComponent += `${dateFormatter(
      startDate,
      startDateFormat,
      timeZone,
      locale
    )} ${dateFormatter(startDate, SHORT_TIME, timeZone, locale)}`;
    divider = ' - ';
  } else if (startDate.hasSame(endDate, 'month')) {
    startDateFormat = showFullMonth ? RECENT_DATE_FULL_MONTH : RECENT_DATE_V2;
    startComponent = `${dateFormatter(
      startDate,
      startDateFormat,
      timeZone,
      locale
    )}${
      showTime
        ? ` ${dateFormatter(startDate, SHORT_TIME, timeZone, locale)}`
        : ''
    }`;
    divider = ' - ';
  } else {
    startDateFormat = showFullMonth ? SHORT_DATE_FULL_MONTH : SHORT_DATE;
    startComponent = `${dateFormatter(
      startDate,
      startDateFormat,
      timeZone,
      locale
    )}${
      showTime
        ? ` ${dateFormatter(startDate, SHORT_TIME, timeZone, locale)}`
        : ''
    }`;
    divider = ' - ';
  }

  if (startDate.hasSame(endDate, 'day')) {
    endComponent = `${dateFormatter(endDate, SHORT_TIME, timeZone, locale)}`;
  } else if (endDate.hasSame(now.plus({ days: 1 }), 'day')) {
    endComponent = translator('Tomorrow {{- time}}', {
      time: dateFormatter(endDate, SHORT_TIME, timeZone, locale),
    });
  } else if (startDate.hasSame(endDate, 'month')) {
    endDateFormat = showFullMonth ? RECENT_DATE_FULL_MONTH : RECENT_DATE_V2;
    endComponent = `${dateFormatter(endDate, endDateFormat, timeZone, locale)}${
      showTime ? ` ${dateFormatter(endDate, SHORT_TIME, timeZone, locale)}` : ''
    }`;
  } else {
    endDateFormat = showFullMonth ? SHORT_DATE_FULL_MONTH : SHORT_DATE;
    endComponent = `${dateFormatter(endDate, endDateFormat, timeZone, locale)}${
      showTime ? ` ${dateFormatter(endDate, SHORT_TIME, timeZone, locale)}` : ''
    }`;
  }

  if (!startDate.hasSame(endDate, 'year')) {
    yearComponent = `, ${year(endDate, timeZone)}`;
  }

  return startComponent + divider + endComponent + yearComponent;
}
