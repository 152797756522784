import React, { useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/typography';
import { Button, DatePickerButton, Icon, Multiselect } from 'design-system-web';
import { SearchBar } from 'components';
import { useReservableCalendarView } from './hooks/useReservableCalendarView';
import { useReservableCalendarFilters } from './hooks/useReservableCalendarFilters';
import styles from './styles.scss';
import { CalendarView } from './Calendar';

const TRANSLATION_KEYS = {
  today: 'web.admin.channel.reservableManagement.calendar.button.today',
  unavailable:
    'web.admin.channel.reservableManagement.calendar.legend.unavailable',
  temporaryPost:
    'web.admin.channel.reservableManagement.calendar.legend.temporaryPost',
  bookingsError:
    'web.admin.channel.reservableManagement.calendar.fetchBookings.error',
  noResults: 'web.admin.channel.reservableManagement.tabs.noResults',
  dateLabel: 'web.admin.channel.reservableManagement.calendar.dateLabel',
  tagLabel: 'web.admin.channel.reservableManagement.calendar.tagLabel',
  searchLabel: 'web.admin.channel.reservableManagement.calendar.searchLabel',
  selectTags: 'web.admin.section.selectTags',
};

function ReservableCalendarLegend() {
  const { t } = useTranslation();

  return (
    <div className={styles.legend}>
      <div className={styles.row}>
        <span className={styles.unavailable} />
        <span>{t(TRANSLATION_KEYS.unavailable)}</span>
      </div>
      <div className={styles.row}>
        <Icon name="alarm-clock" />
        <span>{t(TRANSLATION_KEYS.temporaryPost)}</span>
      </div>
    </div>
  );
}

export function ReservableCalendarView({
  channelId,
  channelTimeZone,
}: {
  channelId: string;
  channelTimeZone: string;
}) {
  const { t } = useTranslation();
  const {
    data: {
      calendarRef,
      referenceDate,
      events: unfilteredEvents,
      resources: unfilteredResources,
      error,
    },
    handlers: { setCalendarDate, refetchReservablesOnChannel },
  } = useReservableCalendarView({ channelId, channelTimeZone });

  const {
    tags,
    selectedTags,
    setSelectedTags,
    resources,
    events,
    searchTerm,
    setSearchTerm,
  } = useReservableCalendarFilters({
    channelId,
    unfilteredResources,
    unfilteredEvents,
  });

  if (error) {
    window.Toast.show(<Text>{t(TRANSLATION_KEYS.bookingsError)}</Text>);
  }
  const noResults = useMemo(
    () =>
      resources.length === 0 &&
      (searchTerm.length !== 0 || selectedTags.length !== 0),
    [selectedTags, searchTerm, resources]
  );

  useEffect(() => {
    if (noResults) {
      window.Toast.show(<Text>{t(TRANSLATION_KEYS.noResults)}</Text>);
    }
  }, [noResults]);

  const handleDateChange = (date: Date) => {
    const dateWithTimeZone = DateTime.fromJSDate(date)
      .setZone(channelTimeZone)
      .startOf('day')
      .toJSDate();
    setCalendarDate(dateWithTimeZone);
  };

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.buttons}>
          <Button
            type="button"
            variant="tertiary"
            size="large"
            onClick={() => {
              const todayDate = DateTime.now()
                .setZone(channelTimeZone)
                .startOf('day')
                .toJSDate();

              setCalendarDate(todayDate);
            }}
            className={styles.button}
          >
            {t(TRANSLATION_KEYS.today)}
          </Button>
          <div>
            <label className={styles.filterLabel}>
              {t(TRANSLATION_KEYS.dateLabel)}
            </label>
            <DatePickerButton
              hideLabel
              value={referenceDate}
              timeZone={channelTimeZone}
              onChange={handleDateChange}
              className={styles.datePicker}
            />
          </div>
          <div>
            <label className={styles.filterLabel}>
              {t(TRANSLATION_KEYS.tagLabel)}
            </label>
            <Multiselect
              items={tags}
              onChange={setSelectedTags}
              value={selectedTags}
              doTranslation={false}
              truncateSelectedItems
              placeholder={t(TRANSLATION_KEYS.selectTags)}
            />
          </div>
          <div>
            <label className={styles.filterLabel}>
              {t(TRANSLATION_KEYS.searchLabel)}
            </label>
            <SearchBar
              searchOptions={{ search: searchTerm }}
              onSearchChange={v => setSearchTerm(v)}
              searchInputPlaceholder=""
            />
          </div>
        </div>
        <ReservableCalendarLegend />
      </div>
      <CalendarView
        ref={calendarRef}
        timeZone={channelTimeZone}
        events={events}
        resources={resources}
        refetchReservablesOnChannel={refetchReservablesOnChannel}
      />
    </div>
  );
}
