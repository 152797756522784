import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  GroupSettingRequest,
  GetGroupSettingRequest,
  GroupSettingResponse,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';

const createBillingPaymentSettingsMutation = gql`
  mutation createBillingPaymentSettings(
    $groupSettingRequest: GroupSettingRequest
  ) {
    accounts {
      createBillingPaymentSettings(groupSettingRequest: $groupSettingRequest) {
        groupSettingId
        groupId
        groupType
        settings
      }
    }
  }
`;

const updateBillingPaymentSettingsMutation = gql`
  mutation updateBillingPaymentSettings(
    $groupSettingRequest: GroupSettingRequest
  ) {
    accounts {
      updateBillingPaymentSettings(groupSettingRequest: $groupSettingRequest) {
        groupSettingId
        groupId
        groupType
        settings
      }
    }
  }
`;

const getBillingPaymentSettingsQuery = gql`
  query getBillingPaymentSettings(
    $getGroupSettingRequest: GetGroupSettingRequest
  ) {
    accounts {
      getBillingPaymentSettings(
        getGroupSettingRequest: $getGroupSettingRequest
      ) {
        groupSettingId
        groupId
        groupType
        settings
      }
    }
  }
`;

export async function createBillingPaymentSettings(
  channelId: string,
  settings: object
): Promise<GroupSettingResponse> {
  const { data } = await getClient().mutate({
    mutation: createBillingPaymentSettingsMutation,
    variables: {
      groupSettingRequest: {
        groupId: channelId,
        groupType: GroupType.GroupTypeActivateChannel,
        settings: JSON.stringify(settings) as any,
      } as GroupSettingRequest,
    },
  });

  return data.accounts.createBillingPaymentSettings;
}

export async function updateBillingPaymentSettings(
  channelId: string,
  settings: object
): Promise<GroupSettingResponse> {
  const { data } = await getClient().mutate({
    mutation: updateBillingPaymentSettingsMutation,
    variables: {
      groupSettingRequest: {
        groupId: channelId,
        groupType: GroupType.GroupTypeActivateChannel,
        settings: JSON.stringify(settings) as any,
      } as GroupSettingRequest,
    },
  });

  return data.accounts.updateBillingPaymentSettings;
}

export async function getBillingPaymentSettings(
  channelId: string
): Promise<GroupSettingResponse> {
  const { data } = await getClient().query({
    query: getBillingPaymentSettingsQuery,
    fetchPolicy: 'network-only',
    variables: {
      getGroupSettingRequest: {
        groupId: channelId,
        groupType: GroupType.GroupTypeActivateChannel,
      } as GetGroupSettingRequest,
    },
  });

  return data.accounts.getBillingPaymentSettings;
}
