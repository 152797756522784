import { gql } from 'graphql-query-contracts';

/**
 * GQL Get Preventive Maintenance Schedule Query
 */
export default gql(/* GraphQL */ `
  query getPMScheduleByIdQuery($scheduleId: String!) {
    pmSchedule(scheduleId: $scheduleId) {
      scheduleId
      extRefId {
        name
        id
      }
      title
      nextDueDate
      untilDate
      repeats
      daysAhead
      weekday
      weekNo
      monthNo
      interval
      assignee {
        id
        name
      }
      equipmentIds
      notes
      timeToComplete
      completeWithin
      steps
      meterReading
      createdBy
      updatedBy
      createdAt
      updatedAt
      isArchived
      location
      attachments
      assigneeGroups
      stepTemplate {
        id
        meterReadingId
        name
        type
        options {
          subtype
          description
          choices
          unit
        }
      }
    }
  }
`);
