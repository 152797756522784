import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getEquipmentForExport(
    $channelId: UUID!
    $includeArchived: Boolean
  ) {
    getEquipmentForExport(
      channelId: $channelId
      includeArchived: $includeArchived
    ) {
      equipment {
        id
        channelId
        name
        status
        location
        category
        make
        model
        asset
        serial
        floor
        suite
        installDate
        warrantyExpirationDate
        isArchived
      }
    }
  }
`);
