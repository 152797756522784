import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

import type { ChannelErrorData } from './ChannelErrorData';

export default class AccessRestrictedError extends CodedError {
  channel: ChannelErrorData | undefined;

  static get errorName() {
    return errorCodes.notAuthorizedError.code;
  }

  constructor(message = errorCodes.notAuthorizedError.message) {
    super(message);
    this.code = errorCodes.notAuthorizedError.code;
    this.message = `${message} ${errorCodes.notAuthorizedError.code}`;
    this.name = errorCodes.notAuthorizedError.name;
    this.status = errorCodes.notAuthorizedError.status;
  }

  setChannel(channel: any) {
    this.channel = mapChannelToChannelErrorData(channel);
  }
}

function mapChannelToChannelErrorData(
  channel: ChannelErrorData
): ChannelErrorData {
  return {
    _id: channel._id,
    address: {
      street1: channel.address.street1,
    },
    profile: {
      image: channel.profile.image,
      name: channel.profile.name,
    },
  };
}
