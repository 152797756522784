import gql from 'graphql-tag';

export const doUsersExistByEmail = gql`
  query doUsersExistByEmail($emails: [String!]!) {
    doUsersExistByEmail(emails: $emails) {
      email
      userId
    }
  }
`;
