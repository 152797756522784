import gql from 'graphql-tag';

import { ProfileFragment } from '../fragments';

export const queryChannelUsersByGroupRolesIncludeResidentInfo = gql`
  ${ProfileFragment}

  query queryChannelUsersByGroupRolesIncludeResidentInfo(
    $groupRoleIds: [UUID]!
    $search: UserGroupRoleSearch
    $channelId: UUID!
  ) {
    channelUsersByGroupRoles(groupRoleIds: $groupRoleIds, search: $search) {
      items {
        _id
        relatedChannels {
          _id
          name
        }
        groupRole {
          name
        }
        user {
          _id
          _created
          _updated
          status
          lastSeen
          name
          profile {
            ...ProfileFragment
          }
          logins {
            _id
            key
            isPrimary
            status
          }
          person {
            units(relationships: [OCCUPIES, LEASES]) {
              id
              name
              floors {
                id
                name
              }
            }
            resident(propertyId: $channelId) {
              status
              type
            }
          }
        }
      }
    }
  }
`;
