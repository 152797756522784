import gql from 'graphql-tag';

export default gql`
  query channelsForBlocksAndTargeting(
    $pagination: PaginationInput!
    $search: ChannelSearch!
  ) {
    channelsForBlocksAndTargeting(pagination: $pagination, search: $search) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        name
        slug
        type
        isPublic
        isDiscoverable
        isLive
        inviteOnly
        parent {
          _id
          name
          slug
        }
        address {
          _id
          name
          street1
          street2
          street3
          city
          state
          country
          code
          geo
          distance
        }
        profile {
          _id
          name
          image
          logo
        }
        stats {
          subscribers
        }
        hierarchy {
          hierarchyDown
          hierarchyUp
        }
        whitelabels {
          whitelabel {
            _id
            name
            url
          }
        }
      }
    }
  }
`;
