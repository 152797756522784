import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { getDisplayName } from 'lane-shared/helpers';
import {
  CONTENT_LOCATION_PAGE_CENTER,
  CONTENT_TYPES,
} from 'lane-shared/helpers/constants/content';
import { useContentQuery } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';

import ContentCard from 'components/cards/ContentCard';
import ErrorMessage from 'components/general/ErrorMessage';
import ContentPreview from 'components/lane/ContentPreview';
import ContentSelectorButton from 'components/lane/ContentSelectorButton';
import { H3 } from 'components/typography';

import styles from './ChannelProfileEdit.scss';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  channel: ChannelType | null;
  onChannelUpdated: (channel: ChannelType) => void;
};

export default function ChannelProfileEdit({
  channel,
  onChannelUpdated,
  style,
  className,
}: Props) {
  const { t } = useTranslation();
  const [contentId, setContentId] = useState(null);

  const { content, error } = useContentQuery({
    includeInteractions: false,
    forPublic: false,
    contentId,
  });

  useEffect(() => {
    if (channel?.profile?.content?._id !== contentId) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setContentId(channel?.profile?.content?._id);
    }
  }, [channel]);

  function renderSelector() {
    return (
      <ContentSelectorButton
        channelId={channel?._id}
        contentId={contentId}
        onContentSelected={(content: any) => {
          setContentId(content?._id);
          onChannelUpdated({
            profile: {
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              ...channel.profile,
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: any; } | null' is not assignable to t... Remove this comment to see the full error message
              content: content ? { _id: content._id } : null,
            },
          });
        }}
        contentSearchLocations={[CONTENT_LOCATION_PAGE_CENTER]}
        contentTypes={[CONTENT_TYPES.STATIC]}
      />
    );
  }

  return (
    <div className={cx(styles.ChannelProfileEdit, className)} style={style}>
      <ErrorMessage error={error} />

      {!content ? (
        <div className={cx(styles.columnContainer, styles.noContentContainer)}>
          <h3>
            {t('web.admin.channel.settings.profile.profileTab.noProfileBlurb')}
          </h3>
          {renderSelector()}
        </div>
      ) : (
        <>
          <div className={styles.columnContainer}>
            <H3 mb={2}>
              {getDisplayName(channel)}{' '}
              {t('web.admin.channel.settings.profile.profileTab.heading')}
            </H3>

            <hr />

            {renderSelector()}

            <hr />

            <Link
              key={content._id}
              to={routes.channelAdminContent
                .replace(':id', channel!.slug)
                .replace(':contentId', content._id)}
            >
              <ContentCard content={content} className={styles.contentCard} />
            </Link>
          </div>
          <ContentPreview contentId={contentId} />
        </>
      )}
    </div>
  );
}
