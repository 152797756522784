import gql from 'graphql-tag';

export default gql`
  mutation claimInvite($_id: UUID!) {
    me {
      claimInvite(_id: $_id) {
        _id
      }
    }
  }
`;
