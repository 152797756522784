import React, { useMemo } from 'react';

import type { SpacingType } from 'lane-shared/config/spacing';

import cssVariables from '../../styles/_variables.scss';

export type BoxSpacing =
  | SpacingType
  | [vertical: SpacingType, horizontal: SpacingType]
  | [
      top: SpacingType,
      right: SpacingType,
      bottom: SpacingType,
      left: SpacingType
    ];

export type BoxProps = {
  children: React.ReactNode;
  /** Margin */
  m?: BoxSpacing;
  /** Margin-Bottom */
  mb?: BoxSpacing;
  /** Padding */
  p?: BoxSpacing;
  style?: React.CSSProperties;
  className?: string;
  width?: 'full';
};

export const Box = ({
  children,
  p,
  m,
  mb,
  style,
  className,
  width,
  ...otherProps
}: BoxProps) => {
  const padding = useMemo(() => getMatchingSCSSVariable(p), [p]);
  const margin = useMemo(() => getMatchingSCSSVariable(m), [m]);
  const marginBottom = useMemo(() => getMatchingSCSSVariable(mb), [mb]);

  return (
    <div
      className={className}
      style={{
        padding: padding ? padding! : undefined,
        margin: margin ? margin! : undefined,
        ...(marginBottom ? { marginBottom } : {}),
        width: width === 'full' ? '100%' : undefined,
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
};

/** Returns the matching scss variable for that spacing size */
function getMatchingSCSSVariable(spacing?: BoxSpacing): string | null {
  return Array.isArray(spacing)
    ? spacing.map(size => cssVariables[`spacing${size}`] || 0).join(' ')
    : cssVariables[`spacing${spacing}`] || null;
}
