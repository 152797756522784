import React, { useEffect, useState } from 'react';

import { Button } from 'design-system-web';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Modal } from 'components/lds';
import { H4, S } from 'lane-web/src/components/typography';

import useBeforeUnload from '../../hooks/useBeforeUnload';

import { IconButton } from 'components/general';
import styles from './LeaveConfirmationPrompt.scss';

import { FONT_AWESOME_LIGHT } from 'lane-shared/helpers/constants/icons';

interface Props {
  when: boolean;
  navigate: (path: string) => void;
  title?: string;
  description?: string;
  testId?: string;
}

function LeaveConfirmationPrompt({
  when,
  navigate,
  title,
  description,
  testId,
}: Props) {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  // handle refresh and exit site
  useBeforeUnload((e: BeforeUnloadEvent) => {
    if (when) {
      e.preventDefault();
    }
  });

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (
      !confirmedNavigation &&
      nextLocation.pathname !== window.location.pathname
    ) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleLeave = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname + lastLocation.search);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        isOpen={modalVisible}
        onClose={closeModal}
        testId={testId}
        className={styles.leaveConfirmationPrompt}
        closeButtonSize="medium"
        includeHeader={false}
      >
        <div className={styles.header}>
          <H4 className={styles.text} mb={2}>
            {title ??
              t(
                'web.content.feature.visitorManagement.leavePrompt.button.leavePage'
              )}
          </H4>
          <IconButton
            type={FONT_AWESOME_LIGHT}
            icon="times"
            data-test="closeButton"
            size="medium"
            onClick={closeModal}
          />
        </div>
        <S variant="secondary" mb={6}>
          {description ??
            t(
              'shared.content.feature.visitorManagement.leavePrompt.button.progressWillBeLost'
            )}
        </S>

        <div className={styles.modalFooter}>
          <Button
            key="2"
            variant="primary"
            onClick={handleLeave}
            fullWidth={false}
            size="medium"
            testId="leavePage"
          >
            {t(
              'web.content.feature.visitorManagement.leavePrompt.button.leave'
            )}
          </Button>

          <Button
            key="1"
            variant="secondary"
            onClick={closeModal}
            fullWidth={false}
            size="medium"
            testId="returnToPage"
          >
            {t(
              'web.content.feature.visitorManagement.leavePrompt.button.returnToPage'
            )}
          </Button>
        </div>
      </Modal>
    </>
  );
}
export default LeaveConfirmationPrompt;
