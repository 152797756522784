import { v4 as uuid } from 'uuid';

import { LaneType } from '../../__generated__/staticTypes';
import {
  ChannelType,
  ChannelTypeEnum,
  ActiveChannelTypeEnum,
  ChannelExperienceTypeEnum,
} from '../../types/ChannelType';
import { PROPERTY_TYPES } from '../constants/channel';
import constructAddress from '../constructAddress';
import constructProfile from '../constructProfile';

export default function constructChannel({
  userId,
  parent,
  ...otherProps
}: Partial<ChannelType> & {
  userId?: LaneType.UUID;
  parent?: { _id: LaneType.UUID; type: ChannelTypeEnum };
}): ChannelType {
  return {
    _id: uuid(),
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    name: '',
    type: parent?.type ?? ActiveChannelTypeEnum.Property,
    description: null,
    parent: parent ? { _id: parent._id, name: parent?.name } : null,
    slug: '',
    isPublic: false,
    isDiscoverable: true,
    isLive: false,
    isCustomer: parent ? parent.isCustomer : false,
    isSub: false,
    inviteOnly: false,
    filters: [],
    tags: [],
    experienceType:
      parent?.type && PROPERTY_TYPES.includes(parent.type)
        ? ChannelExperienceTypeEnum.office
        : null,
    settings: {
      maxUsers: 0,
      subscribersJoinParent: false,
      subscribersAreDecoyed: false,
      groupRoleJoinRules: [],
      hasPerksEnabled: false,
      hasWorkOrdersEnabled: false,
      hasWorkOrderServiceRequestsEnabled: false,
      hasWorkOrderPreventiveMaintenanceEnabled: false,
      hasWorkOrderEquipmentEnabled: false,
      hasWorkOrderCrossPropertyEnabled: false,
      hasBillingPaymentsEnabled: false,
      hasVisitorManagementEnabled: false,
      hasHardwareManagementEnabled: false,
      hasHardwarePinManagementEnabled: false,
      hasSmartUserManagementEnabled: false,
      hasReservableManagementEnabled: false,
      hasAccessControlEnabled: false,
      hasSurveysEnabled: true,
      areUserTemplatesAllowed: false,
      hasMessagingAndMarketplaceEnabled: false,
    },
    stats: {
      subscribers: 0,
      sf: 0,
    },
    address: constructAddress({ userId }),
    profile: constructProfile({ userId }),
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; _created: Date; _createdBy: s... Remove this comment to see the full error message
    special: {},
    ...otherProps,
  };
}
