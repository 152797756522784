import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import useChannelGroupRolesQuery from 'lane-shared/hooks/useChannelGroupRolesQuery';
import useGroupRoleQuery from 'lane-shared/hooks/useGroupRoleQuery';
import { GroupRole } from 'lane-shared/types/GroupRole';

import Dropdown from '../form/Dropdown';
import ChannelSelectorButton from './ChannelSelectorButton';

import styles from './GroupRoleSelectorV2.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  onGroupRoleSelected: (groupRole: GroupRole | null) => void;
  disabled?: boolean;
  includeWorkplaceMember?: boolean;
  showChannelSelector?: boolean;
  groupRoleId?: string | null;
  placeholder?: string;
  channelId: string | undefined;
  className?: string;
  style?: React.CSSProperties;
  channelSelectorButtonStyle?: React.CSSProperties;
  isCardView?: boolean;
  disableChannelSelection?: boolean;
  error?: string[] | null;
};

export function GroupRoleSelectorV2({
  className,
  style,
  disabled = false,
  channelId,
  groupRoleId = null,
  placeholder,
  showChannelSelector = true,
  includeWorkplaceMember = true,
  onGroupRoleSelected,
  isCardView = false,
  disableChannelSelection,
  error,
}: Props) {
  const { t } = useTranslation();
  const [selectedChannelId, setSelectedChannelId] = useState(channelId);

  useEffect(() => {
    // if the channelId passed in is changing, only update it if there is
    // no custom channel selected.
    if (channelId && !selectedChannelId) {
      setSelectedChannelId(channelId);
    }
  }, [channelId]);

  // to determine which channel to show in the channel selector.
  // if a groupRole is selected, show that group roles channel.
  // if a group role is not selected use the custom channel selected
  // if a group role is not selected, and no custom channel selected, use the channelId
  let displayChannelId: string | undefined;

  const { groupRole } = useGroupRoleQuery({ groupRoleId });

  if (groupRoleId) {
    displayChannelId = groupRole?.channel?._id || selectedChannelId;
  } else if (selectedChannelId) {
    displayChannelId = selectedChannelId;
  } else {
    displayChannelId = '';
  }

  const { groupRoles } = useChannelGroupRolesQuery({
    channelId: displayChannelId,
    includeWorkplaceMember,
  });

  return (
    <div
      className={cx(styles.GroupRoleSelector, className)}
      style={style}
      data-has-channel-selector={showChannelSelector}
    >
      {showChannelSelector && (
        <ChannelSelectorButton
          disabled={disableChannelSelection || disabled}
          className={styles.channelSelector}
          channelId={displayChannelId}
          isCardView={isCardView}
          onChannelSelected={(channel: any) => {
            setSelectedChannelId(channel?._id);
            onGroupRoleSelected(null);
          }}
        />
      )}

      <div>
        <Dropdown
          className={styles.dropDown}
          disabled={disabled || !displayChannelId}
          items={groupRoles.map(role => ({
            label: role.name,
            value: role._id,
          }))}
          invalid={Boolean(error)}
          errors={error}
          placeholder={
            placeholder ||
            t(
              'web.admin.channel.content.workflow.editor.v2.outcome.targetLabel.groupRole.selectTeam.placeholder'
            )
          }
          value={groupRole?._id}
          onValueChange={value => {
            const groupRole = groupRoles.find(
              groupRole => groupRole._id === value
            );
            setSelectedChannelId((groupRole as any)?.channel._id);
            onGroupRoleSelected(groupRole || null);
          }}
        />
      </div>
    </div>
  );
}
