import gql from 'graphql-tag';

export const getTag = gql`
  query getTag($id: UUID!) {
    tag(id: $id) {
      id
      name
      name_l10n
      channelId
      isDefault
      contents {
        _id
        name
        type
        liveDate
        unpublishDate
        _updated
      }
      sections {
        _id
        name
        _created
        _updated
      }
    }
  }
`;
