import gql from 'graphql-tag';

export default gql(`
  fragment ThemePaletteFragment on ThemePalette {
      primary
      secondary
      tertiary
      text
      background
      custom {
        _id
        name
        color
      }
  }
`);
