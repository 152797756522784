import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class EventContentMissingStartOrEndDateError extends CodedError {
  static get errorName() {
    return errorCodes.eventContentMissingStartOrEndDateError.code;
  }

  constructor(
    message = errorCodes.eventContentMissingStartOrEndDateError.message
  ) {
    super(message);
    this.code = errorCodes.eventContentMissingStartOrEndDateError.code;
    this.message = `${message} ${errorCodes.eventContentMissingStartOrEndDateError.code}`;
    this.name = errorCodes.eventContentMissingStartOrEndDateError.name;
  }
}
