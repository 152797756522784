import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class GenerateSignedUrlError extends CodedError {
  static get errorName() {
    return errorCodes.generateSignedUrlError.code;
  }

  constructor(message = errorCodes.generateSignedUrlError.message) {
    super(message);
    this.code = errorCodes.generateSignedUrlError.code;
    this.message = `${message} ${errorCodes.generateSignedUrlError.code}`;
    this.name = errorCodes.generateSignedUrlError.name;
    this.status = errorCodes.generateSignedUrlError.status;
  }
}
