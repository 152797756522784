import { NetworkStatus } from '@apollo/client';

import { SignUpContextType } from './SignUpContextType';

const defaultSignUpContext: SignUpContextType = {
  name: '',
  password: '',
  email: '',
  companyName: '',
  building: null,
  emailOptIn: false,
  buildingsResult: {
    buildings: [],
    loading: false,
    called: false,
    error: null,
    networkStatus: NetworkStatus.ready,
    fetchMore: () => {},
    refetch: () => {},
  },
  company: null,
  companyLocationsResult: {
    isSignUpBlocked: false,
    called: false,
    loading: false,
    error: null,
    locations: [],
    refetch: () => {},
    fetchNextPage: () => {},
    networkStatus: NetworkStatus.ready,
  },
  parentCompany: null,
  companyParentsResult: {
    called: false,
    loading: false,
    error: null,
    companies: [],
    networkStatus: NetworkStatus.ready,
    fetchMore: () => {},
    refetch: () => {},
  },
  submitting: false,
  submitError: null,
  searchLoading: false,
  searchError: null,
  signupError: null,
  buildingSearch: '',
  companySearch: '',
  parentCompanySearch: '',
  oAuth: undefined,
  oAuthConfigId: null,
  isPublic: false,
  isLocked: false,
  loginProvider: null,
  enterprise: false,
  companyRelatedTo: null,
  shouldSkipCompanySelection: false,
  inviteId: null,
  inviteError: null,
  updateSignUp: () => null,
  clearInvite: () => null,
  resetSignUp: () => {},
  doSignUp: () => ({} as any), // TODO: fix
  addOAuthSignUpDetails: () => undefined,
};

export default defaultSignUpContext;
