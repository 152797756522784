import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class NewstandDigestError extends CodedError {
  static get errorName() {
    return errorCodes.newstandDigestError.code;
  }

  constructor(error: Error) {
    super(error.message);
    this.code = errorCodes.newstandDigestError.code;
    this.message = `${error.message} ${errorCodes.newstandDigestError.code}`;
    this.name = errorCodes.newstandDigestError.name;
    this.stack = error.stack;
  }
}
