import gql from 'graphql-tag';

export const provisionAuth0UserToOrganization = gql`
  mutation provisionAuth0UserToOrganization(
    $email: String!
    $connection: String!
  ) {
    provisionAuth0UserToOrganization(email: $email, connection: $connection) {
      user {
        id
        email
        connection
      }
    }
  }
`;
