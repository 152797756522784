import React from 'react';

import Radio from '../Radio';

type Schema = {
  id: string | number;
  text: string;
  disabled?: string;
  subtext?: string;
};

type RadioGroupProps = {
  /** function to return provided value and name(radio field id) on select */
  onChange: (value: string) => void;
  /** dictionary to tell what fields from choices should be used for unique value and text */
  schema?: Schema;
  /** list of possible choices as objects (from api) */
  items: any;
  /** mutual name for radio buttons to group them */
  name?: string;
  /** selected instance of radio button */
  selected: any;
  /** flag to mark radio button as disabled */
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  doTranslate?: boolean;
  showBorder?: boolean;
};

const RadioGroup = ({
  schema = {
    id: 'id',
    text: 'text',
  },
  items = [],
  name = '',
  onChange = () => {},
  selected = null,
  disabled = false,
  className,
  style,
  doTranslate = true,
  showBorder,
}: RadioGroupProps) => (
  <>
    {(items as any).map((item: any) => (
      <Radio
        className={className}
        style={style}
        key={`${name}-${item[(schema as any).id]}`}
        disabled={disabled || item[(schema as any).disabled]}
        selected={selected}
        onChange={onChange}
        name={name}
        value={item[(schema as any).id]}
        text={item[(schema as any).text]}
        subtext={item[(schema as any).subtext]}
        showBorder={showBorder!}
        doTranslate={doTranslate}
      />
    ))}
  </>
);

export default React.memo(RadioGroup);
