import { STALE_CHANNEL_NOTIIFICATION_FLAG } from './constants';
import store from './store';

/*
 * clear the stale channel notification
 *
 * * */
export default function clear(): void {
  store.removeItem(STALE_CHANNEL_NOTIIFICATION_FLAG);
}
