import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  mutation updateEquipmentSettingsOnChannel(
    $settings: ChannelEquipmentSettingsInput!
  ) {
    updateEquipmentSettingsOnChannel(settings: $settings) {
      id
      channel {
        _id
      }
      categories
      locations
    }
  }
`);
