import { CodedError } from '../../CodedError';
import { ErrorCodeType } from '../../constants/errorCodes';

export abstract class GoogleCalendarError extends CodedError {
  constructor(errorCode: ErrorCodeType, message?: string) {
    super(errorCode.message);
    this.code = errorCode.code;
    this.message = `${message || errorCode.message} ${errorCode.code}`;
    this.name = errorCode.name;
  }
}
