import gql from 'graphql-tag';

export default gql`
  mutation addOauthLogin(
    $provider: UserLoginProvider!
    $userKey: String!
    $password: String
    $loginType: UserLoginType!
    $channelIntegrationId: UUID
  ) {
    addOAuthLogin(
      userKey: $userKey
      password: $password
      provider: $provider
      loginType: $loginType
      channelIntegrationId: $channelIntegrationId
    )
  }
`;
