import React from 'react';

import { Multiselect } from '../Multiselect/Multiselect';

export type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  onChange: (value: string[] | null) => void;
  options: Option[];
  value?: string[];
  label?: string;
  doTranslation?: boolean;
};

export const MultiselectFilter = ({
  id,
  onChange,
  value,
  options,
  label,
  doTranslation,
}: Props) => {
  const handleChange = (options: Option[]) => {
    onChange(options && options.length > 0 ? options.map(o => o.value) : null);
  };

  return (
    <Multiselect
      id={id}
      ariaLabel={label}
      onChange={handleChange}
      items={options}
      value={options.filter(o => value?.includes(o.value))}
      isFullWidth
      doTranslation={doTranslation}
      truncateSelectedItems
    />
  );
};
