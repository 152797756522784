import gql from 'graphql-tag';

export default gql`
  query getSubscriptionsOverTimeForChannel(
    $channelId: UUID!
    $startDate: String!
    $endDate: String!
  ) {
    portal {
      subscriptionsOverTimeForChannel(
        channelId: $channelId
        startDate: $startDate
        endDate: $endDate
      ) {
        name
        count
      }
    }
  }
`;
