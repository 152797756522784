import React from 'react';

import { Dropdown, DropdownItem } from '../../index';

export type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
  label: string;
  onChange: (value: string) => void;
  options: Option[];
  value?: string;
  doTranslation?: boolean;
  loadOptions?: (inputValue: string) => Promise<Option[]>;
  loadDefaultOptions?: (value: string) => Promise<Option[]>;
  placeholder?: string;
};

export const SelectFilter = ({
  id,
  label,
  onChange,
  value,
  options,
  doTranslation,
  loadOptions,
  loadDefaultOptions,
  placeholder,
}: Props) => {
  const [selected, setSelected] = React.useState<Option | null>(null);
  const [defaultOptions, setDefaultOptions] = React.useState<Option[]>();

  const handleChange = (item: DropdownItem<string | Option>) => {
    if (typeof item.value === 'string') {
      onChange(item.value);
      setSelected(item as Option);
    } else {
      onChange(item.value.value);
      setSelected(item.value);
    }
  };

  React.useEffect(() => {
    if (!value && selected) {
      setSelected(null);
    }

    if (selected === null && value && loadDefaultOptions) {
      loadDefaultOptions(value).then(options => {
        if (options.length > 0) {
          setSelected(options.find(o => o.value === value) || null);
        }
      });
    }
  }, [value]);

  React.useEffect(() => {
    if (loadDefaultOptions && loadOptions && value) {
      loadOptions(value).then(options => {
        if (options.length > 0) {
          setDefaultOptions(options);
          setSelected(options.find(o => o.value === value) || null);
        }
      });
    }
  }, [loadDefaultOptions]);

  return (
    <Dropdown
      id={id}
      ariaLabel={label}
      items={options}
      onChange={handleChange}
      value={(loadOptions ? selected : value) as DropdownItem<string | Option>}
      isFullWidth
      doTranslation={doTranslation}
      loadOptions={
        loadOptions as (
          inputValue: string
        ) => Promise<DropdownItem<string | Option>[]>
      }
      defaultOptions={loadDefaultOptions ? defaultOptions : []}
      placeholder={placeholder}
    />
  );
};
