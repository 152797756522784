import gql from 'graphql-tag';

export default gql`
  query getUserChannelsWithMobileAccess($userId: UUID!, $channelId: UUID) {
    getUserChannelsWithMobileAccess(userId: $userId, channelId: $channelId) {
      channelId
      channelName
      mobileAccess
    }
  }
`;
