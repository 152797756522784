import gql from 'graphql-tag';

export default gql`
  mutation upsertVisitorEmailTemplate(
    $emailTemplate: VisitorEmailTemplateInput!
  ) {
    upsertVisitorEmailTemplate(emailTemplate: $emailTemplate) {
      organizationId
      templateType
      logoId
      backgroundId
      createdByUserId
      updatedByUserId
      qrEnabled
      entryInstructions
      policies
      createdAtDatetime
      updatedAtDatetime
    }
  }
`;
