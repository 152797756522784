import React from 'react';

import { useTranslation } from 'react-i18next';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { Icon, PopupMenu, Button } from 'design-system-web';

import useChannelLocationsSelector, {
  ALL_LOCATIONS,
} from 'lane-shared/hooks/useChannelLocationsSelector';
import { PresetContentFilterLocation } from 'lane-shared/types/filters/PresetContentFilterLocation';

import Dropdown from 'components/form/Dropdown';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channels?: any[];
  filter?: PresetContentFilterLocation;
  showAllLocations: boolean;
  onFilterUpdated: (filter: PresetContentFilterLocation) => void;
};

export default function ChannelLocationsSelectorDropdown({
  className,
  channels = [],
  filter,
  showAllLocations,
  onFilterUpdated = () => null,
}: Props) {
  const { t } = useTranslation();
  const sectionUIMemberUpdate = useFlag(
    FeatureFlag.SectionUIMemberUpdate,
    false
  );

  const { changeHandler, items } = useChannelLocationsSelector({
    channels,
    filter,
    showAllLocations,
    onFilterUpdated,
    allLocationsDefault: true,
  });

  const menuItems = items.map(item => ({
    ...item,
    onSelect: () => {
      changeHandler(item.value);
    },
  }));

  const selectedLocation =
    items.find(item => item.value === filter?.id)?.label || t(ALL_LOCATIONS);

  if (sectionUIMemberUpdate) {
    return (
      <PopupMenu
        trigger={({ isOpen, ...buttonProps }) => (
          <Button
            endIcon={<Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />}
            startIcon={<Icon name="map-marker-alt" />}
            variant="secondary"
            size="medium"
            {...buttonProps}
          >
            {selectedLocation}
          </Button>
        )}
        items={menuItems}
      />
    );
  }

  return (
    <Dropdown
      placeholder={t(ALL_LOCATIONS)}
      className={className}
      onValueChange={changeHandler}
      items={items}
      initialValue={null}
      value={filter?.id}
      isSearchable
      doTranslation={false}
    />
  );
}
