import React, { useContext } from 'react';

import cx from 'classnames';

import config from 'lane-shared/config';
import AppContext from 'lane-shared/contexts/AppContext';

import styles from './Sidebar.scss';

type Props = {
  type?: 'subscriber' | 'admin' | 'super';
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

export function Sidebar({
  type = 'subscriber',
  className,
  style,
  children,
}: Props) {
  const { whitelabel } = useContext(AppContext);
  return (
    <div
      className={cx(styles.Sidebar, className)}
      style={style}
      data-test="sidebar"
      data-type={type}
    >
      <ul className={styles.sidebarItems} role="menu">
        {children}
      </ul>
      <p className={styles.appVersion}>
        {whitelabel?.operatingName} &copy; {new Date().getFullYear()}
        <span>v{config.laneVersion}</span>
      </p>
    </div>
  );
}
