import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import Tooltip from './Tooltip';

import styles from './Label.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  TooltipComponent?: React.ReactNode;
  children: React.ReactNode;
  mb?: number;
  mt?: number;
  as?: string;
  disabled?: boolean;
  htmlFor?: string;
};

export default function Label({
  style,
  className,
  TooltipComponent,
  children,
  h1 = false,
  h2 = false,
  h3 = false,
  h4 = false,
  mt = 2,
  mb = 0,
  as,
  disabled = false,
  htmlFor,
}: Props) {
  const tooltip = TooltipComponent ? (
    <Tooltip
      TooltipComponent={TooltipComponent}
      placement="right"
      className={styles.tooltip}
      zIndex={7}
    >
      <Icon
        name="info-circle"
        className={styles.icon}
        set={ICON_SET_FONTAWESOME}
        type="far"
      />
    </Tooltip>
  ) : null;

  let element = 'label';

  if (h1) {
    element = 'h1';
  } else if (h2) {
    element = 'h2';
  } else if (h3) {
    element = 'h3';
  } else if (h4) {
    element = 'h4';
  }

  if (as) {
    element = as;
  }

  return React.createElement(
    element,
    {
      className: cx(styles.Label, className),
      htmlFor,
      style,
      'data-margin-top': mt,
      'data-margin-bottom': mb,
      'data-text-disabled': disabled,
    },
    children,
    tooltip
  );
}
