import gql from 'graphql-tag';

export default gql`
  mutation signUp(
    $name: String!
    $loginKey: ID!
    $loginType: UserLoginType!
    $password: String
    $channelIds: [UUID]
    $inviteId: UUID
    $loginProvider: UserLoginProvider
    $isPublic: Boolean!
    $emailOptIn: Boolean
    $locale: SupportedLocale
    $metadata: JSON
  ) {
    signUp(
      name: $name
      loginKey: $loginKey
      loginType: $loginType
      password: $password
      channelIds: $channelIds
      inviteId: $inviteId
      isPublic: $isPublic
      loginProvider: $loginProvider
      emailOptIn: $emailOptIn
      locale: $locale
      metadata: $metadata
    ) {
      token
      jti
      warnings
    }
  }
`;
