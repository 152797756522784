import gql from 'graphql-tag';

export default gql(`
  fragment AnonymousChannelProfileFragment on Channel {
    _id
    name
    description
    slug
    type
    isPublic
    isDiscoverable
    isLive
    isSub
    inviteOnly

    pages {
      _id
      _order
      name
      slug
      icon
      iconSet
      label
      channel {
        slug
      }
      content {
        _id
        _created
        _updated
      }
    }

    profile {
      name
      image
      logo
    }
}`);
