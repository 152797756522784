import { gql } from '@apollo/client';

export const listChargesQuery = gql`
  query listCharges($listChargesRequest: ListChargesRequest!) {
    accounts {
      listCharges(listChargesRequest: $listChargesRequest) {
        charges {
          id
          name
          description
          amount
          status
          externalPayerId
          currency
          createdAt
          updatedAt
          invoice {
            id
            invoiceDisplayId
            status
            invoiceDate
            dueDate
          }
          items {
            product {
              id
              name
              description
              productCategory {
                productCategoryId
                name
                description
                productType
              }
              amount
              markup {
                value
                markupAmountType
              }
              tax {
                value
                taxAmountType
              }
              originalPrice {
                amount
                markup {
                  value
                  markupAmountType
                }
                tax {
                  value
                  taxAmountType
                }
              }
            }
            quantity
          }
        }
        pageInfo {
          perPage
          total
          start
        }
      }
    }
  }
`;
