import gql from 'graphql-tag';

export default gql`
  query getCompleteSignupData($guid: String!, $verify: String!) {
    completeSignupData(guid: $guid, verify: $verify) {
      _id
      name
      email
      clientTypeName
    }
  }
`;
