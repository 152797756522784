import gql from 'graphql-tag';

import {
  PublicUserFragment,
  FullProfileWithThemeFragment,
  ThemeFragment,
  AddressFragment,
  SectionFragment,
} from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${AddressFragment}
  ${SectionFragment}

  query getChannel($id: UUID, $slug: String) {
    channel(_id: $id, slug: $slug) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }

      name
      website
      description
      slug
      type
      experienceType
      isPublic
      isDiscoverable
      isLive
      isSub
      inviteOnly
      filters
      tags

      settings {
        hasPerksEnabled
        hasWorkOrdersEnabled
        hasWorkOrderServiceRequestsEnabled
        hasWorkOrderPreventiveMaintenanceEnabled
        hasWorkOrderEquipmentEnabled
        hasVisitorManagementEnabled
        hasBillingPaymentsEnabled
        multiLanguageEnabled
        channelLanguages
        enabledLanguagesForMembers
        hasHardwareManagementEnabled
        hasHardwarePinManagementEnabled
        hasReservableManagementEnabled
        hasSurveysEnabled
        hasWorkOrderCrossPropertyEnabled
        hasSmartUserManagementEnabled
        hasAccessControlEnabled
        hasMessagingAndMarketplaceEnabled
        areUserTemplatesAllowed
        subscribersJoinParent
        subscribersAreDecoyed
        groupRoleJoinRules {
          _id
          groupRoleId
          joinGroupRoleId
          channelId
        }
      }

      address {
        ...AddressFragment
      }

      profile {
        ...FullProfileWithThemeFragment
      }

      parent {
        _id
        name
        slug
      }

      pages {
        _id
        _order
        _created
        _updated
        content {
          _id
          _created
          _updated
        }
        name
        slug
        icon
        iconSet
        label
        label_l10n
      }

      team {
        _id
        name
        users {
          ...PublicUserFragment
        }
      }

      sections {
        ...SectionFragment
      }
    }
  }
`;
