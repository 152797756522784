import gql from 'graphql-tag';

import { PublicUserFragment } from 'lane-shared/graphql/fragments';
import { PublicUserFragmentType } from 'lane-shared/graphql/fragments/PublicUserFragment';

type GroupRole = {
  _id: string;
  name: string;
};

export type GetInvitesByChannelResponse = {
  invitesOnChannel: {
    pageInfo: {
      start: number;
      total: number;
      perPage: number;
    };
    items: {
      _id: string;
      _created: string;
      _updated: string;
      _createdBy: PublicUserFragmentType;
      _updatedBy: PublicUserFragmentType;
      name: string;
      email: string;
      sent: Date;
      status: string;
      result: string | null;
      expires: string;
      groupRole: GroupRole;
      groupRoles: GroupRole[];
    }[];
  };
};

export const getInvitesByChannel = gql`
  ${PublicUserFragment}

  query getInvitesByChannel(
    $channelId: UUID!
    $pagination: PaginationInput
    $search: ChannelInviteSearch
    $skipPagination: Boolean
  ) {
    invitesOnChannel(
      channelId: $channelId
      search: $search
      pagination: $pagination
      skipPagination: $skipPagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        name
        email
        sent
        status
        result
        expires
        groupRole {
          _id
          name
        }
        groupRoles {
          _id
          name
        }
      }
    }
  }
`;
