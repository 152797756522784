import gql from 'graphql-tag';

export default gql`
  query ServiceRequestAuditLogs($serviceRequestId: String!) {
    serviceRequestAuditLogs(serviceRequestId: $serviceRequestId) {
      userName
      userImageUrl
      actions {
        field
        title
        previousState
        currentState
      }
      date
    }
  }
`;
