import React from 'react';
import styles from './WorkflowDetailsV2.scss';
import {
  Dropdown,
  Label,
  ValidationMessage,
  MultiselectField,
  Flex,
} from 'components';
import { WorkflowWhenEnum } from 'lane-shared/types/Workflows';
import type { Workflow } from 'lane-shared/types/workflow';
import { WorkflowTypeEnum } from 'lane-shared/types/workflow';
import type { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';
import { useTranslation } from 'react-i18next';
import useWorkflowWhenOptions from 'components/workflows/useWorkflowWhenOptions';
import useTimeUnits from 'components/workflows/useTimeUnits';
import useWorkflowEventOptions from './useWorkflowEventOptions';
import type { CancelableFeatureProperties } from 'lane-shared/types/features/CancelableFeatureProperties';
import type { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';
import { workflowHandlers } from './helpers/workflowHandlers';
import { ContentType } from 'lane-shared/types/content/Content';
import { EVENT_CONTENT_INTERACTION_STATUSCHANGE } from 'lane-shared/helpers/constants/events';
import useContentStatusTransitionOptions from './useContentStatusTransitionOptions';
import { constructDefaultWorkflowData } from 'lane-shared/helpers/content/constructWorkflow';
import { getValidationMessages, toSchema } from 'lane-shared/helpers';
import { ValidationError } from 'yup';
import isEqual from 'lodash/isEqual';
import { IsInStatusOptionsType } from './helpers/constants';
import {
  INTERACTION_OPEN_STATES,
  INTERACTION_STATES,
} from 'lane-shared/helpers/constants/interactions';
import { ContentWorkflowEventType } from 'lane-shared/types/ContentWorkflowType';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import { WorkflowEscalationConditions } from './workOrder/WorkflowEscalationConditions';

type Props = {
  workflow: Workflow;
  onWorkflowUpdated: (updates: Partial<Workflow>) => void;
  content: ContentType;
  reservableFeature?: ReservableFeatureProperties;
  cancelableFeature?: CancelableFeatureProperties;
  statusesFeature?: StatusesFeatureProperties;
  isEditing?: boolean;
  validation: ValidationError | null;
  isNewWorkflowsUIEnabled?: boolean;
};

export function WorkflowConditions({
  workflow,
  onWorkflowUpdated,
  content,
  reservableFeature,
  cancelableFeature,
  statusesFeature,
  isEditing,
  validation,
  isNewWorkflowsUIEnabled,
}: Props) {
  const { t } = useTranslation();
  const { whenOptions, whenContextOptions } = useWorkflowWhenOptions({
    when: workflow.when,
    contentType: content?.type,
    workflowType: workflow.type,
    reservableFeature,
    isNewWorkflowsUIEnabled,
  });

  const {
    whenChangeHandler,
    whenContextChangeHandler,
    updateWorkflowStatusState,
  } = workflowHandlers({
    workflow,
    onWorkflowUpdated,
    content,
    reservableFeature,
  });

  const {
    timeUnit,
    timeValue,
    timeOptions,
    timeUnitOptions,
    updateTimeUnit,
    updateTimeUnitValue,
  } = useTimeUnits({
    when: workflow.when,
    time: workflow.time,
    onTimeUpdated: time => onWorkflowUpdated({ time }),
  });

  const eventOptions = useWorkflowEventOptions({
    when: workflow.when,
    whenContext: workflow.whenContext,
    cancelableFeature,
    statusesFeature,
    isNewWorkflowsUIEnabled,
  });

  const {
    statusFromOptions,
    statusToOptions,
  } = useContentStatusTransitionOptions({
    statusesFeature,
    statusFrom: workflow.workflow?.statusFrom,
    isNewWorkflowsUIEnabled,
  });

  const workflowStatus = deriveWorkflowStatus(workflow);

  const statuses = INTERACTION_STATES.map(toSchema);

  function updateWorkflowInStatus(action: [{ value: string; label: string }]) {
    onWorkflowUpdated({
      inStatus: action?.length ? action.map(x => x.value) : null,
    });
  }

  function deriveWorkflowStatus(workflow: Workflow) {
    if (!workflow.inStatus) {
      // when this is a new workflow, we default to open
      updateWorkflowStatusState(IsInStatusOptionsType.Open);
    }
    if (isEqual(workflow.inStatus, INTERACTION_OPEN_STATES)) {
      return IsInStatusOptionsType.Open;
    }
    if (isEqual(workflow.inStatus, INTERACTION_STATES)) {
      return IsInStatusOptionsType.Any;
    }
    return IsInStatusOptionsType.Custom;
  }

  if (workflow.type === WorkflowTypeEnum.ServiceRequestEscalation) {
    return (
      <WorkflowEscalationConditions
        workflow={workflow}
        onWorkflowUpdated={onWorkflowUpdated}
        isEditing={isEditing}
      />
    );
  }
  const workflowEventDropdownClassname = `${
    whenContextOptions.length === 1 ? 'mediumLargeDropdown' : 'mediumDropdown'
  }${isEditing ? 'InEditMode' : ''}`;

  return (
    <div>
      <Label className={styles.conditionsLabel}>
        {t('web.admin.channel.content.workflow.editor.v2.conditions.title')}
      </Label>
      <Flex
        className={styles.conditionsWrapper}
        justify={whenContextOptions.length === 1 ? '' : 'space-between'}
      >
        {[WorkflowWhenEnum.Before, WorkflowWhenEnum.After].includes(
          workflow.when
        ) && (
          <>
            <Dropdown
              testId="workflowTimeDropdown"
              disabled={!isEditing}
              className={
                isEditing
                  ? styles.mediumLargeDropdownInEditMode
                  : styles.mediumLargeDropdown
              }
              items={timeOptions[timeUnit]}
              value={timeValue}
              doTranslation={false}
              onValueChange={updateTimeUnitValue}
            />
            <Dropdown
              testId="workflowTimeUnitDropdown"
              className={
                isEditing
                  ? styles.mediumLargeDropdownInEditMode
                  : styles.mediumLargeDropdown
              }
              disabled={!isEditing}
              doTranslation={false}
              items={timeUnitOptions.map(option => ({
                value: option.value,
                label: t(option.label),
              }))}
              value={timeUnit}
              onValueChange={updateTimeUnit}
            />
          </>
        )}
        {content?.type !== ContentTypeEnum.WorkOrder &&
          (whenOptions.length === 1 && whenOptions[0] ? (
            <Flex align="center" justify="flex-end">
              <span>{whenOptions[0].label}</span>
            </Flex>
          ) : (
            <Dropdown
              testId="workflowWhenDropdown"
              className={
                isEditing
                  ? styles.mediumDropdownInEditMode
                  : styles.mediumDropdown
              }
              disabled={!isEditing}
              items={whenOptions}
              value={workflow.when}
              doTranslation={false}
              onValueChange={whenChangeHandler}
            />
          ))}
        {whenContextOptions.length === 1 && whenContextOptions[0] ? (
          <Flex align="center">
            <span>{whenContextOptions[0].label}</span>
          </Flex>
        ) : (
          <Dropdown
            testId="workflowWhenContextDropdown"
            className={
              isEditing
                ? styles.mediumDropdownInEditMode
                : styles.mediumDropdown
            }
            disabled={!isEditing}
            onValueChange={whenContextChangeHandler}
            items={whenContextOptions}
            doTranslation={false}
            value={workflow.whenContext}
          />
        )}
        {content?.type !== ContentTypeEnum.WorkOrder && (
          <div
            className={whenOptions.length === 1 && styles.largeEventDropdown}
          >
            <Dropdown
              testId="workflowEventDropdown"
              className={styles[workflowEventDropdownClassname]}
              disabled={!isEditing}
              placeholder={t(
                'web.admin.channel.content.workflow.editor.selectEvent.placeholder'
              )}
              doTranslation={false}
              items={eventOptions}
              value={workflow.event}
              onValueChange={event =>
                onWorkflowUpdated({ event: event as ContentWorkflowEventType })
              }
            />
            <ValidationMessage
              className={styles.errorMessage}
              errors={getValidationMessages(validation, 'event', '')}
            />
          </div>
        )}
        {workflow.event &&
          workflow.event === EVENT_CONTENT_INTERACTION_STATUSCHANGE && (
            <>
              <div>
                <Dropdown
                  placeholder={t(
                    'web.admin.channel.content.workflow.editor.v2.conditions.placeholder.fromStatus'
                  )}
                  disabled={!isEditing}
                  className={
                    isEditing
                      ? styles.mediumLargeDropdownInEditMode
                      : styles.mediumLargeDropdown
                  }
                  items={statusFromOptions}
                  value={workflow.workflow?.statusFrom}
                  doTranslation={false}
                  onValueChange={statusFrom =>
                    onWorkflowUpdated({
                      workflow: {
                        ...(workflow.workflow ||
                          constructDefaultWorkflowData()),
                        statusFrom,
                      },
                    })
                  }
                />
                <ValidationMessage
                  className={styles.errorMessage}
                  errors={getValidationMessages(
                    validation,
                    'workflow.statusFrom',
                    ''
                  )}
                />
              </div>

              <div>
                <Dropdown
                  placeholder={t(
                    'web.admin.channel.content.workflow.editor.v2.conditions.placeholder.toStatus'
                  )}
                  disabled={!isEditing}
                  className={
                    isEditing
                      ? styles.mediumLargeDropdownInEditMode
                      : styles.mediumLargeDropdown
                  }
                  items={statusToOptions}
                  value={workflow.workflow?.statusTo}
                  doTranslation={false}
                  onValueChange={statusTo =>
                    onWorkflowUpdated({
                      workflow: {
                        ...(workflow.workflow ||
                          constructDefaultWorkflowData()),
                        statusTo,
                      },
                    })
                  }
                />
                <ValidationMessage
                  className={styles.errorMessage}
                  errors={getValidationMessages(
                    validation,
                    'workflow.statusTo',
                    ''
                  )}
                />
              </div>
            </>
          )}
        {content?.type !== ContentTypeEnum.WorkOrder &&
          [WorkflowWhenEnum.Before, WorkflowWhenEnum.After].includes(
            workflow.when
          ) && (
            <>
              <Dropdown
                className={
                  isEditing
                    ? styles.extraLargeDropdownInEditMode
                    : styles.extraLargeDropdown
                }
                disabled={!isEditing}
                items={[
                  {
                    value: IsInStatusOptionsType.Any,
                    label: t(
                      'web.admin.channel.content.workflow.editor.v2.outcome.status.any.option'
                    ),
                  },
                  {
                    value: IsInStatusOptionsType.Open,
                    label: t(
                      'web.admin.channel.content.workflow.editor.v2.outcome.status.open.option'
                    ),
                  },
                  {
                    value: IsInStatusOptionsType.Custom,
                    label: t(
                      'web.admin.channel.content.workflow.editor.v2.outcome.status.custom.option'
                    ),
                  },
                ]}
                onValueChange={updateWorkflowStatusState}
                value={workflowStatus}
              />
              {workflowStatus === IsInStatusOptionsType.Custom && (
                <MultiselectField
                  disabled={!isEditing}
                  className={
                    isEditing
                      ? styles.extraLargeDropdownInEditMode
                      : styles.extraLargeDropdown
                  }
                  items={statuses}
                  placeholder={t(
                    'web.admin.channel.content.workflow.editor.selectStatuses.placeholder'
                  )}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '(action: [{ value: string; label: string; }]... Remove this comment to see the full error message
                  onChange={updateWorkflowInStatus}
                  value={
                    workflow?.inStatus && Boolean(workflow?.inStatus?.length)
                      ? workflow.inStatus.map(toSchema)
                      : []
                  }
                  initialValues={
                    workflow?.inStatus && Boolean(workflow?.inStatus?.length)
                      ? workflow.inStatus.map(toSchema)
                      : []
                  }
                />
              )}
            </>
          )}
      </Flex>
    </div>
  );
}
