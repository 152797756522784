import React from 'react';
import { ChannelServiceRequestFeaturesType } from 'lane-shared/types/ChannelType';

type ChannelServiceRequestFeaturesContextType = {
  serviceRequestFeatures: ChannelServiceRequestFeaturesType;
  setServiceRequestFeatures: React.Dispatch<
    React.SetStateAction<ChannelServiceRequestFeaturesType>
  >;
  refetchChannelFeatures: () => void;
};

// Create the context with default values
const ChannelServiceRequestFeaturesContext = React.createContext<ChannelServiceRequestFeaturesContextType>(
  {
    serviceRequestFeatures: {
      createServiceRequest: true,
      updateServiceRequest: true,
    },
    setServiceRequestFeatures: () => {},
    refetchChannelFeatures: () => {},
  }
);

export { ChannelServiceRequestFeaturesContext };
