import i18next from 'i18next';

import { SurveyTypeEnum } from 'lane-shared/types/features/SurveysFeatureProperties';

import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';

const satisfiedTranslationPrefix =
  'web.admin.content.features.surveys.satisfiedDropdown.';
const SATISFIED_DROPDOWNS = [
  i18next.t(`${satisfiedTranslationPrefix}completelySatisfied`),
  i18next.t(`${satisfiedTranslationPrefix}somewhatSatisfied`),
  i18next.t(`${satisfiedTranslationPrefix}neutral`),
  i18next.t(`${satisfiedTranslationPrefix}somewhatUnsatisfied`),
  i18next.t(`${satisfiedTranslationPrefix}completelyUnsatisfied`),
];

const capacityTranslationPrefix =
  'web.admin.content.features.surveys.capacityDropdown.';
const CAPACITY_DROPDOWNS = [
  i18next.t(`${capacityTranslationPrefix}over`),
  i18next.t(`${capacityTranslationPrefix}right`),
  i18next.t(`${capacityTranslationPrefix}under`),
];

export type QuestionsType = {
  key: string;
  dropdowns?: string[];
  type: PackagedTypeEnum | string;
};

export const TENANT_ADMIN_SATISFACTION_QUESTIONS: QuestionsType[] = [
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q2'
    ),
    dropdowns: [
      ...SATISFIED_DROPDOWNS,
      i18next.t('web.admin.content.features.surveys.dropdown.notApplicable'),
    ],
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q3'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q4'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q5'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q6'
    ),
    dropdowns: CAPACITY_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q7'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q8'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q9'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q1'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: PackagedTypeEnum.Dropdown,
  },
  {
    key: i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.q11'
    ),
    dropdowns: SATISFIED_DROPDOWNS,
    type: 'LongText',
  },
];

const emptyParagraph = {
  type: 'paragraph',
  children: [
    {
      text: '',
    },
  ],
};

const getRichTextObject = (text: string, type = 'paragraph') => {
  return {
    type,
    children: [
      {
        text,
      },
    ],
  };
};

const getHeaderObject = (order: number, header: string) => {
  return {
    type: 'Header',
    order,
    properties: {
      size: 'Medium',
      header,
      textAlign: 'left',
    },
  };
};

export const TENANT_ADMIN_SATISFACTION_BLOCKS = [
  {
    type: 'Hero',
    order: 0,
    properties: {
      size: 'Medium',
      // Office Building.jpg
      images: ['4q2fxqxzGUWfrbqOgSSjOt'],
      textColor: '#FFFFFF',
      useGradient: true,
      backgroundColor: '#000',
    },
  },
  {
    type: 'RichText',
    order: 1,
    properties: {
      text: [
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.title'
          ),
          'heading-two'
        ),
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.p1'
          )
        ),
        emptyParagraph,
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.p2'
          )
        ),
        emptyParagraph,
        getRichTextObject(
          i18next.t(
            'web.admin.content.features.surveys.tenantAdminSatisfaction.block.p3'
          )
        ),
      ],
    },
  },
  getHeaderObject(
    2,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h1'
    )
  ),
  getHeaderObject(
    6,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h2'
    )
  ),
  getHeaderObject(
    9,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h3'
    )
  ),
  getHeaderObject(
    13,
    i18next.t(
      'web.admin.content.features.surveys.tenantAdminSatisfaction.block.h4'
    )
  ),
];

export const SURVEY_TYPE_TO_CONTENT_MAPPING: {
  [key in SurveyTypeEnum]: {
    questions: QuestionsType[];
    blocks: any[];
  };
} = {
  [SurveyTypeEnum.TenantAdminSatisfaction]: {
    questions: TENANT_ADMIN_SATISFACTION_QUESTIONS,
    blocks: TENANT_ADMIN_SATISFACTION_BLOCKS,
  },
};

const contentArrs = Object.values(SURVEY_TYPE_TO_CONTENT_MAPPING);
const questionsArrs: QuestionsType[][] = contentArrs.map(
  ({ questions }: { questions: QuestionsType[] }) => questions
);
const flattendQuestionsArr: QuestionsType[] = questionsArrs.flat(1);
export const ALL_SURVEY_QUESTIONS = flattendQuestionsArr.map(({ key }) => key);
