import gql from 'graphql-tag';

export default gql`
  query getPassCredential($visitorId: UUID!) {
    credential(visitorId: $visitorId) {
      credential
      credentialId
      uri
    }
  }
`;
