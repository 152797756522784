import React, { useContext, useState, useEffect, useRef } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { UserDataContext } from 'lane-shared/contexts';
import { constructAddress, getValidationMessages } from 'lane-shared/helpers';
import longAddress from 'lane-shared/helpers/formatters/longAddress';
import Types from 'lane-shared/properties/Types';
import { AddressType } from 'lane-shared/types/AddressType';
import { validateCreateAddress } from 'lane-shared/validation';

import SimpleInput from 'components/form/SimpleInput';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import IconButton from 'components/general/IconButton';
import Label from 'components/general/Label';
import ModalBackground from 'components/general/ModalBackground';
import PlacesAutocomplete, {
  PlacesAutocompleteHandle,
} from 'components/general/PlacesAutocomplete';
import ResizableWindow from 'components/general/ResizableWindow';
import Stepper from 'components/general/Stepper';

import styles from './AddressEdit.scss';

const example = Types.getBaseTypes().Address!.example;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  address: AddressType;
  onAddressUpdated: (address: AddressType) => void;
  mb?: number;
  mt?: number;
};

const steps = ['Search for an address', 'Confirm details'];
const NO_GEO_LOCATION = 'web.components.lane.AddressEdit.noGeoLocation';

export default function AddressEdit({
  className,
  style,
  address,
  onAddressUpdated,
  mb,
  mt,
}: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const [internalAddress, setInternalAddress] = useState<AddressType | null>(
    null
  );
  const [validation, setValidation] = useState<ValidationError | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [internalHasGeoLocation, setInternalHasGeoLocation] = useState<
    boolean | null
  >(null);
  const [hasGeoLocation, setHasGeoLocation] = useState<boolean | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const placesRef = useRef<PlacesAutocompleteHandle>();

  useEffect(() => {
    const places = placesRef.current;
    if (currentStep === 0 && isOpen && places) {
      places.focus();
      places.click();
    }
  }, [currentStep, isOpen]);

  function checkForGeoLocation(address: AddressType | null): boolean {
    const hasGeo = !!address?.geo?.[0];

    setInternalHasGeoLocation(hasGeo);
    return hasGeo;
  }

  async function validate(address: AddressType): Promise<boolean> {
    checkForGeoLocation(address);

    try {
      await validateCreateAddress.validate(address, { abortEarly: false });
      setValidation(null);
      return true;
    } catch (err) {
      setValidation(err);
      return false;
    }
  }

  function updateInternalAddress(props: Partial<AddressType>) {
    const newAddress = {
      ...internalAddress!,
      ...props,
    };

    setInternalAddress(newAddress);

    validate(newAddress);
  }

  function openEdit() {
    if (!address) {
      setInternalAddress(constructAddress({ userId: user?._id }));
    } else {
      setInternalAddress(address);
    }

    const hasGeo = checkForGeoLocation(address);
    setCurrentStep(hasGeo ? 1 : 0);
    setIsOpen(true);
  }

  function cancelEdit() {
    setIsOpen(false);
    setInternalAddress(null);
  }

  function confirmEdit() {
    setIsOpen(true);
    onAddressUpdated(internalAddress!);
    setInternalAddress(null);
  }

  useEffect(() => {
    setHasGeoLocation(checkForGeoLocation(address));
  }, [address]);

  return (
    <>
      <div
        className={cx(styles.AddressEdit, className)}
        style={style}
        data-margin-top={mt}
        data-margin-bottom={mb}
      >
        <ErrorMessage
          className={styles.errors}
          error={address && !hasGeoLocation && t(NO_GEO_LOCATION)}
        />

        <div className={styles.info} data-test="addressValue">
          <p>{longAddress(address)}</p>
          <IconButton icon="edit" onClick={openEdit} testId="addressButton" />
        </div>
      </div>
      {internalAddress && (
        <ModalBackground
          className={styles.background}
          onClose={cancelEdit}
          isOpen={isOpen}
        >
          <ResizableWindow
            className={styles.window}
            name="AddressEdit"
            defaultPosition={ResizableWindow.centerPosition()}
            onClose={cancelEdit}
            showHeader
          >
            <Stepper
              className={styles.stepper}
              steps={steps}
              active={currentStep}
              maxStep={internalHasGeoLocation ? 1 : 0}
              onClick={step => setCurrentStep(steps.findIndex(s => s === step))}
            />

            <div className={styles.modal}>
              {currentStep === 0 && (
                <>
                  <Label className={styles.label}>
                    {t(
                      'Begin by searching for an address.  You will be able to confirm details in the next step.'
                    )}
                  </Label>
                  <PlacesAutocomplete
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'MutableRefObject<PlacesAutocompleteHandle | ... Remove this comment to see the full error message
                    ref={placesRef}
                    className={cx(styles.places, styles.input)}
                    placeholder={t('Search...')}
                    value=""
                    onChange={place =>
                      updateInternalAddress({
                        ...place,
                        geo: [
                          (place.geo as any).longitude,
                          (place.geo as any).latitude,
                        ],
                      })
                    }
                  />
                </>
              )}

              {currentStep === 1 && (
                <>
                  <Label className={styles.label}>{t('Address Name')}</Label>
                  <SimpleInput
                    className={styles.input}
                    placeholder={t('i.e. Home Address')}
                    value={internalAddress!.name!}
                    errors={getValidationMessages(validation, 'name')}
                    onChange={name => updateInternalAddress({ name })}
                  />

                  <Label className={styles.label}>{t('Street')}</Label>
                  <SimpleInput
                    testId="addressValueStreet1"
                    className={styles.input}
                    placeholder={example.street1}
                    errors={getValidationMessages(validation, `street1`)}
                    value={internalAddress!.street1!}
                    onChange={street1 => updateInternalAddress({ street1 })}
                  />

                  <SimpleInput
                    testId="addressValueStreet2"
                    className={styles.input}
                    placeholder={example.street2}
                    errors={getValidationMessages(validation, `street2`)}
                    value={internalAddress!.street2!}
                    onChange={street2 => updateInternalAddress({ street2 })}
                  />

                  <SimpleInput
                    testId="addressValueStreet3"
                    className={styles.input}
                    placeholder={example.street3}
                    errors={getValidationMessages(validation, `street3`)}
                    value={internalAddress!.street3!}
                    onChange={street3 => updateInternalAddress({ street3 })}
                  />

                  <div className={styles.row}>
                    <div className={styles.col}>
                      <Label className={styles.label}>{t('City')}</Label>
                      <SimpleInput
                        testId="addressValueCity"
                        className={styles.input}
                        placeholder={example.city}
                        errors={getValidationMessages(validation, `city`)}
                        value={internalAddress!.city!}
                        onChange={city => updateInternalAddress({ city })}
                      />
                    </div>

                    <div className={styles.col}>
                      <Label className={styles.label}>
                        {t('State / Province')}
                      </Label>
                      <SimpleInput
                        testId="addressValueState"
                        className={styles.input}
                        placeholder={example.state}
                        errors={getValidationMessages(validation, `state`)}
                        value={internalAddress!.state!}
                        onChange={state => updateInternalAddress({ state })}
                      />
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.col}>
                      <Label className={styles.label}>{t('Country')}</Label>
                      <SimpleInput
                        testId="addressValueCountry"
                        className={styles.input}
                        placeholder={example.country}
                        errors={getValidationMessages(validation, `country`)}
                        value={internalAddress!.country!}
                        onChange={country => updateInternalAddress({ country })}
                      />
                    </div>

                    <div className={styles.col}>
                      <Label className={styles.label}>{t('Code')}</Label>
                      <SimpleInput
                        testId="addressValueCode"
                        className={styles.input}
                        errors={getValidationMessages(validation, `code`)}
                        placeholder={example.code}
                        value={internalAddress!.code!}
                        onChange={code => updateInternalAddress({ code })}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <ControlMenu className={styles.menu} mt={2} mb={4}>
              {currentStep === 0 && (
                <>
                  <hr />
                  <Button variant="outlined-light" onClick={cancelEdit}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    testId="nextStepButton"
                    onClick={() => setCurrentStep(1)}
                    endIcon={<Icon name="chevron-right" />}
                  >
                    Next
                  </Button>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <Button
                    variant="outlined-light"
                    testId="backStepButton"
                    onClick={() => setCurrentStep(0)}
                    startIcon={<Icon name="chevron-left" />}
                  >
                    Back
                  </Button>
                  <hr />
                  <Button variant="outlined-light" onClick={cancelEdit}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    testId="doneButton"
                    onClick={confirmEdit}
                    disabled={Boolean(validation || !internalHasGeoLocation)}
                  >
                    Done
                  </Button>
                </>
              )}
            </ControlMenu>
          </ResizableWindow>
        </ModalBackground>
      )}
    </>
  );
}
