import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query invitesOnChannel(
    $channelId: UUID!
    $groupRoleId: UUID!
    $pagination: PaginationInput
  ) {
    invitesOnChannel(
      channelId: $channelId
      search: { groupRole: { _id: $groupRoleId } }
      pagination: $pagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        name
        email
        sent
        status
        result
        expires
      }
    }
  }
`;
