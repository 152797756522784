import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class InviteExpiredError extends CodedError {
  static get errorName() {
    return errorCodes.inviteExpiredError.code;
  }

  constructor(message = errorCodes.inviteExpiredError.message) {
    super(message);
    this.code = errorCodes.inviteExpiredError.code;
    this.message = `${message} ${errorCodes.inviteExpiredError.code}`;
    this.name = errorCodes.inviteExpiredError.name;
  }
}
