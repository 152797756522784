import gql from 'graphql-tag';

const getServiceRequestsByUCIQuery = gql`
  query getServiceRequestsByUCI($uciId: String!) {
    serviceRequestByUCI(contentInteractionId: $uciId) {
      channelId {
        name
        id
      }
      serviceRequestId
      createdBy {
        name
        id
      }
      createdAt
      updatedAt
      updatedBy {
        name
        id
      }
      description
      category
      issue
      location
      floor
      company {
        name
        id
      }
      contentId
      extRefId
      suite
      assignee {
        name
        id
      }
      status
      customQuestions {
        Question
        Answer
      }
      assigneeGroups
      attachmentRefId
      isBillable
      equipment
      friendlyID
      notes
      hours
      userFriendlyID
      nextStatuses
    }
  }
`;
export default getServiceRequestsByUCIQuery;
