import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class AudienceNotEmptyError extends CodedError {
  static get errorName() {
    return errorCodes.audienceNotEmptyError.code;
  }

  constructor(message = errorCodes.audienceNotEmptyError.message) {
    super(message);
    this.code = errorCodes.audienceNotEmptyError.code;
    this.message = `${message} ${errorCodes.audienceNotEmptyError.code}`;
    this.name = errorCodes.audienceNotEmptyError.name;
    this.status = errorCodes.audienceNotEmptyError.status;
  }
}
