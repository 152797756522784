import { createContext } from 'react';

import { LocationHelperInterface, LocationType } from '../types/LocationType';

type LocationContextType = {
  location: LocationType;
  locationHelper: LocationHelperInterface;
};

const LocationContext = createContext({} as LocationContextType);

export default LocationContext;
