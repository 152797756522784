import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class EssensysApiError extends CodedError {
  innerError: Error | undefined;

  static get errorName() {
    return errorCodes.essensysApiError.code;
  }

  constructor(message = errorCodes.essensysApiError.message) {
    super(message);
    this.code = errorCodes.essensysApiError.code;
    this.message = `${message} ${errorCodes.essensysApiError.code}`;
    this.name = errorCodes.essensysApiError.name;
  }

  setInnerError(err: Error) {
    this.innerError = err;
  }
}
