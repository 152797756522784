import React from 'react';

import { LaneType } from 'common-types';
import { getDisplayName } from 'lane-shared/helpers';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import addressFormatter from 'lane-shared/helpers/formatters/addressFormatter';
import { useChannelProfileQuery, useFlag } from 'lane-shared/hooks';
import useGuestInviteToIcsAttendees from 'lane-shared/hooks/features/useGuestInviteToIcsAttendees';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { PaymentSubscriptionReceiptDetails } from 'lane-web/src/components/lane/paymentSubscriptionReceiptDetails/PaymentSubscriptionReceiptDetails';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'qrco... Remove this comment to see the full error message
import QRCode from 'qrcode.react';
import Pill from 'components/general/Pill';
import { getInteractionColor } from 'helpers/getInteractionColor';
import { useTranslation } from 'react-i18next';

import {
  PaymentFeatureQuote,
  ContentFeatureGoogleCalendarButton,
  ContentFeatureCalendarButton,
} from 'components/features';
import LinkButton from 'components/general/LinkButton';
import StripeChargeDetails from 'components/payments/StripeChargeDetails';

import ReceiptFooter from './ReceiptFooter';
import ReceiptHeader from './ReceiptHeader';

import styles from './MenuContentInteractionReceipt.scss';
import { H5 } from 'components/typography';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';

type Props = {
  contentChannelId: LaneType.UUID;
  quote: PaymentFeatureQuoteType;
  email?: LaneType.Email;
  stripeData?: PaymentFeatureStripeDataType;
  date?: string;
  timeZone?: string;
  interaction: {
    _id: LaneType.UUID;
    startDate: string | Date | null;
    endDate: string | Date | null;
    status: string;
    geo: GeoCoordinateType;
    features: UserContentInteractionFeaturesType;
  };
  reservable: boolean;
  contentName?: string;
  contentDescription?: string;
  cancelable: boolean;
  disabled?: boolean;
  loading?: any;
  onCancel?: () => any;
  qrCode: boolean;
  qrCodeUrl: string;
  hasStatuses: boolean | StatusesFeatureProperties;
};

export default function PaymentContentInteractionReceipt({
  contentChannelId,
  quote,
  email,
  stripeData,
  date,
  reservable,
  timeZone,
  contentName,
  contentDescription,
  interaction,
  cancelable,
  disabled,
  loading,
  onCancel,
  qrCode,
  qrCodeUrl,
  hasStatuses,
}: Props) {
  const [, attendees] = useGuestInviteToIcsAttendees(
    interaction.features?.GuestInvite
  );
  const subscriptionsInContentFlag = useFlag(
    FeatureFlag.SubscriptionsInContent,
    false
  );
  const confirmationNumber = convertTo62(interaction._id);

  const { channel } = useChannelProfileQuery({
    channelId: contentChannelId,
  });

  const isSubscriptionReceipt =
    quote?.subscriptionDetails && subscriptionsInContentFlag;

  const reservableData = interaction.features?.Reservable;
  const startDate = reservableData?.reservation.startDate;
  const endDate = reservableData?.reservation.endDate;

  const { t } = useTranslation();

  return (
    <div className={styles.menuReceipt}>
      {isSubscriptionReceipt ? (
        <>
          <PaymentSubscriptionReceiptDetails
            channelName={getDisplayName(channel)}
            confirmationNumber={confirmationNumber}
            date={date || ''}
            contentName={contentName || ''}
            quote={quote}
          />
        </>
      ) : (
        <>
          <ReceiptHeader
            channelName={getDisplayName(channel)}
            confirmationNumber={confirmationNumber}
            email={email}
            date={date}
          />

          {hasStatuses && (
            <div className={styles.statusWrapper} data-test="statusPill">
              <div>
                <Pill
                  color={getInteractionColor(interaction?.status)}
                  size="medium"
                  text={t(interaction?.status)}
                />
              </div>
            </div>
          )}

          {qrCode && (
            <div className={styles.qrCodeWrapper}>
              <div className={styles.qrCode}>
                <H5>{t('abp.receipt.payment.checkInCode')}</H5>

                <div data-test="qr-code-container">
                  <QRCode renderAs="svg" value={qrCodeUrl} />
                </div>
              </div>
            </div>
          )}

          <PaymentFeatureQuote quote={quote} forReceipt />

          {stripeData && <StripeChargeDetails stripeData={stripeData} />}

          <div className={styles.reservable}>
            {reservable && (
              <>
                <ContentFeatureCalendarButton
                  className={styles.linkButton}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  name={contentName}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  description={contentDescription}
                  startDate={startDate}
                  endDate={endDate}
                  timeZone={timeZone}
                  interactionId={interaction._id}
                  attendees={attendees}
                  geo={interaction.geo}
                  features={interaction.features}
                  status={interaction.status}
                />
                <ContentFeatureGoogleCalendarButton
                  className={styles.linkButton}
                  interaction={interaction}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  name={contentName}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  description={contentDescription}
                  startDate={startDate}
                  endDate={endDate}
                  timeZone={timeZone}
                />
              </>
            )}
            {cancelable && (
              <LinkButton
                className={styles.linkButton}
                disabled={disabled}
                loading={loading}
                onClick={onCancel}
                iconName="times"
                doTranslate
              >
                Cancel
              </LinkButton>
            )}
          </div>
        </>
      )}
      <ReceiptFooter
        channelEmail={channel?.profile?.email}
        channelNumber={channel?.profile?.phone}
        address={addressFormatter(channel?.address, [
          'street1',
          'street2',
          'city',
          'state',
          'code',
          'country',
        ])}
      />
    </div>
  );
}
