import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

export const baseStyles = {
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
  },
  control: {
    borderRadius: '.5rem',
  },
  dropdownIndicator: {
    width: '2em',
    height: '2em',
    padding: '0.25em',
    marginRight: '1em',
    svg: {
      width: '1.25em',
      height: '1.25em',
    },
  },
  placeholder: {
    color: tokens.color.general.gray.primary,
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
  },
  menu: {
    zIndex: 1000000,
    shadowRadius: 3,
    borderRadius: '.5rem',
    elevation: 8,
    shadowColor: tokens.color.general.gray.primary,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.24,
    overflowY: 'auto',
  },
  menuList: {
    margin: '0.5rem 0',
    zIndex: 1000000,
    maxHeight: '15em',
    overflow: 'overlay',
    '::-webkit-scrollbar-thumb': {
      background: tokens.color.general.gray.secondary,
      opacity: '0.4',
      borderRadius: '0.625rem',
    },
    '::-webkit-scrollbar': {
      width: '0.5rem',
    },
  },
  valueContainer: {
    alignItems: 'center',
    flexWrap: 'wrap',
    marginRight: '0.5rem',
    padding: '0 0.875em',
    fontSize: '1rem',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
    fontSize: '14px',
    '& > svg, & > div': {
      marginRight: '0.5rem',
    },
    '&:lastChild': {
      marginBottom: '0',
    },
    cursor: 'pointer',
  },
  singleValue: {
    lineHeight: '1.5em',
  },
  input: {
    margin: '1px',
  },
  inputWithIcon: {
    color: 'transparent',
    margin: 0,
    padding: 0,
  },
  optionCheckboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '2em',
    height: '2em',
    alignItems: 'center',
    margin: '0.5em',
  },
} as const;
