import gql from 'graphql-tag';

export default gql`
  mutation createInteraction(
    $contentId: UUID!
    $interaction: UserContentInteractionInput!
    $meChannelId: UUID
    $submittingAsWorkplaceMember: Boolean
  ) {
    createContentInteraction(
      contentId: $contentId
      interaction: $interaction
      meChannelId: $meChannelId
      submittingAsWorkplaceMember: $submittingAsWorkplaceMember
    ) {
      _id
      _created
      _updated
      geo
      state
      features
      actions
      version
      status
      data
      contentData
      status
      startDate
      endDate
      version
      content {
        _id
        data
      }
      user {
        _id
        profile {
          _id
          name
        }
      }
    }
  }
`;
