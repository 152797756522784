import gql from 'graphql-tag';

import {
  FullProfileWithThemeFragment,
  ThemeFragment,
  AddressFragment,
} from '../fragments';

export default gql`
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${AddressFragment}

  query getWhiteLabels {
    whitelabels {
      _id
      _created
      _updated
      address {
        ...AddressFragment
      }
      profile {
        ...FullProfileWithThemeFragment
      }

      name
      description
      disableLocationPrecision
      instance
      channels {
        _id
        isPrimary
        channel {
          _id
        }
      }
      restrict
      commonName
      sha256Fingerprint
      mobileIos
      mobileAndroid
      privacyPolicyUrls
      termsAndConditionsUrls
      eula
      hosts
      url
      urlDevelopment
      urlStaging
      settings
      oAuthConfig {
        _id
        provider
        iosClientId
        androidClientId
        iosRedirectUrl
        androidRedirectUrl
        webClientId
        webClientSecret
        webRedirectUrl
        buttonLabel
        tenantId
        enabled
      }
      locale
      operatingName
      shortOperatingName
      isAppleIDEnabled
      isMagicLinkEnabled
      complianceContact
      auth0OrganizationId
    }
  }
`;
