import React, { useMemo, useState } from 'react';
import { H4 } from 'components/typography';
import { useTranslation } from 'react-i18next';
import { MultiselectField, Flex } from 'components';
import { Item } from 'components/form/Dropdown/Dropdown';
import { listUnits } from 'lane-shared/graphql/units';
import { useQuery } from '@apollo/client';
import { listPropertyFloors } from 'lane-shared/graphql/floors';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
  ListUnitsQuery,
  ListPropertyFloorsQuery,
} from 'graphql-query-contracts';

export type FloorsAndSuitesInputFields = {
  suites: Item<string>[];
  floors: Item<string>[];
};

export type FloorsAndSuitesFields = {
  floorIds: string[];
  suiteIds: string[];
};

export type FloorsAndSuitesInputFieldKey = keyof FloorsAndSuitesInputFields;

type Props = {
  suites?: Item<string>[];
  floors?: Item<string>[];
  onFloorsAndSuitesUpdate?: (data: FloorsAndSuitesFields) => void;
  direction?: 'row' | 'column';
};
export const FloorsAndSuitesSelector: React.FC<Props> = ({
  suites = [],
  floors = [],
  onFloorsAndSuitesUpdate = () => {},
  direction = 'row',
}) => {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [inputFields, setInputFields] = useState<FloorsAndSuitesInputFields>({
    suites,
    floors,
  });

  const {
    loading: isFetchingFloors,
    error: floorsError,
    data: floorsData,
  } = useQuery<ListPropertyFloorsQuery>(listPropertyFloors, {
    variables: {
      propertyId: channel?._id,
    },
  });

  const genericServerErrorMessage = t(
    'web.admin.channel.profile.info.floorsAndSuites.errors.generic'
  );
  const floorsDropdownNoOptionsMessage = floorsError
    ? genericServerErrorMessage
    : t('web.admin.channel.profile.info.floorsAndSuites.noFloorsAssociated');

  const floorOptions = useMemo(() => {
    const selectedSuiteIds = inputFields.suites.map(item => item.value);
    return (
      floorsData?.property?.floors
        ?.filter(item =>
          selectedSuiteIds.length
            ? item?.units?.some(unit => selectedSuiteIds.includes(unit?.id))
            : !!item?.units?.length
        )
        .map((floor: any) => ({
          label: floor.name,
          value: floor.id,
        })) || []
    );
  }, [floorsData, inputFields.suites]);

  const {
    loading: isFetchingSuites,
    error: suitesError,
    data: suitesData,
  } = useQuery<ListUnitsQuery>(listUnits, {
    variables: {
      propertyId: channel?._id,
    },
  });

  const suitesDropdownNoOptionsMessage = suitesError
    ? genericServerErrorMessage
    : t('web.admin.channel.profile.info.floorsAndSuites.noSuitesAssociated');

  const suiteOptions = useMemo(() => {
    const selectedFloorIds = inputFields.floors.map(item => item.value);
    return (
      suitesData?.property?.units
        ?.filter(
          item =>
            !selectedFloorIds.length ||
            item?.floors?.some(floor => selectedFloorIds.includes(floor?.id))
        )
        .map(unit => ({
          label: unit?.name,
          value: unit?.id,
        })) || []
    );
  }, [suitesData, inputFields.floors]);

  const handleOnChange = (
    key: keyof FloorsAndSuitesInputFields,
    value: FloorsAndSuitesInputFields[FloorsAndSuitesInputFieldKey]
  ) => {
    setInputFields({
      ...inputFields,
      [key]: value,
    });
    updateFloorAndSuiteIds(key, value);
  };

  const updateFloorAndSuiteIds = (
    key: keyof FloorsAndSuitesInputFields,
    value: FloorsAndSuitesInputFields[FloorsAndSuitesInputFieldKey]
  ) => {
    const selectedIds = value.map(item => item.value);
    const filteredData = filterData(selectedIds, key);
    const floorAndSuiteIds: FloorsAndSuitesFields = {
      floorIds: [],
      suiteIds: [],
    };

    if (key === 'floors') {
      floorAndSuiteIds.suiteIds = getIds(filteredData, inputFields.suites);
      floorAndSuiteIds.floorIds = value.map(item => convertToUUID(item.value));
    } else {
      floorAndSuiteIds.floorIds = getIds(filteredData, inputFields.floors);
      floorAndSuiteIds.suiteIds = value.map(item => convertToUUID(item.value));
    }
    onFloorsAndSuitesUpdate(floorAndSuiteIds);
  };

  const getIds = (
    filteredData: Item<string>[],
    items: Item<string>[]
  ): string[] => {
    return filteredData
      .filter(item => items.some(subItem => subItem.value === item.value))
      .map(item => convertToUUID(item.value));
  };

  function filterData(
    selectedIds: string[],
    type: FloorsAndSuitesInputFieldKey
  ): Item<string>[] {
    const data =
      type === 'floors'
        ? suitesData?.property?.units
        : floorsData?.property?.floors?.filter(
            (item: any) => !!item.units?.length
          );
    const key = type === 'floors' ? 'floors' : 'units';

    if (selectedIds.length === 0) {
      return (
        data?.map((item: any) => ({ label: item.name, value: item.id })) || []
      );
    }

    return (
      data
        ?.filter((item: any) => {
          return item[key].some((subItem: any) =>
            selectedIds.includes(subItem.id)
          );
        })
        .map((item: any) => ({ label: item.name, value: item.id })) || []
    );
  }
  return (
    <div>
      <H4 className="!mb-3">
        {t('web.admin.channel.profile.info.floorsAndSuites')}
      </H4>
      <Flex direction={direction} justify="space-between" gap={5}>
        <Flex direction="column" className="flex-1 w-2/5">
          <MultiselectField
            label={t(
              'web.admin.channel.profile.info.floorsAndSuites.floors.label'
            )}
            fixedLabel
            isFullWidth
            isSearchable
            testId="floorsDropdown"
            placeholder={t(
              'web.admin.channel.profile.info.floorsAndSuites.placeholder'
            )}
            noOptionsMessage={floorsDropdownNoOptionsMessage}
            doTranslation={false}
            value={inputFields.floors}
            onChange={value => handleOnChange('floors', value)}
            items={floorOptions}
            isLoading={isFetchingFloors}
          />
        </Flex>
        <Flex direction="column" className="flex-1 w-2/5">
          <MultiselectField
            label={t(
              'web.admin.channel.profile.info.floorsAndSuites.suites.label'
            )}
            fixedLabel
            isFullWidth
            isSearchable
            testId="suitesDropdown"
            placeholder={t(
              'web.admin.channel.profile.info.floorsAndSuites.placeholder'
            )}
            noOptionsMessage={suitesDropdownNoOptionsMessage}
            doTranslation={false}
            value={inputFields.suites}
            onChange={value => handleOnChange('suites', value)}
            items={suiteOptions}
            isLoading={isFetchingSuites}
          />
        </Flex>
      </Flex>
    </div>
  );
};
