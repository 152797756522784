import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  GroupType,
  GetFinalPriceForProductsResponse,
  ProductFinalPrice,
} from 'graphql-query-contracts';

import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { Payer } from '../types';
import { getFinalPriceForProducts } from '../queries';
import { isProbablyUUID } from '../../../helpers';

export function useFinalPriceQuery({
  productIds,
  payer,
  channel,
}: {
  productIds: Array<string>;
  payer: Payer;
  channel: ChannelType | null;
}): { finalPriceList: Array<ProductFinalPrice> } {
  const [
    getFinalPriceForProductsQuery,
    finalPriceForProductsResponse,
  ] = useLazyQuery<{
    getFinalPriceForProducts: GetFinalPriceForProductsResponse;
  }>(getFinalPriceForProducts);

  useEffect(() => {
    if (
      channel &&
      payer.id &&
      productIds.length > 0 &&
      isProbablyUUID(productIds[0])
    ) {
      getFinalPriceForProductsQuery({
        variables: {
          getFinalPriceForProductsRequest: {
            payer: {
              id: convertToUUID(payer.id),
              type: payer.type,
            },
            group: {
              id: convertToUUID(channel._id),
              type: GroupType.GroupTypeActivateChannel,
            },
            productIds,
          },
        },
      });
    }
  }, [channel?._id, payer.id, payer.type, JSON.stringify(productIds)]);

  return {
    finalPriceList:
      finalPriceForProductsResponse.data?.getFinalPriceForProducts
        .productFinalPrices ?? [],
  };
}
