import gql from 'graphql-tag';

export const verifyEmailOnBehalfOfUser = gql`
  mutation verifyEmailOnBehalfOfUser(
    $loginId: UUID!
    $channelId: UUID!
  ) {
    verifyEmailOnBehalfOfUser(
      loginId: $loginId
      channelId: $channelId
    )
  }
`;
