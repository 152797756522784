import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class LoginNotExistsError extends CodedError {
  inviteCode: string | undefined;

  channelHints: string[] | undefined;

  static get errorName() {
    return errorCodes.loginNotExistsError.code;
  }

  constructor(message = errorCodes.loginNotExistsError.message) {
    super(message);
    this.code = errorCodes.loginNotExistsError.code;
    this.message = `${message} ${errorCodes.loginNotExistsError.code}`;
    this.name = errorCodes.loginNotExistsError.name;
    this.status = errorCodes.loginNotExistsError.status;
  }

  setInviteCode(inviteCode: string) {
    this.inviteCode = inviteCode;
  }

  setChannelHints(channels: string[]) {
    this.channelHints = channels;
  }
}
