import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class NotFoundError extends CodedError {
  static get errorName() {
    return errorCodes.notFoundError.code;
  }

  static status = errorCodes.notFoundError.status;
  static message = errorCodes.notFoundError.message;

  constructor(message = errorCodes.notFoundError.message) {
    super(message);
    this.code = errorCodes.notFoundError.code;
    this.message = `${message} ${errorCodes.notFoundError.code}`;
    this.name = errorCodes.notFoundError.name;
    this.status = errorCodes.notFoundError.status;
  }
}
