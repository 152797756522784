import gql from 'graphql-tag';

export default gql`
  query getConsolidatedChannelUsers(
    $channelId: UUID!
    $userIds: [UUID]
    $accessControlGroupId: String
  ) {
    getChannelUsers(
      channelId: $channelId
      userIds: $userIds
      accessControlGroupId: $accessControlGroupId
    ) {
      _id
      name
      email
      syncStatus
      companies {
        id
        name
      }
      source
      mobileAccess
      accessControlGroupIds
      multiIntegrationAcgs {
        channelId
        channelName
        accessControlGroups {
          id
          name
        }
      }
      logo
      image
      role
      roles
      credentials
      integrationChannelId
    }
  }
`;
