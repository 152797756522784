import gql from 'graphql-tag';

export const getTags = gql`
  query getTags($channelId: UUID!) {
    tags(channelId: $channelId) {
      id
      name
      name_l10n
      channelId
      isDefault
      created
      contents {
        _id
      }
      sections {
        _id
      }
    }
  }
`;
