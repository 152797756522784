import gql from 'graphql-tag';

const searchServiceRequestsQuery = gql`
  query searchServiceRequestsQuery(
    $channelId: String!
    $search: SearchServiceRequest
    $pagination: PaginationInput
  ) {
    searchServiceRequests(
      search: $search
      pagination: $pagination
      channelId: $channelId
    ) {
      pageInfo {
        start
        total
        perPage
      }
      serviceRequests {
        serviceRequestId
        createdBy {
          name
          id
        }
        createdAt
        updatedAt
        updatedBy {
          name
          id
        }
        description
        category
        issue
        location
        floor
        suite
        company {
          name
          id
        }
        status
        extRefId
        suite
        assignee {
          name
          id
        }
        customQuestions {
          Question
          Answer
        }
        isBillable
        friendlyID
        userFriendlyID
        nextStatuses
      }
    }
  }
`;

export default searchServiceRequestsQuery;
