// these names need to match the instance field of WhiteLabels table in the
// database.  We use this unique instance keys for white labels in many places.
import {
  WhiteLabelType,
  WhiteLabelConfigType,
  WhiteLabelTeamType,
} from '../../types/WhiteLabelType';
import wlCache from './cache.json';
import whiteLabelsJSON from './whitelabels.json';

const WHITELABELS = [
  'colliersconnect',
  'dream',
  'exchange365',
  'factory',
  'lane',
  'mimec',
  'mybrookfield',
  'neighborhoods',
  'hullmark',
  'canderelplus',
  'studio',
  'oxford',
  'triovest',
  'shopify',
  'alfasi',
  'longfellow',
  'kingsett',
  'hines',
  'hines2',
  'innovationplace',
  'grupo',
  'lsh1',
  'cbre',
  'pembroke',
  'nuveen',
  'lwl1', // internal testing whitelabel for staging wl1.s.app.joinlane.com
  'lwl2', // internal testing whitelabel for staging wl2.s.app.joinlane.com
  '550wjackson',
  'pallas',
  'vicland',
  'rfrholdings',
  '118mount',
  'paramountgroup',
  'hostcore',
  'kilroy',
  'accelerate',
  'charterhall',
  'civis',
  '108sgt',
  'cooperscross',
  'devonshire',
  'harvard',
  'myslc',
  'polycentre20',
  'fabricarino',
  'glebe',
  '1105westpeachtree',
  'bcnfira',
  'hiitbcn',
  'tecom',
  'belgravehouse',
  'columbuscircle3',
  'denison201',
  'cristalia',
  '1201newyork',
  '1801penn',
  'theheights',
  'carltonhouse',
  'spectrum',
  'onecitypark',
  'districtwharfoffices',
  '425parkavenue',
  'bridgewatercrossing',
  'youreden',
  'nmichigan333',
  'galleriaatl',
  'whitefriars',
  'vermont1000',
  'parkave200',
  'hudsonresearchcenter',
  'onebeacon',
  '200wmadison',
  'northwacker110',
  'centuryparkapp',
  'tenantx',
  '40leadenhall',
  '41lothbury',
  'kudosworkplace',
  'thezoapp',
  '3dworkplace',
  'hosty',
  'waverleygate',
  'fifthnyc717',
  'metronational',
  'epic2024',
  'greenwich101',
  'gate8',
  'spectrumcenter',
  'wilfred',
  'nuveenuk',
  'carnegiecenter',
  'thehub',
  'apexlegacy',
  'commonground',
  'onetwopru',
  'connectbycrown',
  'cqcconnect',
  'victoriaparade200',
  'bethesda2',
  'wisconsin5425',
  'wisconsin2',
  'esrt',
  'qbyquintessential',
  'cbreim',
  'cannonstreet',
  'finsburysquare',
  'ekistics',
  'westendlabs',
  'perimeter',
  'dundaskiplingcentre',
  'northamericancentre',
  'manulifecorporatepark',
  'universitycentre',
  'universityavenue100',
  'mon1250',
  'arepx',
  'nsioffices',
  'sagerealty',
  'universityave100',
  'colabds',
] as const;

const [
  WHITELABEL_COLLIERSCONNECT,
  WHITELABEL_DREAM,
  WHITELABEL_EXCHANGE365,
  WHITELABEL_FACTORY,
  WHITELABEL_LANE,
  WHITELABEL_MIMEC,
  WHITELABEL_MYBROOKFIELD,
  WHITELABEL_NEIGHBORHOOS,
  WHITELABEL_HULLMARK,
  WHITELABEL_CANDEREL_PLUS,
  WHITELABEL_STUDIO,
  WHITELABEL_OXFORD,
  WHITELABEL_TRIOVEST,
  WHITELABEL_SHOPIFY,
  WHITELABEL_ALFASI,
  WHITELABEL_LONGFELLOW,
  WHITELABEL_KINGSETT,
  WHITELABEL_HINES,
  WHITELABEL_HINES2,
  WHITELABEL_INNOVATION_PLACE,
  WHITELABEL_GRUPO,
  WHITELABEL_LSH,
  WHITELABEL_CBRE,
  WHITELABEL_PEMBROKE,
  WHITELABEL_NUVEEN,
  WHITELABEL_WL1,
  WHITELABEL_WL2,
  WHITELABEL_550WJACKSON,
  WHITELABEL_PALLAS,
  WHITELABEL_VICLAND,
  WHITELABEL_RFR,
  WHITELABEL_118MOUNT,
  WHITELABEL_PARAMOUNTGROUP,
  WHITELABEL_HOSTCORE,
  WHITELABEL_KILROY,
  WHITELABEL_ACCELERATE,
  WHITELABEL_CHARTERHALL,
  WHITELABEL_CIVIS,
  WHITELABEL_108SGT,
  WHITELABEL_COOPERSCROSS,
  WHITELABEL_DEVONSHIRE,
  WHITELABEL_HARVARD,
  WHITELABEL_MYSLC,
  WHITELABEL_POLYCENTRE20,
  WHITELABEL_FABRICARINO,
  WHITELABEL_GLEBE,
  WHITELABEL_1105WESTPEACHTREE,
  WHITELABEL_BCNFIRA,
  WHITELABEL_HIITBCN,
  WHITELABEL_TECOM,
  WHITELABEL_BELGRAVEHOUSE,
  WHITELABEL_COLUMBUSCIRCLE3,
  WHITELABEL_DENISON201,
  WHITELABEL_CRISTALIA,
  WHITELABEL_1201NEWYORK,
  WHITELABEL_1801PENN,
  WHITELABEL_THEHEIGHTS,
  WHITELABEL_CARLTONHOUSE,
  WHITELABEL_SPECTRUM,
  WHITELABEL_ONECITYPARK,
  WHITELABEL_DISTRICTWHARFOFFICES,
  WHITELABEL_425PARKAVENUE,
  WHITELABEL_BRIDGEWATERCROSSING,
  WHITELABEL_YOUREDEN,
  WHITELABEL_NMICHIGAN333,
  WHITELABEL_GALLERIAATL,
  WHITELABEL_WHITEFRIARS,
  WHITELABEL_VERMONT1000,
  WHITELABEL_PARKAVE200,
  WHITELABEL_HUDSONRESEARCHCENTER,
  WHITELABEL_ONEBEACON,
  WHITELABEL_200WMADISON,
  WHITELABEL_NORTHWACKER110,
  WHITELABEL_CENTURYPARKAPP,
  WHITELABEL_TENANTX,
  WHITELABEL_40LEADENHALL,
  WHITELABEL_41LOTHBURY,
  WHITELABEL_KUDOSWORKPLACE,
  WHITELABEL_THEZOAPP,
  WHITELABEL_3DWORKPLACE,
  WHITELABEL_HOSTY,
  WHITELABEL_WAVERLEYGATE,
  WHITELABEL_FIFTHNYC717,
  WHITELABEL_METRONATIONAL,
  WHITELABEL_EPIC2024,
  WHITELABEL_GREENWICH101,
  WHITELABEL_GATE8,
  WHITELABEL_SPECTRUMCENTER,
  WHITELABEL_WILFRED,
  WHITELABEL_NUVEENUK,
  WHITELABEL_CARNEGIECENTER,
  WHITELABEL_THEHUB,
  WHITELABEL_APEXLEGACY,
  WHITELABEL_COMMONGROUND,
  WHITELABEL_ONETWOPRU,
  WHITELABEL_CONNECTBYCROWN,
  WHITELABEL_CQCCONNECT,
  WHITELABEL_VICTORIAPARADE200,
  WHITELABEL_BETHESDA2,
  WHITELABEL_WISCONSIN5425,
  WHITELABEL_WISCONSIN2,
  WHITELABEL_ESRT,
  WHITELABEL_QBYQUINTESSENTIAL,
  WHITELABEL_CBREIM,
  WHITELABEL_CANNONSTREET,
  WHITELABEL_FINSBURYSQUARE,
  WHITELABEL_EKISTICS,
  WHITELABEL_WESTENDLABS,
  WHITELABEL_PERIMETER,
  WHITELABEL_DUNDASKIPLINGCENTRE,
  WHITELABEL_NORTHAMERICANCENTRE,
  WHITELABEL_MANULIFECORPORATEPARK,
  WHITELABEL_UNIVERSITYCENTRE,
  WHITELABEL_UNIVERSITYAVENUE100,
  WHITELABEL_MON1250,
  WHITELABEL_AREPX,
  WHITELABEL_NSIOFFICES,
  WHITELABEL_SAGEREALTY,
  WHITELABEL_UNIVERSITYAVE100,
  WHITELABEL_COLABDS,
] = WHITELABELS;

function getWhitelabelByBundleId(bundleId: string) {
  switch (bundleId) {
    case 'com.lane.factory':
      return WHITELABEL_FACTORY;
    case 'com.lane.dream':
      return WHITELABEL_DREAM;
    case 'com.lane.mybrookfield':
      return WHITELABEL_MYBROOKFIELD;
    case 'com.lane.neighborhoods':
      return WHITELABEL_NEIGHBORHOOS;
    case 'com.lane.hullmark':
      return WHITELABEL_HULLMARK;
    case 'com.lane.colliersconnect':
      return WHITELABEL_COLLIERSCONNECT;
    case 'com.lane.mimec':
      return WHITELABEL_MIMEC;
    case 'com.lane.exchange365':
      return WHITELABEL_EXCHANGE365;
    case 'com.lane.canderelplus':
      return WHITELABEL_CANDEREL_PLUS;
    case 'com.lane.oxford':
      return WHITELABEL_OXFORD;
    case 'com.lane.triovest':
      return WHITELABEL_TRIOVEST;
    case 'com.lane.shopify':
      return WHITELABEL_SHOPIFY;
    case 'com.lane.longfellow':
      return WHITELABEL_LONGFELLOW;
    case 'com.lane.alfasi':
      return WHITELABEL_ALFASI;
    case 'com.lane.kingsett':
      return WHITELABEL_KINGSETT;
    case 'com.lane.hines':
      return WHITELABEL_HINES;
    case 'com.lane.hines2':
      return WHITELABEL_HINES2;
    case 'com.lane.innovationplace':
      return WHITELABEL_INNOVATION_PLACE;
    case 'com.lane.grupo':
      return WHITELABEL_GRUPO;
    case 'com.lane.lsh':
      return WHITELABEL_LSH;
    case 'com.lane.cbre':
      return WHITELABEL_CBRE;
    case 'com.lane.pembroke':
      return WHITELABEL_PEMBROKE;
    case 'com.lane.nuveen':
      return WHITELABEL_NUVEEN;
    case 'com.lane.wl1':
      return WHITELABEL_WL1;
    case 'com.lane.wl2':
      return WHITELABEL_WL2;
    case 'com.vts.activate.sampleapp':
      return WHITELABEL_550WJACKSON;
    case 'com.vts.activate.sampleapp5':
      return WHITELABEL_THEZOAPP;
    case 'com.lane.pallas':
      return WHITELABEL_PALLAS;
    case 'com.lane.118mount':
    case 'com.lane.mount':
      return WHITELABEL_118MOUNT;
    case 'com.lane.vicland':
      return WHITELABEL_VICLAND;
    case 'com.risesoftware.rfr':
      return WHITELABEL_RFR;
    case 'com.risesoftware.paramountgroup':
      return WHITELABEL_PARAMOUNTGROUP;
    case 'com.vts.activate.cbre.hostcore':
      return WHITELABEL_HOSTCORE;
    case 'com.risesoftware.kilroy':
      return WHITELABEL_KILROY;
    case 'com.vts.activate.accelerate':
      return WHITELABEL_ACCELERATE;
    case 'com.vts.activate.charterhall':
      return WHITELABEL_CHARTERHALL;
    case 'com.vts.activate.sgt108':
      return WHITELABEL_108SGT;
    case 'com.risesoftware.coopercross':
      return WHITELABEL_COOPERSCROSS;
    case 'com.vts.activate.devonshire':
      return WHITELABEL_DEVONSHIRE;
    case 'com.vts.activate.harvard':
      return WHITELABEL_HARVARD;
    case 'com.vts.activate.myslc':
      return WHITELABEL_MYSLC;
    case 'com.vts.activate.polycentre20':
      return WHITELABEL_POLYCENTRE20;
    case 'com.vts.activate.fabricarino':
      return WHITELABEL_FABRICARINO;
    case 'com.vts.activate.glebehillvillage':
      return WHITELABEL_GLEBE;
    case 'com.peachtree.west':
      return WHITELABEL_1105WESTPEACHTREE;
    case 'com.aberdeenstandard.bcnfira':
      return WHITELABEL_BCNFIRA;
    case 'com.vts.activate.hiitbcn':
      return WHITELABEL_HIITBCN;
    case 'com.vts.activate.tecom':
      return WHITELABEL_TECOM;
    case 'com.vts.activate.belgravehouse':
      return WHITELABEL_BELGRAVEHOUSE;
    case 'com.vts.activate.columbuscircle3':
      return WHITELABEL_COLUMBUSCIRCLE3;
    case 'com.vts.activate.denison201':
      return WHITELABEL_DENISON201;
    case 'com.vts.activate.cristalia':
      return WHITELABEL_CRISTALIA;
    case 'com.risesoftware.newyork1201':
      return WHITELABEL_1201NEWYORK;
    case 'com.risesoftware.penn1801':
      return WHITELABEL_1801PENN;
    case 'com.vts.activate.theheights':
      return WHITELABEL_THEHEIGHTS;
    case 'com.vts.activate.cht20':
      return WHITELABEL_CARLTONHOUSE;
    case 'com.vts.activate.aliados':
      return WHITELABEL_SPECTRUM;
    case 'com.vts.activate.bradford':
      return WHITELABEL_ONECITYPARK;
    case 'com.vts.activate.wharfdc':
      return WHITELABEL_DISTRICTWHARFOFFICES;
    case 'com.vts.activate.park425':
    case 'com.vts.activate.park425a':
      return WHITELABEL_425PARKAVENUE;
    case 'com.vts.activate.bridgewatercrossing':
      return WHITELABEL_BRIDGEWATERCROSSING;
    case 'com.risesoftware.eden':
      return WHITELABEL_YOUREDEN;
    case 'com.risesoftware.michigan333':
      return WHITELABEL_NMICHIGAN333;
    case 'com.risesoftware.galleriaatl':
      return WHITELABEL_GALLERIAATL;
    case 'com.risesoftware.whitefriarsbristol':
      return WHITELABEL_WHITEFRIARS;
    case 'com.risesoftware.vermont1000':
      return WHITELABEL_VERMONT1000;
    case 'com.vts.activate.parkave200':
    case 'com.vts.activate.parkave200a':
      return WHITELABEL_PARKAVE200;
    case 'hudson.research.center':
    case 'com.tacon.hrcnyc':
      return WHITELABEL_HUDSONRESEARCHCENTER;
    case 'com.vts.activate.onebeacon':
      return WHITELABEL_ONEBEACON;
    case 'com.bentallgreenoak.madison':
      return WHITELABEL_200WMADISON;
    case 'com.vts.activate.northwacker110':
      return WHITELABEL_NORTHWACKER110;
    case 'com.vts.activate.centuryparkapp':
      return WHITELABEL_CENTURYPARKAPP;
    case 'com.vts.activate.tenantx':
      return WHITELABEL_TENANTX;
    case 'com.vts.activate.leadenhall':
      return WHITELABEL_40LEADENHALL;
    case 'com.vts.activate.lothbury':
      return WHITELABEL_41LOTHBURY;
    case 'com.vts.activate.kudosworkplace':
    case 'com.kudosworkplace.kudos':
    case 'com.workplace.kudos':
      return WHITELABEL_KUDOSWORKPLACE;
    case 'com.findyourzo.zomobile':
    case 'com.RCPILandmarkProperties.findyourzo':
      return WHITELABEL_THEZOAPP;
    case 'com.development.workplace':
      return WHITELABEL_3DWORKPLACE;
    case 'com.cbre.hosty':
      return WHITELABEL_HOSTY;
    case 'com.vts.activate.waverlaygate':
    case 'com.vts.activate.waverley':
      return WHITELABEL_WAVERLEYGATE;
    case 'com.vts.activate.fifthnyc717':
      return WHITELABEL_FIFTHNYC717;
    case 'com.risesoftware.mcitywork':
      return WHITELABEL_METRONATIONAL;
    case 'com.vts.activate.epic2024':
      return WHITELABEL_EPIC2024;
    case 'com.risesoftware.greenwich101':
      return WHITELABEL_GREENWICH101;
    case 'com.vts.activate.gate8':
      return WHITELABEL_GATE8;
    case 'com.vts.activate.spectrum':
      return WHITELABEL_SPECTRUMCENTER;
    case 'com.vts.activate.wilfred':
      return WHITELABEL_WILFRED;
    case 'com.vts.activate.nuveenuk':
      return WHITELABEL_NUVEENUK;
    case 'com.risesoftware.carnegiecenter':
      return WHITELABEL_CARNEGIECENTER;
    case 'com.risesoftware.thehub':
      return WHITELABEL_THEHUB;
    case 'com.apexlegacy':
    case 'com.apex1.app':
      return WHITELABEL_APEXLEGACY;
    case 'com.vts.activate.commonground':
      return WHITELABEL_COMMONGROUND;
    case 'com.risesoftware.onetwopru':
      return WHITELABEL_ONETWOPRU;
    case 'com.vts.activate.sampleapp6':
    case 'com.risesoftware.connectbycrown':
      return WHITELABEL_CONNECTBYCROWN;
    case 'com.vts.activate.cqcconnect':
    case 'com.vts.activate.cqcconnect2':
      return WHITELABEL_CQCCONNECT;
    case 'com.vts.activate.victoriaparade200':
      return WHITELABEL_VICTORIAPARADE200;
    case 'com.vts.activate.bethesda2':
      return WHITELABEL_BETHESDA2;
    case 'com.vts.activate.wisconsin5425':
      return WHITELABEL_WISCONSIN5425;
    case 'com.vts.activate.wisconsin2':
      return WHITELABEL_WISCONSIN2;
    case 'com.vts.activate.qbyquintessential':
      return WHITELABEL_QBYQUINTESSENTIAL;
    case 'com.vts.activate.cbreim':
      return WHITELABEL_CBREIM;
    case 'com.risesoftware.cannonstreet':
      return WHITELABEL_CANNONSTREET;
    case 'com.risesoftware.finsburysquare':
      return WHITELABEL_FINSBURYSQUARE;
    case 'com.risesoftware.saintjames':
      return WHITELABEL_EKISTICS;
    case 'com.vts.activate.westendlabs':
      return WHITELABEL_WESTENDLABS;
    case 'com.vts.activate.perimeter':
      return WHITELABEL_PERIMETER;
    case 'com.vts.activate.sampleapp13':
      return WHITELABEL_DUNDASKIPLINGCENTRE;
    case 'com.vts.activate.sampleapp12':
      return WHITELABEL_NORTHAMERICANCENTRE;
    case 'com.vts.activate.sampleapp11':
      return WHITELABEL_MANULIFECORPORATEPARK;
    case 'com.vts.activate.sampleapp10':
      return WHITELABEL_UNIVERSITYCENTRE;
    case 'com.vts.activate.sampleapp9':
      return WHITELABEL_UNIVERSITYAVENUE100;
    case 'com.vts.activate.mon1250':
      return WHITELABEL_MON1250;
    case 'com.vts.activate.sampleapp7':
      return WHITELABEL_AREPX;
    case 'com.risesoftware.arep':
      return WHITELABEL_AREPX;
    case 'com.risesoftware.sage':
      return WHITELABEL_SAGEREALTY;
    case 'com.vts.activate.nsi':
      return WHITELABEL_NSIOFFICES;
    case 'com.vts.activate.colabds':
    case 'com.vts.activate.colabds2':
      return WHITELABEL_COLABDS;
    case 'com.lane.studio':
    case 'com.yourstudio.app':
    case 'com.yourstudio.yourstudio':
      return WHITELABEL_STUDIO;
    case 'com.lane.civis':
      return WHITELABEL_CIVIS;
    case 'com.lane.lane':
    case 'com.lane.staging':
    case 'com.lane.develop':
    case 'com.lane.uat':
    case 'com.lane.laneuat':
    case 'com.vts.activate.sampleapp3':
    default:
      return WHITELABEL_LANE;
  }
}

const cache: WhiteLabelType[] = (wlCache as unknown) as WhiteLabelType[];
const whiteLabelsConfig: WhiteLabelConfigType[] = (whiteLabelsJSON.whitelabels as unknown) as WhiteLabelConfigType[];
const whiteLabelsTeam: WhiteLabelTeamType[] = (whiteLabelsJSON.ios_stores as unknown) as WhiteLabelTeamType[];

export {
  cache,
  whiteLabelsConfig,
  whiteLabelsTeam,
  WHITELABELS,
  getWhitelabelByBundleId,
  WHITELABEL_CANDEREL_PLUS,
  WHITELABEL_COLLIERSCONNECT,
  WHITELABEL_DREAM,
  WHITELABEL_EXCHANGE365,
  WHITELABEL_FACTORY,
  WHITELABEL_LANE,
  WHITELABEL_MIMEC,
  WHITELABEL_MYBROOKFIELD,
  WHITELABEL_NEIGHBORHOOS,
  WHITELABEL_HULLMARK,
  WHITELABEL_STUDIO,
  WHITELABEL_OXFORD,
  WHITELABEL_TRIOVEST,
  WHITELABEL_SHOPIFY,
  WHITELABEL_ALFASI,
  WHITELABEL_LONGFELLOW,
  WHITELABEL_KINGSETT,
  WHITELABEL_HINES,
  WHITELABEL_HINES2,
  WHITELABEL_INNOVATION_PLACE,
  WHITELABEL_GRUPO,
  WHITELABEL_LSH,
  WHITELABEL_CBRE,
  WHITELABEL_PEMBROKE,
  WHITELABEL_NUVEEN,
  WHITELABEL_WL1,
  WHITELABEL_WL2,
  WHITELABEL_550WJACKSON,
  WHITELABEL_PALLAS,
  WHITELABEL_VICLAND,
  WHITELABEL_RFR,
  WHITELABEL_118MOUNT,
  WHITELABEL_PARAMOUNTGROUP,
  WHITELABEL_HOSTCORE,
  WHITELABEL_KILROY,
  WHITELABEL_ACCELERATE,
  WHITELABEL_CHARTERHALL,
  WHITELABEL_CIVIS,
  WHITELABEL_108SGT,
  WHITELABEL_COOPERSCROSS,
  WHITELABEL_DEVONSHIRE,
  WHITELABEL_HARVARD,
  WHITELABEL_MYSLC,
  WHITELABEL_POLYCENTRE20,
  WHITELABEL_FABRICARINO,
  WHITELABEL_GLEBE,
  WHITELABEL_1105WESTPEACHTREE,
  WHITELABEL_BCNFIRA,
  WHITELABEL_HIITBCN,
  WHITELABEL_TECOM,
  WHITELABEL_BELGRAVEHOUSE,
  WHITELABEL_COLUMBUSCIRCLE3,
  WHITELABEL_DENISON201,
  WHITELABEL_CRISTALIA,
  WHITELABEL_1201NEWYORK,
  WHITELABEL_1801PENN,
  WHITELABEL_THEHEIGHTS,
  WHITELABEL_CARLTONHOUSE,
  WHITELABEL_SPECTRUM,
  WHITELABEL_ONECITYPARK,
  WHITELABEL_DISTRICTWHARFOFFICES,
  WHITELABEL_425PARKAVENUE,
  WHITELABEL_BRIDGEWATERCROSSING,
  WHITELABEL_YOUREDEN,
  WHITELABEL_NMICHIGAN333,
  WHITELABEL_GALLERIAATL,
  WHITELABEL_WHITEFRIARS,
  WHITELABEL_VERMONT1000,
  WHITELABEL_PARKAVE200,
  WHITELABEL_HUDSONRESEARCHCENTER,
  WHITELABEL_ONEBEACON,
  WHITELABEL_200WMADISON,
  WHITELABEL_NORTHWACKER110,
  WHITELABEL_CENTURYPARKAPP,
  WHITELABEL_TENANTX,
  WHITELABEL_40LEADENHALL,
  WHITELABEL_41LOTHBURY,
  WHITELABEL_KUDOSWORKPLACE,
  WHITELABEL_THEZOAPP,
  WHITELABEL_3DWORKPLACE,
  WHITELABEL_HOSTY,
  WHITELABEL_WAVERLEYGATE,
  WHITELABEL_FIFTHNYC717,
  WHITELABEL_METRONATIONAL,
  WHITELABEL_EPIC2024,
  WHITELABEL_GREENWICH101,
  WHITELABEL_GATE8,
  WHITELABEL_SPECTRUMCENTER,
  WHITELABEL_WILFRED,
  WHITELABEL_NUVEENUK,
  WHITELABEL_CARNEGIECENTER,
  WHITELABEL_THEHUB,
  WHITELABEL_APEXLEGACY,
  WHITELABEL_COMMONGROUND,
  WHITELABEL_ONETWOPRU,
  WHITELABEL_CONNECTBYCROWN,
  WHITELABEL_CQCCONNECT,
  WHITELABEL_VICTORIAPARADE200,
  WHITELABEL_BETHESDA2,
  WHITELABEL_WISCONSIN5425,
  WHITELABEL_WISCONSIN2,
  WHITELABEL_ESRT,
  WHITELABEL_QBYQUINTESSENTIAL,
  WHITELABEL_CBREIM,
  WHITELABEL_CANNONSTREET,
  WHITELABEL_FINSBURYSQUARE,
  WHITELABEL_EKISTICS,
  WHITELABEL_WESTENDLABS,
  WHITELABEL_PERIMETER,
  WHITELABEL_DUNDASKIPLINGCENTRE,
  WHITELABEL_NORTHAMERICANCENTRE,
  WHITELABEL_MANULIFECORPORATEPARK,
  WHITELABEL_UNIVERSITYCENTRE,
  WHITELABEL_UNIVERSITYAVENUE100,
  WHITELABEL_MON1250,
  WHITELABEL_AREPX,
  WHITELABEL_NSIOFFICES,
  WHITELABEL_SAGEREALTY,
  WHITELABEL_UNIVERSITYAVE100,
  WHITELABEL_COLABDS,
};
