import { getClient } from '../../apollo';
import { createUserContentInteraction as mutation } from '../../graphql/mutation';
import validateInteraction from '../../renderers/v5/validateInteraction';
import { UserContentInteractionType } from '../../types/UserContentInteraction';
import { ContentType } from '../../types/content/Content';
import {
  SecurityRuleTypeEnum,
  SecurityModesEnum,
} from '../../types/properties/PropertySecurity';

type Props = {
  refetchQueries?: any;
  content: ContentType;
  interaction: any;
  meChannelId?: string | null;
  submittingAsWorkplaceMember?: boolean;
};

export default async function createUserContentInteraction({
  refetchQueries,
  content,
  interaction,
  meChannelId,
  submittingAsWorkplaceMember,
}: Props): Promise<UserContentInteractionType> {
  await validateInteraction({
    content,
    interaction,
    security: {
      mode: SecurityModesEnum.Create,
      type: SecurityRuleTypeEnum.Creator,
    },
  });

  const { data } = await getClient().mutate({
    refetchQueries,
    mutation,
    variables: {
      contentId: content._id,
      interaction,
      meChannelId,
      submittingAsWorkplaceMember,
    },
  });

  return data.createContentInteraction;
}
