import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { getContentWaitlistEnrollments as getContentWaitlistEnrollmentsQuery } from 'graphql-queries';
import { useUserDataContext } from 'lane-shared/hooks';

export const useWaitlistEnrollmentsByContent = (contentId?: string) => {
  const { user } = useUserDataContext();
  const [enrollmentId, setEnrollmentId] = useState('');

  const { refetch, loading } = useQuery(getContentWaitlistEnrollmentsQuery, {
    variables: {
      userId: user?._id,
      contentId,
    },
    onCompleted: data => {
      const enrollments = data?.contentWaitlistEnrollments;

      if (enrollments && enrollments.length > 0 && 'id' in enrollments[0]) {
        setEnrollmentId(enrollments[0].id);
      } else {
        setEnrollmentId('');
      }
    },
  });

  return {
    enrollmentId,
    getWaitlistEnrollmentForUser: refetch,
    isLoading: loading,
  };
};
