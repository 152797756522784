import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  RemovePaymentMethodResponse,
  RemovePaymentMethodRequest,
} from 'lane-shared/domains/billingPayments/types';

const removeCardMutation = gql`
  mutation removePaymentMethod(
    $removePaymentMethodRequest: RemovePaymentMethodRequest!
  ) {
    accounts {
      removePaymentMethod(
        removePaymentMethodRequest: $removePaymentMethodRequest
      ) {
        accountsUserId
      }
    }
  }
`;

export async function removePaymentMethod(
  accountsUserId: string,
  paymentProcessorSourceId: string
): Promise<RemovePaymentMethodResponse> {
  const { data } = await getClient().mutate({
    mutation: removeCardMutation,
    variables: {
      removePaymentMethodRequest: {
        accountsUserId,
        paymentProcessorSourceId,
      } as RemovePaymentMethodRequest,
    },
  });

  return data.accounts.removePaymentMethod;
}
