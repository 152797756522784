import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import findClosestLocale from 'lane-shared/helpers/findClosestLocale';
import { SupportedLocaleEnum, friendlyLocaleItems } from 'localization';

import Box from 'components/lds/Box';

import Dropdown from '../form/Dropdown';

import styles from './LanguagePicker.scss';

type LanguagePickerProps = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  value: SupportedLocaleEnum;
  onChange?: (locale: SupportedLocaleEnum) => void;
  testId?: string;
  isIconEnabled?: boolean;
  isFullWidth?: boolean;
};

export default function LanguagePicker({
  onChange = () => null,
  value,
  disabled,
  className,
  style,
  isIconEnabled = true,
  isFullWidth = false,
}: LanguagePickerProps) {
  return (
    <Box
      data-test="languagePicker"
      className={cx(styles.LanguagePicker, className)}
      style={style}
    >
      {isIconEnabled && <Icon className={styles.icon} name="globe" />}
      <Dropdown
        disabled={disabled}
        className={cx({
          [styles.dropdown]: true,
          [styles.fullWidth]: isFullWidth,
        })}
        onValueChange={onChange}
        value={findClosestLocale(value)}
        items={friendlyLocaleItems}
        doTranslation={false}
      />
    </Box>
  );
}
