import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class AudienceMissingFieldError extends CodedError {
  static get errorName() {
    return errorCodes.audienceMissingFieldError.code;
  }

  constructor(message = errorCodes.audienceMissingFieldError.message) {
    super(message);
    this.code = errorCodes.audienceMissingFieldError.code;
    this.message = `${message} ${errorCodes.audienceMissingFieldError.code}`;
    this.name = errorCodes.audienceMissingFieldError.name;
    this.status = errorCodes.audienceMissingFieldError.status;
  }
}
