import React from 'react';

import { MjmlSection, MjmlColumn, MjmlImage } from '../../../MjmlComponentsV2';
import { bucketUrl } from './bucketUrl';

const STUDIO_HEADER_IMAGE_URL = `${bucketUrl}/Studio_EmailTemplate_Header.jpg`;

export default function EmailFooter() {
  return (
    <MjmlSection paddingBottom={0}>
      <MjmlColumn width={500}>
        <MjmlImage width={500} src={STUDIO_HEADER_IMAGE_URL} />
      </MjmlColumn>
    </MjmlSection>
  );
}
