import React from 'react';

import { Tooltip, TextButton } from '../../index';
import type { RowAction as RowActionType } from './hooks/useTable';

type RowActionProps<TDataShape> = {
  action: RowActionType<TDataShape>;
  row: TDataShape;
};

export function executeOrReturn<TDataShape>(
  arg: ((row: TDataShape) => void) | string | undefined | boolean,
  row: TDataShape
) {
  return arg instanceof Function ? arg(row) : arg;
}

export const RowAction = <TDataShape,>({
  action,
  row,
}: RowActionProps<TDataShape>) => {
  const {
    label,
    onClick,
    isHidden: isHiddenRaw,
    isDisabled: isDisabledRaw,
    tooltipText: tooltipTextRaw,
    icon,
  } = action || {};

  const tooltipText = executeOrReturn(tooltipTextRaw, row);
  const singleIsHidden = executeOrReturn(isHiddenRaw, row);
  const isDisabled = executeOrReturn(isDisabledRaw, row) as boolean;

  const actionButton = (
    <TextButton
      onClick={() => onClick(row)}
      padding="none"
      disabled={isDisabled}
      iconName={icon}
    >
      {label}
    </TextButton>
  );

  const buttonWithTooltip = (
    <Tooltip placement="top" TooltipComponent={tooltipText!}>
      <TextButton
        onClick={() => onClick(row)}
        padding="none"
        iconName={icon || 'info-circle'}
        disabled={isDisabled}
      >
        {label}
      </TextButton>
    </Tooltip>
  );

  return (
    <div>
      {!singleIsHidden && (tooltipText ? buttonWithTooltip : actionButton)}
    </div>
  );
};
