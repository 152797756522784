import React from 'react';

import { colors } from '../../config';
import { MjmlButton } from '../MjmlComponentsV2';
import { IMjmlButtonProps } from '@faire/mjml-react';

type EmailButtonProps = {
  align?: 'center' | 'left' | 'right' | undefined;
  width?: number;
  height?: number;
  href?: string;
  color?: string;
  innerPadding?: string;
  borderRadius?: number;
  children: React.ReactNode;
} & IMjmlButtonProps;

export default function EmailButton({
  color = colors.primary,
  children,
  height = 48,
  innerPadding = '20px',
  align = 'center',
  borderRadius = 30,
  ...props
}: EmailButtonProps) {
  return (
    <MjmlButton
      fontFamily="'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif"
      fontSize={16}
      innerPadding={innerPadding}
      paddingLeft={2}
      paddingRight={2}
      lineHeight={20}
      borderRadius={borderRadius}
      backgroundColor={color}
      color={colors.white}
      fontWeight="bold"
      align={align}
      height={height}
      {...props}
    >
      {children}
    </MjmlButton>
  );
}
