import React, { useState, useEffect } from 'react';
import { Filter } from './Filter';
import { Button } from '../Button/Button';
import { Icon } from '../Icon';
import { SidePanel } from '../SidePanel/SidePanel';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../Checkbox/Checkbox';
import { ColumnFiltersState, FilterFn } from '@tanstack/react-table';
import styles from './Filters.scss';
import { usePersistedSetting } from '../../hooks/usePersistedSetting';
import { SearchFilter } from './SearchFilter';
import { Option } from './MultiselectFilter';

export enum NativeFilterTypes {
  Date = 'date',
  DateRange = 'dateRange',
  Select = 'select',
  Multiselect = 'multiselect',
  ArrIncludesSome = 'arrIncludesSome',
}

interface BaseFilter {
  key: string;
  label: string;
  isPromoted?: boolean;
  isFixed?: boolean;
  excludeFromClear?: boolean;
}

export interface MultiselectFilterType extends BaseFilter {
  type: NativeFilterTypes.Multiselect;
  options: Option[];
  doTranslation?: boolean;
}

export interface SelectFilterType extends BaseFilter {
  type: NativeFilterTypes.Select;
  doTranslation?: boolean;
  placeholder?: string;
  options: Option[];
  loadOptions?: (inputValue: string) => Promise<Option[]>;
  loadDefaultOptions?: (value: string) => Promise<Option[]>;
}

export interface DateRangeFilterType extends BaseFilter {
  type: NativeFilterTypes.DateRange;
  options?: never;
  maxDate?: Date;
  minDate?: Date;
  timeZone?: string;
  hideLabel?: boolean;
}

export interface DateFilterType extends BaseFilter {
  type: NativeFilterTypes.Date;
  options?: never;
  maxDate?: Date;
  minDate?: Date;
  timeZone?: string;
}

export interface MultiselectArrayFilterType extends BaseFilter {
  type: NativeFilterTypes.ArrIncludesSome;
  options: { label: string; value: string }[];
}

export type FilterType =
  | SelectFilterType
  | MultiselectFilterType
  | MultiselectArrayFilterType
  | DateRangeFilterType
  | DateFilterType;

export type CustomFilterType<TDataShape> = FilterType & {
  customFilterFn?: FilterFn<TDataShape>;
};

export type FiltersProps = {
  activeFilters: ColumnFiltersState;
  filters?: Array<CustomFilterType<unknown> | FilterType>;
  setActiveFilters: (filters: ColumnFiltersState) => void;
  cacheKey?: string;
  onSearchFilterChange?: (value: string) => void;
  searchFilterValue?: string;
  searchFilterTooltip?: string;
  searchFilterLabel?: string;
  showFilters?: boolean;
};

const getInitialFilters = (filters: FilterType[]) => {
  return filters.filter(filter => filter.isPromoted).map(filter => filter.key);
};

export const Filters = ({
  filters = [],
  activeFilters,
  setActiveFilters,
  cacheKey,
  onSearchFilterChange,
  searchFilterValue,
  searchFilterTooltip,
  searchFilterLabel,
  showFilters = true,
}: FiltersProps) => {
  const [visibleFilters, setVisibleFilters] = usePersistedSetting<String[]>({
    cacheKey,
    settingKey: 'visibleFilters',
    initialValue: getInitialFilters(filters),
  });
  const [excludedFilterKeys] = useState(
    new Set(
      filters
        .filter(filter => filter.excludeFromClear)
        .map(filter => filter.key)
    )
  );
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const updatedVisibleFilters = [...visibleFilters];
    let hasChanges = false;

    activeFilters.forEach(filter => {
      if (!updatedVisibleFilters.includes(filter.id)) {
        updatedVisibleFilters.push(filter.id);
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setVisibleFilters(updatedVisibleFilters);
    }
  }, [JSON.stringify(activeFilters)]);

  if (!showFilters) {
    return null;
  }

  const onFilterChange =
    <TValue,>(key: string) =>
    (value: TValue | null) => {
      const newFilters = activeFilters.filter(filter => filter.id !== key);
      if (value) {
        newFilters.push({
          id: key,
          value,
        });
      }
      setActiveFilters(newFilters);
    };

  const getFilterValue = <TValue,>(key: string): TValue =>
    activeFilters.find(filter => filter.id === key)?.value as TValue;

  const toggleVisibleFilter = (key: string) => () => {
    if (visibleFilters.includes(key)) {
      setVisibleFilters(visibleFilters.filter(filterKey => filterKey !== key));
      onFilterChange(key)(null);
    } else {
      setVisibleFilters([...visibleFilters, key]);
    }
  };

  const clearAllFilters = () => {
    setActiveFilters(
      activeFilters.filter(filter => excludedFilterKeys.has(filter.id))
    );
  };

  const handleRemoveAllFilters = () => {
    clearAllFilters();
    setActiveFilters([]);
    setVisibleFilters([]);
  };

  const handleSelectAllFilters = () => {
    setVisibleFilters(filters.map(filter => filter.key));
  };

  const filtersExceptExcluded = activeFilters.filter(
    filter => !excludedFilterKeys.has(filter.id)
  );

  return (
    <>
      <div className={styles.FiltersWrapper}>
        {onSearchFilterChange && (
          <SearchFilter
            value={searchFilterValue}
            onChange={onSearchFilterChange}
            tooltip={searchFilterTooltip}
            label={searchFilterLabel}
          />
        )}
        {filters
          .filter(
            filter => filter.isFixed || visibleFilters.includes(filter.key)
          )
          .map(filter => (
            <Filter
              filter={filter}
              key={filter.key}
              onFilterChange={onFilterChange}
              getFilterValue={getFilterValue}
            />
          ))}
        {(filtersExceptExcluded.length > 0 || searchFilterValue) && (
          <div className={styles.ButtonWrapper}>
            <Button
              variant="text"
              size="large"
              onClick={() => {
                clearAllFilters();
                onSearchFilterChange?.('');
              }}
            >
              {t('ads.table.filters.clear')}
            </Button>
          </div>
        )}
        {filters.some(filter => !filter.isFixed) && (
          <div className={styles.ButtonWrapper}>
            <Button
              variant="secondary"
              size="large"
              onClick={() => setIsOpen(true)}
              startIcon={<Icon name="filter" />}
              disabled={isOpen}
            >
              {t(
                visibleFilters.length > 0
                  ? 'ads.table.filters.edit'
                  : 'ads.table.filters.add'
              )}
            </Button>
          </div>
        )}
      </div>
      <SidePanel
        header={t('ads.table.filters.edit')}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnClickOutside
        headerActions={
          <>
            <Button
              variant="secondary"
              size="large"
              onClick={handleSelectAllFilters}
              disabled={filters.length === visibleFilters.length}
            >
              {t('ads.table.filters.selectAll')}
            </Button>
            <Button
              variant="secondary"
              size="large"
              onClick={handleRemoveAllFilters}
              disabled={visibleFilters.length === 0}
            >
              {t('ads.table.filters.remove')}
            </Button>
          </>
        }
      >
        {filters.map(filter => (
          <Checkbox
            key={filter.key}
            text={filter.label}
            value={filter.key}
            selected={!!filter.isFixed || visibleFilters.includes(filter.key)}
            onChange={toggleVisibleFilter(filter.key)}
            disabled={filter.isFixed}
          />
        ))}
      </SidePanel>
    </>
  );
};
