import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

export const darkModeColors = {
  text: tokens.color.text.primary,
  background: tokens.color.background['primary-darkmode'],
  primaryColor: tokens.color.border['regular-darkmode'],
  onPressControlTextColor: tokens.color.text['primary-inverse'],
  hover: tokens.color.general.gray['tertiary-darkmode'],
  borderHover: tokens.color.border['regular-darkmode'],
  hoverText: tokens.color.text['primary-inverse'],
  borderColor: tokens.color.border['regular-darkmode'],
  dropdownColorOpen: tokens.color.text['primary-inverse'],
  dropdownIndicator: tokens.color.text['primary-inverse'],
  textColor: tokens.color.text['primary-inverse'],
  textColorFocused: tokens.color.text['primary-inverse'],
  textColorDisabled: tokens.color.text['disabled-darkmode'],
} as const;
