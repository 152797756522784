import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export class CSVExportRowLimitError extends CodedError {
  static get errorName() {
    return errorCodes.reservablebookingsCSVExportRowLimitError.code;
  }

  constructor(
    message = errorCodes.reservablebookingsCSVExportRowLimitError.message
  ) {
    super(message);
    this.code = errorCodes.reservablebookingsCSVExportRowLimitError.code;
    this.message = `${message} ${errorCodes.reservablebookingsCSVExportRowLimitError.code}`;
    this.name = errorCodes.reservablebookingsCSVExportRowLimitError.name;
    this.status = errorCodes.reservablebookingsCSVExportRowLimitError.status;
  }
}
