import { DateTime } from 'luxon';

import { parseDateTime } from '../../../helpers/dates';
import { MonthIntervalTypeEnum } from '../types';

/**
 * Used to determine which MonthIntervalTypeEnum are valid for the given date. This is used by the
 * ScheduleDateTimeRepeat component.
 * @param date
 * @param timeZone
 * @returns a list of MonthIntervalTypeEnum
 */
export function getMatchingMonthIntervalTypes(date: Date, timeZone?: string) {
  const intervalOptions = [] as MonthIntervalTypeEnum[];

  const dateTime = parseDateTime(date, timeZone);
  if (!dateTime) return intervalOptions;

  if (dateTime.day <= 28) {
    intervalOptions.push(MonthIntervalTypeEnum.OnDate);
  }

  const nthDay = nthDayOfMonth(dateTime);

  switch (nthDay) {
    case 1: {
      intervalOptions.push(MonthIntervalTypeEnum.FirstOfDay);
      break;
    }
    case 2: {
      intervalOptions.push(MonthIntervalTypeEnum.SecondOfDay);
      break;
    }
    case 3: {
      intervalOptions.push(MonthIntervalTypeEnum.ThirdOfDay);
      break;
    }
    case 4: {
      intervalOptions.push(MonthIntervalTypeEnum.FourthOfDay);
      break;
    }
  }

  if (dateTime.day > dateTime.daysInMonth - 7) {
    intervalOptions.push(MonthIntervalTypeEnum.LastOfDay);
  }

  return intervalOptions;
}

/**
 * Calculates the number in which the given day of week has appeared in the month.
 * Ex. Jan 15, 2023 would return 3 since it is the 3rd Sunday of the month
 * @param dateTime
 * @returns a number equaling the nth instance of the given day of week in the month
 */
function nthDayOfMonth(dateTime: DateTime): number {
  let nthDay = 0;
  const month = dateTime.month;
  let currDateTime = dateTime;
  while (currDateTime.month === month) {
    nthDay += 1;
    currDateTime = currDateTime.minus({ week: 1 });
  }
  return nthDay;
}
