import React, { memo } from 'react';

import { Icon } from 'design-system-web';

import Image from '../../general/Image';

import styles from './listView.scss';

const isThumbnail = (type: any) => {
  switch (type) {
    case 'ChannelListView':
      return true;
    case 'ChannelDirectoryListView':
      return false;
    case 'UserListView':
      return true;
    case 'ContentListView':
      return false;
    default:
      return true;
  }
};

type OwnListViewProps = {
  header?: string;
  image?: number;
  type:
    | 'ChannelListView'
    | 'ChannelDirectoryListView'
    | 'UserListView'
    | 'ContentListView';
  text?: any; // TODO: PropTypes.oneOf([ PropTypes.string, PropTypes.arrayOf([PropTypes.string]), ])
  containerClassName?: string;
  headerClassName?: string;
  textClassName?: string;
  iconClassName?: string;
  icon?: boolean;
  iconColor?: string;
  imageSize?: number;
  onSelection?: Function;
  tabIndex?: number;
};

type ListViewProps = OwnListViewProps;

const ListView = ({
  header,
  image,
  text = null,
  containerClassName = '',
  headerClassName = '',
  textClassName = '',
  iconClassName = '',
  icon = true,
  iconColor = '#535353',
  type,
  imageSize = 50,
  onSelection,
  tabIndex = 0,
  ...otherProps
}: ListViewProps) => (
  <div
    role="button"
    tabIndex={tabIndex}
    className={`${styles.container} ${
      styles[`${type}Container`]
    } ${containerClassName}`}
    {...otherProps}
    onClick={() => {
      if (onSelection) onSelection();
    }}
  >
    {image && (
      <Image
        src={(image as unknown) as string}
        className={`
          ${styles[`${type}Image`]}
          ${isThumbnail(type) ? styles.thumbnailImage : styles.squareImage}
        `}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(false | { width: any; height: any; })[]' is... Remove this comment to see the full error message
        style={[
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          isThumbnail() && {
            width: imageSize,
            height: imageSize,
          },
        ]}
      />
    )}
    <div
      className={`${styles.innerContainer} ${styles[`${type}InnerContainer`]}`}
    >
      {header && (
        <div
          className={`${styles.header} ${
            styles[`${type}Header`]
          } ${headerClassName}`}
        >
          {header}
        </div>
      )}
      {Array.isArray(text) ? (
        text.map((t, i) => (
          <div
            key={`${t}${i}`}
            className={`${styles.text} ${
              styles[`${type}Text`]
            } ${textClassName}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(false | { marginTop: number; })[]' is not a... Remove this comment to see the full error message
            style={[i !== 0 && { marginTop: -2 }]}
          >
            {t}
          </div>
        ))
      ) : (
        <div
          className={`${styles.text} ${styles[`${type}Text`]} ${textClassName}`}
        >
          {text}
        </div>
      )}
    </div>
    {icon && (
      <Icon
        name="arrow-right"
        color={iconColor}
        className={`${styles[`${type}Icon`]} ${iconClassName}`}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type '"small" |... Remove this comment to see the full error message
        size={24}
      />
    )}
  </div>
);

export default memo(ListView);
