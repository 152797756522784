import React, { createContext } from 'react';

import { ContentType } from '../types/content/Content';

type TopLevelComponentContextType = {
  showToast: ({
    title,
    description,
    buttonText,
    onPress,
    buttonPosition,
  }: {
    title: string;
    description?: string;
    buttonText?: string;
    onPress?: () => void;
    buttonPosition?: 'right' | 'bottom-right';
  }) => void;
  hideToast: () => void;
  showWebView: ({ url }: { url: string }) => void;
  hideWebView: () => void;
  showLocationRequest: () => void;
  hideLocationRequest: () => void;
  openLoginDrawer: ({
    canCancel,
    redirectRoute,
    enableQuickSignUp,
    associatedContent,
  }: {
    canCancel: boolean;
    redirectRoute: string | null;
    enableQuickSignUp: boolean;
    associatedContent: Partial<ContentType> | null;
  }) => void;
  closeLoginDrawer: () => void;
  toastRef: React.RefObject<any>;
  loginDrawerRef: React.RefObject<any>;
  locationRequestModalRef: React.RefObject<any>;
  webViewRef: React.RefObject<any>;
  navigationRef: React.RefObject<any>;
};

const TopLevelComponentContext = createContext<TopLevelComponentContextType>({
  showToast: () => null,
  hideToast: () => null,
  showWebView: () => null,
  hideWebView: () => null,
  showLocationRequest: () => null,
  hideLocationRequest: () => null,
  openLoginDrawer: () => null,
  closeLoginDrawer: () => null,
  toastRef: React.createRef(),
  loginDrawerRef: React.createRef(),
  locationRequestModalRef: React.createRef(),
  webViewRef: React.createRef(),
  navigationRef: React.createRef(),
});

export default TopLevelComponentContext;
