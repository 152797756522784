import React, { useEffect, useMemo } from 'react';

import cx from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { getContentFeaturePaymentSummary } from 'lane-shared/graphql/content';
import { parseDate } from 'lane-shared/helpers/dates';
import {
  explodeFeatures,
  getPaymentFeatureCurrency,
} from 'lane-shared/helpers/features';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { CONTENT_TYPES_WITH_DATE } from 'lane-shared/types/content/ContentTypeEnum';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';

import Badge from '../general/Badge';
import useUserLocale from 'hooks/useUserLocale';

import styles from './ContentFeaturePaymentSummaryBadge.scss';

export type ContentFeaturePaymentSummaryBadgeProps = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
  startDate?: Date;
  endDate?: Date;
};

export default function ContentFeaturePaymentSummaryBadge({
  className,
  style,
  content,
  startDate,
  endDate,
}: ContentFeaturePaymentSummaryBadgeProps) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const { paymentFeature, statusesFeature } = explodeFeatures(content.features);
  const numberFormat = Intl.NumberFormat(locale).format;

  const currencyFormat = currencyFormatter({
    locale,
    currency: getPaymentFeatureCurrency(paymentFeature),
  });

  const [fetchSummary, { data }] = useLazyQuery(
    getContentFeaturePaymentSummary,
    {
      client: getClient(),
      fetchPolicy: 'network-only',
    }
  );

  const results = useMemo(() => data?.contentFeaturePaymentSummary || [], [
    data?.contentFeaturePaymentSummary,
  ]);

  // maybe needed in the future.
  // const totalInteractions =
  //  data?.contentFeaturePaymentSummary?.totalInteractions || 0;

  useEffect(() => {
    if (content?._id) {
      // figure out a start an end date.
      let _start = parseDate(content._created);
      // default to end of today.
      let _end = DateTime.local().endOf('day').toJSDate();

      if (statusesFeature) {
        // this content has statuses turned on, so use those times for the
        // summary date range
        _start = content.lastReset;
        _end = content.nextReset;
      } else if (CONTENT_TYPES_WITH_DATE.includes(content.type)) {
        // if this is timed content, use the live and end dates.
        _start = content.liveDate;
        _end = content.endDate;
      }

      // only pass these in if no props were provided.
      if (startDate) {
        _start = startDate;
      }

      if (endDate) {
        _end = endDate;
      }

      fetchSummary({
        variables: {
          id: content._id,
          startDate: _start,
          endDate: _end,
        },
      });
    }
  }, [content?._id, startDate, endDate]);

  return (
    <div className={cx(styles.ContentFeaturePaymentSummaryBadge, className)}>
      {results?.map((result: any) => {
        const value =
          result.type === PaymentTypeEnum.Cash
            ? currencyFormat(result.totalAmount / 100)
            : numberFormat(result.totalAmount / 100);

        return (
          <Badge
            key={result.type}
            className={styles.badge}
            style={style}
            title={t(result.type)}
            value={value}
          />
        );
      })}
    </div>
  );
}
