import React, { useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';

import WeeklyTimeRangePicker from '../../../features/TimeAvailability/WeeklyTimeRangePicker';
import FeatureOptionToggle from '../components/FeatureOptionToggle';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  dailyAvailabilityTitle:
    'web.content.features.TimeAvailability.TimeAvailabilityDaily.dailyAvailability.title',
  dailyAvailabilityDescription:
    'web.content.features.TimeAvailability.TimeAvailabilityDaily.dailyAvailability.description',
  availableAnytimeTitle:
    'web.content.features.TimeAvailability.TimeAvailabilityDaily.availableAnytime.title',
  edit: 'web.content.features.TimeAvailability.edit',
  done: 'web.content.features.TimeAvailability.done',
};

export default function TimeAvailabilityDaily({
  onAvailabilityAnytimeChange,
  defaultAvailability,
  onRemoveAvailability,
  onAddAvailability,
  onToggleOpen,
  onTimeRangeSelect,
  onAddTimeRange,
  onRemoveTimeRange,
  onAllDayToggle,
  timeZone,
}: any) {
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();

  return (
    <div className={styles.dailyAvailability}>
      <FeatureOptionToggle
        className={styles.availableAnytime}
        toggleClassName={styles.largeToggle}
        onChange={() => {
          if (defaultAvailability) {
            onRemoveAvailability();
          } else {
            onAddAvailability();
          }
        }}
        title={t(TRANSLATION_KEYS.dailyAvailabilityTitle)}
        description={t(TRANSLATION_KEYS.dailyAvailabilityDescription)}
        isExpanded={Boolean(defaultAvailability)}
      >
        <div className={styles.availabilityRulesContainer}>
          <FeatureOptionToggle
            className={styles.availableAnytime}
            onChange={onAvailabilityAnytimeChange}
            title={t(TRANSLATION_KEYS.availableAnytimeTitle)}
            isExpanded={defaultAvailability?.isAvailableAnytime}
          />

          {defaultAvailability?.isAvailableAnytime === false && (
            <>
              <div
                className={cx(
                  styles.editContainer,
                  styles.editDefaultContainer
                )}
              >
                {isEditing ? (
                  <Button
                    testId="doneEditingButton"
                    variant="contained"
                    onClick={() => setIsEditing(false)}
                  >
                    {t(TRANSLATION_KEYS.done)}
                  </Button>
                ) : (
                  <Button
                    testId="editTimeButton"
                    variant="outlined"
                    onClick={() => setIsEditing(true)}
                  >
                    {t(TRANSLATION_KEYS.edit)}
                  </Button>
                )}
              </div>

              <div
                className={cx(styles.availabilityRule, styles.inputRow, {
                  [styles.isEditing]: isEditing,
                })}
                data-test="availabilityRule"
              >
                <WeeklyTimeRangePicker
                  weeklyAvailability={defaultAvailability}
                  onTimeRangeSelect={onTimeRangeSelect}
                  onAddTimeRange={onAddTimeRange}
                  onRemoveTimeRange={onRemoveTimeRange}
                  onToggleOpen={onToggleOpen}
                  onAllDayToggle={onAllDayToggle}
                  isEditing={isEditing}
                  timeZone={timeZone}
                />
              </div>
            </>
          )}
        </div>
      </FeatureOptionToggle>
    </div>
  );
}
