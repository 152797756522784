type QuantityRange = {
  maxQuantity: number;
  minQuantity: number;
};

export default function getQuantityRange(content: any[]): QuantityRange {
  if (!(content?.length > 0)) {
    return {
      maxQuantity: 100,
      minQuantity: 1,
    };
  }

  const { maxQuantity, minQuantity } = content.reduce(
    ({ minQuantity, maxQuantity }: QuantityRange, content) => {
      const quantity = content.state?.Quantity?.quantity || 0;

      return {
        minQuantity: Math.min(minQuantity, quantity),
        maxQuantity: Math.max(maxQuantity, quantity),
      };
    },
    {
      minQuantity: Number.MAX_SAFE_INTEGER,
      maxQuantity: 1,
    }
  );

  return {
    maxQuantity: maxQuantity > 1 ? maxQuantity : 100,
    minQuantity: minQuantity || 1,
  };
}
