import gql from 'graphql-tag';

export default gql`
  query getChannelsForAccessUser($userId: UUID!) {
    getChannelsForAccessUser(userId: $userId) {
      channelId
      channelName
    }
  }
`;
