import { gql } from 'graphql-query-contracts';

export const getQuantityAllotments = gql(/* GraphQL */ `
  query getQuantityAllotments($contentId: UUID!) {
    contentFeatureQuantityAllotments(contentId: $contentId) {
      ruleMaxAllottedUnits
      totalGeneralClaimedAllotments
      totalRuleClaimedAllotments
      totalRemainingAllotments
      totalUserInteractions
    }
  }
`);
