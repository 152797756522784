import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

export const primaryColors = {
  text: tokens.color.text.primary,
  background: tokens.color.background.primary,
  primaryColor: tokens.color.general.neutral.primary,
  onPressControlTextColor: tokens.color.text.primary,
  hover: tokens.color.general.neutral.tertiary,
  borderHover: tokens.color.general.neutral.primary,
  hoverText: tokens.color.text.primary,
  borderColor: tokens.color.border.regular,
  dropdownColorOpen: tokens.color.background.primary,
  dropdownIndicator: tokens.color.text.primary,
  textColor: tokens.color.text.primary,
  textColorFocused: tokens.color.text.primary,
  textColorDisabled: tokens.color.text.disabled,
} as const;
