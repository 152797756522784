import { useQuery } from '@apollo/client';

import { getCompaniesByChannelId } from 'lane-shared/graphql/query';
import { GetCompaniesByChannelIdQuery } from 'graphql-query-contracts';

export const useCompaniesForChannel = (channelId: string) => {
  const {
    data: companies,
    loading: isLoadingCompanies,
  } = useQuery<GetCompaniesByChannelIdQuery>(getCompaniesByChannelId, {
    variables: {
      channelId,
    },
    fetchPolicy: 'network-only',
  });

  return {
    companyOptions:
      companies?.companiesByChannelId?.flatMap(c =>
        c ? [{ _id: c._id, name: c.name }] : []
      ) ?? [],
    isLoadingCompanies,
  };
};
