import Features from '../../renderers/v5/features';
import getFeatureEditableProperties from '../../renderers/v5/getFeatureEditableProperties';
import { FeatureInstance } from '../../types/FeatureInstance';
import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';
import { IntegrationProviderEnum } from '../../types/integrations/IntegrationEnums';
import { PropertiesInterface } from '../../types/properties/Property';
import getIntegrationDefinition from '../integrations/getIntegrationDefinition';
import constructProperty from './constructProperty';

export default function constructInteraction(content: {
  isInteractive: boolean;
  data: PropertiesInterface | null;
  features: FeatureInstance<any>[];
  integration?: {
    integration: {
      name: IntegrationProviderEnum;
    };
  };
}) {
  const interaction = {
    data: {},
    state: {},
    features: {},
  };

  if (!content || !content.isInteractive) {
    return interaction;
  }

  // setup data based on the content.

  Object.entries(content?.data || {}).forEach(
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ([key, property]) => (interaction.data[key] = constructProperty(property))
  );

  // setup any features data.
  if (content.features) {
    content.features.forEach(feature => {
      const Feature = Features[feature.type];

      // a feature may not exist if this code was deployed in the past
      // (i.e. on the mobile app) and a new feature was added.
      if (!Feature) {
        return;
      }

      const properties = getFeatureEditableProperties(feature);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      interaction.features[feature.type] = {};

      if (properties) {
        properties.forEach(property => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          interaction.features[feature.type][property.name] = constructProperty(
            property
          );
        });
      }

      // special case for quantity.
      if (Feature.name === FeatureNameEnum.Quantity) {
        // TODO: fix
        (interaction.features as any).Quantity.quantity = 1;
      }
    });
  }

  // setup any integration data.
  if (content.integration?.integration?.name) {
    const name = content.integration.integration.name;
    const definition = getIntegrationDefinition(name);
    const data = {};
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    interaction.data[`_${name}`] = data;

    if (definition.interactionData) {
      Object.entries(definition.interactionData).forEach(
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ([key, property]) => (data[key] = constructProperty(property))
      );
    }
  }

  return interaction;
}
