import React, { forwardRef } from 'react';

import cx from 'classnames';

import styles from './styles.scss';

export type MarginType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;

const elementMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  xs: 'span',
  s: 'span',
  m: 'span',
  l: 'span',
  xl: 'span',
  text: 'span',
  subtitle: 'span',
  link: 'a',
};

type FontSize = 'small' | 'medium' | 'default' | 'large' | 'xlarge';
type TextAlignment = 'justify' | 'left' | 'center' | 'right';
type Variant = 'primary' | 'secondary';
type LinkSize = 'medium' | 'small' | 'xsmall';
type Level = keyof typeof elementMap;

type BaseProps = {
  className?: string;
  style?: React.CSSProperties;
  bold?: boolean;
  italic?: boolean;
  underlined?: boolean;
  textAlign?: TextAlignment;
  mt?: MarginType;
  mb?: MarginType;
  children: React.ReactNode;
  block?: boolean;
  disabled?: boolean;
  interfaceStyle?: 'light' | 'dark';
  as?: React.ElementType;
  level?: Level;
  v2?: boolean;
  id?: string;
};

type TextProps = BaseProps & {
  size?: FontSize;
  variant?: Variant;
};

type LinkProps = BaseProps & {
  size?: LinkSize;
  href?: string;
  target?: string;
  variant?: never;
};

type TypographyProps =
  | (BaseProps & { size: never; variant: never })
  | TextProps
  | LinkProps;

export const Typography = forwardRef<HTMLElement, TypographyProps>(
  (
    {
      as,
      className,
      level = 'text',
      block,
      size,
      style,
      bold,
      italic,
      underlined,
      textAlign,
      variant = 'primary',
      disabled,
      interfaceStyle,
      children,
      mb = 0,
      mt = 0,
      v2,
      ...otherProps
    }: TypographyProps,
    ref
  ) => {
    const DefaultElement = level ? elementMap[level] : 'span';
    const El = as || DefaultElement;
    const classNames = cx(
      styles[level],
      (block || mb > 0 || mt > 0) && styles.block,
      v2 && styles.v2,
      className
    );

    return (
      <El
        ref={ref}
        className={classNames}
        style={style}
        data-variant={variant}
        data-disabled={disabled}
        data-size={size}
        data-margin-top={mt}
        data-margin-bottom={mb}
        data-bold={bold}
        data-italic={italic}
        data-underlined={underlined}
        data-text-align={textAlign}
        data-interface-style={interfaceStyle}
        {...otherProps}
      >
        {children}
      </El>
    );
  }
);

export const H1 = forwardRef<HTMLElement, BaseProps>((props, ref) => (
  <Typography level="h1" ref={ref} {...props} />
));
export const H2 = forwardRef<HTMLElement, BaseProps>((props, ref) => (
  <Typography level="h2" ref={ref} {...props} />
));
export const H3 = forwardRef<HTMLElement, BaseProps>((props, ref) => (
  <Typography level="h3" ref={ref} {...props} />
));
export const H4 = forwardRef<HTMLElement, BaseProps>((props, ref) => (
  <Typography level="h4" ref={ref} {...props} />
));
export const H5 = forwardRef<HTMLElement, BaseProps>((props, ref) => (
  <Typography level="h5" ref={ref} {...props} />
));
export const H6 = forwardRef<HTMLElement, BaseProps>((props, ref) => (
  <Typography level="h6" ref={ref} {...props} />
));
export const P = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="p" ref={ref} {...props} />
));
export const XS = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="xs" ref={ref} {...props} />
));
export const S = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="s" ref={ref} {...props} />
));
export const M = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="m" ref={ref} {...props} />
));
export const L = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="l" ref={ref} {...props} />
));
export const XL = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="xl" ref={ref} {...props} />
));
export const Text = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="text" ref={ref} {...props} />
));
export const Subtitle = forwardRef<HTMLElement, TextProps>((props, ref) => (
  <Typography level="subtitle" ref={ref} {...props} />
));
export const TextLink = forwardRef<HTMLElement, LinkProps>((props, ref) => (
  <Typography level="link" ref={ref} {...props} />
));
