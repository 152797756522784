import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class GeneaInvalidFieldError extends CodedError {
  static get errorName() {
    return errorCodes.geneaInvalidFieldError.code;
  }

  constructor(message = errorCodes.geneaInvalidFieldError.message) {
    super(message);
    this.code = errorCodes.geneaInvalidFieldError.code;
    this.message = `${message} ${errorCodes.geneaInvalidFieldError.code}`;
    this.name = errorCodes.geneaInvalidFieldError.name;
  }
}
