import gql from 'graphql-tag';

export default gql`
  query getAttachment($id: UUID!) {
    getAttachment(_id: $id) {
      id
      fileSignedUrl
      thumbnailSignedUrl
      entityId
      entityType
      file
      thumbnail
      created
      deletedAt
    }
  }
`;
