import gql from 'graphql-tag';

export type RemoveTenantMutationResponse = {
  removeTenant: boolean;
};

export const removeTenant = gql`
  mutation RemoveTenant($unitId: UUID!, $tenantId: UUID!) {
    removeTenant(unitId: $unitId, tenantId: $tenantId)
  }
`;
