import commonConfig from './config.common';

const config = {
  ...commonConfig,
  mode: 'development',
  appUrl: 'http://localhost:5000',
  port: 5000,

  secret: 'Y0u_Sh4ll_n0t_p44444s!_H4rry_P0tt3r',
  // please don't push your local settings here,use this as an example
  graphql: {
    introspection: 'true',
    playground: 'true',
    csrfPrevention: 'false',
  },
  database: {
    pgNative: true,
    host: '127.0.0.1',
    port: 5432,
    database: 'lane',
    user: 'lane',
    password: 'password',
  },
  flatfile: {
    secrets: {
      all_fields_required: null,
      name_required: null,
      name_email_cell_required: null,
      name_email_company_required: null,
      name_email_required: null,
      name_cell_required: null,
      name_cell_company_required: null,
      name_company_required: null,
    },
  },
  auth0: {
    apiKey: 'abb24fb9-ef9d-463a-a458-515ebfddd57a',
    systemApi: {
      domain: process.env.AUTH0_SYSTEM_API_DOMAIN || 'auth-local.vts.com',
      clientId:
        process.env.AUTH0_SYSTEM_API_CLIENT_ID || 'system_api_client_id',
      clientSecret:
        process.env.AUTH0_SYSTEM_API_CLIENT_SECRET ||
        'system_api_client_secret',
    },
  },
  kinesis: {
    analytics: {
      streamName: 'analytics-dev-us-east-2',
      maxStreams: 1,
    },
    weblog: {
      streamName: 'analytics-weblog-dev-us-east-2',
      maxStreams: 1,
    },
  },
  kms: {
    retryDelay: 100, // 100 milliseconds
    keyId: 'alias/lane-app-lane-backend-dev',
  },
  parkwhiz: {
    clientId:
      '8db0807e797f2d293e757474df1ed954faccab1f30e70c9e548f17d2b8e78b37',
    clientSecret:
      '5a498e6c41e1d4afaceb1382789df3b5b94dab8cf545c8ecd2ec8804a6be0062',
    baseUrl: 'https://api.sandbox.parkwhiz.com/v4/',
  },
  logLevel: 'info',
  launchDarkly: {
    sdkKey:
      process.env.LD_SDK_KEY || 'sdk-edf7aaef-7f13-431b-8373-03a33096212d',
    clientId: process.env.LD_CLIENT_ID || '62756b2455909914cfbece5b',
    mobileId:
      process.env.LD_MOBILE_ID || 'mob-d9ab7e35-c12d-4ea1-9102-f115be8d0842',
  },
  stripe: {
    publicKey:
      process.env.STRIPE_PUBLIC_KEY ||
      'pk_test_51D5IZUK54uNU04sVIfWehFqgJQurn2Fd2beQw4Eh0pHsWXoMNjHei1sGq6JvA4VG1ut9fKhzm6xHODLXldUlTZPz00hXSrHeI0',
    privateKey: process.env.STRIPE_SECRET_KEY,
    clientId: process.env.CLIENT_ID || 'ca_E0yhpjzPqrjtBZOKoUIOtB6BOZ9wYQJp',
  },
  grpc: {
    tenex: {
      host: process.env.LOCAL_TENEX_HOST || 'localhost:50051',
    },
    gsa: {
      host: process.env.LOCAL_GSA_HOST || 'localhost:50051',
    },
  },
} as const;

export default config;
