import gql from 'graphql-tag';

export default gql`
  mutation sendInvite(
    $groupRoleId: UUID
    $groupRoleIds: [UUID!]
    $companyId: UUID
    $email: EmailAddress!
    $name: String!
    $mobileAccess: Boolean
    $accessControlGroups: [AccessControlGroupsForInvite!]
    $phone: PhoneNumber
    $unitsForResident: UnitsForResident
    $residentType: ResidentType
  ) {
    sendInvite(
      groupRoleId: $groupRoleId
      groupRoleIds: $groupRoleIds
      email: $email
      name: $name
      companyId: $companyId
      mobileAccess: $mobileAccess
      accessControlGroups: $accessControlGroups
      phone: $phone
      unitsForResident: $unitsForResident
      residentType: $residentType
    ) {
      _id
      _created
      _updated
      name
      email
      sent
      status
      result
    }
  }
`;
