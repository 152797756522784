import gql from 'graphql-tag';

export default gql(`
 fragment ContentInteractionFragment on UserContentInteraction {
    _id
    _created
    _updated
    startDate
    endDate 
    content {
     _id
    }
    contentData
    geo
    state
    features
    actions
    version
    status
    data
    user {
      _id
      name
      profile {
        _id
        name
        email
        image
        logo
      }
    }
  }
`);
