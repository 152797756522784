import gql from 'graphql-tag';

export default gql`
  query getCredentialGroups($vtsUserId: UUID!, $vtsOrganizationIds: [UUID]!) {
    getCredentialGroups(
      vtsUserId: $vtsUserId
      vtsOrganizationIds: $vtsOrganizationIds
    ) {
      id
      vtsUserId
      vtsOrganizationId
      provider
      type
      status
      credentials {
        mapId
        cardNumber
        status
        provider
        vtsOrganizationId
        activatedAt
        revokedAt
      }
    }
  }
`;
