import { SYSTEM_LIBRARY_ID } from 'lane-shared/helpers/constants/ids';

import { LibraryType } from '../../types/libraries/LibraryType';
import { LibraryTypeEnum } from '../../types/libraries/LibraryTypeEnum';
import convertTo62 from '../convertId/convertTo62';
import { LANE_TEMPLATE_LIBRARY_ID } from './ids';

export const LANE_TEMPLATE_LIBRARY: LibraryType = {
  type: LibraryTypeEnum.Library,
  _id: convertTo62(LANE_TEMPLATE_LIBRARY_ID),
  name: 'Lane Templates',
};

export const LANE_MEDIA_LIBRARY: LibraryType = {
  type: LibraryTypeEnum.Library,
  _id: SYSTEM_LIBRARY_ID,
  name: 'Lane Media',
};
