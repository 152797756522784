import gql from 'graphql-tag';

export default gql`
  mutation createUserIntegration(
    $channelIntegrationId: UUID!
    $userKey: String!
    $password: String
    $loginType: UserLoginType
    $addToLogins: Boolean! = false
  ) {
    createUserIntegration(
      channelIntegrationId: $channelIntegrationId
      userKey: $userKey
      password: $password
      loginType: $loginType
      addToLogins: $addToLogins
    ) {
      _id
    }
  }
`;
