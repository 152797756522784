import gql from 'graphql-tag';

import { ChannelMerchantFragment } from '../fragments';

export default gql`
  ${ChannelMerchantFragment}

  query getChannelMerchantAccount($id: UUID!) {
    channel(_id: $id) {
      _id
      merchantAccount {
        ...ChannelMerchantFragment
      }
    }
  }
`;
