import * as yup from 'yup';
import {
  BooleanSchema,
  DateSchema,
  MixedSchema,
  NotRequiredArraySchema,
  NumberSchema,
  ObjectSchema,
  Schema,
  StringSchema,
  ArraySchema,
} from 'yup';

import i18n from 'localization';

import { ArrayMaxValidator } from '../../properties/validators/ArrayMax';
import { ArrayMinValidator } from '../../properties/validators/ArrayMin';
import { ArrayUniqueValidator } from '../../properties/validators/ArrayUnique';
import { GreaterThanValidator } from '../../properties/validators/GreaterThan';
import { ITypeValidatesArrayMax } from '../../properties/validators/ITypeValidatesArrayMax';
import { ITypeValidatesArrayMin } from '../../properties/validators/ITypeValidatesArrayMin';
import { ITypeValidatesArrayUnique } from '../../properties/validators/ITypeValidatesArrayUnique';
import { ITypeValidatesGreaterThan } from '../../properties/validators/ITypeValidatesGreaterThan';
import { ITypeValidatesIn } from '../../properties/validators/ITypeValidatesIn';
import { ITypeValidatesIncludes } from '../../properties/validators/ITypeValidatesIncludes';
import { ITypeValidatesLessThan } from '../../properties/validators/ITypeValidatesLessThan';
import { ITypeValidatesMax } from '../../properties/validators/ITypeValidatesMax';
import { ITypeValidatesMin } from '../../properties/validators/ITypeValidatesMin';
import { ITypeValidatesRequired } from '../../properties/validators/ITypeValidatesRequired';
import { ITypeValidatesRequiredIf } from '../../properties/validators/ITypeValidatesRequiredIf';
import { InValidator } from '../../properties/validators/In';
import { IncludesValidator } from '../../properties/validators/Includes';
import { LessThanValidator } from '../../properties/validators/LessThan';
import { MaxValidator } from '../../properties/validators/Max';
import { MinValidator } from '../../properties/validators/Min';
import { RequiredValidator } from '../../properties/validators/Required';
import { RequiredIfValidator } from '../../properties/validators/RequiredIf';
import { addArrayMaxValidatorImpl } from '../../properties/validators/TTypeValidatesArrayMax';
import { addArrayMinValidatorImpl } from '../../properties/validators/TTypeValidatesArrayMin';
import { addArrayUniqueValidatorImpl } from '../../properties/validators/TTypeValidatesArrayUnique';
import { addInValidatorImpl } from '../../properties/validators/TTypeValidatesIn';
import { addRequiredValidatorImpl } from '../../properties/validators/TTypeValidatesRequired';
import { addRequiredIfValidatorImpl } from '../../properties/validators/TTypeValidatesRequiredIf';
import { PropertyType } from '../properties/Property';
import { TypeContextEnum } from '../properties/TypeContextEnum';
import { TypeInterface } from './TypeInterface';

interface ITypeValidates {
  validatesArrayMax: boolean;
  validatesArrayMin: boolean;
  validatesArrayUnique: boolean;
  validatesGreaterThan: boolean;
  validatesIn: boolean;
  validatesIncludes: boolean;
  validatesLessThan: boolean;
  validatesMax: boolean;
  validatesMin: boolean;
  validatesRequired: boolean;
  validatesRequiredIf: boolean;
}

abstract class AbstractTypeBase<T extends Schema<U, any>, U = any>
  implements TypeInterface<T, U>, ITypeValidates {
  _schema?: T;

  _arraySchema?: NotRequiredArraySchema<U, any>;

  get schema(): T {
    if (this._schema === undefined) {
      this._schema = this.buildSchema();
    }
    return this._schema;
  }

  get arraySchema(): NotRequiredArraySchema<U, any> {
    if (this._arraySchema === undefined) {
      this._arraySchema = yup.array().of(this.schema);
    }
    return this._arraySchema;
  }

  getDisplayName(): string {
    return i18n.t(
      this.config?.friendlyName ||
        this.config?.name ||
        this.friendlyName ||
        this.name
    );
  }

  validatesArrayMax = false;

  validatesArrayMin = false;

  validatesArrayUnique = false;

  validatesGreaterThan = false;

  validatesIn = false;

  validatesIncludes = false;

  validatesLessThan = false;

  validatesMax = false;

  validatesMin = false;

  validatesRequired = false;

  validatesRequiredIf = false;

  // Below to enforce class implementation check

  abstract readonly name: string;

  abstract readonly contexts: TypeContextEnum[];

  readonly friendlyName?: string = undefined;

  abstract get default(): any;

  abstract buildSchema(): T;

  constructor(readonly config: PropertyType | undefined = undefined) {}
}

// see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/yup/index.d.ts for a complete list of interfaces with required()
export type SupportedSchema =
  | MixedSchema
  | StringSchema
  | NumberSchema
  | BooleanSchema
  | DateSchema
  | ObjectSchema
  | ArraySchema<any>
  | NotRequiredArraySchema<any>;

export abstract class TypeBase<T extends SupportedSchema>
  extends AbstractTypeBase<T>
  implements
    ITypeValidatesArrayMax,
    ITypeValidatesArrayMin,
    ITypeValidatesArrayUnique,
    ITypeValidatesRequired,
    ITypeValidatesRequiredIf,
    ITypeValidatesIn,
    // Below are validators not supported by the base type and used to catch existing data incompatible with their validators
    ITypeValidatesGreaterThan,
    ITypeValidatesLessThan,
    ITypeValidatesIncludes,
    ITypeValidatesMin,
    ITypeValidatesMax {
  validatesArrayMax = true;

  addArrayMaxValidator(validator: ArrayMaxValidator): void {
    addArrayMaxValidatorImpl(this, validator);
  }

  validatesArrayMin = true;

  addArrayMinValidator(validator: ArrayMinValidator): void {
    addArrayMinValidatorImpl(this, validator);
  }

  validatesArrayUnique = true;

  addArrayUniqueValidator(validator: ArrayUniqueValidator): void {
    addArrayUniqueValidatorImpl(this, validator);
  }

  validatesRequired = true;

  addRequiredValidator(validator: RequiredValidator) {
    addRequiredValidatorImpl(this, validator);
  }

  validatesRequiredIf = true;

  addRequiredIfValidator(validator: RequiredIfValidator) {
    addRequiredIfValidatorImpl(this, validator);
  }

  validatesIn = true;

  addInValidator(validator: InValidator) {
    addInValidatorImpl(this, validator);
  }

  // Below are validators not supported by the base type and used to catch existing data incompatible with their validators
  validatesGreaterThan = false;

  addGreaterThanValidator(_: GreaterThanValidator) {
    if (this.friendlyName !== 'Date & Time') {
      console.warn(
        `WARNING: ${this.friendlyName} does not support GreaterThanValidator`
      );
    }
    this._schema = this.schema.notRequired() as T;
  }

  validatesLessThan = false;

  addLessThanValidator(_: LessThanValidator) {
    if (this.friendlyName !== 'Date & Time') {
      console.warn(
        `WARNING: ${this.friendlyName} does not support LessThanValidator`
      );
    }
    this._schema = this.schema.notRequired() as T;
  }

  validatesIncludes = false;

  addIncludesValidator(_: IncludesValidator) {
    console.warn(
      `WARNING: ${this.friendlyName} does not support IncludesValidator`
    );
    this._schema = this.schema.notRequired() as T;
  }

  validatesMin = false;

  addMinValidator(_: MinValidator) {
    console.warn(`WARNING: ${this.friendlyName} does not support MinValidator`);
    this._schema = this.schema.notRequired() as T;
  }

  validatesMax = false;

  addMaxValidator(_: MaxValidator) {
    console.warn(`WARNING: ${this.friendlyName} does not support MaxValidator`);
    this._schema = this.schema.notRequired() as T;
  }
}

export type BaseType = TypeBase<SupportedSchema>;
