import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class S3UploadError extends CodedError {
    static get errorName() {
      return errorCodes.s3UploadError.code;
    }
  
    constructor(message = errorCodes.s3UploadError.message) {
      super(message);
      this.code = errorCodes.s3UploadError.code;
      this.message = `${message} ${errorCodes.s3UploadError.code}`;
      this.name = errorCodes.s3UploadError.name;
      this.status = errorCodes.s3UploadError.status;
    }
  }