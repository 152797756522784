import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuantityAdvancedRulesEnabled } from 'lane-shared/hooks/useQuantityAdvancedRulesEnabled';
import { Toggle } from 'components/form';
import { QuantityFeatureProperties } from 'lane-shared/types/features/QuantityFeatureProperties';
import { FeatureRendererPropsType } from './FeatureRendererPropsType';
import QuantityAllocationsType from 'lane-shared/renderers/v5/features/types/QuantityAllocations';
import { QuantityAdvancedRule } from './QuantityAdvancedRule';
import { useChannelAdminContext } from 'hooks';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { isFeatureActive } from './QuantityWaitlist';

type QuantityAdvancedRulesProps = Pick<
  FeatureRendererPropsType<QuantityFeatureProperties>,
  'onFeatureUpdated' | 'contentFeature' | 'feature' | 'content'
>;

const TRANSLATION_KEYS = Object.freeze({
  featureExclusivityPopupDescription:
    'web.admin.content.features.featureExclusivity.popup.description',
});

export function QuantityAdvancedRules({
  contentFeature,
  feature,
  content,
  onFeatureUpdated,
}: QuantityAdvancedRulesProps) {
  const isQuantityAdvancedRulesEnabled = useQuantityAdvancedRulesEnabled();
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();

  const isTimeResetActive = isFeatureActive(content, FeatureNameEnum.Reset);

  const handleOnToggleChange = (value: boolean) => {
    if (value === true) {
      const defaultQuantityAllocation = QuantityAllocationsType.default;
      if (channel && channel?._id) {
        onFeatureUpdated({
          quantityAllocations: [
            {
              ...defaultQuantityAllocation,
              channelIds: [
                ...defaultQuantityAllocation.channelIds,
                channel._id,
              ],
            },
          ],
        });
      } else {
        onFeatureUpdated({
          quantityAllocations: [defaultQuantityAllocation],
        });
      }
    } else {
      onFeatureUpdated({
        quantityAllocations: [],
      });
    }
  };

  if (!isQuantityAdvancedRulesEnabled) {
    return null;
  }

  return (
    <>
      <Toggle
        className="mt-2"
        text={t(feature.properties.quantityAllocations.description)}
        onChange={handleOnToggleChange}
        value={!!contentFeature.feature.quantityAllocations?.length}
        disabled={isTimeResetActive}
        TooltipComponent={t(
          TRANSLATION_KEYS.featureExclusivityPopupDescription
        )}
      />
      {(contentFeature.feature.quantityAllocations || []).map(
        (allocation, index) => {
          return (
            <div
              key={index}
              className="rounded border border-border-subdued mt-4 mb-2 p-2.5"
            >
              <QuantityAdvancedRule
                contentFeature={contentFeature}
                onFeatureUpdated={onFeatureUpdated}
                quantityAllocation={allocation}
              />
            </div>
          );
        }
      )}
    </>
  );
}
