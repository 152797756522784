import { CodedError } from '../../CodedError';
import errorCodes from '../../constants/errorCodes';

export class MaxxessInvalidFieldError extends CodedError {
  static get errorName() {
    return errorCodes.maxxessInvalidFieldError.code;
  }

  constructor(message = errorCodes.maxxessInvalidFieldError.message) {
    super(message);
    this.code = errorCodes.maxxessInvalidFieldError.code;
    this.message = `${message} ${errorCodes.maxxessInvalidFieldError.code}`;
    this.name = errorCodes.maxxessInvalidFieldError.name;
  }
}
