import pause from './pause';

const WAIT_TIME = 500;
const MAX_TIMEOUT = 60 * 1000; // 1 minute
const MAX_ATTEMPTS = 5;

export default class BatchHelper {
  _flushScheduled: any;

  _interval: any;

  _lastFlush: any;

  batchSize: any;

  items: any;

  maxAttempts: any;

  maxTimeout: any;

  waitTime: any;

  constructor({
    batchSize = 5,
    waitTime = WAIT_TIME,
    maxAttempts = MAX_ATTEMPTS,
    maxTimeout = MAX_TIMEOUT,
  }) {
    this.batchSize = batchSize;
    this.waitTime = waitTime;
    this.maxAttempts = maxAttempts;
    this.maxTimeout = maxTimeout;
    this.items = [];
    this._flushScheduled = false;
    this._lastFlush = Date.now();
    this._interval = setInterval(() => {
      if (this.shouldFlush) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
        this.flush();
      }
    }, MAX_TIMEOUT);
  }

  cleanup() {
    clearInterval(this._interval);
  }

  get shouldFlush() {
    return (
      this._lastFlush + this.maxTimeout < Date.now() && !this._flushScheduled
    );
  }

  push(obj: any) {
    this.items.push(obj);

    if (this.items.length > this.batchSize || this.shouldFlush) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
      this.flush();
    }
  }

  /* eslint-disable */
  async doAction(items: any) {}
  /* eslint-enable */

  async flush(items: any, attempts = 0) {
    this._flushScheduled = true;

    if (attempts > this.maxAttempts) {
      // These items are lost...
      return;
    }

    if (!items) {
      items = [...this.items];
      this.items = [];
      this._flushScheduled = false;
    }

    try {
      await this.doAction(items);
      this._lastFlush = Date.now();
    } catch (err) {
      await pause(this.waitTime);
      await this.flush(items, ++attempts);
    }
  }
}
