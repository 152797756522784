import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ApiKeyEnvironmentError extends CodedError {
  static get errorName() {
    return errorCodes.ApiKeyEnvironmentError.code;
  }

  constructor(env: any, message = errorCodes.ApiKeyEnvironmentError.message) {
    super(message);
    this.code = errorCodes.ApiKeyEnvironmentError.code;
    this.message = `${env} ${message} ${errorCodes.ApiKeyEnvironmentError.code}`;
    this.name = errorCodes.ApiKeyEnvironmentError.name;
    this.status = errorCodes.ApiKeyEnvironmentError.status;
  }
}
