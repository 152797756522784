import React from 'react';
import type { RowAction as RowActionType } from './hooks/useTable';
import { PopupMenu, TextButton } from '../../index';
import { RowAction, executeOrReturn } from './RowAction';

type RowActionsProps<TDataShape> = {
  actions: RowActionType<TDataShape>[];
  row: TDataShape;
  label?: string;
};

export const RowActions = <TDataShape,>({
  actions,
  row,
  label,
}: RowActionsProps<TDataShape>) => {
  const activeActions = actions.filter(
    ({ isHidden, isDisabled }) => !isHidden && !isDisabled
  );

  const popUpMenuItems = actions.map(
    ({ label, onClick, isHidden, isDisabled, tooltipText, icon }) => ({
      label,
      icon,
      onSelect: () => onClick(row),
      isHidden: executeOrReturn(isHidden, row) as boolean,
      isDisabled: executeOrReturn(isDisabled, row) as boolean,
      tooltipText: executeOrReturn(tooltipText, row) as string,
    })
  );

  return (
    <div>
      {actions.length > 1 && (
        <PopupMenu
          trigger={
            <TextButton
              padding="none"
              iconName="angle-down"
              disabled={activeActions.length === 0}
            >
              {label}
            </TextButton>
          }
          items={popUpMenuItems}
        />
      )}
      {actions.length === 1 && <RowAction row={row} action={actions[0]} />}
    </div>
  );
};
