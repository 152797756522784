import gql from 'graphql-tag';

import { ProfileType } from '../../types/ProfileType';
import { ContentType } from '../../types/content/Content';
import { ThemeFragmentType } from './ThemeFragment';

export type FullProfileWithThemeFragmentType = Pick<
  ProfileType,
  | '_id'
  | '_created'
  | '_updated'
  | 'name'
  | 'description'
  | 'email'
  | 'phone'
  | 'backgroundImage'
  | 'image'
  | 'logo'
  | 'wordmark'
  | 'color'
  | 'backgroundColor'
> & {
  theme?: ThemeFragmentType;
  content?: Pick<
    ContentType,
    | '_id'
    | '_created'
    | '_updated'
    | 'name'
    | 'description'
    | 'subtitle'
    | 'slug'
    | 'type'
    | 'tags'
    | 'data'
    | 'liveDate'
    | 'startDate'
    | 'endDate'
    | 'unpublishDate'
    | 'category'
    | 'logo'
    | 'color'
    | 'geo'
    | 'block'
    | 'isInteractive'
    | 'backgroundImage'
    | 'icon'
    | 'iconSet'
    | 'iconWeight'
    | 'backgroundColor'
    | 'version'
    | 'renderer'
  > & {
    card: Pick<ContentType['card'], '_id'>;
  };
};

export default gql(`
  fragment FullProfileWithThemeFragment on Profile {
    _id
    _created
    _updated
    name
    description
    email
    phone
    noReplyEmail
    backgroundImage
    image
    logo
    wordmark
    color
    backgroundColor
    theme {
      ... ThemeFragment
    }

    content {
      _id
      _created
      _updated
      name
      description
      subtitle
      slug
      type
      tags
      data
      liveDate
      startDate
      endDate
      unpublishDate
      category
      logo
      color
      geo
      block
      isInteractive
      backgroundImage
      icon
      iconSet
      iconWeight
      backgroundColor
      version
      renderer
      card {
        _id
      }
    }
}`);
