import gql from 'graphql-tag';

export default gql(`
  fragment ObjectMetatagFragment on ObjectMetatag {
     _id
    metatag {
      ...MetatagFragment
      values {
        _id
        value
      }
    }
    value
    values {
      _id
      metatagValue {
        _id
        value
      }
    }   
  }
`);
