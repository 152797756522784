import { useEffect } from 'react';

import { ColumnFiltersState, SortingState } from '@tanstack/react-table';

import {
  getFiltersFromQueryString,
  getQueryStringFromActiveFilters,
  getPageSizeFromQueryString,
  getSortQuerystringsFromSortingState,
  QueryString,
} from '../utils/queryStrings';
import { FilterType } from '../../Filters/Filters';
import type { PageSizeType } from './useTable';

type UseTableQueryStringsProps = {
  page: number;
  pageSize: PageSizeType;
  queryStrings: QueryString;
  sorting: SortingState;
  filters: FilterType[];
  activeFilters: ColumnFiltersState;
  keywordFilter: string;
  setQueryStrings: (queryStrings: QueryString) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: PageSizeType) => void;
  setSorting: (sortBy: SortingState) => void;
  setActiveFilters: (activeFilters: ColumnFiltersState) => void;
  setKeywordFilter: (keywordFilter: string) => void;
  queryStringsEnabled: boolean;
};

export const useTableQueryStrings = ({
  page,
  pageSize,
  queryStrings,
  sorting,
  filters,
  activeFilters,
  keywordFilter,
  setQueryStrings,
  setPage,
  setPageSize,
  setSorting,
  setActiveFilters,
  setKeywordFilter,
  queryStringsEnabled,
}: UseTableQueryStringsProps) => {
  useEffect(() => {
    if (!queryStringsEnabled) return;

    if (queryStrings.page && page !== Number(queryStrings.page)) {
      setPage(Number(queryStrings.page));
    }
    if (
      queryStrings.pageSize &&
      pageSize !== getPageSizeFromQueryString(queryStrings.pageSize)
    ) {
      setPageSize(getPageSizeFromQueryString(queryStrings.pageSize));
    }
    if (queryStrings.sortBy && queryStrings.sortBy !== sorting[0]?.id) {
      setSorting([
        {
          id: queryStrings.sortBy,
          desc: queryStrings.sortDirection === 'desc',
        },
      ]);
    }
    if (queryStrings.keyword && queryStrings.keyword !== keywordFilter) {
      setKeywordFilter(queryStrings.keyword);
    }

    const queryFilters = getFiltersFromQueryString(queryStrings, filters);
    if (JSON.stringify(queryFilters) !== JSON.stringify(activeFilters))
      setActiveFilters(queryFilters);
  }, [JSON.stringify(queryStrings)]);

  useEffect(() => {
    if (!queryStringsEnabled) return;

    const { sortBy, sortDirection } =
      getSortQuerystringsFromSortingState(sorting);

    if (
      pageSize !== getPageSizeFromQueryString(queryStrings.pageSize) ||
      page !== Number(queryStrings.page) ||
      sortBy !== queryStrings.sortBy ||
      sortDirection !== queryStrings.sortDirection ||
      getFiltersFromQueryString(queryStrings, filters) !== activeFilters ||
      keywordFilter !== queryStrings.keyword
    ) {
      setQueryStrings({
        page: page.toString(),
        pageSize: pageSize.toString(),
        keyword: keywordFilter,
        sortBy,
        sortDirection,
        ...getQueryStringFromActiveFilters(filters, activeFilters),
      });
    }
  }, [page, pageSize, keywordFilter, JSON.stringify([activeFilters, sorting])]);
};
