import gql from 'graphql-tag';

import { ChannelSettingsRequirementsFragment } from 'lane-shared/graphql/fragments';

export default gql`
  ${ChannelSettingsRequirementsFragment}
  fragment FullDraftContentFragment on DraftContent {
    _id
    _created
    _updated
    _createdBy {
      ...PublicUserFragment
    }
    _updatedBy {
      ...PublicUserFragment
    }
    name
    name_l10n
    description
    description_l10n
    subtitle
    subtitle_l10n
    slug
    type
    tags
    contentTags
    category
    reportingGroup
    logo
    color
    isInteractive
    isSyndicateable
    isPublished
    isEvent
    geo
    backgroundImage
    backgroundColor
    icon
    iconSet
    iconWeight
    liveDate
    startDate
    endDate
    unpublishDate
    interactionEndDate
    resetPeriod
    lastReset
    nextReset
    externalUrl {
      _id
      url
      openingMode
      mediaId
    }
    deepLink {
      _id
      deepLinkUrl
      appStoreUrl
      googlePlayStoreUrl
    }
    card {
      _id
    }
    theme {
      ...ThemeFragment
    }
    channel {
      _id
      name
      description
      slug
      type
      isPublic
      isDiscoverable
      isLive
      isSub

      address {
        ...AddressFragment
      }

      profile {
        ...ProfileFragment
      }

      settings {
        requirements {
          source {
            slug
            profileName
            _id
          }
          ...ChannelSettingsRequirementsFragment
        }
        effectiveParentRequirements {
          source {
            slug
            profileName
            _id
          }
          ...ChannelSettingsRequirementsFragment
        }
      }
    }
    version
    renderer
    block
    properties
    propertiesOptions
    audiences {
      id
    }
    placements {
      _id
      type
      channel {
        _id
      }
      content {
        _id
      }
      groupRole {
        _id
      }
      user {
        _id
      }
    }

    features {
      _id
      type
      feature
    }
    data
    state
    actions {
      _id
      _created
      _updated
      _order
      name
      when
      whenContext
      inStatus
      time
      event
      action
      target
      workflow
      payload
      dataValidationSchema
      workflowTargetedChannelId
      content {
        _id
      }
      groupRole {
        _id
      }
      user {
        _id
      }
      dataField {
        _id
      }
    }
    notifications {
      _id
      _created
      _updated
      title
      title_l10n
      sent
      sendAt
      content {
        _id
      }
      contentGoesLiveNotification
    }
    contentMetatags {
      ...ObjectMetatagFragment
    }
    integration {
      _id
      settings
      deletedAt
      integration {
        _id
        name
        settings
      }
    }
    relatedChannelIntegrationIds
    generator {
      _id
      datasetSchema {
        _id
        name
      }
    }
  }
`;
