import React, { useContext } from 'react';

import UserDataContext from 'lane-shared/contexts/UserDataContext';
import { SHORT_MONTH } from 'lane-shared/helpers/constants/dates';
import { yearItems } from 'lane-shared/helpers/dates';
import findClosestLocale from 'lane-shared/helpers/findClosestLocale';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { useDatePickerRow } from 'lane-shared/hooks';

import Dropdown from 'components/form/Dropdown';
import IconButton from 'components/general/IconButton';

import styles from './DatePickerRow.scss';

type OwnDatePickerRowProps = {
  // called when a new date is selected
  onChange: (date: Date) => void;
  // called when a new month is selected
  onMonthChange: (date: Date) => void;
  // the current value
  value: Date | null | undefined;
  // the minimum date allowed
  minDate: Date | null | undefined;
  // the maximum date allowed
  maxDate: Date | null | undefined;
  // what timezone are we in
  timeZone: string | null;
  showYears: boolean;
  showDays: boolean;
  showMonths: boolean;
  // what time units do the arrows move in?
  quickTimeUnit: 'year' | 'month' | 'day';
  dropdownPosition?: 'absolute' | 'fixed';
};

type DatePickerRowProps = OwnDatePickerRowProps;

export default function DatePickerRow({
  value = null,
  onChange = () => null,
  onMonthChange,
  minDate = null,
  maxDate = null,
  timeZone = null,
  showYears = true,
  showDays = true,
  showMonths = true,
  quickTimeUnit = 'month',
  dropdownPosition,
}: DatePickerRowProps) {
  const {
    dayItems,
    isSameDay,
    nextUnit,
    previousUnit,
    updateDay,
    updateMonth,
    updateYear,
    month,
    year,
    day,
  } = useDatePickerRow({
    timeZone,
    value,
    quickTimeUnit,
    maxDate,
    minDate,
    onChange,
    onMonthChange,
  });

  const { user } = useContext(UserDataContext);

  if (isSameDay) {
    return null;
  }

  // find another type for monthItem
  const monthItems: any[] = [];
  const locale = findClosestLocale(user?.locale);

  for (let i = 0; i < 12; i++) {
    monthItems.push({
      label: dateFormatter(
        new Date(2019, i, 1),
        SHORT_MONTH,
        undefined,
        locale
      ),
      value: i + 1,
    });
  }

  return (
    <div className={styles.DatePickerRow}>
      <IconButton icon="chevron-left" onClick={previousUnit} inverted />
      <div data-test="monthYearPicker" className={styles.dropDowns}>
        {showMonths && (
          <Dropdown
            id="monthDrop"
            value={month}
            className={styles.month}
            items={monthItems}
            onValueChange={updateMonth}
            menuPosition={dropdownPosition}
            testId="monthDropdown"
          />
        )}
        {showDays && (
          <Dropdown
            id="yearDrop"
            value={`${day}`}
            className={styles.day}
            items={dayItems}
            onValueChange={updateDay}
            menuPosition={dropdownPosition}
          />
        )}
        {showYears && (
          <Dropdown
            value={year}
            className={styles.year}
            items={yearItems}
            onValueChange={updateYear}
            menuPosition={dropdownPosition}
            testId="yearDropdown"
            doTranslation={false}
          />
        )}
      </div>
      <IconButton
        onClick={nextUnit}
        icon="chevron-right"
        inverted
        testId="next-month-button"
      />
    </div>
  );
}
