import React, { useState } from 'react';

import cx from 'classnames';

import { ChannelType } from 'lane-shared/types/ChannelType';

import SectionSearchOnChannel from 'components/lane/SectionSearchOnChannel';
import { H4 } from 'components/typography';

import ChannelSelectorButton from './ChannelSelectorButton';

import styles from './SectionSelector.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  channelId?: string | null;
  channelSlug?: string;
  onSectionSelected: (section: any) => void;
};

type Props = OwnProps;

export default function SectionSelector({
  className,
  style,
  channelId = null,
  channelSlug,
  onSectionSelected = () => null,
}: Props) {
  const [selectedChannel, setSelectedChannel] = useState<ChannelType | null>(
    null
  );

  return (
    <div className={cx(styles.SectionSelector, className)} style={style}>
      <H4>Select Section</H4>
      <ChannelSelectorButton
        className={styles.selectorButton}
        channelId={selectedChannel?._id || channelId}
        onChannelSelected={(channel: ChannelType | null) =>
          setSelectedChannel(channel ?? null)
        }
      />
      <SectionSearchOnChannel
        channelId={selectedChannel?._id || channelId}
        slug={selectedChannel?.slug || channelSlug}
        onSectionSelected={onSectionSelected}
      />
    </div>
  );
}
