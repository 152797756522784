import { gql } from '@apollo/client';

export const voidChargeMutation = gql`
  mutation voidCharge($voidChargeRequest: VoidChargeRequest!) {
    accounts {
      voidCharge(voidChargeRequest: $voidChargeRequest) {
        chargeId
      }
    }
  }
`;
