/** @type {import('tailwindcss').Config} */
module.exports = {
  theme: {
    fontFamily: {
      title: 'var(--theme-font-header)',
      body: 'var(--theme-font-body)',
    },
    extend: {
      colors: {
        'theme-primary': 'var(--theme-primary)',
        'theme-secondary': 'var(--theme-secondary)',
        'theme-primary-button-color': 'var(--theme-primary-button-color)',
        'theme-secondary-button-color': 'var(--theme-secondary-button-color)',
        'theme-accent-button-color': 'var(--theme-accent-button-color)',
      },
    },
  },
  darkMode: 'class',
  presets: [require('@viewthespace/tailwind-preset').themeableTailwindPreset],
};
