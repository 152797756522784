import gql from 'graphql-tag';

/**
 * GQL Create Preventive Maintenance Task Mutation
 */

export default gql`
  mutation createTaskMutation($task: CreateTask!) {
    createTask(task: $task) {
      id
      groupBy
      dueDate
      repeats {
        label
        value
      }
      estimateTimeToComplete
      assignee
      assigneeGroup
      notes
      steps
      meterReading
      createdBy
      updatedBy
      completedBy
      equipment {
        id
        location
        name
        category
      }
      createdAt
      updatedAt
      completedAt
      isArchived
      location
      actualTimeToComplete
      completeNotes
      status
      schedule {
        id
        title
      }
    }
  }
`;
