import { v4 as uuid } from 'uuid';

import { colors } from 'lane-shared/config';
import { VisitorBlocks } from 'lane-shared/domains/visitorManagement/types/VisitorBlocks';
import { objectToArray } from 'lane-shared/helpers';
import {
  CONTENT_CARD_ID,
  CONTENT_CARD_HORIZONTAL,
} from 'lane-shared/helpers/constants/content';
import constructProperty from 'lane-shared/helpers/content/constructProperty';
import Features from 'lane-shared/renderers/v5/features';
import {
  FeatureDefinition,
  ContentTypeRequirement,
  ContentTypeRequirementEnum,
} from 'lane-shared/types/FeatureDefinition';
import { FeatureInstance } from 'lane-shared/types/FeatureInstance';
import { ThemeType } from 'lane-shared/types/Theme';
import { ContentMenuTabsEnum } from 'lane-shared/types/content/ContentMenuTabsEnum';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';
import { PropertyType } from 'lane-shared/types/properties/Property';

type BaseBlockType = {
  _id: string;
  blockId: string;
  blockVersion: number;
  properties: any;
};

export function visitorManagementMenuItems() {
  return [
    ContentMenuTabsEnum.Features,
    ContentMenuTabsEnum.Layout,
    ContentMenuTabsEnum.Workflows,
  ];
}

export function visitorManagementContentBuilder(
  contentId: string,
  baseBlock: BaseBlockType,
  theme: ThemeType
) {
  const features = featureRequirements();

  return {
    _id: contentId,
    backgroundColor: theme?.palette?.primary || colors.darkCharcoal,
    color: theme?.palette?.secondary || colors.white,
    backgroundImage: null,
    type: ContentTypeEnum.VisitorManagement,
    block: { ...baseBlock },
    card: {
      _id: CONTENT_CARD_ID[CONTENT_CARD_HORIZONTAL],
    },
    features,
    isInteractive: true,
  };
}

export function visitorManagementFeatureDisplayStatus(
  currentDisplayStatus: boolean,
  feature: FeatureDefinition,
  contentType: ContentTypeEnum
) {
  return contentType && currentDisplayStatus && isFeatureRequired(feature);
}

export function visitorManagementIsEditableProperty(
  property: PropertyType,
  feature: FeatureInstance
) {
  if (
    property.name === VisitorBlocks.HostInformation &&
    !(feature.feature as VisitorManagementFeatureProperties)
      .hostSelectionEnabled
  ) {
    return false;
  }
  if (
    property.name === VisitorBlocks.Notes &&
    !(
      (feature.feature as VisitorManagementFeatureProperties)
        .notesToVisitorEnabled ||
      (feature.feature as VisitorManagementFeatureProperties)
        .notesToStaffEnabled ||
      (feature.feature as VisitorManagementFeatureProperties)
        .additionalRecipientsEnabled
    )
  ) {
    return false;
  }

  return true;
}

function featureRequirements() {
  const featuresArray = objectToArray<FeatureDefinition>(Features);
  const requiredFeatures = featuresArray.filter(isFeatureRequired);
  return requiredFeatures.map(adaptFeature);
}

function isFeatureRequired(feature: FeatureDefinition) {
  return (
    !!feature.contentTypeRequirements &&
    !!feature.contentTypeRequirements.find(
      (requirement: ContentTypeRequirement) =>
        requirement.type === ContentTypeEnum.VisitorManagement &&
        requirement.requirement === ContentTypeRequirementEnum.Required
    )
  );
}

function adaptFeature(feature: FeatureDefinition) {
  const data: {
    [key: string]: PropertyType;
  } = {};

  if (feature.properties) {
    Object.entries(feature.properties).forEach(
      ([key, property]) => (data[key] = constructProperty(property))
    );
  }

  return {
    _id: uuid(),
    type: feature.name,
    feature: {
      useContentCategories: false,
      permissions: [],
      ...data,
    },
  };
}
