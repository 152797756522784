import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Key } from 'ts-key-enum';

import {
  ICON_SET_FONTAWESOME,
  FONT_AWESOME_REGULAR,
} from 'lane-shared/helpers/constants/icons';

import styles from './IconListView.scss';

type Props = {
  children: React.ReactNode;
  iconName: string;
  className?: any;
  style?: React.CSSProperties;
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
};

export default function IconListView({
  style,
  children,
  className,
  onClick,
  iconName,
}: Props) {
  return (
    <div
      className={cx(styles.IconListView, className)}
      style={style}
      role="button"
      tabIndex={0}
      onKeyPress={e => e.key === Key.Enter && onClick && onClick(e)}
      onClick={onClick}
      data-test={`iconListView-${iconName}`}
    >
      <div className={styles.iconWrapper}>
        <Icon
          name={iconName}
          set={ICON_SET_FONTAWESOME}
          type={FONT_AWESOME_REGULAR}
        />
      </div>
      <div className={styles.textWrapper}>{children}</div>
    </div>
  );
}
