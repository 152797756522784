import gql from 'graphql-tag';

export default gql`
  mutation CreateAppleWalletIssuanceToken(
    $channelId: UUID!
    $provider: String!
  ) {
    createAppleWalletIssuanceToken(channelId: $channelId, provider: $provider) {
      issuanceToken
    }
  }
`;
