import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export default class ChannelNotFoundError extends CodedError {
  static get errorName() {
    return errorCodes.channelNotFoundError.code;
  }

  constructor(message = errorCodes.channelNotFoundError.message) {
    super(message);
    this.code = errorCodes.channelNotFoundError.code;
    this.message = `${message} ${errorCodes.channelNotFoundError.code}`;
    this.name = errorCodes.channelNotFoundError.name;
    this.status = errorCodes.channelNotFoundError.status;
  }
}
