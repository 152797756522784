import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ChannelNotSetupError extends CodedError {
  static get errorName() {
    return errorCodes.channelNotSetupError.code;
  }

  constructor(message = errorCodes.channelNotSetupError.message) {
    super(message);
    this.code = errorCodes.channelNotSetupError.code;
    this.message = `${message} ${errorCodes.channelNotSetupError.code}`;
    this.name = errorCodes.channelNotSetupError.name;
  }
}
