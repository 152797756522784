import React, { useMemo } from 'react';

import { Icon } from 'design-system-web';

import Checkbox from 'components/form/Checkbox';

import Flex from '../../layout/Flex';
import {
  Column,
  Dir,
  UrlSearchParams,
  UrlSearchParamsWithPages,
} from './types';

import styles from './styles.scss';

type Props = {
  rowsAreSelectable: boolean;
  allSelected: boolean;
  handleRowSort: (key: string) => () => void;
  columns: Column[];
  urlSearchParams: UrlSearchParams | UrlSearchParamsWithPages;
  onToggleAllRowsSelection: () => void;
  actionsAvailable: boolean;
};

export default function TableHeader({
  rowsAreSelectable,
  allSelected,
  handleRowSort,
  onToggleAllRowsSelection,
  columns,
  urlSearchParams,
  actionsAvailable,
}: Props) {
  const ariaSort = useMemo(() => {
    if (urlSearchParams.dir === Dir.asc) return 'ascending';
    if (urlSearchParams.dir === Dir.desc) return 'descending';

    return 'none';
  }, [urlSearchParams.dir]);

  return (
    <thead>
      <tr>
        {rowsAreSelectable && (
          <th>
            <Checkbox
              value="header-checkbox"
              selected={allSelected}
              onClick={onToggleAllRowsSelection}
              className={styles.checkBox}
            />
          </th>
        )}
        {columns.map(column => (
          <th
            key={column.header}
            aria-sort={ariaSort}
            data-sort-key={column.key}
          >
            <Flex align="center" gap={2}>
              {column.header}
              {column.disableSort ? null : (
                <button
                  className={styles.sortButton}
                  data-test={`sort-btn-${column.key}`}
                  onClick={handleRowSort(column.key)}
                >
                  {urlSearchParams?.sortKey !== column.key && (
                    <Icon
                      set="FontAwesome"
                      name="sort"
                      type="far"
                      className={styles.disabledSortingIcon}
                    />
                  )}
                  {urlSearchParams?.sortKey === column.key && (
                    <Icon
                      set="FontAwesome"
                      name={ariaSort === 'ascending' ? 'sort-up' : 'sort-down'}
                      className={styles.selectedSortingIcon}
                    />
                  )}
                </button>
              )}
            </Flex>
          </th>
        ))}
        {actionsAvailable && <th>Actions</th>}
      </tr>
    </thead>
  );
}
