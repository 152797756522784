import React, { useContext } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import {
  PERMISSION_CONTENT_CREATE,
  PERMISSION_CONTENT_NOTICE_CREATE,
  PERMISSION_CONTENT_PAGE_CREATE,
  PERMISSION_CONTENT_POST_CREATE,
} from 'lane-shared/helpers/constants/permissions';

import Button from '../general/Button';

import styles from './SidebarItemCreateNew.scss';

export default function SidebarItemCreateNew({ channel, onClick }: any) {
  const { hasAnyPermission } = useContext(UserDataContext);
  const { t } = useTranslation();

  if (
    !channel ||
    !hasAnyPermission(
      [
        PERMISSION_CONTENT_CREATE,
        PERMISSION_CONTENT_POST_CREATE,
        PERMISSION_CONTENT_NOTICE_CREATE,
        PERMISSION_CONTENT_PAGE_CREATE,
      ],
      channel?._id
    )
  ) {
    return null;
  }

  return (
    <Button
      testId="newContent"
      className={styles.btnCreateNew}
      interfaceStyle="dark"
      variant="outlined"
      size="medium"
      color="tertiary"
      startIcon={<Icon name="plus" />}
      onClick={onClick}
    >
      {t('New content')}
    </Button>
  );
}
