import React from 'react';

import { useTranslation } from 'react-i18next';

import { getValidationMessages } from 'lane-shared/helpers';

import Input from 'components/form/Input';

export function InputGroupRoleName({
  groupRoleName,
  updateGroupRole,
  groupRoleValidation,
  isDisabled,
}: any) {
  const validationError = getValidationMessages(groupRoleValidation, 'name');

  const { t } = useTranslation();

  return (
    <Input
      placeholder={t(
        'web.components.lane.Team.Header.InputGroupRoleName.teamNamePlaceHolder'
      )}
      disabled={isDisabled}
      value={groupRoleName}
      error={validationError}
      onChange={name => updateGroupRole({ name })}
      testId="inputTeamName"
    />
  );
}
