import {
  ScheduleTypeEnum,
  VisitorFieldEnum,
  IntervalTypeEnum,
} from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import {
  FeatureDefinition,
  ContentTypeRequirementEnum,
} from '../../../types/FeatureDefinition';
import { Validator } from '../../../types/Validator';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import { VisitorManagementFeatureProperties } from '../../../types/features/VisitorManagementFeatureProperties';
import { createDefaultDateTime } from '../helpers';
import '../types/HostInformationType';
import '../types/NotesType';
import '../types/ScheduleType';
import '../types/VisitorType';
import {
  END_HOUR,
  scheduleInAdvanceOptions,
  START_HOUR,
} from './VisitorManagementFeatureValues';

export function isRequired(property: string) {
  return property === VisitorFieldEnum.Required;
}

export function isOptional(property: string) {
  return property === VisitorFieldEnum.Optional;
}

export function isHidden(property: string) {
  return property === VisitorFieldEnum.Hide;
}

const VisitorManagement: FeatureDefinition = {
  name: FeatureNameEnum.VisitorManagement,
  description: 'Create content to invite and manage visitors and their passes',
  superUserOnly: false,
  friendlyName: 'Visitor Management',
  requiresInteraction: true,
  properties: {
    _version: {
      friendlyName: 'Feature version',
      type: 'Number',
      editable: false,
      forDisplay: false,
      default: 1,
    },
    nameSetting: {
      name: 'nameSetting',
      friendlyName: 'First & last name',
      type: 'String',
      editable: true,
      default: VisitorFieldEnum.Required,
      validators: [],
      options: [
        {
          _id: VisitorFieldEnum.Required,
          text: 'Required',
        },
        {
          _id: VisitorFieldEnum.Optional,
          text: 'Optional',
          disabled: true,
        },
        {
          _id: VisitorFieldEnum.Hide,
          text: 'Hide',
          disabled: true,
        },
      ],
    },
    emailSetting: {
      name: 'emailSetting',
      friendlyName: 'Email address',
      type: 'String',
      editable: true,
      default: VisitorFieldEnum.Optional,
      validators: [],
      options: [
        {
          _id: VisitorFieldEnum.Required,
          text: 'Required',
        },
        {
          _id: VisitorFieldEnum.Optional,
          text: 'Optional',
        },
        {
          _id: VisitorFieldEnum.Hide,
          text: 'Hide',
        },
      ],
    },
    phoneSetting: {
      name: 'phoneSetting',
      friendlyName: 'Phone number',
      type: 'String',
      editable: true,
      default: VisitorFieldEnum.Optional,
      validators: [],
      options: [
        {
          _id: VisitorFieldEnum.Required,
          text: 'Required',
        },
        {
          _id: VisitorFieldEnum.Optional,
          text: 'Optional',
        },
        {
          _id: VisitorFieldEnum.Hide,
          text: 'Hide',
        },
      ],
    },
    companySetting: {
      name: 'companySetting',
      friendlyName: 'Company name',
      type: 'String',
      editable: true,
      default: VisitorFieldEnum.Optional,
      validators: [],
      options: [
        {
          _id: VisitorFieldEnum.Required,
          text: 'Required',
        },
        {
          _id: VisitorFieldEnum.Optional,
          text: 'Optional',
        },
        {
          _id: VisitorFieldEnum.Hide,
          text: 'Hide',
        },
      ],
    },
    allDayPassEnabled: {
      name: 'allDayPassEnabled',
      friendlyName: 'All-day pass',
      description: 'Allow visitors to have all-day access to the property.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
    bulkImportSetting: {
      name: 'bulkImportSetting',
      friendlyName: 'Bulk import',
      description: 'Allow importing multiple visitors at once.',
      type: 'Boolean',
      editable: true,
      default: true,
      validators: [],
      isArray: false,
    },
    dateRangeEnabled: {
      name: 'dateRangeEnabled',
      friendlyName: 'Date range',
      description: 'Allow visitor passes to be used within a date range.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
    customDatesEnabled: {
      name: 'customDatesEnabled',
      friendlyName: 'Custom dates',
      description:
        'Allow visitor passes to be used on multiple non-consecutive days.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
    recurringPassesEnabled: {
      name: 'recurringPassesEnabled',
      friendlyName: 'Recurring passes',
      description:
        'Allow visitor passes to be repeated for a set number of times or an end date.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
    multiSchedulesEnabled: {
      name: 'multiSchedulesEnabled',
      friendlyName: 'Multi-schedules',
      description: 'Allow multiple schedules to be added to a visitor pass.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
    scheduleInAdvanceAmount: {
      name: 'scheduleInAdvanceAmount',
      friendlyName: 'Schedule in advance',
      description: 'Define how far in advance a member can schedule a visit.',
      type: 'Number',
      default: 12,
      options: scheduleInAdvanceOptions,
    },
    hostSelectionEnabled: {
      name: 'hostSelectionEnabled',
      friendlyName: 'Host selection',
      description:
        'The host is who the visitor is here to see. Enable the selection below to allow assigning a different host, otherwise it will default to the user completing the form.',
      type: 'Boolean',
      editable: true,
      default: false, // TM-9990 disabled by default to prevent host selection feature flag bug. Revert once `space-invaders.host-selection` flag is gone.
      validators: [],
      isArray: false,
    },
    notesToVisitorEnabled: {
      name: 'notesToVisitorEnabled',
      friendlyName: 'Notes to Visitor',
      description: 'Allow notes to visitors.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
    notesToStaffEnabled: {
      name: 'notesToStaffEnabled',
      friendlyName: 'Notes to staff',
      description: 'Allow notes to staff.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
    additionalRecipientsEnabled: {
      name: 'additionalRecipientsEnabled',
      friendlyName: 'Additional recipients to notify',
      description: 'Allow Additional recipients to notify.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [],
      isArray: false,
    },
  },
  interactionData: {
    visitors: {
      friendlyName: 'Visitors',
      description:
        'The list of visitors that you would want to add to this interaction.',
      type: 'VisitorType',
      editable: true,
      isArray: true,
      default: [],
      validators: [],
    },
    schedule: {
      friendlyName: 'Schedule',
      description: 'The schedule for the visit.',
      type: 'ScheduleType',
      editable: true,
      isArray: false,
      default: {
        type: ScheduleTypeEnum.SpecificDate,
        startDate: createDefaultDateTime(START_HOUR, 0),
        endDate: createDefaultDateTime(END_HOUR, 0),
        isAllDay: true,
        intervalType: IntervalTypeEnum.EveryDay,
        endRepeatDate: createDefaultDateTime(END_HOUR, 0),
      },
      validators: [],
    },
    hostInformation: {
      friendlyName: 'Host information',
      description: 'Information about the Host for this interaction',
      type: 'HostInformationType',
      editable: true,
      isArray: false,
      forDisplay: true,
      showControl: true,
    },
    notes: {
      friendlyName: 'Notes and notifications',
      description:
        'Customize messages and other people to receive notifications',
      type: 'NotesType',
      editable: true,
      isArray: false,
      forDisplay: true,
      showControl: true,
    },
  },
  getInteractionData(featureInstance) {
    const featureProperties = featureInstance.feature as VisitorManagementFeatureProperties;

    const validator = [] as Validator<any>[];

    if (featureProperties.hostSelectionEnabled) {
      validator.push({
        name: 'Required',
        value: true,
      });
    }

    return {
      hostInformation: {
        friendlyName: 'Host',
        description: 'The host you would want to add to this interaction.',
        type: 'HostInformationType',
        editable: true,
        default: null,
        name: 'Host',
        validators: validator,
      },
    };
  },
  contentTypeRequirements: [
    {
      type: ContentTypeEnum.VisitorManagement,
      requirement: ContentTypeRequirementEnum.Required,
    },
    {
      type: ContentTypeEnum.Static,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Notice,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Content,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.ScheduledContent,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.ScheduledNotice,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Promotion,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Perk,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.WorkOrder,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
  ],
};

export default VisitorManagement;
