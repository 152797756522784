import gql from 'graphql-tag';

export default gql(`
  fragment FullWebhookFragment on Webhook {
    _id
    _created
    _updated
    _createdBy {
      ...PublicUserFragment
    }
    _updatedBy {
      ...PublicUserFragment
    }
    name
    description
    url
    status
    version
    events
    signingKey
    channel {
      _id
    }
  }    
`);
