import gql from 'graphql-tag';

export default gql`
  query getTenantBuildingIntegrationChannel($channelId: UUID!) {
    getTenantBuildingIntegrationChannel(channelId: $channelId) {
      _id
      name
    }
  }
`;
