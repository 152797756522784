import gql from 'graphql-tag';

export default gql`
  mutation updateMedia($media: MediaInput!) {
    updateMedia(media: $media) {
      media {
        _id
        file
        fileSignedUrl
        thumbnail
        thumbnailSignedUrl
      }
      originalExtension
    }
  }
`;
