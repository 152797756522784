import gql from 'graphql-tag';

import { MetatagValue } from 'lane-shared/types/Metatag';

import { MetatagFragment, PublicUserFragment } from '../fragments';
import { MetatagFragmentType } from '../fragments/MetatagFragment';
import { PublicUserFragmentType } from '../fragments/PublicUserFragment';

export type GetMetatagResponse = {
  metatag: MetatagFragmentType & {
    _createdBy: PublicUserFragmentType;
    _updatedBy: PublicUserFragmentType;
    values: Pick<MetatagValue, '_id' | '_created' | '_updated' | 'value'>[];
  };
};

export default gql`
  ${MetatagFragment}
  ${PublicUserFragment}

  query getMetatag($id: UUID!) {
    metatag(id: $id) {
      ...MetatagFragment
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      values {
        _id
        _created
        _updated
        value
      }
    }
  }
`;
