import { CodedError } from '../CodedError';
import errorCodes from '../constants/errorCodes';

export default class ChannelModulePramsError extends CodedError {
  static get errorName() {
    return errorCodes.channelModulePramsError.code;
  }

  constructor(message = errorCodes.channelModulePramsError.message) {
    super(message);
    this.code = errorCodes.channelModulePramsError.code;
    this.message = `${message} ${errorCodes.channelModulePramsError.code}`;
    this.name = errorCodes.channelModulePramsError.name;
    this.status = errorCodes.channelModulePramsError.status;
  }
}
