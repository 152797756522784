import gql from 'graphql-tag';

export default gql`
  query searchContentInteractions(
    $search: UserContentInteractionSearch
    $pagination: PaginationInput
  ) {
    me {
      contentInteractions(pagination: $pagination, search: $search) {
        items {
          _id
          _created
          _updated
          status
          startDate
          endDate
          geo
          data
          version
          contentData
          data
        }
        pageInfo {
          total
          start
          perPage
        }
      }
    }
  }
`;
