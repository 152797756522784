import { AssignAccessManagerModal } from '../../components/user-access/AssignAccessManagerModal';
import { ManageBulkACGsModal } from '../../components/user-access/ManageBulkACGsModal';
import { MobileAccessChipSelect } from '../../components/user-access/MobileAccessChipSelect';
import { MobileAccessModal } from '../../components/user-access/MobileAccessModal';
import { MobileAccessDisableModal } from '../../components/user-access/MobileAccessDisableModal';
import {
  UserAccessLocationPicker,
  Location,
} from '../../components/user-access/UserAccessLocationPicker';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { buildLocationData } from '../../helpers/buildLocationData';
import { TruncateTextComponent } from '../../helpers/truncateComponent/truncateTextComponent';
import { UserInfo, AccessControlGroup } from '../../types/AccessControl';
import styles from './styles.scss';
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import cx from 'classnames';
import { Loading, Table } from 'design-system-web';
import { TagCollection, TagListItem } from 'components/ads';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import { AdminPage, PageHeader } from 'components/layout';
import { XS, M } from 'components/typography';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useCompanySearch } from '../../../../pages/portal/admin/channel/users/hooks';
import {
  getChannelAccessControlGroups,
  getChannelsForAccessManager,
  enableMobileAccess,
} from 'lane-shared/graphql/accessControl';
import { getConsolidatedChannelUsers } from 'lane-shared/graphql/user';
import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';
import {
  PERMISSION_ADMIN,
  PERMISSION_ACCESS_CONTROL,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ASSIGN_ACCESS_MANAGER,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
} from 'lane-shared/helpers/constants/permissions';
import {
  AccessControlChipSelectEnum,
  AccessControlUserSyncStatusEnum,
  FiltersListType,
} from 'lane-shared/types/AccessControlType';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { UserDataContext } from 'lane-shared/contexts';
import { convertToUserAccessGroupSelectItem } from '../../helpers/convertToUserAccessGroupSelectItem';
import { hasPermission } from 'lane-shared/helpers';
import { RevokeAccessManagerModal } from '../../components/user-access/RevokeAccessManagerModal';
import useUserAccessFilters from '../../hooks/useUserAccessFilters';
import { formatAccessManagerRoles } from 'lane-web/src/domains/accessControl/helpers/formatAccessManagerRoles';
import { MultiIntegrationAcgs } from 'lane-web/src/domains/accessControl/types/AccessControl';
import {
  MobileAccessAction,
  useTrackMobileAccessEvents,
} from 'lane-web/src/domains/accessControl/analytics/mobile-access';
import { useTrackUserAccessGroupsEvents } from 'lane-web/src/domains/accessControl/analytics/user-access-groups';
import { formatAccessManagerRole } from 'lane-web/src/domains/accessControl/helpers/formatAccessManagerRole';

enum UserAccessTabs {
  users = 'users',
  accessAdmins = 'accessAdmins',
  requiresAttention = 'requiresAttention',
}

type UserInfoExtended = UserInfo & {
  userDetailsCombined: string;
};

const TAB_EMPTY_MESSAGES: { [key: string]: string } = {};
TAB_EMPTY_MESSAGES[UserAccessTabs.users] =
  'web.admin.accessControl.userAccess.table.users.emptyMessage';
TAB_EMPTY_MESSAGES[UserAccessTabs.accessAdmins] =
  'web.admin.accessControl.userAccess.table.accessAdmin.emptyMessage';
TAB_EMPTY_MESSAGES[UserAccessTabs.requiresAttention] =
  'web.admin.accessControl.userAccess.table.requiresAttention.emptyMessage';

function updateMobileAccess(
  data: UserInfo[],
  userInfo: UserInfo,
  value: boolean
) {
  const newData = data.map(user => {
    if (user._id === userInfo._id) {
      return { ...user, mobileAccess: value, syncStatus: userInfo.syncStatus };
    }
    return user;
  });
  return newData;
}

function updateMobileAccessBulk(data: UserInfo[], users: UserInfo[]) {
  return data.map(user => {
    const updatedUser = users.find(u => u._id === user._id);
    return updatedUser
      ? {
          ...user,
          syncStatus: AccessControlUserSyncStatusEnum.IN_PROGRESS, // stop gap for showing processing state
        }
      : user;
  });
}

export function UserAccessPage({
  channel,
  hasAnyPermission,
}: {
  channel: ChannelType;
  hasAnyPermission: (permissions: string[]) => boolean;
}) {
  const currentUserContext = useContext(UserDataContext);
  const { data: channelData } = useCompanySearch(channel?._id);
  const { t } = useTranslation();
  const {
    trackMobileAccessVisit,
    trackMobileAccessEditInitiated,
    trackMobileAccessEditFailed,
    trackMobileAccessEditCompleted,
  } = useTrackMobileAccessEvents();
  const {
    trackUserAccessGroupsEditInitiated,
  } = useTrackUserAccessGroupsEvents();
  const history = useHistory();
  const canDisplayCredentials = useFlag(
    FeatureFlag.AccessControlAccessCredentials,
    false
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<UserInfo[]>([]);
  const [accessAdmins, setAccessAdmins] = useState<UserInfo[]>([]);
  const [requiresAttention, setRequiresAttention] = useState<UserInfo[]>([]);
  const [accessControlGroups, setAccessControlGroups] = useState<
    Map<string, AccessControlGroup>
  >(new Map<string, AccessControlGroup>([]));
  const [modalStates, setModalStates] = useState({
    ManageBulkACGsModal: false,
    MobileAccessModal: false,
    MobileAccessDisableModal: false,
    AssignAccessManagerModal: false,
    RevokeAccessManagerModal: false,
    BulkMobileAccessModal: false,
    BulkMobileAccessDisableModal: false,
  });
  const [activeRow, setActiveRow] = useState<any | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<UserInfo[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected, setSelected] = useState<UserAccessTabs>(
    UserAccessTabs.users
  );
  const [allChannels, setAllChannels] = useState<Location[]>([]);
  const [accessManagerLocations, setAccessManagerLocations] = useState<
    { id: string; name: string }[]
  >([]);
  const [selectedChannelId, setSelectedChannelId] = useState<string>(
    channel?._id
  );
  const [currentUser, setCurrentUser] = useState<null | UserInfo>(null);
  const hasAccessManagerPermissions = hasAnyPermission([
    PERMISSION_ADMIN,
    PERMISSION_ACCESS_CONTROL,
  ]);
  const acgFilterLabelsRef = useRef<FiltersListType[]>([]);
  const channelFilterLabelsRef = useRef<FiltersListType[]>([]);
  const userAccessFilterResult = useUserAccessFilters(
    acgFilterLabelsRef.current,
    channelFilterLabelsRef.current
  );
  const isAccessAdminOrChannelAccessAdmin = (roles: string[] | undefined) => {
    if (
      roles?.length &&
      (roles.includes(SPECIAL_GROUP_ROLES.ACCESS_MANAGER) ||
        roles.includes(SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN))
    ) {
      return true;
    }
    return false;
  };

  const tabs: TabItem[] = [
    {
      value: 'users',
      label: 'web.admin.accessControl.userAccess.tab.users',
    },
    {
      value: 'accessAdmins',
      label: 'web.admin.accessControl.userAccess.tab.accessAdmins',
    },
    {
      value: 'requiresAttention',
      label: 'web.admin.accessControl.userAccess.tab.requiresAttention',
      alert:
        requiresAttention.length > 0 ? requiresAttention.length : undefined,
    },
  ];

  const hasSelectedChannelPermission = (permission: string) =>
    hasPermission(
      currentUserContext.user?.roles,
      [permission],
      selectedChannelId,
      true
    );

  const hasIntegrationChannelPermission = (integrationChannelId: string) =>
    selectedChannelId === integrationChannelId ||
    hasPermission(
      currentUserContext.user?.roles,
      [PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY],
      integrationChannelId,
      true
    );

  const getMobileAccessChipSelectPermissions = (row: UserInfo) => ({
    enableMobileAccess:
      hasSelectedChannelPermission(
        PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE
      ) && hasIntegrationChannelPermission(row.integrationChannelId),
    disableMobileAccess:
      hasSelectedChannelPermission(
        PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE
      ) && hasIntegrationChannelPermission(row.integrationChannelId),
  });

  const handleModalOpen = (modalName: string) => {
    if (
      modalName === 'BulkMobileAccessModal' &&
      selectedUsers.filter(
        user =>
          user &&
          !(
            user?.syncStatus === AccessControlUserSyncStatusEnum.SYNCED &&
            user?.mobileAccess
          )
      ).length === 0
    )
      return;

    if (
      modalName === 'BulkMobileAccessDisableModal' &&
      selectedUsers.filter(user => user && user?.mobileAccess).length === 0
    )
      return;

    setModalStates(prevState => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const setChannelFilterList = () => {
    if (
      channelData?.channelsByRelationship?.items &&
      channelData.channelsByRelationship.items.length
    ) {
      channelFilterLabelsRef.current = channelData.channelsByRelationship.items.map(
        (item: { channel: { name: string; _id: string } }) => {
          return {
            label: item.channel.name,
            value: item.channel._id,
          };
        }
      );
    }
  };

  const handleCloseModal = (modalName: string) => {
    setModalStates(prevState => ({
      ...prevState,
      [modalName]: false,
    }));
    setActiveRow(null);
  };
  const DEBOUNCE_DELAY = 5500;
  const fetchUpdatedUserAccessTable = async () => {
    try {
      setLoading(true);
      const newData: UserInfo[] = await fetchUsers(selectedChannelId);
      setData(newData);
    } catch (err) {
      console.error(
        JSON.stringify({
          message:
            'an error occured while fetching updated mobile access table',
          error: err,
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const { callback: debouncedFetch } = useDebouncedCallback(
    fetchUpdatedUserAccessTable,
    DEBOUNCE_DELAY
  );

  const handleMobileAccessSubmit = (userInfo: UserInfo, access: boolean) => {
    userInfo.syncStatus = AccessControlUserSyncStatusEnum.IN_PROGRESS; // stop gap for upadting mobile access
    const newData = updateMobileAccess(data, userInfo, access);
    setData(newData);
    debouncedFetch();
  };

  const handleEnableMobileAccessWithoutModal = async (user: UserInfo) => {
    if (user) {
      try {
        await getClient().mutate({
          mutation: enableMobileAccess,
          variables: {
            channelId: selectedChannelId,
            userClearances: [
              {
                userId: user._id,
                accessControlGroups: [],
              },
            ],
          },
        });
        window.Toast.show(
          t(
            'web.admin.accessControl.userAccess.modal.manageBulkACGs.saveChanges.enableMobileAccess.successToast',
            { userCount: 1 }
          )
        );
        trackMobileAccessEditCompleted({
          action: MobileAccessAction.ENABLE,
          accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
          attemptedChannelId: selectedChannelId,
        });
        handleMobileAccessSubmit(user, true);
      } catch (err) {
        trackMobileAccessEditFailed({
          action: MobileAccessAction.ENABLE,
          accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
          attemptedChannelId: selectedChannelId,
        });
        console.error(err);
      }
    }
    setActiveRow(null);
  };

  const handleChipSelectOnChange = (row: UserInfo) => (chipState: string) => {
    switch (chipState) {
      case AccessControlChipSelectEnum.ENABLED:
        handleModalOpen('MobileAccessModal');
        trackMobileAccessEditInitiated({
          action: MobileAccessAction.ENABLE,
          syncStatus: row.syncStatus,
          accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
          attemptedChannelId: selectedChannelId,
        });
        break;
      case AccessControlChipSelectEnum.DISABLED:
        handleModalOpen('MobileAccessDisableModal');
        trackMobileAccessEditInitiated({
          action: MobileAccessAction.DISABLE,
          syncStatus: row.syncStatus,
          accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
          attemptedChannelId: selectedChannelId,
        });
        break;
      default:
        break;
    }
    setActiveRow(row);
  };

  const fetchUsers = async (channelId: string) => {
    try {
      const { data } = await getClient().query({
        query: getConsolidatedChannelUsers,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });
      return data.getChannelUsers || [];
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAccessControlGroups = async (channelId: string) => {
    const acgMap = new Map<string, AccessControlGroup>([]);
    try {
      const { data } = await getClient().query({
        query: getChannelAccessControlGroups,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });

      acgFilterLabelsRef.current = [];
      data.getChannelAccessControlGroups.forEach((acg: AccessControlGroup) => {
        acgMap.set(acg.id, acg);
        acgFilterLabelsRef.current.push({
          label: acg.name,
          value: acg.id,
        });
      });
      return acgMap;
    } catch (err) {
      console.error(err);
    }
    return acgMap;
  };

  const fetchAllChannels = async (channelId: string) => {
    try {
      const { data } = await getClient().query({
        query: getChannelsForAccessManager,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });
      return buildLocationData(data.getChannelsForAccessManager, channel._id);
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  const getAccessManagerLocations = (locations: Location[]) => {
    return locations.flatMap(location => {
      const children = location.childLocations ?? [];
      return [
        { id: location.id, name: location.name },
        ...children.map(child => ({ id: child.id, name: child.name })),
      ];
    });
  };

  const fetchData = useCallback(async () => {
    if (channel?._id) {
      setLoading(true);
      const selectedChannel = selectedChannelId || channel._id;
      const users: UserInfo[] = await fetchUsers(selectedChannel);
      const acgMap: Map<
        string,
        AccessControlGroup
      > = await fetchAccessControlGroups(selectedChannel);

      if (hasAccessManagerPermissions) {
        const channels: Location[] = await fetchAllChannels(selectedChannel);
        setAccessManagerLocations(getAccessManagerLocations(channels));
        setAllChannels(channels);
      }

      setData(users);
      setAccessControlGroups(acgMap);
      setCurrentUser(
        users?.find(user => user?._id === currentUserContext.user?._id) || null
      );
      setLoading(false);
    }
  }, [selectedChannelId]);

  const goToEditCredentials = (userId: string) =>
    history.push(
      routes.channelAdminTeamMemberBuildingAccess
        .replace(':id', selectedChannelId)
        .replace(':userId', userId)
    );

  const goToManageUserAccess = (row: any) => {
    return routes.channelAdminManageUserAccess
      .replace(':id', selectedChannelId)
      .replace(':userId', row._id);
  };

  const handleMultiselectChange = (selectedRows: any) => {
    const selectedTableData = getTableData();
    const users = selectedRows.map((index: any) => selectedTableData[index]);
    setSelectedUsers(users);
    setSelectedRows(selectedRows);
  };

  const handleTabChange = (tab: UserAccessTabs) => {
    resetTableSelection();
    setSelected(tab);
  };

  const resetTableSelection = () => {
    setSelectedUsers([]);
    setSelectedRows([]);
  };

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      const admins = data.filter(
        user =>
          user.roles?.includes(SPECIAL_GROUP_ROLES.ACCESS_MANAGER) ||
          user.roles?.includes(SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN)
      );
      const attention = data.filter(
        user => user.syncStatus === AccessControlUserSyncStatusEnum.FAILED
      );
      setAccessAdmins(admins);
      setRequiresAttention(attention);
    }
    if (selectedRows.length > 0) {
      handleMultiselectChange(selectedRows);
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setSelectedChannelId(channel?._id);
  }, [channel?._id]);

  useEffect(() => {
    trackMobileAccessVisit();
  }, [trackMobileAccessVisit]);

  const columns = [
    {
      key: 'userDetailsCombined',
      header: t('web.admin.accessControl.userAccess.table.name'),
      renderCell: (cell: string, row: any) => {
        const url = goToManageUserAccess(row);
        const accessManagerRole = formatAccessManagerRoles(row.roles ?? []);
        return (
          <div style={{ display: 'grid' }}>
            {accessManagerRole && (
              <XS variant="secondary">{t(accessManagerRole)}</XS>
            )}
            <Link to={url}>
              <TruncateTextComponent text={row.name} maxLength={35} textWrap />
            </Link>
            <TruncateTextComponent text={row.email} maxLength={35} />
          </div>
        );
      },
      disableVisibilityToggle: true,
    },
    {
      key: 'companies',
      header: t('web.admin.accessControl.userAccess.table.company'),
      renderCell: (
        cell: {
          id: string;
          name: string;
        }[]
      ) => {
        return (
          <TruncateTextComponent
            text={cell.map(company => company.name).join(', ')}
            maxLength={20}
          />
        );
      },
    },
    {
      key: 'mobileAccess',
      header: t('web.admin.accessControl.userAccess.table.mobileAccess'),
      renderCell: (_: any, row: UserInfo) => (
        <MobileAccessChipSelect
          user={{ ...row }}
          onChange={handleChipSelectOnChange(row)}
          userPermissions={getMobileAccessChipSelectPermissions(row)}
        />
      ),
      disableVisibilityToggle: true,
    },
    {
      key: 'multiIntegrationAcgs',
      header: t('web.admin.accessControl.userAccess.table.accessControlGroups'),
      disableSorting: true,
      renderCell: (cell: MultiIntegrationAcgs[]) => {
        let acgDataCount: TagListItem[] = [];
        // Only show current channel Acgs
        const data = cell?.filter(
          acgData => acgData.channelId === convertToUUID(selectedChannelId)
        );
        const templates = (
          <div>
            {data?.map((acgData: any) => {
              const tags = acgData?.accessControlGroups?.map(
                (acgModel: { id: string; name: string }) => ({
                  label: acgModel?.name || '',
                  value: acgModel?.id,
                })
              );

              acgDataCount = acgDataCount.concat(tags);
              return (
                <ul
                  className={styles.UserAccessTableAccessControlTooltip}
                  key={acgData.channelId}
                >
                  {tags.map((tag: any) => (
                    <li key={tag.label}>
                      <span>{tag.label}</span>
                    </li>
                  ))}
                </ul>
              );
            })}
          </div>
        );

        const dash = '-';
        return acgDataCount?.length > 0 ? (
          <TagCollection
            tags={acgDataCount}
            displayAmount={0}
            size="sm"
            doTranslate={false}
            className={styles.UserAccessTableAccessControlGroups}
            tooltipPlacement="top"
            numberedTagLabel={t(
              'web.admin.accessControl.accessControlGroups.table.groups'
            )}
            customNumberedTagTooltip={templates}
          />
        ) : (
          <M variant="secondary">{dash}</M>
        );
      },
    },
  ];

  const tableBulkActions = [
    {
      label:
        'web.admin.accessControl.userAccess.table.bulkActions.enableMobileAccess',
      onClick: () => {
        handleModalOpen('BulkMobileAccessModal');
        trackMobileAccessEditInitiated({
          action: MobileAccessAction.ENABLE,
          accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
          attemptedChannelId: selectedChannelId,
        });
      },
    },
    {
      label:
        'web.admin.accessControl.userAccess.table.bulkActions.disableMobileAccess',
      onClick: () => {
        handleModalOpen('BulkMobileAccessDisableModal');
        trackMobileAccessEditInitiated({
          action: MobileAccessAction.DISABLE,
          accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
          attemptedChannelId: selectedChannelId,
        });
      },
    },
    {
      label:
        'web.admin.accessControl.userAccess.table.bulkActions.editAccessGroups',
      onClick: () => {
        handleModalOpen('ManageBulkACGsModal');
        trackUserAccessGroupsEditInitiated({
          attemptedChannelId: selectedChannelId,
          accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
        });
      },
    },
  ];

  const assignAccessManagerRowAction = {
    label: t(
      'web.admin.accessControl.userAccess.table.assignAccessManagerRowAction'
    ),
    onClick: (user: UserInfo) => {
      handleModalOpen('AssignAccessManagerModal');
      setActiveRow(user);
    },
    isHidden: (user: UserInfo) =>
      isAccessAdminOrChannelAccessAdmin(user?.roles),
  };

  const revokeAccessManagerRowAction = {
    label: t(
      'web.admin.accessControl.userAccess.table.denyAccessManagerRowAction'
    ),
    onClick: (user: UserInfo) => {
      setActiveRow(user);
      handleModalOpen('RevokeAccessManagerModal');
    },
    isHidden: (user: UserInfo) =>
      !isAccessAdminOrChannelAccessAdmin(user?.roles),
  };

  const resyncUserRowAction = {
    label: t('web.admin.accessControl.userAccess.table.resyncRowAction'),
    onClick: (user: UserInfo) => {
      trackMobileAccessEditInitiated({
        action: MobileAccessAction.ENABLE,
        syncStatus: user.syncStatus,
        accessRole: formatAccessManagerRole(currentUser?.roles ?? []),
        attemptedChannelId: selectedChannelId,
      });
      handleEnableMobileAccessWithoutModal(user);
    },
    isHidden: (user: UserInfo) =>
      user.syncStatus !== AccessControlUserSyncStatusEnum.FAILED,
  };

  const editCredentialsRowAction = {
    label: t(
      'web.admin.accessControl.userAccess.table.editCredentialsRowAction'
    ),
    onClick: (user: UserInfo) => goToEditCredentials(user._id),
  };

  const tableRowActions = [
    ...(hasSelectedChannelPermission(
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ASSIGN_ACCESS_MANAGER
    )
      ? [assignAccessManagerRowAction, revokeAccessManagerRowAction]
      : []),
    resyncUserRowAction,
    editCredentialsRowAction,
  ];

  const pageHeaderProps = {
    header: t('web.admin.accessControl.userAccess.table.mobileAccess'),
    breadcrumbs: [
      {
        label: t('web.admin.accessControl.header'),
        url: routes.channelAdminUserAccess.replace(':id', channel?._id),
      },
      {
        label: t('web.admin.accessControl.userAccess.table.mobileAccess'),
      },
    ],
  };

  const getTableData = (): UserInfoExtended[] => {
    const tableData: UserInfo[] = (() => {
      switch (selected) {
        case UserAccessTabs.users:
          return data || [];
        case UserAccessTabs.accessAdmins:
          return accessAdmins;
        case UserAccessTabs.requiresAttention:
          return requiresAttention;
        default:
          return [];
      }
    })();

    return tableData.map(row => ({
      ...row,
      userDetailsCombined: `${row.name} ${row.email} ${t(
        formatAccessManagerRoles(row.roles ?? [])
      )}`,
    }));
  };

  const handleManageBulkACGsModalClose = (userInfo: UserInfo[]) => {
    if (userInfo.length > 0) {
      const newData = updateMobileAccessBulk(data, userInfo);

      setData(newData);
      debouncedFetch();
    }
    handleCloseModal('ManageBulkACGsModal');
  };

  setChannelFilterList();
  const shouldShowLocationPicker =
    allChannels.reduce((total: number, location: Location) => {
      total++;

      const totalChildLocations = location?.childLocations?.length || 0;
      return total + totalChildLocations;
    }, 0) > 1;
  return (
    <AdminPage>
      <div className={styles.UserAccessHeader}>
        <PageHeader
          externalPadding={[0, 0]}
          {...pageHeaderProps}
          headerLevel="h3"
          description={t('web.admin.accessControl.userAccess.description')}
        />
      </div>
      <div className={cx(styles.UserAccessContentContainer)}>
        {!loading || allChannels.length ? (
          <div className={styles.UserAccessContentContainerRow}>
            {shouldShowLocationPicker && (
              <div className={cx(styles.UserAccessLocationPickerContainer)}>
                <UserAccessLocationPicker
                  locations={allChannels}
                  value={selectedChannelId}
                  onChange={channelId => {
                    resetTableSelection();
                    setSelectedChannelId(channelId);
                  }}
                />
              </div>
            )}
            <div className={cx(styles.UserAccessTableContainer)}>
              <TabStrip
                tabs={tabs}
                selected={{ value: selected }}
                onSelectTab={tab =>
                  handleTabChange(tab.value as UserAccessTabs)
                }
                className={styles.UserAccessTabStrip}
              />
              <div className={styles.UserAccessTable}>
                <Table<UserInfoExtended>
                  key={selectedChannelId}
                  isLoading={loading}
                  columns={
                    canDisplayCredentials
                      ? [
                          ...columns,
                          {
                            key: 'credentials',
                            header: t(
                              'web.admin.accessControl.userAccess.table.credentials'
                            ),
                            renderCell: (cell: UserInfo['credentials']) =>
                              cell && (
                                <M className={cx(styles.CredentialCell)}>
                                  {cell.join(', ')}
                                </M>
                              ),
                          },
                        ]
                      : columns
                  }
                  data={getTableData()}
                  filters={userAccessFilterResult}
                  pagination="client"
                  isSelectable
                  hasKeywordFilter
                  rowActions={tableRowActions}
                  emptyMessage={t(TAB_EMPTY_MESSAGES[selected])}
                  bulkActions={tableBulkActions}
                  rowSelection={selectedRows}
                  onSelectionChange={handleMultiselectChange}
                  showColumnVisibility
                  columnVisibility={{ companies: false }}
                />
              </div>
            </div>
          </div>
        ) : (
          <Loading
            testId="location-picker-spinner-icon"
            className={styles.loading}
          />
        )}
      </div>
      {modalStates.ManageBulkACGsModal && (
        <ManageBulkACGsModal
          channelId={selectedChannelId}
          isOpen={modalStates.ManageBulkACGsModal}
          users={selectedUsers}
          accessControlGroups={convertToUserAccessGroupSelectItem(
            Array.from(accessControlGroups.values()),
            currentUser!.accessControlGroupIds,
            currentUserContext.user?.roles || [],
            selectedChannelId,
            []
          )}
          onClose={handleManageBulkACGsModalClose}
        />
      )}
      {(modalStates.MobileAccessModal || modalStates.BulkMobileAccessModal) &&
        currentUser && (
          <MobileAccessModal
            currentUser={currentUser}
            channelId={selectedChannelId}
            isOpen={
              modalStates.MobileAccessModal || modalStates.BulkMobileAccessModal
            }
            users={
              modalStates.MobileAccessModal
                ? [activeRow]
                : selectedUsers.filter(
                    user =>
                      user &&
                      !(
                        user.syncStatus ===
                          AccessControlUserSyncStatusEnum.SYNCED &&
                        user.mobileAccess
                      )
                  )
            }
            onClose={() =>
              handleCloseModal(
                modalStates.MobileAccessModal
                  ? 'MobileAccessModal'
                  : 'BulkMobileAccessModal'
              )
            }
            onSubmit={(usersUpdated: UserInfo[]) => {
              if (usersUpdated.length > 0) {
                const newData = updateMobileAccessBulk(data, usersUpdated);
                setData(newData);
                debouncedFetch();
              }
            }}
            availableLocations={allChannels}
          />
        )}
      {(modalStates.MobileAccessDisableModal ||
        modalStates.BulkMobileAccessDisableModal) &&
        currentUser && (
          <MobileAccessDisableModal
            isOpen={
              modalStates.MobileAccessDisableModal ||
              modalStates.BulkMobileAccessDisableModal
            }
            onClose={() => {
              handleCloseModal(
                modalStates.MobileAccessDisableModal
                  ? 'MobileAccessDisableModal'
                  : 'BulkMobileAccessDisableModal'
              );
            }}
            users={
              modalStates.MobileAccessDisableModal
                ? [activeRow]
                : selectedUsers.filter(user => user && user.mobileAccess)
            }
            accessManagerLocations={accessManagerLocations}
            currentUser={currentUser}
            channelId={selectedChannelId}
            onSubmit={(usersUpdated: UserInfo[]) => {
              if (usersUpdated.length > 0) {
                const newData = updateMobileAccessBulk(data, usersUpdated);
                setData(newData);
                debouncedFetch();
              }
            }}
          />
        )}

      {modalStates.AssignAccessManagerModal && (
        <AssignAccessManagerModal
          channelId={selectedChannelId}
          isOpen={modalStates.AssignAccessManagerModal}
          user={activeRow}
          onClose={async (triggerRefresh: boolean = false) => {
            handleCloseModal('AssignAccessManagerModal');
            if (triggerRefresh) {
              await fetchUpdatedUserAccessTable();
            }
          }}
        />
      )}
      {modalStates.RevokeAccessManagerModal && (
        <RevokeAccessManagerModal
          channelId={selectedChannelId}
          isOpen={modalStates.RevokeAccessManagerModal}
          user={activeRow}
          onClose={async (triggerRefresh: boolean = false) => {
            handleCloseModal('RevokeAccessManagerModal');
            if (triggerRefresh) {
              await fetchUpdatedUserAccessTable();
            }
          }}
        />
      )}
    </AdminPage>
  );
}
