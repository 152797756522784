import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { PresetContentFilterLocation } from '../types/filters/PresetContentFilterLocation';
import useLocationOrPrimaryLocation from './location/useLocationOrPrimaryLocation';
import useChannelSelectorSections from './useChannelSelectorSections';

type Props = {
  channels?: any[];
  filter?: PresetContentFilterLocation;
  showAllLocations: boolean;
  onFilterUpdated: (filter: PresetContentFilterLocation) => void;
  allLocationsDefault?: boolean;
};

export const ALL_LOCATIONS = 'All locations';
export const ALL = 'all';

export default function useChannelLocationsSelector({
  channels = [],
  showAllLocations,
  onFilterUpdated,
  allLocationsDefault = false,
}: Props) {
  const { t } = useTranslation();
  const { location } = useLocationOrPrimaryLocation();

  const { sections } = useChannelSelectorSections({
    channels,
    location,
    showAllLocations,
  });

  const items = useMemo(() => {
    return sections.map(section => {
      if ((section as any).allLocations) {
        return {
          label: t(ALL_LOCATIONS),
          value: ALL,
          distance: 20000,
        };
      }
      return {
        label: (section as any).name,
        value: (section as any).name,
        distance: (section as any).distance,
      };
    });
  }, [channels, location]);

  function changeHandler(name: any) {
    const section = sections.find(
      section => (section as any).name === name
    ) as any;

    if (!section) {
      return;
    }

    const { geo } = section;

    const newLocationFilter: PresetContentFilterLocation = {
      displayName: name,
      id: name,
      location: geo,
      enabled: true,
      // magic numbers added by Clint, unable to find a reference as to why specifficaly these numbers, but tickets mentioned in PR are no longer accessible
      distance: 200000,
      minDistance: 0,
      maxDistance: 20000,
    };

    if (name === ALL) {
      newLocationFilter.displayName = ALL_LOCATIONS;
      newLocationFilter.id = ALL;
      newLocationFilter.location = null;
      newLocationFilter.enabled = false;
      newLocationFilter.distance = null;
      newLocationFilter.minDistance = null;
      newLocationFilter.maxDistance = null;
    }
    onFilterUpdated(newLocationFilter);
  }

  useEffect(() => {
    const ALL_LOCATIONS = 0;
    const CLOSEST_CITY_TO_USER = 1;
    const valueToShowBasedOnLocationEnabled = location.noLocation
      ? items[ALL_LOCATIONS]
      : items[CLOSEST_CITY_TO_USER];

    if (!allLocationsDefault) {
      changeHandler(valueToShowBasedOnLocationEnabled?.label);
    }
  }, [location.noLocation]);

  return {
    sections,
    items,
    changeHandler,
  };
}
