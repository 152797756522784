import errorCodes from '../../constants/errorCodes';
import { GoogleCalendarError } from './GoogleCalendarError';

const ERROR = errorCodes.googleCalendarChannelIntegrationSettingsNotFound!;

export class GoogleCalendarChannelIntegrationSettingsNotFound extends GoogleCalendarError {
  static get errorName() {
    return ERROR.code;
  }

  constructor(message?: string) {
    super(ERROR, message);
  }
}
