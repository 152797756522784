import gql from 'graphql-tag';

import { AddressFragment, ProfileFragment } from '../fragments';

export default gql`
  ${ProfileFragment}
  ${AddressFragment}

  query GroupRole($id: UUID!) {
    groupRole(_id: $id) {
      _id
      _created
      _updated
      _createdBy {
        _id
      }
      _updatedBy {
        _id
      }
      channel {
        _id
        name
        description
        slug
        address {
          ...AddressFragment
        }
        profile {
          ...ProfileFragment
        }
      }
      name
      settings
      isPublic
      isSystem
      requirements
      permissions
      maxUsers
    }
  }
`;
