import React, { useState, useEffect, useRef } from 'react';

import cx from 'classnames';

import { useStoredState } from 'lane-shared/hooks';

import styles from './ResizableWindow.scss';

const bars = [
  'top',
  'right',
  'bottom',
  'left',
  'topRight',
  'topLeft',
  'bottomRight',
  'bottomLeft',
];

const [
  BAR_TOP,
  BAR_RIGHT,
  BAR_BOTTOM,
  BAR_LEFT,
  BAR_TOP_RIGHT,
  BAR_TOP_LEFT,
  BAR_BOTTOM_RIGHT,
  BAR_BOTTOM_LEFT,
] = bars;

type Props = {
  name: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  defaultPosition?: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  minWidth?: number;
  lockWidth?: boolean;
  minHeight?: number;
  lockHeight?: boolean;
  autoHeight?: boolean;
  showHeader?: boolean;
  onDrag?: () => void;
  onResize?: () => void;
  onBarClick?: () => void;
  onBarDoubleClick?: () => void;
  onClose?: () => void;
  contentContainerClassName?: string;
  TopMenuComponent?: React.ReactNode;
  BottomMenuComponent?: React.ReactNode;
};

function centerPosition(props: { width?: number } = {}) {
  const height = window.innerHeight * 0.5;
  const width = props.width || window.innerWidth * 0.66;
  const top = window.innerHeight * 0.25;
  const left = (window.innerWidth - width) / 2;

  return {
    top,
    left,
    width,
    height,
  };
}

const NOOP = () => undefined;

export default function ResizableWindow({
  name,
  children,
  className,
  style,
  lockWidth,
  lockHeight,
  contentContainerClassName,
  TopMenuComponent,
  BottomMenuComponent,
  showHeader = false,
  minWidth = 200,
  minHeight = 200,
  autoHeight = false,
  defaultPosition = centerPosition(),
  onClose = NOOP,
  onDrag = NOOP,
  onResize = NOOP,
  onBarClick = NOOP,
  onBarDoubleClick = NOOP,
}: Props) {
  function enforceDimensions(newDimensions: any) {
    const enforced = {
      ...newDimensions,
    };

    // width is too small
    if (enforced.width < minWidth) {
      enforced.width = minWidth;
    }

    // height is too short
    if (enforced.height < minHeight) {
      enforced.height = minHeight;
    }

    // window is too wide.
    if (enforced.width > window.innerWidth) {
      enforced.width = window.innerWidth;
    }

    // window is too tall
    if (enforced.height > window.innerHeight * 0.8) {
      enforced.height = window.innerHeight * 0.8;
    }

    // top positioning has this off screen
    if (enforced.top + enforced.height / 2 > window.innerHeight) {
      enforced.top = window.innerHeight - enforced.height / 2;
    } else if (enforced.top < 0) {
      // top positioning is off top of screen
      enforced.top = 0;
    }

    // left positioning is off left of screen
    if (enforced.left < 0) {
      enforced.left = 0;
    } else if (enforced.left + enforced.width / 2 > window.innerWidth) {
      // left positioning is too far left (i.e. window is pushed off screen.)
      enforced.left = window.innerWidth - enforced.width / 2;
    }

    return enforced;
  }

  const [hasUserSetDimensions, setHasUserSetDimensions] = useState(false);
  const childrenRef = useRef(null);
  const [dimensions, setDimensions, isReady] = useStoredState(
    `ResizableWindow${name}`,
    defaultPosition
  );

  function updateHeight() {
    if (autoHeight && childrenRef.current && !hasUserSetDimensions) {
      let height = 0;

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      childrenRef.current.childNodes.forEach(
        (node: any) => (height += node.scrollHeight)
      );

      setDimensions({
        ...dimensions,
        height,
      });
    }
  }

  const [dragging, setDragging] = useState(null);

  useEffect(() => {
    // if we are using the auto height feature, get the height of the children
    // and set the window to that height.
    if (autoHeight) {
      setTimeout(updateHeight, 50);
    }
  }, [children]);

  useEffect(() => {
    setTimeout(updateHeight, 500);
  }, [children]);

  useEffect(() => {
    function resize(e: any) {
      let newDimensions = {
        ...dimensions,
      };

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const dx = e.clientX - dragging.x;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const dy = e.clientY - dragging.y;

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      switch (dragging.type) {
        case 'top':
          newDimensions = {
            ...newDimensions,
            top: dimensions.top + dy,
            left: dimensions.left + dx,
          };
          break;
        case 'left':
          newDimensions = {
            ...newDimensions,
            left: dimensions.left + dx,
            width: dimensions.width - dx,
          };
          break;
        case 'right':
          newDimensions = {
            ...newDimensions,
            width: dimensions.width + dx,
          };
          break;
        case 'bottom':
          newDimensions = {
            ...newDimensions,
            height: dimensions.height + dy,
          };
          break;
        case 'topLeft':
          newDimensions = {
            ...newDimensions,
            left: dimensions.left + dx,
            width: dimensions.width - dx,
            top: dimensions.top + dy,
            height: dimensions.height - dy,
          };
          break;
        case 'topRight':
          newDimensions = {
            ...newDimensions,
            width: dimensions.width + dx,
            top: dimensions.top + dy,
            height: dimensions.height - dy,
          };
          break;
        case 'bottomRight':
          newDimensions = {
            ...newDimensions,
            width: dimensions.width + dx,
            height: dimensions.height + dy,
          };
          break;
        case 'bottomLeft':
          newDimensions = {
            ...newDimensions,
            left: dimensions.left + dx,
            width: dimensions.width - dx,
            height: dimensions.height + dy,
          };
          break;
        default:
          break;
      }

      setHasUserSetDimensions(true);
      setDimensions(enforceDimensions(newDimensions));

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (dragging.type === 'top') {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        onDrag(newDimensions);
      } else {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        onResize(newDimensions);
      }
    }

    function stopDragging() {
      window.removeEventListener('mousemove', resize);
      setDragging(null);

      setTimeout(() => {
        (window as any)._isDragging = false;
      }, 50);
    }

    if (dragging) {
      (window as any)._isDragging = true;
      // kind of stupid to set this, but on click events fire for other
      // components if the mouse down started on place, and finished on another
      // ie. our modal listeners. :|
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopDragging);
    }

    return () => {
      window.removeEventListener('mousemove', resize);
    };
  }, [dragging, children]);

  function startDragging(e: any, type: any) {
    if (dragging) {
      return;
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ x: any; y: any; type: any; }' ... Remove this comment to see the full error message
    setDragging({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY, type });
    e.stopPropagation();
    e.preventDefault();
  }

  const availableBars = bars.filter(bar => {
    if (lockWidth) {
      return ![
        BAR_LEFT,
        BAR_RIGHT,
        BAR_TOP_LEFT,
        BAR_TOP_RIGHT,
        BAR_BOTTOM_LEFT,
        BAR_BOTTOM_RIGHT,
      ].includes(bar);
    }

    if (lockHeight) {
      return ![
        BAR_TOP,
        BAR_BOTTOM,
        BAR_BOTTOM_RIGHT,
        BAR_BOTTOM_LEFT,
        BAR_TOP_RIGHT,
        BAR_TOP_LEFT,
      ].includes(bar);
    }

    return true;
  });

  return (
    <div
      className={cx(styles.ResizableWindow, className)}
      style={{ ...style, top: dimensions.top, left: dimensions.left }}
      data-ready={isReady}
      ref={childrenRef}
      data-name={name}
      data-test="resizableWindow"
    >
      {TopMenuComponent}
      <div
        className={cx(styles.wrapper, contentContainerClassName)}
        style={{
          maxHeight: dimensions.height,
          width: dimensions.width,
        }}
      >
        {children}
      </div>
      {BottomMenuComponent}
      {availableBars.map(bar => (
        <div
          className={styles[bar]}
          key={bar}
          role="presentation"
          data-show-header={showHeader}
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
          onClick={e => onBarClick(e, bar)}
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
          onDoubleClick={e => onBarDoubleClick(e, bar)}
          onMouseDown={e => startDragging(e, bar)}
        >
          {bar === BAR_TOP && showHeader && (
            <button onClick={onClose} aria-label="Close" />
          )}
        </div>
      ))}
    </div>
  );
}

ResizableWindow.mostlyFullScreen = () => {
  const height = window.innerHeight * 0.7;
  const width = window.innerWidth * 0.7;
  const top = window.innerHeight * 0.1;
  const left = window.innerWidth * 0.15;

  return {
    top,
    left,
    width,
    height,
  };
};

ResizableWindow.fullScreen = () => {
  const height = window.innerHeight * 0.8;
  const width = window.innerWidth * 0.9;
  const top = window.innerHeight * 0.05;
  const left = window.innerWidth * 0.05;

  return {
    top,
    left,
    width,
    height,
  };
};

ResizableWindow.centerPosition = () => {
  return centerPosition();
};
