import React from 'react';

import cx from 'classnames';
import Color from 'color';
import { useTranslation } from 'react-i18next';

import { defaultPalette } from 'lane-shared/helpers/content/defaultTheme';
import IconBaseType from 'lane-shared/properties/baseTypes/Icon';
import { ThemeType } from 'lane-shared/types/Theme';
import { IconType } from 'lane-shared/types/baseTypes/IconType';

import ThemePaletteColorSelectorButton from 'components/lane/ThemePaletteColorSelectorButton';

import ColorPickerButton from '../form/ColorPickerButton';
import IconPicker from '../form/IconPicker';
import Slider from '../form/Slider';

import styles from './IconProperty.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  value: IconType | undefined | null;
  theme?: ThemeType;
  onChange: (icon: IconType | null) => void;
};

export default function IconProperty({
  className,
  style,
  value,
  theme,
  onChange,
}: Props) {
  const iconValue = value || IconBaseType.default;
  const { t } = useTranslation();

  function changeSize(size: any) {
    onChange({
      ...iconValue,
      size,
    });
  }

  function changeColor(color: any) {
    if (theme) {
      const defaultColor = new Color(theme.palette?.secondary).hex();
      const newColor = new Color(color).hex();
      if (defaultColor === newColor) {
        return onChange({
          ...iconValue,
          color: null,
        });
      }
    }
    return onChange({
      ...iconValue,
      color,
    });
  }

  return (
    <div className={cx(styles.Icon, className)} style={style}>
      <IconPicker
        palette={theme?.palette || defaultPalette}
        size={iconValue.size}
        color={iconValue.color}
        icon={iconValue.name}
        iconSet={iconValue.set}
        iconWeight={iconValue.iconWeight}
        onChange={({ icon, iconSet, iconWeight }: any) =>
          onChange({ ...iconValue, name: icon, set: iconSet, iconWeight })
        }
      />

      <br />
      <label>{t('web.admin.content.metatag.useMetatagEdit.icon.size')}</label>
      <Slider
        className={styles.size}
        max={100}
        min={5}
        value={iconValue.size || 12}
        onChange={changeSize}
      />

      <label>{t('web.admin.content.metatag.useMetatagEdit.icon.color')}</label>
      {theme ? (
        <ThemePaletteColorSelectorButton
          value={iconValue.color}
          defaultValue={theme.palette?.secondary}
          palette={theme.palette || defaultPalette}
          onColorSelected={changeColor}
        />
      ) : (
        <ColorPickerButton value={iconValue.color} onChange={changeColor} />
      )}
    </div>
  );
}
