import gql from 'graphql-tag';

export default gql`
  mutation CreateAppleWatchIssuanceToken(
    $channelId: UUID!
    $provider: String!
  ) {
    createAppleWatchIssuanceToken(channelId: $channelId, provider: $provider) {
      issuanceToken
    }
  }
`;
