import styles from './ContentWorkflowDetails.scss';
import ContentWorkflowEdit from './ContentWorkflowEdit';
import {
  actionLabels,
  eventLabels,
  prefixLabels,
  targetLabels,
  whenContextLabels,
  whenLabels,
} from './workflowLabels';
import cx from 'classnames';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import Pill from 'components/general/Pill';
import ResizableWindow from 'components/general/ResizableWindow';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';
import UserSelectorButton from 'components/lane/UserSelectorButton';
import { H5, M } from 'components/typography';
import WebhookSelectorButton from 'components/webhooks/WebhookSelectorButton';
import useTimeUnits from 'components/workflows/useTimeUnits';
import { ServiceRequestStatusEnum } from 'lane-web/src/domains/workOrder/serviceRequest/components/types/serviceRequestStatus';
import {
  EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION,
} from 'lane-shared/helpers/constants/events';
import { explodeFeatures } from 'lane-shared/helpers/features';
import useDataForSelectUserType from 'lane-shared/hooks/useDataForSelectUserType';
import { ContentWorkflowType } from 'lane-shared/types/ContentWorkflowType';
import {
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenEnum,
} from 'lane-shared/types/Workflows';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { cloneDeep } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
  channel: any;
  timeZone: string;
  workflow: ContentWorkflowType;
  onWorkflowCloned: (id: string) => void;
  onWorkflowUpdated: (id: string, update: Partial<ContentWorkflowType>) => void;
  onWorkflowDeleted: (id: string) => void;
};

export default function ContentWorkflowDetails({
  className,
  style,
  content,
  channel,
  workflow,
  timeZone,
  onWorkflowCloned,
  onWorkflowDeleted,
  onWorkflowUpdated,
}: Props) {
  const { qrCodeCheckinFeature } = explodeFeatures(content?.features);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { selectUserTypeList } = useDataForSelectUserType({
    content,
  });
  const _oldWorkflow = useMemo(() => {
    return cloneDeep(workflow);
  }, [isOpen]);

  function onCancel() {
    for (const workflowField of Object.keys(workflow)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (workflow[workflowField] && !_oldWorkflow[workflowField]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        delete workflow[workflowField];
      }
    }
    onWorkflowUpdated(workflow._id, _oldWorkflow);
    setIsOpen(false);
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ when: WorkflowWhenEnum; time: ... Remove this comment to see the full error message
  const { timeUnit, timeValue } = useTimeUnits({
    when: workflow.when,
    time: workflow.time,
  });
  const hasCustomText = workflow.payload?.text != null;

  const targetSelectUserType =
    selectUserTypeList.length &&
    selectUserTypeList.find((el: any) => el?._id === workflow?.dataField?._id);

  return (
    <div className={cx(styles.ContentWorkflowDetails, className)} style={style}>
      {workflow.name && <H5 mb={4}>{workflow.name}</H5>}
      <div className={styles.wrapper}>
        {prefixLabels[workflow.whenContext] && (
          <M>{t(prefixLabels[workflow.whenContext])}</M>
        )}
        {[WorkflowWhenEnum.After, WorkflowWhenEnum.Before].includes(
          workflow.when
        ) && (
          <M>
            {timeValue} {timeUnit}
          </M>
        )}
        {workflow.event !== EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION && (
          <M>{t(whenLabels[workflow.when])}</M>
        )}
        <M>{t(whenContextLabels[workflow.whenContext])}</M>
        {workflow.event !== EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION && (
          <M>{t(eventLabels[workflow.event])}</M>
        )}

        {workflow.event === EVENT_CONTENT_INTERACTION_STATUSCHANGE && (
          <>
            <M bold>{workflow.workflow?.statusFrom}</M>
            <M>{t`web.admin.channel.content.workflow.transition`}</M>
            <M bold>{workflow.workflow?.statusTo}</M>
          </>
        )}

        {workflow.event === EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION && (
          <M>
            {workflow.workflow?.value === ServiceRequestStatusEnum.Created
              ? ServiceRequestStatusEnum.Open
              : workflow.workflow?.value}
          </M>
        )}

        {workflow.event &&
          workflow.event !== EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION && (
            <>
              <M>{t(actionLabels[workflow.action])}</M>

              {workflow.action === WorkflowActionEnum.Webhook && (
                <>
                  <M>{t`web.admin.channel.content.workflow.transition`}</M>
                  {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ className: any; webhookId: any; onWebhookS... Remove this comment to see the full error message */}
                  <WebhookSelectorButton
                    className={styles.button}
                    webhookId={(workflow.webhook as any)?._id}
                    onWebhookSelected={() => null}
                    disabled
                  />
                </>
              )}
              {[
                WorkflowActionEnum.SMS,
                WorkflowActionEnum.Email,
                WorkflowActionEnum.Notification,
              ].includes(workflow.action) &&
                content.type !== ContentTypeEnum.WorkOrder && (
                  <>
                    <M>{t`web.admin.channel.content.workflow.transition`}</M>
                    <M>
                      {targetSelectUserType?.name ||
                        t(targetLabels[workflow.target])}
                    </M>
                    {workflow.target === WorkflowTargetEnum.User && (
                      <UserSelectorButton
                        className={styles.button}
                        userId={workflow.user && (workflow.user as any)._id}
                        channelId={content.channel._id}
                        onUserSelected={() => null}
                        disabled
                      />
                    )}
                    {workflow.target === WorkflowTargetEnum.GroupRole && (
                      <GroupRoleSelector
                        channelId={content.channel._id}
                        className={styles.button}
                        groupRoleId={
                          typeof workflow.groupRole === 'string'
                            ? workflow.groupRole
                            : workflow.groupRole?._id
                        }
                        includeWorkplaceMember={false}
                        onGroupRoleSelected={() => null}
                        showChannelSelector={false}
                        disabled
                      />
                    )}
                  </>
                )}

              {(workflow.action === WorkflowActionEnum.SMS ||
                workflow.action === WorkflowActionEnum.Notification) &&
                hasCustomText && (
                  <>
                    <M>
                      {t`web.admin.channel.content.workflow.custom-action.using`}{' '}
                    </M>
                    <M bold>
                      {t`web.admin.channel.content.workflow.custom-action.notification`}
                    </M>
                  </>
                )}

              {workflow.action === WorkflowActionEnum.Email && hasCustomText && (
                <>
                  <M>
                    {t`web.admin.channel.content.workflow.custom-action.using`}{' '}
                  </M>
                  <M bold>
                    {t`web.admin.channel.content.workflow.custom-action.email-subject`}
                  </M>
                </>
              )}
              {workflow.action === WorkflowActionEnum.Email &&
                (workflow.payload?.intro ||
                  workflow.payload?.outtro ||
                  workflow.payload?.body) && (
                  <>
                    <M>
                      {t`web.admin.channel.content.workflow.custom-action.with`}{' '}
                    </M>
                    <M bold>
                      {t`web.admin.channel.content.workflow.custom-action.email`}
                    </M>
                  </>
                )}
              {workflow.payload?.qrCodeCheckIn && qrCodeCheckinFeature && (
                <M>{t`web.admin.channel.content.workflow.custom-action.qr-check`}</M>
              )}
            </>
          )}
      </div>
      <div className={styles.pills}>
        {(workflow?.dataValidationSchema?.length || 0) > 0 ? (
          <Pill
            text={t`web.admin.channel.content.workflow.conditional-rules`}
            color="gray"
          />
        ) : null}
      </div>

      <ControlMenu className={styles.menu} mt={4} mb={0}>
        <hr />
        <Button
          testId="editWorkflowButton"
          onClick={() => setIsOpen(true)}
          size="small"
          variant="contained"
        >
          {t`web.admin.channel.content.workflow.edit`}
        </Button>
      </ControlMenu>

      {isOpen && (
        <ModalBackground
          onClose={onCancel}
          isOpen={isOpen}
          className={styles.background}
        >
          <ResizableWindow
            showHeader
            name="ContentSelectorButton"
            className={styles.window}
            onClose={onCancel}
            defaultPosition={ResizableWindow.fullScreen()}
          >
            <ContentWorkflowEdit
              content={content}
              channel={channel}
              timeZone={timeZone}
              className={styles.workflowDetails}
              workflow={workflow}
              onWorkflowCloned={onWorkflowCloned}
              onWorkflowUpdated={onWorkflowUpdated}
              onWorkflowDeleted={onWorkflowDeleted}
              onClose={() => setIsOpen(false)}
            />
          </ResizableWindow>
        </ModalBackground>
      )}
    </div>
  );
}
