/* eslint-disable react/forbid-component-props */ // Allows passing component instances as props
/* eslint-disable @nx/enforce-module-boundaries */ // Permits importing from modules outside enforced boundaries

import React, { useState } from 'react';
import cx from 'classnames';
import { routes } from 'lane-shared/config';
import { BreadCrumbs } from 'components/lds';
import { useIsAdminView } from 'hooks';
import {
  H3,
  H5,
  H4,
  S,
  Input,
  TextArea,
  DatePickerButton,
  TimePicker,
  TimeIntervals,
  Dropdown,
  Button,
} from 'design-system-web';
import MultiselectField from 'components/form/MultiselectField';
import { WeeklyInterval } from '../../../components/WeeklyInterval/WeeklyInterval';
import {
  MonthlyInterval,
  MonthIntervalTypeEnum,
} from '../../../components/MonthlyInterval/MonthlyInterval';
import {
  IntervalTypeEnum,
  repeatDropdownItems,
} from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { PinInput } from '../../../components/PinInput/PinInput';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChannelType } from 'lane-shared/types/ChannelType';

import styles from './styles.scss';

export type PinCodeItemType = {
  pin: string | null;
  assignTo: string;
  notes: string;
  moveInDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  repeats: string;
  intervalCount: number;
  weekdayRepeats: number[];
  monthIntervalType: MonthIntervalTypeEnum;
  endRepeatDate: Date | null;
  kioskType: string[];
};

export function HardwareAddPinCodes({ channel }: { channel: ChannelType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [, channelSlug] = useIsAdminView();
  const [pinData, setPinData] = useState<PinCodeItemType>({
    pin: null,
    assignTo: '',
    notes: '',
    moveInDate: null,
    startTime: null,
    endTime: null,
    repeats: '',
    intervalCount: 1,
    weekdayRepeats: [],
    monthIntervalType: MonthIntervalTypeEnum.DayOne,
    endRepeatDate: null,
    kioskType: [],
  });

  let timezone = 'UTC'; // default
  if (channel?.address) {
    timezone = getTimeZoneByGeoLocation({
      latitude: channel?.address?.geo[1],
      longitude: channel?.address?.geo[0],
    });
  }

  const handleOnChange = (value: any, key: keyof PinCodeItemType) => {
    setPinData(prevState => {
      if (prevState[key] !== value) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  function onCancel() {
    history.push(routes.channelVMPinCodes.replace(':id', channel?.slug));
  }

  return (
    <div className={cx(styles.CreatePinCode)}>
      <BreadCrumbs
        links={[
          {
            label: t(
              'web.admin.hardware.management.hardware.pin.breadcrumb.viewPin'
            ),
            url: routes.channelVMPinCodes.replace(':id', channelSlug || ''),
          },
          {
            label: t(
              'web.admin.hardware.management.hardware.pin.breadcrumb.createPin'
            ),
          },
        ]}
      />
      <div className={styles.createPinCodeTitle}>
        <H3 mt={4} mb={4}>
          {t('web.admin.hardware.management.hardware.pin.form.title')}
        </H3>
      </div>
      <PinInput
        autoFocus
        isNumberInput
        length={6}
        onPinChange={value => handleOnChange(value, 'pin')}
        pinGenerateButton
        label={t('web.admin.hardware.management.hardware.pin.form.input.label')}
        helper={t(
          'web.admin.hardware.management.hardware.pin.form.input.helper'
        )}
      />
      <div className={styles.inputBox}>
        <H5>
          {t('web.admin.hardware.management.hardware.pin.form.assignTo.label')}
        </H5>
        <Input
          fieldName="Assign to"
          value={pinData.assignTo}
          fixedLabel
          testId="assignToInput"
          onChange={value => handleOnChange(value, 'assignTo')}
        />
        <span className={styles.helperText}>
          {t('web.admin.hardware.management.hardware.pin.form.assignTo.helper')}
        </span>
      </div>
      <div className={styles.notes}>
        <H5>
          {t('web.admin.hardware.management.hardware.pin.form.notes.label')}
        </H5>
        <TextArea
          className={styles.textArea}
          onChange={value => handleOnChange(value, 'notes')}
          value={pinData.notes}
          maxLength={200}
          testId="notesToPinInput"
          showLengthIndicator
          containerClassName={styles.textAreaContainer}
          helperText={t(
            'web.admin.hardware.management.hardware.pin.form.notes.helper'
          )}
        />
      </div>
      <H4 mt={5}>
        {t('web.admin.hardware.management.hardware.pin.form.validity')}
      </H4>
      <div className={styles.dateOptions}>
        <div className={styles.dateContainer}>
          <div className={styles.date}>
            <S>
              {t('web.admin.hardware.management.hardware.pin.form.startDate')}
            </S>
            <DatePickerButton
              value={pinData.moveInDate}
              className={styles.datePicker}
              buttonClassName={styles.datePickerButton}
              onChange={value => handleOnChange(value, 'moveInDate')}
              hideLabel
              placeholder="Select date"
              minDate={new Date()}
              timeZone={timezone}
              disabled={false}
            />
          </div>
        </div>
        <div className={styles.timesContainer}>
          <div className={styles.times}>
            <div className={styles.group}>
              <S>
                {t('web.admin.hardware.management.hardware.pin.form.startTime')}
              </S>
              <TimePicker
                hideLabel
                className={styles.timePicker}
                buttonClassName={styles.timePickerButton}
                value={pinData.startTime}
                onChange={value => handleOnChange(value, 'startTime')}
                showClear={false}
                showIcon
                timeZone={timezone}
                unit={TimeIntervals.QUARTER_HOUR}
                testId="startTime"
                disabled={false}
              />
            </div>
            <div className={styles.group}>
              <S>
                {t('web.admin.hardware.management.hardware.pin.form.endTime')}
              </S>
              <TimePicker
                hideLabel
                className={styles.timePicker}
                buttonClassName={styles.timePickerButton}
                value={pinData.endTime}
                onChange={value => handleOnChange(value, 'endTime')}
                showClear={false}
                showIcon
                timeZone={timezone}
                unit={TimeIntervals.QUARTER_HOUR}
                testId="endTime"
                disabled={false}
                fixedLabel={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dropdownContainer}>
        <S>{t('web.admin.hardware.management.hardware.pin.form.repeats')}</S>
        <Dropdown
          onValueChange={value => handleOnChange(value, 'repeats')}
          items={repeatDropdownItems.map(item => ({
            label: t(item.label),
            value: item.value,
          }))}
          isSearchable={false}
          className={styles.dropdown}
          rightIconClassName={styles.dropdownIcon}
          value={pinData.repeats}
          isClearable
          testId="RepeatInterval"
        />
      </div>
      {pinData.repeats === IntervalTypeEnum.Weekly && (
        <WeeklyInterval
          defaultIntervalCount={pinData.intervalCount}
          defaultWeekdayRepeats={pinData.weekdayRepeats}
          onChange={({ intervalCount, weekdayRepeats }) => {
            handleOnChange(intervalCount, 'intervalCount');
            handleOnChange(weekdayRepeats, 'weekdayRepeats');
          }}
        />
      )}
      {pinData.repeats === IntervalTypeEnum.Monthly && (
        <MonthlyInterval
          defaultIntervalCount={pinData.intervalCount}
          defaultMonthIntervalType={MonthIntervalTypeEnum.DayOne}
          onChange={value => {
            handleOnChange(value.intervalCount, 'intervalCount');
            handleOnChange(value.monthIntervalType, 'monthIntervalType');
          }}
        />
      )}
      <div className={styles.dateOptions}>
        <div className={styles.dateContainer}>
          <div className={styles.date}>
            <S>
              {t('web.admin.hardware.management.hardware.pin.form.endDate')}
            </S>
            <DatePickerButton
              value={pinData.endRepeatDate}
              className={styles.datePicker}
              buttonClassName={styles.datePickerButton}
              onChange={value => handleOnChange(value, 'endRepeatDate')}
              hideLabel
              wrapperClassName={styles.specificDateInput}
              placeholder="Select date"
              disabled={false}
              minDate={new Date()}
              timeZone={timezone}
            />
          </div>
        </div>
      </div>
      <S mt={4}>
        {t('web.admin.hardware.management.hardware.pin.form.kioskType')}
      </S>
      <MultiselectField
        items={[
          {
            label: t(
              'web.admin.hardware.management.hardware.pin.form.kioskType.option1.label'
            ),
            value: 'kiosk-type-option',
          },
          {
            label: t(
              'web.admin.hardware.management.hardware.pin.form.kioskType.option2.label'
            ),
            value: 'intercom',
          },
          {
            label: t(
              'web.admin.hardware.management.hardware.pin.form.kioskType.option3.label'
            ),
            value: 'package-room-entry',
          },
        ]}
        value={pinData.kioskType}
        className={styles.multiselect}
        rightIconClassName={styles.multiselectIcon}
        onChange={value => handleOnChange(value, 'kioskType')}
      />
      <div className={styles.buttonContainer}>
        <div className={styles.button}>
          <Button size="large" onClick={() => {}}>
            {t('web.admin.hardware.management.hardware.pin.form.createButton')}
          </Button>
        </div>
        <div className={styles.button}>
          <Button size="large" variant="secondary" onClick={() => onCancel()}>
            {t('web.admin.hardware.management.hardware.pin.form.cancelButton')}
          </Button>
        </div>
      </div>
    </div>
  );
}
