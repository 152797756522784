import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getAllEquipment($channelId: UUID!) {
    getAllEquipment(channelId: $channelId) {
      id
      name
      category
      location
      status
      notes
      attachments
      createdBy
      createdAt
      updatedAt
      updatedBy
      channelId
      make
      model
      asset
      serial
      floor
      suite
      installDate
      warrantyExpirationDate
      hyperlinks {
        name
        url
      }
      meterReadingSettings {
        id
        unit
        name
        enableNotification
        notificationTrigger
        upperBound
        lowerBound
        notificationTypes
        equipmentId
      }
    }
  }
`);
