import React, { useState, useMemo, useEffect } from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import validateSelectedDateRange from 'lane-shared/helpers/integrations/ShopifyBurst/validateSelectedBurstDateRange';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { DateRangePicker } from 'components/form/DatePickers';

import styles from './BurstDateSelector.scss';

interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

interface Props {
  timeZone: string;
  startDate?: string;
  endDate?: string;
  onChange: (dateRange: DateRange) => void;
  unavailableDateRanges: DateRangeType[];
  minDate: Date;
  maxDate: Date;
  minRangeSize: number;
  maxRangeSize: number;
  isCalendarOpened?: boolean;
}

export default function BurstDateSelector({
  timeZone,
  startDate,
  endDate,
  minDate,
  maxDate,
  minRangeSize,
  maxRangeSize,
  onChange,
  unavailableDateRanges,
  isCalendarOpened,
}: Props) {
  const { t } = useTranslation();
  const [interval, setInterval] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const isSelectedDateRangeValid = useMemo(
    () =>
      validateSelectedDateRange({
        reservation: {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          startDate,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          endDate,
        },
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateRange' is not assignable to type 'DateRa... Remove this comment to see the full error message
        dateRange: interval,
        unavailableDatesAsDateRanges: unavailableDateRanges,
        timeZone,
      }),
    [startDate, endDate, interval, unavailableDateRanges, timeZone]
  );

  function handleClear() {
    setInterval({
      startDate: minDate?.toISOString(),
      endDate: null,
    });
    onChange({
      startDate: minDate?.toISOString(),
      endDate: null,
    });
  }

  function handleChange(dateRange: { startDate?: string; endDate?: string }) {
    setInterval({
      startDate: dateRange.startDate || startDate || minDate?.toISOString(),
      endDate: dateRange.endDate || endDate || null,
    });
  }

  function handleSave() {
    onChange(interval);
  }

  useEffect(() => {
    setInterval({
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      startDate,
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      endDate,
    });
  }, [startDate, endDate]);

  return (
    <div className={styles.BurstAvailableDateFilter}>
      <DateRangePicker
        weekdayOnly
        timeZone={timeZone}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
        startDate={
          interval.startDate ? new Date(interval.startDate) : undefined
        }
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
        endDate={interval.endDate ? new Date(interval.endDate) : undefined}
        minRangeSize={minRangeSize}
        maxRangeSize={maxRangeSize}
        unavailableDateRanges={unavailableDateRanges}
        maxDate={maxDate}
        minDate={minDate}
        className={styles.DateFilter}
        onChange={handleChange}
        dropdownPosition="absolute"
        hasToResetDateRange={isCalendarOpened}
      />
      <hr className={styles.line} />
      <div className={styles.actionButtons}>
        <Button onClick={handleClear}>{t('Clear')}</Button>
        <Button
          disabled={!isSelectedDateRangeValid}
          variant="contained"
          onClick={handleSave}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
}
