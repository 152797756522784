import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class UserGroupRoleExistsError extends CodedError {
  static get errorName() {
    return errorCodes.userGroupRoleExistsError.code;
  }

  constructor(message = errorCodes.userGroupRoleExistsError.message) {
    super(message);
    this.code = errorCodes.userGroupRoleExistsError.code;
    this.message = `${message} ${errorCodes.userGroupRoleExistsError.code}`;
    this.name = errorCodes.userGroupRoleExistsError.name;
  }
}
