import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class InUseError extends CodedError {
  static get errorName() {
    return errorCodes.inUseError.code;
  }

  constructor(message = errorCodes.inUseError.message) {
    super(message);
    this.code = errorCodes.inUseError.code;
    this.message = `${message} ${errorCodes.inUseError.code}`;
    this.name = errorCodes.inUseError.name;
  }
}
