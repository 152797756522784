import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export class ContentFeatureGuestInviteError extends CodedError {
  static get errorName() {
    return errorCodes.contentFeatureGuestInviteError.code;
  }

  constructor(message = errorCodes.contentFeatureGuestInviteError.message) {
    super(message);
    this.code = errorCodes.contentFeatureGuestInviteError.code;
    this.message = `${message} ${errorCodes.contentFeatureGuestInviteError.code}`;
    this.name = errorCodes.contentFeatureGuestInviteError.name;
  }
}
