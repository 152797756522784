import { useMemo } from 'react';
import { getTags } from 'lane-shared/graphql/content';
import { useQuery } from '@apollo/client';

type MultiFilterType = { label: string; value: string };

interface Props {
  channelId: string;
}

export const useTags = ({ channelId }: Props) => {
  const { data: tagData } = useQuery(getTags, {
    variables: {
      channelId,
    },
    skip: false,
  });

  const tags = useMemo(() => {
    if (!tagData?.tags) {
      return [];
    }

    return tagData.tags
      .filter((item: { name: string }) => item.name)
      .map((item: { name: string }) => ({ label: item.name, value: item.name }))
      .sort((a: MultiFilterType, b: MultiFilterType) =>
        a.label.localeCompare(b.label)
      );
  }, [tagData?.tags]);

  return {
    tags,
  };
};
