import gql from 'graphql-tag';

export type CreateFloorMutationResponse = {
  createFloor: string;
};

export const createFloor = gql`
  mutation CreateFloor($propertyId: UUID!, $floor: CreateFloorInput!) {
    createFloor(propertyId: $propertyId, floor: $floor)
  }
`;
