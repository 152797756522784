import gql from 'graphql-tag';

export const getCurrentAuth0Connection = gql`
  mutation GetCurrentAuth0Connection($email: String!) {
    getCurrentAuth0Connection(email: $email) {
      connection
      connectionId
      domainAlias
    }
  }
`;
