import gql from 'graphql-tag';

import {
  PublicProfileType,
  PublicUserGroupRoleType,
  PublicUserType,
} from '../../types/User';

export type PublicUserFragmentType = Pick<
  PublicUserType,
  '_id' | '_created' | 'name' | 'online' | 'lastSeen'
> & {
  role: Pick<
    PublicUserGroupRoleType,
    '_id' | 'name' | 'description' | 'groupRoleName'
  >;
  profile: Pick<
    PublicProfileType,
    '_id' | 'name' | 'phone' | 'email' | 'logo' | 'image'
  >;
};

export default gql(`
  fragment PublicUserFragment on PublicUser {
    _id
    _created
    name
    online
    lastSeen

    role {
      _id
      name
      description
      groupRoleName
    }

    profile {
      _id
      name
      phone
      email
      logo
      image
    }
  }
`);
