import React, { createContext } from 'react';

import { ValidationError } from 'yup';

import { AttachmentPreview } from 'lane-shared/types/attachment';

import { ThemeType } from '../types/Theme';
import { UserContentInteractionType } from '../types/UserContentInteraction';
import { ContentType } from '../types/content/Content';
import { SearchOptions } from '../types/filters/SearchOptions';

export type ContentRendererBlockContextType = {
  sectionSearch?: SearchOptions;
  isVerificationEmailSent?: boolean;
};

export type ContentRendererContextType = {
  scrollRef: React.Ref<React.ReactNode>;
  loading: boolean;
  disabled: boolean;
  submitAttempted: boolean;
  editMode: boolean;
  previewLanguage?: string;
  dataValidation: ValidationError;
  content: ContentType;
  interaction: UserContentInteractionType;
  blockContext: ContentRendererBlockContextType;
  updateBlockContext: (
    update: Partial<ContentRendererBlockContextType>
  ) => void;
  theme: ThemeType;
  onSubmit: () => Promise<void>;
  onLink: (link: any) => Promise<void>;
  onClick: (e: any) => void;
  onInteractionUpdated: (update: Partial<UserContentInteractionType>) => void;
  preventSubmission: boolean;
  hideSubmissionBlocks: boolean;
  submissionCompletedAt?: Date;
  draftAttachmentsCreate?: AttachmentPreview[];
  updateAttachmentsUCICreate?: (data: AttachmentPreview[]) => {};
};

const ContentRendererContext = createContext({} as ContentRendererContextType);

export default ContentRendererContext;
