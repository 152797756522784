import { DateTime } from 'luxon';

/**
 * Checks if the day time of dateOne is greater than dateTwo
 * @param dateOne
 * @param dateTwo
 * @param timeZone - optional
 */
export function isEarlierTime(dateOne: Date, dateTwo: Date, timeZone?: string) {
  const targetOffset = DateTime.fromObject({ zone: timeZone }).offset;
  const offset = targetOffset * 60 * 1000;
  const utcDateOne = DateTime.fromMillis(dateOne.getTime() + offset, {
    zone: 'UTC',
  });
  const utcDateTwo = DateTime.fromMillis(dateTwo.getTime() + offset, {
    zone: 'UTC',
  });

  return dayTimeToMs(utcDateOne) > dayTimeToMs(utcDateTwo);
}

const dayTimeToMs = (date: DateTime) => {
  return ((date.hour * 60 + date.minute) * 60 + date.second) * 1000;
};
