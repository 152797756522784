import React, { useState } from 'react';

import cx from 'classnames';
import { Input } from 'components';

import { PaymentCurrencyEnum } from 'lane-shared/types/payment/PaymentCurrencyEnum';

import { P } from 'components/typography';

import styles from './SimpleCurrencyInput.scss';

type Props = {
  onChange: (value: any, fieldname: string) => void;
  currency: PaymentCurrencyEnum;
  value?: number | null;
  fieldName?: string;
  label?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  showClear?: boolean;
  style?: React.CSSProperties;
  className?: string;
  testId?: string;
};

export default function SimpleCurrencyInput({
  onChange,
  currency,
  value,
  fieldName,
  label,
  placeholder,
  min,
  max,
  showClear,
  style,
  className,
  testId,
}: Props) {
  const [displayValue, setDisplayValue] = useState(value?.toFixed(2));

  const handleOnChange = (value: any, fieldname: string) => {
    if (value === '') {
      onChange(null, fieldname);
    } else {
      onChange(Number(value), fieldname);
    }
    setDisplayValue(value);
  };

  return (
    <div
      className={cx(styles.simpleCurrencyInput, className)}
      style={style}
      data-test={testId}
    >
      <Input
        onChange={handleOnChange}
        value={displayValue}
        label={label}
        fieldName={fieldName}
        placeholder={placeholder}
        min={min}
        max={max}
        showClear={showClear}
        type="number"
      />
      <P className={styles.currency}>{currency}</P>
    </div>
  );
}
