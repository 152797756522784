import gql from 'graphql-tag';

export default gql`
  query userChannelSettingsForMultipleChannels(
    $userId: UUID!
    $channelIds: [UUID]
  ) {
    userChannelSettingsForMultipleChannels(
      userId: $userId
      channelIds: $channelIds
    ) {
      id
      userId
      channelId
      language
    }
  }
`;
