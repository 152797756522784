import Feather from './iconSets/Feather.json';
import FontAwesome from './iconSets/FontAwesome5Pro.json';
import Ion from './iconSets/Ion.json';
import Material from './iconSets/Material.json';

// Font names need to be cross platform, so these names are pulled
// from the matching version of react-native-vector-icons.

const ICON_VALID_NAMES = {
  Feather,
  Ion,
  Material,
  FontAwesome,
};

const ICON_SETS = [
  'Ion',
  'Material',
  'Feather',
  'FontAwesome',
  'Remix',
] as const;

const [
  ICON_SET_ION,
  ICON_SET_MATERIAL,
  ICON_SET_FEATHER,
  ICON_SET_FONTAWESOME,
  ICON_SET_REMIX,
] = ICON_SETS;

export type IconSet = typeof ICON_SETS[number];

const FONT_AWESOME_TYPES = ['fal', 'far', 'fab', 'fas', 'fad'] as const;

export type FontAwesomeType = typeof FONT_AWESOME_TYPES[number];

const [
  FONT_AWESOME_LIGHT,
  FONT_AWESOME_REGULAR,
  FONT_AWESOME_BRAND,
  FONT_AWESOME_SOLID,
  FONT_AWESOME_DUOTONE,
] = FONT_AWESOME_TYPES;

export {
  ICON_VALID_NAMES,
  ICON_SETS,
  ICON_SET_FEATHER,
  ICON_SET_ION,
  ICON_SET_MATERIAL,
  ICON_SET_FONTAWESOME,
  ICON_SET_REMIX,
  FONT_AWESOME_LIGHT,
  FONT_AWESOME_REGULAR,
  FONT_AWESOME_BRAND,
  FONT_AWESOME_SOLID,
  FONT_AWESOME_DUOTONE,
};
