import ExtendableError from 'es6-error';

import { ErrorCodeType } from './constants/errorCodes';

export abstract class CodedError extends ExtendableError {
  code!: ErrorCodeType['code'];

  status: ErrorCodeType['status'];

  metadata: Record<string, any> | undefined;

  static errorName: ErrorCodeType['code'];

  constructor(message: string) {
    super(message);
    this.metadata = undefined;
  }

  setMetadata(metadata: Record<string, any>) {
    this.metadata = metadata;
  }
}
