import React from 'react';

import cx from 'classnames';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'rheo... Remove this comment to see the full error message
import Rheostat from 'rheostat';

import 'rheostat/initialize';

import styles from './MultiSlider.scss';

type OwnProps = {
  onChange?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
  onSliderDragEnd?: (...args: any[]) => any;
  onSliderDragMove?: (...args: any[]) => any;
  onSliderDragStart?: (...args: any[]) => any;
  onValuesUpdated?: (...args: any[]) => any;
  formatLabel?: (...args: any[]) => any;
  getNextHandlePosition?: (...args: any[]) => any;
  pitComponent?: ((...args: any[]) => any) | string;
  pitPoints?: number[];
  values: number[];
  snap?: boolean;
  snapPoints?: number[];
  disabled?: boolean;
  max?: number;
  min?: number;
  className?: string;
};

type Props = OwnProps;

function MultiSlider({
  onChange,
  onClick,
  onKeyPress,
  onSliderDragEnd,
  onSliderDragMove,
  onSliderDragStart,
  onValuesUpdated,
  formatLabel = (value: any) => value,
  getNextHandlePosition,
  pitComponent,
  pitPoints,
  snap = true,
  snapPoints,
  disabled = false,
  values,
  max = 100,
  min = 0,
  className,
}: Props) {
  return (
    <div className={cx(styles.MultiSlider, className)}>
      <div className={styles.slider}>
        <Rheostat
          onChange={onChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onSliderDragEnd={onSliderDragEnd}
          onSliderDragMove={onSliderDragMove}
          onSliderDragStart={onSliderDragStart}
          onValuesUpdated={onValuesUpdated}
          getNextHandlePosition={getNextHandlePosition}
          max={max}
          min={min}
          pitComponent={pitComponent}
          pitPoints={pitPoints}
          snap={snap}
          snapPoints={snapPoints}
          disabled={disabled}
          values={values}
        />
      </div>
      <div className={styles.values}>
        <h2>{formatLabel(values[0])}</h2>
        <hr />
        <h2>{formatLabel(values[1])}</h2>
      </div>
    </div>
  );
}

export default MultiSlider;
