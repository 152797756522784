import gql from 'graphql-tag';

import { PublicUserFragment, ContentFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ContentFragment}

  query getChannelContent($id: UUID!, $search: ContentSearch) {
    channel(_id: $id) {
      _id
      description
      slug
      type

      content(search: $search) {
        ...ContentFragment
      }
    }
  }
`;
