import { GeoCoordinateType } from '../../types/baseTypes/GeoTypes';
import calculateDistance from '../distance';

type DistanceRange = {
  maxDistance: number;
  minDistance: number;
};

const DEFAULT_MAX = 1000 * 1000; // 200 km

export default function getDistanceRange(
  content:
    | {
        geo: GeoCoordinateType;
      }[]
    | undefined
    | null,
  location: GeoCoordinateType
): DistanceRange {
  if (!content || !(content.length > 0)) {
    return {
      maxDistance: DEFAULT_MAX,
      minDistance: 0,
    };
  }

  return content.reduce(
    ({ minDistance, maxDistance }: DistanceRange, content) => {
      const distance = calculateDistance(
        location[1],
        location[0],
        content.geo[1],
        content.geo[0]
      );

      return {
        minDistance: Math.min(minDistance, distance),
        maxDistance: Math.max(maxDistance, distance),
      };
    },
    {
      minDistance: 1000 * 1000, // 1000 km,
      maxDistance: 0,
    }
  );
}
