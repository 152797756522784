import React, { ReactElement, KeyboardEvent, MouseEvent } from 'react';

import { TextButton } from '../TextButton/TextButton';
import { Popup, PopupChildrenRenderProps } from './Popup';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './style.scss';

type clickHandler = (event: MouseEvent | KeyboardEvent) => void;

export type MenuItemType = {
  label: string;
  onSelect?: clickHandler;
  isDisabled?: boolean;
  isHidden?: boolean;
  tooltipText?: string;
  icon?: string;
  variant?: 'primary' | 'secondary' | 'critical';
};

type handleSelect = (
  close: () => void,
  callbackHandler?: clickHandler
) => clickHandler;

export type PopupMenuProps = {
  /** Element that anchors the menu */
  trigger: ReactElement | ((props: Record<string, unknown>) => ReactElement);
  /** Menu items */
  items: MenuItemType[];
  /** Z-index of the menu */
  zIndex?: number;
  placement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'bottom-start'
    | 'bottom-end';
};

export const PopupMenu = ({
  trigger,
  items,
  zIndex = 3,
  placement,
}: PopupMenuProps) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const handleSelect: handleSelect = (close, onSelect) => e => {
    close();
    if (onSelect) {
      onSelect(e);
    }
  };
  const visibleItems = items.filter(({ isHidden }) => !isHidden);

  const buildMenuItem = (
    {
      onSelect,
      label,
      isDisabled,
      tooltipText,
      icon,
      variant = 'secondary',
    }: MenuItemType,
    index: number,
    { getItemProps, listRef, close }: PopupChildrenRenderProps
  ) => {
    const textButton = (
      <TextButton
        onClick={handleSelect(close, onSelect)}
        variant={variant}
        ref={node => {
          listRef.current[index] = node;
        }}
        iconName={icon}
        disabled={isDisabled}
      >
        {label}
      </TextButton>
    );

    const textButtonWithTooltip = (
      <Tooltip placement="top" TooltipComponent={tooltipText}>
        <TextButton
          variant="secondary"
          ref={node => {
            listRef.current[index] = node;
          }}
          iconName={icon || 'info-circle'}
          disabled={isDisabled}
        >
          {label}
        </TextButton>
      </Tooltip>
    );

    return (
      <li
        key={label}
        role="menuitem"
        className={styles.menuListItem}
        {...getItemProps()}
      >
        {tooltipText ? textButtonWithTooltip : textButton}
      </li>
    );
  };

  return (
    <Popup
      trigger={trigger}
      zIndex={zIndex}
      listNavigation
      placement={placement}
    >
      {popUpProps => (
        <ul role="menu" className={styles.menuList}>
          {visibleItems.map((item, index) =>
            buildMenuItem(item, index, popUpProps)
          )}
        </ul>
      )}
    </Popup>
  );
};
