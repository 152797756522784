import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import styles from './ToggleView.scss';

type ToggleViewProps = {
  title: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  testId?: string;
  defaultExpanded?: boolean;
  collapsedViewContent?: React.ReactNode;
};

export default function ToggleView({
  title,
  className,
  style,
  children,
  testId,
  defaultExpanded = false,
  collapsedViewContent,
}: ToggleViewProps) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div className={cx(styles.ToggleView, className)} style={style}>
      <div className={styles.wrapper}>
        <button
          data-test={testId}
          className={styles.toggle}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span>{title}</span>
          <Icon
            className={styles.icon}
            name={isExpanded ? 'chevron-up' : 'chevron-down'}
          />
        </button>
      </div>
      {!isExpanded && collapsedViewContent}
      {isExpanded && children}
    </div>
  );
}
