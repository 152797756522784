import gql from 'graphql-tag';

export default gql`
  query discoverUserIntegrations($channelIntegrationId: UUID!) {
    discoverUserIntegrations(channelIntegrationId: $channelIntegrationId) {
      _id
      key
    }
  }
`;
