import styles from './ContentWorkflowEdit.scss';
import cx from 'classnames';
import {
  Button as LegacyButton,
  Checkbox,
  ErrorMessage,
  ModalBackground,
  MultiselectField,
} from 'components';
import Dropdown from 'components/form/Dropdown';
import { Button, Input } from 'design-system-web';
import ControlMenu from 'components/general/ControlMenu';
import ToggleView from 'components/general/ToggleView';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';
import UserSelectorButton from 'components/lane/UserSelectorButton';
import { AlertType } from 'components/lds/Alert';
import EmailBuilder from 'components/renderers/EmailBuilder';
import { EmailBuilderV2 } from './EmailBuilder';
import getWorkflowWarnings from 'components/renderers/helpers/getWorkflowWarnings';
import { H4 } from 'components/typography';
import WebhookSelectorButton from 'components/webhooks/WebhookSelectorButton';
import ContentWorkflowStatusesEdit from 'components/workflows/ContentWorkflowStatusesEdit';
import ContentWorkflowTextArea from 'components/workflows/ContentWorkflowTextArea';
import DataValidationSchema from 'components/workflows/dataValidationSchema/DataValidationSchema';
import createWorkflowTargetOptionValue from 'components/workflows/helpers/createWorkflowTargetOptionValue';
import useWorkflowActionOptions from 'components/workflows/useWorkflowActionOptions';
import useWorkflowEventOptions from 'components/workflows/useWorkflowEventOptions';
import useWorkflowTargetOptions from 'components/workflows/useWorkflowTargetOptions';
import useWorkflowWhenOptions, {
  getDefaultWhenContext,
} from 'components/workflows/useWorkflowWhenOptions';
import useTimeUnits, {
  TimeUnitEnum,
  getDefaultTime,
} from 'components/workflows/useTimeUnits';
import { ServiceRequestStatusEnum } from 'lane-web/src/domains/workOrder/serviceRequest/components/types/serviceRequestStatus';
import { getLibraryOptions, toSchema } from 'lane-shared/helpers';
import { EVENT_CONTENT_INTERACTION_STATUSCHANGE } from 'lane-shared/helpers/constants/events';
import {
  INTERACTION_STATES,
  INTERACTION_OPEN_STATES,
} from 'lane-shared/helpers/constants/interactions';
import { constructDefaultWorkflowData } from 'lane-shared/helpers/content/constructWorkflow';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { useFlag } from 'lane-shared/hooks';
import useDataForSelectUserType from 'lane-shared/hooks/useDataForSelectUserType';
import {
  ContentWorkflowEventType,
  ContentWorkflowType,
} from 'lane-shared/types/ContentWorkflowType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import {
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  WorkflowWhenEnum,
} from 'lane-shared/types/Workflows';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { isEqual } from 'lodash';
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { UserDataContext } from 'lane-shared/contexts';
import { useTranslation } from 'react-i18next';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { ChannelDataType } from 'lane-shared/types/server/WorkflowTypes';
import { prefixLabels } from './workflowLabels';
import convertToUUID from 'lane-shared/helpers/convertId/convertToUUID';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: any;
  channel: ChannelType;
  timeZone: string;
  workflow: ContentWorkflowType;
  onClose: () => void;
  onWorkflowCloned: (id: string) => void;
  onWorkflowUpdated: (id: string, update: Partial<ContentWorkflowType>) => void;
  onWorkflowDeleted: (id: string) => void;
};

function ContentWorkflowEdit({
  className,
  style,
  content,
  channel,
  workflow,
  timeZone,
  onClose,
  onWorkflowCloned,
  onWorkflowDeleted,
  onWorkflowUpdated,
}: Props) {
  const { selectUserTypeList } = useDataForSelectUserType({ content });

  const { t } = useTranslation();
  const ANY_STATUSES = 'Any statuses';
  const OPEN_STATUSES = 'Open statuses';
  const CUSTOM_STATUSES = 'Custom statuses';
  const [workflowStatusState, setWorkflowStatusState] = useState(OPEN_STATUSES);
  const libraries = useMemo(() => getLibraryOptions({ channel }), [channel]);
  const { user } = useContext(UserDataContext);

  const statuses = INTERACTION_STATES.map(toSchema);
  const isNewWorkflowsUIEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportNewWorkflowsUI,
    false
  );

  const [
    isEmailBuilderV2ModalOpen,
    setIsEmailBuilderV2ModalOpen,
  ] = useState<boolean>(false);

  const {
    cancelableFeature,
    reservableFeature,
    statusesFeature,
    guestInviteFeature,
    qrCodeCheckinFeature,
  } = explodeFeatures(content?.features);

  const {
    timeUnit,
    timeValue,
    timeOptions,
    timeUnitOptions,
    updateTimeUnit,
    updateTimeUnitValue,
  } = useTimeUnits({
    when: workflow.when,
    time: workflow.time,
    onTimeUpdated: time => onWorkflowUpdated(workflow._id, { time }),
  });

  if (content.type === ContentTypeEnum.WorkOrder) {
    const indexOfMonth = timeUnitOptions.findIndex(
      timeUnitOption => timeUnitOption.value === TimeUnitEnum.Months
    );
    if (indexOfMonth !== -1) {
      timeUnitOptions.splice(indexOfMonth, 1);
    }
  }

  const { whenOptions, whenContextOptions } = useWorkflowWhenOptions({
    when: workflow.when,
    contentType: content.type,
    reservableFeature,
  });

  const targetOptions = useWorkflowTargetOptions({
    guestInviteFeature,
    event: workflow?.event,
    selectUserTypeList,
  });

  const eventOptions = useWorkflowEventOptions({
    when: workflow.when,
    whenContext: workflow.whenContext,
    cancelableFeature,
    statusesFeature,
  });

  const actionOptionsForSuperUser = useWorkflowActionOptions(content.type);
  const actionOptions = user?.isSuperUser
    ? actionOptionsForSuperUser
    : actionOptionsForSuperUser.filter(
        action => action.value !== WorkflowActionEnum.Webhook
      );

  const hasCustomText = workflow.payload?.text != null;

  function whenChangeHandler(when: WorkflowWhenEnum) {
    onWorkflowUpdated(workflow._id, {
      whenContext: getDefaultWhenContext({
        contentType: content.type,
        when,
        reservableFeature,
      }),
      time: getDefaultTime(when),
      event: undefined,
      user: null,
      groupRole: null,
      action: undefined,
      webhook: null,
      workflow: constructDefaultWorkflowData(),
      when,
    });
  }

  function whenContextChangeHandler(whenContext: WorkflowWhenContextEnum) {
    onWorkflowUpdated(workflow._id, {
      whenContext,
      event: undefined,
      user: null,
      groupRole: null,
      action: undefined,
      webhook: null,
      workflow: constructDefaultWorkflowData(),
    });
  }

  function updateWorkflowInStatus(action: [{ value: string; label: string }]) {
    onWorkflowUpdated(workflow._id, {
      inStatus: action?.length ? action.map(x => x.value) : null,
    });
  }

  function updateWorkflowDataValidationSchema(
    dataValidationSchema: ContentWorkflowType['dataValidationSchema']
  ) {
    onWorkflowUpdated(workflow._id, {
      dataValidationSchema,
    });
  }

  useEffect(() => {
    if (!workflow.inStatus) {
      return updateWorkflowStatusState(OPEN_STATUSES);
    }
    if (isEqual(workflow.inStatus, INTERACTION_OPEN_STATES)) {
      return updateWorkflowStatusState(OPEN_STATUSES);
    }
    if (isEqual(workflow.inStatus, INTERACTION_STATES)) {
      return updateWorkflowStatusState(ANY_STATUSES);
    }
    updateWorkflowStatusState(CUSTOM_STATUSES);
    onWorkflowUpdated(workflow._id, {
      inStatus: workflow.inStatus,
    });
  }, []);

  function updateWorkflowStatusState(action: any) {
    let inStatus;
    switch (action) {
      case ANY_STATUSES:
        inStatus = INTERACTION_STATES;
        break;
      case OPEN_STATUSES:
        inStatus = INTERACTION_OPEN_STATES;
        break;
      case CUSTOM_STATUSES:
        inStatus = null;
        break;
      default:
        inStatus = INTERACTION_OPEN_STATES;
    }
    setWorkflowStatusState(action);
    onWorkflowUpdated(workflow._id, { inStatus });
  }

  function updateWorkflowAction(action: WorkflowActionEnum) {
    let update: Partial<ContentWorkflowType> = { action };

    if (action === WorkflowActionEnum.Webhook) {
      // if this is a webhook, the only valid target is a Webhook
      update = {
        action,
        target: WorkflowTargetEnum.Webhook,
        groupRole: null,
        user: null,
        webhook: null,
      };
    }

    onWorkflowUpdated(workflow._id, update);
  }

  const dependentWorkflowWarnings = getWorkflowWarnings(
    [workflow],
    content.startDate,
    content.endDate
  );

  return (
    <div className={cx(styles.ContentWorkflowEdit, className)} style={style}>
      <H4 mt={2} mb={2}>
        {content.type === ContentTypeEnum.WorkOrder
          ? t('web.admin.channel.content.workOrder.workflow.editor.title')
          : t('web.admin.channel.content.workflow.editor.title')}
      </H4>
      <div className={styles.outerWrapper}>
        {dependentWorkflowWarnings.length ? (
          <div className={styles.errors}>
            <ErrorMessage
              error={dependentWorkflowWarnings}
              color={AlertType.warning}
            />
          </div>
        ) : null}
        {content.type !== ContentTypeEnum.WorkOrder && (
          <Input
            className={styles.input}
            label={t(
              'web.admin.channel.content.workflow.editor.workflowName.placeholder'
            )}
            onChange={name => onWorkflowUpdated(workflow._id, { name })}
            value={workflow.name}
            maxLength={64}
          />
        )}

        <div className={styles.wrapper}>
          {prefixLabels[workflow.whenContext] && (
            <span>{t(prefixLabels[workflow.whenContext])}</span>
          )}
          {[WorkflowWhenEnum.Before, WorkflowWhenEnum.After].includes(
            workflow.when
          ) && (
            <>
              <Dropdown
                className={styles.dropdown}
                items={timeOptions[timeUnit]}
                value={timeValue}
                doTranslation={false}
                onValueChange={updateTimeUnitValue}
              />
              <Dropdown
                className={styles.mediumDropdown}
                doTranslation={false}
                items={timeUnitOptions.map(option => ({
                  value: option.value,
                  label: t(option.label),
                }))}
                value={timeUnit}
                onValueChange={updateTimeUnit}
              />
            </>
          )}
          {content.type !== ContentTypeEnum.WorkOrder && (
            <Dropdown
              className={styles.mediumDropdown}
              items={whenOptions}
              value={workflow.when}
              doTranslation={false}
              onValueChange={whenChangeHandler}
            />
          )}

          {whenContextOptions.length === 1 && whenContextOptions[0] ? (
            <span>{whenContextOptions[0].label}</span>
          ) : (
            <Dropdown
              className={cx(styles.dropdown, styles.mediumDropdown)}
              onValueChange={whenContextChangeHandler}
              items={whenContextOptions}
              doTranslation={false}
              value={workflow.whenContext}
            />
          )}
          {content.type !== ContentTypeEnum.WorkOrder && (
            <Dropdown
              className={cx(styles.dropdown, styles.mediumDropdown)}
              placeholder={t(
                'web.admin.channel.content.workflow.editor.selectEvent.placeholder'
              )}
              doTranslation={false}
              items={eventOptions}
              value={workflow.event}
              onValueChange={event =>
                onWorkflowUpdated(workflow._id, {
                  event: event as ContentWorkflowEventType,
                })
              }
            />
          )}

          {workflow.event && content.type !== ContentTypeEnum.WorkOrder && (
            <>
              {workflow.event === EVENT_CONTENT_INTERACTION_STATUSCHANGE && (
                <ContentWorkflowStatusesEdit
                  statusesFeature={statusesFeature}
                  workflowData={workflow.workflow}
                  onWorkflowDataUpdated={workflowData =>
                    onWorkflowUpdated(workflow._id, {
                      workflow: {
                        ...(workflow.workflow ||
                          constructDefaultWorkflowData()),
                        ...workflowData,
                      },
                    })
                  }
                />
              )}
              {[WorkflowWhenEnum.Before, WorkflowWhenEnum.After].includes(
                workflow.when
              ) && (
                <>
                  <span>
                    {t('web.admin.channel.content.workflow.editor.when.isIn')}
                  </span>
                  <Dropdown
                    className={cx(styles.dropdown, styles.statusDropdown)}
                    items={[
                      { value: ANY_STATUSES, label: t(ANY_STATUSES) },
                      { value: OPEN_STATUSES, label: t(OPEN_STATUSES) },
                      { value: CUSTOM_STATUSES, label: t(CUSTOM_STATUSES) },
                    ]}
                    onValueChange={updateWorkflowStatusState}
                    value={workflowStatusState}
                  />
                  {workflowStatusState === CUSTOM_STATUSES && (
                    <MultiselectField
                      className={cx(
                        styles.multiselectField,
                        styles.largeDropdown
                      )}
                      items={statuses}
                      placeholder={t(
                        'web.admin.channel.content.workflow.editor.selectStatuses.placeholder'
                      )}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '(action: [{ value: string; label: string; }]... Remove this comment to see the full error message
                      onChange={updateWorkflowInStatus}
                      value={
                        workflow?.inStatus?.length
                          ? workflow.inStatus.map(toSchema)
                          : []
                      }
                      initialValues={
                        workflow?.inStatus?.length
                          ? workflow.inStatus.map(toSchema)
                          : []
                      }
                    />
                  )}
                </>
              )}
              {content.type !== ContentTypeEnum.WorkOrder && (
                <Dropdown
                  className={cx(styles.dropdown, styles.largeDropdown)}
                  items={actionOptions}
                  onValueChange={updateWorkflowAction}
                  value={workflow.action}
                />
              )}

              {workflow.action === WorkflowActionEnum.Webhook && (
                <>
                  <span>
                    {t('web.admin.channel.content.workflow.editor.webhook.to')}
                  </span>
                  <WebhookSelectorButton
                    className={styles.lastDropdown}
                    libraries={libraries}
                    webhookId={(workflow.webhook as any)?._id}
                    onWebhookSelected={webhook =>
                      onWorkflowUpdated(workflow._id, {
                        webhook: webhook ? { _id: webhook._id } : null,
                      })
                    }
                  />
                </>
              )}
              {[
                WorkflowActionEnum.SMS,
                WorkflowActionEnum.Email,
                WorkflowActionEnum.Notification,
              ].includes(workflow.action) &&
                content.type !== ContentTypeEnum.WorkOrder && (
                  <>
                    <span>
                      {t(
                        'web.admin.channel.content.workflow.editor.webhook.to'
                      )}
                    </span>
                    <Dropdown
                      className={styles.mediumLargeDropdown}
                      items={targetOptions}
                      onValueChange={data =>
                        onWorkflowUpdated(workflow._id, {
                          user: null,
                          groupRole: null,
                          webhook: null,
                          dataField: null,
                          ...data,
                        })
                      }
                      // we convert value to object because we had to store and match two values per option
                      value={createWorkflowTargetOptionValue(
                        workflow.target,
                        workflow
                      )}
                    />
                    {workflow.target ===
                      WorkflowTargetEnum.InteractionCreator &&
                      qrCodeCheckinFeature && (
                        <Checkbox
                          value
                          // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
                          selected={workflow.payload?.qrCodeCheckIn}
                          text={t(
                            'web.admin.channel.content.workflow.editor.qrCodeCheckIn.text'
                          )}
                          onChange={() =>
                            onWorkflowUpdated(workflow._id, {
                              payload: {
                                ...(workflow.payload || {}),
                                qrCodeCheckIn: !workflow.payload?.qrCodeCheckIn,
                              },
                            })
                          }
                        />
                      )}
                    {workflow.target === WorkflowTargetEnum.User && (
                      <UserSelectorButton
                        className={styles.lastDropdown}
                        userId={workflow.user && (workflow.user as any)._id}
                        channelId={content.channel._id}
                        onUserSelected={(user, selectedChannel) =>
                          onWorkflowUpdated(workflow._id, {
                            user: user ? { _id: user._id } : null,
                            workflowTargetedChannelId: selectedChannel
                              ? convertToUUID(selectedChannel)
                              : null,
                          })
                        }
                      />
                    )}
                    {workflow.target === WorkflowTargetEnum.GroupRole && (
                      <GroupRoleSelector
                        channelId={content.channel._id}
                        className={cx(
                          styles.largeDropdown,
                          styles.lastDropdown
                        )}
                        groupRoleId={(workflow.groupRole as any)?._id}
                        includeWorkplaceMember={false}
                        onGroupRoleSelected={groupRole =>
                          onWorkflowUpdated(workflow._id, {
                            groupRole: groupRole
                              ? { _id: groupRole._id }
                              : null,
                          })
                        }
                      />
                    )}
                  </>
                )}
            </>
          )}
          {content.type === ContentTypeEnum.WorkOrder && (
            <Dropdown
              className={styles.largeDropdown}
              items={statusesFeature?.rules.map(rule => {
                if (rule.status === ServiceRequestStatusEnum.Created) {
                  return {
                    value: ServiceRequestStatusEnum.Created,
                    label: ServiceRequestStatusEnum.Open,
                  };
                }
                return {
                  value: rule.status,
                  label: rule.status,
                };
              })}
              doTranslation={false}
              onValueChange={value =>
                onWorkflowUpdated(workflow._id, {
                  workflow: { on: 'status', value },
                })
              }
              value={workflow.workflow?.value}
            />
          )}
        </div>

        {(workflow.action === WorkflowActionEnum.SMS ||
          workflow.action === WorkflowActionEnum.Notification) && (
          <ContentWorkflowTextArea
            workflowAction={workflow.action}
            hasCustomText={hasCustomText}
            value={workflow.payload?.text}
            onTextUpdated={text =>
              onWorkflowUpdated(workflow._id, {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                payload: { ...workflow.payload, text },
              })
            }
          />
        )}
        {content.type !== ContentTypeEnum.WorkOrder && (
          <ToggleView
            testId="dataValidationSettings"
            title={t(
              'web.admin.channel.content.workflow.editor.conditionalWorkflow.title',
              {
                count: workflow.dataValidationSchema?.length || 0,
              }
            )}
            className={styles.toggleView}
          >
            <div className={cx(styles.toggleInner, styles.left)}>
              <DataValidationSchema
                data={content.data}
                dataValidationSchema={workflow.dataValidationSchema}
                onSchemaUpdated={updateWorkflowDataValidationSchema}
              />
            </div>
          </ToggleView>
        )}

        {workflow.action === WorkflowActionEnum.Email &&
          content.type !== ContentTypeEnum.WorkOrder &&
          !isNewWorkflowsUIEnabled && (
            <ToggleView
              testId="emailSettings"
              title={t(
                'web.admin.channel.content.workflow.editor.emailSettings.title'
              )}
              className={styles.toggleView}
            >
              <div className={styles.toggleInner}>
                <ContentWorkflowTextArea
                  workflowAction={workflow.action}
                  hasCustomText={hasCustomText}
                  value={workflow.payload?.text}
                  onTextUpdated={text =>
                    onWorkflowUpdated(workflow._id, {
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                      payload: { ...workflow.payload, text },
                    })
                  }
                />
                {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ workflow: ContentWorkflowType; channel: Ch... Remove this comment to see the full error message */}
                <EmailBuilder
                  workflow={workflow}
                  channel={channel as ChannelDataType}
                  content={content}
                  timeZone={timeZone}
                  onEmailUpdated={email =>
                    onWorkflowUpdated(workflow._id, {
                      payload: {
                        ...workflow.payload,
                        ...email,
                      },
                    })
                  }
                />
              </div>
            </ToggleView>
          )}

        {workflow.action === WorkflowActionEnum.Email &&
          content.type !== ContentTypeEnum.WorkOrder &&
          isNewWorkflowsUIEnabled && (
            <>
              <Button
                onClick={() => setIsEmailBuilderV2ModalOpen(true)}
                fullWidth={false}
              >
                {
                  // Todo: Merge with the new Workflows UI and remove this placeholder button
                }
                Open Email
              </Button>

              <ModalBackground
                isOpen={isEmailBuilderV2ModalOpen}
                className={styles.background}
                onClose={() => setIsEmailBuilderV2ModalOpen(false)}
              >
                <EmailBuilderV2
                  workflow={workflow}
                  timeZone={timeZone}
                  channel={channel}
                  content={content}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'CSSProperties | undefined' is... Remove this comment to see the full error message
                  style={style}
                  onEmailUpdated={email =>
                    onWorkflowUpdated(workflow._id, {
                      payload: {
                        ...workflow.payload,
                        ...email,
                      },
                    })
                  }
                  onClose={onClose}
                  className={styles.emailBuilderV2}
                />
              </ModalBackground>
            </>
          )}
      </div>

      <ControlMenu className={styles.menu} mt={4} mb={0}>
        <hr />
        <LegacyButton
          testId="deleteWorkflowButton"
          onClick={() => onWorkflowDeleted(workflow._id)}
          size="small"
          variant="outlined-light"
          color="tertiary"
        >
          {t('web.admin.channel.content.workflow.editor.delete.button')}
        </LegacyButton>
        {content.type !== ContentTypeEnum.WorkOrder && (
          <LegacyButton
            testId="copyWorkflowButton"
            onClick={() => {
              onWorkflowCloned(workflow._id);
              onClose();
            }}
            size="small"
          >
            {t('web.admin.channel.content.workflow.editor.copy.button')}
          </LegacyButton>
        )}
        <LegacyButton
          testId="doneWorkflowEditButton"
          onClick={onClose}
          size="small"
          variant="contained"
        >
          {t('web.admin.channel.content.workflow.editor.done.button')}
        </LegacyButton>
      </ControlMenu>
    </div>
  );
}

export default React.memo(ContentWorkflowEdit);
