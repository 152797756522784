import { CodedError } from '../../CodedError';
import { ErrorCodeType, errorCodes } from '../../constants/errorCodes';

/**
 * This class accepts the following error codes:
 * dsxInvalidFieldError (default)
 * dsxInvalidClientCertificateError
 * dsxInvalidCertificatePassphraseError
 * dsxClientCertificateValidationError
 */

export class DsxInvalidFieldError extends CodedError {
  static get errorName() {
    return errorCodes.dsxInvalidFieldError.code;
  }

  constructor(error: ErrorCodeType = errorCodes.dsxInvalidFieldError) {
    super(error.message);
    this.code = error.code;
    this.message = `${error.message} ${error.code}`;
    this.name = error.name;
  }
}
