import tryParseInt from 'lane-shared/helpers/tryParseInt';

import commonConfig from './config.common';

const env = process.env;

const config = {
  ...commonConfig,
  mode: env.MODE,
  appUrl: env.APP_URL,
  port: env.PORT,
  logLevel: env.LOG_LEVEL,

  secret: env.SECRET,
  stripeKey: env.STRIPE_KEY,
  stripeEndpointSecret: env.STRIPE_ENDPOINT_SECRET,
  database: {
    pgNative: true,
    host: env.DATABASE_HOST,
    port: tryParseInt(env.DATABASE_PORT),
    database: env.DATABASE_NAME,
    user: env.DATABASE_USER,
    password: env.DATABASE_PASSWORD,
    replica_host: env.DATABASE_REPLICA_HOST,
    replica_user: env.DATABASE_REPLICA_USER,
    replica_password: env.DATABASE_REPLICA_PASSWORD,
  },
  flatfile: {
    secrets: {
      all_fields_required: env.FLATFILE_SECRET_ALL_FIELDS_REQUIRED,
      name_required: env.FLATFILE_SECRET_NAME_REQUIRED,
      name_email_cell_required: env.FLATFILE_SECRET_NAME_EMAIL_CELL_REQUIRED,
      name_email_company_required:
        env.FLATFILE_SECRET_NAME_EMAIL_COMPANY_REQUIRED,
      name_email_required: env.FLATFILE_SECRET_NAME_EMAIL_REQUIRED,
      name_cell_required: env.FLATFILE_SECRET_NAME_CELL_REQUIRED,
      name_cell_company_required:
        env.FLATFILE_SECRET_NAME_CELL_COMPANY_REQUIRED,
      name_company_required: env.FLATFILE_SECRET_NAME_COMPANY_REQUIRED,
    },
  },
  auth0: {
    apiKey: env.AUTH0_API_KEY,
    systemApi: {
      domain: process.env.AUTH0_SYSTEM_API_DOMAIN,
      clientId: env.AUTH0_SYSTEM_API_CLIENT_ID,
      clientSecret: env.AUTH0_SYSTEM_API_CLIENT_SECRET,
    },
  },
  graphql: {
    introspection: env.GRAPHQL_INTROSPECTION,
    playground: env.GRAPHQL_PLAYGROUND,
    csrfPrevention: env.GRAPHQL_CSRF_PREVENTION,
  },
  kinesis: {
    analytics: {
      streamName: env.KINESIS_ANALYTICS_STREAM_NAME,
      maxStreams: tryParseInt(env.KINESIS_ANALYTICS_MAX_STREAMS),
    },
    weblog: {
      streamName: env.KINESIS_WEBLOG_STREAM_NAME,
      maxStreams: tryParseInt(env.KINESIS_WEBLOG_MAX_STREAMS),
    },
  },
  parkwhiz: {
    clientId: env.PARKWHIZ_CLIENT_ID,
    clientSecret: env.PARKWHIZ_CLIENT_SECRET,
    baseUrl: env.PARKWHIZ_BASE_URL,
  },
  gsaEventBusHosts: process.env.GSA_EVENT_BUS_HOSTS || 'localhost:9092',
  kafkaPartitions: tryParseInt(env.KAFKA_PARTITIONS),
  kafkaReplicationFactor: tryParseInt(env.KAFKA_REPLICATION_FACTOR),
  essensys: {
    baseURL: env.ESSENSYS_BASE_URL,
    client_secret: env.ESSENSYS_CLIENT_SECRET,
    username: env.ESSENSYS_USERNAME,
    password: env.ESSENSYS_PASSWORD,
    uploadReports: env.ESSENSYS_UPLOAD_REPORTS,
  },
  saml: {
    identityProvider: {
      issuer: env.SAML_ISSUER,
      key: env.SAML_IDENTITY_PROVIDER_KEY,
      cert: env.SAML_IDENTITY_PROVIDER_CERT,
    },
    datawatch: {
      accessToken: env.DATAWATCH_ACCESS_TOKEN,
      userId: env.DATAWATCH_USER_ID,
      password: env.DATAWATCH_PASSWORD,
    },
  },
  stripe: {
    publicKey: env.STRIPE_PUBLIC_KEY,
    privateKey: env.STRIPE_PRIVATE_KEY,
    clientId: env.STRIPE_CLIENT_ID,
    expressAuthorizeURL: env.STRIPE_EXPRESS_AUTHORIZE_URL,
    expressRedirectURI: env.STRIPE_EXPRESS_REDIRECT_URI,
  },
  placePay: {
    publicKey: env.PLACEPAY_PUBLIC_KEY,
    privateKey: env.PLACEPAY_PRIVATE_KEY,
  },
  launchDarkly: {
    sdkKey: env.LAUNCH_DARKLY_SDK_KEY,
  },
  grpc: {
    tenex: {
      host: env.GRPC_GATEWAY_HOST,
      tlsAuthority: env.GRPC_GATEWAY_TLS_AUTHORITY,
      tlsCertificate: env.GRPC_GATEWAY_TLS_CERTIFICATE,
      tlsKey: env.GRPC_GATEWAY_TLS_PRIVATE_KEY,
    },
    gsa: {
      host: env.GRPC_GSA_GATEWAY_HOST,
      tlsAuthority: env.GRPC_GATEWAY_TLS_AUTHORITY,
      tlsCertificate: env.GRPC_GATEWAY_TLS_CERTIFICATE,
      tlsKey: env.GRPC_GATEWAY_TLS_PRIVATE_KEY,
    },
  },
  sso: {
    apple: {
      privateKeyWeb: process.env.SSO_APPLE_ACTIVATE_WEB_PRIVATE_KEY,
      privateKeyIdWeb: process.env.SSO_APPLE_ACTIVATE_WEB_PRIVATE_KEY_ID,
    },
  },
  cdn: {
    attachments: {
      keyPairId: process.env.CLOUDFRONT_ATTACHMENTS_KEY_ID,
      privateKey: process.env.CLOUDFRONT_ATTACHMENTS_PRIVATE_KEY,
    },
    visitorManagement: {
      keyPairId: process.env.CLOUDFRONT_VISITOR_MANAGEMENT_KEY_ID,
      privateKey: process.env.CLOUDFRONT_VISITOR_MANAGEMENT_PRIVATE_KEY,
    },
  },
  appleWalletPass: {
    teamId: env.APPLE_TEAM_ID,
    wwdr: env.APPLE_WWDR_CERT,
    signerCert: env.APPLE_SIGNER_CERT,
    signerKey: env.APPLE_SIGNER_KEY,
    signerKeyPassphrase: env.APPLE_SIGNER_KEY_PASSPHRASE,
  },
  twilio: {
    accountSid: process.env.TWILIO_ACCOUNT_SID || "changeme",
    serviceId: process.env.TWILIO_SERVICE_ID || "changeme",
    authToken: process.env.TWILIO_AUTH_TOKEN || "changeme",
    messagingServiceSid: process.env.TWILIO_MESSAGING_SERVICE_SID || "changeme",
  }
} as const;

export default config;
