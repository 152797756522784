import React from 'react';

import cx from 'classnames';

import styles from './AdminPage.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

export default function AdminPage({ className, style, children }: Props) {
  return (
    <div
      data-test="adminPage"
      className={cx(styles.AdminPage, className)}
      style={style}
    >
      {children}
    </div>
  );
}
