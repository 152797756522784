import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class ContentFeatureReservableNotAvailableError extends CodedError {
  static get errorName() {
    return errorCodes.contentFeatureReservableNotAvailableError.code;
  }

  constructor(
    message = errorCodes.contentFeatureReservableNotAvailableError.message
  ) {
    super(message);
    this.code = errorCodes.contentFeatureReservableNotAvailableError.code;
    this.message = `${message || this.code}`;
    this.name = errorCodes.contentFeatureReservableNotAvailableError.name;
  }
}
