import React from 'react';

import { Popup } from '../PopupMenu/Popup';

import cx from 'classnames';

import styles from './Tooltip.scss';

type TooltipProps = {
  // placement for arrow of tooltip
  placement?: 'left' | 'top' | 'right' | 'bottom';
  // children
  children: React.ReactNode;
  // content for tooltip
  TooltipComponent: React.ReactNode;
  className?: string;
  zIndex?: number;
  wrapperClassName?: string;
};

export const Tooltip = ({
  placement = 'right',
  children,
  TooltipComponent,
  wrapperClassName,
  zIndex,
  className,
}: TooltipProps) => {
  return (
    <Popup
      zIndex={zIndex}
      trigger={
        <span
          data-test="tooltip"
          className={cx(styles.wrapper, wrapperClassName)}
        >
          {children}
        </span>
      }
      placement={placement}
      triggerAction="hover"
    >
      <div data-test="tooltip-text" className={cx(styles.tip, className)}>
        {TooltipComponent}
      </div>
    </Popup>
  );
};
