import React from 'react';

import cx from 'classnames';

import styles from './style.scss';

interface InputWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  id?: string;
  label?: string;
  className?: string;
  isRequired?: boolean;
}

const requiredFieldAsteriskSymbol = '*';

export const InputWrapper = ({
  children,
  id,
  label,
  className,
  isRequired,
  ...divProps
}: InputWrapperProps) => {
  return (
    <div className={cx(styles.InputWrapper, className)} {...divProps}>
      {label && (
        <label className={styles.InputLabel} htmlFor={id}>
          {label}
          {isRequired && (
            <span className={styles.InputLabelRequiredAsterisk}>
              {requiredFieldAsteriskSymbol}
            </span>
          )}
        </label>
      )}

      {children}
    </div>
  );
};
