import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query searchEquipment(
    $channelId: UUID!
    $search: EquipmentSearch
    $pagination: PaginationInput!
    $location: [String]
    $category: [String]
    $status: [String]
    $warrantyExpirationDateStart: DateTime
    $warrantyExpirationDateEnd: DateTime
    $installDateStart: DateTime
    $installDateEnd: DateTime
  ) {
    searchEquipment(
      channelId: $channelId
      search: $search
      pagination: $pagination
      location: $location
      category: $category
      status: $status
      warrantyExpirationDateStart: $warrantyExpirationDateStart
      warrantyExpirationDateEnd: $warrantyExpirationDateEnd
      installDateStart: $installDateStart
      installDateEnd: $installDateEnd
    ) {
      equipment {
        id
        name
        status
        location
        category
        channelId
        make
        model
        asset
        serial
        floor
        suite
        installDate
        warrantyExpirationDate
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`);
