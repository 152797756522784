import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query getChannelIntegrations($id: UUID!) {
    channel(_id: $id) {
      _id
      integrations {
        _id
        _created
        _updated
        _updatedBy {
          ...PublicUserFragment
        }
        deleted
        deletedAt
        integration {
          _id
          name
          type
          properties
          platforms
        }
        settings
      }
    }
  }
`;
