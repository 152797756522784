export enum MetatagType {
  Simple = 'Simple',
  Complex = 'Complex',
}

/**
 * @deprecated use `MetatagType` instead
 */
export const META_TAG_TYPES = [
  MetatagType.Simple,
  MetatagType.Complex,
] as const;

/**
 * @deprecated use `MetatagType` instead
 */
export const [META_TAG_TYPE_SIMPLE, META_TAG_TYPE_COMPLEX] = META_TAG_TYPES;
