import gql from 'graphql-tag';

import { ContentFragment } from '../fragments';

export default gql`
  ${ContentFragment}

  query ContentForRelatedSpaces(
    $channelId: UUID!
    $contentId: UUID!
    $search: ContentSearch!
    $pagination: PaginationInput!
  ) {
    contentForRelatedSpaces(
      channelId: $channelId
      contentId: $contentId
      search: $search
      pagination: $pagination
    ) {
      ...ContentFragment
    }
  }
`;
