import React from 'react';
import { FileInput } from 'components';
import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { Button, Icon, DropdownItem } from 'design-system-web';
import styles from '../teamBulkInvite/styles.scss';
import { AddUserType, InputType, InputTypeEnum } from './types';

const MAX_IMPORT_ROWS = 1000;

function parseString(str: any) {
  if (!str) {
    return '';
  }

  return str.replace(/"/gi, '').trim();
}

function parseBoolean(str: any) {
  const stringValue = parseString(str).toLowerCase();
  return stringValue === 'true';
}

function parseStringDropdown(str: any, items: DropdownItem<string>[]) {
  const strValue = parseString(str);

  const item = items.find(
    item => item.value === strValue || item.label === strValue
  );
  return item ? strValue : '';
}
function parseList(str: any, items: DropdownItem<string>[]) {
  if (!str) {
    return [];
  }

  const arr = str
    .split(';')
    .map((val: string) => val.replace(/"/gi, '').trim());

  return arr.map((val: string) => {
    const item = items.find(item => item.value === val || item.label === val);
    return item ?? {};
  });
}

type Props = {
  loading: boolean;
  setRows: (rows: AddUserType[]) => void;
  inputs: InputType[];
};

export const UsersCsvImportButton = ({ loading, setRows, inputs }: Props) => {
  const { t } = useTranslation();

  function tryByHeader(text: string): AddUserType[] {
    const newRows: AddUserType[] = [];

    const parsed = Papa.parse(text, {
      header: true,
      skipEmptyLines: 'greedy',
    });

    const headers = parsed?.meta?.fields;

    function getField(key: string) {
      if (headers) {
        return headers.find(field => {
          const formattedField = field.toLowerCase().trim().replace(/\s/g, '');
          return formattedField.includes(key.toLowerCase());
        });
      }
      return null;
    }

    function getNewRow(row: AddUserType) {
      const newRow = {} as AddUserType;

      inputs.map((input: InputType) => {
        const field = getField(input.key);
        let value = null;

        if (field) {
          const fieldValue = (row as any)[field];

          if (input.type === InputTypeEnum.String) {
            value = parseString(fieldValue);
          }

          if (input.type === InputTypeEnum.Boolean) {
            value = parseBoolean(fieldValue);
          }
          if (input.type === InputTypeEnum.Dropdown) {
            value = parseStringDropdown(fieldValue, input?.items ?? []);
          }

          if (input.type === InputTypeEnum.MultiSelect) {
            value = parseList(fieldValue, input?.items ?? []);
          }
        }
        (newRow as any)[input.key] = value;
      });
      return newRow;
    }

    // try to find headers.
    if (parsed?.data) {
      // try to find email header.
      const emailField = getField('email');

      if (!emailField) {
        // try parsing by name,email
        throw new Error(
          t(
            'web.admin.channel.teamManagement.team.BulkSendInvite.emailMissingError'
          )
        );
      }

      parsed.data
        .filter(row => (row as any)[emailField])
        .forEach((row: any, i: number) => {
          if (i < MAX_IMPORT_ROWS) {
            const newRow = getNewRow(row);
            newRows.push(newRow);
          }
        });

      if (parsed.data.length > MAX_IMPORT_ROWS) {
        window.Toast.show(
          <p>
            {t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.csvImportLowerLimitMessage',
              { maxImportRows: MAX_IMPORT_ROWS }
            )}
          </p>
        );
      }
    }

    return newRows;
  }

  function onUploadCsv(text: string) {
    try {
      setRows(tryByHeader(text));
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.BulkSendInvite.invalidFileTitle'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.BulkSendInvite.invalidFileMessage'
        ),
        error: err,
      });
    }
  }

  return (
    <FileInput
      disabled={loading}
      loading={loading}
      accept=".csv, text/plain"
      type={FileReturnTypeEnum.Text}
      // @ts-ignore
      onFileSelected={(text: any) => onUploadCsv(text)}
      testId="csv-import-button-for-user-input-list"
    >
      <Button
        className={styles.button}
        loading={loading}
        variant="tertiary"
        startIcon={<Icon name="upload" />}
        data-test="csv-import-button"
      >
        {t(
          'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.uploadCsvButtonText'
        )}
      </Button>
    </FileInput>
  );
};
