import React, { useContext, useEffect } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useFlag, useCurrentChannel } from 'lane-shared/hooks';
import { UnderConstructionLegacy } from './UnderConstructionLegacy';

import { H4, M, Flex } from 'design-system-web';
import { ChannelHierarchy } from './ChannelHierarchy';
import { ChannelsContext } from 'lane-shared/contexts';
import { useUnderConstructionAnalytics } from 'lane-shared/hooks/analytics';
import getDisplayName from 'lane-shared/helpers/getDisplayName';

const TRANSLATION_KEYS = {
  title: 'web.components.layout.PageNotAvailable.title',
  bodyText: 'web.components.layout.PageNotAvailable.body.text',
};

export function PageNotAvailable({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  const { t } = useTranslation();
  const currentChannel = useCurrentChannel();
  const { channels } = useContext(ChannelsContext);
  const { underConstructionTracker } = useUnderConstructionAnalytics();

  const useNewPageNotAvailablePage = useFlag(
    FeatureFlag.UnderConstructionPageWeb,
    false
  );

  useEffect(() => {
    underConstructionTracker.View.Index({ availableChannels: channels });
  }, []);

  return useNewPageNotAvailablePage ? (
    <Flex direction="column" gap={5} className="w-[564px]">
      <div className="text-center">
        <Flex p={[8, 4, 8, 4]} direction="column" align="center" gap={5}>
          <H4>{t(TRANSLATION_KEYS.title)}</H4>
          <M>
            <Trans
              i18nKey={TRANSLATION_KEYS.bodyText}
              components={[<strong key="currentChannel" />, <br key="br" />]}
              values={{ channelName: getDisplayName(currentChannel) }}
            />
          </M>
        </Flex>
        <div className="bg-border-subdued w-full h-px" />
      </div>
      <ChannelHierarchy />
    </Flex>
  ) : (
    <UnderConstructionLegacy className={className} style={style} />
  );
}
