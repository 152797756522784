import { useCallback, useMemo, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { DateRangeType } from '../types/baseTypes/DateRangeType';
import doDateRangesOverlap from '../helpers/dates/doDateRangesOverlap';
import { ContentRendererContext } from 'lane-shared/contexts';
import {
  useReservableWaitlistActions,
  useGetEnrolledUserWaitlist,
  useGetUserBookingsCountForContent,
} from './contentReservable';
import { useSimpleTrack } from './useSimpleTrack';

export type WaitListType = {
  label: string;
  value: DateRangeType;
  onClick: (range: DateRangeType) => void;
};

export enum TimeSlotState {
  JoinWaitlist = 'joinWaitlist',
  LeaveWaitlist = 'leaveWaitlist',
  SelectTimeSlot = 'selectTimeSlot',
  BookedTimeSlot = 'bookedTimeSlot',
}

type UserBookingCountType = {
  count: number;
} & DateRangeType;

const TRANSLATION_KEYS = {
  noAvailability: 'shared.content.feature.reservable.timeSlots.noAvailability',
  bookedTimeslot: 'shared.content.feature.reservable.timeSlots.booked',
  joinWaitlist: 'shared.content.feature.reservable.waitlist.join',
  leaveWaitlist: 'shared.content.feature.reservable.waitlist.leave',
  selectTimeslot: 'shared.content.feature.reservable.waitlist.select',
  joinwaitlistSuccess:
    'shared.content.feature.reservable.waitlist.join.success',
  joinWaitlistContentId:
    'shared.content.feature.reservable.waitlist.join.contentid.required',
  leaveWaitlistContentId:
    'shared.content.feature.reservable.waitlist.leave.contentid.required',
  leaveWaitlistSuccess:
    'shared.content.feature.reservable.waitlist.leave.success',
};

type Props = {
  timeSlots: DateRangeType[];
  unavailableByRules: DateRangeType[];
  unavailableByOccupied: DateRangeType[];
  onClick: (range: DateRangeType) => void;
  selectedTimeSlot: DateRangeType;
  contentId?: string;
  notify: (message: string) => void;
  selectedDay?: Date;
  maxQuantityPerSlotPerUser: number;
};

const hasUnavailableByOccupied = (
  unavailableByOccupied: DateRangeType[],
  timeSlot: DateRangeType
): boolean => {
  return (
    unavailableByOccupied.length > 0 &&
    unavailableByOccupied.some(range => doDateRangesOverlap(timeSlot, range))
  );
};

const hasReachedMaxLimit = (
  userBookingsCount: UserBookingCountType[],
  timeSlot: DateRangeType,
  maxQuantityPerSlotPerUser: number
): boolean => {
  return userBookingsCount.some(
    range =>
      doDateRangesOverlap(timeSlot, range) &&
      range.count >= maxQuantityPerSlotPerUser
  );
};

const hasEnrolledUserWaitlist = (
  enrolledUserWaitlists: DateRangeType[],
  timeSlot: DateRangeType
): boolean => {
  return (
    enrolledUserWaitlists.length > 0 &&
    enrolledUserWaitlists.some(range => doDateRangesOverlap(timeSlot, range))
  );
};

export const getActionButtonLabel = (value: TimeSlotState, t: any) => {
  switch (value) {
    case TimeSlotState.BookedTimeSlot:
      return t(TRANSLATION_KEYS.bookedTimeslot);
    case TimeSlotState.JoinWaitlist:
      return t(TRANSLATION_KEYS.joinWaitlist);
    case TimeSlotState.LeaveWaitlist:
      return t(TRANSLATION_KEYS.leaveWaitlist);
    case TimeSlotState.SelectTimeSlot:
    default:
      return t(TRANSLATION_KEYS.selectTimeslot);
  }
};

const removeUnavailableByRules = (
  timeSlots: DateRangeType[],
  unavailableByRules: DateRangeType[]
): DateRangeType[] => {
  const hasUnavailableByRules = (
    unavailableByRules: DateRangeType[],
    timeslot: DateRangeType
  ) =>
    unavailableByRules.length > 0 &&
    unavailableByRules.some(range => doDateRangesOverlap(range, timeslot));

  return timeSlots.filter(
    timeSlot => !hasUnavailableByRules(unavailableByRules, timeSlot)
  );
};

const getTimeSlotState = (
  timeSlot: DateRangeType,
  userBookingsCount: UserBookingCountType[],
  unavailableByOccupied: DateRangeType[],
  enrolledUserWaitlists: DateRangeType[],
  maxQuantityPerSlotPerUser: number
) => {
  let defaultState = TimeSlotState.SelectTimeSlot;
  if (
    hasReachedMaxLimit(userBookingsCount, timeSlot, maxQuantityPerSlotPerUser)
  ) {
    defaultState = TimeSlotState.BookedTimeSlot;
  } else if (hasUnavailableByOccupied(unavailableByOccupied, timeSlot)) {
    defaultState = TimeSlotState.JoinWaitlist;
    if (hasEnrolledUserWaitlist(enrolledUserWaitlists, timeSlot)) {
      defaultState = TimeSlotState.LeaveWaitlist;
    }
  }
  return defaultState;
};

export function useTimeSlotInput({
  timeSlots,
  unavailableByRules,
  unavailableByOccupied,
  onClick,
  selectedTimeSlot,
  contentId,
  notify,
  selectedDay,
  maxQuantityPerSlotPerUser,
}: Props) {
  const { interaction } = useContext(ContentRendererContext);
  const simpleTrack = useSimpleTrack();
  const {
    enrolledUserWaitlists,
    fetchEnrolledUserWaitlist,
    setEnrolledUserWaitlists,
  } = useGetEnrolledUserWaitlist({
    contentId,
  });

  const { userBookingsCount } = useGetUserBookingsCountForContent({
    contentId,
    selectedDate: selectedDay || undefined,
  });

  const { t } = useTranslation();

  const { joinWaitlist, leaveWaitlist } = useReservableWaitlistActions();

  // TODO: Not being use as of now but keep for future state to handle any scenario or display any message to user.
  const onBooked = useCallback(
    (_timeSlot: DateRangeType) => {
      return;
    },
    [selectedTimeSlot]
  );

  const selectHandlerOnClick = useCallback(
    (timeSlot: DateRangeType) => {
      if (doDateRangesOverlap(timeSlot, selectedTimeSlot)) {
        onClick({
          startDate: undefined,
          endDate: undefined,
        });
        return;
      }
      onClick(timeSlot);
    },
    [onClick, selectedTimeSlot]
  );

  const joinWaitlistHandlerOnClick = useCallback(
    async (timeSlot: DateRangeType) => {
      simpleTrack('content.reservable.waitlist.join');

      try {
        if (!contentId) {
          throw new Error(t(TRANSLATION_KEYS.joinWaitlistContentId));
        }
        await joinWaitlist(contentId, timeSlot);
        const result = await fetchEnrolledUserWaitlist();
        if (result.data) {
          setEnrolledUserWaitlists(result.data.reservableWaitlistEnrollments);
          notify(t(TRANSLATION_KEYS.joinwaitlistSuccess));
        }
      } catch (e) {
        notify(e.message);
      }
    },
    [
      joinWaitlist,
      contentId,
      notify,
      t,
      fetchEnrolledUserWaitlist,
      setEnrolledUserWaitlists,
    ]
  );

  const leaveWaitlistHandlerOnClick = useCallback(
    async (timeSlot: DateRangeType) => {
      simpleTrack('content.reservable.waitlist.leave');
      try {
        const enrollmentTimeSlot = enrolledUserWaitlists.find(range =>
          doDateRangesOverlap(timeSlot, range)
        );
        if (!contentId) {
          throw new Error(t(TRANSLATION_KEYS.leaveWaitlistContentId));
        }
        await leaveWaitlist(enrollmentTimeSlot?.id);
        const result = await fetchEnrolledUserWaitlist();
        if (result.data) {
          setEnrolledUserWaitlists(result.data.reservableWaitlistEnrollments);
        }
        notify(t(TRANSLATION_KEYS.leaveWaitlistSuccess));
      } catch (e) {
        notify(e.message);
      }
    },
    [
      leaveWaitlist,
      contentId,
      notify,
      t,
      fetchEnrolledUserWaitlist,
      setEnrolledUserWaitlists,
      enrolledUserWaitlists,
    ]
  );

  const availableWaitListTimeSlots = useMemo(() => {
    const availableTimeSlots = removeUnavailableByRules(
      timeSlots,
      unavailableByRules
    );
    const enabledOnBehalfOfUser =
      interaction?.features?.SubmitOnBehalfOf?.user?._id !== undefined &&
      ((interaction?.features?.SubmitOnBehalfOf?.user as unknown) as {
        name: string;
      }).name !== undefined;
    const timeslotHandlersMap: Record<
      TimeSlotState,
      (arg: DateRangeType) => void
    > = {
      [TimeSlotState.SelectTimeSlot]: selectHandlerOnClick,
      [TimeSlotState.BookedTimeSlot]: onBooked,
      [TimeSlotState.JoinWaitlist]: joinWaitlistHandlerOnClick,
      [TimeSlotState.LeaveWaitlist]: leaveWaitlistHandlerOnClick,
    } as const;

    return availableTimeSlots.map(timeSlot => {
      const state = enabledOnBehalfOfUser
        ? TimeSlotState.SelectTimeSlot
        : getTimeSlotState(
            timeSlot,
            userBookingsCount,
            unavailableByOccupied,
            enrolledUserWaitlists,
            maxQuantityPerSlotPerUser
          );
      return {
        value: timeSlot,
        label: getActionButtonLabel(state, t),
        onClick: timeslotHandlersMap[state],
      };
    }) as WaitListType[];
  }, [
    timeSlots,
    unavailableByRules,
    unavailableByOccupied,
    enrolledUserWaitlists,
    selectHandlerOnClick,
    onBooked,
    joinWaitlistHandlerOnClick,
    leaveWaitlistHandlerOnClick,
    t,
    userBookingsCount,
    maxQuantityPerSlotPerUser,
    interaction?.features?.SubmitOnBehalfOf,
  ]);

  return { availableWaitListTimeSlots };
}
