import React, { useEffect, useState, useRef } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getFriendlyDate } from 'lane-shared/helpers/content';
import {
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content/subtitleHelpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { LocationType } from 'lane-shared/types/LocationType';
import { ContentType } from 'lane-shared/types/content/Content';

import {
  useDescriptionText,
  renderTextLines,
  useTitleText,
} from 'components/cards/helper';
import { H4, M, XS } from 'components/typography';

import GeneratorContentCount from '../../pages/portal/admin/channel/batch-content/components/GeneratorContentCount';
import ContentStatusPill from './ContentStatusPill';
import StandardContentCardFooter from './StandardContentCardFooter';

import styles from './StandardContentCard.scss';
import useUserLocale from 'hooks/useUserLocale';

import { ContentPriceTag } from './ContentPriceTag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { useFlag } from 'lane-shared/hooks';

type Props = {
  content: ContentType & { generatedChildren?: any };
  className?: string;
  style?: React.CSSProperties;
  mode?: 'default' | 'preview';
  showDistance?: boolean;
  location?: LocationType;
};

export default function StandardContentCard({
  content,
  mode = 'default',
  className,
  style,
  showDistance = false,
  location,
}: Props) {
  const locale = useUserLocale();
  const { t } = useTranslation();
  const date = getFriendlyDate({
    content,
    translator: t,
    showFullMonth: true,
    locale,
  });
  const backgroundImage =
    content?.backgroundImage || content?.backgroundImageId;

  const descriptionRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLElement>(null);
  const [lines, setLines] = useState({ title: 0, desc: 0 });

  const priceOnCardFlag = useFlag(FeatureFlag.PriceOnCard, false);
  const { paymentFeature } = explodeFeatures(content?.features);

  function getLineClamp() {
    if (
      (isSubtitleCustom(content.subtitle) ||
        (date && isSubtitleDate(content.subtitle))) &&
      content?.description
    ) {
      if (lines?.title === 1) {
        return 'short-title-with-subtitle';
      }
      if (lines?.title === 2) {
        return 'long-title-with-subtitle';
      }
    }
    if (
      !isSubtitleCustom(content.subtitle) &&
      !(date && isSubtitleDate(content.subtitle))
    ) {
      if (lines?.title === 1) {
        return 'short-title-without-subtitle';
      }
      if (lines?.title === 2) {
        return 'medium-title-without-subtitle';
      }
      if (lines?.title >= 3) {
        return 'long-title-without-subtitle';
      }
    }
    return 'rest';
  }

  useEffect(() => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Dispatch<SetStateAction<{ title: number; des... Remove this comment to see the full error message
    renderTextLines({ titleRef, descriptionRef, setLines });
  }, [content]);

  return (
    <div
      data-test="standardContentCard"
      className={cx(
        styles.StandardContentCard,
        className,
        content?.description?.length === 0 && styles.noDescription
      )}
      style={style}
      data-line-clamp={getLineClamp()}
    >
      {mode !== 'preview' ? (
        backgroundImage && (
          <div
            className={styles.hero}
            style={{
              backgroundImage: `url(${imageUrl(backgroundImage)})`,
            }}
          >
            {priceOnCardFlag && paymentFeature && (
              <div className={styles.priceTag}>
                <ContentPriceTag content={content} />
              </div>
            )}
          </div>
        )
      ) : (
        <div
          className={styles.hero}
          style={{
            backgroundImage: `url(${imageUrl(backgroundImage)})`,
          }}
        >
          {priceOnCardFlag && paymentFeature && (
            <div className={styles.priceTag}>
              <ContentPriceTag content={content} />
            </div>
          )}
          {!backgroundImage && (
            <Icon name="image" style={{ fontSize: '3em', color: '#e0e0e0' }} />
          )}
        </div>
      )}
      <div
        className={cx(
          styles.details,
          content?.description?.length === 0 && styles.noDescription,
          !backgroundImage && styles.noBackground
        )}
      >
        <div className={styles.header}>
          {!content.backgroundImage &&
            mode !== 'preview' &&
            priceOnCardFlag &&
            paymentFeature && (
              <div className={styles.priceTagWithoutBackground}>
                <ContentPriceTag content={content} />
              </div>
            )}
          {content?.startDate && content?.endDate && (
            <ContentStatusPill
              startDate={content.startDate}
              endDate={content.endDate}
              isPreview={mode === 'preview'}
            />
          )}

          {isSubtitleDate(content.subtitle) && content?.startDate && (
            <XS className={styles.date}>{date}</XS>
          )}

          {isSubtitleCustom(content.subtitle) && Boolean(content?.subtitle) && (
            <XS className={styles.subtitle}>{content?.subtitle}</XS>
          )}

          <H4 className={cx(styles.hidden, styles.hiddenTitle)} ref={titleRef}>
            {useTitleText({ mode, content })}
          </H4>

          <M
            className={cx(styles.hidden, styles.hiddenDesc)}
            ref={descriptionRef}
          >
            {useDescriptionText({ mode, content })}
          </M>

          <H4 className={styles.text} mb={1} mt={1}>
            {useTitleText({ mode, content })}
          </H4>

          <M className={styles.description}>
            {useDescriptionText({ mode, content })}
          </M>
        </div>

        {content?.generatedChildren?.pageInfo?.total > 0 && (
          <GeneratorContentCount
            count={content?.generatedChildren?.pageInfo?.total}
          />
        )}

        <StandardContentCardFooter
          content={content}
          showDistance={showDistance}
          location={location}
        />
      </div>
    </div>
  );
}
