import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class InvalidFeatureStateError extends CodedError {
  static get errorName() {
    return errorCodes.invalidFeatureStateError.code;
  }

  constructor(message = errorCodes.invalidFeatureStateError.message) {
    super(message);
    this.code = errorCodes.invalidFeatureStateError.code;
    this.message = message;
    this.name = errorCodes.invalidFeatureStateError.name;
  }
}
