import { createContext } from 'react';

export type ValidationErrorContextType = {
  errors: string[];
  inner: ValidationErrorContextType[];
  message: string;
  name: string;
  path: string;
  type: string;
  value: {} | undefined;
} | null;

const ValidationErrorContext = createContext<ValidationErrorContextType>(null);

export default ValidationErrorContext;
