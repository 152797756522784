import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Icon, Loading } from 'design-system-web';
import cx from 'classnames';
import { SearchBar, CircleListView } from 'components';
import { useTranslation } from 'react-i18next';

import { useAccessGroupsSearch } from 'lane-shared/hooks';
import { imageUrl } from 'lane-shared/helpers/formatters';

import CardContainer from 'components/cards/CardContainer';
import { M } from 'components/typography';

import { DataStatus, Data } from './types';

import styles from './styles.scss';
import { getNextAccessGroupStatus } from './utils/mappers';

type props = {
  mixedStateData: Data[];
  onChange?: (data: Data[]) => void;
  mixedStateLabel?: string;
  searchPlaceholder?: string;
  className?: string;
  titleComponent?: React.ReactNode;
  title?: string;
  loading?: boolean;
  loaderClassName?: string;
  showLogo?: boolean;
  enableSelectAll?: boolean;
  mixedSelectOnly?: boolean;
};

export function MixedStateList({
  mixedStateData,
  onChange,
  mixedStateLabel,
  searchPlaceholder,
  className,
  titleComponent,
  title,
  loaderClassName,
  loading = false,
  showLogo = false,
  enableSelectAll = false,
  mixedSelectOnly = false,
}: props) {
  const { t } = useTranslation();

  const [data, setData] = useState<Data[]>(mixedStateData);
  const mixedDataRef = useRef<Data[]>(mixedStateData);
  const [search, setSearch] = useState('');
  const showResult: Data[] = useAccessGroupsSearch(data, search);

  const syncShowResult = useCallback(() => {
    const newShowResult = [...showResult];
    data.forEach((d: Data) => {
      const index = newShowResult.findIndex(
        (result: Data) => result.value === d.value
      );

      if (index > -1 && newShowResult[index].status !== d.status) {
        newShowResult[index].status = d.status;
      }
    });
  }, [data, showResult]);

  useEffect(() => {
    if (mixedDataRef.current.length === 0 && mixedStateData.length > 0) {
      mixedDataRef.current = mixedStateData;
    }
    setData(mixedStateData);
  }, [mixedStateData]);

  useEffect(() => {
    syncShowResult();
  }, [data, syncShowResult]);

  const handleClick = (acg: Data) => {
    const index = data.findIndex(d => d.value === acg.value);
    if (acg.isDisabled || index === -1) {
      return;
    }
    const currentStatus = data[index].status;
    const prevStatus = mixedDataRef.current[index]?.status;
    const newStatus = getNextAccessGroupStatus(
      currentStatus,
      prevStatus,
      mixedSelectOnly
    );

    const newData = [...data].map((acg, idx) => {
      if (idx === index) {
        return { ...acg, status: newStatus };
      }
      return { ...acg };
    });
    setData(newData);

    if (onChange) {
      onChange(newData);
    }
  };

  const handleSelectAll = () => {
    const newData = [...data].map(acg => {
      if (!acg.isDisabled) {
        return { ...acg, status: DataStatus.selected };
      }
      return { ...acg };
    });
    setData(newData);

    if (onChange) {
      onChange(newData);
    }
  };

  const getIconName = (status: DataStatus) => {
    if (status === DataStatus.mixed) {
      return 'minus-square';
    }
    return status === DataStatus.selected ? 'check-square' : 'square';
  };

  const sortShowResult = (a: Data, b: Data) => {
    const statusOrder = {
      [DataStatus.selected]: 1,
      [DataStatus.mixed]: 2,
      [DataStatus.unselected]: 3,
    };
    return statusOrder[a.status] - statusOrder[b.status];
  };

  const sortedShowResult = showResult.sort(sortShowResult);

  return (
    <CardContainer>
      {titleComponent}
      <div className={styles.titleContainer}>
        <M>{title}</M>
        {enableSelectAll && (
          <button onClick={handleSelectAll} className={styles.selectAllButton}>
            {t('Select all')}
          </button>
        )}
      </div>
      <div>
        <SearchBar
          searchOptions={{ search }}
          onSearchChange={search => setSearch(search)}
          searchInputPlaceholder={
            searchPlaceholder ??
            t('web.admin.accessControl.accessControlGroups.search.placeholder')
          }
          className={styles.searchBar}
        />
      </div>
      {loading && (
        <div className={loaderClassName}>
          <Loading testId="mix-state-list-loader" />
        </div>
      )}
      {!loading && (
        <div className={cx(styles.mixedStateContainer, className)}>
          <table className={cx(styles.table)}>
            <tbody>
              {sortedShowResult.map((acg: Data) => (
                <tr key={acg.value} onClick={() => handleClick(acg)}>
                  <td
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={cx(acg.isDisabled ? styles.disabled : '')}
                  >
                    <Icon
                      name={getIconName(acg.status)}
                      className={cx(
                        styles.icon,
                        styles[getIconName(acg.status)],
                        acg.isDisabled ? styles.disabled : ''
                      )}
                    />
                    {showLogo ? (
                      <CircleListView
                        image={imageUrl(acg.image)}
                        logo={imageUrl(acg.image)}
                        name={acg.label}
                      >
                        <M>{acg.label}</M>
                      </CircleListView>
                    ) : (
                      <M>{acg.label}</M>
                    )}
                  </td>
                  <td className={cx(acg.isDisabled ? styles.disabled : '')}>
                    {acg.status === DataStatus.mixed && mixedStateLabel && (
                      <M className={cx(styles.mixedStateLabel)}>
                        {mixedStateLabel}
                      </M>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </CardContainer>
  );
}

export default MixedStateList;
