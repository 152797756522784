import gql from 'graphql-tag';

export default gql`
  mutation updateMetatagValues($metatag: MetatagInput!) {
    updateMetatag(metatag: $metatag) {
      _id
      values {
        _id
        _created
        _updated
        value
      }
    }
  }
`;
