import gql from 'graphql-tag';

export default gql`
  query audience($audienceId: UUID!, $channelId: UUID!) {
    audience(audienceId: $audienceId, channelId: $channelId) {
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
      name
      archived
      createdByChannel
      managementChannels
      shareChannels
      contentToTarget
      isDefault
      channelTargetingRules {
        id
        name
        channelId
        audienceId
      }
      groupRoleTargetingRules {
        id
        name
        groupRoleId
        audienceId
      }
    }
  }
`;
