import { createContext } from 'react';

export type GroupSettingContextType = {
  paymentSettingsError: Error | null;
  refund: {
    failureRefund: boolean;
    cancellationRefund: boolean;
    updateFailureRefund: (value: boolean) => void;
    updateCancellationRefund: (value: boolean) => void;
  };
};

export const GroupSettingContext = createContext<GroupSettingContextType>({
  paymentSettingsError: null,
  refund: {
    failureRefund: false,
    cancellationRefund: false,
    updateFailureRefund: () => {},
    updateCancellationRefund: () => {},
  },
});
