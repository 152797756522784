import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { Pagination } from 'components';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { useDebounce } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { queryChannelsForBlocksAndTargeting } from 'lane-shared/graphql/channel';
import { toSchema } from 'lane-shared/helpers';
import { shortAddress } from 'lane-shared/helpers/formatters';
import LocationBaseType from 'lane-shared/properties/baseTypes/Location';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { LocationType } from 'lane-shared/types/LocationType';

import DistanceSlider from '../form/DistanceSlider';
import Input from '../form/Input';
import MultiselectField from '../form/MultiselectField';
import ButtonStrip from '../general/ButtonStrip';
import ControlMenu from '../general/ControlMenu';
import Map from '../general/Map';
import ChannelSearchCircleListView from './ChannelSearchCircleListView';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import styles from './ChannelSearchByGeoLocation.scss';

const views = [
  {
    icon: 'list',
    value: 'list',
  },
  {
    icon: 'map-pin',
    value: 'map',
  },
] as const;

const DEBOUNCE_THROTTLE = 500;
const PER_PAGE = 25;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  type?: ChannelTypeEnum | 'All';
  excludeParentChannels: boolean;
  onChannelSelected: (channel: any) => void;
  location: Pick<LocationType, 'latitude' | 'longitude'>;
  defaultDistance?: number;
};

export default function ChannelSearchByGeoLocation({
  className,
  style,
  type,
  excludeParentChannels = false,
  onChannelSelected,
  location,
  defaultDistance = 5000,
}: Props) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [page, setPage] = useState(0);
  const [distance, setDistance] = useState(defaultDistance);
  const [view, setView] = useState<string>(views[0].value);
  const [types, setTypes] = useState([ChannelTypeEnum.Property]);
  const [debouncedDistance] = useDebounce(distance, DEBOUNCE_THROTTLE);

  const [fetchChannels, { data, loading }] = useLazyQuery(
    queryChannelsForBlocksAndTargeting,
    {
      client: getClient(),
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (!loading) {
      fetchChannels({
        variables: {
          pagination: {
            start: page * PER_PAGE,
            perPage: PER_PAGE,
          },
          search: {
            search: { type: 'like', value: name },
            type: { any: type ? [type] : types },
            isSub: false,
            address: {
              geo: {
                latitude: location.latitude,
                longitude: location.longitude,
                distance: debouncedDistance,
              },
            },
          },
        },
      });
    }
  }, [page, name, types, debouncedDistance, type]);

  useEffect(() => {
    setPage(0);
  }, [name]);

  const channels = excludeParentChannels
    ? data?.channelsForBlocksAndTargeting?.items?.filter(
        (channel: ChannelType) => channel.parent
      ) || []
    : data?.channelsForBlocksAndTargeting?.items || [];
  const pageInfo = data?.channelsForBlocksAndTargeting?.pageInfo || {
    start: 0,
    total: 0,
    perPage: PER_PAGE,
  };
  const center = {
    lat: location ? location.latitude : LocationBaseType.default.latitude,
    lng: location ? location.longitude : LocationBaseType.default.longitude,
  };

  return (
    <div className={cx(styles.ChannelsByGeoLocation, className)} style={style}>
      <ControlMenu>
        <Input
          className={styles.input}
          value={name}
          icon="search"
          showClear
          onChange={name => setName(name)}
          placeholder={t('Search…')}
        />

        {!type && (
          <MultiselectField
            items={Object.values(ChannelTypeEnum).map(toSchema)}
            value={types.map(toSchema)}
            onChange={newTypes => setTypes(newTypes.map(item => item.value))}
          />
        )}

        <ButtonStrip
          className={styles.buttonStrip}
          buttons={[...views]}
          selected={view}
          onClick={view => setView(view)}
        />
      </ControlMenu>

      <DistanceSlider
        className={styles.slider}
        value={distance}
        min={0}
        max={1000000}
        onChange={(distance: any) => setDistance(distance)}
      />

      <Pagination
        loading={loading}
        total={pageInfo.total}
        page={page}
        onPage={page => setPage(page)}
        perPage={PER_PAGE}
      />

      <ul>
        {view === views[0].value &&
          channels.map((channel: ChannelType) => (
            <li
              key={channel._id}
              role="button"
              tabIndex={0}
              onKeyPress={e =>
                e.key === Key.Enter && onChannelSelected(channel)
              }
              onClick={() => onChannelSelected(channel)}
            >
              <ChannelSearchCircleListView
                channel={channel}
                className={styles.channel}
                description={shortAddress(channel.address!)}
              />
            </li>
          ))}
      </ul>
      {view === views[1].value && (
        <Map center={center} defaultZoom={10}>
          {channels.map((channel: any) => {
            const [lng, lat] = channel.address?.geo || [0, 0];

            return (
              <MarkerWithLabel
                key={channel._id}
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'google'.
                labelAnchor={new google.maps.Point(0, 0)}
                labelClass={styles.mapMarker}
                labelVisible={false}
                position={{
                  lat,
                  lng,
                }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  onKeyPress={e =>
                    e.key === Key.Enter && onChannelSelected(channel)
                  }
                  onClick={() => onChannelSelected(channel)}
                >
                  <ChannelSearchCircleListView
                    channel={channel}
                    className={styles.channel}
                    description={shortAddress(channel.address!)}
                  />
                </div>
              </MarkerWithLabel>
            );
          })}
        </Map>
      )}
    </div>
  );
}
