import React, { useContext, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { v4 as uuid } from 'uuid';

import { getClient } from 'lane-shared/apollo';
import { RendererContext } from 'lane-shared/contexts';
import {
  AddressFragment,
  FullDraftContentFragment,
  MetatagFragment,
  ObjectMetatagFragment,
  ProfileFragment,
  PublicUserFragment,
  ThemeFragment,
} from 'lane-shared/graphql/fragments';
import { castGraphQLObject, getLibraryOptions } from 'lane-shared/helpers';
import {
  AVAILABLE_TEMPLATE_TYPES,
  CONTENT_LOCATION_NAMES,
  CONTENT_TYPES,
} from 'lane-shared/helpers/constants/content';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { LANE_TEMPLATE_LIBRARY } from 'lane-shared/helpers/constants/library';
import { removeTranslationsFromContent } from 'lane-shared/helpers/content';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  defaultImportOptions,
  getKeyValue,
  getMergeKeyValue,
  MergeMethodEnum,
  mergeTypeButtons,
  TemplateImportOptionsType,
  useImportExportInfo,
} from 'lane-shared/helpers/templates';
import fetchTemplate from 'lane-shared/helpers/templates/fetchTemplate';
import { useCurrentChannel, useMultiLanguage } from 'lane-shared/hooks';
import Features from 'lane-shared/renderers/v5/features';
import { ContentWorkflowType } from 'lane-shared/types/ContentWorkflowType';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

import ContentCard from 'components/cards/ContentCard';
import Checkbox from 'components/form/Checkbox';
import FileInput from 'components/form/FileInput';
import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';
import ButtonStrip from 'components/general/ButtonStrip';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import ContentSelector from 'components/lane/ContentSelector';
import TemplateLibrary from 'components/lane/TemplateLibrary';
import { findBlock, removeBlock } from 'components/renderers/helpers';

import styles from './TemplateImportModal.scss';

const getContentQuery = gql`
  ${PublicUserFragment}
  ${ProfileFragment}
  ${AddressFragment}
  ${MetatagFragment}
  ${ObjectMetatagFragment}
  ${ThemeFragment}
  ${FullDraftContentFragment}

  query getContent($id: UUID!) {
    draftContent(_id: $id) {
      ...FullDraftContentFragment
    }
  }
`;

type Props = {
  style?: React.CSSProperties;
  className?: string;
  isOpen: boolean;
  content: any;
  channel: any;
  // is this for importing Content or for ContentTemplates
  mode?: 'Content' | 'ContentTemplate';
  onClose: () => void;
  onContentUpdated: (content: any) => void;
};

export default function TemplateImportModal({
  style,
  className,
  isOpen,
  onClose,
  content,
  onContentUpdated,
  channel,
  mode = 'Content',
}: Props) {
  function cloneAction(
    action: ContentWorkflowType,
    contentId: string
  ): ContentWorkflowType {
    const clone = cloneWithNewId(action, contentId);

    // pea-595, it's possible that some workflows have no _order set, this is
    // a required field in the database and could stop this content from being
    // published, so set a default order here.
    if (!clone._order) {
      clone._order = 0;
    }

    return clone;
  }

  function cloneWithNewId(obj: any, contentId: any) {
    const ret = { ...obj, _id: uuid() };

    if (contentId) {
      ret.content = {
        _id: contentId,
      };
    }

    return ret;
  }

  const { blocks } = useContext(RendererContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<
    'content' | 'template' | 'json' | null
  >(null);
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const [referenceContent, setReferenceContent] = useState<any | null>(null);
  const [options, setOptions] = useState<TemplateImportOptionsType>({
    ...defaultImportOptions,
  });
  const currentChannel = useCurrentChannel();
  let props;
  if (currentChannel) {
    props = { channels: [currentChannel] };
  }
  const { translate } = useMultiLanguage(props);

  const {
    appearanceAvailable,
    translationsAvailable,
    optionsAvailable,
    featureCount,
    availableFields,
    allFieldsSelected,
    fieldsStep,
    appearanceStep,
    translationsStep,
    optionsStep,
    featureStep,
  } = useImportExportInfo({ options, channel: currentChannel });

  function resetAndClose() {
    setReferenceContent(null);
    onClose();
  }

  function doImport() {
    if (!referenceContent) {
      return;
    }

    const newContent = { ...content };

    Object.entries(options).forEach(([key, value]) => {
      if (
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean | MergeMethodEnum | { re... Remove this comment to see the full error message
        [undefined, false].includes(value) ||
        (value === MergeMethodEnum.Ignore && key !== 'data')
      ) {
        return;
      }

      switch (key) {
        // these guys all map directly to a key
        case 'name':
        case 'description':
        case 'subtitle':
        case 'category':
        case 'logo':
        case 'color':
        case 'backgroundColor':
        case 'backgroundImage':
        case 'resetPeriod':
        case 'isInteractive':
          newContent[key] = referenceContent[key];
          break;
        // these guys require a bit more logic
        case 'icon':
          newContent.icon = referenceContent.icon;
          newContent.iconSet = referenceContent.iconSet;
          break;
        case 'appearance':
          newContent.block = referenceContent.block;
          newContent.renderer = referenceContent.renderer;
          break;
        case 'tags':
          newContent.tags = referenceContent.tags;
          newContent.contentTags = referenceContent.contentTags;
          break;
        case 'translations':
          newContent.name_l10n = referenceContent.name_l10n;
          newContent.description_l10n = referenceContent.description_l10n;
          newContent.subtitle_l10n = referenceContent.subtitle_l10n;
          break;
        case 'placements':
          // ContentTemplate can't have placements

          if (mode === 'ContentTemplate') {
            break;
          }

          if (value === MergeMethodEnum.Copy) {
            newContent.placements = referenceContent.placements.map(
              (placement: any) => cloneWithNewId(placement, content._id)
            );
          } else {
            newContent.placements = [
              ...newContent.placements,
              ...referenceContent.placements.map((placement: any) =>
                cloneWithNewId(placement, content._id)
              ),
            ];
          }
          break;
        case 'data':
          if (value === MergeMethodEnum.Copy) {
            newContent.data = referenceContent.data;
            newContent.properties = referenceContent.properties;
            newContent.propertiesOptions = referenceContent.propertiesOptions;
            newContent.state = referenceContent.state;
          } else if (value === MergeMethodEnum.Ignore) {
            // if there is a data field, and we are not importing it, then
            // remove all the appearance that reference it.

            if (referenceContent.block) {
              // get all the properties defined for this content
              const dataFieldBlocks = Object.keys(referenceContent.data);
              // clone the appearance block, because we will be modifying it
              // by reference.
              const referenceContentCopy = {
                block: JSON.parse(JSON.stringify(referenceContent.block)),
              };

              dataFieldBlocks.forEach(field => {
                const block = findBlock({
                  content: referenceContentCopy,
                  blocks,
                  key: 'for',
                  value: field,
                });

                if (block) {
                  removeBlock(referenceContentCopy, block, blocks);
                }
              });

              newContent.data = {};
              newContent.block = referenceContentCopy.block;
              newContent.renderer = referenceContent.renderer;
            }
          } else {
            newContent.data = { ...newContent.data, ...referenceContent.data };
            newContent.properties = {
              ...newContent.properties,
              ...referenceContent.properties,
            };
            newContent.propertiesOptions = {
              ...newContent.propertiesOptions,
              ...referenceContent.propertiesOptions,
            };
            newContent.state = {
              ...newContent.state,
              ...referenceContent.state,
            };
          }

          if (newContent.data) {
            // set new ids
            Object.keys(newContent.data).forEach(key => {
              newContent.data[key]._id = uuid();
            });
          }

          break;
        case 'workflows':
          // ContentTemplate can't have workflows
          if (mode === 'ContentTemplate') {
            break;
          }

          if (value === MergeMethodEnum.Copy) {
            newContent.actions = referenceContent.actions.map((action: any) =>
              cloneAction(action, content._id)
            );
          } else {
            newContent.actions = [
              ...newContent.actions,
              ...referenceContent.actions.map((action: any) =>
                cloneAction(action, content._id)
              ),
            ];
          }

          break;
        case 'notifications':
          // ContentTemplate can't have notifications
          if (mode === 'ContentTemplate') {
            break;
          }

          if (value === MergeMethodEnum.Copy) {
            newContent.notifications = referenceContent.notifications.map(
              (notification: any) => cloneWithNewId(notification, content._id)
            );
          } else {
            newContent.notifications = [
              ...newContent.notifications,
              ...referenceContent.notifications.map((notification: any) =>
                cloneWithNewId(notification, content._id)
              ),
            ];
          }
          break;
        case 'features':
          if (!newContent.features) {
            newContent.features = [];
          }

          Object.entries(options.features).forEach(([key, value]) => {
            if (!value) {
              return;
            }

            // if this feature requires interaction set the new content
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (Features[key].requiresInteraction) {
              newContent.isInteractive = true;
            }

            // remove an existing one
            newContent.features = newContent.features.filter(
              ({ type }: any) => type !== key
            );

            // put the new one in
            newContent.features.push(
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
              cloneWithNewId(
                referenceContent.features.find(({ type }: any) => type === key)
              )
            );
          });
          break;
        case 'metatags':
        default:
          // todo: not implemented yet
          break;
      }

      if (options.translations === false) {
        removeTranslationsFromContent(newContent);
      }

      if (referenceContent.externalUrl) {
        newContent.externalUrl = referenceContent.externalUrl;
        newContent.externalUrl._id = uuid();
      }
    });

    let updatedContent = newContent;
    if (currentChannel?.settings?.multiLanguageEnabled) {
      // if MLS is set up on the channel, translate the content first
      updatedContent = translate({ model: newContent, undoTranslation: true });
    }

    onContentUpdated(updatedContent);
    resetAndClose();
  }

  function selectSource(content: any, name: string) {
    setSelectedName(name);

    const options = { ...defaultImportOptions };

    // examine the content being set and determine if its available
    options.name = getKeyValue(content, 'name');
    options.description = getKeyValue(content, 'description');
    options.subtitle = getKeyValue(content, 'subtitle');
    options.tags = getKeyValue(content, 'tags');
    options.category = getKeyValue(content, 'category');
    options.logo = getKeyValue(content, 'logo');
    options.color = getKeyValue(content, 'color');
    options.backgroundColor = getKeyValue(content, 'backgroundColor');
    options.backgroundImage = getKeyValue(content, 'backgroundImage');
    options.icon = getKeyValue(content, 'icon');

    options.appearance = getKeyValue(content, 'block');
    options.placements = getMergeKeyValue(content, 'placements');
    options.data = getMergeKeyValue(content, 'properties');
    options.workflows = getMergeKeyValue(content, 'actions');
    options.notifications = getMergeKeyValue(content, 'notifications');

    const features = explodeFeatures(content?.features);

    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ Cancelable: true | undefined; Deliverable:... Remove this comment to see the full error message
    options.features = {
      Cancelable: features.cancelableFeature ? true : undefined,
      Deliverable: features.deliverableFeature ? true : undefined,
      Entries: features.entriesFeature ? true : undefined,
      EssensysProduct: features.essensysProductFeature ? true : undefined,
      Inventory: features.inventoryFeature ? true : undefined,
      GuestInvite: features.guestInviteFeature ? true : undefined,
      Menu: features.menuFeature ? true : undefined,
      Payment: features.paymentFeature ? true : undefined,
      QRCodeCheckin: features.qrCodeCheckinFeature ? true : undefined,
      Quantity: features.quantityFeature ? true : undefined,
      RemoteFetch: features.remoteFetchFeature ? true : undefined,
      Reservable: features.reservableFeature ? true : undefined,
      Reset: features.resetFeature ? true : undefined,
      Scheduled: features.scheduledFeature ? true : undefined,
      Shipping: features.shippingFeature ? true : undefined,
      SocialOptions: features.socialOptionsFeature ? true : undefined,
      Statuses: features.statusesFeature ? true : undefined,
      SubmitOnBehalfOf: features.submitOnBehalfOfFeature ? true : undefined,
      TimeAvailability: features.timeAvailabilityFeature ? true : undefined,
      UseCompanyPermissions: features.useCompanyPermissionsFeature
        ? true
        : undefined,
    };

    // check for old versions of features
    if (
      features.reservableFeature &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      !(features.reservableFeature._version >= 2)
    ) {
      throw new Error('Reservable on this template is too old to import.');
    }

    setReferenceContent(content);
    setOptions(options);
  }

  async function getContent(contentId: any) {
    setLoading(true);
    try {
      const result = await getClient().query({
        query: getContentQuery,
        variables: {
          id: contentId,
        },
      });

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      selectSource(result.data.draftContent);
      setSelectedType('content');
    } catch (err) {
      window.Alert.alert({
        title: 'There was an error selecting this content.',
        error: err,
      });
      // do something on error
    }
    setLoading(false);
  }

  async function getTemplate(templateId: any) {
    setLoading(true);
    try {
      const template = await fetchTemplate(templateId);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      selectSource(template);
      setSelectedType('template');
    } catch (err) {
      window.Alert.alert({
        title: 'There was an error selecting this template.',
        error: err,
      });
    }
    setLoading(false);
  }

  const libraries = [...getLibraryOptions({ channel }), LANE_TEMPLATE_LIBRARY];

  function toggleAllOptions() {
    const update = {};

    Object.keys(availableFields).forEach(key => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (options[key] !== undefined) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        update[key] = !allFieldsSelected;
      }
    });

    updateOptions(update);
  }

  function updateOptions(props: Partial<TemplateImportOptionsType>) {
    setOptions({
      ...options,
      ...props,
    });
  }

  function importTemplate(text: any, name: any) {
    selectSource(castGraphQLObject(JSON.parse(text)), name);
    setSelectedType('json');
  }

  function renderOption(key: any, name: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return options[key] !== undefined ? (
      <li>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ name: any; ... Remove this comment to see the full error message */}
        <Checkbox
          name={key}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          onChange={() => updateOptions({ [key]: !options[key] })}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          selected={options[key]}
          text={t(name)}
        />
      </li>
    ) : null;
  }

  function renderFeatureOptions() {
    return (
      <ul>
        {Object.values(FeatureNameEnum).map(featureName => {
          if (options.features[featureName] === undefined) {
            return null;
          }

          return (
            <li key={featureName}>
              <Checkbox
                name={featureName}
                onChange={() =>
                  updateOptions({
                    features: {
                      ...options.features,
                      [featureName]: !options.features[featureName],
                    },
                  })
                }
                selected={options.features[featureName]!}
                text={t(Features[featureName].friendlyName || '')}
                value={undefined}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <>
      <ModalBackground
        onClose={resetAndClose}
        isOpen={isOpen}
        className={styles.background}
      >
        <ResizableWindow
          showHeader
          name="templateImportModal"
          className={styles.window}
          onClose={resetAndClose}
          contentContainerClassName={styles.windowContainer}
          defaultPosition={ResizableWindow.mostlyFullScreen()}
        >
          <div className={cx(styles.TemplateImport, className)} style={style}>
            <h1>{t('web.components.lane.ContentImporter.title')}</h1>

            <h2>
              <span className={styles.step}>{t('1')}</span>
              {t('web.admin.channel.autoSetupWizard.selectASource')}
            </h2>

            {!referenceContent && (
              <menu className={styles.buttons}>
                <Button
                  variant="contained"
                  onClick={() => setIsContentOpen(true)}
                >
                  {t('web.components.lane.ContentSelector.selectContent')}
                </Button>

                <Button
                  variant="contained"
                  onClick={() => setIsTemplateOpen(true)}
                >
                  {t('web.components.lane.ContentSelector.selectTemplate')}
                </Button>

                <FileInput
                  disabled={loading}
                  loading={loading}
                  accept="application/json"
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '"text"' is not assignable to type 'FileRetur... Remove this comment to see the full error message
                  type="text"
                  onFileSelected={importTemplate}
                >
                  <Button variant="contained" loading={loading}>
                    <Icon name="upload" />
                    {t('Import from File')}
                  </Button>
                </FileInput>
              </menu>
            )}

            {referenceContent && (
              <>
                <div className={styles.selectedSource}>
                  {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message */}
                  {['template', 'content'].includes(selectedType) && (
                    <ContentCard
                      content={referenceContent}
                      className={styles.contentCard}
                    />
                  )}
                  {selectedType === 'json' && (
                    <div className={styles.selectedFile}>
                      <span>{selectedName}</span>
                    </div>
                  )}
                  <Icon
                    onClick={() => setReferenceContent(null)}
                    className={styles.removeIcon}
                    name="times-circle"
                    set={ICON_SET_FONTAWESOME}
                  />
                </div>

                {availableFields.length > 0 && (
                  <>
                    <h2>
                      <span className={styles.step}>
                        {t(`${fieldsStep + 1}`)}
                      </span>
                      {t(
                        'web.components.lane.ContentImporter.fieldsToImport.title'
                      )}
                    </h2>

                    <div className={styles.options}>
                      <div className={styles.option}>
                        <h1>
                          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message */}
                          <Checkbox
                            selected={allFieldsSelected}
                            text={t(
                              'web.admin.content.draftContent.info.generalInfo.text'
                            )}
                            onChange={toggleAllOptions}
                          />
                        </h1>

                        <ul>
                          {renderOption('name', 'Name')}
                          {renderOption('description', 'Description')}
                          {renderOption(
                            'subtitle',
                            'web.components.lane.ContentImporter.fieldsToImport.subtitle'
                          )}
                          {renderOption('tags', 'Tags')}
                          {renderOption('category', 'Category')}
                          {renderOption(
                            'logo',
                            'web.components.lane.ContentImporter.fieldsToImport.logo'
                          )}
                          {renderOption('backgroundImage', 'Background Image')}
                          {renderOption(
                            'color',
                            'web.components.lane.ContentImporter.fieldsToImport.textColor'
                          )}
                          {renderOption(
                            'backgroundColor',
                            'web.components.lane.ContentImporter.fieldsToImport.backgroundColor'
                          )}
                          {renderOption(
                            'icon',
                            'web.components.lane.ContentImporter.fieldsToImport.icon'
                          )}
                        </ul>
                      </div>
                    </div>
                  </>
                )}

                {appearanceAvailable && (
                  <>
                    <h2>
                      <span className={styles.step}>
                        {t(`${appearanceStep + 1}`)}
                      </span>
                      {t(
                        'web.components.lane.ContentImporter.importAppearance.title'
                      )}
                    </h2>

                    <div className={styles.options}>
                      <div className={styles.option}>
                        <Toggle
                          value={options.appearance}
                          onChange={() =>
                            updateOptions({
                              appearance: !options.appearance,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {optionsAvailable && (
                  <>
                    <h2>
                      <span className={styles.step}>
                        {t(`${optionsStep + 1}`)}
                      </span>
                      {t(
                        'web.components.lane.ContentImporter.importOtherOptions.title'
                      )}
                    </h2>

                    <div className={cx(styles.options, styles.row)}>
                      {mode !== 'ContentTemplate' &&
                        options.placements !== undefined && (
                          <div className={styles.option}>
                            <h1>
                              {t(
                                'web.admin.serviceRequest.settings.tabs.targeting'
                              )}
                            </h1>
                            <ButtonStrip
                              buttons={mergeTypeButtons}
                              selected={options.placements}
                              onClick={placements =>
                                updateOptions({ placements })
                              }
                            />
                          </div>
                        )}

                      {options.data !== undefined && (
                        <div className={styles.option}>
                          <h1>{t('web.admin.content.editor.data.title')}</h1>
                          <ButtonStrip
                            buttons={mergeTypeButtons}
                            selected={options.data}
                            onClick={data => updateOptions({ data })}
                          />
                        </div>
                      )}

                      {mode !== 'ContentTemplate' &&
                        options.notifications !== undefined && (
                          <div className={styles.option}>
                            <h1>
                              {t(
                                'web.admin.visitorManagement.configuration.tab.notifications'
                              )}
                            </h1>
                            <ButtonStrip
                              buttons={mergeTypeButtons}
                              selected={options.notifications}
                              onClick={notifications =>
                                updateOptions({ notifications })
                              }
                            />
                          </div>
                        )}

                      {options.workflows !== undefined && (
                        <div className={styles.option}>
                          <h1>
                            {t(
                              'shared.admin.channel.content.menuTabs.label.workflows'
                            )}
                          </h1>
                          <ButtonStrip
                            buttons={mergeTypeButtons}
                            selected={options.workflows}
                            onClick={workflows => updateOptions({ workflows })}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}

                {featureCount > 0 && (
                  <>
                    <h2>
                      <span className={styles.step}>{featureStep + 1}</span>
                      {t(
                        'web.components.lane.ContentImporter.featuresToImport.title'
                      )}
                    </h2>
                    <div className={styles.options}>
                      <div className={styles.option}>
                        {renderFeatureOptions()}
                      </div>
                    </div>
                  </>
                )}

                {translationsAvailable && (
                  <>
                    <h2>
                      <span className={styles.step}>
                        {translationsStep + 1}
                      </span>
                      {t(
                        'web.components.lane.ContentImporter.importTranslations.title'
                      )}
                    </h2>
                    <div className={styles.options}>
                      <div className={styles.option}>
                        <Toggle
                          value={options.translations}
                          onChange={() =>
                            updateOptions({
                              translations: !options.translations,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <ControlMenu className={styles.bottomMenu}>
            <hr />
            <Button onClick={resetAndClose}>{t('Cancel')}</Button>

            <Button
              testId="importButton"
              variant="contained"
              onClick={doImport}
            >
              {t('Import')}
            </Button>
          </ControlMenu>
        </ResizableWindow>
      </ModalBackground>
      <ModalBackground
        onClose={() => setIsContentOpen(false)}
        isOpen={isContentOpen}
        className={styles.background}
      >
        <ResizableWindow
          showHeader
          onClose={() => setIsContentOpen(false)}
          name="templateImportContent"
          className={styles.window}
          defaultPosition={ResizableWindow.mostlyFullScreen()}
        >
          <ContentSelector
            className={styles.search}
            channelId={channel?._id}
            onContentSelected={(content: any) => {
              setIsContentOpen(false);
              getContent(content._id);
            }}
            availableTypes={Object.values(CONTENT_TYPES)}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'readonly ["Content Center", "Page Center", "... Remove this comment to see the full error message
            contentSearchLocations={CONTENT_LOCATION_NAMES}
            contentQueryType="channel"
          />
        </ResizableWindow>
      </ModalBackground>
      <ModalBackground
        onClose={() => setIsTemplateOpen(false)}
        isOpen={isTemplateOpen}
        className={styles.background}
      >
        <ResizableWindow
          showHeader
          onClose={() => setIsTemplateOpen(false)}
          name="templateImportTemplate"
          className={styles.window}
          defaultPosition={ResizableWindow.mostlyFullScreen()}
        >
          <TemplateLibrary
            libraries={libraries}
            userLibraryEnabled={channel?.settings?.areUserTemplatesAllowed}
            allowedTypes={AVAILABLE_TEMPLATE_TYPES}
            TemplateWrapper={({ template, children }: any) => (
              <div
                className={styles.templateWrapper}
                tabIndex={0}
                role="button"
                onKeyPress={e => {
                  if (e.key === Key.Enter) {
                    setIsTemplateOpen(false);
                    getTemplate(template._id);
                  }
                }}
                onClick={() => {
                  setIsTemplateOpen(false);
                  getTemplate(template._id);
                }}
              >
                {children}
              </div>
            )}
          />
        </ResizableWindow>
      </ModalBackground>
    </>
  );
}
