import React from 'react';

import cx from 'classnames';

import { stringToColor } from 'lane-shared/helpers';
import { getInitials } from 'lane-shared/helpers/formatters';

import { Text } from '../Typography/Typography';

import styles from './Thumbnail.scss';

export type ThumbnailProps = {
  src?: string;
  highlight?: boolean;
  className?: string;
  style?: React.CSSProperties;
  name: string;
  text?: boolean;
  outline?: boolean;
};

export const Thumbnail = ({
  src,
  highlight = false,
  className,
  style,
  name,
  text,
  outline = true,
}: ThumbnailProps) => {
  return (
    <div
      className={cx(styles.Thumbnail, className)}
      data-is-highlighted={highlight}
      data-no-outline={!outline}
      data-no-src={!src}
      style={{
        ...style,
        ...{
          backgroundColor: src ? 'white' : stringToColor(name || 'none'),
          backgroundImage: `url(${src})`,
        },
      }}
    >
      {!src && (
        <Text size="large" className={styles.initials}>
          {text ? name : getInitials(name)}
        </Text>
      )}
    </div>
  );
};
