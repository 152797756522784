// LATER: We do not understand why we need to include reflect-metadata here.
// Running the database seed file somehow imports DI depdendent code when it doesn't actually
// require it. This is a hack to get around that. https://vtschat.slack.com/archives/C02LK39FAQJ/p1677523158307769
// The database seed file; https://github.com/viewthespace/lane-next/blob/8566aafe9cc67da433e21523ed57eafc5ffc7802/packages/lane-infrastructure/database/scripts/seed.ts#L1
// We removed most reflect imports in this ticket: https://viewthespace.atlassian.net/browse/TM-6665
import 'reflect-metadata';

import { ENVIRONMENTS, DEV_ENV } from 'activate-constants';

import envConfig from './config';
import localConfig from './config.local';
import testConfig from './config.test';
import { ContainerModule } from 'inversify';

const {
  NODE_ENV = 'development',
  DEBUG_MODE = 'false',
  APP_ENV = ENVIRONMENTS.LOCAL,
} = process.env as {
  NODE_ENV: string;
  DEBUG_MODE: string;
  APP_ENV: ENVIRONMENTS;
};

/**
 * @deprecated
 *
 * Use app-level declarations of config instead
 */
function getConfig(env: ENVIRONMENTS) {
  switch (env) {
    case ENVIRONMENTS.LOCAL:
      return localConfig;
    case ENVIRONMENTS.TEST:
      return testConfig;
  }

  return envConfig;
}

/**
 * @deprecated
 *
 * Use app-level declarations of config instead
 */
const isDebugMode =
  DEV_ENV.includes(APP_ENV) && DEBUG_MODE.toLowerCase() === 'true';

/**
 * @deprecated
 *
 * Use app-level declarations of config instead
 */
export const config = Object.freeze({
  ...getConfig(APP_ENV),
  NODE_ENV,
  APP_ENV,
  isDebugMode,
});

console.log(
  `[lane-infrastructure] Using APP_ENV="${APP_ENV}" NODE_ENV="${NODE_ENV}"`
);

export const GrpcConfigSymbol = Symbol.for('config-grpc');

// Ideally, these are exported from clients-grpc module,
// but we would need to inverse the config relationship
// and declare it at the application level correctly
export const GrpcClusterTag = Symbol.for('cluster');
export enum GrpcCluster {
  Tenex = 'tenex',
  GSA = 'gsa',
}

/**
 * @deprecated
 *
 * Use app-level declarations of config instead
 */
export const ConfigSymbol = Symbol.for('config');

/**
 * @deprecated
 *
 * Use app-level declarations of config instead
 */
export const configModule = new ContainerModule((bind): void => {
  bind(ConfigSymbol).toConstantValue(config);

  bind(GrpcConfigSymbol)
    .toConstantValue(config.grpc.tenex)
    .whenParentTagged(GrpcClusterTag, GrpcCluster.Tenex);

  bind(GrpcConfigSymbol)
    .toConstantValue(config.grpc.gsa)
    .whenParentTagged(GrpcClusterTag, GrpcCluster.GSA);
});

/**
 * @deprecated
 *
 * Use app-level declarations of config instead
 */
export type Config = typeof config;

/**
 * @deprecated
 *
 * Use app-level declarations of config instead
 */
export default config;
