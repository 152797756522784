import gql from 'graphql-tag';

/**
 * GQL Delete Preventive Maintenance Schedule Mutation
 */

export default gql`
  mutation deletePMScheduleMutation($scheduleId: String!) {
    deletePMSchedule(scheduleId: $scheduleId)
  }
`;
