import commonConfig from './config.common';
import localConfig from './config.local';

const config = {
  ...commonConfig,
  ...localConfig,
  database: {
    pgNative: true,
    host: '127.0.0.1',
    port: 5432,
    database: 'lanetest',
    user: 'lane',
    password: 'password',
  },
  apiKey: {
    secret: 'test-api-secret',
  },
} as const;

export default config;
