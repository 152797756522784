import { ChipStyle } from 'components/ads';

export type UserInfo = {
  _id: string;
  name: string;
  image: string;
  logo: string;
  email: string;
  syncStatus: string;
  companies: {
    id: string;
    name: string;
  }[];
  source: string;
  mobileAccess: boolean | undefined;
  accessControlGroupIds: string[];
  multiIntegrationAcgs?: MultiIntegrationAcgs[];
  role: string | undefined;
  roles?: string[] | undefined;
  integrationChannelId: string;
  credentials: string[] | undefined;
};

export type AccessGroupSelectItem = {
  label: string;
  value: string;
  provider: string;
  channelId: string;
  isDisabled: boolean;
};

export type AccessControlGroup = {
  id: string;
  name: string;
  provider: string;
  channelId: string;
};

export type AccessControlChannel = {
  channelId: string;
  name: string;
  userRoles?: string[] | null | undefined;
  propertyChannelId?: string | null | undefined;
  type?: string | null | undefined;
  profile: {
    image: string;
    logo: string;
  };
  isConnectedByTemplate: boolean;
};

export type AccessControlGroups = {
  id: string;
  name: string;
};

export type MultiIntegrationAcgs = {
  channelId: string;
  channelName: string;
  accessControlGroups: AccessControlGroups[];
};

export interface CredentialGroup {
  id: string;
  provider: string;
  vtsOrganizationId: string;
  status: string;
  name?: string;
  credentials: Credential[];
  credentialMappings?: Credential[];
}

export interface Credential {
  mapId: string;
  cardNumber: string;
  vtsOrganizationId: string;
  status: CredentialStatus;
  provider: string;
  activatedAt: string;
  revokedAt: string;
}

type CredentialStatus =
  | 'ACTIVE'
  | 'ISSUING'
  | 'SUSPENDED'
  | 'REVOKED_OR_EXPIRED'
  | 'EXPIRED';

export type ChannelKeycardData = {
  [channelId: string]: {
    name: string;
    logo?: string | undefined;
    keyCardName: string;
  };
};

export interface KeycardPickerData {
  status: string;
  color: string;
  keyCards: {
    status: string;
    vtsOrganizationId: string;
    name: string;
    logo: string;
    credentialMappings: Credential[];
  }[];
}

export type KeyCardStatus = {
  color: ChipStyle;
  value: string;
};

export enum CredentialGroupStatus {
  UNKNOWN = 'UNKNOWN',
  PROCESSING = 'PROCESSING',
  ISSUING = 'ISSUING',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
  REVOKING = 'REVOKING',
  REVOKED = 'REVOKED',
  RESUMING = 'RESUMING',
  SUSPENDING = 'SUSPENDING',
  SUSPENDED = 'SUSPENDED',
  USER_REVOKING = 'USER_REVOKING',
  USER_SUSPENDING = 'USER_SUSPENDING',
  USER_RESUMING = 'USER_RESUMING',
  USER_SUSPENDED = 'USER_SUSPENDED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
