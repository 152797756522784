import React, { useEffect } from 'react';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import { Calendar } from './components/Calendar';
import useDateRangePicker from './hooks/useDateRangePicker';

type DateRangePickerProps = {
  className?: string;
  style?: React.CSSProperties;
  // display a value that is already selected
  existingValue?: DateRangeType;
  // the timezone to display dates in
  timeZone?: string;
  disabled?: boolean;
  loading?: boolean;
  // the minimum range size in days, i.e. you must select 2 days
  minRangeSize?: number;
  // the maximum range size in days, i.e. you can only select 7 days
  maxRangeSize?: number;
  startDate?: Date;
  endDate?: Date;
  // include a time picker? or just for date range
  includeTime?: boolean;
  // the minimum date allowed to be selected
  minDate?: Date;
  // the maximum date allowed to be selected
  maxDate?: Date;
  // an array of date ranges that are not available
  unavailableDateRanges?: DateRangeType[];
  // when the user changes month view
  onFocusChange?: () => void;
  // when the dates have changed
  onChange?: (dateRange: DateRangeType) => void;
  // when the user sets a new date
  onSubmit?: (dateRange: DateRangeType) => void;
};

export const DateRangePicker = ({
  startDate,
  endDate,
  existingValue,
  onChange = () => {},
  onSubmit,
  includeTime,
  minDate,
  maxDate,
  timeZone,
  unavailableDateRanges,
  minRangeSize,
  maxRangeSize,
  disabled,
  className,
  style,
  onFocusChange,
}: DateRangePickerProps) => {
  const [internalRange, setDate] = useDateRangePicker({
    timeZone,
    startDate,
    endDate,
    minRangeSize,
    maxRangeSize,
  });

  useEffect(() => {
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    onChange(internalRange);
  }, [internalRange.startDate, internalRange.endDate]);

  function onDateClick(_day: any) {
    setDate(_day);
  }

  return (
    <div className={className} style={{ ...style, maxWidth: '21rem' }}>
      <Calendar
        disabled={disabled}
        onChange={onDateClick}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: never; onChange: (_day: any) => ... Remove this comment to see the full error message
        onSubmit={onSubmit ? () => onSubmit(internalRange) : null}
        includeTime={includeTime}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        existingValue={existingValue}
        unavailableDateRanges={unavailableDateRanges}
        onFocusChange={onFocusChange}
      />
    </div>
  );
};
