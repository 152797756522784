import { useCallback, useContext } from 'react';

import AnalyticsContext from '../contexts/AnalyticsContext';
import AppContext from '../contexts/AppContext';

export function useSimpleTrack() {
  const { trackMixpanel } = useContext(AnalyticsContext);
  const { whitelabel } = useContext(AppContext);

  const simpleTrack = useCallback(
    (eventName: string, data: Record<string, any> = {}) => {
      trackMixpanel(eventName, {
        whiteLabelId: whitelabel?._id,
        ...data,
      });
    },
    [trackMixpanel, whitelabel?._id]
  );

  return simpleTrack;
}
