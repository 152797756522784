import React from 'react';

import { OrderSummaryWithProducts } from 'lane-shared/domains/billingPayments/types';
import {
  currencyFormatter,
  imageUrl,
  dateFormatter,
} from 'lane-shared/helpers/formatters';
import { useTranslation } from 'react-i18next';
import styles from './OrderWithProductsPreview.scss';
import { M, S } from 'design-system-web';
import { LONG_MONTH_DATE_YEAR } from 'lane-shared/helpers/constants/dates';

export type OrderWithProductsPreviewProps = {
  backgroundImage: string;
  orderSummary: OrderSummaryWithProducts;
  contentName: string;
  contentDescription: string;
};

export function OrderWithProductsPreview({
  backgroundImage,
  orderSummary,
  contentName,
  contentDescription,
}: OrderWithProductsPreviewProps) {
  const { t } = useTranslation();
  const { subscriptionDetails } = orderSummary;

  let formattedEndDate: string | undefined;
  let formattedStartDate: string | undefined;
  if (subscriptionDetails) {
    if (subscriptionDetails.endDate) {
      formattedEndDate = dateFormatter(
        subscriptionDetails.endDate,
        LONG_MONTH_DATE_YEAR
      );
    }
    formattedStartDate = dateFormatter(new Date(), LONG_MONTH_DATE_YEAR);
  }

  return (
    <div className={styles.previewContainer}>
      {backgroundImage && (
        <div>
          <div
            className={styles.previewSectionImage}
            data-test="order-preview-image"
            style={{
              backgroundImage: `url(${imageUrl(backgroundImage)})`,
            }}
          />
        </div>
      )}
      <div className={styles.contentContainer}>
        <div className={styles.mainDetails}>
          <M className="font-medium">{contentName}</M>
          <S variant="secondary">{contentDescription}</S>
          {subscriptionDetails && (
            <>
              <M className="text-md text-text-subdued font-normal">
                {t('abp.payment.orderSummary.recurrenceStarts', {
                  date: formattedStartDate,
                })}
              </M>
              <M className="text-md text-text-subdued font-normal">
                {formattedEndDate
                  ? t('abp.payment.orderSummary.recurrenceEnds', {
                      date: formattedEndDate,
                    })
                  : t('abp.payment.orderSummary.recurrenceEnds.never')}
              </M>
            </>
          )}
        </div>
        <div className={styles.productTotal}>
          <M>
            {currencyFormatter({
              currency: orderSummary.currency,
            })(Number(orderSummary.cashTotal - orderSummary.taxTotal))}
          </M>
          {subscriptionDetails && (
            <M className="text-md text-text-subdued font-normal">
              {t(
                `abp.payment.orderSummary.recurrenceInterval.${subscriptionDetails?.recurrenceInterval}`
              )}
            </M>
          )}
        </div>
      </div>
    </div>
  );
}
