import { v4 as uuid } from 'uuid';

import Types from '../../properties/Types';
import { Currency, Percentage, Number } from '../../properties/baseTypes';
import { PropertyType } from '../../types/properties/Property';
import { explodeValidators } from '../properties';
import cloneDeep from 'lodash/cloneDeep';

const numberTypes = [Percentage.name, Currency.name, Number.name];

/**
 * Takes a property definition and initializes a value based on that definition.
 *
 * @param property {Property} the property to construct
 * @param forArray {boolean} is this value being added into an array.
 * @returns {any}
 */
export default function constructProperty(
  property: PropertyType,
  forArray: boolean = false
): any {
  const type = Types.getType(property.type);

  // if there is an explicit default set, use that
  if (!forArray && Object.prototype.hasOwnProperty.call(property, 'default')) {
    const value: any = cloneDeep(property.default);

    if (value && typeof value === 'object' && !value?._id) {
      value._id = uuid();
    }

    return value;
  }

  // if the property is an array, setup an empty array
  if (!forArray && property.isArray) {
    return [];
  }

  // otherwise use the type default (this is still likely to be null, but for complex types
  // it will initialize them for us to prevent tons of null checks later).
  let value: any = cloneDeep(type.default);

  if (numberTypes.includes(type.name)) {
    // there is a special validator, the Min validator on number types, that is helpful
    // to set here as the default value.
    const { minValidator } = explodeValidators(property.validators);

    if (minValidator) {
      value = minValidator.value;
    }
  }

  if (
    typeof value === 'object' &&
    value !== null &&
    !value._id &&
    !Array.isArray(value)
  ) {
    value._id = uuid();
  }

  return value;
}
