import gql from 'graphql-tag';

export default gql`
  mutation upsertVisitorManagementOrganizationSettings(
    $organizationSettings: VisitorOrganizationSettingsInput!
  ) {
    upsertVisitorOrganizationSettings(
      organizationSettings: $organizationSettings
    ) {
      organizationId
      visitorEmailOnCreate
      visitorEmailOnUpdate
      visitorEmailOnCancel
      hostEmailOnConfirm
      hostEmailOnUpdate
      hostEmailOnCancel
      hostEmailOnCheckIn
      hostNotificationOnCheckIn
      visitorSmsNotificationOnCreate
      createdAtDatetime
      updatedAtDatetime
      createdByUserId
      updatedByUserId
    }
  }
`;
