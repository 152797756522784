import React, { useEffect } from 'react';

import { useDateRangePicker } from 'lane-shared/hooks';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import Calendar from './components/Calendar';

type OwnDateRangePickerProps = {
  className?: string;
  style?: React.CSSProperties;
  // display a value that is already selected
  existingValue?: DateRangeType;
  // the timezone to display dates in
  timeZone?: string;
  disabled?: boolean;
  weekdayOnly?: boolean;
  loading?: boolean;
  // the minimum range size in days, i.e. you must select 2 days
  minRangeSize: number;
  // the maximum range size in days, i.e. you can only select 7 days
  maxRangeSize: number;
  startDate: Date;
  endDate: Date;
  // include a time picker? or just for date range
  includeTime?: boolean;
  // the minimum date allowed to be selected
  minDate?: Date;
  // the maximum date allowed to be selected
  maxDate?: Date;
  // an array of date ranges that are not available
  unavailableDateRanges?: DateRangeType[];
  // type of position - default 'fixed'
  dropdownPosition?: 'absolute' | 'fixed';
  // when the user changes month view
  onFocusChange?: () => void;
  // when the dates have changed
  onChange: (dateRange: { startDate?: string; endDate?: string }) => void;
  // when the user sets a new date
  onSubmit?: ((dateRange: { startDate?: string; endDate?: string }) => void) | null;
  hasToResetDateRange?: boolean;
};

type DateRangePickerProps = OwnDateRangePickerProps;

/**
 * @deprecated use DateRangePicker in design-system-web instead.
 */
export default function DateRangePicker({
  startDate,
  endDate,
  existingValue,
  onChange = () => {},
  onSubmit = null,
  includeTime = false,
  minDate,
  maxDate,
  timeZone,
  unavailableDateRanges = [],
  minRangeSize,
  maxRangeSize,
  disabled,
  className,
  style,
  onFocusChange = () => {},
  weekdayOnly,
  dropdownPosition,
  ...otherProps
}: DateRangePickerProps) {
  const [internalRange, setDate, resetRange] = useDateRangePicker({
    timeZone,
    startDate,
    endDate,
    minRangeSize,
    maxRangeSize,
  });
  const { hasToResetDateRange } = otherProps;
  const _startDate = internalRange.startDate || startDate;
  const _endDate = internalRange.endDate || endDate;

  useEffect(() => {
    // has to reset date range on unmount component
    return () => resetRange();
  }, [hasToResetDateRange]);

  useEffect(() => {
    onChange(internalRange);
  }, [internalRange.startDate, internalRange.endDate]);

  function onDateClick(_day: any) {
    setDate(_day);
  }

  return (
    <div className={className} style={style}>
      <Calendar
        disabled={disabled}
        timeZone={timeZone}
        onChange={onDateClick}
        onSubmit={onSubmit ? () => onSubmit(internalRange) : null}
        includeTime={includeTime}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Date | nu... Remove this comment to see the full error message
        startDate={_startDate}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Date | nu... Remove this comment to see the full error message
        endDate={_endDate}
        weekdayOnly={weekdayOnly}
        minDate={minDate}
        maxDate={maxDate}
        existingValue={existingValue}
        unavailableDateRanges={unavailableDateRanges}
        onFocusChange={onFocusChange}
        dropdownPosition={dropdownPosition}
      />
    </div>
  );
}
