import { ContentWorkflowType } from '../../types/ContentWorkflowType';
import { WorkflowActionEnum, WorkflowTargetEnum } from '../../types/Workflows';
import {
  EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_CONTENT_INTERACTION_UPDATED,
} from '../constants/events';
import constructWorkflow from './constructWorkflow';

export default function constructDefaultWorkflows({
  userId,
  contentId,
  groupRoleId,
}: {
  userId: string;
  contentId: string;
  groupRoleId?: string;
}): ContentWorkflowType[] {
  const workflows: ContentWorkflowType[] = [];

  // end user email on create
  workflows.push(
    constructWorkflow({
      userId,
      contentId,
      event: EVENT_CONTENT_INTERACTION_CREATED,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.InteractionCreator,
    }) as ContentWorkflowType
  );

  // end user email on update
  workflows.push(
    constructWorkflow({
      userId,
      contentId,
      event: EVENT_CONTENT_INTERACTION_UPDATED,
      action: WorkflowActionEnum.Email,
      target: WorkflowTargetEnum.InteractionCreator,
    }) as ContentWorkflowType
  );

  // end user notification on create
  workflows.push(
    constructWorkflow({
      userId,
      contentId,
      event: EVENT_CONTENT_INTERACTION_CREATED,
      action: WorkflowActionEnum.Notification,
      target: WorkflowTargetEnum.InteractionCreator,
    }) as ContentWorkflowType
  );

  // end user notification on update
  workflows.push(
    constructWorkflow({
      userId,
      contentId,
      event: EVENT_CONTENT_INTERACTION_UPDATED,
      action: WorkflowActionEnum.Notification,
      target: WorkflowTargetEnum.InteractionCreator,
    }) as ContentWorkflowType
  );

  if (groupRoleId) {
    // group role email on create
    workflows.push(
      constructWorkflow({
        userId,
        contentId,
        groupRoleId,
        event: EVENT_CONTENT_INTERACTION_CREATED,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.GroupRole,
      }) as ContentWorkflowType
    );

    // group role email on update
    workflows.push(
      constructWorkflow({
        userId,
        contentId,
        groupRoleId,
        event: EVENT_CONTENT_INTERACTION_UPDATED,
        action: WorkflowActionEnum.Email,
        target: WorkflowTargetEnum.GroupRole,
      }) as ContentWorkflowType
    );

    // group role notification on create
    workflows.push(
      constructWorkflow({
        userId,
        contentId,
        groupRoleId,
        event: EVENT_CONTENT_INTERACTION_CREATED,
        action: WorkflowActionEnum.Notification,
        target: WorkflowTargetEnum.GroupRole,
      }) as ContentWorkflowType
    );

    // group role notification on update
    workflows.push(
      constructWorkflow({
        userId,
        contentId,
        groupRoleId,
        event: EVENT_CONTENT_INTERACTION_UPDATED,
        action: WorkflowActionEnum.Notification,
        target: WorkflowTargetEnum.GroupRole,
      }) as ContentWorkflowType
    );
  }

  return workflows;
}
