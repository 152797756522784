import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { joinWaitlistMutation, leaveWaitlistMutation } from 'graphql-queries';
import { Mutation } from 'graphql-query-contracts';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import useUserDataContext from '../useUserDataContext';
import { TranslationKeys } from 'localization';

const TRANSLATION_KEYS: { [key: string]: TranslationKeys } = {
  joinWaitlistUnauthorise:
    'shared.content.feature.reservable.waitlist.join.unauthorise',
  leaveWaitlistUnauthorise:
    'shared.content.feature.reservable.waitlist.leave.unauthorise',
};

type JoinWaitlistMutation = {
  joinWaitlist: Mutation['joinWaitlist'];
};

type LeaveWaitlistMutation = {
  leaveWaitlist: Mutation['leaveWaitlist'];
};

export function useContentWaitlistActions() {
  const { user } = useUserDataContext();
  const { t } = useTranslation();

  const [
    joinWaitlist,
    { loading: isJoinWaitlistLoading },
  ] = useMutation<JoinWaitlistMutation>(joinWaitlistMutation);
  const [
    leaveWaitlist,
    { loading: isLeaveWaitlistLoading },
  ] = useMutation<LeaveWaitlistMutation>(leaveWaitlistMutation);

  const handleJoinWaitlist = async (
    contentId: string,
    timeRange: DateRangeType
  ) => {
    if (!user?._id) {
      throw new Error(t(TRANSLATION_KEYS.joinWaitlistUnauthorise));
    }

    const { data } = await joinWaitlist({
      variables: {
        userId: user._id,
        contentId,
        startDate: timeRange.startDate,
        endDate: timeRange.endDate,
      },
    });

    return data?.joinWaitlist;
  };

  const handleLeaveWaitlist = async (
    enrollmentId: string,
    contentId: string
  ) => {
    if (!user?._id) {
      throw new Error(t(TRANSLATION_KEYS.leaveWaitlistUnauthorise));
    }

    const { data } = await leaveWaitlist({
      variables: { enrollmentId, contentId },
    });

    return data?.leaveWaitlist;
  };

  return {
    joinWaitlist: handleJoinWaitlist,
    leaveWaitlist: handleLeaveWaitlist,
    isLoading: isJoinWaitlistLoading || isLeaveWaitlistLoading,
  };
}
