import gql from 'graphql-tag';

export default gql`
  query ContentsForIntegration($_id: UUID!) {
    contentsForIntegration(_id: $_id) {
      _id
      name
      _created
      _createdBy {
        name
      }
    }
  }
`;
