import gql from 'graphql-tag';

export default gql`
  mutation CompleteSignup(
    $guid: String!
    $verify: String!
    $password: String!
    $isPublic: Boolean!
  ) {
    completeSignup(
      guid: $guid
      verify: $verify
      password: $password
      isPublic: $isPublic
    ) {
      jti
      token
    }
  }
`;
