import { gql } from 'graphql-query-contracts';

export const leaveWaitlistMutation = gql(/* GraphQL */ `
  mutation leaveWaitlist($enrollmentId: UUID!, $contentId: UUID!) {
    leaveWaitlist(enrollmentId: $enrollmentId, contentId: $contentId) {
      startDate
      endDate
    }
  }
`);
