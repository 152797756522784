import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class SignUpPrivateLocationError extends CodedError {
  static get errorName() {
    return errorCodes.signUpPrivateLocationError.code;
  }

  constructor(message = errorCodes.signUpPrivateLocationError.message) {
    super(message);
    this.code = errorCodes.signUpPrivateLocationError.code;
    this.message = `${message} ${errorCodes.signUpPrivateLocationError.code}`;
    this.name = errorCodes.signUpPrivateLocationError.name;
  }
}
