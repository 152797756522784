import { useLazyQuery } from '@apollo/client';
import { getQuantityAllotments } from 'graphql-queries';

export const useQuantityAllotments = (contentId: string) => {
  const [fetchQuantityAllotments, { data }] = useLazyQuery(
    getQuantityAllotments,
    {
      variables: {
        contentId,
      },
    }
  );

  async function handleFetchQuantityAllotments() {
    await fetchQuantityAllotments();
  }

  return {
    fetchAllotments: handleFetchQuantityAllotments,
    ruleAllotments:
      data?.contentFeatureQuantityAllotments.totalRuleClaimedAllotments || 0,
    ruleMaxAllottedUnits:
      data?.contentFeatureQuantityAllotments.ruleMaxAllottedUnits || 0,
    hasUserRemainingAllotmentsToClaim: !!(
      data && data.contentFeatureQuantityAllotments.totalRemainingAllotments > 0
    ),
    hasUserExistingBookings: !!(
      data && data.contentFeatureQuantityAllotments.totalUserInteractions > 0
    ),
  };
};
