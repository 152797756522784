export function hasChildrenWhereChannelPagesExist({
  parent,
  channelIdsWhereChannelPagesExist,
}: {
  parent: { subs: any[]; channelLocations: any[] };
  channelIdsWhereChannelPagesExist: string[];
}) {
  const channelPagesExistInSubs = parent.subs?.some(sub =>
    channelIdsWhereChannelPagesExist.includes(sub._id)
  );
  const channelPagesExistInChannelLocations = parent.channelLocations?.some(
    channelLocation =>
      channelIdsWhereChannelPagesExist.includes(channelLocation._id)
  );

  return channelPagesExistInSubs || channelPagesExistInChannelLocations;
}
