import convertTo62 from '../convertId/convertTo62';

// todo: update to use user locale for l10n
/**
 * ContentData stored on an interaction is a PITA copy of that content, with just
 * enough information to re-create some key pieces on the client side.
 *
 * @param contentData
 * @returns Object
 */
export default function contentDataToContent(contentData: any) {
  return {
    ...contentData,
    _id: convertTo62(contentData._id),
    _createdBy: convertTo62(contentData._createdBy),
    _updatedBy: convertTo62(contentData._updatedBy),
    _created: new Date(contentData._created),
    _updated: new Date(contentData._updated),
    card: { _id: convertTo62(contentData.cardId || contentData.card._id) },
    geo: contentData.location,
    logo: contentData.logoId ? convertTo62(contentData.logoId) : undefined,
    backgroundImage: contentData.backgroundImageId
      ? convertTo62(contentData.backgroundImageId)
      : undefined,
    liveDate: contentData.liveDate ? new Date(contentData.liveDate) : undefined,
    startDate: contentData.startDate
      ? new Date(contentData.startDate)
      : undefined,
    endDate: contentData.endDate ? new Date(contentData.endDate) : undefined,
    unpublishDate: contentData.unpublishDate
      ? new Date(contentData.unpublishDate)
      : undefined,
    channel: {
      _id:
        contentData.channel || contentData.channelId
          ? convertTo62(contentData.channel || contentData.channelId)
          : undefined,
      settings: {
        ...contentData.channel?.settings,
      },
    },
    name: contentData.name,
    name_l10n: contentData.name_l10n,
    description: contentData.description,
    description_l10n: contentData.description_l10n,
    publishedAt: contentData.publishedAt
      ? new Date(contentData.publishedAt)
      : undefined,
    publishedBy: contentData.publishedBy
      ? convertTo62(contentData.publishedBy)
      : undefined,
    features: contentData.features,
    integration: contentData.integration
      ? {
          _id: convertTo62(contentData.integration._id),
          integration: {
            _id: convertTo62(contentData.integration.integration._id),
            name: contentData.integration.integration.name,
          },
        }
      : null,
  };
}
