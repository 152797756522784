import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class SignUpFullAccessError extends CodedError {
  static get errorName() {
    return errorCodes.signUpFullAccessError.code;
  }

  constructor(message = errorCodes.signUpFullAccessError.message) {
    super(message);
    this.code = errorCodes.signUpFullAccessError.code;
    this.message = `${message} ${errorCodes.signUpFullAccessError.code}`;
    this.name = errorCodes.signUpFullAccessError.name;
  }
}
