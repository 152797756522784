/* istanbul ignore file */
import { createContext } from 'react';

import ILaunchDarklyClient from '../types/LaunchDarkly';

export type LaunchDarklyContextType = {
  flags: undefined | { [key: string]: any };
  client: ILaunchDarklyClient;
};

const defaultLaunchDarklyContext: LaunchDarklyContextType = {
  flags: undefined,
  client: {
    on: () => undefined,
    off: () => undefined,
    setUser: () => undefined,
    unsetUser: () => undefined,
    setAttribute: () => undefined,
  },
};

const LaunchDarklyContext = createContext(defaultLaunchDarklyContext);

export default LaunchDarklyContext;
