import { CodedError } from './CodedError';
import errorCodes from './constants/errorCodes';

export default class NetworkRequestFailedError extends CodedError {
  static get errorName() {
    return errorCodes.networkRequestFailedError.code;
  }

  constructor(message = errorCodes.networkRequestFailedError.message) {
    super(message);
    this.code = errorCodes.networkRequestFailedError.code;
    this.message = `${message} ${errorCodes.networkRequestFailedError.code}`;
    this.name = errorCodes.networkRequestFailedError.name;
  }
}
