import gql from 'graphql-tag';

export type DeleteFloorMutationResponse = {
  deleteFloor: boolean;
};

export const deleteFloor = gql`
  mutation DeleteFloor($propertyId: UUID!, $floorId: UUID!) {
    deleteFloor(propertyId: $propertyId, floorId: $floorId)
  }
`;
