import { pull } from 'lodash';
import type { AuthorizeResult } from 'react-native-app-auth';

import { SafetrustOrganizationType } from 'lane-shared/helpers/integrations/Safetrust/SafetrustEnums';
import { JsonValue } from 'lane-shared/types/JSONType';

import { NotFoundError } from 'activate-errors';

export enum StorageKeys {
  AUTH_TOKEN = 'JWT_AUTH_TOKEN',
  AZURE_AD_AUTH_STATE = '0:AUTH_STATE',
  GOOGLE_AUTH_STATE = '1:AUTH_STATE',
  OKTA_AUTH_STATE = '2:AUTH_STATE',
  LIVESAFE_USED = '@store:hasUserUsedLiveSafe',
  PRIMARY_CHANNEL = 'primary',
  SECONDARY_CHANNEL = 'secondary',
  PERFORM_COOKIE_UPDATE = 'performCookieUpdate',
  PUSH_NOTIFICATIONS = 'PushNotification46',
  PROXYCLICK_ONBOARDING_SEEN = 'PROXYCLICK_ONBOARDING_SEEN',
  SAFETRUST_ONBOARDING_SEEN = 'SAFETRUST_ONBOARDING_SEEN',
  SAFETRUST_ORGANIZATIONS = 'SAFETRUST_ORGANIZATIONS',
  SAFETRUST_SET_ORGANIZATION_IDS = 'SAFETRUST_SET_ORGANIZATIONS',
  SAFETRUST_USERID = 'SAFETRUST_USERID',
  SAFETRUST_FEEDBACK_MODE = 'safetrustFeedbackMode',
  SAFETRUST_ACCESS_MODE = 'SAFETRUST_ACCESS_MODE',
  CONTACTS_NAGGED = 'contactsNagged',
  USER_LOCALE = 'userLocale',
  USER_CHANNELS_DATA_CONTEXT = 'userChannelsDataContext',
  HID_ONBOARDING_SEEN = 'HID_ONBOARDING_SEEN',
  HID_ACCESS_MODE = 'hidAccessMode',
  HID_FEEDBACK_MODE = 'hidFeedbackMode',
  HID_TWISTED_ENABLED = 'hidTwistEnabled',
  KASTLE_EMAIL = 'KASTLE_EMAIL',
  KASTLE_ONBOARDING_SEEN = 'KASTLE_ONBOARDING_SEEN',
  ACCESS_CONTROL_ENABLED_PROVIDERS = 'accessControlEnabledProviders',
  SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN = 'shopifyBurstLatestUpdatedAtAlertShown',
  BE_PRISM_TOKEN = 'be-prism-auth',
  IMAGES_NAGGED = 'imagesNagged',
  CAMERA_NAGGED = 'cameraNagged',
}

/** @deprecated use StorageKeys */
export const AUTH_TOKEN = StorageKeys.AUTH_TOKEN;
/** @deprecated use StorageKeys */
export const LIVESAFE_USED = StorageKeys.LIVESAFE_USED;
/** @deprecated use StorageKeys */
export const PRIMARY_CHANNEL = StorageKeys.PRIMARY_CHANNEL;
/** @deprecated use StorageKeys */
export const SECONDARY_CHANNEL = StorageKeys.SECONDARY_CHANNEL;
/** @deprecated use StorageKeys */
export const PUSH_NOTIFICATIONS = StorageKeys.PUSH_NOTIFICATIONS;
/** @deprecated use StorageKeys */
export const PROXYCLICK_ONBOARDING_SEEN =
  StorageKeys.PROXYCLICK_ONBOARDING_SEEN;
/** @deprecated use StorageKeys */
export const SAFETRUST_ONBOARDING_SEEN = StorageKeys.SAFETRUST_ONBOARDING_SEEN;
/** @deprecated use StorageKeys */
export const SAFETRUST_ORGANIZATIONS = StorageKeys.SAFETRUST_ORGANIZATIONS;
/** @deprecated use StorageKeys */
export const SAFETRUST_SET_ORGANIZATION_IDS =
  StorageKeys.SAFETRUST_SET_ORGANIZATION_IDS;
/** @deprecated use StorageKeys */
export const SAFETRUST_USERID = StorageKeys.SAFETRUST_USERID;
/** @deprecated use StorageKeys */
export const SAFETRUST_FEEDBACK_MODE = StorageKeys.SAFETRUST_FEEDBACK_MODE;
/** @deprecated use StorageKeys */
export const SAFETRUST_ACCESS_MODE = StorageKeys.SAFETRUST_ACCESS_MODE;
/** @deprecated use StorageKeys */
export const CONTACTS_NAGGED = StorageKeys.CONTACTS_NAGGED;
/** @deprecated use StorageKeys */
export const USER_LOCALE = StorageKeys.USER_LOCALE;
/** @deprecated use StorageKeys */
export const USER_CHANNELS_DATA_CONTEXT =
  StorageKeys.USER_CHANNELS_DATA_CONTEXT;
/** @deprecated use StorageKeys */
export const HID_ONBOARDING_SEEN = StorageKeys.HID_ONBOARDING_SEEN;
/** @deprecated use StorageKeys */
export const HID_ACCESS_MODE = StorageKeys.HID_ACCESS_MODE;
/** @deprecated use StorageKeys */
export const HID_FEEDBACK_MODE = StorageKeys.HID_FEEDBACK_MODE;
/** @deprecated use StorageKeys */
export const HID_TWISTED_ENABLED = StorageKeys.HID_TWISTED_ENABLED;
/** @deprecated use StorageKeys */
export const KASTLE_EMAIL = StorageKeys.KASTLE_EMAIL;
/** @deprecated use StorageKeys */
export const KASTLE_ONBOARDING_SEEN = StorageKeys.KASTLE_ONBOARDING_SEEN;
/** @deprecated use StorageKeys */
export const ACCESS_CONTROL_ENABLED_PROVIDERS =
  StorageKeys.ACCESS_CONTROL_ENABLED_PROVIDERS;
/** @deprecated use StorageKeys */
export const SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN =
  StorageKeys.SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN;
/** @deprecated use StorageKeys */
export const BE_PRISM_TOKEN = StorageKeys.BE_PRISM_TOKEN;

export interface StorageData {
  [StorageKeys.AUTH_TOKEN]: {
    jti: string;
    token: string;
  };
  [StorageKeys.AZURE_AD_AUTH_STATE]: AuthorizeResult;
  [StorageKeys.BE_PRISM_TOKEN]: string;
  [StorageKeys.GOOGLE_AUTH_STATE]: AuthorizeResult;
  [StorageKeys.HID_ONBOARDING_SEEN]: boolean | null;
  [StorageKeys.KASTLE_EMAIL]: string;
  [StorageKeys.KASTLE_ONBOARDING_SEEN]: boolean;
  [StorageKeys.OKTA_AUTH_STATE]: AuthorizeResult;
  [StorageKeys.PERFORM_COOKIE_UPDATE]: boolean;
  [StorageKeys.SAFETRUST_ONBOARDING_SEEN]: boolean | null;
  [StorageKeys.SAFETRUST_ORGANIZATIONS]: SafetrustOrganizationType[];
  [StorageKeys.SAFETRUST_SET_ORGANIZATION_IDS]: string[];

  [key: string]: Value | StorageData[keyof StorageData];
}

type Value = JsonValue;
type ValueRaw = string;

export type StorageProvider = {
  setItem(key: string, value: ValueRaw): Promise<unknown>;
  getItem(key: string): Promise<ValueRaw | null>;
  removeItem(key: string): Promise<void>;
  clear(): Promise<void>;
};

export default class Storage {
  /** @deprecated use StorageKeys */
  static LIVESAFE_USED = StorageKeys.LIVESAFE_USED as const;

  /** @deprecated use StorageKeys */
  static AUTH_TOKEN = StorageKeys.AUTH_TOKEN as const;

  /** @deprecated use StorageKeys */
  static PRIMARY_CHANNEL = StorageKeys.PRIMARY_CHANNEL as const;

  /** @deprecated use StorageKeys */
  static SECONDARY_CHANNEL = StorageKeys.SECONDARY_CHANNEL as const;

  /** @deprecated use StorageKeys */
  static PUSH_NOTIFICATIONS = StorageKeys.PUSH_NOTIFICATIONS as const;

  /** @deprecated use StorageKeys */
  static PROXYCLICK_ONBOARDING_SEEN = StorageKeys.PROXYCLICK_ONBOARDING_SEEN as const;

  /** @deprecated use StorageKeys */
  static SAFETRUST_ONBOARDING_SEEN = StorageKeys.SAFETRUST_ONBOARDING_SEEN as const;

  /** @deprecated use StorageKeys */
  static SAFETRUST_USERID = StorageKeys.SAFETRUST_USERID as const;

  /** @deprecated use StorageKeys */
  static SAFETRUST_ORGANIZATIONS = StorageKeys.SAFETRUST_ORGANIZATIONS as const;

  /** @deprecated use StorageKeys */
  static SAFETRUST_FEEDBACK_MODE = StorageKeys.SAFETRUST_FEEDBACK_MODE as const;

  /** @deprecated use StorageKeys */
  static CONTACTS_NAGGED = StorageKeys.CONTACTS_NAGGED as const;

  /** @deprecated use StorageKeys */
  static SAFETRUST_SET_ORGANIZATION_IDS = StorageKeys.SAFETRUST_SET_ORGANIZATION_IDS as const;

  /** @deprecated use StorageKeys */
  static SAFETRUST_ACCESS_MODE = StorageKeys.SAFETRUST_ACCESS_MODE as const;

  /** @deprecated use StorageKeys */
  static USER_LOCALE = StorageKeys.USER_LOCALE as const;

  /** @deprecated use StorageKeys */
  static HID_ONBOARDING_SEEN = StorageKeys.HID_ONBOARDING_SEEN as const;

  /** @deprecated use StorageKeys */
  static HID_ACCESS_MODE = StorageKeys.HID_ACCESS_MODE as const;

  /** @deprecated use StorageKeys */
  static HID_FEEDBACK_MODE = StorageKeys.HID_FEEDBACK_MODE as const;

  /** @deprecated use StorageKeys */
  static HID_TWISTED_ENABLED = StorageKeys.HID_TWISTED_ENABLED as const;

  /** @deprecated use StorageKeys */
  static KASTLE_EMAIL = StorageKeys.KASTLE_EMAIL as const;

  /** @deprecated use StorageKeys */
  static KASTLE_ONBOARDING_SEEN = StorageKeys.KASTLE_ONBOARDING_SEEN as const;

  /** @deprecated use StorageKeys */
  static SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN = StorageKeys.SHOPIFY_BURST_LATEST_UPDATED_ALERT_SHOWN as const;

  /** @deprecated use StorageKeys */
  static ACCESS_CONTROL_ENABLED_PROVIDERS = StorageKeys.ACCESS_CONTROL_ENABLED_PROVIDERS as const;

  static provider: StorageProvider | undefined;

  static subscriptions: {
    [Key in keyof StorageData]?: ((val: StorageData[Key]) => void)[];
  } = {};

  static setProvider(provider: StorageProvider) {
    this.provider = provider;
  }

  static async setItem<Key extends keyof StorageData>(
    key: Key,
    data: StorageData[Key]
  ): Promise<void>;

  static async setItem<Key extends keyof StorageData>(
    key: Key,
    data: unknown
  ): Promise<void>;

  static async setItem<Key extends keyof StorageData>(
    key: Key,
    data: unknown
  ): Promise<void> {
    const provider = this.getProviderOrThrow();

    if (data === null || data === undefined) {
      // setting a null object will fail, should be removed instead.
      return this.removeItem(key);
    }

    provider.setItem(
      key as string,
      typeof data === 'string' ? data : JSON.stringify(data)
    );
    await this.runCallback(key);
  }

  static async getItem<Key extends keyof StorageData>(
    key: Key
  ): Promise<StorageData[Key]>;

  static async getItem<Key extends keyof StorageData>(key: Key): Promise<Value>;

  static async getItem<Key extends keyof StorageData>(
    key: Key
  ): Promise<StorageData[Key]> {
    const data = await this.getItemMaybe(key);
    if (!data) {
      throw new NotFoundError(`${key} not found`);
    }
    return data;
  }

  static async getItemMaybe<Key extends keyof StorageData>(
    key: Key
  ): Promise<StorageData[Key] | null>;

  static async getItemMaybe<Key extends keyof StorageData>(
    key: Key
  ): Promise<Value>;

  static async getItemMaybe<Key extends keyof StorageData>(
    key: Key
  ): Promise<Value | null> {
    const provider = this.getProviderOrThrow();
    const data = await provider.getItem(key as string);
    if (data) {
      try {
        return JSON.parse(data);
      } catch {
        // ignore
      }
    }
    return data;
  }

  static async removeItem<Key extends keyof StorageData>(
    key: Key
  ): Promise<void> {
    const provider = this.getProviderOrThrow();
    await provider.removeItem(key as string);
    await this.runCallback(key);
  }

  static subscribe<Key extends keyof StorageData>(
    key: Key,
    callback: (val: StorageData[Key]) => void
  ): () => void {
    this.subscriptions[key] = this.subscriptions[key] || [];
    const callbacks = this.subscriptions[key]!;
    callbacks.push(callback);
    const unsubscribeFn = () => {
      pull(callbacks, callback);
    };
    return unsubscribeFn;
  }

  static async clear(): Promise<void> {
    const provider = this.getProviderOrThrow();
    await provider.clear();
    this.subscriptions = {};
  }

  private static getProviderOrThrow(): StorageProvider {
    if (!this.provider) {
      throw new Error('Storage.provider is undefined');
    }
    return this.provider;
  }

  private static async runCallback<Key extends keyof StorageData>(key: Key) {
    const callbacks = this.subscriptions[key] || [];
    if (callbacks) {
      const value = await this.getItemMaybe(key);
      callbacks.forEach(callback => callback(value));
    }
  }
}
