import gql from 'graphql-tag';

import { PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}

  query publicUser($id: UUID!) {
    user(_id: $id) {
      ...PublicUserFragment
    }
  }
`;
