import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import WeeklyTimeRangePicker from 'components/features/TimeAvailability/WeeklyTimeRangePicker';
import Button from 'components/general/Button';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';
import { Flex } from 'components/layout';

import FeatureOptionToggle from '../components/FeatureOptionToggle';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  targetedAvailabilityTitle:
    'web.content.features.TimeAvailability.TimeAvailabilityTargeted.targetedAvailability.title',
  targetedAvailabilityDescription:
    'web.content.features.TimeAvailability.TimeAvailabilityTargeted.targetedAvailability.description',
  addAnother: 'web.content.features.TimeAvailability.addAnother',
  edit: 'web.content.features.TimeAvailability.edit',
  done: 'web.content.features.TimeAvailability.done',
};

export default function TimeAvailabilityTargeted({
  onToggleFeature,
  availabilities,
  content,
  hasFeatureToggled,
  onGroupRoleSelected,
  onRemoveAvailability,
  onAddAvailability,
  onToggleOpen,
  onTimeRangeSelect,
  onAddTimeRange,
  onRemoveTimeRange,
  onAllDayToggle,
  timeZone,
}: any) {
  const [editingId, setEditingId] = useState<LaneType.UUID | null>(null);

  const { t } = useTranslation();

  return (
    <FeatureOptionToggle
      title={t(TRANSLATION_KEYS.targetedAvailabilityTitle)}
      description={t(TRANSLATION_KEYS.targetedAvailabilityDescription)}
      onChange={onToggleFeature}
      isExpanded={hasFeatureToggled}
    >
      <>
        <div className={styles.availabilityRulesContainer}>
          {availabilities.map((availability: any) => {
            // Default is already rendered
            if (availability.allGroupRoles) {
              return null;
            }

            const isEditing = availability._id === editingId;

            return (
              <div
                key={availability._id}
                className={cx(styles.availabilityRule, {
                  [styles.isEditing]: isEditing,
                })}
              >
                <div
                  className={styles.inputRow}
                  style={{ marginBottom: '1em' }}
                >
                  <GroupRoleSelector
                    isCardView
                    className={styles.groupRoleSelector}
                    groupRoleId={availability.groupRole?._id}
                    channelId={content?.channel?._id}
                    onGroupRoleSelected={groupRole =>
                      onGroupRoleSelected(availability._id, groupRole)
                    }
                  />
                  <div className={styles.editContainer}>
                    {!isEditing ? (
                      <Button
                        variant="outlined"
                        onClick={() => setEditingId(availability._id)}
                      >
                        {t(TRANSLATION_KEYS.edit)}
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="outlined-light"
                          onClick={() => {
                            setEditingId(null);
                            onRemoveAvailability(availability._id);
                          }}
                        >
                          {t('Delete')}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setEditingId(null)}
                        >
                          {t(TRANSLATION_KEYS.done)}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <WeeklyTimeRangePicker
                    weeklyAvailability={availability}
                    onTimeRangeSelect={onTimeRangeSelect}
                    onAddTimeRange={onAddTimeRange}
                    onRemoveTimeRange={onRemoveTimeRange}
                    onToggleOpen={onToggleOpen}
                    onAllDayToggle={onAllDayToggle}
                    isEditing={isEditing}
                    timeZone={timeZone}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Flex justify="center">
          <Button
            variant="outlined"
            startIcon={<Icon name="plus" />}
            onClick={onAddAvailability}
            testId="addNewRule"
            className={styles.addAnother}
          >
            {t(TRANSLATION_KEYS.addAnother)}
          </Button>
        </Flex>
      </>
    </FeatureOptionToggle>
  );
}
