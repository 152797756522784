import gql from 'graphql-tag';

export default gql`
  query getChannelsForAccessManager($channelId: UUID!) {
    getChannelsForAccessManager(channelId: $channelId) {
      channelId
      name
      propertyChannelId
      type
      userRoles
      profile {
        image
        logo
      }
      isConnectedByTemplate
    }
  }
`;
